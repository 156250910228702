import React, { FC, useMemo } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import TabLineBubbles from '../../../../../molecules/tab-line-bubbles/TabLineBubbles';
import { FiAlignRight, FiCode } from 'react-icons/fi';

type SearchInputProps = {
  active: string;
  setFeaturesSearchValue: (e: string) => void;
  intl: IntlShape;
};

export const SearchInput: FC<SearchInputProps> = ({
  active,
  setFeaturesSearchValue,
  intl,
}: SearchInputProps) => {
  if (active === 'bars') {
    return (
      <div className='influencers-search-input-parent'>
        <input
          className='influencers--search--input'
          placeholder={intl.formatMessage({
            id: 'dashboard.search.search_placeholder',
          })}
          onChange={(e) => setFeaturesSearchValue(e.target.value)}
        />
      </div>
    );
  } else {
    return null; // No Search Bar in this case
  }
};

type Props = {
  active: string;
  setFeaturesSearchValue: (e: string) => void;
  intl: IntlShape;
  linkBars: string;
  linkBubbles: string;
  linkShap: string;
  isShapAvailable: boolean;
};

const InfluencersHeader: FC<Props> = ({
  active,
  setFeaturesSearchValue,
  intl,
  linkBars,
  linkBubbles,
  linkShap,
  isShapAvailable,
}) => {
  const buttons = [
    {
      id: 'bars',
      to: linkBars,
      intlId: 'details.influencers.header.bar_chart',
      intlDefault: 'Bar Chart',
      icon: () => <FiAlignRight size={16} />,
    },
  ];

  if (isShapAvailable) {
    buttons.push({
      id: 'shap',
      to: linkShap,
      intlId: 'details.influencers.header.shap',
      intlDefault: 'Shap Chart',
      icon: () => <FiCode size={16} />,
    });
  }

  buttons.push({
    id: 'bubbles',
    to: linkBubbles,
    intlId: 'details.influencers.header.bubble_chart',
    intlDefault: 'Bubble Chart',
    icon: () => <span className='icon-bubbles-chart' />,
  });

  const search = useMemo(
    () => () =>
      (
        <SearchInput
          active={active}
          setFeaturesSearchValue={setFeaturesSearchValue}
          intl={intl}
          // @ts-ignore
          linkBubbles={linkBubbles}
          linkBars={linkBars}
          linkShap={linkShap}
        />
      ),
    [active, setFeaturesSearchValue, intl, linkBubbles, linkBars, linkShap]
  );

  return (
    <div className='Influencers--header'>
      <TabLineBubbles
        buttons={buttons}
        activeButton={active}
        rightCenterY={true}
        rightAlignRight={true}
        RightComponent={search}
        preserveSearchParamsOnLink={true}
      />
    </div>
  );
};

export default injectIntl(InfluencersHeader);
