import { AdminBackupsState } from './state.types';

export const initial: AdminBackupsState = {
  backups: {
    loading: false,
    loaded: false,
    data: [],
  },
  restoreBackupModal: {
    show: false,
    bak: undefined,
    template: undefined,
  },
  createBackup: {
    submitting: false,
  },
  templates: {
    loading: false,
    loaded: false,
    data: [],
  },
  createTemplate: {
    submitting: false,
  },
};

export const test: AdminBackupsState = {
  backups: {
    loading: false,
    loaded: false,
    data: [],
  },
  restoreBackupModal: {
    show: false,
    bak: undefined,
    template: undefined,
  },
  createBackup: {
    submitting: false,
  },
  templates: {
    loading: false,
    loaded: false,
    data: [],
  },
  createTemplate: {
    submitting: false,
  },
};
