import * as _ from 'lodash';

// limit for timeseries
export const limit = 10;

export function getX(item) {
  return +item[0];
}

export function getY(item) {
  return +item[1];
}

export function getRunData(run) {
  return run.data;
}

export function flattenRuns(runs) {
  const data = runs.map((run) => getRunData(run));
  return _.flatten(data);
}

export function getRunValuesCount(run) {
  return getRunData(run).length;
}

export function getRunTimestamp(run) {
  return Date.parse(run.timestamp);
}

export function getDataByDomain(data, domain) {
  return data.filter(
    (item) =>
      getRunTimestamp(item) >= domain[0] && getRunTimestamp(item) <= domain[1]
  );
}

export const getMaxY = (list) => {
  let max = 0;

  _.forEach(list, (item) => {
    _.forEach(item.data, (data) => {
      const y = getY(data);

      if (y > max) {
        max = y;
      }
    });
  });

  return max;
};
