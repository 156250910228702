import React, { Component } from 'react';
import MultiPageWizard from '../../organisms/multi-page-wizard/MultiPageWizard.container';
import WizardPage from '../../organisms/multi-page-wizard/WizardPage';
import { commonReduxFormProps, wizardHeadline } from '../newAugur.form';
import {
  defaultAsyncBlurFields,
  defaultAsyncChangeFields,
  defaultValidate,
  fieldAugurName,
  fieldModuleType,
  getDefaultMpwAsyncValidate,
} from './default.form';
import WizardStep from '../../organisms/multi-page-wizard/WizardStep';
import AugurName from '../../organisms/modelManagement/newAugurWizard/augurName/AugurName';
import ModuleType from '../../organisms/modelManagement/newAugurWizard/moduleType/ModuleType';

import messages from 'common/dist/messages/augurs';
import { ModulesState } from '../../../store/modules/state.types';

export type OwnProps = {
  habitatCode: string;
  habitatName?: string;

  /** Navigation on cancel (generally back to the origin) */
  navigateOnCancel: () => void;
};

type ContainerProps = {
  modules?: ModulesState;
};

type Props = OwnProps & ContainerProps;

export const renderDefaultWizardSteps = (
  modules: ModulesState,
  resetForm?: () => void
) => {
  return (
    <>
      <WizardStep
        fieldName={fieldModuleType}
        stepNumber={1}
        title={messages.msgTitleModuleTypeStep}
        description={messages.msgDescriptionModuleTypeStep}
      >
        {/* @ts-ignore */}
        <ModuleType
          modules={modules?.data || []}
          loading={modules?.loading}
          blurOnClick
          beforeOnChange={resetForm}
        />
      </WizardStep>

      <WizardStep
        fieldName={fieldAugurName}
        stepNumber={2}
        title={messages.msgTitleNameStep}
        description={messages.msgDescriptionNameStep}
      >
        {/* @ts-ignore */}
        <AugurName inputId={fieldAugurName} inputName={fieldAugurName} />
      </WizardStep>
    </>
  );
};

export default class DefaultNewAugurWizard extends Component<Props> {
  render() {
    const { habitatCode, habitatName, modules, navigateOnCancel } = this.props;

    return (
      // @ts-ignore
      <MultiPageWizard
        {...commonReduxFormProps}
        validate={defaultValidate}
        // @ts-ignore
        asyncValidate={getDefaultMpwAsyncValidate(habitatCode)}
        asyncBlurFields={defaultAsyncBlurFields}
        asyncChangeFields={defaultAsyncChangeFields}
        noNextOrSubmitButton
        wizardHeadline={wizardHeadline(habitatName)}
        onCancel={navigateOnCancel}
      >
        {/* @ts-ignore */}
        <WizardPage
          pageTitle={{ id: 'no-id', defaultMessage: 'Module & Name' }}
        >
          {renderDefaultWizardSteps(modules, () => {})}
        </WizardPage>
      </MultiPageWizard>
    );
  }
}
