export const usersRoutes = {
  basePath: '/app/admin/users',

  userList: {
    path: 'list',
  },

  groups: {
    path: 'groups',
  },

  generalPermissions: {
    path: 'generalPermissions',
  },

  appPermissions: {
    path: 'appPermissions',
  },

  habitats: {
    path: 'habitats',
  },

  cassandraPermissions: {
    path: 'cassandraPermissions',
  },

  s3Permissions: {
    path: 's3Permissions',
  },

  adminUserDetails: {
    path: 'user',
  },
};
