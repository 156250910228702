import React, { FC } from 'react';
import styles from './styles.module.scss';

import { scaleLinear } from 'd3-scale';
import { Operator } from 'common/dist/types/module.optimization';
import classNames from 'classnames';

export const maxWidth = 100;
export const barHeight = 10;

type Props = {
  percentage: number;
  operator: Operator;
};

const ExhaustionBar: FC<Props> = ({ percentage, operator }) => {
  const xScaleColorBar = scaleLinear().domain([0, 1]).range([0, maxWidth]);
  const xScaleGreyBar = scaleLinear()
    .domain([0, percentage])
    .range([0, maxWidth]);

  //
  const colorBarWidth = xScaleColorBar(percentage > 1.0 ? 1.0 : percentage);
  // If the width of the color bar becomes smaller than the height, the height need to be adjusted otherwise
  // the color bar looks like a warped egg instead of a proper circle
  const adjustedColorBarHeight =
    colorBarWidth >= barHeight / 2 ? barHeight : 2 * colorBarWidth; // if the bar is narrower than barHeight/2 the height needs to be adjusted.
  const colorBarY =
    colorBarWidth >= barHeight / 2 ? 0 : barHeight / 2 - colorBarWidth;

  // percentage <= 1.0 means it's a leq / eq constraint -> Use the full width for the grey bar
  // percentage > 1.0 means it's a geq constraint -> Use the relative width of 100% in comparison to the actual
  //  exhaustion (example: 500% exhaustion means the grey bar takes a width of 0.2*maxWidth)
  const greyBarWidth = percentage <= 1.0 ? maxWidth : xScaleGreyBar(1.0);
  const greyBarHeight =
    percentage <= 1.0
      ? barHeight
      : greyBarWidth >= barHeight / 2
      ? barHeight
      : 2 * greyBarWidth;
  const greyBarY =
    percentage <= 1.0
      ? 0
      : greyBarWidth >= barHeight / 2
      ? 0
      : barHeight / 2 - greyBarWidth;

  const renderGreyBar = (className: string) => (
    <rect
      className={className}
      x={0}
      y={greyBarY}
      width={greyBarWidth}
      height={greyBarHeight}
      rx={barHeight / 2}
    />
  );

  const renderColorBar = (className: string) => (
    <rect
      className={className}
      x={0}
      y={colorBarY}
      width={colorBarWidth}
      height={adjustedColorBarHeight}
      rx={barHeight / 2}
    />
  );

  return (
    <svg
      className={classNames(styles.exhaustionBar, {
        [styles.leq]: operator === 'leq',
        [styles.geq]: operator === 'geq',
        [styles.eq]: operator === 'eq',
      })}
      width={maxWidth}
      height={barHeight}
    >
      {percentage <= 1.0 ? (
        <>
          {renderGreyBar(styles.backgroundBar)}
          {renderColorBar(styles.fillBar)}
          {renderGreyBar(styles.border)}
        </>
      ) : (
        <>
          {renderColorBar(styles.fillBar)}
          {renderGreyBar(styles.backgroundBar)}
          {renderColorBar(styles.border)}
        </>
      )}
    </svg>
  );
};

export default ExhaustionBar;
