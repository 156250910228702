import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { gitCommitForm } from './gitCommit.form';
import FieldCommitMessage from './FieldCommitMessage';
import {
  connectedProps,
  GitCommitModalProps,
} from './GitCommitModal.container';
import infoStyles from './../../info/styles.module.scss';
import classNames from 'classnames';
import repositoryMsgs from 'common/dist/messages/repository';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Button from '../../../../../atoms/button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface State {
  buttonClicked: boolean;
}
class GitCommitModal extends Component<
  connectedProps &
    GitCommitModalProps &
    WrappedComponentProps &
    InjectedFormProps<
      connectedProps & GitCommitModalProps & WrappedComponentProps
    >,
  State
> {
  static defaultProps = {
    isOpen: false,
    valid: false,
    pristine: true,
  };
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      buttonClicked: false,
    };
  }

  handleClick() {
    const {
      pristine,
      valid,
      commitMessage,
      gitAddAllAndCommit,
      infoFilePath,
      activeBranch,
    } = this.props;

    // --- Derive the repository path from the infoFilePath ( "abc/def/repository.rsk" -> "/abc/def")
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');
    // ---

    if (!pristine && valid && !this.state.buttonClicked) {
      this.setState({ buttonClicked: true });
      gitAddAllAndCommit(repositoryPath, commitMessage, activeBranch);
    }
  }

  render() {
    const { isOpen, hideGitCommitModal, valid, pristine, intl } = this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel={intl.formatMessage(repositoryMsgs.msgGitCommitModal)}
        onRequestClose={hideGitCommitModal}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer')
          /* Function that will be called to get the parent element
             that the modal will be attached to. */
        }
      >
        <div className={infoStyles.modalCommitParent}>
          <div
            title={intl.formatMessage(repositoryMsgs.msgGitCommitModalClose)}
            className={classNames('workbench-button', 'close-button')}
            onClick={hideGitCommitModal}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <div className={infoStyles.modalCommitElements}>
            <Field
              name={gitCommitForm.fieldCommitMessage}
              label={intl.formatMessage(repositoryMsgs.msgGitCommitMessage)}
              placeholder={'Commit Message'}
              component={FieldCommitMessage}
            />
          </div>

          <div className={infoStyles.modalCommitButtons}>
            <Button
              buttonColor={'primary'}
              withLink={false}
              buttonLabelDefault={'Add & Commit'}
              disabled={pristine || !valid || this.state.buttonClicked}
              onClick={this.handleClick}
            />
          </div>
        </div>
      </ReactModal>
    );
  }
}
//@ts-ignore
export default reduxForm(gitCommitForm)(injectIntl(GitCommitModal));
