import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Icon from './Icon';
import {
  getAugurDetailsLinkForShort,
  getCodeCapsulesDetailsLink,
  getDatapoolDetailsLinkForShort,
} from '../../index/routes';

/**
 * Returns the full link that is supposed to be opened when an item of the SideNav is clicked.
 * This function is able to create the link for augurs, datapools and code capsules menus
 * @param params
 * @param tabId
 * @param isDatapool
 * @param isCodeCapsule
 * @returns {string}
 */
function getLinkPath(params, tabId, isDatapool, isCodeCapsule) {
  if (isDatapool) {
    const { habitatCode, datapoolCode, moduleType: moduleTypeShort } = params;
    return getDatapoolDetailsLinkForShort({
      tabId,
      habitatCode,
      datapoolCode,
      moduleTypeShort,
    });
  } else if (isCodeCapsule) {
    const { habitatCode, codeCapsuleCode } = params;
    return getCodeCapsulesDetailsLink({ tabId, habitatCode, codeCapsuleCode });
  } else {
    // It's an augur
    const { habitatCode, augurCode, augurType: moduleTypeShort } = params;
    return getAugurDetailsLinkForShort({
      tabId,
      habitatCode,
      augurCode,
      moduleTypeShort,
    });
  }
}

export function mapStateToProps(
  state,
  { id: tabId, isDatapool, isCodeCapsule, match }
) {
  const isActive = state.router.location.pathname.indexOf(tabId) !== -1;
  const linkPath = getLinkPath(match.params, tabId, isDatapool, isCodeCapsule);

  return {
    linkPath,
    isActive,
  };
}

export default withRouter(connect(mapStateToProps)(Icon));
