import { connect } from 'react-redux';
import StartingAppInfo from './StartingAppInfo';
import { notebookUser } from '../../../redux/workbench/selectors/notebookUser.selector';
import {
  checkWhetherAppIsRunning,
  startApp,
} from '../../../redux/workbench/modules/app.connection.module';
import { fetchAppPermissions } from '../../../redux/modules/apps.module';

export function mapStateToProps(state) {
  return {
    notebookUser: notebookUser(state),
    loading: state.workbench.app.appStarting.loading,
    loaded: state.workbench.app.appStarting.loaded,
    error: state.workbench.app.appStarting.error,
    eventSourceAvailable: state.workbench.app.appStarting.eventSourceAvailable,
    eventSourceMessages: state.workbench.app.appStarting.eventSourceMessages,
  };
}

export const mapDispatchToProps = {
  startApp,
  checkWhetherAppIsRunning,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartingAppInfo);
