import { validateChannels } from '../../../../../organisms/modelManagement/newDatapoolWizard/optChannels/validate';
import { ChannelsValueType } from '../../../../../organisms/modelManagement/newDatapoolWizard/optChannels/OptChannels';

export const formName = 'channels';

export const fieldChannels = 'channels';

export type FormValues = {
  [fieldChannels]: ChannelsValueType;
};

const validate = (values: FormValues) => {
  const errors = {};

  errors[fieldChannels] = validateChannels(values[fieldChannels]);

  return errors;
};

export const channelsForm = {
  form: formName,
  enableReinitialize: true,
  validate,
  touchOnChange: true,
};
