import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { availableRepositoryTypes } from '../repository.form';
import RepositoryTypeCard from './RepositoryTypeCard';

export default class RepositoryStepType extends Component {
  errorMessage() {
    const { repositoryTypeTouched, repositoryTypeError } = this.props;
    if (repositoryTypeTouched && repositoryTypeError) {
      return <p className={'error'}>{repositoryTypeError}</p>;
    }
  }

  render() {
    const {
      repositoryTypeError,
      repositoryTypeTouched,
      input: { value, onChange, onBlur },
      disabled,
    } = this.props;
    return (
      <div className={'step-container step-type-container'}>
        <div
          className={`input-container${
            repositoryTypeTouched && repositoryTypeError
              ? ' input-container-error'
              : ''
          }`}
        >
          <div className={'error-container'}>{this.errorMessage()}</div>
          <div className={'name-input'}>
            <div className={'repository-types'}>
              {availableRepositoryTypes.map((element) => (
                <RepositoryTypeCard
                  name={element.name}
                  displayName={element.displayName}
                  key={element.name}
                  onChange={(name) => {
                    onChange(name);
                    onBlur(name); // To touch the redux-form Field
                  }}
                  selectedRepositoryType={value}
                  disabled={disabled}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
RepositoryStepType.propTypes = {
  repositoryType: PropTypes.string,
  repositoryTypeTouched: PropTypes.bool,
  repositoryTypeError: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
  disabled: PropTypes.bool,
};
