import { ContentElement, ContentElementType } from './GenericFileBrowser';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { metaFilename } from '../../../workbench/part-right/config';
import { JupyterContentType } from '../../../../store/workbench/state.types';

export function getFileType(
  type: JupyterContentType,
  name: string
): ContentElementType {
  switch (type) {
    case 'notebook':
      return ContentElementType.FILE_NOTEBOOK;
    case 'directory':
      return name.endsWith('.asr')
        ? ContentElementType.DIRECTORY_REPOSITORY
        : ContentElementType.DIRECTORY_PLAIN;
    case 'file':
      if (name.endsWith('.py')) return ContentElementType.FILE_CODE;
      if (name.endsWith('.asapp')) return ContentElementType.FILE_NOTEBOOK;
      else if (
        name.endsWith('.txt') ||
        name.endsWith('.csv') ||
        name.endsWith('.json') ||
        name.startsWith('.') ||
        name === 'Dockerfile'
      )
        return ContentElementType.FILE_TEXT;
      else if (name.endsWith('.md') || name.endsWith('.MD'))
        return ContentElementType.FILE_MARKDOWN;
      else if (name === metaFilename)
        return ContentElementType.FILE_REPOSITORY_INFO;
      return ContentElementType.FILE_DEFAULT;
  }
}

/**
 * Replace the general jupyter type with a more specific type for each content element.
 * @param content
 */
export function computeFileTypes(
  content: (Omit<ContentElement, 'type'> & { type: JupyterContentType })[]
): ContentElement[] {
  return content.map((c) => ({
    ...c,
    type: getFileType(c.type, c.name),
  }));
}

/**
 * Add the file types to each content element and if in a subdirectory add a content element representing the parent directory.
 * @param content
 * @param selectedDirPath
 */
export function prepareContent(
  content: ToBeRefined,
  selectedDirPath: string[]
): ContentElement[] {
  const selectedContent = computeFileTypes(
    selectedDirPath
      .slice(1)
      // Reduce climbs down the content.root.content tree and returns the final content or empty list by following the dirs in selectedDirPath
      .reduce(
        (acc: ContentElement[], curr) =>
          acc.find((e) => e.name === curr)?.content || [],
        content.root.content
      )
  );
  if (selectedDirPath.length > 1) {
    selectedContent.unshift({
      name: '..',
      path: '',
      type: ContentElementType.SPECIAL_ONE_DIRECTORY_UP,
    });
  }
  return selectedContent;
}
