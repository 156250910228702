import AugurSettingsElement from '../../../../../_interfaces/augur-details/settings/AugurSettingsElement';
import ArchetypeNameAndCodeContainer from './ArchetypeNameAndCode.container';

/**
 * Shows the augur name & code in the augur settings tab
 */
export default class ArchetypeNameAndCode implements AugurSettingsElement {
  component = ArchetypeNameAndCodeContainer;
  title = 'dashboard.augur_settings.archetype_title';
  description = 'dashboard.augur_settings.archetype_description';
}
