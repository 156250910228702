import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import archiveMsgs from 'common/dist/messages/augurs.modelArchive';
import styles from './styles.module.scss';
import ModelsTable from './ModelsTable';
import { useModelHistory } from '../../../../../../core/api/mlModels';
import { AugurDetailsRouteParams } from '../types';
import { useParams } from 'react-router-dom';
import { ModelHistoryEntry } from 'common/dist/types/mlModel';

interface Props {}

const renderError = (error) => {
  return (
    <div className={styles.modelArchive}>
      <FormattedMessage
        id='augur.error'
        defaultMessage='Error: {error}'
        values={{ error }}
      />
    </div>
  );
};

const renderLoaded = (
  modelHistEntries: ModelHistoryEntry[],
  habitatCode,
  augurCode
) => {
  return (
    <div className={styles.modelArchive}>
      <div className={styles.headlineContainer}>
        <FormattedMessage {...archiveMsgs.msgHeadline}>
          {(txt) => <span className={styles.headline}>{txt}</span>}
        </FormattedMessage>
      </div>

      <div className={styles.body}>
        <ModelsTable
          modelHistEntries={modelHistEntries}
          habitatCode={habitatCode}
          augurCode={augurCode}
        />
      </div>
    </div>
  );
};

const ModelArchive: FC<Props> = (props) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data, error } = useModelHistory(
    habitatCode,
    augurCode,
    undefined,
    0,
    1000 // FIXME: should be solved with paging
  );
  const modelHistEntries = data ?? [];

  if (error) return renderError(error);
  else return renderLoaded(modelHistEntries, habitatCode, augurCode);
};

export default ModelArchive;
