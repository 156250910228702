import { connect } from 'react-redux';
import DeleteContent from './DeleteContent';
import { deleteContent } from '../../../../../redux/workbench/modules/container-interactions.module';
import { notebookUser } from '../../../../../redux/workbench/selectors/notebookUser.selector';

export function mapStateToProps(state, ownProps) {
  return {
    selectedDirPath: state.workbench.content.selectedDirPath,
    jupyterUser: notebookUser(state),
  };
}

export const mapDispatchToProps = {
  deleteContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteContent);
