import React, { FC } from 'react';
import { K8sEvent } from 'common/dist/types/orchestration/k8sEvent';
import Table from '../../molecules/table/Table';
import { FormattedRelativeTimeConvenient } from '../../atoms/formatted-date-time/FormattedDateTime';
import { FormattedMessage } from 'react-intl';
import orchestrationMsgs from 'common/dist/messages/orchestration';

export type Props = {
  events: K8sEvent[];
};

const KubernetesEventsTable: FC<Props> = ({ events }) => {
  const transformedEvents = events.map((k8sEvent) => ({
    type: k8sEvent.event.type,
    reason: k8sEvent.event.reason,
    age: {
      lastTimestamp: k8sEvent.lastTimestamp,
      firstTimestamp: k8sEvent.firstTimestamp,
      count: k8sEvent.event.count,
    },
    message: k8sEvent.event.message,
  }));
  return (
    <div>
      <Table
        data={transformedEvents}
        renderColumns={[
          {
            key: 'type',
            renderHeader: () => (
              <FormattedMessage
                {...orchestrationMsgs.jobDetailsEventsHeaderType}
              />
            ),
            renderCell: (type: string) => <span>{type}</span>,
          },
          {
            key: 'reason',
            renderHeader: () => (
              <FormattedMessage
                {...orchestrationMsgs.jobDetailsEventsHeaderReason}
              />
            ),
            renderCell: (reason: string) => <span>{reason}</span>,
          },
          {
            key: 'age',
            renderHeader: () => (
              <FormattedMessage
                {...orchestrationMsgs.jobDetailsEventsHeaderAge}
              />
            ),
            renderCell: (age: typeof transformedEvents[number]['age']) => (
              <span>
                {/* The count correlates with whether the timestamps are set. The job schedule event for example
                 has no count and firstTimestamp and lastTimestamp are unset. There is some time information in there,
                 but let's not go into handling specific cases, since the whole events api seems in flux */}
                {age.count === 1 && (
                  <FormattedRelativeTimeConvenient
                    date={new Date(age.lastTimestamp)}
                  />
                )}
                {age.count > 1 && (
                  <FormattedMessage
                    {...orchestrationMsgs.jobDetailsEventsAge}
                    values={{
                      lastTimestamp: (
                        <FormattedRelativeTimeConvenient
                          date={new Date(age.lastTimestamp)}
                        />
                      ),
                      count: age.count,
                      firstTimestamp: (
                        <FormattedRelativeTimeConvenient
                          date={new Date(age.firstTimestamp)}
                        />
                      ),
                    }}
                  />
                )}
              </span>
            ),
          },
          {
            key: 'message',
            renderHeader: () => (
              <FormattedMessage
                {...orchestrationMsgs.jobDetailsEventsHeaderMessage}
              />
            ),
            renderCell: (message: string) => <span>{message}</span>,
          },
        ]}
        showHeader={true}
        striped={true}
      />
    </div>
  );
};

export default KubernetesEventsTable;
