import React, { FC } from 'react';
import styles from './styles.module.scss';
import commonStyles from '../optChannels/optCommonStyles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import classNames from 'classnames';
import { CommunicationGroupsValueType } from './OptCommunicationGroups';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import DescriptionOption, {
  DescriptionOptionType,
} from '../commonComponents/descriptionOption/DescriptionOption';
import {
  Communication,
  CommunicationGroup,
} from 'common/dist/types/module.optimization';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import { FiMinus } from 'react-icons/fi';
type Props = {
  /** List of communications defined in a previous wizard step */
  communications: Communication[];
  rowIndex?: number;
  constraintId: string;
  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: CommunicationGroup) => void;
};
const drvNewValue = (
  input: WrappedFieldInputProps,
  eventValue: string,
  key: string,
  rowIndex: number
) => {
  return [
    ...input.value.slice(0, rowIndex),
    {
      ...(input.value?.[rowIndex] || {}),
      [key]: eventValue,
    },
    ...input.value.slice(rowIndex + 1),
  ];
};
const renderNameField = (props) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optCommunicationGroups_name'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.name}
      valid={!meta.error?.[constraintId]?.name}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Name'}
      placeholderId={'no-id'}
      placeholderDefault={'Name'}
      value={input.value?.[rowIndex]?.name}
      onChange={(e) => {
        const newValue = drvNewValue(input, e.target.value, 'name', rowIndex);
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderDescriptionField = (props) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optCommunicationGroups_description'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.description}
      valid={!meta.error?.[constraintId]?.description}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Description (Optional)'}
      placeholderId={'no-id'}
      placeholderDefault={'Description'}
      value={input.value?.[rowIndex]?.description}
      onChange={(e) => {
        const newValue = drvNewValue(
          input,
          e.target.value,
          'description',
          rowIndex
        );
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderCommunicationsField = (props) => {
  const { input, meta, communications, rowIndex, constraintId } = props;

  const communicationsOptions: DescriptionOptionType[] = (
    communications || []
  ).map((channel) => ({
    value: channel.id,
    label: channel.name,
    description: channel.description,
  }));

  return (
    <DropdownSelectInput
      id={'optCommunicationGroups_communications'}
      label={{
        id: 'no-id',
        defaultMessage: 'Communications',
      }}
      placeholder={{
        id: 'no-id',
        defaultMessage: 'Communications',
      }}
      options={communicationsOptions}
      onFocus={input.onFocus}
      onBlur={() => input.onBlur(input.value)}
      onChange={(options: DescriptionOptionType[]) => {
        const newValue: CommunicationGroupsValueType = [
          ...input.value.slice(0, rowIndex),
          {
            ...(input.value?.[rowIndex] || {}),
            communicationIds: options.map((o) => o.value),
          },
          ...input.value.slice(rowIndex + 1),
        ];
        input.onChange(newValue);
      }}
      value={communicationsOptions.filter((o) =>
        (input.value?.[rowIndex]?.communicationIds || []).includes(o.value)
      )}
      error={meta.error?.[constraintId]?.communicationIds}
      touched={meta.touched}
      valid={!meta.error?.[constraintId]?.communicationIds}
      customComponents={{
        Option: DescriptionOption,
      }}
      multi
      appearance={'multiLines'}
    />
  );
};

const AddCommunicationGroup: FC<Props & WrappedFieldProps> = (props) => {
  const { input, meta, rowIndex, removableRows, onRemoveRow } = props;

  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={commonStyles.RemoveIcon}
            onClick={() => onRemoveRow && onRemoveRow(input.value[rowIndex])}
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div
          className={classNames(
            commonStyles.addElementField,
            styles.communicationsField
          )}
        >
          {renderCommunicationsField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderNameField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderDescriptionField(props)}
        </div>
      </div>
    </div>
  );
};

export default AddCommunicationGroup;
