import React, { Component } from 'react';
import InnerOutput from './InnerOutput';
import { mergeOutputs } from '../../../../redux/workbench/modules/notebook.websocket.module';
import { Output } from 'common/dist/utils/workbench/jupyterNotebookFormat';

// Checks whether there is a message with type 'error' in the given outputs list
const hasError = (outputs) => outputs.some((o) => o.output_type === 'error');

type Props = {
  outputs: Output[];
  path: string;
  cellId: string;

  /** Simply render the output and don't care about scrolling and stuff. Needed for the apps.*/
  plainOutput?: boolean;
};

export default class CodeCellOutput extends Component<Props> {
  /**
   * Merge adjacent "stream" CellOutputs. Needed for CodeCapsule outputs, where the outputs have not yet been merged
   * @param outputs
   */
  reduceOutputs(outputs: Output[]): Output[] {
    if (outputs.length < 2) {
      return outputs;
    }
    return outputs.reduce(mergeOutputs, []);
  }

  render() {
    const { outputs, cellId } = this.props;
    return (
      <div
        className={`output-container-parent${
          hasError(outputs) ? ' has-error' : ''
        }`}
      >
        {this.reduceOutputs(outputs).map((output, index) => {
          return (
            <InnerOutput key={`output_${cellId}_${index}`} output={output} />
          );
        })}
      </div>
    );
  }
}
