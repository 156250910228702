import React, { FC } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type ValueType = 'rejected' | 'forced' | 'auto';

export type Props = {
  value: ValueType;
  onChange: (value: ValueType) => void;
};

const RejectAutoForceToggle: FC<Props> = (props: Props) => {
  const { value, onChange } = props;
  return (
    <div
      className={classNames(
        styles.rejectAutoForceToggle,
        { [styles.rejected]: value === 'rejected' },
        { [styles.auto]: value === 'auto' },
        { [styles.forced]: value === 'forced' }
      )}
    >
      <div
        className={classNames(styles.rejectAutoForceElement)}
        onClick={() => onChange('rejected')}
      >
        <div
          className={classNames(styles.activeBubble, {
            [styles.isActive]: value === 'rejected',
          })}
        />
      </div>

      <div
        className={classNames(styles.rejectAutoForceElement)}
        onClick={() => onChange('auto')}
      >
        <div
          className={classNames(styles.activeBubble, {
            [styles.isActive]: value === 'auto',
          })}
        />
      </div>

      <div
        className={classNames(styles.rejectAutoForceElement)}
        onClick={() => onChange('forced')}
      >
        <div
          className={classNames(styles.activeBubble, {
            [styles.isActive]: value === 'forced',
          })}
        />
      </div>
    </div>
  );
};

export default RejectAutoForceToggle;
