import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../store/store';
import AugurBiography from '../modelManagement/modules/common/augur-details-tabs/augur-biography/AugurBiography';
import { fetchAugurBiographySidebar } from '../../store/augurBiographySidebar/slice';
import { fetchDataSources } from '../../redux/modules/data.dataSources.module';

export function mapStateToProps(state: RootState) {
  const { loading, loaded, error, data } = state.augurBiographySidebar || {};

  return {
    loading,
    loaded,
    error,
    data,
  };
}

export const mapDispatchToProps = {
  fetchAugurBiography: fetchAugurBiographySidebar,
  fetchDataSources: fetchDataSources as () => void,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(AugurBiography);
