import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Wizard from '../../../pages/wizard/Wizard';
import { usersRoutes } from '../routes';
import TextInputLine from '../../../atoms/input-elements/text-input-line/TextInputLine';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import { form } from './addGroup.form';

const StepName = (props) => {
  const {
    input: { onChange, onBlur, value },
  } = props;
  return (
    <TextInputLine
      hasLabel
      id={'groupName'}
      labelDefault={'Group Name'}
      placeholderDefault={'Enter Group Name'}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  );
};

export default class AdminAddGroup extends Component {
  render() {
    const { filledCompletely, adminAddGroup, values } = this.props;

    const buttons = [
      {
        withLink: true,
        linkTo: `${usersRoutes.basePath}/${usersRoutes.groups.path}`,
        buttonColor: 'white',
        buttonLabelId: 'todo',
        buttonLabelDefault: 'Cancel',
      },
      {
        withLink: true,
        buttonColor: 'secondary',
        buttonLabelId: 'todo',
        buttonLabelDefault: 'Finish',
        disabled: !filledCompletely,
        linkTo: `${usersRoutes.basePath}/${usersRoutes.groups.path}`,
        onClick: () => {
          if (filledCompletely) {
            adminAddGroup(values);
          }
        },
      },
    ];

    return (
      <Wizard
        additionalClassname={'AddGroupWizard'}
        withHeadline={true}
        headlineId={'admin.headline.add_group'}
        headlineDefault={'Add Group'}
        buttons={buttons}
      >
        <GenericFormStep
          formName={form.form}
          fieldName={form.fieldName}
          title={{
            defaultMessage: 'Group Name',
          }}
          description={{
            defaultMessage: 'Enter the name for your Group',
          }}
          num={1}
          component={StepName}
        />
      </Wizard>
    );
  }
}

AdminAddGroup.propTypes = {
  values: PropTypes.object,
  adminAddGroup: PropTypes.func.isRequired,
};
