import React from 'react';
import ModelViewer from './ModelViewer';

import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import { MessageDescriptor } from 'react-intl';

export default class ModelViewerWrapper extends ComponentAugurDetailsTab {
  component = ModelViewer;
  icon = () => <span className={'icon-model-viewer'} />;
  id = 'modelviewer';
  to = (habitatCode: string, augurCode: string, shortAugurType: string) =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/modelviewer`;
  subRoutes: object[] | undefined = undefined;
  title: MessageDescriptor = {
    id: 'details.sideNav.model_viewer',
    defaultMessage: 'Model Viewer',
  };
}
