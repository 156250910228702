import { apiRequest, CompletedApiRequest, postApiRequest } from './_tools';
import { useQuery, UseQueryResult } from 'react-query';
import { ToBeRefined } from 'common/dist/types/todo_type';

export function fetchCodeCapsules(affiliationType, habitatCode) {
  return apiRequest(
    `/api/codecapsules?affiliationType=${affiliationType}&habitatCode=${habitatCode}`
  );
}

export function fetchSingleCodeCapsule(codeCapsuleCode) {
  return apiRequest(`/api/codecapsules/${codeCapsuleCode}`);
}

export function fetchCodeCapsuleExecutions(codeCapsuleCode) {
  return apiRequest(`/api/codecapsules/${codeCapsuleCode}/executions`);
}

export function fetchCodeCapsuleReport(jobCode) {
  return apiRequest(`/api/codecapsules/report/${jobCode}`);
}

export function fetchCodeCapsuleOutput(jobCode, notebookName) {
  return apiRequest(
    `/api/codecapsules/output/${jobCode}/notebook/${notebookName}`
  );
}

export function triggerRun(
  repositoryName,
  repositoryCode,
  capsuleVersionNumber,
  capsuleImage,
  codeCapsuleCode,
  notebooksToExecute,
  resources,
  parameters,
  habitatCode,
  requestCredentialsFor,
  name,
  gpu,
  priority
) {
  const body = {
    repositoryCode,
    codeCapsuleCode,
    capsuleVersionNumber,
    notebooksToExecute,
    parameters,
    resources,
    capsuleImage,
    habitatCode,
    requestCredentialsFor,
    repositoryName, // Not read
    name,
    gpu: { model: gpu },
    priority,
  };

  return postApiRequest('/api/codecapsules/triggerrun', body);
}

export type NodeInfo = {
  nodes: {
    name: string;
    resources: {
      cpu: {
        capacity: number;
        allocatable: number;
      };
      memory: {
        capacity: number;
        allocatable: number;
      };
      gpu: {
        capacity: number;
        allocatable: number;
        driverVersion: string;
        runtimeVersion: string;
        computeVersion: string;
        family: string;
        memoryMb: number;
        model: string;
      };
    };
  }[];
};

export function fetchNodeInfo(): CompletedApiRequest<NodeInfo> {
  return apiRequest(`/orchestration/node-info`);
}

export function useNodeInfo(): UseQueryResult<NodeInfo, ToBeRefined> {
  return useQuery('node-info', async () => {
    const res = await fetchNodeInfo();
    if (!res.response) throw Error(`Unable to fetch node-info: ${res.error}.`);
    return res.response;
  });
}
