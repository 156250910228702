import { UseQueryResult, useQuery } from "react-query";
import { apiRequest } from "../_tools";
import {MetricsResponse} from 'common/dist/types/metrics';


export function useMetricsHook(
    notebookUser: string, isShown: boolean
): UseQueryResult<MetricsResponse> {
    return useQuery<unknown, unknown, MetricsResponse>(
        'workbench-metrics',
        () => queryFn(notebookUser),
        {
            refetchInterval: 10000,
            enabled: isShown,
        }
    );
}


async function queryFn(notebookUser) {
    const { response, error } = await apiRequest(`/jupyter/user/${notebookUser}/proxy/3040/metrics`);
    if (error) {
        throw Error('Unable to fetch' + error);
    }
    return response;
};
