import { ContentElementType } from '../generic-file-browser/GenericFileBrowser';
import {
  oneDirectoryDown,
  oneDirectoryUp,
} from '../../../../redux/workbench/modules/content.module';
// import { EmptyRecycleBin } from '../button-bar/ButtonBar.defaults';

export const buttonBarButtons = [];

export const onClickListeners = {
  [ContentElementType.FILE_CODE]: undefined,
  [ContentElementType.FILE_DEFAULT]: undefined,
  [ContentElementType.FILE_MARKDOWN]: undefined,
  [ContentElementType.FILE_NOTEBOOK]: undefined,
  [ContentElementType.FILE_REPOSITORY_INFO]: undefined,
  [ContentElementType.FILE_TEXT]: undefined,
  [ContentElementType.DIRECTORY_PLAIN]: (
    state,
    dispatch,
    path,
    name,
    type,
    history,
    element
  ) => dispatch(oneDirectoryDown(element.name)),
  [ContentElementType.DIRECTORY_REPOSITORY]: (
    state,
    dispatch,
    path,
    name,
    type,
    history,
    element
  ) => dispatch(oneDirectoryDown(element.name)),
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: (
    state,
    dispatch,
    path,
    name,
    type,
    history,
    element
  ) => dispatch(oneDirectoryUp()),
  [ContentElementType.SPECIAL_LAUNCHER]: undefined,
};

export const contextMenuEntries = {
  [ContentElementType.FILE_CODE]: [],
  [ContentElementType.FILE_DEFAULT]: [],
  [ContentElementType.FILE_MARKDOWN]: [],
  [ContentElementType.FILE_NOTEBOOK]: [],
  [ContentElementType.FILE_REPOSITORY_INFO]: [],
  [ContentElementType.FILE_TEXT]: [],
  [ContentElementType.DIRECTORY_PLAIN]: [],
  [ContentElementType.DIRECTORY_REPOSITORY]: [],
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: [],
  [ContentElementType.SPECIAL_LAUNCHER]: [],
};
