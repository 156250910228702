import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputElementShape } from '../_interface/P3InputElementShape';
import { getValueFromSettingsField } from '../_interface/input-element.form';

export default class CheckBox extends Component {
  render() {
    const { path, cellId, updateDataOfInputElement, element, cell } =
      this.props;

    const valueExist =
      element.data &&
      (element.data.checked === true || element.data.checked === false);

    const defaultValueField = element.settings
      ? element.settings.defaultValue
      : undefined;
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      cell.as_variables
    );

    return (
      <div className={'inner-input-element check-box-element'}>
        <label className='checkbox-item'>
          <input
            className='mark-input'
            type='checkbox'
            checked={valueExist ? element.data.checked : defaultValue}
            onChange={(v) =>
              updateDataOfInputElement(path, cellId, element, {
                checked: v.target.checked,
              })
            }
          />
          <span className='mark' />
        </label>
      </div>
    );
  }
}

CheckBox.propTypes = {
  path: PropTypes.string.isRequired,
  cellId: PropTypes.string.isRequired,
  /** Function to update the user input */
  updateDataOfInputElement: PropTypes.func.isRequired,
  /** Input element (including the settings + the user input) */
  element: inputElementShape,
  cell: PropTypes.shape({}).isRequired,
};
