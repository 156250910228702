import { createAction } from 'redux-act';

export const showBarValue = createAction('show bar chart value', (value) => ({
  value,
}));

export const hideBarValue = createAction('hide bar chart value', (value) => ({
  value,
}));

export const reducer = {
  [showBarValue]: (state, payload) => ({
    ...state,
    chart: {
      ...state.chart,
      bar: {
        ...state.chart.bar,
        barValue: payload.value,
      },
    },
  }),
  [hideBarValue]: (state) => ({
    ...state,
    chart: {
      ...state.chart,
      bar: {
        ...state.chart.bar,
        barValue: '',
      },
    },
  }),
};
