import React, { FC, Fragment } from 'react';
import './styles.scss';
import { FiChevronLeft } from 'react-icons/fi';
import { LinkWithQuery } from '../link-with-query/LinkWithQuery';

export type Props = {
  /** Optional link to open when the button is clicked */
  linkTo?: string;
  /** Optional callback when the button is clicked */
  onClick?: () => void;
  /** Label for the back button */
  label: string;
};

const BackTo: FC<Props> = (props) => {
  const { linkTo, onClick, label } = props;

  const inner = (
    <div className={'BackTo'} onClick={() => onClick && onClick()}>
      <Fragment>
        <FiChevronLeft size={16} className={'BackTo--icon'} />
        <span
          data-testingIdentifier={'backButtonId'}
          className={'BackTo--label'}
        >
          {label}
        </span>
      </Fragment>
    </div>
  );

  if (linkTo) {
    return (
      <LinkWithQuery
        className={'BackTo'}
        to={linkTo}
        onClick={() => onClick && onClick()}
        style={{ textDecoration: 'none' }}
      >
        {inner}
      </LinkWithQuery>
    );
  }

  return (
    <div className={'BackTo'} onClick={() => onClick && onClick()}>
      {inner}
    </div>
  );
};

export default BackTo;
