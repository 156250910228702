import React, { Component, Fragment } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { Field, InjectedFormProps } from 'redux-form';
import { editableAttributes } from './Attributes.form';
import InputError from '../../../atoms/input-error/InputError';
import { validate } from './validate';
import { ToBeRefined } from 'common/dist/types/todo_type';
import DropdownSelectInput from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

export interface Props {
  /** AttributesComponent data read from state. */
  attributesData: { [attr: string]: string[] } | {};
  userId?: string;
  /** Toggle the state in the surrounding non edit mode component */
  toggleEdit: (isEditing: boolean) => void;

  // From mapStateToProps
  /** All form values for updating */
  formValues: { [field: string]: string };
  formError: { [field: string]: string };

  // From mapDispatchToProps
  /** Update a user, by sending data to be merged into existing data in the backend.
   *  Data contains e.g. {attributes: {memory_request: "1G"} **/
  updateUser: (userCode: string, data: ToBeRefined) => void;

  // From redux-form
  valid: boolean;
  anyTouched: boolean;
}

interface State {
  additionalAttributes: { [attr: string]: string[] } | {};
}

type OptionType = { label: string; value: string };

export default class AttributesEditMode extends Component<
  Props & InjectedFormProps<ToBeRefined, Props>,
  State
> {
  constructor(props) {
    super(props);
    this.state = { additionalAttributes: {} };
  }

  render() {
    const {
      attributesData,
      userId,
      toggleEdit,
      updateUser,
      formValues,
      valid,
      formError,
    } = this.props;

    // Remaining attributes to add (all available, filtered by read and already added)
    const remainingOptions: OptionType[] = Object.keys(editableAttributes)
      .filter(
        (key) =>
          ![
            ...Object.keys(this.state.additionalAttributes),
            ...Object.keys(attributesData),
          ].includes(key)
      )
      .map((key) => ({ value: key, label: key }));

    return (
      <div className={'attributes-container ct-list'}>
        <div className={'ct-row ct-headline'}>
          <div className={'ct-col ct-col-280px'}>
            <span>Attribute</span>
          </div>
          <div className={'ct-col ct-col-350px'} />
          <div className={'ct-col ct-col-200px'}>
            <span>Value</span>
          </div>
          <div
            data-testingIdentifier={'saveAttributes'}
            className={'icon-container'}
            onClick={() => {
              if (valid) {
                updateUser(userId, { attributes: { ...formValues } });
                toggleEdit(false);
              }
            }}
          >
            <FiCheck className={'action-icon inline-icon-ok'} size={16} />
          </div>
          <div
            className={'icon-container'}
            onClick={() => {
              toggleEdit(false);
            }}
          >
            <FiX className={'action-icon inline-icon-cancel'} size={16} />
          </div>
        </div>

        {Object.entries(formError).map(([key, value]) => (
          <Fragment>
            <InputError touched={true} error={`${key}: ${value}`} />
            <br />
          </Fragment>
        ))}

        {Object.entries(attributesData)
          .sort(([aKey], [bKey]) => (aKey > bKey ? 1 : -1))
          .concat(Object.entries(this.state.additionalAttributes))
          .map(([key, value]) => (
            <div key={key} className={'ct-row attributes-row'}>
              <div className={'ct-col ct-col-280px'}>
                <span>{key}</span>
              </div>
              <div className={'ct-col ct-col-350px'} />
              <div className={'ct-col ct-col-200px'}>
                <Field
                  name={key}
                  component='input'
                  type='text'
                  disabled={!Object.keys(editableAttributes).includes(key)}
                  validate={validate}
                />
              </div>
            </div>
          ))}

        {remainingOptions.length > 0 && (
          <div style={{ width: '280px' }}>
            <DropdownSelectInput
              id={'attribute-options'}
              name={'attribute-options'}
              options={remainingOptions}
              placeholder={{
                id: 'no-id',
                defaultMessage: 'Add a new attribute',
              }}
              value={null}
              onChange={(option: OptionType) => {
                this.setState({
                  additionalAttributes: Object.assign(
                    this.state.additionalAttributes,
                    { [option.value]: editableAttributes[option.value] }
                  ),
                });
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            />
          </div>
        )}
      </div>
    );
  }
}
