import React, { Component } from 'react';
import styles from './styles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import classNames from 'classnames';
import { WithTableSampleProps } from '../commonComponents/withTableSample/WithTableSample';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import ColumnOption, {
  ColumnOptionType,
} from '../commonComponents/columnOption/ColumnOption';
import { MessageDescriptor } from 'react-intl';
import {
  categoricalTypes,
  numericalTypes,
} from 'common/dist/constants/dataManagement/dataTypes';

export type CustormerTableErrorType = {
  tableName?: MessageDescriptor;
  customerIdColumn?: MessageDescriptor;
  customerId2Column?: MessageDescriptor;
  valueIdColumn?: MessageDescriptor;
  valueColumn?: MessageDescriptor;
};

export type Props = {
  /** Selected datapoolCode */
  datapoolCode: string;
  /** code of the habitat to add this augur to */
  habitatCode: string;
  dropdownSelectPortal?: HTMLElement;
};

type OptionType = { label: string; value: string };

export default class OptCustomerTable extends Component<
  Props & WrappedFieldProps & WithTableSampleProps
> {
  getColumnOptions(
    disableByName: string[],
    validColTypes?: string[]
  ): ColumnOptionType[] {
    const { tables, tableSample } = this.props;

    const isLoading = tableSample?.loading;
    const columns = tableSample?.data?.colSpecs;

    if (isLoading || !tables || !columns) {
      return [];
    } else {
      return columns
        .sort((a, b) => (a.colName > b.colName ? 1 : -1))
        .map((column) => {
          const disableDueName = disableByName.includes(column.colName);
          const disableDueType =
            validColTypes &&
            !validColTypes
              .map((t) => t.toLowerCase())
              .includes(column.colType.toLowerCase());

          return {
            label: `${column.colName}`,
            value: column.colName,
            colName: column.colName,
            colType: column.colType,
            isDisabled: disableDueName || disableDueType,
          };
        });
    }
  }

  renderRowInputTable() {
    const {
      tablesLoading,
      tablesLoaded,
      tables,
      input: { value, onChange, onBlur, onFocus },
      meta: { error, touched },
    } = this.props;

    const tableOptions =
      tablesLoading || !tables
        ? []
        : tables.map((table) => ({
            label: table.name,
            value: table.name,
          }));

    return (
      <div className={styles.row}>
        <DropdownSelectInput
          id={'optCustomerTable.tableName'}
          name={'optCustomerTable.tableName'}
          touched={touched}
          error={error?.tableName}
          valid={!error?.tableName}
          disabled={!tablesLoaded}
          label={moduleOptimizationMessages.msgNewAugurCustomerTableLabel}
          placeholder={
            moduleOptimizationMessages.msgNewAugurCustomerTablePlaceholder
          }
          value={tableOptions.find((o) => o.value === value?.tableName)}
          onChange={(option: OptionType) =>
            onChange({
              ...value,
              tableName: option.value,
            })
          }
          onFocus={onFocus}
          onBlur={() => onBlur(value)}
          isLoading={tablesLoading}
          options={tableOptions}
        />
      </div>
    );
  }

  renderRowCustomerId() {
    const {
      tableSample,
      input: { value, onChange, onBlur, onFocus },
      meta: { error, touched },
    } = this.props;
    const columnOptionsId = this.getColumnOptions(
      [value?.valueIdColumn, value?.customerId2Column],
      categoricalTypes
    );

    const columnOptionsId2 = this.getColumnOptions(
      [value?.valueIdColumn, value?.customerIdColumn],
      categoricalTypes
    );

    const columnsLoading = tableSample?.loading;

    return (
      <div className={classNames(styles.row, styles.customerIdsSelect)}>
        <div className={styles.column}>
          <DropdownSelectInput
            id={'optCustomerTable.customerIdColumn'}
            name={'optCustomerTable.customerIdColumn'}
            touched={touched}
            error={error?.customerIdColumn}
            valid={!error?.customerIdColumn}
            disabled={!value?.tableName}
            label={
              moduleOptimizationMessages.msgNewAugurCustomerTableCustomerIdColumnLabel
            }
            placeholder={
              moduleOptimizationMessages.msgNewAugurCustomerTableCustomerIdColumnPlaceholder
            }
            value={columnOptionsId.find(
              (o) => o.value === value.customerIdColumn
            )}
            onChange={(option: OptionType) =>
              onChange({ ...value, customerIdColumn: option.value })
            }
            onFocus={onFocus}
            onBlur={() => onBlur(value)}
            isLoading={columnsLoading}
            options={columnOptionsId}
            customComponents={{
              Option: ColumnOption,
            }}
          />
        </div>
        <div className={styles.column}>
          <DropdownSelectInput
            id={'optCustomerTable.customerId2Column'}
            name={'optCustomerTable.customerId2Column'}
            touched={touched}
            error={error?.customerId2Column}
            valid={!error?.customerId2Column}
            disabled={!value?.tableName}
            label={
              moduleOptimizationMessages.msgNewAugurCustomerTableCustomerId2ColumnLabel
            }
            placeholder={
              moduleOptimizationMessages.msgNewAugurCustomerTableCustomerId2ColumnPlaceholder
            }
            value={columnOptionsId2.find(
              (o) => o.value === value.customerId2Column
            )}
            onChange={(option: OptionType) =>
              onChange({ ...value, customerId2Column: option?.value })
            }
            onFocus={onFocus}
            onBlur={() => onBlur(value)}
            isLoading={columnsLoading}
            options={columnOptionsId2}
            clearable={true}
            customComponents={{
              Option: ColumnOption,
            }}
          />
        </div>
      </div>
    );
  }

  renderRowValueSelect() {
    const {
      tableSample,
      input: { value, onChange, onBlur, onFocus },
      meta: { error, touched },
    } = this.props;

    const valueIdColumnOptions = this.getColumnOptions(
      [value?.customerIdColumn, value?.customerId2Column],
      categoricalTypes
    );
    const valueColumnOptions = this.getColumnOptions([], numericalTypes);
    const columnsLoading = tableSample?.loading;

    return (
      <div className={classNames(styles.row, styles.rowValueSelect)}>
        <div className={styles.column}>
          <DropdownSelectInput
            id={'optCustomerTable.valueIdColumn'}
            name={'optCustomerTable.valueIdColumn'}
            touched={touched}
            error={error?.valueIdColumn}
            valid={!error?.valueIdColumn}
            disabled={!value?.tableName}
            label={
              moduleOptimizationMessages.msgNewAugurCustomerTableValueIdColumnLabel
            }
            placeholder={
              moduleOptimizationMessages.msgNewAugurValueTableValueIdColumnPlaceholder
            }
            value={valueIdColumnOptions.find(
              (o) => o.value === value.valueIdColumn
            )}
            onChange={(option: OptionType) =>
              onChange({ ...value, valueIdColumn: option.value })
            }
            onFocus={onFocus}
            onBlur={() => onBlur(value)}
            isLoading={columnsLoading}
            options={valueIdColumnOptions}
            customComponents={{
              Option: ColumnOption,
            }}
          />
        </div>

        <div className={styles.column}>
          <DropdownSelectInput
            id={'optCustomerTable.valueColumn'}
            name={'optCustomerTable.valueColumn'}
            touched={touched}
            error={error?.valueColumn}
            valid={!error?.valueColumn}
            disabled={!value?.tableName}
            label={
              moduleOptimizationMessages.msgNewAugurCustomerTableValueColumnLabel
            }
            placeholder={
              moduleOptimizationMessages.msgNewAugurValueTableValueColumnPlaceholder
            }
            value={valueColumnOptions.find(
              (o) => o.value === value.valueColumn
            )}
            onChange={(option: OptionType) =>
              onChange({ ...value, valueColumn: option.value })
            }
            onFocus={onFocus}
            onBlur={() => onBlur(value)}
            isLoading={columnsLoading}
            options={valueColumnOptions}
            customComponents={{
              Option: ColumnOption,
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.optCustomerTable}>
        {this.renderRowInputTable()}
        {this.renderRowCustomerId()}
        {this.renderRowValueSelect()}
      </div>
    );
  }
}
