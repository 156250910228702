import { connect } from 'react-redux';
import LatestCommit from './LatestCommit';
import { fetchLatestCommit } from '../../../../redux/workbench/modules/collab.repository.module';

export function mapStateToProps(state) {
  const { repoName, repoFullName, activeBranch } =
    state.workbench.collab.repository;
  const { loading, loaded, data, error } =
    state.workbench.collab.repository.latestCommit;

  return {
    repoName,
    repoFullName,
    activeBranch,
    loading,
    loaded,
    data,
    error,
  };
}

export const mapDispatchToProps = {
  fetchLatestCommit,
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestCommit);
