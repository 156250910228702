export const loaded = (state) => state.dashboard.loaded;

export const loading = (state) => state.dashboard.loading;

export const initialized = (state) => state.dashboard.initialized;

export const isListView = (state, habitatCode) =>
  state.dashboard.habitats[habitatCode].isListView;

export const habitatsCount = (state) => Object.keys(state.summary).length;
