import { connect, ConnectedProps } from 'react-redux';
import RunningWorkbench from './RunningWorkbench';
import {
  fetchRunningWorkbench,
  stopWorkbench,
} from '../../../../store/admin/resources/actions';
import { fetchUserSummaryById } from '../../../../store/users/slice';
import { RootState } from '../../../../store/store';

export function mapStateToProps(state: RootState) {
  const { loading, loaded, data, error, stoppingServers } =
    state.adminResources.runningWorkbench || {};
  return {
    loading,
    loaded,
    /** Response from the call against the JupyterHubs .../users endpoint */
    data,
    error,
    /** List of servers that are currently stopped (userCodes define the servers in this case) */
    stoppingServers,
    /** Users to match the user code with names **/
    users: state.users,
  };
}

export const mapDispatchToProps: {
  fetchRunningWorkbench: () => void;
  fetchUserSummaryById: ({ userId: string }) => void;

  /** Stop the Workbench (the Jupyter Notebook) for a given user */
  stopWorkbench: (userCodeLower: string) => void;
} = {
  fetchRunningWorkbench,
  fetchUserSummaryById,
  stopWorkbench,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(RunningWorkbench);
