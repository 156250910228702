import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { dataManagementRoutes } from '../../routes';
import { FiChevronLeft } from 'react-icons/fi';
import Busy from '../../../atoms/busy/Busy';
import CassandraTablePreview from '../../../organisms/cassandra-table-preview/CassandraTablePreview';
import { RouteComponentProps } from 'react-router';
import styles from './styles.module.scss';
import HttpStatusCodes from 'common/dist/constants/httpStatusCodes';
import { FormattedMessage } from 'react-intl';
import datamanMessage from 'common/dist/messages/dataManagement';
import InputError from '../../../atoms/input-error/InputError';

export interface Props extends RouteComponentProps {
  dataSourceCode: string;
  bucket: string;
  path: string;
  fetchPreview: (dataSourceCode: string, path: string) => void;
  data: {
    colSpecs: { colName: string; colType: string }[];
    data: string[][];
  };
  loading: boolean;
  loaded: boolean;
  error: { status: string; message: string };
}

export default class TableBrowser extends Component<Props> {
  componentDidMount() {
    const { dataSourceCode, bucket, path, fetchPreview } = this.props;
    if (bucket && path) {
      fetchPreview(dataSourceCode, `${bucket}/${path}`);
    }
  }

  render() {
    const { dataSourceCode, bucket, path, data, loading, loaded, error } =
      this.props;

    if (error) {
      return (
        <div className={'cassandra-table-browser'}>
          <div className='TextInputArea--error'>
            <b>
              <InputError
                error={datamanMessage.msgErrorTablePreview.defaultMessage}
                touched={true}
              />
            </b>
            <br />
            <InputError
              // @ts-ignore
              error={error.message}
              touched={true}
            />
          </div>
        </div>
      );
    }

    if (loading) {
      return <Busy isBusy />;
    }

    return (
      <div>
        <div>
          <div className={styles.backButtonContainer}>
            <Link
              to={`${
                dataManagementRoutes.basePath
              }/s3/${dataSourceCode}/bucket/${bucket}/content/${path.substring(
                0,
                path.lastIndexOf('/')
              )}`}
              style={{ textDecoration: 'none' }}
            >
              <div className={styles.backButton}>
                <FiChevronLeft size={20} className={styles.backIcon} />
                <span className={styles.backText}>Back</span>
              </div>
            </Link>
          </div>
        </div>

        <span className={styles.tableBrowserHeadline}>
          TableBrowser for File <b>{path}</b> in Bucket <b>{bucket}</b>
        </span>

        {data && data.colSpecs && data.data && (
          <div className={styles.scrollableTableContainer}>
            <CassandraTablePreview
              colSpecs={data.colSpecs}
              data={data.data}
              editable={false}
            />
          </div>
        )}

        {loaded && (!data || data.data.length === 0) && (
          <span>The table is currently empty.</span>
        )}
      </div>
    );
  }
}
