import { getShortNameForType } from '../modelManagement/modules/ModuleManagerClient';

// --- TODO try to move everything into common/dist/constants/frontendRoutes
export const app = {
  prefix: '/app/',

  models: 'models',
  newHabitat: 'newHabitat',
  newDatapool: 'habitat/:habitatCode/new-datapool',
  newAugur: 'habitat/:habitatCode/new-augur',
  habitat: 'habitat',
  details: 'details',
  account: 'account',
  error: 'error',
  admin: {
    index: 'admin/',
    users: 'users',
    addUser: 'addUser',
    addGroup: 'addGroup',
    resources: 'resources',
    maintenance: 'maintenance',
    createBackup: 'createBackup',
    createTemplate: 'createTemplate',
    auditLog: 'auditlog',
  },
  codeCapsule: {
    prefix: 'habitat/:habitatCode/codecapsule/:codeCapsuleCode',
    versions: 'versions',
    executions: 'executions',
  },
};

export function getNewDatapoolLink(habitatCode) {
  return `${app.prefix}${app.models}/habitat/${habitatCode}/new-datapool`;
}

export function getNewAugurLink(habitatCode) {
  return `${app.prefix}${app.models}/habitat/${habitatCode}/new-augur`;
}

/**
 * Returns the augur details link for the short written moduleType (for example 'nbc')
 * @param habitatCode
 * @param augurCode
 * @param moduleTypeShort
 * @param tabId
 * @returns {string}
 */
export function getAugurDetailsLinkForShort({
  habitatCode,
  augurCode,
  moduleTypeShort,
  tabId,
}) {
  if (tabId) {
    return `/app/habitat/${habitatCode}/augur/${augurCode}/${moduleTypeShort}/${tabId}`;
  }

  return `/app/habitat/${habitatCode}/augur/${augurCode}/${moduleTypeShort}`;
}

/**
 * Returns the augur details link for the long written moduleType (for example 'binary-numerical')
 * @param habitatCode
 * @param augurCode
 * @param moduleType
 * @param tabId
 * @returns {string}
 */
export function getAugurDetailsLink({
  habitatCode,
  augurCode,
  moduleType,
  tabId,
}) {
  const detailsUrl = getShortNameForType(moduleType);

  if (tabId) {
    return `/app/habitat/${habitatCode}/augur/${augurCode}/${detailsUrl}/${tabId}`;
  }

  return `/app/habitat/${habitatCode}/augur/${augurCode}/${detailsUrl}`;
}

export function getAccuracyDetailLinkForShort(
  habitatCode,
  augurCode,
  moduleType,
  tabId
) {
  return `/app/habitat/${habitatCode}/augur/${augurCode}/${moduleType}/accuracy-details/${tabId}`;
}

/**
 * Returns the datapool details link for the short written moduleType (for example 'nbc')
 * @param habitatCode
 * @param augurCode
 * @param moduleTypeShort
 * @param tabId
 * @returns {string}
 */
export function getDatapoolDetailsLinkForShort({
  habitatCode,
  datapoolCode,
  moduleTypeShort,
  tabId,
}) {
  if (tabId) {
    return `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${moduleTypeShort}/${tabId}`;
  }

  return `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${moduleTypeShort}`;
}

/**
 * Returns the datapool details link for the long written moduleType (for example 'binary-numerical')
 * @param habitatCode
 * @param datapoolCode
 * @param moduleType
 * @param tabId
 * @returns {string}
 */
export function getDatapoolDetailsLink({
  habitatCode,
  datapoolCode,
  moduleType,
  tabId,
}: {
  habitatCode: string;
  datapoolCode: string;
  moduleType: string;
  tabId?: string;
}) {
  const detailsUrl = getShortNameForType(moduleType);

  if (tabId) {
    return `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${detailsUrl}/${tabId}`;
  }

  return `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${detailsUrl}`;
}

/**
 * Returns the code capsule details link
 * @param habitatCode
 * @param codeCapsuleCode
 * @param tabId
 * @returns {string}
 */
export function getCodeCapsulesDetailsLink({
  habitatCode,
  codeCapsuleCode,
  tabId,
}) {
  if (tabId) {
    return `/app/habitat/${habitatCode}/codecapsule/${codeCapsuleCode}/${tabId}`;
  }

  return `/app/habitat/${habitatCode}/codecapsule/${codeCapsuleCode}`;
}
