import React, { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { PathData } from '../form';

export default class StepPath extends Component<WrappedFieldProps> {
  render() {
    const {
      meta: { error, touched },
      input: { onChange, onBlur },
    } = this.props;
    const value: PathData = this.props.input.value;
    return (
      <div className={'step-container step-path-container'}>
        <div className={'input-container'}>
          <div className={'input'}>
            <div className={'input-parent'}>
              {touched && error && error.name ? (
                <div className={'error-container'}>
                  <span className={'error'}>{error.name + ''}</span>
                </div>
              ) : (
                <div className={'error-container'} /> // To keep the spacing
              )}
              <input
                type={'text'}
                value={value.name || ''}
                onChange={(e) => onChange({ ...value, name: e.target.value })}
                className={'form--field'}
                onBlur={() => onBlur(value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
