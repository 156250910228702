import { connect } from 'react-redux';
import DirectoryStep1 from './DirectoryStep1';
import { getFormInitialValues } from 'redux-form';
import {
  addDirectoryFormName,
  fieldDirectoryName,
} from '../AddDirectoryWizard.container';

function safeDirectoryName(state) {
  return state.form?.directoryWizard?.values?.directoryName || '';
}

function safeDirectoryTouched(state) {
  return state.form.directoryWizard?.fields?.directoryName?.touched;
}

function safeDirectoryError(state) {
  return state.form.directoryWizard?.syncErrors?.directoryName?.message;
}

function filesInCurrentDir(state) {
  const dirs = state.workbench.content.selectedDirPath;
  if (!dirs) return [];

  let content = state.workbench.content;
  dirs.forEach((dir, i) => {
    if (i === 0) {
      // Root
      content = content[dir].content;
    } else {
      if (!content) return [];
      content = content.find((c) => c.name === dir).content; // TODO this is not failsafe (what if .find() returns undefined?)
    }
  });
  return content;
}

export function mapStateToProps(state) {
  return {
    selectedDirPath: state.workbench.content.selectedDirPath,
    directoryName: safeDirectoryName(state),
    directoryNameTouched: safeDirectoryTouched(state),
    directoryNameError: safeDirectoryError(state),
    filesInCurrentDir: filesInCurrentDir(state),
    isEditing: state.workbench.showEditDirectory?.path !== undefined,
    initialName:
      getFormInitialValues(addDirectoryFormName)(state)?.[fieldDirectoryName],
  };
}

export default connect(mapStateToProps)(DirectoryStep1);
