import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RepositoryTypeIds } from '../../wizards/repository-add/repository.form';
import { withRouter } from 'react-router-dom';
import { collaborationSpaceRoutes } from '../../routes';
import Button from '../../../atoms/button/Button';

class RepositoryButtonBar extends Component {
  render() {
    const { loading, loaded, data, error } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    return this.renderLoaded();
  }

  renderLoading() {
    return <div className={'button-container'} />;
  }

  renderError() {
    const { error } = this.props;
    return <div className={'button-container'}>{JSON.stringify(error)}</div>;
  }

  renderLoaded() {
    const { data } = this.props;
    const { repoType } = data || {};
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    return (
      <div className={'button-container'}>
        {repoType === RepositoryTypeIds.CODE_CAPSULE && (
          <Button
            buttonColor={'primary'}
            withLink
            buttonLabelDefault={'Build Code Capsule'}
            linkTo={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}/${collaborationSpaceRoutes.repositories.buildCodeCapsule}`}
          />
        )}
        {repoType === RepositoryTypeIds.APP && (
          <Button
            buttonColor={'primary'}
            withLink
            buttonLabelDefault={'Build App'}
            linkTo={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}/${collaborationSpaceRoutes.repositories.buildApp}`}
          />
        )}
        {repoType === RepositoryTypeIds.ARCHETYPE && (
          <Button
            buttonColor={'primary'}
            withLink
            buttonLabelDefault={'Build Archetype'}
            linkTo={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}/${collaborationSpaceRoutes.repositories.buildArchetype}`}
          />
        )}

        <Button
          buttonColor={'secondary'}
          withLink
          buttonLabelDefault={'Clone Repository'}
          linkTo={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}/${collaborationSpaceRoutes.repositories.clone}`}
        />
      </div>
    );
  }
}
RepositoryButtonBar.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.shape({
    /** Name of the repository (to look it up at Gogs) */
    repoName: PropTypes.string.isRequired,
    /** Code of the repository */
    repoCode: PropTypes.string.isRequired,
    /** Full name of the repository (to look it up at Gogs) */
    repoFullName: PropTypes.string.isRequired,
    /** Type of the repository */
    repoType: PropTypes.string,
    /** Code of the Code Capsule - only set if repoType = 'code-capsule' */
    codeCapsuleCode: PropTypes.string,
    /** Code of the App - only set if repoType = 'app' */
    appCode: PropTypes.string,
    /** Code of the Archetype - only set if repoType = 'archetype' */
    archetypeCode: PropTypes.string,
  }),
  activeBranch: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      group: PropTypes.string,
      repositoryName: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withRouter(RepositoryButtonBar);
