import React, { FC, Fragment, useState } from 'react';
import { FiEdit2 } from 'react-icons/all';
import ReactModal from 'react-modal';
import ParameterTuningEditModeContainer from './ParameterTuningEditMode.container';
import {
  listToInputString,
  ParameterTuningSchema,
} from '../../../../../../../../organisms/parameter-tuning/parameter-tuning-selection/types';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { ArchetypeVersionWithArchetypeType } from 'common/dist/types/archetypeVersion';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../../../../types';
import { useAugur } from '../../../../../../../../../core/api/augurs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../../store/store';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    marginTop: '40px',
    maxHeight: 'calc(80vh - 40px)',
  },
};

/**
 * Extract the parameter tuning schema from the settings contained in the archetypeVersions data
 */
export function extractParameterTuningSchemaFromArchetypeVersions(
  archetypeVersions: ArchetypeVersionWithArchetypeType[],
  archetypeCode: string,
  archetypeVersionNumber: string
): ParameterTuningSchema | undefined {
  // Find the settings for the correct archetype and version in the data (if settings are missing in db they are null)
  const archetypeVersionSettings: {
    parameterTuning: ParameterTuningSchema;
  } | null = archetypeVersions[archetypeCode]?.versions.find(
    (version) => version.number === archetypeVersionNumber
  )?.settings;
  return archetypeVersionSettings?.parameterTuning;
}

const ParameterTuning: FC<ModelSettingsProps<AugurSettingsDataNbc>> = ({
  settings: { parameterTuning },
  saveSettings,
}) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const { data: augur } = useAugur(habitatCode, augurCode);

  const archetypeCode = augur?.archetypeVersion?.archetype?.code ?? '';
  const archetypeVersionNumber = augur?.archetypeVersion?.number ?? '';
  const archetypeIsCustom = augur?.archetypeVersion?.archetype?.custom ?? false;
  const archetypeVersions = useSelector<
    RootState,
    ArchetypeVersionWithArchetypeType[]
  >((state) => state.archetypeVersions);

  const parameterTuningSchema =
    extractParameterTuningSchemaFromArchetypeVersions(
      archetypeVersions,
      archetypeCode,
      archetypeIsCustom ? archetypeVersionNumber : 'latest'
    );

  const [editMode, setEditMode] = useState(false);

  return (
    <div className={'table-selection'}>
      {parameterTuning && (
        <ul>
          <li>
            <p className={'information-name'}>Search Strategy</p>
            <p className={'information-code'}>
              {parameterTuningSchema?.availableSearchStrategies.find(
                (s) => s.name === parameterTuning.searchStrategy.name
              )?.speakingName ?? parameterTuning.searchStrategy.name}
            </p>
          </li>

          {parameterTuning.algorithms.map(
            ({ name: algorithmName, parameters }) => {
              const algorithmSchema =
                parameterTuningSchema?.availableAlgorithms.find(
                  (a) => a.name === algorithmName
                );
              return (
                <Fragment>
                  <li key={algorithmName}>
                    <p className={'information-name'}>&nbsp;</p>
                    <h3 className={'information-code'}>
                      {algorithmSchema?.speakingName ?? algorithmName}
                    </h3>
                  </li>
                  {parameters.map(({ name: parameterName, values }) => (
                    <li key={`${algorithmName}-${parameterName}`}>
                      <p className={'information-name'}>
                        {algorithmSchema?.parameters.find(
                          (p) => p.name === parameterName
                        )?.speakingName ?? parameterName}
                      </p>
                      <p className={'information-code'}>
                        {listToInputString(values)}
                      </p>
                    </li>
                  ))}
                </Fragment>
              );
            }
          )}
        </ul>
      )}

      <div className={'icon-container'} onClick={() => setEditMode(true)}>
        <FiEdit2 className={'action-icon'} size={16} />
      </div>

      <ReactModal
        isOpen={editMode}
        contentLabel='Parameter Tuning'
        onRequestClose={() => setEditMode(false)}
        style={customStyles}
        appElement={document.getElementById('react-app')}
      >
        {parameterTuning && parameterTuningSchema && (
          //@ts-ignore
          <ParameterTuningEditModeContainer
            saveSettings={saveSettings}
            parameterTuningSchema={parameterTuningSchema}
            parameterTuningSettings={parameterTuning}
            hide={() => setEditMode(false)}
          />
        )}
      </ReactModal>
    </div>
  );
};

export default ParameterTuning;
