import React, { FC } from 'react';

import ConfirmPassword from './ConfirmPassword';
import NewPassword from './NewPassword';
import DescriptionPasswordRules from './DescriptionPasswordRules';
import { SettingsBlock } from '../../settings-block/SettingsBlock';
import {
  Field,
  FormErrors,
  getFormError,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import * as account from '../../../../core/forms/account.form';
import { accountPasswordForm } from '../../../../core/forms/account.form';
import './styles.scss';
import Button from '../../../atoms/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../../../redux/modules/account.module';

const ChangePassword: FC<InjectedFormProps<FormData>> = (props) => {
  const { handleSubmit, pristine, invalid, submitting } = props;
  const dispatch = useDispatch();
  const settingsElement = {
    title: {
      id: 'account.password.change_password.title',
      defaultMessage: 'Change Password',
    },
    DescriptionComponent: (
      <DescriptionPasswordRules
        error={useSelector(getFormError(accountPasswordForm))}
      />
    ),
    Component: (
      <form
        onSubmit={handleSubmit((values: FormData) => {
          // @ts-ignore
          dispatch(changePassword(values.newPassword));
        })}
      >
        <div className={'ChangePassword--Field--Parent'}>
          <Field name={account.newPasswordField} component={NewPassword} />
        </div>
        <div className={'ChangePassword--Field--Parent'}>
          <Field name={account.confirmationField} component={ConfirmPassword} />
        </div>
        <div className={'buttons'}>
          <Button
            buttonLabelDefault={'Cancel'}
            buttonColor={'white'}
            withLink={false}
            disabled={pristine || submitting}
            onClick={() => props.reset()}
          />
          <Button
            buttonLabelDefault={'Save'}
            buttonColor={'secondary'}
            withLink={false}
            isSubmitButton
            disabled={pristine || invalid || submitting}
            isBusy={submitting}
          />
        </div>
      </form>
    ),
  };

  return (
    <div className={'ChangePassword'}>
      <SettingsBlock
        color={'grey'}
        title={{
          id: 'account.password.title',
          defaultMessage: 'Password',
        }}
        settingsElements={[settingsElement]}
      />
    </div>
  );
};

type FormData = { newPassword: string; confirmation: string };
export const form = {
  form: account.accountPasswordForm,
  validate(values: FormData): FormErrors<FormData, string> {
    return {
      newPassword:
        values.newPassword && values.newPassword.length > 0
          ? undefined
          : 'Specify new password.',
      confirmation:
        values.confirmation === values.newPassword
          ? undefined
          : 'Password do not match.',
    };
  },
};

export default reduxForm<FormData>(form)(ChangePassword);
