import { actionTypes } from 'redux-form';

const ports = {
  ftp: 21,
  sftp: 22,
  s3: 443,
};

export function reducer(state, action) {
  switch (action.type) {
    case actionTypes.CHANGE:
      if (action.meta.form === 'newDataConnection1') {
        if (action.meta.field === 'protocol') {
          return {
            ...state,
            values: {
              ...state.values,
              port: ports[action.payload],
            },
          };
        } else if (action.meta.field === 'creationType') {
          return {
            ...state,
            values: {
              ...state.values,
              protocol: action.payload === 'manual' ? 'upload' : '',
            },
          };
        }
      }

      return state;
    default:
      return state;
  }
}
