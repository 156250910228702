import React, { Component, ReactElement } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';
import { ValueType } from '../../../../../organisms/cassandra-table-preview/CassandraTablePreview';
import { ColSpec } from '../../../../../../store/dataManagement/state.types';
import { FormData, fieldKeys } from '../form';

interface StepKeysProps {
  /** List of all columns */
  colSpecs?: ColSpec[];
  // The formfields from the data step as a read only
  overwriteCols?: ValueType;
  disabled: boolean;
}

export default class StepKeys extends Component<
  StepKeysProps & WrappedFieldProps
> {
  constructor(props: StepKeysProps & WrappedFieldProps) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  renderDroppable(
    name: string,
    colSpecs: ColSpec[],
    columnIds: string[]
  ): ReactElement {
    const { overwriteCols, disabled } = this.props;
    return (
      <Droppable droppableId={name}>
        {(provided) => (
          <div
            className={classNames('draggable-col-list', {
              'draggable-col-list-disabled': disabled,
            })}
            ref={provided.innerRef}
          >
            {columnIds.map((columnId, index) => (
              <Draggable
                key={columnId}
                draggableId={String(columnId)}
                index={index}
                isDragDisabled={disabled}
              >
                {(provided) => (
                  <div
                    className={'draggable-list-element'}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {overwriteCols?.[columnId]?.newName ??
                      colSpecs[columnId].colName}
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    );
  }

  onDragEnd(result): void {
    const {
      input: { value, onChange, onBlur },
    } = this.props;
    const { source, destination, draggableId: columnId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const { droppableId: sourceId } = source;
    const { droppableId: destinationId } = destination;
    if (sourceId !== destinationId) {
      // really moved to another list

      const partition = (value || {}).partition || [];
      const clustering = (value || {}).clustering || [];
      let newPartition = partition;
      let newClustering = clustering;

      // --- 1. Check whether the columnId needs to be removed from the partition / clustering columns
      if (sourceId === 'clustering') {
        newClustering = newClustering.filter((x) => x !== columnId);
      } else if (sourceId === 'partition') {
        newPartition = newPartition.filter((x) => x !== columnId);
      }

      // --- 2. Check whether the columnId needs to be added to partition / clustering @
      if (destinationId === 'clustering') {
        newClustering = [...newClustering, columnId];
      } else if (destinationId === 'partition') {
        newPartition = [...newPartition, columnId];
      }

      const newValue = { partition: newPartition, clustering: newClustering };
      onChange(newValue); // Set the new val^ue
      onBlur(newValue); // To make the "touched" attribute of redux-form work
    }
  }

  render() {
    const {
      meta: { valid, error, touched },
      input: { value, onChange, onBlur },
      colSpecs,
    } = this.props;

    // --- Derive the column lists
    const { clustering, partition }: FormData[typeof fieldKeys] = value;
    const regular = colSpecs
      .map((_, i) => String(i))
      .filter(
        (i) => !(clustering || []).includes(i) && !(partition || []).includes(i)
      );

    return (
      <div className={'GenericFormStep--field step-keys-container'}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className={'keys-input-parent'}>
            {/* --- Regular Columns --- */}
            <div className={'col-list-parent'}>
              <span className={'list-name'}>Regular Columns</span>
              {this.renderDroppable('regular', colSpecs, regular || [])}
            </div>

            {/* --- Partition Keys --- */}
            <div className={'col-list-parent'}>
              <span className={'list-name'}>Partition Keys</span>
              {this.renderDroppable('partition', colSpecs, partition || [])}
            </div>

            {/* --- Clustering Keys --- */}
            <div className={'col-list-parent'}>
              <span className={'list-name'}>Clustering Keys</span>
              {this.renderDroppable('clustering', colSpecs, clustering || [])}
            </div>
          </div>
        </DragDropContext>
      </div>
    );
  }
}
