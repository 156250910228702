import { connect } from 'react-redux';
import BuildCodeCapsuleWizard from './BuildCodeCapsuleWizard';
import { fieldTag, formName } from './form';
import { formValueSelector, isValid } from 'redux-form';
import { fetchRepoDetails } from '../../../../redux/workbench/modules/collab.repository.module';
import { triggerCodeCapsuleBuild } from '../../../../redux/workbench/modules/collab.module';
import { RepoDetails } from '../../../../store/workbench/state.types';
import { LoadableData } from '../../../../utils';
import { getTag } from '../common';

export function mapStateToProps(state) {
  const { loading, loaded, error, data }: LoadableData<RepoDetails, unknown> =
    state.workbench.collab.repoDetails || {};
  const codeCapsuleCode = data?.codeCapsule.code;
  const codeCapsule = state.codeCapsules.perCode[codeCapsuleCode];
  const versions = codeCapsule?.data?.versions;
  const initialFieldTag = getTag(versions);

  return {
    valid: isValid(formName)(state),
    tag: formValueSelector(formName)(state, fieldTag),
    loading,
    loaded,
    error,
    data,
    codeCapsule,

    initialValues: {
      [fieldTag]: initialFieldTag,
    },
  };
}

export const mapDispatchToProps = {
  triggerCodeCapsuleBuild,
  fetchRepoDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildCodeCapsuleWizard);
