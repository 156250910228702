import { VARIABLE_TYPES } from '../variableTypes';
import { BaseVariableHandler, getFirstRaw } from './helper';
import { handleJson } from './JsonVariableHandler';

const MAX_ROWS = 500;

export const PandasDataFrameVariableHandler: BaseVariableHandler<
  Record<string, unknown>[]
> = {
  type: VARIABLE_TYPES.PANDAS_DATA_FRAME,
  displayName: 'pandas.DataFrame',
  getExecutionCode: (inputValue: string) =>
    `${(
      inputValue || ''
    ).trim()}[0:${MAX_ROWS}].to_json(orient="records", force_ascii=False)`,
  parseValue: (variable, rawValues) => {
    const firstRaw = getFirstRaw(variable, rawValues);
    if (!firstRaw) return { parsedValue: undefined, error: undefined };

    return handleJson(variable, rawValues, firstRaw);
  },
};
