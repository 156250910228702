import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OverlappingMenuEntry from './OverlappingMenuEntry.container';

export default class OverlappingMenu extends Component {
  render() {
    return (
      <div className={'overlapping-menu'}>
        {this.props.overlappingTabs.map((e) => (
          <OverlappingMenuEntry key={e.path} path={e.path} name={e.name} />
        ))}
      </div>
    );
  }
}
OverlappingMenu.propTypes = {
  overlappingTabs: PropTypes.array.isRequired,
};
