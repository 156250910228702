import { ComponentType } from 'react';
import P3OutputElement from '../_interface/P3OutputElement';
import DownloadFile from './DownloadFile.container';
import { FiDownload } from 'react-icons/fi';
import DownloadFileSettings from './DownloadFileSettings';
import vars from '../../../../../../../../../../../../scss/base/var.module.scss';

export default class DownloadFileWrapper extends P3OutputElement {
  AdditionalSettings: ComponentType = DownloadFileSettings;
  ChildComponent = DownloadFile;
  hasSettings = true;
  icon: ComponentType = () => (
    <div
      style={{
        height: '24px',
        width: '24px',
        padding: '5px', // To center the inner icon
        borderRadius: '50%',
        backgroundColor: vars.colorPrimaryHighlight,
      }}
    >
      <FiDownload size={14} color={'#fff'} />
    </div>
  );
  iconColor = vars.colorPrimaryHighlight;
  name = 'Download File';
  parentContainerClass = 'download-file';
  type = 'download-file';

  // No settings - so also no need to validate them
  validateSettings = (values: object, variables: object[]): object => {
    return undefined;
  };
}
