import React, { CSSProperties, FC } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import './styles.scss';
import baseVariables from '../../../../scss/base/var.module.scss';

const colors = {
  white: baseVariables.colorWhite,
};

export interface FlapButtonProps {
  collapsed: boolean;
  /** Optional border with this color. Intended for overlapping elements */
  borderColor?: keyof typeof colors;
  /** Additional style that can be passed for special cases,
   *  for example passing a zIndex, since the button may overlap other parts */
  style?: CSSProperties;
}

/**
 * A button styled to work with a collapsable container, it is centered and overlapping the top of the nearest
 * component that is positioned (not the default static, but most probable position: relative)
 * @param props
 * @constructor
 */
export const FlapButton: FC<FlapButtonProps> = ({
  style,
  borderColor,
  collapsed,
}) => {
  const finalStyle: CSSProperties = {};
  if (style !== undefined) {
    Object.assign(finalStyle, style);
  }
  if (borderColor !== undefined) {
    Object.assign<CSSProperties, CSSProperties>(finalStyle, {
      border: `4px solid ${borderColor}`,
    });
  }
  return (
    <div className={'FlapButton'} style={finalStyle}>
      {collapsed ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
    </div>
  );
};
