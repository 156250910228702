import React, { FC, useMemo, useState } from 'react';
import Sizer from '../../../../../../../components/atoms/sizer/Sizer';
import Busy from '../../../../../../../components/atoms/busy/Busy';
import {
  ACCURACY_DETAILS_TYPE,
  AccuracyDetailsType,
} from 'common/dist/constants/accuracyCharts';
import _ from 'lodash';
import SelectableTimeline from '../../../../../../molecules/selectable-timeline/SelectableTimeline';
import {
  ChartDataEntry,
  DataEntry,
  Domain,
  MisclassificationDataEntry,
} from '../types';
import qs from 'qs';
import { useLocation, useParams } from 'react-router-dom';
import { useReports } from '../../../../../../../core/api/reports';
import { limit } from '../../../../../../../core/common/accuracyDetail';
import { EvaluationReportType } from 'common/dist/types/reports';
import { UseQueryResult } from 'react-query';
import styles from './styles.module.scss';
import GraphWait from '../../../../../../details/GraphWait';
import { FormattedMessage } from 'react-intl';
import MisclassificationChartContainer from './misclassificationChart/MisclassificationChart.container';
import PredictedValuesChart from './PredictedValuesChart';
import augurDetailsMessages from 'common/dist/messages/augurs.details';
import * as misclassification from '../../../../../../../core/common/misclassification';
import { AugurDetailsQueryParams, AugurDetailsRouteParams } from '../../types';
import DetailChart from './lineChart/DetailChart.container';

export type Props = {
  tabId: AccuracyDetailsType;
};

export const getTimeSeriesDataFromReports = (
  reports: EvaluationReportType[],
  chartType: AccuracyDetailsType
): DataEntry[] => {
  const timeSeries = reports.reduce((acc, report) => {
    const entry = {
      data: report.data[chartType].data,
      time: report.data.startedAt,
    };
    return [...acc, entry];
  }, [] as DataEntry[]);
  timeSeries.sort((a, b) => (a.time < b.time ? -1 : 1));
  return timeSeries;
};

const filterTimeSeries = (domain: Domain, reports: DataEntry[]) => {
  if (!domain || domain.length < 2) return reports; // no domain defined: do not filter

  return reports.filter(
    (item) =>
      Date.parse(item.time) >= domain[0] && Date.parse(item.time) <= domain[1]
  );
};

const renderMisclassificationLegend = () => {
  return (
    <ul className='misclassification-chart_legend'>
      {misclassification.buckets.map((bucket, index) => (
        <li key={index}>
          <span
            className='misclassification-chart_legend_dot'
            style={{ backgroundColor: bucket.color }}
          />
          <FormattedMessage
            id={bucket.label.id}
            defaultMessage={bucket.label.defaultMessage}
          />
        </li>
      ))}
    </ul>
  );
};

const DetailsElement: FC<Props> = ({ tabId }) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const location = useLocation();
  const { modelCode } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as AugurDetailsQueryParams;

  const [domain, setDomain] = useState<Domain>([]);

  const { isLoading, data: reports } = useReports(
    habitatCode,
    augurCode,
    modelCode,
    'evaluation',
    0,
    limit
  ) as UseQueryResult<EvaluationReportType[]>;

  // the following useMemo call are important to prevent the Timeline from re-rendering
  const data = useMemo(() => {
    return reports ? getTimeSeriesDataFromReports(reports, tabId) : [];
  }, [reports, tabId]);
  const timeline = useMemo(() => {
    // the event can fire very often in a short amount of time, this delays the state change until a final selection is made
    const onChangeSelection = _.debounce(setDomain, 200);
    return (
      <Sizer>
        <SelectableTimeline
          data={data.map((element) => ({
            time: element.time,
            value: 1,
          }))}
          onChangeSelection={onChangeSelection}
        />
      </Sizer>
    );
  }, [data]);

  if (isLoading) {
    return <Busy isBusy />;
  }

  // data is empty
  if (!reports || reports.length === 0) {
    return (
      <div className={styles.predictedValues}>
        <GraphWait jobType={'prediction'}>
          <FormattedMessage {...augurDetailsMessages.msgChartNotAvailable} />
        </GraphWait>
      </div>
    );
  }

  // If a domain is selected: Filter the data
  const filteredData = filterTimeSeries(domain, data);

  const renderChart = () => {
    switch (tabId) {
      case 'predictedValuesDistribution':
        return <PredictedValuesChart data={filteredData as ChartDataEntry[]} />;
      case 'misclassification': {
        // MisclassificationChartContainer uses a different data format, not worth the refactor
        const adjustedData = filteredData.map(
          (element: MisclassificationDataEntry) => ({
            time: element.time,
            ...element.data,
          })
        );
        return (
          <Sizer>
            {/* @ts-ignore */}
            <MisclassificationChartContainer data={adjustedData} />
          </Sizer>
        );
      }
      default:
        return (
          <Sizer>
            {/* @ts-ignore */}
            <DetailChart data={filteredData as ChartDataEntry[]} />
          </Sizer>
        );
    }
  };

  return (
    <div className={styles.content}>
      {tabId === ACCURACY_DETAILS_TYPE.MISCLASSIFICATION &&
        renderMisclassificationLegend()}
      <div className={styles.chart}>{renderChart()}</div>
      <div className={styles.timeline}>{timeline}</div>
    </div>
  );
};

export default DetailsElement;
