import React, { Component } from 'react';
// @ts-ignore
import { withRouter } from 'react-router-dom';
// @ts-ignore
import RepositoryHeader from '../../overview/RepositoryHeader.container';
// import { Props as ConnectedProps } from './MergeRequestDetailsHeader.container';
import MergeRequestDetailsHeader from './MergeRequestDetailsHeader.container';
import MergeRequestOverview from './tab-overview/MergeRequestOverview.container';
import TabLine from '../../../../molecules/tab-line/TabLine';
// @ts-ignore
import { collaborationSpaceRoutes } from '../../../routes';
import { MergeRequest } from '../../../giteaTypes';
import MergeRequestResolve from './tab-resolve/MergeRequestResolve.container';
import { ToBeRefined } from 'common/dist/types/todo_type';

export type Props = {
  match: {
    params: {
      id: number;
      group: string;
      repositoryName: string;
    };
  };

  /** Is the MergeRequest information from Gitea loading? */
  mrLoading: boolean;
  /** MergeRequest information from Gitea */
  mrData: MergeRequest;

  /** Load the details for the Merge Request (from Gitea) */
  loadMergeRequestDetails: ToBeRefined;
};

class MergeRequestDetails extends Component<Props> {
  componentDidMount() {
    const {
      match: {
        params: { id, group, repositoryName },
      },
      loadMergeRequestDetails,
    } = this.props;
    loadMergeRequestDetails(group, repositoryName, id);
  }

  render() {
    const {
      match: { params },
      mrLoading,
      mrData,
    } = this.props;

    // Show the conflicts tab if the data was loaded - and the merge request is indeed not mergeable
    const showConflictsTab = !mrLoading && mrData && !mrData.mergeable;

    const tabNames = ['Overview'];
    const tabContents = [
      <MergeRequestOverview
        group={params.group}
        repositoryName={params.repositoryName}
        id={params.id}
      />,
    ];

    if (showConflictsTab) {
      tabNames.push('Merge Conflicts');

      tabContents.push(
        <MergeRequestResolve
          // @ts-ignore
          group={params.group}
          repositoryName={params.repositoryName}
          id={params.id}
        />
      );
    }

    return (
      <div className={'CollaborationSpace--content'}>
        <div className={'repository'}>
          <RepositoryHeader
            linkTo={`${collaborationSpaceRoutes.basePath}/${params.group}/${params.repositoryName}/${collaborationSpaceRoutes.repositories.mergeRequest}`}
            message={'Back to Merge Requests'}
          />

          <MergeRequestDetailsHeader
            id={params.id}
            group={params.group}
            repositoryName={params.repositoryName}
          />

          <TabLine
            tabNames={tabNames}
            tabContents={tabContents}
            style={{
              // To make the left and right boundaries fit the other components. This causes the underline of the tabs to
              //   overlap a little which acts as a visual separator.
              margin: '0 -10px',
            }}
          />
        </div>
      </div>
    );
  }
}

// @ts-ignore TODO what is the problem with the type here?
export default withRouter(MergeRequestDetails);
