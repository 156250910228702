import { ToBeRefined } from 'common/dist/types/todo_type';

export const formName = 'enableMaintenance';

export const fieldTitleId = 'title';
export const fieldDescriptionId = 'description';

export const form = {
  form: formName,
  validate: (values: ToBeRefined) => {
    let errors = {};

    // console.log("validating start ", values);

    const title = values[fieldTitleId];
    if (!title) {
      errors = { ...errors, [fieldTitleId]: 'Please enter a title' };
    }

    const description = values[fieldDescriptionId];
    if (!description) {
      errors = {
        ...errors,
        [fieldDescriptionId]: 'Please enter a description',
      };
    }

    // console.log("validating ", values);

    return errors;
  },
};
