import React, { Component } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import DirectoryStep1 from './DirectoryStep1.container';
import { Link } from 'react-router-dom';
import { workbenchRoutes } from '../../../../../../workbench/common/workbenchRoutes';

interface Props {
  headline;
  directoryName: string;
  filledCompletely: boolean;
  isCreatingDirectory: boolean;
  onSave: (directoryName: string, altaSigmaMeta: object) => void;
  /** Click listener for the Cancel button */
  onCancel: () => void;

  /* --- Edit directory Wizard --- */
  /** List of opened notebooks (paths). Needed to disable the edit wizard button, since otherwise the opened notebooks
   * that belong into this directory can't be saved anymore (the notebooks contain the path - that is changed when
   * renaming directories) */
  openedNotebooks?: string[];
  currentPath: string;
}

const fieldName = 'directoryName';

class DirectoryWizard extends Component<Props & InjectedFormProps> {
  render() {
    const {
      headline,
      onSave,
      directoryName,
      filledCompletely,
      isCreatingDirectory,
      openedNotebooks,
      currentPath,
    } = this.props;

    const notebookInsideDirOpened = !!openedNotebooks?.find((nbPath) =>
      nbPath.startsWith(currentPath + '/')
    );
    return (
      <div
        className={'dialog-container'}
        style={{
          paddingTop: '40px', // This wizard requires additional space in the top to look good
        }}
      >
        <div className={'dialog-inner-container'}>
          <p className={'dialog-headline'}>{headline}</p>
          <form
            onKeyPress={(e) => {
              if (e.which === 13) e.preventDefault();
            }}
          >
            {notebookInsideDirOpened && (
              <div style={{ marginBottom: '20px' }}>
                <span style={{ color: 'red' }}>
                  Please close all notebooks from inside this directory before
                  editing
                </span>
              </div>
            )}

            <DirectoryStep1 />
            <div className={'dialog-button-container'}>
              <Link to={workbenchRoutes.basePath}>
                <button
                  type={'button'}
                  className={'dialog-button dialog-button-neutral'}
                >
                  Cancel
                </button>
              </Link>
              <Link
                to={workbenchRoutes.basePath}
                onClick={() => {
                  if (filledCompletely && !isCreatingDirectory) {
                    const altaSigmaMeta =
                      {}; /* Currently empty, might be filled again later */
                    onSave(directoryName, altaSigmaMeta);
                  }
                }}
              >
                <button
                  type={'button'}
                  className={
                    'dialog-button dialog-button-ok ' +
                    (filledCompletely ? '' : 'dialog-button-disabled')
                  }
                  disabled={
                    notebookInsideDirOpened ||
                    (!filledCompletely && !isCreatingDirectory)
                  }
                >
                  Finish
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const form = { form: 'directoryWizard' };
export default reduxForm(form)(DirectoryWizard);
