import { connect, ConnectedProps } from 'react-redux';
import JobGroupSchedule, { Props } from './JobGroupSchedule';
import { showDeleteSchedule } from '../../../../redux/modules/orchestration.schedules.module';
import { RootState } from '../../../../store/store';

export function mapStateToProps(state: RootState, { scheduleCode }: Props) {
  const augurNames: Record<string, string> = state.names.augurNames;
  const datapoolNames: Record<string, string> = state.names.datapoolNames;
  const codeCapsuleNames: Record<string, string> = state.names.codeCapsuleNames;
  return {
    /** The schedule to render */
    schedule: state.jobSchedules.schedules[scheduleCode],
    augurNames,
    datapoolNames,
    codeCapsuleNames,
  };
}

export const mapDispatchToProps: {
  showDeleteSchedule: (scheduleCode: string) => void;
} = {
  showDeleteSchedule,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(JobGroupSchedule);
