export const formName = 'configure-archetype';
export const fieldServingTypes = 'servingTypes';

function validate(values) {
  // Nothing to do at the moment
  const errors = {};
  return errors;
}

export const form = {
  form: formName,
  destroyOnUnmount: true,
  validate,
};
