import { ConfigProps } from 'redux-form';
import { Props } from './SamplingStrategy';

export const formName = 'augur-settings-sampling-strategy';

export const fieldSamplingStrategy = 'samplingStrategy';

export interface SamplingStrategySettingsField {
  strategy: 'automatic' | 'table';
  trainingSplit?: number;
  evaluationTable?: string;
}

export interface FormValues {
  [fieldSamplingStrategy]: SamplingStrategySettingsField;
}

export const form: ConfigProps<FormValues, Props> = {
  form: formName,
  enableReinitialize: true,
};
