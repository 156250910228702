import { validatePropensities } from '../../../../../organisms/modelManagement/newDatapoolWizard/optPropensities/validate';
import { PropensitiesValueType } from '../../../../../organisms/modelManagement/newDatapoolWizard/optPropensities/OptPropensities';

export const formName = 'propensities';

export const fieldPropensities = 'propensities';

export type FormValues = {
  [fieldPropensities]: PropensitiesValueType;
};

const validate = (values: FormValues) => {
  const errors = {};

  errors[fieldPropensities] = validatePropensities(values[fieldPropensities]);

  return errors;
};

export const propensitiesForm = {
  form: formName,
  enableReinitialize: true,
  validate,
  touchOnChange: true,
};
