export function validateDatapool(datapool) {
  if (!datapool) {
    return {
      id: 'newAugur.stepDatapool.error.no_selection',
      defaultMessage: 'Please select a Datapool',
    };
  }

  return undefined;
}
