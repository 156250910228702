import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RadioButtonsCol extends Component {
  render() {
    const {
      label,
      placeholder,
      options,
      input: { onChange, onBlur, onFocus, value },
      meta: { touched, error },
    } = this.props;
    return (
      <div className={`input-col${error ? ' error' : ''}`}>
        <div className={'form--headline'}>
          <p className={'form--label'}>{label}</p>
          {touched && error && <p className={'form--error'}>{error}</p>}
        </div>
        <div className={'form--input-parent radio-buttons-element'}>
          {options.map((o) => (
            <label className={'radio'}>
              <input
                type='radio'
                value={o.value}
                checked={value.inputValue && value.inputValue === o.value}
                onChange={(e) =>
                  onChange({ ...value, inputValue: e.target.value })
                }
              />
              <span>{o.label}</span>
            </label>
          ))}
        </div>
      </div>
    );
  }
}

RadioButtonsCol.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};
