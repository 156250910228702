import React, { FC } from 'react';

import { getRoundedY, getRoundedX } from '../util';
import { AimType } from './Crosshair';

type Props = {
  aim: AimType;
};

const Tooltip: FC<Props> = (props) => {
  const { aim } = props;
  const height = 25;
  const bottomPadding = 20;

  return (
    <div
      className='chart-tooltip'
      style={{
        transform: `translate(calc(${aim.x}px - 50%), ${
          aim.y - height - bottomPadding
        }px)`,
      }}
    >
      <span>{`${getRoundedY(aim.closest)}, ${getRoundedX(aim.closest)}`}</span>
    </div>
  );
};

export default Tooltip;
