import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import User from './User';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import BreadCrumbs from '../../../atoms/bread-crumbs/BreadCrumbs';
import { Group } from '../../../../store/admin/state.types';
import Paging from '../../../molecules/paging/Paging';
import { Header } from '../user-list/UsersList';
import { User as UserType } from 'common/dist/types/users';
import commonMessages from 'common/dist/messages/common';
import adminMessages from 'common/dist/messages/admin';

export const adminAddUserLink = `${usersRoutes.basePath}/${routes.app.admin.addUser}`;
const PAGE_SIZE = 20;

export interface Props {
  group?: Group;
  users: UserType[];
  loadUsersList: (
    fetchPermissions: boolean,
    offset?: number,
    limit?: number,
    search?: string
  ) => void;
  loadGroups: (fetchPermissions: boolean) => void;
}

const GroupDetails: FC<Props> = ({ group, users }) => {
  return (
    <div className={'ct-list'}>
      <div className={'ct-headline ct-row'}>
        <div className={'ct-col ct-col-200px ct-col-flex-grow'}>
          <FormattedMessage {...adminMessages.msgUsersFirstName} />
        </div>
        <div className={'ct-col ct-col-200px ct-col-flex-grow'}>
          <FormattedMessage {...adminMessages.msgUsersLastName} />
        </div>
        <div className={'ct-col ct-col-200px ct-col-flex-grow'}>
          <FormattedMessage {...adminMessages.msgUsersEmail} />
        </div>
        <div className={'ct-col ct-col-80px'}>
          <FormattedMessage {...adminMessages.msgUsersMember} />
        </div>
      </div>
      {users &&
        users.map((user) => {
          const isMember =
            user.groups.find((g) => g.id === group.id) !== undefined;
          return (
            <div>
              <User
                {...user}
                isMember={isMember}
                groupId={group.id}
                key={user.id}
              />
            </div>
          );
        })}
    </div>
  );
};

const GroupDetailsPaginated: FC<Props> = (props) => {
  const { loadUsersList, loadGroups, users, group } = props;

  const intl = useIntl();

  useEffect(() => {
    loadGroups(false);
  }, [loadGroups]);

  const updatePagingParameters = useCallback(
    (offset?: number, limit?: number, search?: string) =>
      loadUsersList(false, offset, limit, search),
    [loadUsersList]
  );

  if (group === undefined) {
    return <div />;
  }

  return (
    <div className={'AdminUserDetails'}>
      <BreadCrumbs
        backToProps={[
          {
            linkTo: `${usersRoutes.basePath}/${usersRoutes.groups.path}`,
            label: intl.formatMessage(commonMessages.backTo, {
              origin: intl.formatMessage(adminMessages.groupsOrigin),
            }),
          },
        ]}
      />

      <div className={'AdminUserDetails--header'}>
        <div
          className={
            'AdminUserDetails--part AdminUserDetails--part-user-details'
          }
        >
          <span className={'AdminUserDetails--part-headline'}>
            Group Details
          </span>
          <div className={'AdminUserDetails--part-details-container'}>
            <div className={'AdminUserDetails--part-details-line'}>
              <span className={'AdminUserDetails--part-details-key'}>
                Name:{' '}
              </span>
              <span className={'AdminUserDetails--part-details-value'}>
                {group.name}
              </span>
            </div>
            {Object.entries(group.attributes).map(([k, v]) => (
              <div className={'AdminUserDetails--part-details-line'}>
                <span className={'AdminUserDetails--part-details-key'}>
                  {`${k}: `}
                </span>
                <span className={'AdminUserDetails--part-details-value'}>
                  {v}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={'AdminUserDetails--part part-user-roles'}>
        <span className={'AdminUserDetails--part-headline'}>Members</span>
        <Paging
          itemsPerPage={PAGE_SIZE}
          updatePagingParameters={updatePagingParameters}
          Headline={Header}
          currentItems={users.length}
          searchEnabled
        >
          <GroupDetails {...props} />
        </Paging>
      </div>
    </div>
  );
};

export default GroupDetailsPaginated;
