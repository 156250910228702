import React, { FC } from 'react';

import HabitatContainer from '../habitat/Habitat.container';
import EmptyModelManagement from './EmptyModelManagement';
import MainContainer from '../../pages/main-container/MainContainer';
import { HabitatDetailsType } from 'common/dist/types/habitat';

type Props = {
  habitats: HabitatDetailsType[];
  loaded?: boolean;
  loading?: boolean;
};

const Habitats: FC<Props> = ({ habitats, loaded }) => {
  if (loaded && (!habitats || habitats.length === 0)) {
    return <EmptyModelManagement />;
  }

  return (
    <MainContainer
      additionalClassname={'ModelManagement ModelManagementHabitats'}
      transparent={true}
      fullWidth={false}
      scrollableY={true}
    >
      {habitats.map((habitat, index) => (
        <HabitatContainer habitat={habitat} key={index} />
      ))}
    </MainContainer>
  );
};

export default Habitats;
