import { connect } from 'react-redux';
import { RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX } from 'common/dist/constants/keycloak';
import CollaborationSpace from './CollaborationSpace';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';

export function mapStateToProps(state) {
  const permissions = state.dashboard?.permissions?.data || {};
  const missingPermissionsError = !hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX
  );
  return {
    missingPermissionsError,
  };
}

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CollaborationSpace);
