import React, { FC } from 'react';
import TextInputLinePassword from '../../../atoms/input-elements/text-input-line-password/TextInputLinePassword';
import { WrappedFieldProps } from 'redux-form';

const ConfirmPassword: FC<WrappedFieldProps> = (props) => {
  const {
    input: { value, onChange },
    meta: { error, dirty },
  } = props;
  return (
    <TextInputLinePassword
      touched={dirty}
      error={error}
      id={'change-password-confirm'}
      label={{
        id: 'account.password.confirmation',
        defaultMessage: 'Confirm New Password',
      }}
      placeholder={{
        id: '',
        defaultMessage: 'Type here ...',
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default ConfirmPassword;
