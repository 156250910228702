let locale;

try {
  locale = localStorage.getItem('locale') || 'en';
} catch (err) {
  locale = 'en';
}

export const initial = {
  locale,
  messages: {},
};
