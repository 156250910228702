import { ComponentType } from 'react';
import { MessageDescriptor } from 'react-intl';
import { RouteConfig } from 'react-router-config';

/**
 * Will be rendered as a simple horizontal bar in the Sidenav of the Augur Details
 */
export type HrAugurDetailsTab = {
  type: 'hr';
};

/**
 * Will be rendered as a simple headline in the Sidenav of the Augur Details
 */
export type HeadlineAugurDetailsTab = {
  type: 'headline';
  headline: MessageDescriptor;
};

/**
 * Represents one tab of the Augur Details
 */
export abstract class ComponentAugurDetailsTab {
  // Will be a link in the Sidenav and a component that can be clicked and rendered
  type: 'component' = 'component';
  // ID of the Augur Tab
  id: string;
  // URL Base URL for the link in the sidenav
  to: (
    habitatCode: string,
    augurCode: string,
    shortAugurType: string
  ) => string;
  // React intl ID of the name of the component (shown in the bar on the left)
  title: MessageDescriptor;
  // Icon component to use for the Menu entry
  icon: ComponentType<any>;
  // Component of the AugurTab
  component: ComponentType<any>;
  // Sub-routes for the component. Can be empty if no sub-routes exist.
  subRoutes: RouteConfig[] | undefined;
  // Overwrite with false to define subroutes that are not supposed to be shown in the menu on the left
  showInMenu = true;

  /**
   * Returns all subroutes for the given details tab.
   * @param pathPrefix the prefix of the URL to which the subPath of each subRoute will be appended
   */
  getAllSubRoutes(pathPrefix: string): RouteConfig[] {
    if (!this.subRoutes) {
      return [];
    } else {
      return this.subRoutes.map((sr) => ({
        path: `${pathPrefix}${sr.subPath}`,
        component: sr.component,
      }));
    }
  }
}

export type AugurDetailsTab =
  | HrAugurDetailsTab
  | HeadlineAugurDetailsTab
  | ComponentAugurDetailsTab;
