import React, { Component, ComponentProps } from 'react';
import {
  Data as FormData,
  fieldDescription,
  fieldName,
  jobGroupField,
  newScheduleFormName,
  selectedScheduleField,
  JobGroup,
} from './newSchedule.form';
import {
  SelectedSchedule,
  cronToQuartzCron,
} from 'common/dist/utils/schedules';
import { Field, InjectedFormProps } from 'redux-form';
import Wizard from '../../../pages/wizard/Wizard';
import { trigTimedToQuartzCron } from 'common/dist/utils/schedules';
import { ConnectedProps } from './NewScheduleWizard.container';
import { ButtonProps } from '../../../atoms/button/Button';
import PeriodStep from '../../../../components/orchestration/job-schedules/add-schedule/steps/period/PeriodStep';
import _ from 'lodash';
import JobGroupStep from './steps/job-group/JobGroupStep';
import { Summary } from 'common/dist/types/habitat';
import JobGroupEditorField from './steps/JobGroupEditorField';
import { JobGroupInputType } from 'common/dist/types/job';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';

import { WrappedTextInputLine } from '../../../atoms/input-elements/text-input-line/TextInputLine';
import { WrappedTextInputArea } from '../../../atoms/input-elements/text-input-area/TextInputArea';
import styles from './styles.module.scss';

import messages from 'common/dist/messages/orchestration';
import { RouteComponentProps } from 'react-router';
import { orchestrationRoutes } from '../../routes';

import { InputSchedule } from 'common/dist/types/job';
// --- Config
const cancelLink = '/app/orchestration/schedulings';
// ---

const formatDateString = (inputDateString: string) => {
  const dateObject = new Date(inputDateString);
  const formattedDateString: string = dateObject.toISOString();
  return formattedDateString;
};
/**
 * Takes the values selected in the form and creates the InputSchedule object (that will be passed to the backend) from
 * them.
 * @param selectedSchedule
 * @param jobGroup
 */
function createInputSchedule(
  selectedSchedule: SelectedSchedule,
  jobGroup: JobGroup
) {
  const inputSchedule: InputSchedule = {
    trigger: selectedSchedule.trigger,
    jobGroupInput: {
      trigger: selectedSchedule.trigger,
      priority: 'medium', // Currently not treated by the Queue anyway
      jobs: jobGroup.jobs,
      jobGroupTopology: jobGroup.jobGroupTopology,
      name: jobGroup.name,
      description: jobGroup.description,
    },
    trigDelayed: selectedSchedule.trigDelayed
      ? formatDateString(selectedSchedule.trigDelayed)
      : undefined,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...(selectedSchedule.trigger === 'timed' && {
      trigTimed: trigTimedToQuartzCron(selectedSchedule.trigTimed),
    }),
    ...(selectedSchedule.trigger === 'cron' && {
      trigCron: cronToQuartzCron(selectedSchedule.trigCron),
    }),
  };
  return inputSchedule;
}

/**
 * Creates the InputSchedule object, but adds the scheduleCode as it is expected for updates to schedules
 * @param selectedSchedule
 * @param jobGroup
 * @param scheduleCode
 */
function createUpdateSchedule(
  selectedSchedule: SelectedSchedule,
  jobGroup: JobGroup,
  scheduleCode
) {
  return {
    ...createInputSchedule(selectedSchedule, jobGroup),
    scheduleCode,
  };
}

export default class NewScheduleWizard extends Component<
  Props & ConnectedProps & InjectedFormProps & RouteComponentProps
> {
  /**
   * FetchSummary will load all habitats / datapools / augurs into the redux state.
   * This is a rather radical step and is only required to display the options in the Job Picker.
   * It must be made sure that the habitats / datapools / augurs are in the redux state.
   * // TODO This must be replaced with a more efficient solution
   */
  componentDidMount() {
    const {
      scheduleCode,
      edit,
      fetchSummary,
      fetchModules,
      fetchSchedule,
      touch,
      fetchCodeCapsules,
      summary,
      initialJobGroupCode,
      fetchJobGroup,
    } = this.props;

    fetchSummary();
    fetchModules();
    const habitats: string[] = summary.map((habitat) => habitat.code);
    habitats.forEach((code) => fetchCodeCapsules('habitat', code));
    if (edit) {
      touch(selectedScheduleField, jobGroupField, fieldDescription, fieldName);
      fetchSchedule(scheduleCode);
    }

    // If an initial job group code is give, fetch the Job Group (this is the case if a schedule is created based on an existing Job Group)
    initialJobGroupCode && fetchJobGroup({ jobGroupCode: initialJobGroupCode });
  }

  /**
   * After the content for editing is fetched the form is reinitialized so the touched fields are overwritten again
   * @param prevProps
   */
  componentDidUpdate(prevProps: Props & ConnectedProps & InjectedFormProps) {
    const { edit, touch, fetchCodeCapsules, summary } = this.props;
    const habitats: string[] = summary.map((habitat) => habitat.code);
    const prevHabitats: string[] = prevProps.summary.map(
      (habitat) => habitat.code
    );
    if (!_.isEqual(habitats, prevHabitats)) {
      habitats.forEach((code) => fetchCodeCapsules('habitat', code));
    }
    // Only touching the fields again when the initialValues change does not always work: e.g. if the values are already loaded but then fetched again
    if (edit) {
      // && !isEqual(initialValues, prevProps.initialValues)) {
      touch(selectedScheduleField, jobGroupField, fieldDescription, fieldName);
    }
  }

  /**
   * Resets the state for this wizard when the componend is unmounted
   */
  componentWillUnmount() {
    const { destroyScheduleForm } = this.props;
    destroyScheduleForm();
  }

  render() {
    const {
      handleSubmit,
      edit,
      scheduleCode,
      addSchedule,
      jobPickerIsActive,
      selectedSchedule,
      submitting,
      jobGroup,
      valid,
      anyTouched,
      updateSchedule,
      hideJobPicker,
      jobGroupsLoading,
      history,
    } = this.props;
    const buttons: ButtonProps[] = [
      {
        withLink: true,
        linkTo: cancelLink,
        buttonColor: 'white',
        buttonLabelId: 'newAugur.cancel',
        buttonLabelDefault: 'Cancel',
      },
      {
        withLink: false,
        isSubmitButton: true,
        buttonColor: 'secondary',
        buttonLabelId: 'newAugur.finish',
        buttonLabelDefault: 'Finish',
        disabled: !(anyTouched && valid),
        isBusy: submitting,
        onClick: () => {},
      },
    ];
    const headline = edit ? `Edit Schedule` : 'Add Schedule';

    if (jobPickerIsActive) {
      return (
        <Field
          name={jobGroupField}
          component={JobGroupEditorField}
          onClickSave={() => hideJobPicker()}
        />
      );
    }

    return (
      <form
        onSubmit={handleSubmit((values: FormData) => {
          const submitJobGroup = {
            ...jobGroup,
            name: values[fieldName],
            description: values[fieldDescription],
          };

          if (anyTouched && valid) {
            if (edit) {
              const inputSchedule = createUpdateSchedule(
                selectedSchedule,
                submitJobGroup,
                scheduleCode
              );
              // @ts-ignore: incorrect typing for Redux saga
              updateSchedule(inputSchedule, () =>
                history.push(
                  `${orchestrationRoutes.basePath}/${orchestrationRoutes.schedulings.path}`
                )
              );
            } else {
              const inputSchedule = createInputSchedule(
                selectedSchedule,
                submitJobGroup
              );
              // @ts-ignore: incorrect typing for Redux saga
              addSchedule(inputSchedule, () =>
                history.push(
                  `${orchestrationRoutes.basePath}/${orchestrationRoutes.schedulings.path}`
                )
              );
            }
          }
        })}
        className={styles.form}
      >
        <Wizard
          additionalClassname={'AddSchedule'}
          withHeadline={true}
          headlineId={'no-intl-id'}
          headlineDefault={headline}
          buttons={buttons}
        >
          <>
            <GenericFormStep
              fieldName={fieldName}
              formName={newScheduleFormName}
              component={WrappedTextInputLine}
              renderError={false}
              title={{
                id: messages.msgFieldNameName.id,
                defaultMessage: messages.msgFieldNameName.defaultMessage,
              }}
              description={{
                id: messages.msgFieldNameDescription.id,
                defaultMessage: messages.msgFieldNameDescription.defaultMessage,
              }}
              num={1}
              fieldProps={
                {
                  placeholderId: messages.msgFieldNameName.id,
                  placeholderDefault: messages.msgFieldNameName.defaultMessage,
                  labelId: messages.msgFieldNameDescription.id,
                  labelDefault: messages.msgFieldNameDescription.defaultMessage,
                  hasLabel: true,
                } as ComponentProps<typeof WrappedTextInputLine>
              }
            />
            <GenericFormStep
              fieldName={fieldDescription}
              formName={newScheduleFormName}
              component={WrappedTextInputArea}
              renderError={false}
              title={{
                id: messages.msgFieldDescriptionName.id,
                defaultMessage: messages.msgFieldDescriptionName.defaultMessage,
              }}
              description={{
                id: messages.msgFieldDescriptionDescription.id,
                defaultMessage: messages.msgFieldDescriptionDescription.id,
              }}
              num={2}
              fieldProps={
                {
                  hasLabel: true,
                  label: {
                    id: messages.msgFieldDescriptionName.id,
                    defaultMessage:
                      messages.msgFieldDescriptionName.defaultMessage,
                  },
                  placeholder: {
                    id: messages.msgFieldDescriptionDescription.id,
                    defaultMessage:
                      messages.msgFieldDescriptionDescription.defaultMessage,
                  },
                } as ComponentProps<typeof WrappedTextInputArea>
              }
            />
            <PeriodStep step={3} />
            <JobGroupStep step={4} jobGroupsLoading={jobGroupsLoading} />
          </>
        </Wizard>
      </form>
    );
  }
}

export interface Props {
  edit?: boolean;
  /** The scheduleCode from the URL if edit = true */
  scheduleCode?: string;
  /** A custom function that resets the forms data in the store */
  reset: (formName: string) => void;
  /** Is the Job Picker shown? */
  jobPickerIsActive?: boolean;
  submitting?: boolean;
  submitted?: boolean;
  selectedSchedule?: SelectedSchedule;
  jobGroup: JobGroupInputType;
  name: string;
  description: string;
  summary: Summary;
  // Redux type hints
  fetchCodeCapsules: (affiliationType: string, habitatCode: string) => void;
  /** Optionally passed as a query parameter, currently only used for the Add Schedule Wizard */
  initialJobGroupCode?: string;
  /** Fetch a single Job Group by its code */
  fetchJobGroup: (jobGroupCode: string) => void;
  /** Are the job groups (or one of the job groups) currently loading? */
  jobGroupsLoading?: boolean;
}
