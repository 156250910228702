import React, { Component, ReactElement } from 'react';
import styles from './styles.module.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { WrappedFieldProps } from 'redux-form';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import ccMsgs from 'common/dist/messages/codeCapsules';
import TextInputLine from '../atoms/input-elements/text-input-line/TextInputLine';

type Props = {
  /** Available notebook to select from for executing */
  availableNotebooks: string[];
};

type State = {
  filterQuery?: string;
};

class NotebookInputField extends Component<
  Props & WrappedFieldProps & WrappedComponentProps,
  State
> {
  constructor(props: Props & WrappedFieldProps & WrappedComponentProps) {
    super(props);
    this.state = { filterQuery: null };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result): void {
    const {
      input: { value, onChange, onBlur },
    } = this.props;
    const { source, destination, draggableId: notebook } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const { droppableId: sourceId } = source;
    const { droppableId: destinationId, index: destinationIndex } = destination;
    let newSelected = value || [];

    if (sourceId !== destinationId) {
      // really moved to another list

      // Check whether the notebook needs to be added to the selected notebooks
      if (destinationId === 'selected') {
        newSelected = [
          ...newSelected.slice(0, destinationIndex),
          notebook,
          ...newSelected.slice(destinationIndex),
        ];
      }

      // Check whether the notebook needs to be removed from the selected notebooks
      if (destinationId === 'available') {
        newSelected = newSelected.filter((n) => n !== notebook);
      }
    } else if (sourceId === 'selected' && destinationId === 'selected') {
      newSelected = newSelected.filter((n) => n !== notebook); // Remove the notebook so it won't be duplicated
      newSelected = [
        ...newSelected.slice(0, destinationIndex),
        notebook, // and inject it at the destination index
        ...newSelected.slice(destinationIndex),
      ];
    }

    onChange(newSelected); // Set the new value
    onBlur(newSelected); // To make the "touched" attribute of redux-form work
  }

  renderDroppable(
    name: string,
    listElements: string[],
    value,
    onChange,
    onBlur
  ): ReactElement {
    return (
      <Droppable droppableId={name}>
        {(provided) => (
          <div className={styles.droppable} ref={provided.innerRef}>
            {listElements.map((notebook, index) => (
              <Draggable key={notebook} draggableId={notebook} index={index}>
                {(provided) => (
                  <div
                    className={styles.draggable}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={() => {
                      if (name === 'available') {
                        // If in the available column: Add the notebook to the selected notebooks
                        const newValue = [...(value || []), notebook];
                        onChange(newValue);
                        onBlur(newValue);
                      } else if (name === 'selected') {
                        // If in the selected column: Remove the notebook from the selected notebooks
                        const newValue = (value || []).filter(
                          (n) => n !== notebook
                        );
                        onChange(newValue);
                        onBlur(newValue);
                      }
                    }}
                  >
                    {notebook}
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    );
  }

  filterElements(elements) {
    if (!elements) return [];
    let { filterQuery } = this.state;

    if (!filterQuery) return elements;

    filterQuery = filterQuery.toLowerCase();
    return elements.filter((el) => el.toLowerCase().includes(filterQuery));
  }

  render() {
    const {
      input: { value, onChange, onBlur },
      availableNotebooks,
    } = this.props;
    const available = availableNotebooks.filter(
      (n) => !(value || []).includes(n)
    );

    return (
      <div className={styles.notebookInput}>
        <div className={styles.filterParent}>
          <TextInputLine
            touched
            isClearable
            hasLabel={false}
            placeholderId={ccMsgs.msgCcRunModalNotebooksFilter.id}
            placeholderDefault={
              ccMsgs.msgCcRunModalNotebooksFilter.defaultMessage
            }
            value={this.state.filterQuery}
            onChange={(e) => {
              this.setState({ filterQuery: e.target.value });
            }}
            onBlur={() => null}
            onFocus={() => null}
          />
        </div>

        <div className={styles.dndParent}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className={styles.dndInner}>
              <div className={styles.notebookListContainer}>
                <span className={styles.listName}>
                  <FormattedMessage
                    {...ccMsgs.msgCcRunModalNotebooksAvailable}
                  />
                </span>
                {this.renderDroppable(
                  'available',
                  this.filterElements(available),
                  value,
                  onChange,
                  onBlur
                )}
              </div>
              <div className={styles.notebookListContainer}>
                <span className={styles.listName}>
                  <FormattedMessage
                    {...ccMsgs.msgCcRunModalNotebooksSelected}
                  />
                </span>
                {this.renderDroppable(
                  'selected',
                  this.filterElements(value),
                  value,
                  onChange,
                  onBlur
                )}
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

export default injectIntl(NotebookInputField);
