import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FileStep1 from './FileStep1.container';
import { formName } from './file.form';
import { Link } from 'react-router-dom';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';

class AddFile extends Component {
  render() {
    const { addFile, fileName, filledCompletely } = this.props;

    return (
      <div
        className={'dialog-container'}
        style={{
          paddingTop: '40px', // This wizard requires additional space in the top to look good
        }}
      >
        <div className={'dialog-inner-container'}>
          <p className={'dialog-headline'}>Add new File</p>
          <form
            onKeyPress={(e) => {
              if (e.which === 13) e.preventDefault();
            }}
          >
            <FileStep1 />
            <div className={'dialog-button-container'}>
              <Link to={workbenchRoutes.basePath}>
                <button
                  type={'button'}
                  className={'dialog-button dialog-button-neutral'}
                >
                  Cancel
                </button>
              </Link>
              <Link
                to={workbenchRoutes.basePath}
                onClick={() => {
                  if (filledCompletely) {
                    addFile(fileName);
                  }
                }}
              >
                <button
                  type={'button'}
                  className={
                    'dialog-button dialog-button-ok ' +
                    (filledCompletely ? '' : 'dialog-button-disabled')
                  }
                  disabled={!filledCompletely}
                >
                  Finish
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AddFile.propTypes = {
  addFile: PropTypes.func.isRequired,
  filledCompletely: PropTypes.bool.isRequired,
  fileName: PropTypes.string,
};

const form = { form: formName };
export default reduxForm(form)(AddFile);
