import { CommunicationsValueType } from '../../../../../organisms/modelManagement/newDatapoolWizard/optCommunications/OptCommunications';
import { validateCommunications } from '../../../../../organisms/modelManagement/newDatapoolWizard/optCommunications/validate';

export const formName = 'communications';

export const fieldCommunications = 'communications';

export type FormValues = {
  [fieldCommunications]: CommunicationsValueType;
};

const validate = (values: FormValues) => {
  const errors = {};

  errors[fieldCommunications] = validateCommunications(
    values[fieldCommunications]
  );

  return errors;
};

export const communicationsForm = {
  form: formName,
  enableReinitialize: true,
  validate,
  touchOnChange: true,
};
