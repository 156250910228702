import ParameterTuningEditMode, { Props } from './ParameterTuningEditMode';
import { formValueSelector, isInvalid, reduxForm } from 'redux-form';
import { fieldParameterTuning, form, formName } from './parameterTuning.form';
import { connect } from 'react-redux';
import { DeprecatedRootState } from '../../../../../../../../../store/state.type';
import { validateParameterTuningField } from '../../../../../../../../organisms/parameter-tuning/parameter-tuning-selection/form';
import {
  extractParametersFromForm,
  initialValuesFromOutput,
} from '../../../../../../../../organisms/parameter-tuning/parameter-tuning-selection/formHelper';

function mapStateToProps(
  state: DeprecatedRootState,
  { parameterTuningSettings }: Props
) {
  // Extract settings for saving from form
  const selector = formValueSelector(formName);
  const parameterTuning = selector(state, fieldParameterTuning);
  if (parameterTuning !== undefined) {
    parameterTuningSettings = extractParametersFromForm(parameterTuning);
  }
  const settings = {
    parameterTuning: parameterTuningSettings,
  };

  // Set initial values
  const parameterTuningInitialValues = initialValuesFromOutput(
    parameterTuningSettings
  );
  const initialValues = {
    [fieldParameterTuning]: parameterTuningInitialValues,
  };

  const invalid = isInvalid(formName)(state);

  return {
    initialValues,
    validate: validateParameterTuningField,
    settings,
    hasError: invalid,
  };
}

export default connect(
  mapStateToProps
  // @ts-ignore
)(reduxForm(form)(ParameterTuningEditMode));
