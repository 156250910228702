import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import { FiX } from 'react-icons/fi';
import { TabLink } from 'react-tabs-redux';

export default class LauncherTabLinkElement extends Component {
  render() {
    const { hideOverlappingMenu, updateOverlappingTabs, paneId, pane } =
      this.props;
    return (
      <div key={'launcher-' + paneId}>
        <Measure
          bounds
          onResize={(contentRect) => {
            this.props.measuredTabWidth('launcher', contentRect.bounds.width);
            this.props.updateOverlappingTabs();
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              <TabLink
                className={'notebook-tab-link'}
                isActive={true}
                handleSelect={() => {}}
                to={'launcher'}
              >
                <div className={'notebook-link-name'}>Launcher</div>
              </TabLink>
            </div>
          )}
        </Measure>
      </div>
    );
  }
}
LauncherTabLinkElement.propTypes = {
  measuredTabWidth: PropTypes.func.isRequired,
  updateOverlappingTabs: PropTypes.func.isRequired,
  hideOverlappingMenu: PropTypes.func.isRequired,
  paneId: PropTypes.string.isRequired,
  pane: PropTypes.shape({
    selectedContent: PropTypes.string,
  }),
};
