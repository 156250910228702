import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import GitFileStatus from './file-status/GitFileStatus.container';
import GitListCommits from './list-commits/GitListCommits.container';
import GitNotPushed from './git-not-pushed/GitNotPushed.container';
import GitNotPulled from './git-not-pulled/GitNotPulled.container';
import GitBranches from './git-branches/GitBranches.container';
import { Link, Redirect } from 'react-router-dom';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import RepositoryMeta from './repository-meta/RepositoryMeta.container';
import Button from '../../../../atoms/button/Button';

export default class RepositoryInfo extends Component {
  refreshRepoInfo() {
    const { gitActiveBranch, infoFilePath, loadRepoMeta } = this.props;
    if (!infoFilePath) {
      // This happens if no "path" is passed in the query string of the URL
      return;
    }

    // --- Derive the repository path from the infoFilePath ( "abc/def/repository.rsk" -> "/abc/def")
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');
    // ---

    gitActiveBranch(repositoryPath, false);
    loadRepoMeta(infoFilePath);
  }

  componentDidMount() {
    this.refreshRepoInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.infoFilePath !== this.props.infoFilePath) {
      this.refreshRepoInfo();
    }
  }

  renderCodeCapsuleMetaIfRequired() {
    const { infoFilePath, repoMetas } = this.props;
    const belongsToCodeCapsuleRepository =
      repoMetas[infoFilePath]?.repoType === 'code-capsule';
    if (!belongsToCodeCapsuleRepository) return;

    return (
      <Fragment>
        <span className={'repository-info-category-title'}>
          Repository Meta
          <br />
        </span>
        <RepositoryMeta infoFilePath={infoFilePath} />
      </Fragment>
    );
  }

  render() {
    const { infoFilePath, repoMetas, currentUserId } = this.props;
    if (!infoFilePath) {
      // If no path is passed in the query string redirect to the Workbench page
      return <Redirect to={`${workbenchRoutes.basePath}`} />;
    }

    // --- Derive the repository path from the infoFilePath ( "abc/def/repository.asr" -> "/abc/def")
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');
    // ---

    return (
      <div className={'workbench-repository-info'}>
        <div
          className={'dialog-container'}
          style={{
            paddingTop: '40px', // This wizard requires additional space in the top to look good
          }}
        >
          <div className={'dialog-inner-container'}>
            <div style={{ display: 'flex' }}>
              {' '}
              {/**TODO inline css*/}
              <div className={'dialog-header'} style={{ flexGrow: 1 }}>
                <div className={'dialog-headline dialog-header-item'}>
                  Repository Info for{' '}
                  {repoMetas[infoFilePath]?.name ||
                    repoMetas[infoFilePath]?.repoName ||
                    (repositoryPath || '.').split('.').slice(0, -1).join('.')}
                </div>
              </div>
              <Button
                buttonLabelDefault={'Fork'}
                buttonColor={'secondary'}
                withLink={true}
                linkTo={`${workbenchRoutes.basePath}${workbenchRoutes.repositoryFork}?path=${infoFilePath}`}
              />
            </div>

            {this.renderCodeCapsuleMetaIfRequired()}

            <span className={'repository-info-category-title'}>
              Branches
              <br />
            </span>
            <GitBranches repositoryPath={repositoryPath} />

            <span className={'repository-info-category-title'}>
              Remote Changes
            </span>
            <GitNotPulled repositoryPath={repositoryPath} />

            <span className={'repository-info-category-title'}>
              Local Changes
            </span>
            <GitFileStatus repositoryPath={repositoryPath} />

            <>
              <span className={'repository-info-category-title'}>
                Latest local Commits
              </span>
              <GitNotPushed repositoryPath={repositoryPath} />
            </>

            <GitListCommits repositoryPath={repositoryPath} />

            <div className={'dialog-button-container'}>
              <Link to={workbenchRoutes.basePath}>
                <button
                  type={'button'}
                  className={'dialog-button dialog-button-neutral'}
                >
                  Close
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RepositoryInfo.propTypes = {
  infoFilePath: PropTypes.string,
  activeBranch: PropTypes.string.isRequired,
  gitActiveBranch: PropTypes.func.isRequired,
  /** Injected by react-router */
  location: PropTypes.shape({}),
  loadRepoMeta: PropTypes.func.isRequired,
  repoMetas: PropTypes.shape({}),
  currentUserId: PropTypes.string,
};
