import { connect } from 'react-redux';
import AppArrangeModal from './AppArrangeModal';
import { hideAppArrangeModal } from '../../../../../redux/workbench/modules/app.module';

export function mapStateToProps(state) {
  return {
    isShown: (state.workbench.app || {}).isAppArrangeModalShown,
  };
}

export const mapDispatchToProps = {
  hideAppArrangeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppArrangeModal);
