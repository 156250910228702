import { validateContactPolicies } from '../../../../../organisms/modelManagement/newAugurWizard/optContactPolicies/validate';
import { ContactPoliciesValueType } from 'common/dist/types/augurSettings.opt';

export const formName = 'contactPolicies';

export const fieldContactPolicies = 'contactPolicies';

export type FormValues = {
  [fieldContactPolicies]: ContactPoliciesValueType;
};

const validate = (values: FormValues) => {
  const errors = {};

  errors[fieldContactPolicies] = validateContactPolicies(
    values[fieldContactPolicies]
  );

  return errors;
};

export const contactPoliciesForm = {
  form: formName,
  enableReinitialize: true,
  validate,
  touchOnChange: true,
};
