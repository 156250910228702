import React, { FC, Fragment, useState } from 'react';
import { getShortNameForType } from '../../../modelManagement/modules/ModuleManagerClient';
import ActionButton from '../action-button/ActionButton';
import ActionButtonLoading from '../action-button/ActionButtonLoading';
import ErrorModal from './ErrorModal';
import styles from '../styles.module.scss';
import RunPredictionModal from '../../../details/run-prediction-modal/RunPredictionModal.container';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../../../modelManagement/modules/common/augur-details-tabs/types';
import { useAugur, useSettings } from '../../../../core/api/augurs';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

type Props = {
  triggerLearningJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string
  ) => void;
  triggerEvaluationJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string
  ) => void;
  showRunPredictionModal: () => void;
};

const actionButtons = {
  learning: {
    gridColumn: 4,
    gridRow: 1,
    Icon: () => <span className={'icon-play'} />,
    label: {
      id: 'details.action_bar.run_learning',
      defaultMessage: 'Run Learning Now',
    },
  },
  evaluation: {
    gridColumn: 4,
    gridRow: 2,
    Icon: () => <span className={'icon-play'} />,
    label: {
      id: 'details.action_bar.run_evaluation',
      defaultMessage: 'Run Evaluation Now',
    },
  },
  prediction: {
    gridColumn: 5,
    gridRow: 1,
    Icon: () => <span className={'icon-play'} />,
    label: {
      id: 'details.action_bar.run_prediction',
      defaultMessage: 'Run Prediction Now',
    },
  },
  toDatapool: {
    gridColumn: 5,
    gridRow: 2,
    Icon: () => <span className={'icon-datapool'} />,
    label: {
      id: 'details.action_bar.go_to_datapool',
      defaultMessage: 'Go to Datapool',
    },
  },
};

const ActionButtons: FC<Props> = (props: Props) => {
  let { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augur, isSuccess } = useAugur(habitatCode, augurCode);
  const { data } = useSettings(habitatCode, augurCode);
  const settings = data?.data as AugurSettingsDataNbc;

  const { triggerLearningJob, triggerEvaluationJob, showRunPredictionModal } =
    props;

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  if (!isSuccess)
    return (
      <Fragment>
        {Object.values(actionButtons).map((ab, i) => (
          <ActionButtonLoading key={i} {...ab} />
        ))}
      </Fragment>
    );

  augurCode = augur.code ?? augurCode;
  habitatCode = augur.habitatCode ?? habitatCode;
  const augurName = augur.name;
  const datapoolCode = augur.datapoolCode;
  const moduleType = augur.type;
  const moduleTypeShort = getShortNameForType(moduleType);

  // Further enhance the action buttons with the information that came with the loaded information now
  const actionButtonsLoaded = {
    learning: {
      onClick: () => triggerLearningJob(habitatCode, augurCode, augurName),
      disabled: !augur.archetypeVersion?.supportsLearning,
    },
    evaluation: {
      onClick: () => triggerEvaluationJob(habitatCode, augurCode, augurName),
      disabled: !augur.archetypeVersion?.supportsEvaluation,
    },
    prediction: {
      onClick: () => showRunPredictionModal(),
      disabled: !augur.archetypeVersion?.supportsPrediction,
    },
    toDatapool: {
      onClick: () => {},
      hasLink: true,
      linkTo: `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${moduleTypeShort}`,
    },
  };

  return (
    <Fragment>
      {Object.keys(actionButtons).map((abId) => {
        const ab = actionButtons[abId];
        const abc = actionButtonsLoaded[abId];
        return <ActionButton key={abId} {...ab} {...abc} />;
      })}

      {augur.error && (
        <ActionButton
          gridColumn={3}
          gridRow={2}
          onClick={() => setErrorModalOpen(true)}
          Icon={() => <span className={'icon-alert'} />}
          label={{
            id: 'action.show_error',
            defaultMessage: 'Show error message',
          }}
          addlClassName={styles.actionButtonError}
        />
      )}

      {augur.error && (
        <ErrorModal
          isOpen={errorModalOpen}
          error={augur.error}
          closeModal={() => setErrorModalOpen(false)}
        />
      )}

      <RunPredictionModal
        habitatCode={habitatCode}
        datapool={augur.datapool}
        augurCode={augurCode}
        augurName={augurName}
        scoringTable={settings?.scoringTable}
      />
    </Fragment>
  );
};

export default ActionButtons;
