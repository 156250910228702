import { call, put, select, take } from 'redux-saga/effects';
import { notebookUser } from '../selectors/notebookUser.selector';
import * as SessionApi from '../../../core/api/workbench/session';
import { END, eventChannel } from 'redux-saga';
import { v4 as uuidv4 } from 'uuid';
import {
  error as errorNotification,
  success as successNotification,
} from 'react-notification-system-redux';
import { actionFailed, actionSuccessful } from '../notifications/notifications';

/**
 * This helper class opens a 'console' session, executes a single command and shuts down the session again.
 * This can be used to trigger the Kernel build, executing git commands, ... from the Dashboard.
 */
export class SingleCommandSession {
  parentType: string;
  appVersionCode?: string;
  sessionName: string;

  /**
   * @param parentType notebook | app
   * @param appVersionCode
   */
  constructor(parentType: 'notebook' | 'app', appVersionCode?: string) {
    this.parentType = parentType;
    this.appVersionCode = appVersionCode;
    this.executeCommand = this.executeCommand.bind(this);
    this.initConsoleWebsocket = this.initConsoleWebsocket.bind(this);
    this.waitForSocketConnection = this.waitForSocketConnection.bind(this);
    this.requestSocketStatus = this.requestSocketStatus.bind(this);
    this.sendCommandToExecute = this.sendCommandToExecute.bind(this);
    // Name for the session (only required to look up and close the session later).
    // Reason for doing so: When creating a session, the name can be set, but the ID of the session can't. And the ID
    // is also not returned by the POST request to start the session.
    this.sessionName = uuidv4();
  }

  /**
   * Executes the command via the singleCommandSession
   * @param command Python3 command to execute
   * @param onSuccessActions redux-actions that are fired on success (allowed to be undefined)
   * @param onFailureActions redux-actions that are fired on failure (allowed to be undefined)
   * @param onStreamActions redux-actions that are fired whenever a stream message is received (= a Python print() statement)
   * @param showSuccessNotifications show notifications on success
   * @param showFailureNotifications show notifications on failure
   * @returns {Generator<<"TAKE", TakeEffectDescriptor>|<"CALL", CallEffectDescriptor>|<"SELECT", SelectEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>, void, ?>}
   */
  *executeCommand(
    command,
    onSuccessActions,
    onFailureActions,
    onStreamActions,
    showSuccessNotifications = false,
    showFailureNotifications = true
  ) {
    // --- 1. Open the console session (the following steps will be the same for every interaction)
    const jupyterUser = yield select((state) => notebookUser(state));
    const sessionDetails = {
      kernelName: 'python3',
      jupyterUser,
      sessionName: this.sessionName,
    };
    const { response: sessionResponse, error: sessionError } = yield call(
      SessionApi.openConsoleSession,
      sessionDetails,
      this.parentType,
      this.appVersionCode
    );
    if (sessionError) {
      // TODO Put failure message and quit here.
    }
    const { kernel } = sessionResponse;

    // --- 2. Open Websocket
    // const sessionId = ''
    const kernelId = kernel.id;
    const channel = yield call(
      this.initConsoleWebsocket,
      kernelId,
      jupyterUser,
      command,
      onSuccessActions,
      onFailureActions,
      onStreamActions,
      showSuccessNotifications,
      showFailureNotifications
    );
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
    // ---
  }

  /**
   * Returns the URL of the WebSocket Channel that is opened once the new Session was started.
   * This WebSocket Channel is used to execute the command and to receive the result (e.g. success / failure)
   * @param kernelId
   * @param jupyterUser
   * @param parentType
   * @returns {string}
   */
  getConsoleSocketUrl(kernelId, jupyterUser, parentType, appVersionCode) {
    // TODO session_id query parameter is empty - is this fine?
    if (parentType === 'app') {
      const serverName = appVersionCode.toLowerCase();
      return `${location.protocol.includes('https') ? 'wss://' : 'ws://'}${
        location.hostname + (location.port ? ':' + location.port : '')
      }/jupyterapp/user/${jupyterUser}/${serverName}/api/kernels/${kernelId}/channels?session_id=`;
    } else {
      // parentType === 'notebook
      return `${location.protocol.includes('https') ? 'wss://' : 'ws://'}${
        location.hostname + (location.port ? ':' + location.port : '')
      }/jupyter/user/${jupyterUser}/api/kernels/${kernelId}/channels?session_id=`;
    }
  }

  /**
   * This function is used to connect to the WebSocket Channel and to send messages / handle the incoming messages
   * @param kernelId
   * @param jupyterUser
   * @param command
   * @param onSuccessActions redux-actions that are fired on success (allowed to be undefined)
   * @param onFailureActions redux-actions that are fired on failure (allowed to be undefined)
   * @param onStreamActions redux-actions that are fired whenever a stream message is received (= a Python print() statement)
   * @param showSuccessNotifications on success / failure?
   * @param showFailureNotifications on failure?
   * @returns {*}
   */
  initConsoleWebsocket(
    kernelId,
    jupyterUser,
    command,
    onSuccessActions,
    onFailureActions,
    onStreamActions,
    showSuccessNotifications,
    showFailureNotifications
  ) {
    return eventChannel((emitter) => {
      const socketUrl = this.getConsoleSocketUrl(
        kernelId,
        jupyterUser,
        this.parentType,
        this.appVersionCode
      );
      const socket = new WebSocket(socketUrl);

      // Handle socket messages
      socket.onmessage = async (messageEvent) => {
        const message = JSON.parse(messageEvent.data);
        const messageType = message.header.msg_type;

        switch (messageType) {
          case 'kernel_info_reply': {
            if (message.content && message.content.status === 'ok') {
              this.sendCommandToExecute(socket, command);
            }
            break;
          }
          case 'stream': {
            if (message.content && message.content.text) {
              const { text } = message.content;
              if (onStreamActions)
                onStreamActions.forEach((action) => emitter(action(text)));
            }
            break;
          }
          case 'execute_reply': {
            if (message.content && message.content.status === 'ok') {
              if (onSuccessActions)
                onSuccessActions.forEach((action) => emitter(action()));
              if (showSuccessNotifications)
                emitter(successNotification(actionSuccessful()));
            } else {
              // This is treat in case 'error' (since the message is available there)
              // emitter(errorNotification(actionFailed()));
            }
            // Shut down the session
            const { response: runningSessions, error } =
              await SessionApi.fetchSessions(jupyterUser);
            const foundSession =
              runningSessions &&
              runningSessions.find((s) => s.name === this.sessionName);
            if (foundSession) {
              await SessionApi.deleteSession(foundSession.id, jupyterUser);
            }
            // Shut down the emitter
            return emitter(END);
          }
          case 'error': {
            // This message is only received if there were errors during execution.
            // Anyway, a 'execute_reply' message will be sent afterwards anyway, so this case could only be used to for
            // show error messages
            const errorMessage = message.content.evalue;
            if (onFailureActions)
              onFailureActions.forEach((action) =>
                emitter(action(errorMessage))
              );
            if (showFailureNotifications)
              return emitter(errorNotification(actionFailed(errorMessage)));
            break;
          }
          default: {
          }
        }
      };

      // Request the Websocket status - the starting point for the commands. Socket ready -> exeucte command -> shutdown
      this.requestSocketStatus(socket);

      return () => {};
    });
  }

  /**
   * This function checks a WebSocket connection to be ready. Once the socket is accepting connections, the given
   * callback will be executed.
   * @param websocket
   * @param callback
   * @param retryDelayInMs time to wait until the next retry
   */
  waitForSocketConnection(websocket, callback, retryDelayInMs = 200) {
    setTimeout(() => {
      if (websocket.readyState === 1) {
        if (callback != null) {
          callback();
        }
      } else {
        this.waitForSocketConnection(websocket, callback);
      }
    }, retryDelayInMs);
  }

  /**
   * Sends a message through the WebSocket connection that requests for the Kernel status.
   * To send the message, waitForSocketConnection() is used to ensure that the WebSocket channel is accepting messages.
   * The WebSocket will respond with a message of type 'kernel_info_reply' which is then treated in the
   * initConsoleWebsocket() socket.onmessage function
   * @param socket
   */
  requestSocketStatus(socket) {
    // --- Request the socket status
    this.waitForSocketConnection(socket, () => {
      const msgId = uuidv4();
      socket.send(
        JSON.stringify({
          buffers: [],
          channel: 'shell',
          content: {},
          header: {
            msg_id: msgId,
            msg_type: 'kernel_info_request',
            // session: '7a95c6db-6329-4e72-ad0f-54aaa6f6919e',
            username: '',
            version: '5.2',
          },
          metadata: {},
          parent_header: {},
        })
      );
    });
  }

  sendCommandToExecute(socket, command) {
    const msgId = uuidv4();
    socket.send(
      JSON.stringify({
        buffers: [],
        channel: 'shell',
        content: {
          silent: false,
          store_history: true,
          user_expressions: {},
          allow_stdin: true,
          stop_on_error: true,
          code: command,
        },
        header: {
          date: '2020-04-05T16:10:50.767Z',
          msg_id: msgId,
          msg_type: 'execute_request',
          // session: '26965cc4-ddea-4bd5-8358-cf51d6530858',
          username: '',
          version: '5.2',
        },
        metadata: {
          // cellId: 'b13c68c9-aa0a-4e79-b5e1-71b51af1d68f',
        },
        parent_header: {},
      })
    );
  }
}
