import tree from './tree.state';

export const test = {
  baseline: 1,
  active: {
    tree: {
      selected: {
        node: {},
        path: [],
      },
      preview: {
        node: {},
        path: [],
      },
    },
  },

  area: [
    ['5', 1.60926486896849],
    ['10', 1.68850897236467],
    ['15', 1.62030050980842],
    ['20', 1.61536056922974],
    ['25', 1.71812997455157],
    ['30', 1.60316916870725],
    ['35', 1.32681211557897],
    ['40', 1.31667125642877],
    ['45', 1.1739535769178],
    ['50', 0.981407742060332],
    ['55', 0.743675431871804],
    ['60', 1.03332372134952],
    ['65', 1.13380024859144],
    ['70', 0.86212215804901],
    ['75', 0.572995824556964],
    ['80', 0.171201563300513],
    ['85', 0.219445209404795],
    ['90', 0.232344978764981],
    ['95', 0.219445209404795],
    ['100', 0.158488206792352],
  ],

  pie: [
    {
      name: 'True Positive',
      value: 10,
    },
    {
      name: 'True Negative',
      value: 30,
    },
    {
      name: 'False Positive',
      value: 50,
    },
    {
      name: 'False Positive',
      value: 10,
    },
  ],

  tree,

  bar: {
    data: [
      ['0.042921686746988', 1673],
      ['0.0860215053763441', 124],
      ['0.264705882352941', 148],
      ['0.346534653465347', 187],
      ['0.404040404040404', 143],
      ['0.436974789915966', 246],
      ['0.471111111111111', 323],
      ['0.478260869565217', 410],
      ['0.563829787234043', 196],
      ['0.56989247311828', 122],
      ['0.632075471698113', 136],
      ['0.686274509803922', 181],
      ['0.704331450094162', 769],
      ['0.898584905660377', 1738],
      ['0.956140350877193', 156],
    ],
    barValue: '',
  },

  bubble: {
    data: [
      {
        name: 'orders_qty_kitchen_l730d',
        value: 1,
      },
      {
        name: 'orders_amt_kitchen_l730d',
        value: 0.983,
      },
      {
        name: 'amt_order_hk_l730d',
        value: 0.715,
      },
      {
        name: 'qty_order_hk_l730d',
        value: 0.651,
      },
      {
        name: 'qty_hk_pct_l730d',
        value: 0.581,
      },
      {
        name: 'amt_hk_pct_l730d',
        value: 0.579,
      },
      {
        name: 'prod_first_order_category',
        value: 0.183,
      },
      {
        name: 'qty_days_last_order',
        value: 0.182,
      },
      {
        name: 'amt_order_hb_l730d',
        value: 0.162,
      },
      {
        name: 'bundesland',
        value: 0.116,
      },
      {
        name: 'qty_order_hb_l730d',
        value: 0.103,
      },
      {
        name: 'avg_margin_hk_pct_l730d',
        value: 0.076,
      },
      {
        name: 'amt_q2_pct_l730d',
        value: 0.071,
      },
      {
        name: 'qty_days_last_offl_order',
        value: 0.038,
      },
      {
        name: 'amt_hb_pct_l730d',
        value: 0.034,
      },
      {
        name: 'top_division',
        value: 0.032,
      },
      {
        name: 'prod_first_order_top_division',
        value: 0.027,
      },
      {
        name: 'zip_region',
        value: 0.026,
      },
      {
        name: 'amt_q1_pct_l730d',
        value: 0.026,
      },
      {
        name: 'qty_hb_pct_l730d',
        value: 0.025,
      },
      {
        name: 'orders_amt_hometext_l730d',
        value: 0.025,
      },
      {
        name: 'qty_orderlines_l730d',
        value: 0.025,
      },
      {
        name: 'qty_ship_lt',
        value: 0.024,
      },
      {
        name: 'qty_order_lt',
        value: 0.024,
      },
      {
        name: 'amt_pn_pct_l730d',
        value: 0.024,
      },
      {
        name: 'amt_order_lt',
        value: 0.023,
      },
      {
        name: 'relation_duration',
        value: 0.023,
      },
      {
        name: 'qty_orderline_l730d',
        value: 0.022,
      },
      {
        name: 'amt_order_l730d',
        value: 0.022,
      },
      {
        name: 'amt_return_lt',
        value: 0.021,
      },
      {
        name: 'qty_order_hg_l730d',
        value: 0.021,
      },
      {
        name: 'amt_order_pn_l730d',
        value: 0.021,
      },
      {
        name: 'asp_l730d',
        value: 0.021,
      },
      {
        name: 'orders_qty_hometext_l730d',
        value: 0.021,
      },
      {
        name: 'orders_amt_beauty_l730d',
        value: 0.021,
      },
      {
        name: 'qty_pn_pct_l730d',
        value: 0.02,
      },
      {
        name: 'amt_order_l365d',
        value: 0.02,
      },
      {
        name: 'amt_order_hg_l730d',
        value: 0.019,
      },
      {
        name: 'orders_amt_unused_l730d',
        value: 0.019,
      },
      {
        name: 'orders_qty_beauty_l730d',
        value: 0.019,
      },
    ],
  },

  line: {
    data: [
      {
        id: '0',
        mapIndex: '1',
        name: 'cumulativeLift',
        timeStamp: '2014-08-29 22:36:15',
        color: '#008CBA',
        isSelected: true,
        data: [
          [0, 1.50926486896849],
          [5, 1.60926486896849],
          [10, 1.64888692066658],
          [15, 1.63937750423666],
          [20, 1.63336869059648],
          [25, 1.65028989929814],
          [30, 1.64242845492692],
          [35, 1.59741905982584],
          [40, 1.56228541636371],
          [45, 1.51921061599098],
          [50, 1.46536466280134],
          [55, 1.39968373390431],
          [60, 1.3692080981614],
          [65, 1.3510785431816],
          [70, 1.31621401244929],
          [75, 1.26660563523548],
          [80, 1.1982604470037],
          [85, 1.14061070774487],
          [90, 1.09023708268103],
          [95, 1.04434702252123],
          [100, 1.0],
        ],
      },
      {
        id: '1',
        mapIndex: '',
        name: 'cumulativeCapturedResponse',
        timeStamp: '2015-09-29 20:36:15',
        color: 'red',
        isSelected: false,
        data: [
          [0, 0],
          [5, 0.850561489166921],
          [10, 0.865090021361001],
          [15, 0.845956667683857],
          [20, 0.726823314006713],
          [25, 0.412572474824535],
          [30, 0.49282880683552],
          [35, 0.559047909673482],
          [40, 0.624961855355508],
          [45, 0.683552029295087],
          [50, 0.732682331400671],
          [55, 0.769911504424779],
          [60, 0.821483063777846],
          [65, 0.878242294781813],
          [70, 0.921269453768691],
          [75, 0.94995422642661],
          [80, 0.958498626792798],
          [85, 0.969484284406469],
          [90, 0.981080256332011],
          [95, 0.992065913945682],
          [100, 1.0],
        ],
      },
      {
        id: '2',
        name: 'lift',
        mapIndex: '2',
        timeStamp: '2010-06-29 15:36:15',
        color: 'orange',
        isSelected: true,
        data: [
          [0, 1.40926486896849],
          [5, 1.70926486896849],
          [10, 1.78850897236467],
          [15, 1.62030050980842],
          [20, 1.61536056922974],
          [25, 1.71812997455157],
          [30, 1.60316916870725],
          [35, 1.32681211557897],
          [40, 1.31667125642877],
          [45, 1.1739535769178],
          [50, 0.981407742060332],
          [55, 0.743675431871804],
          [60, 1.03332372134952],
          [65, 1.13380024859144],
          [70, 0.86212215804901],
          [75, 0.572995824556964],
          [80, 0.171201563300513],
          [85, 0.219445209404795],
          [90, 0.232344978764981],
          [95, 0.219445209404795],
          [100, 0.158488206792352],
        ],
      },
      {
        id: '3',
        name: 'liftern',
        mapIndex: '3',
        timeStamp: '2014-12-15 11:36:30',
        color: 'green',
        isSelected: true,
        data: [
          [0, 1.0926486896849],
          [5, 1.60926486896849],
          [10, 1.48850897236467],
          [15, 1.62030050980842],
          [20, 1.91536056922974],
          [25, 1.21812997455157],
          [30, 1.00316916870725],
          [35, 1.42681211557897],
          [40, 1.31667125642877],
          [45, 1.1739535769178],
          [50, 0.881407742060332],
          [55, 0.543675431871804],
          [60, 1.23332372134952],
          [65, 1.23380024859144],
          [70, 0.76212215804901],
          [75, 0.672995824556964],
          [80, 0.271201563300513],
          [85, 0.819445209404795],
          [90, 0.132344978764981],
          [95, 0.319445209404795],
          [100, 0.858488206792352],
        ],
      },
      {
        id: '4',
        name: 'cumulativeResponse',
        mapIndex: '',
        timeStamp: '2014-08-15 09:36:30',
        color: 'blue',
        isSelected: false,
        data: [
          [0, 0.2],
          [5, 0.3],
          [10, 0.4],
          [15, 0.5],
          [20, 0.6],
          [25, 0.65],
          [30, 0.7],
          [35, 0.73],
          [40, 0.5],
          [45, 0.8],
          [50, 0.83],
          [55, 0.86],
          [60, 0.89],
          [65, 0.91],
          [70, 0.92],
          [75, 0.94],
          [80, 0.97],
          [85, 1.0],
          [90, 1.1],
          [95, 0.3],
          [100, 1.0],
        ],
      },
    ],
  },
};

export const initial = test;
