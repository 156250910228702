import { connect } from 'react-redux';

import {
  fieldDescription,
  fieldSrcBranch,
  fieldTargetBranch,
  fieldTitle,
  form,
  formName,
} from './mergeRequest.form';
import { fetchBranches } from '../../../../redux/workbench/modules/collab.repository.module';
import {
  openMergeRequest,
  editMergeRequest,
  loadMergeRequestDetails,
} from '../../../../redux/workbench/modules/collab.module';
import { selectValue } from '../repository-add/selectorUtils';
import OpenMergeRequestWizard from './OpenMergeRequestWizard';
import { reduxForm } from 'redux-form';
import qs from 'qs';
import { withRouter } from 'react-router-dom';

export function mapStateToProps(state, { location }) {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const props = {
    filledCompletely:
      state.form[formName] &&
      (!state.form[formName].syncErrors ||
        Object.values(state.form[formName].syncErrors).every(
          (fieldError) => !fieldError
        )),
    sourceBranch: selectValue(state, formName, fieldSrcBranch),
    targetBranch: selectValue(state, formName, fieldTargetBranch),
    description: selectValue(state, formName, fieldDescription),
    title: selectValue(state, formName, fieldTitle),
    branches: state.workbench.collab.branches.data,
    initialValues: {
      // Might be overwritten in the
      [fieldSrcBranch]: (queryParams || {}).sourceBranch,
      [fieldTargetBranch]: (queryParams || {}).targetBranch,
    },
  };

  const mr = state.workbench.collab.mergeRequest.data;
  if (mr) {
    props['initialValues'] = {
      [fieldTitle]: mr.title,
      [fieldDescription]: mr.body,
      [fieldSrcBranch]: mr.head.label,
      [fieldTargetBranch]: mr.base.label,
    };
  }
  return props;
}

export const mapDispatchToProps = {
  fetchBranches,
  openMergeRequest,
  loadMergeRequestDetails,
  editMergeRequest,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm(form)(OpenMergeRequestWizard))
);
