import React, { FC } from 'react';
import SamplingStrategy from './sampling-strategy/SamplingStrategy.container';
import { ModelSettingsProps } from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

const SamplingStrategyCategory: FC<ModelSettingsProps<AugurSettingsDataNbc>> = (
  props
) => {
  return <SamplingStrategy {...props} />;
};

export default SamplingStrategyCategory;
