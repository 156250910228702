import * as React from 'react';
import { Component } from 'react';
import ParameterTuningAlgorithm from './parameter-tuning-algorithm/ParameterTuningAlgorithm';
import './parameterTuningSelection.scss';
import { WrappedFieldProps } from 'redux-form';
import InputError from '../../../_tools/inputError/InputError';
import DropdownSelectInput from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { ParameterTuningSchema } from './types';

type OptionType = { label: string; value: string };

export type ParameterTuningValue = {
  meta: {
    [algorithm: string]: {
      selected: boolean;
    };
  };
  searchStrategy: string;
  algorithms: {
    [algorithm: string]: {
      [parameter: string]: string;
    };
  };
};

export type Props = {
  /** The json schema describing the parameter tuning */
  parameterTuningSchema?: ParameterTuningSchema;
};

export default class ParameterTuningSelection extends Component<
  Props & WrappedFieldProps
> {
  renderAvailableSearchStrategies() {
    const {
      parameterTuningSchema,
      input: { value, onChange, onBlur },
      meta: { error, touched },
    } = this.props;

    const options: OptionType[] =
      parameterTuningSchema.availableSearchStrategies.map(
        ({ name, speakingName }) => ({
          label: speakingName,
          value: name,
        })
      );

    return (
      <div>
        <h3 className={'ParameterTuning--headline'}>Search Strategy</h3>

        <DropdownSelectInput
          id={'search-strategy-select'}
          name={'search-strategy-select'}
          className='ParameterTuning--search-strategy-select'
          placeholder={{
            id: 'no-id',
            defaultMessage: 'Select a Search Strategy',
          }}
          options={options}
          value={options.find((o) => o.value === value?.searchStrategy)}
          onChange={(option: OptionType) =>
            onChange({ ...value, searchStrategy: option.value })
          }
          onBlur={() => onBlur(value)}
          touched={touched}
          error={error?.searchStrategy}
        />
      </div>
    );
  }

  renderSelectableAlgorithms() {
    const { parameterTuningSchema, input, meta } = this.props;
    const { error, touched } = meta;

    return (
      <>
        <div className='error-line'>
          <InputError error={error?.meta} touched={touched} />
        </div>

        {parameterTuningSchema.availableAlgorithms.map(
          (availableAlgorithm, idx) => (
            <ParameterTuningAlgorithm
              key={idx}
              availableAlgorithm={availableAlgorithm}
              input={input}
              meta={meta}
            />
          )
        )}
      </>
    );
  }

  render() {
    return (
      <div className={'ParameterTuningSelection--container'}>
        {this.renderAvailableSearchStrategies()}
        {this.renderSelectableAlgorithms()}
      </div>
    );
  }
}
