import {
  AdminMaintenanceActions,
  ENABLE_MAINTENANCE,
  ENABLE_MAINTENANCE_FAIL,
  ENABLE_MAINTENANCE_SUCCESS,
} from './actions.types';
import { DeprecatedRootState } from '../../state.type';
import { AdminMaintenanceState } from './state.types';

export function reducer(
  state: DeprecatedRootState,
  action: AdminMaintenanceActions
): AdminMaintenanceState {
  switch (action.type) {
    case ENABLE_MAINTENANCE: {
      return {
        ...state.adminMaintenance,
        enableMaintenance: {
          ...state.adminMaintenance.enableMaintenance,
          submitting: true,
        },
      };
    }

    case ENABLE_MAINTENANCE_SUCCESS: {
      return {
        ...state.adminMaintenance,
        enableMaintenance: {
          ...state.adminMaintenance.enableMaintenance,
          submitting: false,
        },
      };
    }

    case ENABLE_MAINTENANCE_FAIL: {
      const { error } = action.payload;
      return {
        ...state.adminMaintenance,
        enableMaintenance: {
          ...state.adminMaintenance.enableMaintenance,
          submitting: false,
          error,
        },
      };
    }

    default: {
      return state.adminMaintenance;
    }
  }
}

export { reducer as adminMaintenanceReducer };
