import { connect, ConnectedProps } from 'react-redux';
import TimedSchedules from './TimedSchedules';
import {
  hideDeleteSchedule,
  showDeleteSchedule,
} from '../../../redux/modules/orchestration.schedules.module';

export function mapStateToProps(state, otherProps) {
  let schedules = state.jobSchedules.schedules;
  if (!schedules) schedules = {};

  return {
    codes: state.jobSchedules.codes || [],
    schedules: schedules,
    deleteModal: state.jobSchedules.deleteModal,
  };
}

const mapDispatchToProps = {
  showDeleteSchedule,
  hideDeleteSchedule,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type connectedProps = ConnectedProps<typeof connector>;
export default connector(TimedSchedules);
