import { connect } from 'react-redux';

import Header from './Header';
import * as selector from '../../../../redux/selectors/menu.selector';
import { getLinkForFirstPermittedMainTab } from 'common/dist/utils/authorization.general';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  const permissions = state.dashboard?.permissions?.data || {};
  const mainTabsDefaultLink = getLinkForFirstPermittedMainTab(permissions);

  return {
    isMenuShown: selector.isMenuShown(state),
    mainTabsDefaultLink,
    configNavbarLabel: state.config?.navbarLabel,
  };
}

export default connect(mapStateToProps)(Header);
