import React from 'react';
import Influencers from './Influencers';

import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import { MessageDescriptor } from 'react-intl';

export default class InfluencersWrapper extends ComponentAugurDetailsTab {
  component = Influencers;
  icon = () => <span className={'icon-influencers'} />;
  id = 'influencers';
  to = (
    habitatCode: string,
    augurCode: string,
    shortAugurType: string
  ): string =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/influencers`;
  title: MessageDescriptor = {
    id: 'details.sideNav.influencers',
    defaultMessage: 'Influencers',
  };
  subRoutes: Record<string, unknown>[] | undefined = undefined;
}
