import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FiXSquare } from 'react-icons/fi';

export default class ContextMenuRepo extends Component {
  render() {
    const { menuId, showDeleteRepoConfirm, repoName, repoFullName } =
      this.props;
    return (
      <ContextMenu id={menuId} className={'context-menu'}>
        <MenuItem>
          <div
            className={'cm-item-container'}
            onClick={(e) => {
              e.preventDefault(); // Don't bubble the click event up (otherwise the repository info will open)
              showDeleteRepoConfirm(repoName, repoFullName);
            }}
          >
            <FiXSquare className={'context-menu-icon'} />
            <p className={'context-menu-text'}>Delete</p>
          </div>
        </MenuItem>
      </ContextMenu>
    );
  }
}
ContextMenuRepo.propTypes = {
  menuId: PropTypes.string.isRequired,
  /** Name of the repo - Can be missing if the extra information besides git is not available */
  repoName: PropTypes.string,
  /** Full name of the repository - Always guaranteed to be there, as it comes directly from git */
  repoFullName: PropTypes.string.isRequired,
  showDeleteRepoConfirm: PropTypes.func.isRequired,
};
