export function validateName(
  value,
  notebooksInCurrentDir,
  isEditing,
  initialName
) {
  // No empty names
  if (!value) {
    return { message: 'Please enter a name' };
  }
  // No special characters
  if (/[^a-zA-Z0-9\-\_\(\)\.]/.test(value)) {
    return { message: 'Please remove invalid characters' };
  }
  // No duplicated names
  let nameWithEnding;

  if (value === "main.asapp") {
      nameWithEnding = value;
  } else if (value.endsWith('.ipynb')) {
      nameWithEnding = value;
  } else {
      nameWithEnding = `${value}.ipynb`;
  }

  if (notebooksInCurrentDir) {
    const sameName = /* notebooksInCurrentDir && */ notebooksInCurrentDir.find(
      (c) => c.name === nameWithEnding
    );
    // In the case where we are editing and the name is the same as the initalName it is allowed to keep it
    if (sameName && !(isEditing && value === initialName)) {
      return { message: 'A file with the given name already exists' };
    }
  }
}
