import { createAction } from 'redux-act';
import * as Api from '../../core/api';
import { call, put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';

export const updateJobStatus = createAction(
  'update job status',
  (jobCode, status) => ({
    jobCode,
    status,
  })
);

export const fetchJobDetails = createAction('fetch job details', (jobCode) => ({
  jobCode,
}));

export const fetchJobDetailsSuccess = createAction(
  'fetch job details - success',
  (jobCode, data) => ({ jobCode, data })
);

export const fetchJobDetailsFailure = createAction(
  'fetch job details - failure',
  (jobCode, error) => ({ jobCode, error })
);

export const fetchJobLogs = createAction('fetch job logs', (jobCode) => ({
  jobCode,
}));

export const fetchJobLogsSuccess = createAction(
  'fetch job logs - success',
  (jobCode, data) => ({ jobCode, data })
);

export const fetchJobLogsFailure = createAction(
  'fetch job logs - failure',
  (jobCode, error) => ({ jobCode, error })
);

export const updateJobKubernetesEvents = createAction(
  'update job kubernetes events',
  (jobCode, data) => ({ jobCode, data })
);

export const fetchJobKubernetesEvents = createAction(
  'fetch job kubernetes events',
  (jobCode) => ({ jobCode })
);

export const fetchJobKubernetesEventsSuccess = createAction(
  'fetch job kubernetes events - success',
  (jobCode, data) => ({ jobCode, data })
);

export const fetchJobKubernetesEventsFailure = createAction(
  'fetch job kubernetes events - failure',
  (jobCode, error) => ({ jobCode, error })
);

export const fetchJobLogsOrchestration = createAction(
  'fetch job logs orchestration',
  (jobCode) => ({ jobCode })
);

export const fetchJobLogsOrchestrationSuccess = createAction(
  'fetch job logs orchestration - success',
  (jobCode, data) => ({ jobCode, data })
);

export const fetchJobLogsOrchestrationFailure = createAction(
  'fetch job logs orchestration - failure',
  (jobCode, error) => ({ jobCode, error })
);

export const updateProgressSteps = createAction(
  'update progress steps',
  (jobCode, data) => ({ jobCode, data })
);

export const fetchProgressSteps = createAction(
  'fetch job progress steps',
  (jobCode) => ({ jobCode })
);

export const fetchProgressStepsSuccess = createAction(
  'fetch job progress steps - success',
  (jobCode, data) => ({ jobCode, data })
);

export const fetchProgressStepsFailure = createAction(
  'fetch job progress steps - failure',
  (jobCode, error) => ({ jobCode, error })
);

export const setDetailsOrigin = createAction(
  'set details origin',
  (origin) => ({ origin })
);

export const appendLogLines = createAction(
  'append log lines',
  (jobCode, logLines) => ({
    jobCode,
    logLines,
  })
);

export const clearJobLog = createAction('clear job log', (jobCode) => ({
  jobCode,
}));

export const reducer = {
  [updateJobStatus]: (state, { jobCode, status }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      data: {
        ...state.jobDetails.data,
        status,
      },
    },
  }),
  [fetchJobDetails]: (state, { jobCode }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      loading: true,
    },
  }),
  [fetchJobDetailsSuccess]: (state, { jobCode, data }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      loading: false,
      loaded: true,
      data,
      error: undefined,
    },
  }),
  [fetchJobDetailsFailure]: (state, { jobCode, error }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      loading: false,
      loaded: false,
      error,
    },
  }),
  [fetchJobLogs]: (state, { jobCode }) => ({
    ...state,
    jobLogs: {
      ...state.jobLogs,
      loading: true,
    },
  }),
  [fetchJobLogsSuccess]: (state, { jobCode, data }) => ({
    ...state,
    jobLogs: {
      ...state.jobLogs,
      loading: false,
      loaded: true,
      data,
    },
  }),
  [fetchJobLogsFailure]: (state, { jobCode, error }) => ({
    ...state,
    jobLogs: {
      ...state.jobLogs,
      loading: false,
      loaded: false,
      error,
    },
  }),
  [updateJobKubernetesEvents]: (state, { jobCode, data }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      events: {
        ...state.jobDetails.events,
        data,
      },
    },
  }),
  [fetchJobKubernetesEvents]: (state, { jobCode }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      events: {
        ...state.jobDetails.events,
        loading: true,
      },
    },
  }),
  [fetchJobKubernetesEventsSuccess]: (state, { jobCode, data }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      events: {
        ...state.jobDetails.events,
        loading: false,
        loaded: true,
        data,
        error: undefined,
      },
    },
  }),
  [fetchJobKubernetesEventsFailure]: (state, { jobCode, error }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      events: {
        ...state.jobDetails.events,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [updateProgressSteps]: (state, { jobCode, data }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      progressSteps: {
        ...state.jobDetails.progressSteps,
        data: (data ?? []).map((step) => ({
          ...(state.jobDetails.progressSteps.data ?? []).find(
            (x) => x.id === step.id
          ),
          ...step,
        })),
      },
    },
  }),
  [fetchProgressSteps]: (state, { jobCode }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      progressSteps: {
        ...state.jobDetails.progressSteps,
        loading: true,
      },
    },
  }),
  [fetchProgressStepsSuccess]: (state, { jobCode, data }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      progressSteps: {
        ...state.jobDetails.progressSteps,
        loading: false,
        loaded: true,
        data,
        error: undefined,
      },
    },
  }),
  [fetchProgressStepsFailure]: (state, { jobCode, error }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      progressSteps: {
        ...state.jobDetails.progressSteps,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [setDetailsOrigin]: (state, { origin }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      origin,
    },
  }),
  [fetchJobLogsOrchestration]: (state, { jobCode }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      logs: {
        ...state.jobDetails.logs,
        loading: true,
      },
    },
  }),
  [fetchJobLogsOrchestrationSuccess]: (state, { jobCode, data }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      logs: {
        ...state.jobDetails.logs,
        loading: false,
        loaded: true,
        error: undefined,
        data: _.uniqBy(
          [...(state.jobDetails.logs?.data || []), ...(data || [])],
          (x) => x.n
        )
          .sort((a, b) => (a.n < b.n ? -1 : 1))
          .slice(-1000), // max amount cached log lines
      },
    },
  }),
  [fetchJobLogsOrchestrationFailure]: (state, { jobCode, error }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      logs: {
        ...state.jobDetails.logs,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [appendLogLines]: (state, { jobCode, logLines }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      logs: {
        ...(state.jobDetails.logs || {}),
        data: _.uniqBy(
          [...(state.jobDetails.logs?.data || []), ...(logLines || [])],
          (x) => x.n
        )
          .sort((a, b) => (a.n < b.n ? -1 : 1))
          .slice(-1000), // max amount cached log lines
      },
    },
  }),
  [clearJobLog]: (state, { jobCode }) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      logs: undefined,
    },
  }),
};

export function* fetchJobDetailsSaga({ payload: { jobCode } }) {
  const { response, error } = yield call(
    Api.orchestration.fetchJobDetails,
    jobCode
  );
  if (response) {
    yield put(fetchJobDetailsSuccess(jobCode, response));
  } else {
    yield put(fetchJobDetailsFailure(jobCode, error));
  }
}

export function* watchFetchJobDetails() {
  yield takeEvery(fetchJobDetails.getType(), fetchJobDetailsSaga);
}

export function* fetchJobLogsSaga({ payload: { jobCode } }) {
  const { response, error } = yield call(
    Api.orchestration.fetchJobLogs,
    jobCode
  );
  if (response) {
    yield put(fetchJobLogsSuccess(jobCode, response));
  } else {
    yield put(fetchJobLogsFailure(jobCode, error));
  }
}

export function* watchFetchJobLogs() {
  yield takeEvery(fetchJobLogs.getType(), fetchJobLogsSaga);
}

export function* fetchJobKubernetesEventsSaga({ payload: { jobCode } }) {
  const { response, error } = yield call(
    Api.orchestration.fetchJobKubernetesEvents,
    jobCode
  );
  if (response) {
    yield put(fetchJobKubernetesEventsSuccess(jobCode, response));
  } else {
    yield put(fetchJobKubernetesEventsFailure(jobCode, error));
  }
}

export function* watchJobKubernetesEvents() {
  yield takeEvery(
    fetchJobKubernetesEvents.getType(),
    fetchJobKubernetesEventsSaga
  );
}

export function* fetchProgressStepsSaga({ payload: { jobCode } }) {
  const { response, error } = yield call(
    Api.orchestration.fetchProgressSteps,
    jobCode
  );
  if (response) {
    yield put(fetchProgressStepsSuccess(jobCode, response));
  } else {
    yield put(fetchProgressStepsFailure(jobCode, error));
  }
}

export function* watchProgressSteps() {
  yield takeEvery(fetchProgressSteps.getType(), fetchProgressStepsSaga);
}

export function* fetchJobLogsOrchestrationSaga({ payload: { jobCode } }) {
  const { response, error } = yield call(
    Api.orchestration.fetchJobLogsOrchestration,
    jobCode
  );
  if (response) {
    yield put(fetchJobLogsOrchestrationSuccess(jobCode, response));
  } else {
    yield put(fetchJobLogsOrchestrationFailure(jobCode, error));
  }
}

export function* watchFetchLogsOrchestration() {
  yield takeEvery(
    fetchJobLogsOrchestration.getType(),
    fetchJobLogsOrchestrationSaga
  );
}
