import { connect } from 'react-redux';
import FetchBranchModal from './FetchBranchModal';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { hideFetchBranchModal } from '../../../../../../../redux/workbench/modules/repository.module';
import { gitFetchAndTrackRemoteBranch } from '../../../../../../../redux/workbench/modules/container-interactions.module';
import qs from 'qs';
import { DeprecatedRootState } from '../../../../../../../store/state.type';

export const inputDeleteRemote = 'input-delete-remote';

export type Props = {
  isFetchBranchModalOpen: boolean;
  branch: string;
  infoFilePath: string;
  hideFetchBranchModal: () => ToBeRefined;
  gitFetchAndTrackRemoteBranch: (
    repositoryPath: string,
    branch: string
  ) => ToBeRefined;
};

export function mapStateToProps(state: DeprecatedRootState) {
  const { isFetchBranchModalOpen, fetchBranch } =
    state.workbench.showRepositoryInfo;
  const infoFilePath = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).path;
  return {
    isFetchBranchModalOpen,
    branch: fetchBranch,
    infoFilePath,
  };
}

export const mapDispatchToProps = {
  hideFetchBranchModal,
  gitFetchAndTrackRemoteBranch,
};

export default connect(mapStateToProps, mapDispatchToProps)(FetchBranchModal);
