import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiSave } from 'react-icons/fi';

export default class ButtonBar extends Component {
  render() {
    const { path, saveNotebook, content, unsavedChanges } = this.props;
    return (
      <div className={'workbench-buttons'}>
        <div
          title={'Save (Cmd+S)'}
          className={
            'workbench-button' + (unsavedChanges ? ' unsaved-changes' : '')
          }
          onClick={() => saveNotebook(path, content, 'file')}
        >
          <FiSave className={'icon save-icon'} size={'20px'} />
        </div>
      </div>
    );
  }
}

ButtonBar.propTypes = {
  path: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  unsavedChanges: PropTypes.bool,
  infoNotification: PropTypes.func.isRequired,
  showCloseConfirm: PropTypes.func.isRequired,
  closeNotebook: PropTypes.func.isRequired,
  saveNotebook: PropTypes.func.isRequired,
  paneId: PropTypes.string.isRequired,
};
