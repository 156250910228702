import React, { Component, ReactElement } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './contextMenu.scss';
import ClipboardWrapper, {
  Props as CopyProps,
} from '../../../molecules/clipboard/ClipboardWrapper';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { DeprecatedRootState } from '../../../../store/state.type';
import { Dispatch } from 'redux-act';
import { setOpenContextMenu } from '../../../../redux/workbench/modules/content.module';
import {
  ContentElement,
  ContentElementType,
} from '../generic-file-browser/GenericFileBrowser';
import onClickOutside from 'react-onclickoutside';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { RouteComponentProps } from 'react-router';
import _ from 'lodash';

export type ContextMenuProps = {
  menuId: string;
  entries: ContextMenuEntry[];
  /** Filename */
  name: string;
  /** Filepath */
  path: string;
  /** Filetype */
  type: ContentElementType;
  state: DeprecatedRootState;
  dispatch: ToBeRefined;
};

export type ContextMenuEntry = {
  /** Text to show for the entry */
  title: MessageDescriptor;
  /** Icon to show for the entry */
  icon: React.ComponentType<{ className: string }>;
  /** Optional: onClick handler */
  onClick?: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    path: string,
    name: string,
    type: ContentElementType,
    history: RouteComponentProps['history'],
    element: Omit<ContentElement, 'content'>
  ) => void;
  /** Optional: Link target */
  linkTo?: string;
  /** Optional: Copy action */
  copy?: (path: string, name: string) => CopyProps;
  isDisabled?: boolean;
};

function wrapWithClipboard(
  element: ReactElement,
  props: CopyProps
): ReactElement {
  return <ClipboardWrapper {...props}>{element}</ClipboardWrapper>;
}

function wrapWithLink(element: ReactElement, linkTo: string): ReactElement {
  return (
    <Link className={'cm-item-link'} to={linkTo}>
      {element}
    </Link>
  );
}

class ContextMenu extends Component<
  ContextMenuProps & RouteComponentProps,
  {}
> {
  handleClickOutside() {
    const { dispatch } = this.props;
    dispatch(setOpenContextMenu(null));
  }

  render() {
    const { menuId, entries, path, name, type, state, dispatch, history } =
      this.props;

    return (
      <div className={'context-menu'} id={menuId}>
        <div className={'arrow'} />
        {entries.map((e, i) => {
          let onClick = () => {
            if (e.onClick)
              e.onClick(state, dispatch, path, name, type, history, {
                path,
                name,
                type,
              });
            dispatch(setOpenContextMenu(null));
          };
          if (e.isDisabled) {
            onClick = undefined;
            e.linkTo = undefined;
            e.copy = undefined;
          }
          const Icon = e.icon;
          let entry = (
            <div
              key={i}
              data-testingidentifier={`cm-${e.title.defaultMessage}`}
              className={
                e.isDisabled
                  ? 'cm-item-container cm-item-container--disabled'
                  : 'cm-item-container'
              }
              onClick={onClick}
            >
              <Icon className={'context-menu-icon'} />
              <FormattedMessage
                id={e.title.id}
                defaultMessage={e.title.defaultMessage}
              >
                {(message) => <p className={'context-menu-text'}>{message}</p>}
              </FormattedMessage>
            </div>
          );
          if (e.copy) {
            entry = wrapWithClipboard(entry, e.copy(path, name));
          }
          if (e.linkTo) {
            entry = wrapWithLink(entry, e.linkTo);
          }
          return entry;
        })}
      </div>
    );
  }
}

export default withRouter(onClickOutside(ContextMenu));
