import { AdminResourcesState } from './state.types';

// @ts-ignore
export const initial: AdminResourcesState = {
  runningWorkbench: {
    loading: false,
    loaded: false,
    stoppingServers: [],
  },
};

// @ts-ignore
export const test: AdminResourcesState = {
  runningWorkbench: {
    loading: false,
    loaded: false,
    stoppingServers: [],
  },
};
