import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabContent } from 'react-tabs-redux';
import Workbench from '../../workbench/_entrypoint/Workbench.container';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';

export default class TabContentWorkbench extends Component {
  render() {
    const { match } = this.props;
    const isVisible = match.params.mainTab === 'workbench';

    return (
      <TabContent
        className={'main-tab-content'}
        for={'workbench'}
        isVisible={isVisible}
        renderActiveTabContentOnly
      >
        <ErrorBoundary
          errorDescription={{
            id: 'error.tab.description',
          }}
          fullScreenHeight={true}
        >
          <Workbench />
        </ErrorBoundary>
      </TabContent>
    );
  }
}
TabContentWorkbench.propTypes = {
  match: PropTypes.object,
};
