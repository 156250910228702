import { ValueType } from './TrainingTable';

export function validateTrainingTable(trainingTable: ValueType) {
  if (!trainingTable) {
    return {
      trainingTable: { id: 'newAugur.step.training_table.no_table_name' },
    };
  }

  const {
    tableName,
    labelColumn,
    primaryKeyColumns,
    positiveLabelValue,
    negativeLabelValue,
  } = trainingTable || {};
  if (!tableName) {
    return {
      trainingTable: { id: 'newAugur.step.training_table.no_table_name' },
    };
  }

  if (!primaryKeyColumns || primaryKeyColumns.length === 0) {
    return {
      primaryKeyColumns: {
        id: 'newAugur.step.training_table.no_primary_key_column',
      },
    };
  }

  if (!labelColumn) {
    return {
      labelColumn: { id: 'newAugur.step.training_table.no_label_column' },
    };
  }

  if (primaryKeyColumns.includes(labelColumn)) {
    return {
      labelColumn: {
        id: 'newAugur.step.training_table.label_must_not_be_in_primary_keys',
        defaultMessage:
          'The Label column must not be part of the primary key columns',
      },
    };
  }

  if (!positiveLabelValue || positiveLabelValue.length === 0) {
    return {
      positiveLabelValue: {
        id: 'newAugur.step.training_table.no_positive_label_value',
      },
    };
  }

  if (!negativeLabelValue || negativeLabelValue.length === 0) {
    return {
      negativeLabelValue: {
        id: 'newAugur.step.training_table.no_negative_label_value',
      },
    };
  }

  if (positiveLabelValue === negativeLabelValue) {
    return {
      positiveLabelValue: {
        id: 'newAugur.step.training_table.positive_and_negative_label_value_equal',
      },
    };
  }

  return undefined;
}
