import React, { Component } from 'react';
import styles from './styles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import classNames from 'classnames';
import { ToBeRefined } from 'common/dist/types/todo_type';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import ColumnOption, {
  ColumnOptionType,
} from '../commonComponents/columnOption/ColumnOption';
import { DatapoolType } from 'common/dist/types/datapool';
import { WithTableSampleProps } from '../commonComponents/withTableSample/WithTableSample';
import augurMsgs from 'common/dist/messages/augurs';
import InputError from '../../../../atoms/input-error/InputError';
import HttpStatusCodes from 'common/dist/constants/httpStatusCodes';
import datamanMessage from 'common/dist/messages/dataManagement';

export type ValueType = {
  tableName?: string;
  labelColumn?: string;
  primaryKeyColumns?: string[];
  positiveLabelValue?: string;
  negativeLabelValue?: string;
};

export type Props = {
  /** Selected datapoolCode */
  datapool: DatapoolType;
  /** code of the habitat to add this augur to */
  habitatCode: string;
  dropdownSelectPortal?: HTMLElement;
};

type OptionType = { label: string; value: string };

export default class TrainingTable extends Component<
  Props & WithTableSampleProps & WrappedFieldProps
> {
  componentDidUpdate(
    prevProps: Readonly<Props & WithTableSampleProps & WrappedFieldProps>
  ) {
    const {
      input: { onChange, value },
      tablesLoaded,
    } = this.props;
    // When tables are reloaded, unset the selected table, since selecting forces the load and we need to reload the preview
    if (
      !prevProps.tablesLoaded &&
      tablesLoaded &&
      value?.tableName !== undefined
    ) {
      onChange({ ...value, tableName: undefined });
    }
  }

  renderRowTrainingTable() {
    const {
      tablesLoading,
      tablesLoaded,
      tables,
      input: { value, onChange, onBlur, onFocus },
      meta: { error, touched },
    } = this.props;

    const tableOptions =
      tablesLoading || !tables
        ? []
        : tables.map((table) => ({
            label: table.name,
            value: table.name,
          }));
    return (
      <div className={classNames(styles.row, styles.rowTrainingTable)}>
        <DropdownSelectInput
          id={`trainingTable_tableName`}
          name={'trainingTable'}
          touched={touched}
          error={error?.tableName}
          valid={!error?.tableName}
          disabled={!tablesLoaded}
          label={augurMsgs.msgTrainingTableTable}
          placeholder={augurMsgs.msgTrainingTablePlaceholderTable}
          value={(tableOptions || []).find((o) => o.value === value.tableName)}
          onChange={(option: OptionType) =>
            onChange({ ...value, tableName: option.value })
          }
          onFocus={onFocus}
          onBlur={() => onBlur(value)}
          isLoading={tablesLoading}
          options={tableOptions}
          autoSelectIfSingle
        />
      </div>
    );
  }

  getColumnOptions(): ColumnOptionType[] {
    const { tables, tableSample } = this.props;

    const isLoading = tableSample?.loading;
    const columns = tableSample?.data?.colSpecs;

    return isLoading || !tables || !columns
      ? []
      : columns
          .sort((a, b) => (a.colName > b.colName ? 1 : -1))
          .map((column) => ({
            label: `${column.colName}`,
            value: column.colName,
            colName: column.colName,
            colType: column.colType,
          }));
  }

  renderRowIdColumns() {
    const {
      tableSample,
      input: { value, onChange, onBlur, onFocus },
      meta: { error, touched },
    } = this.props;

    const columnOptions = this.getColumnOptions();
    const columnsLoading = tableSample?.loading;

    return (
      <div className={classNames(styles.row, styles.rowIdColumns)}>
        <DropdownSelectInput
          id={`trainingTable_primaryKeyColumns`}
          name={'trainingTable'}
          touched={touched}
          error={error?.primaryKeyColumns}
          valid={!error?.primaryKeyColumns}
          disabled={!value.tableName}
          label={augurMsgs.msgTrainingTableIdColumns}
          placeholder={augurMsgs.msgTrainingTablePlaceholderIdColumns}
          value={columnOptions.filter((o) =>
            (value.primaryKeyColumns || []).includes(o.value)
          )}
          onChange={(options: OptionType[]) =>
            onChange({
              ...value,
              primaryKeyColumns: options.map((o) => o.value),
            })
          }
          onFocus={onFocus}
          onBlur={() => onBlur(value)}
          isLoading={columnsLoading}
          options={columnOptions}
          clearable={false}
          customComponents={{
            Option: ColumnOption,
          }}
          multi
        />
      </div>
    );
  }

  renderRowLabel() {
    const {
      tableSample,
      input: { value, onChange, onBlur, onFocus },
      meta: { error, touched },
    } = this.props;
    const columnOptions = this.getColumnOptions();
    const columnsLoading = tableSample?.loading;

    return (
      <div className={classNames(styles.row, styles.rowLabel)}>
        <DropdownSelectInput
          id={`trainingTable_labelColumn`}
          name={'trainingTable'}
          touched={touched}
          error={error?.labelColumn}
          valid={!error?.labelColumn}
          disabled={!value.tableName}
          label={augurMsgs.msgTrainingTableLabelColumn}
          placeholder={augurMsgs.msgTrainingTablePlaceholderLabelColumn}
          value={columnOptions.find((o) => o.value === value.labelColumn)}
          onChange={(option: OptionType) =>
            onChange({ ...value, labelColumn: option.value })
          }
          onFocus={onFocus}
          onBlur={() => onBlur(value)}
          isLoading={columnsLoading}
          options={columnOptions}
          clearable={true}
          customComponents={{
            Option: ColumnOption,
          }}
        />
      </div>
    );
  }

  renderRowPosNegValue() {
    const {
      input: { value, onChange, onBlur, onFocus },
      meta: { error, touched },
    } = this.props;

    return (
      <div className={classNames(styles.row, styles.rowPosNegValue)}>
        <div className={classNames(styles.colPosNegValue, styles.colPosValue)}>
          <TextInputLine
            id={`trainingTable_positiveLabelValue`}
            name={`trainingTable.positiveLabelValue`}
            touched={touched}
            error={touched && error?.positiveLabelValue}
            validating={false}
            valid={value.tableName && touched && !error?.positiveLabelValue}
            disabled={!value.tableName}
            hasLabel={true}
            labelId={augurMsgs.msgTrainingTablePositiveLabel.id}
            labelDefault={
              augurMsgs.msgTrainingTablePositiveLabel.defaultMessage
            }
            placeholderId={
              augurMsgs.msgTrainingTablePlaceholderPositiveLabel.id
            }
            placeholderDefault={
              augurMsgs.msgTrainingTablePlaceholderPositiveLabel.defaultMessage
            }
            value={value.positiveLabelValue}
            onChange={(e: ToBeRefined) =>
              onChange({ ...value, positiveLabelValue: e.target.value })
            }
            onBlur={() => onBlur(value)}
            onFocus={onFocus}
          />
        </div>

        <div className={classNames(styles.colPosNegValue, styles.colNegValue)}>
          <TextInputLine
            id={`trainingTable_negativeLabelValue`}
            name={`trainingTable.negativeLabelValue`}
            touched={touched}
            error={touched && error?.negativeLabelValue}
            validating={false}
            valid={touched && !error?.negativeLabelValue}
            disabled={!value.tableName}
            hasLabel={true}
            labelId={augurMsgs.msgTrainingTableNegativeLabel.id}
            labelDefault={
              augurMsgs.msgTrainingTableNegativeLabel.defaultMessage
            }
            placeholderId={
              augurMsgs.msgTrainingTablePlaceholderNegativeLabel.id
            }
            placeholderDefault={
              augurMsgs.msgTrainingTablePlaceholderNegativeLabel.defaultMessage
            }
            value={value.negativeLabelValue}
            onChange={(e: ToBeRefined) =>
              onChange({ ...value, negativeLabelValue: e.target.value })
            }
            onBlur={() => onBlur(value)}
            onFocus={onFocus}
          />
        </div>
      </div>
    );
  }

  render() {
    const { tableSample } = this.props;
    const error = tableSample?.error;
    return (
      <div className={styles.trainingTable}>
        {error && (
          <div className={styles.errorContainer}>
            <b>
              <InputError
                error={datamanMessage.msgErrorTablePreview.defaultMessage}
                touched={true}
              />
            </b>
            <br />
            <InputError
              // @ts-ignore
              error={error.message}
              touched={true}
            />
          </div>
        )}
        {this.renderRowTrainingTable()}
        {this.renderRowIdColumns()}
        {this.renderRowLabel()}
        {this.renderRowPosNegValue()}
      </div>
    );
  }
}
