export const collaborationSpaceRoutes = {
  basePath: '/app/collab',

  repositories: {
    path: 'repositories',

    create: 'repositories/create',
    clone: 'clone',
    merge: 'merge',
    mergeRequest: 'merge-requests',
    resolve: 'resolve',
    buildApp: 'build-app',
    buildCodeCapsule: 'build-cc',
    buildArchetype: 'build-arch',
  },

  groups: {
    path: 'groups',

    create: 'groups/create',
  },
};
