import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  postApiRequest,
} from './_tools';
import ResourceRepresentation from '@keycloak/keycloak-admin-client/lib/defs/resourceRepresentation';
import {
  cassandraResourceType,
  s3ResourceType,
} from 'common/dist/constants/keycloak';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';
import { AvailableTablesData } from '../../store/datapoolTables/slice';
import { S3Object } from '../../store/dataManagement/state.types';

// TODO replace with direct calls to data-management-api when authorization is implemented
export function fetchDataSources(): CompletedApiRequest<DataSource[]> {
  return apiRequest(`/api/data`);
}

export function fetchDataSourceSettings(dataSourceCode) {
  return apiRequest(`/api/data/datasource/${dataSourceCode}/settings`);
}

export function addDataSource(dataSource) {
  const body = {
    code: dataSource.code,
    name: dataSource.name,
    ds_type: dataSource.ds_type,
    role: dataSource.role,
    settings: dataSource.settings,
  };
  return postApiRequest(`/api/data`, body);
}

export function deleteDataSource(dataSourceCode) {
  return deleteApiRequest(`/api/data/datasource/${dataSourceCode}`);
}

export function updateDataSource(dataSourceCode, dataSource) {
  const body = {
    code: dataSource.code,
    name: dataSource.name,
    ds_type: dataSource.ds_type,
    role: dataSource.role,
    settings: dataSource.settings,
  };
  return postApiRequest(`/api/data/datasource/${dataSourceCode}/update`, body);
}

/**
 * Call directly (there's an extra ingress listening for /data) the data management api and request the
 * Cassandra Keyspaces
 * @param dataSourceCode
 * @returns {*}
 */
export function fetchCassandraKeyspaces(dataSourceCode) {
  return apiRequest(`/dataman/cassandra/${dataSourceCode}/keyspaces`);
}

export function fetchCassandraPermissions(
  dataSourceCode: string
): CompletedApiRequest<{ [resourceName: string]: string[] }> {
  const body: { resources: ResourceRepresentation[] } = {
    resources: [{ type: cassandraResourceType(dataSourceCode) }],
  };
  return postApiRequest(`/api/permissions/evaluate`, body);
}

export function fetchCassandraCredentials(
  dataSourceCode: string
): CompletedApiRequest<{
  username: string;
  password: string;
}> {
  return apiRequest(`/dataman/cassandra/${dataSourceCode}/credentials`);
}

/**
 * Call directly (there's an extra ingress listening for /data) the data management api and request the
 * Cassandra Tables for a given Keyspace
 * @param dataSourceCode
 * @param keyspaceName
 * @returns {*}
 */
export function fetchCassandraTables(
  dataSourceCode: string,
  keyspaceName: string
): CompletedApiRequest<AvailableTablesData> {
  return apiRequest(
    `/dataman/cassandra/${dataSourceCode}/keyspace/${keyspaceName}/tables`
  );
}

export function fetchCassandraTableSample(
  dataSourceCode,
  keyspaceName,
  tableName
) {
  return apiRequest(
    `/dataman/cassandra/${dataSourceCode}/keyspace/${keyspaceName}/table/${tableName}`
  );
}

export function requestCommitInfo(dataSourceCode, uploadCode) {
  return apiRequest(
    `/dataman/cassandra/${dataSourceCode}/upload/${uploadCode}/info`
  );
}

export function commitUpload(dataSourceCode, body) {
  return postApiRequest(`/dataman/cassandra/${dataSourceCode}/commit`, body);
}

export function fetchS3Preview(dataSourceCode, path) {
  return apiRequest(`/dataman/s3/${dataSourceCode}/preview/${path}`);
}

export function fetchS3Buckets(dataSourceCode) {
  return apiRequest(`/dataman/s3/${dataSourceCode}/buckets`);
}

export function fetchS3BucketContent(
  dataSourceCode: string,
  bucket: string,
  bucketPath: string
): CompletedApiRequest<S3Object[]> {
  return apiRequest(
    `/dataman/s3/${dataSourceCode}/bucket/${bucket}/${bucketPath}`
  );
}

export function fetchS3Permissions(
  dataSourceCode: string
): CompletedApiRequest<{ [resourceName: string]: string[] }> {
  const body: { resources: ResourceRepresentation[] } = {
    resources: [{ type: s3ResourceType(dataSourceCode) }],
  };
  return postApiRequest(`/api/permissions/evaluate`, body);
}
export function fetchS3Credentials(
  dataSourceCode: string
): CompletedApiRequest<{
  accessKey: string;
  secretKey: string;
}> {
  return apiRequest(`/dataman/s3/${dataSourceCode}/credentials`);
}

export function validatePath(dataSourceCode, bucket, bucketPath) {
  return apiRequest(
    `/dataman/s3/${dataSourceCode}/exists/bucket/${bucket}/${bucketPath}`
  );
}

export function deleteObject(dataSourceCode, bucket, path) {
  return deleteApiRequest(
    `/dataman/s3/${dataSourceCode}/bucket/${bucket}/${path.replace(/^\//, '')}`
  );
}

export function moveObject(
  dataSourceCode: string,
  srcBucket: string,
  srcPath: string,
  dstBucket: string,
  dstPath: string
) {
  return postApiRequest(
    `/dataman/s3/${dataSourceCode}/move/bucket/${srcBucket}/${srcPath.replace(
      /^\//,
      ''
    )}`,
    { newBucket: dstBucket, newPath: dstPath }
  );
}
