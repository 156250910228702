import React, { Component, ReactElement } from 'react';
import CassandraTablePreview from '../../../../../organisms/cassandra-table-preview/CassandraTablePreview';
import ErrorBoundary from '../../../../../pages/error-boundary/ErrorBoundary';
import { WrappedFieldProps } from 'redux-form';
import { ColSpec } from '../../../../../../store/dataManagement/state.types';
import datamanMsgs from 'common/dist/messages/dataManagement';

interface StepDataProps {
  /** List of all columns */
  colSpecs?: ColSpec[];
  /** List of data */
  data?: string[][];
  additionalInformation?: {
    defaultHeader?: boolean;
  };
}

export default class StepData extends Component<
  StepDataProps & WrappedFieldProps
> {
  constructor(props: StepDataProps & WrappedFieldProps) {
    super(props);
    this.state = { hasError: false };
  }

  render(): ReactElement {
    const { input, colSpecs, data, additionalInformation } = this.props;

    return (
      <ErrorBoundary
        errorHeadline={datamanMsgs.msgCassandraUploadCommitDataError}
      >
        <div>
          {additionalInformation && additionalInformation.defaultHeader && (
            <p>
              One or all column names have been set to a default value. You may
              want to change them.
            </p>
          )}

          <CassandraTablePreview
            colSpecs={colSpecs}
            data={data}
            editable={true}
            input={input}
          />
        </div>
      </ErrorBoundary>
    );
  }
}
