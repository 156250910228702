import { ChannelsErrorType, ChannelsValueType } from './OptChannels';
import { MessageDescriptor } from 'react-intl';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import _ from 'lodash';
import { _globalValidationError } from '../../../../modelManagement/modules/implementations/optimization/new-augur/optimization.form';
import { Channel } from 'common/dist/types/module.optimization';

export const nameRegex = /^[A-Za-z0-9 _\-]*$/;
export const descriptionRegex = /^[A-Za-z0-9 _\-/äöüÄÖÜß]*$/;

export const validateName = (
  name?: string,
  existingNames?: string[]
): MessageDescriptor | null => {
  // The name field is required
  if (!name) {
    return moduleOptimizationMessages.msgNewDatapoolStepChannelsErrNoName;
  }

  // Valid characters
  if (!nameRegex.test(name)) {
    return moduleOptimizationMessages.msgNewDatapoolStepChannelsErrNameInvalidChar;
  }

  // Max length
  if (name.length > 64) {
    return moduleOptimizationMessages.msgNewDatapoolStepChannelsErrNameLength;
  }

  // Prevent name duplicates
  if ((existingNames || []).includes(name)) {
    return moduleOptimizationMessages.msgNewDatapoolStepChannelsErrNameDuplicate;
  }

  return null;
};

export const validateDescription = (
  description?: string
): MessageDescriptor | null => {
  // The description field is optional
  if (!description) return;

  // Valid characters
  if (!descriptionRegex.test(description)) {
    return moduleOptimizationMessages.msgNewDatapoolStepChannelsErrDescriptionInvalidChar;
  }

  // Max length
  if (description.length > 256) {
    return moduleOptimizationMessages.msgNewDatapoolStepChannelsErrDescriptionLength;
  }

  return null;
};

/**
 * Validation for the "sub form": add channel
 * @param value
 */
export const validateChannels = (
  value: ChannelsValueType
): ChannelsErrorType => {
  const error = {};

  // Is there at least one channel added?
  if (!value || (value || []).length === 0) {
    error[_globalValidationError] =
      moduleOptimizationMessages.msgNewDatapoolStepChannelsErrNoChannels;
  } else {
    value?.forEach((channel) => {
      const constraintError = validateSingleChannel(channel, value);
      if (!_.isEmpty(constraintError)) {
        error[channel.id] = constraintError;
      }
    });
  }

  return cleanErrorObject(error);
};

export const validateSingleChannel = (
  channel: Partial<Channel>,
  allChannels?: Partial<Channel>[]
): ChannelsErrorType => {
  const error = {};

  // Validate name
  error['name'] = validateName(
    channel.name,
    (allChannels || []).filter((x) => x.id !== channel.id).map((ch) => ch.name)
  );
  // Validate description
  error['description'] = validateDescription(channel.description);

  return cleanErrorObject(error);
};
