import React, { FC } from 'react';
import styles from './styles.module.scss';
import Button from '../../../../../atoms/button/Button';
import classNames from 'classnames';
import { useAugur, useUpdateAugur } from '../../../../../../core/api/augurs';
import { useHistory, useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../types';
import { sendNotification } from '../../../../../../redux/modules/notifications.module';
import { event } from '../../../../../../core/notifications';
import { useDispatch } from 'react-redux';
import * as dashboardModule from '../../../../../../redux/modules/dashboard.module';

const SettingsButtons: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const { data: augur, isLoading } = useAugur(habitatCode, augurCode);
  const archived = augur?.archived;

  const { mutate: updateAugur } = useUpdateAugur(habitatCode, augurCode);
  const archiveAugur = () =>
    updateAugur(
      { archived: !archived },
      {
        onSuccess: async () => {
          if (archived) {
            // restore
            dispatch(
              sendNotification(
                'notifications.title.augur_restored',
                // @ts-ignore
                'notifications.description.augur_has_been_restored',
                event,
                { augurCode, augurName: augur.name }
              )
            );
            dispatch(
              // @ts-ignore
              dashboardModule.addAugurToHabitat(habitatCode, augurCode)
            );
          } else {
            // archive
            dispatch(
              sendNotification(
                'notifications.title.augur_archived',
                // @ts-ignore
                'notifications.description.augur_has_been_archived',
                event,
                { augurCode, augurName: augur.name }
              )
            );
            history.push('/app/models');
            dispatch(
              // @ts-ignore
              dashboardModule.removeAugurFromHabitat(habitatCode, augurCode)
            );
          }
        },
      }
    );

  const archiveRestoreIconClassName = classNames({
    'icon-archive': !archived,
    'icon-refresh': archived,
  });

  const buttonLabelId = archived
    ? 'dashboard.augur_settings.restore'
    : 'dashboard.augur_settings.archive';
  const buttonLabelDefault = archived ? 'Restore' : 'Archive';

  return (
    <div className={styles.settingsButtons}>
      <Button
        buttonColor={archived ? 'secondary' : 'orange'}
        buttonLabelId={buttonLabelId}
        buttonLabelDefault={buttonLabelDefault}
        withLink={false}
        onClick={archiveAugur}
        Icon={() => <div className={archiveRestoreIconClassName} />}
        disabled={isLoading}
      />
    </div>
  );
};

export default SettingsButtons;
