import { connect, ConnectedProps } from 'react-redux';
import MicroPostman from './MicroPostman';
import {
  realtimeRequests,
  realtimeStatus,
} from '../ActivateRealtimeToggle.container';
import { withRouter } from 'react-router-dom';
import {
  realtimeRequest,
  updateRealtimeRequestBody,
} from '../../../../../../../redux/modules/orchestration.realtime.module';
import { RootState } from '../../../../../../../store/store';
import { RouteComponentProps } from 'react-router';

export function mapStateToProps(
  state: RootState,
  { match }: RouteComponentProps<{ augurCode: string }>
) {
  const augurCode = match.params.augurCode;
  const rStatus = realtimeStatus(state, augurCode);
  const rRequest = realtimeRequests(state, augurCode);

  return {
    augurCode,
    /** Contains information about the realtime model */
    realtimeModelData: rStatus.data?.rest,
    /** Content of the textarea that allows to edit the body */
    body: rRequest?.body,
    responseLoading: rRequest?.loading,
    responseLoaded: rRequest?.loaded,
    responseData: rRequest?.data,
    responseError: rRequest?.error,
  };
}

export const mapDispatchToProps: {
  /** Function that dispatches a realtime request */
  realtimeRequest(...args: unknown[]): unknown;
  /** onChange method for the textarea */
  updateRealtimeRequestBody(...args: unknown[]): unknown;
} = {
  realtimeRequest,
  updateRealtimeRequestBody,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(MicroPostman));
