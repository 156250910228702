import { connect } from 'react-redux';
import { DeprecatedRootState } from '../../../../store/state.type';
import ContextMenu from './ContextMenu';

function mapStateToProps(state: DeprecatedRootState) {
  return {
    state,
  };
}

export default connect(mapStateToProps)(ContextMenu);
