import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import { FormattedMessage } from 'react-intl';
import {
  FiBarChart2,
  FiBook,
  FiHelpCircle,
  FiList,
  FiLogOut,
  FiServer,
  FiTerminal,
  FiTool,
  FiTrendingUp,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import GoTo from './GoTo';
import './styles.scss';

import * as routes from '../../../index/routes';
import { logout } from '../../../../../keycloak';
import auditLogMsgs from 'common/dist/messages/auditLog';
import { FrontendConfig } from 'common/dist/types/frontendConfig';
import messagesHeader from 'common/dist/messages/header';

export const logoutLink = '/logout';
export const monitoringLink = '/grafana';
export const loggingLink = '/opensearch-dashboards';
export const docuLink = '/doc/';
export const outsideClickIgnoreClass = 'profile--link';
export const accountLink = routes.app.prefix + routes.app.account;
export const adminUsersListLink = `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}`;
export const adminResourcesLink = `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.resources}`;
export const adminMaintenanceLink = `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.maintenance}`;
export const adminAuditLogLink = `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.auditLog}`;
const supersetLink = (scheme: string, subdomain: string) =>
  `${scheme}://${subdomain}.${window.location.host}/login/keycloak`;

export type Props = {
  closeMenu: () => void;
  isAdmin: boolean;
  frontendConfig?: FrontendConfig;
  showWhatsNewModal: () => void;
};

export class TopMenu extends Component<Props, {}> {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    const { closeMenu } = this.props;

    closeMenu();
  }

  render() {
    const { isAdmin, frontendConfig, showWhatsNewModal } = this.props;
    const superset = frontendConfig?.components?.superset;

    return (
      <div className='TopMenu'>
        {isAdmin && (
          <div className={'TopMenu--category'}>
            <div className={'TopMenu--category-title'}>
              <span>Admin Tools</span>
            </div>
            <ul className='TopMenu--list'>
              <li className='TopMenu--item'>
                <Link
                  className='TopMenu--link'
                  onClick={this.handleClickOutside}
                  to={adminUsersListLink}
                >
                  <FiUsers size={14} className={'TopMenu--icon'} />
                  <FormattedMessage
                    id='header.menu.adminUsersList'
                    defaultMessage='Users & Permissions'
                  />
                </Link>
              </li>
              <li className='TopMenu--item'>
                <Link
                  className='TopMenu--link'
                  onClick={this.handleClickOutside}
                  to={adminResourcesLink}
                >
                  <FiServer size={14} className={'TopMenu--icon'} />
                  <FormattedMessage
                    id='header.menu.adminResources'
                    defaultMessage='Resources'
                  />
                </Link>
              </li>
              <li className='TopMenu--item'>
                <Link
                  className='TopMenu--link'
                  onClick={this.handleClickOutside}
                  to={adminMaintenanceLink}
                >
                  <FiTool size={14} className={'TopMenu--icon'} />
                  <FormattedMessage
                    id='header.menu.backups.todo'
                    defaultMessage='Maintenance'
                  />
                </Link>
              </li>
            </ul>
            <div className={'TopMenu--category-title'}>
              <FormattedMessage
                id='header.menu.adminMonitoring'
                defaultMessage='Monitoring'
              />
            </div>
            <ul>
              <li className='TopMenu--item'>
                {/* @ts-ignore */}
                <GoTo
                  link={monitoringLink}
                  className='TopMenu--link'
                  onClick={this.handleClickOutside}
                  target={'_blank'}
                >
                  <FiTrendingUp size={14} className={'TopMenu--icon'} />
                  <FormattedMessage
                    id='header.menu.adminMetrics'
                    defaultMessage='Metrics'
                  />
                </GoTo>
              </li>
              <li className='TopMenu--item'>
                {/* @ts-ignore */}
                <GoTo
                  link={loggingLink}
                  className='TopMenu--link'
                  onClick={this.handleClickOutside}
                  target={'_blank'}
                >
                  <FiTerminal size={14} className={'TopMenu--icon'} />
                  <FormattedMessage
                    id='header.menu.adminLogging'
                    defaultMessage='Logging'
                  />
                </GoTo>
              </li>
              <li className='TopMenu--item'>
                <Link
                  to={adminAuditLogLink}
                  className='TopMenu--link'
                  onClick={this.handleClickOutside}
                >
                  <FiList size={14} className={'TopMenu--icon'} />
                  <FormattedMessage {...auditLogMsgs.msgAuditLog} />
                </Link>
              </li>
            </ul>
          </div>
        )}
        <div className={'TopMenu--category'}>
          <div className={'TopMenu--category-title'}>
            <span>Help</span>
          </div>
          <ul className='TopMenu--list'>
            <li className='TopMenu--item'>
              {/* @ts-ignore */}
              <GoTo
                link={docuLink}
                className='TopMenu--link'
                onClick={this.handleClickOutside}
                target={'_blank'}
              >
                <FiBook size={14} className={'TopMenu--icon'} />
                <FormattedMessage
                  id='header.menu.documentation'
                  defaultMessage='Documentation'
                />
              </GoTo>
            </li>
            {frontendConfig?.changelog && (
              <li className='TopMenu--item'>
                <div
                  className='TopMenu--link'
                  onClick={() => {
                    showWhatsNewModal();
                    this.handleClickOutside();
                  }}
                >
                  <FiHelpCircle size={14} className={'TopMenu--icon'} />
                  <FormattedMessage {...messagesHeader.menuWhatsNew} />
                </div>
              </li>
            )}
          </ul>
          {superset?.enabled && (
            <>
              <div className={'TopMenu--category-title'}>
                <span>Tools</span>
              </div>
              <ul className='TopMenu--list'>
                <li className='TopMenu--item'>
                  {/* @ts-ignore */}
                  <GoTo
                    link={supersetLink(superset.scheme, superset.subdomain)}
                    className='TopMenu--link'
                    onClick={this.handleClickOutside}
                    target={'_blank'}
                  >
                    <FiBarChart2 size={14} className={'TopMenu--icon'} />
                    <FormattedMessage
                      id='todo'
                      defaultMessage='Self Service Analytics'
                    />
                  </GoTo>
                </li>
              </ul>
            </>
          )}
          <div className={'TopMenu--category-title'}>
            <span>User Actions</span>
          </div>
          <ul className='TopMenu--list'>
            <li className='TopMenu--item'>
              <Link
                className='TopMenu--link'
                onClick={this.handleClickOutside}
                to={accountLink}
              >
                <FiUser size={14} className={'TopMenu--icon'} />
                <FormattedMessage
                  id='header.menu.profile'
                  defaultMessage='Profile'
                />
              </Link>
            </li>
            <li id={'LogoutButton'} className='TopMenu--item'>
              <a // Just for styling
                className='TopMenu--link'
                onClick={() => {
                  logout(frontendConfig);
                  this.handleClickOutside();
                }}
              >
                <FiLogOut size={14} className={'TopMenu--icon'} />
                <FormattedMessage
                  id='header.menu.logout'
                  defaultMessage='Logout'
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default onClickOutside(TopMenu);
