import React, { Component } from 'react';
import styles from './styles.module.scss';
import Modal from '../../../../../../../organisms/modal/Modal';
import { AvailableTables } from '../../../../../../../../store/datapoolTables/slice';
import Busy from '../../../../../../../atoms/busy/Busy';
import Checkbox from '../../../../../../../atoms/checkbox/Checkbox';
import TextInputLine from '../../../../../../../atoms/input-elements/text-input-line/TextInputLine';
import {
  JobGroupInputType,
  JobGroupTopologyType,
  JobGroupType,
  JobType,
} from 'common/dist/types/job';
import { ButtonProps } from '../../../../../../../atoms/button/Button';
import { CProps } from '../../ScoreDistribution.container';
import { AugurType } from 'common/dist/types/augur';

type Props = {
  habitatCode: string;
  augurCode: string;
  augur: AugurType;
  /** Is the run mass prediction modal shown? */
  isMassPredictionModalShown: boolean;
  /** Available tables for the given Datapool */
  availableTables?: AvailableTables;

  /** Hide the modal to run a mass prediction job */
  hideRunMassPredictionModal: () => void;
  /** Trigger an arbitrary JobGroup */
  runJobGroup: (payload: {
    jobGroup: JobGroupType | JobGroupInputType;
  }) => void;
};

type State = {
  selectedTables?: string[];
  filterQuery?: string;
};

type CombinedProps = Props & Pick<CProps, 'fetchDatapoolTables'>;

export default class RunMassPredictionModal extends Component<
  CombinedProps,
  State
> {
  constructor(props: CombinedProps) {
    super(props);
    this.state = {
      selectedTables: [],
    };
  }

  componentDidUpdate(prevProps: Readonly<CombinedProps>) {
    const { isMassPredictionModalShown: isMassPredictionModalShownPrev } =
      prevProps;
    const {
      isMassPredictionModalShown,
      fetchDatapoolTables,
      augur: { datapool },
    } = this.props;
    if (!isMassPredictionModalShownPrev && isMassPredictionModalShown) {
      // The modal was opened: Fetch the available tables
      fetchDatapoolTables({
        datapoolCode: datapool.code,
        dataSourceCode: datapool.dataSourceCode,
      });
    }
  }

  renderLoading() {
    return (
      <div className={styles.runMassPredictionModal}>
        <Busy isBusy positionAbsolute />
      </div>
    );
  }

  renderError() {
    const {
      availableTables: { error },
    } = this.props;
    return (
      <div className={styles.runMassPredictionModal}>
        {JSON.stringify(error)}
      </div>
    );
  }

  renderLoaded() {
    const {
      availableTables: { data },
    } = this.props;
    const { selectedTables, filterQuery } = this.state;

    const filteredTableNames =
      filterQuery && filterQuery.length > 0
        ? data
            .filter((table) => table.name.includes(filterQuery))
            .map((table) => table.name)
        : data.map((table) => table.name);
    filteredTableNames.sort();

    return (
      <div className={styles.runMassPredictionModal}>
        <div className={styles.filter}>
          <TextInputLine
            touched={true}
            validating={false}
            valid={false}
            disabled={false}
            hasLabel={true}
            labelDefault={'Filter'}
            placeholderDefault={'Filter Query'}
            value={filterQuery}
            onChange={(e) => this.setState({ filterQuery: e.target.value })}
            onBlur={() => {}}
            onFocus={() => {}}
          />
        </div>

        <div className={styles.tables}>
          {filteredTableNames.map((tableName) => {
            const checked = selectedTables.includes(tableName);
            return (
              <div className={styles.tableRow}>
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    if (!checked) {
                      this.setState({
                        selectedTables: [...selectedTables, tableName],
                      });
                    } else {
                      this.setState({
                        selectedTables: selectedTables.filter(
                          (t) => t !== tableName
                        ),
                      });
                    }
                  }}
                  disabled={false}
                  label={{
                    id: 'no-id',
                    defaultMessage: tableName,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderInnerModal() {
    const { availableTables } = this.props;
    if (!availableTables) return null;

    const { loading, loaded, data, error } = availableTables;
    if (loaded && data) return this.renderLoaded();
    else if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else return null;
  }

  deriveJobGroup(): JobGroupType {
    const {
      augurCode,
      augur: { name, type, archetypeVersion },
    } = this.props;
    const tableNames = this.state.selectedTables;
    tableNames.sort();

    const tmpJobName = (tableName: string) => `job_${tableName}`;

    const jobs: JobType[] = tableNames.map((tableName) => ({
      superType: 'augur',
      jobType: 'prediction',
      augurCode,
      archetypeInformation: {
        name: archetypeVersion.archetype.name,
        code: archetypeVersion.archetype.code,
        isCustom: archetypeVersion.archetype.custom,
        versionNumber: archetypeVersion.number,
      },
      overwritePredictionTable: tableName,
      moduleType: type,
      jobCode: tmpJobName(tableName),
      executionType: 'spark', // This is the execution type currently used for Python Jobs too

      priority: 'medium',
      wasInjected: false,
      addedToExecutionQueue: undefined,
      status: 'waiting',
    }));

    // Derive a linear dependency graph: One Prediction job after the other
    const jobGroupTopology: JobGroupTopologyType[] = tableNames.map(
      (tableName: string, index: number) => ({
        jobCode: tmpJobName(tableName),
        predecessors:
          index === 0 ? ([] as string[]) : [jobs[index - 1].jobCode],
        successors:
          index === jobs.length - 1
            ? ([] as string[])
            : [jobs[index + 1].jobCode],
      })
    );

    return {
      addedToPreQueue: '',
      code: 'tmp-group-01',
      name: 'Mass Prediction',
      description: 'Mass Prediction for Augur ' + name,
      trigger: 'manual',
      jobs,
      jobGroupTopology, // JobGroupTopologyType[];

      finished: undefined,
      priority: undefined,
      status: undefined,
    };
  }

  render() {
    const {
      isMassPredictionModalShown,
      hideRunMassPredictionModal,
      runJobGroup,
    } = this.props;

    const buttons: ButtonProps[] = [
      {
        withLink: false,
        buttonColor: 'primary',
        buttonLabelDefault: 'Run Job',
        disabled: this.state.selectedTables.length === 0,
        onClick: () => {
          const jobGroup = this.deriveJobGroup();
          runJobGroup({ jobGroup });
          hideRunMassPredictionModal();
        },
      },
      {
        withLink: false,
        onClick: hideRunMassPredictionModal,
        buttonColor: 'white',
        buttonLabelDefault: 'Cancel',
      },
    ];

    return (
      <Modal
        show={isMassPredictionModalShown}
        hideModal={hideRunMassPredictionModal}
        headline={{
          id: 'no-id',
          defaultMessage: 'Run Mass Prediction',
        }}
        buttons={buttons}
        alwaysFullHeight
      >
        <div>{this.renderInnerModal()}</div>
      </Modal>
    );
  }
}
