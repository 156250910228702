import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Busy from '../../atoms/busy/Busy';
import IndicatorEmpty from '../../molecules/indicator-empty/IndicatorEmpty';

export default class RealtimeSummary extends Component {
  /**
   * Fetch the summary about the realtime models once the component did mount.
   */
  componentDidMount() {
    const { fetchRealtimeModelSummary } = this.props;
    fetchRealtimeModelSummary();
  }

  renderError() {
    const { error } = this.props;
    return <span>Error: {JSON.stringify(error)}</span>;
  }

  renderLoading() {
    return <Busy isBusy />;
  }

  renderEmpty() {
    return (
      <IndicatorEmpty
        classNameImage={'realtime-empty-pic'}
        headlineId={'dummy'}
        headlineDefault={'There are no Realtime Models yet'}
        descriptionId={'dummy'}
        descriptionDefault={
          'Once a model is put active it will be displayed here'
        }
      />
    );
  }

  renderLoaded() {
    const { data } = this.props;
    return (
      <div className={'realtime-container'}>
        <FormattedMessage
          id='orchestration.realtime.headline'
          defaultMessage='Active Realtime-Models'
        >
          {(txt) => <span className='realtime-headline'>{txt}</span>}
        </FormattedMessage>
        <div className={'ct-list'}>
          <div className={'ct-headline ct-row'}>
            <div className={'ct-col ct-col-130px'}>
              <FormattedMessage
                id='common.augur_code'
                defaultMessage='Augur Code'
              />
            </div>
            <div className={'ct-col ct-col-130px'}>
              <FormattedMessage
                id='common.model_code'
                defaultMessage='Model Code'
              />
            </div>
            <div className={'ct-col ct-col-120px'}>
              <FormattedMessage id='common.status' defaultMessage='Status' />
            </div>
            <div className={'ct-col ct-col-200px'}>
              <FormattedMessage
                id='common.started_at'
                defaultMessage='Started at'
              />
            </div>
            <div className={'ct-col ct-col-flex-grow'}>
              <FormattedMessage id='common.url' defaultMessage='Url' />
            </div>
          </div>
          {data.map((line, i) => (
            <Fragment key={i}>
              <div className={'ct-row'} key={i}>
                <div className={'ct-col ct-col-130px'}>
                  <div className={'ct-value'}>{line.augurCode}</div>
                </div>
                <div className={'ct-col ct-col-130px'}>
                  <div className={'ct-value'}>{line.modelCode}</div>
                </div>
                <div className={'ct-col ct-col-120px'}>
                  <div className={'ct-value'}>{line.status}</div>
                </div>
                <div className={'ct-col ct-col-200px'}>
                  <div className={'ct-value'}>{line.started}</div>
                </div>
                <div className={'ct-col ct-col-flex-grow'}>
                  <div className={'ct-value'}>{line.url}</div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    );
  }

  renderInner() {
    const { loading, error, data } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (data.length === 0) return this.renderEmpty();
    return this.renderLoaded();
  }

  render() {
    return (
      <div className='Orchestration--content'>
        <div className={'Orchestration--inner-content'}>
          {this.renderInner()}
        </div>
      </div>
    );
  }
}

RealtimeSummary.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      augurCode: PropTypes.string,
      image: PropTypes.string,
      inputSchema: PropTypes.object,
      modelCode: PropTypes.string,
      servingType: PropTypes.string,
      started: PropTypes.string,
      status: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  fetchRealtimeModelSummary: PropTypes.func.isRequired,
};
