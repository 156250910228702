import React, { FC } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import { openFileNoCheck, WarningSize } from '../../../utils';
import { useAppDispatch } from '../../../../../store/store';
import { useSelector } from 'react-redux';
import { DeprecatedRootState } from '../../../../../store/state.type';
import { RouteComponentProps } from 'react-router';
import { FiAlertTriangle } from 'react-icons/fi';
import SelectedDirs from '../../../../molecules/selected-dirs/SelectedDirs';
import { contentPathToArray } from 'common/dist/utils/workbench/content';
import filesize from 'filesize';
import { useShowWarningOpenFile } from '../../../../../store/workbench/state.types';

export interface Props {
  path: string;
}

const WarningOpenFileWizard: FC<Props & RouteComponentProps> = (props) => {
  const { history } = props;
  const dispatch = useAppDispatch();
  const state = useSelector<DeprecatedRootState, DeprecatedRootState>(
    (state) => state
  );
  const showWarningOpenFile = useShowWarningOpenFile();
  if (showWarningOpenFile.path === undefined) {
    return <Redirect to={workbenchRoutes.basePath} />;
  }

  return (
    <div
      className={'dialog-container'}
      style={{
        paddingTop: '40px', // This wizard requires additional space in the top to look good
      }}
    >
      <div className={'dialog-inner-container'}>
        <p className={'dialog-headline'}>Open File</p>
        <div className={'step-container'}>
          <div className={'description-container'}>
            <div className={'bubble bubble-warn'}>
              <FiAlertTriangle className={'icon-x'} size={'20px'} />
            </div>
            <div className={'description'}>
              <div className={'title'}>
                Are you sure you want to open this large file (&gt;
                {filesize(WarningSize)})? It may lead to instability.
              </div>
              <SelectedDirs
                dirs={contentPathToArray(showWarningOpenFile.path)}
                highlightLastEntry
              />
            </div>
          </div>
        </div>
        <div className={'dialog-button-container'}>
          <Link to={workbenchRoutes.basePath}>
            <button
              type={'button'}
              className={'dialog-button dialog-button-neutral'}
            >
              Cancel
            </button>
          </Link>
          <Link
            to={workbenchRoutes.basePath}
            onClick={() => {
              openFileNoCheck(
                state,
                dispatch,
                history,
                showWarningOpenFile.path
              );
            }}
          >
            <button
              type={'button'}
              className={'dialog-button dialog-button-warn'}
            >
              Open
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(WarningOpenFileWizard);
