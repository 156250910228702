import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInputArea from '../../../../atoms/input-elements/text-input-area/TextInputArea';

export default class RepositoryStepDescription extends Component {
  render() {
    const {
      meta: { touched, error },
      input: { value, onChange, onFocus, onBlur },
    } = this.props;
    return (
      <div className={'GenericFormStep--field'}>
        <TextInputArea
          amountRows={5}
          touched={touched}
          error={error}
          disabled={false}
          hasLabel={true}
          label={{
            id: 'no-id',
            defaultMessage: 'Enter Description',
          }}
          placeholder={{
            id: 'no-id',
            defaultMessage: 'Enter Repository Description',
          }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onBlur={() => onBlur(value)}
          onFocus={onFocus}
        />
      </div>
    );
  }
}

RepositoryStepDescription.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
};
