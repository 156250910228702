import { connect } from 'react-redux';
import DownloadFile from './DownloadFile';
import { RootState } from '../../../../../../../../../../../store/store';
import { notebookUser } from '../../../../../../../../../../../redux/workbench/selectors/notebookUser.selector';

export function mapStateToProps(state: RootState) {
  return {
    notebookUser: notebookUser(state),
  };
}

export default connect(mapStateToProps)(DownloadFile);
