import {
  apiRequest,
  CompletedApiRequest,
  postApiRequest,
} from '../../../core/api/_tools';
import {
  BackupCreateRequest,
  BackupCreateResponse,
  BackupListResponse,
  BackupMeta,
  BackupRestoreRequest,
  TemplateCreateRequest,
  TemplateCreateResponse,
  TemplateMeta,
} from '../../../components/admin/maintenance/types';

/**
 * Get a list backups from the backup-api
 */
export function getBackups(): CompletedApiRequest<BackupListResponse> {
  return apiRequest('/backups/backups');
}

/**
 * Request a restore from the backup-api
 */
export function restoreBackup(
  bakRestore: BackupRestoreRequest
): CompletedApiRequest<BackupMeta[]> {
  return postApiRequest('/backups/backup/restore', bakRestore);
}

/**
 * Request to create a backup from the backup-api
 */
export function createBackup(
  bakCreate: BackupCreateRequest
): CompletedApiRequest<BackupCreateResponse> {
  return postApiRequest('/backups/backup/create', bakCreate);
}

/**
 * Get a list of templates from the backup-api
 */
export function getTemplates(): CompletedApiRequest<TemplateMeta[]> {
  return apiRequest('/backups/templates');
}

/**
 * Request to create a template from the backup-api
 */
export function createTemplate(
  templateRestore: TemplateCreateRequest
): CompletedApiRequest<TemplateCreateResponse> {
  return postApiRequest('/backups/template/create', templateRestore);
}
