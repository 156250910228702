import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabContent } from 'react-tabs-redux';
import DataManagement from '../../dataManagement/DataManagement.container';
import { Switch, Route } from 'react-router-dom';
import { dataManagementRoutes } from '../../dataManagement/routes';
import AddUpdateDataSourceContainer from '../../dataManagement/manage-ds/change-ds/AddUpdateDataSource.container';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';

export default class TabContentDataMan extends Component {
  render() {
    const { match, isAdmin } = this.props;
    const isVisible = match.params.mainTab === 'data';

    return (
      <TabContent
        className={'main-tab-content'}
        for={'data'}
        isVisible={isVisible}
        renderActiveTabContentOnly
      >
        <ErrorBoundary
          errorDescription={{
            id: 'error.tab.description',
          }}
          fullScreenHeight={true}
        >
          <div>
            <Switch>
              {/* Add DataSource Wizard */}
              {isAdmin && (
                <Route
                  path={`${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}/add`}
                  render={() => <AddUpdateDataSourceContainer update={false} />}
                />
              )}

              {/* Update DataSource Wizard */}
              {isAdmin && (
                <Route
                  path={`${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}/:dataSourceCode`} // TODO
                  render={(routeProps) => (
                    <AddUpdateDataSourceContainer
                      update={true}
                      dataSourceCode={routeProps.match.params.dataSourceCode}
                    />
                  )}
                />
              )}

              {/* The regular DataManagement screen with the Tabs on the left */}
              <Route path={'/app/data'} component={DataManagement} />
            </Switch>
          </div>
        </ErrorBoundary>
      </TabContent>
    );
  }
}
TabContentDataMan.propTypes = {
  match: PropTypes.object,
  isAdmin: PropTypes.bool,
};
