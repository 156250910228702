import PropTypes from 'prop-types';
import React from 'react';
import { Motion, spring } from 'react-motion';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';
import { kpiOptionsGrouped } from 'common/dist/constants/keyPerformanceIndicators';
import styles from './styles.module.scss';
import * as drift from '../../../core/common/drift';

function Tooltip(props) {
  function renderTooltip(value) {
    const {
      tooltipX,
      tooltipY,
      top,
      left,
      containerHeight,
      tooltipData,
      selectedKpi,
    } = props;
    const topPadding = 20;
    const height = 30;

    const translateX = `${tooltipX - left}`;
    const translateY = `${
      tooltipY - top - containerHeight - height - topPadding
    }`;
    const translate = `translate(${translateX}px, ${translateY}px) translate(-50%, 0)`;

    const kpiFormatter = kpiOptionsGrouped[selectedKpi];

    const formattedKpiValue =
      kpiFormatter && drift.getDrift(tooltipData)
        ? kpiFormatter.format(drift.getDrift(tooltipData))
        : '';

    const formattedThreshold =
      kpiFormatter && drift.getThreshold(tooltipData)
        ? `(${kpiFormatter.format(drift.getThreshold(tooltipData))})`
        : '';

    return (
      <div
        className={styles.tooltip}
        style={{
          transform: `${translate} scale(${value.scale}, ${value.scale})`,
        }}
      >
        <div className={styles.tooltipGrid}>
          <FormattedMessage
            id={`kpi.${selectedKpi}`}
            defaultValue={selectedKpi}
          >
            {(text) => <span>{text}:</span>}
          </FormattedMessage>
          <span>
            {formattedKpiValue} {formattedThreshold}
          </span>

          <span>Evaluation Date:</span>
          <FormattedDate value={tooltipData.time}>
            {(text) => <span>{text}</span>}
          </FormattedDate>
        </div>
      </div>
    );
  }

  const { isTooltip, animate } = props;

  if (!isTooltip) {
    return null;
  }

  if (!animate) {
    return renderTooltip({ scale: 1 });
  }

  return (
    <Motion defaultStyle={{ scale: 0 }} style={{ scale: spring(1) }}>
      {(value) => renderTooltip(value)}
    </Motion>
  );
}

Tooltip.propTypes = {
  isTooltip: PropTypes.bool.isRequired,
  tooltipX: PropTypes.number.isRequired,
  tooltipY: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  containerHeight: PropTypes.number.isRequired,
  tooltipData: PropTypes.any.isRequired,
  animate: PropTypes.bool.isRequired,
  selectedKpi: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  animate: true,
};

export default injectIntl(Tooltip);
