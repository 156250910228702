import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { event, select, selectAll } from 'd3-selection';
import * as _ from 'lodash';
import { brushX } from 'd3-brush';
import { Domain } from './SelectableTimeline';
import styles from './styles.module.scss';
import { ScaleLinear } from 'd3-scale';
import vars from '../../../../scss/base/var.module.scss';

type Props = {
  xScale: ScaleLinear<number, number>;
  height: number;
  width: number;
  selection?: Domain;
  onChange: (domain: Domain) => void;
};

class Brush extends Component<Props> {
  svgRef = React.createRef();

  setSvgRef = (element) => {
    this.svgRef = element;
  };

  constructor(props) {
    super(props);

    this.setSvgRef = this.setSvgRef.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { height, width, selection, xScale } = this.props;
    this.renderBrush({ height, width, selection, xScale });
  }

  shouldComponentUpdate(props) {
    this.renderBrush(props);
    return false;
  }

  handleChange(selection) {
    const { onChange, xScale, width } = this.props;
    const selectedDomain = selection
      ? [xScale.invert(selection[0]), xScale.invert(selection[1])]
      : [xScale.invert(0), xScale.invert(width)];

    // @ts-ignore
    onChange(selectedDomain);
  }

  renderBrush({ height, width, selection, xScale }) {
    if (!this.svgRef) return null;

    // @ts-ignore
    const node = ReactDOM.findDOMNode(this.svgRef);

    const handleWidth = 10;
    const handleHeight = 30;
    const roundCornerRadius = handleWidth / 2;

    const brush = brushX()
      .extent([
        [0, 0],
        [width, height],
      ])
      .handleSize(handleWidth);

    // @ts-ignore
    select(node).call(brush);

    if (!_.isEmpty(selection)) {
      // @ts-ignore
      brush.move(select(node), [xScale(selection[0]), xScale(selection[1])]);
    } else {
      // @ts-ignore
      brush.move(select(node), [0, 0]);
    }

    selectAll('.handle')
      .attr('fill', 'white')
      .attr('stroke', vars.colorPrimary)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr(
        'transform',
        `translate(0, ${height / 2 - handleHeight / 2 + roundCornerRadius})`
      )
      .attr('height', handleHeight);

    brush.on('start brush end', () => {
      this.handleChange(event.selection);
      selectAll('.handle').attr('height', handleHeight);
    });
  }

  render() {
    return <g ref={this.setSvgRef} className={styles.brush} />;
  }
}

export default Brush;
