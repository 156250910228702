import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputElementShape } from '../_interface/P3InputElementShape';
import SelectableScatterPlot from './plot/SelectableScatterPlot';
import { getValueFromSettingsField } from '../_interface/input-element.form';

export default class SelectableScatterPlotElement extends Component {
  render() {
    const { path, cellId, updateDataOfInputElement, element, cell } =
      this.props;

    // --- Get the user settings input for the categories
    const categoriesField = element.settings
      ? element.settings.categories
      : undefined;
    const categories = getValueFromSettingsField(
      categoriesField,
      cell.as_variables
    );

    // --- Get the user settings input for the data elements
    const dataField = element.settings ? element.settings.data : undefined;
    const data = getValueFromSettingsField(dataField, cell.as_variables);
    let parsedData = [];
    if (typeof data === 'object' && data !== null) {
      // Data is already an object - no need to parse it
      parsedData = data;
    } else {
      // Data is string - try to parse it
      try {
        const parsed = JSON.parse(data);
        if (Array.isArray(parsed)) {
          parsedData = parsed;
        }
      } catch (e) {
        console.log('Exception', e);
      }
    }
    const splitCategories = categories ? categories.split('\n') : [];

    // --- Get the user settings input for the color
    const colorsField = element.settings ? element.settings.colors : undefined;
    const colors = getValueFromSettingsField(colorsField, cell.as_variables);
    const splitColors = colors ? colors.split('\n') : [];
    const colorsMap = {};
    splitCategories.forEach((category, i) => {
      if (splitColors.length >= i + 1) {
        colorsMap[category] = splitColors[i];
      }
    });

    // --- Get the user settings input for the color for the unlabelled points
    const defaultCategory = ((element.settings || {}).defaultCategory || {})
      .inputValue;

    // --- Get the active category
    const activeCategory =
      element.data.category ||
      (splitCategories && splitCategories.length > 0 ? splitCategories[0] : '');

    return (
      <div className={'inner-input-element ssp-input-element'}>
        <SelectableScatterPlot
          categories={splitCategories}
          selectedCategory={activeCategory}
          selectCategory={(category) =>
            updateDataOfInputElement(path, cellId, element, { category })
          }
          data={parsedData}
          selection={element.data.selection || []}
          updateSelection={(selection) =>
            updateDataOfInputElement(path, cellId, element, { selection })
          }
          colorsMap={colorsMap}
          defaultCategory={defaultCategory}
        />
      </div>
    );
  }
}

SelectableScatterPlotElement.propTypes = {
  path: PropTypes.string.isRequired,
  cellId: PropTypes.string.isRequired,
  /** Function to update the user input */
  updateDataOfInputElement: PropTypes.func.isRequired,
  /** Input element (including the settings + the user input) */
  element: inputElementShape,
  cell: PropTypes.shape({}).isRequired,
};
