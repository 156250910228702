import React, { FC, Fragment, useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import { FormattedMessage } from 'react-intl';
import Paging, {
  PagingProps,
} from '../../../../../../../molecules/paging/Paging';
import AllScoringRunsList from './AllScoringRunsList';
import _ from 'lodash';
import Button from '../../../../../../../atoms/button/Button';
import { scoreDistributionUrl } from '../../ScoreDistribution.wrapper';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { CProps } from '../../ScoreDistribution.container';
import ReactLoading from 'react-loading';
import { AugurDetailsRouteParams } from '../../../types';
import { useReports } from '../../../../../../../../core/api/reports';
import { UseQueryResult } from 'react-query';
import { PredictionReportType } from 'common/dist/types/reports';
import vars from '../../../../../../../../../scss/base/var.module.scss';

const PAGE_SIZE = 20;

type Props = {
  /** Code of the active model for this augur */
  activeModelCode?: string;
};

export const scoreDistributionCompareUrl = (
  habitatCode?: string,
  augurCode?: string,
  moduleType?: string
): string => {
  return `${scoreDistributionUrl(habitatCode, augurCode, moduleType)}/compare`;
};

const AllScoringRuns: FC<
  Props & Pick<CProps, 'showRunMassPredictionModal'>
> = ({ activeModelCode, showRunMassPredictionModal }) => {
  const location = useLocation();
  const { habitatCode, augurCode, moduleType } =
    useParams<AugurDetailsRouteParams>();

  const { reportCodes: reportCodesQueryStr, modelCode } =
    qs.parse(location.search, { ignoreQueryPrefix: true }) ||
    ({} as { reportCodes; modelCode });
  const initialReportCodes = (reportCodesQueryStr || '')
    .split(',')
    .filter((x) => x && x.length > 0);
  const [selectedReports, selectReports] = useState(initialReportCodes);

  // reset selected reports on selected model change
  useEffect(() => selectReports([]), [modelCode]);

  const useData = (offset) =>
    (
      useReports(
        habitatCode,
        augurCode,
        modelCode,
        'prediction',
        offset,
        PAGE_SIZE
      ) as UseQueryResult<PredictionReportType[]>
    ).data ?? [];

  const renderHeader = (
    currentPage: number,
    offset: number,
    limit: number,
    totalItemsOrCurrentItems: number
  ) => {
    return (
      <div className={styles.scoreDistributionPagingHeader}>
        <div>
          <div className={styles.scoreDistributionOverviewHeadline}>
            <FormattedMessage
              id={'no-id'}
              defaultMessage={'All Prediction Runs'}
            />
          </div>

          {totalItemsOrCurrentItems > 0 && (
            <span>
              {`Showing entries ${offset + 1} - ` +
                `${offset + totalItemsOrCurrentItems}`}
            </span>
          )}
        </div>

        <div className={styles.scoreDistributionOverviewButtons}>
          <Button
            withLink={false}
            buttonColor={'secondary'}
            buttonLabelDefault={'Run Mass Prediction'}
            onClick={showRunMassPredictionModal}
          />
        </div>
      </div>
    );
  };

  const Inner = ({ offset }: PagingProps) => {
    const {
      data: reports,
      error,
      isLoading,
      isError,
    } = useReports(
      habitatCode,
      augurCode,
      modelCode,
      'prediction',
      offset,
      PAGE_SIZE
    ) as UseQueryResult<PredictionReportType[]>;

    const renderList = () => {
      if (isLoading)
        return (
          <div className={styles.listLoading}>
            <ReactLoading
              className={'starting-stopping-spinner'}
              type={'cylon'}
              color={vars.colorPrimary}
            />
          </div>
        );
      else if (isError)
        return <div className={styles.listError}>{JSON.stringify(error)}</div>;
      else
        return (
          <AllScoringRunsList
            data={reports}
            activeModelCode={activeModelCode}
            selectedReports={selectedReports}
            selectReports={selectReports}
          />
        );
    };

    const renderFooter = () => {
      return (
        <div className={styles.allScoringRunsFooter}>
          <div className={styles.footerSelectLine}>
            <div className={styles.amountSelected}>
              {(selectedReports || []).length === 1 && (
                <span>1 report selected</span>
              )}
              {(selectedReports || []).length > 1 && (
                <span>{(selectedReports || []).length} reports selected</span>
              )}
            </div>

            {(selectedReports || []).length > 0 && (
              <div
                className={styles.deselectAll}
                onClick={() => selectReports([])}
              >
                Clear selection
              </div>
            )}

            <div
              className={styles.selectAll}
              onClick={() => {
                const newValues = [
                  ...selectedReports,
                  ...reports.map((report) => report.code),
                ];
                selectReports(_.uniq(newValues));
              }}
            >
              Select All
            </div>
          </div>

          <div className={styles.footerButtonLine}>
            <Button
              buttonLabelDefault={'Compare Distribution'}
              buttonColor={'secondary'}
              withLink
              disabled={(selectedReports || []).length === 0}
              linkTo={
                scoreDistributionCompareUrl(
                  habitatCode,
                  augurCode,
                  moduleType
                ) +
                qs.stringify(
                  { reportCodes: selectedReports },
                  { addQueryPrefix: true, arrayFormat: 'comma' }
                )
              }
            />
          </div>
        </div>
      );
    };

    return (
      <>
        <div className={styles.allScoringRunsList}>{renderList()}</div>
        {renderFooter()}
      </>
    );
  };

  return (
    <div className={styles.allScoringRunsContainer}>
      {
        <Fragment>
          <Paging
            itemsPerPage={PAGE_SIZE}
            showFirstLast
            Headline={renderHeader}
            useData={useData}
            InnerComponent={Inner}
          />
        </Fragment>
      }
    </div>
  );
};

export default AllScoringRuns;
