import React, { ComponentType, FC } from 'react';
import styles from '../styles.module.scss';
import classNames from 'classnames';

type Props = {
  gridColumnStart: number;
  gridColumnEnd: number;
  gridRowStart: number;
  gridRowEnd: number | string;
  Icon: ComponentType;
  name: string;
  /** For example the habitat name in the augur details */
  subName?: string;
  showHealthBar?: boolean;
  health?: 0 | 1 | 2 | 3 | 4 | undefined;
};

function getHealthClass(health: 0 | 1 | 2 | 3 | 4 | undefined): string {
  switch (health) {
    case 0:
      return styles.health0;
    case 1:
      return styles.health1;
    case 2:
      return styles.health2;
    case 3:
      return styles.health3;
    case 4:
      return styles.health4;
    default:
      return styles.healthNone;
  }
}

const NameAndIcon: FC<Props> = (props: Props) => {
  const {
    Icon,
    gridColumnStart,
    gridColumnEnd,
    gridRowStart,
    gridRowEnd,
    name,
    subName,
    showHealthBar,
    health,
  } = props;

  return (
    <div
      className={styles.nameAndIcon}
      style={{
        gridColumnStart,
        gridColumnEnd,
        gridRowStart,
        gridRowEnd,
      }}
    >
      <div className={styles.iconContainer}>
        <Icon />
      </div>

      <div className={styles.nameContainer}>
        <div className={styles.name}>{name}</div>

        {subName && <div className={styles.subName}>{subName}</div>}
      </div>

      {showHealthBar && (
        <div className={classNames(styles.healthBar, getHealthClass(health))} />
      )}
    </div>
  );
};

export default NameAndIcon;
