import React, { FC } from 'react';
import ElementCard, {
  Props as ElementCardProps,
} from '../../molecules/element-card/ElementCard';
import ElementCardButtonOnly, {
  Props as ElementCardButtonOnlyProps,
} from '../../molecules/element-card-button-only/ElementCardButtonOnly';
import styles from './styles.module.scss';
import CollapsibleArea from '../../molecules/collapsable-area/CollapsibleArea';

export type CardOrButtonType =
  | (ElementCardProps & { type: 'card' })
  | (ElementCardButtonOnlyProps & { type: 'button' });

export type Props = {
  elements: CardOrButtonType[];
  cardsBeforeCollapsing?: number;
  collapsedHeight?: number;
};

const ElementCardGrid: FC<Props> = (props: Props) => {
  const { elements, cardsBeforeCollapsing, collapsedHeight } = props;

  const renderInner = () => {
    return (
      <div className={styles.elementCardGrid}>
        {elements.map((elementProps, i) => {
          if (elementProps.type === 'card') {
            return (
              <ElementCard
                key={i}
                addlClassName={styles.elementCardGridChild}
                {...elementProps}
              />
            );
          } else if (elementProps.type === 'button') {
            return <ElementCardButtonOnly key={i} {...elementProps} />;
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  if (!elements) {
    return null;
  } else if (elements.length > cardsBeforeCollapsing) {
    return (
      <CollapsibleArea
        collapsedHeight={collapsedHeight}
        contentStyle={{
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderRadius: 0,
        }}
        childrenStyle={{
          paddingBottom: '20px',
        }}
      >
        {renderInner()}
      </CollapsibleArea>
    );
  } else {
    return renderInner();
  }
};

ElementCardGrid.defaultProps = {
  cardsBeforeCollapsing: 8,
  collapsedHeight: 312, // 2 * 130px for the element card + 2 * 20px grid-gap + 12 px as half of the collapse button
};

export default ElementCardGrid;
