export const initial = {
  perHabitatCode: {},
  perCode: {},
  executionsPerCode: {},
};

export const test = {
  perHabitatCode: {},
  perCode: {},
  executionsPerCode: {},
};
