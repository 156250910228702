import React, { Component, Fragment } from 'react';
import ScoreBar from './ScoreBar';
import ReactLoading from 'react-loading';
import { CProps } from './MicroPostman.container';
import { RouteComponentProps } from 'react-router';
import vars from '../../../../../../../../scss/base/var.module.scss';
import Button from '../../../../../../atoms/button/Button';

const typeExampleValueMapping = (colType) => {
  if (!colType) return undefined;

  const lowerColType = colType.toLowerCase();
  switch (lowerColType) {
    case 'int':
      return 0;
    case 'int32':
      return 0;
    case 'int64':
      return 0;

    case 'double':
      return 0.0;
    case 'float32':
      return 0.0;
    case 'float64':
      return 0.0;

    case 'string':
      return '';

    case 'timestamp':
      return '1900-01-01 00:00:00';

    default:
      return undefined;
  }
};

/**
 * Takes the inputSchema of a realtime model and transforms it to a request with dummy data
 * @param inputSchema
 */
function inputSchemaToRequest(inputSchema) {
  if (!inputSchema) return '';
  const mappedCols = {};
  inputSchema.forEach((e) => {
    const exampleValue = typeExampleValueMapping(e.colType);
    mappedCols[e.colName] = exampleValue;
  });
  return { data: mappedCols };
}

const regExpCurly = /[{}]/gm;
const regExpEndOfLine = /",\n/gm;
const regexp = /[",]/gm;

/**
 * Prettifies JSON output
 * @param str
 * @returns {string}
 */
const prettify = (str = '') => {
  let newStr = str.replace(regExpCurly, '');
  newStr = newStr.replace(regExpEndOfLine, '\n');
  return newStr.replace(regexp, '');
};

export interface Props {}

type CombinedProps = Props & CProps & RouteComponentProps;

class MicroPostman extends Component<Props & CProps & RouteComponentProps> {
  static defaultProps = {
    body: '',
  };

  componentDidMount() {
    this.initiallyFillRequestBody();
  }

  componentDidUpdate(prevProps: CombinedProps) {
    const { realtimeModelData } = this.props;
    if (
      !prevProps.realtimeModelData || // inputSchema is set for the first time
      realtimeModelData.inputSchema !== prevProps.realtimeModelData.inputSchema
    ) {
      // or inputSchema changed
      this.initiallyFillRequestBody();
    }
  }

  initiallyFillRequestBody() {
    const { augurCode, realtimeModelData, updateRealtimeRequestBody } =
      this.props;
    const inputSchema = realtimeModelData ? realtimeModelData.inputSchema : '';
    const initialRealtimeRequestBody = JSON.stringify(
      inputSchemaToRequest(inputSchema),
      null,
      2
    );
    updateRealtimeRequestBody(augurCode, initialRealtimeRequestBody);
  }

  renderResponseError() {
    const { responseError } = this.props;
    // Fallback TODO handle the error from the orchestration
    return (
      <span className={'response-error'}>{JSON.stringify(responseError)}</span>
    );
  }

  renderResponseLoading() {
    return <ReactLoading type={'cylon'} color={vars.colorPrimary} />;
  }

  renderResponseLoaded() {
    const { responseData } = this.props;
    return (
      <Fragment>
        {Object.keys(responseData || {}).includes('prediction') && (
          <div className={'response-row'}>
            <div className={'response-title'}>Prediction:</div>
            <div className={'response-value'}>{responseData?.prediction}</div>
          </div>
        )}
        {Object.keys(responseData || {}).includes('p1') && (
          <div className={'response-row'}>
            <div className={'response-title'}>Score for class 1</div>
            <div className={'response-value'}>
              <Fragment>
                <ScoreBar width={300} height={20} score={responseData.p1} />
                <span className={'score-plain'}>
                  {responseData.p1 ? responseData.p1.toFixed(4) : ''}
                </span>
              </Fragment>
            </div>
          </div>
        )}

        {responseData && (
          <div className={'response-row response-row-full-json'}>
            <span className={'response-title'}>Response:</span>
            <div className={'response-value'}>
              {JSON.stringify(responseData || {}, null, 2)}
            </div>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      augurCode,
      realtimeModelData,
      realtimeRequest,
      updateRealtimeRequestBody,
      body,
      responseLoading,
      responseLoaded,
      responseError,
    } = this.props;

    // TODO typically realtime/score/augur/:augurCode
    const url = `/orchestration/${realtimeModelData?.url}`;

    return (
      <div className={'micro-postman-container'}>
        <div className={'micro-postman-request'}>
          <div className={'micro-postman-headline'}>
            <div className={'http-method'}>
              <span>POST</span>
            </div>
            <div className={'http-url'}>
              <span>{`${
                window.location.hostname +
                (window.location.port ? ':' + window.location.port : '')
              }${url}`}</span>
            </div>
            <Button
              buttonColor={'secondary'}
              withLink={false}
              buttonLabelDefault={'Send'}
              onClick={() => {
                try {
                  const parsedBody = JSON.parse(body);
                  realtimeRequest(augurCode, url, parsedBody);
                } catch (err) {
                  // This means the parsing didn't work -> Would be worth an error notification
                }
              }}
            />
          </div>
          <textarea
            className={'micro-postman-body'}
            name={'body'}
            value={body}
            onChange={(e) =>
              updateRealtimeRequestBody(augurCode, e.target.value)
            }
          />
        </div>
        <div className={'micro-postman-response'}>
          {responseLoaded && this.renderResponseLoaded()}
          {responseLoading && this.renderResponseLoading()}
          {responseError && this.renderResponseError()}
        </div>
      </div>
    );
  }
}

export default MicroPostman;
