import { connect } from 'react-redux';
import TemplatesPage from './TemplatesPage';
import { DeprecatedRootState } from '../../../../store/state.type';
import { fetchTemplates } from '../../../../store/admin/backups/actions';

export enum TemplateMetaOrdering {
  LastCreated,
  OldestCreated,
  NameAsc,
  NameDesc,
}

export const TemplateMetaOrderings = [
  { label: 'Last Created', value: TemplateMetaOrdering.LastCreated },
  { label: 'Oldest Created', value: TemplateMetaOrdering.OldestCreated },
  { label: 'Name A-Z', value: TemplateMetaOrdering.NameAsc },
  { label: 'Name Z-A', value: TemplateMetaOrdering.NameDesc },
];

function mapStateToProps(state: DeprecatedRootState) {
  return {
    TemplatesMeta: state.adminBackups.templates,
  };
}

const mapDispatchToProps = {
  fetchTemplates,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesPage);
