import { createAction } from 'redux-act';
import { put, call, takeLatest, delay } from 'redux-saga/effects';
import * as Api from '../../core/api/workbench/collab';
import { fieldName } from '../../components/collaborationSpace/wizards/repository-add/repository.form';

export const checkRepoNameExists = createAction(
  'check whether repo with that name exists',
  (repoName, callbacks) => ({ repoName, callbacks })
);

export const checkRepoNameExistsSuccess = createAction(
  'check whether repo with that name exists - success',
  (result) => result
);

export const checkRepoNameExistsFail = createAction(
  'check whether repo with that name exists - fail',
  (fail) => fail
);

export const reducer = {
  [checkRepoNameExists]: (state, { repoName }) => ({
    ...state,
    newRepo: {
      ...state.newRepo,
      repoName: {
        repoName,
        checking: true,
      },
    },
  }),
  [checkRepoNameExistsSuccess]: (state, { exists }) => ({
    ...state,
    newRepo: {
      ...state.newRepo,
      repoName: {
        ...state.newRepo.repoName,
        checking: false,
        checked: true,
        exists,
      },
    },
  }),
  [checkRepoNameExistsFail]: (state, error) => ({
    ...state,
    newRepo: {
      ...state.newRepo,
      repoName: {
        ...state.newRepo.repoName,
        checking: false,
        checked: false,
        error,
      },
    },
  }),
};

export function* checkRepoNameExistsSaga({
  payload: {
    repoName,
    callbacks: { resolve, reject },
  },
}) {
  if (!repoName) {
    reject({ [fieldName]: { id: 'newRepo.stepOne.error.missing_repo_name' } });
    return;
  }

  const { response, error } = yield call(Api.getRepositoryNameExists, repoName);
  if (response) {
    const { exists } = response;
    yield put(checkRepoNameExistsSuccess({ exists }));
    resolve({ exists });
  } else {
    yield put(checkRepoNameExistsFail(error));
    reject(error);
  }
}

export function* watchCheckRepoNameExists() {
  yield takeLatest(checkRepoNameExists.getType(), checkRepoNameExistsSaga);
}
