import DiskUsage from './DiskUsage';
import { connect } from 'react-redux';
import { DeprecatedRootState } from '../../../store/state.type';
import { getDiskUsage } from '../../../redux/workbench/modules/diskUsage.module';

export function mapStateToProps(state: DeprecatedRootState) {
  const { loading, loaded, error, data } = state.workbench.diskUsage || {};
  return {
    loading,
    loaded,
    error,
    data,
  };
}

export const mapDispatchToProps = {
  getDiskUsage,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiskUsage);
