import React, { FC, ComponentType } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styles from '../styles.module.scss';
import classNames from 'classnames';

type Props = {
  /** Column in the grid layout */
  gridColumn: number;
  /** Row in the grid layout */
  gridRow: number;
  /** Icon for the action button */
  Icon: ComponentType;
  /** Label of the button */
  label: MessageDescriptor;
};

const ActionButtonLoading: FC<Props> = (props: Props) => {
  const { gridColumn, gridRow, Icon, label } = props;

  return (
    <div
      className={styles.actionButton}
      style={{
        gridColumn,
        gridRow,
      }}
    >
      <div className={styles.actionButtonLink}>
        <div className={styles.actionButtonIcon}>
          <Icon />
        </div>
        <FormattedMessage {...label}>
          {(text) => (
            <span
              className={classNames(styles.actionButtonText, styles.loading)}
            >
              {text}
            </span>
          )}
        </FormattedMessage>
      </div>
    </div>
  );
};

export default ActionButtonLoading;
