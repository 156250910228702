import { connect } from 'react-redux';
import Groups from './Groups';
import { loadGroups } from '../../../../redux/modules/admin.users.module';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  const { data, loading, loaded, error } = state.admin.groups;
  return {
    data,
    loading,
    loaded,
    error,
  };
}

export const mapDispatchToProps = {
  loadGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
