import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { FiRefreshCw } from 'react-icons/fi';
import PullConflictsModal from './PullConflictsModal';
import vars from '../../../../../../../scss/base/var.module.scss';
import IconButton from '../../../../../atoms/icon-button/IconButton';
import Button from '../../../../../atoms/button/Button';

export interface Props {
  loading?: boolean;
  loaded?: boolean;
  data?: {
    message: string;
    committed_date: string;
    committer: string;
    hexsha: string;
  }[];
  error?: string;
  repositoryPath: string;
  activeBranch: string;
  gitListNotPulledCommits(...args: unknown[]): unknown;
  gitPull(...args: unknown[]): unknown;
  gitListCommits(...args: unknown[]): unknown;
  pullConflictsModal?: {
    show?: boolean;
    error?: string;
    branch?: string;
    conflicts?: string[];
  };
  hidePullConflictsModal(...args: unknown[]): unknown;
}

export default class GitNotPulled extends Component<Props> {
  renderLoaded() {
    const {
      data,
      repositoryPath,
      gitListNotPulledCommits,
      activeBranch,
      gitPull,
    } = this.props;
    return (
      <div className={'git-list-not-pulled-commits'}>
        <IconButton
          Icon={FiRefreshCw}
          size={16}
          rounded
          additionalClassName={'refresh-button'}
          onClick={() =>
            gitListNotPulledCommits(repositoryPath, activeBranch, false)
          }
        />

        <div className={'git-unpulled-status'}>
          {data && data.length > 0 ? (
            data.length > 1 ? (
              <span>There are {data.length} unpulled commits.</span>
            ) : (
              <span>There is {data.length} unpulled commit.</span>
            )
          ) : (
            <span>There are no unpulled commits.</span>
          )}
        </div>

        <div className={'git-button-bar'}>
          <Button
            buttonColor={'primary'}
            withLink={false}
            buttonLabelDefault={'Pull'}
            disabled={!data?.length}
            onClick={() => gitPull(repositoryPath, activeBranch)}
          />
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className={'git-list-commits'}>
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={vars.colorPrimary}
        />
      </div>
    );
  }

  renderEmpty() {
    return (
      <div className={'git-list-commits'}>
        There are no commits for this repository yet
      </div>
    );
  }

  renderError() {
    const { error, repositoryPath, gitListCommits, activeBranch } = this.props;
    return (
      <div className={'git-list-commits'}>
        {error}

        <IconButton
          Icon={FiRefreshCw}
          size={16}
          rounded
          additionalClassName={'refresh-button'}
          onClick={() => gitListCommits(repositoryPath, activeBranch, 20, 0)}
        />
      </div>
    );
  }

  renderComponent() {
    const { loading, loaded, error } = this.props;
    if (loading) return this.renderLoading();
    if (loaded) return this.renderLoaded();
    if (error) return this.renderError();
    return this.renderEmpty();
  }

  render() {
    const {
      pullConflictsModal,
      hidePullConflictsModal,
      repositoryPath,
      gitPull,
    } = this.props;
    return (
      <>
        {this.renderComponent()}

        <PullConflictsModal
          show={pullConflictsModal?.show}
          error={pullConflictsModal?.error}
          branch={pullConflictsModal?.branch}
          conflicts={pullConflictsModal?.conflicts}
          repositoryPath={repositoryPath}
          hidePullConflictsModal={hidePullConflictsModal}
          gitPull={gitPull}
        />
      </>
    );
  }
}
