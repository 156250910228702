import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

import Logo from '../../../atoms/icons/logo/Logo';
import ProfileMenuButtonContainer from '../profile-menu-button/ProfileMenuButton.container';
import TopMenu from '../profile-menu/TopMenu.container';
import SearchBar from '../search-bar/SearchBar.container';
import { ConfigNavbarLabel } from 'common/dist/types/frontendConfig';
import WhatsNew from '../profile-menu/WhatsNew';

export type Props = {
  isMenuShown: boolean;
  mainTabsDefaultLink: string;
  configNavbarLabel?: ConfigNavbarLabel;
};

const Header: FC<Props> = ({
  isMenuShown,
  mainTabsDefaultLink,
  configNavbarLabel,
}) => {
  const [showWhatsNewModal, setShowWhatsNewModal] = useState(false);
  return (
    <header className={styles.header}>
      <div className={styles.headerRow}>
        <div className={styles.headerLeft}>
          <Link to={mainTabsDefaultLink} className={styles.headerLogo}>
            <Logo />
          </Link>

          {configNavbarLabel && (
            <div
              className={styles.headerLabel}
              style={{
                color: configNavbarLabel?.color,
                backgroundColor: configNavbarLabel?.backgroundColor,
              }}
            >
              {configNavbarLabel?.message}
            </div>
          )}
        </div>

        <div className={styles.headerRight}>
          <SearchBar />
          <ProfileMenuButtonContainer />
        </div>
        {isMenuShown && (
          <TopMenu showWhatsNewModal={() => setShowWhatsNewModal(true)} />
        )}
        <WhatsNew
          show={showWhatsNewModal}
          hideModal={() => setShowWhatsNewModal(false)}
        />
      </div>
    </header>
  );
};

export default Header;
