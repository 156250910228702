import React, { FC } from 'react';
import LineChart from '../../../../../../../components/molecules/charts/line-chart/LineChart';
import augurDetailsMsgs from 'common/dist/messages/augurs.details';

type Props = {
  detailsLink: string;
  data: [number, number][];
  baseLine: number;
  helpLine: [[number, number], [number, number]];
};

const CumulativeCapturedResponse: FC<Props> = (props: Props) => {
  const { detailsLink, data, baseLine, helpLine } = props;
  return (
    <div className={'Accuracy--chart-container'}>
      <LineChart
        height={'100%'}
        width={'100%'}
        data={data}
        baseLine={baseLine}
        title={{
          id: 'details.accuracy.cumulative_captured_response',
          defaultMessage: 'Cumulative Captured Response',
        }}
        yLabel={augurDetailsMsgs.msgCumulativeCapturedResponseValue}
        xLabel={augurDetailsMsgs.msgPercentageCustomersContacted}
        // @ts-ignore
        link={{
          to: detailsLink,
        }}
        withBorder={true}
        helpLine={helpLine}
      />
    </div>
  );
};

export default CumulativeCapturedResponse;
