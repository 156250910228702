import React, { Component } from 'react';
import PropTypes from 'prop-types';

// TODO Replace for the storybook component
export default class TextAreaInput extends Component {
  render() {
    return <textarea className={'textarea not-resize'} {...this.props.input} />;
  }
}

TextAreaInput.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
};
