import React, { Component } from 'react';
import PipelineTuningChart from '../pipeline-tuning-chart/PipelineTuningChart';
import { derivePathPipeline } from '../_pipeline-tuning-results-common/utils';
import { PathType, PipelineTuningValue } from 'common/dist/types/pipeline';

type Props = {
  pipeline: PipelineTuningValue;
  path: PathType;
  setActiveNodeId: (nodeId: string) => void;
};

export default class BestModelChart extends Component<Props, {}> {
  render() {
    const { pipeline, path, setActiveNodeId } = this.props;

    const pathPipeline = derivePathPipeline(pipeline, path);

    return (
      <div className={'PipelineTuningResults--best-model-chart'}>
        <PipelineTuningChart
          pipeline={pathPipeline}
          onSelectingNode={(n) => setActiveNodeId(n?.id)}
          inactiveNodeIds={[]}
        />
      </div>
    );
  }
}
