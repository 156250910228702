import { connect } from 'react-redux';
import { RootState } from '../../../../store/store';
import { withRouter } from 'react-router-dom';
import { JOB_GROUP_VARIANTS } from './JobGroupDetails';
import JobGroupDetails, { RProps } from './JobGroupDetails.container';
import { fetchJobGroupDetailsHistory } from '../../../../redux/modules/orchestration.history.module';

export const mapStateToProps = (state: RootState, { match }: RProps) => {
  const code = match.params.jobGroupCode;

  return {
    jobGroupDetails: state.jobGroupHistory.jobGroups[code],
    jobGroupVariant: JOB_GROUP_VARIANTS.jobHistory,
  };
};

export const mapDispatchToProps = {
  fetchData: fetchJobGroupDetailsHistory,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(JobGroupDetails));
