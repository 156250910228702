import React, { FC, useState } from 'react';
import commonStyles from '../optChannels/optCommonStyles.module.scss';
import { MessageDescriptor } from 'react-intl';
import { WrappedFieldProps } from 'redux-form';
import PropensitiesTable from './PropensitiesTable';
import {
  Communication,
  Propensity,
} from 'common/dist/types/module.optimization';
import { v4 as uuidv4 } from 'uuid';
import { FiPlus } from 'react-icons/fi';
import _ from 'lodash';
import InputError from '../../../../atoms/input-error/InputError';
import { _globalValidationError } from '../../../../modelManagement/modules/implementations/optimization/new-augur/optimization.form';

export type Props = {
  /** The list of communications defined in a later wizard step. Required to prevent deleting a channel to which a
   * communication belongs to*/
  communications?: Communication[];
  /** Is the edit mode enabled? (false = render static table of constraints)*/
  inEditMode: boolean;
};

export type PropensitiesValueType = Propensity[];

export type PropensitiesErrorType = {
  [constraintId: string]: {
    name?: MessageDescriptor;
    description?: MessageDescriptor;
    valueId?: MessageDescriptor;
  };
};

const OptPropensities: FC<Props & WrappedFieldProps> = (props) => {
  const { input, meta, communications, inEditMode } = props;
  const expandAutomatically = (props: Props & WrappedFieldProps) => {
    const { input } = props;

    const isEmpty = _.isEmpty(input.value);
    //Also check if it was triggered by the Wizard
    if (props.inEditMode && isEmpty && !addedNewRow) {
      setAddedNewRow(true);
      input.onChange([...(input.value || []), { id: uuidv4() }]);
    }
  };
  const [addedNewRow, setAddedNewRow] = useState(false);

  expandAutomatically(props);
  return (
    <div className={commonStyles.optComponent}>
      <div className={commonStyles.errorContainer}>
        <InputError
          touched={meta?.touched}
          error={meta?.error?.[_globalValidationError]}
        />
      </div>

      <div className={commonStyles.tableContainer}>
        <PropensitiesTable
          editMode={inEditMode}
          input={input}
          meta={meta}
          communications={communications}
          addedNewRow={addedNewRow}
          rowIndex={input.value?.length - 1 || 0}
        />
      </div>

      {inEditMode && (
        <div className={commonStyles.AddIconContainer}>
          <div
            className={commonStyles.AddIcon}
            onClick={() => {
              input.onChange([...(input.value || []), { id: uuidv4() }]);
              setAddedNewRow(true);
            }}
          >
            <FiPlus size={18} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OptPropensities;
