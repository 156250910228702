import { apiRequest, CompletedApiRequest } from './_tools';
import qs from 'qs';
import { ArchetypeVersionType } from 'common/dist/types/archetypeVersion';

// TODO is the hard coded limit of 100 a good idea?
export function getArchetypeVersions(
  archetypeCode: string,
  offset = 0,
  limit = 100
): CompletedApiRequest<ArchetypeVersionType[]> {
  const query = qs.stringify({ offset, limit }, { addQueryPrefix: true });
  return apiRequest(`/api/archetypes/${archetypeCode}/versions${query}`);
}
