import React from 'react';
import { Props } from '../Table';
import { FC } from 'react';
import TableHead from './TableHead';
import TableBody from './TableBody';
import SlowTable from '../Table';

const Table: FC<Props> = (props: Props) => {
  const { colSpecs, data, editable } = props;

  if (colSpecs.length > 250 && !editable) {
    return (
      <table>
        <TableHead cols={colSpecs} />
        <TableBody rows={data} />
      </table>
    );
  }

  return <SlowTable {...props} />;
};

export default Table;
