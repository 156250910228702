import { scaleLinear } from 'd3-scale';
import { min, max } from 'd3-array';

import {
  flattenRuns,
  getX,
} from '../../../../../../../../../core/common/accuracyDetail';

export default function getScales(width, height, data, maxY = 1) {
  const xScale = scaleLinear()
    .domain([min(flattenRuns(data), getX), max(flattenRuns(data), getX)])
    .range([0, width]);

  const yScale = scaleLinear().domain([0, maxY]).range([height, 0]);

  return { xScale, yScale };
}
