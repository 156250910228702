import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const fetchModules = createAction('fetch modules');

export const fetchModulesSuccess = createAction(
  'fetch modules - success',
  (data) => data
);

export const fetchModulesFail = createAction(
  'fetch modules - fail',
  (error) => error
);

export const reducer = {
  [fetchModules]: (state) => ({
    ...state,
    modules: {
      ...state.modules,
      loading: true,
    },
  }),
  [fetchModulesSuccess]: (state, modules) => ({
    ...state,
    modules: {
      ...state.modules,
      loading: false,
      loaded: true,
      data: modules,
      error: undefined,
    },
  }),
  [fetchModulesFail]: (state, error) => ({
    ...state,
    modules: {
      ...state.modules,
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* fetchModulesSaga() {
  const { response, error } = yield call(Api.modules.fetchModules);
  if (response) {
    yield put(fetchModulesSuccess(response));
  } else {
    yield put(fetchModulesFail(error));
  }
}

export function* watchFetchModules() {
  yield takeEvery(fetchModules.getType(), fetchModulesSaga);
}
