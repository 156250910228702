import { noop } from 'lodash';
// import { is } from 'redux-saga/utils';
import { isApiCallResult } from '../core/api';
import { ToBeRefined } from 'common/dist/types/todo_type';

let dispatch;

export function isApiResponse(result: ToBeRefined) {
  return isApiCallResult(result);
}

export function effectResolved(effectId: ToBeRefined, result: ToBeRefined) {
  if (!isApiResponse(result) || !result.error) {
    return;
  }

  let error = result.error;
  if (error.message) {
    error = error.message;
  } else if (error.reason) {
    error = error.reason;
  } else {
    error = 'Unknown';
  }
}

export default {
  effectTriggered: noop,
  effectResolved,
  effectRejected: noop,
  effectCancelled: noop,
  assignDispatch(d: ToBeRefined) {
    dispatch = d;
  },
};
