import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import {
  addJobGroup as addJobGroupApi,
  deleteJobGroup as deleteJobGroupApi,
  fetchJobGroup as fetchJobGroupApi,
  fetchJobGroups as fetchJobGroupsApi,
  runJobGroup as runJobGroupApi,
  updateJobGroup as updateJobGroupApi,
} from './api';
import { JobGroupInputType, JobGroupType } from 'common/dist/types/job';
import { sendNotification } from '../../redux/modules/notifications.module';
import {
  error as errorType,
  event as eventType,
} from '../../core/notifications';
import { ReduxActUnknown3, ReduxActUnknown4 } from '../reduxAct';
import notificationsMsgs from 'common/dist/messages/notifications';

export type JobGroupsState = {
  loaded?: boolean;
  loading?: boolean;
  data?: JobGroupType[];
  error?: ApiError;
  jobGroupSubmitting?: boolean;
};

export const initial: JobGroupsState = {
  loaded: false,
  loading: false,
  data: [],
  error: undefined,
};
export const test: JobGroupsState = {};

export const fetchJobGroups = createAsyncThunk<
  { data: JobGroupType[] },
  { offset?: number; limit?: number; search?: string },
  { rejectValue: { error: ApiError } }
>('jobGroups/fetchJobGroups', async ({ offset, limit, search }, thunkAPI) => {
  const { response, error } = await fetchJobGroupsApi(offset, limit, search);

  if (response) {
    return { data: response };
  } else {
    return thunkAPI.rejectWithValue({ error });
  }
});

export const fetchJobGroup = createAsyncThunk<
  { data: JobGroupType },
  { jobGroupCode: string },
  { rejectValue: { error: ApiError } }
>('jobGroups/fetchJobGroup', async ({ jobGroupCode }, thunkAPI) => {
  const { response, error } = await fetchJobGroupApi(jobGroupCode);

  if (response) {
    return { data: response };
  } else {
    return thunkAPI.rejectWithValue({ error });
  }
});

export const addJobGroup = createAsyncThunk<
  { response: unknown },
  { jobGroup: JobGroupInputType; callback?: (success: boolean) => void },
  { rejectValue: { error: ApiError } }
>('jobGroups/addJobGroup', async ({ jobGroup, callback }, thunkAPI) => {
  const { response, error } = await addJobGroupApi(jobGroup);

  if (response) {
    thunkAPI.dispatch(
      (sendNotification as ReduxActUnknown3 as ReduxActUnknown3)(
        notificationsMsgs.msgTitleJobGroupAddSuccess.id,
        notificationsMsgs.msgDescriptionJobGroupAddSuccess.id,
        eventType
      )
    );
    callback && callback(true);
    return { response };
  } else {
    thunkAPI.dispatch(
      (sendNotification as ReduxActUnknown4)(
        notificationsMsgs.msgTitleJobGroupAddFailure.id,
        error.formattedMessage.id,
        errorType,
        error.formattedMessage.values
      )
    );
    callback && callback(false);
    return thunkAPI.rejectWithValue({ error });
  }
});

export const updateJobGroup = createAsyncThunk<
  { response: unknown },
  {
    jobGroupCode: string;
    jobGroup: JobGroupInputType;
    callback?: (success: boolean) => void;
  },
  { rejectValue: { error: ApiError } }
>(
  'jobGroups/updateJobGroup',
  async ({ jobGroupCode, jobGroup, callback }, thunkAPI) => {
    const { response, error } = await updateJobGroupApi(jobGroupCode, jobGroup);

    if (response) {
      thunkAPI.dispatch(
        (sendNotification as ReduxActUnknown3)(
          notificationsMsgs.msgTitleJobGroupUpdateSuccess.id,
          notificationsMsgs.msgDescriptionJobGroupUpdateSuccess.id,
          eventType
        )
      );
      callback && callback(true);
      return { response };
    } else {
      thunkAPI.dispatch(
        (sendNotification as ReduxActUnknown4)(
          notificationsMsgs.msgTitleJobGroupUpdateFailure.id,
          error.formattedMessage.id,
          errorType,
          error.formattedMessage.values
        )
      );
      callback && callback(false);
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const deleteJobGroupThenFetch = createAsyncThunk<
  { response: unknown },
  { jobGroupCode: string; offset?: number; limit?: number; search?: string },
  { rejectValue: { error: ApiError } }
>(
  'jobGroups/deleteJobGroupThenFetch',
  async ({ jobGroupCode, offset, limit, search }, thunkAPI) => {
    const { response, error } = await deleteJobGroupApi(jobGroupCode);

    if (response) {
      thunkAPI.dispatch(
        (sendNotification as ReduxActUnknown3)(
          notificationsMsgs.msgTitleJobGroupDeleteSuccess.id,
          notificationsMsgs.msgDescriptionJobGroupDeleteSuccess.id,
          eventType
        )
      );
      thunkAPI.dispatch(fetchJobGroups({ offset, limit, search }));
      return { response };
    } else {
      thunkAPI.dispatch(
        (sendNotification as ReduxActUnknown4)(
          notificationsMsgs.msgTitleJobGroupDeleteFailure.id,
          error.formattedMessage.id,
          errorType,
          error.formattedMessage.values
        )
      );
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const runJobGroup = createAsyncThunk<
  { response: unknown },
  { jobGroup: JobGroupInputType },
  { rejectValue: { error: ApiError } }
>('jobGroups/runJobGroup', async ({ jobGroup }, thunkAPI) => {
  const { response, error } = await runJobGroupApi(jobGroup);

  if (response) {
    thunkAPI.dispatch(
      (sendNotification as ReduxActUnknown3)(
        notificationsMsgs.msgTitleJobGroupRunSuccess.id,
        notificationsMsgs.msgDescriptionJobGroupRunSuccess.id,
        eventType
      )
    );
    return { response };
  } else {
    thunkAPI.dispatch(
      (sendNotification as ReduxActUnknown4)(
        notificationsMsgs.msgTitleJobGroupRunFailure.id,
        error.formattedMessage.id,
        errorType,
        error.formattedMessage.values
      )
    );
    return thunkAPI.rejectWithValue({ error });
  }
});

const slice = createSlice({
  name: 'jobGroups',
  initialState: initial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchJobGroups.pending, (state, { payload, meta }) => {
      state.loaded = false;
      state.loading = true;
    });
    builder.addCase(fetchJobGroups.fulfilled, (state, { payload, meta }) => {
      const { data } = payload;
      state.data = data;
      state.loading = false;
      state.loaded = true;
      state.error = undefined;
    });
    builder.addCase(fetchJobGroups.rejected, (state, { payload, meta }) => {
      const { error } = payload;
      state.error = error;
      state.loading = false;
      state.loaded = false;
    });
    builder.addCase(fetchJobGroup.pending, (state, { payload, meta }) => {
      state.loading = true;
    });
    builder.addCase(fetchJobGroup.fulfilled, (state, { payload, meta }) => {
      const { data } = payload;
      const idx = state.data.findIndex(
        (jg) => jg.code === meta.arg.jobGroupCode
      );
      if (idx !== -1) {
        state.data[idx] = data;
      } else {
        state.data.push(data);
      }
      state.loading = false;
      state.loaded = true;
      state.error = undefined;
    });
    builder.addCase(fetchJobGroup.rejected, (state, { payload, meta }) => {
      const { error } = payload;
      state.error = error;
      state.loading = false;
    });
    builder.addCase(addJobGroup.pending, (state) => {
      state.jobGroupSubmitting = true;
    });
    builder.addCase(addJobGroup.fulfilled, (state) => {
      state.jobGroupSubmitting = false;
    });
    builder.addCase(addJobGroup.rejected, (state) => {
      state.jobGroupSubmitting = false;
    });
    builder.addCase(updateJobGroup.pending, (state) => {
      state.jobGroupSubmitting = true;
    });
    builder.addCase(updateJobGroup.fulfilled, (state) => {
      state.jobGroupSubmitting = false;
    });
    builder.addCase(updateJobGroup.rejected, (state) => {
      state.jobGroupSubmitting = false;
    });
  },
});

const reducer = slice.reducer;
export { reducer as jobGroupsReducer };
