import { createAction } from 'redux-act';

export const setOrder = createAction(
  'set augur order',
  (habitatCode, order) => ({ habitatCode, order })
);

export const reducer = {
  [setOrder]: (state, { habitatCode, order }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      habitats: {
        ...state.dashboard.habitats,
        [habitatCode]: {
          ...state.dashboard.habitats[habitatCode],
          order,
        },
      },
    },
  }),
};
