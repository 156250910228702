import { createAction } from 'redux-act';

export const hover = createAction('hover on column', (columnId) => columnId);

export const leave = createAction('leave column');

export const click = createAction('click on column', (columnId) => columnId);

export const reducer = {
  [hover]: (state, columnId) => ({
    ...state,
    dataImport: {
      ...state.dataImport,
      hoveredColumn: columnId,
    },
  }),

  [leave]: (state) => ({
    ...state,
    dataImport: {
      ...state.dataImport,
      hoveredColumn: '',
    },
  }),

  [click]: (state, columnId) => ({
    ...state,
    dataImport: {
      ...state.dataImport,
      activeColumns: {
        ...state.dataImport.activeColumns,
        [columnId]: !state.dataImport.activeColumns[columnId],
      },
    },
  }),
};
