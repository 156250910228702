import React, { Component } from 'react';
import {
  commonReduxFormProps,
  wizardHeadline,
} from '../../../../../newDatapool/newDatapool.form';
import WizardPage from '../../../../../organisms/multi-page-wizard/WizardPage';
import MultiPageWizard from '../../../../../organisms/multi-page-wizard/MultiPageWizard.container';
import { renderDefaultWizardSteps } from '../../../../../newDatapool/defaultWizard/DefaultNewDatapoolWizard';

import {
  getNaturalLanguageTaggingMpwAsyncValidate,
  getNaturalLanguageTaggingSubmitSummary,
  naturalLanguageTaggingAsyncBlurFields,
  naturalLanguageTaggingAsyncChangeFields,
  naturalLanguageTaggingValidate,
} from './naturalLanguageTagging.form';
import { fieldModuleType } from '../../../../../newDatapool/defaultWizard/default.form';
import * as routes from '../../../../../index/routes';

import { moduleType as MODULE_TYPE } from 'common/dist/constants/module_list';
import { NaturalLanguageTaggingSubmitSummary } from 'common/dist/types/requestBodies/datapools';
import { ModulesState } from '../../../../../../store/modules/state.types';
import { ParsedQs } from 'qs';

export type OwnProps = {
  /** Code of the habitat to add this datapool to */
  habitatCode: string;
  /** Name of the habitat to add this datapool to */
  habitatName?: string;
  /** List of available modules */
  modules?: ModulesState;
  /** Is the wizard currently being submitted? */
  isSubmitting?: boolean;
  /** Dispatches a redux action to add an datapool */
  addDatapool: (
    habitatCode: string,
    datapoolSummary: NaturalLanguageTaggingSubmitSummary
  ) => void;
  /** Uses react-routers history.push() to open another page */
  goToLink: (link: string) => void;
  /** Selector for the redux-form form values */
  formValueSelector: (fieldName: string) => any;
  /** Optional passed url query (already parsed) */
  query?: ParsedQs;
};

type ContainerProps = {
  // There is no container for this component (yet?)
};

export type Props = OwnProps & ContainerProps;

export default class NaturalLanguageTaggingNewDatapoolWizard extends Component<Props> {
  render() {
    const {
      habitatCode,
      habitatName,
      modules,
      isSubmitting,
      addDatapool,
      goToLink,
    } = this.props;

    const onSubmit = (values) => {
      const submitSummary = getNaturalLanguageTaggingSubmitSummary(
        values,
        habitatCode
      );
      addDatapool(habitatCode, submitSummary);
      goToLink(routes.app.prefix + routes.app.models);
    };

    // --- Selected values
    const moduleType = MODULE_TYPE.NATURAL_LANGUAGE_TAGGING;
    // const datapoolName = formValueSelector(fieldDatapoolName);

    // --- Initial values
    const initialValues = {
      // If this isn't set, the first click onto the Module Type in the DefaultNewAugurWizard won't work
      // only the second click will really select the module type and render the correct wizard.
      [fieldModuleType]: moduleType,
    };

    return (
      // @ts-ignore
      <MultiPageWizard
        {...commonReduxFormProps}
        initialValues={initialValues}
        // @ts-ignore
        validate={naturalLanguageTaggingValidate}
        // @ts-ignore
        asyncValidate={getNaturalLanguageTaggingMpwAsyncValidate(habitatCode)}
        asyncBlurFields={naturalLanguageTaggingAsyncBlurFields}
        asyncChangeFields={naturalLanguageTaggingAsyncChangeFields}
        wizardHeadline={wizardHeadline(habitatName)}
        onCancel={() => {
          goToLink(routes.app.prefix + routes.app.models);
        }}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      >
        {/* @ts-ignore */}
        <WizardPage
          pageTitle={{
            id: 'no-id',
            defaultMessage: 'Module Type & Datapool Name',
          }}
        >
          {renderDefaultWizardSteps(modules)}
        </WizardPage>
      </MultiPageWizard>
    );
  }
}
