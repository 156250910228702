import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  postApiRequest,
  putApiRequest,
} from './_tools';
import { PostHabitatRequestBody } from 'common/dist/types/requestBodies/habitats';
import { HabitatDetailsType } from 'common/dist/types/habitat';
import {
  ExistsResponseBody,
  PostPutDeleteResponseBody,
} from 'common/dist/types/responseBodies/base';

export function getHabitat(
  habitatCode: string
): CompletedApiRequest<HabitatDetailsType> {
  return apiRequest(`/api/habitats/${habitatCode}`);
}

export function getHabitats(): CompletedApiRequest<HabitatDetailsType[]> {
  return apiRequest('/api/habitats');
}

export function checkHabitatNameExists(
  habitatName: string
): CompletedApiRequest<ExistsResponseBody> {
  return postApiRequest(`/api/habitats/exists`, { name: habitatName });
}

export function addHabitat(name: string) {
  const body: PostHabitatRequestBody = { name };
  return postApiRequest('/api/habitats', body);
}
export function updateHabitat(
  habitatCode: string,
  name: string
): CompletedApiRequest<PostPutDeleteResponseBody> {
  return putApiRequest(`/api/habitats/${habitatCode}`, {
    name,
  });
}
export function deleteHabitat(habitatCode: string, name: string) {
  return deleteApiRequest(`/api/habitats/${habitatCode}`);
}
