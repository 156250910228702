import { apiRequest } from './_tools';

export function fetchApps(onlyWithAtLeastOneVersion, admin = false) {
  return apiRequest(
    `/api${
      admin ? '/admin' : ''
    }/apps?onlyWithAtLeastOneVersion=${onlyWithAtLeastOneVersion}`
  );
}

export function fetchAppVersions(appCode) {
  return apiRequest(`/api/apps/${appCode}/versions`);
}
