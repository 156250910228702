import React, { Component } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';
import { WrappedFieldProps } from 'redux-form';

export interface Props {
  input: {
    value?: {
      key?: string;
      value?: string;
      id?: string;
    }[];
    onChange?: (...args: unknown[]) => unknown;
  };
  meta?: {};
}

export default class FieldParameters extends Component<
  Props & WrappedFieldProps
> {
  render() {
    const {
      input: { value, onChange },
    } = this.props;
    return (
      <div className={'field-parent parameters-parent'}>
        {value &&
          value.map((t, i) => (
            <div className={'parameter-row'} key={i}>
              <div
                className={'small-button button-remove'}
                onClick={() => {
                  const newParamList = (value || []).filter(
                    (x) => x.id !== t.id
                  );
                  onChange(newParamList);
                }}
              >
                <FiMinus className={'icon-remove'} size={20} />
              </div>
              <div
                className={'column-key'}
                style={{ flexGrow: 1, marginRight: '10px' }}
              >
                <input
                  value={t.key}
                  type={'text'}
                  placeholder={'Key'}
                  onChange={(e) => {
                    const newParamList = (value || []).map((x) =>
                      x.id === t.id ? { ...x, key: e.target.value } : x
                    );
                    onChange(newParamList);
                  }}
                />
              </div>
              <div
                className={'column-value'}
                style={{ flexGrow: 1, marginLeft: '10px' }}
              >
                <input
                  value={t.value}
                  type={'text'}
                  placeholder={'Default Value'}
                  onChange={(e) => {
                    const newParamList = (value || []).map((x) =>
                      x.id === t.id ? { ...x, value: e.target.value } : x
                    );
                    onChange(newParamList);
                  }}
                />
              </div>
            </div>
          ))}
        <div
          className={'parameter-row add-parameter'}
          onClick={() => {
            const newParamList = [
              ...(value || []),
              { key: '', value: '', id: uuidv4() },
            ];
            onChange(newParamList);
          }}
        >
          <div className={'small-button button-add'}>
            <FiPlus className={'icon-remove'} size={20} />
          </div>
          <div className={'label-add'}>Add Parameter</div>
        </div>
      </div>
    );
  }
}
