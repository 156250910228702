import { connect } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import TablePreview, { OwnProps } from './TablePreview';
import {
  selectDataSources,
  selectS3TableSample,
  selectTableSample,
} from '../dataSource.selectors';
import { LoadableData } from '../../../../../../utils';
import { CassandraDataPreview } from '../../../../../../store/dataManagement/state.types';

function mapStateToProps(state: RootState, { datapool, tableName }: OwnProps) {
  const dataSources = selectDataSources(state);
  const datapoolDataSource = dataSources.data.find(
    (ds) => ds.code === datapool?.dataSourceCode
  );
  let tableSample: LoadableData<CassandraDataPreview, unknown>;
  switch (datapoolDataSource?.ds_type) {
    case 'cassandra':
      ({ tableSample } = selectTableSample(
        state,
        datapool.dataSourceCode,
        datapool.code,
        tableName
      ));
      break;
    case 's3':
      ({ tableSample } = selectS3TableSample(
        state,
        datapool.dataSourceCode,
        datapool.code,
        tableName
      ));
  }

  return {
    tableSample,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TablePreview);
