import React, { FC } from 'react';
import { area } from 'd3-shape';
import { getX, getY } from './util';
import { DataType } from './LineChart';
import { ScaleLinear } from 'd3-scale';

type Props = {
  data: DataType;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  height: number;
  opacity: number;
  fill: string;
};

const Area: FC<Props> = (props: Props) => {
  const { data, xScale, yScale, height, opacity, fill } = props;

  const areaPath = area()
    .x((d) => xScale(getX(d)))
    .y0((d) => yScale(getY(d)))
    .y1(height)(data);

  return <path d={areaPath} opacity={opacity} fill={fill} />;
};

export default Area;
