import React, { Component } from 'react';
import {
  commonReduxFormProps,
  wizardHeadline,
} from '../../../../../newDatapool/newDatapool.form';
import WizardPage from '../../../../../organisms/multi-page-wizard/WizardPage';
import MultiPageWizard from '../../../../../organisms/multi-page-wizard/MultiPageWizard.container';
import { renderDefaultWizardSteps } from '../../../../../newDatapool/defaultWizard/DefaultNewDatapoolWizard';

import {
  fieldChannels,
  fieldCommunicationGroups,
  fieldCommunications,
  fieldPropensities,
  getOptimizationMpwAsyncValidate,
  getOptimizationSubmitSummary,
  optimizationAsyncBlurFields,
  optimizationAsyncChangeFields,
  optimizationValidate,
  optimizationWarn,
} from './optimization.form';
import { fieldModuleType } from '../../../../../newDatapool/defaultWizard/default.form';
import * as routes from '../../../../../index/routes';

import { moduleType as MODULE_TYPE } from 'common/dist/constants/module_list';
import WizardStep from '../../../../../organisms/multi-page-wizard/WizardStep';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import OptChannels from '../../../../../organisms/modelManagement/newDatapoolWizard/optChannels/OptChannels';
import OptPropensities from '../../../../../organisms/modelManagement/newDatapoolWizard/optPropensities/OptPropensities';
import OptCommunications from '../../../../../organisms/modelManagement/newDatapoolWizard/optCommunications/OptCommunications';
import OptCommunicationGroups from '../../../../../organisms/modelManagement/newDatapoolWizard/optCommunicationGroups/OptCommunicationGroups';
import { OptimizationSubmitSummary } from 'common/dist/types/requestBodies/datapools';
import { ParsedQs } from 'qs';
import { ModulesState } from '../../../../../../store/modules/state.types';

export type OwnProps = {
  /** Code of the habitat to add this datapool to */
  habitatCode: string;
  /** Name of the habitat to add this datapool to */
  habitatName?: string;
  /** List of available modules */
  modules?: ModulesState;
  /** Is the wizard currently being submitted? */
  isSubmitting?: boolean;
  /** Dispatches a redux action to add an datapool */
  addDatapool: (
    habitatCode: string,
    datapoolSummary: OptimizationSubmitSummary
  ) => void;
  /** Uses react-routers history.push() to open another page */
  goToLink: (link: string) => void;
  /** Selector for the redux-form form values */
  formValueSelector: (fieldName: string) => any;
  /** Optional passed url query (already parsed) */
  query?: ParsedQs;
};

type ContainerProps = {
  // There is no container for this component (yet?)
};

export type Props = OwnProps & ContainerProps;

export default class OptimizationNewDatapoolWizard extends Component<Props> {
  render() {
    const {
      habitatCode,
      habitatName,
      modules,
      isSubmitting,
      addDatapool,
      goToLink,
      formValueSelector,
    } = this.props;

    const onSubmit = (values) => {
      const submitSummary = getOptimizationSubmitSummary(values, habitatCode);
      addDatapool(habitatCode, submitSummary);
      goToLink(routes.app.prefix + routes.app.models);
    };

    // --- Selected values
    const moduleType = MODULE_TYPE.CAMPAIGN_OPTIMIZATION;
    const channels = formValueSelector(fieldChannels);
    const propensities = formValueSelector(fieldPropensities);
    const communications = formValueSelector(fieldCommunications);
    const communicationGroups = formValueSelector(fieldCommunicationGroups);

    // --- Initial values
    const initialValues = {
      // If this isn't set, the first click onto the Module Type in the DefaultNewAugurWizard won't work
      // only the second click will really select the module type and render the correct wizard.
      [fieldModuleType]: moduleType,
    };

    // --- Pages
    const renderPageChannels = (stepNumber: number) => {
      return (
        // @ts-ignore
        <WizardPage
          pageTitle={moduleOptimizationMessages.msgNewDatapoolPageChannelsTitle}
        >
          <WizardStep
            title={moduleOptimizationMessages.msgNewDatapoolStepChannelsTitle}
            description={
              moduleOptimizationMessages.msgNewDatapoolStepChannelsDescription
            }
            stepNumber={stepNumber}
            fieldName={fieldChannels}
            BottomComponent={OptChannels}
            bottomComponentProps={{
              communications,
              inEditMode: true,
            }}
          />
        </WizardPage>
      );
    };

    const renderPagePropensities = (stepNumber: number) => {
      return (
        // @ts-ignore
        <WizardPage
          pageTitle={
            moduleOptimizationMessages.msgNewDatapoolPagePropensitiesTitle
          }
        >
          <WizardStep
            fieldName={fieldPropensities}
            title={
              moduleOptimizationMessages.msgNewDatapoolStepPropensitiesTitle
            }
            description={
              moduleOptimizationMessages.msgNewDatapoolStepPropensitiesDescription
            }
            stepNumber={stepNumber}
            BottomComponent={OptPropensities}
            bottomComponentProps={{
              communications,
              inEditMode: true,
            }}
          />
        </WizardPage>
      );
    };

    const renderPageCommunications = (stepNumber: number) => {
      return (
        // @ts-ignore
        <WizardPage
          pageTitle={
            moduleOptimizationMessages.msgNewDatapoolPageCommunicationsTitle
          }
        >
          <WizardStep
            fieldName={fieldCommunications}
            title={
              moduleOptimizationMessages.msgNewDatapoolStepCommunicationsTitle
            }
            description={
              moduleOptimizationMessages.msgNewDatapoolStepCommunicationsDescription
            }
            stepNumber={stepNumber}
            BottomComponent={OptCommunications}
            bottomComponentProps={{
              channels,
              propensities,
              communicationGroups,
              inEditMode: true,
            }}
          />
        </WizardPage>
      );
    };

    const renderPageCommunicationGroups = (stepNumber: number) => {
      return (
        // @ts-ignore
        <WizardPage
          pageTitle={
            moduleOptimizationMessages.msgNewDatapoolPageCommunicationGroupsTitle
          }
        >
          <WizardStep
            fieldName={fieldCommunicationGroups}
            title={
              moduleOptimizationMessages.msgNewDatapoolStepCommunicationGroupsTitle
            }
            description={
              moduleOptimizationMessages.msgNewDatapoolStepCommunicationGroupsDescription
            }
            stepNumber={stepNumber}
            BottomComponent={OptCommunicationGroups}
            bottomComponentProps={{
              communications,
              inEditMode: true,
            }}
          />
        </WizardPage>
      );
    };

    let stepNumber = 3; // Starting at 3, since the first two steps are always the same (coming from the DefaultAugurWizard)
    return (
      <MultiPageWizard
        {...commonReduxFormProps}
        initialValues={initialValues}
        // @ts-ignore
        validate={optimizationValidate}
        // @ts-ignore
        warn={optimizationWarn}
        // @ts-ignore
        asyncValidate={getOptimizationMpwAsyncValidate(habitatCode)}
        asyncBlurFields={optimizationAsyncBlurFields}
        asyncChangeFields={optimizationAsyncChangeFields}
        wizardHeadline={wizardHeadline(habitatName)}
        onCancel={() => {
          goToLink(routes.app.prefix + routes.app.models);
        }}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      >
        {/* @ts-ignore */}
        <WizardPage
          pageTitle={{
            id: 'no-id',
            defaultMessage: 'Module Type & Datapool Name',
          }}
        >
          {renderDefaultWizardSteps(modules)}
        </WizardPage>

        {renderPageChannels(stepNumber++)}
        {renderPagePropensities(stepNumber++)}
        {renderPageCommunications(stepNumber++)}
        {renderPageCommunicationGroups(stepNumber++)}
      </MultiPageWizard>
    );
  }
}
