import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';
import { FormattedRelativeTimeConvenient } from '../../../atoms/formatted-date-time/FormattedDateTime';
import { CProps } from './UserSidenotes.container';

export type Props = {};

export type CombinedProps = Props & CProps;

const UserSidenotes: FC<CombinedProps> = ({ user, isAdmin }) => {
  return (
    <div className={'UserSidenotes'}>
      <hr className={'UserSidenotes--hr'} />

      <div className={'UserSidenotes--line'}>
        <div className={'UserSidenotes--column'}>
          <label className={'UserSidenotes--label'}>
            <FormattedMessage
              id='account.info.user_created'
              defaultMessage='User Created'
            />
          </label>
          <div className={'UserSidenotes--value'}>
            {user?.createdTimestamp && (
              <FormattedRelativeTimeConvenient
                date={new Date(user.createdTimestamp)}
              />
            )}
          </div>
        </div>

        <div className={'UserSidenotes--column'}>
          <label className={'UserSidenotes--label'}>
            <FormattedMessage
              id='account.info.account_enabled'
              defaultMessage='Account Enabled'
            />
          </label>
          <div className={'UserSidenotes--value'}>
            <span>{user?.enabled ? 'Yes' : 'No'}</span>
          </div>
        </div>

        <div className={'UserSidenotes--column'}>
          <label className={'UserSidenotes--label'}>
            <FormattedMessage
              id='account.info.is_admin'
              defaultMessage='Is Administrator'
            />
          </label>
          <div className={'UserSidenotes--value'}>
            <span>{isAdmin ? 'Yes' : 'No'}</span>
          </div>
        </div>

        <div className={'UserSidenotes--column'}>
          <label className={'UserSidenotes--label'}>
            <FormattedMessage id='no-id' defaultMessage='User Id' />
          </label>
          <div className={'UserSidenotes--value'}>
            <span>{user?.id}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSidenotes;
