import React, { FC } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { FiCheck, FiRefreshCw } from 'react-icons/fi';

export type Props = {
  /** Number to show in the bubble */
  number: number;
  /** Renders the circle in red. This dominates over "isValid"
   * (if isErroneous and isValid are both true, isErroneous is more important) */
  isErroneous?: boolean;
  /** Renders the circle in green with a check mark */
  isValid?: boolean;
  /** Is the step currently (asnyc) validating? */
  isValidating?: boolean;
};

const Bubble: FC<Props> = (props) => {
  const { number, isErroneous, isValid, isValidating } = props;

  return (
    <div
      className={classNames(
        styles.bubble,
        { [styles.isErroneous]: isErroneous },
        { [styles.isValid]: isValid }
      )}
    >
      {(!isValid || isErroneous) && !isValidating && (
        <span className={styles.number}>{number}</span>
      )}
      {isValid && !isErroneous && !isValidating && (
        <FiCheck className={styles.checkIcon} size={'20px'} />
      )}
      {isValidating && (
        <FiRefreshCw className={styles.validatingIcon} size={'14px'} />
      )}
    </div>
  );
};

export default Bubble;
