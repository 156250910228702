import React, { FC } from 'react';
import styles from '../styles.module.scss';
import { FormattedMessage } from 'react-intl';

type Props = {};

export const OverlayUsersAndPermissions: FC<Props> = (props: Props) => {
  return (
    <div className={styles.subheaderLeft}>
      <div className={styles.subheaderSimpleHeadlineContainer}>
        <div className={styles.subheaderSimpleHeadline}>
          <FormattedMessage
            id='admin.headline.manage_users'
            defaultMessage='Manage Users & Permissions'
          />
        </div>
      </div>
    </div>
  );
};

export default OverlayUsersAndPermissions;
