import React, { FC, useEffect, useMemo, useState } from 'react';
import { FiCheck, FiEdit2, FiMinus, FiPlus, FiX } from 'react-icons/fi';
import {
  useAugur,
  useUpdateAugur,
} from '../../../../../../../../../core/api/augurs';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../../../../types';

const AttributesComponent: FC = () => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState<[string, string][]>([]);

  const { data: augur } = useAugur(habitatCode, augurCode);
  const attributes = useMemo(
    () => Object.entries<string>(augur?.attributes ?? {}),
    [augur]
  );
  useEffect(() => setInputValue(attributes), [attributes]);

  const { mutate: updateAugur } = useUpdateAugur(habitatCode, augurCode);
  const saveAttributes = () => {
    const inputValueObj = Object.fromEntries(
      inputValue
        .map(([key, value]) => [key.trim(), value.trim()])
        .filter(([key, _]) => key)
    );
    if (inputValueObj !== attributes) {
      updateAugur({ attributes: inputValueObj });
    }
    setEditMode(false);
  };

  const onInputChange = (isKey, index) => {
    return (e) => {
      const newInputValue = [...inputValue]; // copy
      newInputValue[index][isKey ? 0 : 1] = e.target.value;
      setInputValue(newInputValue);
    };
  };

  const newKey = () => {
    setInputValue([...inputValue, ['', '']]);
  };

  const dropIndex = (index) => {
    return () => {
      const newInputValue = [
        ...inputValue.slice(0, index),
        ...inputValue.slice(index + 1),
      ];
      setInputValue(newInputValue);
    };
  };

  const handleKeyDown = (e) => {
    if (editMode) {
      if (e.key === 'Enter') {
        saveAttributes();
      } else if (e.key === 'Escape') {
        setEditMode(false);
        setInputValue(attributes);
      }
    }
  };

  const renderAttributes = () => {
    if (!editMode && attributes.length === 0) {
      return (
        <div className={'information-name-wrapper'}>
          <div>
            <FiEdit2
              className={'action-icon'}
              size={16}
              onClick={() => setEditMode(!editMode)}
            />
          </div>
        </div>
      );
    }

    if (!editMode) {
      return (
        <div className={'information-name-wrapper table-reset'}>
          <table className={'augur-attributes-table'}>
            <tbody>
              {attributes.map(([key, value]) => (
                <tr>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <FiEdit2
              className={'action-icon'}
              size={16}
              onClick={() => setEditMode(!editMode)}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={'edit-input-wrapper table-reset'}>
        <table className={'augur-attributes-table'}>
          <tbody>
            {inputValue.map(([key, value], index) => (
              <tr>
                <td>
                  <input
                    type='text'
                    value={key}
                    onChange={onInputChange(true, index)}
                    placeholder='Enter Key'
                  />
                </td>
                <td>
                  <input
                    type='text'
                    value={value}
                    onChange={onInputChange(false, index)}
                    placeholder='Enter Value'
                    autoFocus
                  />
                </td>
                <td className={'td-icon-minus'}>
                  <div>
                    <FiMinus
                      className={'action-icon inline-icon-cancel'}
                      size={16}
                      onClick={dropIndex(index)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <FiPlus
            className={'action-icon inline-icon-ok'}
            size={16}
            onClick={newKey}
          />
        </div>
        <div>
          <FiCheck
            className={'action-icon inline-icon-ok'}
            size={16}
            onClick={saveAttributes}
          />
          <FiX
            className={'action-icon inline-icon-cancel'}
            size={16}
            onClick={() => {
              setInputValue(attributes);
              setEditMode(false);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='augur-information'>
      <div className='information-name' onKeyDown={handleKeyDown}>
        {renderAttributes()}
      </div>
    </div>
  );
};

export default AttributesComponent;
