import { connect } from 'react-redux';

import { DeprecatedRootState } from '../../store/state.type';
import NewHabitatWizard, { Props } from './NewHabitatWizard';
import { addHabitat } from '../../redux/modules/habitat.add.module';
import {
  deleteHabitat,
  hideDeleteHabitatConfirm,
  showDeleteHabitatConfirm,
  updateHabitat,
} from '../../redux/modules/habitat.modify.module';
import { checkHabitatNameExists } from '../../redux/modules/habitat.checkNameExists.module';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { fieldHabitatName, formName } from './newHabitat.form';
import { formValueSelector } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import { RouteParams } from '../newAugur/NewAugurWizard';
import { isAdmin } from '../../redux/selectors/user.selector';

export const mpwAsyncValidate = (
  {
    habitatName,
    habitatCode,
    submitting,
    deleteHabitatConfirm,
  }: {
    habitatName: string;
    habitatCode: string;
    submitting: boolean;
    deleteHabitatConfirm: any;
  },
  dispatch: ToBeRefined
) => {
  return new Promise((resolve, reject) => {
    if (submitting) {
      // Make sure the habitat name doesn't turn invalid while submitting. Happens if the habitat is added to the
      //   database but the wizard is still "submitting" while the keycloak permissions are set etc.
      resolve(undefined);
    }
    // @ts-ignore
    dispatch(checkHabitatNameExists(habitatName, { resolve, reject }));
  }).then(({ exists }) =>
    exists
      ? Promise.reject({
          habitatName: {
            id: 'newHabitat.stepOne.error.this_name_already_exists',
          },
        })
      : null
  );
};

const selector = formValueSelector(formName);

export function mapStateToProps(
  state: DeprecatedRootState,
  props: RouteComponentProps<RouteParams> & Props
) {
  const {
    match: {
      params: { habitatCode },
    },
    update,
  } = props;
  let name = selector(state, fieldHabitatName);
  if (selector !== undefined && update) {
    if (name === undefined) {
      name = (state.summary || []).find(
        (habitat) => habitat.code === habitatCode
      )?.name;
    }
  }
  const perm =
    isAdmin(state) &&
    (state.summary || []).find((habitat) => habitat.code === habitatCode)
      ?.augurs?.length <= 0 &&
    (state.summary || []).find((habitat) => habitat.code === habitatCode)
      ?.datapools?.length <= 0;
  return {
    submitting: state.newHabitat.submitting,
    update,
    habitatName: name,
    habitatCode: habitatCode,
    deleteHabitatConfirm: state.newHabitat.deleteHabitatConfirm,
    allowDelete: perm,
  };
}

export const mapDispatchToProps = {
  addHabitat,
  updateHabitat,
  hideDeleteHabitatConfirm,
  showDeleteHabitatConfirm,
  deleteHabitat,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(NewHabitatWizard);
