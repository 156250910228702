import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as accountsApi from '../../core/api/accounts';
import { fetchAvatar } from './account.avatar.module';

export const fetchUser = createAction('get user details');

export const fetchUserSuccess = createAction(
  'fetch user success',
  (response) => response
);

export const fetchUserFail = createAction('fetch user fail', (error) => error);

export const reducer = {
  [fetchUserSuccess]: (state, user) => ({
    ...state,
    account: {
      ...state.account,
      user,
    },
  }),
  [fetchUserFail]: (state, error) => ({
    ...state,
    account: {
      ...state.account,
      error,
    },
  }),
};

export function* fetchUserSaga() {
  const { response, error } = yield call(accountsApi.getUser);

  if (response) {
    yield put(fetchUserSuccess(response));
    yield put(fetchAvatar());
  } else {
    yield put(fetchUserFail(error));
  }
}

export function* watchFetchUser() {
  yield takeEvery(fetchUser.getType(), fetchUserSaga);
}
