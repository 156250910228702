import { connect } from 'react-redux';
import { RootState } from '../../store/store';
import NewAugurWizard, { RouteParams } from './NewAugurWizard';
import { fetchModules } from '../../redux/modules/modules.module';
import { ensureHabitatInSummary } from '../../redux/modules/habitat.module';
import { formValueSelector, reset } from 'redux-form';
import { formName } from './newAugur.form';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

function mapStateToProps(
  state: RootState,
  {
    match: {
      params: { habitatCode },
    },
  }: RouteComponentProps<RouteParams>
) {
  // Selector for the values entered in the redux form
  const valueSelector = formValueSelector(formName);

  return {
    modules: state.modules,

    isSubmitting: state.newAugur.submitting,

    formValueSelector: (fieldName: string) => valueSelector(state, fieldName),

    habitatName: (state.summary || []).find(
      (habitat) => habitat.code === habitatCode
    )?.name,
  };
}

const mapDispatchToProps = {
  fetchModules,
  ensureHabitatInSummary,
  resetForm: () => reset(formName),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewAugurWizard)
);
