import { connect } from 'react-redux';
import DropOverlay from './DropOverlay';
import {
  moveNotebookToAnotherPane,
  setNotebookTabDragging,
  splitPane,
} from '../../../../../redux/workbench/modules/notebook.module';

export function mapStateToProps(state) {
  return {
    isNotebookTabDragging: state.workbench.isNotebookTabDragging,
  };
}

export const mapDispatchToProps = {
  splitPane,
  moveNotebookToAnotherPane,
  setNotebookTabDragging,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropOverlay);
