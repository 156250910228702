import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { NodeInfo } from '../../../core/api/codeCapsules';
import TextInputLine from '../../atoms/input-elements/text-input-line/TextInputLine';
import { injectIntl } from 'react-intl';
import { WrappedComponentProps } from 'react-intl/src/components/injectIntl';
import Checkbox from '../../atoms/checkbox/Checkbox';
import {
  extractGpuProductOptions,
  isGpuAvailable,
} from '../k8s-node-info/utils';
import classNames from 'classnames';
import DropdownSelectInput from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { validate } from './validate';
import { Controller, UseFormReturn } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { ToBeRefined } from 'common/src/types/todo_type';
import {
  WB_DEF_CPU_LIM,
  WB_DEF_CPU_REQ,
  WB_DEF_MEM_LIM,
  WB_DEF_MEM_REQ,
  WB_MAX_CPU,
  WB_MAX_GPU,
} from '../../admin/users/user-details/Attributes.form';
import {
  isValidK8sCpuSpec,
  isValidK8sMemorySpec,
  getCpuValueInMilliunits,
} from 'common/dist/utils/kubernetes';

export type Props = {
  /** Has to be fetched via useNodeInfo */
  nodeInfo: NodeInfo;
  userAttributes: Record<string, string>;
  form: UseFormReturn<K8sResources>;
};

export type K8sResources = {
  cpuRequest?: number;
  cpuLimit?: number;
  memoryRequest?: string;
  memoryLimit?: string;
  useGpu?: boolean;
  gpuRequest?: number;
  gpuLimit?: number;
  gpuProduct?: string;
};

export type K8sResourcesInternal = {
  cpuRequest?: string;
  cpuLimit?: string;
  memoryRequest?: string;
  memoryLimit?: string;
  useGpu?: boolean;
  gpuRequest?: string;
  gpuLimit?: string;
  gpuProduct?: string;
};

const convertInternalValuesToString = (
  internalValue: K8sResources
): K8sResourcesInternal => {
  return {
    cpuRequest: internalValue.cpuRequest?.toString(),
    cpuLimit: internalValue.cpuLimit?.toString(),
    memoryRequest: internalValue.memoryRequest,
    memoryLimit: internalValue.memoryLimit,
    useGpu: internalValue.useGpu,
    gpuRequest: internalValue.gpuRequest?.toString(),
    gpuLimit: internalValue.gpuLimit?.toString(),
    gpuProduct: internalValue.gpuProduct,
  };
};

export type ErrorType = null | { [key: string]: string };

type OptionType = {
  value: string;
  label: string;
};

const K8sResourcesSelect: FC<Props & WrappedComponentProps> = (props) => {
  const { nodeInfo, form } = props;

  const {
    control,
    formState: { errors },
    watch,
    trigger,
  } = form;
  const watchUseGpu = watch('useGpu');

  const gpuAvailable = isGpuAvailable(nodeInfo);
  const gpuOptions = extractGpuProductOptions(nodeInfo);
  const attributes =
    useSelector((state: ToBeRefined) => state.currentUser?.attributes) || {};

  const renderTextInput = (
    key: keyof K8sResources,
    labelDefault: string,
    placeholderDefault: string,
    defaultValue: string
  ) => {
    return (
      <Controller
        name={key}
        control={control}
        defaultValue={defaultValue}
        rules={{
          validate: (_fieldValue, formValues) => {
            const getErrors = validate(
              convertInternalValuesToString(formValues),
              attributes
            );
            return getErrors?.[key] ?? true;
          },
        }}
        render={({ field }) => (
          <TextInputLine
            touched
            hasLabel
            labelDefault={labelDefault}
            placeholderDefault={placeholderDefault}
            valid={!errors[key]}
            error={errors[key]?.message}
            value={field.value?.toString()}
            onChange={(e) => {
              field.onChange(e);
              trigger();
            }}
            onBlur={() => {}}
            onFocus={() => {}}
          />
        )}
      />
    );
  };

  const renderCheckboxInput = (
    key: keyof K8sResources,
    label: string,
    disabled: boolean
  ) => {
    return (
      <Controller
        name={key}
        control={control}
        render={({ field }) => {
          return (
            <Checkbox
              label={{ id: 'no-id', defaultMessage: label }}
              checked={field.value as boolean}
              onChange={(e) => {
                field.onChange(!field.value);
              }}
              disabled={disabled}
            />
          );
        }}
      />
    );
  };

  const renderDropdownInput = (
    key: keyof K8sResources,
    label: string,
    placeholder: string,
    options: OptionType[]
  ) => {
    return (
      <Controller
        name={key}
        control={control}
        rules={{
          validate: (fieldValue, formValues) => {
            const getErrors = validate(
              convertInternalValuesToString(formValues),
              attributes
            );
            return getErrors?.[key] ?? true;
          },
        }}
        render={({ field }) => (
          <DropdownSelectInput
            id={'gpuProduct'}
            name={'gpuProduct'}
            valid={!errors[key]}
            error={errors[key]?.message}
            label={{
              id: 'no-id',
              defaultMessage: label,
            }}
            placeholder={{
              id: 'no-id',
              defaultMessage: placeholder,
            }}
            value={options.find((o) => o?.value === field.value?.toString())}
            onChange={(option: OptionType) => field.onChange(option?.value)}
            onBlur={() => {}}
            isLoading={false}
            options={options}
            clearable
          />
        )}
      />
    );
  };

  return (
    <div className={styles.k8sResourcesSelect}>
      <span className={styles.title}>Workbench Resources Select</span>
      <div className={styles.grid}>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              'cpuRequest',
              'CPU Request',
              'e.g. 1.0, 500m',
              attributes[WB_DEF_CPU_REQ] &&
                attributes[WB_DEF_CPU_REQ].length > 0 &&
                isValidK8sCpuSpec(attributes[WB_DEF_CPU_REQ][0])
                ? attributes[WB_DEF_CPU_REQ][0]
                : undefined
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              'cpuLimit',
              'CPU Limit',
              'e.g. 1.0, 500m',
              attributes[WB_DEF_CPU_LIM] &&
                attributes[WB_DEF_CPU_LIM].length > 0 &&
                isValidK8sCpuSpec(attributes[WB_DEF_CPU_LIM][0])
                ? attributes[WB_DEF_CPU_LIM][0]
                : undefined
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              'memoryRequest',
              'Memory Request',
              'e.g. 2Gi, 2.5Gi',
              attributes[WB_DEF_MEM_REQ] &&
                attributes[WB_DEF_MEM_REQ].length > 0 &&
                isValidK8sMemorySpec(attributes[WB_DEF_MEM_REQ][0], true)
                ? attributes[WB_DEF_MEM_REQ][0]
                : undefined
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.inputParent}>
            {renderTextInput(
              'memoryLimit',
              'Memory Limit',
              'e.g. 4Gi, 4.5Gi',
              attributes[WB_DEF_MEM_LIM] &&
                attributes[WB_DEF_MEM_LIM].length > 0 &&
                isValidK8sMemorySpec(attributes[WB_DEF_MEM_LIM][0], true)
                ? attributes[WB_DEF_MEM_LIM][0]
                : undefined
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.grid}>
        <div className={classNames(styles.col, styles.gpuAvailableCol)}>
          <div
            className={classNames(styles.inputParent, {
              [styles.checkboxDisabled]: !gpuAvailable,
            })}
          >
            {!gpuAvailable && (
              <span className={styles.noGpuAvailableInfo}>
                There is no node with a GPU available in the cluster
              </span>
            )}
            {renderCheckboxInput(
              'useGpu',
              'Schedule to GPU node',
              // disable this toggle if:
              !gpuAvailable || // no gpu
                !attributes[WB_MAX_GPU] || // attribute not set
                !(attributes[WB_MAX_GPU].length > 0) || // attribute not set
                !(
                  isValidK8sCpuSpec(attributes[WB_MAX_GPU][0], true, false) ||
                  attributes[WB_MAX_GPU][0] === '0'
                ) || // attribute invalid
                getCpuValueInMilliunits(attributes[WB_MAX_GPU][0]) <= 0 // limit <= 0
            )}
          </div>
        </div>
        {gpuAvailable && watchUseGpu && (
          <>
            <div className={styles.col}>
              <div className={styles.inputParent}>
                {renderTextInput(
                  'gpuLimit',
                  'Optional: GPU Limit',
                  'e.g. 1, 2',
                  undefined
                )}
              </div>
            </div>
            <div className={styles.col}>
              {renderDropdownInput(
                'gpuProduct',
                'Optional: Select the GPU type',
                'No GPU type selected',
                gpuOptions
              )}
            </div>
          </>
        )}
      </div>

      <DevTool control={control} />
    </div>
  );
};

export default injectIntl(K8sResourcesSelect);
