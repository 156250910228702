//import { TemplateData as initalQueueState } from './orchestration.sampleState';

export const initial = {
  loaded: false,
  loading: false,
  selectedCategory: 'queue',
  empty: false,
  // queue: initalQueueState,
  queue: {
    augurQueues: [],
    activities: [],
    loaded: false,
    loading: false,
    error: '',
  },
  error: '',
};

export const test = {
  loaded: false,
  loading: false,
  selectedCategory: 'queue',
  empty: false,
  queue: {
    augurQueues: [],
    activities: [],
    loaded: false,
    loading: false,
    error: '',
  },
  error: '',
};
