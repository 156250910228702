import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// TODO DEPRECATED in favor of js/components/atoms/InputError
// TODO cleanup
export default function InputError({ touched, error }) {
  const shown = !!(touched && error);

  if (!shown) {
    return null;
  }

  const isFormattedMessage = typeof error === 'object';

  const id = isFormattedMessage ? error.id : error;
  const values = isFormattedMessage ? error.values : {};

  return (
    <small className='error'>
      {isFormattedMessage ? (
        <FormattedMessage id={id} values={values} />
      ) : (
        <span>{error}</span>
      )}
    </small>
  );
}

const errorPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.object,
  }),
]);

InputError.propTypes = {
  touched: PropTypes.bool.isRequired,
  error: errorPropType,
};

InputError.defaultProps = {
  touched: false,
};
