import { createSelector } from 'reselect';

/**
 * Get the list of paths of all currently fetched (=opened in the Editor) notebooks
 * @param state
 * @returns {string[]}
 */
export const getFetchedNotebooks = createSelector(
  (state) => state.workbench.notebooks,
  (nbs) => {
    const nbNames = Object.keys(nbs);
    return nbNames.filter((nbName) => nbs[nbName]?.path); // criteria: the notebook must at least have the "path" property, otherwise better re-fetch it.
  }
);

/**
 * Get the list of paths of all currently fetched (=opened in the Editor) notebooks by their paneId
 * @param state
 * @returns {{[paneId: string]: string[]}}
 */
export const getPaneNotebooks = createSelector(
  (state) => state.workbench.panes,
  (panes) => {
    // Strip the Object belonging to each paneId down to the list of notebook paths
    return Object.fromEntries(
      Object.entries(panes).map(([paneId, pane]) => [
        paneId,
        pane.content.map((content) => content.path),
      ])
    );
  }
);

/**
 * Get the list of paths of all currently fetched and unchanged notebooks by their paneId
 * @param state
 * @returns {{[paneId: string]: string[]}}
 */
export const getPaneNotebooksNoUnsaved = createSelector(
  getPaneNotebooks,
  (state) => state.workbench.notebooks,
  (paneNbs, nbs) => {
    // Filter paneNbs down to the paths for which unsavedChanges is not true
    return Object.fromEntries(
      Object.entries(paneNbs).map(([paneId, nbPaths]) => [
        paneId,
        nbPaths.filter((nbPath) => !(nbs[nbPath]?.unsavedChanges === true)),
      ])
    );
  }
);

export function firstSelectedCellType(state, path) {
  const notebook = state.workbench.notebooks[path];
  if (!notebook) return null;

  const selectedCells = notebook.selectedCells;
  if (!selectedCells || selectedCells.length < 1) return null;

  const cellId = selectedCells[0];
  const cell = (notebook.content?.cells || []).find((c) => c.id === cellId);
  if (!cell) return null;

  return cell.cell_type;
}

export function firstSelectedCellIndex(state, path) {
  const notebook = state.workbench.notebooks[path];
  if (!notebook) return 0; // TODO This might cause problems in some cases?

  const selectedCells = notebook.selectedCells;
  if (!selectedCells || selectedCells.length < 1) return 0; // TODO This might cause problems in some cases?

  const cellId = selectedCells[0];
  return (notebook.content.cells || []).findIndex((c) => c.id === cellId);
}

/**
 * Returns all files in the currently selected path (of the private notebook = the browser on the left)
 * @param state
 * @returns {[]|*[]}
 */
export function notebooksInCurrentDir(state) {
  // TODO this should be named 'filesInCurrentDir' since it returns all files - not only the notebooks!
  if (!state.workbench || !state.workbench.content) return [];
  const dirs = state.workbench.content.selectedDirPath;
  if (!dirs) return [];

  if (state.workbench.content.error) {
    // There was an error loading the content - so the list of notebook can't be derived (since they weren't loaded at all)
    return [];
  }

  let content = state.workbench.content; // 'content' variable traverses the content tree in the redux state
  dirs.forEach((dir, i) => {
    if (i === 0) {
      // Root
      if (!content[dir]) return [];
      content = content[dir].content;
    } else {
      const found = content.find((c) => c.name === dir);
      if (found) content = found.content;
    }
  });
  return content;
}

export const getNotebookByPath = createSelector(
  (state) => state.workbench.notebooks,
  (state, path) => path,
  (nbs, path) => nbs[path]
);
