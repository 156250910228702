import { createAction } from 'redux-act';

const DEFAULT_TOOLTIP_DATA = Object.freeze([]);

export const showTooltip = createAction('show tooltip', (x, y, data) => ({
  x,
  y,
  data,
}));

export const hideTooltip = createAction('hide tooltip');

export const reducer = {
  [showTooltip]: (state, { x, y, data }) => ({
    ...state,
    accuracyDetail: {
      ...state.accuracyDetail,
      isTooltip: true,
      tooltipX: x,
      tooltipY: y,
      tooltipData: data,
    },
  }),

  [hideTooltip]: (state) => ({
    ...state,
    accuracyDetail: {
      ...state.accuracyDetail,
      isTooltip: false,
      tooltipX: 0,
      tooltipY: 0,
      tooltipData: DEFAULT_TOOLTIP_DATA,
    },
  }),
};
