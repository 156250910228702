import React, { Component } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import LogoIconColored from '../../atoms/icons/logo-colored/LogoIconColored';
import vars from '../../../../scss/base/var.module.scss';
import styles from './styles.module.scss';

const setDashoffset = function (el) {
  const l = el.getTotalLength();
  el.setAttribute('stroke-dasharray', l);
  return [l, 0];
};

export default class StartingAppInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStarting: false,
      clickAnimationFinished: true,
      autoStartPlainAnimation: false,
      processedMessageIndex: -1,
      message: '',
    };
    this.startCorrectAnimation = this.startCorrectAnimation.bind(this);
    this.processEventSourceMessage = this.processEventSourceMessage.bind(this);
    this.startOpenAnimation = this.startOpenAnimation.bind(this);
  }

  componentDidMount() {
    const { loaded, startApp, notebookUser, appVersionCode } = this.props;
    if (!this.state.isStarting) {
      this.setState({ isStarting: true });
      startApp(notebookUser, appVersionCode); // Send the spawn request to JupyterHub
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { loaded, eventSourceMessages } = this.props;
    // Treat 'loaded' switched from false to true
    if (prevProps.loaded === false && loaded === true) {
      this.startCorrectAnimation();
    }

    // Treat new messages
    if (
      eventSourceMessages &&
      eventSourceMessages.length > this.state.processedMessageIndex + 1
    ) {
      const latestIndex = eventSourceMessages.length - 1;
      const latestMessage = eventSourceMessages[latestIndex];
      this.setState({ processedMessageIndex: latestIndex });
      this.processEventSourceMessage(latestMessage);

      this.setState({
        message: latestMessage.raw_event ? latestMessage.raw_event.message : '',
      });
    }
  }

  /**
   * Called by componentDidMount or componendDidUpdate when the spawn request returns (marked by loaded=true)
   */
  startCorrectAnimation() {
    const { eventSourceAvailable } = this.props;

    if (eventSourceAvailable) {
      // Something to do?
    } else {
      this.startPlainAnimation();
    }
  }

  /**
   * Processes EventSource messages
   * @param message
   */
  processEventSourceMessage(message) {
    const { progress, ready } = message;

    // --- Adjust the Progress bar
    const basicTimeline = anime.timeline({
      autoplay: false,
    });
    basicTimeline.add({
      targets: '#start-server-button--progress-bar',
      duration: 1200,
      width: 300 * (progress / 100),
      easing: 'easeInOutSine',
    });
    basicTimeline.play();

    // --- If ready fire the action to check and open the Workbench
    if (ready) {
      this.startOpenAnimation();
    }
  }

  startOpenAnimation() {
    const { checkWhetherAppIsRunning, appVersionCode, notebookUser } =
      this.props;
    const basicTimeline = anime.timeline({
      autoplay: false,
      complete: () =>
        checkWhetherAppIsRunning(notebookUser, appVersionCode, true),
    });
    basicTimeline
      .add({
        targets:
          '#start-server-button-parent, #start-server-headline-parent, #start-server-message-parent',
        duration: 500,
        opacity: 0,
        easing: 'easeInOutSine',
      })
      .add({
        targets: '#start-server-parent',
        delay: 500,
        duration: 1000,
        width: 1170,
        height: document.body.scrollHeight - 151,
        easing: 'easeInOutSine',
      });
    basicTimeline.play();
  }

  /**
   * Animation if the EventSource is not available
   */
  startPlainAnimation() {
    const { checkWhetherAppIsRunning, appVersionCode, notebookUser } =
      this.props;
    const basicTimeline = anime.timeline({
      autoplay: false,
      complete: () =>
        checkWhetherAppIsRunning(notebookUser, appVersionCode, true),
    });

    basicTimeline
      .add({
        targets: '#start-server-button--progress-bar',
        duration: 2000,
        width: 300,
        easing: 'linear',
      })
      .add({
        targets: '#start-server-button--button',
        width: 0,
        duration: 1,
      })
      .add({
        targets: '#start-server-button--progress-bar',
        width: 80,
        height: 80,
        delay: 500,
        duration: 750,
        borderRadius: 80,
        backgroundColor: vars.colorPrimary,
      })
      .add({
        targets: '#start-server-button--logo',
        opacity: 1,
        delay: 0,
        duration: 1,
      })
      .add({
        targets: '#start-server-button--progress-bar',
        opacity: 0,
        delay: 0,
        duration: 1500,
      });

    basicTimeline.play();
  }

  render() {
    return (
      <div className={styles.startServerParent} id={'start-server-parent'}>
        <div
          className={styles.startServerHeadlineParent}
          id={'start-server-headline-parent'}
        >
          <span
            className={styles.startServerHeadline}
            id={'start-server-headline'}
          >
            Starting the AI App
          </span>
        </div>
        <div
          className={styles.startServerButtonParent}
          id={'start-server-button-parent'}
        >
          <div className={styles.startServerButton} id={'start-server-button'}>
            <div
              style={{
                height: 10,
                width: 300,
                backgroundColor: '#dce2eb',
                border: '0',
                borderTopLeftRadius: 100,
                borderBottomLeftRadius: 100,
                borderTopRightRadius: 100,
                borderBottomRightRadius: 100,
              }}
              id={'start-server-button--button'}
              className={styles.startServerButtonButton}
            />
            <div
              className={styles.startServerButtonProgressBar}
              id={'start-server-button--progress-bar'}
            />
            <div
              className={styles.startServerButtonLogo}
              id={'start-server-button--logo'}
            >
              <LogoIconColored />
            </div>
          </div>
        </div>
        <div
          className={styles.startServerMessageParent}
          id={'start-server-message-parent'}
        >
          <span>{this.state.message}</span>
        </div>
      </div>
    );
  }
}

StartingAppInfo.propTypes = {
  /** User to start the notebook for */
  notebookUser: PropTypes.string.isRequired,
  /** Sends the spawn request to JupyterHub */
  startApp: PropTypes.func.isRequired,
  /** redux-action to check whether the notebook is running. If the notebook is running, it is marked in the redux
   * which causes Workbench.jsx to switch into the running screen */
  checkWhetherAppIsRunning: PropTypes.func.isRequired,
  /** true if the spawn request is pending (only the request - this doesn't have to do anything with the spawning
   * status of the notebook) */
  loading: PropTypes.bool,
  /** true if the spawn request succeeded (only the request - this doesn't have to do anything with the spawning status
   * of the notebook) */
  loaded: PropTypes.bool,
  /** possible error during the spawn request (only the request - this doesn't have to do anything with the spawning
   * status of the notebook) */
  error: PropTypes.string,
  /** Was the event source reachable? */
  eventSourceAvailable: PropTypes.bool,
  /** Array of received eventSource messages (in the ordering they were received by the eventSource) */
  eventSourceMessages: PropTypes.arrayOf(
    PropTypes.shape({
      /** Progress between 0 - 100 */
      progress: PropTypes.number,
      /** Message */
      message: PropTypes.string,
      /** Is the notebook ready? Only set if progress=100, I think */
      ready: PropTypes.bool,
    })
  ),
  appVersionCode: PropTypes.string.isRequired,
};
