import { validate } from './validation';
import { ConfigProps } from 'redux-form';
import { checkRepoNameExists } from '../../../../redux/modules/repo.checkNameExists.module';

export const formName = 'addRepository';

// --- General fields, shown for all repositoryTypes
export const fieldName = 'repositoryName';
export const fieldDescription = 'repositoryDescription';
export const fieldType = 'repositoryType';

// --- Fields for repositoryType='code-capsule'
export const fieldCodeCapsuleHabitat = 'codeCapsuleHabitat';

// --- Fields for repositoryType='archetype'
export const fieldKernel = 'archetypeKernel';
export const fieldArchHabitatDatapool = 'archetypeHabitatDatapool';

export const RepositoryTypeIds = {
  PLAIN: 'plain',
  CODE_CAPSULE: 'code-capsule',
  APP: 'app',
  ARCHETYPE: 'archetype',
};

export const availableRepositoryTypes = [
  {
    name: RepositoryTypeIds.PLAIN,
    displayName: 'Plain',
  },
  {
    name: RepositoryTypeIds.CODE_CAPSULE,
    displayName: 'Code Capsule',
  },
  {
    name: RepositoryTypeIds.APP,
    displayName: 'App',
  },
];

// --- The complete form object passed to redux-form
export const repositoryForm: ConfigProps = {
  form: formName,
  validate,
  asyncValidate: (allValues, dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(
        // @ts-ignore
        checkRepoNameExists(allValues[fieldName]?.slug, { resolve, reject })
      );
    }).then(({ exists }) =>
      exists
        ? Promise.reject({
            [fieldName]: {
              slug: {
                id: 'newRepo.stepOne.error.this_name_already_exists',
                defaultMessage: 'This name exists already.',
              },
            },
          })
        : null
    ),
  asyncChangeFields: [fieldName],
};
