import React, { FC } from 'react';
import { S3Object } from '../../../../../store/dataManagement/state.types';
import { dataManagementRoutes } from '../../../routes';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FiFolder } from 'react-icons/all';
import { RouteComponentProps } from 'react-router';

const DirectoryElement: FC<
  {
    dataSourceCode: string;
    bucket: string;
    element: S3Object;
  } & RouteComponentProps
> = (props) => {
  const { dataSourceCode, bucket, element, history } = props;

  const linkTo = `${
    dataManagementRoutes.basePath
  }/s3/${dataSourceCode}/bucket/${bucket}/content/${(
    element.path || ''
  ).replace(/^(\/)/, '')}`;

  return (
    <tr
      onClick={() => history.push(linkTo)}
      className={classNames(styles.row, styles.rowDirectory)}
    >
      <td className={styles.colIcon}>
        <FiFolder size={'16px'} />
      </td>
      <td className={styles.colName}>{element.name}</td>
      <td className={styles.colCreatedAt} />
      <td className={styles.colSize} />
      <td className={styles.colActions} />
    </tr>
  );
};

export default withRouter(DirectoryElement);
