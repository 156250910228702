import { MessageDescriptor } from 'react-intl';
import React from 'react';

export const formName = 'newDatapool';

export const fieldModuleType = 'moduleType';

// --- Props that are passed to reduxForm() of all NewDatapoolWizards
export const commonReduxFormProps = {
  form: formName,
  /** Needed to allow for initial values in the specific wizard **/
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
};

// --- Headline
export const wizardHeadline: (habitatName: string) => MessageDescriptor = (
  habitatName: string
) => ({
  id: 'no-id', // 'newAugur.header.header',
  defaultMessage: 'Create a new Datapool in Habitat{space}<b>{habitatName}</b>',
  values: {
    habitatName,
    space: <span style={{ width: '5px' }} />, // Otherwise the space is removed by react-intl ...
    b: (chunks) => <span style={{ fontWeight: 400 }}>{chunks}</span>,
  },
});
