import { line } from 'd3-shape';

import {
  getX,
  getY,
  getRunData,
} from '../../../../../../../../../core/common/accuracyDetail';

export default function append(node, xScale, yScale, run, stroke) {
  const strokeLine = line()
    .x((d) => xScale(getX(d)))
    .y((d) => yScale(getY(d)));
  const strokeLinePath = strokeLine(getRunData(run));

  node
    .append('path')
    .attr('d', strokeLinePath)
    .attr('stroke', stroke)
    .attr('fill', 'none')
    .attr('stroke-width', 1.5);
}
