import React, { Component } from 'react';
import Busy from '../atoms/busy/Busy';
import MainContainer from '../pages/main-container/MainContainer';
import ElementCard from '../molecules/element-card/ElementCard';
import { appsRoutes } from './routes';
import { AppVersion } from 'common/dist/types/app';
import vars from '../../../scss/base/var.module.scss';

interface AppsOverviewProps {
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  data?: {
    name?: string;
    code?: string;
    repoCode?: string;
    versions?: AppVersion[];
  }[];
  fetchApps(...args: unknown[]): unknown;
}
export default class AppsOverview extends Component<AppsOverviewProps> {
  componentDidMount() {
    const { fetchApps } = this.props;
    fetchApps('false');
  }

  renderLoaded() {
    const { data } = this.props;
    const appsWithVersions = data.filter((app) => app.versions.length > 0);
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={true}
        fullWidth={false}
        scrollableY={true}
      >
        <div className={'apps-overview'}>
          {appsWithVersions.map((app) => {
            const amountVersions = app.versions ? app.versions.length : 0;
            const amountVersionsText = `${amountVersions} ${
              amountVersions === 1 ? 'Version' : 'Versions'
            }`;

            const sortedVersions = app.versions;
            sortedVersions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
            const latestVersion = sortedVersions[0];

            return (
              <ElementCard
                header={app.name}
                subHeader={amountVersionsText}
                info={`Current Version: ${latestVersion.number}`}
                barColor={vars.colorPrimaryHighlight}
                linkTo={`${appsRoutes.basePath + appsRoutes.runPath}/${
                  app.code
                }/${latestVersion.code}`}
                key={app.code}
              />
            );
          })}
        </div>
      </MainContainer>
    );
  }

  renderLoading() {
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={true}
        fullWidth={false}
      >
        <div className={'apps-loading'}>
          <Busy isBusy />
        </div>
      </MainContainer>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={false}
        fullWidth={false}
      >
        <div className={'apps-overview'}>
          <span>Error: {JSON.stringify(error)}</span>
        </div>
      </MainContainer>
    );
  }

  renderEmpty() {
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={false}
        fullWidth={false}
      >
        <div className={'apps-empty'}>
          <div className={'apps-empty-elements'}>
            <div className={'apps-empty-pic'}>&nbsp;</div>
            <span className={'headline'}>There are no Apps yet.</span>
            <span className={'description'}>
              Please create and build an App in the Workbench or ask an
              Administrator to grant permissions on an existing App to you
            </span>
          </div>
        </div>
      </MainContainer>
    );
  }

  renderContent() {}

  render() {
    const { loading, error, data } = this.props;
    const filteredData = (data || []).filter(
      (app) => app.versions && app.versions.length > 0
    );
    if (loading && filteredData.length === 0) return this.renderLoading();
    else if (error) return this.renderError();
    else if (filteredData.length === 0) return this.renderEmpty();
    return this.renderLoaded();
  }
}
