import React, { FC, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import * as render from './render/render';
import TooltipContainer from './tooltip/Tooltip.container';
import { DataEntry } from '../../types';

type Props = {
  height: number;
  top: number;
  left: number;
  data: DataEntry[];
};

const DetailChart: FC<Props> = (props) => {
  const { height, top, left } = props;
  const chartRef = useRef();

  useEffect(() => {
    const node = ReactDOM.findDOMNode(chartRef.current);
    render.start(node, props);
  }, [props]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div ref={chartRef} />
      <TooltipContainer top={top} left={left} containerHeight={height} />
    </div>
  );
};

export default DetailChart;
