import { connect } from 'react-redux';
import WizardUpload, { InnerProps, Props } from './WizardUpload';
import { destroy, getFormValues, reduxForm } from 'redux-form';
import { uploadFile } from '../../../../../redux/modules/data.s3.module';
import {
  ErrorType,
  fieldBucket,
  fieldPath,
  formName,
  initialValues,
  uploadForm,
  UploadWizardS3Data,
} from './form';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { S3_BUCKET_SCOPE_IDS } from 'common/dist/constants/keycloak';
import { injectIntl } from 'react-intl';

export function mapStateToProps(
  state,
  { dataSourceCode, location }: Props & RouteComponentProps
) {
  const { uploading, uploaded, progress } = state.data.upload || {};

  const queryParameter = qs.parse(location.search, { ignoreQueryPrefix: true });
  const bucketsData = state.data.s3[dataSourceCode]?.buckets?.data || [];
  const permissionsData =
    state.data.s3[dataSourceCode]?.permissions?.data || {};
  const availableBucketNames = bucketsData
    ?.filter((k) =>
      new Set(permissionsData?.[k.name]).has(S3_BUCKET_SCOPE_IDS.WRITE)
    )
    .map((b) => b.name);

  const formValues: UploadWizardS3Data = getFormValues(formName)(state);

  let enrichedInitialValues = initialValues;
  if (
    queryParameter.bucketName &&
    availableBucketNames.includes(queryParameter.bucketName)
  ) {
    enrichedInitialValues = {
      ...enrichedInitialValues,
      [fieldBucket]: {
        ...enrichedInitialValues[fieldBucket],
        name: queryParameter.bucketName as string,
      },
    };
    if (queryParameter.bucketPath) {
      enrichedInitialValues = {
        ...enrichedInitialValues,
        [fieldPath]: {
          ...enrichedInitialValues[fieldPath],
          name:
            ((enrichedInitialValues[fieldPath].name +
              queryParameter.bucketPath) as string) + '/',
        },
      };
    }
  }

  return {
    formValues,
    uploading,
    uploaded,
    progress,
    initialValues: enrichedInitialValues,
  };
}

export const mapDispatchToProps = {
  uploadFile,
  destroyUploadForm: () => destroy(formName),
};

export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      reduxForm<UploadWizardS3Data, InnerProps, ErrorType>(uploadForm)(
        WizardUpload
      )
    )
  )
);
