import { connect } from 'react-redux';
import ExecutionError from './ExecutionError';

export function mapStateToProps(state) {
  const { executionPlan, activeStep } = state.workbench.app;
  return {
    executionPlan,
    activeStep,
  };
}

export default connect(mapStateToProps)(ExecutionError);
