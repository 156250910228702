import * as React from 'react';

function SvgApp(props) {
  return (
    <svg viewBox='0 0 512 512' width='1em' height='1em' {...props}>
      <circle fill='#eef0f2' r={256} cy={256} cx={256} />
      <path
        fill='#dce2eb'
        d='M290.947 509.616C415.796 492.57 512 385.529 512 256c0-14.165-1.169-28.057-3.382-41.596L394.121 100.047l-267.988 207.67-8.604 18.51 76.073 75.678-6.513 4.748 103.858 102.963z'
      />
      <path fill='#E6F3FF' d='M306.855 398.222h-101.71l16.951-85.333h67.808z' />
      <path fill='#CFDBE6' d='M289.904 312.889H256v85.333h50.855z' />
      <path
        fill='#FFF'
        d='M383.045 331.421h-254.09c-8.383 0-15.177-6.796-15.177-15.177V110.423c0-8.383 6.796-15.177 15.177-15.177h254.088c8.383 0 15.177 6.796 15.177 15.177v205.819c.002 8.383-6.793 15.179-15.175 15.179z'
      />
      <path
        fill='#E6F3FF'
        d='M383.045 95.246H256v236.175h127.043c8.383 0 15.177-6.796 15.177-15.179V110.425c.002-8.384-6.793-15.179-15.175-15.179z'
      />
      <path
        fill='#CFDBE6'
        d='M318.666 409.428H193.334a8.444 8.444 0 01-8.445-8.445v-3.796a8.444 8.444 0 018.445-8.445h125.331a8.444 8.444 0 018.445 8.445v3.796a8.443 8.443 0 01-8.444 8.445z'
      />
      <path
        fill='#B8C9D9'
        d='M318.666 388.741H256v20.687h62.666a8.444 8.444 0 008.445-8.445v-3.796a8.444 8.444 0 00-8.445-8.446z'
      />
      <path fill='#324A5E' d='M120.673 104.296h270.653v162.047H120.673z' />
      <path fill='#2B3B4E' d='M256 104.296h135.327v162.047H256z' />
      <circle fill='#CFDBE6' r={9.481} cy={288.754} cx={256} />
      <path
        fill='#B8C9D9'
        d='M265.481 288.754a9.48 9.48 0 00-9.481-9.481v18.963a9.48 9.48 0 009.481-9.482z'
      />
      <path fill='#FFD15D' d='M348.436 142.222h21.333v85.333h-21.333z' />
      <path fill='#FED8B2' d='M316.44 163.547h21.333v63.991H316.44z' />
      <path fill='#F9B54C' d='M284.444 184.889h21.333v42.667h-21.333z' />
    </svg>
  );
}

export default SvgApp;
