import { ComponentType } from 'react';
import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import Realtime from './Realtime.container';
import RealtimeIcon from './RealtimeIcon';
import { wrapRealtimeWithServingTypes } from './RealtimeWrapper';
import { MessageDescriptor } from 'react-intl';

export default class RealtimeWrapper extends ComponentAugurDetailsTab {
  /**
   * @param availableServingTypes List of available serving types
   */
  constructor(availableServingTypes: string[]) {
    super();
    this.component = wrapRealtimeWithServingTypes(
      Realtime,
      availableServingTypes
    );
  }

  component: ComponentType; // Filled in the constructor with the enhanced version of the Realtime Component
  icon: ComponentType = RealtimeIcon;
  id = 'realtime';
  to = (habitatCode: string, augurCode: string, shortAugurType: string) =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/realtime`;
  subRoutes: object[] | undefined = undefined;
  title: MessageDescriptor = {
    id: 'details.sideNav.realtime',
    defaultMessage: 'Realtime Deployment',
  };
}
