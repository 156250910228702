import { connect } from 'react-redux';
import StepUpload from './StepUpload';
import { resetUploadFile } from '../../../../../../redux/modules/data.cassandra.module';

export function mapStateToProps(state) {
  const { uploading, uploaded, progress, error } = state.data.upload || {};
  const analysis = (state.data.upload || {}).analysis;

  return {
    uploading,
    uploaded,
    progress,
    error,
    analysis,
  };
}

export const mapDispatchToProps = {
  resetUploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepUpload);
