import { connect } from 'react-redux';
import OverlappingMenu from './OverlappingMenu';

export function mapStateToProps(state) {
  return {
    overlappingTabs: state.workbench.overlappingNotebookTabs.overlappingTabs,
  };
}

export default connect(mapStateToProps)(OverlappingMenu);
