import { connect } from 'react-redux';
import Arranger from './Arranger';
import {
  collapseArranger,
  expandArranger,
  updateLayouts,
} from '../../../../redux/workbench/modules/app.module';
import { saveNotebookByPath } from '../../../../redux/workbench/modules/notebook.module';

export function mapStateToProps(state) {
  return {
    isArrangerExpanded: state.workbench.app.isArrangerExpanded,
    currentBreakpoint: state.workbench.app.currentBreakpoint,
  };
}

export const mapDispatchToProps = {
  updateLayouts,
  expandArranger,
  collapseArranger,
  saveNotebookByPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(Arranger);
