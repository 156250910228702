import {
  defaultAsyncBlurFields,
  defaultAsyncChangeFields,
  DefaultFormValues,
  defaultValidate,
  fieldAugurName,
  fieldModuleType,
  getDefaultMpwAsyncValidate,
} from '../../../../../newAugur/defaultWizard/default.form';
import { validateDatapool } from '../../../../../organisms/modelManagement/newAugurWizard/datapool/validate';
import { validateArchetype } from '../../../../../organisms/modelManagement/newAugurWizard/archetype/validate';
import { Props } from './NaturalLanguageTaggingNewAugurWizard';
import _ from 'lodash';
import { PostAugurRequestBody } from 'common/dist/types/requestBodies/augurs';
import { AugurSettingsDataNlt } from 'common/dist/types/augurSettings';

// --- Field names
export const fieldDatapool = 'datapool';
export const fieldArchetype = 'archetype';
export const fieldDictionary = 'dictionary';

// --- Type of the form values
export type NaturalLanguageTaggingFormValues = DefaultFormValues & {
  [fieldArchetype]: {
    /** Code of the selected archetype */
    code?: string;
    /** Standard or custom archetype?  */
    origin?: 'stock' | 'custom';
    /** Selected version number */
    versionNumber?: string;
  };
};

// --- Sync validation

export const isValidJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const validateDictionary = (dictionary) => {
  if (!dictionary || _.isEmpty(dictionary)) {
    return {
      id: 'no-id',
      defaultMessage: 'Please enter values for your dictionary',
    };
  }
  if (!isValidJsonString(dictionary)) {
    return {
      id: 'no-id',
      defaultMessage: 'Please enter a valid json for your dictionary',
    };
  }

  return undefined;
};

export const naturalLanguageTaggingValidate = (
  values: NaturalLanguageTaggingFormValues,
  props: { _reduxForm: Props }
) => {
  const errors = defaultValidate(values) || {};
  errors[fieldDatapool] = validateDatapool(values[fieldDatapool]);
  errors[fieldArchetype] = validateArchetype(values[fieldArchetype]);

  // Validate dictionary
  errors[fieldDictionary] = validateDictionary(values[fieldDictionary]);

  return errors;
};

// --- Async validation
export function getNaturalLanguageTaggingMpwAsyncValidate(habitatCode: string) {
  return getDefaultMpwAsyncValidate(habitatCode);
  // extend with .then()... or Promise.all() ... if further async validation is necessary
}

export const naturalLanguageTaggingAsyncBlurFields = [
  ...defaultAsyncBlurFields,
];
export const naturalLanguageTaggingAsyncChangeFields = [
  ...defaultAsyncChangeFields,
];

export const getNaturalLanguageTaggingSubmitSummary = (
  formValues: NaturalLanguageTaggingFormValues,
  habitatCode: string
): PostAugurRequestBody<AugurSettingsDataNlt> => {
  const archetypeCode = (formValues[fieldArchetype] || {}).code;
  const archetypeVersionNumber = (formValues[fieldArchetype] || {})
    .versionNumber;

  return {
    // Habitat Code
    habitatCode,

    // Module type
    type: formValues[fieldModuleType],

    // Datapool
    datapoolCode: formValues[fieldDatapool]?.code,

    // Archetype
    archetypeCode,
    archetypeVersionNumber,

    // Augur name
    name: formValues[fieldAugurName],

    // --- Settings
    settings: {
      dictionary: formValues[fieldDictionary],
    },
  };
};
