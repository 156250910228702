import { connect } from 'react-redux';
import ConfigMap from './ConfigMap';
import {
  configMap,
  edit,
} from '../../../redux/selectors/orchestration.jobconfigs.selector';
import {
  triggerEditConfigmap,
  editConfigmap,
  cancelEditConfigmap,
  confirmEditConfigmap,
} from '../../../redux/modules/orchestration.jobconfigs.module';

export function mapStateToProps(state, props) {
  const editState = edit(state);
  const editTuned =
    (editState && editState.cmName) === props.cmName ? editState : {}; // Only pass down the 'edit' if the current Configmap is being edited to tune the performance.
  return {
    configMap: configMap(state, props.cmName),
    edit: editTuned,
  };
}

export const mapDispatchToProps = {
  triggerEditConfigmap,
  editConfigmap,
  cancelEditConfigmap,
  confirmEditConfigmap,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigMap);
