export const resourceRoutes = {
  basePath: '/app/admin/resources',

  runningWorkbench: {
    path: 'runningWorkbench',
  },

  runningApps: {
    path: 'runningApps',
  },
};
