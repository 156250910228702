import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getElementByType as getInputElementByType } from '../../fileTypes/notebook/component/notebook-cells/cell-implementations/app-cells/python3-input-cell/elements/_interface/Python3InputElementManager';
import { getElementByType as getOutputElementByType } from '../../fileTypes/notebook/component/notebook-cells/cell-implementations/app-cells/python3-output-cell/elements/_interface/Python3OutputElementManager';
import {
  FiChevronDown,
  FiChevronUp,
  FiSave,
  FiArrowDown,
} from 'react-icons/fi';
import { ItemTypes } from './dndConstants';
import { useDrag } from 'react-dnd';

export const UnlayoutedInputElement = ({ e, inputOrOutputCell, path }) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.UNARRANGED_APP_ELEMENT,
      id: e.id,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      key={e.id}
      className={'unlayouted-element'}
      draggable
      unselectable={'on'}
    >
      {getInputElementByType(e.type).renderWrappedComponentForApp({
        cell: inputOrOutputCell,
        path,
        element: e,
      })}
    </div>
  );
};
UnlayoutedInputElement.propTypes = {
  e: PropTypes.object,
  inputOrOutputCell: PropTypes.string,
  path: PropTypes.string,
};

export const UnlayoutedOutputElement = ({ e, inputOrOutputCell, path }) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.UNARRANGED_APP_ELEMENT,
      id: e.id,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      key={e.id}
      className={'unlayouted-element'}
      draggable
      unselectable={'on'}
    >
      {getOutputElementByType(e.type).renderWrappedComponentForApp({
        cell: inputOrOutputCell,
        path,
        element: e,
      })}
    </div>
  );
};
UnlayoutedOutputElement.propTypes = {
  e: PropTypes.object,
  inputOrOutputCell: PropTypes.string,
  path: PropTypes.string,
};

export default class Arranger extends Component {
  renderInput(unlayoutedElements, inputOrOutputCell, path) {
    return unlayoutedElements.map((e) => (
      <UnlayoutedInputElement
        inputOrOutputCell={inputOrOutputCell}
        path={path}
        e={e}
      />
    ));
  }

  renderOutput(unlayoutedElements, inputOrOutputCell, path) {
    return unlayoutedElements.map((e) => (
      <UnlayoutedOutputElement
        inputOrOutputCell={inputOrOutputCell}
        path={path}
        e={e}
      />
    ));
  }

  renderUnlayoutedElements(
    activeStepType,
    unlayoutedElements,
    inputOrOutputCell,
    path
  ) {
    if (activeStepType === 'input') {
      return this.renderInput(unlayoutedElements, inputOrOutputCell, path);
    } else if (activeStepType === 'output') {
      return this.renderOutput(unlayoutedElements, inputOrOutputCell, path);
    } else {
      return <div />;
    }
  }

  renderFlap(unlayoutedElements) {
    const { isArrangerExpanded, expandArranger, collapseArranger } = this.props;
    return (
      <Fragment>
        <div
          className={'expand-collapse-amount-info'}
          style={{
            width: unlayoutedElements.length > 0 ? 180 : 0,
          }}
          onClick={() =>
            isArrangerExpanded ? collapseArranger() : expandArranger()
          }
        >
          {unlayoutedElements.length === 1 && (
            <span>{unlayoutedElements.length} unarranged element</span>
          )}
          {unlayoutedElements.length > 1 && (
            <span>{unlayoutedElements.length} unarranged elements</span>
          )}
        </div>
        <div
          className={`expand-collapse-flap${
            unlayoutedElements.length > 0 ? ' not-empty' : ''
          }`}
          onClick={() =>
            isArrangerExpanded ? collapseArranger() : expandArranger()
          }
        >
          {isArrangerExpanded ? (
            <FiChevronDown size={16} />
          ) : (
            <FiChevronUp size={16} />
          )}
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      activeStep,
      executionPlan,
      activeStepType,
      path,
      isArrangerExpanded,
      currentBreakpoint,
      saveNotebookByPath,
      updateLayouts,
    } = this.props;
    if (!executionPlan || executionPlan.length < activeStep) return <div />; // Shouldn't happen, but just to be very sure.
    const activeExecutionPlanStep = executionPlan[activeStep];
    const inputOrOutputCell =
      activeExecutionPlanStep.cells && activeExecutionPlanStep.cells.length > 0
        ? activeExecutionPlanStep.cells[0]
        : {}; // This case only occurs if the very first cell of the notebook immediately is an Input or Output Cell (and no code cell for example)

    const elements = inputOrOutputCell.as_elements || [];
    const layouts = inputOrOutputCell.layouts; // || deriveDefaultInputLayouts(elements);

    // Determine the elements that are not contained in the layout (yet)
    // If layouts isn't defined yet, all elements have to be treated as unlayouted.
    const unlayoutedElements = layouts
      ? elements.filter(
          (e) => !layouts[currentBreakpoint].map((l) => l.i).includes(e.id)
        )
      : elements;

    return (
      <div className={'app-part-arranger'}>
        {this.renderFlap(unlayoutedElements)}
        <div className={'button-bar workbench-buttons'}>
          <div
            className={'workbench-button'}
            onClick={() => saveNotebookByPath(path)}
          >
            <FiSave className={'icon save-icon'} size={'20px'} />
          </div>
          <div
            className={'workbench-button'}
            onClick={() => {
              const emptyLayout = {};
              Object.keys(layouts).forEach(
                (breakpoint) => (emptyLayout[breakpoint] = [])
              );
              updateLayouts(path, inputOrOutputCell.id, emptyLayout);
            }}
          >
            <FiArrowDown className={'icon clear-icon'} size={'20px'} />
          </div>
        </div>
        <div
          className={'parent-unlayouted'}
          style={{
            height: isArrangerExpanded ? 170 : 0,
            paddingTop: isArrangerExpanded ? 10 : 0,
            paddingBottom: isArrangerExpanded ? 10 : 0,
            borderTopWidth: isArrangerExpanded ? 1 : 0,
          }}
        >
          {this.renderUnlayoutedElements(
            activeStepType,
            unlayoutedElements,
            inputOrOutputCell,
            path
          )}
        </div>
      </div>
    );
  }
}
Arranger.propTypes = {
  /** Index of the active (=visible) execution plan step */
  activeStep: PropTypes.number.isRequired,
  executionPlan: PropTypes.arrayOf(
    PropTypes.shape({
      /** input|output|beginning */
      type: PropTypes.string.isRequired,
      /** waiting|executing|success|failure */
      status: PropTypes.string.isRequired,
      /** Cells for this executionPlan element (duplicate to the notebook.content.cells */
      cells: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  /** Type of the active execution plan step: beginning | input | output */
  activeStepType: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  isArrangerExpanded: PropTypes.bool,
  expandArranger: PropTypes.func.isRequired,
  collapseArranger: PropTypes.func.isRequired,
  currentBreakpoint: PropTypes.string,
  saveNotebookByPath: PropTypes.func.isRequired,
  updateLayouts: PropTypes.func.isRequired,
};

Arranger.defaultProps = {
  isArrangerExpanded: true,
  currentBreakpoint: 'xs',
};
