import {
  defaultAsyncBlurFields,
  defaultAsyncChangeFields,
  DefaultFormValues,
  defaultValidate,
  fieldAugurName,
  fieldModuleType,
  getDefaultMpwAsyncValidate,
} from '../../../../../newAugur/defaultWizard/default.form';
import { validateDatapool } from '../../../../../organisms/modelManagement/newAugurWizard/datapool/validate';
import { validateArchetype } from '../../../../../organisms/modelManagement/newAugurWizard/archetype/validate';
import { validateTrainingTable } from '../../../../../organisms/modelManagement/newAugurWizard/trainingTable/validate';
import { validatePredictionTable } from '../../../../../organisms/modelManagement/newAugurWizard/inputTable/validate';
import { validateSamplingStrategy } from '../../../../../organisms/modelManagement/newAugurWizard/samplingStrategy/validate';
import { Props } from './BinaryClassificationNewAugurWizard';
import {
  parseAllPipelineParameterValues,
  validatePipelineTuningField,
} from '../../../../../organisms/pipeline-tuning/pipeline-tuning-selection/formHelpers';
import { validateParameterTuningField } from '../../../../../organisms/parameter-tuning/parameter-tuning-selection/form';
import { extractParametersFromForm } from '../../../../../organisms/parameter-tuning/parameter-tuning-selection/formHelper';
import { DecoratedFormProps, FormErrors } from 'redux-form';
import { PostAugurRequestBody } from 'common/dist/types/requestBodies/augurs';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

// --- Field names
export const fieldDatapool = 'datapool';
export const fieldArchetype = 'archetype';
export const fieldTrainingTable = 'trainingTable';
export const fieldPredictionTable = 'predictionTable';
export const fieldSamplingStrategy = 'samplingStrategy';
export const fieldPipelineTuning = 'pipelineTuning';
export const fieldParameterTuning = 'parameterTuning';
export const fieldPredictionTargets = 'predictionTargets';

// --- Type of the form values
export type BinaryClassificationFormValues = DefaultFormValues & {
  [fieldArchetype]: {
    /** Code of the selected archetype */
    code?: string;
    /** Standard or custom archetype?  */
    origin?: 'stock' | 'custom';
    /** Selected version number */
    versionNumber?: string;
  };
};

// --- Sync validation
type ErrorType = Record<string, unknown>;
export const binaryClassificationValidate = (
  values: BinaryClassificationFormValues,
  {
    pipelineTuningSchemas,
    parameterTuningSchema,
  }: DecoratedFormProps<BinaryClassificationFormValues, Props, ErrorType>
): FormErrors<BinaryClassificationFormValues, ErrorType> => {
  const errors: FormErrors<BinaryClassificationFormValues, ErrorType> =
    defaultValidate(values) || {};
  errors[fieldDatapool] = validateDatapool(values[fieldDatapool]);
  errors[fieldArchetype] = validateArchetype(values[fieldArchetype]);
  errors[fieldTrainingTable] = validateTrainingTable(
    values[fieldTrainingTable]
  );
  errors[fieldPredictionTable] = validatePredictionTable(
    values[fieldPredictionTable]
  );
  errors[fieldSamplingStrategy] = validateSamplingStrategy(
    values[fieldSamplingStrategy]
  );
  errors[fieldPipelineTuning] = validatePipelineTuningField(
    values[fieldPipelineTuning],
    pipelineTuningSchemas
  );
  errors[fieldParameterTuning] = validateParameterTuningField(
    values[fieldParameterTuning],
    parameterTuningSchema
  );

  return errors;
};

// --- Async validation
export function getBinaryClassificationMpwAsyncValidate(habitatCode: string) {
  return getDefaultMpwAsyncValidate(habitatCode);
  // extend with .then()... or Promise.all() ... if further async validation is necessary
}

export const binaryClassificationAsyncBlurFields = [...defaultAsyncBlurFields];
export const binaryClassificationAsyncChangeFields = [
  ...defaultAsyncChangeFields,
];

export const getBinaryClassificationSubmitSummary = (
  formValues: BinaryClassificationFormValues,
  habitatCode: string
): PostAugurRequestBody<AugurSettingsDataNbc> => {
  const archetypeCode = (formValues[fieldArchetype] || {}).code;
  const archetypeVersionNumber = (formValues[fieldArchetype] || {})
    .versionNumber;

  let parameterTuning = extractParametersFromForm(
    formValues[fieldParameterTuning]
  );
  if (
    !parameterTuning.algorithms ||
    parameterTuning.algorithms.length === 0 ||
    !parameterTuning.searchStrategy
  ) {
    // early exit if unset: Make sure that if there are no algorithms, the object is not stored at all.
    //   negative example for what is not supposed to be stored: { parameterTuning: { algorithms: [], searchStrategy: {} }}
    parameterTuning = undefined;
  }

  const rawPipelineTuningValue = formValues[fieldPipelineTuning];
  const pipelineTuning = parseAllPipelineParameterValues(
    rawPipelineTuningValue
  );

  return {
    // Habitat Code
    habitatCode,

    // Module type
    type: formValues[fieldModuleType],

    // Datapool
    datapoolCode: formValues[fieldDatapool]?.code,

    // Archetype
    archetypeCode,
    archetypeVersionNumber,

    // Augur name
    name: formValues[fieldAugurName],

    // --- Settings
    settings: {
      // Parameter Tuning
      parameterTuning,
      // Pipeline Tuning,
      pipelineTuning,
      // Training table
      trainingTable: (formValues[fieldTrainingTable] || {}).tableName,
      labelColumn: (formValues[fieldTrainingTable] || {}).labelColumn,
      primaryKeyColumns:
        (formValues[fieldTrainingTable] || {}).primaryKeyColumns || [],
      positiveLabelValue: (formValues[fieldTrainingTable] || {})
        .positiveLabelValue,
      negativeLabelValue: (formValues[fieldTrainingTable] || {})
        .negativeLabelValue,

      // Prediction table,
      scoringTable: formValues[fieldPredictionTable],
      // Sampling strategy
      samplingStrategy: formValues[fieldSamplingStrategy],

      // Prediction targets
      predictionTargets: formValues[fieldPredictionTargets],
    },
  };
};
