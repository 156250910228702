import styles from '../details/styles.module.scss';
import { StatusType } from 'common/dist/types/job';

export function getStatusClass(status: StatusType): string {
  switch (status) {
    case 'waiting-in-execution-queue':
      return styles.statusWaitingInExecutionQueue;
    case 'triggered':
      return styles.statusTriggered;
    case 'running':
    case 'in-progress':
      return styles.statusRunning;
    case 'success':
    case 'successful':
      return styles.statusSuccess;
    case 'cancelled':
      return styles.statusCancelled;
    case 'failure':
      return styles.statusFailure;
    case 'waiting':
    default:
      return styles.statusWaiting;
  }
}

export function speakingStatus(status: StatusType): string {
  switch (status) {
    case 'waiting':
      return 'Waiting';
    case 'waiting-in-execution-queue':
      return 'Enqueued';
    case 'triggered':
      return 'Triggered';
    case 'running':
    case 'in-progress':
      return 'Running';
    case 'success':
    case 'successful':
      return 'Done';
    case 'cancelled':
      return 'Cancelled';
    case 'failure':
      return 'Error';
    default:
      return 'Unknown';
  }
}
