/*
 * This module contains the redux actions / sagas to interact with the merger API
 */

import { createAction } from 'redux-act';
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import {
  getMergeRequestConflict,
  getMergeStatus,
  getRefStatus,
  openMergeRequest,
  putConflictResolve,
  postResolveConflictCommit,
} from '../../../core/api/workbench/merger';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { BASE_FILE_PATH } from '../../../components/collaborationSpace/repository-details/merge-requests/merge-request-details/tab-resolve/resolve-conflicts/ButtonsBaseFile';

export const mergerCreateMergeRequest = createAction(
  'merger create merge request',
  (mergeRequestId, repository, group, sourceBranch, targetBranch) => ({
    mergeRequestId,
    repository,
    group,
    sourceBranch,
    targetBranch,
  })
);

export const mergerCreateMergeRequestSuccess = createAction(
  'merger create merge request - success',
  (id) => ({ id })
);

export const mergerCreateMergeRequestFailure = createAction(
  'merger create merge request - failure',
  (error) => ({ error })
);

export const fetchRefStatus = createAction(
  'fetch ref status',
  (repository, ref1, ref2) => ({ repository, ref1, ref2 })
);

export const fetchRefStatusSuccess = createAction(
  'fetch ref status - success',
  (result) => ({ result })
);

export const fetchRefStatusFailure = createAction(
  'fetch ref status - failure',
  (error) => ({ error })
);

export const fetchMergeRequestStatus = createAction(
  'fetch merge request status',
  (id, retryDelay) => ({ id, retryDelay })
);

export const fetchMergeRequestStatusSuccess = createAction(
  'fetch merge request status - success',
  (result) => ({ result })
);

export const fetchMergeRequestStatusFailure = createAction(
  'fetch merge request status - failure',
  (error) => ({ error })
);

export const loadMergeRequestConflict = createAction(
  'load merge request conflict',
  (mergeId, fileName) => ({ mergeId, fileName })
);

export const loadMergeRequestConflictSuccess = createAction(
  'load merge request conflict - success',
  (mergeId, fileName, data) => ({ mergeId, fileName, data })
);

export const loadMergeRequestConflictFailure = createAction(
  'load merge request conflict - failure',
  (mergeId, fileName, error) => ({ mergeId, fileName, error })
);

export const addResolveAction = createAction(
  'add resolve action',
  (fileName, resolveAction) => ({ fileName, resolveAction })
);

export const removeResolveAction = createAction(
  'remove resolve action',
  (fileName, resolveActionId) => ({ fileName, resolveActionId })
);

export const toggleSourceFile = createAction(
  'merger - toggle source file',
  (isExtended) => ({ isExtended })
);

export const toggleTargetFile = createAction(
  'merger - toggle target file',
  (isExtended) => ({ isExtended })
);

export const submitConflictResolve = createAction(
  'submit conflict resolve',
  (mergeId, fileName, resolvedVersion) => ({
    mergeId,
    fileName,
    resolvedVersion,
  })
);

export const submitConflictResolveSuccess = createAction(
  'submit conflict resolve - success',
  (mergeId, fileName) => ({ mergeId, fileName })
);

export const submitConflictResolveFailure = createAction(
  'submit conflict resolve - failure',
  (mergeId, fileName, error) => ({ mergeId, fileName, error })
);

export const toggleCommitModal = createAction(
  'merge conflicts - toggle commit modal',
  (isShown) => ({ isShown })
);

export const sendResolveConflictCommit = createAction(
  'send resolve conflict commit',
  (mergeId, commitMessage) => ({ mergeId, commitMessage })
);

export const sendResolveConflictCommitSuccess = createAction(
  'send resolve conflict commit - success',
  (mergeId) => ({ mergeId })
);

export const sendResolveConflictCommitFailure = createAction(
  'send resolve conflict commit - failure',
  (mergeId, error) => ({ mergeId, error })
);

export const reducer = {
  [mergerCreateMergeRequest]: (state) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      merge: {
        ...((state.mergerAPI || {}).merge || {}),
        loading: true,
        error: undefined,
      },
    },
  }),
  [mergerCreateMergeRequestSuccess]: (state, { id }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      merge: {
        ...((state.mergerAPI || {}).merge || {}),
        loading: false,
        loaded: true,
        error: undefined,
        data: {
          id,
        },
      },
    },
  }),
  [mergerCreateMergeRequestFailure]: (state, { error }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      merge: {
        ...((state.mergerAPI || {}).merge || {}),
        loading: false,
        loaded: false,
        error,
        data: undefined,
      },
    },
  }),
  [fetchRefStatus]: (state) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      refStatus: {
        loading: true,
        loaded: false,
        data: undefined,
        error: undefined, // Just to make it explicit
      },
    },
  }),
  [fetchRefStatusSuccess]: (state, { result }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      refStatus: {
        loading: false,
        loaded: true,
        data: result,
        error: undefined, // Just to make it explicit
      },
    },
  }),
  [fetchRefStatusFailure]: (state, { error }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      refStatus: {
        loading: false,
        loaded: true,
        data: undefined,
        error,
      },
    },
  }),
  [fetchMergeRequestStatus]: (state, { id }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      id,
      refStatus: {
        loading: true,
        loaded: false,
        data: undefined,
        error: undefined, // Just to make it explicit
      },
    },
  }),
  [fetchMergeRequestStatusSuccess]: (state, { result }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      status: {
        loading: false,
        loaded: true,
        data: result,
        error: undefined, // Just to make it explicit
      },
    },
  }),
  [fetchMergeRequestStatusFailure]: (state, { error }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      status: {
        loading: false,
        loaded: true,
        data: undefined,
        error,
      },
    },
  }),
  [loadMergeRequestConflict]: (state, { mergeId, fileName }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      conflicts: {
        ...(state.mergerAPI.conflicts || {}),
        [fileName]: {
          ...((state.mergerAPI.conflicts || {})[fileName] || {}),
          loading: true,
        },
      },
    },
  }),
  [loadMergeRequestConflictSuccess]: (state, { mergeId, fileName, data }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      conflicts: {
        ...(state.mergerAPI.conflicts || {}),
        [fileName]: {
          ...((state.mergerAPI.conflicts || {})[fileName] || {}),
          loading: false,
          loaded: true,
          data: {
            // Add IDs to the source and target diffs
            ...data,
            source_diff: (data.source_diff || []).map((sd) => ({
              ...sd,
              id: uuidv4(),
            })),
            target_diff: (data.target_diff || []).map((td) => ({
              ...td,
              id: uuidv4(),
            })),
          },
          error: undefined,
          amountOpenDiffs:
            (data.source_diff || []).length + (data.target_diff || []).length,
        },
      },
    },
    notebooks: {
      ...state.notebooks,
      [BASE_FILE_PATH]: {
        name: (data.base_file || {}).filename,
        path: BASE_FILE_PATH,
        content: (data.base_file || {}).content || {},
        selectedCells: [], // Don't select any cell when opening the notebook
      },
    },
  }),
  [loadMergeRequestConflictFailure]: (state, { fileName, error }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      conflicts: {
        ...(state.mergerAPI.conflicts || {}),
        [fileName]: {
          ...((state.mergerAPI.conflicts || {})[fileName] || {}),
          loading: false,
          loaded: true,
          error,
        },
      },
    },
  }),
  [addResolveAction]: (state, { fileName, resolveAction }) => {
    // let baseFile = (((state.mergerAPI.conflicts || {})[fileName] || {}).data || {}).base_file
    let baseFile = state.notebooks[BASE_FILE_PATH] || {};
    if (resolveAction.action === 'accept') {
      switch (resolveAction.diff.op) {
        case 'add': {
          const beforeIndex = baseFile.content.cells.findIndex(
            (cell) => cell.id === resolveAction.diff.before_cell_id
          );
          baseFile = {
            ...baseFile,
            content: {
              ...baseFile.content,
              cells: [
                ...baseFile.content.cells.slice(0, beforeIndex),
                resolveAction.diff.value,
                ...baseFile.content.cells.slice(beforeIndex),
              ],
            },
          };
          break;
        }
        case 'change': {
          baseFile = {
            ...baseFile,
            content: {
              ...baseFile.content,
              cells: baseFile.content.cells.map((c) =>
                c.id === resolveAction.diff.cell_id
                  ? resolveAction.diff.value
                  : c
              ),
            },
          };
          break;
        }
        case 'remove': {
          baseFile = {
            ...baseFile,
            content: {
              ...baseFile.content,
              cells: baseFile.content.cells.map((c) =>
                c.id === resolveAction.diff.cell_id
                  ? { ...c, removed: true }
                  : c
              ),
            },
          };
          break;
        }
      }
    }

    const resolveActions = {
      ...(((state.mergerAPI.conflicts || {})[fileName] || {}).resolveActions ||
        {}),
      [resolveAction.id]: resolveAction,
    };

    // Count the amount of open diffs
    const resolveActionIds = Object.keys(resolveActions);

    const amountOpenDiffs =
      (
        (((state.mergerAPI.conflicts || {})[fileName] || {}).data || {})
          .source_diff || []
      ).filter((diff) => !resolveActionIds.includes(diff.id)).length +
      (
        (((state.mergerAPI.conflicts || {})[fileName] || {}).data || {})
          .target_diff || []
      ).filter((diff) => !resolveActionIds.includes(diff.id)).length;

    return {
      ...state,
      mergerAPI: {
        ...state.mergerAPI,
        conflicts: {
          ...(state.mergerAPI.conflicts || {}),
          [fileName]: {
            ...((state.mergerAPI.conflicts || {})[fileName] || {}),
            resolveActions,
            amountOpenDiffs,
          },
        },
      },
      notebooks: {
        ...state.notebooks,
        [BASE_FILE_PATH]: baseFile,
      },
    };
  },
  [removeResolveAction]: (state, { fileName, resolveActionId }) => {
    const resolveActions = _.omit(
      ((state.mergerAPI.conflicts || {})[fileName] || {}).resolveActions || {},
      resolveActionId
    );

    // Count the amount of open diffs
    const resolveActionIds = Object.keys(resolveActions);
    const amountOpenDiffs =
      (
        (((state.mergerAPI.conflicts || {})[fileName] || {}).data || {})
          .source_diff || []
      ).filter((diff) => !resolveActionIds.includes(diff.id)).length +
      (
        (((state.mergerAPI.conflicts || {})[fileName] || {}).data || {})
          .target_diff || []
      ).filter((diff) => !resolveActionIds.includes(diff.id)).length;

    return {
      ...state,
      mergerAPI: {
        ...state.mergerAPI,
        conflicts: {
          ...(state.mergerAPI.conflicts || {}),
          [fileName]: {
            ...((state.mergerAPI.conflicts || {})[fileName] || {}),
            resolveActions,
            amountOpenDiffs,
          },
        },
      },
    };
  },
  [toggleSourceFile]: (state, { isExtended }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      sourceExtended: isExtended,
    },
  }),
  [toggleTargetFile]: (state, { isExtended }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      targetExtended: isExtended,
    },
  }),
  [toggleCommitModal]: (state, { isShown }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      commitModal: {
        ...(state.mergerAPI.commitModal || {}),
        isShown,
      },
    },
  }),
  [sendResolveConflictCommitSuccess]: (state, { mergeId }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      commitModal: {
        ...(state.mergerAPI.commitModal || {}),
        isShown: false,
      },
    },
  }),
  [sendResolveConflictCommitFailure]: (state, { mergeId, error }) => ({
    ...state,
    mergerAPI: {
      ...state.mergerAPI,
      commitModal: {
        ...(state.mergerAPI.commitModal || {}),
        isShown: false,
      },
    },
  }),
};

export function* mergerCreateMergeRequestSaga({
  payload: { mergeRequestId, repository, group, sourceBranch, targetBranch },
}) {
  const { response, error } = yield call(
    openMergeRequest,
    mergeRequestId,
    repository,
    group,
    sourceBranch,
    targetBranch
  );
  if (response) {
    yield put(mergerCreateMergeRequestSuccess(response.merge_request_id));
    yield put(fetchMergeRequestStatus(response.merge_request_id, 500));
  } else {
    yield put(mergerCreateMergeRequestFailure(error));
    console.log(error);
  }
}

export function* watchMergerCreateMergeRequest() {
  yield takeEvery(
    mergerCreateMergeRequest.getType(),
    mergerCreateMergeRequestSaga
  );
}

export function* fetchRefStatusSaga({ payload: { repository, ref1, ref2 } }) {
  const { response, error, status } = yield call(
    getRefStatus,
    repository,
    ref1,
    ref2
  );
  if (response) {
    yield put(fetchRefStatusSuccess(response));
  } else {
    console.log('fetchRefStatusSaga', status, error);
    yield put(fetchRefStatusFailure(JSON.parse(error)));
  }
}

export function* watchFetchRefStatus() {
  yield takeEvery(fetchRefStatus.getType(), fetchRefStatusSaga);
}

export function* fetchMergeRequestStatusSaga({ payload: { id, retryDelay } }) {
  const { response, error } = yield call(getMergeStatus, id);
  if (response) {
    if (response && response.status === 'pending') {
      yield put(fetchMergeRequestStatusSuccess(response));
      yield delay(retryDelay);
      yield put(fetchMergeRequestStatus(id, retryDelay * 2));
    } else yield put(fetchMergeRequestStatusSuccess(response));
  } else {
    console.log(error);
    yield put(fetchMergeRequestStatusFailure(JSON.parse(error)));
  }
}

export function* watchFetchMergeRequestStatus() {
  yield takeEvery(
    fetchMergeRequestStatus.getType(),
    fetchMergeRequestStatusSaga
  );
}

export function* loadMergeRequestConflictSaga({
  payload: { mergeId, fileName },
}) {
  const { response, error } = yield call(
    getMergeRequestConflict,
    mergeId,
    fileName
  );
  if (response) {
    yield put(loadMergeRequestConflictSuccess(mergeId, fileName, response));
  } else {
    yield put(loadMergeRequestConflictFailure(mergeId, fileName, error));
  }
}

export function* watchLoadMergeRequestConflict() {
  yield takeEvery(
    loadMergeRequestConflict.getType(),
    loadMergeRequestConflictSaga
  );
}

export function* submitConflictResolveSaga({
  payload: { mergeId, fileName, resolvedVersion },
}) {
  const { response, error } = yield call(
    putConflictResolve,
    mergeId,
    fileName,
    resolvedVersion
  );
  if (response) {
    yield put(submitConflictResolveSuccess(mergeId, fileName));
  } else {
    yield put(submitConflictResolveFailure(mergeId, fileName, error));
  }
}

export function* watchSubmitConflictResolve() {
  yield takeEvery(submitConflictResolve.getType(), submitConflictResolveSaga);
}

export function* sendResolveConflictCommitSaga({
  payload: { mergeId, commitMessage },
}) {
  const { response, error } = yield call(
    postResolveConflictCommit,
    mergeId,
    commitMessage
  );
  if (response) {
    yield put(sendResolveConflictCommitSuccess(mergeId));
    // TODO show notification
  } else {
    yield put(sendResolveConflictCommitFailure(mergeId, error));
    // TODO show notification
  }
}

export function* watchSendResolveConflictCommit() {
  yield takeEvery(
    sendResolveConflictCommit.getType(),
    sendResolveConflictCommitSaga
  );
}
