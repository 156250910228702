import { createSelector } from 'reselect';
import { RootState } from '../../../store/store';

export const getSessionByPath = createSelector(
  (state: RootState) => state.workbench.sessions.data,
  (state: RootState, path: string) => path,
  (sessions, path) => {
    return sessions.find((session) => session.path === path);
  }
);
