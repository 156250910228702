import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiChevronsLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { collaborationSpaceRoutes } from '../../routes';

export default class RepositoryHeader extends Component {
  render() {
    const { linkTo, message } = this.props;
    return (
      <div className={'repository-header'}>
        <div className={'back-button-container'}>
          <Link to={linkTo} style={{ textDecoration: 'none' }}>
            <div className={'back-button'}>
              <FiChevronsLeft size={20} className={'back-icon'} />
              <span className={'back-text'}>{message}</span>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  /* -> There is no page to list all repositories for the given "group" - so this header doesn't give more value at the
  moment. Will be introduced once there is an overview for the projects of a given group

  render() {
    const { group, repoName } = this.props;
    return (
      <div className={'repository-header'}>
        <div className={'back-button-container'}>
          <Link
            to={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.path}`}
            style={{ textDecoration: 'none' }}
          >
            <div
              className={'back-button'}
            >
              <FiHome
                size={20}
                className={'back-icon'}
              />
            </div>
          </Link>
          <FiChevronRight
            size={20}
            className={'back-icon'}
          />
          <Link
            to={`${collaborationSpaceRoutes.basePath}/${group}`}
            style={{ textDecoration: 'none' }}
          >
            <div
              className={'back-button'}
            >
              <span className={'back-text'}>{group}</span>
            </div>
          </Link>
          <FiChevronRight
            size={20}
            className={'back-icon'}
          />
          <Link
            to={`${collaborationSpaceRoutes.basePath}/${group}/${repoName}`}
            style={{ textDecoration: 'none' }}
          >
            <div
              className={'back-button'}
            >
              <span className={'back-text'}>{repoName}</span>
            </div>
          </Link>
        </div>
      </div>
    );
  }
   */
}
RepositoryHeader.propTypes = {
  /** Link of the back button */
  linkTo: PropTypes.string,
  /** Message of the back button */
  message: PropTypes.string,
};

RepositoryHeader.defaultProps = {
  linkTo: `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.path}`,
  message: 'Back to Repository Browser',
};
