import { ComponentType } from 'react';

import NotebookCellWrapper from '../../../NotebookCellWrapper';
import Python3OutputCell from './Python3OutputCell.container';

export default class Python3OutputCellWrapper extends NotebookCellWrapper {
  ChildComponent = Python3OutputCell;
  type = 'python3-output';
  name = 'App Output';
  parentContainerClass = 'python3-output-cell output-cell app-cell';
  executable = true;
}
