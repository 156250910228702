import { connect } from 'react-redux';
import ButtonBar from './ButtonBar';
import { saveNotebook } from '../../../../../redux/workbench/modules/notebook.module';
import { info as infoNotification } from 'react-notification-system-redux';

export function mapStateToProps(state, ownProps) {
  return {};
}
export const connectDispatchToProps = {
  saveNotebook,
  infoNotification,
};

export default connect(mapStateToProps, connectDispatchToProps)(ButtonBar);
