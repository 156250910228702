import { connect } from 'react-redux';
import P3InputElementParent from './P3InputElementParent';
import {
  removeInputElement,
  showEditInputElement,
  hideEditInputElement,
  updateDataOfInputElement,
} from '../../../../../../../../../../../redux/workbench/modules/cells.app.module';

export function mapStateToProps(state) {
  return {
    editInputElementModal: state.workbench.editInputElementModal,
  };
}

export const mapDispatchToProps = {
  removeInputElement,
  updateDataOfInputElement,
  showEditInputElement,
  hideEditInputElement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(P3InputElementParent);
