import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { outputElementShape } from './elements/_interface/P3OutputElementShape';
import OutputElements from './output-elements/OutputElements';
import OutputElementSelectModal from './OutputElementSelectModal';

export default class Python3OutputCell extends Component {
  render() {
    const {
      path,
      cell,
      addOutputElement,
      toggleOutputElements,
      showAddOutputElement,
      hideAddOutputElement,
      addOutputElementModal,
      showEditOutputElement,
    } = this.props;

    return (
      <div className={'editor-parent-container'}>
        <div className={'editor-container app-cell-container'}>
          <OutputElements
            cell={cell}
            path={path}
            handleOpenDialogModal={() => showAddOutputElement(path, cell.id)}
            toggleOutputElements={toggleOutputElements}
          />
        </div>

        <OutputElementSelectModal
          isOpen={addOutputElementModal?.active}
          closeDialog={hideAddOutputElement}
          path={addOutputElementModal?.path}
          cellId={addOutputElementModal?.cellId}
          addOutputElement={addOutputElement}
          toggleOutputElements={toggleOutputElements}
          showEditOutputElement={(e) => showEditOutputElement(path, cell.id, e)}
        />
      </div>
    );
  }
}

Python3OutputCell.propTypes = {
  cell: PropTypes.shape({
    as_elements: PropTypes.arrayOf(outputElementShape),
    source: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  path: PropTypes.string.isRequired,
  selectedCells: PropTypes.array.isRequired,
  selectCells: PropTypes.func.isRequired,
  addOutputElement: PropTypes.func.isRequired,
  toggleOutputElements: PropTypes.func.isRequired,
  addOutputElementModal: PropTypes.shape({
    active: PropTypes.bool,
    path: PropTypes.string,
    cellId: PropTypes.string,
  }),
  showAddOutputElement: PropTypes.func.isRequired,
  hideAddOutputElement: PropTypes.func.isRequired,
};
