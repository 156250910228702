import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

const storedHabitatInfo = (summary, habitatCode) => {
  if (!habitatCode) return {};

  const habitat = summary.find((habitat) => habitat.code === habitatCode);
  if (!habitat) return {};

  return {
    code: habitat.code,
    name: habitat.name,
  };
};

export default class RepositoryStepHabitat extends Component {
  componentDidMount() {
    const { summary, fetchSummary } = this.props;
    if (!summary) fetchSummary();
  }

  render() {
    const {
      input: { onChange, value, onFocus, onBlur },
      meta: { touched, valid, error },
      summary,
      summaryLoading,
    } = this.props;

    const options = !summary
      ? []
      : summary.map((habitat) => ({
          label: habitat.name,
          value: habitat.code,
        }));

    return (
      <div className={'GenericFormStep--field'}>
        <DropdownSelectInput
          touched={touched}
          error={error}
          valid={touched && valid}
          disabled={false}
          id={'habitat'}
          name={'habitat'}
          placeholder={{
            id: 'habitat',
            defaultMessage: 'Select Habitat',
          }}
          value={options.find((o) => o.value === value?.code)}
          onChange={(option) =>
            onChange(storedHabitatInfo(summary, option.value))
          }
          isLoading={summaryLoading}
          options={options}
          autoSelectIfSingle
          scrollMenuIntoView={false}
        />
      </div>
    );
  }
}

RepositoryStepHabitat.propTypes = {
  summaryLoading: PropTypes.bool,
  /** Summary of all Habitats / Datapools */
  summary: PropTypes.arrayOf(
    PropTypes.shape({
      /** Name of the Habitat */
      name: PropTypes.string.isRequired,
      /** Code of the Habitat */
      code: PropTypes.string.isRequired,
      /** List of Datapools for this Habitat */
      datapools: PropTypes.arrayOf(
        PropTypes.shape({
          /** Name of the Datapool */
          name: PropTypes.string.isRequired,
          /** Code of the Datapool */
          code: PropTypes.string.isRequired,
          /** Module Type of the Datapool */
          moduleType: PropTypes.string.isRequired,
          module: PropTypes.shape({
            name: PropTypes.string,
          }).isRequired,
        })
      ),
    })
  ),
  /** Redux action to fetch the summary of Habitats / Datapools */
  fetchSummary: PropTypes.func.isRequired,

  meta: PropTypes.shape({
    touched: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
};
