import { connect } from 'react-redux';
import DirectoryWizard from './ui/DirectoryWizard';
import { addDirectory } from '../../../../../redux/workbench/modules/directory.module';
import { formValueSelector } from 'redux-form';

export const addDirectoryFormName = 'directoryWizard';
export const fieldDirectoryName = 'directoryName';

const selector = formValueSelector(addDirectoryFormName);

export function mapStateToProps(state) {
  return {
    // Headline
    headline: 'Add new Directory',
    directoryName: selector(state, fieldDirectoryName),
    filledCompletely:
      !state.form.directoryWizard || !state.form.directoryWizard.syncErrors,
    // Same for Add, Edit, Paste
    isCreatingDirectory: state.workbench.isCreatingDirectory,
  };
}

export const mapDispatchToProps = {
  // Save button
  onSave: addDirectory,
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectoryWizard);
