import { VARIABLE_TYPES } from '../variableTypes';
import { BaseVariableHandler, getFirstRaw } from './helper';

const TIMESTAMP_FORMAT = 'yyyy-MM-dd HH:mm:ss[.S]';

export const TimestampVariableHandler: BaseVariableHandler<string> = {
  type: VARIABLE_TYPES.TIMESTAMP,
  displayName: 'Timestamp',
  getExecutionCode: (inputValue: string) => inputValue,
  parseValue: (variable, rawValues) => {
    const firstRaw = getFirstRaw(variable, rawValues);
    if (!firstRaw) return { parsedValue: undefined, error: undefined };
    try {
      const firstRawNoQuotes = firstRaw.split("'").join('');
      // @ts-ignore
      const ignored = Date.parse(firstRawNoQuotes, TIMESTAMP_FORMAT); // Parsing is only done to validate the string format
      if (!Number.isNaN(ignored)) {
        return { parsedValue: firstRawNoQuotes, error: undefined };
      } else {
        return {
          parsedValue: undefined,
          error: `Couldn't parse Timestamp, please provide as ${TIMESTAMP_FORMAT}`,
        };
      }
    } catch (e) {
      return {
        parsedValue: undefined,
        error: `Couldn't parse Timestamp, please provide as ${TIMESTAMP_FORMAT}`,
      };
    }
  },
};
