import { connect } from 'react-redux';
import VariableCard from './VariableCard';
import { withRouter } from 'react-router-dom';
import {
  removeVariable,
  updateVariable,
} from '../../../../../../../../../../redux/workbench/modules/cells.app.module';

export function mapStateToProps(state) {
  return {};
}

export const mapDispatchToProps = {
  removeVariable,
  updateVariable,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VariableCard)
);
