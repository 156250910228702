import React from 'react';
import { FiHash, FiPlay } from 'react-icons/fi';

import IconContainer from '../../details/sidenav/Icon.container';
import BackIconContainer from '../../details/sidenav/BackIcon.container';

function SideNav(props) {
  const tabs = [
    {
      id: 'versions',
      title: 'Versions', // TODO Intl
      icon: () => <FiHash size={16} />,
    },
    {
      id: 'executions',
      title: 'Executions', // TODO Intl
      icon: () => <FiPlay size={16} />,
    },
  ];
  return (
    <ul>
      {tabs.map((tab, index) => (
        <IconContainer
          key={index}
          id={tab.id}
          title={tab.title}
          icon={tab.icon}
          isCodeCapsule
        />
      ))}
      <BackIconContainer />
    </ul>
  );
}

SideNav.propTypes = {};

export default SideNav;
