import React, { Component } from 'react';
import { WizardStepProps } from './WizardStep';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { GenericWrappedFieldProps } from './genericWrappedFieldProps';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import Bubble from '../../atoms/bubble/Bubble';
import { CProps } from './WizardStepFieldComponent.container';

export type WizardStepFieldProps<Value, Error> = WizardStepProps<Value, Error> &
  GenericWrappedFieldProps<Value, Error>;

export default class WizardStepFieldComponent<Value, Error> extends Component<
  WizardStepFieldProps<Value, Error> & CProps
> {
  componentDidMount() {
    const {
      input: { onChange },
      meta: { dirty, touched, form },
      mpwInitialValues,
      touch,
      fieldName,
    } = this.props;
    if (mpwInitialValues) {
      // With touchOnChange they will automatically be touched
      onChange(mpwInitialValues);
    }
    // Touch fields that are inherited from another wizard (e.g. Default -> Binary Classification) and already filled
    if (dirty && !touched) {
      touch(form, fieldName);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<WizardStepFieldProps<Value, Error>>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    const {
      input: { onChange },
      mpwInitialValues,
    } = this.props;
    const { mpwInitialValues: mpwInitialValuesPrev } = prevProps;
    if (!_.isEqual(mpwInitialValuesPrev, mpwInitialValues)) {
      // the mpwInitialValues changed -> set them via onChange
      onChange(mpwInitialValues);
    }
  }

  renderChild() {
    const { children, input, meta } = this.props;
    if (React.isValidElement(children)) {
      return React.cloneElement<GenericWrappedFieldProps<Value, Error>>(
        children,
        {
          /* Pass down the redux-form related props */
          input,
          meta,
        }
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      meta,
      stepNumber,
      title,
      description,
      BottomComponent,
      bottomComponentProps,
      bottomComponentFullWidth,
    } = this.props;
    const { touched, valid, error, asyncValidating } = meta;

    return (
      <div className={classNames(styles.wizardStep)}>
        <div className={styles.wizardStepTop}>
          <div className={styles.infoContainer}>
            <div className={styles.bubbleContainer}>
              <Bubble
                number={stepNumber}
                isValid={touched && valid && !asyncValidating}
                isErroneous={touched && !!error && !asyncValidating}
                isValidating={asyncValidating}
              />
            </div>
            <div className={styles.textContainer}>
              {title && (
                <FormattedMessage {...title}>
                  {(text) => <span className={styles.title}>{text}</span>}
                </FormattedMessage>
              )}
              {description && (
                <FormattedMessage {...description}>
                  {(text) => <span className={styles.description}>{text}</span>}
                </FormattedMessage>
              )}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.errorParent}>
              {/* <ErrorMessage touched={touched} error={error} /> Done by the input components themselves */}
            </div>
            <div className={styles.inputParent}>{this.renderChild()}</div>
          </div>
        </div>
        {!!BottomComponent && (
          <div
            className={classNames(styles.wizardStepBottom, {
              [styles.fullWidth]: bottomComponentFullWidth,
            })}
          >
            <BottomComponent
              {...this.props}
              {...(bottomComponentProps || {})}
            />
          </div>
        )}
      </div>
    );
  }
}
