import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const fetchAppPermissions = createAction('fetch app permissions');

export const fetchAppPermissionsSuccess = createAction(
  'fetch app permissions - success',
  (data) => ({ data })
);

export const fetchAppPermissionsFailure = createAction(
  'fetch app permissions - failure',
  (error) => ({ error })
);

export const fetchAppsAdmin = createAction(
  'fetch apps admin',
  (onlyWithAtLeastOneVersion) => ({ onlyWithAtLeastOneVersion })
);

export const fetchAppsAdminSuccess = createAction(
  'fetch apps admin - success',
  (data) => ({ data })
);

export const fetchAppsAdminFailure = createAction(
  'fetch apps admin - failure',
  (error) => ({ error })
);

export const fetchApps = createAction(
  'fetch apps',
  (onlyWithAtLeastOneVersion) => ({ onlyWithAtLeastOneVersion })
);

export const fetchAppsSuccess = createAction(
  'fetch apps - success',
  (data) => ({ data })
);

export const fetchAppsFailure = createAction(
  'fetch apps - failure',
  (error) => ({ error })
);

export const fetchAppVersions = createAction(
  'fetch app versions',
  (appCode) => ({ appCode })
);

export const fetchAppVersionsSuccess = createAction(
  'fetch app versions - success',
  (appCode, data) => ({ appCode, data })
);

export const fetchAppVersionsFailure = createAction(
  'fetch app versions - failure',
  (appCode, error) => ({ appCode, error })
);

export const reducer = {
  [fetchAppPermissions]: (state) => ({
    ...state,
    apps: {
      ...state.apps,
      permissions: {
        ...state.apps.permissions,
        loading: true,
      },
    },
  }),

  [fetchAppPermissionsSuccess]: (state, { data }) => ({
    ...state,
    apps: {
      ...state.apps,
      permissions: {
        loading: false,
        loaded: true,
        error: undefined,
        data,
      },
    },
  }),

  [fetchAppPermissionsFailure]: (state, { error }) => ({
    ...state,
    apps: {
      ...state.apps,
      permissions: {
        loading: false,
        loaded: true,
        error,
        data: undefined,
      },
    },
  }),
  [fetchAppsAdmin]: (state) => ({
    ...state,
    appsAdmin: {
      ...state.apps,
      loading: true,
      error: undefined,
    },
  }),
  [fetchAppsAdminSuccess]: (state, { data }) => ({
    ...state,
    appsAdmin: {
      ...state.apps,
      loading: false,
      loaded: true,
      data,
    },
  }),
  [fetchAppsAdminFailure]: (state, { error }) => ({
    ...state,
    appsAdmin: {
      ...state.apps,
      loading: false,
      loaded: false,
      error,
    },
  }),
  [fetchApps]: (state) => ({
    ...state,
    apps: {
      ...state.apps,
      loading: true,
      error: undefined,
    },
  }),
  [fetchAppsSuccess]: (state, { data }) => ({
    ...state,
    apps: {
      ...state.apps,
      loading: false,
      loaded: true,
      data,
    },
  }),
  [fetchAppsFailure]: (state, { error }) => ({
    ...state,
    apps: {
      ...state.apps,
      loading: false,
      loaded: false,
      error,
    },
  }),
  [fetchAppVersions]: (state, { appCode }) => ({
    ...state,
    appVersions: {
      ...state.appVersions,
      [appCode]: {
        ...(state.appVersions[appCode] || {}),
        loading: true,
        error: undefined,
      },
    },
  }),
  [fetchAppVersionsSuccess]: (state, { appCode, data }) => ({
    ...state,
    appVersions: {
      ...state.appVersions,
      [appCode]: {
        ...(state.appVersions[appCode] || {}),
        loading: false,
        loaded: true,
        data,
      },
    },
  }),
  [fetchAppVersionsFailure]: (state, { appCode, error }) => ({
    ...state,
    appVersions: {
      ...state.appVersions,
      [appCode]: {
        ...(state.appVersions[appCode] || {}),
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* fetchAppsAdminSaga({
  payload: { onlyWithAtLeastOneVersion },
}) {
  const { response, error } = yield call(
    Api.apps.fetchApps,
    onlyWithAtLeastOneVersion,
    true
  );
  if (response) {
    yield put(fetchAppsAdminSuccess(response));
  } else {
    yield put(fetchAppsAdminFailure(error));
  }
}

export function* watchFetchAppsAdmin() {
  yield takeEvery(fetchAppsAdmin.getType(), fetchAppsAdminSaga);
}

export function* fetchAppsSaga({ payload: { onlyWithAtLeastOneVersion } }) {
  const { response, error } = yield call(
    Api.apps.fetchApps,
    onlyWithAtLeastOneVersion
  );
  if (response) {
    yield put(fetchAppsSuccess(response));
  } else {
    yield put(fetchAppsFailure(error));
  }
}

export function* watchFetchApps() {
  yield takeEvery(fetchApps.getType(), fetchAppsSaga);
}

export function* fetchAppVersionsSaga({ payload: { appCode } }) {
  const { response, error } = yield call(Api.apps.fetchAppVersions, appCode);
  if (response) {
    yield put(fetchAppVersionsSuccess(appCode, response));
  } else {
    yield put(fetchAppVersionsFailure(appCode, error));
  }
}

export function* watchFetchAppVersions() {
  yield takeEvery(fetchAppVersions.getType(), fetchAppVersionsSaga);
}

export function* fetchAppPermissionsSaga() {
  const { response, error } = yield call(
    Api.permissions.evaluatePermissions,
    'app'
  );
  if (response) {
    yield put(fetchAppPermissionsSuccess(response));
  } else {
    yield put(fetchAppPermissionsFailure(error));
  }
}

export function* watchFetchAppPermissions() {
  yield takeEvery(fetchAppPermissions.getType(), fetchAppPermissionsSaga);
}
