import { CompletedApiRequest, postApiRequest } from '../../../core/api/_tools';
import { EnableMaintenanceRequest } from '../../../components/admin/maintenance/types';

/**
 * Request to enable maintenance mode
 */
export function enableMaintenance(
  data: EnableMaintenanceRequest
): CompletedApiRequest<never> {
  return postApiRequest('/maintenance/api/v1/maintenance', data);
}
