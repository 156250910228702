import { ComponentType } from 'react';
import P3OutputElement from './P3OutputElement';
import CodeOutputWrapper, {
  CODE_OUTPUT_TYPE,
} from '../code-output/CodeOutputWrapper';
import DownloadFileWrapper from '../download-button/DownloadFileWrapper';

export const allOutputElements: Array<P3OutputElement> = [
  new CodeOutputWrapper(),
  new DownloadFileWrapper(),
];

/**
 * Returns the output element implementation for the given type
 * @param elementType
 */
export function getElementByType(elementType: string): P3OutputElement {
  // The fallback is required for backward compatibility, since previously there have only been elements without a
  //   "type" property, since all outputs simply were code outputs
  const elementTypeWithFallback = elementType || CODE_OUTPUT_TYPE;
  const foundElement = allOutputElements.find(
    (c) => c.type === elementTypeWithFallback
  );
  if (!foundElement) {
    // console.log(`Unknown output element type: ${elementType}`);
  }
  return foundElement;
}

/**
 * Returns the "AdditionalSettings" Component for the given elementType
 * @param elementType
 */
export function p3AdditionalSettingsForType(
  elementType: string
): ComponentType | undefined {
  const foundElement = getElementByType(elementType);
  if (!foundElement) return undefined;

  return foundElement.AdditionalSettings;
}
