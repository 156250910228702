export const initial = {
  loaded: false,
  loading: false,
  empty: false,
  data: [],
  error: '',
};

export const test = {
  loaded: false,
  loading: false,
  empty: false,
  data: [],
  error: '',
};
