import PropTypes from 'prop-types';
import React from 'react';
import InputError from '../inputError/InputError';

export default function FieldError({ meta, useWarning }) {
  return (
    <InputError
      touched={meta.touched}
      error={useWarning ? meta.warning : meta.error}
    />
  );
}

const errorPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.object,
  }),
]);

FieldError.propTypes = {
  useWarning: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: errorPropType,
  }),
};
