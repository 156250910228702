import * as React from 'react';
import { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Parameter, ValueTypeToSpeaking } from '../types';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';

export interface Props {
  algorithmName: string;
  parameter: Parameter;
}

class ParameterTuningParameter extends Component<Props & WrappedFieldProps> {
  render() {
    const { parameter, algorithmName, input, meta } = this.props;
    const { touched, error } = meta;
    const { value, onChange, onBlur, onFocus } = input;
    const parameterName = parameter?.name;

    return (
      <div className={'ParameterTuningParameter--container'}>
        <h4 className={'ParameterTuning--headline'}>
          {parameter.speakingName}
        </h4>
        <p>{parameter.description}</p>
        <p>
          Enter a list (separated by ,), where each entry must be{' '}
          {parameter.validValues
            .map((vt) => ValueTypeToSpeaking(vt))
            .join(' or ')}
          .
        </p>

        <div className={'ParameterTuning--input'}>
          <TextInputLine
            touched={touched}
            error={error?.algorithms?.[algorithmName]?.[parameterName]}
            hasLabel={false}
            placeholderDefault={''}
            value={value?.algorithms?.[algorithmName]?.[parameterName]}
            onChange={(e) =>
              onChange({
                ...(value || {}),
                algorithms: {
                  ...(value?.algorithms || {}),
                  [algorithmName]: {
                    ...(value?.algorithms?.[algorithmName] || {}),
                    [parameterName]: e.target.value,
                  },
                },
              })
            }
            onBlur={() => onBlur(value)}
            onFocus={() => onFocus(value)}
          />
        </div>
      </div>
    );
  }
}

export default ParameterTuningParameter;
