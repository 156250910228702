import * as React from 'react';
import PropTypes from 'prop-types';

function DefaultIcon({ width, height, elementName, elementType, iconColor }) {
  const firstChar = elementName ? elementName.charAt(0).toUpperCase() : '';
  return (
    <svg
      viewBox='0 0 100 100'
      width={width}
      height={height}
      className={`element-icon ${elementType}`}
    >
      <circle cx={'50'} cy={'50'} r={'50'} fill={iconColor} />
      <text
        x={'50'}
        y={'55'} // The text is supposed to be centered, but '55' looks better than '50'
        fill={'white'}
        fontSize={'56px'}
        dominantBaseline={'middle'}
        textAnchor={'middle'}
        fontWeight={400}
        fontFamily={'Roboto'}
      >
        {firstChar}
      </text>
    </svg>
  );
}

DefaultIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  elementName: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
};

DefaultIcon.defaultProps = {
  width: 30,
  height: 30,
  elementName: '?',
  elementType: '?',
  iconColor: '#a6aeb5',
};

export default DefaultIcon;
