import React, { FC } from 'react';
import ZmqMqttGroup from './zmq-mqtt/ZmqMqttGroup';
import KafkaGroup from './kafka/KafkaGroup';
import RestGroup from './rest/RestGroup';
import HttpSseGroup from './http-sse/HttpSseGroup';
import { CProps } from './Realtime.container';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../types';
import { useAugur } from '../../../../../../core/api/augurs';

export interface Props {
  availableServingTypes: string[];
}

const Realtime: FC<Props & CProps> = ({ data, availableServingTypes }) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const { data: augur } = useAugur(habitatCode, augurCode);

  const inputFormat = augur?.datapool?.inputFormat;
  const archetype = augur?.archetypeVersion?.archetype;
  const archetypeVersion = augur?.archetypeVersion;

  const isRestActive =
    data != null &&
    data.rest &&
    data.rest.status != null &&
    data.rest.status === 'running';

  if (!archetype || !archetype.custom) {
    // --- STOCK ARCHETYPE (or no archetype information, which is a case that should never occur)
    // The information about the available serving types comes from the "availableServingTypes" variable
    return (
      <div className={'h100p model-realtime-container'}>
        {availableServingTypes.includes('rest') && (
          <RestGroup isRestActive={isRestActive} />
        )}
        {availableServingTypes.includes('kafka') && <KafkaGroup />}
        {availableServingTypes.includes('zmq-mqtt') &&
          inputFormat === 'cyclic-timeseries' && <ZmqMqttGroup />}
      </div>
    );
  } else {
    // --- CUSTOM ARCHETYPE
    // !!! The information about the available serving types comes from archetypeVersion.settings.servingTypes
    const servingTypesSafe = archetypeVersion.settings?.servingTypes || [];
    return (
      <div className={'h100p model-realtime-container'}>
        {servingTypesSafe.includes('rest') && (
          <RestGroup isRestActive={isRestActive} />
        )}
        {servingTypesSafe.includes('kafka') && <KafkaGroup />}
        {servingTypesSafe.includes('http-sse') && <HttpSseGroup />}
      </div>
    );
  }
};

export default Realtime;
