import React from 'react';
import AugurBiography from './AugurBiography.container';
import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import { FiBookOpen } from 'react-icons/fi';
import { MessageDescriptor } from 'react-intl';

export default class AugurBiographyWrapper extends ComponentAugurDetailsTab {
  component = AugurBiography;
  icon = () => <FiBookOpen size={17} />;
  id = 'augur-biography';

  to = (habitatCode: string, augurCode: string, shortAugurType: string) =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/augur-biography`;
  title: MessageDescriptor = {
    id: 'details.sideNav.augur_biography',
    defaultMessage: 'Augur Biography',
  };
  subRoutes: object[] | undefined = undefined;
}
