import { connect } from 'react-redux';
import TargetHierarchy from './TargetHierarchy';
import { RootState } from '../../../../../store/store';
import { fetchPredictionTargets } from '../../../../../redux/modules/augur.predictionTargets.module';

export function mapStateToProps(state: RootState) {
  return {
    predictionTargets: state.predictionTargets,
  };
}

export const mapDispatchToProps = {
  fetchPredictionTargets,
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetHierarchy);
