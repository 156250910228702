import * as strokeCircles from '../areaChart/strokeCircles';
import appendRoot from '../common/root';
import getScales from '../common/scales';
import * as config from './config';
import { getRunData } from '../../../../../../../../../core/common/accuracyDetail';

const THREE = require('three');

if (process.env.NODE_ENV !== 'test') {
  require('imports-loader?THREE=three!three/examples/js/controls/OrbitControls.js'); // eslint-disable-line
}

export function render(
  scene,
  width,
  height,
  runs,
  showTooltip,
  hideTooltip,
  maxY
) {
  const fakeDiv = document.createElement('div');

  const { areaWidth, areaHeight } = appendRoot(fakeDiv, width, height);
  const { xScale, yScale } = getScales(areaWidth, areaHeight, runs, maxY);

  runs.forEach((run, index) => {
    getRunData(run).forEach((point) => {
      const div = document.createElement('div');

      strokeCircles.append(div, width, height, point, showTooltip, hideTooltip);

      const object = new THREE.CSS3DObject(div);
      object.position.x = areaWidth / 2 - xScale(point[0]) - 12.5;
      object.position.y = areaHeight / 2 - yScale(point[1]) + 13.5;
      object.position.z = -config.DISTANCE_BETWEEN_LAYERS * (index + 1);
      object.rotation.y = Math.PI;

      scene.add(object);
    });
  });
}
