import { connect, ConnectedProps } from 'react-redux';
import { clearForceFocus } from '../../../../../../../../redux/workbench/modules/notebook.focus.module';
import MarkdownCell, { Props } from './MarkdownCell';
import { RootState } from '../../../../../../../../store/store';

export function mapStateToProps(state: RootState, { path }: Props) {
  return {
    forceFocus: state.workbench.notebooks[path]?.forceFocus,
  };
}

export const mapDispatchToProps: { clearForceFocus: (path) => void } = {
  clearForceFocus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(MarkdownCell);
