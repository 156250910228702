import { connect } from 'react-redux';
import Queue from './Queue';
import { fetchPreQueue } from '../../../redux/modules/orchestration.queue.module';

export function mapStateToProps(state) {
  return {
    links: {
      // TODO this is not supposed to be hard coded here ...
      pre: '/app/orchestration/queue/pre',
      execution: '/app/orchestration/queue/execution',
    },
  };
}

export const mapDispatchToProps = {
  fetchPreQueue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Queue);
