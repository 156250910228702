import { connect } from 'react-redux';
import CloseConfirm from './CloseConfirm';
import {
  saveNotebook,
  closeNotebook,
  hideCloseConfirm,
} from '../../../../../redux/workbench/modules/notebook.module';

export const mapDispatchToProps = {
  saveNotebook,
  closeNotebook,
  hideCloseConfirm,
};
export default connect(null, mapDispatchToProps)(CloseConfirm);
