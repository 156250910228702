import React, { FC, useEffect, useState } from 'react';
import { PropensitiesValueType } from './OptPropensities';
import Table, { RenderColumn } from '../../../../molecules/table/Table';
import { MessageDescriptor } from 'react-intl';
import commonStyles from '../optChannels/optCommonStyles.module.scss';
import {
  Communication,
  Propensity,
} from 'common/dist/types/module.optimization';
import classNames from 'classnames';
import styles from '../../newAugurWizard/optConstraints/styles.module.scss';
import AddPropensity from './AddPropensity';
import { TypedWrappedFieldProps } from '../../../../../utils';

type Props = {
  /** The list of communications defined in a later wizard step. Required to prevent deleting a channel to which a
   * communication belongs to*/
  communications?: Communication[];
  editMode?: boolean;
  addedNewRow?: boolean;
  rowIndex: number;
};

type TableRowType = Propensity & { removeDisabled: MessageDescriptor };

const PropensitiesTable: FC<
  Props & TypedWrappedFieldProps<PropensitiesValueType>
> = (props) => {
  const { input, communications, meta, editMode, addedNewRow, rowIndex } =
    props;
  const inputValue: PropensitiesValueType = input.value;

  // Derive additional properties for rendering only
  const propensities = (inputValue || []).map((propensity) => ({
    ...propensity,
    removeDisabled: (communications || [])
      .map((com) => com.propensityId)
      .includes(propensity.id)
      ? { id: 'no-id', defaultMessage: 'Used in a Communication' }
      : null,
  }));
  const initialVisible = {
    index: addedNewRow ? inputValue?.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  };
  const [visible, setShow] = useState(initialVisible);

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : inputValue?.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex]);

  const renderColumns: RenderColumn<TableRowType, keyof TableRowType>[] = [
    {
      key: 'valueId',
      renderHeader: () => <span>Value ID</span>,
      renderCell: (valueId: string, propensity: Propensity) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[propensity.id]?.valueId,
          })}
        >
          {valueId}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,
      renderCell: (description?: string, propensity?: Propensity) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[propensity?.id]?.description,
          })}
        >
          {description}
        </span>
      ),
    },
  ];
  const onRemove = (removedRow: Propensity) => {
    const updatedPropensity: PropensitiesValueType = inputValue.filter(
      (con) => con.id !== removedRow.id
    );
    input.onChange(updatedPropensity);
  };
  const renderClickedRow = (
    element: Propensity,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (editMode && visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddPropensity
                rowIndex={rowIndex}
                constraintId={element.id}
                input={input}
                meta={meta}
                //@ts-ignore
                removableRows={visible.shown && !element.removeDisabled}
                onRemoveRow={onRemove}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };
  return (
    <Table<TableRowType>
      data={propensities}
      renderColumns={renderColumns}
      showHeader
      verticalAlignMiddle
      roundedBorder
      removableRows={editMode}
      keyRemoveDisabledMessage={'removeDisabled'}
      onRemoveRow={onRemove}
      addlRowClassname={(rowIndex: number, propensity: Propensity) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!meta.error?.[propensity.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
    />
  );
};

export default PropensitiesTable;
