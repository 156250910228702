import { connect } from 'react-redux';
import DataManagement from './DataManagement';
import { fetchDataSources } from '../../redux/modules/data.dataSources.module';
import { isAdmin } from '../../redux/selectors/user.selector';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';
import { RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX } from 'common/dist/constants/keycloak';

export function mapStateToProps(state) {
  const permissions = state.dashboard?.permissions?.data || {};
  const missingPermissionsError = !hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX
  );

  return {
    dataSources: state.data.dataSources,
    isAdmin: isAdmin(state),
    missingPermissionsError,
  };
}

export const mapDispatchToProps = {
  fetchDataSources,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataManagement);
