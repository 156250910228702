import { Elements, isNode, Node, Position } from 'react-flow-renderer';
import dagre, { GraphLabel } from 'dagre';
import _ from 'lodash';

/**
 * Layout react-flow elements using dagre
 * @param elements
 * @param nodes - the internal representation of the nodes
 * @param layout
 * @return {elements, layout} - layout after arranging the elements, contains height and width of graph for example
 */
export function getLayoutedElements(
  elements: Elements,
  nodes: Node[],
  layout: GraphLabel
): { elements: Elements; layout: GraphLabel } {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph(layout);
  const isHorizontal = layout.rankdir === 'LR';

  const nodesMap = _.keyBy(nodes, 'id');

  // Recreate the nodes and edges in the dagreGraph
  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {
        width: nodesMap[el.id].__rf.width,
        height: nodesMap[el.id].__rf.height,
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  // Do the layout
  dagre.layout(dagreGraph);

  // Modify and return the elements using the layoutet dagreGraph
  return {
    elements: elements.map((el) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id);
        el.targetPosition = isHorizontal ? Position.Left : Position.Top;
        el.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

        el.position = {
          x: nodeWithPosition.x - nodesMap[el.id].__rf.width / 2,
          y: nodeWithPosition.y - nodesMap[el.id].__rf.height / 2,
        };
      }

      return el;
    }),
    layout: dagreGraph.graph(),
  };
}

/**
 * Layout react-flow elements using dagre, but without relying on the nodes array, which is only used for
 * width and height, which are only available after the nodes have been rendered once? (they need to be measured)
 * @param elements
 * @param width
 * @param height
 * @param layout
 * @return {elements, layout} - layout after arranging the elements, contains height and width of graph for example
 */
export function getLayoutedElementsWithoutMeasuring(
  elements: Elements,
  width: number,
  height: number,
  layout: GraphLabel
): { elements: Elements; layout: GraphLabel } {
  // Fake the node list with the passed width and height
  return getLayoutedElements(
    elements,
    elements.map((e) => ({ id: e.id, __rf: { width, height } })) as Node[],
    layout
  );
}
