import { connect } from 'react-redux';
import InfluencersBubbleChart from './InfluencesBubbleChart';

export function mapStateToProps() {
  return {
    labelKey: 'feature',
    valueKey: 'importance',
  };
}

export default connect(mapStateToProps)(InfluencersBubbleChart);
