import { ToBeRefined } from 'common/dist/types/todo_type';

export const fieldCommitMessage = 'resolve-conflicts-commit-message';

export const gitCommitForm = {
  form: 'resolve-conflicts-commit-form',
  validate: (values: ToBeRefined) => {
    let errors = {};

    // --- Validate commit message
    const commitMessage = values[fieldCommitMessage];
    if (!commitMessage || commitMessage.trim() === '') {
      errors = {
        ...errors,
        [fieldCommitMessage]: 'Please enter a commit message',
      };
    }

    return errors;
  },
};
