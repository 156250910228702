import AugurSettingsElement from '../../../../../_interfaces/augur-details/settings/AugurSettingsElement';
import DatapoolNameAndCodeComponent from './DatapoolNameAndCodeComponent';

/**
 * Shows the augur name & code in the augur settings tab
 */
export default class DatapoolNameAndCode implements AugurSettingsElement {
  component = DatapoolNameAndCodeComponent;
  title = 'dashboard.augur_settings.datapool_title';
  description = 'dashboard.augur_settings.datapool_description';
}
