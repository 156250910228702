import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { DeprecatedRootState } from '../../../../store/state.type';
import TextInputSearch from './TextInputSearch';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    loading: state.orchestration.loading,
    loaded: state.orchestration.loaded,
  };
}

export default connect(mapStateToProps, undefined)(TextInputSearch);
