import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as routes from '../index/routes';
import Resources from '../admin/resources/Resources';
import UsersAndPermissions from './users/UsersAndPermissions';
import NotFound from '../pages/not-found/NotFound.container';
import Maintenance from './maintenance/Maintenance';
import CreateBackup from './maintenance/create-backup/CreateBackup.container';
import CreateTemplate from './maintenance/create-template/CreateTemplate.container';
import MainContainer from '../pages/main-container/MainContainer';
import AuditLog from './auditLog/AuditLog';
import { isAdmin as isAdminSelector } from '../../redux/selectors/user.selector';
import { useSelector } from 'react-redux';
import { DeprecatedRootState } from '../../store/state.type';

const Admin: FC = () => {
  const isAdmin = useSelector<DeprecatedRootState, boolean>(isAdminSelector);
  return (
    <MainContainer>
      <Switch>
        {!isAdmin && <Route component={NotFound} />}

        {/* Contains sub-routes to manage users and permissions /app/admin/users/... */}
        <Route
          path={
            routes.app.prefix + routes.app.admin.index + routes.app.admin.users
          }
          component={UsersAndPermissions}
        />

        {/* Contains sub-routes to manage the resources /app/admin/resources/... */}
        <Route
          path={
            routes.app.prefix +
            routes.app.admin.index +
            routes.app.admin.resources
          }
          component={Resources}
        />

        {/* Contains the route to manage backups /app/admin/maintenance */}
        <Route
          path={
            routes.app.prefix +
            routes.app.admin.index +
            routes.app.admin.maintenance
          }
          component={Maintenance}
        />

        {/* Create a backup /app/admin/createBackup */}
        <Route
          path={
            routes.app.prefix +
            routes.app.admin.index +
            routes.app.admin.createBackup
          }
          component={CreateBackup}
        />

        {/* Create a template /app/admin/createTemplate */}
        <Route
          path={
            routes.app.prefix +
            routes.app.admin.index +
            routes.app.admin.createTemplate
          }
          component={CreateTemplate}
        />

        {/* Audit Log /app/admin/auditLog */}
        <Route
          path={
            routes.app.prefix +
            routes.app.admin.index +
            routes.app.admin.auditLog
          }
          component={AuditLog}
        />

        {/*Fallback: 404*/}
        <Route component={NotFound} />
      </Switch>
    </MainContainer>
  );
};

export default Admin;
