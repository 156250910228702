import { connect } from 'react-redux';
import SingleEditorPane from './SingleEditorPane';
import { DeprecatedRootState } from '../../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState, { paneId }) {
  return {
    pane: state.workbench.panes[paneId],
    notebooks: state.workbench.notebooks, // Could this cause too many renderings if notebooks change that don't belong to this pane?
  };
}

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SingleEditorPane);
