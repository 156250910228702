import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FiEdit2, FiCheck, FiX } from 'react-icons/fi';
import ReactLoading from 'react-loading';

export default class ConfigMap extends Component {
  renderEmptyConfig() {
    const { configurations } = this.props;
    // TODO Style empty config component
    return <span>Config is empty.</span>;
  }

  renderConfig() {
    const {
      cmName,
      configMap: { configs },
      edit,
      triggerEditConfigmap,
      editConfigmap,
      cancelEditConfigmap,
      confirmEditConfigmap,
    } = this.props;
    return (
      <div className={'table-reset'}>
        <table className={'cm-table'}>
          <thead>
            <tr>
              <th className={'td-key'}>Key</th>
              <th className={'td-value'}>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(configs).map((key) => {
              const value = configs[key];
              return (
                <tr key={key} className={'cm-row'}>
                  <td className={'td-key'}>
                    <span>{key}</span>
                  </td>
                  <td className={'td-value'}>
                    {edit.cmName === cmName && edit.key === key ? (
                      // This key of this configmap is currently being edited.
                      <div className={'cm-input-parent'}>
                        <input
                          className={'cm-input'}
                          type={'text'}
                          value={edit.value}
                          onChange={(e) =>
                            editConfigmap(cmName, key, e.target.value)
                          }
                          disabled={edit.confirming}
                        />
                        {edit.confirming ? (
                          <ReactLoading
                            className={'busy'}
                            type={'cylon'}
                            color={'grey'}
                          />
                        ) : (
                          <Fragment>
                            <FiCheck
                              className={'cm-input-ok inline-icon-ok'}
                              size={16}
                              onClick={() =>
                                confirmEditConfigmap(cmName, key, edit.value)
                              }
                            />
                            <FiX
                              className={'cm-input-cancel inline-icon-cancel'}
                              size={16}
                              onClick={cancelEditConfigmap}
                            />
                          </Fragment>
                        )}
                      </div>
                    ) : (
                      // This key is currently not edited: Simply show the value.
                      <div
                        onClick={() => triggerEditConfigmap(cmName, key, value)}
                      >
                        <span>{value}</span>
                        <FiEdit2 size={16} className={'cm-edit-icon'} />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const {
      cmName,
      configMap: { configs },
    } = this.props;
    return (
      <div className={'configmap'}>
        <span className={'configmap-headline'}>{cmName}</span>
        {!configs || Object.keys(configs).length === 0
          ? this.renderEmptyConfig()
          : this.renderConfig()}
      </div>
    );
  }
}
ConfigMap.propTypes = {
  cmName: PropTypes.string.isRequired,
  configMap: PropTypes.object.isRequired,
  edit: PropTypes.shape({
    cmName: PropTypes.string,
    key: PropTypes.string,
    originalValue: PropTypes.string,
    value: PropTypes.string,
    confirming: PropTypes.bool,
  }),
  triggerEditConfigmap: PropTypes.func.isRequired,
  editConfigmap: PropTypes.func.isRequired,
  cancelEditConfigmap: PropTypes.func.isRequired,
  confirmEditConfigmap: PropTypes.func.isRequired,
};
