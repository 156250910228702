import { connect, ConnectedProps } from 'react-redux';
import S3BucketDetails from './S3BucketDetails';
import { withRouter } from 'react-router-dom';
import { fetchDataSources } from '../../../../redux/modules/data.dataSources.module';
import { RootState } from '../../../../store/store';
import { RouteComponentProps } from 'react-router';

export function mapStateToProps(
  state: RootState,
  {
    match,
  }: RouteComponentProps<{
    dataSourceCode: string;
    bucketName: string;
  }>
) {
  const { dataSourceCode, bucketName } = match.params;
  const dataSources = state.data.dataSources;
  const dataSource = ((dataSources || {}).data || []).find(
    (ds) => ds.code === dataSourceCode
  );
  const names = state.names;

  return {
    dataSourceCode,
    bucketName,
    dataSource,
    names,
  };
}

export const mapDispatchToProps = {
  fetchDataSources,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(S3BucketDetails));
