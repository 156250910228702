import React, { Component } from 'react';
import commonStyles from '../../newDatapoolWizard/optChannels/optCommonStyles.module.scss';
import styles from './styles.module.scss';

import { WrappedFieldProps } from 'redux-form';
import SummandGroupsTable from './SummandGroupsTable';
import { MessageDescriptor } from 'react-intl';
import RadioButtonsInput from '../../../../atoms/input-elements/radio-buttons-input/RadioButtonsInput';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { FiPlus } from 'react-icons/fi';
import InputError from '../../../../atoms/input-error/InputError';
import { _globalValidationError } from '../../../../modelManagement/modules/implementations/optimization/new-augur/optimization.form';

export type Props = {
  /** Habitat Code the Augur is supposed to be added to */
  habitatCode: string;
  /** Code of the selected datapool */
  datapoolCode: string;
  /** Is the edit mode enabled? (false = render static table of constraints)*/
  inEditMode: boolean;
};

export type TargetFunctionErrorType = {
  [constraintId: string]: {
    id?: MessageDescriptor;
    name?: MessageDescriptor;
    description?: MessageDescriptor;
    sumOf?: MessageDescriptor;
    sumOver?: MessageDescriptor;
    factor?: MessageDescriptor;
  };
};
export interface State {
  addedNewRow: boolean;
}

export default class OptTargetFunction extends Component<
  Props & WrappedFieldProps,
  State
> {
  constructor(props: Props & WrappedFieldProps) {
    super(props);

    this.state = { addedNewRow: false };
    this.expandAutomatically(props);
  }
  private expandAutomatically(props: Props & WrappedFieldProps) {
    const { input } = props;

    const isEmpty = _.isEmpty(input.value.summandGroups);

    //Also check if it was triggered by the Wizard
    if (props.inEditMode && isEmpty && !this.state.addedNewRow) {
      this.state = { addedNewRow: true };
      input.onChange({
        ...(input.value || {}),
        summandGroups: [
          ...(input.value?.summandGroups || []),
          {
            id: uuidv4(),
          },
        ],
      });
    }
  }
  render() {
    const { input, meta, inEditMode } = this.props;

    this.expandAutomatically(this.props);
    return (
      <div className={commonStyles.optComponent}>
        <div className={commonStyles.errorContainer}>
          {' '}
          {/* This empty
         container makes the Layout for each component in the optimization augur
         identical*/}
        </div>

        <div className={styles.minMaxContainer}>
          <RadioButtonsInput
            id={'optTargetFunction_minimizeOrMaximize'}
            touched={meta.touched}
            error={meta.error?.minimizeOrMaximize}
            disabled={!inEditMode}
            hasLabel={true}
            labelId={'no-id'}
            labelDefault={'Minimize or Maximize the target function?'}
            value={input.value?.minimizeOrMaximize}
            onChange={(minimizeOrMaximize: string) => {
              const newValue = {
                ...(input.value || {}),
                minimizeOrMaximize,
              };
              input.onChange(newValue);
            }}
            radioButtons={[
              {
                value: 'minimize',
                labelId: 'no-id',
                labelDefault: 'Minimize',
              },
              {
                value: 'maximize',
                labelId: 'no-id',
                labelDefault: 'Maximize',
              },
            ]}
            entriesPerRow={1}
          />
        </div>
        <div className={commonStyles.errorContainer}>
          <InputError
            touched={meta?.touched}
            error={meta?.error?.[_globalValidationError]}
          />
        </div>
        <div className={commonStyles.tableContainer}>
          <SummandGroupsTable
            input={input}
            meta={meta}
            editMode={inEditMode}
            addedNewRow={this.state.addedNewRow}
            rowIndex={input.value?.summandGroups?.length - 1 || 0}
          />
        </div>
        {inEditMode && (
          <div className={commonStyles.AddIconContainer}>
            <div
              className={commonStyles.AddIcon}
              onClick={() => {
                input.onChange({
                  ...(input.value || {}),
                  summandGroups: [
                    ...(input.value?.summandGroups || []),
                    {
                      id: uuidv4(),
                    },
                  ],
                });
                this.setState({ addedNewRow: true });
              }}
            >
              <FiPlus size={18} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
