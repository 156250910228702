import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiCheck } from 'react-icons/fi';

export default class NameInputComponent extends Component {
  errorMessage() {
    const {
      meta: { touched, error },
    } = this.props;
    if (touched && error) {
      return <p className={'error'}>{error.message}</p>;
    }
  }

  render() {
    const {
      input: { value, onChange, onFocus, onBlur },
      meta: { touched, error },
    } = this.props;
    return (
      <div className={'name-input'}>
        <input
          className={'input'}
          type={'text'}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    );
  }
}

NameInputComponent.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
};
