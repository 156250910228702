import { connect } from 'react-redux';
import Machine from './Machine';

export function mapStateToProps(state) {
  return {
    datapool: state.datapoolDetails.datapool,
  };
}

export default connect(mapStateToProps)(Machine);
