import React, { Component, Fragment } from 'react';
import { FiCornerUpLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import './backupElement.scss';
import { BackupMetaWithId } from '../types';
import { connectedProps } from './BackupElement.container';
import { FormattedDate, FormattedTime } from 'react-intl';
import { FormattedRelativeTimeConvenient } from '../../../atoms/formatted-date-time/FormattedDateTime';

export type Props = {
  bak: BackupMetaWithId;
};

export default class BackupElement extends Component<Props & connectedProps> {
  render() {
    const {
      bak,
      showRestoreBackupModal,
      templatesMeta: { data },
    } = this.props;
    const template = data.find((temp) => temp.id === bak.templateId);
    return (
      <Fragment>
        <div className={'backup-element'}>
          <div style={{ textDecoration: 'none', flexGrow: 1 }}>
            <div className={'element'}>
              <div className={'element-bak-container'}>
                <div className={'element-name'}>{bak.backupMeta.timestamp}</div>
                <div className={'element-details'}>
                  Template: {template?.name}
                </div>
              </div>

              <div className={'element-bak-buttons'}>
                <div
                  title={'Restore'}
                  className={'element-bak-button'}
                  onClick={(e) => {
                    showRestoreBackupModal(bak, template);
                    e.preventDefault();
                  }}
                >
                  <FiCornerUpLeft className={'icon'} size={'20px'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
