import { connect } from 'react-redux';

import Node from './Node';
import {
  selectPreviewNode,
  showPreviewNodePath,
  deselectPreviewNode,
  showSelectedNodePath,
  hidePreviewNodePath,
  selectNode,
} from '../../../redux/modules/chart.tree.module';
import { getNodeType } from './treeChart/nodeParse';

export const onNodeEnter = (dispatch) => (node) => {
  dispatch(selectPreviewNode(node));
  dispatch(showPreviewNodePath());
};

export const onNodeLeave = (dispatch) => (node) => {
  dispatch(deselectPreviewNode(node));
  dispatch(hidePreviewNodePath());
};

export const onNodeClick = (dispatch) => (node) => {
  dispatch(selectNode(node));
  dispatch(showSelectedNodePath());
};

export function mapStateToProps(state, { node }) {
  return {
    node,
    isActive:
      node === state.chart.active.tree.selected.node ||
      node === state.chart.active.tree.preview.node,
    type: getNodeType(node),
    isSelected:
      !!state.chart.active.tree.selected.node.data &&
      node.data.id === state.chart.active.tree.selected.node.data.id,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    onNodeEnter: onNodeEnter(dispatch),
    onNodeLeave: onNodeLeave(dispatch),
    onNodeClick: onNodeClick(dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Node);
