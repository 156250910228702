import { connect } from 'react-redux';
import GitListCommits from './GitListCommits';
import { gitListCommits } from '../../../../../../redux/workbench/modules/container-interactions.module';

export function mapStateToProps(state) {
  const { loading, loaded, data, error } =
    state.workbench.showRepositoryInfo.gitCommits;
  const { data: unpushedData } =
    state.workbench.showRepositoryInfo.gitNotPushedCommits;
  return {
    activeBranch: state.workbench.showRepositoryInfo.activeBranch,
    loading,
    loaded,
    data,
    error,
    unpushedData,
  };
}

export const mapDispatchToProps = {
  gitListCommits,
};

export default connect(mapStateToProps, mapDispatchToProps)(GitListCommits);
