import { Link, LinkProps, useLocation } from 'react-router-dom';
import { FC } from 'react';

export const LinkWithQuery: FC<LinkProps> = ({ children, to, ...props }) => {
  const { search } = useLocation();

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
};
