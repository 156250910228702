import {
  jobGroupField,
  selectedScheduleField,
} from '../../components/orchestration/job-schedules/add-schedule/newSchedule.form';

export const initial = {
  step: 0,
  numberOfSteps: 2,
  submitting: false,
  fieldNames: [
    [
      selectedScheduleField + '.trigger',
      selectedScheduleField + '.period',
      selectedScheduleField + '.periodValue',
      selectedScheduleField + '.time',
      selectedScheduleField + '.trigDelayed',
    ],
    [jobGroupField],
  ],
  stepsVisited: [false, false],
  stepsValid: [false, false],
  jobPicker: {
    activeTabs: {},
  },
};

export const test = {
  step: 0,
  numberOfSteps: 2,
  submitting: false,
  fieldNames: [
    [
      selectedScheduleField + '.trigger',
      selectedScheduleField + '.period',
      selectedScheduleField + '.periodValue',
      selectedScheduleField + '.time',
      selectedScheduleField + '.trigDelayed',
    ],
    [jobGroupField],
  ],
  stepsVisited: [false, false],
  stepsValid: [false, false],
  jobPicker: {
    activeTabs: {},
  },
};
