import React from 'react';
import P3OutputElementParent from '../../../python3-output-cell/elements/_interface/P3OutputElementParent.container';
import P3OutputElementParentApp from '../../../python3-output-cell/elements/_interface/P3OutputElementParentApp.container';

export type SettingsElement = {
  inputValue: string;
  origin: string;
  variableName: string;
};

export type AsOutputElement = {
  id: string;
  type: string; // "code-output" | "file-download" -> maybe narrow down the type later

  settings: {
    // The settings defined when creating the notebook (in the output element settings modal)
    // --- Generic settings
    description?: SettingsElement;
    label?: SettingsElement;
    /** Path for the file to download in the download file button */
    downloadPath?: SettingsElement;
    /** Label for the download button */
    downloadButtonLabel?: SettingsElement;
  };
};

export default abstract class P3OutputElement {
  // --- Elements

  // react component of the output element (for example the output)
  abstract ChildComponent: React.ComponentType<any>;
  // React component of the additional settings in the settings modal.
  //  Is allowed to be 'undefined' if there are no additional settings for this component
  abstract AdditionalSettings: React.ComponentType;
  // Type of the output element - this is how the element is looked up for rendering
  abstract type: string;
  // Name of the output type - displayed
  abstract name: string;
  // CSS-class that is passed to the parent container for styling-issues
  abstract parentContainerClass: string;
  // Icon component
  abstract icon: any;
  // Color of the icon (used as the fill for the circle if the default icon is used)
  abstract iconColor: string;
  // Does the component have settings? (decides for example whether a 'settings' icon is displayed)
  abstract hasSettings: boolean;

  // --- Functions

  // Function to validate the settings in the element modal (label, description, outputVariable, ...)
  abstract validateSettings: (values: object, variables: object[]) => object;

  /**
   * Renders the input element for the notebook view
   * @param props
   */
  renderWrappedComponent(props: any) {
    return (
      <P3OutputElementParent
        parentContainerClass={this.parentContainerClass}
        {...props}
      >
        <this.ChildComponent
          path={props.path}
          element={props.element}
          displayMode={'large'}
          parentType={'notebook'}
          cell={props.cell}
        />
      </P3OutputElementParent>
    );
  }

  /**
   * Renders the input element for the app view
   * @param props
   */
  renderWrappedComponentForApp(props: any) {
    return (
      <P3OutputElementParentApp
        parentContainerClass={this.parentContainerClass}
        {...props}
      >
        <this.ChildComponent
          path={props.path}
          element={props.element}
          cell={props.cell}
          parentType={props.parentType}
          displayMode={'slim'}
          appVersionCode={props.appVersionCode}
        />
      </P3OutputElementParentApp>
    );
  }
}
