import React from 'react';
import DatapoolSettingsElement from '../../../../../_interfaces/datapool-details/settings/DatapoolSettingsElement';
import DataSourceContainer from './DataSource.container';
import datapoolMsgs from 'common/dist/messages/datapools';

/**
 * Shows the dataSourceCode in the datapool settings tab
 */
export default class DataSource implements DatapoolSettingsElement {
  // @ts-ignore
  component: React.Component = DataSourceContainer;
  title = datapoolMsgs.msgDatapoolSettingsDataSourceTitle;
  description = datapoolMsgs.msgDatapoolSettingsDataSourceDescription;
}
