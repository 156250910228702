import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';
import { allInputElements } from './elements/_interface/Python3InputElementManager';
import { v4 as uuidv4 } from 'uuid';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const defaultElement = (type) => ({
  type,
  data: {},
  label: 'Enter label',
  description: 'Enter description',
});

export default class InputElementSelectModal extends Component {
  render() {
    const {
      isOpen,
      closeDialog,
      path,
      cellId,
      addInputElement,
      showEditInputElement,
      toggleInputElements,
    } = this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel='Dialog Modal'
        onRequestClose={closeDialog}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer')
          /* Function that will be called to get the parent element
             that the modal will be attached to. */
        }
      >
        <div className={'modal-select-parent'}>
          <div
            title={'Close Dialog'}
            className={'workbench-button close-button'}
            onClick={closeDialog}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <div className={'modal-select-elements'}>
            {allInputElements.map((e) => (
              <div
                className={'selectable-element'}
                onClick={() => {
                  const elementId = uuidv4();
                  const element = { ...defaultElement(e.type), id: elementId };
                  addInputElement(path, cellId, element, elementId);
                  toggleInputElements(path, cellId, true); // Make sure the input elements are expanded (otherwise it's bad usability: it feels like no element is added)
                  closeDialog();
                  showEditInputElement(element);
                }}
              >
                <div className={'element-icon'}>
                  <e.icon
                    width={24}
                    height={24}
                    elementName={e.name}
                    elementType={e.type}
                    iconColor={e.iconColor}
                  />
                </div>
                <p className={'element-name'}>{e.name}</p>
              </div>
            ))}
          </div>
        </div>
      </ReactModal>
    );
  }
}
InputElementSelectModal.propTypes = {
  isOpen: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  /** Path of the parent notebook (required for changes) */
  path: PropTypes.string,
  /** ID of the Parent cell this input element belongs to */
  cellId: PropTypes.string,
  /** Function to add anv*/
  addInputElement: PropTypes.func.isRequired,
  showEditInputElement: PropTypes.func.isRequired,
  toggleInputElements: PropTypes.func.isRequired,
};
