import {
  AdminBackupsActions,
  CREATE_BACKUP,
  CREATE_BACKUP_FAIL,
  CREATE_BACKUP_SUCCESS,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_FAIL,
  CREATE_TEMPLATE_SUCCESS,
  FETCH_BACKUPS,
  FETCH_BACKUPS_FAIL,
  FETCH_BACKUPS_SUCCESS,
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FAIL,
  FETCH_TEMPLATES_SUCCESS,
  HIDE_RESTORE_BACKUP_MODAL,
  SHOW_RESTORE_BACKUP_MODAL,
} from './actions.types';
import { DeprecatedRootState } from '../../state.type';
import { AdminBackupsState } from './state.types';

export function reducer(
  state: DeprecatedRootState,
  action: AdminBackupsActions
): AdminBackupsState {
  switch (action.type) {
    case FETCH_BACKUPS: {
      return {
        ...state.adminBackups,
        backups: {
          ...state.adminBackups.backups,
          loading: true,
        },
      };
    }

    case FETCH_BACKUPS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state.adminBackups,
        backups: {
          ...state.adminBackups.backups,
          loading: false,
          loaded: true,
          data,
          error: undefined,
        },
      };
    }

    case FETCH_BACKUPS_FAIL: {
      const { error } = action.payload;
      return {
        ...state.adminBackups,
        backups: {
          ...state.adminBackups.backups,
          loading: false,
          loaded: false,
          error,
        },
      };
    }

    case SHOW_RESTORE_BACKUP_MODAL: {
      const { bak, template } = action.payload;
      return {
        ...state.adminBackups,
        restoreBackupModal: {
          ...state.adminBackups.restoreBackupModal,
          show: true,
          bak,
          template,
        },
      };
    }

    case HIDE_RESTORE_BACKUP_MODAL: {
      return {
        ...state.adminBackups,
        restoreBackupModal: {
          ...state.adminBackups.restoreBackupModal,
          show: false,
        },
      };
    }

    case CREATE_BACKUP: {
      return {
        ...state.adminBackups,
        createBackup: {
          ...state.adminBackups.createBackup,
          submitting: true,
        },
      };
    }

    case CREATE_BACKUP_SUCCESS: {
      return {
        ...state.adminBackups,
        createBackup: {
          ...state.adminBackups.createBackup,
          submitting: false,
        },
      };
    }

    case CREATE_BACKUP_FAIL: {
      const { error } = action.payload;
      return {
        ...state.adminBackups,
        createBackup: {
          ...state.adminBackups.createBackup,
          submitting: false,
          error,
        },
      };
    }

    case FETCH_TEMPLATES: {
      return {
        ...state.adminBackups,
        templates: {
          ...state.adminBackups.templates,
          loading: true,
        },
      };
    }

    case FETCH_TEMPLATES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state.adminBackups,
        templates: {
          ...state.adminBackups.templates,
          loading: false,
          loaded: true,
          data,
          error: undefined,
        },
      };
    }

    case FETCH_TEMPLATES_FAIL: {
      const { error } = action.payload;
      return {
        ...state.adminBackups,
        templates: {
          ...state.adminBackups.templates,
          loading: false,
          loaded: false,
          error,
        },
      };
    }

    case CREATE_TEMPLATE: {
      return {
        ...state.adminBackups,
        createTemplate: {
          ...state.adminBackups.createTemplate,
          submitting: true,
        },
      };
    }

    case CREATE_TEMPLATE_SUCCESS: {
      return {
        ...state.adminBackups,
        createTemplate: {
          ...state.adminBackups.createTemplate,
          submitting: false,
        },
      };
    }

    case CREATE_TEMPLATE_FAIL: {
      const { error } = action.payload;
      return {
        ...state.adminBackups,
        createTemplate: {
          ...state.adminBackups.createTemplate,
          submitting: false,
          error,
        },
      };
    }

    default: {
      return state.adminBackups;
    }
  }
}

export { reducer as adminBackupsReducer };
