import { connect } from 'react-redux';
import SspSettings from './SspSettings';
import { formValueSelector } from 'redux-form';
import { formName, fieldCategories } from '../_interface/input-element.form';

export function mapStateToProps(state) {
  const categories = formValueSelector(formName)(state, fieldCategories);

  return {
    categories,
  };
}

export default connect(mapStateToProps)(SspSettings);
