import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Bubble extends Component {
  static propTypes = {
    className: PropTypes.string,
    radius: PropTypes.number.isRequired,
    fill: PropTypes.string.isRequired,
    strokeWidth: PropTypes.number.isRequired,
    stroke: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    fontSize: PropTypes.number.isRequired,
    maxSymbols: PropTypes.number,
  };

  static defaultProps = {
    strokeWidth: 0,
    stroke: 'none',
    maxSymbols: 12,
  };

  ellipsis = (str) => {
    let modificatedString = str;
    let length = modificatedString.length;
    const ellipsis = '...';

    while (length > this.props.maxSymbols - 1) {
      modificatedString = modificatedString.substring(0, length - 1);
      modificatedString += ellipsis;
      length--;
    }

    return modificatedString;
  };

  formatValue = (raw, float) => {
    return (raw * 100).toFixed(float) + '%';
  };

  textIE = () => (
    <text x='0' y='0' textAnchor='middle'>
      <tspan
        className='bubble-chart_bubble-text'
        style={{
          width: `${length}px`,
          fontSize: `${this.props.fontSize * 100}%`,
        }}
      >
        <tspan x='0'>
          {this.ellipsis(this.formatValue(this.props.value, 2))}
        </tspan>
        <tspan x='0' dy='1.1em'>
          {this.ellipsis(this.props.text)}
        </tspan>
      </tspan>
    </text>
  );

  render() {
    const {
      className,
      radius,
      fill,
      text,
      value,
      x,
      y,
      fontSize,
      stroke,
      strokeWidth,
    } = this.props;

    const length = Math.sqrt(2) * radius;

    return (
      <g className={className} transform={`translate(${x}, ${y})`}>
        <circle
          r={radius}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
        <g>
          <switch>
            <foreignObject
              x={-length / 2}
              y={-length / 2}
              width={length}
              height={length}
              requiredFeatures='http://www.w3.org/TR/SVG11/feature#Extensibility'
            >
              <div
                className='bubble-chart_bubble-text'
                style={{
                  width: `${length}px`,
                  fontSize: `${fontSize * 100}%`,
                }}
              >
                <p style={{ padding: 0 }}>{this.formatValue(value, 2)}</p>
                <p style={{ padding: 0 }}>{text}</p>
              </div>
            </foreignObject>
            <text dx={-length / 2}>
              <tspan x='0'>{this.formatValue(value, 2)}</tspan>
              <tspan x='0' dy='1em'>
                {text}
              </tspan>
            </text>
          </switch>
        </g>
      </g>
    );
  }
}
