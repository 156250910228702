import { VARIABLE_TYPES } from '../variableTypes';
import {
  BaseVariableHandler,
  getFirstRaw,
  isArray,
  numberRegex,
} from './helper';
import { handleJson } from './JsonVariableHandler';

export const ListOfNumberVariableHandler: BaseVariableHandler<string> = {
  type: VARIABLE_TYPES.LIST_OF_NUMBER,
  displayName: 'List of Number',
  getExecutionCode: (inputValue: string) => inputValue,
  parseValue: (variable, rawValues) => {
    const firstRaw = getFirstRaw(variable, rawValues);
    if (!firstRaw) return { parsedValue: undefined, error: undefined };
    const { parsedValue, error } = handleJson(variable, rawValues, firstRaw);
    if (error)
      return { parsedValue: undefined, error: "Couldn't parse List of Number" };

    // Validate the parsed value
    try {
      if (!isArray(parsedValue))
        return { parsedValue: undefined, error: 'Input was no list' };

      // Remove all values that don't satisfy the number regex
      const filteredArray = parsedValue.filter((v) =>
        v.toString().match(numberRegex)
      );
      const parsedArray = filteredArray.map((v) => Number.parseFloat(v));
      return { parsedValue: parsedArray, error: undefined };
    } catch (e) {
      return { parsedValue: undefined, error: "Couldn't parse List of Number" };
    }
  },
};
