import React, { FunctionComponent } from 'react';
import './borderBox.scss';

const BorderBox: FunctionComponent<{ title?: string; style?: object }> = ({
  title,
  children,
  style = {},
}) => (
  <div>
    {title && title !== '' && <div className={'title-box'}>{title}</div>}
    <div className={'border-box'} style={style}>
      {children}
    </div>
  </div>
);

export default BorderBox;
