import { connect } from 'react-redux';
import StepTag from './StepTag';
import { fetchAppVersions } from '../../../../../redux/modules/apps.module';

export function mapStateToProps(state, { appCode }) {
  const {
    loaded: versionsLoaded,
    loading: versionsLoading,
    error: versionsError,
    data: versionsData,
  } = state.appVersions[appCode] || {};
  return {
    versionsLoaded,
    versionsLoading,
    versionsError,
    versionsData,
  };
}

export const mapDispatchToProps = {
  fetchAppVersions,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTag);
