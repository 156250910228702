import { connect } from 'react-redux';
import StepPath from './StepPath';
import { defaultBrowserSize } from '../../../workbench/part-left/BrowserTabLinks';
import { fetchContent } from '../../../../redux/workbench/modules/content.module';

export function mapStateToProps(state) {
  return {
    selectedDirPath: state.workbench.content.selectedDirPath,
    repoName: state.workbench.showCloneRepository.repoName,
    width: state.workbench.browserWidth || defaultBrowserSize,
    content: state.workbench.content,
    error: (state.workbench.content || {}).error,
  };
}

export const mapDispatchToProps = {
  fetchContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepPath);
