import TabLineBubbles from '../../molecules/tab-line-bubbles/TabLineBubbles';
import { dataManagementRoutes } from '../routes';
import { Route, Switch } from 'react-router-dom';
import Authentication from './authentication/Authentication';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { FiDatabase, FiKey } from 'react-icons/fi';
import Buckets from './buckets/Buckets.container';

export type Props = {
  code: string;
};
const Tabs: FC<Props> = (props) => {
  const urlParts = useLocation().pathname.split('/');
  const activeTab = `${props.code}-${urlParts[urlParts.length - 1]}`;
  return (
    <div className={'data-source-body-tabs'}>
      <div className={'data-source-body-tabs-line'}>
        <TabLineBubbles
          buttons={[
            {
              id: `${props.code}-${dataManagementRoutes.buckets}`,
              to: `${dataManagementRoutes.basePath}/s3/${props.code}/${dataManagementRoutes.buckets}`,
              intlId: 'datapools.s3.buckets',
              intlDefault: 'Buckets',
              icon: () => <FiDatabase size={16} />,
            },
            {
              id: `${props.code}-${dataManagementRoutes.auth}`,
              to: `${dataManagementRoutes.basePath}/s3/${props.code}/${dataManagementRoutes.auth}`,
              intlId: 'datapools.s3.auth',
              intlDefault: 'Authentication',
              icon: () => <FiKey size={16} />,
            },
          ]}
          activeButton={activeTab}
        />
      </div>
      <div className={'data-source-body-tabs-content'}>
        <Switch>
          <Route
            exact
            path={`${dataManagementRoutes.basePath}/s3/${props.code}/${dataManagementRoutes.buckets}`}
          >
            {/* @ts-ignore */}
            <Buckets dataSourceCode={props.code} />
          </Route>
          <Route
            exact
            path={`${dataManagementRoutes.basePath}/s3/${props.code}/${dataManagementRoutes.auth}`}
          >
            <Authentication dataSourceCode={props.code} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Tabs;
