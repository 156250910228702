import React, { FC } from 'react';
import styles from './styles.module.scss';

import SettingsForm from './SettingsForm';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../types';
import AugurSettingsCategory from '../../_interfaces/augur-details/settings/AugurSettingsCategory';
import { useAugur } from '../../../../../../core/api/augurs';

export type Props = {
  settingsCategories: AugurSettingsCategory[];
};

const Settings: FC<Props> = ({ settingsCategories }) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const { data: augur } = useAugur(habitatCode, augurCode);
  const inputFormat = augur?.datapool?.inputFormat;

  return (
    <div className={styles.augurDetailsSettings}>
      <SettingsForm
        settingsCategories={settingsCategories}
        habitatCode={habitatCode}
        augurCode={augurCode}
        inputFormat={inputFormat}
      />
    </div>
  );
};

export default Settings;
