import AugurSettingsCategory, {
  WhiteOrGrey,
} from '../../../../_interfaces/augur-details/settings/AugurSettingsCategory';
import AugurSettingsElement from '../../../../_interfaces/augur-details/settings/AugurSettingsElement';
import AugurNameAndCode from './elements/AugurNameAndCode';
import DatapoolNameAndCode from './elements/DatapoolNameAndCode';
import ArchetypeNameAndCode from './elements/ArchetypeNameAndCode';
import HabitatNameAndCode from './elements/HabitatNameAndCode';
import Attributes from './elements/Attributes';

/**
 * Shows the habitat name & code, datapool name & code, archetype name & code and augur name & code in the Augur Settings
 */
export default class NamesAndCodesCategory implements AugurSettingsCategory {
  title = 'dashboard.augur_settings.category.names_and_codes';
  children(inputFormat: string): AugurSettingsElement[] {
    return [
      new AugurNameAndCode(),
      new DatapoolNameAndCode(),
      new ArchetypeNameAndCode(),
      new HabitatNameAndCode(),
      new Attributes(),
    ];
  }
  backgroundColor: WhiteOrGrey = 'white';
}
