import { MessageDescriptor } from 'react-intl';

export interface Component {
  name: string;
}

export const Components = {
  POSTGRES: 'postgres',
  GITEA: 'gitea',
  WORKBENCH: 'workbench',
};

export const ComponentsSpeaking = {
  POSTGRES: 'AltaSigma Database',
  GITEA: 'Git',
  WORKBENCH: 'Workbench',
};

export interface BackupMeta {
  // Format 2021-07-07 09:37:17
  timestamp: string;
  id: string;
}

export interface BackupMetaWithId {
  backupMeta: BackupMeta;
  templateId: string;
}

export type BackupListResponse = BackupMetaWithId[];

export interface TemplateMeta {
  components: Component[];
  name: string;
  timestamp: string;
  id: string;
}

export interface BackupRestoreRequest {
  backupId: string;
  templateId: string;
}

export interface BackupCreateRequest {
  templateId: string;
}

export interface BackupCreateResponse {
  timestamp: string;
  id: string;
}

export interface TemplateCreateRequest {
  components: Component[];
  name: string;
}

export interface TemplateCreateResponse {
  timestamp: string;
  id: string;
}

export interface EnableMaintenanceRequest {
  title: MessageDescriptor;
  description: MessageDescriptor;
}
