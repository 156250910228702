import { ComponentType } from 'react';
import ConstraintExhaustion from './ConstraintExhaustion';
import { DatapoolType } from 'common/dist/types/datapool';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { FiAlignLeft } from 'react-icons/fi';
import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';

export default class OptConstraintExhaustionWrapper extends ComponentAugurDetailsTab {
  type: 'component' = 'component';
  id = 'constraintExhaustion';
  title = moduleOptimizationMessages.msgAugurDetailsConstraintExhaustionTabName;
  to = (
    habitatCode: string,
    augurCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortDatapoolType}/constraintExhaustion`;

  icon: ComponentType = () => <FiAlignLeft size={16} />;
  component: ComponentType<any> = ConstraintExhaustion;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType): boolean => true;
}
