import * as React from 'react';
import { Component } from 'react';
import {
  DataSourceOrdering,
  dataSourceOrderings,
  Props,
} from './ManageDataSources.container';
import DataSourceContentElement, {
  Props as DataSourceContentProps,
} from './DataSourceContentElement.container';
import SortableSearchableListContainer from '../../organisms/sortable-searchable-list/SortableSearchableList.container';
import { dataManagementRoutes } from '../routes';
import ConfirmationModal from '../../organisms/confirmation-modal/ConfirmationModal';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';

export default class ManageDataSources extends Component<Props> {
  render() {
    const {
      dataSources,
      deleteDataSourceConfirm,
      deleteDataSource,
      hideDeleteDataSourceConfirm,
    } = this.props;
    const data = dataSources !== undefined ? dataSources : [];
    const List = SortableSearchableListContainer<
      DataSource,
      DataSourceOrdering,
      DataSourceContentProps
    >();
    return (
      <div className={'data-source-parent'}>
        <List
          orderings={dataSourceOrderings}
          defaultOrdering={DataSourceOrdering.NameAsc}
          data={data}
          // @ts-ignore
          contentProps={(ds: DataSource, i: number) => ({ ds })}
          ContentComponent={DataSourceContentElement}
          search={(ds: DataSource, searchString: string) =>
            ds.name.includes(searchString)
          }
          sort={(dss: DataSource[], ordering: DataSourceOrdering) => {
            switch (ordering) {
              case DataSourceOrdering.LastCreated:
                return dss.sort((a, b) =>
                  a.created_at > b.created_at ? -1 : 1
                );
              case DataSourceOrdering.OldestCreated:
                return dss.sort((a, b) =>
                  a.created_at < b.created_at ? -1 : 1
                );
              case DataSourceOrdering.NameAsc:
                return dss.sort((a, b) => (a.name < b.name ? -1 : 1));
              case DataSourceOrdering.NameDesc:
                return dss.sort((a, b) => (a.name > b.name ? -1 : 1));
            }
          }}
          title={'Data Sources'}
          linkBack={false}
          button={{
            buttonText: 'Add Data Source',
            link: `${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}/add`, // TODO
          }}
        />
        <ConfirmationModal
          secure={true}
          secureInput={deleteDataSourceConfirm.name}
          show={deleteDataSourceConfirm?.show}
          payload={{ code: deleteDataSourceConfirm?.code }}
          buttonConfirmAction={({ code }) => {
            deleteDataSource(code);
          }}
          hideModal={hideDeleteDataSourceConfirm}
          buttonConfirmText={{
            id: 'todo',
            defaultMessage: 'Delete',
          }}
          buttonConfirmColor={'red'}
          description={{
            id: 'todo',
            defaultMessage:
              'You are going to delete the connection to data source {nameBold}. Please type in {nameCode} and confirm if you are sure.',
            values: {
              nameBold: <b>{deleteDataSourceConfirm.name}</b>,
              nameCode: <code>{deleteDataSourceConfirm.name}</code>,
            },
          }}
          headline={{
            id: 'todo',
            defaultMessage: 'Delete Data Source',
          }}
          headlineColor={'red'}
        />
      </div>
    );
  }
}
