import React, { FC } from 'react';
import styles from '../styles.module.scss';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Optional } from 'common/dist/types/helpers';

type Props = {
  id?: string;
  /** Column in the grid layout */
  gridColumn: number;
  /** Row in the grid layout */
  gridRow: number;
  /** Key */
  detailKey: MessageDescriptor;
  /** Value */
  detailValue: Optional<MessageDescriptor, 'id'>;
};

const DetailValue: FC<Props> = (props: Props) => {
  const { gridRow, gridColumn, detailKey, detailValue, id } = props;
  return (
    <div
      id={id}
      className={styles.detailValue}
      style={{
        gridRow,
        gridColumn,
      }}
    >
      <div className={styles.detailValueKey}>
        <FormattedMessage {...detailKey} />
      </div>
      <div id={`${id}-value`} className={styles.detailValueValue}>
        {detailValue.id ? (
          <FormattedMessage {...detailValue} />
        ) : (
          detailValue.defaultMessage
        )}
      </div>
    </div>
  );
};

export default DetailValue;
