import React, { Component } from 'react';
import JobGroupPreQueue from './JobGroupPreQueue.container';
import Busy from '../../../atoms/busy/Busy';
import IndicatorEmpty from '../../../molecules/indicator-empty/IndicatorEmpty';
import { ORCHESTRATION_ORIGINS } from '../../links';
import { Props } from './PreQueue.container';
import messagesOrchestration from 'common/dist/messages/orchestration';
import { FormattedMessage } from 'react-intl';

export default class PreQueue extends Component<Props> {
  poller: ReturnType<typeof setTimeout>;

  componentDidMount() {
    // Start reloading the queue every 5 seconds
    const { fetchPreQueue } = this.props;
    this.poller = setInterval(() => fetchPreQueue(false), 5000);
  }

  componentWillUnmount() {
    if (this.poller) {
      // Stop reloading the queue every 5 seconds
      clearInterval(this.poller);
    }
  }

  /**
   * Renders the Job Groups when the PreQueue was loaded successfully
   * @returns {*}
   */
  renderLoading() {
    return (
      <div className={'pre-queue-container'}>
        <Busy isBusy positionAbsolute />
      </div>
    );
  }

  renderEmpty() {
    return (
      <IndicatorEmpty
        classNameImage={'pre-queue-empty-pic'}
        headlineId={messagesOrchestration.jobQueueEmptyHeadline.id}
        headlineDefault={
          messagesOrchestration.jobQueueEmptyHeadline.defaultMessage
        }
        descriptionId={messagesOrchestration.jobQueueEmptyDescription.id}
        descriptionDefault={
          messagesOrchestration.jobQueueEmptyDescription.defaultMessage
        }
      />
    );
  }

  renderLoaded() {
    const { jobGroupCodes } = this.props;
    return (
      <div className={'pre-queue-container'}>
        <span
          style={{
            marginBottom: '10px',
            display: 'block',
          }}
        >
          <FormattedMessage
            {...messagesOrchestration.jobQueueAmount}
            values={{ amount: jobGroupCodes.length }}
          />
        </span>
        <div className={'pre-queue-header'}>
          <strong>Job Group</strong>
          <strong>Name</strong>
          <strong>Created</strong>
          <strong>By</strong>
          <strong>Actions</strong>
        </div>
        {jobGroupCodes.map((jobGroupCode) => (
          <JobGroupPreQueue
            key={jobGroupCode}
            jobGroupCode={jobGroupCode}
            detailsOrigin={ORCHESTRATION_ORIGINS.queue}
          />
        ))}
      </div>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className={'pre-queue-container'}>
        <span>Error: {JSON.stringify(error)}</span>
      </div>
    );
  }

  render() {
    const { loading, loaded, error, jobGroupCodes } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (jobGroupCodes.length === 0) return this.renderEmpty();
    return this.renderLoaded();
  }
}
