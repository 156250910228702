import { connect } from 'react-redux';
import Versions from './Versions';
import { withRouter } from 'react-router-dom';
import { triggerRunCodeCapsuleJob } from '../../../../redux/modules/triggerJobs.module';

export function mapStateToProps(state, { match }) {
  const codeCapsuleCode = match.params.codeCapsuleCode;
  const codeCapsule = state.codeCapsules.perCode[codeCapsuleCode];
  const { loading, loaded, error, data } = codeCapsule ? codeCapsule : {};

  return {
    codeCapsuleCode,
    loading,
    loaded,
    error,
    data,
  };
}

export const mapDispatchToProps = {
  triggerRunCodeCapsuleJob,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Versions)
);
