import { connect } from 'react-redux';

import ProfileMenuButton from './ProfileMenuButton';
import { onMenu, closeMenu } from '../../../../redux/modules/menu.module';
import * as selector from '../../../../redux/selectors/user.selector';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    currentUser: selector.currentUser(state),
    photo: state.account.photo?.data,
    isMenuShown: state.menu?.isMenuShown,
  };
}
export const mapDispatchToProps = {
  openMenu: onMenu,
  closeMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenuButton);
