import PropTypes from 'prop-types';

export const outputElementShape = PropTypes.shape({
  /** ID of the element (globally unique) */
  id: PropTypes.string,
  /** Label of the element (shown above the element) */
  label: PropTypes.string,
  /** Description of the element (shown above the element) */
  description: PropTypes.string,
  /** Additional data/settings of the element */
  data: PropTypes.object,
});
