import { ComponentType } from 'react';
import { ComponentDatapoolDetailsTab } from '../../_interfaces/datapool-details/DatapoolDetailsTab';
import Propensities from './Propensities.container';
import { DatapoolType } from 'common/dist/types/datapool';
import { FiLink2 } from 'react-icons/all';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';

export default class OptPropensitiesWrapper extends ComponentDatapoolDetailsTab {
  type: 'component' = 'component';
  id = 'propensities';
  title = moduleOptimizationMessages.msgDatapoolDetailsPropensitiesTabName;
  to = (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${shortDatapoolType}/propensities`;

  icon: ComponentType = () => <FiLink2 size={16} />;
  component: ComponentType<any> = Propensities;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType): boolean => true;
}
