import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ArchetypesList from './archetypes-list/ArchetypesList';
import Busy from '../../../../../../components/atoms/busy/Busy';

class Archetypes extends React.Component {
  // eslint-disable-next-line no-undef
  componentDidMount = () => {
    const { fetchArchetypes, match } = this.props;

    fetchArchetypes(match.params.habitatCode, match.params.datapoolCode);
  };

  render() {
    const {
      archetypesLoading,
      archetypesLoaded,
      archetypes,
      archetypesError,
      archetypeVersions,
      fetchArchetypeVersions,
      archetypesExpanded,
      expandArchetype,
      collapseArchetype,
    } = this.props;

    return (
      <Busy isBusy={archetypesLoading}>
        <div className='h100p archetypes-container'>
          {!!archetypesError && (
            <FormattedMessage
              id='augur.error'
              defaultMessage='Error: {error}'
              values={{ error: archetypesError }}
            />
          )}

          {archetypesLoaded && archetypes.length > 0 && (
            <Fragment>
              {}
              <div className={'archetype-list-wrapper'}>
                <FormattedMessage
                  id='datapoolDetails.stock_archetypes'
                  defaultMessage='Standard Archetypes'
                >
                  {(txt) => <span className='archetypes-headline'>{txt}</span>}
                </FormattedMessage>
                <ArchetypesList
                  archetypes={archetypes.filter((k) => !k.custom)}
                  archetypeVersions={archetypeVersions}
                  fetchArchetypeVersions={fetchArchetypeVersions}
                  archetypesExpanded={archetypesExpanded}
                  expandArchetype={expandArchetype}
                  collapseArchetype={collapseArchetype}
                />
              </div>

              {}
              <div className={'archetype-list-wrapper'}>
                <FormattedMessage
                  id='datapoolDetails.custom_archetypes'
                  defaultMessage='Custom Archetypes'
                >
                  {(txt) => <span className='archetypes-headline'>{txt}</span>}
                </FormattedMessage>
                <ArchetypesList
                  archetypes={archetypes.filter((k) => k.custom)}
                  archetypeVersions={archetypeVersions}
                  fetchArchetypeVersions={fetchArchetypeVersions}
                  archetypesExpanded={archetypesExpanded}
                  expandArchetype={expandArchetype}
                  collapseArchetype={collapseArchetype}
                />
              </div>
            </Fragment>
          )}
        </div>
      </Busy>
    );
  }
}
Archetypes.propTypes = {
  fetchArchetypes: PropTypes.func.isRequired,
  archetypes: PropTypes.array.isRequired,
  archetypesLoading: PropTypes.bool.isRequired,
  archetypesLoaded: PropTypes.bool.isRequired,
  archetypesError: PropTypes.any,
  archetypeVersions: PropTypes.array.isRequired,
  fetchArchetypeVersions: PropTypes.func.isRequired,
  /** Key value pairs archetypeCode -> expanded (true/false) */
  archetypesExpanded: PropTypes.object,
  expandArchetype: PropTypes.func.isRequired,
  collapseArchetype: PropTypes.func.isRequired,
};
Archetypes.defaultProps = {
  archetypesExpanded: {},
};

export default Archetypes;
