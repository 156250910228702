import React, { Component } from 'react';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { MetaShape } from '../../../../../store/dataManagement/state.types';

interface UploadSummaryProps {
  /** Meta data that was entered in the Upload Wizard */
  meta?: MetaShape;
  /** Derived by the Data Management API: Errors concerning the Keyspace */
  keyspaceErrors?: string[];
  /** Derived by the Data Management API: Errors concerning the Table */
  tableErrors?: string[];
  /** Derived by the Data Management API: Errors concerning the Data itself */
  dataErrors?: string[];
}

export default class UploadSummary extends Component<UploadSummaryProps> {
  render() {
    const {
      meta: { keyspace, table, addlInfo },
      keyspaceErrors,
      tableErrors,
      dataErrors,
    } = this.props;

    const options = [{ value: addlInfo.charset, label: addlInfo.charset }];

    return (
      <div className={'upload-summary-container'}>
        {/* --- KEYSPACE --- */}
        <div className={'summary-headline-container'}>
          <span className={'summary-headline'}>Keyspace</span>
          {keyspaceErrors && keyspaceErrors.length > 0 ? (
            <div className={'validity-label invalid'}>invalid</div>
          ) : (
            <div className={'validity-label valid'}>valid</div>
          )}
        </div>
        <div className={'summary-info-container'}>
          <div className={'info-row'}>
            <span>Name: {keyspace.name}</span>
          </div>
          <div className={'info-row'}>
            <span>Strategy: {keyspace.strategy}</span>
          </div>
        </div>
        {keyspaceErrors && keyspaceErrors.length > 0 && (
          <div className={'commit-error-container'}>
            {keyspaceErrors.map((ke) => (
              <span>{ke}</span>
            ))}
          </div>
        )}

        {/* --- TABLE --- */}
        <div className={'summary-headline-container'}>
          <span className={'summary-headline'}>Table</span>
          {tableErrors && tableErrors.length > 0 ? (
            <div className={'validity-label invalid'}>invalid</div>
          ) : (
            <div className={'validity-label valid'}>valid</div>
          )}
        </div>
        <div className={'summary-info-container'}>
          <div className={'info-row'}>
            <span>Name: {table.name}</span>
          </div>
          <div className={'info-row'}>
            <span>Strategy: {table.strategy}</span>
          </div>
          {table.strategy === 'append' && (
            <div className={'info-row'}>
              <span>Concatenation Strategy: {table.concatStrategy}</span>
            </div>
          )}
        </div>
        {tableErrors && tableErrors.length > 0 && (
          <div className={'commit-error-container'}>
            {tableErrors.map((te) => (
              <span>{te}</span>
            ))}
          </div>
        )}

        {/* --- ADDITIONAL INFORMATION --- */}
        <div className={'summary-headline-container'}>
          <span className={'summary-headline'}>Additional Information</span>
          {dataErrors && dataErrors.length > 0 ? (
            <div className={'validity-label invalid'}>invalid</div>
          ) : (
            <div className={'validity-label valid'}>valid</div>
          )}
        </div>
        <div className={'summary-info-container'}>
          <div className={'info-row'}>
            <div className={'checkbox-parent'}>
              <label className='checkbox-item'>
                <input
                  className='mark-input'
                  type='checkbox'
                  checked={addlInfo.hasHeadline}
                  disabled={true}
                />
                <span className='mark mark-fixed' />
              </label>
            </div>
            <span>Table has headline</span>
          </div>

          <div className={'info-row'}>
            <div className={'text-input-parent'}>
              <input
                type={'text'}
                value={addlInfo.columnSeparator}
                className={'form--field'}
                disabled={true}
              />
            </div>
            <span>Column separator</span>
          </div>

          <div className={'info-row'}>
            <div className={'text-input-parent'}>
              <input
                type={'text'}
                value={addlInfo.thousandsSeparator}
                className={'form--field'}
                disabled={true}
              />
            </div>
            <span>Thousands separator</span>
          </div>

          <div className={'info-row'}>
            <div className={'text-input-parent'}>
              <input
                type={'text'}
                value={addlInfo.decimalSeparator}
                className={'form--field'}
                disabled={true}
              />
            </div>
            <span>Decimal separator</span>
          </div>

          <div className={'info-row'}>
            <div className={'dropdown-parent'}>
              <DropdownSelectInput
                options={options}
                value={options.find((o) => o.value === addlInfo.charset)}
                className=''
                clearable={false}
                disabled={true}
                onChange={() => {}}
              />
            </div>
            <span>File encoding</span>
          </div>
        </div>
        {dataErrors && dataErrors.length > 0 && (
          <div className={'commit-error-container'}>
            {dataErrors.map((de) => (
              <span>{de}</span>
            ))}
          </div>
        )}

        {((keyspaceErrors && keyspaceErrors.length > 0) ||
          (tableErrors && tableErrors.length > 0) ||
          (dataErrors && dataErrors.length > 0)) && (
          <div className={'commit-error-container'}>
            <span>
              To fix any error above please go back to the previous step using
              the Back button at the bottom
            </span>
          </div>
        )}
      </div>
    );
  }
}
