import PropTypes from 'prop-types';
import React from 'react';
import { Motion, spring } from 'react-motion';

class Tooltip extends React.Component {
  renderTooltip(value) {
    const {
      tooltipX,
      tooltipY,
      top,
      left,
      containerHeight,
      height,
      tooltipData,
      width,
    } = this.props;

    const topPadding = 20;

    const translateX = `${tooltipX - left - width / 2}`;
    const translateY = `${
      tooltipY - top - containerHeight - height - topPadding
    }`;
    const translate = `translate(${translateX}px, ${translateY}px)`;

    return (
      <div
        className='misclassification-tooltip'
        style={{
          transform: `${translate} scale(${value.scale}, ${value.scale})`,
        }}
      >
        <ul className='misclassification-chart_legend'>
          {tooltipData.map((item, index) => (
            <li key={index}>
              <div
                className='misclassification-chart_legend_dot'
                style={{ background: item.color }}
              />
              {item.percent}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { isTooltip, height, animate } = this.props;
    if (!isTooltip) {
      return <div style={{ height }}></div>;
    }

    if (!animate) {
      return this.renderTooltip({ scale: 1 });
    }

    return (
      <Motion defaultStyle={{ scale: 0 }} style={{ scale: spring(1) }}>
        {(value) => this.renderTooltip(value)}
      </Motion>
    );
  }
}

Tooltip.propTypes = {
  isTooltip: PropTypes.bool.isRequired,
  tooltipX: PropTypes.number.isRequired,
  tooltipY: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  containerHeight: PropTypes.number.isRequired,
  tooltipData: PropTypes.array.isRequired,
  animate: PropTypes.bool.isRequired,
};

Tooltip.defaultProps = {
  animate: true,
};

export default Tooltip;
