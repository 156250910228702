import React from 'react';

/**
 * HOC that wraps the Settings component with the settingsCategories
 * @param Realtime
 * @param availableServingTypes
 */
export function wrapRealtimeWithServingTypes(Realtime, availableServingTypes) {
  return class extends React.Component {
    render() {
      return (
        <Realtime
          availableServingTypes={availableServingTypes}
          {...this.props}
        />
      );
    }
  };
}
