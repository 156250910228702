import * as React from 'react';
import { Component } from 'react';
import ParameterTuningParameter from '../parameter-tuning-parameter/ParameterTuningParameter';
import Checkbox from '../../../../atoms/checkbox/Checkbox';
import { WrappedFieldProps } from 'redux-form';
import { availableAlgorithm } from '../types';

export interface Props {
  availableAlgorithm: availableAlgorithm;
}

export default class ParameterTuningAlgorithm extends Component<
  Props & WrappedFieldProps
> {
  constructor(props) {
    super(props);
    this.state = { selected: true };
  }

  render() {
    const { availableAlgorithm, input, meta } = this.props;
    const { value } = input;
    const parameterTuningMeta = input.value?.meta;

    const algorithmMeta = parameterTuningMeta?.[availableAlgorithm.name];

    return (
      <div className={'ParameterTuningAlgorithm--container'}>
        {/* Field access for the meta category is a bit hacky here since there are duplicate hierarchies here
            between the algorithm hierarchy (which the html structure follows)
            and a parallel meta hierarchy again for each algorithm (Which has to do without FormSection */}

        <Checkbox
          checked={input.value?.meta?.[availableAlgorithm.name]?.selected}
          onChange={() =>
            input.onChange({
              ...(value || {}),
              meta: {
                ...(value?.meta || {}),
                [availableAlgorithm.name]: {
                  selected:
                    !input.value?.meta?.[availableAlgorithm.name]?.selected,
                },
              },
            })
          }
          disabled={false}
        />

        <div
          className={
            'ParameterTuning--element' +
            (algorithmMeta?.selected ? '' : ' ParameterTuning--deselected')
          }
        >
          <h3 className={'ParameterTuning--headline'}>
            {availableAlgorithm.speakingName}
          </h3>
          <p>{availableAlgorithm.description}</p>
          {algorithmMeta?.selected && (
            <div className={'ParameterTuning--parameter-container'}>
              {availableAlgorithm.parameters.map((parameter, idx) => (
                <ParameterTuningParameter
                  key={idx}
                  algorithmName={availableAlgorithm.name}
                  parameter={parameter}
                  input={input}
                  meta={meta}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
