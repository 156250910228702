import React, { FC } from 'react';
import styles from './styles.module.scss';
import commonStyles from './optCommonStyles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import classNames from 'classnames';
import { Channel } from 'common/dist/types/module.optimization';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import { FiMinus } from 'react-icons/fi';

type Props = {
  rowIndex?: number;
  constraintId: string;
  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: Channel) => void;
};
const drvNewValue = (
  input: WrappedFieldInputProps,
  eventValue: string,
  key: string,
  rowIndex: number
) => {
  return [
    ...input.value.slice(0, rowIndex),
    {
      ...(input.value?.[rowIndex] || {}),
      [key]: eventValue,
    },
    ...input.value.slice(rowIndex + 1),
  ];
};
const renderNameField = (props) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optChannels_name'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.name}
      valid={!meta.error?.[constraintId]?.name}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Name'}
      placeholderId={'no-id'}
      placeholderDefault={'Name'}
      value={input.value?.[rowIndex]?.name}
      onChange={(e) => {
        const newValue = drvNewValue(input, e.target.value, 'name', rowIndex);
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderDescriptionField = (props) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optChannels_description'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.description}
      valid={!meta.error?.[constraintId]?.description}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Description (Optional)'}
      placeholderId={'no-id'}
      placeholderDefault={'Description'}
      value={input.value?.[rowIndex]?.description}
      onChange={(e) => {
        const newValue = drvNewValue(
          input,
          e.target.value,
          'description',
          rowIndex
        );
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const AddChannel: FC<Props & WrappedFieldProps> = (props) => {
  const { input, rowIndex, removableRows, onRemoveRow } = props;
  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={commonStyles.RemoveIcon}
            onClick={() => onRemoveRow && onRemoveRow(input.value[rowIndex])}
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div className={commonStyles.addElementField}>
          {renderNameField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderDescriptionField(props)}
        </div>
      </div>
    </div>
  );
};

export default AddChannel;
