import { connect, ConnectedProps as ConnectedProps_ } from 'react-redux';
import { reduxForm, getFormValues, getFormSyncErrors } from 'redux-form';
import { ErrorType, form, FormValues } from './addUser.form';
import { adminAddUser } from '../../../../redux/modules/admin.users.module';
import { formName } from './addUser.form';
import { RootState } from '../../../../store/store';
import AdminAddUserWizard, { Props } from './AdminAddUserWizard';

export function mapStateToProps(state: RootState) {
  const formValues: FormValues = getFormValues(formName)(state);
  const initialValues: FormValues = undefined;
  return {
    formValues,
    initialValues,
    filledCompletely:
      state.form[formName] &&
      (!getFormSyncErrors(formName)(state) ||
        Object.values(getFormSyncErrors(formName)(state)).every(
          (fieldError) => !fieldError
        )),
  };
}

export const mapDispatchToProps = {
  addUser: (values) => adminAddUser(values),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ConnectedProps = ConnectedProps_<typeof connector>;

export default connector(
  reduxForm<FormValues, Props, ErrorType>(form)(AdminAddUserWizard)
);
