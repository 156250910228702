import React, { Component } from 'react';
import ContentElement from './ContentElement';
import DeleteRepoConfirmModal from './DeleteRepoConfirmModal';
import { Link, withRouter } from 'react-router-dom';
import { collaborationSpaceRoutes } from '../../routes';
import Busy from '../../../atoms/busy/Busy';
import IndicatorEmpty from '../../../molecules/indicator-empty/IndicatorEmpty';
import Button from '../../../atoms/button/Button';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import _ from 'lodash';
import Paging, { PagingParams } from '../../../molecules/paging/Paging';
import { DEFAULT_PAGE_SIZE } from './util';
import ReactLoading from 'react-loading';
import styles from './styles.module.scss';
import vars from '../../../../../scss/base/var.module.scss';
import { RouteComponentProps } from 'react-router';
import qs from 'qs';
import { RepoDetails } from '../../../../store/workbench/state.types';

type Props = {
  data: RepoDetails[];
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  deleteRepoConfirm?: {
    show: boolean;
    repoCode: string;
    repoName: string;
  };
  hideDeleteRepoConfirm: () => void;
  deleteRepository: (repoFullName: string) => void;
  fetchRepositories: (offset?: number, limit?: number, search?: string) => void;
  fetchUserSummaryById: ({ userId: string }) => void;
  usersById: { [userId: string]: any }; // TODO typing
  currentUserId: string;
};

class Repositories extends Component<
  Props & WrappedComponentProps & RouteComponentProps
> {
  constructor(props) {
    super(props);

    this.renderHeadline = this.renderHeadline.bind(this);
    this.updatePagingParameters = this.updatePagingParameters.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { fetchUserSummaryById, data, usersById } = this.props;

    // --- Username handling
    // Gitea specific: Use the username of the repo owner (=group) instead of splitting the full_name. Each group name is a userId
    const groupNames = new Set(data.map((repo) => repo.owner.username));
    const prevGroupNames = new Set(
      prevProps.data.map((repo) => repo.owner.username)
    );
    // The repos have changed and we may need to fetch userNames
    if (!_.isEqual(groupNames, prevGroupNames)) {
      const userIdsToFetch = Array.from(groupNames.values()).filter(
        (userId) => !(userId in usersById)
      );
      userIdsToFetch.forEach((userId) => fetchUserSummaryById({ userId }));
    }
  }

  renderHeadline(
    currentPage: number,
    offset: number,
    limit: number,
    totalCount?: number,
    currentPageAmount?: number
  ) {
    const { loading } = this.props;
    return (
      <div className={styles.headline}>
        <Button
          buttonColor={'secondary'}
          withLink
          buttonLabelDefault={'Create Repository'}
          linkTo={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.create}`}
        />

        <div className={styles.spinner}>
          {loading && <ReactLoading type={'cylon'} color={vars.colorPrimary} />}
        </div>
      </div>
    );
  }

  renderLoaded() {
    const {
      data,
      deleteRepoConfirm,
      hideDeleteRepoConfirm,
      deleteRepository,
      usersById,
      currentUserId,
    } = this.props;

    return (
      <>
        <div className={'repositories'}>
          {data.map((repo, i) => {
            // Gitea specific
            const groupName = repo.owner?.username;
            const user = usersById[groupName]?.data;
            const createdBy = usersById[repo.createdByUserId]?.data;
            const speakingOwner =
              user === undefined
                ? undefined
                : `${user.firstName} ${user.lastName}`;
            return (
              <Link
                to={`${collaborationSpaceRoutes.basePath}/${repo.full_name}`}
                style={{ textDecoration: 'none' }}
              >
                <ContentElement
                  key={i}
                  {...repo}
                  name={repo.name}
                  description={repo.repoDescription}
                  speakingOwner={speakingOwner}
                  currentUserId={currentUserId}
                  createdBy={createdBy}
                />
              </Link>
            );
          })}
        </div>

        <DeleteRepoConfirmModal
          deleteRepoConfirm={deleteRepoConfirm}
          hideDeleteRepoConfirm={hideDeleteRepoConfirm}
          deleteRepository={deleteRepository}
        />
      </>
    );
  }

  renderLoading() {
    return <Busy isBusy />;
  }

  renderError() {
    const { error } = this.props;
    return <div className={'repositories'}>{this.renderErrorText(error)}</div>;
  }

  renderErrorText(error) {
    if (!error) {
      return <div />;
    } else if (error.id || error.defaultMessage) {
      return (
        <FormattedMessage
          id={error.id || 'no-id'}
          defaultMessage={error.defaultMessage}
        />
      );
    } else {
      return <span>{JSON.stringify(error)}</span>;
    }
  }

  renderEmpty() {
    return (
      <IndicatorEmpty
        classNameImage={'git-repositories-empty-pic'}
        headlineId={'no-id'}
        headlineDefault={'There are no repositories yet'}
        descriptionId={'no-id'}
        descriptionDefault={'Create your first repository now'}
        Actions={() => (
          <Button
            withLink={true}
            linkTo={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.create}`}
            buttonColor={'secondary'}
            buttonLabelId={'no-id'}
            buttonLabelDefault={'Create Repository'}
          />
        )}
      />
    );
  }

  renderNoSearchResults() {
    return (
      <IndicatorEmpty
        classNameImage={'git-repositories-empty-pic'}
        headlineId={'no-id'}
        headlineDefault={'No search results'}
        descriptionId={'no-id'}
        descriptionDefault={`There are no results for your search query.`}
      />
    );
  }

  renderInnerComponent() {
    const { data, error, loading, loaded, location } = this.props;

    // this is quite a dirty fix, consider replacing it with a better solutions (e.g. checking number of repos directly)
    const queryParameters: PagingParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const { search } = queryParameters;

    if (error) return this.renderError();
    else if (loading && (!data || data.length === 0))
      return this.renderLoading();
    else if (loaded && data && data.length === 0 && search && search !== '')
      return this.renderNoSearchResults();
    else if (loaded && data && data.length === 0) return this.renderEmpty();
    else return this.renderLoaded();
  }

  updatePagingParameters(offset?: number, limit?: number, search?: string) {
    const { fetchRepositories } = this.props;
    fetchRepositories(offset, limit, search);
  }

  render() {
    const { data } = this.props;
    return (
      <div className={'CollaborationSpace--content'}>
        <Paging
          itemsPerPage={DEFAULT_PAGE_SIZE}
          currentItems={data?.length || 0}
          searchEnabled
          updatePagingParameters={this.updatePagingParameters}
          Headline={(
            currentPage: number,
            offset: number,
            limit: number,
            totalCount?: number
          ) =>
            this.renderHeadline(
              currentPage,
              offset,
              limit,
              totalCount,
              data?.length
            )
          }
        >
          {this.renderInnerComponent()}
        </Paging>
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(injectIntl(Repositories));
