import { connect } from 'react-redux';
import TableBrowser from './TableBrowser';
import { withRouter } from 'react-router-dom';
import {
  fetchCassandraTableSample,
  setActiveUploadWizard,
} from '../../../../redux/modules/data.cassandra.module';

export function mapStateToProps(state, { dataSourceCode, match }) {
  const keyspaceName = match.params.keyspaceName || '';
  const tableName = match.params.tableName || '';
  const { loading, loaded, error, data } =
    ((state.data.cassandra[dataSourceCode] || {}).sample || {})[
      `${keyspaceName}#${tableName}`
    ] || {};
  return {
    keyspaceName,
    tableName,
    loading,
    loaded,
    error,
    data,
  };
}

export const mapDispatchToProps = {
  fetchCassandraTableSample,
  setActiveUploadWizard,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableBrowser)
);
