import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Busy from '../../atoms/busy/Busy';
import './styles.scss';
import BackTo from '../../atoms/back-to/BackTo';
import IndicatorEmpty from '../../molecules/indicator-empty/IndicatorEmpty';
import FlatHistogram from '../../molecules/charts/flat-histogram/FlatHistogram';
import commonMessages from 'common/dist/messages/common';

// Formats a number with thousand-separators
// TODO Currently only works for numbers without decimal places
function formatNumber(x) {
  if (!x) return '';

  let y = x.toString();
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(y)) {
    y = y.replace(pattern, '$1.$2');
  }
  return y;
}

const SORT_DIRECTIONS = {
  ASC: 1,
  DESC: -1,
};

const SORT_BEHAVIOUR = {
  KEYS_THEN_NAMES: 'keys_then_names',
};

function sortColumnSpecs(columns, keys) {
  const sortBehaviour = SORT_BEHAVIOUR.KEYS_THEN_NAMES; // Fixed at the moment, but can be made dyamic in the future
  const sortDirection = SORT_DIRECTIONS.ASC; // Fixed at the moment, but can be made dyamic in the future

  if (!columns) return columns; // Only to make sure the filtering doesn't fail when the column list is undefined
  const keysSafe = keys || [];

  const keyColumns = columns.filter((c) => keysSafe.includes(c.name));
  const otherColumns = columns.filter((c) => !keysSafe.includes(c.name));

  switch (sortBehaviour) {
    case SORT_BEHAVIOUR.KEYS_THEN_NAMES: {
      keyColumns.sort((c1, c2) => sortDirection * (c1.name < c2.name ? -1 : 1));
      otherColumns.sort(
        (c1, c2) => sortDirection * (c1.name < c2.name ? -1 : 1)
      );
    }
    // To be extended if different sort criteria are required
  }

  return keyColumns.concat(otherColumns);
}

/** Column specs for the numeric columns */
type NumericColumnSpec = {
  colTypeCategory: 'numeric';
  /** Name of the column */
  colName: string;
  /** Type of the column */
  colType: string;
  /** Amount of missing values for this column */
  missing: number;
  /** Min value of this column */
  min: number;
  /** Max value of this column */
  max: number;
  /** Mean value of this column */
  mean: number;
  /** Standard Deviation of this column */
  stddev: number;
  /** Value histogram for this column */
  histogram: HistogramEntry[];
};

/** General specs for columns (currently for all non-numeric columns) */
type GeneralColumnSpec = {
  colTypeCategory: 'general';
  /** Name of the column */
  colName: string;
  /** Type of the column */
  colType: string;
  /** Amount of missing values for this column */
  missing: number;
};

type HistogramEntry = {
  from: number;
  to: number;
  amount: number;
};

type Props = {
  /** Name of the table */
  tableName: string;
  /** Code of the Habitat the Datapool of this Table belongs to*/
  habitatCode: string;
  /** Code of the Datapool this Table belongs to */
  datapoolCode: string;
  /** Are the statistics loading? */
  loading: boolean;
  /** Have the statistics been loaded? */
  loaded: boolean;
  /** Fetch the latest statistics for this table */
  fetchLatestDatapoolStatistics: (
    habitatCode: string,
    datapoolCode: string,
    tableName: string
  ) => {};
  /** Callback to go back to the Statistics Table Overview page */
  onBack: () => {};
  /** The statistics for this table */
  data: {
    /** Is the table valid? */
    valid: boolean;
    /** List of warnings for this table */
    warnings?: object[];
    /** List of errors for this table */
    errors?: object[];
    /** Amount of rows of this table */
    rowCount: number;
    /** Amount of columns of this table */
    columnCount: number;
    /** List of keys for this table */
    keys: string[];
    /** The specs and statistics for the columns */
    columnSpecs: (NumericColumnSpec | GeneralColumnSpec)[];
  };
};

export class SingleTableStatistics extends Component<
  Props & WrappedComponentProps,
  {}
> {
  componentDidMount() {
    // Load the available columns
    const {
      habitatCode,
      datapoolCode,
      tableName,
      fetchLatestDatapoolStatistics,
    } = this.props;
    fetchLatestDatapoolStatistics(habitatCode, datapoolCode, tableName);
  }

  /**
   * Renders the information once it's available
   * @returns {*}
   */
  renderLoaded() {
    const {
      tableName,
      data: { columnSpecs, rowCount, columnCount, keys },
    } = this.props;
    return (
      <div className={'table-reset'}>
        <div className={'SingleTableStatistics--header'}>
          <span className={'SingleTableStatistics--table-name'}>
            Table: {tableName}
          </span>
          <div className={'SingleTableStatistics--meta-information'}>
            <div className={'row-count'}>{formatNumber(rowCount)} Rows</div>
            <div className={'column-count'}>
              {formatNumber(columnCount)} Columns
            </div>
          </div>
        </div>
        <table className={'SingleTableStatistics--table'}>
          <thead>
            <tr>
              <td className={'SingleTableStatistics--td'}>Column Name</td>
              <td className={'SingleTableStatistics--td'}>Column Type</td>
              <td className={'SingleTableStatistics--td'}>Missings</td>
              <td className={'SingleTableStatistics--td'}>Minimum</td>
              <td className={'SingleTableStatistics--td'}>Maximum</td>
              <td className={'SingleTableStatistics--td'}>Mean</td>
              <td className={'SingleTableStatistics--td'}>Std Dev</td>
              <td className={'SingleTableStatistics--td'}>Histogram</td>
              <td className={'td-key SingleTableStatistics--td'} />
            </tr>
          </thead>
          <tbody>
            {columnSpecs &&
              sortColumnSpecs(columnSpecs, keys).map((col) => (
                <tr key={col.colName}>
                  <td className={'SingleTableStatistics--td'}>{col.colName}</td>
                  <td className={'SingleTableStatistics--td'}>{col.colType}</td>
                  <td className={'SingleTableStatistics--td'}>{col.missing}</td>
                  <td className={'SingleTableStatistics--td'}>
                    {col.min && col.min.toFixed(3)}
                  </td>
                  <td className={'SingleTableStatistics--td'}>
                    {col.max && col.max.toFixed(3)}
                  </td>
                  <td className={'SingleTableStatistics--td'}>
                    {col.mean && col.mean.toFixed(3)}
                  </td>
                  <td className={'SingleTableStatistics--td'}>
                    {col.stddev && col.stddev.toFixed(3)}
                  </td>
                  <td className={'SingleTableStatistics--td'}>
                    {col.histogram && (
                      <FlatHistogram
                        data={col.histogram}
                        width={100}
                        height={30}
                      />
                    )}
                  </td>
                  <td className={'SingleTableStatistics--td td-key'}>
                    {keys.includes(col.colName) && (
                      <div className={'key-label'}>key</div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderEmpty() {
    const { tableName } = this.props;
    return (
      <IndicatorEmpty
        classNameImage={'statistics-empty-pic'}
        headlineDefault={`There are no statistics for the table ${tableName} yet.`}
        descriptionDefault={
          'Check the box to calculate the Statistics for this table and run the Datapool Statistics job to derive ' +
          'the Statistics.'
        }
      />
    );
  }

  renderLoading() {
    return <Busy isBusy />;
  }

  renderInner() {
    const { loading, loaded, data } = this.props;
    if (loading) return this.renderLoading();
    else if (!data || Object.keys(data).length === 0) return this.renderEmpty();
    else if (data) return this.renderLoaded();
    else return <div />;
  }

  render() {
    const { onBack, intl } = this.props;
    return (
      <div className={'SingleTableStatistics'}>
        <BackTo
          onClick={onBack}
          label={intl.formatMessage(commonMessages.backTo, {
            origin: 'Statistics',
          })}
        />
        {this.renderInner()}
      </div>
    );
  }
}

export default injectIntl(SingleTableStatistics);
