import { connect, ConnectedProps } from 'react-redux';
import CodeCapsuleOutput from './CodeCapsuleOutput';
import { fetchCodeCapsuleOutput } from '../../../../redux/modules/orchestration.codeCapsuleReport.module';
import { getStatusFromPapermillNotebook } from 'common/dist/utils/workbench/notebook';

export function mapStateToProps(state) {
  const { loading, loaded, error, output } = state.codeCapsuleOutput;
  let status;
  if (loaded && !error) {
    status = getStatusFromPapermillNotebook(output.notebookOutput);
  }
  return {
    loading,
    loaded,
    error,
    output,
    origin: state.jobDetails.origin,
    status,
  };
}

export const mapDispatchToProps: {
  fetchCodeCapsuleOutput: (jobCode: string, notebookName: string) => void;
} = {
  fetchCodeCapsuleOutput,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connect(mapStateToProps, mapDispatchToProps)(CodeCapsuleOutput);
