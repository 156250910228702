import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const fetchCodeCapsules = createAction(
  'fetch code capsules',
  (affiliationType, habitatCode) => ({ affiliationType, habitatCode })
);

export const fetchCodeCapsulesSuccess = createAction(
  'fetch code capsules - success',
  (affiliationType, habitatCode, data) => ({
    affiliationType,
    habitatCode,
    data,
  })
);

export const fetchCodeCapsulesFailure = createAction(
  'fetch code capsules - failure',
  (affiliationType, habitatCode, error) => ({
    affiliationType,
    habitatCode,
    error,
  })
);

/** TODO The habitatCode is not used? */
export const fetchSingleCodeCapsule = createAction(
  'fetch single code capsule',
  (codeCapsuleCode, habitatCode) => ({ codeCapsuleCode, habitatCode })
);

export const fetchSingleCodeCapsuleSuccess = createAction(
  'fetch single code capsule - success',
  (codeCapsuleCode, habitatCode, data) => ({
    codeCapsuleCode,
    habitatCode,
    data,
  })
);

export const fetchSingleCodeCapsuleFailure = createAction(
  'fetch single code capsule - failure',
  (codeCapsuleCode, habitatCode, error) => ({
    codeCapsuleCode,
    habitatCode,
    error,
  })
);

export const fetchCodeCapsuleExecutions = createAction(
  'fetch code capsule executions',
  (codeCapsuleCode, habitatCode) => ({
    codeCapsuleCode,
    habitatCode,
  })
);

export const fetchCodeCapsuleExecutionsSuccess = createAction(
  'fetch code capsule executions - success',
  (codeCapsuleCode, habitatCode, data) => ({
    codeCapsuleCode,
    habitatCode,
    data,
  })
);

export const fetchCodeCapsuleExecutionsFailure = createAction(
  'fetch code capsule executions - failure',
  (codeCapsuleCode, habitatCode, error) => ({
    codeCapsuleCode,
    habitatCode,
    error,
  })
);

export const reducer = {
  [fetchCodeCapsules]: (state, { affiliationType, habitatCode }) => {
    const key = affiliationType === 'habitat' ? habitatCode : affiliationType;
    return {
      ...state,
      codeCapsules: {
        ...state.codeCapsules,
        perHabitatCode: {
          ...state.codeCapsules.perHabitatCode,
          [key]: {
            ...state.codeCapsules.perHabitatCode[key],
            loading: true,
          },
        },
      },
    };
  },
  [fetchCodeCapsulesSuccess]: (
    state,
    { affiliationType, habitatCode, data }
  ) => {
    const key = affiliationType === 'habitat' ? habitatCode : affiliationType;
    // Also set the transformed representation of the data in `perCode`. Only do this on success, because we don't know which cc we failed to load
    const perCode = Object.fromEntries(
      data.map((codeCapsule) => [
        [codeCapsule.code],
        {
          loading: false,
          loaded: true,
          data: {
            ...codeCapsule,
            habitatCode,
          },
        },
      ])
    );
    // Also extract the codeCapsule names
    const codeCapsuleNames = Object.fromEntries(
      data.map((codeCapsule) => [codeCapsule.code, codeCapsule.name])
    );
    return {
      ...state,
      codeCapsules: {
        ...state.codeCapsules,
        perHabitatCode: {
          ...state.codeCapsules.perHabitatCode,
          [key]: {
            ...state.codeCapsules.perHabitatCode[key],
            loading: false,
            loaded: true,
            data,
          },
        },
        perCode: {
          ...state.codeCapsules.perCode,
          ...perCode,
        },
      },
      names: {
        ...state.names,
        codeCapsuleNames: {
          ...state.names.codeCapsuleNames,
          ...codeCapsuleNames,
        },
      },
    };
  },
  [fetchCodeCapsulesFailure]: (
    state,
    { affiliationType, habitatCode, error }
  ) => {
    const key = affiliationType === 'habitat' ? habitatCode : affiliationType;
    return {
      ...state,
      codeCapsules: {
        ...state.codeCapsules,
        perHabitatCode: {
          ...state.codeCapsules.perHabitatCode,
          [key]: {
            ...state.codeCapsules.perHabitatCode[key],
            loading: false,
            loaded: false,
            error,
          },
        },
      },
    };
  },

  [fetchSingleCodeCapsule]: (state, { codeCapsuleCode }) => ({
    ...state,
    codeCapsules: {
      ...state.codeCapsules,
      perCode: {
        ...state.codeCapsules.perCode,
        [codeCapsuleCode]: {
          ...state.codeCapsules.perCode[codeCapsuleCode],
          loading: true,
        },
      },
    },
  }),
  [fetchSingleCodeCapsuleSuccess]: (
    state,
    { codeCapsuleCode, habitatCode, data }
  ) => ({
    ...state,
    codeCapsules: {
      ...state.codeCapsules,
      perCode: {
        ...state.codeCapsules.perCode,
        [codeCapsuleCode]: {
          ...state.codeCapsules.perCode[codeCapsuleCode],
          loading: false,
          loaded: true,
          data: {
            ...data,
            habitatCode,
          },
        },
      },
    },
  }),
  [fetchSingleCodeCapsuleFailure]: (state, { codeCapsuleCode, error }) => ({
    ...state,
    codeCapsules: {
      ...state.codeCapsules,
      perCode: {
        ...state.codeCapsules.perCode,
        [codeCapsuleCode]: {
          ...state.codeCapsules.perCode[codeCapsuleCode],
          loading: false,
          loaded: false,
          error,
        },
      },
    },
  }),

  [fetchCodeCapsuleExecutions]: (state, { codeCapsuleCode }) => ({
    ...state,
    codeCapsules: {
      ...state.codeCapsules,
      executionsPerCode: {
        ...state.codeCapsules.executionsPerCode,
        [codeCapsuleCode]: {
          ...state.codeCapsules.executionsPerCode[codeCapsuleCode],
          loading: true,
        },
      },
    },
  }),
  [fetchCodeCapsuleExecutionsSuccess]: (state, { codeCapsuleCode, data }) => ({
    ...state,
    codeCapsules: {
      ...state.codeCapsules,
      executionsPerCode: {
        ...state.codeCapsules.executionsPerCode,
        [codeCapsuleCode]: {
          ...state.codeCapsules.executionsPerCode[codeCapsuleCode],
          loading: false,
          loaded: true,
          data,
        },
      },
    },
  }),
  [fetchCodeCapsuleExecutionsFailure]: (state, { codeCapsuleCode, error }) => ({
    ...state,
    codeCapsules: {
      ...state.codeCapsules,
      executionsPerCode: {
        ...state.codeCapsules.executionsPerCode,
        [codeCapsuleCode]: {
          ...state.codeCapsules.executionsPerCode[codeCapsuleCode],
          loading: false,
          loaded: false,
          error,
        },
      },
    },
  }),
};

export function* fetchCodeCapsulesSaga({
  payload: { affiliationType, habitatCode },
}) {
  const { response, error } = yield call(
    Api.codeCapsules.fetchCodeCapsules,
    affiliationType,
    habitatCode
  );
  if (response) {
    yield put(fetchCodeCapsulesSuccess(affiliationType, habitatCode, response));
  } else {
    yield put(fetchCodeCapsulesFailure(affiliationType, habitatCode, error));
  }
}

export function* watchFetchCodeCapsules() {
  yield takeEvery(fetchCodeCapsules.getType(), fetchCodeCapsulesSaga);
}

export function* fetchSingleCodeCapsuleSaga({
  payload: { codeCapsuleCode, habitatCode },
}) {
  const { response, error } = yield call(
    Api.codeCapsules.fetchSingleCodeCapsule,
    codeCapsuleCode
  );
  if (response) {
    yield put(
      fetchSingleCodeCapsuleSuccess(codeCapsuleCode, habitatCode, response)
    );
  } else {
    yield put(
      fetchSingleCodeCapsuleFailure(codeCapsuleCode, habitatCode, error)
    );
  }
}

export function* watchFetchSingleCodeCapsule() {
  yield takeEvery(fetchSingleCodeCapsule.getType(), fetchSingleCodeCapsuleSaga);
}

export function* fetchCodeCapsuleExecutionsSaga({
  payload: { codeCapsuleCode, habitatCode, offset, limit },
}) {
  const { response, error } = yield call(
    Api.codeCapsules.fetchCodeCapsuleExecutions,
    codeCapsuleCode
  );
  if (response) {
    yield put(
      fetchCodeCapsuleExecutionsSuccess(codeCapsuleCode, habitatCode, response)
    );
  } else {
    yield put(
      fetchCodeCapsuleExecutionsFailure(codeCapsuleCode, habitatCode, error)
    );
  }
}

export function* watchFetchCodeCapsuleExecutions() {
  yield takeEvery(
    fetchCodeCapsuleExecutions.getType(),
    fetchCodeCapsuleExecutionsSaga
  );
}
