import React, { Fragment, FC } from 'react';
import DropdownSelectInput from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { AvailableTables } from '../../../store/datapoolTables/slice';

type Props = {
  input: {
    value: string;
    onChange: (value: string | undefined) => void;
    onFocus: () => void;
    onBlur: () => void;
  };
  meta: {
    touched: boolean;
    valid: boolean;
    error?: string;
  };

  /** Available tables for the given Datapool */
  availableTables?: AvailableTables;
};

type OptionType = { label: string; value: string };

const FieldSelectTable: FC<Props> = (props: Props) => {
  const height = '68px';
  const width = '400px';

  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { touched, valid, error },
    availableTables,
  } = props;
  const options = (availableTables?.data || []).map((table) => ({
    label: table.name,
    value: table.name,
  }));

  return (
    <Fragment>
      <div
        style={{
          overflow: 'visible',
          position: 'absolute',
          height,
          width,
          zIndex: 3,
        }}
      >
        <DropdownSelectInput
          disabled={false}
          id={'run-prediction-modal--table-select'}
          label={{
            id: 'no-id',
            defaultMessage: 'Input Table',
          }}
          placeholder={{
            id: 'no-id',
            defaultMessage: 'Select Input Table',
          }}
          isLoading={availableTables?.loading}
          clearable={false}
          multi={false}
          scrollMenuIntoView={false}
          options={options}
          autoSelectIfSingle
          touched={touched}
          error={error}
          valid={valid}
          value={options.find((o) => o.value === value)}
          onChange={(option: OptionType) => onChange(option.value)}
          onFocus={(e) => onFocus()}
          onBlur={(e) => onBlur()}
        />
      </div>

      {/* Placeholder for the absolut positioned text field above */}
      <div
        style={{
          position: 'relative',
          height,
          width,
          zIndex: 2,
          marginBottom: '10px',
        }}
      />
    </Fragment>
  );
};

export default FieldSelectTable;
