import { connect } from 'react-redux';
import Habitats from './Habitats';
import { RootState } from '../../../store/store';

export function mapStateToProps(state: RootState) {
  return {
    loading: state.dashboard.loading,
    loaded: state.dashboard.loaded,
    habitats: state.summary,
  };
}

export default connect(mapStateToProps)(Habitats);
