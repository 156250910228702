import { connect } from 'react-redux';
import AddRepositoryWizard from './AddRepositoryWizard';
import { isAsyncValidating, isValid } from 'redux-form';

import {
  fieldArchHabitatDatapool,
  fieldCodeCapsuleHabitat,
  fieldDescription,
  fieldKernel,
  fieldName,
  fieldType,
  formName,
} from './repository.form';
import { selectValue } from './selectorUtils';
import { addRepository } from '../../../../redux/workbench/modules/collab.module';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

export function mapStateToProps(state, { location }) {
  const archHabitatDatapool = selectValue(
    state,
    formName,
    fieldArchHabitatDatapool
  );

  const initialRepositoryType =
    qs.parse(location.search, { ignoreQueryPrefix: true }).repositoryType || '';
  const initialHabitatCode =
    qs.parse(location.search, { ignoreQueryPrefix: true }).habitatCode || '';

  return {
    activePath: state.workbench.collab.activePath,
    repositoryName: selectValue(state, formName, fieldName),
    repositoryType: selectValue(state, formName, fieldType),
    repositoryDescription: selectValue(state, formName, fieldDescription),
    codeCapsuleHabitat: selectValue(state, formName, fieldCodeCapsuleHabitat),
    archKernel: selectValue(state, formName, fieldKernel),
    archHabitat: archHabitatDatapool?.habitat,
    archDatapool: archHabitatDatapool?.datapool,
    contentInCurrentDir: state.workbench.collab.content.data,
    formValid: isValid(formName)(state),
    isAsyncValidating: isAsyncValidating(formName)(state),

    initialValues: {
      [fieldType]: initialRepositoryType,
      [fieldCodeCapsuleHabitat]: {
        code: initialHabitatCode,
      },
    },
  };
}

export const mapDispatchToProps = {
  addRepository,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddRepositoryWizard)
);
