import { connect } from 'react-redux';

import Icon from './Icon';
import BackIcon from './BackIcon';
import { app } from '../../index/routes';

export function mapStateToProps() {
  return {
    linkPath: app.prefix + app.models,
    isActive: false,
    icon: BackIcon,
    title: 'details.sideNav.back_to_dashboard',
    id: 'chevron-left',
  };
}

export default connect(mapStateToProps)(Icon);
