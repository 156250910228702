import React, { FC, useEffect } from 'react';
import RunningWorkbench from './RunningWorkbench.container';
import { notebookUser } from '../../../../redux/workbench/selectors/notebookUser.selector';
import { useSelector } from 'react-redux';
import { checkWhetherNotebookIsRunning } from '../../../../redux/workbench/modules/connection.module';
import { RootState, useAppDispatch } from '../../../../store/store';
import Busy from '../../../atoms/busy/Busy';
import styles from '../styles.module.scss';

/**
 * Thin wrapper around RunningWorkbench that makes the login. Needed to keep the original component as is, which depends on being logged in.
 */
const RunningWorkbenchLogin: FC = () => {
  const user = useSelector((state) => notebookUser(state));
  const dispatch = useAppDispatch();
  // Check without retries
  useEffect(
    () => dispatch(checkWhetherNotebookIsRunning(user, false)),
    [dispatch, user]
  );
  const notebookRunning = useSelector<
    RootState,
    RootState['workbench']['notebookRunning']
  >((state) => state.workbench.notebookRunning);

  if (notebookRunning.loading)
    return (
      <div className={styles.Resources}>
        <Busy isBusy />
      </div>
    );

  if (notebookRunning.error)
    return (
      <div className={styles.Resources}>
        <span className={'error'}>{JSON.stringify(notebookRunning.error)}</span>
      </div>
    );

  return <RunningWorkbench />;
};

export default RunningWorkbenchLogin;
