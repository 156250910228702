import { createAction } from 'redux-act';
import { put, call, takeEvery, select, takeLatest } from 'redux-saga/effects';
import * as ContentApi from '../../../core/api/workbench/content';
import {
  findAndAdjustOpenedNotebookPath,
  loadRepoMeta,
} from './notebook.module';
import { notebookUser } from '../selectors/notebookUser.selector';
import {
  directoryExtension as repositoryDirectoryExtension,
  contentType as repositoryContentType,
  recycleBinContentType,
} from '../../../components/workbench/part-right/config';
import NotebookApi from '../../../core/api/workbench/git.notebook';
import {
  directoryExtension,
  metaFilename,
} from '../../../components/collaborationSpace/wizards/repository-clone/config';
import qs from 'qs';

export const setOpenContextMenu = createAction('open context menu', (path) => ({
  path,
}));

export const fetchContent = createAction(
  'fetch content',
  (selectedDirPath, ignoreErrors = false) => ({ selectedDirPath, ignoreErrors })
);

export const fetchContentSuccess = createAction(
  'fetch content - success',
  (nodes, selectedDirPath) => ({ nodes, selectedDirPath })
);

export const fetchContentFail = createAction(
  'fetch content - fail',
  (error) => error
);

export const checkRepoDirExists = createAction(
  'check repo dir exists',
  (selectedDirPath, callbacks) => ({ selectedDirPath, callbacks })
);

export const oneDirectoryDown = createAction(
  'one directory down',
  (directory) => directory
);

export const oneDirectoryUp = createAction('one directory up');

export const jumpToDirectory = createAction(
  'jump to directory',
  (dirs) => dirs
);

export const renameContent = createAction(
  'rename content',
  (oldPath, newPath) => ({ oldPath, newPath })
);

export const renameContentSuccess = createAction(
  'rename content - success',
  (success) => success
);

export const renameContentFail = createAction(
  'rename content - fail',
  (error) => error
);

export const showDeleteContent = createAction(
  'show delete confirm',
  (path, type, permanently = false) => ({ path, type, permanently })
);

export const restoreContent = createAction(
  'restore content',
  (name, targetPath) => ({ name, targetPath })
);

export const restoreContentSuccess = createAction(
  'restore content - success',
  (targetPath) => ({ targetPath })
);

export const restoreContentFailure = createAction(
  'restore content - failure',
  (targetPath, error) => ({ targetPath, error })
);

export const emptyRecycleBin = createAction('empty recycle bin');

export const emptyRecycleBinSuccess = createAction(
  'empty recycle bin - success'
);

export const emptyRecycleBinFailure = createAction(
  'empty recycle bin - failure',
  () => ({})
);

export const toggleHidden = createAction('toggle hidden files');

export const reducer = {
  [setOpenContextMenu]: (state, { path }) => ({
    ...state,
    content: {
      ...state.content,
      openContextMenu: path,
    },
  }),
  [fetchContent]: (state) => ({
    ...state,
  }),
  [fetchContentSuccess]: (state, { nodes, selectedDirPath }) => ({
    ...state,
    content: injectLoadedContent(state, nodes, selectedDirPath),
  }),
  [fetchContentFail]: (state, error) => ({
    ...state,
    content: {
      ...state.content,
      error,
      root: {
        content: [],
      },
    },
  }),
  [oneDirectoryDown]: (state, directory) => ({
    ...state,
    content: {
      ...state.content,
      selectedDirPath: [...state.content.selectedDirPath, directory],
      repoMeta: {},
    },
  }),
  [oneDirectoryUp]: (state, directory) => ({
    ...state,
    content: {
      ...state.content,
      selectedDirPath: state.content.selectedDirPath.slice(
        0,
        state.content.selectedDirPath.length - 1
      ),
      // TODO why is this reset, for example the repository info dialogue may still be open and need this info
      repoMeta: {},
    },
  }),
  [jumpToDirectory]: (state, dirs) => ({
    ...state,
    content: {
      ...state.content,
      selectedDirPath: dirs,
      repoMeta: {},
    },
  }),
  [showDeleteContent]: (state, { path, type, permanently }) => ({
    ...state,
    showDeleteContent: { path, type, permanently },
  }),
  [toggleHidden]: (state) => ({
    ...state,
    settings: {
      ...state.settings,
      showHidden: !state.settings.showHidden,
    },
  }),
};

function injectLoadedContent(state, node, selectedDirPath) {
  if (selectedDirPath.length === 1) {
    return {
      ...state.content,
      error: null,
      root: mergeDirectoryContentWithExisting(state.content.root, node),
    };
  }

  return {
    ...state.content,
    error: null,
    root: injectDeeperContent(
      state.content.root,
      node,
      selectedDirPath.slice(1)
    ),
  };
}

/**
 * Recursively go down the content hierarchy following the path and inject nodes when finished
 * Add potentially missing intermediary paths with placeholder content, for jumps to previously not loaded parts of the tree
 * This could add non-existing directories if called with a non-existing path
 *
 * Preserve even deeper directories if they are up-to-date and have more information than our injected content has.
 * This is necessary because fetching a directory from jupyterhub does not include the content of its children
 * and this update may come from moving up the tree for example.
 *
 * @param dir - the currently visited dir
 * @param node - the content node to inject, for example a {type: "directory", name: "x", content: [...], ...}
 * @param path - remaining path to traverse. string array of path elements
 * @return {(*&{content})|(*&{content: (*[]|*)})}
 */
function injectDeeperContent(dir, node, path) {
  const nextDir = path[0];
  // Two cases for the content field in dir:
  // 1. content is null or does not include nextDir -> add new content to old if it exists
  // 2. content includes nextDir -> modify existing
  const missingEntry =
    dir.content === null ||
    dir.content.findIndex((e) => e.name === nextDir) === -1;
  // Base case/We found the dir into which we want to inject our node
  if (path.length === 1) {
    return {
      ...dir,
      content: missingEntry
        ? // It is missing => Add it
          [...(dir.content || []), node]
        : // It already exists in some form => Merge it if old and new are directories, otherwise use the new one. Leave siblings alone
          dir.content.map((e) =>
            e.name === nextDir
              ? e.type === 'directory' && node.type === 'directory'
                ? mergeDirectoryContentWithExisting(e, node)
                : node
              : e
          ),
    };
  }
  // Recursion
  return {
    ...dir,
    content: missingEntry
      ? // Pass a placeholder directory for a missing intermediary in the path
        [
          ...(dir.content || []),
          injectDeeperContent(
            {
              name: nextDir,
              path: `${dir.path}/${nextDir}`,
              type: 'directory',
              content: null,
            },
            node,
            path.slice(1)
          ),
        ]
      : dir.content.map((e) =>
          e.name === nextDir ? injectDeeperContent(e, node, path.slice(1)) : e
        ),
  };
}

/**
 * Merge an existing directory to potentially preserve information, that would otherwise be lost.
 * If a child item still exists and the last modified has not changed, we keep any information that was gained
 * by fetching that child item directly in the past.
 *
 * @param existingNode
 * @param node
 * @return {*&{content: unknown[]}}
 */
function mergeDirectoryContentWithExisting(existingNode, node) {
  if (existingNode.type !== 'directory' || node.type !== 'directory') {
    console.error(
      'Trying to merge two nodes, that are not both directories.',
      existingNode,
      node
    );
    return node;
  }
  return {
    ...node,
    content: (node.content || []).map(
      (childNode) =>
        (existingNode.content || []).find(
          (existingChildNode) => existingChildNode.name === childNode.name
          // && existingChildNode.last_modified === childNode.last_modified // This seems more correct, but restoring(=moving) from the recycle bin changes its last_modified
        ) || childNode
    ),
  };
}

export function* fetchContentSaga({
  payload: { selectedDirPath, ignoreErrors },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const path = selectedDirPath.slice(1).join('/'); // Throw away the first 'root' directory
  const { response, error } = yield call(
    ContentApi.fetchContent,
    path,
    jupyterUser
  );
  if (response && response.content) {
    // --- Treat the special directory types
    const treatedContent = response.content.map((c) => {
      if (c.type === 'directory') {
        // 1. Repository
        if (c.name.endsWith(repositoryDirectoryExtension)) {
          // c is a repository
          return {
            ...c,
            asType: repositoryContentType,
          };
        }
        // 2. Recycle Bin
        else if (
          selectedDirPath.length === 1 &&
          selectedDirPath[0] === 'root' &&
          c.name === '__recycleBin'
        ) {
          return {
            ...c,
            asType: recycleBinContentType,
          };
        }
        // c is a usual directory
        return c;
      }
      // c is something else than a directory
      return c;
    });
    const treatedResponse = {
      ...response,
      content: treatedContent,
    };
    // ---

    yield put(fetchContentSuccess(treatedResponse, selectedDirPath));

    // If inside a repository: Load the meta information
    if (path.endsWith(directoryExtension)) {
      yield put(loadRepoMeta(path + '/' + metaFilename));
    }
  } else {
    if (!ignoreErrors) yield put(fetchContentFail(error));
  }
}

export function* watchFetchContent() {
  yield takeEvery(fetchContent.getType(), fetchContentSaga);
}

export function* checkContentExistsSaga({
  payload: {
    selectedDirPath,
    callbacks: { resolve, reject },
  },
}) {
  // A bit ugly and potentially out of sync to read the address here, but how else to use it in asyncValidation?
  const infoFilePath = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).path;
  const jupyterUser = yield select((state) => notebookUser(state));
  // Throw away <repoDir>/repository.asr
  const pathArray = infoFilePath.split('/').slice(0, -2);
  // Append the name as it would appear as a repo directory on the same level as the current
  pathArray.push(selectedDirPath + '.asr');
  const path = pathArray.join('/');
  const { response, error } = yield call(
    ContentApi.fetchContent,
    path,
    jupyterUser
  );
  if (response) {
    // Status was ok 200 and the path exists
    resolve({ exists: true });
  } else {
    reject(error);
  }
}

export function* watchCheckContentExists() {
  yield takeLatest(checkRepoDirExists.getType(), checkContentExistsSaga);
}

export function* renameContentSaga({ payload: { oldPath, newPath } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    ContentApi.renameContent,
    oldPath,
    newPath,
    jupyterUser
  );
  if (response) {
    yield put(renameContentSuccess(response));
    const selectedDirPath = yield select(
      (state) => state.workbench.content.selectedDirPath
    );
    yield put(fetchContent(selectedDirPath));
    yield put(findAndAdjustOpenedNotebookPath(oldPath, newPath));
  } else {
    yield put(renameContentFail(error));
  }
}

export function* watchRenameContent() {
  yield takeEvery(renameContent.getType(), renameContentSaga);
}

export function* restoreContentSaga({ payload: { name, targetPath } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    ContentApi.restoreContent,
    name,
    targetPath,
    jupyterUser
  );
  if (response) {
    yield put(restoreContentSuccess(targetPath));
    const selectedDirPath = yield select(
      (state) => state.workbench.content.selectedDirPath
    );
    yield put(fetchContent(selectedDirPath));
    yield put(fetchContent(['root', '__recycleBin'])); // TODO No good idea to hard code the path of the recycle bin
  } else {
    yield put(restoreContentFailure(targetPath, error));
  }
}

export function* watchRestoreContent() {
  yield takeEvery(restoreContent.getType(), restoreContentSaga);
}

export function* emptyRecycleBinSaga() {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.deleteContent],
    '__recycleBin',
    true
  );
  if (response) {
    yield put(emptyRecycleBinSuccess());
    yield put(fetchContent(['root', '__recycleBin'])); // TODO No good idea to hard code this.
  } else {
    yield put(emptyRecycleBinFailure(error));
  }
}

export function* watchEmptyRecycleBin() {
  yield takeEvery(emptyRecycleBin.getType(), emptyRecycleBinSaga);
}
