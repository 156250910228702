// Contains selector functions that aren't offered by redux-form natively

/**
 * Returns the errors for a given field in a given form. Return value can be undefined - this means the field is valid.
 * @param state
 * @param formName
 * @param fieldName
 * @returns {undefined|*}
 */
export function selectFieldSyncError(state, formName, fieldName) {
  if (!state.form[formName]) return undefined;
  if (!state.form[formName].syncErrors) return undefined;
  if (!state.form[formName].syncErrors[fieldName]) return undefined;
  return state.form[formName].syncErrors[fieldName].message;
}

/**
 * Returns whether a field was already touched or not
 * @param state
 * @param formName
 * @param fieldName
 * @returns {boolean|*}
 */
export function selectFieldTouched(state, formName, fieldName) {
  if (!state.form[formName]) return false;
  if (!state.form[formName].fields) return false;
  if (!state.form[formName].fields[fieldName]) return false;
  return state.form[formName].fields[fieldName].touched;
}

/**
 * Returns whether a field was already visited or not
 * @param state
 * @param formName
 * @param fieldName
 * @returns {boolean|*}
 */
export function selectFieldVisited(state, formName, fieldName) {
  if (!state.form[formName]) return false;
  if (!state.form[formName].fields) return false;
  if (!state.form[formName].fields[fieldName]) return false;
  return state.form[formName].fields[fieldName].visited;
}

/**
 * Selects the value of a field. Is provided by redux-form, but doesn't work for some reason
 * @param state
 * @param formName
 * @param fieldName
 * @returns {string|*}
 */
export function selectValue(state, formName, fieldName) {
  if (!state.form[formName]) return '';
  if (!state.form[formName].values) return '';
  return state.form[formName].values[fieldName];
}
