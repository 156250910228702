import React, { Component } from 'react';
import { ToBeRefined } from 'common/dist/types/todo_type';
import {
  DSRoles,
  DSRolesToSpeaking,
  DSRolesType,
} from 'common/dist/types/dataManagement/dataSource';
import DropdownSelectInput from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

export type Value = DSRolesType;
export type Error = string;
export type Props = {
  input: {
    value: Value;
    onBlur: (v: Value) => ToBeRefined;
    onChange: (v: Value) => ToBeRefined;
  };
  valid: boolean;
  error: Error;
  touched: boolean;
  selectedDsType: string;
};

type OptionType = { value: DSRolesType; label: string };

export default class StepRole extends Component<Props> {
  render() {
    const {
      input: { value, onChange, onBlur },
      selectedDsType,
    } = this.props;

    const options: () => OptionType[] = () => {
      // TODO Coding this "filter" here doesn't seem right. How could this be done better (maybe at the sample place as the type definitions?)
      if (selectedDsType === 'cassandra') {
        return [
          {
            value: DSRoles.DefaultCassandra,
            label: DSRolesToSpeaking[DSRoles.DefaultCassandra],
          },
        ];
      } else if (selectedDsType === 's3') {
        return [
          {
            value: DSRoles.HostMount,
            label: DSRolesToSpeaking[DSRoles.HostMount],
          },
          {
            value: DSRoles.RGW,
            label: DSRolesToSpeaking[DSRoles.RGW],
          },
        ];
      }
    };

    return (
      <div className={'input-container'}>
        <div className={'input'}>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>Role</span>
            </label>

            <DropdownSelectInput
              id={'DropdownSelectInput'}
              placeholder={{
                id: 'no-id',
                defaultMessage: 'Role',
              }}
              options={options()}
              value={options().find((o) => o.value === value)}
              onBlur={() => onBlur(value)}
              onChange={(option: OptionType) => onChange(option.value)}
              onFocus={() => {}}
            />
          </div>
        </div>
      </div>
    );
  }
}
