import React, { Component } from 'react';
import Pipeline from './Pipeline';
import './styles.scss';
import { WrappedFieldProps } from 'redux-form';
import TabLineBubbles from '../../../molecules/tab-line-bubbles/TabLineBubbles';
import { PipelineTuningValue } from 'common/dist/types/pipeline';
import { PipelineTuningSchemaType } from '../_pipeline-tuning-results-common/types';

export type Props = {
  /** List of pipeline tuning schemas, each of them defines one pipeline structure */
  pipelineTuningSchemas: PipelineTuningSchemaType[];
  /** The default values of the pipeline (used for the "reset to defaults" button) */
  defaultValues?: PipelineTuningValue[];
};

type State = {
  activePipelineIndex: number;
};

export default class PipelineTuningSelection extends Component<
  Props & WrappedFieldProps,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      activePipelineIndex: 0,
    };
  }

  render() {
    const {
      pipelineTuningSchemas,
      input: { value, onChange, onBlur, onFocus },
      meta: { error },
      defaultValues,
    } = this.props;

    if (!pipelineTuningSchemas)
      return (
        <div className={'PipelineTuningSelection'}>
          No pipeline definitions found for this archetype
        </div>
      );

    const tabLineButtons = pipelineTuningSchemas?.map((pipeline, i) => ({
      id: i.toString(),
      intlId: 'no-id',
      intlDefault: pipeline.displayName,
      onClick: () =>
        this.setState({
          activePipelineIndex: i,
        }),
    }));

    const pipelineToRender =
      pipelineTuningSchemas[this.state.activePipelineIndex];

    return (
      <div className={'PipelineTuningSelection'}>
        <TabLineBubbles
          buttons={tabLineButtons}
          activeButton={this.state.activePipelineIndex.toString()}
          design={'tabs'}
        />
        {pipelineTuningSchemas.map((pipeline, i) => (
          <div
            style={{
              // Hide the pipelines that are not selected instead of only changing the pipeline={} prop of <Pipeline>.
              //   The Pipeline component is not capable of properly re-render if the pipeline prop changes dynamically ...
              display: this.state.activePipelineIndex === i ? 'block' : 'none',
            }}
          >
            <Pipeline
              key={`pipeline_${i}`}
              pipeline={pipeline}
              value={(value || [])[i]}
              onChange={(singleValue: PipelineTuningValue) =>
                onChange([
                  ...(value || []).slice(0, i),
                  singleValue,
                  ...(value || []).slice(i + 1),
                ])
              }
              onBlur={
                () =>
                  onBlur(value) /* is this a good idea not to pass the value? */
              }
              onFocus={
                () =>
                  onFocus(
                    value
                  ) /* is this a good idea not to pass the value? */
              }
              error={(error || {})[i]}
              defaultValues={(defaultValues || [])[i]}
            />
          </div>
        ))}
      </div>
    );
  }
}
