import { connect } from 'react-redux';
import RunningApp from './RunningApp';
import { fetchNotebookForApp } from '../../../redux/workbench/modules/app.module';

export function mapStateToProps(state) {
  const { loading, loaded, error } = state.workbench.app;
  return {
    loading,
    loaded,
    error,
    notifications: state.workbench.notifications,
  };
}

export const mapDispatchToProps = {
  fetchNotebookForApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(RunningApp);
