import { connect } from 'react-redux';
import CodeCell from './CodeCell';
import { clearForceFocus } from '../../../../redux/workbench/modules/notebook.focus.module';

export function mapStateToProps(state, { path }) {
  return {
    forceFocus: state.workbench.notebooks[path]?.forceFocus,
  };
}

export const mapDispatchToProps = {
  clearForceFocus,
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeCell);
