import { connect, ConnectedProps } from 'react-redux';
import Realtime from './Realtime';
import { realtimeStatus } from './ActivateRealtimeToggle.container';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../../../../../store/store';
import { RouteComponentProps } from 'react-router';

export function mapStateToProps(
  state: RootState,
  { match }: RouteComponentProps<{ augurCode: string }>
) {
  const { augurCode } = match.params;
  const rStatus = realtimeStatus(state, augurCode);
  return {
    /** Status about the realtime serving (possibly empty if not started) */
    data: rStatus?.data,
  };
}

const connector = connect(mapStateToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(Realtime));
