import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { select } from 'd3-selection';
import { axisBottom } from 'd3-axis';
import { injectIntl, IntlShape } from 'react-intl';
import { ToBeRefined } from 'common/dist/types/todo_type';
import styles from './styles.module.scss';

export const getDateFormat = (intl) => (value) =>
  intl.formatDate(value, {
    month: 'short',
    day: '2-digit',
  });

type Props = {
  scale: ToBeRefined;
  intl: IntlShape;
};

export class AxisBottom extends Component<Props> {
  componentDidMount() {
    this.renderAxis(this.props);
  }

  shouldComponentUpdate(props) {
    this.renderAxis(props);
    return false;
  }

  renderAxis(props) {
    const { scale, intl } = props;
    const axis = axisBottom(scale).ticks(2).tickFormat(getDateFormat(intl));

    const axisNode = ReactDOM.findDOMNode(this.refs.ticks);
    select<ToBeRefined, ToBeRefined>(axisNode).call(axis);
  }

  render() {
    return (
      <g className={styles.chartAxis}>
        <g className={styles.bottomTicks} ref='ticks' />
      </g>
    );
  }
}

export default injectIntl(AxisBottom);
