import React, { FC } from 'react';
import styles from './styles.module.scss';
import { OptionProps } from 'react-select';

import classNames from 'classnames';

export type ColumnOptionType = {
  label: string;
  value: string;
  colType: string;
  colName: string;
  isDisabled?: boolean;
};
type OptionComponentProps = OptionProps<ColumnOptionType, false>;
const ColumnOption: FC<OptionComponentProps> = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <div
      {...innerProps}
      className={classNames(
        styles.columnOption,
        { [styles.isSelected]: props.isSelected },
        { [styles.isDisabled]: props.isDisabled }
      )}
      ref={innerRef}
      data-testingIdentifier={`${props.data.colName} - ${props.data.colType}`}
    >
      <span className={styles.colType}>{props.data.colType}</span>
      <span className={styles.colName}>{props.data.colName}</span>
    </div>
  );
};

export default ColumnOption;
