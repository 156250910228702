import { connect } from 'react-redux';

import Dashboard from './Dashboard';
import * as selector from '../../redux/selectors/dashboard.selector';

export function mapStateToProps(state) {
  return {
    initialized: selector.initialized(state),
  };
}

export default connect(mapStateToProps)(Dashboard);
