import React, { FC, Fragment } from 'react';
import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import { scoreDistributionUrl } from './ScoreDistribution.wrapper';
import { scoreDistributionCompareUrl } from './overview/all-scoring-runs/AllScoringRuns';
import Overview from './overview/Overview';
import qs from 'qs';
import Compare from './compare/Compare';
import RunMassPredictionModal from './overview/mass-prediction-modal/RunMassPredictionModal';
import { AvailableTables } from '../../../../../../store/datapoolTables/slice';
import { CProps } from './ScoreDistribution.container';
import { AugurDetailsRouteParams } from '../types';
import { useAugur } from '../../../../../../core/api/augurs';
import { useActiveModel } from '../../../../../../core/api/mlModels';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { FormattedMessage } from 'react-intl';

const ScoreDistribution: FC<CProps> = ({
  showRunMassPredictionModal,
  hideRunMassPredictionModal,
  isMassPredictionModalShown,
  fetchDatapoolTables,
  runJobGroup,
}) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const location = useLocation();

  const { data: augur, error } = useAugur(habitatCode, augurCode);
  const { data: activeModel } = useActiveModel(habitatCode, augurCode);

  const availableTables = useSelector<RootState, AvailableTables>(
    (state) =>
      state.datapoolTables.byDatapoolCode?.[augur?.datapoolCode]
        ?.availableTables
  );

  const { reportCodes: reportCodesToCompareStr } =
    qs.parse(location.search, { ignoreQueryPrefix: true }) ||
    ({} as { reportCodes });
  const reportCodesToCompare = (reportCodesToCompareStr || '').split(',');

  if (!augur) {
    return (
      <div>
        <FormattedMessage
          id='augur.error'
          defaultMessage='Error: {error}'
          values={{ error: error }}
        />
      </div>
    );
  }

  return (
    <Fragment>
      <Switch>
        {/* Score Distribution Compare page */}
        <Route path={scoreDistributionCompareUrl()}>
          <Compare
            augurName={augur?.name}
            activeModelCode={activeModel?.code}
            reportCodes={reportCodesToCompare}
          />
        </Route>

        {/* Score Distribution page */}
        <Route path={scoreDistributionUrl()}>
          <Overview
            activeModelCode={activeModel?.code}
            showRunMassPredictionModal={showRunMassPredictionModal}
          />
        </Route>
      </Switch>

      <RunMassPredictionModal
        augurCode={augurCode}
        augur={augur}
        habitatCode={habitatCode}
        isMassPredictionModalShown={isMassPredictionModalShown}
        hideRunMassPredictionModal={hideRunMassPredictionModal}
        fetchDatapoolTables={fetchDatapoolTables}
        availableTables={availableTables}
        runJobGroup={runJobGroup}
      />
    </Fragment>
  );
};

export default ScoreDistribution;
