import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import { inputElementShape, variableShape } from './P3InputElementShape';
import { getElementByType } from './Python3InputElementManager';
import ModalDialog from '../../../settings-dialog/ModalDialog.container';

export default class P3InputElementParent extends Component {
  render() {
    const {
      parentContainerClass,
      children,
      path,
      removeInputElement,
      cell,
      element,
      updateDataOfInputElement,
      showEditInputElement,
      hideEditInputElement,
      editInputElementModal,
    } = this.props;
    const elementClass = getElementByType(element.type); // "elementClass" is a pretty bad variable name here
    const ElementIcon = elementClass.icon;
    const elementName = elementClass.name;
    const iconColor = elementClass.iconColor;

    const source = getElementByType(element.type).getSource(
      element,
      cell.as_variables
    );

    // Split the source into several lines and cut off after 200 characters
    // (especially important for the editable table element that might have an arbitrary large source)
    const MAX_SOURCE_CHARACTERS = 200;
    const sourceCleaned = (source || '')
      .slice(0, MAX_SOURCE_CHARACTERS)
      .concat((source || '').length > MAX_SOURCE_CHARACTERS ? '...' : '')
      .split('\n')
      .map((line) => <span className={'code'}>{line}</span>);

    return (
      <div className={`input-element ${parentContainerClass}`}>
        <div className={'input-element-buttons'}>
          <div className={'input-element-icon-parent'}>
            <ElementIcon
              height={24}
              width={24}
              elementName={elementName}
              elementType={element.type}
              iconColor={iconColor}
            />
          </div>
          <div
            title={'Remove Input Element'}
            className={'workbench-button'}
            onClick={() => removeInputElement(path, cell.id, element.id)}
          >
            <FiTrash2 className={'icon'} size={'20px'} />
          </div>
          <div
            title={'Edit Input Element'}
            className={'workbench-button'}
            onClick={() =>
              showEditInputElement(path, cell.id, element, cell.as_variables)
            }
          >
            <FiEdit className={'icon'} size={'20px'} />
          </div>
        </div>
        <div className={'input-element-body'}>
          <div className={'input-element-container'}>
            {element.settings &&
              element.settings.label &&
              element.settings.label.inputValue &&
              element.settings.label.inputValue.trim() && (
                <p className={'input-element-label'}>
                  {element.settings.label.inputValue}
                </p>
              )}
            {element.settings &&
              element.settings.description &&
              element.settings.description.inputValue &&
              element.settings.description.inputValue.trim() && (
                <p className={'input-element-description'}>
                  {element.settings.description.inputValue}
                </p>
              )}
            {element.data && element.data.error && (
              <p className={'input-element-error'}>{element.data.error}</p>
            )}
            {
              // --- Visual component for inside the cell: text input / calendar element / ...
              React.Children.map(children, (child) =>
                React.cloneElement(child, {
                  path,
                  cellId: cell.id,
                  updateDataOfInputElement,
                  element,
                })
              )
            }
          </div>
          <div className={'input-element-source'}>{sourceCleaned}</div>
        </div>
        {editInputElementModal.active /* This is important to re-create the redux-form every time it is opened (otherwise old values - that might even be from different cells/elements - will be kept in the form) */ && (
          <ModalDialog
            isOpen={editInputElementModal.active}
            closeDialog={hideEditInputElement}
            element={editInputElementModal.element}
            path={editInputElementModal.path}
            cellId={editInputElementModal.cellId}
            variables={editInputElementModal.variables}
            inputOrOutput={'input'}
          />
        )}
      </div>
    );
  }
}

P3InputElementParent.propTypes = {
  parentContainerClass: PropTypes.string,
  children: PropTypes.arrayOf(React.Component),
  /** Path of the parent notebook (required for changes) */
  path: PropTypes.string.isRequired,
  /** Click listener for the "remove" buttons of the input elements */
  removeInputElement: PropTypes.func.isRequired,
  /** Parent cell this input element belongs to */
  cell: PropTypes.object.isRequired,
  element: inputElementShape.isRequired,
  /** Function that updates the user input data entered in the input element */
  updateDataOfInputElement: PropTypes.func.isRequired,
  editInputElementModal: PropTypes.shape({
    active: PropTypes.bool,
    path: PropTypes.string,
    cellId: PropTypes.string,
    variables: PropTypes.arrayOf(variableShape),
    element: PropTypes.object,
  }),
  showEditInputElement: PropTypes.func.isRequired,
  hideEditInputElement: PropTypes.func.isRequired,
};
