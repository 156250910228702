import React, { ComponentType } from 'react';
import ScoreDistribution from './ScoreDistribution.container';
import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import { FiBarChart2 } from 'react-icons/fi';
import { MessageDescriptor } from 'react-intl';

export const scoreDistributionUrl = (
  habitatCode = ':habitatCode',
  augurCode = ':augurCode',
  shortAugurType = ':moduleType'
) =>
  `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/score-distribution`;

export default class ScoreDistributionWrapper extends ComponentAugurDetailsTab {
  component = ScoreDistribution;
  icon: ComponentType = () => <FiBarChart2 size={17} />;
  id = 'score-distribution';

  to = scoreDistributionUrl;
  title: MessageDescriptor = {
    id: 'details.sideNav.score_distribution',
    defaultMessage: 'Score Distribution',
  };
  subRoutes: object[] | undefined = undefined;
}
