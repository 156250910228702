import React from 'react';

/**
 * HOC that wraps the Settings component with the settingsCategories
 * @param Settings
 * @param settingsCategories
 */
export function wrapSettingsWithCategories(Settings, settingsCategories) {
  return class extends React.Component {
    render() {
      return (
        <Settings settingsCategories={settingsCategories} {...this.props} />
      );
    }
  };
}
