import React, { FC } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { dataManagementRoutes } from '../../routes';
import { FiChevronLeft } from 'react-icons/fi';
import Busy from '../../../atoms/busy/Busy';
import { RouteComponentProps } from 'react-router';
import styles from './styles.module.scss';
import InputError from '../../../atoms/input-error/InputError';
import { useQuery } from 'react-query';
import keycloak from '../../../../../keycloak';

type Props = {
  dataSourceCode: string;
};

const ImagePreview: FC<
  Props & RouteComponentProps<{ path: string; bucket: string }>
> = (props) => {
  const { dataSourceCode, match } = props;

  const path = match.params.path || '';
  const bucket = match.params.bucket || '';

  const { data, isLoading, error } = useQuery<{}, string, string>(
    `image-preview-${path}`,
    async () => {
      const res = await keycloak.updateToken().then((_) => {
        return fetch(
          `/dataman/s3/${dataSourceCode}/download/bucket/${bucket}/${path}`,
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          }
        );
      });
      if (res.status >= 400) {
        throw Error(`Unable to fetch: ${res.error}.`);
      }

      const imageBlob = await res.blob();
      return URL.createObjectURL(imageBlob);
    }
  );

  const renderInner = () => {
    if (isLoading) {
      return (
        <div className={styles.loading}>
          <Busy isBusy />
        </div>
      );
    }

    if (error) {
      return (
        <InputError
          // @ts-ignore
          error={error.message}
          touched={true}
        />
      );
    }

    if (data) {
      return (
        <div className={styles.imageContainer}>
          <img src={data} />
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <div className={styles.backButtonContainer}>
          <Link
            to={`${
              dataManagementRoutes.basePath
            }/s3/${dataSourceCode}/bucket/${bucket}/content/${path.substring(
              0,
              path.lastIndexOf('/')
            )}`}
            style={{ textDecoration: 'none' }}
          >
            <div className={styles.backButton}>
              <FiChevronLeft size={20} className={styles.backIcon} />
              <span className={styles.backText}>Back</span>
            </div>
          </Link>
        </div>
      </div>

      <span className={styles.tableBrowserHeadline}>
        Image Preview for <b>{path}</b> in Bucket <b>{bucket}</b>
      </span>

      {renderInner()}
    </div>
  );
};

export default withRouter(ImagePreview);
