import PropTypes from 'prop-types';
import React from 'react';

import { Tabs } from 'react-tabs-redux';
import TabContentModelMan from './main-container/TabContentModelMan';
import TabContentWorkbench from './main-container/TabContentWorkbench';
import TabContentCollaborationSpace from './main-container/TabContentCollaborationSpace';
import TapContentOrchestration from './main-container/TabContentOrchestration';
import TabContentApps from './main-container/TabContentApps';
import TabContentDataMan from './main-container/TabContentDataMan.container';
import Busy from '../atoms/busy/Busy';
import SubHeader from '../organisms/sub-header/SubHeader.container';

class Dashboard extends React.Component {
  render() {
    const { initialized } = this.props;
    if (!initialized) {
      return (
        <div className='fullViewportHeight'>
          <Busy isBusy />
        </div>
      );
    }

    return (
      <div>
        <Tabs name={'main-tab'} renderActiveTabContentOnly={false}>
          {/* Contains all TabLinks */}
          <SubHeader hasOverlay={false} />

          <TabContentModelMan {...this.props} />
          <TabContentApps {...this.props} />
          <TabContentWorkbench {...this.props} />
          <TabContentCollaborationSpace {...this.props} />
          <TapContentOrchestration {...this.props} />
          <TabContentDataMan {...this.props} />
        </Tabs>
      </div>
    );
  }
}

Dashboard.propTypes = {
  initialized: PropTypes.bool.isRequired,
  match: PropTypes.shape({}).isRequired,
};

export default Dashboard;
