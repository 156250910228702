import React, { Component } from 'react';
import { Tabs } from 'react-tabs-redux';
import TabLine from '../tab-line/TabLine.container';
import SingleEditorPaneTabContent from './SingleEditorPaneTabContent';
import ErrorBoundary from '../../../../pages/error-boundary/ErrorBoundary';
import { Pane } from '../../../../../workbench/types';
import { Notebook } from '../../../../../store/workbench/state.types';

export type Props = {
  notebooks?: Record<string, Notebook>;
  /** ID of the Pane this editor is placed at */
  paneId: string;
  /** Details about the pane */
  pane: Pane;
};
export default class SingleEditorPane extends Component<Props> {
  render() {
    const { notebooks, pane, paneId } = this.props;
    if (!pane) {
      // This case should never happen, but just to be sure
      console.log('Pane was undefined');
      return <div />;
    }
    const { content, selectedContent } = pane;

    return (
      <Tabs
        // @ts-ignore
        className={'container-notebook-tabs'}
        name={'notebook-tabs'}
        renderActiveTabContentOnly
        selectedTab={selectedContent}
      >
        <TabLine paneId={paneId} />
        {content &&
          content.map((contentElement) => (
            <ErrorBoundary>
              <SingleEditorPaneTabContent
                paneId={paneId}
                contentElement={contentElement}
                selectedContent={selectedContent}
                notebook={notebooks[contentElement.path]}
              />
            </ErrorBoundary>
          ))}
      </Tabs>
    );
  }
}
