export const isNewPasswordShown = (state) => state.account.isNewPasswordShown;

export const isOldPasswordShown = (state) => state.account.isOldPasswordShown;

export const language = (state) => state.intl.locale;

export const userLocale = (state) =>
  state.account.user?.attributes?.locale?.[0];

export const avatarState = (state) => state.account.photo.state;

export const avatarFile = (state) => state.account.photo.file;
