import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import RadioButtonsInput from '../../../../../atoms/input-elements/radio-buttons-input/RadioButtonsInput';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

export default class StepKeyspace extends Component {
  componentDidMount() {
    const {
      fetchCassandraKeyspaces,
      fetchCassandraPermissions,
      dataSourceCode,
    } = this.props;
    fetchCassandraKeyspaces(dataSourceCode);
    fetchCassandraPermissions(dataSourceCode);
  }

  render() {
    const {
      availableKeyspaces,
      keyspacesLoading,
      meta: { error, touched },
      input: { value, onChange, onBlur, onFocus },
    } = this.props;

    const options = (availableKeyspaces || [])
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((k) => ({ label: k.name, value: k.name }));

    return (
      <Fragment>
        <div className={'GenericFormStep--field'}>
          <RadioButtonsInput
            touched={touched}
            error={error?.strategy}
            disabled={false}
            id={'strategy'}
            hasLabel={false}
            value={value?.strategy}
            onChange={(strategy) => onChange({ ...value, strategy })}
            entriesPerRow={2}
            columnWidth={'120px'}
            radioButtons={[
              {
                value: 'existing',
                labelId: 'no-label',
                labelDefault: 'Existing',
              },
              {
                value: 'create',
                labelId: 'no-label',
                labelDefault: 'Create',
                disabled: true,
              },
            ]}
          />
        </div>

        {value?.strategy && value.strategy === 'existing' && (
          <div className={'GenericFormStep--field'}>
            <DropdownSelectInput
              touched={touched}
              error={error?.keyspace}
              valid={!error?.keyspace}
              id={'keyspace-select'}
              name={'keyspace-select'}
              placeholder={{
                id: 'keyspace',
                defaultMessage: 'Keyspace',
              }}
              value={options.find((o) => o.value === value?.name)}
              onChange={(option) => onChange({ ...value, name: option.value })}
              onFocus={onFocus}
              onBlur={() => onBlur(value)}
              isLoading={keyspacesLoading}
              clearable={true}
              options={options}
              autoSelectIfSingle={true}
            />
          </div>
        )}
      </Fragment>
    );
  }
}
StepKeyspace.propTypes = {
  dataSourceCode: PropTypes.string.isRequired,
  fetchCassandraKeyspaces: PropTypes.func.isRequired,
  keyspacesLoading: PropTypes.bool,
  availableKeyspaces: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  input: PropTypes.shape({
    value: PropTypes.shape({
      /** existing | create */
      strategy: PropTypes.string,
      /** Name of the selected Keyspace */
      name: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.shape({
    valid: PropTypes.bool,
    error: PropTypes.object, // TODO or is it a string?
  }),
};
