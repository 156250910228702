import React, { FC } from 'react';
import { BiographyEntry } from 'common/dist/types/augurBiography';
import { speakingJobType } from '../../../molecules/job-groups/job/Job';
import styles from '../styles.module.scss';
import classNames from 'classnames';

type Props = {
  entry: BiographyEntry;
  isErroneous?: boolean;
  isReactivated?: boolean;
};

const CommonEntryParts: FC<Props> = (props: Props) => {
  const { entry, isReactivated } = props;
  return (
    <div className={styles.jobTypeContainer}>
      <span className={styles.jobType}>
        {isReactivated ? 'Model Reactivation' : speakingJobType(entry.jobType)}
      </span>
      {entry.isErroneous && (
        <span className={classNames(styles.errorIcon, 'icon-alert')} />
      )}
    </div>
  );
};

export default CommonEntryParts;
