import PropTypes from 'prop-types';
import React from 'react';
import vars from '../../../../scss/base/var.module.scss';

import { getDrift, getThreshold, getTime } from '../../../core/common/drift';

export function EvaluationBar(props) {
  const {
    xScale,
    yScale,
    evaluation,
    showTooltip,
    hideTooltip,
    moveTooltip,
    isTooltip,
  } = props;

  function handleMouseEnter(event) {
    showTooltip(event.clientX, event.pageY, evaluation);
  }

  const handleMouseMove = (event) => {
    if (isTooltip) {
      moveTooltip(event.clientX, event.pageY, evaluation);
    }
  };

  function handleMouseLeave() {
    hideTooltip();
  }

  const y = getDrift(evaluation);
  const x = getTime(evaluation);
  const fill =
    getDrift(evaluation) > getThreshold(evaluation)
      ? vars.colorPrimaryLighter60
      : vars.colorPrimaryLighter90;

  return (
    <g>
      <rect
        fill={fill}
        width={xScale.bandwidth()}
        height={Math.abs(yScale(0) - yScale(y))}
        x={xScale(x)}
        y={yScale(y)}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
      />
    </g>
  );
}

EvaluationBar.propTypes = {
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  evaluation: PropTypes.object.isRequired,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  moveTooltip: PropTypes.func.isRequired,
  isTooltip: PropTypes.bool,
};

export default EvaluationBar;
