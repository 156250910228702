import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { UserSummary } from 'common/dist/types/users';
import { fetchUserSummaryById as fetchUserSummaryByIdApi } from './api';

export interface User {
  loading: boolean;
  loaded: boolean;
  requestId: string | undefined;
  data?: UserSummary;
  error?: string;
}

export type UsersState = {
  byId: {
    [userId: string]: User;
  };
};

export const initial: UsersState = {
  byId: {},
};

export const test: UsersState = {
  byId: {},
};

export const getUserById = createSelector(
  (state: UsersState) => state.byId,
  (state: UsersState, id: string) => id,
  (ids, id) => ids[id]
);

/** Types are in order:
 * - expected return type
 * - call parameter / arg (is an object so that parameters are named)
 * - additional config for example the error return type (for the automatically created reject action)
 */
export const fetchUserSummaryById = createAsyncThunk<
  { data: UserSummary },
  { userId: string },
  { rejectValue: { error: string }; state: { users: UsersState } }
>('users/fetchUserSummaryById', async ({ userId }, thunkAPI) => {
  const state = thunkAPI.getState();
  const user = getUserById(state.users, userId);
  // Reject the action that has not set the requestId. This will return undefined to the fulfilled case, where that must be handled
  if (user.requestId !== thunkAPI.requestId) return;
  const apiResponse = await fetchUserSummaryByIdApi(userId);

  if ('response' in apiResponse) {
    return { data: apiResponse.response };
  } else {
    // @ts-ignore
    return thunkAPI.rejectWithValue({ error: apiResponse.error });
  }
});

const slice = createSlice({
  name: 'users',
  initialState: initial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserSummaryById.pending, (state, action) => {
      const { userId } = action.meta.arg;
      // Don't update the state if another action is already underway (We need that action's requestId to reject this action's effect)
      if (state.byId[userId]?.loading) return;
      state.byId[userId] = {
        loading: true,
        loaded: false,
        requestId: action.meta.requestId,
      };
    });
    builder.addCase(
      fetchUserSummaryById.fulfilled,
      (state, { payload, meta }) => {
        const { userId } = meta.arg;
        // Ignore actions that returned with undefined, because they were ignored and now have no payload
        if (state.byId[userId].requestId === meta.requestId) {
          state.byId[userId].data = payload.data;
          state.byId[userId].loading = false;
          state.byId[userId].loaded = true;
          state.byId[userId].requestId = undefined;
        }
      }
    );
    builder.addCase(
      fetchUserSummaryById.rejected,
      (state, { payload, meta }) => {
        const { userId } = meta.arg;
        state.byId[userId].error = payload.error;
        state.byId[userId].loading = false;
        state.byId[userId].loaded = false;
      }
    );
  },
});

const reducer = slice.reducer;
export { reducer as usersReducer };
