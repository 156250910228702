import React, { FC, useState } from 'react';
import { renderRoutes, RouteConfig } from 'react-router-config';
import {
  Redirect,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { MenuEntryType } from '../molecules/with-sidemenu/SideMenu';
import WithSidemenu from '../molecules/with-sidemenu/WithSidemenu';
import MainContainer from '../pages/main-container/MainContainer';
import {
  getAllAugurDetailsRoutes,
  getAugurDetailsDefaultPage,
  getAugurDetailsTabs,
} from '../modelManagement/modules/ModuleManagerClient';
import memoize from 'lru-memoize';
import SubHeader from '../organisms/sub-header/SubHeader.container';
import OverlayAugurDetails from '../organisms/sub-header/overlay-augur-details/OverlayAugurDetails.container';
import AugurBiographySidebar from './AugurBiographySidebar.container';
import BrowserCollapseFlap from '../atoms/BrowserCollapseFlap/BrowserCollapseFlap';
import styles from './styles.module.scss';
import {
  AugurDetailsRouteParams,
  AugurDetailsWhitelistQuery,
} from '../modelManagement/modules/common/augur-details-tabs/types';
import { useAugur } from '../../core/api/augurs';

const getMenuEntries = (
  habitatCode: string,
  augurCode: string,
  shortAugurType: string,
  inputFormat: string | undefined
): MenuEntryType[] => {
  if (!shortAugurType) return [];
  return getAugurDetailsTabs(
    habitatCode,
    augurCode,
    shortAugurType,
    inputFormat
  );
};

// Memoized version of getMenuEntries
const getMenuEntriesMemoized: (
  habitatCode: string,
  augurCode: string,
  shortAugurType: string,
  inputFormat: string | undefined
) => MenuEntryType[] = memoize(10)(getMenuEntries);

// Memoized version of getAllAugurDetailsRoutes
const getRoutesMemoized: (
  augurType: string,
  inputFormat: string | undefined
) => RouteConfig[] = memoize(10)(getAllAugurDetailsRoutes);

const AugurDetails: FC = () => {
  const match = useRouteMatch();
  const { habitatCode, augurCode, augurType } = useParams<
    AugurDetailsRouteParams & { augurType: string }
  >();
  const location = useLocation();

  const [biographyExpanded, setBiographyExpanded] = useState(true);

  const { data: augur } = useAugur(habitatCode, augurCode);

  const getActiveCategory = () => {
    const parts = location.pathname.split('/');
    if (parts.length < 8) return null;

    let active = parts[7]; // TODO No good idea to pick out the active category like this ...
    if (active === 'accuracy-details') active = 'accuracy'; // TODO The accuracy-details should be sub-routes of the accuracy

    return active;
  };

  if (match.isExact) {
    const defaultPage = getAugurDetailsDefaultPage(augurType);
    return <Redirect to={`${match.url}/${defaultPage}`} />;
  }

  const selectedCategory = getActiveCategory();

  const menuEntries = getMenuEntriesMemoized(
    habitatCode,
    augurCode,
    augurType,
    augur?.datapool?.inputFormat
  );
  const routes = getRoutesMemoized(
    augurType,
    augur?.datapool?.inputFormat
  ).filter(
    ({ path }: { path: string }) =>
      !path || path.includes(augurType) || path.includes(':moduleType')
  );

  return (
    <MainContainer fullWidth={true}>
      <SubHeader
        hasOverlay
        Overlay={OverlayAugurDetails}
        defaultSelectedTabId={'models'}
      />

      <WithSidemenu
        menuEntries={menuEntries}
        selectedTab={selectedCategory}
        handleSelect={() => {
          /* Nothing to do */
        }}
        collapsable={true}
        preserveWhitelistQueryOnLink={AugurDetailsWhitelistQuery}
      >
        <div className={styles.augurDetailsParent}>
          <div className={styles.augurDetailsContentParent}>
            {!biographyExpanded && (
              <BrowserCollapseFlap
                isExpanded={true} // Inverted because it is on the right hand side
                toggleBrowser={() => setBiographyExpanded(true)}
              />
            )}
            {renderRoutes(routes)}
          </div>
          {biographyExpanded && (
            <div className={styles.augurBiographyParent}>
              <BrowserCollapseFlap
                isExpanded={false} // Inverted because it is on the right hand side
                toggleBrowser={() => setBiographyExpanded(false)}
              />

              <AugurBiographySidebar
                queryParameterPrefix={'sb'}
                selectable={true}
                header={'Augur Changes'}
              />
            </div>
          )}
        </div>
      </WithSidemenu>
    </MainContainer>
  );
};

export default AugurDetails;
