import * as React from 'react';

function SvgCodeCapsule(props) {
  return (
    <svg height='1em' viewBox='0 0 512 512' width='1em' {...props}>
      <path
        fill='#eef0f2'
        d='M512 256c0 141.387-114.613 256-256 256S0 397.387 0 256 114.613 0 256 0s256 114.613 256 256zm0 0'
      />
      <path
        fill='#dce2eb'
        d='M61 302.629l208.984 208.984c116.23-6.258 211.84-90.043 236.063-200.566L451 256 61 302.629zm0 0'
      />
      <path
        fill='#224e90'
        d='M311.11 209.371V116.11L451 256l-195 16.957L61 256v-46.629h250.11zm0 0'
      />
      <path
        stroke='#224e90'
        fill='#224e90'
        d='M451 257L311.11 396.89V303.63H61V257h390zm0 0'
      />
    </svg>
  );
}

export default SvgCodeCapsule;
