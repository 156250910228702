import React from 'react';
import SortableSearchableList from './SortableSearchableList';

export type OwnProps<Element, Ordering, ContentProps> = {
  /** List of available ordering options */
  orderings: {
    label: string;
    value: Ordering;
  }[];
  /** Default ordering (selected as default in the ordering Dropdown) */
  defaultOrdering: Ordering;
  /** List of the list elements to display */
  data: Element[];
  contentProps: (element: Element, index: number) => ContentProps;
  /** Component of one list element */
  ContentComponent: React.ComponentType<ContentProps>;
  /** How to apply the filtering query to the elements? */
  search: (element: Element, searchString: string) => boolean;
  /** Function for how to sort the list elements based on the selected ordering */
  sort: (data: Element[], ordering: Ordering) => Element[];
  /** Title of the list */
  title: string;
  /** "Sub-title" of the list */
  info?: string;
  /** Display a button in the right top corner? */
  button?: {
    buttonText: string;
    onClick?: () => any;
    link?: string;
  };
  style?: object;
};

export type Props<Element, Ordering, ContentProps> = OwnProps<
  Element,
  Ordering,
  ContentProps
>;

export default function SortableSearchableListContainer<
  Element,
  Ordering,
  ContentProps
>() {
  return SortableSearchableList as new (
    props: Props<Element, Ordering, ContentProps>
  ) => SortableSearchableList<Element, Ordering, ContentProps>;
}
