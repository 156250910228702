import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { dataManagementRoutes } from '../routes';
import BucketBrowser from './bucket-browser/BucketBrowser.container';
import Tabs from './Tabs';
import { RouteComponentProps } from 'react-router';
import { typeToSpeaking } from 'common/dist/types/dataManagement/dataSource';
import TableBrowserContainer from './table-browser/TableBrowser.container';
import ImagePreview from './image-preview/ImagePreview';

export interface Props {
  /** Name of the data source */
  name: string;
  /** Must be 's3' in this case */
  type: 's3';
  /** Code of the data source */
  code: string;
}

class S3 extends Component<Props & RouteComponentProps> {
  render() {
    const { name, type, code } = this.props;
    return (
      <div className={'data-source-parent'}>
        <div className={'data-source-headline'}>
          <div className={'data-source-description'}>
            <span className={'data-source-name'}>{name}</span>
            <span className={'data-source-type'}>{typeToSpeaking(type)}</span>
          </div>
        </div>

        <div className={'data-source-body'}>
          <Switch>
            <Route
              exact
              path={[
                `${dataManagementRoutes.basePath}/s3/${code}/${dataManagementRoutes.buckets}`,
                `${dataManagementRoutes.basePath}/s3/${code}/${dataManagementRoutes.auth}`,
              ]}
            >
              <Tabs code={code} />
            </Route>
            <Route
              path={`${dataManagementRoutes.basePath}/s3/${code}/bucket/:bucket/preview-table/:path+`}
            >
              <TableBrowserContainer
                dataSourceCode={code}
              ></TableBrowserContainer>
            </Route>
            <Route
              path={`${dataManagementRoutes.basePath}/s3/${code}/bucket/:bucket/preview-image/:path+`}
            >
              <ImagePreview dataSourceCode={code} />
            </Route>
            <Route
              path={`${dataManagementRoutes.basePath}/s3/${code}/bucket/:bucket/content/*`}
              component={() => <BucketBrowser dataSourceCode={code} />}
            />
            <Redirect
              exact
              path={`${dataManagementRoutes.basePath}/s3/${code}`}
              to={`${dataManagementRoutes.basePath}/s3/${code}/${dataManagementRoutes.buckets}`}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(S3);
