import { connect } from 'react-redux';
import RepositoryWarning from './RepositoryWarning';
import {
  addNotebook,
  loadRepoMeta,
  saveEditNotebook,
} from '../../../../redux/workbench/modules/notebook.module';
import { loadArchetypeExample } from '../../../../redux/workbench/modules/container-interactions.module';

export function mapStateToProps(state) {
  const selectedDirPath = state.workbench.content.selectedDirPath || [];
  const activePath = selectedDirPath.slice(1).join('/');

  return {
    repoMeta: state.workbench?.content?.repoMeta || {},
    activePath,
  };
}

export const mapDispatchToProps = {
  loadRepoMeta,
  addNotebook,
  loadArchetypeExample,
  saveEditNotebook,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepositoryWarning);
