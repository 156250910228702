import { FiX } from 'react-icons/fi';
import ParameterTuningSelection from '../../../../../../../../../components/organisms/parameter-tuning/parameter-tuning-selection/ParameterTuningSelection';
import { fieldParameterTuning } from './parameterTuning.form';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { ParameterTuningSchema } from '../../../../../../../../organisms/parameter-tuning/parameter-tuning-selection/types';
import { Field, InjectedFormProps } from 'redux-form';
import { AugurSettingsData } from 'common/dist/types/augurSettings';
import { ParameterTuningOutput } from 'common/dist/types/augurSettings.nbc';
import Button from '../../../../../../../../atoms/button/Button';

export interface Props {
  parameterTuningSettings: ParameterTuningOutput;
  parameterTuningSchema: ParameterTuningSchema;
  hide: () => void;

  // --- From mapStateToProps
  hasError: boolean;
  settings: AugurSettingsData;

  // --- From mapDispatchToProps
  saveSettings: (settings: Partial<AugurSettingsData>, merge: boolean) => void;
}

class ParameterTuningEditMode extends Component<
  Props & InjectedFormProps & WrappedComponentProps
> {
  render() {
    const { hide, parameterTuningSchema, hasError, saveSettings, settings } =
      this.props;
    return (
      <div className={'data-preview-modal modal-parent'}>
        <div
          title={'Close Dialog'}
          className={'workbench-button close-button'}
          onClick={hide}
        >
          <FiX className={'icon'} size={'20px'} />
        </div>

        <div className={'modal-group-parent'}>
          <span className={'modal-headline'}>Parameter Tuning</span>
          <span className={'modal-description'}>
            Tune parameters for training
          </span>

          <Field
            name={fieldParameterTuning}
            component={ParameterTuningSelection}
            parameterTuningSchema={parameterTuningSchema}
          />

          <div className='augur--line active'>
            <div className='form--buttons--line form--buttons'>
              <Button
                buttonColor={'transparent'}
                withLink={false}
                buttonLabelDefault={'Reset & Close'}
                onClick={() => {
                  hide();
                }}
              />

              <Button
                buttonColor={'secondary'}
                withLink={false}
                buttonLabelDefault={'Save'}
                disabled={hasError}
                onClick={() => {
                  hide();
                  saveSettings(settings, true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(ParameterTuningEditMode);
