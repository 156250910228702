import React, { Component, Fragment } from 'react';
import './styles.scss';

import Donut from './Donut';
import Legend from './Legend';
import Sizer from '../../../atoms/sizer/Sizer';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { toLinkWhitelistQuery } from '../../../../utils';
import { AugurDetailsWhitelistQuery } from '../../../modelManagement/modules/common/augur-details-tabs/types';
import { RouteComponentProps } from 'react-router';
import { ToBeRefined } from 'common/dist/types/todo_type';

type InnerPieChartProps = {
  /** Injected by the <Sizer> component */
  width: number;
  /** Injected by the <Sizer> component */
  height: number;
  /** Injected by the <Sizer> component */
  left: number;
  /** Force the sizer to measure again (called after drawing the SVG for the first time) */
  requestSize: () => void;
} & PieChartProps;

export type PieChartProps = {
  /** CSS prop for the height of the chart */
  height: string;
  /** CSS prop for the width of the chart */
  width: string;
  /** Input data for the line chart */
  data: ToBeRefined;
  /** Optional title for the chart */
  title?: MessageDescriptor;
  link?: {
    to: string;
    onClick: () => void;
  };
  /** Render a border around the chart? */
  withBorder?: boolean;
  legend: LegendEntryType[];
};

export type LegendEntryType = {
  id: string;
  label: MessageDescriptor;
  color: string;
};

class InnerPieChart extends Component<InnerPieChartProps> {
  componentDidMount() {
    const { requestSize } = this.props;
    requestSize();
  }

  render() {
    const { data, width, height } = this.props;
    return <Donut data={data} width={width} height={height} />;
  }
}

class PieChart extends Component<PieChartProps & RouteComponentProps> {
  renderInner() {
    const { data, title, withBorder, legend } = this.props;
    return (
      <Fragment>
        {title && (
          <div className={'PieChart--title'}>
            <FormattedMessage
              id={title.id || 'no-id'}
              defaultMessage={title.defaultMessage}
            />
          </div>
        )}
        <div
          className={
            'PieChart--container' +
            (title ? ' PieChart--with-title' : '') +
            (withBorder ? ' PieChart--with-border' : '')
          }
        >
          <div className={'PieChart--chart'}>
            <Sizer>
              {/* @ts-ignore */}
              <InnerPieChart data={data} />
            </Sizer>
          </div>

          <Legend legend={legend} />
        </div>
      </Fragment>
    );
  }

  render() {
    const { link, width, height, location } = this.props;
    if (link) {
      // --- Render the component wrapped into a <Link>
      return (
        <div className={'PieChart'} style={{ width, height }}>
          <Link
            className='PieChart--link'
            to={toLinkWhitelistQuery(
              link.to,
              location,
              AugurDetailsWhitelistQuery
            )}
            onClick={link.onClick || (() => {})}
          >
            {this.renderInner()}
          </Link>
        </div>
      );
    } else {
      // --- Render the component without a <Link>
      return (
        <div className={'PieChart'} style={{ width, height }}>
          {this.renderInner()}
        </div>
      );
    }
  }
}

export default withRouter(PieChart);
