import { connect } from 'react-redux';
import SingleNotebookCell from './SingleNotebookCell';
import { DeprecatedRootState } from '../../../../../../../store/state.type';
// @ts-ignore
import { changeSource } from '../../../../../../../redux/workbench/modules/notebook.source.module';
// @ts-ignore
import { selectCells } from '../../../../../../../redux/workbench/modules/notebook.module';
import { BASE_FILE_PATH } from './ButtonsBaseFile';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    selectedCells:
      (state.workbench.notebooks[BASE_FILE_PATH] || {}).selectedCells || [],
  };
}

export const mapDispatchToProps = {
  changeSource,
  selectCells,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleNotebookCell);
