import React, { FC, useState } from 'react';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { fieldPropensities, propensitiesForm } from './form';
import { Field, reduxForm } from 'redux-form';
import CancelSubmitButtonsWrapper from '../../../../../molecules/reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import OptPropensities from '../../../../../organisms/modelManagement/newDatapoolWizard/optPropensities/OptPropensities';
import {
  Communication,
  Propensity,
} from 'common/dist/types/module.optimization';
import Button from '../../../../../atoms/button/Button';
import { FiEdit } from 'react-icons/all';
import classNames from 'classnames';

type Props = {
  initialValues: {
    [fieldPropensities]: Propensity[];
  };
  updateDatapool: (
    habitatCode: string,
    datapoolCode: string,
    data: Record<string, string | {}>,
    mergeMeta: boolean
  ) => void;
  habitatCode: string;
  datapoolCode: string;
  communications: Communication[];
};

const propensitiesComponent = ({ input, meta, communications, inEditMode }) => (
  <OptPropensities
    input={input}
    meta={meta}
    communications={communications}
    inEditMode={inEditMode}
  />
);

const Propensities: FC<Props & InjectedFormProps<FormValueType, Props>> = (
  props
) => {
  const {
    pristine,
    reset,
    submitting,
    handleSubmit,
    updateDatapool,
    habitatCode,
    datapoolCode,
    communications,
    valid,
  } = props;
  const [inEditMode, setInEditMode] = useState(false);

  return (
    <div className={styles.optPropensities}>
      <div className={styles.headlineContainer}>
        <FormattedMessage
          {...moduleOptimizationMessages.msgDatapoolDetailsPropensitiesTabName}
        >
          {(text) => <span className={styles.headline}>{text}</span>}
        </FormattedMessage>
        <Button
          buttonColor={'primary'}
          buttonLabelId={'no-id'}
          buttonLabelDefault={'Edit'}
          withLink={false}
          Icon={() => <FiEdit size={16} />}
          onClick={() => {
            setInEditMode(true);
          }}
          additionalClassNames={[
            classNames(styles.button, {
              [styles.invisible]: inEditMode,
            }),
          ]}
        />
      </div>
      <CancelSubmitButtonsWrapper
        cancelDisabled={!inEditMode || submitting}
        saveDisabled={pristine || submitting || !valid}
        savingButtonBusy={submitting}
        onCancel={() => {
          reset();
          setInEditMode(false);
        }}
        onSubmit={handleSubmit((values) => {
          updateDatapool(
            habitatCode,
            datapoolCode,
            {
              meta: {
                propensities: values[fieldPropensities] || [],
              },
            },
            true
          );
          setInEditMode(false);
        })}
      >
        <Field
          name={fieldPropensities}
          component={propensitiesComponent}
          communications={communications}
          inEditMode={inEditMode}
        />
      </CancelSubmitButtonsWrapper>
    </div>
  );
};

type FormValueType = {
  [fieldPropensities]: Propensity[];
};

// @ts-ignore
export default reduxForm<FormValueType, Props>(propensitiesForm)(Propensities);
