import { validatePath } from '../../../../../redux/modules/data.s3.module';
import { ConfigProps, FormErrors } from 'redux-form';
import { InnerProps } from './WizardUpload';
import { ReduxActUnknown4 } from '../../../../../store/reduxAct';
import { DecoratedFormProps } from 'redux-form/lib/reduxForm';
import dataManagementMsgs from 'common/dist/messages/dataManagement';
import HttpStatusCode from 'common/dist/constants/httpStatusCodes';

export const formName = 'upload-wizard-s3';

export const fieldBucket = 'bucket';
export const fieldPath = 'path';
export const fieldUpload = 'upload';

export interface BucketData {
  /** Name of the selected Bucket */
  name?: string;
  strategy?: 'existing' | 'create';
}

export interface PathData {
  /** The path */
  name?: string;
}

export interface UploadData {
  file: File[];
}

export interface UploadWizardS3Data {
  [fieldBucket]?: BucketData;
  [fieldPath]?: PathData;
  [fieldUpload]?: UploadData;
}

export interface UploadError extends Record<string, unknown> {
  file: string;
}

export type ErrorType = Record<string, unknown>;
export interface UploadWizardS3Error extends FormErrors<UploadData, ErrorType> {
  [fieldBucket]?: {
    name: string;
  };
  [fieldPath]?: {
    /** Message */
    name: string;
  };
  [fieldUpload]?: UploadError;
}

const pathMaxLength = 1000;

export function validate(
  values: UploadWizardS3Data,
  { intl }: DecoratedFormProps<UploadWizardS3Data, InnerProps, ErrorType>
): UploadWizardS3Error {
  let errors: UploadWizardS3Error = {};

  // --- Validate Bucket
  const bucket = values[fieldBucket];
  if (bucket) {
    const bucketName = bucket.name;
    if (!bucketName) {
      errors = {
        ...errors,
        [fieldBucket]: {
          name: intl.formatMessage(
            dataManagementMsgs.dataManS3UploadValidationErrorNoBucket
          ),
        },
      };
    }
  }

  // --- Validate Path
  const path = values[fieldPath];
  if (path) {
    const pathName = path.name;
    if (pathName.length >= pathMaxLength) {
      errors = {
        ...errors,
        [fieldPath]: {
          name: intl.formatMessage(
            dataManagementMsgs.dataManS3UploadValidationErrorMaxPath,
            { pathMaxLength }
          ),
        },
      };
    } else if (
      Array.from(pathName).reduce(
        (acc, c) => (c === '/' && acc.endsWith('/') ? acc : acc + c),
        ''
      ) !== pathName
    ) {
      errors = {
        ...errors,
        [fieldPath]: {
          name: intl.formatMessage(
            dataManagementMsgs.dataManS3UploadValidationErrorPathSeparators
          ),
        },
      };
    }
  }

  // --- Validate Upload
  const upload = values[fieldUpload];
  if (!(upload?.file?.length > 0)) {
    errors = {
      ...errors,
      [fieldUpload]: {
        file: intl.formatMessage(
          dataManagementMsgs.dataManS3UploadValidationErrorNoFile
        ),
      },
    };
  }

  return errors;
}

export function asyncValidate(
  values: UploadWizardS3Data,
  dispatch,
  {
    dataSourceCode,
    intl,
  }: DecoratedFormProps<UploadWizardS3Data, InnerProps, ErrorType>
) {
  // Validate path
  const bucket = values[fieldBucket];
  const path = values[fieldPath];
  const pathStrippedLeadingSlash = path.name.replace(/^\//, '');
  if (bucket.name && path.name) {
    return new Promise((resolve, reject) =>
      dispatch(
        (validatePath as ReduxActUnknown4)(
          dataSourceCode,
          bucket.name,
          pathStrippedLeadingSlash,
          { resolve, reject }
        )
      )
    ).then((status) =>
      status === HttpStatusCode.NO_CONTENT
        ? Promise.reject({
            [fieldPath]: {
              name: intl.formatMessage(
                dataManagementMsgs.dataManS3UploadValidationErrorPathExists
              ),
              exists: true,
            },
          })
        : null
    );
  }
}

export const initialValues: Partial<UploadWizardS3Data> = {
  [fieldBucket]: {
    strategy: 'existing',
  },
  [fieldPath]: {
    name: '/',
  },
};

export const uploadForm: ConfigProps<
  UploadWizardS3Data,
  InnerProps,
  ErrorType
> = {
  form: formName,
  validate,
  // initialValues, -- Will be set in the "WizardUpload.container.js" as a classical prop (so it can be enriched from the URL)
  destroyOnUnmount: false,
  enableReinitialize: true,
};
