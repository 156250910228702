import { connect } from 'react-redux';
import { isAsyncValidating, isValid, reduxForm } from 'redux-form';

import {
  fieldArchHabitatDatapool,
  fieldCodeCapsuleHabitat,
  fieldDescription,
  fieldKernel,
  fieldName,
  fieldType,
  form,
  formName,
} from './form';
import { selectValue } from '../../../../collaborationSpace/wizards/repository-add/selectorUtils';
import { RepositoryTypeIds } from '../../../../collaborationSpace/wizards/repository-add/repository.form';
import { forkRepository } from '../../../../../redux/workbench/modules/collab.module';
import ForkRepositoryWizard from './ForkRepositoryWizard';
import qs from 'qs';
import { loadRepoMeta } from '../../../../../redux/workbench/modules/notebook.module';
import { fetchRepoDetails } from '../../../../../redux/workbench/modules/collab.repository.module';
import { fetchSingleCodeCapsule } from '../../../../../redux/modules/codeCapsules.module';
import { RepoDetails } from '../../../../../store/workbench/state.types';

export function mapStateToProps(state) {
  const archHabitatDatapool = selectValue(
    state,
    formName,
    fieldArchHabitatDatapool
  );
  const infoFilePath = qs.parse(location.search, { ignoreQueryPrefix: true })
    .path as string;
  const repoMeta = state.workbench.repoMetas[infoFilePath];
  const repoDetails: RepoDetails = state.workbench.collab.repoDetails?.data;
  const initialValues = repoDetails && {
    [fieldType]: repoDetails.repoType,
    [fieldDescription]: repoDetails.repoDescription,
    [fieldCodeCapsuleHabitat]:
      repoMeta?.repoType === RepositoryTypeIds.CODE_CAPSULE
        ? {
            code: state.codeCapsules.perCode[repoMeta?.codeCapsuleCode]?.data
              ?.habitatCode,
            name: state.codeCapsules.perCode[repoMeta?.codeCapsuleCode]?.data
              ?.habitatName,
          }
        : undefined,
    // TODO archetype fields
  };
  return {
    activePath: state.workbench.collab.activePath,
    repositoryName: selectValue(state, formName, fieldName),
    repositoryType: selectValue(state, formName, fieldType),
    repositoryDescription: selectValue(state, formName, fieldDescription),
    codeCapsuleHabitat: selectValue(state, formName, fieldCodeCapsuleHabitat),
    archKernel: selectValue(state, formName, fieldKernel),
    archHabitat: archHabitatDatapool?.habitat,
    archDatapool: archHabitatDatapool?.datapool,
    currentUserCode: state.currentUser.code,
    accountCode: state.currentUser.accountCode,
    contentInCurrentDir: state.workbench.collab.content.data,
    formValid: isValid(formName)(state),
    isAsyncValidating: isAsyncValidating(formName)(state),
    infoFilePath,
    repoMeta,
    initialValues,
    repoDetails,
  };
}

export const mapDispatchToProps = {
  forkRepository,
  loadRepoMeta,
  fetchRepoDetails,
  fetchSingleCodeCapsule,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm(form)(ForkRepositoryWizard));
