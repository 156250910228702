import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { RootState } from '../../../../../../../../../store/store';
import { RouteComponentProps } from 'react-router';
import DataSourceComponent from './DataSourceComponent';

export function mapStateToProps(
  state: RootState,
  {
    match,
  }: RouteComponentProps<{
    augurType: string;
    datapoolCode: string;
    habitatCode: string;
  }>
) {
  const moduleTypeShort = match.params.augurType;
  const datapoolCode = match.params.datapoolCode;
  const habitatCode = match.params.habitatCode;
  const dataSourceCode = state.datapoolDetails?.datapool?.dataSourceCode;
  const dataSources = state.data.dataSources;

  return {
    habitatCode,
    moduleTypeShort,
    datapoolCode,
    dataSourceCode,
    dataSources,
  };
}

const connector = connect(mapStateToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(DataSourceComponent));
