import React, { FC, useEffect, useState } from 'react';
import { SPEAKING_OPERATOR } from '../optConstraints/common';
import { SPEAKING_CONTACT_POLICY_TYPE } from './common';
import Table, { RenderColumn } from '../../../../molecules/table/Table';
import commonStyles from '../../newDatapoolWizard/optChannels/optCommonStyles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import { CampaignOptimizationDatapoolType } from 'common/dist/types/datapool';
import { ContactPolicy } from 'common/dist/types/module.optimization';
import classNames from 'classnames';
import styles from '../optConstraints/styles.module.scss';
import AddContactPolicy from './AddContactPolicy';

type Props = {
  /** Are the datapool details currently loading? */
  datapoolDetailsLoading?: boolean;
  /** Datapool details */
  datapoolDetails?: CampaignOptimizationDatapoolType;
  editMode?: boolean;
  addedNewRow?: boolean;
  rowIndex: number;
};

const ConstraintTable: FC<Props & WrappedFieldProps> = (props) => {
  const {
    input,
    datapoolDetails,
    datapoolDetailsLoading,
    meta,
    editMode,
    addedNewRow,
    rowIndex,
  } = props;

  const contactPolicies: ContactPolicy[] = input.value || [];
  const initialVisible = {
    index: addedNewRow ? contactPolicies?.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  };
  const [visible, setShow] = useState(initialVisible);

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : contactPolicies?.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex]);

  const renderColumns: RenderColumn<ContactPolicy, keyof ContactPolicy>[] = [
    {
      key: 'name',
      renderHeader: () => <span>Name</span>,
      renderCell: (name: string, constraint) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[constraint.id]?.name,
          })}
        >
          {name}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,
      renderCell: (description?: string, constraint?) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[constraint?.id]?.description,
          })}
        >
          {description}
        </span>
      ),
    },
    {
      key: 'contactPolicyType', //TODO: Check validation
      renderHeader: () => <span>Contact Policy Type</span>,
      renderCell: (
        contactPolicyType: ContactPolicy['contactPolicyType'],
        constraint
      ) => (
        <span
          className={classNames({
            [styles.erroneous]:
              !!meta.error?.[constraint.id]?.contactPolicyType,
          })}
        >
          {SPEAKING_CONTACT_POLICY_TYPE[contactPolicyType]}
        </span>
      ),
    },
    {
      key: 'operator',
      renderHeader: () => <span>Operator</span>,
      renderCell: (operator: 'leq' | 'geq' | 'eq', constraint) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[constraint.id]?.operator,
          })}
        >
          {SPEAKING_OPERATOR[operator]}
        </span>
      ),
    },
    {
      key: 'value',
      renderHeader: () => <span>Value</span>,
      renderCell: (value: string, constraint) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[constraint.id]?.value,
          })}
        >
          {value}
        </span>
      ),
    },
  ];

  const renderClickedRow = (
    element: ContactPolicy,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (editMode && visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddContactPolicy
                input={input}
                meta={meta}
                datapoolDetailsLoading={datapoolDetailsLoading}
                datapoolDetails={
                  datapoolDetails as CampaignOptimizationDatapoolType
                }
                rowIndex={rowIndex}
                constraintId={element.id}
                removableRows={visible.shown}
                onRemoveRow={(removedRow) => {
                  const updatedContactPolicy = contactPolicies.filter(
                    (con) => con.id !== removedRow.id
                  );
                  input.onChange(updatedContactPolicy);
                }}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };

  return (
    <Table
      data={contactPolicies}
      renderColumns={renderColumns}
      showHeader
      removableRows={editMode}
      verticalAlignMiddle
      onRemoveRow={(removedRow) => {
        const updatedContactPolicies = contactPolicies.filter(
          (con) => con.id !== removedRow.id
        );
        input.onChange(updatedContactPolicies);
      }}
      addlRowClassname={(rowIndex: number, contactPolicy: ContactPolicy) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!meta.error?.[contactPolicy.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
    />
  );
};

export default ConstraintTable;
