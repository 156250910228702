import React, { FC } from 'react';
import {
  FormattedDate,
  FormattedRelativeTime,
  FormattedTime,
  useIntl,
} from 'react-intl';
import { FormatNumberOptions } from 'react-intl';

export interface Props {
  date: Date;
  dateFirst?: boolean;
}

/**
 * A component that combines both Time and Date, since react-intl does not
 * > While you can abuse FormattedDate to behave like FormattedDateTime, making it easier
 * > is not something we'd be comfortable with exposing a top level Component for.
 * https://github.com/formatjs/formatjs/issues/1471
 *
 * If you want something other than a space between the two, do it yourself.
 */
export const FormattedDateTime: FC<Props> = ({ date, dateFirst }) => {
  if (dateFirst) {
    return (
      <>
        <FormattedDate value={date} /> <FormattedTime value={date} />
      </>
    );
  }
  return (
    <>
      <FormattedTime value={date} /> <FormattedDate value={date} />
    </>
  );
};

export interface PropsRelative {
  date: Date;
  reference?: Date;
  tooltip?: boolean;
}

/**
 * Let react-intl handle the conversions from seconds to hours,... although it stops at days. Automatically updates.
 * If tooltip = false it will just return a string like react-intl, otherwise it's a span
 */
export const FormattedRelativeTimeConvenient: FC<PropsRelative> = ({
  date,
  reference,
  tooltip = true,
}) => {
  const intl = useIntl();
  const referenceOrNow = reference ? reference.getTime() : Date.now();

  if (isNaN(date.getTime())) {
    // Invalid date
    return null;
  }

  const diff = (date.getTime() - referenceOrNow) / 1000;
  const relativeTime = (
    <FormattedRelativeTime
      value={diff}
      numeric={'auto'}
      updateIntervalInSeconds={1}
    />
  );
  if (tooltip) {
    // Create a tooltip in the style of FormattedDateTime
    const dateString = intl.formatDate(date);
    const timeString = intl.formatTime(date);
    return <span title={`${timeString} ${dateString}`}>{relativeTime}</span>;
  }
  return relativeTime;
};

/**
 * 'Cause why even bother with react-intl directly in that case
 * @param time
 * @param options
 * @constructor
 */
export const FormattedDateTimeDIY: FC<
  Props & { options?: Intl.DateTimeFormatOptions }
> = ({ date, options }) => {
  const intl = useIntl();
  return <>{new Intl.DateTimeFormat(intl.locale, options).format(date)}</>;
};

/**
 * Render a list of time units in a narrow format
 *
 * @param formatList [[1,{"style":"unit","unit":"minute"}],[41,{"style":"unit","unit":"second"}]]
 * Output: 1m 41s
 * @constructor
 */
export const FormattedDuration: FC<{
  formatList: [number, FormatNumberOptions][];
}> = ({ formatList }) => {
  const intl = useIntl();
  return (
    <>
      {intl.formatList(
        formatList.map(([value, options]) =>
          intl.formatNumber(value, {
            ...options,
            unitDisplay: 'narrow',
          })
        ),
        { type: 'unit', style: 'narrow' }
      )}
    </>
  );
};
