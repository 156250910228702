/** Suffix of the Repository directory name saved to Jupyter */
export const directoryExtension = '.asr';

/** Name of the meta file that is being placed inside every repository directory */
export const metaFilename = 'repository.asr';

/** the asType field of the content entry */
export const contentType = 'repository';

export const recycleBinContentType = 'recycleBin';
