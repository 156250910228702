import { connect } from 'react-redux';
import _ from 'lodash';

import NodeTooltip from './NodeTooltip';
import { getNodeType } from './treeChart/nodeParse';
import { RootState } from '../../../store/store';

const getTotalRecordCount = (path) => {
  return path && path[0] && path[0].recordCount;
};

const mapStateToProps = (state: RootState) => {
  const node = state.chart.active.tree.preview.node;
  const path = state.chart.active.tree.preview.path;

  const isShown = !_.isEmpty(node) && !_.isEmpty(path);

  return {
    node,
    totalRecordCount: getTotalRecordCount(path),
    isShown,
    type: getNodeType(node),
  };
};

export default connect(mapStateToProps)(NodeTooltip);
