import DatapoolDetails from '../../../common/_interfaces/datapool-details/DatapoolDetails';
import { DatapoolDetailsTab } from '../../../common/_interfaces/datapool-details/DatapoolDetailsTab';
import ArchetypesWrapper from '../../../common/datapool-details-tabs/archetypes/Archetypes.wrapper';
import TablesWrapper from '../../../common/datapool-details-tabs/cassandraTables/Tables.wrapper';
import SettingsWrapper from '../../../common/datapool-details-tabs/settings/Settings.wrapper';
import DatapoolSettingsCategory from '../../../common/_interfaces/datapool-details/settings/DatapoolSettingsCategory';
import NamesAndCodesCategory from '../../../common/datapool-details-tabs/settings/datapoolSettingsCategories/namesAndCodes/NamesAndCodesCategory';
import { DatapoolType } from 'common/dist/types/datapool';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';

/**
 * Datapool Details for the binary classification (event-based) augur
 */
export default class NltDatapoolDetails extends DatapoolDetails {
  tabs: (
    datapool: DatapoolType,
    dataSource: DataSource
  ) => DatapoolDetailsTab[] = (datapool, dataSource) => {
    const tabs = [];

    // --- Tables
    tabs.push(new TablesWrapper());

    // --- Archetypes
    tabs.push(new ArchetypesWrapper());

    // --- Settings
    // Specification of the sub-components of the Settings tab
    const settingsCategories: DatapoolSettingsCategory[] = [
      new NamesAndCodesCategory(),
    ];
    tabs.push(new SettingsWrapper(settingsCategories));

    return tabs;
  };
}
