import React, { FC } from 'react';
import styles from './styles.module.scss';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { LinkWithQuery } from '../../../atoms/link-with-query/LinkWithQuery';

type ResourceListEntry = {
  linkTo: string;
  columnValues: {
    [headlineId: string]: string;
  };
};

export type Props = {
  headlines: {
    /** ID of the headline (to lookup the data) */
    headlineId: string;
    label: MessageDescriptor;
  }[];
  entries: ResourceListEntry[];
  /** Specs for the column widths of the grid layout. Default: n-times 'auto' */
  gridTemplateColumns?: string;
};

const ResourceList: FC<Props> = (props: Props) => {
  const { headlines, entries, gridTemplateColumns } = props;
  const gridTemplateColumnsOrDefault =
    gridTemplateColumns || headlines.map((unused) => 'auto').join(' ');

  return (
    <div
      className={styles.resourceList}
      style={{
        gridTemplateColumns: gridTemplateColumnsOrDefault,
      }}
    >
      {headlines.map((headline, columnIndex) => (
        <div
          className={styles.resourceListHeadlineCol}
          style={{
            gridRow: 1,
            gridColumn: columnIndex + 1,
          }}
        >
          <FormattedMessage {...headline.label} />
        </div>
      ))}

      {entries.map((entry, rowIndex) => (
        <div className={styles.resourceListEntriesRow}>
          {headlines.map((headline, columnIndex) => (
            <LinkWithQuery
              data-testingIdentifier={entry.columnValues[headline.headlineId]}
              to={entry.linkTo}
              className={styles.resourceListEntriesCol}
              style={{
                gridRow: rowIndex + 2, // +1 to compensate for the "index" and +1 to compensate for the headline
                gridColumn: columnIndex + 1,
              }}
            >
              {entry.columnValues[headline.headlineId]}
            </LinkWithQuery>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ResourceList;
