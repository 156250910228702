import { connect } from 'react-redux';
import JobGroupExecution from '../../../molecules/job-groups/job-group-execution/JobGroupExecution.container';

export function mapStateToProps(state, otherProps) {
  const { jobGroupCode } = otherProps;
  return {
    jobGroup: state.queue.pre.jobGroups[jobGroupCode],
  };
}

export const mapDispatchToProps = {};

/**
 * Wraps the JobGroup for the PreQueue
 */
export default connect(mapStateToProps, mapDispatchToProps)(JobGroupExecution);
