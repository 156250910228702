import React, { FC } from 'react';
import styles from './styles.module.scss';
import commonStyles from '../../newDatapoolWizard/optChannels/optCommonStyles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { SPEAKING_OPERATOR } from '../optConstraints/common';
import { SPEAKING_CONTACT_POLICY_TYPE } from './common';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import classNames from 'classnames';
import { CampaignOptimizationDatapoolType } from 'common/dist/types/datapool';
import { EnrichedConstraint } from 'common/dist/types/module.optimization';
import { FiMinus } from 'react-icons/fi';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import { ContactPoliciesValueType } from 'common/dist/types/augurSettings.opt';

type Option = { label: string; value: string };

export type Props = {
  /** Are the datapool details currently loading? */
  datapoolDetailsLoading?: boolean;
  /** Datapool details */
  datapoolDetails?: CampaignOptimizationDatapoolType;
  rowIndex?: number;
  constraintId: string;
  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: EnrichedConstraint) => void;
};

const contactPolicyTypeOptions: Option[] = ['count'].map((c) => ({
  label: SPEAKING_CONTACT_POLICY_TYPE[c],
  value: c,
}));

const operatorOptions: Option[] = ['leq', 'geq', 'eq'].map((c) => ({
  label: SPEAKING_OPERATOR[c],
  value: c,
}));
const drvNewValue = (
  input: WrappedFieldInputProps,
  eventValue: string,
  key: string,
  rowIndex: number
) => {
  return [
    ...input.value.slice(0, rowIndex),
    {
      ...(input.value?.[rowIndex] || {}),
      [key]: eventValue,
    },
    ...input.value.slice(rowIndex + 1),
  ];
};
const renderNameField = (props: Props & WrappedFieldProps) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optContactPolicy_name'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.name}
      valid={!meta.error?.[constraintId]?.name}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Name'}
      placeholderId={'no-id'}
      placeholderDefault={'Name'}
      value={input.value?.[rowIndex]?.name}
      onChange={(e) => {
        const newValue = drvNewValue(input, e.target.value, 'name', rowIndex);

        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderDescriptionField = (props: Props & WrappedFieldProps) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optContactPolicy_description'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.description}
      valid={!meta.error?.[constraintId]?.description}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Description'}
      placeholderId={'no-id'}
      placeholderDefault={'Description'}
      value={input.value?.[rowIndex]?.description}
      onChange={(e) => {
        const newValue = [
          ...input.value.slice(0, rowIndex),
          {
            ...(input.value?.[rowIndex] || {}),

            description: e.target.value,
          },
          ...input.value.slice(rowIndex + 1),
        ];
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderContactPolicyTypeField = (props: Props & WrappedFieldProps) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <DropdownSelectInput
      id={'optContactPolicy_contactPolicyTypeField'}
      label={{
        id: 'no-id',
        defaultMessage: 'Contact Policy Type',
      }}
      placeholder={{
        id: 'no-id',
        defaultMessage: 'Please select the Contact Policy Type',
      }}
      options={contactPolicyTypeOptions}
      onFocus={input.onFocus}
      onBlur={() => input.onBlur(input.value)}
      onChange={(option: Option) => {
        const newValue: ContactPoliciesValueType = [
          ...input.value.slice(0, rowIndex),
          {
            ...(input.value?.[rowIndex] || {}),
            contactPolicyType: option.value,
          },
          ...input.value.slice(rowIndex + 1),
        ];

        input.onChange(newValue);
      }}
      value={contactPolicyTypeOptions.find(
        (o) => o.value === input.value?.[rowIndex]?.contactPolicyType
      )}
      error={meta.error?.[constraintId]?.contactPolicyType}
      touched={meta.touched}
      valid={!meta.error?.[constraintId]?.contactPolicyType}
    />
  );
};

const renderOperatorField = (props: Props & WrappedFieldProps) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <DropdownSelectInput
      id={'optContactPolicy_operator'}
      label={{
        id: 'no-id',
        defaultMessage: 'Operator',
      }}
      placeholder={{
        id: 'no-id',
        defaultMessage: 'Please select the Operator',
      }}
      options={operatorOptions}
      onFocus={input.onFocus}
      onBlur={() => input.onBlur(input.value)}
      onChange={(option: Option) => {
        const newValue: ContactPoliciesValueType = [
          ...input.value.slice(0, rowIndex),
          {
            ...(input.value?.[rowIndex] || {}),
            operator: option.value,
          },
          ...input.value.slice(rowIndex + 1),
        ];

        input.onChange(newValue);
      }}
      value={operatorOptions.find(
        (o) => o.value === input.value?.[rowIndex]?.operator
      )}
      error={meta.error?.[constraintId]?.operator}
      touched={meta.touched}
      valid={!meta.error?.[constraintId]?.operator}
    />
  );
};

const renderValueField = (props: Props & WrappedFieldProps) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optContactPolicy_value'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.value}
      valid={!meta.error?.[constraintId]?.value}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Value'}
      placeholderId={'no-id'}
      placeholderDefault={'1'}
      value={input.value?.[rowIndex]?.value}
      onChange={(e) => {
        const newValue = [
          ...input.value.slice(0, rowIndex),
          {
            ...(input.value?.[rowIndex] || {}),
            value: e.target.value,
          },
          ...input.value.slice(rowIndex + 1),
        ];
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const AddConstraint: FC<Props & WrappedFieldProps> = (props) => {
  const { input, rowIndex, removableRows, onRemoveRow } = props;

  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={commonStyles.RemoveIcon}
            onClick={() => onRemoveRow && onRemoveRow(input.value[rowIndex])}
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div className={commonStyles.addElementField}>
          {renderContactPolicyTypeField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderOperatorField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderValueField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderNameField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderDescriptionField(props)}
        </div>
      </div>
    </div>
  );
};

export default AddConstraint;
