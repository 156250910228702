import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { dataManagementRoutes } from '../../routes';
import { UPLOAD_WIZARDS } from '../upload/uploadWizards';
import Busy from '../../../atoms/busy/Busy';
import PermissionTable from '../../../organisms/permission-table/PermissionTable';
import { RouteComponentProps } from 'react-router';
import { CassandraPermissions } from '../../../../store/dataManagement/state.types';
import { CASSANDRA_KEYSPACE_SCOPES } from 'common/dist/constants/keycloak';
import {
  belongsToDatapool,
  datapoolForKeyspace,
} from 'common/dist/constants/dataManagement/cassandra';
import { Summary } from 'common/dist/types/habitat';
import Button from '../../../atoms/button/Button';

export interface Props {
  /** Fetch list of all Keyspaces */
  fetchCassandraKeyspaces: (...args: unknown[]) => unknown;
  fetchCassandraPermissions: (...args: unknown[]) => unknown;
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  data?: {
    /** Name of the Keyspace */
    name: string;
  }[];
  /** Code of the data source */
  dataSourceCode: string;
  /** Set the active upload wizard */
  setActiveUploadWizard: (...args: unknown[]) => unknown;
  fetchSummary: (...args: unknown[]) => unknown;
  summary?: Summary;
  permissions?: CassandraPermissions;
  allowedToUpload: boolean;
}

const Keyspaces: React.FC<Props & RouteComponentProps> = ({
  data,
  dataSourceCode,
  error,
  loading,
  loaded,
  setActiveUploadWizard,
  summary,
  fetchCassandraKeyspaces,
  fetchCassandraPermissions,
  fetchSummary,
  permissions,
  allowedToUpload,
}) => {
  useEffect(() => {
    fetchCassandraKeyspaces(dataSourceCode);
    fetchCassandraPermissions(dataSourceCode);
  }, [fetchCassandraPermissions, fetchCassandraKeyspaces, dataSourceCode]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  if (error) {
    return (
      <div className={'keyspaces'}>
        <span className={'error'}>{JSON.stringify(error)}</span>
      </div>
    );
  }

  if (loading) {
    return <Busy isBusy />;
  }

  return (
    <div className={'keyspaces'}>
      <div className={'keyspaces-header'}>
        <span className={'keyspaces-headline'} />{' '}
        {/* Hack to keep the upload buttons position without changing too much */}
        <div className={'headline-buttons'}>
          {allowedToUpload && (
            <Button
              buttonColor={'secondary'}
              withLink
              buttonLabelDefault={'Upload Data'}
              linkTo={`${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}/upload`}
              onClick={() =>
                setActiveUploadWizard(UPLOAD_WIZARDS.UPLOAD, undefined)
              }
            />
          )}
        </div>
      </div>

      {data && (
        <PermissionTable
          title={'Keyspace'}
          permissionColumns={CASSANDRA_KEYSPACE_SCOPES.map(
            (s) => s.displayName
          )}
          rowProps={data
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((keyspace) => {
              const permissionSet = new Set(permissions.data?.[keyspace.name]);
              return {
                title: keyspace.name,
                displayTitle:
                  belongsToDatapool(keyspace.name) &&
                  `Datapool ${datapoolForKeyspace(keyspace.name, summary)}`,
                withLink: true,
                linkTo: `${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}/keyspace/${keyspace.name}`,
                permissionList: CASSANDRA_KEYSPACE_SCOPES.map((s) =>
                  permissionSet.has(s.id)
                ),
              };
            })}
        />
      )}
      {loaded && (!data || data.length === 0) && (
        <span>There are no Keyspaces yet.</span>
      )}
    </div>
  );
};

export default withRouter(Keyspaces);
