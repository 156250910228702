import { connect, ConnectedProps } from 'react-redux';
import BackupElement from './BackupElement';
import { showRestoreBackupModal } from '../../../../store/admin/backups/actions';
import { DeprecatedRootState } from '../../../../store/state.type';

function mapStateToProps(state: DeprecatedRootState) {
  return {
    templatesMeta: state.adminBackups.templates,
  };
}

export const mapDispatchToProps = {
  showRestoreBackupModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type connectedProps = ConnectedProps<typeof connector>;
export default connector(BackupElement);
