import React, { Component } from 'react';
import { getCellByTypeMemoized } from '../notebook-cells/NotebookCellManager';
import {
  AltaSigmaCell,
  Session,
} from '../../../../../../store/workbench/state.types';

interface Props {
  content?: {
    cells?: AltaSigmaCell[];
    metadata?: {
      execution?: object;
      papermill?: object;
      tags?: string[];
    };
  };
  path?: string;
  session?: Session;
  selectedCells?: string[];
  /** Should only be missing if in "read-only" mode like for running code capsule output */
  selectCells?: (path: string, cellIds: string[]) => void;
  /** Should only be missing if in "read-only" mode like for running code capsule output */
  executeCells?: (path: string, sessionId: string, cellId: string[]) => void;
  /** Should only be missing if in "read-only" mode like for running code capsule output */
  changeSource?: (...args: unknown[]) => unknown;
  /** Should only be missing if in "read-only" mode like for running code capsule output */
  requestCodeCompletion?: (
    path: string,
    cellId: string,
    sessionId: string,
    currentRowSource: string,
    column: number,
    row: number
  ) => void;
  /** Should only be missing if in "read-only" mode like for running code capsule output */
  clearCodeCompletion?: (path: string, cellId: string) => void;
  /** Should only be missing if in "read-only" mode like for running code capsule output */
  metadata?: Record<string, unknown>;
  readOnly?: boolean;
}
/**
 * Shows the notebook content itself (=all the cells of a notebook)
 */
export default class NotebookCells extends Component<Props> {
  render() {
    const {
      content,
      path,
      session,
      selectedCells,
      executeCells,
      changeSource,
      requestCodeCompletion,
      clearCodeCompletion,
      metadata,
      readOnly = false,
    } = this.props;
    // Not the most elegant way to disable selecting cells when in readOnly, but the easiest
    const { selectCells } = readOnly ? { selectCells: () => {} } : this.props;
    return (
      <div className={'cells'}>
        {content.cells.map((cell) =>
          getCellByTypeMemoized(cell.cell_type).renderWrappedComponent({
            key: cell.id,
            cell,
            path,
            metadata,
            session,
            selectedCells,
            selectCells,
            executeCells,
            changeSource,
            requestCodeCompletion,
            clearCodeCompletion,
            allCells: content.cells,
            readOnly: readOnly,
          })
        )}
      </div>
    );
  }
}
