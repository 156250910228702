import React, { Component, MouseEvent } from 'react';

import Aim from './Aim';
import Tooltip from './Tooltip';
import { getClosestTo, getX, getY } from '../util';
import { ScaleLinear } from 'd3-scale';
import { DataType } from '../LineChart';

export type AimType = {
  x: number;
  y: number;
  closest: [number, number];
  show: boolean;
};

type Props = {
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  width: number;
  height: number;
  left: number;
  data: DataType;
  transform: string;
};

class Crosshair extends Component<Props, AimType> {
  constructor(props: Props) {
    super(props);

    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  handleMouseMove(event: MouseEvent) {
    const { left, xScale, yScale, data } = this.props;
    const x = xScale.invert(event.clientX - left);
    const closest = getClosestTo(x, data);
    this.setState({
      x: xScale(getX(closest)),
      y: yScale(getY(closest)),
      closest,
      show: true,
    });
  }

  handleMouseLeave() {
    this.setState({
      show: false,
    });
  }

  render() {
    const { xScale, yScale, data, width, height, transform } = this.props;

    const aim = !this.state
      ? { show: false, x: 0, y: 0, closest: null }
      : this.state;

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.handleMouseMove}
      >
        {aim.show && (
          <div
            style={{
              width,
              height,
              position: 'relative',
              transform,
            }}
          >
            <Aim data={data} xScale={xScale} yScale={yScale} aim={aim} />
            <Tooltip aim={aim} />
          </div>
        )}
      </div>
    );
  }
}

export default Crosshair;
