import React, { FC } from 'react';
import { scaleLinear } from 'd3-scale';

export const maxWidth = 200;
export const barHeight = 22;

export type Props = {
  importance: number;
};

const Bar: FC<Props> = ({ importance }) => {
  const xScale = scaleLinear().domain([0, 1]).range([0, maxWidth]);

  const barWidth = xScale(importance > 1.0 ? 1.0 : importance);
  const adjustedBarHeight =
    barWidth >= barHeight / 2 ? barHeight : 2 * barWidth; // if the bar is smaller than barHeight/2 the height needs to be adjusted.
  const y = barWidth >= barHeight / 2 ? 0 : barHeight / 2 - barWidth;

  return (
    <svg width={maxWidth} height={barHeight}>
      <rect
        className='horizontal-bar-chart_background-bar'
        x={0}
        y={0}
        width={maxWidth}
        height={barHeight}
        rx={barHeight / 2}
      />
      <rect
        className='horizontal-bar-chart_bar'
        x={maxWidth - barWidth}
        y={y}
        width={barWidth}
        height={adjustedBarHeight}
        rx={barHeight / 2}
      />
    </svg>
  );
};

export default Bar;
