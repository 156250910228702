import { createSelector } from 'reselect';
import React from 'react';

export const currentUser = (state) => state.currentUser;

export const isAdmin = (state) =>
  !!(
    state.currentUser &&
    !!state.currentUser.realmRoles.find(
      (role) => role.name === 'altasigma-admin'
    )
  );

export const position = (state) => state.account.user.position;

export const company = (state) => state.account.user.accountName;

export const firstName = (state) => state.account.user.firstName;

export const middleName = (state) => state.account.user.middleName;

export const lastName = (state) => state.account.user.lastName;

export const id = (state) => state.account.user.id;

export const joinedUserName = createSelector(
  firstName,
  middleName,
  lastName,
  (first, middle, last) => `${first}${middle ? ` ${middle} ` : ' '}${last}`
);
