import React, { FunctionComponent } from 'react';
import LoadableData from '../../../../molecules/loadable-data/LoadableData';
import { collaborationSpaceRoutes } from '../../../routes';
import { FormattedRelativeTimeConvenient } from '../../../../atoms/formatted-date-time/FormattedDateTime';
import Button from '../../../../atoms/button/Button';

type MergeRequestData = {
  title: string;
  user: {
    full_name: string;
  };
  created_at: string;
};

export type OwnProps = {
  id: number;
  group: string;
  repositoryName: string;
};

export type ContainerProps = {
  mergeRequest: {
    loading: boolean;
    loaded: boolean;
    error: string;
    data: MergeRequestData;
  };
};

export type Props = OwnProps & ContainerProps;

const MergeRequestDetailsHeader: FunctionComponent<Props> = (props) => {
  const LoadableDataMrInfo = LoadableData<MergeRequestData, string>();
  return (
    <div className={'repository-top'}>
      <div className={'repository-info'}>
        <div className={'repository-top-line'}>
          <LoadableDataMrInfo
            loadableData={props.mergeRequest}
            renderData={(info: MergeRequestData) => (
              <div className={'top-side-by-side-parent'}>
                <div>
                  <div>
                    <span className={'repository-name'}>{info.title}</span>
                  </div>
                  <div className={'repository-description'}>
                    <span>Opened&nbsp</span>
                    <span>
                      <FormattedRelativeTimeConvenient
                        date={new Date(info.created_at)}
                      />
                    </span>
                    <span>&nbspby&nbsp</span>
                    <span>{info.user.full_name}</span>
                  </div>
                </div>
                <Button
                  buttonColor={'primary'}
                  withLink
                  buttonLabelDefault={'Edit'}
                  linkTo={`${collaborationSpaceRoutes.basePath}/${props.group}/${props.repositoryName}/${collaborationSpaceRoutes.repositories.merge}/edit/${props.id}`}
                />
              </div>
            )}
            renderError={(error: string) => (
              <span className={'name error'}>{error}</span>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default MergeRequestDetailsHeader;
