import React, { FC } from 'react';
import { FiInfo } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { useAugur } from '../../../../../../../../../core/api/augurs';
import { AugurDetailsRouteParams } from '../../../../types';

const DatapoolNameAndCodeComponent: FC = () => {
  const { habitatCode, augurCode, moduleType } =
    useParams<AugurDetailsRouteParams>();

  // TODO: replace with useDatapool at some point
  const { data: augur } = useAugur(habitatCode, augurCode);

  let datapoolDetailsLink;
  if (augur?.habitatCode && augur?.datapoolCode && moduleType) {
    datapoolDetailsLink = `/app/habitat/${augur?.habitatCode}/datapool/${augur?.datapoolCode}/${moduleType}`;
  }

  return (
    <div>
      <p>
        {augur?.datapool?.name ?? ''}
        {'  '}
        {datapoolDetailsLink && (
          <Link to={datapoolDetailsLink}>
            <FiInfo className={'action-icon'} size={'16px'} />
          </Link>
        )}
      </p>
      <p className={'information-code'}>{augur?.datapoolCode ?? ''}</p>
    </div>
  );
};

export default DatapoolNameAndCodeComponent;
