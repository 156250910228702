import React from 'react';
import { FileTypeWrapper } from '../FileTypeWrapper';
import NotebookContent from './component/notebook-content/NotebookContent.container';
import { Notebook as NotebookT } from '../../../../store/workbench/state.types';

export const Notebook: FileTypeWrapper = {
  // @ts-ignore TODO Notebook does not extend File, but in general a file should be a supertype of the real Notebook type
  component: (file: NotebookT, paneId: string) => (
    <NotebookContent
      name={file.name}
      path={file.path}
      content={file.content}
      // @ts-ignore
      cells={file.content.cells}
      metadata={file.content.metadata}
      selectedCells={file.selectedCells}
      session={file.session}
      unsavedChanges={file.unsavedChanges}
      parentRepository={file.as_parentRepository}
      showCloseConfirm={file.showCloseConfirm}
      paneId={paneId}
    />
  ),
};
