import React, { Component } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import TextInputLine from '../../../../../../../../atoms/input-elements/text-input-line/TextInputLine';
import CancelSubmitButtonsWrapper from '../../../../../../../../molecules/reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

type Props = {
  /** value of the redux form field for the positive condition */
  positiveCondition: string;
  /** value of the redux form field for the negative condition */
  negativeCondition: string;
};

export class CandidateConditions extends Component<
  Props &
    WrappedComponentProps &
    InjectedFormProps &
    ModelSettingsProps<AugurSettingsDataNbc>
> {
  positiveCondition = (props) => (
    <TextInputLine
      {...props.input}
      {...props.meta}
      hasLabel={true}
      labelId={'details.candidateConditions.positive_candidates'}
      labelDefault={'Positive Candidates Condition'}
      placeholderId={'details.candidateConditions.positive_placeholder'}
      placeholderDefault={'Condition for positive training Candidates'}
    />
  );

  negativeCondition = (props) => (
    <TextInputLine
      {...props.input}
      {...props.meta}
      hasLabel={true}
      labelId={'details.candidateConditions.negative_candidates'}
      labelDefault={'Negative Candidates Condition'}
      placeholderId={'details.candidateConditions.negative_placeholder'}
      placeholderDefault={'Condition for negative training Candidates'}
    />
  );

  render() {
    const {
      positiveCondition,
      negativeCondition,
      reset,
      pristine,
      saveSettings,
      saving,
    } = this.props;
    return (
      <div className={'CandidateConditions'}>
        <CancelSubmitButtonsWrapper
          disabled={pristine || saving}
          savingButtonBusy={saving}
          onCancel={reset}
          onSubmit={() =>
            saveSettings(
              {
                positiveCondition,
                negativeCondition,
              },
              true
            )
          }
        >
          <div className={'CandidateConditions--field-parent'}>
            <Field
              name='positiveCondition'
              component={this.positiveCondition}
            />
            <Field
              name='negativeCondition'
              component={this.negativeCondition}
            />
          </div>
        </CancelSubmitButtonsWrapper>
      </div>
    );
  }
}

export default injectIntl(CandidateConditions);
