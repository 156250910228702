import {
  FiCheckSquare,
  FiClipboard,
  FiFilePlus,
  FiFolderPlus,
  FiPlus,
  FiRefreshCcw,
  FiSquare,
  FiUpload,
  FiXSquare,
} from 'react-icons/all';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import { DeprecatedRootState } from '../../../../store/state.type';
import { Dispatch } from 'redux-act';
import { showPasteNotebook } from '../../../../redux/workbench/modules/notebook.module';
import { IconButtonBarButton } from './IconButtonBar';
import {
  fetchContent,
  toggleHidden,
} from '../../../../redux/workbench/modules/content.module';
import { showUploadModal } from '../../../../redux/workbench/modules/upload.module';
import { FiLink2, FiPower, FiSettings, FiStopCircle } from 'react-icons/fi';
import { deleteSession } from '../../../../redux/workbench/modules/sessions.module';
import { showShutdownWorkbenchModal } from '../../../../redux/workbench/modules/connection.module';

export const AddNotebook: IconButtonBarButton = {
  Icon: FiPlus,
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.createNotebook}`,
  title: 'Add Notebook',
  id: 'wb-btn-add-notebook',
};

export const AddFile: IconButtonBarButton = {
  Icon: FiFilePlus,
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.addFile}`,
  title: 'Add File',
  id: 'wb-btn-add-file',
};

export const AddDirectory: IconButtonBarButton = {
  Icon: FiFolderPlus,
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.addDirectory}`,
  title: 'Add Directory',
  id: 'wb-btn-add-directory',
};

export const Paste: IconButtonBarButton = {
  Icon: FiClipboard,
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.pasteNotebook}`,
  onClick: (state: DeprecatedRootState, dispatch: Dispatch) => {
    // Check whether there is a notebook in the clipboard, if not: cancel.
    if (
      !state.workbench.clipboard ||
      state.workbench.clipboard.type !== 'notebook'
    ) {
      return;
    }
    dispatch(showPasteNotebook());
  },
  title: 'Paste',
  id: 'wb-btn-paste',
  disabled: (state) => state.workbench.clipboard.data === undefined,
};

export const Upload: IconButtonBarButton = {
  Icon: FiUpload,
  onClick: (state: DeprecatedRootState, dispatch: Dispatch) => {
    dispatch(showUploadModal());
  },
  title: 'Upload',
  id: 'wb-btn-upload',
};

export const Reload: IconButtonBarButton = {
  Icon: FiRefreshCcw,
  onClick: (state: DeprecatedRootState, dispatch: Dispatch) => {
    dispatch(fetchContent(state.workbench.content.selectedDirPath));
  },
  title: 'Reload Content',
  id: 'wb-btn-reload-content',
};

export const Settings: IconButtonBarButton = {
  Icon: FiSettings,
  title: 'Settings',
  id: 'wb-btn-settings',
  contextMenu: [
    {
      title: {
        id: 'no-id',
        defaultMessage: 'Show hidden files',
      },
      icon: (state: DeprecatedRootState) =>
        state.workbench.settings.showHidden ? FiCheckSquare : FiSquare,
      onClick: (dispatch: Dispatch): void => {
        dispatch(toggleHidden());
      },
    },
  ],
};

export const EmptyRecycleBin: IconButtonBarButton = {
  Icon: FiXSquare,
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.emptyRecycleBin}`,
  title: 'Empty Recycle Bin',
  id: 'wb-btn-empty-recycle-bin',
};

export const ShutdownSessions: IconButtonBarButton = {
  Icon: FiStopCircle,
  onClick: (state: DeprecatedRootState, dispatch: Dispatch) => {
    const data = state.workbench.sessions.data;
    data &&
      data.forEach((session) => {
        // @ts-ignore
        dispatch(deleteSession(session.id, session.name));
      });
  },
  title: 'Shutdown all sessions',
  id: 'wb-btn-shutdown-sessions',
};

export const ShutdownWorkbench: IconButtonBarButton = {
  Icon: FiPower,
  onClick: (state: DeprecatedRootState, dispatch: Dispatch) => {
    dispatch(showShutdownWorkbenchModal());
  },
  title: 'Shutdown workbench',
  id: 'wb-btn-shutdown-workbench',
};
