export const initial = {
  loading: false,
  loaded: true,
  error: '',
  report: undefined,
};

export const test = {
  loading: false,
  loaded: false,
  error: '',
  report: undefined,
};
