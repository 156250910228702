export const formName = 'runPredictionModal';

export const fieldTable = 'overwritePredictionTable';

export type ValueType = {
  [fieldTable]: string;
};

export const form = {
  form: formName,

  /** Needed to make sure the initial value is correct **/
  enableReinitialize: true,

  validate: (values: ValueType) => {
    const errors = {};

    const overwritePredictionTable = values[fieldTable];
    if (!overwritePredictionTable) {
      errors[fieldTable] = 'Please select a table';
    }

    return errors;
  },
};
