import React, { FC } from 'react';
import { WrappedFieldProps } from 'redux-form';
import EvaluationTableSelect from './EvaluationTableSelect';
import styles from './styles.module.scss';
import RadioButtonsInput from '../../atoms/input-elements/radio-buttons-input/RadioButtonsInput';
import AutomaticStrategy from './AutomaticStrategy';
import InfoBox from '../../atoms/info-box/InfoBox';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { DatapoolType } from 'common/dist/types/datapool';
import WithTableSample from '../../organisms/modelManagement/newAugurWizard/commonComponents/withTableSample/WithTableSample.container';

export type Props = {
  datapool: DatapoolType;
  /** Render links in the info box or only the text? */
  withLinksInInfo?: boolean;
  /** Optional portal for the dropdown select */
  dropdownSelectPortal?: HTMLElement;
  withPreviewButton?: boolean;
  previewButtonOnClick?: () => void;
};

export type ValueType = {
  trainingSplit?: number;
};

const SamplingStrategySelect: FC<
  Props &
    WrappedFieldProps &
    RouteComponentProps<{
      habitatCode: string;
      augurCode: string;
      moduleType: string;
    }>
> = (props) => {
  const {
    input,
    meta,
    match,
    withLinksInInfo,
    dropdownSelectPortal,
    datapool,
    withPreviewButton,
    previewButtonOnClick,
  } = props;

  const { value, onChange, onBlur } = input;
  const { touched, error } = meta;

  const habitatCode = match?.params?.habitatCode;
  const augurCode = match?.params?.augurCode;
  const moduleType = match?.params?.moduleType;

  // TODO bad idea to hard code this here
  const modelTuningLink = `/app/habitat/${habitatCode}/augur/${augurCode}/${moduleType}/model-settings/model-tuning`;

  return (
    <div className={styles.samplingStrategy}>
      <RadioButtonsInput
        hasLabel={true}
        labelId={'no-id'}
        labelDefault={'Source of Evaluation data'}
        // entriesPerRow={2} // To put the radio buttons in one row instead of showing them as a vertical list
        disabled={false}
        touched={touched}
        value={value.strategy}
        onChange={(strategy: string) => onChange({ ...value, strategy })}
        radioButtons={[
          {
            value: 'automatic',
            labelId: 'newAugur.steps.samplingStrategy.option.automatic',
            labelDefault: 'Sample from Training Table',
          },
          {
            value: 'table',
            labelId: 'newAugur.steps.samplingStrategy.option.table',
            labelDefault: 'Separate Tables',
          },
        ]}
      />

      {value.strategy === 'automatic' && (
        <AutomaticStrategy
          value={value}
          touched={touched}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          modelTuningLink={modelTuningLink}
          withLinksInInfo={withLinksInInfo}
        />
      )}

      {value.strategy === 'table' && (
        <div className={styles.tableStrategy}>
          <div className={styles.tableSelectContainer}>
            <WithTableSample
              datapool={datapool}
              tableName={input.value.evaluationTable}
            >
              {/* @ts-ignore */}
              <EvaluationTableSelect
                input={input}
                meta={meta}
                dropdownSelectPortal={dropdownSelectPortal}
                //{...evaluationTableSelectProps}
                withPreviewButton={withPreviewButton}
                previewButtonOnClick={previewButtonOnClick}
              />
            </WithTableSample>
          </div>

          <div className={styles.infoBox}>
            <InfoBox>
              <FormattedMessage
                id={'info_box.augur.sampling_strategy'}
                defaultMessage={
                  'The complete input table will be used for the Training job, there will be no sampling. Depending on the settings of the {linkModelTuning}, a cross validation might be applied to the training data during the Training Job.\n\n' +
                  'The whole table table <b>{evaluationTable}</b> will be ignored during training and used as the hold-out sample of the Evaluation job.'
                }
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                  linkModelTuning: withLinksInInfo ? (
                    <Link to={modelTuningLink}>Model Tuning</Link>
                  ) : (
                    'Model Tuning'
                  ),
                  evaluationTable: value.evaluationTable,
                }}
              />
            </InfoBox>
          </div>
        </div>
      )}
    </div>
  );
};

SamplingStrategySelect.defaultProps = {
  withLinksInInfo: true,
};

export default withRouter(SamplingStrategySelect);
