import * as axisBottom from '../axis/axisBottom';
import * as config from './config';

const THREE = require('three');

if (process.env.NODE_ENV !== 'test') {
  require('imports-loader?THREE=three!three/examples/js/renderers/CSS3DRenderer.js'); // eslint-disable-line
}

export function render(scene, width, height, data) {
  const div = document.createElement('div');
  div.style.pointerEvents = 'none';

  axisBottom.append(div, width, height, data);

  const object = new THREE.CSS3DObject(div);
  object.position.x = 0;
  object.position.y = 0;
  object.position.z = -config.DISTANCE_BETWEEN_LAYERS * (data.length + 1);
  object.rotation.y = Math.PI;

  scene.add(object);
}
