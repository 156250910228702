import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { err, ok } from '../../../../utils';
import { computeFileTypes } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser.utils';
import RecycleBinFileBrowser from '../../../organisms/workbench-browser/recycle-bin-file-browser/RecycleBinFileBrowser';

export default class RecycleBin extends Component {
  componentDidMount() {
    const { fetchContent } = this.props;
    fetchContent(['root', '__recycleBin'], true); // TODO No good idea to hard code this.
  }

  render() {
    const { content, width, error } = this.props;
    if (!content || content.length === 0) {
      return (
        <div className={'empty-recycle-bin-list'}>
          <div className={'empty-recycle-bin-button-bar-placeholder'} />
          <div className={'empty-recycle-bin-text-parent'}>
            <span>The Recycle Bin is empty</span>
          </div>
        </div>
      );
    }
    const selectedContent = error ? err(error) : ok(computeFileTypes(content));
    return <RecycleBinFileBrowser content={selectedContent} width={width} />;
  }
}
RecycleBin.propTypes = {
  fetchContent: PropTypes.func.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      asType: PropTypes.string.isRequired,
    })
  ),
};
