import { connect } from 'react-redux';
import DirectoryWizard from './ui/DirectoryWizard';
import {
  hideEditDirectory,
  saveEditDirectory,
} from '../../../../../redux/workbench/modules/directory.module';

import { formValueSelector } from 'redux-form';

function originalName(state) {
  return state.workbench.showEditDirectory?.name || '';
}

export const addDirectoryFormName = 'directoryWizard';
export const fieldDirectoryName = 'directoryName';

const selector = formValueSelector(addDirectoryFormName);

export function mapStateToProps(state) {
  const showEditDirectory = state.workbench.showEditDirectory;
  return {
    // Headline
    headline: 'Edit Directory',
    // Can the wizard even work or is some crucial information missing?
    redirect: showEditDirectory?.name === undefined,
    directoryName: selector(state, fieldDirectoryName),
    filledCompletely:
      !state.form.directoryWizard || !state.form.directoryWizard.syncErrors,
    // Same for Add, Edit, Paste
    isCreatingDirectory: state.workbench.isCreatingDirectory,
    initialValues: {
      directoryName: originalName(state),
    },
    currentPath: state.workbench.showEditDirectory?.path,
    openedNotebooks: Object.keys(state.workbench.notebooks || {}),
  };
}

export const mapDispatchToProps = {
  // Save button
  onSave: saveEditDirectory,
  // Cancel button
  onCancel: hideEditDirectory,
};
export default connect(mapStateToProps, mapDispatchToProps)(DirectoryWizard);
