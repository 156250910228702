import { validateAddHabitatWizard } from 'common/dist/validation/habitat';

export const formName = 'newHabitat';

export const fieldHabitatName = 'habitatName';

export const form = {
  form: formName,
  validate: validateAddHabitatWizard,
  destroyOnUnmount: true,
};
