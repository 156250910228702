import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type Props = {
  fullViewHeight: boolean;
};

function NoPermissions({ fullViewHeight }: Props) {
  return (
    <div
      className={classNames(styles.noPermissions, {
        [styles.fullViewHeight]: fullViewHeight,
      })}
    >
      <span className={styles.headline}>403</span>
      <span className={styles.title}>No permissions</span>
      <span className={styles.description}>
        You do not have permissions to view this page
      </span>
    </div>
  );
}

export default NoPermissions;
