import { DataSource } from 'common/dist/types/dataManagement/dataSource';
import { showDeleteDataSourceConfirm } from '../../../redux/modules/data.dataSources.module';
import { connect, ConnectedProps } from 'react-redux';
import { DeprecatedRootState } from '../../../store/state.type';
import DataSourceContentElement from './DataSourceContentElement';

export type OwnProps = {
  ds: DataSource;
};

export type Props = OwnProps & ConnectedProps<typeof connector>;

export function mapStateToProps(
  state: DeprecatedRootState,
  ownProps: OwnProps
) {
  return {};
}

export const mapDispatchToProps = {
  showDeleteDataSourceConfirm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(DataSourceContentElement);
