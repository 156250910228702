import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddNotebookWizard from './wizards/notebook/AddNotebookWizard.container';
import PasteNotebookWizard from './wizards/notebook/PasteNotebookWizard.container';
import EditNotebookWizard from './wizards/notebook/EditNotebookWizard.container';
import DirectoryWizard from './wizards/directory/AddDirectoryWizard.container';
import EditDirectoryWizard from './wizards/directory/EditDirectoryWizard.container';
import AddFileWizard from './wizards/file/AddFileWizard.container';
import AppArrangeModal from './app/arrange-modal/AppArrangeModal.container';
import ConfigureCodeCapsuleModal from './code-capsule/ConfigureCodeCapsuleModal.container';
import ConfigureArchetypeModal from './archetype/configure-modal/ConfigureArchetypeModal.container';
import DeleteContent from '../part-left/content/delete/DeleteContent.container';
import RepositoryInfo from './repository/info/RepositoryInfo.container';
import EditorParent from './editor/editor-parent/EditorParent.container';
import { Route, Switch } from 'react-router-dom';
import EmptyRecycleBinWizard from './wizards/emptyRecycleBin/EmptyRecycleBinWizard.container';
import { deepEquals } from 'common/dist/utils/deepEquals';
import { workbenchRoutes } from '../../../workbench/common/workbenchRoutes';
import ForkRepositoryWizard from './repository/fork/ForkRepositoryWizard.container';
import WarningOpenFileWizard from './wizards/file/WarningOpenFileWizard';
import UploadModal from '../../organisms/workbench/upload-modal/UploadModal.container';
import ShutdownWorkbenchModal from '../../organisms/workbench/shutdown-workbench-modal/ShutdownWorkbenchModal.container';

export default class Workspace extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !deepEquals(this.props, nextProps);
  }

  renderScreenElement() {
    const { showDeleteContent } = this.props;

    /** Screen-filling parts */
    return (
      <Switch>
        {/*- Create Notebook Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.createNotebook}`}
          component={AddNotebookWizard}
        />
        {/*- Create File Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.addFile}`}
          component={AddFileWizard}
        />
        {/*- Create Directory Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.addDirectory}`}
          component={DirectoryWizard}
        />
        {/*- Edit Notebook Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.editDirectory}`}
          component={EditDirectoryWizard}
        />

        {/*- Paste Notebook/File Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.pasteNotebook}`}
          component={PasteNotebookWizard}
        />
        {/*- Edit Notebook Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.editNotebook}`}
          component={EditNotebookWizard}
        />
        {/*- Warning Open File Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.warningOpenFile}`}
          component={WarningOpenFileWizard}
        />
        {/*- Repository Info -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.repositoryInfo}`}
          component={RepositoryInfo}
        />
        {/* Fork Repository Wizard */}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.repositoryFork}`}
        >
          <ForkRepositoryWizard />
        </Route>
        {/*- Delete Content Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.deleteContent}`}
        >
          <DeleteContent
            path={showDeleteContent.path}
            type={showDeleteContent.type}
            permanently={showDeleteContent.permanently}
          />
        </Route>
        {/* - Empty Recycle Bin Wizard -*/}
        <Route
          path={`${workbenchRoutes.basePath}${workbenchRoutes.emptyRecycleBin}`}
        >
          <EmptyRecycleBinWizard />
        </Route>

        {/*- Editor -*/}
        <Route path={workbenchRoutes.basePath} component={EditorParent} />
      </Switch>
    );
  }

  render() {
    return (
      <div className={'notebook-tab-content-container'}>
        {this.renderScreenElement()}
        <UploadModal />
        <AppArrangeModal />
        <ConfigureCodeCapsuleModal />
        <ConfigureArchetypeModal />
        <ShutdownWorkbenchModal />
      </div>
    );
  }
}

Workspace.propTypes = {
  /** Determines which screen is shown on the right */
  showOnRight: PropTypes.string,
  /** Does not determine which screen is displayed. Contains the information for the delete content wizard */
  showDeleteContent: PropTypes.shape({
    path: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};
