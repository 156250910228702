import { connect } from 'react-redux';
import BuildArchetypeWizard from './BuildArchetypeWizard';
import { fieldTag, formName } from './form';
import { isValid, formValueSelector } from 'redux-form';
import {
  hideArchetypeBuildConfirm,
  triggerArchetypeBuild,
} from '../../../../redux/workbench/modules/collab.module';
import { fetchRepoDetails } from '../../../../redux/workbench/modules/collab.repository.module';
import { RepoDetails } from '../../../../store/workbench/state.types';
import { LoadableData } from '../../../../utils';
import { getTag } from '../common';

export function mapStateToProps(state) {
  const { loading, loaded, error, data }: LoadableData<RepoDetails, unknown> =
    state.workbench.collab.repoDetails || {};

  const archetypeCode = data?.archetypeCode;

  const versions = state.archetypeVersions[archetypeCode]?.versions;
  const initialFieldTag = getTag(versions);

  return {
    valid: isValid(formName)(state),
    tag: formValueSelector(formName)(state, fieldTag),
    loading,
    loaded,
    error,
    data,
    versions,

    initialValues: {
      [fieldTag]: initialFieldTag,
    },
  };
}

export const mapDispatchToProps = {
  triggerArchetypeBuild,
  fetchRepoDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildArchetypeWizard);
