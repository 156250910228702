import PropTypes from 'prop-types';
import React from 'react';

function Tooltip({
  tooltipX,
  tooltipY,
  top,
  left,
  containerHeight,
  tooltipData,
  isTooltip,
}) {
  if (!isTooltip) {
    return null;
  }

  const topPadding = 10;
  const height = 30;

  const translateX = `${tooltipX - left}`;
  const translateY = `${
    tooltipY - top - containerHeight - height - topPadding
  }`;
  const translate = `translate(${translateX}px, ${translateY}px) translate(-50%, 0)`;
  const data = `${tooltipData[0]}, ${tooltipData[1]}`;

  return (
    <div
      className='predictedValues-tooltip'
      style={{ transform: `${translate}` }}
    >
      {data}
    </div>
  );
}

Tooltip.propTypes = {
  isTooltip: PropTypes.bool.isRequired,
  tooltipX: PropTypes.number.isRequired,
  tooltipY: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  containerHeight: PropTypes.number.isRequired,
  tooltipData: PropTypes.any.isRequired,
};

export default Tooltip;
