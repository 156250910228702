export const formName = 'mergeRequest';
export const fieldSrcBranch = 'sourceBranch';
export const fieldTargetBranch = 'targetBranch';
export const fieldTitle = 'title';
export const fieldDescription = 'description';

export const form = {
  form: formName,
  validate: (values, props) => {
    const { branches } = props;
    return {
      [fieldTargetBranch]: validateBranch(values[fieldTargetBranch], branches),
      [fieldSrcBranch]: validateBranch(values[fieldSrcBranch], branches),
      [fieldTitle]: validateTitle(values[fieldTitle]),
      [fieldDescription]: validateDescription(values[fieldDescription]),
    };
  },
  enableReinitialize: true,
  touchOnBlur: true,
  touchOnChange: true,
};

function validateBranch(branchName, branches) {
  if (branches && branches.map((b) => b.name).includes(branchName))
    return undefined;

  return 'Please choose a valid branch.';
}

function validateTitle(title) {
  if (title && title !== '') return undefined;
  else return 'Please enter a name.';
}

function validateDescription(description) {
  if (description && description !== '') return undefined;
  else return 'Please enter a description.';
}
