import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as Api from '../../core/api';

export const loadLoginHistory = createAction("load user's login history");

export const loadLoginHistorySuccess = createAction(
  "load user's login history - success",
  (history) => history
);

export const loadLoginHistoryFail = createAction(
  "load user's login history - fail",
  (error) => error
);

export const reducer = {
  [loadLoginHistory]: (state) => ({
    ...state,
    account: {
      ...state.account,
      loginHistory: {
        ...state.account.loginHistory,
        loading: true,
        loaded: false,
      },
    },
  }),

  [loadLoginHistorySuccess]: (state, history) => ({
    ...state,
    account: {
      ...state.account,
      loginHistory: {
        ...state.account.loginHistory,
        loading: false,
        loaded: true,
        history,
      },
    },
  }),

  [loadLoginHistoryFail]: (state, error) => ({
    ...state,
    account: {
      ...state.account,
      loginHistory: {
        ...state.account.loginHistory,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* loadLoginHistorySaga() {
  const { response, error } = yield call(Api.accounts.getLoginHistory);

  if (response) {
    yield put(loadLoginHistorySuccess(response));
  } else {
    yield put(loadLoginHistoryFail(error));
  }
}

export function* watchLoadLoginHistory() {
  yield takeEvery(loadLoginHistory.getType(), loadLoginHistorySaga);
}
