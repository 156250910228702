import React, {
  Component,
  ComponentType,
  MouseEvent,
  ReactElement,
} from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export type Props = {
  /** The icon to render */
  Icon: ComponentType<{ size?: number }>;
  /** On click listener */
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  linkTo?: string;
  disabled?: boolean;
  /** Title text to display on hover */
  title?: string;
  size?: number;
  /** ID for the button (especially for selecting the buttons in the e2e tests) */
  id?: string;
  /** Additional classes to add to the button */
  additionalClassName?: string;
  rounded?: boolean;
};

function wrapWithLink(element: ReactElement, linkTo: string): ReactElement {
  return (
    <Link className={'ib-link'} to={linkTo}>
      {element}
    </Link>
  );
}

export default class IconButton extends Component<Props> {
  render() {
    const {
      Icon,
      onClick,
      disabled,
      title,
      id,
      linkTo,
      additionalClassName,
      rounded = false,
    } = this.props;
    let res = (
      <div
        className={classNames(
          'IconButton',
          rounded ? 'rounded' : 'squared',
          additionalClassName,
          {
            disabled: disabled,
          }
        )}
        title={title}
        onClick={(e) => {
          if (!disabled && onClick) {
            onClick(e);
          }
        }}
        id={id}
      >
        <Icon size={this.props.size} />
      </div>
    );
    if (!disabled && linkTo) res = wrapWithLink(res, linkTo);
    return res;
  }
}
