/*
 * This module contains the redux actions / sagas to interact with the Jupyter Notebook container.
 * This happens by:
 * 1. Creating a new Python Console Session
 * 2. Sending the Python command to execute a specific Python file / function that's located at
 * /container-interactions/*.py in the Notebok
 */

import { createAction } from 'redux-act';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { notebookUser } from '../selectors/notebookUser.selector';
import { fetchContent } from './content.module';
import {
  hideCreateBranchModal,
  hideDeleteBranchModal,
  hideFetchBranchModal,
  hideForceDeleteConfirm,
  hideGitCommitModal,
  showForceDeleteConfirm,
} from './repository.module';
import NotebookApi, {
  CommitFilter,
} from '../../../core/api/workbench/git.notebook';
import { fetchBranches } from './collab.repository.module';
import { ensureNotebookIsClosed, fetchNotebookUpdate } from './notebook.module';
import { pathToArray } from '../../../workbench/contentPaths';
import { getPaneNotebooksNoUnsaved } from '../selectors/notebook.selectors';
import { sendNotification } from '../../modules/notifications.module';
import { error as errorType } from '../../../core/notifications';
import { SingleCommandSession } from './singleCommandSession';

/**
 * Takes the path of the repository (to look it up in the Notebook at /workbench/<path> and a
 * @type {ComplexActionCreator2<unknown, unknown, {repositoryPath: unknown, commitMessage: unknown}, {}>}
 */
export const gitAddAllAndCommit = createAction(
  'git add all and commit',
  (repositoryPath, commitMessage, activeBranch) => ({
    repositoryPath,
    commitMessage,
    activeBranch,
  })
);

export const getGitRemote = createAction('get remote url', (repoPath) => ({
  repoPath,
}));

export const getGitRemoteSuccess = createAction(
  'get remote url - success',
  (remote) => ({ remote })
);

export const gitConfig = createAction(
  'git config',
  (gitDisplayName, gitMail) => ({ gitDisplayName, gitMail })
);

export const gitDeleteRemoteBranch = createAction(
  'git delete remote branch',
  (repositoryPath, branchName, group, repository) => ({
    repositoryPath,
    branchName,
    group,
    repository,
  })
);

export const gitDeleteBranch = createAction(
  'git delete branch',
  (repositoryPath, branchName) => ({ repositoryPath, branchName })
);

export const gitDeleteBranchSuccess = createAction(
  'git delete branch - success'
);

export const gitForceDeleteBranch = createAction(
  'git force delete branch',
  (repositoryPath, branchName) => ({ repositoryPath, branchName })
);

export const gitForceDeleteBranchSuccess = createAction(
  'git force delete branch - success'
);

export const gitFetchAndTrackRemoteBranch = createAction(
  'git fetch and track remote branch',
  (repositoryPath, branchName) => ({ repositoryPath, branchName })
);

export const gitFetchAndTrackRemoteBranchSuccess = createAction(
  'git fetch and track remote branch - success',
  (branchName) => ({ branchName })
);

export const gitCreateBranch = createAction(
  'git create new branch',
  (repositoryPath, branchName, group, repository) => ({
    repositoryPath,
    branchName,
    group,
    repository,
  })
);

export const gitCreateBranchSuccess = createAction(
  'git create new branch',
  (branchName) => branchName
);

export const gitSwitchBranch = createAction(
  'git switch branch',
  (repositoryPath, branchName) => ({ repositoryPath, branchName })
);

export const gitSwitchBranchSuccess = createAction(
  'git switch branch - success',
  (branchName) => branchName
);

export const gitListBranches = createAction(
  'git list branches',
  (repositoryPath, showNotifications) => ({ repositoryPath, showNotifications })
);

export const gitListBranchesSuccess = createAction(
  'git list branches - success'
);

export const gitListBranchesFailure = createAction(
  'git list branches - failure',
  (error) => error
);

export const gitListBranchesReceived = createAction(
  'git list branches - received',
  (branches) => ({ branches })
);

export const gitActiveBranch = createAction(
  'git active branch',
  (repositoryPath, showNotifications) => ({ repositoryPath, showNotifications })
);

export const gitActiveBranchReceived = createAction(
  'git active branch - received',
  (activeBranch) => ({ activeBranch })
);

export const gitPush = createAction(
  'git push',
  (repositoryPath, activeBranch) => ({ repositoryPath, activeBranch })
);

export const gitPull = createAction(
  'git pull',
  (repositoryPath, activeBranch, force) => ({
    repositoryPath,
    activeBranch,
    force,
  })
);

export const cloneGitRepository = createAction(
  'clone git repository',
  (
    notebookPath,
    repoName,
    repoFullPath,
    gitDisplayName,
    gitMail,
    metaFileContent,
    branch
  ) => ({
    notebookPath,
    repoName,
    repoFullPath,
    gitDisplayName,
    gitMail,
    metaFileContent,
    branch,
  })
);

export const gitFileStatus = createAction(
  'git file status',
  (repositoryPath) => ({ repositoryPath })
);

export const gitFileStatusReceived = createAction(
  'git file status - received',
  (repositoryPath, fileStatus) => ({ repositoryPath, fileStatus })
);

export const gitFileStatusSuccess = createAction('git file status - success');

export const gitFileStatusFailure = createAction(
  'git file status - failure',
  (error) => error
);

export const gitListCommits = createAction(
  'git list commits',
  (repositoryPath, branch, maxCount, skip) => ({
    repositoryPath,
    branch,
    maxCount,
    skip,
  })
);

export const gitListCommitsReceived = createAction(
  'git list commits - received',
  (repositoryPath, branch, commits) => ({ repositoryPath, branch, commits })
);

export const gitListCommitsSuccess = createAction('git list commits - success');

export const gitListCommitsFailure = createAction(
  'git list commits - failure',
  (error) => error
);

export const gitListNotPushedCommits = createAction(
  'git list not pushed commits',
  (repositoryPath, branch) => ({ repositoryPath, branch })
);

export const gitListNotPushedCommitsReceived = createAction(
  'git list not pushed commits - received',
  (repositoryPath, branch, commits) => ({ repositoryPath, branch, commits })
);

export const gitListNotPushedCommitsSuccess = createAction(
  'git list not pushed commits - success'
);

export const gitListNotPushedCommitsFailure = createAction(
  'git list not pushed commits - failure',
  (error) => error
);

export const gitListNotPulledCommits = createAction(
  'git list not pulled commits',
  (repositoryPath, branch) => ({ repositoryPath, branch })
);

export const gitListNotPulledCommitsReceived = createAction(
  'git list not pulled commits - received',
  (repositoryPath, branch, commits) => ({ repositoryPath, branch, commits })
);

export const gitListNotPulledCommitsSuccess = createAction(
  'git list not pulled commits - success'
);

export const gitListNotPulledCommitsFailure = createAction(
  'git list not pulled commits - failure',
  (error) => error
);

export const deleteContent = createAction(
  'container interactions - delete content',
  (path, selectedDirPath, permanently = false) => ({
    path,
    selectedDirPath,
    permanently,
  })
);

export const loadArchetypeExample = createAction(
  'load archetype example',
  (path, exampleTar) => ({ path, exampleTar })
);

export const loadArchetypeExampleSuccess = createAction(
  'load archetype example - success',
  (path) => ({ path })
);

export const loadArchetypeExampleFail = createAction(
  'load archetype example - fail',
  (error) => ({ error })
);

export const showPushConflictsModal = createAction(
  'show push conflicts modal',
  (branch, commitsBehind) => ({ branch, commitsBehind })
);

export const hidePushConflictsModal = createAction('hide push conflicts modal');

export const showPullConflictsModal = createAction(
  'show pull conflicts modal',
  (error, branch, conflicts) => ({ error, branch, conflicts })
);

export const hidePullConflictsModal = createAction('hide pull conflicts modal');

/**
 * Runs "mkdir -p $targetDirPath" in the Notebook Container.
 * Ensures that all required directories are available (used for example before uploading files into a specific directory)
 */
export const ensureDirectoryExists = createAction(
  'container interactions - ensure directory path',
  (targetDirPath, parentType, appVersionCode) => ({
    targetDirPath,
    parentType,
    appVersionCode,
  })
);

export const checkGitRefFormat = createAction(
  'check git ref format for validity',
  (ref, callbacks) => ({ ref, callbacks })
);

export const reducer = {
  [getGitRemoteSuccess]: (state, remote) => {
    return {
      ...state,
      showRepositoryInfo: {
        ...state.showRepositoryInfo,
        remote: new URL(remote.remote),
      },
    };
  },
  [gitCreateBranchSuccess]: (state, branchName) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      activeBranch: branchName,
    },
  }),
  [gitSwitchBranchSuccess]: (state, branchName) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      activeBranch: branchName,
    },
  }),
  [gitFetchAndTrackRemoteBranchSuccess]: (state, { branchName }) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      activeBranch: branchName,
    },
  }),
  [gitListBranches]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitBranches: {
        ...state.showRepositoryInfo.gitBranches,
        loading: true,
        loaded: false,
        data: [],
        error: undefined, // Just to make it explicit
      },
    },
  }),
  [gitListBranchesSuccess]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitBranches: {
        ...state.showRepositoryInfo.gitBranches,
        loading: false,
        error: undefined, // Just to make it explicit
      },
    },
  }),
  [gitListBranchesFailure]: (state, err) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitBranches: {
        ...state.showRepositoryInfo.gitBranches,
        loading: false,
        loaded: true,
        data: [],
        error: err,
      },
    },
  }),
  [gitListBranchesReceived](state, { branches }) {
    return {
      ...state,
      showRepositoryInfo: {
        ...state.showRepositoryInfo,
        gitBranches: {
          ...state.showRepositoryInfo.gitBranches,
          loading: false,
          loaded: true,
          data: branches.map((branch) => ({
            label: branch.name,
            value: branch.name,
          })),
          error: undefined, // Just to make it explicit
        },
      },
    };
  },
  [gitFileStatusReceived](state, { repositoryPath, fileStatus }) {
    return {
      ...state,
      showRepositoryInfo: {
        ...state.showRepositoryInfo,
        gitFileStatus: {
          ...state.showRepositoryInfo.gitFileStatus,
          data: fileStatus,
          loaded: true,
        },
      },
    };
  },
  [gitActiveBranchReceived](state, { activeBranch }) {
    return {
      ...state,
      showRepositoryInfo: {
        ...state.showRepositoryInfo,
        activeBranch: activeBranch.name,
      },
    };
  },
  [gitFileStatus]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitFileStatus: {
        ...state.showRepositoryInfo.gitFileStatus,
        loading: true,
      },
    },
  }),
  [gitFileStatusSuccess]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitFileStatus: {
        ...state.showRepositoryInfo.gitFileStatus,
        loading: false,
        error: undefined,
      },
    },
  }),
  [gitFileStatusFailure]: (state, error) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitFileStatus: {
        ...state.showRepositoryInfo.gitFileStatus,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [gitListCommitsReceived](state, { repositoryPath, branch, commits }) {
    return {
      ...state,
      showRepositoryInfo: {
        ...state.showRepositoryInfo,
        gitCommits: {
          ...state.showRepositoryInfo.gitCommits,
          data: commits,
          loaded: true,
        },
      },
    };
  },
  [gitListCommits]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitCommits: {
        ...state.showRepositoryInfo.gitCommits,
        loading: true,
      },
    },
  }),
  [gitListCommitsSuccess]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitCommits: {
        ...state.showRepositoryInfo.gitCommits,
        loading: false,
        error: undefined,
      },
    },
  }),
  [gitListCommitsFailure]: (state, error) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitCommits: {
        ...state.showRepositoryInfo.gitCommits,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [gitListNotPushedCommitsReceived](
    state,
    { repositoryPath, branch, commits }
  ) {
    return {
      ...state,
      showRepositoryInfo: {
        ...state.showRepositoryInfo,
        gitNotPushedCommits: {
          ...state.showRepositoryInfo.gitNotPushedCommits,
          data: commits,
          loaded: true,
        },
      },
    };
  },
  [gitListNotPushedCommits]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitNotPushedCommits: {
        ...state.showRepositoryInfo.gitNotPushedCommits,
        loading: true,
      },
    },
  }),
  [gitListNotPushedCommitsSuccess]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitNotPushedCommits: {
        ...state.showRepositoryInfo.gitNotPushedCommits,
        loading: false,
        error: undefined,
      },
    },
  }),
  [gitListNotPushedCommitsFailure]: (state, error) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitNotPushedCommits: {
        ...state.showRepositoryInfo.gitNotPushedCommits,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [gitListNotPulledCommitsReceived](
    state,
    { repositoryPath, branch, commits }
  ) {
    return {
      ...state,
      showRepositoryInfo: {
        ...state.showRepositoryInfo,
        gitNotPulledCommits: {
          ...state.showRepositoryInfo.gitNotPulledCommits,
          data: commits,
          loaded: true,
        },
      },
    };
  },
  [gitListNotPulledCommits]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitNotPulledCommits: {
        ...state.showRepositoryInfo.gitNotPulledCommits,
        loading: true,
      },
    },
  }),
  [gitListNotPulledCommitsSuccess]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitNotPulledCommits: {
        ...state.showRepositoryInfo.gitNotPulledCommits,
        loading: false,
        error: undefined,
      },
    },
  }),
  [gitListNotPulledCommitsFailure]: (state, error) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      gitNotPulledCommits: {
        ...state.showRepositoryInfo.gitNotPulledCommits,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [showPushConflictsModal]: (state, { branch, commitsBehind }) => ({
    ...state,
    pushConflictsModal: {
      show: true,
      branch,
      commitsBehind,
    },
  }),
  [hidePushConflictsModal]: (state) => ({
    ...state,
    pushConflictsModal: {
      show: false,
    },
  }),
  [showPullConflictsModal]: (state, { error, branch, conflicts }) => ({
    ...state,
    pullConflictsModal: {
      show: true,
      error,
      branch,
      conflicts,
    },
  }),
  [hidePullConflictsModal]: (state) => ({
    ...state,
    pullConflictsModal: {
      show: false,
    },
  }),
};

export function* getGitRemoteSaga({ payload: { repoPath } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.getRemote],
    repoPath
  );
  if (response) {
    yield put(getGitRemoteSuccess(response.trim()));
    const remote = new URL(response.trim());
    const parts = remote.pathname.substr(1).split('/');
    const group = parts[0];
    const repoName = parts.slice(1).join('/').replace('.git', '');
    yield put(fetchBranches(group, repoName));
  }
}

export function* watchGetGitRemote() {
  yield takeEvery(getGitRemote.getType(), getGitRemoteSaga);
}

export function* gitConfigSaga({ payload: { gitDisplayName, gitMail } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.config],
    gitDisplayName,
    gitMail
  );
  if (response) {
    //Nothing to do here
  } else {
    yield put(
      sendNotification(
        'Git config failed to change',
        JSON.stringify(error),
        errorType
      )
    );
  }
}

export function* watchGitConfig() {
  yield takeEvery(gitConfig.getType(), gitConfigSaga);
}

export function* cloneGitRepositorySaga({
  payload: {
    notebookPath,
    repoName,
    repoFullPath,
    gitDisplayName,
    gitMail,
    metaFileContent,
    branch,
  },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.clone],
    notebookPath,
    repoName,
    repoFullPath,
    gitDisplayName,
    gitMail,
    metaFileContent,
    branch
  );
  if (response) {
    yield put(fetchContent(pathToArray(notebookPath)));
  } else {
    yield put(
      sendNotification(
        'Cloning repository failed',
        JSON.stringify(error),
        errorType
      )
    );
  }
}

export function* watchCloneGitRepository() {
  yield takeEvery(cloneGitRepository.getType(), cloneGitRepositorySaga);
}

export function* gitFileStatusSaga({ payload: { repositoryPath } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.status],
    repositoryPath
  );
  if (response) {
    yield put(gitFileStatusSuccess());
    yield put(gitFileStatusReceived(repositoryPath, response));
  } else {
    yield put(gitFileStatusFailure(error));
    //TODO: should probably the user somehow...
  }
}

export function* watchGitFileStatus() {
  yield takeEvery(gitFileStatus.getType(), gitFileStatusSaga);
}

export function* gitListCommitsSaga({
  payload: { repositoryPath, branch, maxCount, skip },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.listCommits],
    repositoryPath,
    branch,
    maxCount,
    skip,
    CommitFilter.None
  );
  if (response) {
    yield put(gitListCommitsSuccess());
    yield put(gitListCommitsReceived(repositoryPath, branch, response));
  } else {
    yield put(gitListCommitsFailure(error));
  }
}

export function* watchGitListCommits() {
  yield takeEvery(gitListCommits.getType(), gitListCommitsSaga);
}

export function* gitListNotPushedCommitsSaga({
  payload: { repositoryPath, branch, maxCount, skip },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.listCommits],
    repositoryPath,
    branch,
    maxCount,
    skip,
    CommitFilter.NotPushed
  );
  if (response) {
    yield put(gitListNotPushedCommitsSuccess());
    yield put(
      gitListNotPushedCommitsReceived(repositoryPath, branch, response)
    );
  } else {
    yield put(gitListNotPushedCommitsFailure(error));
  }
}

export function* watchGitListNotPushedCommits() {
  yield takeEvery(
    gitListNotPushedCommits.getType(),
    gitListNotPushedCommitsSaga
  );
}

export function* gitListNotPulledCommitsSaga({
  payload: { repositoryPath, branch, maxCount, skip },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.listCommits],
    repositoryPath,
    branch,
    maxCount,
    skip,
    CommitFilter.NotPulled
  );
  if (response) {
    yield put(gitListNotPulledCommitsSuccess());
    yield put(
      gitListNotPulledCommitsReceived(repositoryPath, branch, response)
    );
  } else {
    yield put(gitListNotPulledCommitsFailure(error));
  }
}

export function* watchGitListNotPulledCommits() {
  yield takeEvery(
    gitListNotPulledCommits.getType(),
    gitListNotPulledCommitsSaga
  );
}

export function* gitDeleteRemoteBranchSaga({
  payload: { repositoryPath, branchName, group, repository },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.deleteBranch],
    repositoryPath,
    branchName,
    false,
    true
  );
  if (response) {
    yield put(fetchBranches(group, repository));
  } else {
    yield put(
      sendNotification(
        'Delete remote branch failed',
        JSON.stringify(error),
        errorType
      )
    );
  }
}

export function* watchGitDeleteRemoteBranch() {
  yield takeEvery(gitDeleteRemoteBranch.getType(), gitDeleteRemoteBranchSaga);
}

export function* gitDeleteBranchSaga({
  payload: { repositoryPath, branchName },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.deleteBranch],
    repositoryPath,
    branchName,
    false,
    false
  );
  if (response) {
    yield put(gitFileStatus(repositoryPath));
    yield put(gitListCommits(repositoryPath, 'master', 20, 0));
    yield put(gitListNotPushedCommits(repositoryPath, 'master'));
    yield put(gitListBranches(repositoryPath));
    yield put(gitActiveBranch(repositoryPath));
    yield put(hideDeleteBranchModal());
  } else {
    yield put(showForceDeleteConfirm());
  }
}

export function* watchGitDeleteBranch() {
  yield takeEvery(gitDeleteBranch.getType(), gitDeleteBranchSaga);
}

export function* gitForceDeleteBranchSaga({
  payload: { repositoryPath, branchName },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.deleteBranch],
    repositoryPath,
    branchName,
    true,
    false
  );
  if (response) {
    yield put(gitFileStatus(repositoryPath));
    yield put(gitListCommits(repositoryPath, 'master', 20, 0));
    yield put(gitListNotPushedCommits(repositoryPath, 'master'));
    yield put(gitListBranches(repositoryPath));
    yield put(gitActiveBranch(repositoryPath));
    yield put(hideDeleteBranchModal());
  } else {
    yield put(
      sendNotification(
        'Force delete branch failed',
        JSON.stringify(error),
        errorType
      )
    );
  }
}

export function* watchGitForceDeleteBranch() {
  yield takeEvery(gitForceDeleteBranch.getType(), gitForceDeleteBranchSaga);
}

export function* gitFetchAndTrackRemoteBranchSaga({
  payload: { repositoryPath, branchName },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error, status } = yield call(
    [notebookApi, notebookApi.fetchAndTrackRemoteBranch],
    repositoryPath,
    branchName
  );
  const localBranchName = branchName.split('/').slice(1).join('/');
  if (response) {
    yield put(gitFileStatus(repositoryPath));
    yield put(gitListBranches(repositoryPath));
    yield put(gitListCommits(repositoryPath, localBranchName, 20, 0));
    yield put(gitListNotPushedCommits(repositoryPath, localBranchName));
    yield put(gitListNotPulledCommits(repositoryPath, localBranchName));
    yield put(gitFetchAndTrackRemoteBranchSuccess(localBranchName));
    yield put(hideFetchBranchModal());
  } else {
    const branches = yield select(
      (state) => state.workbench.showRepositoryInfo.gitBranches?.data || []
    );
    // Expected failure may be that a local branch already exists - if it does, switch to it
    if (
      status === 400 &&
      branches.map((o) => o.value).includes(localBranchName)
    ) {
      yield put(gitSwitchBranch(repositoryPath, localBranchName));
    } else {
      yield put(
        sendNotification(
          'Fetching and tracking remote failed',
          JSON.stringify(error),
          errorType
        )
      );
    }
    yield put(hideFetchBranchModal());
  }
}

export function* watchGitFetchAndTrackRemoteBranch() {
  yield takeEvery(
    gitFetchAndTrackRemoteBranch.getType(),
    gitFetchAndTrackRemoteBranchSaga
  );
}

export function* gitCreateBranchSaga({
  payload: { repositoryPath, branchName, group, repository },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.createBranch],
    repositoryPath,
    branchName
  );
  if (response) {
    yield put(gitFileStatus(repositoryPath));
    yield put(gitListCommits(repositoryPath, 'master', 20, 0));
    yield put(gitListNotPushedCommits(repositoryPath, 'master'));
    yield put(gitListBranches(repositoryPath));
    yield put(fetchBranches(group, repository));
    yield put(gitActiveBranch(repositoryPath));
    yield put(hideCreateBranchModal());
    yield put(hideForceDeleteConfirm());
  } else {
    yield put(gitForceDeleteBranch(repositoryPath, branchName));
    yield put(hideCreateBranchModal());
  }
}

export function* watchGitCreateBranch() {
  yield takeEvery(gitCreateBranch.getType(), gitCreateBranchSaga);
}

export function* gitSwitchBranchSaga({
  payload: { repositoryPath, branchName },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.switchBranch],
    repositoryPath,
    branchName
  );
  if (response) {
    yield put(gitFileStatus(repositoryPath));
    yield put(gitListCommits(repositoryPath, branchName, 20, 0));
    yield put(gitListNotPushedCommits(repositoryPath, branchName));
    yield put(gitListNotPulledCommits(repositoryPath, branchName));
    yield put(gitSwitchBranchSuccess(branchName));
    yield put(hideForceDeleteConfirm());
  }
}

export function* watchGitSwitchBranch() {
  yield takeEvery(gitSwitchBranch.getType(), gitSwitchBranchSaga);
}

export function* gitListBranchesSaga({ payload: { repositoryPath } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.getBranches],
    repositoryPath
  );
  if (response) {
    yield put(gitListBranchesSuccess());
    yield put(gitListBranchesReceived(response));
  } else {
    yield put(gitListBranchesFailure(error));
  }
}

export function* watchGitListBranches() {
  yield takeEvery(gitListBranches.getType(), gitListBranchesSaga);
}

export function* gitActiveBranchSaga({ payload: { repositoryPath } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.getCurrentBranch],
    repositoryPath
  );
  if (response) {
    yield put(gitFileStatus(repositoryPath));
    yield put(gitListCommits(repositoryPath, response.name, 20, 0));
    yield put(gitListNotPushedCommits(repositoryPath, response.name));
    yield put(gitListNotPulledCommits(repositoryPath, response.name));
    yield put(gitActiveBranchReceived(response));
  }
}

export function* watchGitActiveBranch() {
  yield takeEvery(gitActiveBranch.getType(), gitActiveBranchSaga);
}

export function* gitAddAllAndCommitSaga({
  payload: { repositoryPath, commitMessage, activeBranch },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.addAllAndCommit],
    repositoryPath,
    commitMessage,
    activeBranch
  );
  if (response) {
    yield put(gitFileStatus(repositoryPath));
    yield put(gitListCommits(repositoryPath, activeBranch, 20, 0));
    yield put(gitListNotPushedCommits(repositoryPath, activeBranch));
    yield put(hideGitCommitModal());
  } else {
    yield put(hideGitCommitModal());
  }
}

export function* watchGitAddAllAndCommit() {
  yield takeEvery(gitAddAllAndCommit.getType(), gitAddAllAndCommitSaga);
}

export function* gitPushSaga({ payload: { repositoryPath, activeBranch } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error, status } = yield call(
    [notebookApi, notebookApi.push],
    repositoryPath,
    activeBranch
  );
  if (response) {
    yield put(gitListNotPushedCommits(repositoryPath, activeBranch));
  } else if (status === 409) {
    const { branch, commits_behind } = JSON.parse(error);
    yield put(showPushConflictsModal(branch, commits_behind));
    yield put(gitListNotPulledCommits(repositoryPath, branch));
  } else {
    yield put(
      sendNotification('Push failed', JSON.stringify(error), errorType)
    );
  }
}

export function* watchGitPush() {
  yield takeEvery(gitPush.getType(), gitPushSaga);
}

export function* gitPullSaga({
  payload: { repositoryPath, activeBranch, force },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error, status } = yield call(
    [notebookApi, notebookApi.pull],
    repositoryPath,
    activeBranch,
    force
  );
  if (response) {
    // Refresh repository info
    yield put(gitListNotPulledCommits(repositoryPath, activeBranch));
    yield put(gitListCommits(repositoryPath, activeBranch, 20, 0));
    yield put(gitFileStatus(repositoryPath));
    // Refresh sidebar
    const selectedDirPath = yield select(
      (state) => state.workbench.content.selectedDirPath
    );
    yield put(fetchContent(selectedDirPath));
    // Refresh all open notebooks with no unsaved changes, belonging to this repositoryPath, because their content may have changed
    const nbsNoUnsaved = yield select((state) =>
      getPaneNotebooksNoUnsaved(state)
    );
    for (const [paneId, notebooks] of Object.entries(nbsNoUnsaved)) {
      for (const notebookPath of notebooks) {
        if (notebookPath.startsWith(repositoryPath)) {
          yield put(fetchNotebookUpdate(notebookPath, paneId));
        }
      }
    }
    // Force currently uses reset, so the local commits will be removed and only the file changes kept
    if (force) {
      yield put(gitListNotPushedCommits(repositoryPath, activeBranch));
    }
  } else if (status === 409) {
    const { error: appError, branch, conflicts } = JSON.parse(error);
    yield put(showPullConflictsModal(appError, branch, conflicts));
  } else {
    yield put(
      sendNotification('Pull failed', JSON.stringify(error), errorType)
    );
  }
}

export function* watchGitPull() {
  yield takeEvery(gitPull.getType(), gitPullSaga);
}

export function* deleteContentSaga({
  payload: { path, selectedDirPath, permanently },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.deleteContent],
    path,
    permanently
  );
  if (response) {
    if (!permanently) {
      yield put(fetchContent(selectedDirPath));
      yield put(ensureNotebookIsClosed(path));
    } else {
      yield put(fetchContent(['root', '__recycleBin'])); // TODO No good idea to hard code this.
    }
  } else {
    yield put(fetchContent(selectedDirPath)); // Fetch anyway
  }
}

export function* watchDeleteContent() {
  yield takeEvery(deleteContent.getType(), deleteContentSaga);
}

export function* loadArchetypeExampleSaga({ payload: { path, exampleTar } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.fetchArchetypeExample],
    path,
    exampleTar
  );
  if (response) {
    yield put(loadArchetypeExampleSuccess(path));
    yield put(fetchContent(pathToArray(path)));
  } else {
    yield put(loadArchetypeExampleFail(error));
  }
}

export function* watchLoadArchetypeExample() {
  yield takeEvery(loadArchetypeExample.getType(), loadArchetypeExampleSaga);
}

export function* checkGitRefFormatSaga({ payload: { ref, callbacks } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call(
    [notebookApi, notebookApi.checkRefFormat],
    ref
  );
  if (response) {
    callbacks.resolve(response);
  } else {
    callbacks.reject(error);
  }
}

export function* watchCheckGitRefFormat() {
  yield takeEvery(checkGitRefFormat.getType(), checkGitRefFormatSaga);
}

/**
 * Makes sure the directory exists in the notebook for the given path
 * @param joinedPath
 * @param parentType
 * @param appVersionCode
 * @returns {Generator<<"CALL", CallEffectDescriptor>, void, *>}
 */
/**
 * Makes sure the directory exists in the notebook for the given path
 * @param joinedPath
 * @param parentType
 * @param appVersionCode
 * @returns {Generator<<"CALL", CallEffectDescriptor>, void, *>}
 */
export function* ensureDirectoryExistsCall(
  joinedPath,
  parentType,
  appVersionCode
) {
  const jupyterUser = yield select((state) => notebookUser(state));

  if (parentType === 'notebook') {
    const notebookApi = new NotebookApi(jupyterUser);
    const { response, error } = yield call(
      [notebookApi, notebookApi.ensureDir],
      joinedPath
    );
  } else if (parentType === 'app') {
    const singleCommandSession = new SingleCommandSession(
      parentType,
      appVersionCode
    );
    const onSuccessActions = [];
    const onFailureActions = [];
    const onStreamActions = [];

    const NOTEBOOK_BASE_PATH = '/workbench';
    const command = `import pathlib; pathlib.Path('${NOTEBOOK_BASE_PATH}/${joinedPath}').mkdir(parents=True, exist_ok=True)`;

    yield call(
      singleCommandSession.executeCommand,
      command,
      onSuccessActions,
      onFailureActions,
      onStreamActions
    );
  }
}

export function* ensureDirectoryExistsSaga({
  payload: { targetDirPath, parentType, appVersionCode },
}) {
  yield call(
    ensureDirectoryExistsCall,
    targetDirPath,
    parentType,
    appVersionCode
  );
}

export function* watchEnsureDirectoryExistsSaga() {
  yield takeEvery(ensureDirectoryExists.getType(), ensureDirectoryExistsSaga);
}
