import { connect } from 'react-redux';
import SubHeader from './SubHeader';
import { RootState } from '../../../store/store';
import { selectMainTab } from '../../../redux/modules/mainTab.module';
import { fetchComponentPermissions } from '../../../redux/modules/dashboard.module';

export function mapStateToProps(state: RootState) {
  return {
    permissions: state.dashboard?.permissions?.data || {},
  };
}

export const mapDispatchToProps = {
  selectMainTab,
  fetchComponentPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
