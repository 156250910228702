import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import ReactModal from 'react-modal';
import Button from '../../../atoms/button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default class DeleteRepoConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = { userInput: '' };
  }

  render() {
    const { deleteRepoConfirm, hideDeleteRepoConfirm, deleteRepository } =
      this.props;
    const nameShown =
      deleteRepoConfirm.repoName !== undefined
        ? deleteRepoConfirm.repoName
        : deleteRepoConfirm.repoFullName;
    const inputCorrect = this.state.userInput === nameShown;
    return (
      <ReactModal
        isOpen={deleteRepoConfirm.show}
        contentLabel='Dialog Modal'
        onRequestClose={hideDeleteRepoConfirm}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer')
          /* Function that will be called to get the parent element
             that the modal will be attached to. */
        }
      >
        <div className={'delete-repo-modal'}>
          <div
            title={'Close Dialog'}
            className={'close-button'}
            onClick={hideDeleteRepoConfirm}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <div className={'delete-repo-headline'}>
            <span>Delete Repository</span>
          </div>

          <div className={'delete-repo-description'}>
            <span>
              You are going to delete <b>{nameShown}</b>. Deleting the
              repository cannot be undone, the repository data will be lost.
              Please type in <code>{nameShown}</code> and confirm if you are
              absolutely sure.
            </span>
          </div>

          <div className={'delete-repo-input'}>
            <input
              type={'text'}
              value={this.state.userInput}
              onChange={(e) => this.setState({ userInput: e.target.value })}
            />
          </div>

          <div className={'delete-repo-button-bar'}>
            <Button
              buttonColor={'red'}
              withLink={false}
              buttonLabelDefault={'Delete'}
              disabled={!inputCorrect}
              onClick={() => {
                if (inputCorrect) {
                  deleteRepository(deleteRepoConfirm.repoFullName);
                  hideDeleteRepoConfirm();
                }
              }}
            />
            <Button
              buttonColor={'white'}
              withLink={false}
              buttonLabelDefault={'Cancel'}
              onClick={hideDeleteRepoConfirm}
            />
          </div>
        </div>
      </ReactModal>
    );
  }
}

DeleteRepoConfirmModal.propTypes = {
  deleteRepoConfirm: PropTypes.shape({
    show: PropTypes.bool,
    /** Name of the repo - Can be missing if the extra information besides git is not available */
    repoName: PropTypes.string,
    /** Full name of the repository - Always guaranteed to be there, as it comes directly from git */
    repoFullName: PropTypes.string,
  }),
  hideDeleteRepoConfirm: PropTypes.func.isRequired,
  deleteRepository: PropTypes.func.isRequired,
};
DeleteRepoConfirmModal.defaultProps = {
  deleteRepoConfirm: {},
};
