import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';
import * as CollabApi from '../../../core/api/workbench/collab';

export const switchBranch = createAction('switch branch', (branchName) => ({
  branchName,
}));

export const fetchBranches = createAction(
  'get branches',
  (group, repository) => ({ group, repository })
);

export const fetchBranchesSuccess = createAction(
  'get branches - success',
  (success) => success
);

export const fetchBranchesFailure = createAction(
  'get branches - failure',
  (error) => error
);

export const fetchLatestCommit = createAction(
  'fetch latest commit',
  (repoName, branch, repoGroup) => ({ repoName, branch, repoGroup })
);

export const fetchLatestCommitSuccess = createAction(
  'fetch latest commit - success',
  (commit) => commit
);

export const fetchLatestCommitFailure = createAction(
  'fetch latest commit - failure',
  (error) => error
);

export const fetchRepoContent = createAction(
  'fetch repo content',
  (repoName, ref, path, repoGroup) => ({ repoName, ref, path, repoGroup })
);

export const fetchRepoContentSuccess = createAction(
  'fetch repo content - success',
  (data) => data
);

export const fetchRepoContentFailure = createAction(
  'fetch repo content - failure',
  (error) => error
);

export const fetchRepoDetails = createAction(
  'fetch repo details',
  (repoGroup, repoName) => ({ repoGroup, repoName })
);

export const fetchRepoDetailsSuccess = createAction(
  'fetch repo details - success',
  (repoGroup, repoName, data) => ({ repoGroup, repoName, data })
);

export const fetchRepoDetailsFailure = createAction(
  'fetch repo details - failure',
  (repoGroup, repoName, error) => ({ repoGroup, repoName, error })
);

export const reducer = {
  [switchBranch]: (state, { branchName }) => ({
    ...state,
    collab: {
      ...state.collab,
      repository: {
        ...state.collab.repository,
        activeBranch: branchName,
      },
    },
  }),
  [fetchLatestCommit]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      repository: {
        ...state.collab.repository,
        latestCommit: {
          ...state.collab.repository.latestCommit,
          loading: true,
          error: undefined,
        },
      },
    },
  }),
  [fetchLatestCommitSuccess]: (state, commit) => ({
    ...state,
    collab: {
      ...state.collab,
      repository: {
        ...state.collab.repository,
        latestCommit: {
          ...state.collab.repository.latestCommit,
          loading: false,
          loaded: true,
          data: commit,
          error: undefined,
        },
      },
    },
  }),
  [fetchLatestCommitFailure]: (state, error) => ({
    ...state,
    collab: {
      ...state.collab,
      repository: {
        ...state.collab.repository,
        latestCommit: {
          ...state.collab.repository.latestCommit,
          loading: false,
          loaded: false,
          error,
        },
      },
    },
  }),
  [fetchRepoContent]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      repository: {
        ...state.collab.repository,
        repoContent: {
          ...state.collab.repository.repoContent,
          loading: true,
          error: undefined,
        },
      },
    },
  }),
  [fetchRepoContentSuccess]: (state, commit) => ({
    ...state,
    collab: {
      ...state.collab,
      repository: {
        ...state.collab.repository,
        repoContent: {
          ...state.collab.repository.repoContent,
          loading: false,
          loaded: true,
          data: commit,
          error: undefined,
        },
      },
    },
  }),
  [fetchRepoContentFailure]: (state, error) => ({
    ...state,
    collab: {
      ...state.collab,
      repository: {
        ...state.collab.repository,
        repoContent: {
          ...state.collab.repository.repoContent,
          loading: false,
          loaded: false,
          error,
        },
      },
    },
  }),
  [fetchBranches]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      branches: {
        ...state.collab.branches,
        loading: true,
        loaded: false,
        data: [],
        error: undefined, // Just to make it explicit
      },
    },
  }),
  [fetchBranchesSuccess]: (state, branches) => ({
    ...state,
    collab: {
      ...state.collab,
      branches: {
        ...state.collab.branches,
        loading: false,
        loaded: true,
        data: branches,
        error: undefined, // Just to make it explicit
      },
    },
  }),
  [fetchBranchesFailure]: (state, err) => ({
    ...state,
    collab: {
      ...state.collab,
      branches: {
        ...state.collab.branches,
        loading: false,
        loaded: true,
        data: [],
        error: err,
      },
    },
  }),
  [fetchRepoDetails]: (state, { repoGroup, repoName }) => ({
    ...state,
    collab: {
      ...state.collab,
      repoDetails: {
        ...(state.collab.repoDetails || {}),
        loading: true,
        error: undefined,
      },
    },
  }),
  [fetchRepoDetailsSuccess]: (state, { repoGroup, repoName, data }) => ({
    ...state,
    collab: {
      ...state.collab,
      repoDetails: {
        ...(state.collab.repoDetails || {}),
        loading: false,
        loaded: true,
        error: undefined,
        data,
      },
    },
  }),
  [fetchRepoDetailsFailure]: (state, { repoGroup, repoName, error }) => ({
    ...state,
    collab: {
      ...state.collab,
      repoDetails: {
        ...(state.collab.repoDetails || {}),
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* fetchLatestCommitSaga({
  payload: { repoName, branch, repoGroup },
}) {
  const { response, error } = yield call(
    CollabApi.fetchLatestCommit,
    repoName,
    branch,
    repoGroup
  );
  if (response) {
    yield put(fetchLatestCommitSuccess(response));
  } else {
    yield put(fetchLatestCommitFailure(error));
  }
}

export function* watchFetchLatestCommit() {
  yield takeEvery(fetchLatestCommit.getType(), fetchLatestCommitSaga);
}

export function* fetchRepoContentSaga({
  payload: { repoName, ref, path, repoGroup },
}) {
  const { response, error } = yield call(
    CollabApi.fetchRepoContent,
    repoName,
    ref,
    path,
    repoGroup
  );
  if (response) {
    yield put(fetchRepoContentSuccess(response));
  } else {
    yield put(fetchRepoContentFailure(error));
  }
}

export function* watchFetchRepoContent() {
  yield takeEvery(fetchRepoContent.getType(), fetchRepoContentSaga);
}

export function* fetchBranchesSaga({ payload: { group, repository } }) {
  const { response, error } = yield call(
    CollabApi.getBranches,
    group,
    repository
  );
  if (response) {
    yield put(fetchBranchesSuccess(response));
  } else {
    yield put(fetchBranchesFailure(error));
  }
}

export function* watchFetchBranches() {
  yield takeEvery(fetchBranches.getType(), fetchBranchesSaga);
}

export function* fetchRepoDetailsSaga({ payload: { repoGroup, repoName } }) {
  const { response, error } = yield call(
    CollabApi.fetchRepoDetails,
    repoGroup,
    repoName
  );
  if (response) {
    yield put(fetchRepoDetailsSuccess(repoGroup, repoName, response));
  } else {
    yield put(fetchRepoDetailsFailure(repoGroup, repoName, error));
  }
}

export function* watchFetchRepoDetails() {
  yield takeEvery(fetchRepoDetails.getType(), fetchRepoDetailsSaga);
}
