import React, { FC, useEffect, useState } from 'react';
import { ChannelsValueType } from './OptChannels';
import Table, { RenderColumn } from '../../../../molecules/table/Table';
import { MessageDescriptor } from 'react-intl';
import { WrappedFieldProps } from 'redux-form';
import commonStyles from './optCommonStyles.module.scss';
import {
  Communication,
  Channel,
  Propensity,
} from 'common/dist/types/module.optimization';
import classNames from 'classnames';
import styles from '../../newAugurWizard/optConstraints/styles.module.scss';
import AddChannel from './AddChannel';
import { PropensitiesValueType } from '../optPropensities/OptPropensities';
import { TypedWrappedFieldProps } from '../../../../../utils';

type Props = {
  /** The list of communications defined in a later wizard step. Required to prevent deleting a channel to which a
   * communication belongs to*/
  communications?: Communication[];
  editMode?: boolean;
  addedNewRow?: boolean;
  rowIndex: number;
};

export type TableRowType = Channel & { removeDisabled: MessageDescriptor };

const ChannelsTable: FC<Props & TypedWrappedFieldProps<ChannelsValueType>> = (
  props
) => {
  const { input, communications, meta, editMode, addedNewRow, rowIndex } =
    props;
  const inputValue: ChannelsValueType = input.value || [];

  // Derive additional properties for rendering only
  const channels = (inputValue || []).map((channel) => ({
    ...channel,
    removeDisabled: (communications || [])
      .map((com) => com.channelId)
      .includes(channel.id)
      ? { id: 'no-id', defaultMessage: 'Used in a Communication' }
      : null,
  }));
  const initialVisible = {
    index: addedNewRow ? inputValue?.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  };
  const [visible, setShow] = useState(initialVisible);

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : inputValue?.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex]);

  const renderColumns: RenderColumn<TableRowType, keyof TableRowType>[] = [
    {
      key: 'name',
      renderHeader: () => <span>Name</span>,
      renderCell: (name: string, channel: Channel) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[channel.id]?.name,
          })}
        >
          {name}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,
      renderCell: (description?: string, channel?: Channel) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[channel?.id]?.description,
          })}
        >
          {description}
        </span>
      ),
    },
  ];
  const onRemove = (removedRow: Channel) => {
    const updatedChannels = inputValue.filter(
      (con) => con.id !== removedRow.id
    );
    input.onChange(updatedChannels);
  };
  const renderClickedRow = (
    element: Channel,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (editMode && visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddChannel
                rowIndex={rowIndex}
                constraintId={element.id}
                input={input}
                meta={meta}
                //@ts-ignore
                removableRows={visible.shown && !element.removeDisabled}
                onRemoveRow={onRemove}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };

  return (
    <Table<TableRowType>
      data={channels}
      renderColumns={renderColumns}
      showHeader
      verticalAlignMiddle
      roundedBorder
      removableRows={editMode}
      keyRemoveDisabledMessage={'removeDisabled'}
      onRemoveRow={onRemove}
      addlRowClassname={(rowIndex: number, channel: Channel) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!meta.error?.[channel.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
    />
  );
};

export default ChannelsTable;
