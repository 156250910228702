import { connect } from 'react-redux';
import RepositoryHeader from './RepositoryHeader';

export function mapStateToProps(state) {
  // const { repoName, repoFullName } = ((state.workbench.collab.repoDetails || {}).data || {});
  // const group = (repoFullName || '').split('/')[0];
  return {
    // group,
    // repoName,
  };
}
export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RepositoryHeader);
