export const initial = {
  step: 0,
  numberOfSteps: 1,
  fieldNames: [],
  stepsVisited: [false],
  stepsValid: [false],
};

export const test = {
  step: 0,
  numberOfSteps: 1,
  fieldNames: [
    ['datapoolCode', 'augurName'],
    ['predictionTargets'],
    ['predictionSchedule'],
  ],
  stepsVisited: [false],
  stepsValid: [false],
};
