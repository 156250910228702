export type SortByType = 'importance' | 'feature' | 'rejected';
export type OrderByType = 'ascending' | 'descending';

const sortByImportance = (p1, p2) => p1.importance - p2.importance;

const sortByFeature = (p1, p2) => {
  if ((p1.discarded && p2.discarded) || (!p1.discarded && !p2.discarded)) {
    const el1 = p1.feature.toLowerCase();
    const el2 = p2.feature.toLowerCase();

    if (el1 < el2) {
      return -1;
    }

    if (el1 > el2) {
      return 1;
    }

    return 0;
  }
  return p1.discarded ? 1 : -1;
};

const sortByRejected = (p1, p2) => {
  if (p1.rejected === p2.rejected && p1.forced === p2.forced) {
    return 0;
  } else if (p1.rejected === p2.rejected) {
    return p1.forced ? 1 : -1;
  }

  return p1.rejected ? -1 : 1;
};

export const sortFeatures = (
  f1,
  f2,
  sort: SortByType,
  order: OrderByType
): number => {
  if (sort === 'rejected') {
    return order === 'descending'
      ? sortByRejected(f2, f1)
      : sortByRejected(f1, f2);
  }

  if (sort === 'feature') {
    return order === 'descending'
      ? sortByFeature(f2, f1)
      : sortByFeature(f1, f2);
  }

  if (sort === 'importance') {
    return order === 'descending'
      ? sortByImportance(f2, f1)
      : sortByImportance(f1, f2);
  }

  return 0;
};
