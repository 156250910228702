import React, { Component } from 'react';
import { targetBaseDir } from './UploadElementWrapper';
import { getValueFromSettingsField } from '../_interface/input-element.form';
import Upload from '../../../../../../../../../../organisms/workbench/upload-modal/upload/Upload.container';
import { AsInputElement } from '../_interface/P3InputElement';

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export interface UploadElementProps {
  /** = element.id is set in the UploadElement.container */
  uploadId: string;
  path: string;
  cellId: string;
  /** Function to update the user input */
  updateDataOfInputElement(...args: unknown[]): unknown;
  /** Input element (including the settings + the user input) */
  element?: AsInputElement;
  cell: Record<string, unknown>;
  /** List of uploaded files in the upload modal */
  uploadedFiles?: string[];
  /** notebook | app */
  parentType?: 'app' | 'notebook';
  /** Only set if parentType='app' */
  appVersionCode?: string;
}

export default class UploadElement extends Component<UploadElementProps> {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { uploadedFiles, updateDataOfInputElement, path, cellId, element } =
      this.props;
    if (
      prevProps.uploadedFiles &&
      uploadedFiles &&
      !arraysEqual(prevProps.uploadedFiles, uploadedFiles)
    ) {
      updateDataOfInputElement(path, cellId, element, { files: uploadedFiles });
    }
  }

  render() {
    const {
      path,
      cellId,
      element,
      cell,
      updateDataOfInputElement,
      uploadId,
      parentType,
      appVersionCode,
    } = this.props;

    // --- Get the target path suffix (as an array of directories)
    const targetPathField = element.settings
      ? element.settings.targetPath
      : undefined;
    const targetPath =
      getValueFromSettingsField(targetPathField, cell.as_variables) || '';
    const targetPathArr = targetPath.split('/').filter((y) => y.trim() !== '');

    return (
      <div className={'inner-input-element upload'}>
        <Upload
          uploadId={uploadId}
          targetDirPath={targetBaseDir.concat(targetPathArr)}
          isClosable={false}
          ensureDirectoriesBeforeUpload
          parentType={parentType}
          appVersionCode={appVersionCode}
        />
      </div>
    );
  }
}
