import { createAction } from 'redux-act';
import { put, call, takeEvery, delay, select } from 'redux-saga/effects';
import * as KernelspecsApi from '../../../core/api/workbench/kernelspecs';
import { notebookUser } from '../selectors/notebookUser.selector';

export const fetchKernelspecs = createAction(
  'fetch kernelspecs',
  (retry = false) => ({ retry })
);

export const fetchKernelspecsSuccess = createAction(
  'fetch kernelspecs - success',
  (nodes) => nodes
);

export const fetchKernelspecsFail = createAction(
  'fetch kernelspecs - fail',
  (error) => error
);

export const reducer = {
  [fetchKernelspecs]: (state) => ({
    ...state,
  }),
  [fetchKernelspecsSuccess]: (state, data) => ({
    ...state,
    kernelspecs: {
      ...state.kernelspecs,
      ...data,
      error: null,
    },
  }),
  [fetchKernelspecsFail]: (state, error) => ({
    ...state,
    kernelspecs: {
      ...state.kernelspecs,
      error,
      kernelspecs: null,
    },
  }),
};

export function* fetchKernelspecsSaga({ payload: { retry } }) {
  try {
    const jupyterUser = yield select((state) => notebookUser(state));
    const { response, error } = yield call(
      KernelspecsApi.fetchKernelspecs,
      jupyterUser
    );
    if (response) {
      yield put(fetchKernelspecsSuccess(response));
    } else {
      yield put(fetchKernelspecsFail(error));
    }
  } catch (e) {
    // This happens if the connection to the notebook can't be established (yet)
    if (retry) {
      yield delay(3000);
      yield put(fetchKernelspecs(retry));
    }
  }
}

export function* watchFetchKernelspecs() {
  yield takeEvery(fetchKernelspecs().type, fetchKernelspecsSaga);
}
