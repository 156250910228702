import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
} from '../../../core/api/_tools';
import { UserResponse } from './api.types';

/**
 * Get a list of running Jupyter Notebooks from the Workbench JupyterHub
 */
export function getRunningWorkbench(): CompletedApiRequest<UserResponse[]> {
  return apiRequest('/jupyter/hub/api/users');
}

/**
 * Delete the Workbench Jupyter Notebook instance for the given user
 * @param userCodeLower
 */
export function stopWorkbench(
  userCodeLower: string
): CompletedApiRequest<never> {
  return deleteApiRequest(`/jupyter/hub/api/users/${userCodeLower}/server`);
}

/**
 * Get a list of running Jupyter Notebooks from the Apps JupyterHub
 */
export function getRunningApps(): CompletedApiRequest<UserResponse[]> {
  return apiRequest('/jupyterapp/hub/api/users');
}

/**
 * Delete the App Jupyter Notebook instance for the given user and app version
 * @param userCodeLower
 * @param appVersionCodeLower
 */
export function stopApp(
  userCodeLower: string,
  appVersionCodeLower: string
): CompletedApiRequest<never> {
  return deleteApiRequest(
    `/jupyterapp/hub/api/users/${userCodeLower}/servers/${appVersionCodeLower}`
  );
}
