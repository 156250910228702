import { v4 as uuidv4 } from 'uuid';

export const error = 'error';

export const event = 'event';

export const notificationTimeout = 5000;

export function compose(
  title,
  description,
  type,
  descriptionValues,
  titleValues
) {
  return {
    id: uuidv4(),
    title,
    description,
    descriptionValues,
    titleValues,
    type,
  };
}

export function getNotificationIndex(notifications, id) {
  return notifications.findIndex((notification) => notification.id === id);
}
