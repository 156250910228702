import { createAction } from 'redux-act';
import {
  call,
  cancel,
  cancelled,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import * as CollabApi from '../../../core/api/workbench/collab';
import {
  error as errorNotification,
  success as successNotification,
} from 'react-notification-system-redux';
import {
  deleteRepositoryFailNotification,
  deleteRepositorySuccessNotification,
  openMergeRequestFailed,
  openMergeRequestSuccessful,
} from '../notifications/notifications';
import { notebookUser } from '../selectors/notebookUser.selector';
import * as ContentApi from '../../../core/api/workbench/content';
import NotebookApi from '../../../core/api/workbench/git.notebook';
import { jumpToDirectory } from './content.module';
import { sendNotification } from '../../modules/notifications.module';
import * as NOTIFICATION_TYPES from '../../../core/notifications';
import { runJobGroup as runJobGroupApi } from '../../../store/jobGroups/api';
import { DEFAULT_PAGE_SIZE } from '../../../components/collaborationSpace/browser/repositories/util';
import notifications from 'common/dist/messages/notifications';

export const addRepository = createAction(
  'add repository',
  (
    repositoryName,
    activePath,
    repositoryType,
    repositoryDescription,
    codeCapsuleHabitat,
    archKernel,
    archHabitat,
    archDatapool
  ) => ({
    repositoryName,
    activePath,
    repositoryType,
    repositoryDescription,
    codeCapsuleHabitat,
    archKernel,
    archHabitat,
    archDatapool,
  })
);

export const addRepositorySuccess = createAction(
  'add repository - success',
  (success) => success
);

export const addRepositoryFailure = createAction(
  'add repository - failure',
  (error) => error
);

export const forkRepository = createAction(
  'fork repository',
  (infoFilePath, addRepositoryParams, origRepoMeta) => ({
    infoFilePath,
    addRepositoryParams,
    origRepoMeta,
  })
);

export const forkRepositorySuccess = createAction(
  'fork repository - success',
  (success) => success
);

export const forkRepositoryFailure = createAction(
  'fork repository - failure',
  (error) => error
);

export const triggerCodeCapsuleBuild = createAction(
  'trigger code capsule build',
  (repositoryCode, codeCapsuleCode, capsuleVersionNumber) => ({
    repositoryCode,
    codeCapsuleCode,
    capsuleVersionNumber,
  })
);

export const triggerCodeCapsuleBuildSuccess = createAction(
  'trigger code capsule build - success'
);

export const triggerCodeCapsuleBuildFailure = createAction(
  'trigger code capsule build - failure',
  (error) => error
);

export const triggerAppBuild = createAction(
  'trigger app build',
  (repositoryCode, appCode, appVersionNumber, notebooksToExecute) => ({
    repositoryCode,
    appCode,
    appVersionNumber,
    notebooksToExecute,
  })
);

export const triggerAppBuildSuccess = createAction(
  'trigger app build - success'
);

export const triggerAppBuildFailure = createAction(
  'trigger app build - failure',
  (error) => error
);

export const hideAppBuildConfirm = createAction('hide app build confirm');

export const triggerArchetypeBuild = createAction(
  'trigger archetype build',
  (repositoryCode, archetypeCode, archetypeVersionNumber) => ({
    repositoryCode,
    archetypeCode,
    archetypeVersionNumber,
  })
);

export const triggerArchetypeBuildSuccess = createAction(
  'trigger archetype build - success'
);

export const triggerArchetypeBuildFailure = createAction(
  'trigger archetype build - failure',
  (error) => error
);

export const hideArchetypeBuildConfirm = createAction(
  'hide archetype build confirm'
);

export const deleteRepository = createAction(
  'collab delete repository',
  (fullRepoName) => ({ fullRepoName })
);

export const deleteRepositorySuccess = createAction(
  'collab delete repository - success'
);

export const deleteRepositoryFail = createAction(
  'collab delete repository - failed',
  (fullRepoName, error) => ({ fullRepoName, error })
);

export const fetchRepositories = createAction(
  'fetch repositories',
  (offset, limit, search) => ({ offset, limit, search })
);

export const fetchRepositoriesSuccess = createAction(
  'fetch repositories - success',
  (data) => ({ data })
);

export const fetchRepositoriesFailure = createAction(
  'fetch repositories - failure',
  (error) => ({ error })
);

export const fetchGroups = createAction('fetch groups');

export const fetchGroupsSuccess = createAction(
  'fetch groups - success',
  (data) => ({ data })
);

export const fetchGroupsFailure = createAction(
  'fetch groups - failure',
  (error) => ({ error })
);

export const showDeleteRepoConfirm = createAction(
  'show delete repo confirm',
  (repoName, repoFullName) => ({ repoName, repoFullName })
);

export const hideDeleteRepoConfirm = createAction('hide delete repo confirm');

export const openMergeRequest = createAction(
  'open merge request',
  (repoGroup, repoName, src, target, title, description) => ({
    repoGroup,
    repoName,
    src,
    target,
    title,
    description,
  })
);

export const openMergeRequestSuccess = createAction(
  'open merge request - success',
  (repoGroup, repoName, src, target, title) => ({
    repoGroup,
    repoName,
    src,
    target,
    title,
  })
);

export const openMergeRequestFailure = createAction(
  'open merge request - fail',
  (repoGroup, repoName, src, target, title, error) => ({
    repoGroup,
    repoName,
    src,
    target,
    title,
    error,
  })
);

export const loadMergeRequestDetails = createAction(
  'load merge request details',
  (repoGroup, repoName, id) => ({ repoGroup, repoName, id })
);

export const loadMergeRequestDetailsSuccess = createAction(
  'load merge request details - success',
  (mergeRequest) => ({ mergeRequest })
);

export const loadMergeRequestDetailsFailure = createAction(
  'load merge request details - failure',
  (error) => ({ error })
);

export const loadMergeRequests = createAction(
  'list merge requests',
  (repoGroup, repoName, state, page, limit) => ({
    repoGroup,
    repoName,
    state,
    page,
    limit,
  })
);

export const loadMergeRequestsSuccess = createAction(
  'list merge requests - success',
  (mergeRequests) => ({ mergeRequests })
);

export const loadMergeRequestsFailure = createAction(
  'list merge requests - failure',
  (error) => ({ error })
);

export const editMergeRequest = createAction(
  'edit merge request',
  (repoGroup, repoName, id, title, description, targetBranch) => ({
    repoGroup,
    repoName,
    id,
    title,
    description,
    targetBranch,
  })
);

export const mergeMergeRequest = createAction(
  'merge merge request',
  (repoGroup, repoName, id) => ({ repoGroup, repoName, id })
);

export const mergeMergeRequestSuccess = createAction(
  'merge merge request - success'
);

export const mergeMergeRequestFailure = createAction(
  'merge merge request - failure',
  (error) => ({ error })
);

export const reducer = {
  [mergeMergeRequest]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      merging: {
        loading: true,
        loaded: false,
        data: undefined,
        error: undefined,
      },
    },
  }),
  [mergeMergeRequestSuccess]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      merging: {
        loading: false,
        loaded: true,
        data: {},
        error: undefined,
      },
    },
  }),
  [mergeMergeRequestFailure]: (state, { error }) => ({
    ...state,
    collab: {
      ...state.collab,
      merging: {
        loading: false,
        loaded: false,
        data: undefined,
        error,
      },
    },
  }),
  [loadMergeRequestDetails]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      mergeRequest: {
        loading: true,
        loaded: false,
        data: undefined,
        error: undefined,
      },
    },
  }),
  [loadMergeRequestDetailsSuccess]: (state, { mergeRequest }) => ({
    ...state,
    collab: {
      ...state.collab,
      mergeRequest: {
        loading: false,
        loaded: true,
        data: mergeRequest,
        error: undefined,
      },
    },
  }),
  [loadMergeRequestDetailsFailure]: (state, { error }) => ({
    ...state,
    collab: {
      ...state.collab,
      mergeRequest: {
        loading: false,
        loaded: false,
        data: [],
        error,
      },
    },
  }),
  [loadMergeRequests]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      mergeRequests: {
        ...state.collab.mergeRequests,
        loading: true,
        loaded: false,
        data: [],
        error: undefined,
      },
    },
  }),
  [loadMergeRequestsSuccess]: (state, { mergeRequests }) => ({
    ...state,
    collab: {
      ...state.collab,
      mergeRequests: {
        ...state.collab.mergeRequests,
        loading: false,
        loaded: true,
        data: mergeRequests,
        error: undefined,
      },
    },
  }),
  [loadMergeRequestsFailure]: (state, { error }) => ({
    ...state,
    collab: {
      ...state.collab,
      mergeRequests: {
        ...state.collab.mergeRequests,
        loading: false,
        loaded: false,
        data: [],
        error,
      },
    },
  }),
  [addRepository]: (state) => ({
    ...state,
    isCreatingRepository: true,
  }),
  [addRepositorySuccess]: (state) => ({
    ...state,
    isCreatingRepository: false,
  }),
  [addRepositoryFailure]: (state) => ({
    ...state,
    isCreatingRepository: false,
  }),
  [fetchRepositories]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      repositories: {
        ...state.collab.repositories,
        loading: true,
      },
    },
  }),
  [fetchRepositoriesSuccess]: (state, { data }) => ({
    ...state,
    collab: {
      ...state.collab,
      repositories: {
        ...state.collab.repositories,
        loading: false,
        loaded: true,
        data,
        error: undefined,
      },
    },
  }),
  [fetchRepositoriesFailure]: (state, { error }) => ({
    ...state,
    collab: {
      ...state.collab,
      repositories: {
        ...state.collab.repositories,
        loading: false,
        loaded: false,
        data: [],
        error,
      },
    },
  }),
  [fetchGroups]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      groups: {
        ...state.collab.groups,
        loading: true,
      },
    },
  }),
  [fetchGroupsSuccess]: (state, { data }) => ({
    ...state,
    collab: {
      ...state.collab,
      groups: {
        ...state.collab.groups,
        loading: false,
        loaded: true,
        data,
        error: undefined,
      },
    },
  }),
  [fetchGroupsFailure]: (state, { error }) => ({
    ...state,
    collab: {
      ...state.collab,
      groups: {
        ...state.collab.groups,
        loading: false,
        loaded: false,
        data: [],
        error,
      },
    },
  }),
  [showDeleteRepoConfirm]: (state, { repoName, repoFullName }) => ({
    ...state,
    collab: {
      ...state.collab,
      deleteRepoConfirm: {
        ...(state.collab.deleteRepoConfirm || {}),
        show: true,
        repoName,
        repoFullName,
      },
    },
  }),
  [hideDeleteRepoConfirm]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      deleteRepoConfirm: {
        ...(state.collab.deleteRepoConfirm || {}),
        show: false,
      },
    },
  }),
  [triggerCodeCapsuleBuildSuccess]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      buildCodeCapsuleConfirm: true,
    },
  }),
  [triggerAppBuildSuccess]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      buildAppConfirm: true,
    },
  }),
  [hideAppBuildConfirm]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      buildAppConfirm: false,
    },
  }),
  [triggerArchetypeBuildSuccess]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      buildArchetypeConfirm: true,
    },
  }),
  [hideArchetypeBuildConfirm]: (state) => ({
    ...state,
    collab: {
      ...state.collab,
      buildArchetypeConfirm: false,
    },
  }),
};

export function* addRepositorySaga({
  payload: {
    repositoryName,
    activePath,
    repositoryType,
    repositoryDescription,
    codeCapsuleHabitat,
    archKernel,
    archHabitat,
    archDatapool,
  },
}) {
  let path = activePath
    .map((d) => d.name)
    .slice(1)
    .join('/'); // Throw away the first 'root' directory
  if (!path.startsWith('/')) path = `/${path}`;
  const { response, error } = yield call(
    CollabApi.addRepository,
    path,
    repositoryName,
    repositoryType,
    repositoryDescription,
    codeCapsuleHabitat,
    archKernel,
    archHabitat,
    archDatapool
  );
  if (response) {
    yield put(addRepositorySuccess(response));
    yield put(fetchRepositories(0, DEFAULT_PAGE_SIZE, undefined));
  } else {
    yield put(addRepositoryFailure(error));
    yield put(fetchRepositories(0, DEFAULT_PAGE_SIZE, undefined));
  }
}

export function* watchAddRepository() {
  yield takeEvery(addRepository.getType(), addRepositorySaga);
}

/**
 * Fork a repository
 * TODO? parallelize some steps, like creating the repo and renaming dir, also the way rollbacks are handled could be nicer
 * 1. The repository is created in gitea
 * 2. The directory is renamed
 * 3. The repository.asr is adjusted (change content and commit)
 * 4. The remote of the repository in the workbench is adjusted and
 *    the current state of the repository is pushed to the newly created repository in Gitea
 * @param addRepositoryParams
 * @return {Generator<SimpleEffect<"PUT", PutEffectDescriptor<Action<unknown, {}>>>|SimpleEffect<"PUT", PutEffectDescriptor<Action<null, null>>>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>, void, *>}
 */
export function* forkRepositorySaga({
  payload: {
    infoFilePath,
    addRepositoryParams: {
      repositoryName,
      activePath, // Ignored here. What does this mean in add repository?
      repositoryType,
      repositoryDescription,
      codeCapsuleHabitat,
      archKernel,
      archHabitat,
      archDatapool,
      currentUserCode,
      accountCode,
    },
    origRepoMeta,
  },
}) {
  try {
    const jupyterUser = yield select((state) => notebookUser(state));

    // 1. Add repository
    const parentPath = '/';
    const { response: responseAddRepo, error: errorAddRepo } = yield call(
      CollabApi.addRepository,
      parentPath,
      repositoryName,
      repositoryType,
      repositoryDescription,
      codeCapsuleHabitat,
      archKernel,
      archHabitat,
      archDatapool,
      currentUserCode,
      accountCode
    );
    if (responseAddRepo === undefined) {
      yield put(forkRepositoryFailure(errorAddRepo));
      yield cancel();
    }
    const {
      code: repoCode,
      repoName,
      repoFullName,
      repoType,
      codeCapsuleCode,
      appCode,
      archetypeCode,
    } = responseAddRepo;

    // 2. Rename dir
    // infoFilePath <...>/<repoDir>/repository.asr - paths don't have leading slashes
    const oldRepoPath = infoFilePath.split('/').slice(0, -1).join('/'); // <...>/<repoDir>
    const repoParentPath = infoFilePath.split('/').slice(0, -2).join('/'); // <...>
    const newRepoPath =
      repoParentPath +
      (repoParentPath !== '' ? '/' : '') +
      `${repositoryName}.asr`; // <...>/<newRepoDir>
    const { response: responseRename, error: errorRename } = yield call(
      ContentApi.renameContent,
      oldRepoPath,
      newRepoPath,
      jupyterUser
    );
    if (responseRename === undefined) {
      yield call(CollabApi.deleteRepository, repoFullName);
      yield put(forkRepositoryFailure(errorRename));
      yield cancel();
    }

    // 3. Create meta file and change it on disk (write to repository.asr)
    // Content that will be written into the repository.asr meta file
    const metaFileContent = JSON.stringify({
      repoCode,
      repoType,
      repoName,
      codeCapsuleCode,
      archetypeCode,
      appCode,
      repoFullName,
    });
    const metaFilePath = newRepoPath + '/repository.asr';
    const { response: responseMetaFile, error: errorMetaFile } = yield call(
      ContentApi.putFile,
      metaFilePath,
      metaFileContent,
      jupyterUser
    );
    if (responseMetaFile === undefined) {
      yield call(CollabApi.deleteRepository, repoFullName);
      yield call(
        ContentApi.renameContent,
        newRepoPath,
        oldRepoPath,
        jupyterUser
      );
      yield put(forkRepositoryFailure(errorMetaFile));
      yield cancel();
    }
    // Commit
    const notebookApi = new NotebookApi(jupyterUser);
    const commitMessage = 'Fork repository';
    const { response: responseCommit, error: errorCommit } = yield call(
      [notebookApi, notebookApi.addAllAndCommit],
      newRepoPath,
      commitMessage
    );
    if (responseCommit === undefined) {
      yield call(CollabApi.deleteRepository, repoFullName);
      yield call(
        ContentApi.renameContent,
        newRepoPath,
        oldRepoPath,
        jupyterUser
      );
      // rollback meta file
      yield call(
        ContentApi.putFile,
        oldRepoPath + '/repository.asr',
        JSON.stringify(origRepoMeta),
        jupyterUser
      );
      yield put(forkRepositoryFailure(errorCommit));
      yield cancel();
    }

    // 4. Fork: Change remote to the newly created one and push
    const { response: responseFork, error: errorFork } = yield call(
      [notebookApi, notebookApi.fork],
      newRepoPath,
      repoFullName
    );
    if (responseFork === undefined) {
      yield call(CollabApi.deleteRepository, repoFullName);
      yield call(
        ContentApi.renameContent,
        newRepoPath,
        oldRepoPath,
        jupyterUser
      );
      // rollback meta file
      yield call(
        ContentApi.putFile,
        oldRepoPath + '/repository.asr',
        JSON.stringify(origRepoMeta),
        jupyterUser
      );
      yield call(
        [notebookApi, notebookApi.addAllAndCommit],
        oldRepoPath,
        'Fork repository rollback'
      );
      yield put(forkRepositoryFailure(errorFork));
      yield cancel();
    }

    // Change the sidebar
    yield put(jumpToDirectory(['root', ...newRepoPath.split('/')]));
  } finally {
    const cancelledSaga = yield cancelled();
    if (!cancelledSaga) {
      yield put(forkRepositorySuccess());
      yield put(
        sendNotification(
          'Fork successful',
          'The Repository was forked successfully',
          NOTIFICATION_TYPES.event
        )
      );
    } else {
      yield put(
        sendNotification(
          'Fork failed',
          'The Repository could not be forked',
          NOTIFICATION_TYPES.error
        )
      );
    }
  }
}

export function* watchForkRepository() {
  yield takeEvery(forkRepository.getType(), forkRepositorySaga);
}

export function* triggerCodeCapsuleBuildSaga({
  payload: { repositoryCode, codeCapsuleCode, capsuleVersionNumber },
}) {
  const jobs = [
    {
      jobCode: 'TMP001',
      superType: 'code-capsule',
      jobType: 'build-code-capsule',
      moduleType: 'code-capsule',
      priority: 'medium',
      executionType: 'other',
      capsuleImage: '', // This won't be used for the build job. It's just passed to make parsing the "Job" entity work
      repositoryCode,
      codeCapsuleCode,
      capsuleVersionNumber,
      notebooksToExecute: [], // Will be ignored, but is required in the backend (needed for running cc later)
    },
  ];
  const jobGroupTopology = [
    {
      jobCode: 'TMP001',
      successors: [],
      predecessors: [],
    },
  ];

  const jobGroup = {
    jobs: jobs,
    jobGroupTopology: jobGroupTopology,
    name: 'Build Code Capsule',
    description: 'Build Code Capsule',
    trigger: 'manual',
  };
  const { response, error } = yield call(runJobGroupApi, jobGroup);
  if (response) {
    yield put(triggerCodeCapsuleBuildSuccess());
    yield put(
      sendNotification(
        notifications.msgTitleCCBuildTriggered.id,
        notifications.msgDescriptionCCBuildTriggered.id,
        NOTIFICATION_TYPES.event
      )
    ); // TODO this is not working outside the "Workbench" Tab!
  } else {
    yield put(triggerCodeCapsuleBuildFailure(error));
    yield put(
      sendNotification(
        notifications.msgTitleCCBuildTriggeredFailed.id,
        notifications.msgDescriptionCCBuildTriggeredFailed.id,
        NOTIFICATION_TYPES.error
      )
    ); // TODO this is not working outside the "Workbench" Tab!
  }
}

export function* watchTriggerCodeCapsuleBuild() {
  yield takeEvery(
    triggerCodeCapsuleBuild.getType(),
    triggerCodeCapsuleBuildSaga
  );
}

export function* triggerAppBuildSaga({
  payload: { repositoryCode, appCode, appVersionNumber, notebooksToExecute },
}) {
  const jobs = [
    {
      jobCode: 'TMP001',
      superType: 'app',
      jobType: 'build-app',
      moduleType: 'app',
      priority: 'medium',
      executionType: 'other',
      repositoryCode,
      appCode,
      appVersionNumber,
      notebooksToExecute,
    },
  ];
  const jobGroupTopology = [
    {
      jobCode: 'TMP001',
      successors: [],
      predecessors: [],
    },
  ];
  const jobGroup = {
    jobs: jobs,
    jobGroupTopology: jobGroupTopology,
    name: 'Build App',
    description: 'Build App',
    trigger: 'manual',
  };
  const { response, error } = yield call(runJobGroupApi, jobGroup);
  if (response) {
    yield put(triggerAppBuildSuccess());
    yield put(
      sendNotification(
        notifications.msgTitleAppBuildTriggered.id,
        notifications.msgDescriptionAppBuildTriggered.id,
        NOTIFICATION_TYPES.event
      )
    );
  } else {
    yield put(triggerAppBuildFailure(error));
    yield put(
      sendNotification(
        notifications.msgTitleAppBuildTriggered.id,
        notifications.msgDescriptionAppBuildTriggeredFailed.id,
        NOTIFICATION_TYPES.error
      )
    );
  }
}

export function* watchTriggerAppBuild() {
  yield takeEvery(triggerAppBuild.getType(), triggerAppBuildSaga);
}

export function* triggerArchetypeBuildSaga({
  payload: { repositoryCode, archetypeCode, archetypeVersionNumber },
}) {
  const jobs = [
    {
      jobCode: 'TMP001',
      superType: 'custom-archetype',
      jobType: 'build-archetype',
      moduleType: 'custom-archetype',
      priority: 'medium',
      executionType: 'other',
      repositoryCode,
      archetypeCode,
      archetypeVersionNumber,
      archetypeModelCode: '', // TODO Remove later, very likely this is not required
    },
  ];
  const jobGroupTopology = [
    {
      jobCode: 'TMP001',
      successors: [],
      predecessors: [],
    },
  ];

  const jobGroup = {
    jobs: jobs,
    jobGroupTopology: jobGroupTopology,
    name: 'Build Archetype',
    description: 'Build Archetype',
    trigger: 'manual',
  };
  const { response, error } = yield call(runJobGroupApi, jobGroup);
  if (response) {
    yield put(triggerArchetypeBuildSuccess());
    yield put(
      sendNotification(
        notifications.msgTitleArchetypeBuildTriggered.id,
        notifications.msgDescriptionArchetypeBuildTriggered.id,
        NOTIFICATION_TYPES.event
      )
    );
  } else {
    yield put(triggerArchetypeBuildFailure(error));
    yield put(
      sendNotification(
        notifications.msgTitleArchetypeBuildTriggered.id,
        notifications.msgDescriptionArchetypeBuildTriggeredFailed.id,
        NOTIFICATION_TYPES.error
      )
    );
  }
}

export function* watchTriggerArchetypeBuild() {
  yield takeEvery(triggerArchetypeBuild.getType(), triggerArchetypeBuildSaga);
}

export function* deleteRepositorySaga({ payload: { fullRepoName } }) {
  const { response, error } = yield call(
    CollabApi.deleteRepository,
    fullRepoName
  );
  if (response) {
    // 1. Fire success action
    yield put(deleteRepositorySuccess());

    // 2. Send notification
    yield put(successNotification(deleteRepositorySuccessNotification()));

    // 3. Refresh the collab content
    const activePath = yield select(
      (state) => state.workbench.collab.activePath
    );
    yield put(fetchRepositories(0, DEFAULT_PAGE_SIZE, undefined));
  } else {
    // 1. Fire fail action
    yield put(deleteRepositoryFail(fullRepoName, error));

    // 2. Send notification
    yield put(errorNotification(deleteRepositoryFailNotification()));
  }
}

export function* watchDeleteRepository() {
  yield takeEvery(deleteRepository.getType(), deleteRepositorySaga);
}

export function* fetchRepositoriesSaga({ payload: { offset, limit, search } }) {
  const { response, error } = yield call(
    CollabApi.fetchRepositories,
    offset,
    limit,
    search
  );
  if (response) {
    yield put(fetchRepositoriesSuccess(response));
  } else {
    yield put(fetchRepositoriesFailure(error));
  }
}

export function* watchFetchRepositories() {
  yield takeEvery(fetchRepositories.getType(), fetchRepositoriesSaga);
}

export function* fetchGroupsSaga() {
  const { response, error } = yield call(CollabApi.fetchGroups);
  if (response) {
    yield put(fetchGroupsSuccess(response));
  } else {
    yield put(fetchGroupsFailure(error));
  }
}

export function* watchFetchGroups() {
  yield takeEvery(fetchGroups.getType(), fetchGroupsSaga);
}

export function* openMergeRequestSaga({
  payload: { repoGroup, repoName, src, target, title, description },
}) {
  const { response, error } = yield call(
    CollabApi.openMergeRequest,
    repoGroup,
    repoName,
    src,
    target,
    title,
    description
  );
  if (response) {
    yield put(successNotification(openMergeRequestSuccessful()));
  } else {
    yield put(errorNotification(openMergeRequestFailed()));
  }
}

export function* watchOpenMergeRequest() {
  yield takeEvery(openMergeRequest.getType(), openMergeRequestSaga);
}

export function* loadMergeRequestDetailsSaga({
  payload: { repoGroup, repoName, id },
}) {
  const { response, error } = yield call(
    CollabApi.loadMergeRequest,
    repoGroup,
    repoName,
    id
  );
  if (response) {
    yield put(loadMergeRequestDetailsSuccess(response));
  } else {
    yield put(loadMergeRequestDetailsFailure(error));
  }
}

export function* watchLoadMergeRequestDetails() {
  yield takeEvery(
    loadMergeRequestDetails.getType(),
    loadMergeRequestDetailsSaga
  );
}

export function* loadMergeRequestsSaga({
  payload: { repoGroup, repoName, state, page, limit },
}) {
  const { response, error } = yield call(
    CollabApi.loadMergeRequests,
    repoGroup,
    repoName,
    state,
    page,
    limit
  );
  if (response) {
    yield put(loadMergeRequestsSuccess(response));
  } else {
    yield put(loadMergeRequestsFailure(error));
  }
}

export function* watchListMergeRequests() {
  yield takeEvery(loadMergeRequests.getType(), loadMergeRequestsSaga);
}

export function* mergeMergeRequestSaga({
  payload: { repoGroup, repoName, id },
}) {
  const { response, error } = yield call(
    CollabApi.mergeMergeRequest,
    repoGroup,
    repoName,
    id
  );
  if (response) {
    yield put(mergeMergeRequestSuccess());
  } else {
    yield put(mergeMergeRequestFailure(error));
  }
}

export function* watchMergeMergeRequest() {
  yield takeEvery(mergeMergeRequest.getType(), mergeMergeRequestSaga);
}

export function* editMergeRequestSaga({
  payload: { repoGroup, repoName, id, title, description, targetBranch },
}) {
  const { response, error } = yield call(
    CollabApi.updateMergeRequest,
    repoGroup,
    repoName,
    id,
    title,
    description,
    targetBranch
  );
}

export function* watchEditMergeRequest() {
  yield takeEvery(editMergeRequest.getType(), editMergeRequestSaga);
}
