import { connect } from 'react-redux';
import StartingApp from './StartingApp';
import { notebookUser } from '../../../redux/workbench/selectors/notebookUser.selector';
import {
  checkWhetherAppIsRunning,
  startApp,
} from '../../../redux/workbench/modules/app.connection.module';
import { fetchAppPermissions } from '../../../redux/modules/apps.module';

export function mapStateToProps(state) {
  const { loading, loaded, isRunning, error } =
    (state.workbench.app || {}).appRunning || {};
  const permissions = state.apps?.permissions || {};
  return {
    notebookUser: notebookUser(state),
    loading,
    loaded,
    isRunning,
    error,
    notifications: state.workbench.notifications,
    permissions,
  };
}

export const mapDispatchToProps = {
  startApp,
  checkWhetherAppIsRunning,
  fetchAppPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartingApp);
