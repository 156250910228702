import React, { Component } from 'react';
import styles from './styles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import classNames from 'classnames';
import { MessageDescriptor } from 'react-intl';
import { WithTableSampleProps } from '../commonComponents/withTableSample/WithTableSample';
import { DatapoolType } from 'common/dist/types/datapool';

export type OwnProps = {
  /** Selected datapoolCode */
  datapool: DatapoolType;
  selectedValue: string;
};

export type Props = OwnProps & {
  /** code of the habitat to add this augur to */
  habitatCode: string;
  dropdownSelectPortal?: HTMLElement;
  /** ID for the dropdown select */
  dropdownSelectId?: string;
  /** name for the dropdown select */
  dropdownSelectName?: string;
  /** Label of the dropdown select */
  dropdownSelectLabel: MessageDescriptor;
  /** Placeholder of the dropdown select */
  dropdownSelectPlaceholder: MessageDescriptor;
};

type OptionType = { label: string; value: string };

export default class InputTable extends Component<
  Props & WrappedFieldProps & WithTableSampleProps
> {
  renderRowInputTable() {
    const {
      tablesLoading,
      tablesLoaded,
      tables,
      input: { value, onChange, onBlur, onFocus },
      meta: { error, touched },
      dropdownSelectName,
      dropdownSelectId,
      dropdownSelectLabel,
      dropdownSelectPlaceholder,
    } = this.props;

    const tableOptions =
      tablesLoading || !tables
        ? []
        : tables.map((table) => ({
            label: table.name,
            value: table.name,
          }));

    return (
      <div className={classNames(styles.row)}>
        <DropdownSelectInput
          id={dropdownSelectId}
          name={dropdownSelectName}
          touched={touched}
          error={error}
          valid={true}
          disabled={!tablesLoaded}
          label={dropdownSelectLabel}
          placeholder={dropdownSelectPlaceholder}
          value={tableOptions.find((o) => o.value === value)}
          onChange={(option?: OptionType) => onChange(option?.value)}
          onFocus={onFocus}
          onBlur={() => onBlur(value)}
          isLoading={tablesLoading}
          options={tableOptions}
          clearable={true}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={styles.inputTable}>{this.renderRowInputTable()}</div>
    );
  }
}
