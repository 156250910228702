import React from 'react';
import HabitatNameAndCodeContainer from './HabitatNameAndCode.container';
import datapoolMsgs from 'common/dist/messages/datapools';
import DatapoolSettingsElement from '../../../../../_interfaces/datapool-details/settings/DatapoolSettingsElement';

/**
 * Shows the augur name & code in the augur settings tab
 */
export default class HabitatNameAndCode implements DatapoolSettingsElement {
  // @ts-ignore
  component: React.Component = HabitatNameAndCodeContainer;
  title = datapoolMsgs.msgDatapoolSettingsHabitatTitle;
  description = datapoolMsgs.msgDatapoolSettingsHabitatDescription;
}
