import React, { FC } from 'react';

import AreaWithStroke from './AreaWithStroke';
import Bars from './Bars';
import { ConvertedDataEntry } from '../SelectableTimeline';
import { ScaleContinuousNumeric } from 'd3-scale';

type Props = {
  data: ConvertedDataEntry[];
  xScale: ScaleContinuousNumeric<number, number>;
  yScale: ScaleContinuousNumeric<number, number>;
  height: number;
  width: number;
  barWidth: number;
  paddingX: number;
};

const TimelineChart: FC<Props> = (props) => {
  const { data, height, xScale, yScale, barWidth, width } = props;

  return (
    <g>
      <AreaWithStroke
        data={data}
        xScale={xScale}
        yScale={yScale}
        height={height}
        width={width}
      />
      <Bars
        data={data}
        xScale={xScale}
        yScale={yScale}
        height={height}
        barWidth={barWidth}
      />
    </g>
  );
};
export default TimelineChart;
