import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import AppsOverview from './AppsOverview.container';
import { appsRoutes } from './routes';
import StartingApp from './starting-app/StartingApp.container';
import { RouteComponentProps } from 'react-router';
import NoPermissions from '../pages/no-permissions/NoPermissions';

type Props = {
  missingPermissionsError: boolean;
};

class Apps extends Component<Props & RouteComponentProps> {
  render() {
    const { missingPermissionsError } = this.props;

    if (missingPermissionsError) {
      return <NoPermissions fullViewHeight />;
    }

    return (
      <Switch>
        <Route exact path={appsRoutes.basePath} component={AppsOverview} />

        <Route
          exact
          path={
            appsRoutes.basePath +
            appsRoutes.runPath +
            appsRoutes.runAppCode +
            appsRoutes.runVersionCode
          }
          component={StartingApp}
        />
      </Switch>
    );
  }
}

export default withRouter(Apps);
