import React, { Component, RefObject } from 'react';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';
import ButtonBar from './ButtonBar.container';
import AceEditor from 'react-ace';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import CloseConfirm from '../../../part-right/editor/close-confirm/CloseConfirm.container';
import ReactAce from 'react-ace';

export type Props = {
  path: string;
  content: object;
  unsavedChanges?: boolean;
  saveNotebook(...args: unknown[]): unknown;
  changeCodeContent(...args: unknown[]): unknown;
  showCloseConfirm?: boolean;
  paneId: string;
};
export type State = {
  tab: string;
};

export default class MarkdownContent extends Component<Props, State> {
  // Filled in a callback of the <AceEditor/>
  editorRef: RefObject<ReactAce>;
  constructor(props) {
    super(props);
    this.state = { tab: 'edit' }; // Switch between 'rendered' and 'edit'
    this.editorRef = React.createRef();
  }

  componentDidMount() {
    if (
      this.editorRef &&
      document.activeElement !== this.editorRef.current.editor
    ) {
      this.editorRef.current.editor.focus();
    }
  }
  keyMap = {
    save: 'command+s',
  };

  keyHandlers = {
    save: (e) => {
      this.props.saveNotebook(this.props.path, this.props.content, 'file');
      e.preventDefault();
    },
  };

  /**
   * Render the "edit" mode for the Markdown editor
   * @returns {JSX.Element}
   */
  renderEdit() {
    const { path, content, changeCodeContent } = this.props;
    return (
      <div className={'editor-con'}>
        <AceEditor
          ref={this.editorRef}
          width={'100%'}
          className={'code-cell-editor'}
          mode=''
          theme='tomorrow'
          onChange={(content) => {
            changeCodeContent({
              path,
              content,
            });
          }}
          showGutter
          highlightActiveLine
          //@ts-ignore
          value={content}
          setOptions={{
            maxLines: Infinity,
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
          editorProps={{ $blockScrolling: Infinity }}
          scrollIntoView
          readOnly={false}
        />
      </div>
    );
  }

  renderRendered() {
    const { content } = this.props;
    const parseHtml = htmlParser();

    const sourceSafe = Array.isArray(content) ? content.join('') : content;
    let sourceWithDefault: string;
    if (typeof sourceSafe === 'string') {
      sourceWithDefault = sourceSafe && sourceSafe.trim() ? sourceSafe : '';
    }

    return (
      <div className={'markdown-con'}>
        <div className={'main-container'}>
          <ReactMarkdown
            source={sourceWithDefault}
            escapeHtml={false}
            astPlugins={[parseHtml]}
          />
        </div>
      </div>
    );
  }

  render() {
    const { path, content, unsavedChanges, showCloseConfirm, paneId } =
      this.props;

    // Is the close confirm supposed to be shown?
    if (showCloseConfirm) {
      return <CloseConfirm path={path} content={content} paneId={paneId} />;
    }

    return (
      <div className={'code-content'}>
        <HotKeys
          className={'hotkeys'}
          keyMap={this.keyMap}
          handlers={this.keyHandlers}
        >
          <ButtonBar
            path={path}
            content={content}
            unsavedChanges={unsavedChanges}
            selectedTab={this.state.tab}
            onTabSelect={(tab) => {
              this.setState({ tab });
            }}
            paneId={paneId}
          />
          <div className={'editor-parent-container'}>
            {this.state.tab === 'rendered'
              ? this.renderRendered()
              : this.renderEdit()}
          </div>
        </HotKeys>
      </div>
    );
  }
}
