import { TargetFunctionSummandGroup } from 'common/dist/types/module.optimization';

export const SPEAKING_SUM_OF: {
  [x in TargetFunctionSummandGroup['sumOf']]: string;
} = {
  costs: 'Costs',
  values: 'Values',
};

export const SPEAKING_SUM_OVER: {
  [x in TargetFunctionSummandGroup['sumOver']]: string;
} = {
  allCommunications: 'All Communications',
};
