import NotebookCellWrapper from '../../NotebookCellWrapper';
import MarkdownCell from './MarkdownCell.container';

export default class MarkdownCellWrapper extends NotebookCellWrapper {
  ChildComponent = MarkdownCell;
  type = 'markdown';
  name = 'Markdown';
  parentContainerClass = 'markdown-cell';
  executable = false;
}
