import ModelSettingsCategory from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { MessageDescriptor } from 'react-intl';
import { FiPieChart } from 'react-icons/fi';
import React from 'react';
import SamplingStrategyCategory from './SamplingStrategyCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

export default class SamplingStrategyCategoryWrapper
  implements ModelSettingsCategory<AugurSettingsDataNbc>
{
  component = SamplingStrategyCategory;
  icon: React.ComponentType = () => <FiPieChart size={17} />;
  id = 'sampling-strategy';
  label: MessageDescriptor = {
    id: 'no-id',
    defaultMessage: 'Sampling Strategy',
  };
}
