import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type ExpandLinkProps = {
  onClick: () => void;
};

const ExpandLink: FC<ExpandLinkProps> = ({ onClick }) => {
  return (
    <a className='LoginHistory--expand-button' onClick={onClick}>
      <FormattedMessage
        id='account.loginHistory.datetime.show_more'
        defaultMessage='Show More'
      />
    </a>
  );
};

type ShrinkLinkProps = {
  onClick: () => void;
};

const ShrinkLink: FC<ShrinkLinkProps> = ({ onClick }) => {
  return (
    <a className='LoginHistory--expand-button' onClick={onClick}>
      <FormattedMessage
        id='account.loginHistory.datetime.show_less'
        defaultMessage='Show Less'
      />
    </a>
  );
};

type Props = {
  expanded: boolean;
  onExpand: () => void;
  onShrink: () => void;
};

const Expanded: FC<Props> = ({ expanded, onExpand, onShrink }) => {
  return (
    <div className='LoginHistory--expand-link'>
      {expanded ? (
        <ShrinkLink onClick={onShrink} />
      ) : (
        <ExpandLink onClick={onExpand} />
      )}
    </div>
  );
};

export default Expanded;
