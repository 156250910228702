import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import CodeCellOutput from '../../../../../../../../../../organisms/workbench-cells/code-cell/CodeCellOutput';
import { outputElementShape } from '../_interface/P3OutputElementShape';

export default class CodeOutput extends Component {
  renderLarge() {
    const { path, cell, element, updateDataOfOutputElement } = this.props;
    return (
      <Fragment>
        <div className={'editor-parent-container'}>
          <div
            className={`editor-container${
              element.data &&
              element.data.outputs &&
              element.data.outputs.length > 0
                ? ' has-output'
                : ''
            }`}
          >
            <AceEditor
              width={'100%'}
              className={'code-cell-editor'}
              mode='python'
              theme='tomorrow'
              onChange={(source) => {
                updateDataOfOutputElement(path, cell.id, element, { source });
              }}
              showGutter
              highlightActiveLine
              value={
                element.data && element.data.source ? element.data.source : ''
              }
              setOptions={{
                maxLines: Infinity,
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              editorProps={{ $blockScrolling: Infinity }}
              scrollIntoView
            />
          </div>
        </div>
        {element.data &&
          element.data.outputs &&
          element.data.outputs.length > 0 && (
            <CodeCellOutput
              outputs={element.data.outputs}
              path={path}
              cellId={cell.id}
              cellOutputExpanded={cell.cellOutputExpanded}
            />
          )}
      </Fragment>
    );
  }

  renderSlim() {
    const { path, cell, element } = this.props;
    return (
      <Fragment>
        {element.data &&
          element.data.outputs &&
          element.data.outputs.length > 0 && (
            <CodeCellOutput
              outputs={element.data.outputs}
              path={path}
              cellId={cell.id}
              cellOutputExpanded={true}
            />
          )}
      </Fragment>
    );
  }

  render() {
    const { displayMode } = this.props;
    if (displayMode === 'slim') {
      return this.renderSlim();
    } else {
      // displayMode === 'large'
      return this.renderLarge();
    }
  }
}
CodeOutput.propTypes = {
  /** The cell this output element belongs to */
  cell: PropTypes.shape({}),
  /** Path of the notebook the cell of the output element belongs to */
  path: PropTypes.string.isRequired,
  /** Description of the output element itself */
  element: outputElementShape,
  /** Redux Action to remove an output element */
  removeOutputElement: PropTypes.func.isRequired,
  /** To change the source of an output element */
  updateDataOfOutputElement: PropTypes.func.isRequired,
  /** Is the element shown in the notebook or in the app? */
  parentType: 'notebook' | 'app',
  /** Render all the input fields and stuff or simply the output? */
  displayMode: 'slim' | 'large',
};
