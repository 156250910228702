import React, { Component } from 'react';
import { FiX } from 'react-icons/fi';
import ReactModal from 'react-modal';
import Upload from './upload/Upload.container';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
export type Props = {
  isUploadModalOpen: boolean;
  selectedDirPath: string[];
  hideUploadModal: () => void;
};

export default class UploadModal extends Component<Props> {
  render() {
    const { isUploadModalOpen, hideUploadModal, selectedDirPath } = this.props;
    return (
      <ReactModal
        isOpen={isUploadModalOpen}
        contentLabel='Upload Modal'
        onRequestClose={hideUploadModal}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer') ?? document.body
          /* Function that will be called to get the parent element
             that the modal will be attached to.
             The #MainContainer does not exist in storybook. Therefore attach the modal to the body if it was not found
             */
        }
      >
        <div className={'modal-parent upload-modal-parent'}>
          <div
            title={'Close Dialog'}
            className={'workbench-button close-button'}
            onClick={hideUploadModal}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <Upload
            isClosable={true}
            uploadId={'workbenchContent'}
            onClose={hideUploadModal}
            targetDirPath={selectedDirPath}
          />
        </div>
      </ReactModal>
    );
  }
}
