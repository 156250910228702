import { postApiRequest } from './_apiRequests';

export function restartKernel(kernelId, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/kernels/${kernelId}/restart`;
  const body = {
    type: 'directory',
  };
  return postApiRequest(url, body);
}

export function interruptKernel(kernelId, jupyterUser) {
  const now = new Date().getTime();
  const url = `/jupyter/user/${jupyterUser}/api/kernels/${kernelId}/interrupt?${now}`;
  const body = {};
  return postApiRequest(url, body);
}
