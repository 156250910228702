import React, { FC } from 'react';
import styles from './styles.module.scss';
import { OptionProps } from 'react-select';

import classNames from 'classnames';

export type DescriptionOptionType = {
  label: string;
  value: string;
  description?: string;
  isDisabled?: boolean;
};
type OptionComponentProps = OptionProps<DescriptionOptionType, false>;
const DescriptionOption: FC<OptionComponentProps> = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <div
      {...innerProps}
      className={classNames(
        styles.descriptionOption,
        { [styles.isSelected]: props.isSelected },
        { [styles.isDisabled]: props.isDisabled }
      )}
      ref={innerRef}
    >
      <span className={styles.label}>{props.data.label}</span>
      {props.data.description && (
        <span className={styles.description}>{props.data.description}</span>
      )}
    </div>
  );
};

export default DescriptionOption;
