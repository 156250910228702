import { connect } from 'react-redux';
import GitBranches from './GitBranches';
import {
  showCreateBranchModal,
  showDeleteBranchModal,
  showFetchBranchModal,
} from '../../../../../../redux/workbench/modules/repository.module';
import {
  getGitRemote,
  gitActiveBranch,
  gitListBranches,
  gitSwitchBranch,
} from '../../../../../../redux/workbench/modules/container-interactions.module';
import { fetchBranches } from '../../../../../../redux/workbench/modules/collab.repository.module';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

export function mapStateToProps(state, { location }) {
  const { loading, loaded, data, error } =
    state.workbench.showRepositoryInfo.gitBranches;
  const infoFilePath = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).path;
  const repoName = (state.workbench.content.repoMeta || {}).repoName || '';
  return {
    activeBranch: (state.workbench.showRepositoryInfo || {}).activeBranch,
    remote: (state.workbench.showRepositoryInfo || {}).remote,
    remoteBranches: ((state.workbench.collab || {}).branches || {}).data,
    infoFilePath,
    loading,
    loaded,
    data,
    error,
    repoName,
  };
}

export const mapDispatchToProps = {
  showCreateBranchModal,
  gitListBranches,
  gitSwitchBranch,
  showDeleteBranchModal,
  gitActiveBranch,
  getGitRemote,
  fetchBranches,
  showFetchBranchModal,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GitBranches)
);
