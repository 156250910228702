import { connect } from 'react-redux';
import Upload from './Upload';

export function mapStateToProps(state) {
  return {
    activeWizard: state.data.upload.activeWizard,
  };
}

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
