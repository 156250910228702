import { FC } from 'react';

export type Props = {
  rows: string[][];
};

const TableBody: FC<Props> = (props) => {
  return (
    <tbody>
      {
        // Loop over the table rows
        props.rows.map((row) => {
          return (
            // Apply the row props
            <tr>
              {
                // Loop over the rows cells
                row.map((cell) => (
                  // Apply the cell props
                  <td>
                    {
                      // Render the cell contents
                      cell
                    }
                  </td>
                ))
              }
            </tr>
          );
        })
      }
    </tbody>
  );
};

export default TableBody;
