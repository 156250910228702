import React, { Component, ReactNode } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import styles from './styles.module.scss';
import { FiX } from 'react-icons/fi';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Button, { ButtonProps } from '../../atoms/button/Button';

export type Props = {
  /** Is the modal supposed to be shown? */
  show: boolean;
  /** Callback to hide the modal */
  hideModal: () => void;
  children: ReactNode;
  /** Optional headline for the modal */
  headline?: MessageDescriptor;
  /** Specs for the optional buttons */
  buttons?: ButtonProps[];
  /** Make the modal always grow to full height? */
  alwaysFullHeight?: boolean;
  /** Make the modal always grow to full width? */
  alwaysFullWidth?: boolean;
} & Pick<ReactModalProps, 'parentSelector'>;

export default class Modal extends Component<Props> {
  render() {
    const {
      show,
      hideModal,
      children,
      headline,
      buttons,
      alwaysFullHeight,
      alwaysFullWidth,
      /** Function that will be called to get the parent element that the modal will be attached to.
       *  The default only works inside a tab like Model Management. As a default fallback (e.g. storybook) attach the modal to the body if it was not found */
      parentSelector = () =>
        document.querySelector('#MainContainer') ?? document.body,
    } = this.props;

    const customStyles = {
      content: {
        top: 'calc(50% + 55px)', // Not really sure where the +55px are coming from - simply measured it.
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        minHeight: '120px',
        maxHeight: 'calc(100vh - 120px - 60px)', // 30px margin top and bottom
        height: alwaysFullHeight ? 'calc(100vh - 120px - 60px)' : 'unset',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        maxWidth: '80vw',
        width: alwaysFullWidth ? '80vw' : 'unset',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible', // For dropdowns
      },
    };

    return (
      <ReactModal
        isOpen={show}
        onRequestClose={hideModal}
        // @ts-ignore
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={parentSelector}
      >
        <div className={styles.modalHeader}>
          {headline && (
            <div className={styles.modalHeadline}>
              <FormattedMessage {...headline} />
            </div>
          )}

          <div
            title={'Close Dialog'}
            className={styles.modalClose}
            onClick={hideModal}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>
        </div>

        <div className={styles.modalBody}>{children}</div>

        {buttons && (
          <div className={styles.modalButtons}>
            {buttons.map((buttonArgs, i) => (
              <Button {...buttonArgs} key={`btn_${i}`} />
            ))}
          </div>
        )}
      </ReactModal>
    );
  }
}
