import NotFound from '../pages/not-found/NotFound.container';
import * as routes from '../index/routes';
import Versions from './subpages/versions/Versions.container';
import Executions from './subpages/executions/Executions.container';

export const codeCapsuleDefaultPage = routes.app.codeCapsule.versions;

export default function codeCapsuleRoutes() {
  const data = [
    {
      path: `${routes.app.prefix + routes.app.codeCapsule.prefix}/${
        routes.app.codeCapsule.versions
      }`,
      component: Versions,
      routes: [],
    },
    {
      path: `${routes.app.prefix + routes.app.codeCapsule.prefix}/${
        routes.app.codeCapsule.executions
      }`,
      component: Executions,
      routes: [],
    },
  ];

  data.push({
    component: NotFound,
  });

  return data;
}
