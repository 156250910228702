import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import RadioButtonsInput from '../../../../../atoms/input-elements/radio-buttons-input/RadioButtonsInput';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import TextInputLine from '../../../../../atoms/input-elements/text-input-line/TextInputLine';

export default class StepTable extends Component {
  componentDidMount() {
    const { fetchCassandraTables, dataSourceCode, keyspaceName } = this.props;
    if (keyspaceName) {
      fetchCassandraTables(dataSourceCode, keyspaceName);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { fetchCassandraTables, dataSourceCode, keyspaceName } = this.props;
    if (keyspaceName !== prevProps.keyspaceName) {
      fetchCassandraTables(dataSourceCode, keyspaceName);
    }
  }

  render() {
    const {
      availableTables,
      tablesLoading,
      meta: { valid, error, touched },
      input: { value, onChange, onFocus, onBlur },
    } = this.props;

    const options = (availableTables || [])
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((k) => ({ label: k.name, value: k.name }));

    console.log('OPTIONS', { options, availableTables });

    return (
      <Fragment>
        <div className={'GenericFormStep--field'}>
          <RadioButtonsInput
            touched={touched}
            error={error?.strategy}
            disabled={false}
            id={'strategy'}
            hasLabel={false}
            value={value?.strategy}
            onChange={(strategy) => onChange({ ...value, strategy })}
            entriesPerRow={2}
            columnWidth={'120px'}
            radioButtons={[
              {
                value: 'existing',
                labelId: 'no-label',
                labelDefault: 'Existing',
                disabled: availableTables && availableTables.length === 0, // Disable if this Keyspace doesn't have tables
              },
              {
                value: 'create',
                labelId: 'no-label',
                labelDefault: 'Create',
              },
            ]}
          />
        </div>

        {value.strategy && value.strategy === 'existing' && (
          <div className={'GenericFormStep--field'}>
            <DropdownSelectInput
              touched={touched}
              error={error?.table}
              valid={!error?.table}
              disabled={false}
              id={'table-select'}
              name={'table-select'}
              placeholder={{
                id: 'table',
                defaultMessage: 'Select a Table',
              }}
              value={options.find((o) => o.value === value?.name)}
              onChange={(option) => onChange({ ...value, name: option.value })}
              onFocus={onFocus}
              onBlur={() => onBlur(value)}
              isLoading={tablesLoading}
              clearable={true}
              options={options}
              autoSelectIfSingle={true}
            />
          </div>
        )}

        {value.strategy && value.strategy === 'create' && (
          <div className={'GenericFormStep--field'}>
            <TextInputLine
              touched={touched}
              error={error?.name}
              valid={!error?.name}
              disabled={false}
              id={'table-name'}
              name={'table-name'}
              hasLabel={false}
              placeholderDefault={'Enter Table Name'}
              value={value?.name || ''}
              onChange={(e) => onChange({ ...value, name: e.target.value })}
              onBlur={() => onBlur(value)}
              onFocus={onFocus}
            />
          </div>
        )}

        {value.strategy && value.strategy === 'existing' && (
          <div className={'GenericFormStep--field'}>
            <RadioButtonsInput
              touched={touched}
              error={error?.concatStrategy}
              disabled={false}
              id={'concatStrategy'}
              hasLabel={false}
              value={value?.concatStrategy}
              onChange={(concatStrategy) =>
                onChange({ ...value, concatStrategy })
              }
              entriesPerRow={2}
              columnWidth={'200px'}
              radioButtons={[
                {
                  value: 'append',
                  labelId: 'no-label',
                  labelDefault: 'Append',
                },
                {
                  value: 'recreate',
                  labelId: 'no-label',
                  labelDefault: 'Delete & Re-Create',
                },
              ]}
            />
          </div>
        )}
      </Fragment>
    );
  }
}
StepTable.propTypes = {
  dataSourceCode: PropTypes.string.isRequired,
  /** Name of the Keyspace that was selected by the user in the previous step */
  keyspaceName: PropTypes.string.isRequired,
  fetchCassandraTables: PropTypes.func.isRequired,
  tablesLoading: PropTypes.bool,
  availableTables: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  input: PropTypes.shape({
    value: PropTypes.shape({
      /** existing | create */
      strategy: PropTypes.string,
      /** Name of the selected Keyspace */
      name: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.shape({
    valid: PropTypes.bool,
    error: PropTypes.object, // TODO or is it a string?
  }),
};
