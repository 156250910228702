import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputElementShape } from '../_interface/P3InputElementShape';

export default class TextInput extends Component {
  render() {
    const { path, cellId, updateDataOfInputElement, element } = this.props;

    return (
      <div className={'inner-input-element text-input-element'}>
        <input
          type={'text'}
          onChange={(v) =>
            updateDataOfInputElement(path, cellId, element, {
              text: v.target.value,
            })
          }
          value={element.data.text}
        />
      </div>
    );
  }
}

TextInput.propTypes = {
  path: PropTypes.string.isRequired,
  cellId: PropTypes.string.isRequired,
  /** Function to update the user input */
  updateDataOfInputElement: PropTypes.func.isRequired,
  /** Input element (including the settings + the user input) */
  element: inputElementShape,
  cell: PropTypes.shape({}).isRequired,
};
