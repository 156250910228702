import { connect, ConnectedProps as ConnectedProps_ } from 'react-redux';
import NewScheduleWizard, { Props } from './NewScheduleWizard';
import {
  Data,
  fieldDescription,
  fieldName,
  jobGroupField,
  newScheduleForm,
  newScheduleFormName,
  selectedScheduleField,
} from './newSchedule.form';
import { fetchSummary } from '../../../../redux/modules/dashboard.summary.module';
import { fetchModules } from '../../../../redux/modules/modules.module';
import {
  addSchedule,
  fetchSchedule,
  hideJobPicker,
  updateSchedule,
} from '../../../../redux/modules/orchestration.schedules.module';
import {
  destroy,
  formValueSelector,
  getFormValues,
  reduxForm,
} from 'redux-form';
import { DeprecatedRootState } from '../../../../store/state.type';
import { fetchCodeCapsules } from '../../../../redux/modules/codeCapsules.module';
import qs from 'qs';
import { ScheduleParams } from '../../job-groups/JobGroups';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { fieldJobGroup } from '../../job-groups/wizard/form';
import { fetchJobGroup } from '../../../../store/jobGroups/slice';

const selector = formValueSelector(newScheduleFormName);

function mapStateToProps(
  state: DeprecatedRootState,
  ownProps: Props & RouteComponentProps
) {
  const queryParams: ScheduleParams = qs.parse(ownProps.location.search, {
    ignoreQueryPrefix: true,
  });

  const formValues: Data = getFormValues(newScheduleFormName)(state);
  const dataSources = state.data.dataSources;
  let initialValues = undefined;
  //let initialValues: Data;
  if (ownProps.edit) {
    // "Edit schedule wizard"
    const schedule = state.jobSchedules?.schedules?.[ownProps.scheduleCode];
    initialValues = {
      [jobGroupField]: schedule?.jobGroupInput,
      [selectedScheduleField]: schedule,
      [fieldName]: schedule?.jobGroupInput.name,
      [fieldDescription]: schedule?.jobGroupInput.description,
    };
  } else {
    // "Add schedule wizard"
    initialValues = {
      [fieldName]: queryParams?.name,
      [fieldDescription]: queryParams?.description,
      [fieldJobGroup]: !queryParams?.jobGroupCode
        ? null
        : state.jobGroups?.data?.find(
            (jg) => jg.code === queryParams.jobGroupCode
          ),
    };
  }
  return {
    dataSources,
    formValues,
    initialValues,
    jobPickerIsActive: state.newSchedule.showJobPicker,
    submitting: state.newSchedule.submitting,
    selectedSchedule: selector(state, selectedScheduleField),
    jobGroup: selector(state, jobGroupField),
    summary: state.summary,
    initialJobGroupCode: queryParams?.jobGroupCode,
    jobGroupsLoading: state.jobGroups?.loading,
  };
}

export const mapDispatchToProps = {
  fetchModules,
  fetchSummary,
  addSchedule,
  updateSchedule,
  fetchSchedule,
  destroyScheduleForm: () => destroy(newScheduleFormName),
  fetchCodeCapsules,
  hideJobPicker,
  fetchJobGroup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ConnectedProps = ConnectedProps_<typeof connector>;
export default withRouter(
  connector(reduxForm<Data>(newScheduleForm)(NewScheduleWizard))
);
