import React, { FC } from 'react';
import { ToBeRefined } from 'common/dist/types/todo_type';
import TextInputLinePassword from '../../../atoms/input-elements/text-input-line-password/TextInputLinePassword';

type Props = {
  input: {
    value: string;
    onChange: (e: ToBeRefined) => void;
  };
  meta: {
    error: ToBeRefined;
    valid: boolean;
    touched: boolean;
  };
};

const NewPassword: FC<Props> = (props) => {
  const {
    input: { value, onChange },
    meta: { error, touched },
  } = props;
  return (
    <TextInputLinePassword
      touched={touched}
      error={error}
      id={'change-password-new'}
      label={{
        id: 'account.password.new_password',
        defaultMessage: 'New Password',
      }}
      placeholder={{
        id: '',
        defaultMessage: 'Type here ...',
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default NewPassword;
