import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

interface StatisticsListProps {
  tables?: {
    name: string;
  }[];
  selectedTables: string[];
  updateSelectedTables(...args: unknown[]): unknown;
  onShowStats(...args: unknown[]): unknown;
}

export default class StatisticsList extends Component<StatisticsListProps> {
  render() {
    const { tables, selectedTables, updateSelectedTables, onShowStats } =
      this.props;
    return (
      <div className={'ct-list'}>
        <div className={'ct-headline ct-row'}>
          <div className={'ct-col ct-col-flex-grow'}>
            <FormattedMessage
              id='common.table_name'
              defaultMessage='Table Name'
            />
          </div>
          <div className={'ct-col ct-col-200px'}>
            <FormattedMessage
              id='common.calculate_statistics'
              defaultMessage='Calculate Statistics'
            />
          </div>
        </div>
        {tables.map((table, i) => (
          <Fragment key={i}>
            <div className={'ct-row'} key={i}>
              <div
                className={'ct-col ct-col-flex-grow'}
                onClick={() => onShowStats(table.name)}
              >
                <div className={'ct-value'}>{table.name}</div>
              </div>
              <div className={'ct-col ct-col-200px ct-col-low-pad'}>
                <div className={'ct-value checkbox-item-parent'}>
                  <label className='checkbox-item'>
                    <input
                      className='mark-input'
                      type='checkbox'
                      checked={selectedTables.includes(table.name)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          updateSelectedTables([...selectedTables, table.name]);
                        } else {
                          updateSelectedTables(
                            selectedTables.filter((n) => n !== table.name)
                          );
                        }
                      }}
                    />
                    <span className='mark' />
                  </label>
                </div>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    );
  }
}
