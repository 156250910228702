import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  fetchQueryFn,
  postApiRequest,
  putApiRequest,
} from './_tools';
import { patchApiRequest } from './workbench/_apiRequests';
import qs from 'qs';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { UserType as User } from '../../components/admin/users/user-list/UsersList';
import { AddUserSubmitValues } from '../../components/admin/users/add-user/AdminAddUserWizard';
import { HabitatNamesOnlyType } from 'common/dist/types/habitat';
import { RealmRoleMappingRequestBody } from 'common/dist/types/requestBodies/permissions';
import { GenericSuccessResponseBody } from 'common/dist/types/responseBodies/base';

export const userKeys = {
  all: () => ['users'] as const,
  users: (offset?: number, limit?: number, search?: string) =>
    [...userKeys.all(), offset, limit, search] as const,
  add: () => [...userKeys.all(), 'add'] as const,
};

export function getUsersList(
  offset?: number,
  limit?: number,
  search?: string
): CompletedApiRequest<User[]> {
  return apiRequest(
    `/api/users${qs.stringify(
      { offset, limit, search },
      { addQueryPrefix: true }
    )}`
  );
}

export function useUsersList(
  offset?: number,
  limit?: number,
  search?: string
): UseQueryResult<User[]> {
  const key = userKeys.users(offset, limit, search);
  return useQuery(
    key,
    () => fetchQueryFn(key, () => getUsersList(offset, limit, search)),
    {
      keepPreviousData: true,
    }
  );
}

// TODO: this is not a good type to use
export function addUser(data: AddUserSubmitValues) {
  return postApiRequest(`/api/users`, data);
}

export function useAddUser(): UseMutationResult {
  const queryClient = useQueryClient();
  const key = userKeys.add();
  return useMutation(
    key,
    (submitValues: AddUserSubmitValues) =>
      fetchQueryFn(key, () => addUser(submitValues)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(userKeys.all());
      },
    }
  );
}

export function updateUser(userCode, userData) {
  return putApiRequest(`/api/users/${userCode}`, userData);
}

export function changePassword(userId, newPassword, temporary) {
  return patchApiRequest(`/api/users/${userId}`, {
    newPassword,
    isTemporaryPassword: temporary,
  });
}

export function getGroups() {
  return apiRequest('/api/groups');
}

export function getGroupMembers(groupId) {
  return apiRequest(`/api/groups/${groupId}/members`);
}

export function addGroup(data) {
  return postApiRequest('/api/groups', data);
}

export function getUserDetails(userId) {
  return apiRequest(`/api/users/id/${userId}`);
}

export function getRoles() {
  return apiRequest('/api/roles');
}

export function addRealmRoleMapping(
  userId: string,
  roleId: string,
  roleName: string
): CompletedApiRequest<GenericSuccessResponseBody> {
  const body: RealmRoleMappingRequestBody = { roleId, roleName };
  return postApiRequest(`/api/users/${userId}/role-mapping/realm`, body);
}

export function removeRealmRoleMapping(
  userId: string,
  roleId: string,
  roleName: string
): CompletedApiRequest<GenericSuccessResponseBody> {
  const body: RealmRoleMappingRequestBody = { roleId, roleName };
  return postApiRequest(`/api/users/${userId}/role-mapping/realm/delete`, body);
}

export function joinGroup(
  userId: string,
  groupId: string
): CompletedApiRequest<GenericSuccessResponseBody> {
  const body = { groupId };
  return putApiRequest(`/api/users/${userId}/group`, body);
}

export function leaveGroup(
  userId: string,
  groupId: string
): CompletedApiRequest<GenericSuccessResponseBody> {
  return deleteApiRequest(`/api/users/${userId}/group/${groupId}`);
}

export function addGroupPermission(resourceName, type, scopeName, group) {
  const body = { resourceName, type, scopeName, group };
  return postApiRequest('/api/permissions/group', body);
}

export function removeGroupPermission(resourceName, scopeName, group) {
  const body = { resourceName, scopeName, group };
  return postApiRequest('/api/permissions/group/delete', body);
}

export function addUserPermission(resourceName, type, scopeName, user) {
  const body = { resourceName, type, scopeName, user };
  return postApiRequest('/api/permissions/user', body);
}

export function removeUserPermission(resourceName, scopeName, user) {
  const body = { resourceName, scopeName, user };
  return postApiRequest('/api/permissions/user/delete', body);
}

export function getUserPermissions(userId) {
  return apiRequest(`/api/permissions/user/${userId}`);
}

export function getGroupPermissions(groupId) {
  return apiRequest(`/api/permissions/group/${groupId}`);
}

export function fetchNamesForAdminPermission(): CompletedApiRequest<
  HabitatNamesOnlyType[]
> {
  return apiRequest('/api/admin/namesForAdminPermission');
}
