import React, { FC } from 'react';
import PieChart, {
  LegendEntryType,
} from '../../../../../../../components/molecules/charts/pie-chart/PieChart';
import { Misclassification as MissclassificationType } from 'common/dist/types/reports';

type Props = {
  detailsLink: string;
  data: MissclassificationType;
  legend: LegendEntryType[];
};

const Misclassification: FC<Props> = (props: Props) => {
  const { detailsLink, data, legend } = props;
  return (
    <div className={'Accuracy--chart-container'}>
      <PieChart
        height={'100%'}
        width={'100%'}
        data={data}
        legend={legend}
        title={{
          id: 'details.accuracy.misclassification',
          defaultMessage: 'Misclassification',
        }}
        // @ts-ignore
        link={{
          to: detailsLink,
        }}
        withBorder={true}
      />
    </div>
  );
};

export default Misclassification;
