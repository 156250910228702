import React, { Component } from 'react';
import './styles.scss';
import { ToBeRefined } from 'common/dist/types/todo_type';

export type Props = {
  /** Fetch the information about the disk usage */
  getDiskUsage: () => void;
  /** Is the disk usage data loading? */
  loading: boolean;
  /** Is the disk usage data loaded? */
  loaded: boolean;
  /** Was there an error while loading the disk usage data? */
  error?: ToBeRefined;
  /** Data about the disk usage */
  data?: {
    /** Total available disk in bytes */
    total: number;
    /** Total used disk in bytes */
    used: number;
    /** Total free disk in bytes */
    free: number;
  };
};

export default class DiskUsage extends Component<Props, {}> {
  componentDidMount() {
    const { getDiskUsage } = this.props;
    getDiskUsage();
  }

  renderLoaded() {
    const { data } = this.props;

    const usedFormatted = (data?.used / (1000 * 1000 * 1000)).toFixed(2);
    const totalFormatted = (data?.total / (1000 * 1000 * 1000)).toFixed(2);
    const message = `Storage: Used ${usedFormatted} GB of ${totalFormatted} GB`;

    return (
      <div className={'DiskUsage'}>
        <span>{message}</span>
      </div>
    );
  }

  renderLoading() {
    return <div className={'DiskUsage'}>Loading ...</div>;
  }

  renderError() {
    const { error } = this.props;
    return <div className={'DiskUsage'}>{JSON.stringify(error)};</div>;
  }

  render() {
    const { loading, error, data } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (data) return this.renderLoaded();
    else return null;
  }
}
