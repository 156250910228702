import React, { FC, ReactChild, ReactChildren } from 'react';
import styles from './styles.module.scss';
import { FiInfo } from 'react-icons/fi';

export type Props = {
  children: ReactChild | ReactChildren;
};

const InfoBox: FC<Props> = ({ children }) => {
  return (
    <div className={styles.infoBox}>
      <div className={styles.iconContainer}>
        <FiInfo size={16} />
      </div>
      <div className={styles.infoContainer}>{children}</div>
    </div>
  );
};

export default InfoBox;
