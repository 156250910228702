import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputValueSafe } from '../../python3-input-cell/elements/_interface/input-element.form';
import { FiBookmark } from 'react-icons/fi';
import { DateTimePicker } from 'react-widgets';
import { formatDate } from 'common/dist/utils/dates';

export default class DatetimePickerCol extends Component {
  render() {
    const {
      label,
      input: { onChange, name, onBlur, onFocus, value },
      meta: { touched, error },
      allowVariable,
      showSelectVariable,
      path,
      cellId,
      validVariableTypes,
      useTime,
      useDate,
    } = this.props;

    const datePattern = `${useDate ? 'yyyy-MM-dd' : ''} ${
      useTime ? 'HH:mm:ss' : ''
    }`.trim();
    const formatPattern = `${useDate ? 'YYYY-MM-DD' : ''} ${
      useTime ? 'HH:mm:ss' : ''
    }`.trim();

    const inputValue = inputValueSafe(value);
    const valueOrDefault = inputValue ? new Date(inputValue) : undefined;

    return (
      <div className={`input-col${error ? ' error' : ''}`}>
        <div className={'form--headline'}>
          <p className={'form--label'}>{label}</p>
          {error && <p className={'form--error'}>{error}</p>}
        </div>
        <div className={'form--input-parent'}>
          <DateTimePicker
            onChange={(datetime) =>
              onChange({ inputValue: formatDate(datetime, datePattern) })
            }
            value={valueOrDefault}
            format={formatPattern}
            time={useTime}
            date={useDate}
          />
          {allowVariable && (
            <div
              title={'Use variable value'}
              className={`workbench-button use-variable-button origin-${
                value.origin || 'input'
              }`}
              onClick={() =>
                showSelectVariable(
                  path,
                  cellId,
                  name,
                  validVariableTypes,
                  label
                )
              }
            >
              <FiBookmark className={'icon'} size={'20px'} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
DatetimePickerCol.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.shape({
      origin: PropTypes.string,
      variableName: PropTypes.string,
      inputValue: PropTypes.string,
    }),
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  allowVariable: PropTypes.bool,
  path: PropTypes.string,
  cellId: PropTypes.string,
  showSelectVariable: PropTypes.func.isRequired,
  /** What types are allowed to be selected as variables? */
  validVariableTypes: PropTypes.arrayOf(PropTypes.string),
  useTime: PropTypes.bool,
  useDate: PropTypes.bool,
};
DatetimePickerCol.defaultProps = {
  allowVariable: false,
  useTime: true,
  useDate: true,
};
