import { createAction } from 'redux-act';
import { selectNotebook, updateNotebookMetadata } from './utils/notebooks';

export const showConfigureArchetypeModal = createAction(
  'show configure archetype modal',
  (path) => ({ path })
);

export const hideConfigureArchetypeModal = createAction(
  'hide configure archetype modal'
);

export const updateArchetypeConfiguration = createAction(
  'update archetype configuration',
  (path, configuration) => ({ path, configuration })
);

export const reducer = {
  [showConfigureArchetypeModal](state, { path }) {
    const notebook = state.notebooks[path];
    return {
      ...state,
      archetype: {
        ...state.archetype,
        isConfigureModalShown: true,
        path,
        notebook,
      },
    };
  },
  [hideConfigureArchetypeModal]: (state) => ({
    ...state,
    archetype: {
      ...state.archetype,
      isConfigureModalShown: false,
    },
  }),
  [updateArchetypeConfiguration]: (state, { path, configuration }) => {
    const notebook = selectNotebook(state, path);
    const updatedFields = {
      as_archetype: {
        ...(notebook.content.metadata.as_archetype || {}),
        ...configuration,
      },
    };
    return updateNotebookMetadata(state, path, updatedFields);
  },
};
