import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { fieldNameDefaultValue } from '../_interface/input-element.form';
import ToggleCol from '../../../settings-dialog/dialog-settings-cols/ToggleCol';
import { inputModalForm } from '../../../settings-dialog/inpmodalDialog.form';

class ToggleSettings extends Component {
  render() {
    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldNameDefaultValue}
            label={'Default Value (optional)'}
            component={ToggleCol}
            placeholder={'Enter Default Value'}
          />
        </div>
      </Fragment>
    );
  }
}

export default reduxForm(inputModalForm)(ToggleSettings);
