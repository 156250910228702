import { createAction } from 'redux-act';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const loadHabitat = createAction(
  'load habitat',
  (habitatCode) => habitatCode
);

export const loadHabitatSuccess = createAction(
  'load habitat - success',
  (habitat) => habitat
);

export const loadHabitatFail = createAction(
  'load habitat - fail',
  (error) => error
);

export const ensureHabitatInSummary = createAction(
  'ensure habitat in summary',
  (habitatCode) => habitatCode
);

export const reducer = {
  [loadHabitat]: (state, habitatCode) => ({
    ...state,
    habitat: {
      code: habitatCode,
      loading: true,
      loaded: false,
    },
  }),
  [loadHabitatSuccess]: (state, { id, name, description, code }) => ({
    ...state,
    habitat: {
      loading: false,
      loaded: true,
      id,
      name,
      description,
      code,
    },
  }),
  [loadHabitatFail]: (state, error) => ({
    ...state,
    habitat: {
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* loadHabitatData({ payload: habitatCode }) {
  const { response, error } = yield call(Api.habitats.getHabitat, habitatCode);
  if (response) {
    yield put(loadHabitatSuccess(response));
  } else {
    yield put(loadHabitatFail(error));
  }
}

export function* watchLoadHabitat() {
  yield takeEvery(loadHabitat.getType(), loadHabitatData);
}

export function* ensureHabitatInSummarySaga({ payload: habitatCode }) {
  const isHabitatLoaded = yield select((state) => {
    state.summary.find(({ code }) => code === habitatCode);
  });

  if (!isHabitatLoaded) put(loadHabitat(habitatCode));
}

export function* watchEnsureHabitatInSummary() {
  yield takeEvery(ensureHabitatInSummary.getType(), ensureHabitatInSummarySaga);
}
