import React, { Component, ReactChild, ReactChildren } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { HierarchyOptionElement } from '../HierarchyCheckboxesInput';
type Props = {
  options: HierarchyOptionElement[];
  children: ReactChild | ReactChildren;
  onChange: (targets: string[], isChecked: boolean) => void;
  initial?: string[];
};

type State = {
  checkedTree: CheckedTreeType[];
};
type CheckedTreeType = {
  isChecked: boolean;
  isExpanded: boolean;
  parentTarget?: string;
  sub: string[];
};
export default class Hierarchy extends Component<Props, State> {
  static defaultProps = {
    onChange: () => {},
  };

  static recursiveReducer(accumulator, target) {
    /* eslint-disable no-param-reassign */
    const hasChildren = Array.isArray(target.sub);
    if (hasChildren) {
      target.sub.reduce(Hierarchy.recursiveReducer, accumulator);
      target.sub.forEach((t) => {
        accumulator[t.id].parentTarget = target.id;
      });
    }
    accumulator[target.id] = {
      isChecked: false,
      isExpanded: true,
      sub: hasChildren ? target.sub.map((t) => t.id) : [],
    };
    return accumulator;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      checkedTree: props.options.reduce(Hierarchy.recursiveReducer, {}),
    };

    this.onChange = this.onChange.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.getCheckedState = this.getCheckedState.bind(this);
    this.getExpandedState = this.getExpandedState.bind(this);
  }

  onChange(element: HierarchyOptionElement) {
    const { onChange } = this.props;
    const elementId = element.id;
    this.setState(
      ({ checkedTree }) => {
        if (checkedTree.hasOwnProperty(elementId)) {
          const nextCheckedTree = cloneDeep(checkedTree);
          const isChecked = nextCheckedTree[elementId].isChecked;
          this.checkChildren(elementId, nextCheckedTree, !isChecked);
          let parentTarget = nextCheckedTree[elementId].parentTarget;
          while (parentTarget) {
            nextCheckedTree[parentTarget].isChecked = nextCheckedTree[
              parentTarget
            ].sub.every((tid) => nextCheckedTree[tid].isChecked);
            parentTarget = nextCheckedTree[parentTarget].parentTarget;
          }
          return { checkedTree: nextCheckedTree };
        }
        return { checkedTree };
      },
      () => {
        const { checkedTree } = this.state;
        const isChecked = checkedTree[elementId].isChecked;
        const parentTargets = [];
        let parentTarget = checkedTree[elementId].parentTarget;
        while (
          parentTarget &&
          checkedTree[parentTarget].isChecked === isChecked
        ) {
          parentTargets.push(parentTarget);
          parentTarget = checkedTree[parentTarget].parentTarget;
        }
        const childrenTargets = this.getChildren(elementId, checkedTree);
        onChange([...parentTargets, ...childrenTargets], isChecked);
      }
    );
  }

  getCheckedState(elementId: string) {
    const { checkedTree } = this.state;
    return checkedTree.hasOwnProperty(elementId)
      ? checkedTree[elementId].isChecked
      : false;
  }

  getExpandedState(elementId: string) {
    const { checkedTree } = this.state;
    return checkedTree.hasOwnProperty(elementId)
      ? checkedTree[elementId].isExpanded
      : true;
  }

  getChildren(elementId: string, checkedTree) {
    const hasChildren = Array.isArray(checkedTree[elementId].sub);
    let result = [elementId];
    if (hasChildren) {
      result = result.concat(
        checkedTree[elementId].sub.flatMap((tid) =>
          this.getChildren(tid, checkedTree)
        )
      );
    }
    return result;
  }

  toggleExpand(elementId: string) {
    this.setState(({ checkedTree }) => {
      if (checkedTree.hasOwnProperty(elementId)) {
        const nextCheckedTree = cloneDeep(checkedTree);
        nextCheckedTree[elementId].isExpanded =
          !nextCheckedTree[elementId].isExpanded;
        return { checkedTree: nextCheckedTree };
      }
      return { checkedTree };
    });
  }

  checkChildren(elementId, checkedTree, isChecked) {
    checkedTree[elementId].isChecked = isChecked;
    checkedTree[elementId].sub.forEach((tid) => {
      this.checkChildren(tid, checkedTree, isChecked);
    });
  }

  componentDidMount() {
    const { initial } = this.props;
    const { checkedTree } = this.state;
    initial && // For whatever reason, "initial" can be an empty string initially. (empty string).forEach is prevented this way.
      initial.forEach((elementId) => {
        if (checkedTree[elementId]) checkedTree[elementId].isChecked = true;
      });
    this.setState({ checkedTree });
  }
  componentDidUpdate(prevProps) {
    const { initial } = this.props;
    const { checkedTree } = this.state;
    if (!isEqual(prevProps.initial, initial)) {
      initial &&
        initial.forEach((elementId) => {
          if (checkedTree[elementId]) checkedTree[elementId].isChecked = true;
        });
      this.setState({ checkedTree });
    }
  }
  render() {
    const { children } = this.props;
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          onChange: this.onChange,
          toggleExpand: this.toggleExpand,
          getCheckedState: this.getCheckedState,
          getExpandedState: this.getExpandedState,
        });
      } else return null;
    });
  }
}
