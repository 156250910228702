import { apiRequest, postApiRequest, deleteApiRequest } from './_apiRequests';
import { v4 as uuidv4 } from 'uuid';

export function fetchSessions(jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/sessions`;
  return apiRequest(url);
}

/**
 * Opens a notebook session
 * TODO 'openNotebookSession' would be the better fitting name here.
 * @param notebookName
 * @param notebookPath
 * @param kernelName
 * @param jupyterUser
 * @returns {*}
 */
export function postSession(
  notebookName,
  notebookPath,
  kernelName,
  jupyterUser
) {
  const url = `/jupyter/user/${jupyterUser}/api/sessions`;
  const body = {
    kernel: {
      name: kernelName,
    },
    name: notebookName,
    path: notebookPath,
    type: 'notebook',
  };
  return postApiRequest(url, body);
}

/**
 * Opens a console session
 * @param kernelName ID of the Kernel to use: "python3" / ...
 * @param jupyterUser User of the spawned notebook (= Dashboard Login: user.code)
 * @param parentType notebook | app
 * @returns {*}
 */
export function openConsoleSession(
  { kernelName, jupyterUser, sessionName },
  parentType,
  appVersionCode
) {
  let url = '';
  if (parentType === 'app') {
    const serverName = appVersionCode.toLowerCase();
    url = `/jupyterapp/user/${jupyterUser}/${serverName}/api/sessions`;
  } else {
    // parentType === 'notebook'
    url = `/jupyter/user/${jupyterUser}/api/sessions`;
  }
  const path = `/console-${uuidv4()}`;
  const body = {
    kernel: {
      name: kernelName,
    },
    name: sessionName,
    type: 'console',
    path,
  };
  return postApiRequest(url, body);
}

export function deleteSession(sessionId, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/sessions/${sessionId}`;
  return deleteApiRequest(url);
}

/**
 * Opens a notebook session for the app execution
 * TODO 'openNotebookSessionForApp' would be the better fitting name here.
 * @param notebookName
 * @param notebookPath
 * @param kernelName
 * @param jupyterUser
 * @param serverName
 * @returns {*}
 */
export function postSessionForApp(
  notebookName,
  notebookPath,
  kernelName,
  jupyterUser,
  serverName
) {
  const url = `/jupyterapp/user/${jupyterUser}/${serverName}/api/sessions`;
  const body = {
    kernel: {
      name: kernelName,
    },
    name: notebookName,
    path: notebookPath,
    type: 'notebook',
  };
  return postApiRequest(url, body);
}

/**
 * Fetch the details of a single session
 * @param jupyterUser
 * @param sessionId
 * @returns {*}
 */
export function fetchSessionDetails(jupyterUser, sessionId) {
  const url = `/jupyter/user/${jupyterUser}/api/sessions/${sessionId}`;
  return apiRequest(url);
}
