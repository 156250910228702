import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createBackupFail,
  createBackupSuccess,
  createTemplateFail,
  createTemplateSuccess,
  fetchBackupsFail,
  fetchBackupsSuccess,
  fetchTemplatesFail,
  fetchTemplatesSuccess,
  restoreBackupFail,
  restoreBackupSuccess,
} from './actions';
import {
  CREATE_BACKUP,
  CREATE_TEMPLATE,
  CreateBackupAction,
  CreateTemplateAction,
  FETCH_BACKUPS,
  FETCH_TEMPLATES,
  RESTORE_BACKUP,
  RestoreBackupAction,
} from './actions.types';
import {
  createBackup,
  createTemplate,
  getBackups,
  getTemplates,
  restoreBackup,
} from './api';
import { sendNotification } from '../../../redux/modules/notifications.module';
import { error as errorNotification, event } from '../../../core/notifications';

export function* fetchBackupsSaga() {
  const { response, error, status } = yield call(getBackups);
  if (response) {
    yield put(fetchBackupsSuccess(response));
  } else {
    yield put(fetchBackupsFail(error));
  }
}

export function* watchFetchBackups() {
  yield takeEvery(FETCH_BACKUPS, fetchBackupsSaga);
}

export function* restoreBackupSaga({
  payload: { bakRestore },
}: RestoreBackupAction) {
  const { response, error } = yield call(restoreBackup, bakRestore);
  if (!error) {
    yield put(restoreBackupSuccess());
  } else {
    yield put(restoreBackupFail(error));
  }
}

export function* watchRestoreBackup() {
  yield takeEvery(RESTORE_BACKUP, restoreBackupSaga);
}

export function* createBackupSaga({
  payload: { bakCreate, callback },
}: CreateBackupAction) {
  const { response, error } = yield call(createBackup, bakCreate);
  if (response) {
    if (callback) callback();
    yield put(createBackupSuccess(response));
    yield put(
      sendNotification(
        'notifications.title.backup_create_success',
        // @ts-ignore
        'notifications.description.backup_create_success',
        event
      )
    );
  } else {
    yield put(createBackupFail(error));
    yield put(
      sendNotification(
        'notifications.title.backup_create_failure',
        // @ts-ignore
        'notifications.description.backup_create_failure',
        errorNotification
      )
    );
  }
}

export function* watchCreateBackup() {
  yield takeEvery(CREATE_BACKUP, createBackupSaga);
}

export function* fetchTemplatesSaga() {
  const { response, error, status } = yield call(getTemplates);
  if (response) {
    yield put(fetchTemplatesSuccess(response));
  } else {
    yield put(fetchTemplatesFail(error));
  }
}

export function* watchFetchTemplates() {
  yield takeEvery(FETCH_TEMPLATES, fetchTemplatesSaga);
}

export function* createTemplateSaga({
  payload: { templateCreate, callback },
}: CreateTemplateAction) {
  const { response, error, status } = yield call(
    createTemplate,
    templateCreate
  );
  if (response) {
    if (callback) callback();
    yield put(createTemplateSuccess(response));
    yield put(
      sendNotification(
        'notifications.title.template_create_success',
        // @ts-ignore
        'notifications.description.template_create_success',
        event
      )
    );
  } else {
    yield put(createTemplateFail(error));
    yield put(
      sendNotification(
        'notifications.title.template_create_failure',
        // @ts-ignore
        'notifications.description.template_create_failure',
        errorNotification
      )
    );
  }
}

export function* watchCreateTemplate() {
  yield takeEvery(CREATE_TEMPLATE, createTemplateSaga);
}
