import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { select } from 'd3-selection';
import { axisLeft } from 'd3-axis';
import { scaleLinear } from 'd3-scale';

export default class AxisLeft extends Component {
  static propTypes = {
    ticks: PropTypes.number.isRequired,
    tickSize: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.renderAxis(this.props);
  }

  shouldComponentUpdate(props) {
    this.renderAxis(props);
    return false;
  }

  renderAxis(props) {
    this.renderAxisTicks(props);
    this.renderHelpLines(props);
  }

  renderAxisTicks(props) {
    const { height, ticks } = props;
    const scale = scaleLinear().range([height, 0]).domain([0, 100]);
    const tickValues = scale.ticks(ticks);
    tickValues.push(0);
    const axis = axisLeft(scale)
      .tickValues(tickValues)
      .tickFormat((tick) => `${tick}%`);

    const axisNode = ReactDOM.findDOMNode(this.refs.ticks);
    select(axisNode).call(axis);
  }

  renderHelpLines(props) {
    const { height, ticks, tickSize } = props;
    const scale = scaleLinear().range([height, 0]).domain([0, 100]);
    let tickValues = scale.ticks(ticks);
    tickValues = _.without(tickValues, 0);
    const axis = axisLeft(scale).tickValues(tickValues).tickSize(-tickSize);

    const axisNode = ReactDOM.findDOMNode(this.refs.helpLine);
    select(axisNode).call(axis);
  }

  render() {
    return (
      <g className='chart-axis'>
        <g className='drift-axis-left__tick' ref='ticks'></g>
        <g
          className='misclassification-axis-left__help-line'
          ref='helpLine'
        ></g>
      </g>
    );
  }
}
