import React, { FC } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import styles from './styles.module.scss';

type Props = {
  revealPassword: () => void;
  hidePassword: () => void;
  isPasswordRevealed: boolean;
};

const ShowPassword: FC<Props> = ({
  revealPassword,
  hidePassword,
  isPasswordRevealed,
}) => {
  return (
    <div className={styles.TextInputLinePasswordRevealButtonParent}>
      <button
        type='button'
        className={styles.TextInputLinePasswordRevealButton}
        title='Show password'
        onClick={() => (isPasswordRevealed ? hidePassword() : revealPassword())}
      >
        {isPasswordRevealed ? (
          <FiEyeOff size={16} className={styles.revealIcon} />
        ) : (
          <FiEye size={16} className={styles.revealIcon} />
        )}
      </button>
    </div>
  );
};

export default ShowPassword;
