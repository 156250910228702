import React, { FC } from 'react';
import styles from './styles.module.scss';
import MainContainer from '../../../pages/main-container/MainContainer';

type Props = {
  error?: string;
};

const ServerError: FC<Props> = ({ error }) => {
  return (
    <MainContainer
      additionalClassname={styles.errorContainer}
      transparent={true}
      fullWidth={false}
      noMainTabs={false}
    >
      <div className={styles.errorInnerContainer}>
        <div className={styles.errorMessageContainer}>
          <span>Error: {error}</span>
          <span className={styles.checkingAgain}>Checking again...</span>
        </div>
      </div>
    </MainContainer>
  );
};

export default ServerError;
