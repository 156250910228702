import React, { ComponentType } from 'react';
import Accuracy from './Accuracy';

import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import { MessageDescriptor } from 'react-intl';

export default class AccuracyWrapper extends ComponentAugurDetailsTab {
  // @ts-ignore
  component: ComponentType = Accuracy;
  icon: ComponentType = () => <span className={'icon-accuracy'} />;
  id = 'accuracy';
  to = (habitatCode: string, augurCode: string, shortAugurType: string) =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/accuracy`;
  title: MessageDescriptor = {
    id: 'details.sideNav.accuracy',
    defaultMessage: 'Accuracy',
  };
  subRoutes: object[] | undefined = undefined;
}
