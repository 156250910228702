import { connect } from 'react-redux';
import TopMenu from './TopMenu';
import { isAdmin } from '../../../../redux/selectors/user.selector';
import { closeMenu } from '../../../../redux/modules/menu.module';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    isAdmin: isAdmin(state),
    frontendConfig: state.config,
  };
}

export const mapDispatchToProps = {
  closeMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
