export const comparators = {
  '1a': (a, b) => b.health - a.health,
  '1b': (a, b) => a.health - b.health,

  '2a': (a, b) => a.name.localeCompare(b.name),
  '2b': (a, b) => b.name.localeCompare(a.name),

  '3a': (a, b) =>
    new Date(b.lastEvaluationDate) - new Date(a.lastEvaluationDate),
  '3b': (a, b) =>
    new Date(a.lastEvaluationDate) - new Date(b.lastEvaluationDate),
};

export const defaultOrdering = '2a';
export const defaultComparator = comparators[defaultOrdering];

export function composeHabitats(habitats, summary) {
  const newHabitats = {};

  summary.forEach((habitat) => {
    if (!habitat.augurs) {
      habitat.augurs = []; // eslint-disable-line no-param-reassign
    }

    if (!habitat.datapools) {
      habitat.datapools = []; // eslint-disable-line no-param-reassign
    }

    const order = habitats[habitat.code] && habitats[habitat.code].order;

    newHabitats[habitat.code] = {
      isListView: false,
      order: order || {
        value: defaultOrdering,
      },
      augurs: habitat.augurs,
      datapools: {
        data: habitat.datapools,
        order: 'ascending',
        sortBy: 'code',
      },
      amountArchivedAugurs: habitat.amountArchivedAugurs,
      archived: [],
    };
  });

  return newHabitats;
}

export function setHabitatView(state, { habitatCode, isListView }) {
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      habitats: {
        ...state.dashboard.habitats,
        [habitatCode]: {
          ...state.dashboard.habitats[habitatCode],
          isListView,
        },
      },
    },
  };
}

export function filterDataSourcesAndSinks(dataConnections) {
  return {
    sources: dataConnections.filter(({ type }) => type === 'source'),
    sinks: dataConnections.filter(({ type }) => type === 'sink'),
  };
}

export function getDataConnectionsCollectionName(type) {
  return `${type}s`;
}

export function updateDataConnection(
  dataConnections,
  collectionName,
  dataConnection,
  updateData
) {
  const collection = dataConnections[collectionName];
  const index = collection.findIndex(
    ({ code }) => code === dataConnection.code
  );

  if (index === -1) {
    return collection;
  }

  return [
    ...collection.slice(0, index),
    {
      ...dataConnection,
      ...updateData,
    },
    ...collection.slice(index + 1),
  ];
}

export function addDataConnection(
  dataConnections,
  collectionName,
  dataConnection
) {
  return [...dataConnections[collectionName], dataConnection];
}

export function findDataConnection(dataConnections, collectionName, code) {
  return dataConnections[collectionName].find((c) => c.code === code);
}

export function removeAugurFromHabitat(habitats, habitatCode, augurCode) {
  const habitat = habitats[habitatCode];
  if (!habitat) return habitats;

  const augurIndex = habitat.augurs.findIndex(({ code }) => code === augurCode);
  if (augurIndex < 0) return habitats;

  return {
    ...habitats,
    [habitatCode]: {
      ...habitat,
      augurs: [
        ...habitat.augurs.slice(0, augurIndex),
        ...habitat.augurs.slice(augurIndex + 1),
      ],
    },
  };
}

export function addAugurToHabitat(habitats, habitatCode, augur) {
  const habitat = habitats[habitatCode];
  if (!habitat) return habitats;

  return {
    ...habitats,
    [habitatCode]: {
      ...habitat,
      augurs: [augur, ...habitat.augurs],
    },
  };
}
