import { createAction } from 'redux-act';
import { put, call, takeLatest, delay } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const checkHabitatNameExists = createAction(
  'check whether habitat with that name exists',
  (habitatName, callbacks) => ({ habitatName, callbacks })
);

export const checkHabitatNameExistsSuccess = createAction(
  'check whether habitat with that name exists - success',
  (result) => result
);

export const checkHabitatNameExistsFail = createAction(
  'check whether habitat with that name exists - fail',
  (fail) => fail
);

export const reducer = {
  [checkHabitatNameExists]: (state, { habitatName }) => ({
    ...state,
    newHabitat: {
      ...state.newHabitat,
      habitatName: {
        habitatName,
        checking: true,
        checked: false,
      },
    },
  }),
  [checkHabitatNameExistsSuccess]: (state, { exists }) => ({
    ...state,
    newHabitat: {
      ...state.newHabitat,
      habitatName: {
        ...state.newHabitat.habitatName,
        checking: false,
        checked: true,
        exists,
      },
    },
  }),
  [checkHabitatNameExistsFail]: (state, error) => ({
    ...state,
    newHabitat: {
      ...state.newHabitat,
      habitatName: {
        ...state.newHabitat.habitatName,
        checking: false,
        checked: false,
        error,
      },
    },
  }),
};

export function* checkHabitatNameExistsSaga({
  payload: {
    habitatName,
    callbacks: { resolve, reject },
  },
}) {
  yield delay(500);
  if (!habitatName) {
    reject({
      habitatName: { id: 'newHabitat.stepOne.error.missing_habitat_name' },
    });
    return;
  }

  const { response, error } = yield call(
    Api.habitats.checkHabitatNameExists,
    habitatName
  );
  if (response) {
    yield put(checkHabitatNameExistsSuccess(response));
    resolve(response);
  } else {
    yield put(checkHabitatNameExistsFail(error));
    reject(error);
  }
}

export function* watchCheckHabitatNameExists() {
  yield takeLatest(
    checkHabitatNameExists.getType(),
    checkHabitatNameExistsSaga
  );
}
