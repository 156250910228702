import React, { FC, Fragment } from 'react';
import { SettingsBlock } from '../../../../../molecules/settings-block/SettingsBlock';
import AugurSettingsCategory from '../../_interfaces/augur-details/settings/AugurSettingsCategory';
import SettingsButtons from './SettingsButtons';

type Props = {
  augurCode: string;
  habitatCode: string;

  settingsCategories: AugurSettingsCategory[];
  inputFormat: string;
};

const SettingsForm: FC<Props> = ({
  settingsCategories,
  augurCode,
  habitatCode,
  inputFormat,
}) => {
  return (
    <Fragment>
      {settingsCategories &&
        settingsCategories.length > 0 &&
        settingsCategories.map((category) => {
          const settingsElements = (category.children(inputFormat) || []).map(
            (element) => ({
              title: {
                id: element.title,
                defaultMessage: 'Element Title', // TODO pass default messages with the element.title
              },
              description: {
                id: element.description,
                defaultMessage: 'Element Description', // TODO pass default messages with the element.description
              },
              Component: (
                <element.component
                  habitatCode={habitatCode}
                  augurCode={augurCode}
                />
              ),
            })
          );

          return (
            <SettingsBlock
              title={{
                id: category.title,
                defaultMessage: 'Settings Block', // TODO pass default messages with the category.title
              }}
              color={category.backgroundColor}
              settingsElements={settingsElements}
            />
          );
        })}
      <SettingsButtons />
    </Fragment>
  );
};

export default SettingsForm;
