import AugurSettingsElement from '../../../../../_interfaces/augur-details/settings/AugurSettingsElement';
import AugurNameAndCodeComponent from './AugurNameAndCodeComponent';

/**
 * Shows the augur name & code in the augur settings tab
 */
export default class AugurNameAndCode implements AugurSettingsElement {
  component = AugurNameAndCodeComponent;
  title = 'dashboard.augur_settings.augur_title';
  description = 'dashboard.augur_settings.augur_description';
}
