import React, { FC } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

type Props = {
  isOver?: boolean;
  canDrop?: boolean;
};

const PlaceholderRenderer: FC<Props> = ({ isOver, canDrop }) => {
  return (
    <div className={styles.placeholder}>
      <h1 className={styles.placeholderLabel}>Drag & Drop new elements here</h1>
    </div>
  );
};

PlaceholderRenderer.defaultProps = {
  isOver: false,
  canDrop: false,
};

PlaceholderRenderer.propTypes = {
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
};

export default PlaceholderRenderer;
