import { createAction } from 'redux-act';
import * as Api from '../../core/api';
import { put, call, takeEvery } from 'redux-saga/effects';

export const fetchCodeCapsuleReport = createAction(
  'fetch code capsule report',
  (jobCode) => ({ jobCode })
);

export const fetchCodeCapsuleReportSuccess = createAction(
  'fetch code capsule report - success',
  (jobCode, report) => ({ jobCode, report })
);

export const fetchCodeCapsuleReportFailure = createAction(
  'fetch code capsule report - failure',
  (jobCode, error) => ({ jobCode, error })
);

export const fetchCodeCapsuleOutput = createAction(
  'fetch code capsule output',
  (jobCode, notebookName) => ({ jobCode, notebookName })
);

export const fetchCodeCapsuleOutputSuccess = createAction(
  'fetch code capsule output - success',
  (jobCode, notebookName, output) => ({ jobCode, notebookName, output })
);

export const fetchCodeCapsuleOutputFailure = createAction(
  'fetch code capsule output - failure',
  (jobCode, notebookName, error) => ({ jobCode, notebookName, error })
);

export const reducer = {
  [fetchCodeCapsuleReport]: (state, { jobCode }) => ({
    ...state,
    codeCapsuleReport: {
      ...(state.codeCapsuleReport | {}),
      loading: true,
    },
  }),
  [fetchCodeCapsuleReportSuccess]: (state, { jobCode, report }) => ({
    ...state,
    codeCapsuleReport: {
      ...(state.codeCapsuleReport | {}),
      loading: false,
      loaded: true,
      report,
      error: undefined,
    },
  }),
  [fetchCodeCapsuleReportFailure]: (state, { jobCode, error }) => ({
    ...state,
    codeCapsuleReport: {
      ...(state.codeCapsuleReport | {}),
      loading: false,
      loaded: false,
      error,
    },
  }),
  [fetchCodeCapsuleOutput]: (state, { jobCode, notebookName }) => ({
    ...state,
    codeCapsuleOutput: {
      ...state.codeCapsuleOutput,
      loading: true,
    },
  }),
  [fetchCodeCapsuleOutputSuccess]: (
    state,
    { jobCode, notebookName, output }
  ) => ({
    ...state,
    codeCapsuleOutput: {
      ...state.codeCapsuleOutput,
      loading: false,
      loaded: true,
      error: undefined,
      output,
    },
  }),
  [fetchCodeCapsuleOutputFailure]: (
    state,
    { jobCode, notebookName, error }
  ) => ({
    ...state,
    codeCapsuleOutput: {
      ...state.codeCapsuleOutput,
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* fetchCodeCapsuleReportSaga({ payload: { jobCode } }) {
  const { response, error } = yield call(
    Api.codeCapsules.fetchCodeCapsuleReport,
    jobCode
  );
  if (response) {
    yield put(fetchCodeCapsuleReportSuccess(jobCode, response));
  } else {
    yield put(fetchCodeCapsuleReportFailure(jobCode, error));
  }
}

export function* watchFetchCodeCapsuleReport() {
  yield takeEvery(fetchCodeCapsuleReport.getType(), fetchCodeCapsuleReportSaga);
}

export function* fetchCodeCapsuleOutputSaga({
  payload: { jobCode, notebookName },
}) {
  const { response, error } = yield call(
    Api.codeCapsules.fetchCodeCapsuleOutput,
    jobCode,
    notebookName
  );
  // @type {{error: {status: string, message: string}}
  if (response) {
    yield put(fetchCodeCapsuleOutputSuccess(jobCode, notebookName, response));
  } else {
    yield put(fetchCodeCapsuleOutputFailure(jobCode, notebookName, error));
  }
}

export function* watchFetchCodeCapsuleOutput() {
  yield takeEvery(fetchCodeCapsuleOutput.getType(), fetchCodeCapsuleOutputSaga);
}
