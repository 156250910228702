import { ComponentType } from 'react';
import { ComponentDatapoolDetailsTab } from '../../_interfaces/datapool-details/DatapoolDetailsTab';
import Settings from './Settings';
import SettingsIcon from './SettingsIcon';
import DatapoolSettingsCategory from '../../_interfaces/datapool-details/settings/DatapoolSettingsCategory';
import { wrapSettingsWithCategories } from './SettingsWrapper';

export default class SettingsWrapper extends ComponentDatapoolDetailsTab {
  /**
   * @param settingsCategories Settings categories that are supposed to be displayed in the settings screen
   */
  constructor(settingsCategories: DatapoolSettingsCategory[]) {
    super();
    this.component = wrapSettingsWithCategories(Settings, settingsCategories);
  }

  type: 'component' = 'component';
  id = 'settings';
  title = {
    id: 'details.sideNav.settings',
    defaultMessage: 'Settings',
  };
  to = (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${shortDatapoolType}/settings`;

  icon: ComponentType = SettingsIcon;
  component: ComponentType;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: object) => true;
}
