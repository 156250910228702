import { connect } from 'react-redux';
import RepositoryStepKernel from './RepositoryStepKernel';
import { getSelectedKernel } from '../../../../workbench/part-right/wizards/notebook/notebook.form';

export function mapStateToProps(state, otherProps) {
  return {
    kernelspecs: {
      // Static while there is only "Python" available as the Kernel for Archetypes
      python3: {
        name: 'python3',
        spec: {
          display_name: 'Python 3',
          language: 'python',
        },
      },
    },

    error: ((state.workbench || {}).kernelspecs || {}).error,
    selectedKernel: otherProps.isRepositoryWizard
      ? otherProps.selectedKernel
      : getSelectedKernel(state),
  };
}

export default connect(mapStateToProps)(RepositoryStepKernel);
