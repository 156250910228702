import { connect, ConnectedProps } from 'react-redux';
import TablesParent from './TablesParent';
import { RootState } from '../../../../../../store/store';
import { RouteComponentProps } from 'react-router';

export function mapStateToProps(
  state: RootState,
  { match }: RouteComponentProps<{ datapoolCode: string }>
) {
  const dataSources = state.data.dataSources;
  const dataSourceCode = state.datapoolDetails.datapool?.dataSourceCode;
  const dataSource = dataSources.data.find((ds) => ds.code === dataSourceCode);

  return {
    dataSource,
    datapoolCode: match.params.datapoolCode,
  };
}

const connector = connect(mapStateToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(TablesParent);
