// Contains constants that are used to determine which component is supposed to be shown on the right
// (for example when clicking the 'Add Notebook' button 'showOnRight' in the redux state will be set to ADD_NOTEBOOK)

// TODO Is this really still required? Might be deprecated since the switch which page to show comes from the route now.
export const RightParts = {
  EDITOR: 'editor', // notebook, code, ... editor
  ADD_NOTEBOOK: 'add-notebook',
  ADD_FILE: 'add-file',
  ADD_DIRECTORY: 'add-directory',
  PASTE_NOTEBOOK: 'paste-notebook',
  EDIT_NOTEBOOK: 'edit-notebook',
  EDIT_DIRECTORY: 'edit-directory',
};
