import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../_components/TextInput';
import { Field } from 'redux-form';
import { FiCheck } from 'react-icons/fi';
import { validateFileName } from './validation';
import { fieldName } from './file.form';
import SelectedDirs from '../../../../molecules/selected-dirs/SelectedDirs';

export default class FileStep1 extends Component {
  validateNonInline = (value) =>
    validateFileName(value, this.props.filesInCurrentDir);

  errorMessage() {
    if (this.props.directoryNameTouched && this.props.directoryNameError) {
      return <p className={'error'}>{this.props.directoryNameError}</p>;
    }
  }

  render() {
    const {
      fileName,
      fileNameTouched,
      fileNameError,
      filesInCurrentDir,
      selectedDirPath,
    } = this.props;
    return (
      <div className={'step-container step-1-container'}>
        <div className={'description-container'}>
          {validateFileName(fileName, filesInCurrentDir) ? (
            <div className={'bubble bubble-info'}>
              <p>1</p>
            </div>
          ) : (
            <div className={'bubble bubble-ok'}>
              <FiCheck className={'checked-icon'} size={'20px'} />
            </div>
          )}
          <div className={'description'}>
            <div className={'title'}>
              <p>Select Name</p>
            </div>
            <div className={'text'}>
              <p>
                Select a name for the file. Browse on the left to change the
                path where the file will be created.
              </p>
            </div>
          </div>
        </div>
        <div
          className={
            'input-container' +
            (fileNameTouched && validateFileName(fileName, filesInCurrentDir)
              ? ' input-container-error'
              : '')
          }
        >
          <div className={'error-container'}>{this.errorMessage()}</div>
          <div className={'name-input'}>
            <Field
              name={fieldName}
              label={fieldName}
              type='text'
              component={TextInput}
              validate={this.validateNonInline}
            />
            <SelectedDirs
              dirs={[...(this.props.selectedDirPath || []), fileName]}
              highlightLastEntry
            />
          </div>
        </div>
      </div>
    );
  }
}

validateFileName.propTypes = {
  selectedDirPath: PropTypes.array.isRequired,
  fileName: PropTypes.string,
  fileNameTouched: PropTypes.bool,
  fileNameError: PropTypes.string,
  filesInCurrentDir: PropTypes.array.isRequired,
};
