import { connect, ConnectedProps } from 'react-redux';
import CodeCapsuleReport, { RProps } from './CodeCapsuleReport';
import { withRouter } from 'react-router-dom';
import { fetchCodeCapsuleReport } from '../../../../redux/modules/orchestration.codeCapsuleReport.module';
import { RootState } from '../../../../store/store';

export function mapStateToProps(state: RootState, { match }: RProps) {
  return {
    jobCode: match.params.jobCode,
    report: state.codeCapsuleReport?.report,
    loading: state.codeCapsuleReport?.loading,
    loaded: state.codeCapsuleReport?.loaded,
    error: state.codeCapsuleReport?.error,
  };
}

export const mapDispatchToProps: {
  fetchCodeCapsuleReport: (jobCode: string) => void;
} = {
  fetchCodeCapsuleReport,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(CodeCapsuleReport));
