import PropTypes from 'prop-types';
import React from 'react';
import { scaleBand } from 'd3-scale';

import BarContainer from './Bar.container';
import * as misclassification from '../../../../../../../../core/common/misclassification';

function Bars({ data, height, width }) {
  const innerPadding = 0.5;
  const xScale = scaleBand()
    .domain(data.map(misclassification.getTimestamp))
    .range([0, width])
    .paddingInner(innerPadding);

  return (
    <g>
      {data.map((item, index) => (
        <BarContainer
          key={index}
          xScale={xScale}
          height={height}
          data={item}
          innerPadding={innerPadding}
        />
      ))}
    </g>
  );
}

Bars.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Bars;
