import { AugurDetailsTab, ComponentAugurDetailsTab } from './AugurDetailsTab';
import { MenuEntryType } from '../../../../../molecules/with-sidemenu/SideMenu';
import { RouteConfig } from 'react-router-config';

/**
 * Interface for all components required to fill the "Augur Details".
 * Has to be implemented exactly once for each module type.
 */
export default abstract class AugurDetails {
  augurDetailsPathPrefix = '/app/habitat/:habitatCode/augur/:augurCode';
  tabs: (inputFormat: string | undefined) => AugurDetailsTab[] = (
    inputFormat
  ) => [];

  /**
   * Returns all required routes for the Augur Details to configure the sub-routes.
   * @param shortName shortAugurType The short type of the augurs module. Bad naming, should be "shortModuleType" instead
   * @param inputFormat of the datapool of the augur
   */
  getAllRoutes(
    shortName: string,
    inputFormat: string | undefined
  ): RouteConfig[] {
    return this.tabs(inputFormat)
      .filter((tab) => tab.type === 'component')
      .map(
        (tab: ComponentAugurDetailsTab): RouteConfig => ({
          key: tab.id,
          path: `${this.augurDetailsPathPrefix}/${shortName}/${tab.id}`,
          component: tab.component,
          routes: tab.getAllSubRoutes(
            `${this.augurDetailsPathPrefix}/${shortName}/`
          ),
        })
      );
  }

  /**
   * Returns all required objects to set up the Menu-Tab-View on the left side of the Augur details.
   * @param habitatCode
   * @param augurCode
   * @param shortAugurType The short version of the augur / module type! Misleading naming ...
   * @param inputFormat of the datapool of the augur
   */
  getAllMenuTabs(
    habitatCode: string,
    augurCode: string,
    shortAugurType: string,
    inputFormat: string | undefined
  ): MenuEntryType[] {
    return this.tabs(inputFormat)
      .filter(
        (tab) =>
          tab.type !== 'component' ||
          (tab.type === 'component' && tab.showInMenu)
      ) // Remove the component tabs that are not supposed to be shown in the menu
      .map((tab) => {
        switch (tab.type) {
          case 'hr': // Fall-through intended
          case 'headline': {
            // For the "hr" and "headline" cases the Tab can simply be returned, since it's a compatible MenuEntry object
            return tab;
          }
          case 'component':
            return {
              // In this case the Tab needs to be mapped to the MenuEntry object
              type: 'link',
              color: 'black',
              id: tab.id,
              to: tab.to(habitatCode, augurCode, shortAugurType),
              nameIntlId: tab.title.id as string,
              nameDefault: tab.title.defaultMessage as string,
              icon: tab.icon,
            };
        }
      });
  }
}
