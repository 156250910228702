import React, { Component } from 'react';
import { inputFormats } from 'common/dist/constants/datapool';
import { CProps } from './DatapoolNameAndCode.container';

export default class DatapoolNameAndCodeComponent extends Component<CProps> {
  render() {
    const { datapoolCode, datapoolName, inputFormat } = this.props;
    const speakingInputFormat = inputFormats.find((x) => x.id === inputFormat);

    return (
      <div>
        <p>{datapoolName}</p>
        <p>{datapoolCode}</p>
        <p>{speakingInputFormat ? speakingInputFormat.name : inputFormat}</p>
      </div>
    );
  }
}
