import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import { fetchContent } from '../../../../../core/api/workbench/content';
import SelectedDirs from '../../../../molecules/selected-dirs/SelectedDirs';
import { contentPathToArray } from 'common/dist/utils/workbench/content';
import ErrorBoundary from '../../../../pages/error-boundary/ErrorPage';
import { FormattedMessage } from 'react-intl';
import HttpStatusCode from 'common/dist/constants/httpStatusCodes';
export interface Props {
  path: string;
  type: string;
  deleteContent(...args: unknown[]): unknown;
  selectedDirPath?: unknown[];
  history?: object[];
  /** permanently=true means the file is not moved into the recycle bin, but deleted immediately.
   * This can also be used to remove elements from the recycle bin */
  permanently?: false;
  jupyterUser: string;
}
type State = {
  loading: boolean; // In case of a directory, it's children have to be fetched async first - so the component is loading.
  dirNotEmpty: boolean;
  hasError: boolean;
};

class DeleteContent extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: props.type === 'directory', // In case of a directory, it's children have to be fetched async first - so the component is loading.
      dirNotEmpty: undefined,
      hasError: false,
    };
  }

  componentDidMount() {
    if (this.props.type === 'directory') {
      fetchContent(this.props.path, this.props.jupyterUser).then(
        (success, error) => {
          if (success) {
            const content = success.response?.content;
            if (success.error && success.status === HttpStatusCode.NOT_FOUND) {
              this.setState({
                loading: false,
                hasError: true,
              });
            }
            if (content && content.length > 0) {
              this.setState({ loading: false, dirNotEmpty: true });
            } else {
              this.setState({ loading: false, dirNotEmpty: false });
            }
          } else {
            // TODO what to do in this case?
          }
        }
      );
    }
  }

  name(type: string) {
    switch (type) {
      case 'notebook':
        return 'Notebook';
      case 'directory':
        return 'Directory';
      case 'file':
        return 'File';
      default:
        return '';
    }
  }

  headline() {
    return `Delete ${this.name(this.props.type)}`;
  }

  title() {
    const { type, permanently } = this.props;
    return (
      <p>
        Are you sure you want to delete this {this.name(type)}
        {permanently && <b> permanently</b>}?
      </p>
    );
  }
  render() {
    const { path, deleteContent, selectedDirPath, permanently } = this.props;
    // If the page was loaded directly, the content to delete is missing from the state
    if (path === undefined || this.state.hasError) {
      return <Redirect to={workbenchRoutes.basePath} />;
    }
    return (
      <div
        className={'dialog-container'}
        style={{
          paddingTop: '40px', // This wizard requires additional space in the top to look good
        }}
      >
        <div className={'dialog-inner-container'}>
          <p className={'dialog-headline'}>{this.headline()}</p>
          <div className={'step-container'}>
            <div className={'description-container'}>
              <div className={'bubble bubble-delete'}>
                <FiX className={'icon-x'} size={'20px'} />
              </div>
              <div className={'description'}>
                <div className={'title'}>{this.title()}</div>
                <SelectedDirs
                  dirs={contentPathToArray(this.props.path)}
                  highlightLastEntry
                />
              </div>
            </div>
          </div>
          {this.props.type === 'directory' && this.state.dirNotEmpty && (
            <div className={'step-container'}>
              <div className={'description-container'}>
                <div className={'bubble bubble-warn'}>
                  <p>!</p>
                </div>
                <div className={'description'}>
                  <p className={'title'}>This directory is not empty!</p>
                  <p className={'text'}>
                    Are you sure you want to delete all sub-directories and
                    sub-files of this directory too?
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className={'dialog-button-container'}>
            <Link to={workbenchRoutes.basePath}>
              <button className={'dialog-button dialog-button-neutral'}>
                Cancel
              </button>
            </Link>
            <Link
              to={workbenchRoutes.basePath}
              onClick={() => {
                if (!this.state.loading) {
                  this.setState({ loading: true });
                  deleteContent(path, selectedDirPath, permanently);
                }
              }}
              style={{ textDecoration: 'none' }}
            >
              <button
                className={
                  'button dialog-button dialog-button-delete ' +
                  (this.state.loading ? ' dialog-button-disabled' : '')
                }
              >
                {this.state.loading ? (
                  <ReactLoading
                    className={'busy'}
                    type={'bubbles'}
                    color={'white'}
                  />
                ) : (
                  <span>Delete</span>
                )}
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DeleteContent);
