import React, { Component } from 'react';
import { CProps } from './DataSource.container';
import { Link } from 'react-router-dom';
import { FiInfo } from 'react-icons/fi';
import { typeToSpeaking } from 'common/dist/types/dataManagement/dataSource';

export default class DataSourceComponent extends Component<CProps> {
  render() {
    const { dataSourceCode, dataSources } = this.props;
    const dataSource = dataSources.data.find(
      (ds) => ds.code === dataSourceCode
    );

    return (
      <div>
        <div>
          {dataSource && <p>{dataSource.name}</p>}
          <p>
            {dataSourceCode}
            {'  '}
            {dataSource && (
              <Link to={`/app/data/${dataSource.ds_type}/${dataSourceCode}`}>
                <FiInfo className={'action-icon'} size={'16px'} />
              </Link>
            )}
          </p>
          <p>{typeToSpeaking(dataSource.ds_type)}</p>
        </div>
      </div>
    );
  }
}
