import { connect } from 'react-redux';

import Tooltip from './Tooltip';

export function mapStateToProps(state, { top, left, containerHeight }) {
  return {
    isTooltip: state.accuracyDetail.isTooltip,
    tooltipX: state.accuracyDetail.tooltipX,
    tooltipY: state.accuracyDetail.tooltipY,
    tooltipData: state.accuracyDetail.tooltipData,
    top,
    left,
    containerHeight,
  };
}

export default connect(mapStateToProps)(Tooltip);
