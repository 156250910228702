import { connect } from 'react-redux';
import Content from './Content';
import { fetchContent } from '../../../../redux/workbench/modules/content.module';
import { defaultBrowserSize } from '../BrowserTabLinks';

export function mapStateToProps(state) {
  return {
    width: state.workbench.browserWidth || defaultBrowserSize,
    content: state.workbench.content,
    error: (state.workbench.content || {}).error,
    selectedDirPath: (state.workbench.content || {}).selectedDirPath || [
      'root',
    ],
  };
}

export const mapDispatchToProps = {
  fetchContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
