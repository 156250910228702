import React, { FC } from 'react';
import styles from './styles.module.scss';
import ProgressStep, {
  Props as StepProps,
} from '../../molecules/progress-step/ProgressStep';

export type Props = {
  finished?: boolean;
  progressSteps: StepProps[];
};

const ProgressSteps: FC<Props> = ({ finished, progressSteps }) => {
  return (
    <div className={styles.container}>
      {progressSteps.map((s) => (
        <ProgressStep jobFinished={finished} {...s} />
      ))}
    </div>
  );
};

export default ProgressSteps;
