import React, { Component } from 'react';
import _ from 'lodash';
import { UserResponse } from '../../../../store/admin/resources/api.types';
import Busy from '../../../atoms/busy/Busy';
import IndicatorEmpty from '../../../molecules/indicator-empty/IndicatorEmpty';
import { formatDate } from 'common/dist/utils/dates';
import { CProps } from './RunningWorkbench.container';
import styles from '../styles.module.scss';
import Button from '../../../atoms/button/Button';

export type ServerListEntry = {
  userId: string;
  appVersionCode: string;
  created: string;
  lastActivity: string;
  ready: boolean;
  pending: boolean;
};
export function flattenUserResponses(
  userResponses: UserResponse[]
): ServerListEntry[] {
  return _.flatMap(userResponses, (user) =>
    Object.values(user.servers).map((s) => ({
      userId: user.name,
      appVersionCode: s.name,
      created: user.created,
      lastActivity: user.last_activity,
      ready: s.ready,
      pending: s.pending,
    }))
  );
}

type Props = {};

type CombinedProps = Props & CProps;

export default class RunningWorkbench extends Component<CombinedProps> {
  componentDidMount() {
    const { fetchRunningWorkbench } = this.props;
    fetchRunningWorkbench();
  }

  componentDidUpdate(prevProps: Readonly<CombinedProps>) {
    const { loaded, loading, data, fetchUserSummaryById } = this.props;
    if (loaded === true && prevProps.loading === true && loading === false) {
      const flattened = flattenUserResponses(data);
      for (const serverListEntry of flattened) {
        fetchUserSummaryById({ userId: serverListEntry.userId });
      }
    }
  }

  renderLoading() {
    return (
      <div className={'resources-parent'}>
        <Busy isBusy />
      </div>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className={'resources-parent'}>
        <span className={'error'}>{JSON.stringify(error)}</span>
      </div>
    );
  }

  renderEmpty() {
    return (
      <IndicatorEmpty
        classNameImage={'resources-no-workbench-pic'}
        headlineId={'no-id'}
        headlineDefault={'There are no running Workbench Servers.'}
      />
    );
  }

  renderLoaded() {
    const { data, stopWorkbench, stoppingServers, users } = this.props;
    const flattened = flattenUserResponses(data);
    return (
      <div className={'resources-parent'}>
        <div className={'ct-list'}>
          <div className={'ct-headline ct-row'}>
            <div className={'ct-col ct-col-140px'}>
              <span>First Name</span>
            </div>
            <div className={'ct-col ct-col-140px'}>
              <span>Last Name</span>
            </div>
            <div className={'ct-col ct-col-80px'}>
              <span>User</span>
            </div>
            <div className={'ct-col ct-col-160px'}>
              <span>Started</span>
            </div>
            <div className={'ct-col ct-col-160px'}>
              <span>Last Activity</span>
            </div>
            <div className={'ct-col ct-col-80px'}>
              <span>Ready</span>
            </div>
            <div className={'ct-col ct-col-flex-grow'}></div>
          </div>
          {flattened.map((e, i) => {
            const user = users.byId[e.userId];
            const userSummary = user?.data;
            return (
              <div key={i} className={'ct-row'}>
                <div className={'ct-col ct-col-140px'}>
                  <span>{userSummary?.firstName}</span>
                </div>
                <div className={'ct-col ct-col-140px'}>
                  <span>{userSummary?.lastName}</span>
                </div>
                <div className={'ct-col ct-col-80px'}>
                  <span>{e.userId}</span>
                </div>
                <div className={'ct-col ct-col-160px'}>
                  <span>
                    {formatDate(new Date(e.created), 'dd.MM.yyyy HH:mm:ss')}
                  </span>
                </div>
                <div className={'ct-col ct-col-160px'}>
                  <span>
                    {formatDate(
                      new Date(e.lastActivity),
                      'dd.MM.yyyy HH:mm:ss'
                    )}
                  </span>
                </div>
                <div className={'ct-col ct-col-80px'}>
                  <span>{e.ready + ''}</span>
                </div>
                <div className={'ct-col ct-col-flex-grow'}>
                  {e.ready &&
                    (stoppingServers.includes(e.userId) ? (
                      <Button
                        buttonColor={'orange'}
                        withLink={false}
                        buttonLabelDefault={'Stopping ...'}
                        disabled
                        additionalClassNames={[styles.stopWorkbenchButton]}
                      />
                    ) : (
                      <Button
                        buttonColor={'orange'}
                        withLink={false}
                        buttonLabelDefault={'Shutdown'}
                        additionalClassNames={[styles.stopWorkbenchButton]}
                        onClick={() => {
                          stopWorkbench(e.userId);
                        }}
                      />
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const { loading, error, data } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (!data || flattenUserResponses(data).length === 0)
      return this.renderEmpty();
    return this.renderLoaded();
  }
}
