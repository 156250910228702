import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import './templateElement.scss';
import { TemplateMeta } from '../types';

export type Props = {
  template: TemplateMeta;
};

export default class TemplateElement extends Component<Props> {
  render() {
    const { template } = this.props;
    return (
      <Fragment>
        <div className={'template-element'}>
          <div style={{ textDecoration: 'none', flexGrow: 1 }}>
            <div className={'element'}>
              <div className={'element-bak-container'}>
                <div className={'element-name'}>{template.name}</div>
                <div className={'element-details'}>
                  Components:{' '}
                  {template.components.map((com) => com.name).join(', ')}; Id:{' '}
                  {template.id}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
