import React, { FC, useEffect } from 'react';
import { useTable } from 'react-table';
import EditableCell from './EditableCell';
import styles from './styles.module.scss';

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Each entry in the array corresponds to one row in the table, "unknown" stands for the value of one table cell
export type TableDataType = { [colName: string]: unknown }[];

export type Props = {
  /** Current value of the table */
  value: TableDataType;
  /** onChange callback */
  onChange: (updatedValue: TableDataType) => void;
};

/**
 * This component simply memoizes the props and passes them over to EditableTableMemoized.
 * This is a requirement of react-table, see: https://github.com/TanStack/table/issues/2369#issuecomment-652118213
 */
const EditableTable: FC<Props> = ({ value, onChange }) => {
  const valueMemoized = React.useMemo(() => value, [value]);
  // --- Map the columns / data to the format required by react-table
  const colNames = Object.keys(value?.[0] || {});
  const columnsMemoized = React.useMemo(
    () =>
      colNames.map((c) => ({
        Header: c,
        accessor: c,
      })),
    [colNames]
  );

  return (
    <EditableTableMemoized
      value={valueMemoized}
      columns={columnsMemoized}
      onChange={onChange}
    />
  );
};

export default EditableTable;

// ---

type PropsMemoized = {
  value: TableDataType;
  onChange: (updatedValue: TableDataType) => void;
  columns: { Header: string; accessor: string }[];
};

/**
 * The actual UI component for the editable table
 */
const EditableTableMemoized: FC<PropsMemoized> = ({
  value,
  onChange,
  columns,
}) => {
  // --- Callback for changes
  const updateMyData = (
    rowIndex: number,
    columnId: string,
    cellValue: string
  ) => {
    const updatedData = value.map((row, i) => {
      if (i === rowIndex) {
        return {
          ...value[i],
          [columnId]: cellValue,
        };
      }
      return row;
    });

    onChange(updatedData);
  };

  // --- Setup the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: value,
      defaultColumn,

      // @ts-ignore
      updateMyData,
    });

  useEffect(() => {
    const tableCells = document.querySelectorAll('td');
    for (let x = 0; x < tableCells.length; x++) {
      if (tableCells[x].clientWidth > 350) {
        //please note if you want this to include the border width then you need to use tableCells[x]..offsetWidth to include the border.
        tableCells[x].classList.add(styles.long);
      }
    }
  }, [value]);

  // Render the UI for your table
  return (
    <div className={styles.tableContainer}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className={styles.tableHeader} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
