import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { axisBottom, AxisScale, AxisDomain } from 'd3-axis';
import { select } from 'd3-selection';

interface Props {
  scale: AxisScale<AxisDomain>;
  height: number;
  additionalLabels?: any[];
}
export class HorizontalAxis extends Component<Props> {
  static defaultProps = {
    additionalLabels: [],
  };
  private gRef: SVGSVGElement;

  constructor(props) {
    super(props);

    this.setGRef = this.setGRef.bind(this);
  }

  componentDidMount() {
    this.drawAxis();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scale !== this.props.scale) {
      this.drawAxis();
    }
  }

  setGRef(ref) {
    this.gRef = ref;
  }

  drawAxis() {
    const { scale, additionalLabels } = this.props;

    select(this.gRef)
      .call(axisBottom(scale))
      .call((selection) => {
        if (!additionalLabels || additionalLabels.length === 0) return;
        selection
          .selectAll('g text')
          /* eslint-disable prefer-arrow-callback */
          // we need reference to `this`
          .each(function appendAdditionalLabels(_, index) {
            const textElement = select(this);
            additionalLabels[index].forEach((label) => {
              textElement
                .append('tspan')
                .text(label)
                .attr('x', 0)
                .attr('dy', 12);
            });
          });
      });
  }

  render() {
    const { height } = this.props;
    return (
      <g className='tooltip-chart-axis' transform={`translate(${0},${height})`}>
        <g className='tooltip-chart-axis__main' ref={this.setGRef} />
      </g>
    );
  }
}

export function Bar({ item, xScale, yScale }) {
  return (
    <rect
      className={item.className}
      width={xScale.bandwidth()}
      height={Math.abs(yScale(0) - yScale(item.recordCount))}
      x={xScale(item.value)}
      y={yScale(item.recordCount)}
    />
  );
}

Bar.propTypes = {
  item: PropTypes.object.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
};

export default function TooltipBarChart({
  scoreDistributions,
  additionalLabels,
  xScale,
  yScale,
  height,
  margin,
}) {
  return (
    <svg className='tooltip-chart'>
      <g
        className='tooltip-chart-container'
        transform={`translate(${margin.right},${margin.top})`}
      >
        <g className='tooltip-chart-bars'>
          {scoreDistributions.map((scoreDistribution, index) => (
            <Bar
              key={index}
              item={scoreDistribution}
              xScale={xScale}
              yScale={yScale}
            />
          ))}
        </g>
        <HorizontalAxis
          scale={xScale}
          height={height}
          additionalLabels={additionalLabels}
        />
      </g>
    </svg>
  );
}

TooltipBarChart.propTypes = {
  scoreDistributions: PropTypes.array.isRequired,
  additionalLabels: PropTypes.array.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  margin: PropTypes.shape({
    top: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
  }).isRequired,
};
