import { connect } from 'react-redux';
import CreateBranchModal from './CreateBranchModal';
import { hideCreateBranchModal } from '../../../../../../../redux/workbench/modules/repository.module';
import { createBranchForm } from './createBranch.form';
import { formValueSelector } from 'redux-form';
import { gitCreateBranch } from '../../../../../../../redux/workbench/modules/container-interactions.module';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

export function mapStateToProps(state) {
  const infoFilePath = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).path;
  return {
    isCreateBranchModalOpen:
      state.workbench.showRepositoryInfo.isCreateBranchModalOpen,
    branchName: formValueSelector(createBranchForm.form)(
      state,
      createBranchForm.fieldBranchName
    ),
    infoFilePath,
    remote: (state.workbench.showRepositoryInfo || {}).remote,
  };
}

export const mapDispatchToProps = {
  hideCreateBranchModal,
  gitCreateBranch,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateBranchModal)
);
