import React, { FC } from 'react';
import styles from '../styles.module.scss';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import classNames from 'classnames';

type Props = {
  /** Column in the grid layout */
  gridColumn: number;
  /** Row in the grid layout */
  gridRow: number;
  /** Key */
  detailKey: MessageDescriptor;
};

const DetailValueLoading: FC<Props> = (props: Props) => {
  const { gridRow, gridColumn, detailKey } = props;

  return (
    <div
      className={styles.detailValue}
      style={{
        gridRow,
        gridColumn,
      }}
    >
      <div className={styles.detailValueKey}>
        <FormattedMessage {...detailKey} />
      </div>

      <div className={classNames(styles.detailValueValue, styles.loading)} />
    </div>
  );
};

export default DetailValueLoading;
