export const data = [
  {
    id: 1,
    customer_id: 12356,
    zipcode: '43244-12332',
    city: 'Lesleyport',
    age: 39,
    gender: 'M',
  },
  {
    id: 2,
    customer_id: 12357,
    zipcode: '43257-78989',
    city: 'Lesleyport',
    age: 25,
    gender: 'F',
  },
  {
    id: 3,
    customer_id: 1290,
    zipcode: '98788-654455',
    city: 'Lesleyport',
    age: 40,
    gender: 'M',
  },
];

export const initial = {
  step: 0,
  submitted: false,
  submitting: false,
  valid: false,
  stepsVisited: [false, false],
  data,
  hoveredColumn: '',
  openedTypeSelector: '',
  activeColumns: {
    id: true,
    customer_id: true,
    zipcode: true,
    city: true,
    age: true,
    gender: true,
  },
  columnTypes: {
    id: '',
    customer_id: '',
    zipcode: '',
    city: '',
    age: '',
    gender: '',
  },
  isHistogram: {
    id: false,
    customer_id: false,
    zipcode: false,
    city: false,
    age: true,
    gender: false,
  },
};

export const test = {
  step: 0,
  submitted: false,
  submitting: true,
  valid: false,
  stepsVisited: [false, false],
  data,
  hoveredColumn: '',
  openedTypeSelector: '',
  activeColumns: {
    id: true,
    customer_id: true,
    zipcode: false,
    city: true,
    age: true,
    gender: true,
  },
  columnTypes: {
    id: '',
    customer_id: '',
    zipcode: '',
    city: 'string',
    age: '',
    gender: '',
  },
  isHistogram: {
    id: false,
    customer_id: false,
    zipcode: false,
    city: false,
    age: true,
    gender: false,
  },
};
