import React, { Component } from 'react';
import { Field } from 'redux-form';
import WizardStepFieldComponent from './WizardStepFieldComponent.container';
import { GenericWrappedFieldProps } from './genericWrappedFieldProps';
import { Props as MessageProps } from 'react-intl/src/components/message';

export type WizardStepProps<Value, Error> = {
  // --- Child components
  children?: React.ReactElement<GenericWrappedFieldProps<Value, Error>>;

  // --- Appearance
  /** Title that is shown on the left */
  title: MessageProps;
  /** Description that is show on the left below the title */
  description?: MessageProps;
  /** Number of the step */
  stepNumber: number;

  /** Optional component that is rendered below the "info left, input right" component.
   * It is also wrapped into the redux-form Field to have the { input, meta } props */
  BottomComponent?: React.FC<GenericWrappedFieldProps<Value, Error>>;
  bottomComponentProps?: Record<string, any>;
  /** Is the bottom component supposed to take the whole width of the screen? Otherwise it's restricted to max-width: 1200px (or similar) */
  bottomComponentFullWidth?: boolean;

  // --- redux-form related
  fieldName: string;

  /** Custom prop to inject initial values. Can be used to inject initial values AFTER the form has already
   * been mounted and some of the values have been already set. Even if the two parameters are set for the form
   * ("enableReinitialize" and "keepDirtyOnReinitialize"), setting initial values later while the form has already
   * been filled in parts, it causes the form to re-mount (which crashes the values and the validation) */
  mpwInitialValues?: Value;
};

export default class WizardStep<Value, Error> extends Component<
  WizardStepProps<Value, Error>
> {
  render() {
    const { fieldName } = this.props;
    return (
      <Field
        name={fieldName}
        component={WizardStepFieldComponent}
        {...this.props}
      />
    );
  }
}
