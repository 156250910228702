import React, { FC, useEffect } from 'react';
import RunningApps from './RunningApps.container';
import { notebookUser } from '../../../../redux/workbench/selectors/notebookUser.selector';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../store/store';
import Busy from '../../../atoms/busy/Busy';
import styles from '../styles.module.scss';
import { checkWhetherAppIsRunning } from '../../../../redux/workbench/modules/app.connection.module';

/**
 * Thin wrapper around RunningApps that makes the login. Needed to keep the original component as is, which depends on being logged in.
 */
const RunningWorkbenchLogin: FC = () => {
  const user = useSelector((state) => notebookUser(state));
  const dispatch = useAppDispatch();
  useEffect(() => {
    const appVersionCode = '';
    dispatch(checkWhetherAppIsRunning(notebookUser, appVersionCode, false)); // Check without retries
  }, [dispatch, user]);
  const appRunning = useSelector<
    RootState,
    RootState['workbench']['app']['appRunning']
  >((state) => state.workbench.app.appRunning);

  if (appRunning.loading)
    return (
      <div className={styles.Resources}>
        <Busy isBusy />
      </div>
    );

  if (appRunning.error)
    return (
      <div className={styles.Resources}>
        <span className={'error'}>{JSON.stringify(appRunning.error)}</span>
      </div>
    );

  return <RunningApps />;
};

export default RunningWorkbenchLogin;
