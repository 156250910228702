import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputValueSafe } from '../../python3-input-cell/elements/_interface/input-element.form';

export default class CheckBoxCol extends Component {
  render() {
    const {
      label,
      input: { onChange, onBlur, onFocus, value },
      meta: { touched, error },
    } = this.props;

    return (
      <div className={`input-col${error ? ' error' : ''}`}>
        <div className={'form--headline'}>
          <p className={'form--label'}>{label}</p>
          {touched && error && <p className={'form--error'}>{error}</p>}
        </div>

        <div className={'form--input-parent'}>
          <label className='checkbox-item'>
            <input
              className={'mark-input'}
              type={'checkbox'}
              // value={value}
              checked={inputValueSafe(value)}
              onChange={(inputValue) => onChange({ ...value, inputValue })} // Construct the settingField (= { origin, variableName, inputValue })
              onBlur={onBlur}
              onFocus={onFocus}
            />
            <span className='mark' />
          </label>
        </div>
      </div>
    );
  }
}
CheckBoxCol.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};
