import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';

export function mapStateToProps(state) {
  return {
    notifications: state.workbench.notifications,
  };
}

export default connect(mapStateToProps)(Notifications);
