import React, { FC } from 'react';

import { DatapoolType } from 'common/dist/types/datapool';
import DatapoolsCardView from './DatapoolsCardView';
import DatapoolsListView from './DatapoolsListView';
import { getNewDatapoolLink } from '../../../index/routes';
import NoElements from '../no-elements/NoElements';

type Props = {
  habitatCode: string;
  isListView: boolean;
  datapools: DatapoolType[];
};

const Datapools: FC<Props> = (props) => {
  const { datapools, habitatCode } = props;

  if (!datapools || datapools.length === 0)
    return (
      <NoElements
        title={{
          id: 'dashboard.datapools.add_first_datapool',
          defaultMessage: 'There are no Datapools for this Habitat yet.',
        }}
        linkTo={getNewDatapoolLink(habitatCode)}
        buttonLabelId={'common.new_datapool'}
        buttonLabelDefault={'New Datapool'}
      />
    );

  if (props.isListView) {
    // Table view
    return (
      <DatapoolsListView datapools={datapools} habitatCode={habitatCode} />
    );
  } else {
    // Card view
    return (
      <DatapoolsCardView datapools={datapools} habitatCode={habitatCode} />
    );
  }
};

export default Datapools;
