import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputElementShape } from '../_interface/P3InputElementShape';
import { getValueFromSettingsField } from '../_interface/input-element.form';
import { DateTimePicker } from 'react-widgets';
import { formatDate } from 'common/dist/utils/dates';
import moment from 'moment';
import MomentLocalizer from 'react-widgets-moment';

const localizer = new MomentLocalizer(moment);

export default class DatetimePicker extends Component {
  render() {
    const {
      path,
      cellId,
      updateDataOfInputElement,
      element,
      cell,
      useTime,
      useDate,
    } = this.props;

    const lowerBoundField = element.settings
      ? element.settings.lowerBound
      : undefined;
    const upperBoundField = element.settings
      ? element.settings.upperBound
      : undefined;
    const defaultValueField = element.settings
      ? element.settings.defaultValue
      : undefined;

    const lowerBound =
      getValueFromSettingsField(lowerBoundField, cell.as_variables) ||
      '1900-01-01 00:00:00';
    const upperBound =
      getValueFromSettingsField(upperBoundField, cell.as_variables) ||
      '2100-01-01 00:00:00';
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      cell.as_variables
    );

    const datePattern = `${useDate ? 'yyyy-MM-dd' : ''} ${
      useTime ? 'HH:mm:ss' : ''
    }`.trim();
    const formatPattern = `${useDate ? 'YYYY-MM-DD' : ''} ${
      useTime ? 'HH:mm:ss' : ''
    }`.trim();

    const valueOrDefaultOrNow = () => {
      if (element.data && element.data.datetime) return element.data.datetime;
      else if (defaultValue) return defaultValue;
      else return formatDate(new Date(), datePattern);
    };

    return (
      <div className={'inner-input-element datetime-picker-element'}>
        <div className={'datetime-picker'}>
          <DateTimePicker
            onChange={(datetime) =>
              updateDataOfInputElement(path, cellId, element, {
                datetime: formatDate(datetime, datePattern),
              })
            }
            value={new Date(valueOrDefaultOrNow())}
            min={new Date(lowerBound)}
            max={new Date(upperBound)}
            format={formatPattern}
            placeholder={'Select a Date & Time'}
            time={useTime}
            date={useDate}
          />
        </div>
      </div>
    );
  }
}

DatetimePicker.propTypes = {
  path: PropTypes.string.isRequired,
  cellId: PropTypes.string.isRequired,
  /** Function to update the user input */
  updateDataOfInputElement: PropTypes.func.isRequired,
  /** Input element (including the settings + the user input) */
  element: inputElementShape,
  cell: PropTypes.shape({}).isRequired,
  useDate: PropTypes.bool,
  useTime: PropTypes.bool,
};
DateTimePicker.defaultProps = {
  useDate: true,
  useTime: true,
};
