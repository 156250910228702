import DatapoolDetails from '../../../common/_interfaces/datapool-details/DatapoolDetails';
import { DatapoolDetailsTab } from '../../../common/_interfaces/datapool-details/DatapoolDetailsTab';
import TaxonomyWrapper from '../../../common/datapool-details-tabs/taxonomy/Taxonomy.wrapper';
import ArchetypesWrapper from '../../../common/datapool-details-tabs/archetypes/Archetypes.wrapper';
import MachineWrapper from '../../../common/datapool-details-tabs/machine/Machine.wrapper';
import TablesWrapper from '../../../common/datapool-details-tabs/cassandraTables/Tables.wrapper';
import StatisticsWrapper from '../../../common/datapool-details-tabs/statistics/Statistics.wrapper';
import * as inputFormats from 'common/dist/types/datapool';
import SettingsWrapper from '../../../common/datapool-details-tabs/settings/Settings.wrapper';
import DatapoolSettingsCategory from '../../../common/_interfaces/datapool-details/settings/DatapoolSettingsCategory';
import NamesAndCodesCategory from '../../../common/datapool-details-tabs/settings/datapoolSettingsCategories/namesAndCodes/NamesAndCodesCategory';
import { DatapoolType } from 'common/dist/types/datapool';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';

/**
 * Datapool Details for the binary classification (event-based) augur
 */
export default class BcDatapoolDetails extends DatapoolDetails {
  tabs: (
    datapool: DatapoolType,
    dataSource?: DataSource
  ) => DatapoolDetailsTab[] = (datapool, dataSource) => {
    const tabs = [];

    // --- Tables
    tabs.push(new TablesWrapper());

    // --- Statistics
    if (dataSource?.ds_type === 'cassandra') {
      // As long as the datapoolStatistics are only implemented for cassandra
      tabs.push(new StatisticsWrapper());
    }

    // --- Taxonomy
    if (datapool.inputFormat === inputFormats.POPULATION_EVENTS) {
      // The taxonomy page is only required for the population events input format
      tabs.push(new TaxonomyWrapper());
    }

    // --- Archetypes
    tabs.push(new ArchetypesWrapper());

    // --- Machine (Describes the Datatron - if a Datatron is delivering the data for this Datapool)
    tabs.push(new MachineWrapper());

    // --- Settings
    // Specification of the sub-components of the Settings tab
    const settingsCategories: DatapoolSettingsCategory[] = [
      new NamesAndCodesCategory(),
    ];
    tabs.push(new SettingsWrapper(settingsCategories));

    return tabs;
  };
}
