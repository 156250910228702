import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import {
  apiRequest,
  CompletedApiRequest,
  fetchQueryFn,
  postApiRequest,
} from './_tools';
import { Model, ModelHistoryEntry } from 'common/dist/types/mlModel';
import qs from 'qs';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { useDispatch } from 'react-redux';
import { sendNotification } from '../../redux/modules/notifications.module';
import archiveMsgs from 'common/dist/messages/augurs.modelArchive';
import * as NOTIFICATION_TYPES from '../notifications';
import { PostSetActiveModelRequestBody } from 'common/dist/types/requestBodies/mlModels';
import { PostPutDeleteResponseBody } from 'common/dist/types/responseBodies/base';

export const mlModelKeys = {
  all: () => ['models'] as const,
  some: (habitatCode: string, augurCode: string) =>
    [...mlModelKeys.all(), habitatCode, augurCode] as const,
  model: (habitatCode: string, augurCode: string, modelCode: string) =>
    [...mlModelKeys.some(habitatCode, augurCode), modelCode] as const,
  models: (
    habitatCode: string,
    augurCode: string,
    offset?: number,
    limit?: number
  ) => [...mlModelKeys.some(habitatCode, augurCode), offset, limit] as const,
  setActive: (habitatCode: string, augurCode: string) =>
    [...mlModelKeys.some(habitatCode, augurCode), 'set_active'] as const,
  modelHistory: (
    habitatCode: string,
    augurCode: string,
    modelCode?: string,
    offset?: number,
    limit?: number
  ) =>
    [
      ...mlModelKeys.some(habitatCode, augurCode),
      'history',
      modelCode,
      offset,
      limit,
    ] as const,
};

export function getModel(
  habitatCode: string,
  augurCode: string,
  modelCode: string
): CompletedApiRequest<Model> {
  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/models/${modelCode}`
  );
}

export function useModel(
  habitatCode: string,
  augurCode: string,
  modelCode = 'active'
): UseQueryResult<Model, ToBeRefined> {
  const key = mlModelKeys.model(habitatCode, augurCode, modelCode);
  return useQuery(key, () =>
    fetchQueryFn(key, () => getModel(habitatCode, augurCode, modelCode))
  );
}

export const useActiveModel = (
  habitatCode: string,
  augurCode: string
): UseQueryResult<Model> => useModel(habitatCode, augurCode, 'active');
export const useLatestModel = (
  habitatCode: string,
  augurCode: string
): UseQueryResult<Model> => useModel(habitatCode, augurCode, 'latest');

export function getModels(
  habitatCode: string,
  augurCode: string,
  offset?: number,
  limit?: number
): CompletedApiRequest<Model[]> {
  const query = qs.stringify({ offset, limit }, { addQueryPrefix: true });

  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/models${query}`
  );
}

export function useModels(
  habitatCode: string,
  augurCode: string,
  offset?: number,
  limit?: number
): UseQueryResult<Model[]> {
  const key = mlModelKeys.models(habitatCode, augurCode, offset, limit);
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () => getModels(habitatCode, augurCode, offset, limit)),
    {
      keepPreviousData: true,
    }
  );
}

export function setActiveModel(
  habitatCode: string,
  augurCode: string,
  modelCode: string
): CompletedApiRequest<PostPutDeleteResponseBody> {
  const body: PostSetActiveModelRequestBody = { modelCode };
  return postApiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/models/active`,
    body
  );
}

export function useSetActiveModel(
  habitatCode: string,
  augurCode: string
): UseMutationResult {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const key = mlModelKeys.setActive(habitatCode, augurCode);
  return useMutation(
    key,
    (modelCode: string) =>
      fetchQueryFn(key, () =>
        setActiveModel(habitatCode, augurCode, modelCode)
      ),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: mlModelKeys.some(habitatCode, augurCode),
        });
      },
      onSuccess: async (data, modelCode) => {
        dispatch(
          sendNotification(
            archiveMsgs.msgNotificationSuccessTitle.id,
            // @ts-ignore
            archiveMsgs.msgNotificationSuccessDescription.id,
            NOTIFICATION_TYPES.event,
            { modelCode: modelCode || '' }
          )
        );
      },
      onError: async (data, modelCode) => {
        dispatch(
          sendNotification(
            archiveMsgs.msgNotificationErrorTitle.id,
            // @ts-ignore
            archiveMsgs.msgNotificationErrorDescription.id,
            NOTIFICATION_TYPES.error,
            { modelCode: modelCode || '' }
          )
        );
      },
    }
  );
}

export function getModelHistory(
  habitatCode: string,
  augurCode: string,
  modelCode: string,
  offset: number,
  limit: number
): CompletedApiRequest<ModelHistoryEntry[]> {
  const query = qs.stringify(
    { modelCode, offset, limit },
    { addQueryPrefix: true }
  );

  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/models/history${query}`
  );
}

export function useModelHistory(
  habitatCode: string,
  augurCode: string,
  modelCode: string,
  offset: number,
  limit: number
): UseQueryResult<ModelHistoryEntry[]> {
  const key = mlModelKeys.modelHistory(
    habitatCode,
    augurCode,
    modelCode,
    offset,
    limit
  );
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () =>
        getModelHistory(habitatCode, augurCode, modelCode, offset, limit)
      ),
    {
      keepPreviousData: true,
    }
  );
}
