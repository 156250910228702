import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { form } from './cloneRepository.form';
import StepPath from './StepPath.container';
import { collaborationSpaceRoutes } from '../../routes';
import { Link, withRouter } from 'react-router-dom';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import Busy from '../../../atoms/busy/Busy';

class CloneRepositoryWizard extends Component {
  componentDidMount() {
    const {
      checkWhetherNotebookIsRunning,
      notebookUser,
      fetchRepoDetails,
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    checkWhetherNotebookIsRunning(notebookUser, false);
    fetchRepoDetails(group, repositoryName);
  }

  renderLoaded() {
    const {
      filledCompletely,
      cloneGitRepository,
      selectedDirPathString,
      gitDisplayName,
      gitMail,
      repoCode,
      activeBranch,
      repoDetails,
    } = this.props;
    const {
      repoName,
      repoFullName,
      repoType,
      codeCapsuleCode,
      archetypeCode,
      appCode,
      name,
    } = repoDetails?.data || {};

    // Content that will be written into the repository.asr meta file of the cloned repository (locally in the private
    //  notebook space - not on the git server)
    const metaFileContent = JSON.stringify({
      repoCode,
      repoType,
      repoName,
      name,
      codeCapsuleCode,
      archetypeCode,
      appCode,
      // Includes the group (=organization) as prefix to the repoName
      // TODO may be better to add owner.username, to avoid string manipulation. Would need to come from backend and be added while creating the repository
      //  although that is only useful to distinguish the gitea group (=organization = owner of a repo)
      repoFullName,
    });

    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    const {
      notebookRunning: { loading, isRunning },
    } = this.props;
    // repoName can be missing if only the git infos are available, but we still need a dir to clone into and its only(?!) used for that
    const repoNameSafe =
      repoName !== undefined ? repoName : repoFullName?.split('/')[1];

    if (!isRunning) {
      return (
        <div className={'dialog-container clone-repository-wizard'}>
          <div className={'dialog-inner-container'}>
            <p className={'dialog-headline'}>Clone Repository</p>
            <div className={'start-workbench-parent dialog-button-container'}>
              <p>
                Your Workbench isn't running yet. Please start it first before
                you can clone your repository.
              </p>
              <div className={'buttons'}>
                <Link
                  to={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}`}
                >
                  <button
                    type={'button'}
                    className={'dialog-button dialog-button-neutral'}
                  >
                    Cancel
                  </button>
                </Link>
                <Link to={workbenchRoutes.basePath}>
                  <button
                    type={'button'}
                    className={'dialog-button dialog-button-ok'}
                  >
                    Go to Workbench
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={'dialog-container clone-repository-wizard'}>
        <div className={'dialog-inner-container'}>
          <p className={'dialog-headline'}>Clone Repository</p>
          <form
            onKeyPress={(e) => {
              if (e.which === 13) e.preventDefault();
            }}
          >
            <StepPath />
            <div className={'dialog-button-container'}>
              <Link
                to={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}`}
              >
                <button
                  type={'button'}
                  className={'dialog-button dialog-button-neutral'}
                >
                  Cancel
                </button>
              </Link>
              <Link
                to={workbenchRoutes.basePath}
                onClick={() => {
                  if (filledCompletely && isRunning) {
                    cloneGitRepository(
                      selectedDirPathString,
                      repoNameSafe,
                      repoFullName,
                      gitDisplayName,
                      gitMail,
                      metaFileContent,
                      activeBranch
                    );
                  }
                }}
              >
                <button
                  type={'button'}
                  className={'dialog-button dialog-button-ok'}
                  disabled={!isRunning || !filledCompletely}
                >
                  Finish
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className={'CollaborationSpace--content'}>
        <Busy isBusy />
      </div>
    );
  }

  render() {
    const {
      notebookRunning: { loading },
      repoDetails,
    } = this.props;
    const { loading: loadingDetails } = repoDetails || {};
    if (loading || loadingDetails) return this.renderLoading();
    else return this.renderLoaded();
  }
}

CloneRepositoryWizard.propTypes = {
  fetchRepoDetails: PropTypes.func.isRequired,
  filledCompletely: PropTypes.bool.isRequired,
  cloneGitRepository: PropTypes.func.isRequired,
  /** Code of the repository */
  repoCode: PropTypes.string.isRequired,
  selectedDirPathString: PropTypes.string.isRequired,
  /** Derived from the first and last name of the currently logged in user */
  gitDisplayName: PropTypes.string.isRequired,
  gitMail: PropTypes.string.isRequired,
  activeBranch: PropTypes.string.isRequired,
  /** List of notebooks in the currently active "selectedDirPath" */
  notebooksInCurrentDir: PropTypes.array.isRequired,
  repoDetails: PropTypes.shape({
    data: PropTypes.shape({
      repoName: PropTypes.string.isRequired,
      repoFullName: PropTypes.string.isRequired,
      repoType: PropTypes.string.isRequired,
      codeCapsuleCode: PropTypes.string.isRequired,
      archetypeCode: PropTypes.string.isRequired,
      appCode: PropTypes.string.isRequired,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      group: PropTypes.string,
      repositoryName: PropTypes.string,
    }).isRequired,
  }).isRequired,
  checkWhetherNotebookIsRunning: PropTypes.func.isRequired,
  notebookRunning: PropTypes.shape({
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    isRunning: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default withRouter(reduxForm(form)(CloneRepositoryWizard));
