import React, { Component } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import {
  fieldSamplingStrategy,
  FormValues,
  SamplingStrategySettingsField,
} from './samplingStrategy.form';
import CancelSubmitButtonsWrapper from '../../../../../../../../molecules/reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import SamplingStrategySelect, {
  Props as SamplingStrategySelectProps,
} from '../../../../../../../../molecules/sampling-strategy/SamplingStrategySelect';
import { BaseFieldProps } from 'redux-form/lib/Field';
import { DatapoolType } from 'common/dist/types/datapool';
import { CProps } from './SamplingStrategy.container';
import ReactModal from 'react-modal';

import TablePreviewModal from '../../../../../../../../organisms/table-preview-modal/TablePreviewModal.container';
import { tablePreviewCustomStyles } from '../../../../../../../../organisms/table-preview-modal/TablePreviewModal';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

function validate(value?: SamplingStrategySettingsField) {
  let error = undefined;

  if (value?.strategy === 'table' && value?.evaluationTable === undefined) {
    error = { id: 'newAugur.step.samplingStrategy.error.no_table_name' };
  }
  return error;
}

export interface Props {
  /** Code of the in a previous step selected datapool */
  datapool: DatapoolType;
}

interface State {
  modalIsShown: boolean;
}

class SamplingStrategy extends Component<
  Props &
    CProps &
    InjectedFormProps<FormValues, Props> &
    ModelSettingsProps<AugurSettingsDataNbc>,
  State
> {
  constructor(
    props: Props &
      CProps &
      InjectedFormProps<FormValues, Props> &
      ModelSettingsProps<AugurSettingsDataNbc>
  ) {
    super(props);

    this.handleSaveSettings = this.handleSaveSettings.bind(this);

    this.state = {
      modalIsShown: false,
    };
  }

  handleSaveSettings(data: FormValues) {
    const { saveSettings } = this.props;
    const samplingStrategy = data[fieldSamplingStrategy];
    const samplingStrategySettings =
      samplingStrategy.strategy === 'automatic'
        ? {
            strategy: samplingStrategy.strategy,
            trainingSplit: samplingStrategy.trainingSplit,
          }
        : {
            strategy: samplingStrategy.strategy,
            evaluationTable: samplingStrategy.evaluationTable,
          };
    const settings = {
      samplingStrategy: samplingStrategySettings,
    };
    saveSettings(settings, true);
  }

  render() {
    const { saving, pristine, reset, datapool, handleSubmit, tableName } =
      this.props;
    return (
      <div className={'SamplingStrategy'}>
        <CancelSubmitButtonsWrapper
          onCancel={reset}
          onSubmit={handleSubmit((data) => {
            this.handleSaveSettings(data);
          })}
          savingButtonBusy={saving}
          disabled={pristine || saving}
        >
          <div>
            <Field<BaseFieldProps<SamplingStrategySelectProps>>
              name={fieldSamplingStrategy}
              component={SamplingStrategySelect}
              validate={validate}
              props={{
                datapool,
                withPreviewButton: true,
                previewButtonOnClick: () => {
                  this.setState({ modalIsShown: true });
                },
              }}
            />
          </div>
        </CancelSubmitButtonsWrapper>

        <ReactModal
          isOpen={this.state.modalIsShown}
          contentLabel='Add Directory before Transfer'
          onRequestClose={() => this.setState({ modalIsShown: false })}
          style={tablePreviewCustomStyles}
          appElement={document.getElementById('react-app')}
        >
          <TablePreviewModal
            datapool={datapool}
            tableName={tableName}
            hideTablePreview={() => this.setState({ modalIsShown: false })}
          />
        </ReactModal>
      </div>
    );
  }
}

export default SamplingStrategy;
