import React, { Component } from 'react';
import LauncherCreateNotebook from './LauncherCreateNotebook.container';

export default class Launcher extends Component {
  render() {
    return (
      <div className={'notebook-tab-content launcher-content'}>
        <div className={'launcher-headline'}>AI Workbench Launcher</div>
        <LauncherCreateNotebook />
      </div>
    );
  }
}
