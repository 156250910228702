import { connect } from 'react-redux';
import App from './App';
import {
  selectPreviousStep,
  selectNextStepAndExecute,
} from '../../../../redux/workbench/modules/app.module';

export function mapStateToProps(state) {
  const { path, executionPlan, activeStep, session } = state.workbench.app;
  return {
    path,
    // notebook, // -> The notebook is not required for the app. The executionPlan is the single source of truth!
    executionPlan,
    activeStep,
    session,
  };
}

export const mapDispatchToProps = {
  selectNextStepAndExecute,
  selectPreviousStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
