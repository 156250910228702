import React, { FC } from 'react';
import LoginHistoryItem from './LoginHistoryItem';
import { FormattedMessage } from 'react-intl';
import { HistoryItemType } from '../../../../store/account/state.types';

type Props = {
  history: HistoryItemType[];
};

const LoginHistoryItems: FC<Props> = ({ history }) => {
  return (
    <div className={'table-reset'}>
      <table>
        <thead>
          <tr>
            <td>
              <FormattedMessage
                id='account.loginHistory.datetime'
                defaultMessage='Datetime'
              />
            </td>
            <td>
              <FormattedMessage
                id='account.loginHistory.event'
                defaultMessage='Event'
              />
            </td>
            <td>
              <FormattedMessage
                id='account.loginHistory.success'
                defaultMessage='Successful'
              />
            </td>
            <td>
              <FormattedMessage
                id='account.loginHistory.ip'
                defaultMessage='IP'
              />
            </td>
          </tr>
        </thead>
        <tbody>
          {history.map((item, index) => (
            <LoginHistoryItem item={item} key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LoginHistoryItems;
