import React, { Component } from 'react';
import {
  FiArrowDown,
  FiArrowUp,
  FiClipboard,
  FiCopy,
  FiPlus,
  FiScissors,
  FiTrash2,
} from 'react-icons/fi';
import { ToBeRefined } from 'common/dist/types/todo_type';
import {
  notificationCopy,
  notificationCut,
} from '../../../../../../../redux/workbench/notifications/notifications';
import DropdownSelectInput from '../../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { AltaSigmaCell } from '../../../../../../../store/workbench/state.types';

// Used as the dummy "path" of the notebook to treat it as a regular notebook in the Redux state.
export const BASE_FILE_PATH = '__base_file.ipynb';

type Props = {
  /** Index of the first selected cell */
  selectedCellIndex: number;
  selectedCells: string[];
  addCell: (path: string, index: number) => void;
  moveCellsUp: (
    path: string,
    selectedCells: string[],
    selectedCellIndex: number
  ) => void;
  moveCellsDown: (
    path: string,
    selectedCells: string[],
    selectedCellIndex: number
  ) => void;
  copyCellsToClipboard: (cellArray: AltaSigmaCell[]) => void;
  content: ToBeRefined;
  selectCells: (path: string, cellsToSelect: string[]) => void;
  deleteCells: (path: string, cellIds: string[]) => void;
  infoNotification: (message: ToBeRefined) => void;
  pasteCellsFromClipboard: (
    path: string,
    cells: AltaSigmaCell[],
    index: number
  ) => void;
  clipboard: ToBeRefined;
  changeCellType: (path: string, cellIds: string[], newType: string) => void;
  /** List of available cell types for the Dropdown selection */
  availableCells: OptionType[];
  /** Type of the (first) selected cell */
  selectedCellType: string;
};

type OptionType = { label: string; value: string };

export default class ButtonsBaseFile extends Component<Props, {}> {
  render() {
    const {
      content,
      selectedCellIndex,
      addCell,
      selectedCells,
      moveCellsUp,
      moveCellsDown,
      deleteCells,
      infoNotification,
      copyCellsToClipboard,
      pasteCellsFromClipboard,
      clipboard,
      changeCellType,
      availableCells,
      selectedCellType,
    } = this.props;
    return (
      <div className={'buttons-base-file'}>
        <div
          title={'Add Cell'}
          className={'merger-button'}
          onClick={() => addCell(BASE_FILE_PATH, selectedCellIndex + 1)}
        >
          <FiPlus className={'icon add-cell-icon'} size={'20px'} />
        </div>

        <div
          title={'Move Cell up'}
          className={'merger-button'}
          onClick={() =>
            moveCellsUp(BASE_FILE_PATH, selectedCells, selectedCellIndex)
          }
        >
          <FiArrowUp className={'icon add-cell-icon'} size={'20px'} />
        </div>

        <div
          title={'Move Cell down'}
          className={'merger-button'}
          onClick={() =>
            moveCellsDown(BASE_FILE_PATH, selectedCells, selectedCellIndex)
          }
        >
          <FiArrowDown className={'icon add-cell-icon'} size={'20px'} />
        </div>

        <div
          title={'Cut'}
          className={'merger-button'}
          onClick={() => {
            if (!content.cells) return;
            const selectedCells = this.props.selectedCells;
            if (!selectedCells || selectedCells.length === 0) return;

            // Copy cells to clipboard
            const cellArr = content.cells.filter(
              (c) => selectedCells && selectedCells.includes(c.id)
            );
            copyCellsToClipboard(cellArr);

            // Select next cell
            const cellId = selectedCells[selectedCells.length - 1];
            const cells = content.cells;
            const index = cells.findIndex((c) => c.id === cellId);
            if (index < cells.length - 1) {
              // There is a next cell -> select it
              const nextId = cells[index + 1].id;
              this.props.selectCells(BASE_FILE_PATH, [nextId]);
            } else {
              this.props.selectCells(BASE_FILE_PATH, []);
            }

            // Delete cells
            deleteCells(BASE_FILE_PATH, this.props.selectedCells);
            infoNotification(notificationCut('cells'));
          }}
        >
          <FiScissors className={'icon cut-icon'} size={'20px'} />
        </div>
        <div
          title={'Copy'}
          className={'merger-button'}
          onClick={() => {
            if (!content.cells) return;
            const selectedCells = this.props.selectedCells;
            if (!selectedCells || selectedCells.length === 0) return;
            const cellArr = content.cells.filter(
              (c) => selectedCells && selectedCells.includes(c.id)
            );
            copyCellsToClipboard(cellArr);
            infoNotification(notificationCopy('cells'));
          }}
        >
          <FiCopy className={'icon copy-icon'} size={'20px'} />
        </div>
        <div
          title={'Paste'}
          className={`merger-button${
            !clipboard || clipboard.type !== 'cells' ? ' disabled' : ''
          }`}
          onClick={() => {
            // Check whether there are cells in the clipboard, if not: cancel.
            if (!clipboard || clipboard.type !== 'cells') {
              return;
            }

            const selectedCells = this.props.selectedCells;
            let cellIndex;
            if (!selectedCells || selectedCells.length === 0) {
              // No cells selected -> Insert at the end
              cellIndex = content.cells.length;
            } else {
              const cellId = selectedCells[selectedCells.length - 1];
              cellIndex = content.cells.findIndex((c) => c.id === cellId);
            }
            const data = clipboard.data;
            pasteCellsFromClipboard(BASE_FILE_PATH, data, cellIndex);
          }}
        >
          <FiClipboard className={'icon paste-icon'} size={'20px'} />
        </div>
        <div
          title={'Delete'}
          className={'merger-button'}
          onClick={() => {
            const selectedCells = this.props.selectedCells;
            if (!selectedCells || selectedCells.length === 0) return;
            // Select next cell
            const cellId = selectedCells[selectedCells.length - 1];
            const cells = content.cells;
            const index = cells.findIndex((c) => c.id === cellId);
            if (index < cells.length - 1) {
              // There is a next cell -> select it
              const nextId = cells[index + 1].id;
              this.props.selectCells(BASE_FILE_PATH, [nextId]);
            } else {
              this.props.selectCells(BASE_FILE_PATH, []);
            }

            // Delete Cell
            deleteCells(BASE_FILE_PATH, selectedCells);
          }}
        >
          <FiTrash2 className={'icon delete-icon'} size={'20px'} />
        </div>
        <div className={'dropdown cell-type'} title={'Change Cell type'}>
          <DropdownSelectInput
            options={availableCells}
            value={availableCells.find((o) => o.value === selectedCellType)}
            onChange={(option: OptionType) =>
              changeCellType(
                BASE_FILE_PATH,
                this.props.selectedCells,
                option.value
              )
            }
            appearance={'small'}
          />
        </div>
      </div>
    );
  }
}
