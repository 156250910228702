import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../types';
import {
  useAugur,
  useSettings,
  useUpdateSettings,
} from '../../../../../../core/api/augurs';
import { ComponentType, FC } from 'react';
import { ModelSettingsProps } from '../../_interfaces/augur-details/model-settings/ModelSettingsCategory';

// this is required in order to use the React Query hooks with the existing implementation of the OptAugurDetails
// TODO: this should be removed if possible
export const SettingsWrapper: FC<ComponentType<ModelSettingsProps>> = (
  WrappedComponent: ComponentType<ModelSettingsProps>
) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const { data: augur } = useAugur(habitatCode, augurCode);
  const { data: settings } = useSettings(habitatCode, augurCode);
  const saveSettings = useUpdateSettings(habitatCode, augurCode);

  return (
    <WrappedComponent
      augur={augur}
      settings={settings.data}
      saveSettings={(settings, merge) =>
        saveSettings.mutate({ settings, merge })
      }
      saving={saveSettings.isLoading}
    />
  );
};
