import { connect } from 'react-redux';
import ConflictsButtonRow from './ConflictsButtonRow';
import { DeprecatedRootState } from '../../../../../../../store/state.type';
import {
  toggleSourceFile,
  toggleTargetFile,
} from '../../../../../../../redux/workbench/modules/merger.module';

export function mapStateToProps(state: DeprecatedRootState) {
  const { sourceExtended, targetExtended } = state.workbench.mergerAPI || {};
  return {
    sourceExtended,
    targetExtended,
  };
}

export const mapDispatchToProps = {
  toggleSourceFile,
  toggleTargetFile,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ConflictsButtonRow);
