import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  fieldNameDefaultValue,
  fieldNameLowerBound,
  fieldNameUpperBound,
} from '../_interface/input-element.form';
import TextInputCol from '../../../settings-dialog/dialog-settings-cols/TextInputCol';
import { inputModalForm } from '../../../settings-dialog/inpmodalDialog.form';

class NumberInputSettings extends Component {
  render() {
    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldNameDefaultValue}
            label={'Default Value (optional)'}
            component={TextInputCol}
            placeholder={'Enter Default Value'}
          />
        </div>

        <div className={'input-row'}>
          <Field
            name={fieldNameLowerBound}
            label={'Lower Bound (optional)'}
            component={TextInputCol}
            placeholder={'Enter Lower Bound'}
          />
          <Field
            name={fieldNameUpperBound}
            label={'Upper Bound (optional)'}
            component={TextInputCol}
            placeholder={'Enter Upper Bound'}
          />
        </div>
      </Fragment>
    );
  }
}

export default reduxForm(inputModalForm)(NumberInputSettings);
