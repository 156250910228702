import { connect } from 'react-redux';
import RealtimeSummary from './RealtimeSummary';
import { fetchRealtimeModelSummary } from '../../../redux/modules/orchestration.realtime.module';

export function mapStateToProps(state) {
  return {
    loading: state.realtime.summary.loading,
    loaded: state.realtime.summary.loaded,
    error: state.realtime.summary.error,
    data: state.realtime.summary.data,
  };
}

export const mapDispatchToProps = {
  fetchRealtimeModelSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealtimeSummary);
