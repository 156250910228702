import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import FieldResources from './FieldResources';
import { fieldParameters, fieldResources, form } from './form';
import FieldParameters from './FieldParameters';
import Button from '../../../atoms/button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minWidth: '600px',
    transform: 'translate(-50%, -50%)',
  },
};

export interface Props {
  isShown?: boolean;
  hideConfigureCodeCapsuleModal: (...args: unknown[]) => unknown;
  resetForm: (...args: unknown[]) => unknown;
  updateCodeCapsuleConfiguration: (...args: unknown[]) => unknown;
  path?: string;
  formValues?: {
    resources?: {
      cpu?: string;
      memory?: string;
    };
    parameters?: {
      key?: string;
      value?: string;
    }[];
  };
  repoMeta?: {};
}

type CombinedProps = Props & InjectedFormProps;

class ConfigureCodeCapsuleModal extends Component<CombinedProps> {
  static defaultProps: Partial<CombinedProps> = {
    isShown: false,
  };

  render() {
    const {
      isShown,
      hideConfigureCodeCapsuleModal,
      updateCodeCapsuleConfiguration,
      resetForm,
      path,
      formValues,
      repoMeta,
      valid,
    } = this.props;
    const repoMetaWithValues = { ...repoMeta, ...formValues };
    return (
      <ReactModal
        isOpen={isShown}
        contentLabel='Dialog Modal'
        onRequestClose={() => {
          hideConfigureCodeCapsuleModal();
          resetForm();
        }}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer')
          /* Function that will be called to get the parent element
             that the modal will be attached to. */
        }
      >
        <div className={'configure-code-capsule-modal modal-parent'}>
          <div
            title={'Close Dialog'}
            className={'workbench-button close-button'}
            onClick={() => {
              hideConfigureCodeCapsuleModal();
              resetForm();
            }}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <div className={'modal-group-parent resources-parent'}>
            <span className={'modal-headline'}>
              Proposed Resources (optional)
            </span>
            <span className={'modal-subheadline'}>
              Propose resource limits for this Code Capsule.
            </span>
            <Field name={fieldResources} component={FieldResources} />
          </div>

          <div className={'modal-group-parent parameters-parent'}>
            <span className={'modal-headline'}>Available Parameters</span>
            <span className={'modal-subheadline'}>
              Define the list of available input Parameters and their default
              values.
            </span>
            <Field name={fieldParameters} component={FieldParameters} />
          </div>

          <div className={'code-capsule-modal--button-bar'}>
            <Button
              buttonColor={'primary'}
              withLink={false}
              buttonLabelDefault={'OK'}
              onClick={() => {
                updateCodeCapsuleConfiguration(path, repoMetaWithValues);
                hideConfigureCodeCapsuleModal();
              }}
              disabled={!valid}
            />
            <Button
              buttonColor={'white'}
              withLink={false}
              buttonLabelDefault={'Cancel'}
              onClick={() => {
                hideConfigureCodeCapsuleModal();
                resetForm();
              }}
            />
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default reduxForm(form)(ConfigureCodeCapsuleModal);
