import React, { FC } from 'react';
import './checkbox.scss';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

export type Props = {
  checked: boolean;
  /** Really an onClickEvent */
  onChange: (event: React.MouseEvent<HTMLDivElement>) => void;
  disabled: boolean;
  label?: MessageDescriptor;
};

const Checkbox: FC<Props> = ({ disabled, checked, label, onChange }) => (
  <div
    className='Checkbox'
    onClick={(event) => {
      if (!disabled) {
        onChange(event);
      }
    }}
  >
    <input
      className='Checkbox--mark-input'
      type='checkbox'
      checked={checked}
      disabled={disabled}
      // TODO warning: Checkbox contains an input of type checkbox with both checked and defaultChecked props. Input elements must be either controlled or uncontrolled (specify either the checked prop, or the defaultChecked prop, but not both). Decide between using a controlled or uncontrolled input element and remove one of these props. More info: https://reactjs.org/link/controlled-components
    />
    <span className='Checkbox--mark' />
    {label && (
      <FormattedMessage {...label}>
        {(text) => <span className='Checkbox--label'>{text}</span>}
      </FormattedMessage>
    )}
  </div>
);

export default Checkbox;
