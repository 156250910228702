import { connect } from 'react-redux';
import IntroStep, { OwnProps } from './IntroStep';
import { updateAppIntro } from '../../../../redux/workbench/modules/app.module';

export function mapStateToProps(state, { path, isArrangeEditor }: OwnProps) {
  const appIntro = isArrangeEditor
    ? state.workbench?.notebooks?.[path]?.content?.appIntro || {}
    : state.workbench?.app?.notebook?.content?.appIntro || {};

  return {
    appIntro,
  };
}

export const mapDispatchToProps = {
  updateAppIntro,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroStep);
