import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RepositoryTypeIds } from '../repository.form';
import SvgPlain from './icons/SvgPlain';
import SvgCodeCapsule from './icons/SvgCodeCapsule';
import SvgApp from './icons/SvgApp';
import SvgArchetype from './icons/SvgArchetype';
// import SvgPython from './icons/SvgPython';;

export default class RepositoryTypeCard extends Component {
  icon() {
    switch (this.props.name) {
      case RepositoryTypeIds.PLAIN: {
        return <SvgPlain className={'icon'} />;
      }
      case RepositoryTypeIds.CODE_CAPSULE: {
        return <SvgCodeCapsule className={'icon'} />;
      }
      case RepositoryTypeIds.APP: {
        return <SvgApp className={'icon'} />;
      }
      case RepositoryTypeIds.ARCHETYPE: {
        return <SvgArchetype className={'icon'} />;
      }
      default:
        return null;
    }
  }

  render() {
    const { selectedRepositoryType, onChange, name, displayName, disabled } =
      this.props;
    return (
      <div
        className={`repository-type-card ${
          selectedRepositoryType === name ? ' selected' : ''
        } ${
          selectedRepositoryType && selectedRepositoryType !== name
            ? ' unselected'
            : ''
        } ${!disabled ? ' selectable' : ''}`}
        onClick={() => {
          if (!disabled) {
            onChange(name);
          }
        }}
      >
        <div className={'icon-container'}>{this.icon()}</div>
        <p className={'display-name'}>{displayName}</p>
      </div>
    );
  }
}
RepositoryTypeCard.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  /** Selected repository type */
  selectedRepositoryType: PropTypes.string,
  disabled: PropTypes.bool,
};
