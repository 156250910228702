import { connect } from 'react-redux';
import StepTag from './StepTag';
import { fetchSingleCodeCapsule } from '../../../../../redux/modules/codeCapsules.module';

export function mapStateToProps(state, { codeCapsuleCode }) {
  const {
    loaded: versionsLoaded,
    loading: versionsLoading,
    error: versionsError,
    data,
  } = state.codeCapsules.perCode[codeCapsuleCode] || {};
  const versionsData = data?.versions;
  return {
    versionsLoaded,
    versionsLoading,
    versionsError,
    versionsData,
  };
}

export const mapDispatchToProps = {
  fetchSingleCodeCapsule,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTag);
