// TODO is this still a useful initialization?
export const initial = {
  loading: false,
  name: '',
  locale: 'en',
};

export const test = {
  loading: false,
  name: 'John Johnson',
  firstName: 'John',
  lastName: 'Johnson',
};
