import React, { Component } from 'react';
import { renderRoutes } from 'react-router-config';
import { Redirect, withRouter } from 'react-router-dom';

import datapoolRoutes from './datapoolDetailRoutes';
import {
  getDatapoolDetailsDefaultPage,
  getDatapoolDetailsTabs,
} from '../modelManagement/modules/ModuleManagerClient';
import MainContainer from '../pages/main-container/MainContainer';
import WithSidemenu from '../molecules/with-sidemenu/WithSidemenu';
import { MenuEntryType } from '../molecules/with-sidemenu/SideMenu';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { DatapoolType } from 'common/dist/types/datapool';
import SubHeader from '../organisms/sub-header/SubHeader.container';
import OverlayDatapoolDetails from '../organisms/sub-header/overlay-datapool-details/OverlayDatapoolDetails.container';
import { RouteComponentProps } from 'react-router';
import { CProps } from './DatapoolDetails.container';
import Busy from '../atoms/busy/Busy';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';

type Props = {
  /** Are the datapool details loading? */
  loading: boolean;
  /** Have the datapool details been loaded? */
  loaded: boolean;
  /** Datapool Details */
  data?: DatapoolType;
  /** Error while fetching the datapool details */
  error?: ToBeRefined;
  /** Fetch the details for this datapool */
  fetchDatapool: (habitatCode: string, datapoolCode: string) => void;
  fetchDataSources: () => void;
  /** Clear the datapool details from the redux state */
  clearDatapoolDetails: () => void;
  /** Injected by React Router */
  match: {
    isExact: boolean;
    url: string;
    params: {
      habitatCode: string;
      datapoolCode: string;
      moduleType: string;
    };
  };
  history: {
    location: {
      pathname: string;
    };
  };
};

class DatapoolDetails extends Component<Props & CProps & RouteComponentProps> {
  componentDidMount() {
    const {
      fetchDatapool,
      match: {
        params: { habitatCode, datapoolCode },
      },
      fetchDataSources,
    } = this.props;
    fetchDatapool(habitatCode, datapoolCode);
    fetchDataSources();
  }

  componentWillUnmount() {
    const { clearDatapoolDetails } = this.props;
    clearDatapoolDetails();
  }

  /**
   * Derives the menu entries for the DatapoolDetails
   */
  getMenuEntries(
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string,
    datapool: DatapoolType,
    dataSource?: DataSource
  ): MenuEntryType[] {
    if (!shortDatapoolType) return [];
    return getDatapoolDetailsTabs(
      habitatCode,
      datapoolCode,
      shortDatapoolType,
      datapool,
      dataSource
    );
  }

  getActiveCategory() {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    if (!pathname) return null;
    const parts = pathname.split('/');
    if (parts.length < 8) return null;

    const active = parts[7]; // TODO No good idea to pick out the active category like this ...
    return active;
  }

  renderLoading() {
    return <Busy isBusy={true} />;
  }

  renderError() {
    const { error, dataSources } = this.props;
    return (
      <div>
        <div>Datapool Error: {JSON.stringify(error)}</div>
        <div>Datasources Error: {JSON.stringify(dataSources.error)}</div>
      </div>
    );
  }

  render() {
    const { data, loading, loaded, match, dataSources } = this.props;
    if (loading || dataSources.loading) {
      return this.renderLoading();
    }
    if (!loaded || !dataSources.loaded) {
      return this.renderError();
    }

    const {
      params: { moduleType: datapoolType, habitatCode, datapoolCode },
    } = match;
    // Just to make it more robust we assume this may find no ds (perhaps it was deleted) Add extra tabs and routes if we found it
    const dataSource = dataSources.data.find(
      (ds) => ds.code === data.dataSourceCode
    );

    if (match.isExact) {
      const defaultPage = getDatapoolDetailsDefaultPage(datapoolType);
      return <Redirect to={`${match.url}/${defaultPage}`} />;
    }

    const selectedCategory = this.getActiveCategory();

    return (
      <MainContainer>
        <SubHeader
          hasOverlay
          Overlay={OverlayDatapoolDetails}
          defaultSelectedTabId={'models'}
        />

        <WithSidemenu
          menuEntries={this.getMenuEntries(
            habitatCode,
            datapoolCode,
            datapoolType,
            data,
            dataSource
          )}
          selectedTab={selectedCategory}
          handleSelect={() => {
            /* Nothing to do, right? */
          }}
        >
          {renderRoutes(
            datapoolRoutes(data, dataSource).filter(
              ({ path }: { path: string; exact: boolean }) =>
                path === '*' || path.includes(datapoolType)
            )
            // .filter(({ path }: { path: string; exact: boolean }) => {
            //   switch (datapoolType) {
            //     case 'nbc':
            //       if (path !== undefined && dataSource?.ds_type === 's3') {
            //         return path.split('/')[7] !== 'statistics';
            //       } else {
            //         return true;
            //       }
            //     default:
            //       return true;
            //   }
            // })
          )}
        </WithSidemenu>
      </MainContainer>
    );
  }
}

export default withRouter(DatapoolDetails);
