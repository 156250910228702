import React, { Component } from 'react';
import anime from 'animejs';
import Convert from 'ansi-to-html';
import { ToBeRefined } from 'common/dist/types/todo_type';
import Measure from 'react-measure';
import { Error } from 'common/dist/utils/workbench/jupyterNotebookFormat';

const convert = new Convert();

function convertTracebackToHtml(traceback) {
  return traceback.map((t) => convert.toHtml(t));
}

type Props = {
  evalue: Error['evalue'];
  traceback: Error['traceback'];
  status: ToBeRefined;
};

type State = {
  height: number;
};

export class ExtendedErrorOutput extends Component<Props, State> {
  aniRef = undefined;
  animeRef = undefined;

  constructor(props) {
    super(props);
    this.state = { height: -1 };
    this.aniRef = React.createRef();
  }

  componentDidUpdate() {
    if (['entering', 'exiting'].includes(this.props.status)) {
      this.animeRef = anime({
        targets: this.aniRef.current,
        height: () => {
          if (this.props.status === 'entering') {
            return this.state.height;
          } else if (this.props.status === 'exiting') {
            return 0;
          }
        },
        opacity: () => {
          if (this.props.status === 'entering') {
            return 1.0;
          } else if (this.props.status === 'exiting') {
            return 0.0;
          }
        },
        duration: 200,
        elasticity: 0,
        easing: 'linear',
      });
    }
  }

  render() {
    return (
      <div className={'output-error-value-container'} ref={this.aniRef}>
        <Measure
          bounds
          onResize={(r) => this.setState({ height: r.bounds.height })}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              <p className={'output-error-value'}>{this.props.evalue}</p>
              <div className={'output-error-traceback'}>
                {convertTracebackToHtml(this.props.traceback).map((line, i) => (
                  <div key={i} dangerouslySetInnerHTML={{ __html: line }} />
                ))}
              </div>
            </div>
          )}
        </Measure>
      </div>
    );
  }
}
