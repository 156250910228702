import { connect } from 'react-redux';
import RepositoryStepType from './RepositoryStepType';
import {
  selectFieldSyncError,
  selectFieldTouched,
  selectValue,
} from '../selectorUtils';
import { fieldType, formName } from '../repository.form';

export function mapStateToProps(state) {
  return {
    repositoryType: selectValue(state, formName, fieldType),
    repositoryTypeTouched: selectFieldTouched(state, formName, fieldType),
    repositoryTypeError: selectFieldSyncError(state, formName, fieldType),
  };
}

export default connect(mapStateToProps)(RepositoryStepType);
