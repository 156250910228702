import { connect } from 'react-redux';
import App from './App';

import { getLinkForFirstPermittedMainTab } from 'common/dist/utils/authorization.general';

export function mapStateToProps(state) {
  const permissions = state.dashboard?.permissions?.data || {};
  const mainTabsDefaultLink = getLinkForFirstPermittedMainTab(permissions);

  return {
    mainTabsDefaultLink,

    initialized: state.dashboard.initialized,
  };
}

export default connect(mapStateToProps)(App);
