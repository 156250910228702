import { axisLeft } from 'd3-axis';
import { format } from 'd3-format';

import appendRoot from '../common/root';
import getScales from '../common/scales';

const TICK_STEPS = 10;

export const getTickValues = (maxY) => {
  const values = [];
  const step = maxY / TICK_STEPS;

  for (let i = 0; i < maxY + step; i += step) {
    values.push(i);
  }

  return values;
};

export function append(node, width, height, runs, maxY) {
  const { g, areaWidth, areaHeight } = appendRoot(node, width, height);

  const { yScale } = getScales(areaWidth, areaHeight, runs, maxY);

  const gTicks = g.append('g').attr('class', 'accuracy-detail-axis');

  const tickValues = getTickValues(maxY);

  const axisTicks = axisLeft(yScale)
    .tickValues(tickValues)
    .tickFormat(format('.2f'));

  gTicks.call(axisTicks);
}
