import * as React from 'react';
import { Component } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/all';
import CSS from 'csstype';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';

type State = {
  open: boolean;
};

export type Props = {
  title?: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  onChange?: (isOpen: boolean) => void;
  /** Optional height for collapsed state in pixel. Default: 0 */
  collapsedHeight?: number;
  containerStyle?: CSS.Properties;
  contentStyle?: CSS.Properties;
  childrenStyle?: CSS.Properties;
};

export default class CollapsibleArea extends Component<Props, State> {
  static defaultProps = {
    defaultOpen: false,
    collapsedHeight: 0,
    childrenStyle: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      open: props.defaultOpen,
    };
  }

  render() {
    const {
      onChange,
      children,
      title,
      containerStyle,
      contentStyle,
      collapsedHeight,
      childrenStyle,
    } = this.props;
    const { open } = this.state;
    return (
      <div className={styles.container} style={containerStyle}>
        {title && (
          <div className={styles.headline}>
            <span>{title}</span>
          </div>
        )}
        <div
          className={styles.content}
          style={{
            borderTopWidth: open || collapsedHeight ? '1px' : 0,
            ...contentStyle,
          }}
        >
          {open ? (
            <button
              type={'button'}
              className={styles.button}
              id={'collapsableWizardButton'}
              onClick={() => {
                this.setState({ open: false });
                onChange?.(false);
              }}
            >
              <FormattedMessage
                id='dashboard.show_less'
                defaultMessage='Show Less'
              />
              <FiChevronUp className={styles.buttonIcon} />
            </button>
          ) : (
            <button
              type='button'
              className={styles.button}
              id='collapsableWizardButton'
              onClick={() => {
                this.setState({ open: true });
                onChange?.(true);
              }}
            >
              <FormattedMessage
                id='dashboard.show_more'
                defaultMessage='Show More'
              />
              <FiChevronDown className={styles.buttonIcon} />
            </button>
          )}

          {(open || collapsedHeight > 0) && (
            <div
              className={styles.children}
              style={{
                height: open ? 'unset' : `${collapsedHeight}px`,
                ...childrenStyle,
              }}
            >
              {children}
            </div>
          )}
        </div>
      </div>
    );
  }
}
