import * as React from 'react';

const Optimization = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 100 100'
    {...props}
  >
    <path
      d='M67.7 12.2c-2.5 4.5-4.3 8.8-4 9.5 1 2.6 3.8.8 6.5-4.2 1.8-3.3 2.9-4.5 3.2-3.5 2.9 8.1 3.4 22.5 1 27.1-2.4 4.8-6.8 6-25.7 7-14 .7-17.7 1.2-21.3 3-8.4 4-10.5 9.4-8.6 22.4 1.3 8.6 3 11.7 5.1 9.2.8-1.1.9-2.6.2-5.8-1.6-6.7-1.3-15.1.5-17.7 3-4.4 6.9-5.4 23.6-6.3 8.7-.5 17.7-1.3 19.8-1.9 11.4-3.1 15.2-13.7 11.5-31.4-.8-3.8-1.3-7.1-1.1-7.4.2-.2 2.2.6 4.5 1.7 5 2.6 7.1 2.7 7.1.2 0-1.3-2.3-3.1-7.9-6C77.8 5.9 73.7 4 73.2 4c-.6 0-3 3.7-5.5 8.2z'
      fill='#224e90'
    />
    <path
      d='M17 10.5c-.9 1.1-.4 2.1 2.3 5.2l3.5 3.8-3.5 3.8c-2.7 3.1-3.2 4.1-2.3 5.3 1.4 1.6 3.4.8 6.9-3l2.4-2.6 4 3.6c3.6 3.3 4.1 3.5 5.5 2 1.5-1.5 1.3-1.9-2.1-5.3L30 19.6l3.7-3.8c3.4-3.5 3.6-3.9 2.1-5.4-1.4-1.5-1.9-1.3-5.5 2l-4 3.6-2.4-2.6c-3.5-3.8-5.5-4.6-6.9-2.9z'
      fill='#6aa0eb'
    />
    <path
      d='M63.6 72.5c-.3.9.8 3.1 2.9 5.4l3.4 3.8-3.4 3.3c-3.9 3.8-4.2 4.8-1.9 5.7.9.3 2.8-.7 5-2.8l3.4-3.3 3.3 3.2c6.2 6 9.5 2.7 3.5-3.5L76.6 81l3.7-3.8c3.4-3.5 3.6-4 2.2-5.2-1.4-1.1-2.1-.8-5.3 2.3L73.4 78 70 74.5c-3.7-3.8-5.5-4.4-6.4-2z'
      fill='#9f9f9f'
    />
    <path
      d='M23.4 92.3c-.8 2.1 1.4 6.7 3.1 6.7 2.1 0 2.8-2.1 1.6-5.3-1.1-2.8-3.8-3.6-4.7-1.4z'
      fill='#224e90'
    />
  </svg>
);

export default Optimization;
