import React, { Component } from 'react';
import {
  commonReduxFormProps,
  wizardHeadline,
} from '../../../../../newDatapool/newDatapool.form';
import WizardPage from '../../../../../organisms/multi-page-wizard/WizardPage';
import MultiPageWizard from '../../../../../organisms/multi-page-wizard/MultiPageWizard.container';
import WizardStep from '../../../../../organisms/multi-page-wizard/WizardStep';
import { renderDefaultWizardSteps } from '../../../../../newDatapool/defaultWizard/DefaultNewDatapoolWizard';
import datapoolMsgs from 'common/dist/messages/datapools';

import {
  binaryClassificationAsyncBlurFields,
  binaryClassificationAsyncChangeFields,
  binaryClassificationValidate,
  fieldInputFormat,
  getBinaryClassificationMpwAsyncValidate,
  getBinaryClassificationSubmitSummary,
} from './binaryClassification.form';
import * as routes from '../../../../../index/routes';
import InputFormat from '../../../../../organisms/modelManagement/newDatapoolWizard/inputFormat/InputFormat';

import { inputFormats } from 'common/dist/constants/datapool';
import { BinaryClassificationSubmitSummary } from 'common/dist/types/requestBodies/datapools';
import { ParsedQs } from 'qs';
import { ModulesState } from '../../../../../../store/modules/state.types';

export type OwnProps = {
  /** Code of the habitat to add this datapool to */
  habitatCode: string;
  /** Name of the habitat to add this datapool to */
  habitatName?: string;
  /** List of available modules */
  modules?: ModulesState;
  /** Is the wizard currently being submitted? */
  isSubmitting?: boolean;
  /** Dispatches a redux action to add an datapool */
  addDatapool: (
    habitatCode: string,
    datapoolSummary: BinaryClassificationSubmitSummary
  ) => void;
  /** Uses react-routers history.push() to open another page */
  goToLink: (link: string) => void;
  /** Selector for the redux-form form values */
  formValueSelector: (fieldName: string) => any;
  /** Optional passed url query (already parsed) */
  query?: ParsedQs;
};

type ContainerProps = {
  // There is no container for this component (yet?)
};

export type Props = OwnProps & ContainerProps;

export default class BinaryClassificationNewDatapoolWizard extends Component<Props> {
  render() {
    const {
      habitatCode,
      habitatName,
      modules,
      isSubmitting,
      addDatapool,
      goToLink,
    } = this.props;

    const onSubmit = (values) => {
      const submitSummary = getBinaryClassificationSubmitSummary(
        values,
        habitatCode
      );
      addDatapool(habitatCode, submitSummary);
      goToLink(routes.app.prefix + routes.app.models);
    };

    // --- Steps
    const renderInputFormatStep = (stepNumber: number) => {
      return (
        <WizardStep
          stepNumber={stepNumber}
          title={datapoolMsgs.msgNewDatapoolStepInputFormatTitle}
          description={datapoolMsgs.msgNewDatapoolStepInputFormatDescription}
          fieldName={fieldInputFormat}
        >
          {/* @ts-ignore */}
          <InputFormat
            inputFormats={inputFormats}
            dropdownSelectPortal={document.querySelector('body')}
          />
        </WizardStep>
      );
    };

    return (
      // @ts-ignore
      <MultiPageWizard
        {...commonReduxFormProps}
        // @ts-ignore
        validate={binaryClassificationValidate}
        // @ts-ignore
        asyncValidate={getBinaryClassificationMpwAsyncValidate(habitatCode)}
        asyncBlurFields={binaryClassificationAsyncBlurFields}
        asyncChangeFields={binaryClassificationAsyncChangeFields}
        wizardHeadline={wizardHeadline(habitatName)}
        onCancel={() => {
          goToLink(routes.app.prefix + routes.app.models);
        }}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      >
        {/* @ts-ignore */}
        <WizardPage pageTitle={datapoolMsgs.msgNewDatapoolPageModule}>
          {renderDefaultWizardSteps(modules)}
          {renderInputFormatStep(4)}
        </WizardPage>
      </MultiPageWizard>
    );
  }
}
