import React, { Component } from 'react';
import Button from '../../../atoms/button/Button';
import TextInputLine from '../../../atoms/input-elements/text-input-line/TextInputLine';
import TextInputArea from '../../../atoms/input-elements/text-input-area/TextInputArea';
import htmlParser from 'react-markdown/plugins/html-parser';
import ReactMarkdown from 'react-markdown';

export type OwnProps = {
  path: string;
  isArrangeEditor: boolean;
};

type ContainerProps = {
  appIntro?: {
    title?: string;
    description?: string;
  };
  updateAppIntro: (path: string, title: string, description: string) => void;
};

type Props = OwnProps & ContainerProps;

type State = {
  isEditing: boolean;
  newTitle?: string;
  newDescription?: string;
};

const DEFAULT_TITLE = 'Welcome to your app';
const DEFAULT_DESCRIPTION =
  'Clicking next will guide you through the execution';

export default class IntroStep extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
    };
  }

  renderNotEditing() {
    const parseHtml = htmlParser();

    const { appIntro, isArrangeEditor } = this.props;
    const titleOrDefault = appIntro?.title || DEFAULT_TITLE;
    const descriptionOrDefault = appIntro?.description || DEFAULT_DESCRIPTION;
    return (
      <div className={'step-parent beginning-step-parent'}>
        <div className={'beginning-message-parent'}>
          <span className={'headline'}>{titleOrDefault}</span>
          <div className={'description-container markdown-con'}>
            <ReactMarkdown
              source={descriptionOrDefault}
              escapeHtml={false}
              astPlugins={[parseHtml]}
              className={'description app-intro-description'}
            />
          </div>
        </div>

        {isArrangeEditor && (
          <div className={'beginning-buttons-parent'}>
            <Button
              additionalClassNames={['bbp-button']}
              buttonColor={'secondary'}
              buttonLabelDefault={'Edit App Intro'}
              withLink={false}
              onClick={() =>
                this.setState({
                  isEditing: true,
                  newTitle: titleOrDefault,
                  newDescription: descriptionOrDefault,
                })
              }
            />
          </div>
        )}
      </div>
    );
  }

  renderEditing() {
    const { updateAppIntro, path } = this.props;
    const { newTitle, newDescription } = this.state;
    return (
      <div className={'step-parent beginning-step-parent'}>
        <div className={'beginning-message-parent'}>
          <TextInputLine
            touched
            hasLabel={false}
            placeholderDefault={'Title'}
            value={newTitle}
            onChange={(e) => this.setState({ newTitle: e.target.value })}
            onBlur={() => {}}
            onFocus={() => {}}
          />

          <TextInputArea
            touched
            hasLabel={false}
            disabled={false}
            placeholder={{
              id: 'no-id',
              defaultMessage: 'Description',
            }}
            value={newDescription}
            onChange={(e) => this.setState({ newDescription: e.target.value })}
            onBlur={() => {}}
            onFocus={() => {}}
            /** Amount of rows for the text area. Defaults to 5 */
            amountRows={10}
          />
        </div>

        <div className={'beginning-buttons-parent'}>
          <Button
            additionalClassNames={['bbp-button']}
            buttonColor={'primary'}
            buttonLabelDefault={'Submit'}
            withLink={false}
            onClick={() => {
              updateAppIntro(path, newTitle, newDescription);
              this.setState({ isEditing: false });
            }}
          />
          <Button
            additionalClassNames={['bbp-button']}
            buttonColor={'white'}
            buttonLabelDefault={'Cancel'}
            withLink={false}
            onClick={() => this.setState({ isEditing: false })}
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isEditing) {
      return this.renderEditing();
    } else {
      return this.renderNotEditing();
    }
  }
}
