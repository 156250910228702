import { connect, ConnectedProps } from 'react-redux';
import ModelSettings from './ModelSettings';
import { fetchArchetypeVersions } from '../../../../../../redux/modules/archetypeVersions.module';

export const mapDispatchToProps: {
  /** Fetch all archetype versions for the given archetype of this augur */
  fetchArchetypeVersions: (archetypeCode: string) => void;
} = {
  fetchArchetypeVersions,
};

const connector = connect(null, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(ModelSettings);
