import { ToBeRefined } from 'common/dist/types/todo_type';
import { MessageDescriptor } from 'react-intl';
import { validateDatapoolName } from 'common/dist/validation/datapool';
import { checkDatapoolNameExists } from '../../../redux/modules/datapool.checkNameExists.module';
import { validateDataSourceCode } from '../../organisms/modelManagement/newDatapoolWizard/dataSource/validate';
import { ModuleTypeType } from 'common/dist/constants/module_list';

// --- Field names
export const fieldDatapoolName = 'datapoolName';
export const fieldModuleType = 'moduleType';
export const fieldDataSourceCode = 'dataSourceCode';

// --- Type of the form values
export type DefaultFormValues = {
  [fieldDatapoolName]: string;
  [fieldModuleType]: ModuleTypeType;
  [fieldDataSourceCode]?: string;
};

// --- Sync validation
type ErrorType = {
  [fieldName: string]:
    | MessageDescriptor
    | { [subField: string]: MessageDescriptor };
};
export const defaultValidate = (values: DefaultFormValues) => {
  const errors: ErrorType = {};

  // --- Validate Module Type
  if (!values[fieldModuleType]) {
    errors[fieldModuleType] = {
      id: 'no-id',
      defaultMessage: 'Please select a Module',
    };
  }

  // --- Validate Datapool Name
  // @ts-ignore
  errors[fieldDatapoolName] = validateDatapoolName(values[fieldDatapoolName]);

  errors[fieldDataSourceCode] = validateDataSourceCode(
    values[fieldDataSourceCode]
  );

  return errors;
};

// --- Async validation
export function getDefaultMpwAsyncValidate(habitatCode: string) {
  return ({ datapoolName }: DefaultFormValues, dispatch: ToBeRefined) =>
    new Promise((resolve, reject) => {
      dispatch(
        // @ts-ignore
        checkDatapoolNameExists(habitatCode, datapoolName, { resolve, reject })
      );
    }).then(({ exists }) =>
      exists
        ? Promise.reject({
            [fieldDatapoolName]: {
              id: 'newDatapool.stepOne.error.this_name_already_exists',
            },
          })
        : null
    );
}

export const defaultAsyncBlurFields = [fieldDatapoolName];
export const defaultAsyncChangeFields = [];

// --- Step titles and messages
export const titleNameStep = {
  id: 'newDatapool.steps.name.title',
  defaultMessage: 'Datapool Name',
};

export const descriptionNameStep = {
  id: 'newDatapool.steps.name.description',
  defaultMessage: 'Give your Datapool a unique name',
};

export const titleModuleTypeStep = {
  id: 'no-id',
  defaultMessage: 'Module',
};

export const descriptionModuleTypeStep = {
  id: 'no-id',
  defaultMessage: 'Select the use case of your Datapool',
};
