import { AdminState } from '../../store/admin/state.types';

export const test: AdminState = {
  users: {
    data: [
      {
        id: '1',
        firstName: 'John',
        lastName: 'Smith',
        email: 'a@a.com',
        groups: [],
        realmRoles: [],
      },
      {
        id: '2',
        firstName: 'Jessica',
        lastName: 'Smith',
        email: 'b@b.com',
        groups: [],
        realmRoles: [],
      },
    ],
    loading: false,
    loaded: true,
    error: '',
  },
  groups: {
    data: [],
    loading: false,
    loaded: false,
    error: '',
  },
  userDetails: {
    data: {},
    loading: false,
    loaded: false,
    error: '',
  },
  roles: {
    data: [],
    loading: false,
    loaded: false,
    error: '',
  },
  permissions: {},
  policies: {},
  groupPermissions: {},
};

export const initial: AdminState = {
  users: {
    data: [],
    loading: false,
    loaded: false,
    error: '',
  },
  groups: {
    data: [],
    loading: false,
    loaded: false,
    error: '',
  },
  userDetails: {
    data: {},
    loading: false,
    loaded: false,
    error: '',
  },
  roles: {
    data: [],
    loading: false,
    loaded: false,
    error: '',
  },
  permissions: {},
  policies: {},
  groupPermissions: {},
};
