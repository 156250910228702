import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as Api from '../../core/api';
import { sendNotification } from './notifications.module';
import { error as errorType } from '../../core/notifications';
import notificationsMsgs from 'common/dist/messages/notifications';

export const addDatapool = createAction(
  'add datapool',
  (habitatCode, datapool) => ({ habitatCode, datapool })
);

export const addDatapoolSuccess = createAction(
  'add datapool - success',
  (data) => data
);

export const addDatapoolFail = createAction(
  'add datapool - fail',
  (error) => error
);

export const reducer = {
  [addDatapool]: (state) => ({
    ...state,
    newDatapool: {
      ...state.newDatapool,
      submitting: true,
    },
  }),
  [addDatapoolSuccess]: (state, data) => ({
    ...state,
    newDatapool: {
      ...state.newDatapool,
      submitting: false,
      createdDatapool: data,
    },
  }),
  [addDatapoolFail]: (state, error) => ({
    ...state,
    newDatapool: {
      ...state.newDatapool,
      submitting: false,
      error,
    },
  }),
};

export function* addDatapoolSaga({ payload }) {
  const { response, error } = yield call(
    Api.datapools.addDatapool,
    payload.habitatCode,
    payload.datapool
  );
  if (response) {
    yield put(addDatapoolSuccess(response));
  } else {
    yield put(
      sendNotification(
        notificationsMsgs.msgTitleNewDatapoolFailed.id,
        notificationsMsgs.msgDescriptionNewDatapoolFailed.id,
        errorType,
        { datapoolName: payload.datapool?.name }
      )
    );
    yield put(addDatapoolFail(error));
  }
}

export function* watchAddDatapool() {
  yield takeEvery(addDatapool.getType(), addDatapoolSaga);
}
