import { createAction } from 'redux-act';

export const selectBrowser = createAction(
  'select browser',
  (selected) => selected
);

export const reducer = {
  [selectBrowser]: (state, selected) => ({
    ...state,
    selectedBrowser: selected,
  }),
};
