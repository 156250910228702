export const initial = {
  pre: {
    error: '',
    loading: false,
    loaded: false,
    jobGroupCodes: [], // List of codes
    jobGroups: {}, // Mapping: Codes -> Values
    cancelJobGroupConfirm: {
      show: false,
      jobGroupCode: '',
    },
  },
  execution: {
    error: '',
    loading: false,
    loaded: false,
    executionTypes: [], // List of codes
    cancelJobGroupConfirm: {
      show: false,
      jobGroupCode: '',
    },
  },
};

export const test = {
  pre: {
    error: '',
    loading: false,
    loaded: false,
    jobGroupCodes: [], // List of codes
    jobGroups: {}, // Mapping: Codes -> Values
    cancelJobGroupConfirm: {
      show: false,
      jobGroupCode: '',
    },
  },
  execution: {
    error: '',
    loading: false,
    loaded: false,
    executionTypes: [], // List of codes
    cancelJobGroupConfirm: {
      show: false,
      jobGroupCode: '',
    },
  },
};
