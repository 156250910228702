import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  fieldNameDefaultValue,
  fieldNameLowerBound,
  fieldNameUpperBound,
  fieldNameStepSize,
} from '../_interface/input-element.form';
import TextInputCol from '../../../settings-dialog/dialog-settings-cols/TextInputCol';
import { variableShape } from '../_interface/P3InputElementShape';
import { VARIABLE_TYPES } from '../../variables/variableTypes';
import { inputModalForm } from '../../../settings-dialog/inpmodalDialog.form';

class NumericSliderSettings extends Component {
  render() {
    const { path, cellId, showSelectVariable } = this.props;
    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldNameDefaultValue}
            label={'Default Value (optional)'}
            component={TextInputCol}
            placeholder={'Enter Default Value'}
            showSelectVariable={showSelectVariable}
          />
        </div>

        <div className={'input-row'}>
          <Field
            name={fieldNameLowerBound}
            label={'Lower Bound'}
            component={TextInputCol}
            placeholder={'Enter Lower Bound'}
            allowVariable
            path={path}
            cellId={cellId}
            showSelectVariable={showSelectVariable}
            validVariableTypes={[VARIABLE_TYPES.NUMBER]}
          />
          <Field
            name={fieldNameUpperBound}
            label={'Upper Bound'}
            component={TextInputCol}
            placeholder={'Enter Upper Bound'}
            allowVariable
            path={path}
            cellId={cellId}
            showSelectVariable={showSelectVariable}
            validVariableTypes={[VARIABLE_TYPES.NUMBER]}
          />
        </div>
        <div className={'input-row'}>
          <Field
            name={fieldNameStepSize}
            label={'StepSize'}
            component={TextInputCol}
            placeholder={'Enter Step Size'}
            showSelectVariable={showSelectVariable}
          />
        </div>
      </Fragment>
    );
  }
}

NumericSliderSettings.propTypes = {
  path: PropTypes.string,
  cellId: PropTypes.string,
  variables: PropTypes.arrayOf(variableShape),
  showSelectVariable: PropTypes.func.isRequired,
};

export default reduxForm(inputModalForm)(NumericSliderSettings);
