import React, { Children, Component } from 'react';
import styles from './styles.module.scss';
import { MessageDescriptor } from 'react-intl';
import { InjectedFormProps } from 'redux-form';
import { WizardStepProps } from './WizardStep';

export type WizardPageProps = {
  // --- Child components
  children?:
    | React.ReactElement<WizardStepProps<unknown, unknown>>
    | React.ReactElement<WizardStepProps<unknown, unknown>>[];

  pageTitle: MessageDescriptor;
};

class WizardPage<FormValues, ErrorType> extends Component<
  WizardPageProps & InjectedFormProps<FormValues, WizardPageProps>
> {
  renderWizardSteps() {
    const { children } = this.props;

    if (React.isValidElement(children)) {
      return children;
    } else if (Array.isArray(children)) {
      return Children.map(children, (child) =>
        React.isValidElement(child) ? child : null
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <form
        className={styles.wizardPage}
        onKeyPress={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      >
        {this.renderWizardSteps()}
      </form>
    );
  }
}

export default WizardPage;
