import { JupyterNotebookFormat } from 'common/dist/utils/workbench/jupyterNotebookFormat';
import { formValueSelector } from 'redux-form';

export const formName = 'notebookWizard';

export const fieldName = 'notebookName';
export const fieldKernel = 'kernel';

export type FormData = Partial<{
  [fieldName]: string;
  [fieldKernel]: JupyterNotebookFormat['metadata']['kernelspec'];
}>;

export const form = {
  form: 'notebookWizard',
  touchOnChange: true,
};
export const getNotebookName = (state) =>
  formValueSelector(formName)(state, fieldName);
export const getSelectedKernel = (state) =>
  formValueSelector(formName)(state, fieldKernel);
