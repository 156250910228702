import React, { FC } from 'react';
import { WrappedFieldProps } from 'redux-form';
import styles from './styles.module.scss';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { InputFormatIdType, InputFormatType } from 'common/dist/types/datapool';

export type Props = {
  inputFormats: InputFormatType[];
  dropdownSelectPortal?: HTMLElement;
};

type OptionType = { value: string; label: string };

export type InputFormatValueType = InputFormatIdType;

const InputFormat: FC<Props & WrappedFieldProps> = (props) => {
  const {
    dropdownSelectPortal,
    inputFormats,
    input: { value, onChange, onBlur, onFocus },
    meta: { touched, error },
  } = props;
  const mappedInputFormats = (inputFormats || []).map((inputFormat) => ({
    label: inputFormat.name,
    value: inputFormat.id,
  }));

  // TODO Custom Option component that also shows the description of the input format
  return (
    <div className={styles.inputFormat}>
      <DropdownSelectInput
        id={'inputFormat'}
        name={'inputFormat'}
        touched={touched}
        error={error}
        valid={true}
        disabled={false}
        label={{
          id: 'newDatapool.form.js.step.input_format.select',
          defaultMessage: 'Select Input Format',
        }}
        placeholder={{
          id: 'newDatapool.form.js.step.input_format.no_selected',
          defaultMessage: 'No Input Format selected',
        }}
        value={mappedInputFormats.find((o) => o.value === value)}
        onChange={(option: OptionType) => onChange(option.value)}
        onFocus={onFocus}
        onBlur={() => onBlur(value)}
        options={mappedInputFormats}
        menuPortalTarget={dropdownSelectPortal}
      />
    </div>
  );
};

export default InputFormat;
