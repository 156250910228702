import React, { Component, Fragment } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { maintenanceRoutes } from './routes';
import BackupsPage from './backups-page/BackupsPage.container';
import WithSidemenu from '../../molecules/with-sidemenu/WithSidemenu';
import { MenuEntryType } from '../../molecules/with-sidemenu/SideMenu';
import { FiHardDrive, FiTool } from 'react-icons/fi';
import TemplatesPage from './templates-page/TemplatesPage.container';
import EnableMaintenance from './enable-maintenance/EnableMaintenance.container';
import './styles.scss';
import SubHeader from '../../organisms/sub-header/SubHeader.container';
import OverlayMaintenance from '../../organisms/sub-header/overlay-maintenance/OverlayMaintenance';

type Props = {
  history: {
    // Injected by react-router
    location: {
      pathname: string;
    };
  };
};

const menuEntries = [
  {
    type: 'headline',
    headline: {
      id: 'dashboard.category.backups.todo',
      defaultMessage: 'Backup',
    },
  },
  {
    type: 'link',
    id: 'backups',
    to: `${maintenanceRoutes.basePath}/${maintenanceRoutes.backups.path}`,
    nameIntlId: 'dashboard.tab.backups.todo',
    nameDefault: 'Backups',
    icon: FiHardDrive,
  },
  {
    type: 'link',
    id: 'templates',
    to: `${maintenanceRoutes.basePath}/${maintenanceRoutes.templates.path}`,
    nameIntlId: 'dashboard.tab.backups.todo',
    nameDefault: 'Templates',
    icon: FiHardDrive,
  },
  { type: 'hr' },
  {
    type: 'headline',
    headline: {
      id: 'dashboard.category.maintenance.todo',
      defaultMessage: 'Maintenance',
    },
  },
  {
    type: 'link',
    id: 'maintenance',
    to: `${maintenanceRoutes.basePath}/${maintenanceRoutes.maintenance.path}`,
    nameIntlId: 'dashboard.tab.maintenance.todo',
    nameDefault: 'Maintenance Mode',
    icon: FiTool,
  },
] as MenuEntryType[];

class Maintenance extends Component<Props, {}> {
  getActiveCategory() {
    const path = this.props.history.location.pathname;
    if (!path) return null;
    const rep = path.replace(maintenanceRoutes.basePath + '/', '');
    const parts = rep.split('/');
    return parts[0];
  }

  renderInnerComponent() {
    const selectedCategory = this.getActiveCategory();

    return (
      <div className={'Maintenance'}>
        <WithSidemenu
          menuEntries={menuEntries}
          selectedTab={selectedCategory}
          handleSelect={() => {}}
        >
          <Switch>
            <Route
              path={`${maintenanceRoutes.basePath}/${maintenanceRoutes.templates.path}`}
              component={TemplatesPage}
            />
            <Route
              path={`${maintenanceRoutes.basePath}/${maintenanceRoutes.backups.path}`}
              component={BackupsPage}
            />
            <Route
              path={`${maintenanceRoutes.basePath}/${maintenanceRoutes.maintenance.path}`}
              component={EnableMaintenance}
            />
            <Redirect
              exact
              path={maintenanceRoutes.basePath}
              to={`${maintenanceRoutes.basePath}/${maintenanceRoutes.backups.path}`}
            />
          </Switch>
        </WithSidemenu>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <SubHeader hasOverlay Overlay={OverlayMaintenance} />
        {this.renderInnerComponent()}
      </Fragment>
    );
  }
}

// @ts-ignore
export default withRouter(Maintenance);
