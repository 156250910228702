import React, { Component } from 'react';
import classNames from 'classnames';
import { injectIntl, IntlShape, MessageDescriptor } from 'react-intl';
import { ToBeRefined } from 'common/dist/types/todo_type';
import styles from './styles.module.scss';
import RevealPasswordButton from './RevealPasswordButton';
import PasswordInput from './PasswordInput';
import { ErrorType } from '../../../admin/users/add-user/addUser.form';

export type Props = {
  /** Has the field been touched? */
  touched: boolean;
  /** Has the field an error? */
  error?: ErrorType;
  /** ID of the input element */
  id?: string;
  /** Is the text input line disabled? */
  disabled?: boolean;
  /** Intl message for the label */
  label?: MessageDescriptor;
  /** Intl message for the placeholder */
  placeholder?: MessageDescriptor;
  /** Value of the input field */
  value: string;
  /** onChange callback */
  onChange: (e: ToBeRefined) => void;
  intl: IntlShape;
  /**Disable the autocomplete **/
  autoComplete?: string;
  testingIdentifier?: string;
  /** onBlur callback */
  onBlur?: (e: ToBeRefined) => void;
  /** onFocus callback */
  onFocus?: (e: ToBeRefined) => void;
};

type State = {
  /** Is the password shown in plain text? */
  isPasswordRevealed: boolean;
};

class TextInputLinePassword extends Component<Props, State> {
  static defaultProps = {
    id: 'TextInputLinePassword',
  };

  constructor(props: Props) {
    super(props);
    this.state = { isPasswordRevealed: false };
  }

  render() {
    const {
      id,
      touched,
      error,
      intl,
      placeholder,
      label,
      value,
      onChange,
      disabled,
      autoComplete,
      onFocus,
      onBlur,
    } = this.props;
    const { isPasswordRevealed } = this.state;
    const placeholderStr = placeholder
      ? intl.formatMessage({
          id: placeholder.id || 'no-id',
          defaultMessage: placeholder.defaultMessage,
        })
      : undefined;

    const labelStr = label
      ? intl.formatMessage({
          id: label.id || 'no-id',
          defaultMessage: label.defaultMessage,
        })
      : undefined;

    const revealPassword = () => this.setState({ isPasswordRevealed: true });
    const hidePassword = () => this.setState({ isPasswordRevealed: false });

    return (
      <div className={styles.TextInputLinePassword}>
        <div className={styles.TextInputLinePasswordHeader}>
          {label && (
            <p className={styles.TextInputLinePasswordLabel}>{labelStr}</p>
          )}
        </div>
        <div
          className={classNames(
            styles.TextInputLinePasswordInputGroup,
            disabled ? styles.TextInputLinePasswordDisabled : '',
            touched && error && error?.password
              ? styles.inputContainerError
              : ''
          )}
        >
          <PasswordInput
            id={id as string}
            testingIdentifier={this.props.testingIdentifier}
            isPasswordRevealed={isPasswordRevealed}
            value={value}
            disabled={disabled}
            onChange={onChange}
            autoComplete={autoComplete}
            onFocus={onFocus}
            onBlur={onBlur}
          />

          <RevealPasswordButton
            isPasswordRevealed={isPasswordRevealed}
            revealPassword={revealPassword}
            hidePassword={hidePassword}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(TextInputLinePassword);
