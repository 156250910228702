import { cleanErrorObject } from 'common/dist/validation/helpers';

/**
 * Validation for the "sub form": add channel
 * @param value
 */
import { ConstraintsErrorType } from './OptConstraints';
import {
  validateDescription,
  validateName,
} from '../../newDatapoolWizard/optChannels/validate';
import { Constraint } from 'common/dist/types/module.optimization';
import { MessageDescriptor } from 'react-intl';
import { numberRegex } from '../../newDatapoolWizard/optCommunications/validate';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import _ from 'lodash';
import { ConstraintsValueType } from 'common/dist/types/augurSettings.opt';

const validateConstraintLevel = (
  constraintLevel: Constraint['constraintLevel']
): MessageDescriptor | null => {
  // The validateConstraintLevel field is required
  if (!constraintLevel) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoLevel;
  }
};

const validateChannelId = (
  channelId: string,
  constraintLevel: Constraint['constraintLevel']
): MessageDescriptor | null => {
  // The validateConstraintLevel field is required
  if (constraintLevel === 'channel' && !channelId) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoChannel;
  }
};

const validateCommunicationId = (
  communicationId: string,
  constraintLevel: Constraint['constraintLevel']
): MessageDescriptor | null => {
  // The validateConstraintLevel field is required
  if (constraintLevel === 'communication' && !communicationId) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoCommunication;
  }
};

const validateCommunicationGroupId = (
  communicationGroupId: string,
  constraintLevel: Constraint['constraintLevel']
): MessageDescriptor | null => {
  // The validateConstraintLevel field is required
  if (constraintLevel === 'communicationGroup' && !communicationGroupId) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoCommunicationGroup;
  }
};

const validateConstraintType = (
  constraintType: Constraint['constraintType']
): MessageDescriptor | null => {
  // The validateConstraintLevel field is required
  if (!constraintType) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoConstraintType;
  }
};

const validateOperator = (
  operator: Constraint['operator']
): MessageDescriptor | null => {
  // The validateConstraintLevel field is required
  if (!operator) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoOperator;
  }
};

export const validateValue = (value?: string): MessageDescriptor | null => {
  // The name field is required
  if (!value) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrNoValue;
  }

  // Valid characters
  if (!numberRegex.test(value)) {
    return moduleOptimizationMessages.msgNewAugurStepConstraintsErrValueInvalid;
  }

  return null;
};

// ---
export const validateConstraints = (
  value: ConstraintsValueType
): ConstraintsErrorType => {
  const error: ConstraintsErrorType = {};

  value?.forEach((constraint) => {
    const constraintError = validateSingleConstraint(constraint, value);
    if (!_.isEmpty(constraintError)) {
      error[constraint.id] = constraintError;
    }
  });
  return cleanErrorObject(error);
};

export const validateSingleConstraint = (
  constraint: Partial<Constraint>,
  allConstraints?: Partial<Constraint>[]
) => {
  const error = {};
  // Validate name
  error['name'] = validateName(
    constraint.name,
    (allConstraints || [])
      .filter((x) => x.id !== constraint.id)
      .map((ch) => ch.name)
  );
  // Validate description
  error['description'] = validateDescription(constraint.description);

  // Validate constraintLevel
  error['constraintLevel'] = validateConstraintLevel(
    constraint.constraintLevel
  );

  // Validate channelId
  error['channelId'] = validateChannelId(
    // @ts-ignore
    constraint.channelId,
    constraint.constraintLevel
  );

  // Validate communicationId
  error['communicationId'] = validateCommunicationId(
    // @ts-ignore
    constraint.communicationId,
    constraint.constraintLevel
  );

  // Validate communicationGroupId
  error['communicationGroupId'] = validateCommunicationGroupId(
    // @ts-ignore
    constraint.communicationGroupId,
    constraint.constraintLevel
  );

  // Validate constraintType
  error['constraintType'] = validateConstraintType(
    // @ts-ignore
    constraint.constraintType
  );

  // Validate operator
  error['operator'] = validateOperator(
    // @ts-ignore
    constraint.operator
  );

  // Validate value
  error['value'] = validateValue(
    // @ts-ignore
    constraint.value
  );
  return cleanErrorObject(error);
};
