import { createSlice } from '@reduxjs/toolkit';

export type RunPredictionModalState = {
  showModal: boolean;
};

export const initial: RunPredictionModalState = {
  showModal: false,
};

export const test: RunPredictionModalState = {
  showModal: false,
};

const slice = createSlice({
  name: 'runPredictionModal',
  initialState: initial as RunPredictionModalState,

  reducers: {
    showRunPredictionModal: (state) => ({
      showModal: true,
    }),
    hideRunPredictionModal: (state) => ({
      showModal: false,
    }),
  },
});

const reducer = slice.reducer;
export { reducer as runPredictionModalReducer };

const { showRunPredictionModal, hideRunPredictionModal } = slice.actions;
export { showRunPredictionModal, hideRunPredictionModal };
