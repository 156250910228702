import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const fetchArchetypes = createAction(
  'fetch archetypes',
  (habitatCode, datapoolCode) => ({ habitatCode, datapoolCode })
);

export const fetchArchetypesSuccess = createAction(
  'fetch archetypes - success',
  (list) => list
);

export const fetchArchetypesFail = createAction(
  'fetch archetypes - fail',
  (error) => error
);

export const expandArchetype = createAction(
  'expand archetype',
  (archetypeCode) => archetypeCode
);

export const collapseArchetype = createAction(
  'collapse archetype',
  (archetypeCode) => archetypeCode
);

export const reducer = {
  [fetchArchetypes]: (state) => ({
    ...state,
    archetypes: {
      ...state.archetypes,
      loading: true,
    },
  }),
  [fetchArchetypesSuccess]: (state, list) => ({
    ...state,
    archetypes: {
      ...state.archetypes,
      loading: false,
      loaded: true,
      data: list,
    },
  }),
  [fetchArchetypesFail]: (state, error) => ({
    ...state,
    archetypes: {
      ...state.archetypes,
      loading: false,
      loaded: false,
      error,
    },
  }),
  [expandArchetype]: (state, archetypeCode) => ({
    ...state,
    archetypes: {
      ...state.archetypes,
      expanded: {
        ...state.archetypes.expanded,
        [archetypeCode]: true,
      },
    },
  }),
  [collapseArchetype]: (state, archetypeCode) => ({
    ...state,
    archetypes: {
      ...state.archetypes,
      expanded: {
        ...state.archetypes.expanded,
        [archetypeCode]: false,
      },
    },
  }),
};

export function* fetchArchetypesSaga({
  payload: { habitatCode, datapoolCode },
}) {
  const { response, error } = yield call(
    Api.datapools.fetchArchetypes,
    habitatCode,
    datapoolCode
  );
  if (response) {
    yield put(fetchArchetypesSuccess(response));
  } else {
    yield put(fetchArchetypesFail(error));
  }
}

export function* watchFetchArchetypes() {
  yield takeEvery(fetchArchetypes.getType(), fetchArchetypesSaga);
}
