import {
  apiRequest,
  CompletedApiRequest,
  postApiRequest,
  putApiRequest,
} from './_tools';
import {
  GetDatapoolDetailsResponseBody,
  PostAddDatapoolResponseBody,
} from 'common/dist/types/responseBodies/datapools';
import { PostAddDatapoolBody } from 'common/dist/types/requestBodies/datapools';
import {
  ExistsResponseBody,
  GetNameResponseBody,
} from 'common/dist/types/responseBodies/base';

export function checkDatapoolNameExists(
  habitatCode: string,
  datapoolName: string
): CompletedApiRequest<ExistsResponseBody> {
  return postApiRequest(`/api/habitats/${habitatCode}/datapools/exists`, {
    name: datapoolName,
  });
}

export function addDatapool(
  habitatCode: string,
  datapool: PostAddDatapoolBody
): CompletedApiRequest<PostAddDatapoolResponseBody> {
  return postApiRequest(`/api/habitats/${habitatCode}/datapools`, datapool);
}

// Mainly used to add the Datapool name to the Jobs
export function getDatapoolName(
  datapoolCode: string
): CompletedApiRequest<GetNameResponseBody> {
  return apiRequest(`/api/datapools/${datapoolCode}/name`);
}

export function fetchDatapool(
  habitatCode: string,
  datapoolCode: string
): CompletedApiRequest<GetDatapoolDetailsResponseBody> {
  return apiRequest(`/api/habitats/${habitatCode}/datapools/${datapoolCode}`);
}

export function saveTaxonomy(habitatCode, datapoolCode, taxonomy) {
  return postApiRequest(
    `/api/habitats/${habitatCode}/datapools/${datapoolCode}/targets`,
    { targets: taxonomy }
  );
}

export function updateDatapool(
  habitatCode: string,
  datapoolCode: string,
  data: Record<string, unknown>,
  mergeMeta: boolean
): CompletedApiRequest<Record<string, never>> {
  const body = {
    data: data,
    mergeMeta,
  };
  return putApiRequest(
    `/api/habitats/${habitatCode}/datapools/${datapoolCode}`,
    body
  );
}

export function triggerStatisticsRun(habitatCode, datapoolCode) {
  return postApiRequest(
    `/api/habitats/${habitatCode}/datapools/${datapoolCode}/triggerstatisticsrun`
  );
}

export function fetchPredictionTargets(habitatCode, datapoolCode) {
  return apiRequest(
    `/api/habitats/${habitatCode}/datapools/${datapoolCode}/targets`
  );
}

export function fetchArchetypes(habitatCode, datapoolCode) {
  return apiRequest(
    `/api/habitats/${habitatCode}/datapools/${datapoolCode}/archetypes`
  );
}
