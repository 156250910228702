import React from 'react';

import P3InputElementParent from './P3InputElementParent.container';
import P3InputElementParentApp from './P3InputElementParentApp.container';
import { ToBeRefined } from 'common/dist/types/todo_type';

export type BaseInputElement = {
  id: string;
  type: string;
};

export type SettingsType<InputValue> = {
  inputValue: InputValue;
  origin: 'input' | 'variable';
  variableName: string;
};

export type AsInputElement = BaseInputElement & {
  data: {
    // The user input (the values filled into the input fields)
    error: string;

    // + additional fields specific to the type
    number: number;
    checked: boolean;
    text: string;
    selectedValue: string;
    selectedValues: string[];
    datetime: string;
    files: string[];
    selection: { id: string; category: string }[];
    tableData: ToBeRefined;
  };
  settings: {
    // The settings defined when creating the notebook (in the input element settings modal)
    // --- Generic settings
    description: SettingsType<string>;
    label: SettingsType<string>;
    outputVariable: SettingsType<string>;
    defaultValue: SettingsType<any>;

    // --- Settings specific to the element type
    lowerBound: SettingsType<number>;
    upperBound: SettingsType<number>;
    targetPath: SettingsType<string>;
  };
};

export class AsVariable {
  id: string;
  name: string;
  type: string;
  rawValue: string[];
  parsedValue: any;
  error: string;
}

export default abstract class P3InputElement {
  // --- Elements

  // React component of the input element (for example the text field itself)
  abstract ChildComponent: React.ComponentType;
  // React component of the additional settings in the settings modal.
  //  Is allowed to be 'undefined' if there are no additional settings for this component
  abstract AdditionalSettings: React.ComponentType;
  // Type of the input element - this is how the element is looked up for rendering
  abstract type: string;
  // Name of the input type - displayed
  abstract name: string;
  // CSS-class that is passed to the parent container for styling-issues.
  abstract parentContainerClass: string;
  // Icon component
  abstract icon: any;
  // Color for the icon (used as the fill for the circle if the default icon is used)
  abstract iconColor: string;

  // --- Functions

  // Function that returns the python code for execution (supposed to fill the code template with the user input)
  abstract getSource: (
    asElement: AsInputElement,
    variables: AsVariable[]
  ) => string;
  // Function to validate the settings in the element modal (label, description, outputVariable, ...)
  abstract validateSettings: (values: object, variables: object[]) => object;
  // Function to validate the user input (called before execution) for example of the text field itself
  // -> null means cell is valid, a string !== '' means the cell is not valid + the string contains the error to display
  abstract validate: (asElement: AsInputElement, variables: object[]) => string;

  /**
   * Renders the input element for the notebook view
   * @param props
   */
  renderWrappedComponent(props: any) {
    return (
      <P3InputElementParent
        parentContainerClass={this.parentContainerClass}
        {...props}
      >
        <this.ChildComponent
          // @ts-ignore
          path={props.path}
          element={props.element}
          parentType={'notebook'}
          cell={props.cell}
        />
      </P3InputElementParent>
    );
  }

  /**
   * Renders the input element for the app view
   * @param props
   */
  renderWrappedComponentForApp(props: any) {
    return (
      <P3InputElementParentApp
        parentContainerClass={this.parentContainerClass}
        {...props}
      >
        <this.ChildComponent
          // @ts-ignore
          path={props.path}
          element={props.element}
          cell={props.cell}
          parentType={'app'}
          appVersionCode={props.appVersionCode}
        />
      </P3InputElementParentApp>
    );
  }
}
