import React, { FC, useEffect, useState } from 'react';
import { SPEAKING_CONSTRAINT_TYPE, SPEAKING_OPERATOR } from './common';
import Table, { RenderColumn } from '../../../../molecules/table/Table';
import styles from './styles.module.scss';
import commonStyles from '../../newDatapoolWizard/optChannels/optCommonStyles.module.scss';
import {
  FiLayers,
  FiMaximize,
  FiMessageSquare,
  FiPhoneForwarded,
} from 'react-icons/all';
import { WrappedFieldProps } from 'redux-form';
import { CampaignOptimizationDatapoolType } from 'common/dist/types/datapool';
import {
  Constraint,
  ConstraintDetails,
  EnrichedConstraint,
} from 'common/dist/types/module.optimization';
import { getConstraintDetails } from './helpers';
import AddConstraint from './AddConstraint';
import classNames from 'classnames';

type Props = {
  /** Are the datapool details currently loading? */
  datapoolDetailsLoading?: boolean;
  /** Datapool details */
  datapoolDetails?: CampaignOptimizationDatapoolType;
  editMode?: boolean;
  addedNewRow?: boolean;
  rowIndex: number;
};

const ConstraintTable: FC<Props & WrappedFieldProps> = (props) => {
  const {
    input,
    datapoolDetails,
    meta,
    datapoolDetailsLoading,
    editMode,
    addedNewRow,
    rowIndex,
  } = props;

  const constraints: Constraint[] = input.value || [];

  const enrichedConstraints: EnrichedConstraint[] = (constraints || []).map(
    (con) => ({
      ...con,
      icon: con.constraintLevel,
      constraintDetails: getConstraintDetails(con, datapoolDetails),
    })
  );
  const initialVisible = {
    index: addedNewRow ? constraints?.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  };
  const [visible, setShow] = useState(initialVisible);

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : constraints?.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex]);

  const renderColumns: RenderColumn<
    EnrichedConstraint,
    keyof EnrichedConstraint
  >[] = [
    {
      key: 'icon',
      renderHeader: () => null,
      renderCell: (constraintLevel) => {
        switch (constraintLevel) {
          case 'channel':
            return (
              <div className={styles.columnIcon}>
                <FiPhoneForwarded size={16} />
              </div>
            );
          case 'communication':
            return (
              <div className={styles.columnIcon}>
                <FiMessageSquare size={16} />
              </div>
            );
          case 'communicationGroup':
            return (
              <div className={styles.columnIcon}>
                <FiLayers size={16} />
              </div>
            );
          case 'allCommunications':
            return (
              <div className={styles.columnIcon}>
                <FiMaximize size={16} />
              </div>
            );
          default:
            return null;
        }
      },
      width: '20px',
    },
    {
      key: 'name',
      renderHeader: () => <span>Name</span>,
      renderCell: (name: string, constraint) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[constraint.id]?.name,
          })}
        >
          {name}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,
      renderCell: (description?: string, constraint?) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[constraint?.id]?.description,
          })}
        >
          {description}
        </span>
      ),
    },
    {
      key: 'constraintDetails',
      renderHeader: () => <span>Constraint Level</span>,
      renderCell: (constraintDetails: ConstraintDetails, constraint) => (
        <div className={styles.columnConstraintLevel}>
          <span
            className={classNames(styles.constraintReferenceName, {
              [styles.erroneous]:
                !!meta.error?.[constraint?.id]?.channelId ||
                !!meta.error?.[constraint?.id]?.communicationGroupId ||
                !!meta.error?.[constraint?.id]?.communicationId,
            })}
          >
            {constraintDetails?.name}
          </span>
          <span
            className={classNames(styles.speakingLevel, {
              [styles.erroneous]:
                !!meta.error?.[constraint?.id]?.constraintLevel ||
                !!meta.error?.[constraint?.id]?.channelId ||
                !!meta.error?.[constraint?.id]?.communicationGroupId ||
                !!meta.error?.[constraint?.id]?.communicationId,
            })}
          >
            {constraintDetails?.speakingLevel}
          </span>
        </div>
      ),
    },
    {
      key: 'constraintType',
      renderHeader: () => <span>Constraint Type</span>,
      renderCell: (
        constraintType: Constraint['constraintType'],
        constraint
      ) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[constraint.id]?.constraintType,
          })}
        >
          {SPEAKING_CONSTRAINT_TYPE[constraintType]}
        </span>
      ),
    },
    {
      key: 'operator',
      renderHeader: () => <span>Operator</span>,
      renderCell: (operator: 'leq' | 'geq' | 'eq', constraint) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[constraint.id]?.operator,
          })}
        >
          {SPEAKING_OPERATOR[operator]}
        </span>
      ),
    },
    {
      key: 'value',
      renderHeader: () => <span>Value</span>,
      renderCell: (value: string, constraint) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[constraint.id]?.value,
          })}
        >
          {value}
        </span>
      ),
    },
  ];

  const renderClickedRow = (
    element: EnrichedConstraint,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (editMode && visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddConstraint
                rowIndex={rowIndex}
                constraintId={element.id}
                input={input}
                meta={meta}
                datapoolDetailsLoading={datapoolDetailsLoading}
                datapoolDetails={
                  datapoolDetails as CampaignOptimizationDatapoolType
                }
                removableRows={visible.shown}
                onRemoveRow={(removedRow) => {
                  const updatedConstraints = constraints.filter(
                    (con) => con.id !== removedRow.id
                  );
                  input.onChange(updatedConstraints);
                }}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };

  return (
    <Table
      data={enrichedConstraints}
      renderColumns={renderColumns}
      showHeader
      removableRows={editMode}
      verticalAlignMiddle
      onRemoveRow={(removedRow) => {
        const updatedConstraints = constraints.filter(
          (con) => con.id !== removedRow.id
        );
        input.onChange(updatedConstraints);
      }}
      addlRowClassname={(rowIndex: number, constraint: EnrichedConstraint) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!meta.error?.[constraint.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
    />
  );
};

export default ConstraintTable;
