import React, { Component, ComponentType } from 'react';
import { SettingsBlock } from '../../../../../molecules/settings-block/SettingsBlock';
import './styles.scss';
import { RouteComponentProps } from 'react-router';
import { MessageDescriptor } from 'react-intl';

export interface Props {
  settingsCategories?: {
    title: string;
    children: () => {
      title: MessageDescriptor;
      description: MessageDescriptor;
      component: ComponentType<{ habitatCode: string; datapoolCode: string }>;
    }[];
    backgroundColor: 'white' | 'grey';
  }[];
}

export default class Settings extends Component<
  Props & RouteComponentProps<{ datapoolCode: string; habitatCode: string }>
> {
  render() {
    const { settingsCategories, match } = this.props;
    const { datapoolCode, habitatCode } = match.params;

    return (
      <div className='DatapoolDetails--Settings'>
        {settingsCategories &&
          settingsCategories.length > 0 &&
          settingsCategories.map((category) => {
            const settingsElements = (category.children() || []).map(
              (element, i) => ({
                title: element.title,
                description: element.description,
                Component: (
                  <element.component
                    key={i}
                    habitatCode={habitatCode}
                    datapoolCode={datapoolCode}
                  />
                ),
              })
            );

            return (
              <SettingsBlock
                title={{
                  id: category.title,
                  defaultMessage: 'Settings Block', // TODO pass default messages with the category.title
                }}
                color={category.backgroundColor}
                settingsElements={settingsElements}
              />
            );
          })}
      </div>
    );
  }
}
