import {
  apiRequest,
  postApiRequest,
  putApiRequest,
  patchApiRequest,
} from './_apiRequests';

export function fetchNotebook(path, jupyterUser) {
  let url = `/jupyter/user/${jupyterUser}/api/contents/${path}`;
  if (path.endsWith('.asapp')) url += '?type=notebook';
  return apiRequest(url);
}

/**
 * Saves a notebook to Jupyter
 *
 * @param path
 * @param content
 * @param type
 * @param format
 * @param jupyterUser
 * @returns {*}
 */
export function saveNotebook(path, content, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${path}`;
  const body = {
    type: 'notebook',
    format: 'json',
    path,
    content,
  };
  return putApiRequest(url, body);
}

/**
 * Saves a file to Jupyter
 *
 * @param path
 * @param content
 * @param jupyterUser
 * @returns {*}
 */
export function saveFile(path, content, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${path}`;
  const body = {
    type: 'file',
    format: 'text',
    path,
    content,
  };
  return putApiRequest(url, body);
}

export function initialSaveFile(path, initialNotebook, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${path}`;
  const body = initialNotebook;
  return putApiRequest(url, body);
}

export function createFile(jupyterUser, fileType = 'notebook') {
  const url = `/jupyter/user/${jupyterUser}/api/contents/`;
  const body = {
    type: fileType,
  };
  return postApiRequest(url, body);
}

export function copyNotebook(oldPath, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/`;
  const body = {
    copy_from: oldPath,
    type: 'notebook',
  };
  return postApiRequest(url, body);
}

/**
 * Fetches a notebook for the app execution. The single difference to 'fetchNotebook' is the URL that is used.
 * The two reasons for this are: 1. It's a second JupyterHub and 2. This JupyterHub allows for named servers
 * @param path
 * @param serverName
 * @param jupyterUser
 * @returns {*}
 */
export function fetchNotebookForApp(path, serverName, jupyterUser) {
  let url = `/jupyterapp/user/${jupyterUser}/${serverName}/api/contents/${path}`;
  if (path.endsWith('.asapp')) url += '?type=notebook';
  return apiRequest(url);
}
