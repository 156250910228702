import React, { FC } from 'react';
import '../styles_common.scss';
import { SpecificExecuteResult } from '../../../../../store/workbench/state.types';

export type Props = {
  data: SpecificExecuteResult['data'];
};

const ExecuteResultOutput: FC<Props> = (props: Props) => {
  const { data } = props;

  if (!data) {
    return <div />;
  }

  const htmlOutput = data['text/html'];
  const plainOutput = data['text/plain'];
  const pngOutput = data['image/png']; // TODO image/png always comes base64-decoded - image/svg+xml comes as a unencoded plain svg string. Can this somehow be determined from the response?

  const svgOutput = data['image/svg+xml'];
  let svgOutputBase64 = '';
  if (svgOutput) {
    const buff = new Buffer(svgOutput);
    svgOutputBase64 = buff.toString('base64');
  }

  /* If there's html output, show it. If no html output -> show plain output */
  return (
    <div className={'CodeCellOutputs'}>
      <div className={'output-container-buttons'}>{}</div>
      <div
        className={'CodeCellOutputs--container execute-result-output-container'}
      >
        {htmlOutput && (
          <div
            dangerouslySetInnerHTML={{
              __html: Array.isArray(htmlOutput)
                ? htmlOutput.join('')
                : htmlOutput,
            }}
          />
        )}
        {plainOutput && !htmlOutput && !pngOutput && !svgOutput && (
          <p>
            {Array.isArray(plainOutput) ? plainOutput.join('') : plainOutput}
          </p>
        )}
        {pngOutput && (
          <img alt={'png plot'} src={`data:image/png;base64, ${pngOutput}`} />
        )}
        {svgOutput && (
          <img
            alt={'svg plot'}
            src={`data:image/svg+xml;base64,${svgOutputBase64}`}
          />
        )}
      </div>
    </div>
  );
};

export default ExecuteResultOutput;
