import { createAction } from 'redux-act';

import * as lineChartCore from '../../core/lineChart';

export const toggleState = createAction('toggle line state', (value) => ({
  value,
}));

export const reducer = {
  [toggleState]: (state, payload) => lineChartCore.toggleState(state, payload),
};
