import React, { Component, Fragment, RefObject } from 'react';

import AceEditor from 'react-ace';
import 'brace/mode/python';
import 'brace/theme/tomorrow';
import 'brace/ext/language_tools';
import CodeCellOutput from './CodeCellOutput';
// import CodeCompletion from './CodeCompletion';
import ReactAce from 'react-ace';
import {
  AltaSigmaCodeCell,
  Session,
} from '../../../../store/workbench/state.types';

export type Props = {
  /** Cell content */
  cell: AltaSigmaCodeCell;
  /** Path of the notebook */
  path: string;
  /** IDs of the currently selected cells */
  selectedCells: string[];
  session: Session;
  /** Force focus "flag": ID of the cell that needs to get focused after mounting, because it was newly created or the type changed */
  forceFocus?: string;

  changeSource: (data: {
    path: string;
    cellId: string;
    source: string;
  }) => void;
  requestCodeCompletion: (
    path: string,
    cellId: string,
    sessionId: string,
    currentRowSource: string,
    column: number,
    row: number
  ) => void;
  clearCodeCompletion: (path: string, cellId: string) => void;
  /** Clears the "forceFocus" flag */
  clearForceFocus: (path: string) => void;
  readOnly?: boolean;
};

function getAceMode(session: Session) {
  const kernelName = session?.kernel?.name;
  if (!kernelName) return undefined;
  if (kernelName.startsWith('python3') || kernelName === 'pysparkkernel')
    return 'python';
  return undefined;
}

/**
 * Component wraps the code editor and the outputs for this cell.
 */
export default class CodeCell extends Component<Props> {
  // Filled in a callback of the <AceEditor/>
  editorRef: RefObject<ReactAce>;

  constructor(props) {
    super(props);
    this.onCompletionSelect = this.onCompletionSelect.bind(this);
    this.editorRef = React.createRef();
  }

  checkForCodeCompletion(source, event) {
    const { path, cell, session, requestCodeCompletion } = this.props;
    const { action, end, lines } = event;
    if (action !== 'insert') return true; // Everything apart from insert actions aren't of any interest
    const { row, column } = end || {};
    const currentRow = (source || '').split('\n')[row] || '';

    // Check whether the entered character was a tab
    const wasTab = (lines[0] || '').charCodeAt(0) === 9; // char code 9 = "Tab" key
    if (!wasTab) return true; //Early exit: If it wasn't a tab it isn't relevant.

    const onlyWhitespacesSoFar =
      currentRow.slice(0, column).trim().length === 0;
    if (onlyWhitespacesSoFar) return true; // If there were only whitespaces so far in this row, simply add the tab to the line.

    // When arrived here, a Tab (= 2 spaces) was entered and the line hasn't started with only white spaces so far.
    // -- If there would have been only whitespaces so far - enter the tab instead of triggering the auto completion
    // In this case, take the current line - and send it as a completion request through the WebSocket channel
    requestCodeCompletion(
      path,
      cell.id,
      session.id,
      currentRow,
      column - 1,
      row
    ); // column-1 to go back the just entered character

    return false;
  }

  onCompletionSelect(selected) {
    const { cell, changeSource, path, clearCodeCompletion } = this.props;
    if (!cell.completion) return;
    const source = Array.isArray(cell.source)
      ? cell.source
      : cell.source.split('\n');
    const row = cell.completion.row;

    const newSource = source
      .map((line, index) => {
        if (index !== row) return line;
        else {
          return (
            line.slice(0, selected.start) +
            selected.text +
            line.slice(selected.end, line.length)
          );
        }
      })
      .join('\n');

    changeSource({
      path,
      cellId: cell.id,
      source: newSource,
    });

    clearCodeCompletion(path, cell.id);
  }

  componentDidMount() {
    if (
      this.props.forceFocus === this.props.cell.id &&
      this.editorRef &&
      document.activeElement !== this.editorRef.current.editor
    ) {
      this.editorRef.current.editor.focus();
      this.props.clearForceFocus(this.props.path);
    }
  }

  render() {
    const { path, cell, changeSource, clearCodeCompletion, session, readOnly } =
      this.props;

    const aceMode = getAceMode(session);

    return (
      <Fragment>
        <div className={'editor-parent-container'}>
          <div
            className={`editor-container${
              cell.outputs && cell.outputs.length > 0 ? ' has-output' : ''
            }`}
          >
            <AceEditor
              ref={this.editorRef}
              width={'100%'}
              className={'code-cell-editor'}
              mode={aceMode}
              theme={'tomorrow'}
              onChange={(source, event) => {
                // const doChangeSource = this.checkForCodeCompletion(source, event);
                const doChangeSource = true; // Disabled for the moment since this prevents copy&pasting with leading spaces

                if (doChangeSource) {
                  // Change the source? Or was the code completion triggered?
                  changeSource({
                    path,
                    cellId: cell.id,
                    source,
                  });
                  if (cell.completion) {
                    clearCodeCompletion(path, cell.id);
                  }
                } else {
                  // Set the current value, do not enter the "Tab" (or the two spaces), instead the code completion
                  // is triggered
                  this.editorRef.current.editor.setValue(
                    Array.isArray(cell.source)
                      ? cell.source.join('')
                      : cell.source
                  );
                  this.editorRef.current.editor.clearSelection(); // Otherwise setValue will select the whole cell source
                }
              }}
              showGutter
              highlightActiveLine
              value={
                Array.isArray(cell.source) ? cell.source.join('') : cell.source
              }
              setOptions={{
                maxLines: Infinity,
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
                useSoftTabs: false,
              }}
              editorProps={{ $blockScrolling: Infinity }}
              // @ts-ignore
              scrollIntoView
              readOnly={!!readOnly}
            />
          </div>
        </div>
        {/* cell.completion &&
        <CodeCompletion
          cell={cell}
          path={path}
          clearCodeCompletion={clearCodeCompletion}
          onCompletionSelect={this.onCompletionSelect}
        />*/}
        {cell.outputs && cell.outputs.length > 0 && (
          <CodeCellOutput outputs={cell.outputs} path={path} cellId={cell.id} />
        )}
      </Fragment>
    );
  }
}
