import { connect } from 'react-redux';
import { getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import { adminUpdateUser } from '../../../../redux/modules/admin.users.module';
import AttributesEditMode, { Props } from './AttributesEditMode';
import { dataToForm, form, formName } from './Attributes.form';
import { ToBeRefined } from 'common/dist/types/todo_type';

export function mapStateToProps(state, otherProps) {
  const attributes = dataToForm(
    state.admin.userDetails.user.data?.attributes || {}
  );
  const formValues = getFormValues(formName)(state);

  return {
    initialValues: attributes,
    formValues,
    formError: getFormSyncErrors(formName)(state),
  };
}

export const mapDispatchToProps = {
  updateUser: adminUpdateUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm<ToBeRefined, Props>(form)(AttributesEditMode));
