import React, { useEffect, useMemo, useState } from 'react';
import { FiCheck, FiEdit2, FiX } from 'react-icons/fi';
import DropdownSelectInput from '../../../../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { useParams } from 'react-router-dom';
import {
  useAugur,
  useUpdateAugur,
} from '../../../../../../../../../core/api/augurs';
import { useSelector } from 'react-redux';

function versionsToOptions(versions) {
  return versions
    .sort((a, b) => {
      // Sort Descending
      if (a.number < b.number) return 1;
      else if (a.number > b.number) return -1;
      return 0;
    })
    .map((v) => ({
      label: v.number,
      value: v.number,
    }));
}

const ArchetypeNameAndCodeComponent = ({ fetchArchetypeVersions }) => {
  const { habitatCode, augurCode } = useParams();

  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { data: augur } = useAugur(habitatCode, augurCode);
  const name = useMemo(() => augur?.name ?? '', [augur]);
  useEffect(() => setInputValue(name), [name]);

  const archetypeCode = augur?.archetype?.code ?? '';
  const archetypeName = augur?.archetype?.name ?? '';
  const archetypeVersionNumber = augur?.archetypeVersionNumber ?? '';
  const archetypeIsCustom = augur?.archetype?.custom ?? false;
  useEffect(
    () => fetchArchetypeVersions(archetypeCode),
    [fetchArchetypeVersions, archetypeCode]
  );

  const archetypeVersions = useSelector((state) => state.archetypeVersions);

  const { mutate: updateAugur } = useUpdateAugur(habitatCode, augurCode);
  const saveArchetypeVersionNumber = () => {
    if (inputValue !== archetypeVersionNumber) {
      updateAugur({ archetypeVersionNumber: inputValue });
    }
    setEditMode(false);
  };

  const handleKeyDown = (e) => {
    if (editMode) {
      if (e.key === 'Enter') {
        saveArchetypeVersionNumber();
      } else if (e.key === 'Escape') {
        setEditMode(false);
        setInputValue(augur.archetypeVersionNumber);
      }
    }
  };

  const versionNumber = () => {
    if (!editMode) {
      return (
        <div className={'information-version-number-wrapper'}>
          <p className={'information-version-number'}>
            {archetypeVersionNumber}
          </p>
          <div className={'icon-container'}>
            <FiEdit2
              className={'action-icon'}
              size={16}
              onClick={() => setEditMode(!editMode)}
            />
          </div>
        </div>
      );
    }

    // FIXME: this is broken and crashes the app, won't fix now as it's removed anyway
    const options = versionsToOptions(archetypeVersions);

    return (
      <div className={'edit-dropdown-wrapper'}>
        <DropdownSelectInput
          id='archetypeCode'
          name='archetypeCode'
          placeholder={{
            id: 'newAugur.stepOne.no_version_selected',
            defaultMessage: 'No archetype version selected',
          }}
          options={options}
          className=''
          value={options.find((o) => o.value === inputValue)}
          onChange={(value) => setInputValue(value)}
          openOnFocus
          clearable={false}
        />
        <div className={'icon-container'}>
          <FiCheck
            className={'action-icon inline-icon-ok'}
            size={16}
            onClick={saveArchetypeVersionNumber}
          />
        </div>
        <div className={'icon-container'}>
          <FiX
            className={'action-icon inline-icon-cancel'}
            size={16}
            onClick={() => {
              setInputValue(archetypeVersionNumber);
              setEditMode(!editMode);
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div className={'datapool-information'}>
      <div className={'information-name-wrapper'}>
        <p className={'information-name'}>
          {archetypeIsCustom ? '[Custom] ' : '[Stock] '} {archetypeName}
        </p>
      </div>
      <p className={'information-code'}>{archetypeCode}</p>
      {archetypeIsCustom && (
        <p className={'information-version-number'} onKeyDown={handleKeyDown}>
          {versionNumber()}
        </p>
      )}
    </div>
  );
};

export default ArchetypeNameAndCodeComponent;
