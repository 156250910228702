export const workbenchRoutes = {
  basePath: '/app/workbench',
  createNotebook: '/create-notebook',
  pasteNotebook: '/paste-notebook',
  editNotebook: '/edit-notebook',
  warningOpenFile: '/warning-open-file',
  addFile: '/add-file',
  addDirectory: '/add-directory',
  editDirectory: '/edit-directory',
  repositoryInfo: '/repository-info',
  repositoryFork: '/repository-fork',
  deleteContent: '/delete-content',
  emptyRecycleBin: '/empty-recycle-bin',
};
