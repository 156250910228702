import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ResourcePermissions from '../../../organisms/permissions/ressource-permissions/ResourcePermissions.container';
import { cassandraDetailsRoute } from '../cassandra-permissions/CassandraPermissions';
import {
  CASSANDRA_KEYSPACE_SCOPES,
  RESOURCE_TYPE_CASSANDRA_KEYSPACE_PREFIX,
} from 'common/dist/constants/keycloak';
import dataManagementMessages from 'common/dist/messages/dataManagement';
import { useSelector } from 'react-redux';
import { NamesState } from '../../../../store/names/state.types';
import { datapoolFromKeyspace } from 'common/dist/constants/dataManagement/cassandra';
import { RootState, useAppDispatch } from '../../../../store/store';
import { DataSources } from '../../../../store/dataManagement/state.types';
import { fetchDataSources } from '../../../../redux/modules/data.dataSources.module';
import { useIntl } from 'react-intl';
import commonMessages from 'common/dist/messages/common';

const CassandraDetails: FC = () => {
  const { cassandraCode, keyspace } = useParams<{
    cassandraCode: string;
    keyspace: string;
  }>();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const dataSources = useSelector<RootState, DataSources>(
    (state) => state.data.dataSources
  );
  const dataSource = dataSources.data.find((ds) => ds.code === cassandraCode);
  useEffect(() => {
    // If there are no dataSources (or they aren't an array) fetch them, but avoid it if they are already fetched.
    if (!(dataSources.data.length > 0)) {
      dispatch(fetchDataSources());
    }
  }, [dispatch, dataSources.data]);

  const names = useSelector<RootState, NamesState>((state) => state.names);
  const speakingName =
    names.datapoolNames[datapoolFromKeyspace(keyspace).toUpperCase()];

  let detailsRows = [
    {
      key: dataManagementMessages.msgS3PermissionsRowDataSourceName,
      value: dataSource?.name,
    },
    {
      key: dataManagementMessages.msgCassandraPermissionsRowKeyspaceName,
      value: keyspace,
    },
  ];
  if (speakingName !== undefined) {
    detailsRows = [
      ...detailsRows,
      {
        key: dataManagementMessages.msgCassandraPermissionsRowDatapoolName,
        value: speakingName,
      },
    ];
  }

  return (
    <ResourcePermissions
      baseUrl={cassandraDetailsRoute(cassandraCode, keyspace)}
      showBackButton
      backButtonLabel={intl.formatMessage(commonMessages.backTo, {
        origin: 'Keyspaces',
      })}
      backToUrl={`/app/admin/users/cassandraPermissions/${cassandraCode}`}
      resourceType={`${RESOURCE_TYPE_CASSANDRA_KEYSPACE_PREFIX}:${cassandraCode}`}
      resourceName={`${RESOURCE_TYPE_CASSANDRA_KEYSPACE_PREFIX}:${cassandraCode}:${keyspace}`}
      details={{
        headline: dataManagementMessages.msgCassandraPermissionsHeadline,
        rows: detailsRows,
      }}
      scopes={CASSANDRA_KEYSPACE_SCOPES}
      messagesGroupPermissions={{
        subTitle: dataManagementMessages.msgCassandraPermissionsGroupSubTitle,
        description:
          dataManagementMessages.msgCassandraPermissionsGroupDescription,
      }}
      messagesUserPermissions={{
        subTitle: dataManagementMessages.msgCassandraPermissionsUserSubTitle,
      }}
    />
  );
};

export default CassandraDetails;
