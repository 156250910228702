import * as React from 'react';

function SvgPlain(props) {
  return (
    <svg viewBox='0 0 512 512' width='1em' height='1em' {...props}>
      <circle fill='#eef0f2' r={256} cy={255} cx={256} />
      <path
        fill='#dce2eb'
        d='M481.26 376.7v.01C437.97 456.68 353.32 511 256 511S74.03 456.68 30.74 376.71v-.01h450.52z'
      />
      <path
        fill='#474F6F'
        d='M149.99 197.51v42.56c0 9.9-5.28 19.06-13.86 24.01l-21.67 12.51 10.16 193.07a41.102 41.102 0 01-.06 5.06c-5.32-3.19-10.52-6.57-15.58-10.13a256.013 256.013 0 01-23.56-18.7l-.01-.01a255.912 255.912 0 01-21.28-21.41 255.12 255.12 0 01-14.62-18.13l6.82-129.77-21.63-12.49a27.73 27.73 0 01-13.87-24.01v-42.56c0-9.9 5.29-19.05 13.87-24.01l14.37-8.3c3.88-2.24 8.73.57 8.73 5.05v41.91c0 4.23 2.26 8.14 5.93 10.26l15.76 9.1a11.827 11.827 0 0011.85 0l15.76-9.1c3.67-2.12 5.93-6.03 5.93-10.26v-41.91c0-4.48 4.85-7.29 8.73-5.05l14.37 8.3a27.733 27.733 0 0113.86 24.01z'
      />
      <path
        fill='#323B5A'
        d='M149.99 197.51v42.56c0 9.9-5.28 19.06-13.86 24.01l-21.67 12.51 10.16 193.07a41.102 41.102 0 01-.06 5.06c-5.32-3.19-10.52-6.57-15.58-10.13a256.013 256.013 0 01-23.56-18.7l-.01-.01V233.11c2.05 0 4.1-.53 5.93-1.59l15.76-9.1c3.67-2.12 5.93-6.03 5.93-10.26v-41.91c0-4.48 4.85-7.29 8.73-5.05l14.37 8.3a27.733 27.733 0 0113.86 24.01z'
      />
      <path
        fill='#323B5A'
        d='M108.98 464.59a256.013 256.013 0 01-23.56-18.7l-.01-.01a255.912 255.912 0 01-21.28-21.41l5.44-95.95c.47-8.4 7.42-14.97 15.84-14.98h.01c4.2 0 8.05 1.65 10.89 4.34 2.86 2.69 4.71 6.44 4.95 10.64l7.72 136.07z'
      />
      <path
        fill='#20273A'
        d='M108.98 464.59a256.013 256.013 0 01-23.56-18.7V313.54c4.2 0 8.05 1.65 10.89 4.34 2.86 2.69 4.71 6.44 4.95 10.64l7.72 136.07z'
      />
      <g>
        <path fill='#474F6F' d='M181.901 195.689h21.287v227.196h-21.287z' />
        <path fill='#323B5A' d='M192.543 195.689h10.646v227.196h-10.646z' />
        <path fill='#323B5A' d='M181.901 195.689h21.287v40.448h-21.287z' />
        <path fill='#20273A' d='M192.543 195.689h10.646v40.448h-10.646z' />
        <path
          fill='#FFD112'
          d='M192.54 510.96c.41.03.83.04 1.25.04h-1.25v-.04zM227.38 356.73v135.25c0 6.88-3.66 12.91-9.13 16.25-8.72-1.28-17.3-3.01-25.71-5.16-5.66-1.44-11.24-3.07-16.74-4.89-6.14-2.02-12.17-4.27-18.09-6.74V356.73c0-10.5 8.52-19.02 19.03-19.02h31.62c10.5 0 19.02 8.52 19.02 19.02z'
        />
        <path
          fill='#FF9F00'
          d='M227.38 356.73v135.25c0 6.88-3.66 12.91-9.13 16.25-8.72-1.28-17.3-3.01-25.71-5.16-5.66-1.44-11.24-3.07-16.74-4.89-.68-1.94-1.04-4.03-1.04-6.2V356.73c0-6.01 2.79-11.37 7.14-14.85a18.938 18.938 0 0111.89-4.17h14.57c10.5 0 19.02 8.52 19.02 19.02z'
        />
        <path
          fill='#474F6F'
          d='M208.546 205.152a16.111 16.111 0 01-5.358 8.893c-.125.105-.25.211-.379.312a16.44 16.44 0 01-10.263 3.59h-.004c-4.008 0-7.75-1.448-10.642-3.902a16.117 16.117 0 01-5.358-8.893 63.243 63.243 0 014.257-39.671l5.1-11.188c1.296-2.849 3.973-4.273 6.642-4.273 2.677 0 5.346 1.424 6.646 4.273l5.1 11.188a63.23 63.23 0 014.259 39.671z'
        />
        <path
          fill='#323B5A'
          d='M192.543 217.948v-67.929c2.677 0 5.346 1.424 6.646 4.273l5.1 11.188a63.236 63.236 0 014.257 39.671 16.117 16.117 0 01-5.358 8.894l-10.645 3.903z'
        />
        <path
          fill='#ED771B'
          d='M192.54 510.96c.41.03.83.04 1.25.04h-1.25v-.04zM227.38 356.73v135.25c0 6.88-3.66 12.91-9.13 16.25-8.72-1.28-17.3-3.01-25.71-5.16V337.71h15.82c10.5 0 19.02 8.52 19.02 19.02z'
        />
      </g>
      <g>
        <path
          fill='#474F6F'
          d='M512 255c0 38.71-8.59 75.41-23.98 108.31H264.07c-5.4 0-9.78-4.39-9.78-9.8v-184.1c0-5.41 4.38-9.79 9.78-9.79h229.56C505.48 189.1 512 221.29 512 255z'
        />
        <path
          fill='#323B5A'
          d='M512 255c0 38.71-8.59 75.41-23.98 108.31h-84.09V159.62h89.7C505.48 189.1 512 221.29 512 255z'
        />
        <g fill='#FFF'>
          <path d='M304.806 213.787l-17.058-7.076a3.39 3.39 0 01-2.091-3.131v-1.698a3.39 3.39 0 012.089-3.13l17.057-7.088c2.232-.928 4.69.713 4.69 3.13v.041a3.39 3.39 0 01-2.171 3.163l-12.316 4.747 12.316 4.749a3.388 3.388 0 012.17 3.162c.002 2.416-2.454 4.056-4.686 3.131zM321.471 186.842h2.281a2.561 2.561 0 012.489 3.166l-6.475 26.66a2.562 2.562 0 01-2.489 1.957h-2.245a2.562 2.562 0 01-2.49-3.163l6.439-26.66a2.563 2.563 0 012.49-1.96zM331.319 207.493l12.316-4.749-12.316-4.747a3.39 3.39 0 01-2.171-3.163v-.041c0-2.417 2.458-4.058 4.69-3.13l17.058 7.088a3.39 3.39 0 012.089 3.13v1.698a3.39 3.39 0 01-2.091 3.131l-17.057 7.076c-2.232.926-4.688-.714-4.688-3.131a3.39 3.39 0 012.17-3.162z' />
        </g>
        <path
          fill='#6aa0eb'
          d='M319.726 243.206H291.99a6.333 6.333 0 010-12.666h27.736a6.333 6.333 0 110 12.666z'
        />
        <path
          stroke='null'
          fill='#6aa0eb'
          d='M393.673 286.206h-27.736a6.333 6.333 0 01-6.333-6.333h0a6.333 6.333 0 016.333-6.333h27.736a6.333 6.333 0 016.333 6.333h0a6.333 6.333 0 01-6.333 6.333z'
        />
        <path
          fill='#FFF'
          d='M457.521 243.206h-115.03a6.333 6.333 0 010-12.666h115.031a6.334 6.334 0 01-.001 12.666zM407.021 264.706h-115.03a6.333 6.333 0 010-12.666h115.031a6.334 6.334 0 01-.001 12.666zM343.736 286.206H291.99a6.333 6.333 0 010-12.666h51.746a6.333 6.333 0 110 12.666zM407.021 307.706h-115.03a6.333 6.333 0 010-12.666h115.031a6.334 6.334 0 01-.001 12.666zM436.129 307.706h-14.721a2.417 2.417 0 110-4.834h14.721a2.417 2.417 0 110 4.834z'
        />
      </g>
    </svg>
  );
}

export default SvgPlain;
