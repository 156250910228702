import { connect } from 'react-redux';
import ModalDialog from './ModalDialog';
import { getFormValues, reduxForm } from 'redux-form';
import {
  hideSelectVariable,
  showSelectVariable,
  updateSettingsOfInputElement,
  updateSettingsOfOutputElement,
} from '../../../../../../../../../redux/workbench/modules/cells.app.module';
import { formName } from '../python3-input-cell/elements/_interface/input-element.form';
import { getElementByType as getInputElementByType } from '../python3-input-cell/elements/_interface/Python3InputElementManager';
import { getElementByType as getOutputElementByType } from '../python3-output-cell/elements/_interface/Python3OutputElementManager';
import P3InputElement, {
  AsInputElement,
} from '../python3-input-cell/elements/_interface/P3InputElement';
import P3OutputElement, {
  AsOutputElement,
} from '../python3-output-cell/elements/_interface/P3OutputElement';
import { inputModalForm } from './inpmodalDialog.form';
import { DeprecatedRootState } from '../../../../../../../../../store/state.type';
import { ToBeRefined } from 'common/dist/types/todo_type';

type OwnProps = {
  inputOrOutput: 'input' | 'output';
  element: AsInputElement | AsOutputElement;
  variables: ToBeRefined;
};

export function mapStateToProps(
  state: DeprecatedRootState,
  ownProps: OwnProps
) {
  const { element, inputOrOutput } = ownProps;

  let elementClass: P3InputElement | P3OutputElement = undefined;
  if (inputOrOutput === 'input') {
    elementClass = getInputElementByType(element.type);
  } else if (inputOrOutput === 'output') {
    elementClass = getOutputElementByType(element.type);
  }

  const validateSettings = elementClass?.validateSettings || (() => {});

  return {
    initialValues: {
      ...ownProps.element.settings,
    },
    formValues: getFormValues(formName)(state),
    validate: (settings) => validateSettings(settings, ownProps.variables),
    selectVariable: state.workbench.selectVariable,
  };
}

export const mapDispatchToProps = {
  updateInputElement: updateSettingsOfInputElement,
  updateOutputElement: updateSettingsOfOutputElement,
  showSelectVariable,
  hideSelectVariable,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(reduxForm(inputModalForm)(ModalDialog));
