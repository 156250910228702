import React, { FC } from 'react';
import { useAugur } from '../../../../../../../../../core/api/augurs';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../../store/store';

const HabitatNameAndCodeComponent: FC = () => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  // FIXME: this is a workaround because the details component does no longer exist in this form
  //   this should definitely use useHabitat at some point
  //   this also currently breaks if the page is not opened from the dashboard
  const habitatName = useSelector<RootState>(
    (state) => state.dashboard.habitats[habitatCode].name
  );

  const { data: augur } = useAugur(habitatCode, augurCode);

  return (
    <div className={'habitat-information'}>
      <p className={'information-name'}>{habitatName}</p>
      <p className={'information-code'}>{augur?.habitatCode}</p>
    </div>
  );
};

export default HabitatNameAndCodeComponent;
