import * as dashboard from '../redux/state/dashboard.state';
import * as newAugur from '../redux/state/newAugur.state';
import * as chart from '../redux/state/chart.state';
import * as details from '../redux/state/details.state';
import * as currentUser from '../redux/state/currentUser.state';
import * as habitats from '../redux/state/habitats.state';
import * as predictionTargets from '../redux/state/predictionTargets.state';
import * as summary from '../redux/state/summary.state';
import * as form from '../redux/state/form.state';
import * as misclassification from '../redux/state/misclassification.state';
import * as menu from '../redux/state/menu.state';
import * as intl from '../redux/state/intl.state';
import * as habitat from '../redux/state/habitat.state';
import * as account from '../redux/state/account.state';
import * as notifications from '../redux/state/notifications.state';
import * as page from '../redux/state/page.state';
import * as accuracyDetail from '../redux/state/accuracyDetail.state';
import * as newHabitat from '../redux/state/newHabitat.state';
import * as admin from '../redux/state/admin.state';
import * as dataImport from '../redux/state/dataImport.state';
import * as newDatapool from '../redux/state/newDatapool.state';
import * as modules from '../redux/state/modules.state';
import * as datapoolDetails from '../redux/state/datapoolDetails.state';
import * as models from '../redux/state/models.state';
import * as orchestration from '../redux/state/orchestration.state';
import * as jobconfigs from '../redux/state/orchestration.jobconfigs.state';
import * as archetypes from '../redux/state/archetypes.state';
import * as queue from '../redux/state/orchestration.queue.state';
import * as history from '../redux/state/orchestration.history.state';
import * as schedules from '../redux/state/orchestration.schedules.state';
import * as jobDetails from '../redux/state/orchestration.jobdetails.state';
import * as codeCapsuleReport from '../redux/state/orchestration.codeCapsuleReport.state';
import * as codeCapsuleOutput from '../redux/state/orchestration.codeCapsuleOutput.state';
import * as newSchedule from '../redux/state/newSchedule.state';
import * as archetypeVersions from '../redux/state/archetypeVersions.state';
import * as realtime from '../redux/state/orchestration.realtime.state';
import * as names from '../redux/state/orchestration.names.state';
import * as codeCapsules from '../redux/state/codeCapsules.state';
import * as apps from '../redux/state/apps.state';
import * as data from '../redux/state/data.state';
import * as adminBackups from './admin/backups/state';
import * as adminMaintenance from './admin/maintenance/state';
import * as users from './users/slice';

// Workbench initial state
import { initialState as initialWorkbenchState } from '../redux/workbench/state/initial';

// --- Migrated to Typescript
import * as adminResources from './admin/resources/state';
import * as augurBiography from './augurBiography/slice';
import * as augurBiographySidebar from './augurBiographySidebar/slice';
import { DeprecatedRootState } from './state.type';

export const test: DeprecatedRootState = {
  dashboard: dashboard.test,
  newAugur: newAugur.test,
  selectedNode: {},
  chart: chart.test,
  // @ts-ignore
  currentUser: currentUser.test,
  // @ts-ignore
  details: details.test,
  habitats: habitats.test,
  summary: summary.test,
  predictionTargets: predictionTargets.test,
  misclassification: misclassification.test,
  form: form.test,
  menu: menu.test,
  account: account.test,
  habitat: habitat.test,
  notifications: notifications.test,
  page: page.test,
  accuracyDetail: accuracyDetail.test,
  admin: admin.test,
  newHabitat: newHabitat.test,
  dataImport: dataImport.test,
  newDatapool: newDatapool.test,
  modules: modules.test,
  selectedMainTab: 'models',
  // @ts-ignore
  workbench: initialWorkbenchState,
  datapoolDetails: datapoolDetails.test,
  models: models.test,
  orchestration: orchestration.test,
  jobconfigs: jobconfigs.test,
  archetypes: archetypes.test,
  archetypeVersions: archetypeVersions.test,
  queue: queue.test,
  jobGroupHistory: history.test,
  jobDetails: jobDetails.test,
  codeCapsuleReport: codeCapsuleReport.test,
  codeCapsuleOutput: codeCapsuleOutput.test,
  jobSchedules: schedules.test,
  newSchedule: newSchedule.test,
  realtime: realtime.test,
  names: names.test,
  codeCapsules: codeCapsules.test,
  apps: apps.test,
  appVersions: {},
  adminResources: adminResources.test,
  adminBackups: adminBackups.test,
  adminMaintenance: adminMaintenance.test,
  // @ts-ignore
  data: data.test,
  users: users.test,
  augurBiography: augurBiography.test,
  augurBiographySidebar: augurBiographySidebar.test,
};

export const initial: DeprecatedRootState = {
  dashboard: dashboard.initial,
  newAugur: newAugur.initial,
  selectedNode: {},
  chart: chart.initial,
  // @ts-ignore
  currentUser: currentUser.initial,
  // @ts-ignore
  details: details.initial,
  habitats: habitats.initial,
  predictionTargets: predictionTargets.initial,
  summary: summary.initial,
  misclassification: misclassification.initial,
  form: form.initial,
  menu: menu.initial,
  intl: intl.initial,
  account: account.initial,
  habitat: habitat.initial,
  notifications: notifications.initial,
  page: page.initial,
  accuracyDetail: accuracyDetail.initial,
  admin: admin.initial,
  newHabitat: newHabitat.initial,
  dataImport: dataImport.initial,
  newDatapool: newDatapool.initial,
  modules: modules.initial,
  selectedMainTab: 'models',
  // @ts-ignore
  workbench: initialWorkbenchState,
  datapoolDetails: datapoolDetails.initial,
  models: models.initial,
  orchestration: orchestration.initial,
  jobconfigs: jobconfigs.initial,
  archetypes: archetypes.initial,
  archetypeVersions: archetypeVersions.initial,
  queue: queue.initial,
  jobGroupHistory: history.initial,
  jobDetails: jobDetails.initial,
  codeCapsuleReport: codeCapsuleReport.initial,
  codeCapsuleOutput: codeCapsuleOutput.initial,
  jobSchedules: schedules.initial,
  newSchedule: newSchedule.initial,
  realtime: realtime.initial,
  names: names.initial,
  codeCapsules: codeCapsules.initial,
  apps: apps.initial,
  appVersions: {},
  adminResources: adminResources.initial,
  adminBackups: adminBackups.initial,
  adminMaintenance: adminMaintenance.initial,
  // @ts-ignore
  data: data.initial,
  users: users.initial,
  augurBiography: augurBiography.initial,
  augurBiographySidebar: augurBiographySidebar.initial,
};
