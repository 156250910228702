import React from 'react';

/* eslint-disable max-len */

function LogoIconColored() {
  return (
    <svg width='108.01' height='80.75' viewBox='0 0 108.01 80.75'>
      <path
        fill='#224F91'
        d='M97.15 30.723c-6 0-10.866 4.686-10.866 10.473 0 .707.074 1.408.213 2.082.027.29.06.58.06.877 0 5.25-4.41 9.492-9.847 9.492-1.422 0-2.77-.303-3.998-.836-.26-.145-.518-.308-.79-.448-2.487-2.2-4.044-5.36-4.044-8.87 0-3.784 1.818-7.163 4.65-9.372 6.184-2.928 10.436-9.055 10.436-16.14C82.964 8.046 74.618 0 64.318 0c-9.754 0-17.762 7.226-18.576 16.433-1.69 8.992-9.85 15.81-19.666 15.81-1.98 0-3.887-.282-5.69-.79-1.84-.834-3.89-1.31-6.054-1.31-7.92 0-14.33 6.18-14.33 13.815 0 7.625 6.41 13.82 14.33 13.82 7.494 0 13.63-5.55 14.266-12.617 2.078-7.825 9.432-13.62 18.21-13.62 2.245 0 4.4.393 6.397 1.086 4.348 1.97 7.355 6.213 7.355 11.145 0 3.54-1.547 6.733-4.03 8.978-4.552 2.64-7.608 7.428-7.608 12.928 0 8.32 7.004 15.076 15.646 15.076 8.635 0 15.64-6.756 15.64-15.076 0-.938-.104-1.844-.267-2.734l.01.027c0-6.458 5.347-11.894 12.034-11.894.84 0 1.646.086 2.436.244.87.22 1.78.346 2.73.346 6 0 10.86-4.69 10.86-10.47 0-5.787-4.86-10.473-10.86-10.473'
      />
    </svg>
  );
}

export default LogoIconColored;

/* eslint-enable max-len */
