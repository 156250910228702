import appendAreaChart from '../areaChart/areaChart';
import * as config from './config';

const THREE = require('three');

if (process.env.NODE_ENV !== 'test') {
  require('imports-loader?THREE=three!three/examples/js/renderers/CSS3DRenderer.js'); // eslint-disable-line
}

export function render(scene, width, height, data, maxY) {
  data.forEach((run, index) => {
    const div = document.createElement('div');
    div.style.pointerEvents = 'none';

    appendAreaChart(div, width, height, data, run, maxY);

    const object = new THREE.CSS3DObject(div);
    object.position.x = 0;
    object.position.y = 0;
    object.position.z = -config.DISTANCE_BETWEEN_LAYERS * (index + 1);
    object.rotation.y = Math.PI;

    scene.add(object);
  });
}
