import React from 'react';
import {
  SortableTreeWithoutDndContext as SortableTree,
  changeNodeAtPath,
} from 'react-sortable-tree';
import { FiEdit2, FiCheck } from 'react-icons/fi';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import styles from './styles.module.scss';

import ButtonBar from './ButtonBar.container';
import AddButton from './AddButton';
import RemoveDropzone from './RemoveDropzone';
import PlaceholderRenderer from './PlaceholderRenderer';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { error as ERROR_PUSH } from '../../../core/notifications';

const externalNodeType = 'add-node'; // TODO Must be in sync with the same variable from the AddButton

export type OwnProps = {
  match: {
    params: {
      habitatCode: string;
      datapoolCode: string;
    };
  };
};

type ContainerProps = {
  treeData: TreeData[];
  newTaxonomy: {};
  fetchTaxonomy: (habitatCode: string, datapoolCode: string) => void;

  setTreeData: (arg: ToBeRefined) => void;
  setNewTaxonomy: (newTaxonomy: NewTaxonomy) => void;
  sendNotification: (title: string, description: string, type: string) => void;
};

export type Props = OwnProps & ContainerProps;

export type NewTaxonomy = { title: string; subtitle: string };

type TreeData = {};

export default class TargetTaxonomy extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.alert = this.alert.bind(this);
  }

  componentDidMount() {
    const {
      fetchTaxonomy,
      match: { params },
    } = this.props;
    fetchTaxonomy(params?.habitatCode, params?.datapoolCode);
  }

  updateNode(node, path, payload = {}) {
    const { treeData, setTreeData } = this.props;
    const getNodeKey = ({ treeIndex }) => treeIndex;

    const changedNode = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: {
        ...node,
        ...payload,
      },
    });
    setTreeData(changedNode);
  }

  markNodeAsEditing(node, path, editing) {
    if (!editing && (!node.title || node.title.replace(/\s/g, '') === '')) {
      this.updateNode(node, path, { titleError: 'Required' });
      return this.alert(false);
    }
    this.updateNode(node, path, { editing, titleError: null });
  }

  updateTitle(node, path, title) {
    this.updateNode(node, path, { title, titleError: null });
  }

  updateSubtitle(node, path, subtitle) {
    this.updateNode(node, path, { id: subtitle, subtitle });
  }

  alert(creating?: boolean) {
    const { sendNotification } = this.props;

    const title = !creating
      ? 'notifications.title.node_no_save'
      : 'notifications.title.node_no_new_drag';
    sendNotification(
      title,
      'notifications.description.name_is_required',
      ERROR_PUSH
    );
  }

  // --- Tree Elements
  buttons(node, path) {
    if (node.editing) {
      return [
        <button onClick={() => this.markNodeAsEditing(node, path, false)}>
          <FiCheck className='icon icon-check' size='14px' />
        </button>,
      ];
    } else {
      return [
        <button onClick={() => this.markNodeAsEditing(node, path, true)}>
          <FiEdit2 className='icon icon-edit' size='14px' />
        </button>,
      ];
    }
  }

  title(node, path) {
    return node.editing ? (
      <input
        className={classNames(styles.inputTitle, {
          [styles.nodeInvalid]: !!node.titleError,
        })}
        value={node.title}
        placeholder={node.titleError || 'Enter Name'}
        autoFocus
        onChange={(event) => {
          const newTitle = event.target.value;
          this.updateTitle(node, path, newTitle);
        }}
      />
    ) : (
      <p>{node.title}</p>
    );
  }

  subtitle(node, path) {
    return node.editing ? (
      <input
        className={classNames(styles.inputSubtitle, {
          [styles.nodeInvalid]: !!node.titleError,
        })}
        value={node.subtitle}
        placeholder={node.subtitleError || 'Enter ID'}
        onChange={(event) => {
          const newSubtitle = event.target.value;
          this.updateSubtitle(node, path, newSubtitle);
        }}
      />
    ) : (
      <p>{node.subtitle}</p>
    );
  }

  // ---

  render() {
    const { newTaxonomy, setNewTaxonomy, treeData, setTreeData } = this.props;

    const emptyTree = !treeData || isEmpty(treeData);

    return (
      <div className={styles.taxonomy}>
        <div className={styles.addRemoveBar}>
          <AddButton
            newTaxonomy={newTaxonomy}
            setNewTaxonomy={setNewTaxonomy}
            key={'__new-node'}
            node={{ title: '', subtitle: '', id: '__new-node' }}
            alert={this.alert}
          />
          {!emptyTree && <RemoveDropzone />}
        </div>
        <SortableTree
          treeData={treeData || []}
          onChange={(treeData) => setTreeData(treeData)}
          generateNodeProps={({ node, path }) => ({
            buttons: this.buttons(node, path),
            title: this.title(node, path),
            subtitle: this.subtitle(node, path),
          })}
          dndType={externalNodeType}
          style={{
            height: '100%',
            width: '100%',
          }}
          placeholderRenderer={PlaceholderRenderer}
          isVirtualized={false} // According to https://stackoverflow.com/a/66730488
        />
        <ButtonBar />
      </div>
    );
  }
}
