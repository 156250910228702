import { validateAddSchedule } from 'common/dist/validation/addSchedule';
import { JobGroupTopologyType, JobType } from 'common/dist/types/job';
import { ConfigProps, FormErrors } from 'redux-form';
import { Props } from '../../job-groups/wizard/JobGroupWizard';
import { MessageDescriptor } from 'react-intl';
import { SelectedSchedule } from 'common/src/utils/schedules';

export const newScheduleFormName = 'newSchedule';

export const selectedScheduleField = 'selectedSchedule';
export const jobGroupField = 'jobGroup';
export const fieldDescription = 'description';
export const fieldName = 'name';

export interface JobGroup {
  jobs: JobType[];
  jobGroupTopology: JobGroupTopologyType[];
  name: string;
  description: string;
}

export interface Data {
  [jobGroupField]?: JobGroup;
  [selectedScheduleField]?: SelectedSchedule;
  [fieldName]?: string;
  [fieldDescription]?: string;
}

export type ErrorType = MessageDescriptor;

export type Errors = FormErrors<Data, ErrorType>;

export const newScheduleForm = {
  form: newScheduleFormName,
  validate: validateAddSchedule,
  destroyOnUnmount: false,
  touchOnChange: true,
  /** Set because we don't wait to initialize the form until all calls are done */
  enableReinitialize: true,
};
