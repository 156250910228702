import React from 'react';
import { File, FileTypeWrapper } from '../FileTypeWrapper';
import MarkdownContent from './component/MarkdownContent.container';

export const FileMarkdown: FileTypeWrapper = {
  // eslint-disable-next-line react/display-name
  component: (file: File, paneId: string) => (
    <MarkdownContent
      path={file.path}
      content={file.content}
      unsavedChanges={file.unsavedChanges}
      showCloseConfirm={file.showCloseConfirm}
      paneId={paneId}
    />
  ),
};
