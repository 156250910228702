import React, { Component } from 'react';
import { ToBeRefined } from 'common/dist/types/todo_type';
import MainContainer from '../pages/main-container/MainContainer';
import { fieldModuleType } from './newDatapool.form';
import styles from './styles.module.scss';
import { RouteComponentProps } from 'react-router';
import DefaultNewDatapoolWizard from './defaultWizard/DefaultNewDatapoolWizard';
import BinaryClassificationNewDatapoolWizard from '../modelManagement/modules/implementations/binary-classification/new-datapool/BinaryClassificationNewDatapoolWizard';
import { moduleType as MODULE_TYPE } from 'common/dist/constants/module_list';
import NaturalLanguageTaggingNewDatapoolWizard from '../modelManagement/modules/implementations/natural-language-tagging/new-datapool/NaturalLanguageTaggingNewDatapoolWizard';
import OptimizationNewDatapoolWizard from '../modelManagement/modules/implementations/optimization/new-datapool/OptimizationNewDatapoolWizard';
import qs, { ParsedQs } from 'qs';
import { ModulesState } from '../../store/modules/state.types';

export type RouteParams = {
  habitatCode: string;
};

type Props = {
  /** Is the form currently submitting? (-> disable the Submit button and show a spinner in it)*/
  isSubmitting: boolean;

  /** Selector for the redux-form form values */
  formValueSelector: (fieldName: string) => any;

  /** Clear the new augur wizard form */
  resetForm: () => void;
  /** Fetch the list of available modules */
  fetchModules: () => void;

  /** The available modules */
  modules?: ModulesState;

  /** Redux action to submit the new Datapool */
  addDatapool: (habitatCode: string, datapool: ToBeRefined) => void; // the type of "datapool" depends on the module type that is selected

  /** Code of the habitat the new Datapool is supposed to be added to */
  habitatCode: string;
  /** Name of the habitat */
  habitatName?: string;
};

class NewDatapoolWizard extends Component<
  Props & RouteComponentProps<RouteParams>
> {
  componentDidMount() {
    const { fetchModules, resetForm } = this.props;
    // Make sure the form is initially empty
    resetForm();
    // Fetch all available modules
    fetchModules();
  }

  /**
   *
   */
  renderWizard() {
    const {
      formValueSelector,
      history,
      match: {
        params: { habitatCode },
      },
      habitatName,
      location,
      modules,
      isSubmitting,
      addDatapool,
    } = this.props;

    const moduleType: string = formValueSelector(fieldModuleType);

    let query: ParsedQs = {};
    if (location && location.search) {
      query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
    }

    if (!moduleType) {
      return (
        <DefaultNewDatapoolWizard
          habitatCode={habitatCode}
          habitatName={habitatName}
          modules={modules}
          goToLink={(link: string) => history.push(link)}
        />
      );
    } else {
      switch (moduleType) {
        case MODULE_TYPE.BINARY_NUMERICAL:
          return (
            <BinaryClassificationNewDatapoolWizard
              habitatCode={habitatCode}
              habitatName={habitatName}
              modules={modules}
              isSubmitting={isSubmitting}
              addDatapool={addDatapool}
              goToLink={(link: string) => history.push(link)}
              formValueSelector={formValueSelector}
              query={query}
            />
          );
        case MODULE_TYPE.NATURAL_LANGUAGE_TAGGING:
          return (
            <NaturalLanguageTaggingNewDatapoolWizard
              habitatCode={habitatCode}
              habitatName={habitatName}
              modules={modules}
              isSubmitting={isSubmitting}
              addDatapool={addDatapool}
              goToLink={(link: string) => history.push(link)}
              formValueSelector={formValueSelector}
              query={query}
            />
          );
        case MODULE_TYPE.CAMPAIGN_OPTIMIZATION:
          return (
            <OptimizationNewDatapoolWizard
              habitatCode={habitatCode}
              habitatName={habitatName}
              modules={modules}
              isSubmitting={isSubmitting}
              addDatapool={addDatapool}
              goToLink={(link: string) => history.push(link)}
              formValueSelector={formValueSelector}
              query={query}
            />
          );
        default: {
          return <div>Unexpected module type: {moduleType}</div>;
        }
      }
    }
  }

  render() {
    return (
      <MainContainer fullWidth scrollableY={false}>
        <div className={styles.newDatapoolWizard}>{this.renderWizard()}</div>
      </MainContainer>
    );
  }
}

export default NewDatapoolWizard;
