import React, { FC } from 'react';
import styles from './styles.module.scss';
import Button from '../../atoms/button/Button';
import classNames from 'classnames';
import { CProps } from './NotFound.container';

export type Props = {
  fullViewHeight?: boolean;
};

const NotFound: FC<Props & CProps> = ({
  fullViewHeight,
  mainTabsDefaultLink,
}) => {
  return (
    <div
      className={classNames(styles.notFound, {
        [styles.fullViewHeight]: fullViewHeight,
      })}
    >
      <span className={styles.headline}>404</span>
      <span className={styles.description}>Page Not Found</span>

      <Button
        buttonColor={'primary'}
        withLink
        linkTo={mainTabsDefaultLink}
        buttonLabelId={'no-id'}
        buttonLabelDefault={'Back to Dashboard'}
      />
    </div>
  );
};

export default NotFound;
