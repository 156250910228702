import React, { Component } from 'react';

export interface Props {
  progress: number;
  done: boolean;
}

export default class Progress extends Component<Props> {
  static defaultProps = {
    progress: 0,
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    const { progress, done } = this.props;
    return (
      <div className='progress-bar'>
        <div
          className={'progress ' + (done ? 'done' : '')}
          style={{ width: `${progress}%` }}
        />
      </div>
    );
  }
}
