import { reducer as augur } from './augur';
import { reducer as newDataConnection1 } from './newDataConnection1';

const plugins = {
  newAugur2: augur,
  augurSettings: augur,
  newDataConnection1,
};

export default plugins;
