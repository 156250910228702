import { createAction } from 'redux-act';
import { put, call, select, takeEvery } from 'redux-saga/effects';

import * as Api from '../../core/api';
import * as userSelectors from '../selectors/user.selector';

export const updateProfileInfo = createAction(
  'update profile info',
  (info) => info
);

export const updateProfileInfoSuccess = createAction(
  'update profile info - success'
);

export const updateProfileInfoFail = createAction(
  'update profile info - fail',
  (error) => error
);

export const reducer = {};

export function* updateProfileInfoSaga({ payload: info }) {
  const { response, error } = yield call(Api.accounts.updateInfo, info);

  if (response) {
    yield put(updateProfileInfoSuccess(response));
  } else {
    yield put(updateProfileInfoFail(error));
  }
}

export function* watchUpdateProfileInfo() {
  yield takeEvery(updateProfileInfo.getType(), updateProfileInfoSaga);
}
