import React, { FC } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { resetEventSource } from '../../../../redux/workbench/modules/connection.module';
import MainContainer from '../../../pages/main-container/MainContainer';
import vars from '../../../../../scss/base/var.module.scss';
import styles from './styles.module.scss';

const ServerIsStopping: FC<Record<string, any>> = (props) => {
  const dispatch = useDispatch();
  dispatch(resetEventSource());
  return (
    <MainContainer transparent={true} fullWidth={false}>
      <div className={styles.serverStopping}>
        <div className={styles.innerContainer}>
          <div className={styles.headline}>
            <span>AI Workbench Server Is shutting down...</span>
          </div>
          <div className={styles.spinnerContainer}>
            <ReactLoading type={'cylon'} color={vars.colorPrimary} />
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default ServerIsStopping;
