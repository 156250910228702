import React from 'react';
import P3InputElement, { AsInputElement } from '../_interface/P3InputElement';
import { validateCommonSettings } from '../_interface/input-element.form';
import SelectableScatterPlotElement from './SelectableScatterPlotElement';
import SspSettings from './SspSettings.container';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';
import vars from '../../../../../../../../../../../../scss/base/var.module.scss';

export default class SelectableScatterPlotWrapper extends P3InputElement {
  // --- Elements

  ChildComponent: React.ComponentType = SelectableScatterPlotElement;
  AdditionalSettings: any = SspSettings;
  name = 'Scatter Plot';
  parentContainerClass = 'selectable-scatter-plot';
  type = 'selectable-scatter-plot';
  icon: any = DefaultElementIcon;
  iconColor = vars.colorPrimary;

  // --- Functions

  getSource: (asElement: AsInputElement) => string = (
    asElement: AsInputElement
  ) => {
    if (
      !asElement.settings ||
      !asElement.settings.outputVariable ||
      !asElement.data
    )
      return '';

    const selection = asElement.data.selection;
    return `${asElement.settings.outputVariable.inputValue} = '${JSON.stringify(
      selection
    )}'`;
  };

  /**
   * No validation implemented - every input is valid.
   * @param asElement
   */
  validate: (asElement: AsInputElement, variables: object[]) => string = (
    asElement: AsInputElement,
    variables: object[]
  ) => undefined;

  /**
   * Function to validate the settings in the element modal (label, description, outputVariable, ...)
   * For the NumberInput no additional validation apart from the common settings validation is required
   */
  validateSettings: (values: object, variables: object[]) => object =
    validateCommonSettings;
}
