import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { select } from 'd3-selection';
import { axisLeft } from 'd3-axis';
import { kpiOptionsGrouped } from 'common/dist/constants/keyPerformanceIndicators';

export default class AxisLeft extends Component {
  static propTypes = {
    scale: PropTypes.func.isRequired,
    ticks: PropTypes.number.isRequired,
    tickSize: PropTypes.number.isRequired,
    selectedKpi: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.renderAxis(this.props);
  }

  shouldComponentUpdate(props) {
    this.renderAxis(props);
    return false;
  }

  renderAxis(props) {
    this.renderAxisTicks(props);
    this.renderHelpLines(props);
    this.renderBaseLine(props);
  }

  renderAxisTicks(props) {
    const { scale, ticks, selectedKpi } = props;
    const kpiFormatter = kpiOptionsGrouped[selectedKpi];

    const tickValues = scale.ticks(ticks);
    tickValues.push(0);
    const axis = axisLeft(scale)
      .tickValues(tickValues)
      .tickFormat((x) => kpiFormatter.format(x));

    const axisNode = ReactDOM.findDOMNode(this.refs.ticks);
    select(axisNode).call(axis);
  }

  renderHelpLines(props) {
    const { scale, ticks, tickSize } = props;

    let tickValues = scale.ticks(ticks);
    tickValues = _.without(tickValues, 0);
    const axis = axisLeft(scale).tickValues(tickValues).tickSize(-tickSize);

    const axisNode = ReactDOM.findDOMNode(this.refs.helpLine);
    select(axisNode).call(axis);
  }

  renderBaseLine(props) {
    const { scale, tickSize } = props;

    const tickValues = [];
    tickValues.push(0);
    const axis = axisLeft(scale).tickValues(tickValues).tickSize(-tickSize);

    const axisNode = ReactDOM.findDOMNode(this.refs.baseLine);
    select(axisNode).call(axis);
  }

  render() {
    return (
      <g className='chart-axis'>
        <g className='drift-axis-left__tick' ref='ticks'></g>
        <g className='drift-axis-left__help-line' ref='helpLine'></g>
        <g className='drift-axis-left__base-line' ref='baseLine'></g>
      </g>
    );
  }
}
