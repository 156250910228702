import { connect, ConnectedProps } from 'react-redux';
import HabitatNameAndCodeComponent from './HabitatNameAndCodeComponent';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../../../../../../../../store/store';
import { RouteComponentProps } from 'react-router';

export function mapStateToProps(
  state: RootState,
  { match }: RouteComponentProps<{ habitatCode: string }>
) {
  const habitatCode = match.params.habitatCode;
  const habitatName = state.datapoolDetails?.datapool?.habitatName;

  return {
    habitatCode,
    habitatName,
  };
}

const connector = connect(mapStateToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(HabitatNameAndCodeComponent));
