import React, { FC } from 'react';
import styles from './styles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import AceEditor from 'react-ace';
import InputError from '../../../../atoms/input-error/InputError';

export type Props = {};

const Dictionary: FC<Props & WrappedFieldProps> = (props) => {
  const { meta, input } = props;

  return (
    <div className={styles.dictionaryContainer}>
      <div className={styles.header}>
        <span className={styles.label}>
          Key-Value pairs of your Tagger Dictionary
        </span>
        <InputError touched={meta.touched} error={meta.error} />
      </div>

      <AceEditor
        className={styles.dictionary}
        width={'100%'}
        mode={'json'}
        theme={'tomorrow'}
        onChange={input.onChange}
        onFocus={(e) => input.onFocus(e)}
        onBlur={() => input.onBlur(input.value)}
        value={input.value || ''}
        fontSize={14}
        showGutter
        highlightActiveLine
        setOptions={{
          minLines: 10,
          maxLines: 30,
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          useSoftTabs: false,
        }}
        editorProps={{ $blockScrolling: Infinity }}
      />
    </div>
  );
};

export default Dictionary;
