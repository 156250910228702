import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputElementShape } from '../_interface/P3InputElementShape';
import { getValueFromSettingsField } from '../_interface/input-element.form';

export default class MultipleSelect extends Component {
  render() {
    const { path, cellId, updateDataOfInputElement, element, cell } =
      this.props;

    const onChange = (selected) => {
      updateDataOfInputElement(path, cellId, element, {
        selectedValues: selected,
      });
    };

    // Get the selectable values either from the hard-coded settings dialog, or from an input variable (depending on the 'origin' of the setting)
    const selectableValuesField = element.settings
      ? element.settings.selectableValues
      : undefined;
    const selectableValues = getValueFromSettingsField(
      selectableValuesField,
      cell.as_variables
    );
    if (!selectableValues) {
      return (
        <div
          className={
            'inner-input-element radio-buttons-element no-selectable-values'
          }
        >
          <span>No selectable values defined yet.</span>
        </div>
      );
    }

    const valueExist =
      element.data !== undefined && element.data.selectedValues !== undefined;

    // Get the default value either from the hard-coded settings dialog, or from an input variable (depending on the 'origin' of the setting)
    const defaultValueField = element.settings
      ? element.settings.defaultValue
      : undefined;
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      cell.as_variables
    );

    let options = [];
    // This is a special case for the Multiple Select unfortunately:
    //  When a variable is used, it's a list of Strings.
    //  But the user input comes as a a big string from the text area ...
    if (selectableValuesField.origin === 'variable') {
      // Variable
      options = selectableValues
        ? selectableValues.map((v) => ({ label: v, value: v }))
        : [];
    } else {
      // User input
      options = selectableValues
        ? selectableValues.split('\n').map((v) => ({ label: v, value: v }))
        : [];
    }

    const selectedValues = valueExist
      ? element.data.selectedValues
      : defaultValue;
    return (
      <div className={'inner-input-element multiple-select-element check-box'}>
        {options.map((o) => (
          <div className={'checkbox-item-parent'}>
            <label className={'checkbox-item'}>
              <input
                className={'mark-input'}
                type={'checkbox'}
                value={o.value}
                checked={selectedValues && selectedValues.includes(o.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    // Add the checked element
                    onChange([...(selectedValues || []), o.value]);
                  } else {
                    // Remove the checked element
                    onChange(
                      (selectedValues || []).filter((x) => x !== o.value)
                    );
                  }
                }}
              />
              <span className={'mark'} />
            </label>
            <span className={'name'}>{o.label}</span>
          </div>
        ))}
      </div>
    );
  }
}

MultipleSelect.propTypes = {
  path: PropTypes.string.isRequired,
  cellId: PropTypes.string.isRequired,
  /** Function to update the user input */
  updateDataOfInputElement: PropTypes.func.isRequired,
  /** Input element (including the settings + the user input) */
  element: inputElementShape,
  cell: PropTypes.shape({}).isRequired,
};
