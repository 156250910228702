import React, { Component } from 'react';
import { Motion, spring } from 'react-motion';
import { ToBeRefined } from 'common/dist/types/todo_type';

type Props = {
  item: [number, number];
  xScale: ToBeRefined;
  yScale: ToBeRefined;
  animate?: boolean;
  colorBar: string;
};

export class Bar extends Component<Props> {
  static defaultProps = {
    animate: true,
  };

  renderBar(value: { x?: number; y?: number }) {
    const { xScale, yScale, item, colorBar } = this.props;
    const x = item[0];

    return (
      <rect
        fill={colorBar}
        width={xScale.bandwidth()}
        height={Math.abs(yScale(0) - yScale(value.y))}
        x={xScale(x)}
        y={yScale(value.y)}
      />
    );
  }

  render() {
    const { item, animate } = this.props;
    const y = item[1];

    if (!animate) {
      return this.renderBar({ y });
    }

    return (
      <Motion defaultStyle={{ y: 0 }} style={{ y: spring(y) }}>
        {(value: { x: number; y: number }) => this.renderBar(value)}
      </Motion>
    );
  }
}

export default Bar;
