import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { InjectedFormProps } from 'redux-form';
import { CProps } from './UserDetails.container';

type Props = {};

export type CombinedProps = Props & CProps & InjectedFormProps;

const UserDetails: FC<CombinedProps> = ({ user }) => {
  return (
    <div className={'AccountUserInfo'}>
      <div className={'AccountUserInfo--line'}>
        <div className={'AccountUserInfo--column'}>
          <label className={'AccountUserInfo--label'}>
            <FormattedMessage
              id='account.info.first_name'
              defaultMessage='First Name'
            />
          </label>
          <div className={'AccountUserInfo--value'}>
            <span>{user?.firstName}</span>
          </div>
        </div>

        <div className={'AccountUserInfo--column'}>
          <label className={'AccountUserInfo--label'}>
            <FormattedMessage
              id='account.info.last_name'
              defaultMessage='Last Name'
            />
          </label>
          <div className={'AccountUserInfo--value'}>
            <span>{user?.lastName}</span>
          </div>
        </div>
      </div>

      <div className={'AccountUserInfo--line'}>
        <div className={'AccountUserInfo--column'}>
          <label className={'AccountUserInfo--label'}>
            <FormattedMessage id='account.info.email' defaultMessage='E-Mail' />
          </label>
          <div className={'AccountUserInfo--value'}>
            <span>{user?.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
