import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputElementShape } from '../_interface/P3InputElementShape';
import { getValueFromSettingsField } from '../_interface/input-element.form';
import DropdownSelectInput from '../../../../../../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

export default class DropdownSelect extends Component {
  render() {
    const { path, cellId, updateDataOfInputElement, element, cell } =
      this.props;

    const onChange = (selected) => {
      updateDataOfInputElement(path, cellId, element, {
        selectedValue: selected,
      });
    };

    // Get the selectable values either from the hard-coded settings dialog, or from an input variable (depending on the 'origin' of the setting)
    const selectableValuesField = element.settings
      ? element.settings.selectableValues
      : undefined;
    const selectableValues = getValueFromSettingsField(
      selectableValuesField,
      cell.as_variables
    );
    if (!selectableValues) {
      return (
        <div
          className={
            'inner-input-element dropdown-select-element no-selectable-values'
          }
        >
          <span>No selectable values defined yet.</span>
        </div>
      );
    }

    const valueExist =
      element.data !== undefined && element.data.selectedValue !== undefined;

    // Get the default value either from the hard-coded settings dialog, or from an input variable (depending on the 'origin' of the setting)
    const defaultValueField = element.settings
      ? element.settings.defaultValue
      : undefined;
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      cell.as_variables
    );

    let options = [];
    // This is a special case for the Dropdown Select Settings field unfortunately:
    //  When a variable is used, it's a list of Strings.
    //  But the fixed user input (if it's not coming from a variable) comes as a a big string from the text area ...
    if (selectableValuesField.origin === 'variable') {
      // Variable
      try {
        // Necessary to catch the case that selectableValues doesn't allow to ".map"
        options = selectableValues
          ? selectableValues.map((v) => ({ label: v, value: v }))
          : [];
      } catch (err) {
        // empty
      }
    } else {
      // User input
      options = selectableValues
        ? selectableValues.split('\n').map((v) => ({ label: v, value: v }))
        : [];
    }

    return (
      <div className={'inner-input-element dropdown-select-element'}>
        <DropdownSelectInput
          id={element.id}
          name={element.id}
          options={options}
          placeholder={{ id: 'no-id', defaultMessage: 'Please select a value' }}
          value={options.find(
            (o) =>
              o.value ===
              (valueExist ? element.data.selectedValue : defaultValue)
          )}
          onChange={(option) => onChange(option.value)}
          clearable={false}
        />
      </div>
    );
  }
}

DropdownSelect.propTypes = {
  path: PropTypes.string.isRequired,
  cellId: PropTypes.string.isRequired,
  /** Function to update the user input */
  updateDataOfInputElement: PropTypes.func.isRequired,
  /** Input element (including the settings + the user input) */
  element: inputElementShape,
  cell: PropTypes.shape({}).isRequired,
};
