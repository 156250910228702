import { VARIABLE_TYPES } from '../variableTypes';
import { BaseVariableHandler, getFirstRaw, isArray } from './helper';
import { handleJson } from './JsonVariableHandler';

export const ListOfStringVariableHandler: BaseVariableHandler<string> = {
  type: VARIABLE_TYPES.LIST_OF_STRING,
  displayName: 'List of String',
  getExecutionCode: (inputValue: string) => inputValue,
  parseValue: (variable, rawValues) => {
    const firstRaw = getFirstRaw(variable, rawValues);
    if (!firstRaw) return { parsedValue: undefined, error: undefined };
    const { parsedValue, error } = handleJson(variable, rawValues, firstRaw);
    if (error)
      return { parsedValue: undefined, error: "Couldn't parse List of String" };

    // Validate the parsed value
    try {
      if (!isArray(parsedValue))
        return { parsedValue: undefined, error: 'Input was no list' };
      const stringifiedArray = parsedValue.map((x) => x.toString());
      return { parsedValue: stringifiedArray, error: undefined };
    } catch (e) {
      return { parsedValue: undefined, error: "Couldn't parse List of String" };
    }
  },
};
