import PropTypes from 'prop-types';
import React from 'react';
import { barHeight, maxWidth } from '../../influencers/barChart/Bar';

export default function ScoreBar(props) {
  const { x, y, width, height, score } = props;
  const filledWidth = score * width;

  const adjustedBarHeight =
    filledWidth >= height / 2 ? height : 2 * filledWidth; // if the bar is smaller than height/2 the height needs to be adjusted.
  const adjustedY = filledWidth >= height / 2 ? 0 : height / 2 - filledWidth;

  return (
    <div className={'score-bar'}>
      <svg width={width} height={height}>
        <rect
          className='score-bar_background-bar'
          x={x}
          y={y}
          width={width}
          height={height}
          rx={height / 2}
        />
        <rect
          className='score-bar_bar'
          x={0}
          y={adjustedY}
          width={filledWidth}
          height={adjustedBarHeight}
          rx={barHeight / 2}
        />
      </svg>
    </div>
  );
}

ScoreBar.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
};
ScoreBar.defaultProps = {
  x: 0,
  y: 0,
};
