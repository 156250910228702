import { NodeInfo } from '../../../core/api/codeCapsules';

export const extractGpuProductOptions = (nodeInfo: NodeInfo) => {
  return (nodeInfo?.nodes || [])
    .map((node) => ({
      value: node.resources.gpu.model,
      label: node.resources.gpu.model,
    }))
    .filter((x) => !!x.value);
};

export const isGpuAvailable = (nodeInfo: NodeInfo) => {
  return (
    (nodeInfo?.nodes || []).find((n) => n.resources.gpu.allocatable > 0) !==
    undefined
  );
};
