import { connect } from 'react-redux';
import TableBrowser from './TableBrowser';
import { withRouter } from 'react-router-dom';
import { fetchPreview } from '../../../../redux/modules/data.s3.module';
import {
  CassandraDataPreview,
  ParquetPreview,
} from '../../../../store/dataManagement/state.types';

function avroTypeToString(t): string {
  let res = t;
  if (t instanceof Array) {
    res = t.filter((x) => x !== 'null');
    if (res.length === 1) res = res[0];
  }
  if (typeof res === 'string' || res instanceof String) return res as string;
  else return JSON.stringify(res);
}

/**
 * Transform a ParquetPreview into a CassandraDataPreview, since most methods already work with that
 * @param parquetPreview
 */
export function parquetPreviewToCassandraDataPreview(
  parquetPreview: ParquetPreview
): CassandraDataPreview {
  const colSpecs = (parquetPreview.schema?.fields || []).map((field) => ({
    colName: field.name,
    colType: avroTypeToString(field.type),
  }));

  const data = (parquetPreview.data || []).map((row) =>
    colSpecs
      .map((colSpec) => row[colSpec.colName])
      .map((value) => String(value))
  );

  return {
    colSpecs,
    data,
  };
}

export function mapStateToProps(state, { dataSourceCode, match }) {
  const path = match.params.path || '';
  const bucket = match.params.bucket || '';
  const { loading, loaded, error, data } =
    ((state.data.s3[dataSourceCode] || {}).preview || {})[
      `${bucket}/${path}`
    ] || {};
  return {
    dataSourceCode,
    bucket,
    path,
    loading,
    loaded,
    error,
    data:
      data !== undefined
        ? parquetPreviewToCassandraDataPreview(data)
        : undefined,
  };
}

export const mapDispatchToProps = {
  fetchPreview,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableBrowser)
);
