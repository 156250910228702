import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StartServer from './start-server/StartServer';
import WorkbenchMainContainer from './workbench-main/WorkbenchMainContainer.container';
import Busy from '../../atoms/busy/Busy';
import ServerIsStopping from './server-is-stopping/ServerIsStopping';
import ReloginRequired from './relogin-required/ReloginRequired';
import MainContainer from '../../pages/main-container/MainContainer';
import NoPermissions from '../../pages/no-permissions/NoPermissions';
import ServerError from './server-error/ServerError';

/**
 * This component is the central entrypoint and contains the navbar, the content view on the right
 * as well as the notebooks on the right.
 */
export default class Workbench extends Component {
  interval = undefined;

  componentDidMount() {
    const { checkWhetherNotebookIsRunning, notebookUser } = this.props;
    checkWhetherNotebookIsRunning(notebookUser, false);

    this.interval = setInterval(
      () => checkWhetherNotebookIsRunning(notebookUser, false), // Check without retries
      10000
    );
  }

  componentWillUnmount() {
    if (this.interval) window.clearInterval(this.interval);
  }

  /**
   * Render the loading screen (that the status of the notebook is currently requested)
   * @returns {*}
   */
  renderLoading() {
    return <Busy isBusy positionAbsolute />;
  }

  /**
   * Render the workbench window (notebook is running)
   * @returns {*}
   */
  renderRunning() {
    return <WorkbenchMainContainer />;
  }

  /**
   * Render the "Start Workbench Server" button (notebook is not running)
   */
  renderNotRunning() {
    return <StartServer />;
  }

  renderShuttingDown() {
    return <ServerIsStopping />;
  }

  renderError() {
    const { error } = this.props;
    return <ServerError error={error} />;
  }

  renderReloginRequired() {
    return <ReloginRequired />;
  }

  render() {
    const {
      loading,
      loaded,
      error,
      missingPermissionsError,
      isRunning,
      isStopping,
      reloginRequired,
    } = this.props;
    if (reloginRequired) return this.renderReloginRequired();
    else if (missingPermissionsError) return <NoPermissions fullViewHeight />;
    else if (error) return this.renderError();
    else if (isRunning) return this.renderRunning();
    else if (isStopping) return this.renderShuttingDown();
    else if (loaded && !isRunning) return this.renderNotRunning();
    else if (loading) return this.renderLoading();
    return <div />; // This case should never occur
  }
}

Workbench.propTypes = {
  checkWhetherNotebookIsRunning: PropTypes.func.isRequired,
  // True if the check whether the notebook is running is currently loading
  loading: PropTypes.bool,
  // True if the check whether the notebook is running is done
  loaded: PropTypes.bool,
  // Is the notebook for the logged in user running?
  isRunning: PropTypes.bool,
  // Filled if an error occured during the check whether the notebook is running
  error: PropTypes.string,
  // Missing permissions for this page? -> Render the 403 page.
  missingPermissionsError: PropTypes.bool,
  // Rather rare case, but a re-login of the user into the AltaSigma platform is required.
  reloginRequired: PropTypes.bool,
};
