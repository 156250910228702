export const fieldCommitMessage = 'git-commit-message';

export const gitCommitForm = {
  form: 'git-commit-form',
  fieldCommitMessage,
  validate: (values) => {
    let errors = {};

    // --- Validate commit message
    const commitMessage = values[fieldCommitMessage];
    if (!commitMessage || commitMessage.trim() === '') {
      errors = {
        ...errors,
        [fieldCommitMessage]: 'Please enter a commit message',
      };
    }

    return errors;
  },
};
