import React, { FC } from 'react';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { customerTableForm, fieldCustomerTable } from './form';
import { Field, reduxForm } from 'redux-form';
import CancelSubmitButtonsWrapper from '../../../../../molecules/reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import OptCustomerTable from '../../../../../organisms/modelManagement/newAugurWizard/optCustomerTable/OptCustomerTable';
import WithTableSample from '../../../../../organisms/modelManagement/newAugurWizard/commonComponents/withTableSample/WithTableSample.container';
import TablePreview from '../../../../../organisms/modelManagement/newAugurWizard/commonComponents/tablePreview/TablePreview.container';
import { customerTableColumnHighlights } from '../../../implementations/optimization/new-augur/OptimizationNewAugurWizard';
import { DatapoolType } from 'common/dist/types/datapool';
import { AugurSettingsData } from 'common/dist/types/augurSettings';
import { CustomerTableValueType } from 'common/dist/types/augurSettings.opt';

type Props = {
  initialValues: {
    [fieldCustomerTable]: CustomerTableValueType;
  };
  saveSettings: (settings: Partial<AugurSettingsData>, merge: boolean) => void;
  habitatCode: string;
  datapool: DatapoolType | undefined;
  /** Selector for the redux-form form values */
  valueSelector: (fieldName: string) => any;
};

const customerTableComponent = ({ input, meta, datapool }) => {
  if (!datapool) return null;

  return (
    <WithTableSample datapool={datapool} tableName={input.value?.tableName}>
      {/* @ts-ignore */}
      <OptCustomerTable input={input} meta={meta} />
    </WithTableSample>
  );
};

const CustomerTable: FC<Props & InjectedFormProps<FormValueType>> = (props) => {
  const {
    pristine,
    reset,
    submitting,
    handleSubmit,
    saveSettings,
    datapool,
    valueSelector,
    valid,
  } = props;

  return (
    <div className={styles.optCustomerTable}>
      <FormattedMessage
        {...moduleOptimizationMessages.msgAugurDetailsCustomerTableTabName}
      >
        {(text) => <span className={styles.headline}>{text}</span>}
      </FormattedMessage>
      {/* @ts-ignore */}
      <CancelSubmitButtonsWrapper
        disabled={pristine || submitting || !valid}
        savingButtonBusy={submitting}
        onCancel={reset}
        onSubmit={handleSubmit((values) => {
          saveSettings(
            {
              customerTable: values[fieldCustomerTable],
            },
            true
          );
        })}
      >
        <Field
          name={fieldCustomerTable}
          component={customerTableComponent}
          datapoolCode={datapool?.code}
        />

        <div className={styles.tablePreview}>
          <WithTableSample
            // @ts-ignore TODO
            datapool={datapool}
            tableName={valueSelector(fieldCustomerTable)?.tableName}
          >
            {/* @ts-ignore */}
            <TablePreview
              // @ts-ignore TODO
              datapoolCode={datapool?.code}
              tableName={valueSelector(fieldCustomerTable)?.tableName}
              columnHighlights={customerTableColumnHighlights(
                valueSelector(fieldCustomerTable)
              )}
              fullHeight={true}
            />
          </WithTableSample>
        </div>
      </CancelSubmitButtonsWrapper>
    </div>
  );
};

type FormValueType = {
  [fieldCustomerTable]: CustomerTableValueType;
};
export default reduxForm<FormValueType>(customerTableForm)(CustomerTable);
