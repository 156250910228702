import { connect } from 'react-redux';

import {
  updateCurrentBreakpoint,
  updateLayouts,
  updateUnlayoutedElements,
} from '../../../../redux/workbench/modules/app.module';
import InputOrOutputStep from './InputOrOutputStep';

export function mapStateToProps(state) {
  return {
    currentBreakpoint: state.workbench.app.currentBreakpoint,
  };
}

export const mapDispatchToProps = {
  updateLayouts,
  updateUnlayoutedElements,
  updateCurrentBreakpoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(InputOrOutputStep);
