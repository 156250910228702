import { connect } from 'react-redux';
import P3OutputElementParent from './P3OutputElementParent';
import {
  removeOutputElement,
  showEditOutputElement,
  hideEditOutputElement,
  updateDataOfOutputElement,
} from '../../../../../../../../../../../redux/workbench/modules/cells.app.module';

export function mapStateToProps(state) {
  return {
    editOutputElementModal: state.workbench.editOutputElementModal,
  };
}

export const mapDispatchToProps = {
  removeOutputElement,
  showEditOutputElement,
  hideEditOutputElement,
  updateDataOfOutputElement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(P3OutputElementParent);
