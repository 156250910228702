import { ComponentType } from 'react';
import NotFound from '../pages/not-found/NotFound.container';
import { getAllDatapoolDetailsRoutes } from '../modelManagement/modules/ModuleManagerClient';
import { RouteConfig } from 'react-router-config';
import { DatapoolType } from 'common/dist/types/datapool';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';

export default function datapoolRoutes(
  datapool: DatapoolType,
  dataSource?: DataSource
): RouteConfig[] {
  const data: RouteConfig[] = [
    ...getAllDatapoolDetailsRoutes(datapool, dataSource).map((r) => ({
      ...r,
      exact: true,
    })),
  ];

  data.push({
    path: '*', // This is the last entry, so it will fall back to this
    component: NotFound as ComponentType,
  });

  return data;
}
