import { createAction } from 'redux-act';
import { put, call, select, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const fetchLatestDatapoolStatistics = createAction(
  'fetch latest datapool statistics',
  (habitatCode, datapoolCode, tableName) => ({
    habitatCode,
    datapoolCode,
    tableName,
  })
);

export const fetchLatestDatapoolStatisticsSuccess = createAction(
  'fetch latest datapool statistics - success',
  (data, datapoolCode, tableName) => ({ data, datapoolCode, tableName })
);

export const fetchLatestDatapoolStatisticsFailure = createAction(
  'fetch latest datapool statistics - failure',
  (error, datapoolCode, tableName) => ({ error, datapoolCode, tableName })
);

export const reducer = {
  [fetchLatestDatapoolStatistics](state, { datapoolCode, tableName }) {
    // state.datapoolStatistics.<datapoolCode>.<tableName>.data for the data and
    // state.datapoolStatistics.<datapoolCode>.tableNames for a list of all available tables
    const safeDatapoolStatistics = state.datapoolStatistics
      ? state.datapoolStatistics
      : {};
    const safeDatapoolCode = safeDatapoolStatistics[datapoolCode]
      ? safeDatapoolStatistics[datapoolCode]
      : {};
    const safeTableName = safeDatapoolCode[tableName]
      ? safeDatapoolCode[tableName]
      : {};

    return {
      ...state,
      datapoolStatistics: {
        ...safeDatapoolStatistics,
        [datapoolCode]: {
          ...safeDatapoolCode,
          [tableName]: {
            ...safeTableName,
            loading: true,
          },
        },
      },
    };
  },
  [fetchLatestDatapoolStatisticsSuccess](
    state,
    { data, datapoolCode, tableName }
  ) {
    const safeDatapoolStatistics = state.datapoolStatistics
      ? state.datapoolStatistics
      : {};
    const safeDatapoolCode = safeDatapoolStatistics[datapoolCode]
      ? safeDatapoolStatistics[datapoolCode]
      : {};
    const safeTableName = safeDatapoolCode[tableName]
      ? safeDatapoolCode[tableName]
      : {};
    const safeTableNames = safeDatapoolCode.tableNames
      ? safeDatapoolCode.tableNames
      : [];

    return {
      ...state,
      datapoolStatistics: {
        ...safeDatapoolStatistics,
        [datapoolCode]: {
          ...safeDatapoolCode,
          [tableName]: {
            ...safeTableName,
            data,
            loading: false,
            loaded: true,
            error: null,
          },
          tableNames: [...safeTableNames, tableName].filter(onlyUnique),
        },
      },
    };
  },
  [fetchLatestDatapoolStatisticsFailure](
    state,
    { error, datapoolCode, tableName }
  ) {
    const safeDatapoolStatistics = state.datapoolStatistics
      ? state.datapoolStatistics
      : {};
    const safeDatapoolCode = safeDatapoolStatistics[datapoolCode]
      ? safeDatapoolStatistics[datapoolCode]
      : {};
    const safeTableName = safeDatapoolCode[tableName]
      ? safeDatapoolCode[tableName]
      : {};

    return {
      ...state,
      datapoolStatistics: {
        ...safeDatapoolStatistics,
        [datapoolCode]: {
          ...safeDatapoolCode,
          [tableName]: {
            loading: false,
            loaded: false,
            error,
          },
        },
      },
    };
  },
};

export function* fetchLatestDatapoolStatisticsSaga({
  payload: { habitatCode, datapoolCode, tableName },
}) {
  const { response, error } = yield call(
    Api.datapoolStatistics.fetchLatestDatapoolStatistics,
    habitatCode,
    datapoolCode,
    tableName
  );

  if (response) {
    yield put(
      fetchLatestDatapoolStatisticsSuccess(response, datapoolCode, tableName)
    );
  } else {
    yield put(
      fetchLatestDatapoolStatisticsFailure(error, datapoolCode, tableName)
    );
  }
}

export function* watchFetchLatestDatapoolStatisticsSaga() {
  yield takeEvery(
    fetchLatestDatapoolStatistics.getType(),
    fetchLatestDatapoolStatisticsSaga
  );
}
