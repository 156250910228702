import { InputFormatIdType } from 'common/dist/types/datapool';
import message from 'common/dist/messages/datapools';

export function validateInputFormat(inputFormat?: InputFormatIdType) {
  if (!inputFormat) {
    return {
      id: message.msgDatapoolMissingInputFormat.id,
      defaultMessage: message.msgDatapoolMissingInputFormat.defaultMessage,
    };
  }

  return undefined;
}
