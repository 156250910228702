import React, { Component, ComponentType } from 'react';
import { MessageDescriptor } from 'react-intl';
import { TabLink } from 'react-tabs-redux';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import _ from 'lodash';

export type MainTabIdType =
  | 'models'
  | 'apps'
  | 'workbench'
  | 'collab'
  | 'orchestration'
  | 'data';

export type MainTabType = {
  /** ID of the main tab */
  id: MainTabIdType;
  /** URL path of the main tab */
  path: string;
  /** Optional callback for when the main tab is selected */
  onSelect?: () => void;
  /** Title of the main tab */
  title: MessageDescriptor;
  /** Icon that is displayed instead of the text for narrow screens (responsiveness) */
  Icon: ComponentType;
};

type Props = MainTabType & {
  isActive: boolean;
  history: {
    push: (path: string) => void;
  };
  handleMainTabSelect: (mainTabId: string) => void;

  /** The tab component measures itself after mounting.
   * Then the width is reported to the parent component via this callback. */
  onMeasure: (mainTabId: string, width: number) => void;
};

export default class MainTab extends Component<Props, {}> {
  tabRef = undefined;

  constructor(props: Props) {
    super(props);
    this.tabRef = React.createRef();
    this.measureComponent = this.measureComponent.bind(this);
  }

  componentDidMount() {
    this.measureComponent();
    window.addEventListener('resize', this.measureComponent);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.measureComponent);
  }

  measureComponent = _.throttle(
    () => {
      const { id, onMeasure } = this.props;
      setTimeout(() => {
        const width = this.tabRef.current?.getBoundingClientRect()?.width;
        onMeasure(id, width);
      }, 1);
    },
    1000,
    { trailing: true, leading: true }
  );

  render() {
    const {
      id,
      path,
      isActive,
      history,
      handleMainTabSelect,
      onSelect,
      title,
      Icon,
    } = this.props;

    return (
      <TabLink
        className={classNames(styles.mainTabLink, {
          [styles.mainTabLinkActive]: isActive,
        })}
        isActive={isActive}
        handleSelect={(mainTabId) => {
          history.push(path);
          handleMainTabSelect(mainTabId);
          onSelect && onSelect();
        }}
        to={id}
      >
        <div ref={this.tabRef}>
          <div className={styles.mainTabText}>
            <div className={styles.mainTabTitle}>
              <FormattedMessage
                id={title.id}
                defaultMessage={title.defaultMessage}
              />
            </div>
          </div>
          <div className={styles.mainTabIcon}>{Icon && <Icon />}</div>
        </div>
      </TabLink>
    );
  }
}
