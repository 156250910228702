import React, { FC } from 'react';
import ConfirmationModal from '../../confirmation-modal/ConfirmationModal';
import modalMsgs from 'common/dist/messages/modal';

export type Props = {
  currentUserId: string;
  shutdownWorkbenchModalVisible: boolean;
  hideShutdownWorkbenchModal: () => void;
  stopWorkbench(userId: string): void;
  checkWhetherNotebookIsRunning(userId: string, retry: boolean): void;
};

const ShutdownWorkbenchModal: FC<Props> = ({
  currentUserId,
  shutdownWorkbenchModalVisible,
  hideShutdownWorkbenchModal,
  stopWorkbench,
  checkWhetherNotebookIsRunning,
}) => {
  return (
    <ConfirmationModal
      show={shutdownWorkbenchModalVisible}
      secure={false}
      payload={currentUserId}
      hideModal={hideShutdownWorkbenchModal}
      buttonConfirmAction={(userId: string) => {
        stopWorkbench(userId);
        checkWhetherNotebookIsRunning(userId, false);
      }}
      buttonConfirmText={modalMsgs.msgModalShutdownConfirm}
      buttonConfirmColor={'red'}
      description={modalMsgs.msgModalShutdownWorkbenchDescription}
      headline={modalMsgs.msgModalShutdownWorkbenchHeadline}
      headlineColor={'red'}
    />
  );
};

export default ShutdownWorkbenchModal;
