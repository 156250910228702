import { connect, ConnectedProps } from 'react-redux';
import WizardStepFieldComponent from './WizardStepFieldComponent';
import { touch } from 'redux-form';

const mapDispatchToProps = {
  touch,
};

const connector = connect(null, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(WizardStepFieldComponent);
