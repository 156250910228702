import React, { Component } from 'react';
import Modal from '../../../../../../../../organisms/modal/Modal';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { allOutputElements } from './elements/_interface/Python3OutputElementManager';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

type Props = {
  isOpen: boolean;
  closeDialog: () => void;
  path: string;
  cellId: string;
  addOutputElement: (
    path: string,
    cellId: string,
    element: ToBeRefined,
    elementId: string
  ) => void;
  toggleOutputElements: (path: string, cellId: string, expand: boolean) => void;
};

const defaultElement = (type) => ({
  type,
  data: {},
  label: 'Enter label',
  description: 'Enter description',
});

export default class OutputElementSelectModal extends Component<Props, {}> {
  render() {
    const {
      isOpen,
      closeDialog,
      path,
      cellId,
      addOutputElement,
      toggleOutputElements,
    } = this.props;
    return (
      <Modal show={isOpen} hideModal={closeDialog}>
        <div className={'modal-select-elements'}>
          {allOutputElements.map((e) => (
            <div
              className={'selectable-element'}
              onClick={() => {
                const elementId = uuidv4();
                const element = { ...defaultElement(e.type), id: elementId };
                addOutputElement(path, cellId, element, elementId);
                toggleOutputElements(path, cellId, true); // Make sure the input elements are expanded (otherwise it's bad usability: it feels like no element is added)
                closeDialog();
                if (e.hasSettings) {
                  // showEditOutputElement(element); // TODO Enable (and probably add the action first)
                }
              }}
            >
              <div className={'element-icon'}>
                <e.icon
                  width={24}
                  height={24}
                  elementName={e.name}
                  elementType={e.type}
                  iconColor={e.iconColor}
                />
              </div>
              <p className={'element-name'}>{e.name}</p>
            </div>
          ))}
        </div>
      </Modal>
    );
  }
}
