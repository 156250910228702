import { connect } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import Channels from './Channels';
import { updateDatapool } from '../../../../../../redux/modules/datapool.details.module';
import { fieldChannels } from './form';

export function mapStateToProps(state: RootState) {
  const habitatCode = state.datapoolDetails?.datapool?.habitatCode;
  const datapoolCode = state.datapoolDetails?.datapool?.code;

  return {
    initialValues: {
      [fieldChannels]: state.datapoolDetails?.datapool?.meta?.channels || [],
    },
    habitatCode,
    datapoolCode,
    communications: state.datapoolDetails?.datapool?.meta?.communications || [],
  };
}

export const mapDispatchToProps = {
  updateDatapool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Channels);
