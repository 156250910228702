import { ComponentType } from 'react';
import { ComponentDatapoolDetailsTab } from '../../_interfaces/datapool-details/DatapoolDetailsTab';
import Channels from './Channels.container';
import { DatapoolType } from 'common/dist/types/datapool';
import { FiPhoneForwarded } from 'react-icons/all';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';

export default class OptChannelsWrapper extends ComponentDatapoolDetailsTab {
  type: 'component' = 'component';
  id = 'channels';
  title = moduleOptimizationMessages.msgDatapoolDetailsChannelsTabName;
  to = (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${shortDatapoolType}/channels`;

  icon: ComponentType = () => <FiPhoneForwarded size={16} />;
  component: ComponentType<any> = Channels;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType): boolean => true;
}
