import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import {
  ExecutionState,
  Session,
} from '../../../../../../store/workbench/state.types';
import { cleanDisplayName } from '../../../../part-right/wizards/notebook/ui/kernels/KernelSpec';
import { JupyterNotebookFormat } from 'common/dist/utils/workbench/jupyterNotebookFormat';

export interface Props {
  kernelspec: JupyterNotebookFormat['metadata']['kernelspec'];
  session?: Session;
}

export default class Metadata extends Component<Props> {
  kernelStatus() {
    const kernel = this.props.session?.kernel;
    if (
      kernel === undefined ||
      [ExecutionState.Unknown, ExecutionState.NotFound].includes(
        kernel?.execution_state
      )
    ) {
      return <div className={'status-circle no'} />;
    }
    if (kernel.execution_state === ExecutionState.Busy) {
      return (
        <ReactLoading className={'busy'} type={'bubbles'} color={'black'} />
      );
    }
    if (kernel.execution_state === ExecutionState.Idle) {
      return <div className={'status-circle idle'} />;
    }
    if (kernel.execution_state === ExecutionState.Starting) {
      return <div className={'status-circle starting'} />;
    }
  }

  render() {
    return (
      <div className={'kernel-display-name-container'} title={'Kernel'}>
        {this.props.kernelspec && (
          //  display_name should not be empty, but you never know when reading directly from the notebook
          <p>{cleanDisplayName(this.props.kernelspec.display_name || '')}</p>
        )}
        <div className={'kernel-status'}>{this.kernelStatus()}</div>
      </div>
    );
  }
}
