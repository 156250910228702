import React, { Component } from 'react';
import { FiRotateCw } from 'react-icons/fi';
import Busy from '../../../atoms/busy/Busy';
import NotebookCells from '../../../workbench/fileTypes/notebook/component/notebook-content/NotebookCells';
import IconButton from '../../../atoms/icon-button/IconButton';
import IndicatorEmpty from '../../../molecules/indicator-empty/IndicatorEmpty';
import { withRouter } from 'react-router-dom';
import qs, { ParsedQs } from 'qs';
import BreadCrumbs from '../../../atoms/bread-crumbs/BreadCrumbs';
import { originName, OriginsType } from '../../links';
import { orchestrationRoutes } from '../../routes';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import orchMsg from 'common/dist/messages/orchestration';
import {
  FormattedDateTime,
  FormattedDuration,
} from '../../../atoms/formatted-date-time/FormattedDateTime';
import { timeDiffAll } from 'common/dist/utils/time';
import { CProps } from './CodeCapsuleOutput.container';
import { NotebookStatus } from 'common/dist/types/codeCapsule';
import { AltaSigmaCell } from '../../../../store/workbench/state.types';
import commonMessages from 'common/dist/messages/common';
import { RouteComponentProps } from 'react-router';
import ThemedSwitch from '../../../atoms/themed-switch/ThemedSwitch';

interface Props {
  isOutputModalShown: boolean;
  loading?: boolean;
  loaded?: boolean;
  error?: ApiError;
  output?: {
    createdAt: string;
    notebookOutput?: {
      cells?: AltaSigmaCell[];
      metadata?: Record<string, unknown>;
    };
  };
  match?: {
    params?: {
      jobCode: string;
    };
  };
  location?: {
    search: string;
  };
  /** Contains the id of the site from which it was opened (to show the "<< Return to" button) */
  origin?: OriginsType;
}

type State = {
  autoRefreshEnabled: boolean;
};

export interface QParams extends ParsedQs {
  notebookName?: string;
}

class CodeCapsuleOutput extends Component<
  Props & CProps & WrappedComponentProps & RouteComponentProps,
  State
> {
  private poller: ReturnType<typeof setTimeout>;

  constructor(props) {
    super(props);
    this.state = {
      autoRefreshEnabled: false,
    };
    this.renderNotebook = this.renderNotebook.bind(this);
    this.renderNotebookLoading = this.renderNotebookLoading.bind(this);
    this.renderNotebookLoaded = this.renderNotebookLoaded.bind(this);
    this.renderNotebookError = this.renderNotebookError.bind(this);
  }

  renderNotebook() {
    const { loading, loaded, error, output } = this.props;
    if (output && loaded) return this.renderNotebookLoaded();
    else if (loading) return this.renderNotebookLoading();
    else if (error) return this.renderNotebookError();
    else return <div />;
  }

  renderNotebookLoading() {
    return (
      <div className={styles.loadingContainer}>
        <Busy isBusy />
      </div>
    );
  }

  renderNotebookError() {
    const { error } = this.props;
    if (error.status === 404) {
      // TODO is "=== 404" really correct here?
      return (
        <IndicatorEmpty
          classNameImage={'wait-for-augur-pic'}
          headlineId={'todo'}
          headlineDefault={'Notebook is not available'}
          descriptionId={'todo'}
          descriptionDefault={error.formattedMessage.values.message}
        />
      );
    } else {
      return <div>{JSON.stringify(error)}</div>;
    }
  }

  renderNotebookLoaded() {
    const { output } = this.props;
    if (output && output.notebookOutput) {
      return <NotebookCells content={output.notebookOutput} readOnly={true} />;
    } else {
      return <div />;
    }
  }

  componentDidMount() {
    const {
      fetchCodeCapsuleOutput,
      match: {
        params: { jobCode },
      },
      location: { search },
    } = this.props;
    const { notebookName }: QParams =
      qs.parse(search, { ignoreQueryPrefix: true }) || {};

    if (jobCode && notebookName) {
      fetchCodeCapsuleOutput(jobCode, notebookName);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.autoRefreshEnabled !== this.state.autoRefreshEnabled) {
      const {
        fetchCodeCapsuleOutput,
        match: {
          params: { jobCode },
        },
        location: { search },
      } = this.props;
      const { notebookName }: QParams =
        qs.parse(search, { ignoreQueryPrefix: true }) || {};
      if (this.state.autoRefreshEnabled) {
        fetchCodeCapsuleOutput(jobCode, notebookName);
        this.poller = setInterval(
          () => fetchCodeCapsuleOutput(jobCode, notebookName),
          10000
        );
      } else clearInterval(this.poller);
    }
  }

  componentWillUnmount() {
    if (this.poller) {
      clearInterval(this.poller);
    }
  }

  render() {
    const {
      loading,
      output,
      fetchCodeCapsuleOutput,
      match: {
        params: { jobCode },
      },
      location: { search },
      origin,
      status,
      intl,
    } = this.props;
    const { notebookName }: QParams =
      qs.parse(search, { ignoreQueryPrefix: true }) || {};
    return (
      <div
        className={classNames(
          'Orchestration--content Orchestration--JobDetails',
          styles.orchestrationCCOutput
        )}
      >
        <div className={styles.codeCapsuleOutputParent}>
          <div className={styles.jobDetailsHeader}>
            <div className={styles.backButtonContainer}>
              <BreadCrumbs
                backToProps={[
                  {
                    linkTo: origin,
                    label: intl.formatMessage(commonMessages.backTo, {
                      origin: intl.formatMessage(originName(origin)),
                    }),
                  },
                  {
                    linkTo: `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/${jobCode}`,
                    label: intl.formatMessage(commonMessages.backTo, {
                      origin: intl.formatMessage(orchMsg.jobDetailsTitle),
                    }),
                  },
                ]}
              />
            </div>

            <FormattedMessage
              id={orchMsg.msgCodeCapsuleOutputToggle.id}
              defaultMessage={orchMsg.msgCodeCapsuleOutputToggle.defaultMessage}
            >
              {(defaultMessage) => (
                <span className={styles.switchDescription}>
                  {defaultMessage}
                </span>
              )}
            </FormattedMessage>
            <ThemedSwitch
              onChange={(checked) =>
                this.setState({ autoRefreshEnabled: checked })
              }
              checked={this.state.autoRefreshEnabled}
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
              activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
              themeColor={'primary'}
            />
            <div className={styles.topButtonContainer}>
              <div className={styles.refreshIconContainer}>
                {loading ? (
                  <div className={styles.refreshIconSpinningParent}>
                    <FiRotateCw
                      size={16}
                      className={styles.refreshIconSpinning}
                    />
                  </div>
                ) : (
                  <IconButton
                    Icon={() => (
                      <FiRotateCw size={16} className={'refresh-icon'} />
                    )}
                    onClick={() => {
                      fetchCodeCapsuleOutput(jobCode, notebookName);
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={styles.notebookTitleContainer}>
            <div className={styles.iconParent}>
              <span
                className={classNames(styles.icon, {
                  [styles.success]: status === NotebookStatus.SUCCESS,
                  [styles.failure]: status === NotebookStatus.FAILURE,
                  [styles.inProgress]: status === NotebookStatus.IN_PROGRESS,
                  [styles.waiting]: ![
                    NotebookStatus.SUCCESS,
                    NotebookStatus.FAILURE,
                    NotebookStatus.IN_PROGRESS,
                  ].includes(status),
                })}
              />
            </div>
            <span className={styles.notebookTitle}>{notebookName}</span>
            {status && (
              <span className={styles.runningTimestamp}>
                {status === NotebookStatus.IN_PROGRESS ? (
                  <FormattedMessage
                    {...orchMsg.msgCodeCapsuleOutputTimeInProgress}
                    values={{
                      started: (
                        <FormattedDateTime
                          date={
                            output.notebookOutput.metadata.papermill.start_time
                          }
                        />
                      ),
                      status: <FormattedDateTime date={output.createdAt} />,
                      duration: (
                        <FormattedDuration
                          // @ts-ignore TODO why? They are the same import for FormatNumberOptions - Different versions in frontend and commons?
                          formatList={timeDiffAll(
                            new Date(output.createdAt),
                            new Date(
                              output.notebookOutput.metadata.papermill.start_time
                            )
                          )}
                        />
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    {...orchMsg.msgCodeCapsuleOutputTimeFinished}
                    values={{
                      started: (
                        <FormattedDateTime
                          date={
                            output.notebookOutput.metadata.papermill.start_time
                          }
                        />
                      ),
                      ended: (
                        <FormattedDateTime
                          date={
                            output.notebookOutput.metadata.papermill.end_time
                          }
                        />
                      ),
                      duration: (
                        <FormattedDuration
                          // @ts-ignore TODO why? They are the same import for FormatNumberOptions - Different versions in frontend and commons?
                          formatList={timeDiffAll(
                            new Date(
                              output.notebookOutput.metadata.papermill.end_time
                            ),
                            new Date(
                              output.notebookOutput.metadata.papermill.start_time
                            )
                          )}
                        />
                      ),
                    }}
                  />
                )}
              </span>
            )}
          </div>
          <div
            className={classNames(
              'notebook-content',
              styles.modalNotebookContent
            )}
          >
            {this.renderNotebook()}
          </div>
        </div>
      </div>
    );
  }
}

// @ts-ignore
export default injectIntl(withRouter(CodeCapsuleOutput));
