/* eslint-disable */

export default {
  root: {
    id: '1',
    score: '0',
    recordCount: '33918',
    defaultChild: '3',
    true: null,
    scoreDistribution: [
      {
        value: '0',
        recordCount: '16959',
        confidence: '0.5',
      },
      {
        value: '1',
        recordCount: '16959',
        confidence: '0.5',
      },
    ],
    children: [
      {
        id: '2',
        score: '0',
        recordCount: '10543',
        simplePredicate: {
          field: 'orders_qty_beauty_l730d',
          operator: 'lessThan',
          value: '5.5',
        },
        scoreDistribution: [
          {
            value: '0',
            recordCount: '9163',
            confidence: '0.8691074646685',
          },
          {
            value: '1',
            recordCount: '1380',
            confidence: '0.1308925353315',
          },
        ],
      },
      {
        id: '3',
        score: '1',
        recordCount: '23375',
        defaultChild: '7',
        simplePredicate: {
          field: 'orders_qty_beauty_l730d',
          operator: 'greaterOrEqual',
          value: '5.5',
        },
        scoreDistribution: [
          {
            value: '0',
            recordCount: '7796',
            confidence: '0.33351871657754',
          },
          {
            value: '1',
            recordCount: '15579',
            confidence: '0.66648128342246',
          },
        ],
        children: [
          {
            id: '6',
            score: '0',
            recordCount: '1334',
            simplePredicate: {
              field: 'qty_days_last_order',
              operator: 'greaterOrEqual',
              value: '66.5',
            },
            scoreDistribution: [
              {
                value: '0',
                recordCount: '1131',
                confidence: '0.847826086956522',
              },
              {
                value: '1',
                recordCount: '203',
                confidence: '0.152173913043478',
              },
            ],
          },
          {
            id: '7',
            score: '1',
            recordCount: '22041',
            defaultChild: '14',
            simplePredicate: {
              field: 'qty_days_last_order',
              operator: 'lessThan',
              value: '66.5',
            },
            scoreDistribution: [
              {
                value: '0',
                recordCount: '6665',
                confidence: '0.30239099859353',
              },
              {
                value: '1',
                recordCount: '15376',
                confidence: '0.69760900140647',
              },
            ],
            children: [
              {
                id: '14',
                score: '1',
                recordCount: '5164',
                defaultChild: '29',
                simplePredicate: {
                  field: 'orders_qty_beauty_l730d',
                  operator: 'lessThan',
                  value: '15.5',
                },
                scoreDistribution: [
                  {
                    value: '0',
                    recordCount: '2103',
                    confidence: '0.40724244771495',
                  },
                  {
                    value: '1',
                    recordCount: '3061',
                    confidence: '0.59275755228505',
                  },
                ],
                children: [
                  {
                    id: '28',
                    score: '1',
                    recordCount: '3096',
                    defaultChild: '57',
                    simplePredicate: {
                      field: 'amt_pn_pct_l730d',
                      operator: 'lessThan',
                      value: '6.35',
                    },
                    scoreDistribution: [
                      {
                        value: '0',
                        recordCount: '1429',
                        confidence: '0.46156330749354',
                      },
                      {
                        value: '1',
                        recordCount: '1667',
                        confidence: '0.53843669250646',
                      },
                    ],
                    children: [
                      {
                        id: '56',
                        score: '0',
                        recordCount: '691',
                        simplePredicate: {
                          field: 'amt_q1_pct_l730d',
                          operator: 'lessThan',
                          value: '18.95',
                        },
                        scoreDistribution: [
                          {
                            value: '0',
                            recordCount: '378',
                            confidence: '0.547033285094067',
                          },
                          {
                            value: '1',
                            recordCount: '313',
                            confidence: '0.452966714905933',
                          },
                        ],
                      },
                      {
                        id: '57',
                        score: '1',
                        recordCount: '2405',
                        simplePredicate: {
                          field: 'amt_q1_pct_l730d',
                          operator: 'greaterOrEqual',
                          value: '18.95',
                        },
                        scoreDistribution: [
                          {
                            value: '0',
                            recordCount: '1051',
                            confidence: '0.437006237006237',
                          },
                          {
                            value: '1',
                            recordCount: '1354',
                            confidence: '0.562993762993763',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '29',
                    score: '1',
                    recordCount: '2068',
                    simplePredicate: {
                      field: 'amt_pn_pct_l730d',
                      operator: 'greaterOrEqual',
                      value: '6.35',
                    },
                    scoreDistribution: [
                      {
                        value: '0',
                        recordCount: '674',
                        confidence: '0.325918762088975',
                      },
                      {
                        value: '1',
                        recordCount: '1394',
                        confidence: '0.674081237911025',
                      },
                    ],
                  },
                ],
              },
              {
                id: '15',
                score: '1',
                recordCount: '16877',
                simplePredicate: {
                  field: 'orders_qty_beauty_l730d',
                  operator: 'greaterOrEqual',
                  value: '15.5',
                },
                scoreDistribution: [
                  {
                    value: '0',
                    recordCount: '4562',
                    confidence: '0.270308704153582',
                  },
                  {
                    value: '1',
                    recordCount: '12315',
                    confidence: '0.729691295846418',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
