import { RootState } from '../../../../../store/store';
import { DataSources } from '../../../../../store/dataManagement/state.types';
import { parquetPreviewToCassandraDataPreview } from '../../../../dataManagement/s3/table-browser/TableBrowser.container';
import {
  nameToTableName,
  objectIsTable,
  s3ObjectToTableObject,
} from '../../../../dataManagement/util';

import { buildDatapoolString } from 'common/dist/utils/nameUtils';

/**
 * Selects the list of available data source from the redux state
 */
export const selectDataSources = (state: RootState): DataSources => {
  return state.data.dataSources;
};

/**
 * Selects the list of available tables for the "default cassandra" and a Datapool from the redux-state
 * @param state
 * @param cassandraDsCode
 * @param datapoolCode
 */
export const selectTables = (
  state: RootState,
  cassandraDsCode: string,
  datapoolCode: string
) => {
  // Get the tables of the Cassandra Keyspace
  if (datapoolCode && cassandraDsCode) {
    const keyspaceName = buildDatapoolString(datapoolCode, 'cassandra');
    const { loading, loaded, data } =
      state.data.cassandra[cassandraDsCode]?.tables?.[keyspaceName] || {};
    return { tablesLoading: loading, tables: data, tablesLoaded: loaded };
  } else {
    return { tables: [], tablesLoading: false, tablesLoaded: false };
  }
};

/**
 * Selects a tableSample for the "default cassandra", a Datapool and table name from the redux-state
 * @param state
 * @param cassandraDsCode
 * @param datapoolCode
 * @param tableName
 */
export const selectTableSample = (
  state: RootState,
  cassandraDsCode: string,
  datapoolCode: string,
  tableName: string
) => {
  if (datapoolCode && cassandraDsCode && tableName) {
    const keyspaceName = buildDatapoolString(datapoolCode, 'cassandra');
    const tableSample =
      state.data.cassandra[cassandraDsCode]?.sample?.[
        `${keyspaceName}#${tableName}`
      ];
    return { tableSample };
  } else {
    return {};
  }
};

/**
 * Select only the parquet files (.parquet file extension) from the root ("")
 * Transform into the { name: string }[] format used by the cassandra call before returning
 * @param state
 * @param dataSourceCode
 * @param datapoolCode
 */
export const selectS3Tables = (
  state: RootState,
  dataSourceCode: string,
  datapoolCode: string
) => {
  if (datapoolCode && dataSourceCode) {
    const bucketName = buildDatapoolString(datapoolCode, 's3');
    const bucketPath = '';
    const { loading, loaded, data } =
      state.data.s3[dataSourceCode]?.bucketContent?.[bucketName]?.[
        bucketPath
      ] || {};
    const filteredAndTransformedData = (data || [])
      .filter(objectIsTable)
      .map(s3ObjectToTableObject);
    return {
      tablesLoading: loading,
      tables: filteredAndTransformedData,
      tablesLoaded: loaded,
    };
  } else {
    return { tables: [], tablesLoading: false, tablesLoaded: false };
  }
};

export const selectS3TableSample = (
  state: RootState,
  dataSourceCode: string,
  datapoolCode: string,
  tableName: string
) => {
  if (datapoolCode && dataSourceCode && tableName) {
    const bucketName = buildDatapoolString(datapoolCode, 's3');
    const fileName = nameToTableName(tableName);
    const preview =
      state.data.s3[dataSourceCode]?.preview?.[`${bucketName}/${fileName}`];
    const tableSample =
      preview !== undefined
        ? {
            ...preview,
            data:
              preview.data !== undefined
                ? parquetPreviewToCassandraDataPreview(preview.data)
                : undefined,
          }
        : undefined;
    return { tableSample };
  } else {
    return {};
  }
};
