import React, { FC } from 'react';
import RejectAutoForceToggle from '../../../../../../atoms/reject-auto-force-toggle/RejectAutoForceToggle';
import { FiArrowDown } from 'react-icons/fi';

type Props = {
  visibleFeatures: string[];
  /** Reject / unreject a feature */
  updateRejectedFeatures: (features: string[], doReject: boolean) => void;
  /** Force / unforce a feature */
  updateForcedFeatures: (features: string[], doForce: boolean) => void;
};

const ToggleAllBar: FC<Props> = (props) => {
  const { visibleFeatures, updateRejectedFeatures, updateForcedFeatures } =
    props;

  const onChange = (clickedButton) => {
    // Depending on which button is selected the separate actions for forced and rejected features state are called
    // They each only add to the list if not present or remove if present, so the checking logic happens here

    updateRejectedFeatures(visibleFeatures, clickedButton === 'rejected');
    updateForcedFeatures(visibleFeatures, clickedButton === 'forced');
  };

  return (
    <div className='rejected-features-row toggle-all-bar'>
      <div className='rejected-features-cell bar-parent' />

      <div className='rejected-features-cell rejected'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <RejectAutoForceToggle value={null} onChange={onChange} />
        </div>
      </div>

      <div className={'rejected-features-cell discarded-or-used-icon'}>
        <FiArrowDown size={16} />
      </div>
      <div className={'rejected-features-cell feature-parent'}>
        <span className='feature'>Apply to all visible features</span>
      </div>
    </div>
  );
};

export default ToggleAllBar;
