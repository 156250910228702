import React, { FC, useEffect } from 'react';
import ResourceList from '../../../organisms/permissions/resouce-list/ResourceList';
import { cassandraDetailsRoute } from './CassandraPermissions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { fetchCassandraKeyspaces } from '../../../../redux/modules/data.cassandra.module';
import { NamesState } from '../../../../store/names/state.types';
import { DeprecatedRootState } from '../../../../store/state.type';
import { datapoolFromKeyspace } from 'common/dist/constants/dataManagement/cassandra';

type Props = {
  /** Code of the Cassandra Data Source*/
  code: string;
};

type KeyspaceType = {
  name: string;
};

const SingleCassandraPermissions: FC<Props> = (props: Props) => {
  const { code } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCassandraKeyspaces(code));
  }, [dispatch]);

  const keyspaces = useSelector<RootState, KeyspaceType[]>(
    (state) => state.data.cassandra[code]?.keyspaces?.data
  );

  const names = useSelector<DeprecatedRootState, NamesState>(
    (state) => state.names
  );

  return (
    <ResourceList
      headlines={[
        {
          headlineId: 'keyspaceName',
          label: {
            id: 'no-id',
            defaultMessage: 'Keyspace Name',
          },
        },
        {
          headlineId: 'datapoolName',
          label: {
            id: 'no-id',
            defaultMessage: 'Datapool Name',
          },
        },
      ]}
      entries={(keyspaces || []).map((keyspace) => {
        const speakingName =
          names.datapoolNames[
            datapoolFromKeyspace(keyspace.name).toUpperCase()
          ];
        return {
          linkTo: cassandraDetailsRoute(code, keyspace.name),
          columnValues: {
            keyspaceName: keyspace.name,
            datapoolName: speakingName,
          },
        };
      })}
    />
  );
};

export default SingleCassandraPermissions;
