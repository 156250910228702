import {
  isValidK8sCpuSpec,
  isValidK8sMemorySpec,
} from 'common/dist/utils/kubernetes';

export const formName = 'configure-code-capsule';
export const fieldResources = 'resources';
export const fieldParameters = 'parameters';

function validate(values) {
  const { resources, parameters } = values;
  let errors: Record<string, any> = {};

  // --- Validate Resources
  if (resources) {
    const { cpu, memory } = resources;
    if (cpu) {
      if (!isValidK8sCpuSpec(cpu)) {
        errors = {
          ...errors,
          resources: {
            ...(errors.resources || {}),
            cpu: 'Please enter valid CPU, for example: 500m or 2',
          },
        };
      }
    }
    if (memory) {
      if (!isValidK8sMemorySpec(memory, true)) {
        errors = {
          ...errors,
          resources: {
            ...(errors.resources || {}),
            memory: 'Please enter valid Memory, for example: 2Gi',
          },
        };
      }
    }
  }

  // --- Validate Parameters
  if (parameters) {
    // TODO
  }

  return errors;
}

export const form = {
  form: formName,
  destroyOnUnmount: true,
  validate,
  // Needed because the form is in a modal, so is initialized immediately
  enableReinitialize: true,
};
