import { createAction } from 'redux-act';
import { put, call } from 'redux-saga/effects';
import * as Api from '../../core/api';
import { takeEvery } from '@redux-saga/core/effects';

export const getConfig = createAction('get config', (callback = () => {}) => ({
  callback,
}));

const setConfig = createAction('set config', (config) => ({ config }));

export const reducer = {
  [setConfig]: (state, { config }) => ({
    ...state,
    config,
  }),
};

export function* getConfigSaga({ payload: { callback } }) {
  const { response } = yield call(Api.dashboard.getConfig);

  if (response) {
    yield put(setConfig(response));
  }

  callback();
}

export function* watchGetConfig() {
  yield takeEvery(getConfig.getType(), getConfigSaga);
}
