import { connect } from 'react-redux';
import SingleDiff from './SingleDiff';
import { DeprecatedRootState } from '../../../../../../../store/state.type';
import {
  addResolveAction,
  removeResolveAction,
} from '../../../../../../../redux/workbench/modules/merger.module';

type OwnProps = {
  /** Filename of the file to resolve the diff for */
  filename: string;
  /** Is it a diff coming from the source or the target file? @*/
  sourceOrTarget: 'source' | 'target';
};

export function mapStateToProps(
  state: DeprecatedRootState,
  { filename }: OwnProps
) {
  const { resolveActions } =
    // @ts-ignore
    (state.workbench.mergerAPI.conflicts || {})[filename + ''] || {};
  return {
    resolveActions,
  };
}

export const mapDispatchToProps = {
  addResolveAction,
  removeResolveAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleDiff);
