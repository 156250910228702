import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputElementShape } from './P3InputElementShape';

export default class P3InputElementParent extends Component {
  render() {
    const {
      parentContainerClass,
      children,
      path,
      cell,
      element,
      updateDataOfInputElementApp,
    } = this.props;
    return (
      <div className={`input-element ${parentContainerClass}`}>
        <div className={'input-element-container'}>
          {element.settings &&
            element.settings.label &&
            element.settings.label.inputValue &&
            element.settings.label.inputValue.trim() && (
              <p className={'input-element-label'}>
                {element.settings.label.inputValue}
              </p>
            )}
          {element.settings &&
            element.settings.description &&
            element.settings.description.inputValue &&
            element.settings.description.inputValue.trim() && (
              <p className={'input-element-description'}>
                {element.settings.description.inputValue}
              </p>
            )}
          {element.data && element.data.error && (
            <p className={'input-element-error'}>{element.data.error}</p>
          )}
          {
            // --- Visual component for inside the cell
            React.Children.map(children, (child) =>
              React.cloneElement(child, {
                path,
                cellId: cell.id,
                updateDataOfInputElement: updateDataOfInputElementApp,
                element,
              })
            )
          }
        </div>
      </div>
    );
  }
}

P3InputElementParent.propTypes = {
  parentContainerClass: PropTypes.string,
  children: PropTypes.arrayOf(React.Component),
  /** Path of the parent notebook (required for changes) */
  path: PropTypes.string.isRequired,
  /** Parent cell this input element belongs to */
  cell: PropTypes.object.isRequired,
  element: inputElementShape.isRequired,
  /** Function that updates the user input data entered in the input element */
  updateDataOfInputElementApp: PropTypes.func.isRequired,
  /** Key of the element = element.id (this is requried for react-grid-layout)*/
  key: PropTypes.string,
};
