import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { WrappedFieldProps } from 'redux-form';
import DropdownSelectInput from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { WithTableSampleProps } from '../../organisms/modelManagement/newAugurWizard/commonComponents/withTableSample/WithTableSample';
import { ErrorType } from '../../organisms/modelManagement/newAugurWizard/samplingStrategy/validate';

export interface Props {
  intl: IntlShape;

  withPreviewButton?: boolean;
  previewButtonOnClick?: () => void;
  /** Optional portal for the dropdown select */
  dropdownSelectPortal?: HTMLElement;
}

const placeholderTable = {
  id: 'newAugur.steps.samplingStrategy.no_table_selected',
  defaultMessage: 'No Table selected',
};

type OptionType = { value: string; label: string };

class EvaluationTableSelect extends Component<
  Props & WithTableSampleProps & WrappedFieldProps
> {
  render() {
    const {
      tablesLoading,
      tablesLoaded,
      tables,
      meta: { touched },
      input: { onChange, value, onFocus, onBlur },
      dropdownSelectPortal,
      withPreviewButton,
      previewButtonOnClick,
    } = this.props;
    const error: ErrorType | undefined = this.props.meta.error;

    const tableOptions =
      tablesLoading || !tables
        ? []
        : tables.map((table) => ({
            label: table.name,
            value: table.name,
          }));

    return (
      <div>
        <div className='form--group'>
          <label className='form--label'>
            <FormattedMessage
              id='newAugur.steps.samplingStrategy.select_evaluation_table'
              defaultMessage='Select the Evaluation Table'
            />
          </label>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <DropdownSelectInput
                id={'samplingStrategy.evaluationTable'}
                name={'samplingStrategy'}
                placeholder={placeholderTable}
                options={tableOptions}
                value={tableOptions.find(
                  (o) => o.value === value.evaluationTable
                )}
                onChange={(option: OptionType) =>
                  onChange({ ...value, evaluationTable: option.value })
                }
                onFocus={onFocus}
                onBlur={() => onBlur(value)}
                clearable={false}
                isLoading={tablesLoading}
                menuPortalTarget={dropdownSelectPortal}
                error={error?.evaluationTable}
                touched={touched}
              />
            </div>
            {withPreviewButton && (
              <div
                title={'Show Preview'}
                className={
                  'form--icon-button ' +
                  (value.evaluationTable ? 'enabled' : 'disabled')
                }
                style={{
                  marginLeft: '10px',
                }}
                onClick={previewButtonOnClick}
              >
                <span className={'icon-tables'} style={{ fontSize: '16px' }} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(EvaluationTableSelect);
