import { NodeType } from '../../_pipeline-tuning-results-common/types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  data: NodeType;
};

const nodeTypeLabels = (nodeType: string) => {
  if (nodeType === 'classifier') {
    return {
      id: 'no-id',
      defaultMessage: 'Classifier',
    };
  } else if (nodeType === 'transformer') {
    return {
      id: 'no-id',
      defaultMessage: 'Transformer',
    };
  } else {
    // Fallback
    return {
      id: 'no-id',
      defaultMessage: nodeType,
    };
  }
};

export default class Node extends Component<Props, {}> {
  render() {
    const { data } = this.props;

    return (
      <div
        className={
          'PipelineTuningChart--node' +
          (data?.isInactive ? ' PipelineTuningChart--node-inactive' : '')
        }
      >
        <div
          data-testingIdentifier={data?.id}
          className={'PipelineTuningChart--title'}
        >
          <span>{data?.displayName}</span>
        </div>
        <div className={'PipelineTuningChart--node-type'}>
          <FormattedMessage {...nodeTypeLabels(data.nodeType)} />
        </div>
      </div>
    );
  }
}
