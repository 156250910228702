import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Executions from './Executions';
import { fetchCodeCapsuleExecutions } from '../../../../redux/modules/codeCapsules.module';

export function mapStateToProps(state, { match }) {
  const { codeCapsuleCode, habitatCode } = match.params;

  let executionsPerCode = state.codeCapsules.executionsPerCode[codeCapsuleCode];
  if (!executionsPerCode) executionsPerCode = {};

  const { loading, loaded, error, data } = executionsPerCode;

  return {
    codeCapsuleCode,
    habitatCode,
    loading,
    loaded,
    error,
    data,
  };
}

export const mapDispatchToProps = {
  fetchCodeCapsuleExecutions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Executions)
);
