import * as axisLeft from '../axis/axisLeft';

const THREE = require('three');

if (process.env.NODE_ENV !== 'test') {
  require('imports-loader?THREE=three!three/examples/js/renderers/CSS3DRenderer.js'); // eslint-disable-line
}

export function render(scene, width, height, data, maxY) {
  const div = document.createElement('div');
  div.style.pointerEvents = 'none';

  axisLeft.append(div, width, height, data, maxY);

  const object = new THREE.CSS3DObject(div);
  object.position.x = 0;
  object.position.y = 0;
  object.position.z = 0;
  object.rotation.y = Math.PI;

  scene.add(object);

  return object;
}
