import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiPlay, FiPlus } from 'react-icons/fi';
import {
  inputElementShape,
  variableShape,
} from './elements/_interface/P3InputElementShape';
import VariableCard from './variables/VariableCard.container';
import ExpandCollapseIcon from '../../../../../../../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import IconButton from '../../../../../../../../atoms/icon-button/IconButton';

export default class InputVariables extends Component {
  render() {
    const {
      addVariable,
      executeVariables,
      path,
      cell,
      session,
      toggleInputVariables,
    } = this.props;
    const variables = cell.as_variables ? cell.as_variables : [];

    const variablesExpanded = Object.keys(cell || {}).includes(
      'as_variables_expanded'
    )
      ? cell.as_variables_expanded
      : true; // Default: Expanded

    return (
      <div className={'input-variables'}>
        <div className={'input-output-cell-headline-container'}>
          <div className={'input-output-cell-headline-left'}>
            <ExpandCollapseIcon
              isExpanded={variablesExpanded}
              onClick={() =>
                toggleInputVariables(path, cell.id, !variablesExpanded)
              }
              color={'black'}
              size={20}
              title={['Minimize Variables', 'Maximize Variables']}
              withBackground
            />

            <div className={'visual-input-headline'}>
              <span>Input Variables</span>
            </div>

            <div className={'visual-input-amount-indicator'}>
              <span>{(variables || []).length}</span>
            </div>
          </div>

          <div className={'input-output-cell-headline-right'}>
            <IconButton
              title={'Add Variable'}
              Icon={() => <FiPlus size={'20px'} />}
              onClick={() => {
                addVariable(path, cell.id);
                // Make sure the variables are expanded
                toggleInputVariables(path, cell.id, true);
              }}
            />

            <IconButton
              title={'Execute Variables'}
              Icon={() => <FiPlay size={'20px'} />}
              onClick={() =>
                executeVariables(
                  path,
                  cell.id,
                  variables,
                  session.id,
                  'notebook'
                )
              }
            />
          </div>
        </div>

        <div className={'variables-parent'}>
          {variablesExpanded &&
            variables.map((v, i) => (
              <VariableCard key={i} variable={v} path={path} cell={cell} />
            ))}
          {variablesExpanded && (!variables || variables.length === 0) && (
            <span className={'empty'}>
              This Input Cell doesn't have Input Variables yet.
            </span>
          )}
        </div>
      </div>
    );
  }
}
InputVariables.propTypes = {
  cell: PropTypes.shape({
    as_elements: PropTypes.arrayOf(inputElementShape),
    as_variables: PropTypes.arrayOf(variableShape),
    source: PropTypes.string,
    id: PropTypes.string,
    as_elements_expanded: PropTypes.bool,
    as_variables_expanded: PropTypes.bool,
  }).isRequired,
  path: PropTypes.string.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
  }),
  addVariable: PropTypes.func.isRequired,
  /** Execute the "variables" part of the input cell */
  executeVariables: PropTypes.func.isRequired,
  toggleInputVariables: PropTypes.func.isRequired,
};
