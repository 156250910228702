import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const fetchArchetypeVersions = createAction(
  'fetch archetype versions',
  (archetypeCode) => ({ archetypeCode })
);

export const fetchArchetypeVersionsSuccess = createAction(
  'fetch archetype versions - success',
  (archetypeCode, data) => ({ archetypeCode, data })
);

export const fetchArchetypeVersionsFailure = createAction(
  'fetch archetype versions - failure',
  (archetypeCode, error) => ({ archetypeCode, error })
);

export const reducer = {
  [fetchArchetypeVersions](state, { archetypeCode }) {
    const kcSafe = state.archetypeVersions[archetypeCode]
      ? state.archetypeVersions[archetypeCode]
      : {};
    return {
      ...state,
      archetypeVersions: {
        ...state.archetypeVersions,
        [archetypeCode]: {
          ...kcSafe,
          loading: true,
          loaded: false,
          versions: [],
        },
      },
    };
  },
  [fetchArchetypeVersionsSuccess](state, { archetypeCode, data }) {
    const kcSafe = state.archetypeVersions[archetypeCode]
      ? state.archetypeVersions[archetypeCode]
      : {};
    return {
      ...state,
      archetypeVersions: {
        ...state.archetypeVersions,
        [archetypeCode]: {
          ...kcSafe,
          loading: false,
          loaded: true,
          versions: data,
        },
      },
    };
  },
  [fetchArchetypeVersionsFailure](state, { archetypeCode, error }) {
    const kcSafe = state.archetypeVersions[archetypeCode]
      ? state.archetypeVersions[archetypeCode]
      : {};
    return {
      ...state,
      archetypeVersions: {
        ...state.archetypeVersions,
        [archetypeCode]: {
          ...kcSafe,
          loading: false,
          loaded: false,
          error,
          versions: [],
        },
      },
    };
  },
};

export function* fetchArchetypeVersionsSaga({ payload: { archetypeCode } }) {
  const { response, error } = yield call(
    Api.archetypeVersions.getArchetypeVersions,
    archetypeCode
  );
  if (response) {
    yield put(fetchArchetypeVersionsSuccess(archetypeCode, response));
  } else {
    yield put(fetchArchetypeVersionsFailure(archetypeCode, error));
  }
}

export function* watchFetchArchetypeVersions() {
  yield takeEvery(fetchArchetypeVersions.getType(), fetchArchetypeVersionsSaga);
}
