import React from 'react';
import PropTypes from 'prop-types';

const regExpCurly = /[{}]/gm;
const regExpEndOfLine = /",\n/gm;
const regexp = /[",]/gm;

const prettify = (str = '') => {
  let newStr = str.replace(regExpCurly, '');
  newStr = newStr.replace(regExpEndOfLine, '\n');
  return newStr.replace(regexp, '');
};

export const Machine = ({ datapool }) => {
  const hasMeta = !!(datapool && datapool.meta && datapool.meta.machine);

  return (
    <div
      className='h100p model-archive-container'
      style={{ 'font-family': 'monospace', 'white-space': 'pre' }}
    >
      {hasMeta ? (
        <pre>{prettify(JSON.stringify(datapool.meta.machine, null, 4))}</pre>
      ) : (
        <p>No machine data available.</p>
      )}
    </div>
  );
};

Machine.propTypes = {
  datapool: PropTypes.object.isRequired,
};

export default Machine;
