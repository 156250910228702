import { connect, ConnectedProps } from 'react-redux';
import StepUpload from './StepUpload';
import { resetUploadFile } from '../../../../../../redux/modules/data.s3.module';
import { RootState } from '../../../../../../store/store';

export function mapStateToProps(state: RootState) {
  const { uploading, uploaded, progress, error } = state.data.upload || {};

  return {
    uploading,
    uploaded,
    progress,
    error,
  };
}

export const mapDispatchToProps: {
  resetUploadFile: (dataSourceCode: string) => void;
} = {
  resetUploadFile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(StepUpload);
