import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getPasswordPolicies } from '../../../../core/api/accounts';
import { ToBeRefined } from 'common/dist/types/todo_type';

export type Props = {
  error: ToBeRefined;
};

const DescriptionPasswordRules: FC<Props> = (props) => {
  const { error } = props;
  const [passwordPolicies, setPasswordPolicies] = useState({
    data: undefined,
    error: undefined,
  });
  useEffect(() => {
    (async () => {
      const { response, error } = await getPasswordPolicies();
      setPasswordPolicies({ data: response, error });
    })();
  }, []);
  return (
    <div className='ChangePassword--rules'>
      {passwordPolicies.data &&
        !passwordPolicies.error &&
        Object.entries(passwordPolicies.data).length > 0 && (
          <FormattedMessage
            id='account.password.change_password.password_must_contain'
            defaultMessage='The password must:'
          />
        )}
      {passwordPolicies.data &&
        !passwordPolicies.error &&
        Object.entries(passwordPolicies.data).length === 0 && (
          <FormattedMessage
            id='account.password.change_password.no_policies'
            defaultMessage='There are no password policies.'
          />
        )}
      {passwordPolicies.error && (
        <FormattedMessage
          id='account.password.change_password.policy_error'
          defaultMessage='There was an error fetching the password policies.'
        />
      )}
      <ul>
        {passwordPolicies.data &&
          !passwordPolicies.error &&
          Object.entries(passwordPolicies.data).map(([k, v]) => (
            <li key={k}>
              <FormattedMessage
                id={k}
                values={{
                  // @ts-ignore
                  val: v,
                }}
              />
            </li>
          ))}
      </ul>
      <span className={'InputError'}>{error}</span>
    </div>
  );
};

export default DescriptionPasswordRules;
