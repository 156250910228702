import { select } from 'd3-selection';

export function append(node, width, height, item, showTooltip, hideTooltip) {
  const svg = select(node).append('svg').attr('width', 10).attr('height', 10);

  const circle = svg
    .append('circle')
    .attr('stroke', '#247FE3')
    .attr('r', 3)
    .attr('fill', 'white')
    .attr('cx', 5)
    .attr('cy', 5);

  circle.on('mouseover', () => {
    const { top, left } = circle.nodes()[0].getBoundingClientRect();
    showTooltip(left, top, item);
    circle.attr('fill', '#247FE3');
  });

  circle.on('mouseleave', () => {
    hideTooltip();
    circle.attr('fill', 'white');
  });
}
