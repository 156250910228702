import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export function Icon({ isActive, title, icon: IconComponent, linkPath, id }) {
  const liClassName = classNames('menus--item', {
    active: isActive,
  });

  return (
    <li className={liClassName} id={id}>
      <Link className='menus--link' to={linkPath} data-icon={`${id}`}>
        <span className='menus--icon'>
          <IconComponent />
        </span>
        <span className='menus--text'>
          <FormattedMessage id={title} />
        </span>
      </Link>
    </li>
  );
}

Icon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  linkPath: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Icon;
