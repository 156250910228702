import { connect } from 'react-redux';
import { RootState } from '../../../../../store/store';
import Datapool, { OwnProps } from './Datapool';
import { HabitatDetailsType } from 'common/dist/types/habitat';

function mapStateToProps(state: RootState, { habitatCode }: OwnProps) {
  const habitat =
    state.summary.find((h: HabitatDetailsType) => h.code === habitatCode) || {};

  return {
    datapools: habitat.datapools,
  };
}

export default connect(mapStateToProps)(Datapool);
