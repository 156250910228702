import { FC } from 'react';

import GenericFormStep from '../../../../../molecules/generic-form-step/GenericFormStep';
import {
  newScheduleFormName,
  selectedScheduleField,
} from '../../newSchedule.form';
import { SelectedSchedule } from 'common/src/utils/schedules';
import ScheduleSelect from '../../../../../organisms/schedule-select/ScheduleSelect';
import stepMessage from 'common/dist/messages/orchestration';
import { validateField } from '../../../../../organisms/schedule-select/ScheduleSelect';

export type Props = {
  step: number;
};

const PeriodStep: FC<Props> = ({ step }) => {
  return (
    <GenericFormStep<SelectedSchedule, Record<string, unknown>>
      fieldName={selectedScheduleField}
      formName={newScheduleFormName}
      component={ScheduleSelect}
      validate={validateField}
      title={{
        id: stepMessage.msgStepPeriodName.id,
        defaultMessage: stepMessage.msgStepPeriodName.defaultMessage,
      }}
      description={{
        id: stepMessage.msgStepPeriodDescription.id,
        defaultMessage: stepMessage.msgStepPeriodDescription.defaultMessage,
      }}
      num={step}
      renderError={false}
    />
  );
};

export default PeriodStep;
