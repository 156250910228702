import { ComponentType } from 'react';
import { ComponentDatapoolDetailsTab } from '../../_interfaces/datapool-details/DatapoolDetailsTab';
import Communications from './Communications.container';
import { DatapoolType } from 'common/dist/types/datapool';
import { FiMessageSquare } from 'react-icons/all';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';

export default class OptCommunicationsWrapper extends ComponentDatapoolDetailsTab {
  type: 'component' = 'component';
  id = 'communications';
  title = moduleOptimizationMessages.msgDatapoolDetailsCommunicationsTabName;
  to = (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${shortDatapoolType}/communications`;

  icon: ComponentType = () => <FiMessageSquare size={16} />;
  component: ComponentType<any> = Communications;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType): boolean => true;
}
