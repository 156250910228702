import React, { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';

export interface Props {
  input: {
    value?: {
      memory?: string;
      cpu?: number;
    };
  };
  meta: {};
}

export default class FieldResources extends Component<
  Props & WrappedFieldProps
> {
  render() {
    const {
      meta: { error },
      input: { value, onChange },
    } = this.props;
    return (
      <div className={'field-parent'}>
        <div className={'field-column'}>
          <span className={'modal-description'}>CPU</span>
          {error?.cpu && <p className={'form--error'}>{error.cpu}</p>}
          <input
            style={{ flexGrow: 1, marginRight: '5px' }}
            type={'text'}
            defaultValue=''
            placeholder='CPU'
            name='cpu'
            value={(value || {}).cpu || ''}
            onChange={(e) =>
              onChange({ ...(value || {}), cpu: e.target.value })
            }
          />
        </div>
        <div
          style={{
            width: '50%',
            marginLeft: '10px',
          }}
        >
          <span className={'modal-description'}>Memory</span>
          {error?.memory && <p className={'form--error'}>{error.memory}</p>}
          <input
            type={'text'}
            defaultValue=''
            placeholder='RAM'
            name='memory'
            value={(value || {}).memory || ''}
            onChange={(e) =>
              onChange({ ...(value || {}), memory: e.target.value })
            }
          />
        </div>
      </div>
    );
  }
}
