import React, { Component } from 'react';
import AttributesEditMode from './AttributesEditMode.container';
import { FiEdit2 } from 'react-icons/fi';

interface Props {
  /** AttributesComponent data read from state. */
  attributesData: { [attr: string]: string[] } | {};
  userId?: string;
}

export interface State {
  editMode: boolean;
}

export default class Attributes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { editMode: false };
  }

  render() {
    if (this.state.editMode) return this.renderEdit();
    else return this.renderStatic();
  }

  renderEdit() {
    return (
      <AttributesEditMode
        attributesData={this.props.attributesData}
        userId={this.props.userId}
        toggleEdit={(isEditing: boolean) =>
          this.setState({ editMode: isEditing })
        }
      />
    );
  }

  renderStatic() {
    const { attributesData } = this.props;

    return (
      <div className={'attributes-container ct-list'}>
        <div className={'ct-row ct-headline'}>
          <div className={'ct-col ct-col-280px'}>
            <span>Attribute</span>
          </div>
          <div className={'ct-col ct-col-350px'} />
          <div className={'ct-col ct-col-200px'}>
            <span>Value</span>
          </div>
          <div
            data-testingIdentifier={'editAttributes'}
            className={'icon-container'}
            onClick={() => this.setState({ editMode: !this.state.editMode })}
          >
            <FiEdit2 className={'action-icon'} size={16} />
          </div>
        </div>
        {Object.entries(attributesData)
          .sort(([aKey], [bKey]) => (aKey > bKey ? 1 : -1))
          .map(([key, value]) => (
            <div key={key} className={'ct-row attributes-row'}>
              <div className={'ct-col ct-col-280px'}>
                <span>{key}</span>
              </div>
              <div className={'ct-col ct-col-350px'} />
              <div className={'ct-col ct-col-200px'}>
                <span>{value}</span>
              </div>
            </div>
          ))}
      </div>
    );
  }
}
