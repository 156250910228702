import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { dataManagementRoutes } from '../../routes';
import { FiChevronLeft } from 'react-icons/fi/index';

export interface Props {
  dataSourceCode: string;
}

const BackButton: FC<Props> = ({ dataSourceCode }) => (
  <div className={'back-button-container'}>
    <Link
      to={`${dataManagementRoutes.basePath}/s3/${dataSourceCode}/${dataManagementRoutes.buckets}`}
      style={{ textDecoration: 'none' }}
    >
      <div className={'back-button'}>
        <FiChevronLeft size={20} className={'back-icon'} />
        <span className={'back-text'}>Back to Buckets</span>
      </div>
    </Link>
  </div>
);

export default BackButton;
