import { connect } from 'react-redux';
import UploadConfirmation from './UploadConfirmation';
import { destroy } from 'redux-form';
import { formName as uploadFormName } from '../../s3/upload/wizard-upload/form';

export function mapStateToProps(state) {
  const { error } = (state.data.upload || {}).write || {};
  return {
    error,
  };
}

export const mapDispatchToProps = {
  destroyUploadForm: () => destroy(uploadFormName),
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadConfirmation);
