import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { fieldArchHabitatDatapool } from '../repository.form';

const datapoolOptions = (summary, repositoryHabitat) => {
  if (!summary || !repositoryHabitat || !repositoryHabitat.code) return [];
  const habitatCode = repositoryHabitat.code;

  const habitat = summary.find((h) => h.code === habitatCode);
  if (!habitat) return [];

  return (habitat.datapools || []).map((datapool) => ({
    label: datapool.name,
    value: datapool.code,
  }));
};

const storedHabitatInfo = (summary, habitatCode) => {
  if (!habitatCode) return {};

  const habitat = summary.find((habitat) => habitat.code === habitatCode);
  if (!habitat) return {};

  return {
    code: habitat.code,
    name: habitat.name,
  };
};

const storedDatapoolInfo = (summary, repositoryHabitat, datapoolCode) => {
  if (
    !summary ||
    !repositoryHabitat ||
    !repositoryHabitat.code ||
    !datapoolCode
  )
    return {};
  const habitatCode = repositoryHabitat.code;

  const habitat = summary.find((habitat) => habitat.code === habitatCode);
  if (!habitat) return {};

  const datapool = (habitat.datapools || []).find(
    (datapool) => datapool.code === datapoolCode
  );

  return {
    code: datapool.code,
    name: datapool.name,
  };
};

export default class RepositoryStepHabitatDatapool extends Component {
  componentDidMount() {
    const { summary, fetchSummary } = this.props;
    if (!summary) fetchSummary();
  }

  render() {
    const {
      summary,
      summaryLoading,
      input: { value, onChange, onBlur, onFocus },
      meta: { error, valid, touched },
    } = this.props;

    const optionsHabitat = !summary
      ? []
      : summary.map((habitat) => ({
          label: habitat.name,
          value: habitat.code,
        }));
    const optionsDatapool = datapoolOptions(summary, value?.habitat);

    return (
      <Fragment>
        <div className={'GenericFormStep--field'}>
          <DropdownSelectInput
            id={`${fieldArchHabitatDatapool}.habitat`}
            name={`${fieldArchHabitatDatapool}.habitat`}
            touched={touched}
            error={error?.habitat?.message}
            valid={!error?.habitat && touched}
            disabled={false}
            label={{
              id: 'no-id',
              defaultMessage: 'Habitat',
            }}
            placeholder={{
              id: 'newAugur.training_table.no_table_selected',
              defaultMessage: 'Select a Habitat',
            }}
            value={optionsHabitat.find((o) => o.value === value?.habitat?.code)}
            onChange={(option) =>
              onChange({
                ...value,
                habitat: storedHabitatInfo(summary, option.value),
              })
            }
            onFocus={onFocus}
            onBlur={() => onBlur(value)}
            isLoading={summaryLoading}
            options={optionsHabitat}
            autoSelectIfSingle
            scrollMenuIntoView={false}
          />
        </div>

        <div className={'GenericFormStep--field'}>
          <DropdownSelectInput
            id={`${fieldArchHabitatDatapool}.datapool`}
            name={`${fieldArchHabitatDatapool}.datapool`}
            touched={touched}
            error={error?.datapool?.message}
            valid={!error?.datapool && touched}
            disabled={!value?.habitat}
            hasLabel={true}
            label={{
              id: 'no-id',
              defaultMessage: 'Datapool',
            }}
            placeholder={{
              id: 'no-id',
              defaultMessage: 'Select a Datapool',
            }}
            value={optionsDatapool.find(
              (o) => o.value === value?.datapool?.code
            )}
            onChange={(option) =>
              onChange({
                ...value,
                datapool: storedDatapoolInfo(
                  summary,
                  value?.habitat,
                  option.value
                ),
              })
            }
            onFocus={onFocus}
            onBlur={() => onBlur(value)}
            isLoading={summaryLoading}
            options={optionsDatapool}
            autoSelectIfSingle
            scrollMenuIntoView={false}
          />
        </div>
      </Fragment>
    );
  }
}

RepositoryStepHabitatDatapool.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.shape({
      habitat: PropTypes.string,
      datapool: PropTypes.string,
    }),
  }).isRequired,

  input: PropTypes.shape({
    value: PropTypes.shape({
      habitat: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
      }),
      datapool: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
      }),
    }),
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),

  summaryLoading: PropTypes.bool,
  /** Summary of all Habitats / Datapools */
  summary: PropTypes.arrayOf(
    PropTypes.shape({
      /** Name of the Habitat */
      name: PropTypes.string.isRequired,
      /** Code of the Habitat */
      code: PropTypes.string.isRequired,
      /** List of Datapools for this Habitat */
      datapools: PropTypes.arrayOf(
        PropTypes.shape({
          /** Name of the Datapool */
          name: PropTypes.string.isRequired,
          /** Code of the Datapool */
          code: PropTypes.string.isRequired,
          /** Module Type of the Datapool */
          moduleType: PropTypes.string.isRequired,
          module: PropTypes.shape({
            name: PropTypes.string,
          }).isRequired,
        })
      ),
    })
  ),
  /** Redux action to fetch the summary of Habitats / Datapools */
  fetchSummary: PropTypes.func.isRequired,
};
