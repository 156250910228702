import React, { Component } from 'react';
import { select } from 'd3-selection';
import { axisBottom } from 'd3-axis';
import { injectIntl, IntlShape } from 'react-intl';
import { ToBeRefined } from 'common/dist/types/todo_type';
import styles from './styles.module.scss';

type Props = {
  scale: ToBeRefined;
  intl: IntlShape;
};

export class AxisBottom extends Component<Props> {
  ticksRef;

  constructor(props) {
    super(props);

    this.ticksRef = React.createRef();
  }

  componentDidMount() {
    this.renderAxisTicks(this.props);
  }

  shouldComponentUpdate(props) {
    this.renderAxisTicks(props);
    return false;
  }

  renderAxisTicks(props) {
    const { scale, intl } = props;
    const axis = axisBottom(scale).tickFormat((date) => {
      return intl.formatDate(date);
    });

    const ticksRefCurrent = this.ticksRef.current;

    select(ticksRefCurrent).call(axis);

    select(ticksRefCurrent)
      .selectAll('text')
      .attr('transform', 'translate(-10, 20) rotate(-30)');
  }

  render() {
    return (
      <g className={styles.chartAxis}>
        <g className={styles.bottomTicks} ref={this.ticksRef} />
      </g>
    );
  }
}

export default injectIntl(AxisBottom);
