import NotebookCellWrapper from '../../NotebookCellWrapper';
import CodeCell from '../../../../../../../organisms/workbench-cells/code-cell/CodeCell.container';

export default class CodeCellWrapper extends NotebookCellWrapper {
  ChildComponent = CodeCell;
  type = 'code';
  name = 'Code';
  parentContainerClass = 'code-cell';
  executable = true;
}
