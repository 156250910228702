import { connect } from 'react-redux';
import { DeprecatedRootState } from '../../../../../store/state.type';
import MergeRequestDetailsHeader from './MergeRequestDetailsHeader';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    mergeRequest: ((state.workbench || {}).collab || {}).mergeRequest || {},
  };
}

export default connect(mapStateToProps)(MergeRequestDetailsHeader);
