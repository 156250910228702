import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiCode, FiX } from 'react-icons/fi';

export default class OverlappingMenuEntry extends Component {
  render() {
    const { path, mostRecentPaneId, hideOverlappingMenu, selectNotebook } =
      this.props;
    return (
      <div
        className={
          'overlapping-menu-entry' +
          (this.props.selectedNotebook === path ? ' selected' : '')
        }
        onClick={() => {
          selectNotebook(path);
          hideOverlappingMenu();
        }}
      >
        <FiCode className={'overlapping-icon'} size={'20px'} />
        <p className={'overlapping-name'}>{this.props.name}</p>
        <FiX
          className={'overlapping-icon close'}
          size={'14px'}
          onClick={() => {
            this.props.closeNotebook(path);
            this.props.updateOverlappingTabs();
          }}
        />
      </div>
    );
  }
}
OverlappingMenuEntry.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  selectedNotebook: PropTypes.string,
  selectNotebook: PropTypes.func.isRequired,
  closeNotebook: PropTypes.func.isRequired,
  hideOverlappingMenu: PropTypes.func.isRequired,
  updateOverlappingTabs: PropTypes.func.isRequired,
  mostRecentPaneId: PropTypes.string.isRequired,
};
