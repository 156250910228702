import { isEmpty } from 'lodash';

export const step0Form = 'dataImportStep0';

export const step1Form = 'dataImportStep1';

export const idColumnField = 'idColumnField';

export const shapshotColumnField = 'snapshotColumnField';

export const linkColumnField = 'linkColumnField';

export const categoryColumnField = 'categoryColumnField';

export function validateMasterDataStep1() {
  const errors = {};

  return errors;
}

export function validateMasterDataStep0(values) {
  const errors = {};

  if (isEmpty(values[idColumnField])) {
    errors[idColumnField] = {
      id: 'dataImport.stepOne.please_select_id_column',
    };
  }

  return errors;
}

export function validateMappingDataStep1() {
  const errors = {};

  return errors;
}

export function validateMappingDataStep0(values) {
  const errors = {};

  if (isEmpty(values[linkColumnField])) {
    errors[linkColumnField] = {
      id: 'dataImport.stepOne.please_select_link_column',
    };
  }

  if (isEmpty(values[categoryColumnField])) {
    errors[categoryColumnField] = {
      id: 'dataImport.stepOne.please_select_category_column',
    };
  }

  return errors;
}
