import React, { Component } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import classNames from 'classnames';
import { PipelineTuningValueNode } from 'common/dist/types/pipeline';

type Props = {
  node: PipelineTuningValueNode;
  /** Is the corresponding node currently active? */
  isActive: boolean;
  tuningParameterMap: { [parameterId: string]: string | number };
};

export default class ModelStep extends Component<Props, {}> {
  /**
   * Renders the description of the node
   * @param description
   */
  renderDescription(description: MessageDescriptor) {
    if (!description) return null;

    if (!description.id) {
      return (
        <span className={'ModelParameter--model-step-description'}>
          {description.defaultMessage}
        </span>
      );
    } else {
      return (
        <FormattedMessage
          id={description.id}
          defaultMessage={description.defaultMessage}
        >
          {(text) => (
            <span className={'ModelParameter--model-step--description'}>
              {text}
            </span>
          )}
        </FormattedMessage>
      );
    }
  }

  render() {
    const { node, isActive, tuningParameterMap } = this.props;

    if (!node) {
      return null;
    }

    const { displayName, description } = node;

    return (
      <div
        className={classNames('ModelParameter--model-step', {
          'ModelParameter--model-step-active': isActive,
        })}
      >
        <span className={'ModelParameter--model-step--display-name'}>
          {displayName}
        </span>
        {isActive && this.renderDescription(description)}

        <div className={'ModelParameter--model-step--parameters'}>
          {node.staticParameters && (
            <div className={'ModelParameter--model-step--static'}>
              <span className={'ModelParameter--model-step--params-headline'}>
                Static Parameters
              </span>
              <div className={'ModelParameter--model-step--params-container'}>
                {node.staticParameters.map((param) => (
                  <div className={'ModelParameter--model-step--params'}>
                    <span className={'ModelParameter--model-step--params-name'}>
                      {param.displayName}:
                    </span>
                    <span
                      className={'ModelParameter--model-step--params-value'}
                    >
                      {param.value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {node.tuningParameters && (
            <div className={'ModelParameter--model-step--tuning'}>
              <span className={'ModelParameter--model-step--params-headline'}>
                Tunable Parameters
              </span>
              <div className={'ModelParameter--model-step--params-container'}>
                {node.tuningParameters.map((param) => (
                  <div className={'ModelParameter--model-step--params'}>
                    <span className={'ModelParameter--model-step--params-name'}>
                      {param.displayName}:
                    </span>
                    <span
                      className={'ModelParameter--model-step--params-value'}
                    >
                      {tuningParameterMap[param.id]}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
