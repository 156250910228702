import React, { FC, PropsWithChildren } from 'react';
import { SettingsElementType } from './SettingsBlock';
import { FormattedMessage } from 'react-intl';

const SettingsBlockElement: FC<SettingsElementType> = (
  props: PropsWithChildren<SettingsElementType>
) => {
  const { title, description, DescriptionComponent, Component } = props;
  return (
    <div className={'SettingsBlock--element'}>
      <div className={'SettingsBlock--left'}>
        <div className={'SettingsBlock--element-title'}>
          <FormattedMessage
            id={title.id || 'no-id'}
            defaultMessage={title.defaultMessage}
          />
        </div>
        {description && (
          <div className={'SettingsBlock--element-description'}>
            <FormattedMessage
              id={description.id || 'no-id'}
              defaultMessage={description.defaultMessage}
            />
          </div>
        )}
        {DescriptionComponent && DescriptionComponent}
      </div>

      <div className={'SettingsBlock--right'}>{Component && Component}</div>
    </div>
  );
};

export default SettingsBlockElement;
