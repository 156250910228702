import React, { FC, useEffect } from 'react';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import { Group } from '../../../../store/admin/state.types';
import _ from 'lodash';
import { LinkWithQuery } from '../../../atoms/link-with-query/LinkWithQuery';
import styles from '../styles.module.scss';
import adminMsgs from 'common/dist/messages/admin';
import Button from '../../../atoms/button/Button';
import { useIntl } from 'react-intl';

export const adminAddGroupLink = `${usersRoutes.basePath}/${routes.app.admin.addGroup}`;
export const adminGroupDetailsLink = (groupId) =>
  `${usersRoutes.basePath}/${usersRoutes.groups.path}/${groupId}`;

export interface Props {
  loadGroups: (fetchPermissions: boolean) => void;
  data: Group[];
  loading: boolean;
  loaded: boolean;
  error?: string;
}

const Groups: FC<Props> = ({ data, loadGroups }) => {
  const intl = useIntl();

  useEffect(() => loadGroups(false), [loadGroups]);

  return (
    <div>
      <div className={styles.topButtonBar}>
        <Button
          buttonColor={'secondary'}
          withLink={true}
          buttonLabelDefault={intl.formatMessage(adminMsgs.msgAddGroup)}
          linkTo={adminAddGroupLink}
        />
      </div>

      <div className={styles.listContainer}>
        <div className={'ct-list'}>
          <div className={'ct-headline ct-row'}>
            <div className={'ct-col ct-col-200px'}>
              <span>Name</span>
            </div>
          </div>
          {data &&
            data
              .filter((g) => !_.isEqual(g.attributes.isHabitat, ['true']))
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((group) => (
                <LinkWithQuery
                  to={adminGroupDetailsLink(group.id)}
                  style={{ textDecoration: 'none' }}
                >
                  <div className={'ct-row'}>
                    <div className={'ct-col ct-col-200px'}>
                      <span>{group.name}</span>
                    </div>
                  </div>
                </LinkWithQuery>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Groups;
