import React, { Component, RefObject } from 'react';

import AceEditor from 'react-ace';
import 'brace/mode/python';
import 'brace/theme/tomorrow';
import 'brace/ext/language_tools';
import { CProps } from './RawCell.container';
import ReactAce from 'react-ace';
import { AltaSigmaRawCell } from '../../../../../../../../store/workbench/state.types';

function isCellActive(selectedCells, ownCellId) {
  if (selectedCells && selectedCells.length > 0) {
    const firstSelectedCellId = selectedCells[0];
    if (ownCellId === firstSelectedCellId) {
      return true;
    }
  }
  return false;
}

export interface Props {
  cell: AltaSigmaRawCell;
  path: string;
  selectedCells?: string[];
  selectCells?: (path: string, cellIds: string[]) => void;
  changeSource: (...args: unknown[]) => void;
  readOnly?: boolean;
}

export default class RawCell extends Component<Props & CProps> {
  // Filled in a callback of the <AceEditor/>
  editorRef: RefObject<ReactAce>;

  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.forceFocus === this.props.cell.id &&
      this.editorRef &&
      document.activeElement !== this.editorRef.current.editor
    ) {
      this.editorRef.current.editor.focus();
      this.props.clearForceFocus(this.props.path);
    }
  }

  render() {
    const { cell, readOnly } = this.props;

    return (
      <div className={'editor-parent-container'}>
        <div className={'editor-container'}>
          <AceEditor
            ref={this.editorRef}
            width={'100%'}
            className={'raw-cell-editor'}
            mode='python'
            theme='tomorrow'
            onChange={(source) => {
              this.props.changeSource({
                path: this.props.path,
                cellId: this.props.cell.id,
                source,
              });
            }}
            showGutter
            highlightActiveLine
            value={
              Array.isArray(cell.source) ? cell.source.join('') : cell.source
            }
            setOptions={{
              maxLines: Infinity,
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
            }}
            editorProps={{ $blockScrolling: Infinity }}
            readOnly={!!readOnly}
          />
        </div>
      </div>
    );
  }
}
