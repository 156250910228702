import React, { FC } from 'react';
import styles from './styles.module.scss';
import commonStyles from '../optChannels/optCommonStyles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import classNames from 'classnames';
import { Channel, Propensity } from 'common/dist/types/module.optimization';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import DescriptionOption, {
  DescriptionOptionType,
} from '../commonComponents/descriptionOption/DescriptionOption';
import { FiMinus } from 'react-icons/fi';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';

type Props = {
  /** List of channels defined in a previous wizard step */
  channels: Channel[];
  /** List of propensities defined in a previous wizard step */
  propensities: Propensity[];
  rowIndex?: number;
  constraintId: string;
  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: Channel) => void;
};
const drvNewValue = (
  input: WrappedFieldInputProps,
  eventValue: string,
  key: string,
  rowIndex: number
) => {
  return [
    ...input.value.slice(0, rowIndex),
    {
      ...(input.value?.[rowIndex] || {}),
      [key]: eventValue,
    },
    ...input.value.slice(rowIndex + 1),
  ];
};
const renderNameField = (props) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optCommunications_name'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.name}
      valid={!meta.error?.[constraintId]?.name}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Name'}
      placeholderId={'no-id'}
      placeholderDefault={'Name'}
      value={input.value?.[rowIndex]?.name}
      onChange={(e) => {
        const newValue = drvNewValue(input, e.target.value, 'name', rowIndex);
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderDescriptionField = (props) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optCommunications_description'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.description}
      valid={!meta.error?.[constraintId]?.description}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Description (Optional)'}
      placeholderId={'no-id'}
      placeholderDefault={'Description'}
      value={input.value?.[rowIndex]?.description}
      onChange={(e) => {
        const newValue = drvNewValue(
          input,
          e.target.value,
          'description',
          rowIndex
        );
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderBoostFactorField = (props) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optCommunications_boostFactor'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.boostFactor}
      valid={!meta.error?.[constraintId]?.boostFactor}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Boost Factor'}
      placeholderId={'no-id'}
      placeholderDefault={'1.0'}
      value={input.value?.[rowIndex]?.boostFactor}
      onChange={(e) => {
        const newValue = drvNewValue(
          input,
          e.target.value,
          'boostFactor',
          rowIndex
        );
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderVariableCostsField = (props) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optCommunications_variableCosts'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.variableCosts}
      valid={!meta.error?.[constraintId]?.variableCosts}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Variable Costs'}
      placeholderId={'no-id'}
      placeholderDefault={'0.0'}
      value={input.value?.[rowIndex]?.variableCosts}
      onChange={(e) => {
        const newValue = drvNewValue(
          input,
          e.target.value,
          'variableCosts',
          rowIndex
        );
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderFixedCostsField = (props) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optCommunications_fixedCosts'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.fixedCosts}
      valid={!meta.error?.[constraintId]?.fixedCosts}
      disabled
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Fixed Costs'}
      placeholderId={'no-id'}
      placeholderDefault={'0.0'}
      value={input.value?.[rowIndex]?.fixedCosts}
      onChange={(e) => {
        const newValue = drvNewValue(
          input,
          e.target.value,
          'fixedCosts',
          rowIndex
        );
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderChannelField = (props) => {
  const { input, meta, channels, rowIndex, constraintId } = props;

  const channelOptions: DescriptionOptionType[] = (channels || []).map(
    (channel) => ({
      value: channel.id,
      label: channel.name,
      description: channel.description,
    })
  );

  return (
    <DropdownSelectInput
      id={'optCommunications_channel'}
      label={{
        id: 'no-id',
        defaultMessage: 'Allocate Channel',
      }}
      placeholder={{
        id: 'no-id',
        defaultMessage: 'Channel',
      }}
      options={channelOptions}
      onFocus={input.onFocus}
      onBlur={() => input.onBlur(input.value)}
      onChange={(option: DescriptionOptionType) => {
        const newValue = drvNewValue(
          input,
          option.value,
          'channelId',
          rowIndex
        );
        input.onChange(newValue);
      }}
      value={channelOptions.find(
        (o) => o.value === input.value?.[rowIndex]?.channelId
      )}
      error={meta.error?.[constraintId]?.channelId}
      touched={meta.touched}
      valid={!meta.error?.[constraintId]?.channelId}
      customComponents={{
        Option: DescriptionOption,
      }}
    />
  );
};

const renderPropensityField = (props) => {
  const { input, meta, propensities, rowIndex, constraintId } = props;

  const propensityOptions: DescriptionOptionType[] = (propensities || []).map(
    (propensity: Propensity) => ({
      value: propensity.id,
      label: propensity.valueId,
      description: propensity.description,
    })
  );

  return (
    <DropdownSelectInput
      id={'optCommunications_propensity'}
      label={{
        id: 'no-id',
        defaultMessage: 'Allocate Propensity',
      }}
      placeholder={{
        id: 'no-id',
        defaultMessage: 'Propensity',
      }}
      options={propensityOptions}
      onFocus={input.onFocus}
      onBlur={() => input.onBlur(input.value)}
      onChange={(option: DescriptionOptionType) => {
        const newValue = drvNewValue(
          input,
          option.value,
          'propensityId',
          rowIndex
        );
        input.onChange(newValue);
      }}
      value={propensityOptions.find(
        (o) => o.value === input.value?.[rowIndex]?.propensityId
      )}
      error={meta.error?.[constraintId]?.propensityId}
      touched={meta.touched}
      valid={!meta.error?.[constraintId]?.propensityId}
      customComponents={{
        Option: DescriptionOption,
      }}
    />
  );
};

const AddCommunication: FC<Props & WrappedFieldProps> = (props) => {
  const { input, rowIndex, removableRows, onRemoveRow } = props;

  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={commonStyles.RemoveIcon}
            onClick={() => onRemoveRow && onRemoveRow(input.value[rowIndex])}
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div className={commonStyles.addElementField}>
          {renderChannelField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderPropensityField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.boostFactorField
          )}
        >
          {renderBoostFactorField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderVariableCostsField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderFixedCostsField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderNameField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderDescriptionField(props)}
        </div>
      </div>
    </div>
  );
};

export default AddCommunication;
