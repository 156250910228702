import {
  formName,
  fieldName,
} from '../../../components/workbench/part-right/wizards/file/file.form';

export const getFileName = (state) => {
  if (!(state.form && state.form[formName] && state.form[formName].values))
    return null;
  return state.form[formName].values[fieldName];
};
