import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import App from '../App.container';
import { FiX } from 'react-icons/fi';

const customStyles = {
  content: {
    top: '130px',
    left: '80px',
    right: '80px',
    bottom: 'auto',
    // translate(50%, ...) doesn't work here since this will break the draggable items
  },
};

export default class AppArrangeModal extends Component {
  render() {
    const { isShown, hideAppArrangeModal } = this.props;
    return (
      <ReactModal
        isOpen={isShown}
        contentLabel='Dialog Modal'
        onRequestClose={() => hideAppArrangeModal()}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer')
          /* Function that will be called to get the parent element
             that the modal will be attached to. */
        }
      >
        <div className={'app-arrange-modal modal-parent'}>
          <div
            title={'Close Dialog'}
            className={'workbench-button close-button'}
            onClick={() => hideAppArrangeModal()}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>
          <App isArrangeEditor />
        </div>
      </ReactModal>
    );
  }
}
AppArrangeModal.propTypes = {
  isShown: PropTypes.bool,
  hideAppArrangeModal: PropTypes.func.isRequired,
};
AppArrangeModal.defaultProps = {
  isShown: false,
};
