import { connect } from 'react-redux';
import Python3InputCell from './Python3InputCell';
import {
  addInputElement,
  addVariable,
  executeVariables,
  hideAddInputElement,
  showAddInputElement,
  showEditInputElement,
  toggleInputElements,
  toggleInputVariables,
} from '../../../../../../../../../redux/workbench/modules/cells.app.module';

export function mapStateToProps(state) {
  return {
    addInputElementModal: state.workbench.addInputElementModal,
  };
}

export const mapDispatchToProps = {
  addInputElement,
  addVariable,
  executeVariables,
  toggleInputElements,
  toggleInputVariables,
  showAddInputElement,
  hideAddInputElement,
  showEditInputElement,
};

export default connect(mapStateToProps, mapDispatchToProps)(Python3InputCell);
