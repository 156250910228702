import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const fetchDataSources = createAction('fetch data sources');

export const fetchDataSourcesSuccess = createAction(
  'fetch data sources - success',
  (data) => ({ data })
);

export const fetchDataSourcesFailure = createAction(
  'fetch data sources - failure',
  (error) => ({ error })
);

export const addDataSource = createAction('add data source', (dataSource) => ({
  dataSource,
}));

export const addDataSourceSuccess = createAction('add data source - success');

export const addDataSourceFailure = createAction(
  'add data source - failure',
  (error) => ({ error })
);

export const deleteDataSource = createAction(
  'delete data source',
  (dataSourceCode) => ({ dataSourceCode })
);

export const deleteDataSourceSuccess = createAction(
  'delete data source - success'
);

export const deleteDataSourceFailure = createAction(
  'delete data source - failure',
  (error) => ({ error })
);

export const fetchDataSourceSettings = createAction(
  'fetch data source settings',
  (dataSourceCode) => ({ dataSourceCode })
);

export const fetchDataSourceSettingsSuccess = createAction(
  'fetch data source settings - success',
  (dataSourceCode, settings) => ({ dataSourceCode, settings })
);

export const fetchDataSourceSettingsFailure = createAction(
  'fetch data source settings - failure',
  (error) => ({ error })
);

export const updateDataSource = createAction(
  'update data source',
  (dataSourceCode, dataSource) => ({ dataSourceCode, dataSource })
);

export const updateDataSourceSuccess = createAction(
  'update data source - success'
);

export const updateDataSourceFailure = createAction(
  'update data source - failure',
  (error) => ({ error })
);

export const hideDeleteDataSourceConfirm = createAction(
  'hide delete data source confirm'
);

export const showDeleteDataSourceConfirm = createAction(
  'show delete data source confirm',
  (name, code) => ({ name, code })
);

export const reducer = {
  [fetchDataSources]: (state) => ({
    ...state,
    data: {
      ...state.data,
      dataSources: {
        ...state.data.dataSources,
        loading: true,
        error: undefined,
      },
    },
  }),
  [fetchDataSourcesSuccess]: (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      dataSources: {
        ...state.data.dataSources,
        loading: false,
        loaded: true,
        data,
      },
    },
  }),
  [fetchDataSourcesFailure]: (state, { error }) => ({
    ...state,
    data: {
      ...state.data,
      dataSources: {
        ...state.data.dataSources,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
  [fetchDataSourceSettingsSuccess]: (state, { dataSourceCode, settings }) => ({
    ...state,
    data: {
      ...state.data,
      dataSources: {
        ...state.data.dataSources,
        data: state.data.dataSources.data.map((ds) => {
          if (ds.code === dataSourceCode) {
            return { ...ds, settings };
          }
          return ds;
        }),
      },
    },
  }),
  [hideDeleteDataSourceConfirm]: (state) => ({
    ...state,
    data: {
      ...state.data,
      manageDS: {
        ...state.data.manageDS,
        deleteDataSourceConfirm: {
          ...state.data.manageDS.deleteDataSourceConfirm,
          show: false,
        },
      },
    },
  }),
  [showDeleteDataSourceConfirm]: (state, { name, code }) => ({
    ...state,
    data: {
      ...state.data,
      manageDS: {
        ...state.data.manageDS,
        deleteDataSourceConfirm: {
          show: true,
          name,
          code,
        },
      },
    },
  }),
};

export function* fetchDataSourcesSaga() {
  const { response, error } = yield call(Api.data.fetchDataSources);
  if (response) {
    yield put(fetchDataSourcesSuccess(response));
  } else {
    yield put(fetchDataSourcesFailure(error));
  }
}

export function* watchFetchDataSources() {
  yield takeEvery(fetchDataSources.getType(), fetchDataSourcesSaga);
}

export function* fetchDataSourceSettingsSaga({ payload: { dataSourceCode } }) {
  const { response, error } = yield call(
    Api.data.fetchDataSourceSettings,
    dataSourceCode
  );
  if (response) {
    yield put(fetchDataSourceSettingsSuccess(dataSourceCode, response));
  } else {
    yield put(fetchDataSourceSettingsFailure(error));
  }
}

export function* watchFetchDataSourceSettings() {
  yield takeEvery(
    fetchDataSourceSettings.getType(),
    fetchDataSourceSettingsSaga
  );
}

export function* updateDataSourceSaga({
  payload: { dataSourceCode, dataSource },
}) {
  const { response, error } = yield call(
    Api.data.updateDataSource,
    dataSourceCode,
    dataSource
  );
  if (response) {
    yield put(updateDataSourceSuccess());
    yield put(fetchDataSources());
  } else {
    yield put(updateDataSourceFailure(error));
  }
}

export function* watchUpdateDataSource() {
  yield takeEvery(updateDataSource.getType(), updateDataSourceSaga);
}

export function* addDataSourceSaga({ payload: { dataSource } }) {
  const { response, error } = yield call(Api.data.addDataSource, dataSource);
  if (response) {
    yield put(addDataSourceSuccess());
    yield put(fetchDataSources());
  } else {
    yield put(addDataSourceFailure(error));
  }
}

export function* watchAddDataSource() {
  yield takeEvery(addDataSource.getType(), addDataSourceSaga);
}

export function* deleteDataSourceSaga({ payload: { dataSourceCode } }) {
  const { response, error } = yield call(
    Api.data.deleteDataSource,
    dataSourceCode
  );
  if (response) {
    yield put(deleteDataSourceSuccess());
    yield put(fetchDataSources());
  } else {
    yield put(deleteDataSourceFailure(error));
  }
}

export function* watchDeleteDataSource() {
  yield takeEvery(deleteDataSource.getType(), deleteDataSourceSaga);
}
