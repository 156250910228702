import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { usersRoutes } from './routes';
import Groups from './groups/Groups.container';
import S3Permissions from './s3-permissions/S3Permissions';
import AdminUserDetails from './user-details/AdminUserDetails.container';
import S3BucketDetails from './s3-bucket-details/S3BucketDetails.container';
import styles from './styles.module.scss';
import WithSidemenu from '../../molecules/with-sidemenu/WithSidemenu';
import {
  FiDatabase,
  FiMonitor,
  FiSettings,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import * as routes from '../../index/routes';
import AdminAddUserContainer from './add-user/AdminAddUserWizard.container';
import AdminAddGroupContainer from './add-group/AdminAddGroup.container';
import CassandraPermissions from './cassandra-permissions/CassandraPermissions';
import CassandraDetails from './cassandra-details/CassandraDetails';
import SubHeader from '../../organisms/sub-header/SubHeader.container';
import OverlayUsersAndPermissions from '../../organisms/sub-header/overlay-users-and-permissions/OverlayUsersAndPermissions';
import GeneralPermissions from './general-permissions/GeneralPermissions';
import AppPermissions from './app-permissions/AppPermissions';
import { MenuEntryType } from '../../molecules/with-sidemenu/SideMenu';
import GeneralDetails from './general-details/GeneralDetails';
import GroupDetails from './group-details/GroupDetails.container';
import Habitats from './habitats/Habitats.container';
import HabitatDetails from './habitat-details/HabitatDetails.container';
import AppDetails from './app-details/AppDetails';
import { UsersAndPermissionsWhitelistQuery } from './types';
import UsersList from './user-list/UsersList';

const menuEntries: MenuEntryType[] = [
  {
    type: 'headline',
    headline: {
      id: 'no-id',
      defaultMessage: 'Users & Groups',
    },
  },
  {
    type: 'link',
    id: 'list',
    activePerDefault: true,
    to: usersRoutes.userList.path,
    nameIntlId: 'dashboard.tab.users.userlist',
    nameDefault: 'Users',
    icon: FiUser,
  },
  {
    type: 'link',
    id: 'groups',
    activePerDefault: false,
    to: usersRoutes.groups.path,
    nameIntlId: 'dashboard.tab.users.groups',
    nameDefault: 'Groups',
    icon: FiUsers,
  },
  {
    type: 'link',
    id: 'habitats',
    activePerDefault: false,
    to: usersRoutes.habitats.path,
    nameIntlId: 'todo',
    nameDefault: 'Habitats',
    // TODO this does not look quite right yet: hover while selected
    icon: () => <span className={'icon-home'} />,
  },
  {
    type: 'hr',
  },
  {
    type: 'headline',
    headline: {
      id: 'no-id',
      defaultMessage: 'Permissions',
    },
  },
  {
    type: 'link',
    id: 'generalPermissions',
    activePerDefault: false,
    to: usersRoutes.generalPermissions.path,
    nameIntlId: 'dashboard.tab.users.general_permissions',
    nameDefault: 'General',
    icon: FiSettings,
  },
  {
    type: 'link',
    id: 'appPermissions',
    activePerDefault: false,
    to: usersRoutes.appPermissions.path,
    nameIntlId: 'dashboard.tab.users.app_permissions',
    nameDefault: 'Apps',
    icon: FiMonitor,
  },
  {
    type: 'link',
    id: 'cassandraPermissions',
    activePerDefault: false,
    to: usersRoutes.cassandraPermissions.path,
    nameIntlId: 'dashboard.tab.users.cassandra_permissions',
    nameDefault: 'Cassandra',
    icon: FiDatabase,
  },
  {
    type: 'link',
    id: 's3Permissions',
    activePerDefault: false,
    to: usersRoutes.s3Permissions.path,
    nameIntlId: 'dashboard.tab.users.s3_bucket_permissions',
    nameDefault: 'S3 Buckets',
    icon: FiDatabase,
  },
];

function getActiveCategory(history) {
  const path = history.location.pathname;
  if (!path) return null;
  const rep = path.replace(`${usersRoutes.basePath}/`, '');
  const parts = rep.split('/');

  const active = parts[0];

  // --- Treat some special cases
  if (active === 'addUser' || active === 'user') return 'list';
  if (active === 'addGroup') return 'groups';

  return parts[0];
}

const UsersAndPermissions: FC = () => {
  const history = useHistory();
  const selectedCategory = getActiveCategory(history);
  const handleSelect = (selectedTab, query) =>
    history.push({
      pathname: `${usersRoutes.basePath}/${selectedTab}`,
      search: query,
    });

  return (
    <div className={styles.container}>
      <SubHeader hasOverlay Overlay={OverlayUsersAndPermissions} />

      {/* <HeaderUsers /> */}
      <WithSidemenu
        menuEntries={menuEntries}
        selectedTab={selectedCategory}
        handleSelect={handleSelect}
        preserveWhitelistQueryOnLink={UsersAndPermissionsWhitelistQuery}
      >
        <div className={styles.content}>
          <Switch>
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.userList.path}`}
              component={UsersList}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.groups.path}/:groupId`}
              component={GroupDetails}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.groups.path}`}
              component={Groups}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.habitats.path}/:groupIds`}
              component={HabitatDetails}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.habitats.path}`}
              component={Habitats}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.generalPermissions.path}/:id`}
              component={GeneralDetails}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.generalPermissions.path}`}
              component={GeneralPermissions}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.appPermissions.path}/:id`}
              component={AppDetails}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.appPermissions.path}`}
              component={AppPermissions}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.cassandraPermissions.path}/:cassandraCode/:keyspace`}
              component={CassandraDetails}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.cassandraPermissions.path}`}
              component={CassandraPermissions}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.s3Permissions.path}/:dataSourceCode/:bucketName`}
              component={S3BucketDetails}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.s3Permissions.path}`}
              component={S3Permissions}
            />
            <Route
              path={`${usersRoutes.basePath}/${usersRoutes.adminUserDetails.path}/:userId`}
              component={AdminUserDetails}
            />
            <Route
              path={`${usersRoutes.basePath}/${routes.app.admin.addUser}`}
              component={AdminAddUserContainer}
            />
            <Route
              path={`${usersRoutes.basePath}/${routes.app.admin.addGroup}`}
              component={AdminAddGroupContainer}
            />
            <Redirect
              path={usersRoutes.basePath}
              to={`${usersRoutes.basePath}/${usersRoutes.userList.path}`}
            />
          </Switch>
        </div>
      </WithSidemenu>
    </div>
  );
};

export default UsersAndPermissions;
