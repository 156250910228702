import React, { Component } from 'react';
import BorderBox from '../../../../../molecules/border-box/BorderBox';
import { Link } from 'react-router-dom';
import { FaCheckCircle, RiErrorWarningFill } from 'react-icons/all';
// @ts-ignore
import { collaborationSpaceRoutes } from '../../../../../collaborationSpace/routes';
import { MergeRequest } from '../../../../giteaTypes';
import Busy from '../../../../../atoms/busy/Busy';
import { RefStatusData } from '../../../../../../store/workbench/state.types';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import Button from '../../../../../atoms/button/Button';

export type Commit = {
  /** Author of the Commit */
  author: string;
  message: string;
  sha: string;
  timestamp: string;
};

export type Props = {
  /** ID of the Merge Request */
  id: number;
  /** Repository group */
  group: string;
  /** Repository name */
  repositoryName: string;

  /** Is the MergeRequest information from Gitea loading? */
  mrLoading: boolean;
  /** Is the MergeRequest information from Gitea loaded? */
  mrLoaded: boolean;
  /** Possible error while loading the MergeRequest information from Gitea */
  mrError?: ApiError;
  /** MergeRequest information from Gitea */
  mrData?: MergeRequest;

  /** Is the Ref Status information from the Merger API loading? */
  refStatusLoading: boolean;
  /** Has the Ref Status information from the Merger API loaded? */
  refStatusLoaded: boolean;
  /** Possible error while loading the Ref Status information from the Merger API */
  refStatusError?: ApiError;
  /** Ref Status from the Merger API */
  refStatusData?: RefStatusData;

  /** Function to switch the branch TODO: What is this required for? */
  switchBranch: (branchName: string) => void;
  /** Function to merge the Merge Request */
  mergeMergeRequest: (repoGroup: string, repoName: string, id: number) => void;

  /** Function to fetch the amount of commits ahead/behind of two branches */
  fetchRefStatus: (repository: string, ref1: string, ref2: string) => void;
};
export default class MergeRequestOverview extends Component<Props, {}> {
  componentDidMount() {
    const { fetchRefStatus, group, repositoryName, mrData } = this.props;

    // Pick out the branch names from the Merge Request details
    const sourceBranchName = ((mrData || {}).head || {}).ref;
    const targetBranchName = ((mrData || {}).base || {}).ref;

    if (sourceBranchName && targetBranchName) {
      // Fetch the information about commits ahead / behind
      fetchRefStatus(
        `/${group}/${repositoryName}.git`,
        `origin/${sourceBranchName}`,
        `origin/${targetBranchName}`
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { fetchRefStatus, group, repositoryName, mrData } = this.props;

    // Pick out the branch names from the Merge Request details
    const sourceBranchName = ((mrData || {}).head || {}).ref;
    const targetBranchName = ((mrData || {}).base || {}).ref;
    const prevSourceBranchName = ((prevProps.mrData || {}).head || {}).ref;
    const prevTargetBranchName = ((prevProps.mrData || {}).base || {}).ref;

    // If the source and target branch names have been loaded now, create the merge request and fetch the ref status
    if (sourceBranchName && targetBranchName) {
      if (
        sourceBranchName !== prevSourceBranchName ||
        targetBranchName !== prevTargetBranchName
      ) {
        // Fetch the information about commits ahead / behind
        fetchRefStatus(
          `/${group}/${repositoryName}.git`,
          `origin/${sourceBranchName}`,
          `origin/${targetBranchName}`
        );
      }
    }
  }

  /**
   * Rendered while the MergeRequest details are still fetched from Gitea
   */
  renderLoading() {
    return (
      <div className={'merge-request-tab merge-request-overview'}>
        <Busy isBusy positionAbsolute />
      </div>
    );
  }

  /**
   * Rendered if there was an error while fetching the MergeRequest from Gitea
   */
  renderError() {
    const { mrError } = this.props;
    return (
      <div className={'merge-request-tab merge-request-overview'}>
        <div className={'description error'}>
          Error: {JSON.stringify(mrError)}
        </div>
      </div>
    );
  }

  /**
   * Rendered when the MergeRequest details were fetched successfully from Gitea
   */
  renderLoaded() {
    const { mrData, group, repositoryName, switchBranch, refStatusData } =
      this.props;
    const sourceBranchName = mrData.head.ref;
    const targetBranchName = mrData.base.ref;
    const mergeable = mrData.mergeable;

    return (
      <div className={'merge-request-tab merge-request-overview'}>
        <div className={'description'}>{mrData.body}</div>

        <BorderBox>
          <div className={'status-box'}>
            <div className={'top'}>
              Request to merge branch&nbsp;
              <Link
                to={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}`}
                onClick={() => switchBranch(sourceBranchName)}
                style={{ textDecoration: 'none' }}
              >
                <span className={'branch-name'}>{sourceBranchName}</span>
              </Link>
              &nbsp;into branch&nbsp;
              <Link
                to={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}`}
                onClick={() => switchBranch(targetBranchName)}
                style={{ textDecoration: 'none' }}
              >
                <span className={'branch-name'}>{targetBranchName}</span>
              </Link>
            </div>

            <div>
              {refStatusData &&
                refStatusData?.behind?.amount > 0 &&
                `The source branch is ${refStatusData.behind} commits behind the target branch.`}
            </div>
          </div>
        </BorderBox>

        <BorderBox
          style={{
            alignItems: 'center',
          }}
        >
          {mergeable ? this.renderMergeable() : this.renderNotMergeable()}
        </BorderBox>
      </div>
    );
  }

  /**
   * Merge Actions if the MergeRequest is ready to be merged
   */
  renderMergeable() {
    const { mergeMergeRequest, group, repositoryName, id, mrData } = this.props;
    return (
      <div className={'conflict-box'}>
        <div>There are no Merge Conflicts.</div>
        {!mrData.merged ? (
          <Button
            buttonColor={'primary'}
            withLink={false}
            buttonLabelDefault={'Merge'}
            onClick={() => mergeMergeRequest(group, repositoryName, id)}
          />
        ) : (
          <div className={'checked'}>
            <FaCheckCircle className={'checked-icon'} size={'30px'} />
            <span>Merged</span>
          </div>
        )}
      </div>
    );
  }

  renderNotMergeable() {
    return (
      <div className={'conflict-parent'}>
        <div className={'conflict-box'}>
          <div>
            There are merge conflicts. Please resolve them before continuing to
            merge.
          </div>
          <RiErrorWarningFill className={'error-icon'} size={'36px'} />
        </div>
      </div>
    );
  }

  render() {
    const { mrLoading, mrLoaded, mrError, mrData } = this.props;

    if (mrLoading) return this.renderLoading();
    else if (mrError) return this.renderError();
    else if (mrLoaded && mrData) return this.renderLoaded();
    else return <div />; //Never supposed to happen, but just to be sure.
  }
}
