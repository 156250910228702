import { connect, ConnectedProps } from 'react-redux';
import JobHistory from './JobHistory';
import { fetchHistory } from '../../../redux/modules/orchestration.history.module';
import { DeprecatedRootState } from '../../../store/state.type';
import { JobGroupInputType } from 'common/dist/types/job';

interface StateProps {
  pageSize: number;
  totalCount: number;
  jobGroupCodes: string[];
  loading: boolean;
  loaded: boolean;
  error: string;
  jobGroups: JobGroupInputType[];
}

export function mapStateToProps(state: DeprecatedRootState): StateProps {
  const {
    pageSize,
    totalCount,
    codes,
    loading,
    loaded,
    error,
  }: {
    pageSize: number;
    totalCount: number;
    codes: string[];
    loading: boolean;
    loaded: boolean;
    error: string;
    jobGroups: JobGroupInputType[];
  } = state.jobGroupHistory;
  return {
    pageSize,
    totalCount,
    jobGroupCodes: codes,
    loading,
    loaded,
    error,
    jobGroups: state.jobGroupHistory.jobGroups,
  };
}

export const mapDispatchToProps: {
  fetchHistory: (offset: number, limit: number, search?: string) => void;
} = {
  fetchHistory,
};

export type connectedProps = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(JobHistory);
