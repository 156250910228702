import AugurDetails from '../../../common/_interfaces/augur-details/AugurDetails';
import { AugurDetailsTab } from '../../../common/_interfaces/augur-details/AugurDetailsTab';
import ModelsHistoryWrapper from '../../../common/augur-details-tabs/modelhistory/ModelsHistory.wrapper';
import SettingsWrapper from '../../../common/augur-details-tabs/settings/Settings.wrapper';
import AugurSettingsCategory from '../../../common/_interfaces/augur-details/settings/AugurSettingsCategory';
import AugurBiographyWrapper from '../../../common/augur-details-tabs/augur-biography/AugurBiography.wrapper';
import NamesAndCodesCategory from '../../../common/augur-details-tabs/settings/augurSettingsCategories/namesAndCodes/NamesAndCodesCategory';
import OptCustomerTableWrapper from '../../../common/augur-details-tabs/optCustomerTable/OptCustomerTable.wrapper';
import OptConstraintsWrapper from '../../../common/augur-details-tabs/optConstraints/OptConstraints.wrapper';
import OptContactPoliciesWrapper from '../../../common/augur-details-tabs/optContactPolicies/OptContactPolicies.wrapper';
import OptTargetFunctionWrapper from '../../../common/augur-details-tabs/optTargetFunction/OptTargetFunction.wrapper';
import OptConstraintExhaustionDetailsWrapper from '../../../common/augur-details-tabs/optConstraintExhaustion/OptConstraintExhaustion.wrapper';

/**
 * Augur Details for the binary classification (event-based) augur
 */
export default class BcAugurDetails extends AugurDetails {
  // Specification of the sub-components of the Settings tab
  settingsCategories: AugurSettingsCategory[] = [new NamesAndCodesCategory()];

  // Tabs to show on the left in the Augur Details view
  tabs: (inputFormat: string | undefined) => AugurDetailsTab[] = (
    inputFormat
  ) => {
    return [
      {
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Details' },
      },
      new OptConstraintExhaustionDetailsWrapper(),
      {
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Model Settings' },
      },
      new OptCustomerTableWrapper(),
      new OptConstraintsWrapper(),
      new OptContactPoliciesWrapper(),
      new OptTargetFunctionWrapper(),

      { type: 'hr' },
      {
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Model Management' },
      },
      new ModelsHistoryWrapper(),
      new AugurBiographyWrapper(),

      { type: 'hr' },
      {
        type: 'headline',
        headline: {
          id: 'details.sideNav.settings',
          defaultMessage: 'Settings',
        },
      },
      new SettingsWrapper(this.settingsCategories),
    ];
  };
}
