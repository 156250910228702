import React, { FC, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import GraphWait from '../../../../../../components/details/GraphWait';
import KpiSelect from './KpiSelect';
import Busy from '../../../../../../components/atoms/busy/Busy';
import PerformanceDriftComponent from '../../../../../../components/organisms/performance-drift/PerformanceDrift';
import qs from 'qs';
import { JobDescriptionBanner } from '../job-banner/JobDescriptionBanner';
import { AugurDetailsQueryParams, AugurDetailsRouteParams } from '../types';
import { useLocation, useParams } from 'react-router-dom';
import detailsMessages from 'common/dist/messages/augurs.details';
import { EvaluationReportType } from 'common/dist/types/reports';
import { DriftDataEntry } from '../accuracyDetails/types';
import { UseQueryResult } from 'react-query';
import { useReports } from '../../../../../../core/api/reports';

const DRIFT_VALUES_LIMIT = 100;

export const getTimeSeriesDriftDataFromReports = (
  reports: EvaluationReportType[],
  kpi: string
): DriftDataEntry[] => {
  const timeSeries = reports.reduce((acc, report) => {
    const entry = {
      data: report.data.performanceDrift[kpi][0], //why is this even an array
      time: report.data.startedAt,
    };
    return [...acc, entry];
  }, [] as DriftDataEntry[]);
  timeSeries.sort((a, b) => (a.time < b.time ? -1 : 1));
  return timeSeries;
};

const PerformanceDrift: FC = () => {
  const location = useLocation();

  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const { modelCode } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as AugurDetailsQueryParams;

  const [kpi, setKpi] = useState('accuracy');

  const {
    isLoading,
    isSuccess,
    data: reports,
  } = useReports(
    habitatCode,
    augurCode,
    modelCode,
    'evaluation',
    0,
    DRIFT_VALUES_LIMIT
  ) as UseQueryResult<EvaluationReportType[]>;

  const data = useMemo(() => {
    return reports ? getTimeSeriesDriftDataFromReports(reports, kpi) : [];
  }, [reports, kpi]);

  const renderLoaded = () => (
    <div>
      <div className='page--bar'>
        <div className='performance--row'>
          <div className='performance--col--left'>
            <div className='settings-performance-block'>
              <KpiSelect value={kpi} onChange={setKpi} />
            </div>
          </div>
          <div className='performance--col--center'>
            <div className='performance--infos'>
              <FormattedMessage
                id='augur.performance_drift.changes_description'
                defaultMessage='Changes in the training data result over the last 30 runs'
              />
            </div>
          </div>
          <div className='performance--col--right'>&nbsp;</div>
        </div>
      </div>
      <PerformanceDriftComponent timeSeries={data} kpi={kpi} />
    </div>
  );

  return (
    <JobDescriptionBanner>
      <div style={{ height: 700 }}>
        <Busy isBusy={isLoading}>
          <div className='h100p'>
            {(!reports || reports.length === 0) && (
              <GraphWait jobType={'evaluation'}>
                <FormattedMessage {...detailsMessages.msgChartNotAvailable} />
              </GraphWait>
            )}
            {isSuccess && reports.length !== 0 && renderLoaded()}
          </div>
        </Busy>
      </div>
    </JobDescriptionBanner>
  );
};

export default PerformanceDrift;
