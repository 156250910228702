import React, { FC } from 'react';
import { OptionProps } from 'react-select';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type DefaultOptionComponentProps = OptionProps<
  { label: string; value: string },
  false
>;
const DefaultOptionComponent: FC<DefaultOptionComponentProps> = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <div
      {...innerProps}
      className={classNames(styles.defaultOptionComponent, {
        [styles.isSelected]: props.isSelected,
        [styles.isFocused]: props.isFocused,
      })}
      ref={innerRef}
      data-testingIdentifier={props.data.label}
    >
      <span className={styles.label}>{props.data.label}</span>
    </div>
  );
};

export default DefaultOptionComponent;
