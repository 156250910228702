import React, { FC, Fragment } from 'react';

import Bar from './Bar';
import { useIntl } from 'react-intl';
import RejectAutoForceToggle from '../../../../../../atoms/reject-auto-force-toggle/RejectAutoForceToggle';
import { FiSlash } from 'react-icons/all';
import { FiCheckCircle } from 'react-icons/fi';

export type Category = 'rejected' | 'auto' | 'forced';

export type Props = {
  updateRejectedFeatures: (features: string[], reject: boolean) => void;
  updateForcedFeatures: (features: string[], force: boolean) => void;

  feature: {
    feature;
    rejected;
    forced;
    importance;
    discarded;
  };
};

const InfluencerBar: FC<Props> = ({
  updateRejectedFeatures,
  updateForcedFeatures,
  feature: { feature, rejected, forced, importance, discarded },
}) => {
  const intl = useIntl();

  const update = (newValue: Category) => {
    // Depending on which button is selected the separate actions for forced and rejected features state are called
    // They each only add to the list if not present or remove if present, so the checking logic happens here

    updateRejectedFeatures([feature], newValue === 'rejected');
    updateForcedFeatures([feature], newValue === 'forced');
  };

  const renderActive = () => {
    return (
      <Fragment>
        <div
          className={'rejected-features-cell discarded-or-used-icon icon-used'}
        >
          <FiCheckCircle size={16} />
        </div>
        <div
          className={'rejected-features-cell feature-parent tooltip-sm'}
          data-tooltip={'Feature is used for the model'}
        >
          <span className='feature'>{feature}</span>
        </div>
      </Fragment>
    );
  };

  const renderRejected = () => {
    return (
      <Fragment>
        <div
          className={
            'rejected-features-cell discarded-or-used-icon icon-rejected'
          }
        >
          <FiSlash size={16} />
        </div>

        <div
          className='rejected-features-cell feature-parent tooltip-sm'
          data-tooltip={'Feature is manually rejected'}
        >
          <span className={'feature discarded'}>{feature}</span>
        </div>
      </Fragment>
    );
  };

  const renderDiscarded = () => {
    return (
      <Fragment>
        <div
          className={
            'rejected-features-cell discarded-or-used-icon icon-discarded'
          }
        >
          <FiSlash size={16} />
        </div>

        <div
          className='rejected-features-cell feature-parent tooltip-sm'
          data-tooltip={
            'Feature is auto-rejected:\n' +
            discarded
              .map((s) =>
                intl.formatMessage({
                  id: s,
                  defaultMessage: s,
                })
              )
              .join(', ')
          }
        >
          <span className={'feature discarded'}>{feature}</span>
        </div>
      </Fragment>
    );
  };

  const value = rejected ? 'rejected' : forced ? 'forced' : 'auto';

  return (
    <div className='rejected-features-row'>
      <span className='rejected-features-cell'>
        <Bar importance={importance} />
      </span>

      <div className='rejected-features-cell rejected'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <RejectAutoForceToggle value={value} onChange={update} />
        </div>
      </div>

      {!discarded && !rejected && renderActive()}
      {!discarded && rejected && renderRejected()}
      {discarded && renderDiscarded()}
    </div>
  );
};

export default InfluencerBar;
