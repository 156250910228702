import { createAction } from 'redux-act';

export const showMenu = createAction('show overlapping notebook menu');

export const hideMenu = createAction('hide overlapping notebook menu');

export const measuredParentWidth = createAction(
  'measured notebook tab links parent width',
  (width) => width
);

export const updateOverlappingTabs = createAction('update overlapping tabs');

export const reducer = {
  [showMenu]: (state) => ({
    ...state,
    overlappingNotebookTabs: {
      ...state.overlappingNotebookTabs,
      menuActive: true,
    },
  }),
  [hideMenu]: (state) => ({
    ...state,
    overlappingNotebookTabs: {
      ...state.overlappingNotebookTabs,
      menuActive: false,
    },
  }),
  [measuredParentWidth]: (state, width) => ({
    ...state,
    overlappingNotebookTabs: {
      ...state.overlappingNotebookTabs,
      parentWidth: width,
    },
  }),
  [updateOverlappingTabs](state) {
    const BUTTON_WIDTH = 47;
    const parentWidth =
      state.overlappingNotebookTabs && state.overlappingNotebookTabs.parentWidth
        ? state.overlappingNotebookTabs.parentWidth
        : 0;
    const notebooks = Object.values(state.notebooks);
    let totalTabWidth = 0;
    const overlappingTabs = [];
    notebooks.forEach((nb) => {
      totalTabWidth += nb.tabWidth;
      if (totalTabWidth > parentWidth - BUTTON_WIDTH) {
        // -47px since this is the width of the menu button
        overlappingTabs.push({
          name: nb.name,
          path: nb.path,
        });
      }
    });

    return {
      ...state,
      overlappingNotebookTabs: {
        ...state.overlappingNotebookTabs,
        overlappingTabs,
        totalTabWidth,
        menuIconActive: totalTabWidth > parentWidth - BUTTON_WIDTH,
      },
    };
  },
};
