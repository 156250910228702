import React, { Component } from 'react';
import { min, max } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import * as _ from 'lodash';

import Brush from './Brush';
import TimelineChart from './timeline-chart/TimelineChart';
import AxisBottom from './AxisBottom';

export type Domain = [number, number];

export type DataEntry = {
  /** For example: "2021-06-06 18:25:03" */
  time: string;
  value: number;
};

export type ConvertedDataEntry = {
  time: number;
  value: number;
};

export type Props = {
  width: number;
  height: number;
  data: DataEntry[];
  /** Optional callback that is invoked on changing selection */
  onChangeSelection?: (domain: Domain) => void;
  /** Optional override for the bar width. Default: 5*/
  barWidth?: number;
  showLineChart?: boolean;
};

type State = {
  /** Selected range */
  selection?: Domain;
};

class SelectableTimeline extends Component<Props, State> {
  static defaultProps = {
    width: 200,
    height: 100,
    barWidth: 5,
    showLineChart: true,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.changeSelection = this.changeSelection.bind(this);
  }

  changeSelection(selection: Domain) {
    const { onChangeSelection } = this.props;
    if (!_.isEqual(this.state.selection, selection)) {
      this.setState({ selection });
    }
    onChangeSelection && onChangeSelection(selection);
  }

  render() {
    const { width, height, data, barWidth, showLineChart } = this.props;

    const margin = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 70,
    };

    const axisHeight = 20;
    const paddingX = 10;

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const sliderHeight = chartHeight - axisHeight;

    if (_.isEmpty(data)) {
      return (
        <svg width={width} height={height}>
          <g transform={`translate(${margin.left}, ${margin.top})`}>
            <rect
              width={chartWidth}
              height={sliderHeight}
              stroke='#DBE1EB'
              fill='none'
            />
          </g>
        </svg>
      );
    }

    const convertedData: ConvertedDataEntry[] = data.map((element) => ({
      time: Date.parse(element.time),
      value: element.value,
    }));

    const xScale = scaleLinear()
      .domain([
        min(convertedData, (e) => e.time),
        max(convertedData, (e) => e.time),
      ])
      .range([barWidth / 2 + paddingX, chartWidth - barWidth / 2 - paddingX]);

    const yScale = scaleLinear()
      .domain([0, max(convertedData, (e) => e.value)])
      .range([0, sliderHeight - 10]);

    return (
      <svg
        width={width}
        height={height}
        style={{ width: '100%', height: '100%' }}
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <rect
            width={chartWidth}
            height={sliderHeight}
            stroke='#DBE1EB'
            fill='none'
          />
          {showLineChart && (
            <TimelineChart
              data={convertedData}
              xScale={xScale}
              yScale={yScale}
              height={sliderHeight}
              barWidth={barWidth}
              paddingX={paddingX}
              width={chartWidth}
            />
          )}
          <g transform='translate(0, 1)'>
            {data && data.length > 1 && (
              <Brush
                xScale={xScale}
                height={sliderHeight - 1}
                width={chartWidth - 1}
                selection={this.state.selection}
                onChange={this.changeSelection}
              />
            )}
          </g>
          <g transform={`translate(0, ${sliderHeight})`}>
            <AxisBottom scale={xScale} />
          </g>
        </g>
      </svg>
    );
  }
}

export default SelectableTimeline;
