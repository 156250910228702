import { connect } from 'react-redux';

import ModelManagementNoTopBar from './ModelManagementNoTopBar';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';
import { RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX } from 'common/dist/constants/keycloak';

export function mapStateToProps(state) {
  const permissions = state.dashboard?.permissions?.data || {};
  const missingPermissionsError = !hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX
  );

  return {
    missingPermissionsError,
  };
}

export default connect(mapStateToProps)(ModelManagementNoTopBar);
