import React, { FC } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import './styles.scss';
import vars from '../../../../../scss/base/var.module.scss';

type HistogramEntry = {
  /** Amount of elements in this bin */
  amount: number;
  /** Left boundary of the bin */
  from: number;
  /** Right boundary of the bin */
  to: number;
};

export type Props = {
  /** List of bins */
  data: HistogramEntry[];
  /** Width of the chart */
  width: number;
  /** Height of the chart */
  height: number;
};

const FlatHistogram: FC<Props> = ({ data, width, height }) => {
  const tooltip = (tooltipProps) => {
    const {
      data: { from, to, amount },
    } = tooltipProps;
    return (
      <div className={'FlatHistogram--tooltip table-reset'}>
        <table>
          <tr>
            <td className={'key'}>From:</td>
            <td className={'value'}>{from && from.toFixed(3)}</td>
          </tr>
          <tr>
            <td className={'key'}>To:</td>
            <td className={'value'}>{to && to.toFixed(3)}</td>
          </tr>
          <tr>
            <td className={'key'}>Amount:</td>
            <td className={'value'}>{amount}</td>
          </tr>
        </table>
      </div>
    );
  };

  return (
    <div
      className={'FlatHistogram'}
      style={{
        width,
        height,
      }}
    >
      <ResponsiveBar
        data={data}
        // @ts-ignore
        width={width}
        height={height}
        keys={['amount']}
        indexBy={'from'}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        padding={0}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={[vars.colorPrimary]}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridY={false}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[]}
        tooltip={tooltip}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

export default FlatHistogram;
