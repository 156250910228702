import React, { Component } from 'react';
import '../styles_common.scss';
// Not only sets the inner html, but also executes "<script>" tags, as required for example for plotly
import InnerHTML from 'dangerously-set-html-content';
import { DisplayData } from 'common/dist/utils/workbench/jupyterNotebookFormat';

export type Props = {
  data: DisplayData['data'];
};

export default class DisplayDataOutput extends Component<Props, {}> {
  render() {
    const { data } = this.props;

    const pngData = data['image/png'];
    const htmlData = data['text/html'] as string;
    const jupyterWidgetViewData =
      data['application/vnd.jupyter.widget-view+json'];

    const plainData = data['text/plain']; // Will only be shown if no other alternative is given

    return (
      <div className={'CodeCellOutputs'}>
        <div className={'output-container-buttons'}>{}</div>
        <div
          className={'CodeCellOutputs--container display-data-output-container'}
        >
          {pngData && (
            <img alt={'plot'} src={`data:image/jpeg;base64,${pngData}`} />
          )}
          {htmlData && <InnerHTML html={htmlData} />}
          {!pngData && !htmlData && !jupyterWidgetViewData && (
            <p>{plainData}</p>
          )}
        </div>
      </div>
    );
  }
}
