import { ConfigProps } from 'redux-form';
import { Props } from './TargetList';

export const formName = 'augur-settings-predictionTargets';

export const fieldPredictionTargets = 'predictionTargets';

export const form: ConfigProps<FormValues, Props> = {
  form: formName,
  enableReinitialize: true,
};
// --- Type of the form values
export type FormValues = {
  [fieldPredictionTargets]: {
    predictionTargets: string[];
  };
};
