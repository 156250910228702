import { connect } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import OptimizationNewAugurWizard, {
  OwnProps,
} from './OptimizationNewAugurWizard';

function mapStateToProps(state: RootState, { formValueSelector }: OwnProps) {
  const archetypes = state.archetypes?.data;

  return {
    archetypes,
  };
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptimizationNewAugurWizard);
