import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { search } from '../../../../redux/modules/dashboard.search.module';
import SearchBar from './SearchBar';
import { DeprecatedRootState } from '../../../../store/state.type';

export function onSubmit({ query }, dispatch) {
  dispatch(search(query));
}

export const form = {
  form: 'dashboardSearch',
  onSubmit,
};

const selector = formValueSelector('dashboardSearch');

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    loading: state.dashboard.search.loading,
    loaded: state.dashboard.search.loaded,
    error: state.dashboard.search.error,
    result: state.dashboard.search.data,
    query: selector(state, 'query'),
  };
}

export const mapDispatchToProps = {
  search,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(reduxForm(form)(SearchBar));
