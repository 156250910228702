import React, { Component } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { TransitionGroup, Transition } from 'react-transition-group';
import { ExtendedErrorOutput } from './ExtendedErrorOutput';
import '../styles_common.scss';
import { Error } from 'common/dist/utils/workbench/jupyterNotebookFormat';

export type Props = {
  ename: Error['ename'];
  evalue: Error['evalue'];
  traceback: Error['traceback'];
};

type State = {
  collapsed: boolean;
};

export default class ErrorOutput extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  render() {
    return (
      <div className={'CodeCellOutputs'}>
        <div className={'output-container-buttons'}>
          <div
            className={'output-button output-button-error'}
            onClick={() => this.setState({ collapsed: !this.state.collapsed })}
          >
            {this.state.collapsed ? (
              <FiPlus className={'icon play-icon'} size={20} />
            ) : (
              <FiMinus className={'icon play-icon'} size={20} />
            )}
          </div>
        </div>

        <div
          className={
            'CodeCellOutputs--container CodeCellOutputs--container-error'
          }
        >
          <p className={'output-error-name'}>{this.props.ename}</p>

          <TransitionGroup>
            {!this.state.collapsed && (
              <Transition timeout={200}>
                {(state) => (
                  <ExtendedErrorOutput
                    evalue={this.props.evalue}
                    traceback={this.props.traceback}
                    status={state}
                  />
                )}
              </Transition>
            )}
          </TransitionGroup>
        </div>
      </div>
    );
  }
}
