import { CommunicationGroupsValueType } from '../../../../../organisms/modelManagement/newDatapoolWizard/optCommunicationGroups/OptCommunicationGroups';
import { validateCommunicationGroups } from '../../../../../organisms/modelManagement/newDatapoolWizard/optCommunicationGroups/validate';

export const formName = 'communicationGroups';

export const fieldCommunicationGroups = 'communicationGroups';

export type FormValues = {
  [fieldCommunicationGroups]: CommunicationGroupsValueType;
};

const validate = (values: FormValues) => {
  const errors = {};

  errors[fieldCommunicationGroups] = validateCommunicationGroups(
    values[fieldCommunicationGroups]
  );

  return errors;
};

export const communicationGroupsForm = {
  form: formName,
  enableReinitialize: true,
  validate,
  touchOnChange: true,
};
