export const formName = 'input-modal-form';

export const fieldNameLabel = 'label';
export const fieldNameDescription = 'description';
export const fieldNameVariableName = 'outputVariable';
export const fieldNameDefaultValue = 'defaultValue';
export const fieldNameLowerBound = 'lowerBound';
export const fieldNameUpperBound = 'upperBound';
export const fieldNameStepSize = 'stepSize';
export const fieldNameSelectableValues = 'selectableValues';
export const fieldNameTargetPath = 'targetPath';
export const fieldCategories = 'categories';
export const fieldDefaultCategory = 'defaultCategory';
export const fieldData = 'data';
export const fieldColors = 'colors';
export const fieldInputTable = 'inputTable';
export const fieldTableData = 'tableData';

export const fieldDownloadPath = 'downloadPath';
export const fieldDownloadButtonLabel = 'downloadButtonLabel';

export const form = {
  form: formName,
};

const variableNameRegex = /^[a-zA-Z_][\w]*$/;

/**
 * Validation function for the common setting fields: label, description, outputVariable
 * @param values
 */
export function validateCommonSettings(values, variables) {
  const errors = {};

  // --- Validate the Label
  const labelField = values[fieldNameLabel];
  const label = labelField ? labelField.inputValue : undefined;
  if (label) {
    if (label.length > 100) {
      errors[fieldNameLabel] =
        'The Label must not be longer than 100 characters';
    }
  }

  // --- Validate the Description
  const descriptionField = values[fieldNameDescription];
  const description = descriptionField
    ? descriptionField.inputValue
    : undefined;
  if (description) {
    if (description.length > 300) {
      errors[fieldNameDescription] =
        'The Description must not be longer than 300 characters';
    }
  }

  // --- Validate the Output Variable name
  const outputVariableField = values[fieldNameVariableName];
  const outputVariable = outputVariableField
    ? outputVariableField.inputValue
    : undefined;
  if (!outputVariable) {
    errors[fieldNameVariableName] = 'The Variable name must not be empty';
  } else {
    // 1. Check if it's a valid variable name
    if (!outputVariable.match(variableNameRegex)) {
      errors[fieldNameVariableName] = 'Enter a valid variable name';
    }
  }

  return errors;
}

/**
 * Safely extracts the user input value from a settingField = (= { origin, variableName, inputValue })
 * @param settingField
 * @returns {undefined|*}
 */
export const inputValueSafe = (settingField) => {
  if (!settingField) return undefined;
  return settingField.inputValue;
};

/**
 * Takes a setting object and returns the value according to the origin.
 * If origin = 'input' the user input is supposed to be used for this setting field.
 * If origin = 'variable' the value for this setting field comes from a variable.
 * @param settingField (= { origin, variableName, inputValue })
 * @param variables All variables for the input cell.
 */
export function getValueFromSettingsField(settingField, variables) {
  if (!settingField) return undefined;
  const { origin, variableName, inputValue } = settingField;
  // If the value is not supposed to be overwritten but the user input is supposed to be used, return that value
  if (!origin || origin === 'input') {
    // 'input' is also the default case if no origin is given
    return inputValue;
  } else if (origin === 'variable') {
    // If no variables are available: Fallback to the inputValue
    if (!variables) return inputValue;

    const foundVariable = variables.find((v) => v.name === variableName);
    // If the variable wasn't found or doesn't have a value: Fallback to the inputValue
    if (!foundVariable || !foundVariable.parsedValue) return inputValue;

    // If reached here, a variable was found for the name specified to be used for the input field and this variable has a value. Return this variable value.
    return foundVariable.parsedValue;
  } else {
    // This case is not supposed to happen
    return undefined;
  }
}
