import keycloak from '../../../keycloak';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import { QueryKey } from 'react-query/types/core/types';

export const IS_API = '@@API';

export const HEADERS = {
  JSON: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export function isApiCallResult(result) {
  return !!(result && result[IS_API]);
}

/**
 * TODO does not quite work as expected. The goal is an Either type so that you can extract both
 *  response and error: `const { response, error } = ...` and typescript will know which it is after checking `if (response) ...`
 */
export type CompletedApiRequest<T = unknown> =
  | Promise<SuccessApiRequest<T>>
  | Promise<ErrorApiRequest>;

export type SuccessApiRequest<T = unknown> = {
  response: T;
  error?: never;
};

export type ErrorApiRequest = {
  response?: never;
  error: ApiError;
};

type Options = {
  headers?: Record<string, string>;
  [option: string]: unknown;
};

export function apiRequest<T>(
  url: string,
  options?: Options
): CompletedApiRequest<T> {
  const passedHeaders = options ? options.headers : {};
  const optionsNoCache: Options = {
    ...options,
    headers: {
      ...passedHeaders,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
  };
  return keycloak
    .updateToken()
    .then((refreshed) => {
      optionsNoCache.headers = {
        ...optionsNoCache.headers,
        Authorization: `Bearer ${keycloak.token}`,
      };
      return fetch(
        url,
        // @ts-ignore mismatch between our Options type and RequestInit (import from where?)
        Object.assign({}, optionsNoCache, { credentials: 'include' })
      );
    })
    .then((response) => {
      if (response.ok) {
        return response
          .json()
          .then((data) => ({ response: data, status: response.status }))
          .catch((err) => ({ response: {}, status: response.status })); // If the json can't be parsed, return an empty object for the response
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response
          .json()
          .then((json) => ({
            [IS_API]: true,
            error: json,
            status: response.status,
          }))
          .catch((err) => ({
            [IS_API]: true,
            error: err.message,
            status: response.status,
          }));
      }
      return response.text().then((text) => ({
        [IS_API]: true,
        error: text,
        status: response.status,
      }));
    })
    .catch((e) => console.error('e: ', e));
}

export function putApiRequest<T>(url, body, options = { headers: {} }) {
  return apiRequest<T>(url, {
    ...options,
    method: 'PUT',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
    },
    body: JSON.stringify(body),
  });
}

export function postApiRequest<T>(url, body = {}, options = { headers: {} }) {
  return apiRequest<T>(url, {
    ...options,
    method: 'POST',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
    },
    body: JSON.stringify(body),
  });
}

export function postUploadRequest<T>(url, files) {
  const data = new FormData();
  data.append('files', files);

  return apiRequest<T>(url, {
    method: 'POST',
    body: data,
  });
}

export function deleteApiRequest<T>(url, options = { headers: {} }) {
  return apiRequest<T>(url, {
    ...options,
    method: 'DELETE',
    headers: options.headers,
  });
}

/**
 * Wrapper function for the React Query queryFn functions.
 * Extracts the response if the request was successful or otherwise throws an error
 *    to conform to React Query's 'throw on error'-pattern.
 */
export const fetchQueryFn = async <
  TQueryFnData = unknown,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  fetchFn: () => CompletedApiRequest<TQueryFnData>
): Promise<TQueryFnData> => {
  const { response, error } = await fetchFn();
  if (!response)
    throw Error(`Unable to fetch [ ${queryKey} ]: ${JSON.stringify(error)}.`);
  return response;
};
