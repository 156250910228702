import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import HabitatsContainer from '../dashboard/habitats/Habitats.container';
import * as routes from '../index/routes';
import NewAugurWizard from '../newAugur/NewAugurWizard.container';
import NewDatapoolWizard from '../newDatapool/NewDatapoolWizard.container';
import NewHabitatWizard from '../newHabitat/NewHabitatWizard.container';
import NoPermissions from '../pages/no-permissions/NoPermissions';
import { dataManagementRoutes } from '../dataManagement/routes';
import AddUpdateDataSourceContainer from '../dataManagement/manage-ds/change-ds/AddUpdateDataSource.container';

export default class ModelManagement extends Component {
  componentDidMount() {
    // On first load fetchSummary is called twice, once from here and once from the dashboard init saga.
    // Both may be needed: this for later switching to the tab and the saga for loading arbitrary urls
    const { fetchSummary } = this.props;
    fetchSummary();
  }

  render() {
    const { missingPermissionsError } = this.props;

    if (missingPermissionsError) {
      return <NoPermissions fullViewHeight />;
    }
    return (
      <Switch>
        {/* New Augur */}
        <Route
          path={
            routes.app.prefix + routes.app.models + '/' + routes.app.newAugur
          }
          component={NewAugurWizard}
        />

        {/* New Datapool */}
        <Route
          path={
            routes.app.prefix + routes.app.models + '/' + routes.app.newDatapool
          }
          component={NewDatapoolWizard}
        />

        {/* Update Habitat */}
        <Route
          path={`${routes.app.prefix}${routes.app.models}/${routes.app.newHabitat}/:habitatCode`}
          render={(routeProps) => (
            <NewHabitatWizard {...routeProps} update={true}></NewHabitatWizard>
          )}
        />

        {/* New Habitat */}
        <Route
          path={
            routes.app.prefix + routes.app.models + '/' + routes.app.newHabitat
          }
          component={NewHabitatWizard}
        />

        <Route component={HabitatsContainer} />
      </Switch>
    );
  }
}

ModelManagement.propTypes = {
  missingPermissionsError: PropTypes.bool,
  fetchSummary: PropTypes.func.isRequired,
};
