import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FiGrid } from 'react-icons/fi';

export default class Group extends Component {
  render() {
    const { id, name, fullName, description, speakingOwner } = this.props;

    return (
      <Fragment>
        <div className={'group'}>
          <div className={'group-icon-parent'}>
            <FiGrid className={'content-element-icon'} size={'20px'} />
          </div>

          <div className={'group-name-parent'}>
            <div className={'group-name'}>
              <span className={'name'}>
                {name} {speakingOwner !== undefined ? `(${speakingOwner})` : ''}
              </span>
            </div>
            <div className={'group-description'}>
              {description ? (
                <span>{description}</span>
              ) : (
                <span>
                  <i>No description available</i>
                </span>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
Group.propTypes = {
  /** ID of the group */
  id: PropTypes.string,
  /** Name of the group */
  name: PropTypes.string.isRequired,
  /** Full name of the group */
  fullName: PropTypes.string,
  /** Description of the group */
  description: PropTypes.string,
};
