import React, { FC, useState } from 'react';
import { deriveElements } from './utils';
import ElementCardGrid from '../../../organisms/element-card-grid/ElementCardGrid';
import ElementCardTable from '../../../organisms/element-card-table/ElementCardTable';
import { useIntl } from 'react-intl';
import NoElements from '../no-elements/NoElements';
import { getNewAugurLink } from '../../../index/routes';
import { useProgressSummary } from '../../../../core/api/orchestration';
import {
  useAugurs,
  useUpdateAugurWithAugurCode,
} from '../../../../core/api/augurs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import {
  getAugurs,
  getOrder,
} from '../../../../redux/selectors/augurs.selectors';
import { comparators } from '../../../../core/dashboard';
import { isListView as getIsListView } from '../../../../redux/selectors/dashboard.selector';
import { AugurType } from 'common/dist/types/augur';

type Props = {
  /** Habitat Code */
  habitatCode: string;
};

enum TABS {
  ACTIVE,
  ARCHIVE,
}

const Augurs: FC<Props> = (props) => {
  const { habitatCode } = props;
  const intl = useIntl();
  const [tab, setTab] = useState<TABS>(TABS.ACTIVE);

  const progressQuery = useProgressSummary(true);
  const jobProgressSummary = progressQuery.isSuccess ? progressQuery.data : {};

  //const { data: augurs } = useAugurs(habitatCode, false);
  const augurs = useSelector<RootState, AugurType[]>((state) =>
    getAugurs(state, { habitatCode })
  );
  const { data } = useAugurs(habitatCode, true);
  const archivedAugurs = data ?? [];

  // Fix the habitatCode and payload here, so that we get a function we can use where we know the augurCode but can't use hooks
  const { mutate: updateAugur } = useUpdateAugurWithAugurCode(habitatCode);
  const restoreAugur = (augurCode) =>
    updateAugur({ augurCode, payload: { archived: false } });

  const order = useSelector<RootState, string>((state) =>
    getOrder(state, { habitatCode })
  );
  const comparator = comparators[order];

  const sortedAugurs = augurs.sort(comparator);
  const sortedArchivedAugurs = archivedAugurs.sort(comparator);

  const isListView = useSelector<RootState, boolean>((state) =>
    getIsListView(state, habitatCode)
  );

  const getElements = () => {
    if (tab === TABS.ARCHIVE) {
      // --- Archive tab is opened
      return deriveElements(
        sortedArchivedAugurs as AugurType[],
        habitatCode,
        intl,
        true,
        restoreAugur,
        archivedAugurs.length,
        () => setTab(TABS.ARCHIVE),
        () => setTab(TABS.ACTIVE)
      );
    } else {
      // --- Active augurs tab is opened
      return deriveElements(
        sortedAugurs,
        habitatCode,
        intl,
        false,
        restoreAugur,
        archivedAugurs.length,
        () => setTab(TABS.ARCHIVE),
        () => setTab(TABS.ACTIVE),
        jobProgressSummary
      );
    }
  };

  const elements = getElements();

  const renderCardView = () => {
    return (
      <ElementCardGrid
        elements={elements}
        cardsBeforeCollapsing={8}
        collapsedHeight={312} // 2 * 130px for the element card + 2 * 20px grid-gap + 12 px as half of the collapse button
      />
    );
  };

  const renderTableView = () => {
    return (
      <ElementCardTable
        elements={elements}
        rowsBeforeCollapsing={8}
        collapsedHeight={316} // 8 * 38px for 8 rows + 12px as half the button height
      />
    );
  };

  if (!augurs || (augurs.length === 0 && archivedAugurs.length === 0))
    return (
      <NoElements
        title={{
          id: 'dashboard.augurs.add_first_augur',
          defaultMessage: 'There are no Augurs for this Habitat yet.',
        }}
        linkTo={getNewAugurLink(habitatCode)}
        buttonLabelId={'common.new_augur'}
        buttonLabelDefault={'New Augur'}
      />
    );

  return (
    <div className={'augurs'}>
      {isListView ? renderTableView() : renderCardView()}
    </div>
  );
};

export default Augurs;
