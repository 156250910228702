import React, { Component } from 'react';
import { max, maxBy } from 'lodash';
import { scaleBand, scaleLinear } from 'd3-scale';

import EvaluationBars from './EvaluationBars';
import AxisLeft from './AxisLeft';
import AxisBottom from './AxisBottom';
import Threshold from './Threshold';
import * as drift from '../../../core/common/drift';
import Tooltip from './Tooltip';
import {
  Domain,
  DriftDataEntry,
} from '../../modelManagement/modules/common/augur-details-tabs/accuracyDetails/types';

const margin = {
  top: 20,
  right: 0,
  bottom: 80,
  left: 30,
};

export type Props = {
  width: number;
  height: number;
  top: number;
  left: number;

  timeSeries: DriftDataEntry[];
  domain: Domain;
  selectedKpi: string;
};

export type State = {
  tooltip: boolean;
  tooltipX?: number;
  tooltipY?: number;
  tooltipData?: DriftDataEntry | Record<string, never>;
};

export default class PerformanceDriftChart extends Component<Props, State> {
  static defaultProps = {
    width: 200,
    height: 200,
    top: 0,
    left: 0,
    tooltip: true,
  };

  constructor(props) {
    super(props);

    // @ts-ignore
    this.state = {
      tooltip: false,
    };
  }

  render() {
    const { width, height, top, left, timeSeries, selectedKpi } = this.props;

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const axisLeftWidth = 40;
    const ticksSize = chartWidth - axisLeftWidth;
    const allBarsWidth = Math.min(
      chartWidth - axisLeftWidth,
      100 * timeSeries.length
    );

    const topBarsPadding = 40;
    const barsHeight = chartHeight - topBarsPadding;

    if (drift.isEvaluationsEmpty(timeSeries)) {
      return (
        <svg width={width} height={height}>
          <g
            transform={`translate(${axisLeftWidth + margin.left}, ${
              margin.top
            })`}
          >
            <rect
              width={ticksSize}
              height={chartHeight}
              stroke='#DBE1EB'
              fill='none'
            />
          </g>
        </svg>
      );
    }

    const withMaxDrift = maxBy(timeSeries, drift.getDrift);
    const withMaxThreshold = maxBy(timeSeries, drift.getThreshold);
    const maxY = withMaxThreshold
      ? max([
          drift.getDrift(withMaxDrift),
          drift.getThreshold(withMaxThreshold),
        ])
      : drift.getDrift(withMaxDrift);
    const yScale = scaleLinear().range([barsHeight, 0]).domain([0, maxY]);

    const xScale = scaleBand()
      // @ts-ignore
      .domain(timeSeries.map(drift.getTime))
      .range([0, allBarsWidth])
      .paddingInner(0.4);

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <svg
          width={width}
          height={height}
          style={{ width: '100%', height: '100%' }}
        >
          <g
            transform={`translate(${axisLeftWidth + margin.left}, ${
              margin.top
            })`}
          >
            <rect
              width={ticksSize}
              height={chartHeight}
              stroke='#DBE1EB'
              fill='none'
            />
            <g transform={`translate(0, ${topBarsPadding})`}>
              <AxisLeft
                ticks={6}
                tickSize={ticksSize}
                scale={yScale}
                selectedKpi={selectedKpi}
              />
              <g transform={`translate(0, ${barsHeight})`}>
                <AxisBottom scale={xScale} />
              </g>
              <EvaluationBars
                evaluations={timeSeries}
                xScale={xScale}
                yScale={yScale}
                isTooltip={this.state.tooltip}
                showTooltip={() =>
                  this.setState({
                    tooltip: true,
                    tooltipX: 0,
                    tooltipY: 0,
                    tooltipData: {},
                  })
                }
                hideTooltip={() =>
                  this.setState({
                    tooltip: false,
                    tooltipX: 0,
                    tooltipY: 0,
                    tooltipData: {},
                  })
                }
                moveTooltip={(x, y, data) =>
                  this.setState({
                    tooltipX: x,
                    tooltipY: y,
                    tooltipData: data,
                  })
                }
              />
              <Threshold
                data={timeSeries}
                xScale={xScale}
                yScale={yScale}
                innerPadding={0.4}
              />
            </g>
          </g>
        </svg>
        {this.state.tooltip && (
          <Tooltip
            top={top}
            left={left}
            containerHeight={height}
            selectedKpi={selectedKpi}
            isTooltip={this.state.tooltip}
            tooltipX={this.state.tooltipX}
            tooltipY={this.state.tooltipY}
            tooltipData={this.state.tooltipData}
          />
        )}
      </div>
    );
  }
}
