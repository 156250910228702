import StepAddlInfo from './step-addl-info/StepAddlInfo';

export const formName = 'upload-wizard';

export const fieldKeyspace = 'keyspace';
export const fieldTable = 'table';
export const fieldUpload = 'upload';
export const fieldAddlInfo = 'addlInfo';
export const file = 'file'; // -> Is there even a field required for the upload?

// Keyspace and table names must begin with an alpha-numeric character and can only contain alpha-numeric characters and underscores.
// https://docs.datastax.com/en/cql-oss/3.x/cql/cql_reference/ref-lexical-valid-chars.html
const tableRegexp = /^[a-zA-Z0-9][a-zA-Z0-9_]*$/;

export function validate(values) {
  let errors = {};

  // --- Validate Keyspace
  const keyspace = values[fieldKeyspace];
  if (keyspace) {
    const keyspaceName = keyspace.name;
    if (!keyspaceName) {
      errors = {
        ...errors,
        [fieldKeyspace]: { name: 'Please select a Keyspace' },
      };
    }
  }

  // --- Validate Table
  const table = values[fieldTable];
  if (table) {
    const tableName = table.name;
    if (!tableName) {
      errors = {
        ...errors,
        [fieldTable]: { name: 'Please chose a name for the table' },
      };
    } else {
      if (!tableRegexp.test(tableName)) {
        errors = {
          ...errors,
          [fieldTable]: { name: 'Please enter a valid name for the table' },
        };
      }
    }
  }

  // --- Validate Upload
  const upload = values[fieldUpload];
  if (!upload || !upload.file) {
    errors = {
      ...errors,
      [fieldUpload]: { file: 'Please pick a file to upload' },
    };
  }

  // --- Validate meta
  const addlInfo = values[fieldAddlInfo];
  if (addlInfo) {
    if (addlInfo.columnSeparator.length !== 1) {
      errors = {
        ...errors,
        [fieldAddlInfo]: {
          ...errors[fieldAddlInfo],
          columnSeparator: 'Please use only a single character',
        },
      };
    }
    if (addlInfo.thousandsSeparator.length > 1) {
      errors = {
        ...errors,
        [fieldAddlInfo]: {
          ...errors[fieldAddlInfo],
          thousandsSeparator: 'Please use at most a single character',
        },
      };
    }
    if (addlInfo.decimalSeparator.length !== 1) {
      errors = {
        ...errors,
        [fieldAddlInfo]: {
          ...errors[fieldAddlInfo],
          decimalSeparator: 'Please use only a single character',
        },
      };
    }
    if (
      new Set([
        addlInfo.columnSeparator,
        addlInfo.thousandsSeparator,
        addlInfo.decimalSeparator,
      ]).size !== 3
    ) {
      errors = {
        ...errors,
        [fieldAddlInfo]: {
          ...errors[fieldAddlInfo],
          general:
            'Column, Thousands and Decimal separator must each be different',
        },
      };
    }
  }

  return errors;
}

export const initialValues = {
  [fieldKeyspace]: {
    strategy: 'existing',
  },
  [fieldTable]: {
    strategy: 'create',
    concatStrategy: 'recreate',
  },
  [fieldAddlInfo]: {
    hasHeadline: true,
    columnSeparator: ',',
    thousandsSeparator: '',
    decimalSeparator: '.',
    charset: StepAddlInfo.CHARSETS['Automatic'],
  },
};

export const uploadForm = {
  form: formName,
  validate,
  // initialValues, -- Will be set in the "WizardUpload.container.js" as a classical prop (so it can be enriched from the URL)
  destroyOnUnmount: false,
};
