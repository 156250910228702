import { createAction } from 'redux-act';

export const clearForceFocus = createAction('clear force focus', (path) => ({
  path,
}));

export const reducer = {
  [clearForceFocus](state, { path }) {
    return {
      ...state,
      notebooks: {
        ...state.notebooks,
        [path]: {
          ...(state.notebooks[path] || {}),
          forceFocus: undefined,
        },
      },
    };
  },
};
