import React, { Component, Fragment } from 'react';
import { InjectedFormProps } from 'redux-form';
import RepositoryStepName from '../../../../collaborationSpace/wizards/repository-add/step-name/RepositoryStepName';
import {
  fieldArchHabitatDatapool,
  fieldCodeCapsuleHabitat,
  fieldDescription,
  fieldKernel,
  fieldName,
  fieldType,
  formName,
  RepositoryTypeIds,
} from '../../../../collaborationSpace/wizards/repository-add/repository.form';
import RepositoryStepDescription from '../../../../collaborationSpace/wizards/repository-add/step-description/RepositoryStepDescription';
import RepositoryStepType from '../../../../collaborationSpace/wizards/repository-add/step-type/RepositoryStepType.container';
import RepositoryStepHabitat from '../../../../collaborationSpace/wizards/repository-add/step-cc-habitat/RepositoryStepHabitat.container';
import RepositoryStepKernel from '../../../../collaborationSpace/wizards/repository-add/step-ar-kernel/RepositoryStepKernel.container';
import RepositoryStepHabitatDatapool from '../../../../collaborationSpace/wizards/repository-add/step-ar-habitat-datapool/RepositoryStepHabitatDatapool.container';
import Wizard from '../../../../pages/wizard/Wizard';
import GenericFormStep from '../../../../molecules/generic-form-step/GenericFormStep';
import { ButtonProps } from '../../../../atoms/button/Button';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import { Redirect } from 'react-router-dom';
import {
  RepoDetails,
  RepoMeta,
} from '../../../../../store/workbench/state.types';

export default class ForkRepositoryWizard extends Component<
  Props & InjectedFormProps
> {
  refreshRepoInfo() {
    const { infoFilePath, loadRepoMeta } = this.props;
    if (!infoFilePath) {
      // This happens if no "path" is passed in the query string of the URL
      return;
    }

    loadRepoMeta(infoFilePath);
  }

  refreshRepoDetails() {
    const {
      repoMeta: { repoFullName, codeCapsuleCode, repoType },
      fetchRepoDetails,
      fetchSingleCodeCapsule,
    } = this.props;
    const parts = repoFullName.split('/');
    const repoGroup = parts[0];
    const repoName = parts[1];
    fetchRepoDetails(repoGroup, repoName);
    if (repoType === RepositoryTypeIds.CODE_CAPSULE) {
      fetchSingleCodeCapsule(codeCapsuleCode);
    }
  }

  componentDidMount() {
    const { repoMeta } = this.props;
    this.refreshRepoInfo();
    if (repoMeta !== undefined) {
      this.refreshRepoDetails();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props & InjectedFormProps>,
    prevState: Readonly<{}>
  ) {
    const { repoMeta, touch, initialValues } = this.props;
    if (repoMeta !== prevProps.repoMeta) {
      this.refreshRepoDetails();
    }
    if (initialValues !== prevProps.initialValues) {
      touch(
        fieldDescription,
        fieldType,
        fieldCodeCapsuleHabitat,
        fieldKernel,
        fieldArchHabitatDatapool
      );
    }
  }

  render() {
    const {
      forkRepository,
      repositoryName,
      activePath,
      repositoryType,
      repositoryDescription,
      codeCapsuleHabitat,
      archKernel,
      archHabitat,
      archDatapool,
      currentUserCode,
      accountCode,
      formValid,
      isAsyncValidating,
      infoFilePath,
      repoMeta,
    } = this.props;

    if (!infoFilePath) {
      // If no path is passed in the query string redirect to the Workbench page
      return <Redirect to={`${workbenchRoutes.basePath}`} />;
    }

    // --- Derive the repository path from the infoFilePath ( "abc/def/repository.asr" -> "/abc/def")
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');

    const buttons: ButtonProps[] = [
      {
        withLink: true,
        linkTo: workbenchRoutes.basePath,
        buttonColor: 'white',
        buttonLabelDefault: 'Cancel',
      },
      {
        withLink: true,
        linkTo: workbenchRoutes.basePath,
        buttonColor: 'secondary',
        buttonLabelDefault: 'Submit',
        disabled: !formValid,
        onClick: () => {
          if (formValid && !isAsyncValidating) {
            forkRepository(
              infoFilePath,
              {
                repositoryName,
                activePath,
                repositoryType,
                repositoryDescription,
                codeCapsuleHabitat,
                archKernel,
                archHabitat,
                archDatapool,
                currentUserCode,
                accountCode,
              },
              repoMeta
            );
          }
        },
      },
    ];

    return (
      <Wizard
        withHeadline={true}
        headlineId={'no-intl-id'}
        headlineDefault={`Fork Repository ${(repositoryPath || '.')
          .split('.')
          .slice(0, -1)
          .join('.')}`}
        buttons={buttons}
      >
        <form
          onKeyPress={(e) => {
            if (e.which === 13) e.preventDefault();
          }}
        >
          {/* --- Wizard Steps */}
          <GenericFormStep
            fieldName={fieldName}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Enter Name',
            }}
            description={{
              id: 'no-id',
              defaultMessage: 'Enter a name for the repository.',
            }}
            component={RepositoryStepName}
            num={1}
            renderError={false}
          />

          <GenericFormStep
            fieldName={fieldDescription}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Enter Description',
            }}
            description={{
              id: 'no-id',
              defaultMessage: 'Enter a description for the repository.',
            }}
            component={RepositoryStepDescription}
            num={2}
            renderError={false}
          />

          <GenericFormStep
            fieldName={fieldType}
            formName={formName}
            title={{
              id: 'no-id',
              defaultMessage: 'Select Type',
            }}
            description={{
              id: 'no-id',
              defaultMessage: 'Select a type for the repository.',
            }}
            // @ts-ignore
            component={RepositoryStepType}
            num={3}
            renderError={false}
            fieldProps={{ disabled: true }}
          />

          {repositoryType === RepositoryTypeIds.CODE_CAPSULE && (
            <GenericFormStep
              fieldName={fieldCodeCapsuleHabitat}
              formName={formName}
              title={{
                id: 'no-id',
                defaultMessage: 'Select Habitat',
              }}
              description={{
                id: 'no-id',
                defaultMessage:
                  'Select a Habitat this Code Capsule will belong to.',
              }}
              // @ts-ignore
              component={RepositoryStepHabitat}
              num={4}
              renderError={false}
            />
          )}

          {repositoryType === RepositoryTypeIds.ARCHETYPE && (
            <Fragment>
              <GenericFormStep
                fieldName={fieldKernel}
                formName={formName}
                title={{
                  id: 'no-id',
                  defaultMessage: 'Select Kernel',
                }}
                description={{
                  id: 'no-id',
                  defaultMessage: 'Select a Kernel for the Archetype.',
                }}
                component={RepositoryStepKernel}
                num={4}
                renderError={false}
              />

              <GenericFormStep
                fieldName={fieldArchHabitatDatapool}
                formName={formName}
                title={{
                  id: 'no-id',
                  defaultMessage: 'Select Habitat and Datapool',
                }}
                description={{
                  id: 'no-id',
                  defaultMessage:
                    'Select the Habitat and the Datapool this Archetype will be based on.',
                }}
                // @ts-ignore
                component={RepositoryStepHabitatDatapool}
                num={5}
                renderError={false}
              />
            </Fragment>
          )}
        </form>
      </Wizard>
    );
  }
}

export interface Props {
  hideAddRepositoryWizard: () => void;
  forkRepository: (...args: unknown[]) => void;
  formValid?: boolean;
  /** Active path in the repository browser */
  activePath?: string;

  /** --- ENTERED FORM VALUES --- */
  /** Entered repository name */
  repositoryName?: string;
  /** Entered repository type */
  repositoryType?: string;
  /** Entered repository description */
  repositoryDescription?: string;
  /** Selected habitat for the code capsule (only set if repositoryType='code-capsule' */
  codeCapsuleHabitat?: string;
  /** Selected kernel for the archetype (only set if repositoryType='archetype' */
  archKernel?: string;
  /** Selected habitat for the archetype (only set if repositoryType='archetype' */
  archHabitat?: string;
  /** Selected datapool for the archetype (only set if repositoryType='archetype' */
  archDatapool?: string;
  /** The code of the currently logged in user */
  currentUserCode: string;
  /** Account the logged in unser belongs to */
  accountCode: string;

  isAsyncValidating?: boolean;
  infoFilePath: string;
  loadRepoMeta: (...args: unknown[]) => void;
  fetchRepoDetails: (repoGroup: string, repoName: string) => void;
  fetchSingleCodeCapsule: (
    codeCapsuleCode: string,
    habitatCode?: string
  ) => void;
  repoMeta: RepoMeta;
  repoDetails: RepoDetails;
}
