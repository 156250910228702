import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiPlus } from 'react-icons/fi';
import { DragSource } from 'react-dnd';
import styles from './styles.module.scss';
import classNames from 'classnames';

// --- External Components
const externalNodeType = 'add-node';
const externalNodeSpec = {
  // This needs to return an object with a property `node` in it.
  // Object rest spread is recommended to avoid side effects of
  // referencing the same object in different trees.
  beginDrag: (componentProps) => ({
    node: {
      title: componentProps.newTaxonomy ? componentProps.newTaxonomy.title : '',
      subtitle: componentProps.newTaxonomy
        ? componentProps.newTaxonomy.subtitle
        : '',
      id: componentProps.newTaxonomy ? componentProps.newTaxonomy.subtitle : '',
    },
  }),
  canDrag: (componentProps) => {
    const allowed =
      componentProps.newTaxonomy &&
      componentProps.newTaxonomy.title.replace(/\s/g, '') !== '';
    if (!allowed) {
      componentProps.alert(true);
    }
    return allowed;
  },
};
const externalNodeCollect = (connect /* , monitor */) => ({
  connectDragSource: connect.dragSource(),
  // Add props via react-dnd APIs to enable more visual
  // customization of your component
  // isDragging: monitor.isDragging(),
  // didDrop: monitor.didDrop(),
});

class AddButton extends Component {
  render() {
    const { connectDragSource, node, newTaxonomy, setNewTaxonomy } = this.props;
    const title = newTaxonomy ? newTaxonomy.title : '';
    const subtitle = newTaxonomy ? newTaxonomy.subtitle : '';

    return connectDragSource(
      <div className={styles.addTaxonomyContainer}>
        <div className={styles.iconContainer}>
          <FiPlus className={classNames(styles.icon, 'icon-add')} size='20px' />
        </div>
        <div className={styles.inputContainer}>
          <input
            className={styles.inputTitle}
            value={title}
            onChange={(event) => {
              const newTitle = event.target.value;
              setNewTaxonomy({
                title: newTitle,
                subtitle,
              });
            }}
            placeholder='Enter Name'
            autoFocus
          />
          <input
            className={styles.inputSubtitle}
            value={subtitle}
            onChange={(event) => {
              const newSubtitle = event.target.value;
              setNewTaxonomy({
                title,
                subtitle: newSubtitle,
              });
            }}
            placeholder='Enter ID'
          />
        </div>
      </div>,
      { dropEffect: 'copy' }
    );
  }
}

AddButton.propTypes = {
  node: PropTypes.shape({ title: PropTypes.string }).isRequired,
  newTaxonomy: PropTypes.array,
  connectDragSource: PropTypes.func.isRequired,
  setNewTaxonomy: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
};

export default DragSource(
  externalNodeType,
  externalNodeSpec,
  externalNodeCollect
)(AddButton);
