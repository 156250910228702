import React, { FC, useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { fetchDataSources } from '../../../../redux/modules/data.dataSources.module';
import TabLineBubbles from '../../../molecules/tab-line-bubbles/TabLineBubbles';
import SingleCassandraPermissions from './SingleCassandraPermissions';
import { RouteComponentProps } from 'react-router';

export const cassandraDetailsRoute = (cassandraCode, keyspace) =>
  `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.cassandraPermissions.path}/${cassandraCode}/${keyspace}`;

type CassandraType = {
  name: string;
  code: string;
};

type Props = {
  history: {
    location: {
      pathname: string;
    };
  };
};

const CassandraPermissions: FC<Props & RouteComponentProps> = (props) => {
  const {
    history: {
      location: { pathname, search },
    },
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataSources());
  }, [dispatch]);

  const cassandras = useSelector<RootState, CassandraType[]>((state) =>
    state.data?.dataSources?.data.filter((x) => x.ds_type === 'cassandra')
  );

  const tabLineButtons = (cassandras || []).map((cassandra) => ({
    id: cassandra.code,
    to: `/app/admin/users/cassandraPermissions/${cassandra.code}`,
    onClick: () => {},
    intlId: 'no-id',
    intlDefault: cassandra.name,
  }));

  const activeButton = pathname.split('/')[5];

  return (
    <div>
      <TabLineBubbles buttons={tabLineButtons} activeButton={activeButton} />

      <Switch>
        {cassandras.map((cassandra) => (
          <Route
            path={`/app/admin/users/cassandraPermissions/${cassandra.code}`}
          >
            <div
              style={{
                marginTop: '15px',
              }}
            >
              <SingleCassandraPermissions code={cassandra.code} />
            </div>
          </Route>
        ))}

        {cassandras.length > 0 && (
          <Redirect
            to={`/app/admin/users/cassandraPermissions/${cassandras[0].code}${search}`}
            path={'/app/admin/users/cassandraPermissions'}
          />
        )}
      </Switch>
    </div>
  );
};

export default withRouter(CassandraPermissions);
