import React, { FC } from 'react';
import { injectIntl, IntlShape, WrappedComponentProps } from 'react-intl';
import styles from './styles.module.scss';
import { OrderType } from './Habitat';
import DropdownSelectInput from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import _ from 'lodash';

type Props = {
  order: OrderType;
  setOrder: (order: OrderType) => void;
};

type Option = { value: string; label: string; disabled?: boolean };
type Group = { label: string; options: Option[] };

const optionsGroupedAndLocalized: (intl: IntlShape) => Group[] = (intl) => [
  {
    label: intl.formatMessage({ id: 'dashboard.augurs.order.health' }),
    options: [
      {
        value: '1a',
        label: intl.formatMessage({ id: 'dashboard.augurs.order.high_low' }),
      },
      {
        value: '1b',
        label: intl.formatMessage({ id: 'dashboard.augurs.order.low_high' }),
      },
    ],
  },
  {
    label: intl.formatMessage({ id: 'dashboard.augurs.order.alphabetic' }),
    options: [
      {
        value: '2a',
        label: intl.formatMessage({ id: 'dashboard.augurs.order.a_z' }),
      },
      {
        value: '2b',
        label: intl.formatMessage({ id: 'dashboard.augurs.order.z-a' }),
      },
    ],
  },
  {
    label: intl.formatMessage({ id: 'dashboard.augurs.order.last_run' }),
    options: [
      {
        value: '3a',
        label: intl.formatMessage({
          id: 'dashboard.augurs.order.short_ago_long_ago',
        }),
      },
      {
        value: '3b',
        label: intl.formatMessage({
          id: 'dashboard.augurs.order.long_ago_short_ago',
        }),
      },
    ],
  },
];

const Order: FC<Props & WrappedComponentProps> = (props) => {
  const { order, setOrder, intl } = props;
  const options = optionsGroupedAndLocalized(intl);

  let orderFilled = order;
  if (order?.value && !order?.label) {
    // Happens if the default value is selected - the order object has to be picked due to the internationalization, the
    // label can't be hard coded into the default value.
    // But: react-select checks for object-equality, so the object has to be exactly the same. If the 'label' property
    // misses, the dropdown select field will be empty
    orderFilled = _.flatMap(options.map((group) => group.options)).find(
      (x) => x.value === order.value
    );
  }

  return (
    <div className={styles.order}>
      <DropdownSelectInput
        className={'order-dropdown-select'}
        appearance={'small'}
        options={options}
        value={orderFilled}
        searchable={false}
        clearable={false}
        placeholder={{
          id: 'dashboard.augurs.order.order_by',
          defaultMessage: 'Order By',
        }}
        onChange={(orderValue: Option) => {
          setOrder({ label: orderValue?.label, value: orderValue?.value });
        }}
      />
    </div>
  );
};

export default injectIntl(Order);
