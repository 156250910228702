import React, { FC } from 'react';
import { line } from 'd3-shape';
import { getX, getY } from '../util';
import { ScaleLinear } from 'd3-scale';
import { AimType } from './Crosshair';
import { DataType } from '../LineChart';

type Props = {
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  data: DataType;
  aim: AimType;
};

const Aim: FC<Props> = (props: Props) => {
  const { xScale, yScale, data, aim } = props;

  const strokeLine = line()
    .x((d) => xScale(getX(d)))
    .y((d) => yScale(getY(d)));

  const horizontalLine = [
    [getX(data[0]), getY(aim.closest)],
    [getX(data[data.length - 1]), getY(aim.closest)],
  ] as [number, number][];

  const verticalLine = [
    [getX(aim.closest), yScale.domain()[0]],
    [getX(aim.closest), yScale.domain()[1]],
  ] as [number, number][];

  const horizontalLinePath = strokeLine(horizontalLine);
  const verticalLinePath = strokeLine(verticalLine);

  return (
    <svg style={{ width: '100%', height: '100%' }}>
      <g>
        <circle
          cx={aim.x}
          cy={aim.y}
          fill='none'
          stroke='black'
          strokeWidth='1.5'
          r={10}
          opacity={0.9}
        />
        <path
          d={horizontalLinePath}
          stroke='black'
          fill='none'
          strokeWidth='1.5'
          opacity={0.6}
        />
        <path
          d={verticalLinePath}
          stroke='black'
          fill='none'
          strokeWidth='1.5'
          opacity={0.6}
        />
      </g>
    </svg>
  );
};

export default Aim;
