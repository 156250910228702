import { createAction } from 'typesafe-actions';
import {
  CREATE_BACKUP,
  CREATE_BACKUP_FAIL,
  CREATE_BACKUP_SUCCESS,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_FAIL,
  CREATE_TEMPLATE_SUCCESS,
  FETCH_BACKUPS,
  FETCH_BACKUPS_FAIL,
  FETCH_BACKUPS_SUCCESS,
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FAIL,
  FETCH_TEMPLATES_SUCCESS,
  HIDE_RESTORE_BACKUP_MODAL,
  RESTORE_BACKUP,
  RESTORE_BACKUP_FAIL,
  RESTORE_BACKUP_SUCCESS,
  SHOW_RESTORE_BACKUP_MODAL,
} from './actions.types';
import {
  BackupCreateRequest,
  BackupCreateResponse,
  BackupListResponse,
  BackupMeta,
  BackupMetaWithId,
  BackupRestoreRequest,
  TemplateCreateRequest,
  TemplateCreateResponse,
  TemplateMeta,
} from '../../../components/admin/maintenance/types';

export const fetchBackups = createAction(FETCH_BACKUPS)();

export const fetchBackupsSuccess = createAction(
  FETCH_BACKUPS_SUCCESS,
  (data: BackupListResponse) => ({ data })
)();

export const fetchBackupsFail = createAction(
  FETCH_BACKUPS_FAIL,
  (error: string) => ({ error })
)();

export const showRestoreBackupModal = createAction(
  SHOW_RESTORE_BACKUP_MODAL,
  (bak: BackupMetaWithId, template: TemplateMeta) => ({ bak, template })
)();

export const hideRestoreBackupModal = createAction(HIDE_RESTORE_BACKUP_MODAL)();

export const restoreBackup = createAction(
  RESTORE_BACKUP,
  (bakRestore: BackupRestoreRequest) => ({ bakRestore })
)();

export const restoreBackupSuccess = createAction(RESTORE_BACKUP_SUCCESS)();

export const restoreBackupFail = createAction(
  RESTORE_BACKUP_FAIL,
  (error: string) => ({ error })
)();

export const createBackup = createAction(
  CREATE_BACKUP,
  (bakCreate: BackupCreateRequest, callback: () => void) => ({
    bakCreate,
    callback,
  })
)();

export const createBackupSuccess = createAction(
  CREATE_BACKUP_SUCCESS,
  (bakCreated: BackupCreateResponse) => ({ bakCreated })
)();

export const createBackupFail = createAction(
  CREATE_BACKUP_FAIL,
  (error: string) => ({ error })
)();

export const fetchTemplates = createAction(FETCH_TEMPLATES)();

export const fetchTemplatesSuccess = createAction(
  FETCH_TEMPLATES_SUCCESS,
  (data: BackupMeta[]) => ({ data })
)();

export const fetchTemplatesFail = createAction(
  FETCH_TEMPLATES_FAIL,
  (error: string) => ({ error })
)();

export const createTemplate = createAction(
  CREATE_TEMPLATE,
  (templateCreate: TemplateCreateRequest, callback: () => void) => ({
    templateCreate,
    callback,
  })
)();

export const createTemplateSuccess = createAction(
  CREATE_TEMPLATE_SUCCESS,
  (templateCreated: TemplateCreateResponse) => ({ templateCreated })
)();

export const createTemplateFail = createAction(
  CREATE_TEMPLATE_FAIL,
  (error: string) => ({ error })
)();
