import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import ButtonBar from './ButtonBar';
import {
  fetchTaxonomy,
  saveTaxonomy,
} from '../../../redux/modules/datapool.details.module';

export function mapStateToProps(state) {
  const treeData = state.datapoolDetails.treeData;
  const initialTreeData = state.datapoolDetails.initialTreeData;

  const pristine =
    (initialTreeData.length === 0 && treeData.length === 0) ||
    isEqual(initialTreeData, treeData);

  return {
    datapoolCode: state.datapoolDetails.datapoolCode,
    habitatCode: state.datapoolDetails.habitatCode,
    taxonomy: state.datapoolDetails.taxonomy,
    saveButtonLoading: state.datapoolDetails.saving,
    pristine,
  };
}

export const mapDispatchToProps = {
  fetchTaxonomy,
  saveTaxonomy,
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonBar);
