import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BiographyType } from 'common/dist/types/augurBiography';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import { fetchAugurBiography as fetchAugurBiographyApi } from '../augurBiography/api';
import { AugurBiographyState } from '../augurBiography/slice';

export const initial: AugurBiographyState = {};
export const test: AugurBiographyState = {};

export const fetchAugurBiographySidebar = createAsyncThunk<
  { data: BiographyType },
  { habitatCode: string; augurCode: string; offset?: number; limit?: number },
  { rejectValue: { error: ApiError } }
>(
  'fetchAugurBiographySidebar',
  async ({ habitatCode, augurCode, offset, limit }, thunkAPI) => {
    const { response, error } = await fetchAugurBiographyApi(
      habitatCode,
      augurCode,
      offset,
      limit,
      ['learning'],
      true
    );

    if (response) {
      return { data: response };
    } else {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const slice = createSlice({
  name: 'augurBiographySidebar',
  initialState: initial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchAugurBiographySidebar.pending,
      (state, { payload, meta }) => {
        state.loaded = false;
        state.loading = true;
      }
    );
    builder.addCase(
      fetchAugurBiographySidebar.fulfilled,
      (state, { payload, meta }) => {
        const { data } = payload;
        state.data = data;
        state.loading = false;
        state.loaded = true;
        state.error = undefined;
      }
    );
    builder.addCase(
      fetchAugurBiographySidebar.rejected,
      (state, { payload, meta }) => {
        const { error } = payload;
        state.error = error;
        state.loading = false;
        state.loaded = false;
      }
    );
  },
});

const reducer = slice.reducer;
export { reducer as augurBiographySidebarReducer };
