import React from 'react';
import ReactDOM from 'react-dom';

import store from './js/store/store';
import App from './js/components/index/App.container';

import reportWebVitals from './reportWebVitals';

import './scss/styles.scss';
import 'classlist';
import keycloak, { initKeycloak, logout } from './keycloak';
import * as localeModule from './js/redux/modules/locale.module';
import * as configModule from './js/redux/modules/config.module';
import * as Api from './js/core/api';
import _ from 'lodash';
import * as actionsAuth from './js/redux/modules/auth.module';
import { fetchAvatar } from './js/redux/modules/account.avatar.module';
import { configureSockets } from './js/socket';
import * as dashboardInit from './js/redux/modules/dashboard.init.module';
import classNames from 'classnames';

if (localStorage.getItem('debug') === null || !window.console) {
  const noOp = function () {
    // no-op function
  };
  // eslint-disable-next-line no-global-assign,no-native-reassign
  console = {
    assert: noOp,
    clear: noOp,
    count: noOp,
    countReset: noOp,
    debug: noOp,
    dir: noOp,
    dirxml: noOp,
    error: noOp,
    group: noOp,
    groupCollapsed: noOp,
    groupEnd: noOp,
    info: noOp,
    log: noOp,
    markTimeline: noOp,
    profile: noOp,
    profileEnd: noOp,
    table: noOp,
    time: noOp,
    timeEnd: noOp,
    timeline: noOp,
    timelineEnd: noOp,
    timeLog: noOp,
    timeStamp: noOp,
    trace: noOp,
    warn: noOp,
    Console: undefined,
  };
}

// Immediately invoked async function to be able to use await instead of chaining promises
(async () => {
  const success = await initKeycloak();
  if (window.location.pathname === '/logout') {
    logout(undefined, { redirectUri: '/' }); // Otherwise, there is a loop, redirecting to /logout)
    return;
  }
  if (success) {
    const defaultLocale = store.getState().intl.locale;
    await new Promise<void>((resolve, reject) => {
      store.dispatch(
        // @ts-ignore
        localeModule.changeLocale(defaultLocale, () => {
          resolve();
        })
      );
    });
    await new Promise<void>((resolve, reject) => {
      store.dispatch(
        // @ts-ignore
        configModule.getConfig(() => {
          resolve();
        })
      );
    });
    // TODO is this still needed? Can't we use the info from the accessToken? Do we need more? Put that in the token?
    const { response: user } = await Api.auth.getUser();
    if (user && !_.isEmpty(user)) {
      store.dispatch(actionsAuth.sessionRestored(user));

      // Set user locale, don't do so if locale is missing (could happen) or same as default
      if (
        user.attributes?.locale?.[0] !== undefined &&
        user.attributes?.locale?.[0] !== defaultLocale
      ) {
        store.dispatch(localeModule.changeLocale(user.attributes?.locale[0]));
      }

      // --- Fetch the users avatar
      store.dispatch(fetchAvatar());

      // --- Initialize sockets
      configureSockets(store);

      // --- Initialize the dashboard (load the summary + component permissions)
      store.dispatch(dashboardInit.init());

      // Initialization succeeded and the App can be rendered
      ReactDOM.render(
        <React.StrictMode>
          <App store={store} />
        </React.StrictMode>,
        document.getElementById('react-app')
      );
    } else {
      // User details couldn't be fetched - ask for a re-login. Should restart the init process.
      keycloak.login();
    }
  } else {
    throw new Error('Failed to initialize keycloak.');
  }
})().catch((error: Error) => {
  console.error(error);
  ReactDOM.render(
    <React.StrictMode>
      <div className={classNames('ErrorBoundary', 'ErrorBoundary-100vh')}>
        <div className={'ErrorBoundary--picture error--pic'}>&nbsp;</div>
        <span className={'ErrorBoundary--headline'}>Initialization Error</span>
        <span className={'ErrorBoundary--description'}>
          {error?.message || 'Unknown error'}
        </span>
      </div>
    </React.StrictMode>,
    document.getElementById('react-app')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(); // TODO How can this be used?
