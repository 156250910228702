import React, { FC } from 'react';
import styles from '../styles.module.scss';
import NameContainer from './NameContainer';
import ActionButtons from './ActionButtons';
import DetailValues from './DetailValues';
import { CProps } from './OverlayAugurDetails.container';

const OverlayAugurDetails: FC<CProps> = ({
  triggerLearningJob,
  triggerEvaluationJob,
  showRunPredictionModal,
}) => {
  return (
    <div className={styles.subheaderLeft}>
      <div className={styles.detailsBar}>
        <NameContainer />
        <DetailValues />
        <ActionButtons
          triggerLearningJob={triggerLearningJob}
          triggerEvaluationJob={triggerEvaluationJob}
          showRunPredictionModal={showRunPredictionModal}
        />
      </div>
    </div>
  );
};

export default OverlayAugurDetails;
