import React, { FC } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { kpiOptions } from 'common/dist/constants/keyPerformanceIndicators';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

type Props = {
  onChange: (value: string) => void;
  value?: string;
};

type OptionType = { value: string; label: string };

export function getLocalizedOptions(intl, options) {
  return options.map((option) => ({
    label: intl.formatMessage({ id: option.label }),
    value: option.id,
  }));
}

const KpiSelect: FC<Props & WrappedComponentProps> = (props) => {
  const { onChange, value, intl } = props;

  const placeholder = { id: 'kpi.placeholder' };
  const options = getLocalizedOptions(intl, kpiOptions);

  return (
    <div className='select--wrap'>
      <DropdownSelectInput
        className='Select-grouped'
        placeholder={placeholder}
        options={options}
        onChange={(option: OptionType) => onChange(option.value)}
        value={options.find((o) => o.value === value)}
        clearable={false}
      />
    </div>
  );
};

export default injectIntl(KpiSelect);
