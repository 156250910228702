import { UserResponse } from './api.types';

export const FETCH_RUNNING_WORKBENCHES = 'fetch running workbenches';
export const FETCH_RUNNING_WORKBENCHES_SUCCESS =
  'fetch running workbenches - success';
export const FETCH_RUNNING_WORKBENCHES_FAIL =
  'fetch running workbenches - fail';
export const STOP_WORKBENCH = 'stop workbench';
export const STOP_WORKBENCH_SUCCESS = 'stop workbench - success';
export const STOP_WORKBENCH_FAIL = 'stop workbench - fail';
export const FETCH_RUNNING_APPS = 'fetch running apps';
export const FETCH_RUNNING_APPS_SUCCESS = 'fetch running apps - success';
export const FETCH_RUNNING_APPS_FAIL = 'fetch running apps - fail';
export const STOP_APP = 'stop app';
export const STOP_APP_SUCCESS = 'stop app - success';
export const STOP_APP_FAIL = 'stop app - fail';

export interface FetchRunningWorkbenchAction {
  type: typeof FETCH_RUNNING_WORKBENCHES;
}

export interface FetchRunningWorkbenchSuccessAction {
  type: typeof FETCH_RUNNING_WORKBENCHES_SUCCESS;
  payload: { data: UserResponse[] };
}

export interface FetchRunningWorkbenchFailAction {
  type: typeof FETCH_RUNNING_WORKBENCHES_FAIL;
  payload: { error: string };
}

export type StopWorkbenchAction = {
  type: typeof STOP_WORKBENCH;
  payload: { userCodeLower: string };
};

export interface StopWorkbenchSuccessAction {
  type: typeof STOP_WORKBENCH_SUCCESS;
  payload: { userCodeLower: string };
}

export interface StopWorkbenchFailAction {
  type: typeof STOP_WORKBENCH_FAIL;
  payload: { userCodeLower: string; error: string };
}

export interface FetchRunningAppsAction {
  type: typeof FETCH_RUNNING_APPS;
}

export interface FetchRunningAppsSuccessAction {
  type: typeof FETCH_RUNNING_APPS_SUCCESS;
  payload: { data: UserResponse[] };
}

export interface FetchRunningAppsFailAction {
  type: typeof FETCH_RUNNING_APPS_FAIL;
  payload: { error: string };
}

export type StopAppAction = {
  type: typeof STOP_APP;
  payload: { userCodeLower: string; appVersionCodeLower: string };
};

export interface StopAppSuccessAction {
  type: typeof STOP_APP_SUCCESS;
  payload: { userCodeLower: string; appVersionCodeLower: string };
}

export interface StopAppFailAction {
  type: typeof STOP_APP_FAIL;
  payload: {
    userCodeLower: string;
    appVersionCodeLower: string;
    error: string;
  };
}

export type AdminResourcesActions =
  | FetchRunningWorkbenchAction
  | FetchRunningWorkbenchSuccessAction
  | FetchRunningWorkbenchFailAction
  | StopWorkbenchAction
  | StopWorkbenchSuccessAction
  | StopWorkbenchFailAction
  | FetchRunningAppsAction
  | FetchRunningAppsSuccessAction
  | FetchRunningAppsFailAction
  | StopAppAction
  | StopAppSuccessAction
  | StopAppFailAction;
