import { connect } from 'react-redux';
import LauncherTabLinkElement from './LauncherTabLinkElement';
import { measuredTabWidth } from '../../../../../../redux/workbench/modules/notebook.module';
import {
  hideMenu,
  updateOverlappingTabs,
} from '../../../../../../redux/workbench/modules/notebook.overlapping.module';

export function mapStateToProps(state, { paneId }) {
  return {};
}

export const mapDispatchToProps = {
  measuredTabWidth,
  updateOverlappingTabs,
  hideOverlappingMenu: hideMenu,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LauncherTabLinkElement);
