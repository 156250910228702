import { createAction } from 'redux-act';
import * as KernelApi from '../../../core/api/workbench/kernel';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import {
  success as successNotification,
  error as errorNotification,
  info as infoNotification,
} from 'react-notification-system-redux';
import {
  notificationRestartKernelSuccess,
  notificationRestartKernelFail,
  notificationRestartKernelTriggered,
  notificationInterruptKernelSuccess,
  notificationInterruptKernelFail,
} from '../notifications/notifications';
import { clearAllOutputs, markAllCellsAsNotExecuting } from './notebook.module';
import { notebookUser } from '../selectors/notebookUser.selector';
import { getSessionDetails, postSession } from './sessions.module';
import { getNotebookByPath } from '../selectors/notebook.selectors';
import { ExecutionState } from '../../../store/workbench/state.types';

export const restartKernel = createAction(
  'restart kernel',
  (path, kernelId) => ({ path, kernelId })
);

export const restartKernelSuccess = createAction(
  'restart kernel - success',
  (success) => success
);

export const restartKernelFail = createAction(
  'restart kernel - fail',
  (error) => error
);

export const interruptKernel = createAction(
  'interrupt kernel',
  (kernelId, sessionId, path) => ({ kernelId, sessionId, path })
);

export const interruptKernelSuccess = createAction(
  'interrupt kernel - success',
  (success) => success
);

export const interruptKernelFail = createAction(
  'interrupt kernel - fail',
  (error) => error
);

// There is no need for a reducer here since the state itself isn't touched (right?)

export function* restartKernelSaga({ payload: { path, kernelId } }) {
  const jupyterUser = yield select((state) => notebookUser(state));
  yield put(infoNotification(notificationRestartKernelTriggered()));

  const notebook = yield select(getNotebookByPath, path);
  const content = notebook.content;
  if (notebook.session?.kernel.execution_state !== ExecutionState.NotFound) {
    // Restart the kernel
    const { response, error } = yield call(
      KernelApi.restartKernel,
      kernelId,
      jupyterUser
    );
    if (response) {
      yield put(restartKernelSuccess(response));
      yield put(clearAllOutputs(path));
      yield put(successNotification(notificationRestartKernelSuccess()));
    } else {
      yield put(restartKernelFail(error));
      yield put(errorNotification(notificationRestartKernelFail()));
    }
  } else {
    // There is no longer a kernel to restart, recreate the session
    yield put(clearAllOutputs(path));
    yield put(
      postSession(
        notebook.name,
        notebook.path,
        content.metadata?.kernelspec?.name,
        jupyterUser,
        true
      )
    );
  }
}

export function* watchRestartKernel() {
  yield takeEvery(restartKernel().type, restartKernelSaga);
}

export function* interruptKernelSaga({
  payload: { kernelId, sessionId, path },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));
  const { response, error } = yield call(
    KernelApi.interruptKernel,
    kernelId,
    jupyterUser
  );
  if (response) {
    yield put(interruptKernelSuccess(response));
    yield put(markAllCellsAsNotExecuting(path));
    yield put(getSessionDetails(sessionId));
    yield put(successNotification(notificationInterruptKernelSuccess()));
  } else {
    yield put(interruptKernelFail(error));
    yield put(getSessionDetails(sessionId));
    yield put(errorNotification(notificationInterruptKernelFail()));
  }
}

export function* watchInterruptKernel() {
  yield takeEvery(interruptKernel().type, interruptKernelSaga);
}
