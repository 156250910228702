import React from 'react';
import PermissionRow, { Props as RowProps } from './PermissionRow';
import styles from './styles.module.scss';

export interface Props {
  /** Title of the Resources - will be shown in the top left cell of the table */
  title: string;
  /** List of names of the permissions - will be shown as the headlines of the permission cells */
  permissionColumns: string[];
  /** List of rows to display */
  rowProps: RowProps[];
}

const PermissionTable: React.FC<Props> = ({
  title,
  permissionColumns,
  rowProps,
}) => {
  return (
    <table className={styles.keyspacesTable}>
      <thead className={styles.keyspacesHeader}>
        <tr>
          <td>
            <span className={styles.keyspacesHeadline}>{title}</span>
          </td>
          {permissionColumns.map((p) => (
            <td key={p}>
              <div className={styles.permissionCell}>{p}</div>
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowProps.map((p) => (
          <PermissionRow {...p} key={p.title} />
        ))}
      </tbody>
    </table>
  );
};

export default PermissionTable;
