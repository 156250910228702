import React, { Component } from 'react';
import IndicatorEmpty from '../../../../../../components/molecules/indicator-empty/IndicatorEmpty';
import { CProps } from './TablesParent.container';
import Tables from '../../../../../dataManagement/cassandra/tables/Tables.container';
import BucketBrowser from '../../../../../dataManagement/s3/bucket-browser/BucketBrowser.container';
import datapoolMsgs from 'common/dist/messages/datapools';

export default class TablesParent extends Component<CProps> {
  render() {
    const { dataSource, datapoolCode } = this.props;

    if (!dataSource) {
      return (
        <IndicatorEmpty
          classNameImage={'data-sources-empty-pic'}
          headlineId={
            datapoolMsgs.datapoolDetailsIndicatorNoDatasourceHeadline.id
          }
          headlineDefault={
            datapoolMsgs.datapoolDetailsIndicatorNoDatasourceHeadline
              .defaultMessage
          }
          descriptionId={
            datapoolMsgs.datapoolDetailsIndicatorNoDatasourceDescription.id
          }
          descriptionDefault={
            datapoolMsgs.datapoolDetailsIndicatorNoDatasourceDescription
              .defaultMessage
          }
        />
      );
    }

    switch (dataSource.ds_type) {
      case 'cassandra':
        return (
          <div className={'data-source-parent'}>
            <div className={'data-source-body'}>
              <Tables
                dataSourceCode={dataSource.code}
                showBackButton={false}
                showUploadButton={false}
              />
            </div>
          </div>
        );
      case 's3':
        return (
          <div className={'data-source-parent'}>
            <div className={'data-source-body'}>
              <BucketBrowser
                dataSourceCode={dataSource.code}
                bucket={`datapool-${datapoolCode.toLowerCase()}`}
                bucketPath={''}
                showBackButton={false}
                showUploadButton={false}
              />
            </div>
          </div>
        );
    }
  }
}
