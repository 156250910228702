import React, { Component } from 'react';
import { FiX } from 'react-icons/fi';
import CassandraTablePreview from '../cassandra-table-preview/CassandraTablePreview';
import { DatapoolType } from 'common/dist/types/datapool';
import { CProps } from './TablePreviewModal.container';

export const tablePreviewCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    marginTop: '40px',
    maxHeight: 'calc(80vh - 40px)',
  },
};

export type Props = {
  tableName: string;
  datapool: DatapoolType;
  hideTablePreview: () => void;
};

export default class TablePreviewModal extends Component<Props & CProps> {
  render() {
    const { hideTablePreview, tableName, data } = this.props;
    return (
      <div className={'data-preview-modal modal-parent'}>
        <div
          title={'Close Dialog'}
          className={'workbench-button close-button'}
          onClick={hideTablePreview}
        >
          <FiX className={'icon'} size={'20px'} />
        </div>

        <div className={'modal-group-parent'}>
          <span className={'modal-headline'}>Table Preview</span>
          <span className={'modal-description'}>Table: {tableName}</span>

          {data && data.colSpecs && data.data && (
            <div className={'data-preview-parent'}>
              <CassandraTablePreview
                colSpecs={data.colSpecs}
                data={data.data}
                editable={false}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
