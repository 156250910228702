import { ToBeRefined } from 'common/dist/types/todo_type';

export const formName = 'createTemplate';

export const fieldName = 'name';
export const fieldComponents = 'components';

export const form = {
  form: formName,
  touchOnChange: true,
  validate: (values: ToBeRefined) => {
    let errors = {};

    // --- Validate name field
    const name = values[fieldName];
    if (!name || name.trim() === '') {
      errors = { ...errors, [fieldName]: 'Please enter a name' };
    }

    // --- Validate components field
    const components = values[fieldComponents];
    if (!components || components.length < 1) {
      errors = {
        ...errors,
        [fieldComponents]: 'Please select at least one component',
      };
    }

    return errors;
  },
};
