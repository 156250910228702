import React, { FC, useEffect } from 'react';
import MainContainer from '../pages/main-container/MainContainer';
import DefaultNewAugurWizard from './defaultWizard/DefaultNewAugurWizard';
import styles from './styles.module.scss';
import BinaryClassificationNewAugurWizard from '../modelManagement/modules/implementations/binary-classification/new-augur/BinaryClassificationNewAugurWizard.container';
import { fieldModuleType } from './defaultWizard/default.form';
import qs, { ParsedQs } from 'qs';
import { moduleType as MODULE_TYPE } from 'common/dist/constants/module_list';
import NaturalLanguageTaggingNewAugurWizard from '../modelManagement/modules/implementations/natural-language-tagging/new-augur/NaturalLanguageTaggingNewAugurWizard.container';
import OptimizationNewAugurWizard from '../modelManagement/modules/implementations/optimization/new-augur/OptimizationNewAugurWizard.container';
import { ModulesState } from '../../store/modules/state.types';
import { useHistory, useParams } from 'react-router-dom';
import { useAddAugur } from '../../core/api/augurs';
import * as routes from '../index/routes';

type Props = {
  /** Clear the new augur wizard form */
  resetForm: () => void;
  /** Fetch the list of available modules */
  fetchModules: () => void;
  /** Fetch the dashboard summary if necessary */
  ensureHabitatInSummary: (habitatCode: string) => void;

  /** Selector for the redux-form form values */
  formValueSelector: (fieldName: string) => any;

  /** The available modules */
  modules?: ModulesState;

  /** Name of the habitat */
  habitatName?: string;
};

export type RouteParams = {
  habitatCode: string;
};

const NewAugurWizard: FC<Props> = ({
  fetchModules,
  ensureHabitatInSummary,
  resetForm,
  formValueSelector,
  habitatName,
  modules,
}) => {
  const { habitatCode } = useParams<{ habitatCode: string }>();
  const history = useHistory();

  const navigateToModelManagement = () =>
    history.push(routes.app.prefix + routes.app.models);

  const { mutate, isLoading } = useAddAugur(habitatCode);
  const addAugur = (augur) =>
    mutate(augur, {
      onSuccess: navigateToModelManagement,
    });

  useEffect(() => {
    // Make sure the form is initially empty
    resetForm();

    if (habitatCode) {
      // If needed, fetch the Dashboard summary
      ensureHabitatInSummary(habitatCode);
    }
    // Fetch all available modules
    fetchModules();
  }, [resetForm, habitatCode, ensureHabitatInSummary, fetchModules]);

  const renderWizard = () => {
    const moduleType: string = formValueSelector(fieldModuleType);

    let query: ParsedQs = {};
    if (history?.location?.search) {
      query = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      });
    }

    if (!moduleType) {
      return (
        <DefaultNewAugurWizard
          habitatCode={habitatCode}
          habitatName={habitatName}
          modules={modules}
          navigateOnCancel={navigateToModelManagement}
        />
      );
    } else {
      switch (moduleType) {
        case MODULE_TYPE.BINARY_NUMERICAL: {
          return (
            <BinaryClassificationNewAugurWizard
              habitatCode={habitatCode}
              habitatName={habitatName}
              modules={modules}
              isSubmitting={isLoading}
              addAugur={addAugur}
              navigateOnCancel={navigateToModelManagement}
              formValueSelector={formValueSelector}
              query={query}
              resetForm={resetForm}
            />
          );
        }
        case MODULE_TYPE.NATURAL_LANGUAGE_TAGGING: {
          return (
            <NaturalLanguageTaggingNewAugurWizard
              habitatCode={habitatCode}
              habitatName={habitatName}
              modules={modules}
              isSubmitting={isLoading}
              addAugur={addAugur}
              navigateOnCancel={navigateToModelManagement}
              formValueSelector={formValueSelector}
              query={query}
              resetForm={resetForm}
            />
          );
        }
        case MODULE_TYPE.CAMPAIGN_OPTIMIZATION: {
          return (
            <OptimizationNewAugurWizard
              habitatCode={habitatCode}
              habitatName={habitatName}
              modules={modules}
              isSubmitting={isLoading}
              addAugur={addAugur}
              navigateOnCancel={navigateToModelManagement}
              formValueSelector={formValueSelector}
              query={query}
              resetForm={resetForm}
            />
          );
        }
        default: {
          return <div>Unexpected module type: {moduleType}</div>;
        }
      }
    }
  };

  return (
    <MainContainer fullWidth scrollableY={false}>
      <div className={styles.newAugurWizard}>{renderWizard()}</div>
    </MainContainer>
  );
};

export default NewAugurWizard;
