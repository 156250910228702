import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ArchetypeVersionsList extends Component {
  render() {
    const { versions } = this.props;
    return (
      <div className={'ct-sub-list'}>
        {versions.map((v) => {
          const { id, number, createdAt } = v;
          return (
            <div key={id} className={'ct-row ct-version-row'}>
              <div className={'ct-col ct-col-80px'} />
              <div className={'ct-col ct-col-flex-grow'}>{number}</div>
              <div className={'ct-col ct-col-130px'} />
              <div className={'ct-col ct-col-200px'} />
              <div className={'ct-col ct-col-200px'}>{createdAt}</div>
            </div>
          );
        })}
      </div>
    );
  }
}
ArchetypeVersionsList.propTypes = {
  versions: PropTypes.array.isRequired,
};
ArchetypeVersionsList.defaultProps = {
  versions: [],
};
