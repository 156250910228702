import React from 'react';
import { File, FileTypeWrapper } from '../FileTypeWrapper';
import LauncherContent from './component/Launcher';

export const Launcher: FileTypeWrapper = {
  // eslint-disable-next-line react/display-name
  component: (file: File, paneId: string) => (
    <LauncherContent paneId={paneId} />
  ),
};
