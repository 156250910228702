import React, { FC } from 'react';
import * as d3 from 'd3-shape';
import { curveCatmullRom, line } from 'd3-shape';

import { ConvertedDataEntry } from '../SelectableTimeline';
import { ScaleContinuousNumeric } from 'd3-scale';
import vars from '../../../../../scss/base/var.module.scss';

type Props = {
  data: ConvertedDataEntry[];
  xScale: ScaleContinuousNumeric<number, number>;
  yScale: ScaleContinuousNumeric<number, number>;
  height: number;
  width: number;
};

const AreaWithStroke: FC<Props> = (props) => {
  const { data, xScale, yScale, height, width } = props;

  const scaledData = data.map((item) => ({
    x: xScale(item.time),
    y: height - yScale(item.value),
  }));

  const firstElement = data[0];
  const lastElement = data[data.length - 1];

  const leftExtraElement = {
    x: 0,
    y: height - yScale(firstElement.value),
  };

  const rightExtraElement = {
    x: width,
    y: height - yScale(lastElement.value),
  };

  const dataExtrapolated = [leftExtraElement, ...scaledData, rightExtraElement];

  const area = d3
    .area<{ x: number; y: number }>()
    .x((d) => d.x)
    .y0((d) => d.y)
    .y1(height)
    .curve(d3.curveCatmullRom);

  const areaPath = area(dataExtrapolated);

  const strokePath = line<{ x: number; y: number }>()
    .x((d) => d.x)
    .y((d) => d.y)
    .curve(curveCatmullRom)(dataExtrapolated);

  return (
    <g>
      <path d={areaPath} opacity={0.7} fill={vars.colorPrimaryLighter90} />
      <path
        d={strokePath}
        stroke={vars.colorPrimary}
        fill='none'
        strokeWidth='1.5'
      />
    </g>
  );
};

AreaWithStroke.propTypes = {};

export default AreaWithStroke;
