import React, { Component } from 'react';
import JobGroupHistory from './JobGroupHistory.container';
import Paging from '../../molecules/paging/Paging';
import { connectedProps } from './JobHistory.container';
import Busy from '../../atoms/busy/Busy';
import IndicatorEmpty from '../../molecules/indicator-empty/IndicatorEmpty';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';
import { ORCHESTRATION_ORIGINS } from '../links';
import errorMessages from 'common/dist/messages/error';

export interface Props {
  // redux type override
  fetchHistory: (offset?: number, limit?: number, search?: string) => void;
}

class JobHistory extends Component<Props & connectedProps> {
  constructor(props: Props & connectedProps) {
    super(props);
    this.renderInnerComponent = this.renderInnerComponent.bind(this);
    this.updatePagingParameters = this.updatePagingParameters.bind(this);
  }

  renderLoading() {
    return <Busy isBusy positionAbsolute />;
  }

  renderError() {
    const { error } = this.props;
    return <span>Error: {JSON.stringify(error)}</span>;
  }

  renderEmpty() {
    return (
      <IndicatorEmpty
        classNameImage={'orchestration-history-empty-pic'}
        headlineId={'dummy'}
        headlineDefault={'There are no Jobs in the Job History yet'}
        descriptionId={'dummy'}
        descriptionDefault={'Once a Job has finished, it will be archived here'}
      />
    );
  }

  renderLoaded() {
    const { jobGroupCodes } = this.props;
    return (
      <>
        <div className={'JobHistory--Header'}>
          <strong>Job Group</strong>
          <strong>Name</strong>
          <strong>Created</strong>
          <strong>By</strong>
          <strong>Finished</strong>
          <strong>Actions</strong>
        </div>
        {jobGroupCodes.map((code) => {
          return (
            <JobGroupHistory
              key={code}
              jobGroupCode={code}
              detailsOrigin={ORCHESTRATION_ORIGINS.history}
            />
          );
        })}
      </>
    );
  }

  renderInnerComponent() {
    const { jobGroupCodes, loading, error } = this.props;

    if (loading) return this.renderLoading();
    else if (jobGroupCodes && jobGroupCodes.length === 0)
      return this.renderEmpty();
    else if (error) return this.renderError();

    return this.renderLoaded();
  }

  updatePagingParameters(offset?: number, limit?: number, search?: string) {
    const { fetchHistory } = this.props;
    fetchHistory(offset, limit, search);
  }

  render() {
    const { pageSize, totalCount } = this.props;

    const JobHistoryHeader = (
      currentPage: number,
      offset: number,
      limit: number
    ) => (
      <span>
        {`Showing Job Groups ${
          offset > totalCount ? totalCount : offset + 1
        } - ` +
          `${
            offset + limit > totalCount ? totalCount : offset + limit
          } of ${totalCount}`}
      </span>
    );

    return (
      <ErrorBoundary
        errorHeadline={errorMessages.headline}
        errorDescription={errorMessages.description}
      >
        <div className='Orchestration--content'>
          <div className={'Orchestration--inner-content not-scrollable'}>
            <Paging
              itemsPerPage={pageSize}
              totalItems={totalCount}
              showFirstLast
              searchEnabled
              updatePagingParameters={this.updatePagingParameters}
              Headline={JobHistoryHeader}
            >
              {this.renderInnerComponent()}
            </Paging>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default JobHistory;
