import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ActivateRealtimeToggle, { Props } from './ActivateRealtimeToggle';
import {
  activateRealtimeModel,
  deactivateRealtimeModel,
  fetchRealtimeModelStatus,
} from '../../../../../../redux/modules/orchestration.realtime.module';
import { RootState } from '../../../../../../store/store';
import {
  Augur,
  AugurRequest,
} from '../../../../../../store/realtime/state.types';
import { RouteComponentProps } from 'react-router';

export function realtimeStatus(
  state: RootState,
  augurCode: string
): Augur | undefined {
  if (!augurCode) return undefined;
  if (!state.realtime.augurs[augurCode]) return undefined;
  return state.realtime.augurs[augurCode];
}

export function realtimeRequests(
  state: RootState,
  augurCode: string
): AugurRequest | undefined {
  if (!augurCode) return undefined;
  if (!state.realtime.requests[augurCode]) return undefined;
  return state.realtime.requests[augurCode];
}

export function mapStateToProps(
  state: RootState,
  { match, servingType }: Props & RouteComponentProps<{ augurCode: string }>
) {
  const augurCode = match.params.augurCode;
  const rStatus = realtimeStatus(state, augurCode);

  /** Status about the realtime serving (possibly empty if not started) */
  const data = rStatus?.data?.[servingType];

  return {
    loading: rStatus?.loading,
    loaded: rStatus?.loaded,
    /** Filled if there was an error while fetching the realtime status of the Augur */
    error: rStatus?.error,
    data,
    /** Is true between the two events: Model was activated - Orchestration hasn't responded yet */
    isStarting: rStatus?.[servingType]?.starting,
    /** Is true between the two events: Model was deactivated - Orchestration hasn't responded yet */
    isStopping: rStatus?.[servingType]?.stopping,
  };
}

export const mapDispatchToProps: {
  /** Function to fetch the status about the realtime model serving for this Augur from the Orchestration API */
  fetchRealtimeModelStatus(...args: unknown[]): unknown;
  /** Activate the model for Realtime-Scoring */
  activateRealtimeModel(...args: unknown[]): unknown;
  /** Deactivate the model from Realtime-Scoring */
  deactivateRealtimeModel(...args: unknown[]): unknown;
} = {
  fetchRealtimeModelStatus,
  activateRealtimeModel,
  deactivateRealtimeModel,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(ActivateRealtimeToggle));
