import { connect } from 'react-redux';
import WizardUpload from './WizardUpload';
import { getFormValues, destroy, reduxForm } from 'redux-form';
import { uploadFile } from '../../../../../redux/modules/data.cassandra.module';
import { fieldKeyspace, formName, initialValues, uploadForm } from './form';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { DeprecatedRootState } from '../../../../../store/state.type';

type OwnProps = {
  location: {
    search: string;
  };
};

export function mapStateToProps(
  state: DeprecatedRootState,
  { location }: OwnProps
) {
  const { uploading, uploaded, progress } = state.data.upload || {};

  const queryParameter = qs.parse(location.search, { ignoreQueryPrefix: true });
  let enrichedInitialValues = initialValues;
  if (queryParameter.keyspaceName) {
    enrichedInitialValues = {
      ...enrichedInitialValues,
      [fieldKeyspace]: {
        ...enrichedInitialValues[fieldKeyspace],
        name: queryParameter.keyspaceName,
      },
    };
  }

  return {
    formValues: getFormValues(formName)(state),
    uploading,
    uploaded,
    progress,
    initialValues: enrichedInitialValues,
  };
}

export const mapDispatchToProps = {
  uploadFile,
  destroyUploadForm: () => destroy(formName),
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm(uploadForm)(WizardUpload))
);
