import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import { ResponsiveHeatMap, HeatMapSerie, DefaultHeatMapDatum, ComputedCell } from '@nivo/heatmap';
import { tooltip } from './Tooltip';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { scaleLinear } from 'd3-scale';
import _ from 'lodash';
import { ChartDataEntry } from '../types';
import { useThemeColor } from '../../../../../../../utils';

type Props = {
  data: ChartDataEntry[];
};

const PredictedValuesChart: FC<Props> = ({ data }) => {
  const intl = useIntl();

  const maxHeight = 600;
  const marginTop = 20;
  const marginBottom = 100;
  const itemHeight = 40;
  const amountItems = (data || []).length;
  let height = amountItems * itemHeight + marginBottom + marginTop;
  if (height > maxHeight) height = maxHeight;

  const deriveChartDataForHeatmap = (data: ChartDataEntry[]) => { 
    return data.map((singleRunReport) => ({
      id: singleRunReport.time,
      data: singleRunReport.data.map(point => ({
        x: point[0],
        y: point[1],
      }))
    }));
  };

  const chartData = deriveChartDataForHeatmap(data);

  const renderTickRight = ({
    opacity,
    textAnchor,
    textBaseline,
    textX,
    textY,
    theme,
    value,
    x,
    y,
  }: ToBeRefined) => {
    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <text
          alignmentBaseline={textBaseline}
          style={{ fontSize: '12px' }}
          textAnchor={textAnchor}
          transform={`translate(${textX},${textY - 2})`}
        >
          <tspan x='0' dy='14px'>
            {intl.formatDate(value || '')}&nbsp;
            {intl.formatTime(value || '')}
          </tspan>
        </text>
      </g>
    );
  };

  const bucketKeys = (data[0]?.data || []).map((entry) => entry[0]);
  const minKey = _.min(bucketKeys);
  const maxKey = _.max(bucketKeys);
  const amountBuckets = bucketKeys.length;
  const bucketSize = 1 / amountBuckets;

  const getInterval = (bucketIndex: number) => {
    const bucketLeftNumber = bucketIndex / amountBuckets;
    const bucketLeft = _.round(bucketLeftNumber, 2).toFixed(2);
    const bucketRight = _.round(bucketLeftNumber + bucketSize, 2).toFixed(2);
    return bucketIndex === maxKey
      ? `[${bucketLeft},${bucketRight}]`
      : `[${bucketLeft},${bucketRight})`;
  };

  const renderTickBottom = ({
    opacity,
    textAnchor,
    textBaseline,
    textX,
    textY,
    theme,
    value,
    x,
    y,
  }: ToBeRefined) => {
    const interval = getInterval(value);

    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <text
          alignmentBaseline={textBaseline}
          style={{ fontSize: '12px' }}
          textAnchor={'start'}
          transform={`translate(${textX + 5},${textY}) rotate(45)`}
        >
          <tspan x='0' dy='14px'>
            {interval}
          </tspan>
        </text>
      </g>
    );
  };

  // Build color scale
  const colorPrimaryHighlight = useThemeColor('primary-highlight');
  const colorPrimaryHighlightLighter90 = useThemeColor(
    'primary-highlight',
    '-lighter90'
  );


  let maxVal = -Infinity;
  let minVal = Infinity;

  chartData.forEach((entry) => {
    entry.data.forEach((point) => {
      if (point.y > maxVal) maxVal = point.y;
    });
  });

  chartData.forEach((entry) => {
    entry.data.forEach((point) => {
      if (point.y < minVal) minVal = point.y;
    });
  });

  const scale = (cell: Omit<ComputedCell<DefaultHeatMapDatum>, 'color' | 'opacity' | 'borderColor' | 'labelTextColor'>): string => {
    const value: number = Number(cell.value);
    const domain = [minVal, maxVal];
    const range = [colorPrimaryHighlightLighter90, colorPrimaryHighlight];
    const linearScale = scaleLinear<number, string>().domain(domain).range(range);
    const calculatedColor = linearScale(value);

    return calculatedColor;
  };

  return (
    <div
      className={styles.heatmap}
      style={{
        width: '100%',
        height,
      }}
    >
      <ResponsiveHeatMap
        data={chartData}
        // @ts-ignore
        indexBy={'timestamp'}
        keys={bucketKeys.map(String)}
        margin={{
          top: marginTop,
          right: 180,
          bottom: marginBottom,
          left: 80,
        }}
        forceSquare={false}
        // @ts-ignore
        colors={scale}
        padding={2}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          renderTick: renderTickBottom,
        }}
        axisLeft={null}
        axisTop={null}
        axisRight={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          renderTick: renderTickRight,
        }}
        cellOpacity={1}
        cellBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
        animate={true}
        hoverTarget={'row'}
        cellHoverOthersOpacity={0.5}
        enableLabels={false}
        xInnerPadding={0.05}
        yInnerPadding={0.05}
        tooltip={tooltip(intl, getInterval)}  
      />
    </div>
  );
};

export default PredictedValuesChart;
