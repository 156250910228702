import { ToBeRefined } from 'common/dist/types/todo_type';
import { VariableTypeType } from '../variableTypes';

export const numberRegex = /^-?\d*(\.\d+)?$/;

export type BaseVariableHandler<T> = {
  type: string;
  displayName: string;
  /** Defines what code is actually sent against the Jupyter Kernel when the variable is evaluated.
   * In most cases it will be enough to simply send the variable name (since Python will then respond with the value).
   * But in more complex cases like for a pandas DataFrame you might want to call something like `${inputValue}.to_json()` */
  getExecutionCode: (inputValue: string) => string;
  /** Defines how the response of the Jupyter Kernel is parsed to JavaScript. */
  parseValue: (
    variable: { type: VariableTypeType },
    rawValues: ToBeRefined[]
  ) => { parsedValue: T; error: string };
};

export const getFirstRaw = (
  variable,
  rawValues,
  outputType = 'text/plain'
): string | undefined => {
  if (!variable || !rawValues || rawValues.length === 0) return undefined;
  const firstOutput = rawValues[0];
  if (!firstOutput.data) return undefined;
  const firstRaw = firstOutput.data[outputType];
  if (!firstRaw) {
    return undefined;
  }
  return firstRaw;
};

export const isArray = (o) =>
  Object.prototype.toString.call(o) === '[object Array]';
