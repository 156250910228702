import { connect } from 'react-redux';
import Orchestration from './Orchestration';
import { isAdmin } from '../../redux/selectors/user.selector';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';
import { RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX } from 'common/dist/constants/keycloak';
import { fetchCodeCapsules } from '../../redux/modules/codeCapsules.module';

export function mapStateToProps(state) {
  const permissions = state.dashboard?.permissions?.data || {};
  const missingPermissionsError = !hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX
  );
  const summary = state.summary;

  return {
    isAdmin: isAdmin(state),
    origin: state.jobDetails.origin,
    missingPermissionsError,
    summary,
  };
}

const mapDispatchToProps = {
  fetchCodeCapsules,
};

export default connect(mapStateToProps, mapDispatchToProps)(Orchestration);
