import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { form, formName } from './CreateBackup.form';
import { DeprecatedRootState } from '../../../../store/state.type';
import CreateBackup, { Props } from './CreateBackup';
import {
  createBackup,
  fetchTemplates,
} from '../../../../store/admin/backups/actions';
import { ToBeRefined } from 'common/dist/types/todo_type';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    submitting: state.adminBackups.createBackup.submitting,
    templates: state.adminBackups.templates,
    formValues: getFormValues(formName)(state) || {},
  };
}

export const mapDispatchToProps = {
  createBackup,
  fetchTemplates,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm<ToBeRefined, Props>(form)(CreateBackup));
