import { connect } from 'react-redux';
import ResolveConflicts from './ResolveConflicts';
import { DeprecatedRootState } from '../../../../../../../store/state.type';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import {
  loadMergeRequestConflict,
  submitConflictResolve,
} from '../../../../../../../redux/workbench/modules/merger.module';
import { BASE_FILE_PATH } from './ButtonsBaseFile';

export type OwnProps = {
  location: {
    search: string;
  };
};

export function mapStateToProps(
  state: DeprecatedRootState,
  ownProps: OwnProps
) {
  const {
    location: { search },
  } = ownProps;
  const { filename, merge_id: mergeId } =
    qs.parse(search, { ignoreQueryPrefix: true }) || {};

  // --- Conflict details (from the Merger API)
  const { loading, loaded, error, data, amountOpenDiffs } =
    // @ts-ignore
    (state.workbench.mergerAPI.conflicts || {})[filename + ''] || {};

  const { sourceExtended, targetExtended } = state.workbench.mergerAPI || {};

  // Copy of the base_file so it can be edited in the redux state like a regular notebook
  const baseFileNotebook = (state.workbench.notebooks || {})[BASE_FILE_PATH];

  return {
    filename,
    mergeId: Number.parseInt(mergeId + ''),

    loading,
    loaded,
    error,
    data,
    amountOpenDiffs,

    baseFileNotebook,

    sourceExtended,
    targetExtended,
  };
}

export const mapDispatchToProps = {
  loadMergeRequestConflict,
  submitConflictResolve,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResolveConflicts)
);
