import { createAction } from 'redux-act';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  reset as resetForm,
  getFormSyncErrors,
  actionTypes,
  updateSyncErrors,
} from 'redux-form';

import { initial } from '../../store/state';
import * as wizard from '../../core/wizard';

export const nextStep = createAction(
  'active the specified step of a new wizard',
  (formName, currentStep) => ({ formName, currentStep })
);

export const reset = createAction(
  'reset user input data for a new wizard',
  (formName) => ({ formName })
);

export const setStepsValidity = createAction(
  'set validity of a new wizard',
  (formName, stepsValid) => ({ formName, stepsValid })
);

export const updateFormSteps = createAction(
  'update form steps',
  (formName, numberOfSteps, fieldNames) => ({
    formName,
    numberOfSteps,
    fieldNames,
  })
);

export const reducer = {
  // TODO Why? Nothing in this file is required! There are actions provided by redux-form for doing so ...
  [nextStep]: (state, { formName, currentStep }) => ({
    ...state,
    [formName]: wizard.nextStep(state[formName], currentStep),
  }),
  [reset]: (state, { formName }) => ({
    ...state,
    [formName]: wizard.reset(state[formName], initial[formName]),
  }),
  [setStepsValidity]: (state, { formName, stepsValid }) => ({
    ...state,
    [formName]: wizard.setValidity(state[formName], { stepsValid }),
  }),
  [updateFormSteps]: (state, { formName, numberOfSteps, fieldNames }) => ({
    ...state,
    [formName]: wizard.updateFormSteps(state[formName], {
      numberOfSteps,
      fieldNames,
    }),
  }),
};

export const getForm = (formName) => (state) =>
  [state.form[formName], state[formName]];

export function* updateFormStepsSaga({ payload: { formName } }) {
  const syncErrors = yield select(getFormSyncErrors(formName));
  yield put(updateSyncErrors(formName, syncErrors));
}

export function* resetDataSaga({ payload: { formName } }) {
  yield put(resetForm(formName));
}

function* updateStepsValidity({ meta }) {
  const formName = meta.form;

  const [form, fieldNameParent] = yield select(getForm(formName));

  // TODO fix the following - this is a switch between the Model Management and the Workbench forms
  if (!fieldNameParent) return; //This happens for the forms of the Workbench only

  const { fieldNames } = fieldNameParent;

  if (Array.isArray(fieldNames) && fieldNames.length > 0) {
    const stepsValid = fieldNames.map((stepFieldNames) =>
      stepFieldNames.every(
        (fieldName) => !form.syncErrors || !form.syncErrors[fieldName]
      )
    );
    let currentStep = stepsValid.indexOf(false);
    currentStep = currentStep === -1 ? stepsValid.length - 1 : currentStep;
    if (currentStep !== 0 && stepsValid[currentStep - 1]) {
      yield put(nextStep(formName, currentStep - 1));
    }
    yield put(setStepsValidity(formName, stepsValid));
  }
}

export function* watchUpdateFormSteps() {
  yield takeEvery(updateFormSteps.getType(), updateFormStepsSaga);
}

export function* watchFormReset() {
  yield takeEvery(reset.getType(), resetDataSaga);
}

export function* watchFormUpdateErrors() {
  yield takeLatest(actionTypes.UPDATE_SYNC_ERRORS, updateStepsValidity);
}
