import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { getElementByType } from './Python3OutputElementManager';
import ModalDialog from '../../../settings-dialog/ModalDialog.container';

export default class P3OutputElementParent extends Component {
  render() {
    const {
      parentContainerClass,
      children,
      path,
      removeOutputElement,
      cell,
      element,
      updateDataOfOutputElement,
      showEditOutputElement,
      hideEditOutputElement,
      editOutputElementModal,
    } = this.props;

    const elementClass = getElementByType(element.type); // "elementClass" is a pretty bad variable name here
    const ElementIcon = elementClass.icon;
    const elementName = elementClass.name;
    const iconColor = elementClass.iconColor;
    const hasSettings = elementClass.hasSettings;

    return (
      <div
        className={`output-element-parent output-element ${parentContainerClass}`}
      >
        <div className={'output-element-buttons'}>
          <div className={'output-element-icon-parent'}>
            <ElementIcon
              height={24}
              width={24}
              elementName={elementName}
              elementType={element.type}
              iconColor={iconColor}
            />
          </div>
          <div
            title={'Remove Output Element'}
            className={'workbench-button'}
            onClick={() => removeOutputElement(path, cell.id, element.id)}
          >
            <FiTrash2 className={'icon'} size={'20px'} />
          </div>
          {hasSettings && (
            <div
              title={'Edit Input Element'}
              className={'workbench-button'}
              onClick={() => showEditOutputElement(path, cell.id, element)}
            >
              <FiEdit className={'icon'} size={'20px'} />
            </div>
          )}
        </div>

        <div className={'output-element-container'}>
          {element.settings &&
            element.settings.label &&
            element.settings.label.inputValue &&
            element.settings.label.inputValue.trim() && (
              <p className={'output-element-label'}>
                {element.settings.label.inputValue}
              </p>
            )}
          {element.settings &&
            element.settings.description &&
            element.settings.description.inputValue &&
            element.settings.description.inputValue.trim() && (
              <p className={'output-element-description'}>
                {element.settings.description.inputValue}
              </p>
            )}
          {
            // --- Visual component for inside the cell: text input / calendar element / ...
            React.Children.map(children, (child) =>
              React.cloneElement(child, {
                path,
                cellId: cell.id,
                updateDataOfOutputElement,
                element,
              })
            )
          }
        </div>
        {editOutputElementModal?.active && ( // This is important to re-create the redux-form every time it is opened (otherwise old values - that might even be from different cells/elements - will be kept in the form)
          <ModalDialog
            isOpen={editOutputElementModal.active}
            closeDialog={hideEditOutputElement}
            element={editOutputElementModal.element}
            path={editOutputElementModal.path}
            cellId={editOutputElementModal.cellId}
            variables={editOutputElementModal.variables}
            inputOrOutput={'output'}
          />
        )}
      </div>
    );
  }
}

P3OutputElementParent.propTypes = {
  parentContainerClass: PropTypes.string,
  children: PropTypes.arrayOf(React.Component),
  /** Path of the parent notebook (required for changes) */
  path: PropTypes.string.isRequired,
  /** Click listener for the "remove" buttons of the input elements */
  removeOutputElement: PropTypes.func.isRequired,
  /** Parent cell this input element belongs to */
  cell: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  /** Function that updates the user input data entered in the input element */
  updateDataOfOutputElement: PropTypes.func.isRequired,
  editOutputElementModal: PropTypes.shape({
    active: PropTypes.bool,
    path: PropTypes.string,
    cellId: PropTypes.string,
    element: PropTypes.object,
  }),
  showEditOutputElement: PropTypes.func.isRequired,
  hideEditOutputElement: PropTypes.func.isRequired,
};
