import React from 'react';
import PropTypes from 'prop-types';
import { getElementByType } from '../../fileTypes/notebook/component/notebook-cells/cell-implementations/app-cells/python3-input-cell/elements/_interface/Python3InputElementManager';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { FiX } from 'react-icons/fi';
import ExecutionError from './ExecutionError.container';
import { ItemTypes } from './dndConstants';
import { useDrop } from 'react-dnd';
import Busy from '../../../atoms/busy/Busy';
import { getElementByType as getOutputElementByType } from '../../fileTypes/notebook/component/notebook-cells/cell-implementations/app-cells/python3-output-cell/elements/_interface/Python3OutputElementManager';

// eslint-disable-next-line new-cap
const ResponsiveGridLayout = WidthProvider(Responsive);

/**
 * Generates the default layout for react-grid-layout
 * @param elements List of input or list of output elements
 */
export function deriveDefaultInputLayouts(elements) {
  const layout = elements.map((e, index) => ({
    i: e.id,
    w: 4,
    h: 4,
    x: 0,
    y: index * 4,
  }));
  return { xs: layout }; // For the moment only one size is provided
}

const InputOrOutputStep = ({
  updateLayouts,
  activeExecutionPlanStep,
  path,
  isExecuting,
  isFailure,
  stepType,
  currentBreakpoint,
  updateCurrentBreakpoint,
  isArrangeEditor,
  parentType,
  appVersionCode,
}) => {
  /**
   * Renders the list of input elements. Only called if stepType = 'input'
   * @param layoutedElements
   * @param inputOrOutputCell
   * @param path
   * @param removeLayoutItem
   * @param isArrangeEditor
   * @param parentType notebook | app
   * @param appVersionCode
   * @returns {*}
   */
  function renderInputElements(
    layoutedElements,
    inputOrOutputCell,
    path,
    removeLayoutItem,
    isArrangeEditor,
    parentType,
    appVersionCode
  ) {
    return layoutedElements.map((e) => (
      <div key={e.id} className={'grid-item-parent'}>
        {getElementByType(e.type).renderWrappedComponentForApp({
          cell: inputOrOutputCell,
          path,
          element: e,
          parentType,
          appVersionCode,
        })}
        {isArrangeEditor && (
          <div className={'remove-icon'} onClick={() => removeLayoutItem(e.id)}>
            <FiX size={16} />
          </div>
        )}
      </div>
    ));
  }

  /**
   * Renders the list of output elements. Only called if stepType = 'output'
   * @param layoutedElements
   * @param inputOrOutputCell
   * @param path
   * @param removeLayoutItem
   * @param isArrangeEditor
   * @param parentType notebook | app
   * @param appVersionCode
   * @returns {*}
   */
  function renderOutputElements(
    layoutedElements,
    inputOrOutputCell,
    path,
    removeLayoutItem,
    isArrangeEditor,
    parentType,
    appVersionCode
  ) {
    return layoutedElements.map((e) => (
      <div key={e.id} className={'grid-item-parent'}>
        {getOutputElementByType(e.type).renderWrappedComponentForApp({
          cell: inputOrOutputCell,
          path,
          element: e,
          parentType,
          appVersionCode,
        })}
        {isArrangeEditor && (
          <div className={'remove-icon'} onClick={() => removeLayoutItem(e.id)}>
            <FiX size={16} />
          </div>
        )}
      </div>
    ));
  }

  function renderInputOrOutputElements(
    stepType,
    layoutedElements,
    inputOrOutputCell,
    path,
    removeLayoutItem,
    isArrangeEditor,
    parentType,
    appVersionCode
  ) {
    if (stepType === 'input') {
      return renderInputElements(
        layoutedElements,
        inputOrOutputCell,
        path,
        removeLayoutItem,
        isArrangeEditor,
        parentType,
        appVersionCode
      );
    } else if (stepType === 'output') {
      return renderOutputElements(
        layoutedElements,
        inputOrOutputCell,
        path,
        removeLayoutItem,
        isArrangeEditor,
        parentType,
        appVersionCode
      );
    } else {
      return <div />; // Should never happen
    }
  }

  /**
   * Callback function when an item is dropped over the react-grid-layout.
   * Simply adding the element to the layout isn't easily possible here: Immediately after the onDrop event, the
   * onLayoutChange method is called - overwriting the recently added element.
   * So the element has to be "enqueued" and added in the next "render()" call.
   * @param dragItem
   */
  function onElementDrop(dragItem) {
    try {
      const { type, id } = dragItem;
      const x = 0,
        y = 0,
        w = 2,
        h = 4;
      if (type === ItemTypes.UNARRANGED_APP_ELEMENT) {
        const newElement = { x, y, w, h, i: id };
        const updatedLayouts = layouts;
        Object.keys(updatedLayouts).forEach((breakpoint) => {
          updatedLayouts[breakpoint] = [
            ...updatedLayouts[breakpoint],
            newElement,
          ];
        });
        updateLayouts(path, inputOrOutputCell.id, updatedLayouts);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function onLayoutChange(currentLayout, allLayouts) {
    const inputOrOutputCell = activeExecutionPlanStep.cells[0];
    updateLayouts(path, inputOrOutputCell.id, allLayouts);
  }

  // --- Validate that the first cell of the executionPlanStep really is an input or output cell respectively
  const inputOrOutputCell = activeExecutionPlanStep.cells[0];
  if (stepType === 'input') {
    if (!inputOrOutputCell || inputOrOutputCell.cell_type !== 'python3-input') {
      return (
        <div className={'step-parent input-step-parent'}>
          The first cell of the Input step wasn't an input cell.
        </div>
      );
    }
  } else if (stepType === 'output') {
    if (
      !inputOrOutputCell ||
      inputOrOutputCell.cell_type !== 'python3-output'
    ) {
      return (
        <div className={'step-parent output-step-parent'}>
          The first cell of the Output step wasn't an output cell.
        </div>
      );
    }
  }

  if (isFailure) {
    return <ExecutionError />;
  }

  const elements = inputOrOutputCell.as_elements || [];
  const layouts = inputOrOutputCell.layouts || { [currentBreakpoint]: [] };

  if (layouts[currentBreakpoint]) {
    // If in arrange editor: Mark all elements as movable. If in app: Mark all elements as static.
    layouts[currentBreakpoint] = layouts[currentBreakpoint].map((l) => ({
      ...l,
      static: !isArrangeEditor,
    }));
  }

  // (A) Determine the layout elements that are contained in the layout, but missing in the elements
  // (-> these elements were removed from the Input/Output Cell)
  const missingLayouts = layouts[currentBreakpoint].filter(
    (l) => !elements.map((e) => e.id).includes(l.i)
  ); // TODO This should not be fixed for the cs layout only here

  // (B) Determine the elements that are placed in the layout
  const layoutedElements = elements.filter((e) =>
    layouts[currentBreakpoint].map((l) => l.i).includes(e.id)
  );

  const removeLayoutItem = (id) =>
    updateLayouts(path, inputOrOutputCell.id, {
      ...layouts,
      [currentBreakpoint]: layouts[currentBreakpoint].filter((l) => l.i !== id),
    });

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.UNARRANGED_APP_ELEMENT,
    drop: (dragObject) => onElementDrop(dragObject),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      className={
        'step-parent input-step-parent ' + (isOver ? 'dragged-over' : '')
      }
    >
      {isExecuting && <Busy isBusy positionAbsolute />}
      <div
        ref={drop}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <ResponsiveGridLayout
          className={'layout'}
          layouts={layouts}
          breakpoints={{ xs: 480, xxs: 0 }}
          cols={{ xs: 12, xxs: 2 }}
          rowHeight={30}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={(newBreakpoint) =>
            updateCurrentBreakpoint(newBreakpoint)
          }
        >
          {renderInputOrOutputElements(
            stepType,
            layoutedElements,
            inputOrOutputCell,
            path,
            removeLayoutItem,
            isArrangeEditor,
            parentType,
            appVersionCode
          )}
          {missingLayouts.map((l) => (
            <div key={l.i} className={'grid-item-parent missing-grid-item'}>
              <span>Missing Element</span>
              <div
                className={'remove-icon'}
                onClick={() => removeLayoutItem(l.i)}
              >
                <FiX size={16} />
              </div>
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};
InputOrOutputStep.propTypes = {
  activeExecutionPlanStep: PropTypes.shape({
    /** List of cells belonging to this excution step */
    cells: PropTypes.arrayOf(
      PropTypes.shape({
        /** Type of the cell: code|markdown|raw|python3-input|python3-output */
        type: PropTypes.string,
        /** The layout definition for react-grid-layout. Only set if type='python3-input' or 'python3-output' */
        layout: PropTypes.arrayOf(
          PropTypes.shape({
            /** Key of the layou telement = element.id */
            i: PropTypes.string.isRequired,
            /** x Position of the layout element */
            x: PropTypes.number.isRequired,
            /** y Position of the layout element */
            y: PropTypes.number.isRequired,
            /** width of the layout element */
            w: PropTypes.number.isRequired,
            /** height of the layout element */
            h: PropTypes.number.isRequired,
          })
        ),
      })
    ),
  }),
  path: PropTypes.string,
  /** Is the loading screen supposed to be shown? */
  isExecuting: PropTypes.bool,
  /** Was there a failure during the execution of the previous execution plan step? */
  isFailure: PropTypes.bool,
  /** Update the react-grid-layouts in the redux state */
  updateLayouts: PropTypes.func.isRequired,
  /** Update the list of not layouted elements in the redux state */
  updateUnlayoutedElements: PropTypes.func.isRequired,
  /** input | output */
  stepType: PropTypes.string.isRequired,
  currentBreakpoint: PropTypes.string,
  /** Updates the current breakpoint of react-grid-layout (for responsiveness) */
  updateCurrentBreakpoint: PropTypes.func.isRequired,
  /** Is the app called in the arrange editor modal or in the read/execute-only mode? */
  isArrangeEditor: PropTypes.bool.isRequired,
  /** notebook | app */
  parentType: PropTypes.oneOf(['app', 'notebook']),
  /** Only set if parentType='app' */
  appVersionCode: PropTypes.string,
};
InputOrOutputStep.defaultProps = {
  currentBreakpoint: 'xs',
  parentType: 'notebook',
  appVersionCode: '',
};

export default InputOrOutputStep;
