import { createAction } from 'typesafe-actions';
import { UserResponse } from './api.types';
import {
  FETCH_RUNNING_APPS,
  FETCH_RUNNING_APPS_FAIL,
  FETCH_RUNNING_APPS_SUCCESS,
  FETCH_RUNNING_WORKBENCHES,
  FETCH_RUNNING_WORKBENCHES_FAIL,
  FETCH_RUNNING_WORKBENCHES_SUCCESS,
  STOP_APP,
  STOP_APP_FAIL,
  STOP_APP_SUCCESS,
  STOP_WORKBENCH,
  STOP_WORKBENCH_FAIL,
  STOP_WORKBENCH_SUCCESS,
} from './actions.types';

export const fetchRunningWorkbench = createAction(FETCH_RUNNING_WORKBENCHES)();

export const fetchRunningWorkbenchSuccess = createAction(
  FETCH_RUNNING_WORKBENCHES_SUCCESS,
  (data: UserResponse[]) => ({ data })
)();

export const fetchRunningWorkbenchFail = createAction(
  FETCH_RUNNING_WORKBENCHES_FAIL,
  (error: string) => ({ error })
)();

export const stopWorkbench = createAction(
  STOP_WORKBENCH,
  (userCodeLower: string) => ({ userCodeLower })
)();

export const stopWorkbenchSuccess = createAction(
  STOP_WORKBENCH_SUCCESS,
  (userCodeLower: string) => ({ userCodeLower })
)();

export const stopWorkbenchFail = createAction(
  STOP_WORKBENCH_FAIL,
  (userCodeLower: string, error: string) => ({ userCodeLower, error })
)();

export const fetchRunningApps = createAction(FETCH_RUNNING_APPS)();

export const fetchRunningAppsSuccess = createAction(
  FETCH_RUNNING_APPS_SUCCESS,
  (data: UserResponse[]) => ({ data })
)();

export const fetchRunningAppsFail = createAction(
  FETCH_RUNNING_APPS_FAIL,
  (error: string) => ({ error })
)();

export const stopApp = createAction(
  STOP_APP,
  (userCodeLower: string, appVersionCodeLower: string) => ({
    userCodeLower,
    appVersionCodeLower,
  })
)();

export const stopAppSuccess = createAction(
  STOP_APP_SUCCESS,
  (userCodeLower: string, appVersionCodeLower: string) => ({
    userCodeLower,
    appVersionCodeLower,
  })
)();

export const stopAppFail = createAction(
  STOP_APP_FAIL,
  (userCodeLower: string, appVersionCodeLower: string, error: string) => ({
    userCodeLower,
    error,
    appVersionCodeLower,
  })
)();
