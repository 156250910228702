import React from 'react';
import { FormattedMessage } from 'react-intl';
import augurMsgs from 'common/dist/messages/augurs';

export interface Props {
  jobType: string;
}

export default function GraphWait({ children, jobType = 'learning' }) {
  return (
    <div className='graphWait'>
      <div>
        <div className='graphWait-pic'>&nbsp;</div>
        <div className='h4'>{children}</div>

        <p>
          {jobType === 'learning' && (
            <FormattedMessage {...augurMsgs.augurWaitForLearning} />
          )}
          {jobType === 'evaluation' && (
            <FormattedMessage {...augurMsgs.augurWaitForEvaluation} />
          )}
          {jobType === 'prediction' && (
            <FormattedMessage {...augurMsgs.augurWaitForPrediction} />
          )}
        </p>
      </div>
    </div>
  );
}
