import { connect } from 'react-redux';

import AccountAddPhoto from './AccountAddPhoto';
import * as selector from '../../../redux/selectors/account.selector';
import {
  uploadAvatar,
  deleteAvatar,
  fetchAvatar,
} from '../../../redux/modules/account.avatar.module';
import { DeprecatedRootState } from '../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  const { loading, loaded, error, data } = state.account.photo || {};
  const { uploading, uploaded, progress, uploadError } =
    state.account.photo || {};
  return {
    state: selector.avatarState(state),

    loading,
    loaded,
    error,
    data,

    uploading,
    uploaded,
    progress,
    uploadError,
  };
}

export const mapDispatchToProps = {
  fetchAvatar,
  uploadAvatar,
  deleteAvatar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountAddPhoto);
