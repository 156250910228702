import {
  ComponentDatapoolDetailsTab,
  DatapoolDetailsTab,
} from './DatapoolDetailsTab';
import { MenuEntryType } from '../../../../../molecules/with-sidemenu/SideMenu';
import { DatapoolType } from 'common/dist/types/datapool';
import { RouteConfig } from 'react-router-config';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';

/**
 * Interface for all components required to fill the "Datapool Details"
 */
export default abstract class DatapoolDetails {
  tabs: (
    datapool: DatapoolType,
    dataSource: DataSource
  ) => DatapoolDetailsTab[];

  /**
   * Returns all required routes for the Datapool Details
   */
  getAllRoutes(
    shortName: string,
    datapool: DatapoolType,
    dataSource?: DataSource
  ): RouteConfig[] {
    return this.tabs(datapool, dataSource)
      .filter((tab) => tab.type === 'component')
      .map((tab: ComponentDatapoolDetailsTab) => ({
        path: `/app/habitat/:habitatCode/datapool/:datapoolCode/${shortName}/${tab.id}`,
        component: tab.component,
        routes: tab.subRoutes,
      }));
  }

  /**
   * Returns all required objects to set up the Menu-Tab-View on the left side of the Datapool details.
   * @param habitatCode
   * @param datapoolCode
   * @param shortDatapoolType The short version of the augur / module type! Misleading naming ...
   * @param datapool
   * @param dataSource
   */
  getAllMenuTabs(
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string,
    datapool: DatapoolType,
    dataSource?: DataSource
  ): MenuEntryType[] {
    return this.tabs(datapool, dataSource)
      .filter(
        (tab) =>
          tab.type !== 'component' ||
          (tab.type === 'component' && tab.showTab(datapool))
      ) // Remove the component tabs that are not supposed to be shown in the menu
      .map((tab) => {
        switch (tab.type) {
          case 'hr': // Fall-through intended
          case 'headline': {
            // For the "hr" and "headline" cases the Tab can simply be returned, since it's a compatible MenuEntry object
            return tab;
          }
          case 'component':
            return {
              // In this case the Tab needs to be mapped to the MenuEntry object
              type: 'link',
              color: 'black',
              id: tab.id,
              to: tab.to(habitatCode, datapoolCode, shortDatapoolType),
              nameIntlId: tab.title.id as string,
              nameDefault: tab.title.defaultMessage as string,
              icon: tab.icon,
            };
        }
      });
  }
}
