import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StartingAppInfo from './StartingAppInfo.container';
import Notifications from 'react-notification-system-redux';
import RunningApp from '../running-app/RunningApp.container';
import { withRouter } from 'react-router-dom';
import Busy from '../../atoms/busy/Busy';
import MainContainer from '../../pages/main-container/MainContainer';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';
import NoPermissions from '../../pages/no-permissions/NoPermissions';
import * as keycloak from 'common/dist/constants/keycloak';
import errorMessages from 'common/dist/messages/error';

class StartingApp extends Component {
  constructor(props) {
    super(props);

    this.renderRunning = this.renderRunning.bind(this);
    this.renderNotRunning = this.renderNotRunning.bind(this);
  }

  componentDidMount() {
    const {
      match,
      notebookUser,
      checkWhetherAppIsRunning,
      fetchAppPermissions,
    } = this.props;
    const appVersionCode = match.params.appVersionCode;
    checkWhetherAppIsRunning(notebookUser, appVersionCode, false); // Check without retries
    fetchAppPermissions();
  }

  /**
   * Render the loading screen (that the status of the notebook is currently requested)
   * @returns {*}
   */
  renderLoading() {
    return <Busy isBusy positionAbsolute />;
  }

  /**
   * Render the running app
   * @returns {*}
   */
  renderRunning() {
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={false}
        fullWidth={true}
      >
        <div className={'running-app-parent'}>
          <RunningApp />
        </div>
      </MainContainer>
    );
  }

  renderNotRunning() {
    const { notifications, match } = this.props;
    const appVersionCode = match.params.appVersionCode;
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={true}
        fullWidth={false}
      >
        <div className={'running-app-parent'}>
          <div className={'starting-app-container'}>
            <div className={'button-container'}>
              <StartingAppInfo appVersionCode={appVersionCode} />
            </div>
            <Notifications notifications={notifications} />
          </div>
        </div>
      </MainContainer>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <MainContainer
        additionalClassname={'Apps'}
        transparent={false}
        fullWidth={false}
      >
        <div className={'running-app-parent'}>
          <div style={{ margin: '200px' }}>{error}</div>
        </div>
      </MainContainer>
    );
  }

  renderInner() {
    const { loading, loaded, error, isRunning, permissions } = this.props;
    const appCode = this.props.match.params.appCode;
    const missingPermissionsError =
      permissions?.loaded &&
      ((permissions?.data || {})[keycloak.appResourceName(appCode)] || [])
        .length === 0;
    if (missingPermissionsError) return <NoPermissions fullViewHeight />;
    else if (loading) return this.renderLoading();
    else if (loaded && isRunning) return this.renderRunning();
    else if (loaded && !isRunning) return this.renderNotRunning();
    else if (error) return this.renderError();
    return <div />; // This case should never occur
  }

  render() {
    return (
      <ErrorBoundary
        errorHeadline={errorMessages.headline}
        errorDescription={errorMessages.description}
      >
        {this.renderInner()}
      </ErrorBoundary>
    );
  }
}

StartingApp.propTypes = {
  /** Injected by withRouter */
  match: PropTypes.shape({
    params: PropTypes.shape({
      /** Code of the app version */
      appVersionCode: PropTypes.string.isRequired,
    }),
  }),
  /** Check whether the app is already running */
  checkWhetherAppIsRunning: PropTypes.func.isRequired,
  /** Lower case code of the currently active user of the Dashboard */
  notebookUser: PropTypes.string.isRequired,
  /** 'loading' while the check whether the app is already running is performed */
  loading: PropTypes.bool,
  /** 'loaded' once the check whether the app is already running is performed */
  loaded: PropTypes.bool,
  /** Is the app running? */
  isRunning: PropTypes.bool,
  /** Possible error during the check whether the app is already running is performed */
  error: PropTypes.string,
  startApp: PropTypes.func.isRequired,
  notifications: PropTypes.array,
};

export default withRouter(StartingApp);
