import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import LoginHistoryItems from './LoginHistoryItems';
import Expand from './Expand';
import './styles.scss';
import { HistoryItemType } from '../../../../store/account/state.types';

type Props = {
  history: HistoryItemType[];
};

type State = {
  expanded: boolean;
};

class LoginHistory extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.expand = this.expand.bind(this);
    this.shrink = this.shrink.bind(this);
  }

  expand() {
    this.setState({
      expanded: true,
    });
  }

  shrink() {
    this.setState({
      expanded: false,
    });
  }

  render() {
    const { history } = this.props;

    const expandLinkShown = history.length > 5;

    if (history && history.length === 0) {
      // History is empty - don't render the headline or anything
      return <div className='LoginHistory--wrap' />;
    }

    return (
      <div className={'LoginHistory'}>
        <div className='LoginHistory--title'>
          <FormattedMessage
            id='account.loginHistory.title'
            defaultMessage='Login History'
          />
        </div>
        <div className='LoginHistory--wrap'>
          <div
            className={classNames({
              'LoginHistory--preview': !this.state.expanded,
            })}
          >
            <LoginHistoryItems history={history} />
          </div>
          {expandLinkShown && (
            <Expand
              expanded={this.state.expanded}
              onExpand={this.expand}
              onShrink={this.shrink}
            />
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(LoginHistory);
