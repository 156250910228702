import React, { FC } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { LegendEntryType } from './PieChart';

type Props = {
  legend: LegendEntryType[];
  intl: IntlShape;
};

const Legend: FC<Props> = ({ legend, intl }) => {
  const localizedLegend = legend.map((item) => ({
    ...item,
    label: intl.formatMessage({
      id: item.label.id,
      defaultMessage: item.label.defaultMessage,
    }),
  }));

  return (
    <ul className='PieChart--legend'>
      {localizedLegend.map((item, index) => (
        <li key={index}>
          <span
            className='PieChart--legend-dot'
            style={{ backgroundColor: item.color }}
          />
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export default injectIntl(Legend);
