import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RepositoryHeader from '../overview/RepositoryHeader.container';
import { collaborationSpaceRoutes } from '../../routes';
import { Link, withRouter } from 'react-router-dom';
import ContentElement from './ContentElement.container';
import DropdownSelectInput from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

function sortData(data, ordering) {
  if (!data) return [];
  if (ordering === 'last-updated') {
    return data.sort((a, b) => {
      if (!a.commit.timestamp || !b.commit.timestamp) return -1;
      return a.commit.timestamp > b.commit.timestamp ? -1 : 1;
    });
  } else if (ordering === 'oldest-updated') {
    return data.sort((a, b) => {
      if (!a.commit.timestamp || !b.commit.timestamp) return -1;
      return a.commit.timestamp < b.commit.timestamp ? -1 : 1;
    });
  } else if (ordering === 'name-desc') {
    return data.sort((a, b) => {
      if (!a.name || !b.name) return -1;
      return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
    });
  } else {
    return data.sort((a, b) => {
      if (!a.name || !b.name) return -1;
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });
  }
}

class Branches extends Component {
  constructor(props) {
    super(props);
    this.state = { searchString: '', ordering: 'name-asc' };
  }

  componentDidMount() {
    const { fetchBranches } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    fetchBranches(group, repositoryName);
  }

  render() {
    const { branches, switchBranch } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    const searchedData = this.state.searchString
      ? branches.filter((branch) =>
          branch.name.includes(this.state.searchString)
        )
      : branches;

    const options = [
      { label: 'Name A-Z', value: 'name-asc' },
      { label: 'Name Z-A', value: 'name-desc' },
      { label: 'Last Updated', value: 'last-updated' },
      { label: 'Oldest Updated', value: 'oldest-updated' },
    ];

    return (
      <div className={'CollaborationSpace--content'}>
        <div className={'repository branches'}>
          <RepositoryHeader />

          <div className={'repository-top'}>
            <div className={'repository-info'}>
              <div className={'repository-top-line'}>
                <span className={'repository-name'}>Branches</span>
              </div>
            </div>
          </div>
          <div className={'headline'}>
            <div className={'search'}>
              <input
                placeholder={'Filter by name'}
                type={'text'}
                value={this.state.searchString}
                onChange={(e) =>
                  this.setState({ ...this.state, searchString: e.target.value })
                }
              />
            </div>
            <DropdownSelectInput
              id='sorting-selector'
              name='order'
              containerClassName={'sorting-selector'}
              options={options}
              value={options.find((o) => o.value === this.state.ordering)}
              onChange={(option) => {
                this.setState({ ...this.state, ordering: option.value });
              }}
              openOnFocus
              clearable={false}
            />
          </div>
          {sortData(searchedData, this.state.ordering).map((branch, i) => (
            <Link
              to={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}`}
              style={{ textDecoration: 'none' }}
              onClick={() => switchBranch(branch.name)}
            >
              <ContentElement
                key={i}
                name={branch.name}
                commit={branch.commit}
                group={group}
                repositoryName={repositoryName}
              />
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

Branches.propTypes = {
  activeBranch: PropTypes.string,
  branches: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  fetchBranches: PropTypes.func.isRequired,
  switchBranch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      group: PropTypes.string,
      repositoryName: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withRouter(Branches);
