import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputValueSafe } from '../../python3-input-cell/elements/_interface/input-element.form';
import { FiBookmark } from 'react-icons/fi';

export default class TextInputCol extends Component {
  render() {
    const {
      label,
      input: { onChange, name, onBlur, onFocus, value },
      meta: { touched, error },
      allowVariable,
      showSelectVariable,
      path,
      cellId,
      validVariableTypes,
    } = this.props;
    return (
      <div className={`input-col${error ? ' error' : ''}`}>
        <div className={'form--headline'}>
          <p className={'form--label'}>{label}</p>
          {touched && error && <p className={'form--error'}>{error}</p>}
        </div>
        <div className={'form--input-parent'}>
          <input
            type={'text'}
            value={inputValueSafe(value)}
            onChange={(e) => onChange({ inputValue: e.target.value })} // Construct the settingField (= { origin, variableName, inputValue })
            // TODO onBlur and onFocus cause the loss of the value currently (probably the value needs to be adjusted)
            // onBlur={onBlur}
            // onFocus={onFocus}
          />
          {allowVariable && (
            <div
              title={'Use variable value'}
              className={`workbench-button use-variable-button origin-${
                value.origin || 'input'
              }`}
              onClick={() =>
                showSelectVariable(
                  path,
                  cellId,
                  name,
                  validVariableTypes,
                  label
                )
              }
            >
              <FiBookmark className={'icon'} size={'20px'} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

TextInputCol.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.shape({
      origin: PropTypes.string,
      variableName: PropTypes.string,
      inputValue: PropTypes.string,
    }),
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  allowVariable: PropTypes.bool,
  path: PropTypes.string,
  cellId: PropTypes.string,
  showSelectVariable: PropTypes.func.isRequired,
  /** What types are allowed to be selected as variables? */
  validVariableTypes: PropTypes.arrayOf(PropTypes.string),
};
TextInputCol.defaultProps = {
  allowVariable: false,
};
