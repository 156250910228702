import { CompletedApiRequest, postApiRequest } from './_tools';
import ResourceRepresentation from '@keycloak/keycloak-admin-client/lib/defs/resourceRepresentation';

export function evaluatePermissions(
  resourceType: string
): CompletedApiRequest<{ [resourceName: string]: string[] }> {
  const body: { resources: ResourceRepresentation[] } = {
    resources: [{ type: resourceType }],
  };
  return postApiRequest(`/api/permissions/evaluate`, body);
}
