import { apiRequest, putApiRequest, patchApiRequest } from './_apiRequests';
import { API_PORT } from './git.notebook';

export function fetchContent(path, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${encodeURIComponent(
    path
  )}`;
  return apiRequest(url);
}

export function renameContent(oldPath, newPath, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${encodeURIComponent(
    oldPath
  )}`;
  const body = {
    path: newPath,
  };
  return patchApiRequest(url, body);
}

export function restoreContent(name, targetPath, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/proxy/${API_PORT}/restore`;
  const body = {
    name,
    targetPath,
  };
  return putApiRequest(url, body);
}

export function putFile(path, content, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${path}`;
  const body = {
    type: 'file',
    format: 'text',
    path,
    content,
  };
  return putApiRequest(url, body);
}
