import React, { FC } from 'react';
import { deriveElements } from './utils';
import ElementCardTable from '../../../organisms/element-card-table/ElementCardTable';
import { DatapoolType } from 'common/dist/types/datapool';

type Props = {
  datapools: DatapoolType[];
  habitatCode: string;
};

const CodeCapsulesListView: FC<Props> = (props) => {
  const { datapools, habitatCode } = props;

  // Derive the elements
  const elements = deriveElements(datapools, habitatCode);

  return (
    <div className={'datapools'}>
      <ElementCardTable elements={elements} />
    </div>
  );
};

export default CodeCapsulesListView;
