import { axisBottom } from 'd3-axis';

import appendRoot from '../common/root';
import getScales from '../common/scales';

export function append(node, width, height, runs, maxY) {
  const { g, areaWidth, areaHeight } = appendRoot(node, width, height);

  const { xScale } = getScales(areaWidth, areaHeight, runs, maxY);

  const gAxis = g.append('g').attr('transform', `translate(0,${areaHeight})`);

  const gTicks = gAxis.append('g').attr('class', 'accuracy-detail-axis');

  const axisTicks = axisBottom(xScale).ticks(10);

  gTicks.call(axisTicks);
}
