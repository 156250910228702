import ModelSettingsCategory from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { MessageDescriptor } from 'react-intl';
import { FiRefreshCw } from 'react-icons/fi';
import React from 'react';
import RelearningCategory from './RelearningCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

export default class RelearningCategoryWrapper
  implements ModelSettingsCategory<AugurSettingsDataNbc>
{
  component = RelearningCategory;
  icon: React.ComponentType = () => <FiRefreshCw size={17} />;
  id = 'relearning';
  label: MessageDescriptor = {
    id: 'no-id',
    defaultMessage: 'Re-Learning',
  };
}
