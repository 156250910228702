import { connect } from 'react-redux';
import Branches from './Branches';
import {
  fetchBranches,
  switchBranch,
} from '../../../../redux/workbench/modules/collab.repository.module';

export function mapStateToProps(state) {
  const { activeBranch } = state.workbench.collab.repository;
  return {
    activeBranch,
    branches: state.workbench.collab.branches.data,
  };
}

export const mapDispatchToProps = {
  fetchBranches,
  switchBranch,
};

export default connect(mapStateToProps, mapDispatchToProps)(Branches);
