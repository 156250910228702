import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiFolder, FiEdit, FiFile } from 'react-icons/fi';

export default class RepoContentElement extends Component {
  render() {
    const { type, name, path, sha } = this.props;
    return (
      <div className={'ct-row'}>
        <div className={'ct-col ct-col-40px'}>
          {type === 'dir' && (
            <FiFolder className={'content-element-icon'} size={'20px'} />
          )}
          {type === 'file' &&
            (name && (name.endsWith('.ipynb') || (name.endsWith('.asapp')))  ? (
              <FiEdit className={'content-element-icon'} size={'20px'} />
            ) : (
              <FiFile className={'content-element-icon'} size={'20px'} />
            ))}
        </div>
        <div className={'ct-col ct-col-flex-grow'}>
          <span>{name}</span>
        </div>
      </div>
    );
  }
}
RepoContentElement.propTypes = {
  /** Type of the content element:  file / dir / symlink / submodule */
  type: PropTypes.string.isRequired,
  /** Name of the content element */
  name: PropTypes.string.isRequired,
  /** Path of the content element */
  path: PropTypes.string.isRequired,
  /** sha of the content element */
  sha: PropTypes.string.isRequired,
};
