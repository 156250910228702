import React from 'react';
import DatapoolSettingsElement from '../../../../../_interfaces/datapool-details/settings/DatapoolSettingsElement';
import DatapoolNameAndCodeContainer from './DatapoolNameAndCode.container';
import datapoolMsgs from 'common/dist/messages/datapools';

/**
 * Shows the augur name & code in the augur settings tab
 */
export default class DatapoolNameAndCode implements DatapoolSettingsElement {
  // @ts-ignore
  component: React.Component = DatapoolNameAndCodeContainer;
  title = datapoolMsgs.msgDatapoolSettingsDatapoolTitle;
  description = datapoolMsgs.msgDatapoolSettingsDatapoolDescription;
}
