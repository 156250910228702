import React, { FC, useMemo, useState } from 'react';
import PerformanceDriftChart from './PerformanceDriftChart';
import Sizer from '../../atoms/sizer/Sizer';
import SelectableTimeline from '../../molecules/selectable-timeline/SelectableTimeline';
import _ from 'lodash';
import styles from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import {
  Domain,
  DriftDataEntry,
} from '../../modelManagement/modules/common/augur-details-tabs/accuracyDetails/types';

export type Props = {
  timeSeries: DriftDataEntry[];
  kpi: string;
};

const CHART_LIMIT = 20;

const filterTimeSeries = (domain: Domain, reports: DriftDataEntry[]) => {
  if (!domain || domain.length < 2) return reports; // no domain defined: do not filter

  return reports.filter(
    (item) =>
      Date.parse(item.time) >= domain[0] && Date.parse(item.time) <= domain[1]
  );
};

const PerformanceDrift: FC<Props> = ({ timeSeries, kpi }) => {
  const [domain, setDomain] = useState<Domain>([]);

  const timeline = useMemo(() => {
    // the event can fire very often in a short amount of time, this delays the state change until a final selection is made
    const onChangeSelection = _.debounce(setDomain, 200);

    return (
      <Sizer>
        <SelectableTimeline
          data={timeSeries.map((entry) => ({
            value: entry.data.drift,
            time: entry.time,
          }))}
          onChangeSelection={onChangeSelection}
        />
      </Sizer>
    );
  }, [timeSeries]);

  const filteredData = filterTimeSeries(domain, timeSeries);
  const filteredAndLimitedReports = filteredData.slice(-CHART_LIMIT);
  const showSliceWarning = filteredData.length > CHART_LIMIT;

  return (
    <div className={styles.performanceDrift}>
      {showSliceWarning && (
        <div className={styles.sliceWarning}>
          <FormattedMessage
            id={'augur.performance_drift.evaluation_reports'}
            defaultMessage={
              'Showing only the { limit } most recent evaluation reports of your selection'
            }
            values={{ limit: CHART_LIMIT }}
          />
        </div>
      )}

      <div className={styles.performanceDriftChart}>
        <Sizer>
          <PerformanceDriftChart
            timeSeries={filteredAndLimitedReports}
            domain={domain}
            selectedKpi={kpi}
          />
        </Sizer>
      </div>
      <div className={styles.performanceDriftChartSizer}>{timeline}</div>
    </div>
  );
};

export default PerformanceDrift;
