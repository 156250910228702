import { connect } from 'react-redux';
import { getFormValues, isValid, reduxForm } from 'redux-form';
import { form, formName } from './CreateTemplate.form';
import { DeprecatedRootState } from '../../../../store/state.type';
import CreateTemplate, { Props } from './CreateTemplate';
import { createTemplate } from '../../../../store/admin/backups/actions';
import { ToBeRefined } from 'common/dist/types/todo_type';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    submitting: state.adminBackups.createTemplate.submitting,
    formValues: getFormValues(formName)(state) || {},
    isValid: isValid(formName)(state),
  };
}

export const mapDispatchToProps = {
  createTemplate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm<ToBeRefined, Props>(form)(CreateTemplate));
