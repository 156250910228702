import React, { FC } from 'react';
import styles from './styles.module.scss';
import LatestScoring from './latest-scoring/LatestScoring';
import AllScoringRuns from './all-scoring-runs/AllScoringRuns';
import { CProps } from '../ScoreDistribution.container';
import { JobDescriptionBanner } from '../../job-banner/JobDescriptionBanner';

type Props = {
  /** Code of the active model for this augur */
  activeModelCode?: string;
};

type CombinedProps = Props & Pick<CProps, 'showRunMassPredictionModal'>;

const Overview: FC<CombinedProps> = ({
  activeModelCode,
  showRunMassPredictionModal,
}) => {
  return (
    <JobDescriptionBanner>
      <div className={styles.scoreDistributionOverview}>
        <div className={styles.scoreDistributionOverviewContent}>
          <LatestScoring activeModelCode={activeModelCode} />

          <AllScoringRuns
            activeModelCode={activeModelCode}
            showRunMassPredictionModal={showRunMassPredictionModal}
          />
        </div>
      </div>
    </JobDescriptionBanner>
  );
};

export default Overview;
