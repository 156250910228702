import { connect, ConnectedProps } from 'react-redux';
import RepositoryMeta from './RepositoryMeta';
import { showConfigureCodeCapsuleModal } from '../../../../../../redux/workbench/modules/codeCapsule.module';

const mapDispatchToProps = {
  showConfigureCodeCapsuleModal,
};

const connector = connect(null, mapDispatchToProps);
export type connectedProps = ConnectedProps<typeof connector>;
export default connector(RepositoryMeta);
