import React, { FC } from 'react';
import './styles.scss';
import CandidateConditions from './candidate-conditions/CandidateConditions.container';
import { ModelSettingsProps } from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

const CandidateConditionsCategory: FC<
  ModelSettingsProps<AugurSettingsDataNbc>
> = (props) => {
  return (
    <div className={'CandidateConditionsCategory'}>
      <CandidateConditions {...props} />
    </div>
  );
};

export default CandidateConditionsCategory;
