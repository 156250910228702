import React, { Component } from 'react';
import { CodeCell } from 'common/dist/utils/workbench/jupyterNotebookFormat';
import { BaseInputElement, SettingsType } from '../_interface/P3InputElement';
import { FiRotateCcw } from 'react-icons/all';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { TableDataType } from '../../../../../../../../../../molecules/editable-table/EditableTable';
import EditableTableComponent from '../../../../../../../../../../molecules/editable-table/EditableTable';

type EditableTableInputElement = BaseInputElement & {
  data: {
    tableData: TableDataType;
  };
  settings: {
    inputTable: SettingsType<string | undefined>;
  };
};

type Props = {
  path: string;
  cellId: string;
  /** Function to update the user input */
  updateDataOfInputElement: (path, cellId, element, data) => void;
  /** Input element (including the settings + the user input) */
  element: EditableTableInputElement;
  cell: CodeCell;
  parentType: 'app' | 'notebook';
};

const getInitialTableData = (props) => {
  const { element, cell } = props;
  // Get the value from the variable
  const inputTableVariable = element.settings?.inputTable?.variableName;
  // @ts-ignore
  const initialTableData = cell.as_variables?.find(
    (x) => x.name === inputTableVariable
  )?.parsedValue;

  return initialTableData;
};

export default class EditableTable extends Component<Props> {
  componentDidUpdate(prevProps) {
    const { cell, element, updateDataOfInputElement, path } = this.props;

    const initialTableData = getInitialTableData(this.props);
    const prevTableData = getInitialTableData(prevProps);

    if (initialTableData !== prevTableData && !element.data?.tableData) {
      // @ts-ignore
      updateDataOfInputElement(path, cell.id, element, {
        tableData: initialTableData,
      });
    }
  }

  render() {
    const {
      path,
      cellId,
      updateDataOfInputElement,
      element,
      cell,
      parentType,
    } = this.props;

    return (
      <div
        className={classNames(
          'inner-input-element editable-table-element',
          styles.editableTableElement,
          { [styles.inNotebook]: parentType === 'notebook' }
        )}
      >
        <div
          className={styles.resetButton}
          title={'Reset to Input Variable'}
          onClick={() => {
            const initialTableData = getInitialTableData(this.props);
            // @ts-ignore
            updateDataOfInputElement(path, cell.id, element, {
              tableData: initialTableData,
            });
          }}
        >
          <FiRotateCcw size={16} />
        </div>
        <EditableTableComponent
          value={element.data?.tableData}
          onChange={(tableData: TableDataType) =>
            // @ts-ignore
            updateDataOfInputElement(path, cell.id, element, {
              tableData,
            })
          }
        />
      </div>
    );
  }
}
