import React, { Component, Fragment } from 'react';

import store from '../../store/store';
import * as userModule from '../../redux/modules/user.module';
import * as loginHistory from '../../redux/modules/account.loginHistory.module';
import { pageDataLoad } from '../../redux/modules/page.module';
import MainContainer from '../pages/main-container/MainContainer';
import AccountInfo from '../molecules/user-profile/user-info/UserInfo';
import LoginHistory from '../molecules/user-profile/login-history/LoginHistory.container';
import Language from '../molecules/user-profile/language/Language.container';
import ChangePassword from '../molecules/user-profile/change-password/ChangePassword';
import UserSidenotes from '../molecules/user-profile/user-sidenotes/UserSidenotes.container';
import './style.scss';
import SubHeader from '../organisms/sub-header/SubHeader.container';
import OverlayUserProfile from '../organisms/sub-header/overlay-user-profile/OverlayUserProfile';

type Props = {
  saveChanges: () => void;
  cancel: () => void;
};

class UserProfile extends Component<Props, {}> {
  componentDidMount = () => {
    store.dispatch(
      pageDataLoad(
        userModule.fetchUser(),
        // @ts-ignore: Type actions/reducers first
        userModule.fetchUserSuccess,
        // @ts-ignore: Type actions/reducers first
        userModule.fetchUserFail
      )
    );

    store.dispatch(
      pageDataLoad(
        loginHistory.loadLoginHistory(),
        // @ts-ignore: Type actions/reducers first
        loginHistory.loadLoginHistorySuccess,
        // @ts-ignore: Type actions/reducers first
        loginHistory.loadLoginHistoryFail
      )
    );
  };

  render() {
    const { cancel, saveChanges } = this.props;
    return (
      <Fragment>
        <SubHeader hasOverlay Overlay={OverlayUserProfile} />

        <MainContainer
          additionalClassname={'Account'}
          transparent={false}
          fullWidth={false}
        >
          <div className={'UserProfile'}>
            <AccountInfo />
            <Language />
            <ChangePassword />
            <LoginHistory />
            <UserSidenotes />
          </div>
        </MainContainer>
      </Fragment>
    );
  }
}

export default UserProfile;
