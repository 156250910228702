import { ComponentType } from 'react';
import { ComponentDatapoolDetailsTab } from '../../_interfaces/datapool-details/DatapoolDetailsTab';
import Statistics from './Statistics.container';
import StatisticsIcon from './StatisticsIcon';
import { DatapoolType } from 'common/dist/types/datapool';

export default class StatisticsWrapper extends ComponentDatapoolDetailsTab {
  type: 'component' = 'component';
  id = 'statistics';
  title = {
    id: 'datapoolDetails.sideNav.statistics',
    defaultMessage: 'Statistics',
  };
  to = (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${shortDatapoolType}/statistics`;

  icon: ComponentType = StatisticsIcon;
  component: ComponentType = Statistics;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType): boolean => true;
}
