import { area } from 'd3-shape';

import {
  getX,
  getY,
  getRunData,
} from '../../../../../../../../../core/common/accuracyDetail';

export default function append(node, run, fill, xScale, yScale, height) {
  const areaPath = area()
    .x((d) => xScale(getX(d)))
    .y0((d) => yScale(getY(d)))
    .y1(height)(getRunData(run));

  node
    .append('path')
    .attr('fill', fill)
    .attr('d', areaPath)
    .attr('opacity', 0.2);
}
