import React, { ComponentType } from 'react';
import ModelTuningResults from './ModelTuningResults.container';
import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import { FiCpu } from 'react-icons/fi';
import { MessageDescriptor } from 'react-intl';

export default class ModelTuningResultsWrapper extends ComponentAugurDetailsTab {
  component = ModelTuningResults;
  icon: ComponentType = () => <FiCpu size={17} />;
  id = 'model-tuning-results';

  to = (habitatCode: string, augurCode: string, shortAugurType: string) =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/model-tuning-results`;
  title: MessageDescriptor = {
    id: 'details.sideNav.model_tuning_results',
    defaultMessage: 'Model Tuning Results',
  };
  subRoutes: object[] | undefined = undefined;
}
