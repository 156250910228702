import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import { FiTrash2 } from 'react-icons/fi';
import classNames from 'classnames';
import styles from './styles.module.scss';

const trashAreaDndType = 'add-node';
const trashAreaSpec = {
  // The endDrag handler on the tree source will use some of the properties of
  // the source, like node, treeIndex, and path to determine where it was before.
  // The treeId must be changed, or it interprets it as dropping within itself.
  drop: (props, monitor) => ({ ...monitor.getItem(), treeId: 'trash' }),
};
const trashAreaCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver({ shallow: true }),
});

type Props = {
  connectDropTarget: (node: React.ReactNode) => React.ReactNode;
  isOver?: boolean;
};

class RemoveDropzone extends Component<Props> {
  render() {
    const { connectDropTarget, isOver } = this.props;

    return connectDropTarget(
      <div
        className={classNames(styles.removeDropzone, {
          [styles.isOver]: isOver,
        })}
      >
        <div className={styles.iconContainer}>
          <FiTrash2
            className={classNames(styles.icon, 'icon-add')}
            size={'20px'}
          />
        </div>
        <div
          className={classNames(
            styles.inputContainer,
            styles.inputContainerPlaceholder
          )}
        >
          <span>Drag & Drop to remove</span>
        </div>
      </div>
    );
  }
}

export default DropTarget(
  trashAreaDndType,
  trashAreaSpec,
  trashAreaCollect
)(RemoveDropzone);
