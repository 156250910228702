import { connect } from 'react-redux';
import TreePath from './TreePath';

export function mapStateToProps(state) {
  const path =
    (state.chart.active.tree.preview.path.length &&
      state.chart.active.tree.preview.path) ||
    state.chart.active.tree.selected.path;

  return {
    path,
  };
}

export default connect(mapStateToProps)(TreePath);
