import React, { FC } from 'react';
import { ConvertedDataEntry } from '../SelectableTimeline';
import { ScaleContinuousNumeric } from 'd3-scale';

type Props = {
  data: ConvertedDataEntry[];
  xScale: ScaleContinuousNumeric<number, number>;
  yScale: ScaleContinuousNumeric<number, number>;
  height: number;
  barWidth: number;
};

const Bars: FC<Props> = (props) => {
  const { data, yScale, height, xScale, barWidth } = props;
  return (
    <g>
      {data.map((item, index) => (
        <rect
          key={index}
          fill='#9CA2A9'
          width={barWidth}
          height={yScale(item.value)}
          x={xScale(item.time) - barWidth / 2}
          y={height - yScale(item.value)}
        />
      ))}
    </g>
  );
};
export default Bars;
