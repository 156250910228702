import { RenderColumn } from '../../molecules/table/Table';
import { FormattedDateTime } from '../../atoms/formatted-date-time/FormattedDateTime';
import React from 'react';

type Version = { number: string; createdAt: Date };

export const versionsRenderColumns: RenderColumn<Version, keyof Version>[] = [
  {
    key: 'number',
    renderHeader: () => <>Tag</>,
    renderCell: (number: string) => (
      <div
        // Table widths don't work well so use the element itself
        style={{ width: '40ch', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {number}
      </div>
    ),
  },
  {
    key: 'createdAt',
    renderHeader: () => <>Created At</>,
    renderCell: (createdAt: Date) => (
      <FormattedDateTime date={new Date(createdAt)} />
    ),
  },
];

// Regex that matches for valid Semantic Versioning tags.
// See https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
const semVerRegex =
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;

// Tries to increment the patch number of the latest matching Semantic Versioning tag.
// If there are no versions yet, "0.0.1" is returned.
// If there are versions, but no tag matches the Semantic Versioning regex, a timestamp is returned, for example:
// '2020-04-10_18-58-42_378'
export const getTag = (versions: Version[]) => {
  if (!versions) return undefined;

  // Check 1: No versions yet? Return "0.0.1"
  if (versions && versions.length === 0) {
    return '0.0.1';
  }

  // Check 2: Is there a Semantic Versioning Tag yet?
  const latestMatchingSemVerTag = versions
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .filter((version) => semVerRegex.test(version.number))[0]?.number;
  if (latestMatchingSemVerTag) {
    const [major, minor, patch] = latestMatchingSemVerTag.split('.');
    const incrementedPatch = parseInt(patch) + 1;
    return `${major}.${minor}.${incrementedPatch}`;
  }

  return '0.0.1';
};
