import { ToBeRefined } from 'common/dist/types/todo_type';
import {
  AltaSigmaCell,
  Notebook,
} from '../../../../store/workbench/state.types';

/**
 * Updates the properties of a cell for a given notebook ("path") and cell ("cellId")
 * TODO Using cells.map ... in the implementation is absolutely inefficient. This reducer is called whenever code
 *   changes in a notebook - so it should be super efficient instead of this.
 *
 * @param state current state
 * @param path path of the notebook (= identifier)
 * @param cellId ID of the cell to update the fields for
 * @param updatedFields the new/updated fields (will be merged into the cell)
 */
export function updateNotebookCells(
  state: ToBeRefined,
  path: string,
  cellId: string,
  updatedFields: object,
  markAllCellsAsNotExecuting = false
): ToBeRefined {
  const notebook = selectNotebook(state, path);

  let updatedCells = ((notebook.content || {}).cells || []).map((c) =>
    c.id === cellId
      ? {
          ...c,
          ...updatedFields,
        }
      : c
  );

  if (markAllCellsAsNotExecuting) {
    updatedCells = updatedCells.map((c) => ({ ...c, executing: false }));
  }

  return {
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...notebook,
        content: {
          ...notebook.content,
          cells: updatedCells,
        },
      },
    },
  };
}

/**
 * Updates the metadata for a given notebook
 * @param state
 * @param path
 * @param updatedFields
 */
export function updateNotebookMetadata(
  state: ToBeRefined,
  path: string,
  updatedFields: object
): ToBeRefined {
  const notebook: Notebook = state.notebooks[path] || {};
  return {
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...notebook,
        content: {
          ...notebook.content,
          metadata: {
            ...notebook.content.metadata,
            ...updatedFields,
          },
        },
      },
    },
  };
}

/**
 * Select the cell for a given notebook and cellId from the state
 * @param state
 * @param path
 * @param cellId
 */
export function selectCell(
  state: ToBeRefined,
  path: string,
  cellId: string
): AltaSigmaCell {
  const notebook = selectNotebook(state, path);
  if (!notebook) return;
  return notebook.content.cells.find((c) => c.id === cellId);
}

/**
 * Select the notebook for a given path
 * @param state
 * @param path
 */
export function selectNotebook(state: ToBeRefined, path: string): Notebook {
  return state.notebooks[path] || {};
}
