import { connect } from 'react-redux';
import AdminAddGroup from './AdminAddGroup';
import { reduxForm, getFormValues } from 'redux-form';
import { form } from './addGroup.form';
import { adminAddGroup } from '../../../../redux/modules/admin.users.module';

export function mapStateToProps(state) {
  const formName = form.form;
  return {
    values: getFormValues(formName)(state),
    filledCompletely:
      state.form[formName] &&
      (!state.form[formName].syncErrors ||
        Object.values(state.form[formName].syncErrors).every(
          (fieldError) => !fieldError
        )),
  };
}

export const mapDispatchToProps = {
  adminAddGroup,
};

export default reduxForm(form)(
  connect(mapStateToProps, mapDispatchToProps)(AdminAddGroup)
);
