import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/store';
import OverlayDatapoolDetails from './OverlayDatapoolDetails';
import { triggerDatapoolStatisticsJob } from '../../../../redux/modules/triggerJobs.module';
import { setActiveUploadWizard } from '../../../../redux/modules/data.cassandra.module';

export function mapStateToProps(state: RootState) {
  const { loaded, loading, datapool, habitatName } = state.datapoolDetails;

  // Will be fetched by parent container DatapoolDetails
  const { data } = state.data.dataSources;
  const dataSource = data.find((ds) => ds.code === datapool.dataSourceCode);

  return {
    loaded,
    loading,
    datapool,
    habitatName,
    dataSource,
  };
}

export const mapDispatchToProps = {
  triggerDatapoolStatisticsJob,
  setActiveUploadWizard,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(OverlayDatapoolDetails);
