import { connect } from 'react-redux';
import MultiPageWizard, { ContainerProps, Props } from './MultiPageWizard';
import {
  getFormAsyncErrors,
  getFormSyncErrors,
  InjectedFormProps,
} from 'redux-form';
import { RootState } from '../../../store/store';
import { sendNotification } from '../../../redux/modules/notifications.module';

export function mapStateToProps<FormValues, ErrorType>(
  state: RootState,
  {
    form,
  }: Props<FormValues, ErrorType> &
    InjectedFormProps<FormValues, Props<FormValues, ErrorType>, ErrorType>
) {
  const props: ContainerProps<FormValues, ErrorType> = {
    // @ts-ignore TODO how can the typing be fixed?
    mpwSyncErrors: getFormSyncErrors(form)(state),
    // @ts-ignore TODO how can the typing be fixed?
    mpwAsyncErrors: getFormAsyncErrors(form)(state),
    // @ts-ignore TODO how can the typing be fixed?
    // mpwSubmitErrors: getFormSubmitErrors(form)(state),
  };
  return props;
}

export const mapDispatchToProps = {
  sendNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiPageWizard);
