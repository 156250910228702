// @type {{error: {status: string, message: string}}
export const initial = {
  loading: false,
  loaded: true,
  error: {},
  output: {},
};

export const test = {
  loading: false,
  loaded: false,
  error: {},
  output: {},
};
