import DatapoolDetails from '../../../common/_interfaces/datapool-details/DatapoolDetails';
import { DatapoolDetailsTab } from '../../../common/_interfaces/datapool-details/DatapoolDetailsTab';
import ArchetypesWrapper from '../../../common/datapool-details-tabs/archetypes/Archetypes.wrapper';
import TablesWrapper from '../../../common/datapool-details-tabs/cassandraTables/Tables.wrapper';
import SettingsWrapper from '../../../common/datapool-details-tabs/settings/Settings.wrapper';
import DatapoolSettingsCategory from '../../../common/_interfaces/datapool-details/settings/DatapoolSettingsCategory';
import NamesAndCodesCategory from '../../../common/datapool-details-tabs/settings/datapoolSettingsCategories/namesAndCodes/NamesAndCodesCategory';
import OptChannelsWrapper from '../../../common/datapool-details-tabs/optChannels/OptChannels.wrapper';
import OptPropensitiesWrapper from '../../../common/datapool-details-tabs/optPropensities/OptPropensities.wrapper';
import OptCommunicationsWrapper from '../../../common/datapool-details-tabs/optCommunications/OptCommunications.wrapper';
import OptCommunicationGroupsWrapper from '../../../common/datapool-details-tabs/optCommunicationGroups/OptCommunicationGroups.wrapper';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';
import { DatapoolType } from 'common/dist/types/datapool';

/**
 * Datapool Details for the binary classification (event-based) augur
 */
export default class BcDatapoolDetails extends DatapoolDetails {
  // @ts-ignore
  tabs: (
    datapool: DatapoolType,
    dataSource: DataSource
  ) => DatapoolDetailsTab[] = (datapool, dataSource) => {
    const tabs = [
      {
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Optimization' },
      },
      new OptChannelsWrapper(),
      new OptPropensitiesWrapper(),
      new OptCommunicationsWrapper(),
      new OptCommunicationGroupsWrapper(),

      { type: 'hr' },
      {
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Tables & Archetypes' },
      },
      // --- Tables
      new TablesWrapper(),
      // --- Archetypes
      new ArchetypesWrapper(),

      { type: 'hr' },
      {
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Settings' },
      },
    ];

    // --- Settings
    // Specification of the sub-components of the Settings tab
    const settingsCategories: DatapoolSettingsCategory[] = [
      new NamesAndCodesCategory(),
    ];
    tabs.push(new SettingsWrapper(settingsCategories));

    return tabs;
  };
}
