import React, { Component, Fragment } from 'react';
import { MergeRequest } from '../../../../giteaTypes';
import { FiAlertTriangle, FiCheckCircle } from 'react-icons/fi';
import { Link, withRouter } from 'react-router-dom';
// @ts-ignore
import { collaborationSpaceRoutes } from '../../../../routes';
import _ from 'lodash';
import CommitModal from './CommitModal.container';
import { RouteComponentProps } from 'react-router';
import Button from '../../../../../atoms/button/Button';

export type Conflict = {
  /** */
  filename: string;
  /** */
  renamed: boolean;
  /** */
  deleted: boolean;
  /** */
  renamed_from: string;
  /** */
  branch: string;
};

export type Props = {
  /** ID of the Merge Request */
  id: number;
  /** Name of the repository */
  repositoryName: string;
  /** Group of the repository */
  group: string;

  /** Is the MergeRequest information from Gitea loading? */
  mrLoading: boolean;
  /** Is the MergeRequest information from Gitea loaded? */
  mrLoaded: boolean;
  /** Possible error while loading the MergeRequest information from Gitea */
  mrError?: string;
  /** MergeRequest information from Gitea */
  mrData?: MergeRequest;

  /** Is the MergeRequest information from the Merger API loading? */
  mergerMrLoading: boolean;
  /** Is the MergeRequest information from the Merger API loaded? */
  mergerMrLoaded: boolean;
  /** Possible error while loading the MergeRequest information from the Merger API */
  mergerMrError?: string;
  /** MergeRequest information from the Merger API */
  mergerMrData?: {
    /** ID of the MergeRequest in the Merger API (this ID is different than the ID from Gitea!)*/
    id: number;
  };

  /** Is the MergeRequest status from the Merger API loading? */
  statusLoading: boolean;
  /** Is the MergeRequest status from the Merger API loaded? */
  statusLoaded: boolean;
  /** Possible error while loading the MergeRequest status from the Merger API */
  statusError?: string;
  /** MergeRequest status from the Merger API */
  statusData?: {
    /** ID of the MergeRequest in the Merger API (this ID is different than the ID from Gitea!)*/
    conflicted_files?: Conflict[];
    resolved_files: { filename: string }[];
    /**
     * success: no conflicts for this merge request
     * failure: conflicts couldn't be derived
     * pending: the conflicts are still calculated
     * conflict: there are still conflicts for this MR
     * outdated: new commits on the remote target branch
     * committed: the resolves have been committed (and pushed!)
     * */
    status:
      | 'success'
      | 'failure'
      | 'pending'
      | 'outdated'
      | 'committed'
      | 'conflict';
  };

  /** Create a Merge Request session in the Merger API. If a merge request for the branches already exists, it will be returned instead */
  mergerCreateMergeRequest: (
    mergeRequestId: number,
    repository: string,
    group: string,
    sourceBranch: string,
    targetBranch: string
  ) => void;
  /** Show or hide the commit modal */
  toggleCommitModal: (isShown: boolean) => void;
};

class MergeRequestResolve extends Component<Props & RouteComponentProps> {
  componentDidMount() {
    const { mergerCreateMergeRequest, id, repositoryName, group, mrData } =
      this.props;
    // Create the merge request in the Merger API.
    //   If the merge request already exists in the Merger API, the Merger will simply return it instead of creating
    //   it once more
    // Pick out the branch names from the Merge Request details
    const sourceBranchName = ((mrData || {}).head || {}).ref;
    const targetBranchName = ((mrData || {}).base || {}).ref;
    if (sourceBranchName && targetBranchName) {
      mergerCreateMergeRequest(
        id,
        repositoryName,
        group,
        sourceBranchName,
        targetBranchName
      );
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    const { mergerCreateMergeRequest, id, repositoryName, group, mrData } =
      this.props;
    // Create the merge request in the Merger API.
    //   If the merge request already exists in the Merger API, the Merger will simply return it instead of creating
    //   it once more
    // Pick out the branch names from the Merge Request details
    const sourceBranchName = ((mrData || {}).head || {}).ref;
    const targetBranchName = ((mrData || {}).base || {}).ref;
    const prevSourceBranchName = ((prevProps.mrData || {}).head || {}).ref;
    const prevTargetBranchName = ((prevProps.mrData || {}).base || {}).ref;

    if (
      sourceBranchName &&
      targetBranchName &&
      // Only trigger if the values actually changed
      (prevSourceBranchName !== sourceBranchName ||
        prevTargetBranchName !== targetBranchName)
    ) {
      mergerCreateMergeRequest(
        id,
        repositoryName,
        group,
        sourceBranchName,
        targetBranchName
      );
    }
  }

  renderConflict() {
    const {
      id,
      group,
      repositoryName,
      statusData,
      mergerMrData,
      toggleCommitModal,
    } = this.props;

    const conflictingFiles = ((statusData || {}).conflicted_files || [])
      .filter((cf) => !cf.renamed && !cf.deleted)
      .map((conflictedFile) => conflictedFile.filename);
    const renamedOrDeletedFiles =
      (statusData || {}).conflicted_files ||
      [].filter((cf) => cf.renamed || cf.deleted);

    return (
      <Fragment>
        {conflictingFiles.length > 0 && (
          <div className={'conflicted-files-parent'}>
            <div className={'conflicted-files-headline'}>Conflicting Files</div>
            <div className={'conflicted-files-list'}>
              {_.uniq(conflictingFiles)
                .sort()
                .map((filename) => (
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={`${
                      collaborationSpaceRoutes.basePath
                    }/${group}/${repositoryName}/${
                      collaborationSpaceRoutes.repositories.mergeRequest
                    }/${id}/${
                      collaborationSpaceRoutes.repositories.resolve
                    }?filename=${filename}&merge_id=${(mergerMrData || {}).id}`}
                  >
                    <div className={'conflicted-file'}>
                      <div className={'conflicted-file-icon-parent'}>
                        <FiAlertTriangle size={20} />
                      </div>
                      <div className={'conflicted-file-description-parent'}>
                        <span>{filename}</span>
                      </div>
                    </div>
                  </Link>
                ))}
              {((statusData || {}).conflicted_files || []).length === 0 && (
                <div className={'no-conflicted-files'}>
                  There are no Conflicted Files anymore.
                  <br /> Commit the Resolved Files and your Merge Request is
                  ready to be merged.
                </div>
              )}
            </div>
          </div>
        )}

        {renamedOrDeletedFiles.length > 0 && (
          <div className={'conflicted-files-parent renamed-files-parent'}>
            <div className={'conflicted-files-headline'}>
              Renamed/Deleted Files
            </div>
            <div className={'conflicted-files-list'}>
              {JSON.stringify(renamedOrDeletedFiles)}
            </div>
          </div>
        )}

        {conflictingFiles.length + renamedOrDeletedFiles.length > 0 && <hr />}

        <div className={'conflicted-files-parent resolved-files-parent'}>
          <div className={'conflicted-files-headline'}>Resolved Files</div>
          <div className={'conflicted-files-list'}>
            {((statusData || {}).resolved_files || []).map((resolvedFile) => (
              <div className={'conflicted-file'}>
                <div className={'conflicted-file-icon-parent'}>
                  <FiCheckCircle size={20} />
                </div>
                <div className={'conflicted-file-description-parent'}>
                  <span>{resolvedFile.filename}</span>
                </div>
              </div>
            ))}
            {((statusData || {}).resolved_files || []).length === 0 && (
              <div className={'no-conflicted-files'}>
                There are no Resolved Files
              </div>
            )}
            {((statusData || {}).resolved_files || []).length > 0 && (
              <div className={'resolved-files-button-bar'}>
                <Button
                  buttonColor={'primary'}
                  withLink={false}
                  buttonLabelDefault={'Commit Resolved Files'}
                  onClick={() => toggleCommitModal(true)}
                />
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  /**
   * Render the Conflicts screen with respect to the status coming from the Merger API.
   * See Props for documentation of the status codes.
   */
  renderWrtStatus() {
    const { statusData } = this.props;
    switch (statusData?.status) {
      case 'success':
        return <div>TODO</div>;
      case 'failure':
        return <div>TODO</div>;
      case 'conflict':
        return this.renderConflict();
      case 'committed':
        return <div>TODO</div>;
      case 'outdated':
        return <div>TODO</div>;
      case 'pending':
        return <div>TODO</div>;
      default: {
        // This case only happens when statusData isn't loaded yet.
        return <div />;
      }
    }
  }

  render() {
    const { statusData, mergerMrData } = this.props;
    return (
      <Fragment>
        <div className={'merge-request-tab merge-request-resolve'}>
          <div
            style={{
              color: 'deeppink',
              marginBottom: '10px',
            }}
          >
            <span>Status: {statusData?.status}</span>
          </div>
          {this.renderWrtStatus()}
        </div>
        {/* @ts-ignore */}
        <CommitModal mergeId={mergerMrData?.id} />
      </Fragment>
    );
  }
}

// @ts-ignore
export default withRouter(MergeRequestResolve);
