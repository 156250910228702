import _ from 'lodash';
import { PredictionReportType } from 'common/dist/types/reports';
import { downloadContent } from '../../../../../../organisms/workbench-browser/context-menu/download';


export function deriveChartDataForHeatmap(data: PredictionReportType[]) {
  const CODE = 'code';

  // Create the chart data
  const lookup = {}; // Used to lookup the values for the y-axis labels later


  const chartData = data.map((report) => {
    lookup[report.code] = {
      createdAt: report.createdAt,
      inputTable: report.data.inputTable,
      outputTable: report.data.outputTable,
      modelCode: report.modelCode,
    };
    return {
      id: report.code,
      data: report.data.predictedValuesDistribution.data.map((arr) => ({
        x: arr[0],
        y: arr[1],
      }))
    }
  });

  const keys = _.uniq(
    _.flatMap(
      chartData.map((cd) => Object.keys(cd).filter((x) => ![CODE].includes(x)))
    )
  );

  // Make sure that each key will hit a value != undefined in the chartData
  keys.forEach((key) => {
    chartData.forEach((cd) => {
      if (cd[key] === undefined) {
        cd[key] = 0;
      }
    });
  });

  return { lookup, chartData, keys };
}

export function deriveChartDataForBarchart(data: PredictionReportType[]): {
  chartData: object[];
  lookup: Record<string, { inputTable: string }>;
  keys: string[];
} {
  // Create the chart data
  const lookup = {}; // Used to lookup the values for the y-axis labels later
  const chartDataMap: Record<string, object> = {};
  data.forEach((report) => {
    report.data.predictedValuesDistribution.data.forEach((arr) => {
      const interval = arr[0].toString();
      const value = arr[1];

      if (!Object.keys(chartDataMap).includes(interval)) {
        chartDataMap[interval] = {
          interval,
        };
      }
      chartDataMap[interval][report.code] = value;
    });

    lookup[report.code] = {
      createdAt: report.createdAt,
      inputTable: report.data.inputTable,
      outputTable: report.data.outputTable,
      modelCode: report.modelCode,
    };
  });
  const chartData = Object.values(chartDataMap);
  const keys = data.map((report) => report.code);

  return { lookup, chartData, keys };
}

export function downloadDistribution(
  reports: PredictionReportType[],
  augurName: string
) {
  const COL_SEPERATOR = ';';

  // Early exit: Should never happen.
  if (!reports) return;

  // 1. --- Derive the headlines
  const headline = {
    runDate: ['Run Date:'],
    inputTable: ['Input Table:'],
  };

  reports.forEach((report) => {
    headline.runDate.push(report.createdAt.toString() || '');
    headline.inputTable.push(report.data.inputTable || '');
  });

  // 2. --- Derive the values
  const allIntervals = _.uniq(
    reports.flatMap((report) =>
      report.data.predictedValuesDistribution.data.map((arr) => arr[0])
    )
  );
  allIntervals.sort();

  const values = [allIntervals];
  reports.forEach((report) => {
    const singleValues = {};

    allIntervals.forEach((interval) => {
      singleValues[interval] = '';
    });

    report.data.predictedValuesDistribution.data.forEach((arr) => {
      const interval = arr[0].toString();
      const value = arr[1];

      singleValues[interval] = value;
    });

    const orderedValues = allIntervals.map(
      (interval) => singleValues[interval]
    );
    values.push(orderedValues);
  });

  // eslint-disable-next-line prefer-spread
  const transposedValues = _.zip.apply(_, values);

  const csvContent = [
    headline.runDate.join(COL_SEPERATOR),
    headline.inputTable.join(COL_SEPERATOR),
    ...transposedValues.map((arr) => arr.join(COL_SEPERATOR)),
  ].join('\n');

  const augurNameClean = augurName.replaceAll(' ', '_').toLowerCase();
  const fileName = `score_distribution_${augurNameClean}.csv`;

  downloadContent(fileName, csvContent, 'text/csv');
}
