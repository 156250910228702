import { connect } from 'react-redux';
import { getFormValues, isValid, reduxForm } from 'redux-form';
import { form, formName } from './EnableMaintenance.form';
import { DeprecatedRootState } from '../../../../store/state.type';
import EnableMaintenance, { Props } from './EnableMaintenance';
import { enableMaintenance } from '../../../../store/admin/maintenance/actions';
import { ToBeRefined } from 'common/dist/types/todo_type';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    isValid: isValid(formName)(state),
    submitting: state.adminBackups.createBackup.submitting,
    formValues: getFormValues(formName)(state) || {},
  };
}

export const mapDispatchToProps = {
  enableMaintenance,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm<ToBeRefined, Props>(form)(EnableMaintenance));
