import { ContentElementType } from '../generic-file-browser/GenericFileBrowser';
import {
  Copy,
  CopyName,
  CopyPath,
  Delete,
  Download,
  Edit,
  EditDirectory,
  OpenNotebook,
  OpenDir,
} from '../context-menu/ContextMenu.defaults';
import {
  FiArrowUp,
  FiCode,
  FiEdit,
  FiFile,
  FiFileText,
  FiFolder,
  FiGitBranch,
  IoLogoMarkdown,
} from 'react-icons/all';
import { openFile } from '../../../workbench/utils';
import { Dispatch } from 'redux-act';
import { DeprecatedRootState } from '../../../../store/state.type';
import {
  oneDirectoryDown,
  oneDirectoryUp,
  renameContent,
} from '../../../../redux/workbench/modules/content.module';
import {
  AddDirectory,
  AddFile,
  AddNotebook,
  Paste,
  Reload,
  Settings,
  Upload,
} from '../icon-button-bar/IconButtonBar.defaults';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import { ContextMenuEntry } from '../context-menu/ContextMenu';

export const buttonBarButtons = [
  AddNotebook,
  AddFile,
  AddDirectory,
  Paste,
  Upload,
  Reload,
  Settings,
];

export const onClickListeners = {
  [ContentElementType.FILE_CODE]: openFile,
  [ContentElementType.FILE_DEFAULT]: undefined,
  [ContentElementType.FILE_MARKDOWN]: openFile,
  [ContentElementType.FILE_NOTEBOOK]: openFile,
  [ContentElementType.FILE_REPOSITORY_INFO]: (
    state,
    dispatch,
    path,
    name,
    type,
    history,
    element
  ) =>
    history.push(
      `${workbenchRoutes.basePath}${workbenchRoutes.repositoryInfo}?path=${element.path}`
    ),
  [ContentElementType.FILE_TEXT]: openFile,
  [ContentElementType.DIRECTORY_PLAIN]: (
    state,
    dispatch,
    path,
    name,
    type,
    history,
    element
  ) => dispatch(oneDirectoryDown(element.name)),
  [ContentElementType.DIRECTORY_REPOSITORY]: (
    state,
    dispatch,
    path,
    name,
    type,
    history,
    element
  ) => dispatch(oneDirectoryDown(element.name)),
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: (
    state,
    dispatch,
    path,
    name,
    type,
    history,
    element
  ) => dispatch(oneDirectoryUp()),
  [ContentElementType.SPECIAL_LAUNCHER]: undefined,
};

export const contextMenuEntries: {
  [type in ContentElementType]: ContextMenuEntry[];
} = {
  [ContentElementType.FILE_CODE]: [CopyName, CopyPath, Download, Delete],
  [ContentElementType.FILE_DEFAULT]: [CopyName, CopyPath, Download, Delete],
  [ContentElementType.FILE_MARKDOWN]: [CopyName, CopyPath, Download, Delete],
  [ContentElementType.FILE_NOTEBOOK]: [
    CopyName,
    CopyPath,
    OpenNotebook,
    Copy,
    Edit,
    Download,
    Delete,
  ],
  [ContentElementType.FILE_REPOSITORY_INFO]: [],
  [ContentElementType.FILE_TEXT]: [CopyName, CopyPath, Download, Delete],
  [ContentElementType.DIRECTORY_PLAIN]: [OpenDir, Delete, EditDirectory],
  [ContentElementType.DIRECTORY_REPOSITORY]: [OpenDir, Delete],
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: [],
  [ContentElementType.SPECIAL_LAUNCHER]: [],
};

export const icons = {
  [ContentElementType.FILE_CODE]: FiCode,
  [ContentElementType.FILE_DEFAULT]: FiFile,
  [ContentElementType.FILE_MARKDOWN]: IoLogoMarkdown,
  [ContentElementType.FILE_NOTEBOOK]: FiEdit,
  [ContentElementType.FILE_REPOSITORY_INFO]: FiGitBranch,
  [ContentElementType.FILE_TEXT]: FiFileText,
  [ContentElementType.DIRECTORY_PLAIN]: FiFolder,
  [ContentElementType.DIRECTORY_REPOSITORY]: FiGitBranch,
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: FiArrowUp,
  [ContentElementType.SPECIAL_LAUNCHER]: undefined,
};

const directoryDrop =
  (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    path: string,
    name: string
  ) =>
  (e) => {
    const dataRaw = e.dataTransfer.getData('text/plain');
    const data = JSON.parse(dataRaw);
    if (data.type === 'notebookmove') {
      const oldPath = data.path;
      const newPath = `${path}/${data.name}`;
      // @ts-ignore: Type actions/reducers first
      dispatch(renameContent(oldPath, newPath));
    }
  };

export const dnd = {
  [ContentElementType.FILE_CODE]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_DEFAULT]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_MARKDOWN]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_NOTEBOOK]: {
    isDraggable: true,
    onDragStart:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string
      ) =>
      (e) => {
        const data = {
          type: 'notebookmove',
          name,
          path,
        };
        e.dataTransfer.setData('text/plain', JSON.stringify(data));
      },
  },
  [ContentElementType.FILE_REPOSITORY_INFO]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_TEXT]: {
    isDraggable: false,
  },
  [ContentElementType.DIRECTORY_PLAIN]: {
    isDraggable: false,
    onDrop: directoryDrop,
    onDragEnter:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) =>
      (e) => {
        setHighlighted(true);
      },
    onDragLeave:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) =>
      (e) => {
        setHighlighted(false);
      },
    onDragOver:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string
      ) =>
      (e) => {
        e.preventDefault();
      },
  },
  [ContentElementType.DIRECTORY_REPOSITORY]: {
    isDraggable: false,
    onDrop: directoryDrop,
    onDragEnter:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) =>
      (e) => {
        setHighlighted(true);
      },
    onDragLeave:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) =>
      (e) => {
        setHighlighted(false);
      },
    onDragOver:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string
      ) =>
      (e) => {
        e.preventDefault();
      },
  },
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: {
    isDraggable: false,
    onDrop:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string
      ) =>
      (e) => {
        const selectedDirPath = state.workbench.content.selectedDirPath;
        const dataRaw = e.dataTransfer.getData('text/plain');
        const data = JSON.parse(dataRaw);
        console.log(data, dataRaw, selectedDirPath);
        if (data.type === 'notebookmove') {
          const oldPath = data.path;
          let dir = selectedDirPath
            .slice(1, selectedDirPath.length - 1)
            .join('/');
          if (dir !== '') dir += '/';
          const newPath = dir + data.name;
          // @ts-ignore: Type actions/reducers first
          dispatch(renameContent(oldPath, newPath));
        }
      },
    onDragEnter:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) =>
      (e) => {
        setHighlighted(true);
      },
    onDragLeave:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string,
        setHighlighted: (val: boolean) => void
      ) =>
      (e) => {
        setHighlighted(false);
      },
    onDragOver:
      (
        state: DeprecatedRootState,
        dispatch: Dispatch,
        path: string,
        name: string
      ) =>
      (e) => {
        e.preventDefault();
      },
  },
  [ContentElementType.SPECIAL_LAUNCHER]: {
    isDraggable: false,
  },
};
