import React, { FC } from 'react';
import Busy from '../../../../../../../components/atoms/busy/Busy';
import { FormattedMessage } from 'react-intl';
import PipelineTuningResultsBestModel from '../../../../../../organisms/pipeline-tuning/pipeline-tuning-results-best-model/PipelineTuningResultsBestModel';
import './styles.scss';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import { FiLayers, FiStar } from 'react-icons/fi';
import TabLineBubbles from '../../../../../../molecules/tab-line-bubbles/TabLineBubbles';
import PipelineTuningResultsSingleResults from '../../../../../../organisms/pipeline-tuning/pipeline-tuning-results-single-results/PipelineTuningResultsSingleResults';
import { AugurDetailsQueryParams, AugurDetailsRouteParams } from '../../types';
import { JobDescriptionBanner } from '../../job-banner/JobDescriptionBanner';
import { ModelTuningResultsWait } from '../ModelTuningResults';
import qs from 'qs';
import { useModel } from '../../../../../../../core/api/mlModels';
import _ from 'lodash';
import { useAugur } from '../../../../../../../core/api/augurs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/store';
import { ArchetypeVersionWithArchetypeType } from 'common/dist/types/archetypeVersion';
import { UseQueryResult } from 'react-query';
import { NbcModel } from 'common/dist/types/mlModel';

const PipelineTuningResults: FC = () => {
  const { habitatCode, augurCode, moduleType } =
    useParams<AugurDetailsRouteParams>();
  const location = useLocation();
  const { modelCode } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as AugurDetailsQueryParams;

  const { data: augur } = useAugur(habitatCode, augurCode);

  const archetypeCode = augur?.archetypeVersion?.archetype?.code ?? '';
  const archetypeVersions = useSelector<
    RootState,
    ArchetypeVersionWithArchetypeType[]
  >((state) => state.archetypeVersions);

  const {
    data: model,
    error,
    isLoading,
    isSuccess,
    isError,
  } = useModel(habitatCode, augurCode, modelCode) as UseQueryResult<NbcModel>;

  const {
    loading: loadingSchema,
    loaded: loadedSchema,
    error: errorSchema,
  } = archetypeVersions[archetypeCode] || {};

  const renderLoaded = () => {
    const parts = location.pathname.split('/');
    const tabFromRoute = parts[8];
    const activeTab = tabFromRoute || 'best-model'; // Default tab is the "best-model" tab. Also ensured by the <Redirect />

    const routeBestModel = `/app/habitat/${habitatCode}/augur/${augurCode}/${moduleType}/model-tuning-results/best-model`;
    const routeSingleResults = `/app/habitat/${habitatCode}/augur/${augurCode}/${moduleType}/model-tuning-results/single-results`;

    const buttons = [
      {
        id: 'best-model',
        to: routeBestModel,
        intlId: 'no-id',
        intlDefault: 'Best Model',
        icon: () => <FiStar size={16} />,
      },
      {
        id: 'single-results',
        to: routeSingleResults,
        intlId: 'no-id',
        intlDefault: 'Single Results',
        icon: () => <FiLayers size={16} />,
      },
    ];

    return (
      <div className={'PipelineTuningResults'}>
        <div className={'PipelineTuningResults--header'}>
          <TabLineBubbles
            buttons={buttons}
            activeButton={activeTab}
            preserveSearchParamsOnLink={true}
          />
        </div>
        <div className={'PipelineTuningResults--body'}>
          <Switch>
            {/* Best Model */}
            <Route path={routeBestModel}>
              <PipelineTuningResultsBestModel
                pipelineTuningResults={model.details.pipelineTuning}
              />
            </Route>

            {/* Single Results */}
            <Route path={routeSingleResults}>
              <PipelineTuningResultsSingleResults
                pipelineTuningResults={model.details.pipelineTuning}
              />
            </Route>

            {/* Default: Redirect to the best models */}
            <Redirect
              to={{ pathname: routeBestModel, search: location.search }}
            />
          </Switch>
        </div>
      </div>
    );
  };

  return (
    <JobDescriptionBanner>
      <Busy isBusy={isLoading && loadingSchema}>
        {(isError || errorSchema) && (
          <div className={'PipelineTuningResults'}>
            <FormattedMessage
              id='details.model_tuning.error'
              defaultMessage={`${
                error ? 'Error model tuning results' : 'Error schema'
              }: {error}`}
              values={error ? { error } : { error: errorSchema }}
            />
          </div>
        )}
        {isSuccess &&
          loadedSchema &&
          !_.isEmpty(model?.details?.pipelineTuning) &&
          renderLoaded()}
        {isSuccess &&
          loadedSchema &&
          _.isEmpty(model?.details?.pipelineTuning) && (
            <div className={'PipelineTuningResults'}>
              <ModelTuningResultsWait />
            </div>
          )}
      </Busy>
    </JobDescriptionBanner>
  );
};

export default PipelineTuningResults;
