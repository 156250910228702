import AugurSettingsElement from '../../../../../_interfaces/augur-details/settings/AugurSettingsElement';
import AttributesComponent from './AttributesComponent';

/**
 * Shows the augur name & code in the augur settings tab
 */
export default class Attributes implements AugurSettingsElement {
  component = AttributesComponent;
  title = 'dashboard.augur_settings.attributes_title';
  description = 'dashboard.augur_settings.attributes_description';
}
