import { ComponentDatapoolDetailsTab } from '../../_interfaces/datapool-details/DatapoolDetailsTab';
import TablesParent from './TablesParent.container';
import TablesIcon from './TablesIcon';
import { MessageDescriptor } from 'react-intl';
import { DatapoolType } from 'common/dist/types/datapool';

export default class TablesWrapper extends ComponentDatapoolDetailsTab {
  type: 'component' = 'component';
  id = 'tables';
  title: MessageDescriptor = {
    id: 'datapoolDetails.sideNav.tables',
    defaultMessage: 'Tables',
  };
  to = (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${shortDatapoolType}/tables`;

  icon = TablesIcon;
  component = TablesParent;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType): boolean => true;
}
