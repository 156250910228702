import React, { Component } from 'react';
import { TemplateMeta } from '../types';
import SortableSearchableListContainer from '../../../organisms/sortable-searchable-list/SortableSearchableList.container';
import TemplateElement from './TemplateElement.container';
import { Props as TemplateElementProps } from './TemplateElement';
import {
  TemplateMetaOrdering,
  TemplateMetaOrderings,
} from './TemplatesPage.container';
import Busy from '../../../atoms/busy/Busy';
// @ts-ignore
import { app as appRoutes } from '../../../index/routes';

type Props = {
  TemplatesMeta: {
    loading: boolean;
    loaded: boolean;
    error?: string;
    data: TemplateMeta[];
  };
  fetchTemplates: () => void;
};

export default class TemplatesPage extends Component<Props> {
  componentDidMount() {
    this.props.fetchTemplates();
  }

  renderLoading() {
    return (
      <div className={'templates-parent'}>
        <Busy isBusy />
      </div>
    );
  }

  renderError() {
    const {
      TemplatesMeta: { error },
    } = this.props;
    return (
      <div className={'templates-parent'}>
        <span className={'error'}>{JSON.stringify(error)}</span>
      </div>
    );
  }

  renderLoaded() {
    const { TemplatesMeta } = this.props;
    const List = SortableSearchableListContainer<
      TemplateMeta,
      TemplateMetaOrdering,
      TemplateElementProps
    >();
    return (
      <div className={'templates-parent'}>
        <List
          orderings={TemplateMetaOrderings}
          defaultOrdering={TemplateMetaOrdering.LastCreated}
          data={TemplatesMeta.data}
          contentProps={(template: TemplateMeta, i: number) => ({ template })}
          ContentComponent={TemplateElement}
          search={(bak: TemplateMeta, searchString: string) =>
            bak.name.includes(searchString)
          }
          sort={(baks: TemplateMeta[], ordering: TemplateMetaOrdering) => {
            switch (ordering) {
              case TemplateMetaOrdering.LastCreated:
                return baks.sort((a, b) =>
                  a.timestamp > b.timestamp ? -1 : 1
                );
              case TemplateMetaOrdering.OldestCreated:
                return baks.sort((a, b) =>
                  a.timestamp < b.timestamp ? -1 : 1
                );
              case TemplateMetaOrdering.NameDesc:
                return baks.sort((a, b) => (a.name > b.name ? -1 : 1));
              case TemplateMetaOrdering.NameAsc:
                return baks.sort((a, b) => (a.name < b.name ? -1 : 1));
            }
          }}
          title={'Templates'}
          button={{
            buttonText: 'Create Template',
            link:
              appRoutes.prefix +
              appRoutes.admin.index +
              appRoutes.admin.createTemplate,
          }}
        />
      </div>
    );
  }

  render() {
    const {
      TemplatesMeta: { error, loading },
    } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    return this.renderLoaded();
  }
}
