import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../_components/TextInput';
import { Field } from 'redux-form';
import { FiCheck } from 'react-icons/fi';
import { validateDirectoryName } from '../validation';
import SelectedDirs from '../../../../../molecules/selected-dirs/SelectedDirs';

export default class Step1 extends Component {
  validateNonInline = (value) =>
    validateDirectoryName(value, this.props.filesInCurrentDir);

  errorMessage() {
    if (this.props.directoryNameTouched && this.props.directoryNameError) {
      return <p className={'error'}>{this.props.directoryNameError}</p>;
    }
  }

  render() {
    return (
      <div className={'step-container step-1-container'}>
        <div className={'description-container'}>
          {validateDirectoryName(
            this.props.directoryName,
            this.props.filesInCurrentDir,
            this.props.isEditing,
            this.props.initialName
          ) ? (
            <div className={'bubble bubble-info'}>
              <p>1</p>
            </div>
          ) : (
            <div className={'bubble bubble-ok'}>
              <FiCheck className={'checked-icon'} size={'20px'} />
            </div>
          )}
          <div className={'description'}>
            <div className={'title'}>
              <p>Select Name</p>
            </div>
            <div className={'text'}>
              <p>
                Select a name for the directory. Browse on the left to change
                the path where the directory will be created.
              </p>
            </div>
          </div>
        </div>
        <div
          className={
            'input-container' +
            (this.props.directoryNameTouched &&
            validateDirectoryName(
              this.props.directoryName,
              this.props.filesInCurrentDir
            )
              ? ' input-container-error'
              : '')
          }
        >
          <div className={'error-container'}>{this.errorMessage()}</div>
          <div className={'name-input'}>
            <Field
              name='directoryName'
              label='directoryName'
              type='text'
              component={TextInput}
              validate={this.validateNonInline}
            />
            <SelectedDirs
              dirs={[
                ...(this.props.selectedDirPath || []),
                this.props.directoryName,
              ]}
              highlightLastEntry
            />
          </div>
        </div>
      </div>
    );
  }
}

Step1.propTypes = {
  selectedDirPath: PropTypes.array.isRequired,
  directoryName: PropTypes.string,
  directoryNameTouched: PropTypes.bool,
  directoryNameError: PropTypes.string,
  filesInCurrentDir: PropTypes.array.isRequired,
  /** Is the directory being edited: Needed for validation as in that case the name can be the same */
  isEditing: PropTypes.bool,
  /** The initial name of the directory: Needed for correct validation in the case of isEditing */
  initialName: PropTypes.string,
};
