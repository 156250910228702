import React, { Component } from 'react';
import styles from '../styles.module.scss';
import NameContainer from './NameContainer';
import ActionButtons from './ActionButtons';
import { CodeCapsule } from 'common/dist/types/codeCapsule';

type Props = {
  /** Are the code capsule details loading? */
  loading?: boolean;
  /** Are the code capsule details loaded? */
  loaded?: boolean;
  /** The code capsule details */
  data?: CodeCapsule;
  triggerRunCodeCapsuleJob: (
    ccName: string,
    repoCode: string,
    versionNumber: string,
    imageName: string,
    ccCode: string,
    availableNotebooks: string[]
  ) => void;
};

type State = {};

export default class OverlayCodeCapsuleDetails extends Component<Props, State> {
  render() {
    const { loading, loaded, data, triggerRunCodeCapsuleJob } = this.props;

    return (
      <div className={styles.subheaderLeft}>
        <div className={styles.detailsBar}>
          <NameContainer loading={loading} loaded={loaded} codeCapsule={data} />

          {/* <DetailValues
            loading={loading}
            loaded={loaded}
            codeCapsule={data}
          /> */}

          <ActionButtons
            loading={loading}
            loaded={loaded}
            codeCapsule={data}
            triggerRunCodeCapsuleJob={triggerRunCodeCapsuleJob}
          />
        </div>
      </div>
    );
  }
}
