import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { DeprecatedRootState } from '../../../../../../../../../store/state.type';
import RelearningCondition from '../../../../../../../../molecules/relearning-condition/RelearningCondition';
import {
  fieldLearningKpi,
  fieldLearningThreshold,
  formName,
} from '../../../../../../../../molecules/relearning-condition/form';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

export const selector = formValueSelector(formName);

export function mapStateToProps(
  state: DeprecatedRootState,
  ownProps: ModelSettingsProps<AugurSettingsDataNbc>
) {
  const {
    settings: { learningKpi, learningThreshold },
  } = ownProps;

  return {
    initialValues: {
      learningKpi,
      learningThreshold,
    },

    learningKpi: selector(state, fieldLearningKpi),
    learningThreshold: selector(state, fieldLearningThreshold),
  };
}

export default connect(mapStateToProps)(RelearningCondition);
