import React, { FC, useEffect, useState } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import ExpandCollapseIcon from '../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import styles from './styles.module.scss';
import Busy from '../../atoms/busy/Busy';
import { FormattedDuration } from '../../atoms/formatted-date-time/FormattedDateTime';
import { timeDiffAll } from 'common/dist/utils/time';
import { ProgressStep as TProgressStep } from 'common/dist/types/orchestration/progress';

export type Props = TProgressStep;

const ProgressStep: FC<Props> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [setNow]);
  return (
    <div className={styles.container}>
      <ExpandCollapseIcon
        isExpanded={expanded}
        onClick={(isExpanded) => setExpanded(isExpanded)}
      />
      <div>
        <div className={styles.topLine}>
          <div className={styles.statusIcon}>
            {props.started_at !== undefined && props.started_at !== null ? (
              props.completed_at !== undefined &&
              props.completed_at !== null ? (
                props.error !== undefined && props.error !== null ? (
                  <div className={styles.error} title='Error'>
                    <FiX />
                  </div>
                ) : (
                  <div className={styles.success} title='Success'>
                    <FiCheck />
                  </div>
                )
              ) : props.jobFinished ? (
                <div className={styles.unknown} title='Unknown'>
                  <span>!</span>
                </div>
              ) : (
                <div className={styles.tinyBusy} title='Running'>
                  <Busy isBusy={true} />
                </div>
              )
            ) : null}
          </div>
          <span className={styles.title}>{props.title}</span>
          {props.started_at && (!props.jobFinished || props.completed_at) && (
            <>
              <span className={styles.light}>&bull;</span>
              <span className={styles.grey}>
                <FormattedDuration
                  // @ts-ignore TODO why? They are the same import for FormatNumberOptions - Different versions in frontend and commons?
                  formatList={timeDiffAll(
                    props.completed_at ? new Date(props.completed_at) : now,
                    new Date(props.started_at)
                  )}
                />
              </span>
            </>
          )}
        </div>
        {expanded && (
          <div className={styles.body}>
            <p className={styles.description}>{props.description}</p>
            {props.error && <p className={styles.errorText}>{props.error}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressStep;
