import { connect, ConnectedProps } from 'react-redux';

import NotFound from './NotFound';
import { getLinkForFirstPermittedMainTab } from 'common/dist/utils/authorization.general';

export function mapStateToProps(state) {
  const permissions = state.dashboard?.permissions?.data || {};
  const mainTabsDefaultLink = getLinkForFirstPermittedMainTab(permissions);

  return {
    mainTabsDefaultLink,
  };
}

const connector = connect(mapStateToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(NotFound);
