import P3InputElement, { AsInputElement } from '../_interface/P3InputElement';
import { validateCommonSettings } from '../_interface/input-element.form';
import TextInput from './TextInput';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';
import vars from '../../../../../../../../../../../../scss/base/var.module.scss';

export default class TextInputWrapper extends P3InputElement {
  // --- Elements

  ChildComponent: React.ComponentType = TextInput;
  AdditionalSettings: any = undefined;
  name = 'Text Input';
  parentContainerClass = 'text-input';
  type = 'text-input';
  icon: any = DefaultElementIcon;
  iconColor = vars.colorPrimary;

  // --- Functions

  getSource: (asElement: AsInputElement) => string = (
    asElement: AsInputElement
  ) => {
    if (
      !asElement.settings ||
      !asElement.settings.outputVariable ||
      !asElement.data
    )
      return '';

    const text = asElement.data.text;
    const textEscaped = text
      ? text.replaceAll('\\', '\\\\').replaceAll('"', '\\"')
      : '';
    return `${asElement.settings.outputVariable.inputValue} = "${textEscaped}"`;
  };

  /**
   * No validation implemented - every input is valid.
   * @param asElement
   */
  validate: (asElement: AsInputElement, variables: object[]) => string = (
    asElement: AsInputElement,
    variables: object[]
  ) => undefined;

  /**
   * Function to validate the settings in the element modal (label, description, outputVariable, ...)
   * For the NumberInput no additional validation apart from the common settings validation is required
   */
  validateSettings: (values: object, variables: object[]) => object =
    validateCommonSettings;
}
