import qs from 'qs';

export interface AugurDetailsRouteParams {
  habitatCode: string;
  augurCode: string;
  moduleType: string;
}

export interface AugurDetailsQueryParams extends qs.ParsedQs {
  modelCode?: string;
}

/**
 * Keep some query parameters while on the augur details pages.
 * These both belong to the Model Changes / Augur Biography sidebar
 */
export const AugurDetailsWhitelistQuery = ['modelCode', 'sboffset'];
