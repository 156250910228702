import { connect } from 'react-redux';
import RepoContent from './RepoContent';
import { fetchRepoContent } from '../../../../../redux/workbench/modules/collab.repository.module';

export function mapStateToProps(state) {
  const { repoName, activeBranch, repoCode } =
    state.workbench.collab.repository;
  const { repoFullName } =
    (state.workbench.collab.repoDetails || {}).data || {};
  const { loading, loaded, data, error, selectedPath } =
    state.workbench.collab.repository.repoContent;
  return {
    loading,
    loaded,
    data,
    error,
    selectedPath,
    repoName,
    repoFullName,
    activeBranch,
    repoCode,
  };
}

export const mapDispatchToProps = {
  fetchRepoContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepoContent);
