import { createAction } from 'redux-act';
import { put, call, select, race, take, takeEvery } from 'redux-saga/effects';

import {
  fetchSummary,
  fetchSummarySuccess,
  fetchSummaryFail,
} from './dashboard.summary.module';

import { loaded as getSummaryLoaded } from '../selectors/dashboard.selector';
import { fetchComponentPermissionsSaga } from './dashboard.module';

export const init = createAction('initialize dashboard');
export const inited = createAction('initialize dashboard - finished');

export const reducer = {
  [inited]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      initialized: true,
    },
  }),
};

export function* fetchSummarySaga() {
  const isSummaryLoaded = yield select(getSummaryLoaded);
  if (isSummaryLoaded) return;

  yield put(fetchSummary());
  yield race({
    success: take(fetchSummarySuccess.getType()),
    fail: take(fetchSummaryFail.getType()),
  });
}

export function* initSaga(data) {
  // 1. Fetch the model management summary
  yield call(fetchSummarySaga);

  // 2. Fetch the component permissions
  yield call(fetchComponentPermissionsSaga);

  yield put(inited());
  if (data && data.payload && data.payload.callback) {
    data.payload.callback();
  }
}

export function* watchDashboardInit() {
  yield takeEvery(init.getType(), initSaga);
}
