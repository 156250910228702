import { connect } from 'react-redux';
import ModalDialogVariables from './ModalDialogVariables';
import { hideSelectVariable } from '../../../../../../../../../redux/workbench/modules/cells.app.module';

export function mapStateToProps(state) {
  return {
    selectVariable: state.workbench.selectVariable,
  };
}

export const mapDispatchToProps = {
  hideSelectVariable,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDialogVariables);
