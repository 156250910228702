import React, { Component } from 'react';
import './styles.scss';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import AccountAddPhoto from '../../../atoms/account-add-photo/AccountAddPhoto.container';
import UserDetails from './user-details/UserDetails.container';

export type Props = {};

class UserInfo extends Component<Props & WrappedComponentProps> {
  render() {
    return (
      <div className={'AccountInfo'}>
        <div className={'AccountInfo--title'}>
          <FormattedMessage id='account.info.title' defaultMessage='Info' />
        </div>

        <div className={'AccountInfo--content'}>
          <UserDetails />
          <AccountAddPhoto />
        </div>
      </div>
    );
  }
}

export default injectIntl(UserInfo);
