import React, { FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  ACCURACY_DETAILS_TYPE,
  AccuracyDetailsType,
} from 'common/dist/constants/accuracyCharts';
import { JobDescriptionBanner } from '../job-banner/JobDescriptionBanner';
import DetailsElement from './elements/DetailsElement';
import { getAugurDetailsLinkForShort } from '../../../../../index/routes';
import { AugurDetailsRouteParams } from '../types';

const AccuracyDetails: FC = () => {
  const location = useLocation();

  const { habitatCode, augurCode, moduleType, tabId } = useParams<
    AugurDetailsRouteParams & { augurType: string; tabId: AccuracyDetailsType }
  >();
  const detailsLink = getAugurDetailsLinkForShort({
    habitatCode,
    augurCode,
    moduleTypeShort: moduleType,
    tabId: 'accuracy',
  });

  const renderInnerComponent = () => {
    switch (tabId) {
      case ACCURACY_DETAILS_TYPE.MISCLASSIFICATION:
      case ACCURACY_DETAILS_TYPE.PREDICTED_VALUES_DISTRIBUTION:
      case ACCURACY_DETAILS_TYPE.LIFT:
      case ACCURACY_DETAILS_TYPE.CUMULATIVE_LIFT:
      case ACCURACY_DETAILS_TYPE.RESPONSE:
      case ACCURACY_DETAILS_TYPE.CUMULATIVE_RESPONSE:
      case ACCURACY_DETAILS_TYPE.CAPTURED_RESPONSE:
      case ACCURACY_DETAILS_TYPE.CUMULATIVE_CAPTURED_RESPONSE:
      case ACCURACY_DETAILS_TYPE.ROC:
        return <DetailsElement tabId={tabId} />;
      default:
        return (
          <div style={{ padding: '20px' }}>
            <span>Unknown Report ID: {tabId}</span>
          </div>
        );
    }
  };

  return (
    <JobDescriptionBanner>
      <div className='h100p'>
        <div className='page--bar'>
          <Link
            to={{ pathname: detailsLink, search: location.search }}
            className='page--back'
          >
            <span className='icon-chevron-left' />
            <FormattedMessage
              id='details.accuracy.back'
              defaultMessage='Back'
            />
          </Link>
          <div className='page--path'>
            <ul>
              <li>
                <Link to={{ pathname: detailsLink, search: location.search }}>
                  <FormattedMessage
                    id='details.accuracy.accuracy'
                    defaultMessage='Accuracy'
                  />
                </Link>
              </li>
              <li>
                <FormattedMessage id={`details.accuracy.${tabId}`} />
              </li>
            </ul>
          </div>
        </div>
        <div style={{ height: 700 }}>
          {renderInnerComponent()}
          {/* renderRoutes(route.routes) */}
        </div>
      </div>
    </JobDescriptionBanner>
  );
};

export default AccuracyDetails;
