import {
  AdminResourcesActions,
  FETCH_RUNNING_APPS,
  FETCH_RUNNING_APPS_FAIL,
  FETCH_RUNNING_APPS_SUCCESS,
  FETCH_RUNNING_WORKBENCHES,
  FETCH_RUNNING_WORKBENCHES_FAIL,
  FETCH_RUNNING_WORKBENCHES_SUCCESS,
  STOP_APP,
  STOP_APP_FAIL,
  STOP_APP_SUCCESS,
  STOP_WORKBENCH,
  STOP_WORKBENCH_FAIL,
  STOP_WORKBENCH_SUCCESS,
} from './actions.types';
import { DeprecatedRootState } from '../../state.type';
import { AdminResourcesState } from './state.types';

export function reducer(
  state: DeprecatedRootState,
  action: AdminResourcesActions
): AdminResourcesState {
  switch (action.type) {
    // --- WORKBENCH
    case FETCH_RUNNING_WORKBENCHES: {
      return {
        ...state.adminResources,
        runningWorkbench: {
          ...(state.adminResources.runningWorkbench || {
            loaded: false,
            stoppingServers: [],
          }),
          loading: true,
        },
      };
    }

    case FETCH_RUNNING_WORKBENCHES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state.adminResources,
        runningWorkbench: {
          ...(state.adminResources.runningWorkbench || { stoppingServers: [] }),
          loading: false,
          loaded: true,
          data,
          error: undefined,
        },
      };
    }

    case FETCH_RUNNING_WORKBENCHES_FAIL: {
      const { error } = action.payload;
      return {
        ...state.adminResources,
        runningWorkbench: {
          ...(state.adminResources.runningWorkbench || { stoppingServers: [] }),
          loading: false,
          loaded: false,
          error,
        },
      };
    }

    case STOP_WORKBENCH: {
      const { userCodeLower } = action.payload;
      return {
        ...state.adminResources,
        runningWorkbench: {
          ...(state.adminResources.runningWorkbench || {
            loading: false,
            loaded: false,
          }),
          stoppingServers: [
            ...state.adminResources.runningWorkbench.stoppingServers,
            userCodeLower,
          ],
        },
      };
    }

    case STOP_WORKBENCH_SUCCESS: // Fall-through intended
    case STOP_WORKBENCH_FAIL: {
      const { userCodeLower } = action.payload;
      return {
        ...state.adminResources,
        runningWorkbench: {
          ...(state.adminResources.runningWorkbench || {
            loading: false,
            loaded: false,
          }),
          stoppingServers:
            state.adminResources.runningWorkbench.stoppingServers.filter(
              (ucl) => ucl !== userCodeLower
            ),
        },
      };
    }

    // --- APPS
    case FETCH_RUNNING_APPS: {
      return {
        ...state.adminResources,
        runningApps: {
          ...(state.adminResources.runningApps || {
            loaded: false,
            stoppingApps: [],
          }),
          loading: true,
        },
      };
    }

    case FETCH_RUNNING_APPS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state.adminResources,
        runningApps: {
          ...(state.adminResources.runningApps || { stoppingApps: [] }),
          loading: false,
          loaded: true,
          data,
          error: undefined,
        },
      };
    }

    case FETCH_RUNNING_APPS_FAIL: {
      const { error } = action.payload;
      return {
        ...state.adminResources,
        runningApps: {
          ...(state.adminResources.runningApps || { stoppingApps: [] }),
          loading: false,
          loaded: false,
          error,
        },
      };
    }

    case STOP_APP: {
      const { userCodeLower, appVersionCodeLower } = action.payload;
      return {
        ...state.adminResources,
        runningApps: {
          ...(state.adminResources.runningApps || {
            loading: false,
            loaded: false,
          }),
          stoppingApps: [
            ...state.adminResources.runningApps.stoppingApps,
            { userCode: userCodeLower, appVersionCode: appVersionCodeLower },
          ],
        },
      };
    }

    case STOP_APP_SUCCESS: // Fall-through intended
    case STOP_APP_FAIL: {
      const { userCodeLower, appVersionCodeLower } = action.payload;
      return {
        ...state.adminResources,
        runningApps: {
          ...(state.adminResources.runningApps || {
            loading: false,
            loaded: false,
          }),
          stoppingApps: state.adminResources.runningApps.stoppingApps.filter(
            (ucl) =>
              ucl.userCode !== userCodeLower &&
              ucl.appVersionCode !== appVersionCodeLower
          ),
        },
      };
    }

    default: {
      return state.adminResources;
    }
  }
}

export { reducer as adminResourcesReducer };
