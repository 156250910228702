import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RepositoryHeader from './RepositoryHeader.container';
import LatestCommit from './LatestCommit.container';
import RepoContent from './repo-content/RepoContent.container';
import RepoType from '../../RepoType';
import RepositoryButtonBar from './RepositoryButtonBar.container';
import { withRouter } from 'react-router-dom';
import Label from '../../../atoms/label/Label';
import DropdownSelectInput from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

class Repository extends Component {
  componentDidMount() {
    const { fetchRepoDetails, fetchBranches } = this.props;
    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;
    fetchRepoDetails(group, repositoryName);
    fetchBranches(group, repositoryName);
  }

  renderContent() {
    const { loading, error } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else return this.renderLoaded();
  }

  renderLoading() {
    return <div className={'repository-info'}>Loading ...</div>;
  }

  renderError() {
    const { error } = this.props;
    return <div className={'repository-info'}>{JSON.stringify(error)}</div>;
  }

  renderLoaded() {
    const { activeBranch, data, branches, switchBranch, currentUserId } =
      this.props;
    const { name, repoDescription, repoType, repoFullName } = data || {};
    const parts = (repoFullName || '').split('/');
    const group = parts[0];
    const isOwner = group === currentUserId;
    const nameShown = name !== undefined ? name : parts[1];

    const options = (branches || []).map((branch) => ({
      label: branch.name,
      value: branch.name,
    }));

    return (
      <div className={'repository-info'}>
        <div className={'repository-top-line'}>
          <span className={'repository-name'}>{nameShown}</span>
          <RepoType repoType={repoType} />
        </div>
        {repoDescription ? (
          <span className={'repository-description'}>{repoDescription}</span>
        ) : (
          <span className={'repository-description'}>
            <i>No description available</i>
          </span>
        )}
        <span className={'repository-active-branch'}>
          Active Branch:
          <DropdownSelectInput
            id='git-branch-selector'
            name='active-branch'
            className={'git-branch-selector'}
            options={options}
            value={options.find((o) => o.value === (activeBranch || 'master'))}
            onChange={(option) => switchBranch(option.value)}
            openOnFocus
            clearable={false}
          />
        </span>
      </div>
    );
  }

  render() {
    return (
      <div className={'CollaborationSpace--content'}>
        <div className={'repository'}>
          <RepositoryHeader />

          <div className={'repository-top'}>
            {this.renderContent()}

            <RepositoryButtonBar />
          </div>

          <LatestCommit />
          <RepoContent />
        </div>
      </div>
    );
  }
}

Repository.propTypes = {
  activeBranch: PropTypes.string.isRequired,
  fetchRepoDetails: PropTypes.func.isRequired,
  fetchBranches: PropTypes.func.isRequired,
  switchBranch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      group: PropTypes.string,
      repositoryName: PropTypes.string,
    }).isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  branches: PropTypes.arrayOf(PropTypes.string),
  loaded: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.shape({
    /** Name of the repository (coming from Postgres, not from Gogs!) */
    name: PropTypes.string.isRequired,
    /** Description of the repository (coming from Postgres, not from Gogs!) */
    repoDescription: PropTypes.string.isRequired,
    /** Type of the repository */
    repoType: PropTypes.string,
  }),
  currentUserId: PropTypes.string.isRequired,
};

export default withRouter(Repository);
