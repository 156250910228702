import { VARIABLE_TYPES } from '../variableTypes';
import { BaseVariableHandler, getFirstRaw, numberRegex } from './helper';

export const NumberVariableHandler: BaseVariableHandler<number> = {
  type: VARIABLE_TYPES.NUMBER,
  displayName: 'Number',
  getExecutionCode: (inputValue: string) => inputValue,
  parseValue: (variable, rawValues) => {
    const firstRaw = getFirstRaw(variable, rawValues);
    if (!firstRaw) return { parsedValue: undefined, error: undefined };

    try {
      const firstRawNoQuotes = firstRaw.split("'").join('');

      // Regex check first
      if (!firstRawNoQuotes.match(numberRegex))
        return { parsedValue: undefined, error: "Couldn't parse Number" };
      const parsedValue = Number.parseFloat(firstRawNoQuotes); // Remove all ' characters

      // Try to parse
      if (!Number.isNaN(parsedValue)) {
        return { parsedValue, error: undefined };
      } else {
        return { parsedValue: undefined, error: "Couldn't parse Number" };
      }
    } catch (e) {
      return { parsedValue: undefined, error: "Couldn't parse Number" };
    }
  },
};
