import { connect } from 'react-redux';
import OptConstraints, { OwnProps } from './OptConstraints';
import { RootState } from '../../../../../store/store';
import { fetchDatapool } from '../../../../../redux/modules/datapool.details.module';

export function mapStateToProps(
  state: RootState,
  { habitatCode, datapoolCode }: OwnProps
) {
  return {
    datapoolDetailsLoading: state.datapoolDetails?.loading,
    datapoolDetails: state.datapoolDetails?.datapool,
  };
}

export const mapDispatchToProps = {
  fetchDatapool,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptConstraints);
