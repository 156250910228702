import { connect } from 'react-redux';
import Repositories from './Repositories';
import {
  deleteRepository,
  fetchRepositories,
  hideDeleteRepoConfirm,
} from '../../../../redux/workbench/modules/collab.module';
import { fetchUserSummaryById } from '../../../../store/users/slice';
import * as selector from '../../../../redux/selectors/user.selector';

export const mapStateToProps = (state) => ({
  deleteRepoConfirm: state.workbench.collab.deleteRepoConfirm,

  data: state.workbench.collab.repositories.data,
  loading: state.workbench.collab.repositories.loading,
  loaded: state.workbench.collab.repositories.loaded,
  error: state.workbench.collab.repositories.error,
  usersById: state.users.byId,
  currentUserId: selector.currentUser(state).id,
});

export const mapDispatchToProps = {
  hideDeleteRepoConfirm,
  deleteRepository,
  fetchRepositories,
  fetchUserSummaryById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Repositories);
