import { connect } from 'react-redux';
import RepositoryStepHabitat from './RepositoryStepHabitat';
import { fetchSummary } from '../../../../../redux/modules/dashboard.summary.module';

export function mapStateToProps(state) {
  return {
    summary: state.summary,
    summaryLoading: state.dashboard.loading,
    summaryLoaded: state.dashboard.loaded,
    summaryError: state.dashboard.error,
  };
}

export const mapDispatchToProps = {
  fetchSummary,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepositoryStepHabitat);
