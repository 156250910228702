import { connect } from 'react-redux';
import AddFileWizard from './AddFileWizard';

import { addFile } from '../../../../../redux/workbench/modules/file.add.module';
import { formName } from './file.form';
import { getFileName } from '../../../../../redux/workbench/selectors/addNewFile.selectors';

export function mapStateToProps(state) {
  return {
    fileName: getFileName(state),
    filledCompletely: !state.form[formName] || !state.form[formName].syncErrors,
  };
}

export const mapDispatchToProps = {
  addFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFileWizard);
