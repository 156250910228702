import { validate } from '../../../../collaborationSpace/wizards/repository-add/validation';
import { ConfigProps } from 'redux-form';
import { checkRepoNameExists } from '../../../../../redux/modules/repo.checkNameExists.module';

export const formName = 'forkRepository';

// --- General fields, shown for all repositoryTypes
export const fieldName = 'repositoryName';
export const fieldDescription = 'repositoryDescription';
export const fieldType = 'repositoryType';

// --- Fields for repositoryType='code-capsule'
export const fieldCodeCapsuleHabitat = 'codeCapsuleHabitat';

// --- Fields for repositoryType='archetype'
export const fieldKernel = 'archetypeKernel';
export const fieldArchHabitatDatapool = 'archetypeHabitatDatapool';

// --- The complete form object passed to redux-form
export const form: ConfigProps = {
  form: formName,
  validate,
  asyncValidate: (allValues, dispatch) =>
    Promise.all([
      new Promise((resolve, reject) => {
        dispatch(
          // @ts-ignore
          checkRepoNameExists(allValues[fieldName], { resolve, reject })
        );
      }).then(({ exists }) =>
        exists
          ? Promise.reject({
              [fieldName]: {
                id: 'newRepo.stepOne.error.this_name_already_exists',
                defaultMessage: 'This repository name exists already.',
              },
            })
          : null
      ),
      // Currently redundant since checkRepoNameExists checks across all users, and you can't manually create a .asr directory
      /*      new Promise((resolve, reject) => {
              dispatch(checkRepoDirExists(allValues[fieldName], { resolve, reject }));
            }).then(({ exists }) =>
              exists
                ? Promise.reject({
                    [fieldName]: {
                      id: 'newRepo.stepOne.error.todo',
                      defaultMessage:
                        'Can not rename directory. A file or directory with that name already exists.',
                    },
                  })
                : null,
            ),*/
    ]),
  asyncChangeFields: [fieldName],
  enableReinitialize: true,
};
