import React, { Component, CSSProperties } from 'react';
import { ToBeRefined } from 'common/dist/types/todo_type';
import DisplayDataOutput from '../code-cell-outputs/display-data-output/DisplayDataOutput';
import ExecuteResultOutput from '../code-cell-outputs/execute-result-output/ExecuteResultOutput';
import ErrorOutput from '../code-cell-outputs/error-output/ErrorOutput';
import StreamOutput from '../code-cell-outputs/stream-output/StreamOutput';

type Props = {
  output: ToBeRefined;
  key: string;
};

export default class InnerOutput extends Component<Props, {}> {
  ownRef = null;

  render() {
    const { output, key } = this.props;

    const parentStyle: CSSProperties = {
      position: 'relative',
    };

    switch (output.output_type) {
      case 'execute_result': {
        return (
          <div style={parentStyle}>
            <ExecuteResultOutput key={key} data={output.data} />
          </div>
        );
      }
      case 'error': {
        return (
          <div style={parentStyle}>
            <ErrorOutput
              key={key}
              ename={output.ename}
              evalue={output.evalue}
              traceback={output.traceback}
            />
          </div>
        );
      }
      case 'display_data': {
        return (
          <div ref={this.ownRef} style={parentStyle}>
            <DisplayDataOutput key={key} data={output.data} />
          </div>
        );
      }
      case 'stream': {
        return (
          <div ref={this.ownRef} style={parentStyle}>
            <StreamOutput key={key} output={output} />
          </div>
        );
      }
      default:
        return (
          <div ref={this.ownRef} style={parentStyle}>
            <p key={key}>{output.output_type}</p>
          </div>
        );
    }
  }
}
