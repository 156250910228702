import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ActivateRealtimeToggle from '../ActivateRealtimeToggle.container';

/**
 * Shows the ZmqMqtt part in the Realtime screen of the Augur Details
 */
export default class ZmqMqttGroup extends Component {
  render() {
    return (
      <div className={'activate-container rest-activate-container'}>
        <div className={'activate-top'}>
          <div className={'activate-title'}>
            <FormattedMessage
              id={'dashboard.augur_settings.zmq_realtime_api'}
              defaultMessage={'ZMQ Realtime Scoring'}
            />
          </div>
          <div className={'activate-description'}>
            <FormattedMessage
              id={'dashboard.augur_settings.activate_zmq_realtime_scoring'}
              defaultMessage={'Activate your model for scoring via ZMQ'}
            />
          </div>
        </div>
        <div className={'activate-bottom'}>
          <ActivateRealtimeToggle servingType={'zmq_mqtt'} />
        </div>
      </div>
    );
  }
}
