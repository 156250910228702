import { createAction } from 'redux-act';
import { put, call, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const checkDatapoolNameExists = createAction(
  'check whether datapool with that name exists',
  (habitatCode, datapoolName, callbacks) => ({
    habitatCode,
    datapoolName,
    callbacks,
  })
);

export const checkDatapoolNameExistsSuccess = createAction(
  'check whether datapool with that name exists - success',
  (response) => response
);

export const checkDatapoolNameExistsFail = createAction(
  'check whether datapool with that name exists - fail',
  (error) => error
);

export const reducer = {
  [checkDatapoolNameExists]: (state, { habitatCode, datapoolName }) => ({
    ...state,
    newDatapool: {
      ...state.newDatapool,
      datapoolName: {
        habitatCode,
        datapoolName,
        checking: true,
      },
    },
  }),
  [checkDatapoolNameExistsSuccess]: (state, { exists }) => ({
    ...state,
    newDatapool: {
      ...state.newDatapool,
      datapoolName: {
        ...state.newDatapool.datapoolName,
        exists,
        checking: false,
        checked: true,
      },
    },
  }),
  [checkDatapoolNameExistsFail]: (state, error) => ({
    ...state,
    newDatapool: {
      ...state.newDatapool,
      datapoolName: {
        ...state.newDatapool.datapoolName,
        error,
        checking: false,
        checked: false,
      },
    },
  }),
};

export function* checkDatapoolNameExistsSaga({
  payload: { habitatCode, datapoolName, callbacks },
}) {
  const { response, error } = yield call(
    Api.datapools.checkDatapoolNameExists,
    habitatCode,
    datapoolName
  );
  if (response) {
    yield put(checkDatapoolNameExistsSuccess(response));
    callbacks.resolve(response);
  } else {
    yield put(checkDatapoolNameExistsFail(error));
    callbacks.reject(error);
  }
}

export function* watchCheckDatapoolNameExists() {
  yield takeEvery(
    checkDatapoolNameExists.getType(),
    checkDatapoolNameExistsSaga
  );
}
