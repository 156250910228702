import React, { FC } from 'react';
import styles from './styles.module.scss';
import { NodeInfo } from '../../../core/api/codeCapsules';
import classNames from 'classnames';
import { humanFileSize } from 'common/dist/utils/kubernetes';
import InfoBox from '../../atoms/info-box/InfoBox';

export type Props = {
  nodeInfo: NodeInfo;
  renderTitle?: boolean;
};

const K8sNodeInfo: FC<Props> = ({ nodeInfo, renderTitle }) => {
  if (!nodeInfo) return null;

  return (
    <div className={styles.k8sNodeInfo}>
      {renderTitle && (
        <span className={styles.title}>Kubernetes Node Info</span>
      )}
      <div className={styles.infoBox}>
        <InfoBox>
          <span>
            This shows the total resources of the nodes in the Kubernetes
            cluster. Some of these resources might already be allocated by other
            workloads in the cluster.
          </span>
        </InfoBox>
      </div>

      <table className={styles.table}>
        <thead>
          <tr className={classNames(styles.row, styles.rowHeader)}>
            <th className={classNames(styles.col, styles.colName)}>
              Node Name
            </th>
            <th className={classNames(styles.col, styles.colCpu)}>
              Allocatable CPU
            </th>
            <th className={classNames(styles.col, styles.colMemory)}>
              Allocatable RAM
            </th>
            <th className={classNames(styles.col, styles.colGpu)}>
              Allocatable GPU
            </th>
            <th className={classNames(styles.col, styles.colGpuModel)}>
              GPU Model
            </th>
          </tr>
        </thead>
        <tbody>
          {(nodeInfo?.nodes || [])
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((node) => (
              <tr className={styles.row}>
                <th className={classNames(styles.col, styles.colName)}>
                  {node.name}
                </th>
                <th className={classNames(styles.col, styles.colCpu)}>
                  {node.resources?.cpu?.allocatable}
                </th>
                <th className={classNames(styles.col, styles.colMemory)}>
                  {humanFileSize(node.resources?.memory?.allocatable, false)}
                </th>
                <th className={classNames(styles.col, styles.colGpu)}>
                  {node.resources?.gpu?.allocatable > 0 &&
                    node.resources?.gpu?.allocatable}
                </th>
                <th className={classNames(styles.col, styles.colGpuModel)}>
                  {/* @ts-ignore */}
                  {node.resources?.gpu?.model || ''}
                </th>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

K8sNodeInfo.defaultProps = {
  renderTitle: true,
};

export default K8sNodeInfo;
