import {
  CommunicationGroupsErrorType,
  CommunicationGroupsValueType,
} from './OptCommunicationGroups';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import { validateDescription, validateName } from '../optChannels/validate';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import _ from 'lodash';
import { CommunicationGroup } from 'common/dist/types/module.optimization';

export const validateCommunicationGroups = (
  value: CommunicationGroupsValueType
): CommunicationGroupsErrorType => {
  const error = {};

  // No validation required: The communication groups are optional
  value?.forEach((communicationGroup) => {
    const communicationGroupError = validateSingleCommunicationGroup(
      communicationGroup,
      value
    );
    if (!_.isEmpty(communicationGroupError)) {
      error[communicationGroup.id] = communicationGroupError;
    }
  });
  return cleanErrorObject(error);
};

/**
 * Validation for the "sub form": add communication group
 * @param value
 */
export const validateSingleCommunicationGroup = (
  communicationGroup: Partial<CommunicationGroup>,
  allCommunicationsGroups: Partial<CommunicationGroup>[]
): CommunicationGroupsErrorType => {
  const error = {};
  // Validate name
  error['name'] = validateName(
    communicationGroup.name,
    (allCommunicationsGroups || [])
      .filter((x) => x.id !== communicationGroup.id)
      .map((comm) => comm.name)
  );
  // Validate description
  error['description'] = validateDescription(communicationGroup.description);
  // At least one communication is required
  if ((communicationGroup?.communicationIds || []).length === 0) {
    error['communicationIds'] =
      moduleOptimizationMessages.msgNewDatapoolStepCommunicationGroupsErrNoCommunication;
  }
  return cleanErrorObject(error);
};
