import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputElementShape } from '../_interface/P3InputElementShape';
import { getValueFromSettingsField } from '../_interface/input-element.form';
import ThemedSwitch from '../../../../../../../../../../atoms/themed-switch/ThemedSwitch';

export default class Toggle extends Component {
  render() {
    const { path, cellId, updateDataOfInputElement, element, cell } =
      this.props;

    const valueExist =
      element.data &&
      (element.data.checked === true || element.data.checked === false);

    const defaultValueField = element.settings
      ? element.settings.defaultValue
      : undefined;
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      cell.as_variables
    );

    return (
      <div className={'inner-input-element toggle-element'}>
        <ThemedSwitch
          onChange={(v) =>
            updateDataOfInputElement(path, cellId, element, { checked: v })
          }
          checked={valueExist ? element.data.checked : defaultValue}
          height={20}
          width={40}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
          activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
          themeColor={'primary'}
        />
      </div>
    );
  }
}

Toggle.propTypes = {
  path: PropTypes.string.isRequired,
  cellId: PropTypes.string.isRequired,
  /** Function to update the user input */
  updateDataOfInputElement: PropTypes.func.isRequired,
  /** Input element (including the settings + the user input) */
  element: inputElementShape,
  cell: PropTypes.shape({}).isRequired,
};
