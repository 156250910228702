import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class LoadError extends Component {
  render() {
    return (
      <div className={'load-error-container'}>
        <p className={'load-error-headline'}>Load Error</p>
        <p className={'load-error-message'}>
          {JSON.stringify(this.props.message)}
        </p>
      </div>
    );
  }
}

LoadError.propTypes = {
  message: PropTypes.string.isRequired,
};
