import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import JobQueueContainer from './job-queues/Queue.container';
import JobHistoryContainer from './job-history/JobHistory.container';
import JobConfigurationContainer from './job-configuration/JobConfiguration.container';
import NewScheduleWizard from './job-schedules/add-schedule/NewScheduleWizard.container';
import RealtimeSummary from './realtime/RealtimeSummary.container';

import { orchestrationRoutes } from './routes';
import JobDetails from './job-details/JobDetails.container';
import MainContainer from '../pages/main-container/MainContainer';
import WithSidemenu from '../molecules/with-sidemenu/WithSidemenu';
import {
  FiActivity,
  FiClock,
  FiGrid,
  FiLayers,
  FiRepeat,
  FiSliders,
} from 'react-icons/fi';
import NotFound from '../pages/not-found/NotFound.container';
import JobSchedulesPaginated from './job-schedules/JobSchedulesPaginated.container';
import JobLogs from './job-details/JobLogs.container';
import CodeCapsuleOutput from './job-details/code-capsule-output/CodeCapsuleOutput.container';
import JobGroups from './job-groups/JobGroups';
import JobGroupWizard from './job-groups/wizard/JobGroupWizard.container';
import NoPermissions from '../pages/no-permissions/NoPermissions';
import { JOB_GROUP_VARIANTS } from './details/job-group-details/JobGroupDetails';
import JobHistoryGroupDetails from './details/job-group-details/JobHistoryGroupDetails.container';
import JobTemplateGroupDetails from './details/job-group-details/JobTemplateGroupDetails.container';
import JobScheduleGroupDetails from './details/job-group-details/JobScheduleGroupDetails.container';

function orchestrationMenuEntries(isAdmin) {
  const regularTabs = [
    {
      type: 'headline',
      headline: {
        id: 'no-id',
        defaultMessage: 'Batch',
      },
    },
    {
      type: 'link',
      id: 'queue',
      activePerDefault: true,
      to: 'queue',
      nameIntlId: 'dashboard.tab.orchestration.job_queue',
      nameDefault: 'Job Queue',
      icon: FiLayers,
    },
    {
      type: 'link',
      id: 'history',
      activePerDefault: false,
      to: 'history',
      nameIntlId: 'dashboard.tab.orchestration.job_history',
      nameDefault: 'Job History',
      icon: FiClock,
    },
    {
      type: 'link',
      id: 'jobgroups',
      activePerDefault: false,
      to: 'jobgroups',
      nameIntlId: 'dashboard.tab.orchestration.job_groups',
      nameDefault: 'Job Groups',
      icon: FiGrid,
    },
    {
      type: 'link',
      id: 'schedulings',
      activePerDefault: false,
      to: 'schedulings',
      nameIntlId: 'dashboard.tab.orchestration.job_schedules',
      nameDefault: 'Job Schedules',
      icon: FiRepeat,
    },
    {
      type: 'hr',
    },
    {
      type: 'headline',
      headline: {
        id: 'no-id',
        defaultMessage: 'Realtime',
      },
    },
    {
      type: 'link',
      id: 'realtime',
      activePerDefault: false,
      to: 'realtime',
      nameIntlId: 'dashboard.tab.orchestration.realtime',
      nameDefault: 'Realtime',
      icon: FiSliders,
    },
  ];

  const adminTabs = [
    {
      type: 'hr',
    },
    {
      type: 'headline',
      headline: {
        id: 'no-id',
        defaultMessage: 'Settings',
      },
    },
    {
      type: 'link',
      id: 'jobconfiguration',
      activePerDefault: false,
      to: 'jobconfiguration',
      nameIntlId: 'dashboard.tab.orchestration.jobconfigs',
      nameDefault: 'Job Configuration',
      icon: FiActivity,
    },
  ];

  if (!isAdmin) {
    return regularTabs;
  } else {
    return [...regularTabs, ...adminTabs];
  }
}

class Orchestration extends Component {
  // Fetch code capsules after the global summary is fetched, which is missing the code capsules
  //  the orchestration needs them in quite a few places: names in history, jobgroups, schedules and job group editor
  componentDidMount() {
    const { summary, fetchCodeCapsules } = this.props;
    const habitats = summary.map((habitat) => habitat.code);
    habitats.forEach((code) => fetchCodeCapsules('habitat', code));
  }

  componentDidUpdate(prevProps) {
    const { summary, fetchCodeCapsules } = this.props;
    if (summary !== prevProps.summary) {
      const habitats = summary.map((habitat) => habitat.code);
      habitats.forEach((code) => fetchCodeCapsules('habitat', code));
    }
  }

  getActiveCategory() {
    const path = this.props.history.location.pathname;
    const { origin } = this.props;
    if (!path) return null;
    const rep = path.replace('/app/orchestration/', '');
    const parts = rep.split('/');

    // Special case for the Job Details: Mark the "origin" as active (for example the Job Queue, if the Job Details have been opened from there)
    if (parts[0] === 'jobdetails' && origin) {
      return origin;
    }

    return parts[0];
  }

  render() {
    const { isAdmin, missingPermissionsError } = this.props;
    const selectedCategory = this.getActiveCategory();

    const handleSelect = (selectedTab) =>
      this.props.history.push(`${orchestrationRoutes.basePath}/${selectedTab}`);
    const menuEntries = orchestrationMenuEntries(isAdmin);

    if (missingPermissionsError) {
      return <NoPermissions fullViewHeight />;
    }

    return (
      <MainContainer
        additionalClassname={'Orchestration'}
        transparent={false}
        fullWidth
        scrollableY={false}
      >
        <Switch>
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.queue.path}`}
            component={() => (
              <WithSidemenu
                menuEntries={menuEntries}
                selectedTab={selectedCategory}
                handleSelect={handleSelect}
              >
                <JobQueueContainer />
              </WithSidemenu>
            )}
          />
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.history.path}`}
          >
            <WithSidemenu
              menuEntries={menuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <JobHistoryContainer />
            </WithSidemenu>
          </Route>
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.schedulings.path}`}
          >
            <WithSidemenu
              menuEntries={menuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <JobSchedulesPaginated />
            </WithSidemenu>
          </Route>
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.addSchedule.path}`}
          >
            <NewScheduleWizard />
          </Route>
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.editSchedule.path}/:scheduleCode`}
            render={(routeProps) => (
              <NewScheduleWizard
                edit={true}
                scheduleCode={routeProps.match.params.scheduleCode}
              />
            )}
          />
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.realtime.path}`}
            component={() => (
              <WithSidemenu
                menuEntries={menuEntries}
                selectedTab={selectedCategory}
                handleSelect={handleSelect}
              >
                <RealtimeSummary />
              </WithSidemenu>
            )}
          />
          {isAdmin && (
            <Route
              exact
              path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobConfiguration.path}`}
              component={() => (
                <WithSidemenu
                  menuEntries={menuEntries}
                  selectedTab={selectedCategory}
                  handleSelect={handleSelect}
                >
                  <JobConfigurationContainer />
                </WithSidemenu>
              )}
            />
          )}
          {isAdmin && (
            <Route
              path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/:jobCode/logs`}
              render={(routeProps) => (
                <WithSidemenu
                  menuEntries={menuEntries}
                  selectedTab={selectedCategory}
                  handleSelect={handleSelect}
                >
                  <JobLogs jobCode={routeProps.match.params.jobCode} />
                </WithSidemenu>
              )}
            />
          )}
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/:jobCode/ccoutput`}
          >
            <WithSidemenu
              menuEntries={menuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <CodeCapsuleOutput />
            </WithSidemenu>
          </Route>
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/:jobCode`}
          >
            <WithSidemenu
              menuEntries={menuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <JobDetails />
            </WithSidemenu>
          </Route>
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroups.path}`}
          >
            <WithSidemenu
              menuEntries={menuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <JobGroups />
            </WithSidemenu>
          </Route>
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.addJobGroup.path}`}
          >
            <JobGroupWizard />
          </Route>
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.editJobGroup.path}/:jobGroupCode`}
            render={(routeProps) => (
              <JobGroupWizard
                edit={true}
                jobGroupCode={routeProps.match.params.jobGroupCode}
              />
            )}
          />
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobHistory}/:jobGroupCode`}
          >
            <WithSidemenu
              menuEntries={menuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <JobHistoryGroupDetails />
            </WithSidemenu>
          </Route>
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobTemplate}/:jobGroupCode`}
          >
            <WithSidemenu
              menuEntries={menuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <JobTemplateGroupDetails />
            </WithSidemenu>
          </Route>
          <Route
            path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobSchedule}/:jobGroupCode`}
          >
            <WithSidemenu
              menuEntries={menuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <JobScheduleGroupDetails />
            </WithSidemenu>
          </Route>
          <Redirect
            exact
            path={orchestrationRoutes.basePath}
            to={`${orchestrationRoutes.basePath}/${orchestrationRoutes.queue.path}`}
          />
          <Route component={NotFound} />
        </Switch>
      </MainContainer>
    );
  }
}

Orchestration.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  missingPermissionsError: PropTypes.bool,
  history: {
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  },
  /** Contains the id of the site from which it was opened */
  origin: PropTypes.string,
};

export default withRouter(Orchestration);
