import { connect } from 'react-redux';
import Upload from './Upload';
import {
  addFiles,
  cancelUpload,
  clearFilesList,
  uploadFiles,
} from '../../../../../redux/workbench/modules/upload.module';

export function mapStateToProps(state, { uploadId }) {
  const uploadState = state.workbench?.upload?.[uploadId] || {};
  const { loading, diskLoaded, diskError, data } = state.workbench.diskUsage || {};
  const {
    files,
    uploading,
    error,
    uploadProgress,
    uploadDone,
    uploadCancelled,
  } = uploadState;

  return {
    files,
    uploading,
    error,
    uploadProgress,
    uploadDone,
    uploadCancelled,
    diskLoaded,
    data,
  };
}

export const mapDispatchToProps = {
  clearFilesList,
  addFiles,
  uploadFiles,
  cancelUpload,
};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
