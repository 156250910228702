import { ConfigProps, FormErrors } from 'redux-form';
import { JobBackup } from 'common/dist/types/job';

export const formName = 'runBackup';
export const fieldJobType = 'jobType';
export const fieldTemplateId = 'templateId';
export const fieldBackupId = 'backupId';

export interface Values {
  JobBackup;
}

export type Errors = FormErrors<JobBackup, string>;

export function validate(values: JobBackup): Errors {
  let errors: Errors = {};

  // --- Validate type
  const jobType = values[fieldJobType];
  if (!jobType) {
    errors = { ...errors, [fieldJobType]: 'Please select a type.' };
  }

  switch (jobType) {
    case 'backup':
      // --- Validate type
      if (!values[fieldTemplateId]) {
        errors = { ...errors, [fieldTemplateId]: 'Please select a template.' };
      }
      break;
    case 'restore':
      // --- Validate type
      if (!values[fieldBackupId]) {
        errors = { ...errors, [fieldBackupId]: 'Please select a backup.' };
      }
      break;
  }

  return errors;
}

export const form: ConfigProps = {
  form: formName,
  validate,
};
