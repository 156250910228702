import { FC } from 'react';
import { ExtendedColSpec } from '../Table';

export type Props = {
  cols: ExtendedColSpec[];
};

const TableHead: FC<Props> = (props) => {
  return (
    <thead>
      <tr>
        {props.cols.map((column, i) => (
          <th
            style={{
              cursor: 'default',
              ...(column.highlightStyle || {}),
            }}
          >
            <span style={column.highlightStyle?.colName} className={'col-name'}>
              {column.colName}
            </span>
            <span style={column.highlightStyle?.colType} className={'col-type'}>
              {column.colType}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
