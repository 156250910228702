import React, { FC } from 'react';
import BackTo, { Props as BackToProps } from '../back-to/BackTo';
import './styles.scss';

export type Props = {
  backToProps: BackToProps[];
};

const BreadCrumbs: FC<Props> = (props) => {
  return (
    <div className={'BreadCrumbs'}>
      {props.backToProps.map((btp) => (
        <BackTo {...btp} />
      ))}
    </div>
  );
};

export default BreadCrumbs;
