import keycloak from '../../../../keycloak';

export const HEADERS = {
  JSON: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  FORM: {
    Accept:
      'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
export const IS_API = '@@API';
const REQUEST_TIMEOUT = 10000;

export function apiRequest(url, options) {
  return keycloak
    .updateToken()
    .then((refreshed) => {
      return fetch(
        url,
        Object.assign({}, options, {
          cache: 'no-store',
          headers: {
            ...options?.headers,
            Authorization: `Bearer ${keycloak.token}`,
          },
          // Will result in something like "DOMException: The operation was aborted."
          // You may want to catch it, check the name for "AbortError" and rethrow with more info
          signal: AbortSignal.timeout(REQUEST_TIMEOUT),
        })
      );
    })
    .then((response) => {
      if (response.ok) {
        return response
          .text()
          .then((text) => {
            if (!text) return {};
            try {
              return JSON.parse(text);
            } catch (e) {
              return text;
            }
          })
          .then((data) => ({ response: data }));
      } else if (response.status == 404){
        if (url.includes("main.asapp")){
          throw new Error("404 for main.asapp");
        } 
      }

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response
          .json()
          .then((json) => ({
            [IS_API]: true,
            error: json,
            status: response.status,
          }))
          .catch((err) => ({
            [IS_API]: true,
            error: err.message,
            status: response.status,
          }));
      }
      return response.text().then((text) => ({
        [IS_API]: true,
        error: text,
        status: response.status,
      }));
    });
}

export function putApiRequest(url, body, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'PUT',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
      ...getXsrfHeader(),
    },
    body: JSON.stringify(body),
  });
}

export function postApiRequest(
  url,
  body,
  options = { headers: {} },
  contentType = 'json'
) {
  let headers = { ...options.headers };
  switch (contentType) {
    case 'json': {
      headers = {
        ...headers,
        ...HEADERS.JSON,
        ...getXsrfHeader(),
      };
      break;
    }
    case 'form':
      {
        headers = {
          ...headers,
          ...HEADERS.FORM,
        };
      }
      break;
  }

  let bodyProcessed = body;
  switch (contentType) {
    case 'application/json': {
      // Could be simplified with "case 'json'" but the fall-through didn't work for any reason
      bodyProcessed = JSON.stringify(body);
      break;
    }
    case 'json': {
      bodyProcessed = JSON.stringify(body);
      break;
    }
    case 'form':
      break; // Nothing to do
  }

  return apiRequest(url, {
    ...options,
    method: 'POST',
    headers,
    body: bodyProcessed,
  });
}

export function deleteApiRequest(url, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'DELETE',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
      ...getXsrfHeader(),
    },
  });
}

export function patchApiRequest(url, body, options = { headers: {} }) {
  return apiRequest(url, {
    ...options,
    method: 'PATCH',
    headers: {
      ...options.headers,
      ...HEADERS.JSON,
      ...getXsrfHeader(),
    },
    body: JSON.stringify(body),
  });
}

export function getApiRequest(
  url,
  options = { headers: {} },
  contentType = 'json'
) {
  let headers = { ...options.headers };
  switch (contentType) {
    case 'json': {
      headers = {
        ...headers,
        ...HEADERS.JSON,
      };
      break;
    }
    case 'form':
      {
        headers = {
          ...headers,
          ...HEADERS.FORM,
        };
      }
      break;
  }

  return apiRequest(url, {
    ...options,
    method: 'GET',
    headers,
  });
}

/**
 * Returns the Xsrf-Header required for POST/ (PUT/...?) requests against the Jupyter Notebook API
 * @returns {{}|{"X-XSRFToken": string | boolean}}
 */
function getXsrfHeader() {
  const cookie = getCookie('_xsrf');
  if (!cookie) return {};

  return { 'X-XSRFToken': cookie };
}

/**
 * Get cookie by name
 * @param name
 * @returns {string|boolean}
 */
function getCookie(name) {
  const pattern = RegExp(`${name}=.[^;]*`);
  const matched = document.cookie.match(pattern);
  if (matched) {
    const cookie = matched[0].split('=');
    return cookie[1];
  }
  return false;
}
