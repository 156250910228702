import { connect } from 'react-redux';
import UploadModal from './UploadModal';
import { hideUploadModal } from '../../../../redux/workbench/modules/upload.module';

export function mapStateToProps(state) {
  return {
    isUploadModalOpen: (state.workbench.upload || {}).isUploadModalOpen,
    selectedDirPath: (state.workbench.content || {}).selectedDirPath,
  };
}

export const mapDispatchToProps = {
  hideUploadModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);
