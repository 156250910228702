import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';
import { sendNotification } from './notifications.module';
import { error as errorNotification } from '../../core/notifications';

export const activateRealtimeModel = createAction(
  'activate realtime model',
  (augurCode, servingType, datatronName) => ({
    augurCode,
    servingType,
    datatronName,
  })
);

export const activateRealtimeModelSuccess = createAction(
  'activate realtime model - success ',
  (augurCode, servingType) => ({ augurCode, servingType })
);

export const activateRealtimeModelFailure = createAction(
  'activate realtime model - failure',
  (error, augurCode, servingType) => ({ error, augurCode, servingType })
);

export const deactivateRealtimeModel = createAction(
  'deactivate realtime model',
  (augurCode, servingType) => ({ augurCode, servingType })
);

export const deactivateRealtimeModelSuccess = createAction(
  'deactivate realtime model - success ',
  (augurCode, servingType) => ({ augurCode, servingType })
);

export const deactivateRealtimeModelFailure = createAction(
  'deactivate realtime model - failure',
  (error, augurCode, servingType) => ({ error, augurCode, servingType })
);

export const fetchRealtimeModelStatus = createAction(
  'fetch realtime model status',
  (augurCode, showSpinner = true) => ({ augurCode, showSpinner })
);

export const fetchRealtimeModelStatusSuccess = createAction(
  'fetch realtime model status - success ',
  (augurCode, data) => ({ augurCode, data })
);

export const fetchRealtimeModelStatusFailure = createAction(
  'fetch realtime model status - failure',
  (augurCode, error) => ({ error, augurCode })
);

export const fetchRealtimeModelSummary = createAction(
  'fetch realtime model summary'
);

export const fetchRealtimeModelSummarySuccess = createAction(
  'fetch realtime model summary - success ',
  (data) => data
);

export const fetchRealtimeModelSummaryFailure = createAction(
  'fetch realtime model summary - failure',
  (error) => error
);

export const realtimeRequest = createAction(
  'realtime request',
  (augurCode, url, body) => ({ augurCode, url, body })
);

export const realtimeRequestSuccess = createAction(
  'realtime request - success',
  (augurCode, data) => ({ augurCode, data })
);

export const realtimeRequestFailure = createAction(
  'realtime request - failure',
  (augurCode, error) => ({ augurCode, error })
);
export const updateRealtimeRequestBody = createAction(
  'update realtime request body',
  (augurCode, body) => ({ augurCode, body })
);

export const reducer = {
  [fetchRealtimeModelStatus](state, { augurCode, showSpinner }) {
    const currentStatus = state.realtime.augurs[augurCode]
      ? state.realtime.augurs[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        augurs: {
          ...state.realtime.augurs,
          [augurCode]: {
            ...currentStatus,
            loading: showSpinner,
            // loaded: false,
          },
        },
      },
    };
  },
  [fetchRealtimeModelStatusSuccess](state, { augurCode, data }) {
    const currentStatus = state.realtime.augurs[augurCode]
      ? state.realtime.augurs[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        augurs: {
          ...state.realtime.augurs,
          [augurCode]: {
            ...currentStatus,
            data,
            loading: false,
            loaded: true,
          },
        },
      },
    };
  },
  [fetchRealtimeModelStatusFailure](state, { augurCode, error }) {
    const currentStatus = state.realtime.augurs[augurCode]
      ? state.realtime.augurs[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        augurs: {
          ...state.realtime.augurs,
          [augurCode]: {
            ...currentStatus,
            loading: false,
            loaded: true,
            error,
          },
        },
      },
    };
  },
  [fetchRealtimeModelSummary]: (state) => ({
    ...state,
    realtime: {
      ...state.realtime,
      summary: {
        ...state.realtime.summary,
        loading: true,
        loaded: false,
        error: undefined,
      },
    },
  }),
  [fetchRealtimeModelSummarySuccess]: (state, data) => ({
    ...state,
    realtime: {
      ...state.realtime,
      summary: {
        ...state.realtime.summary,
        loading: false,
        loaded: true,
        data,
        error: undefined,
      },
    },
  }),
  [fetchRealtimeModelSummaryFailure]: (state, error) => ({
    ...state,
    realtime: {
      ...state.realtime,
      summary: {
        ...state.realtime.summary,
        loading: false,
        loaded: true,
        error,
      },
    },
  }),
  [activateRealtimeModel](state, { augurCode, servingType, datatronName }) {
    const currentStatus = state.realtime.augurs[augurCode]
      ? state.realtime.augurs[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        augurs: {
          ...state.realtime.augurs,
          [augurCode]: {
            ...currentStatus,
            [servingType]: {
              ...(currentStatus[servingType] || {}),
              starting: true,
            },
          },
        },
      },
    };
  },
  [activateRealtimeModelSuccess](state, { augurCode, servingType }) {
    const currentStatus = state.realtime.augurs[augurCode]
      ? state.realtime.augurs[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        augurs: {
          ...state.realtime.augurs,
          [augurCode]: {
            ...currentStatus,
            [servingType]: {
              ...(currentStatus[servingType] || {}),
              starting: false,
            },
          },
        },
      },
    };
  },
  [activateRealtimeModelFailure](state, { augurCode, servingType }) {
    const currentStatus = state.realtime.augurs[augurCode]
      ? state.realtime.augurs[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        augurs: {
          ...state.realtime.augurs,
          [augurCode]: {
            ...currentStatus,
            [servingType]: {
              ...(currentStatus[servingType] || {}),
              starting: false,
            },
          },
        },
      },
    };
  },
  [deactivateRealtimeModel](state, { augurCode, servingType }) {
    const currentStatus = state.realtime.augurs[augurCode]
      ? state.realtime.augurs[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        augurs: {
          ...state.realtime.augurs,
          [augurCode]: {
            ...currentStatus,
            [servingType]: {
              ...(currentStatus[servingType] || {}),
              stopping: true,
            },
          },
        },
      },
    };
  },
  [deactivateRealtimeModelSuccess](state, { augurCode, servingType }) {
    const currentStatus = state.realtime.augurs[augurCode]
      ? state.realtime.augurs[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        augurs: {
          ...state.realtime.augurs,
          [augurCode]: {
            ...currentStatus,
            [servingType]: {
              ...(currentStatus[servingType] || {}),
              stopping: false,
            },
          },
        },
      },
    };
  },
  [deactivateRealtimeModelFailure](state, { augurCode, error, servingType }) {
    const currentStatus = state.realtime.augurs[augurCode]
      ? state.realtime.augurs[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        augurs: {
          ...state.realtime.augurs,
          [augurCode]: {
            ...currentStatus,
            [servingType]: {
              ...(currentStatus[servingType] || {}),
              stopping: false,
            },
          },
        },
      },
    };
  },
  [realtimeRequest](state, { augurCode }) {
    const currentStatus = state.realtime.requests[augurCode]
      ? state.realtime.requests[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        requests: {
          ...state.realtime.requests,
          [augurCode]: {
            ...currentStatus,
            loading: true,
          },
        },
      },
    };
  },
  [realtimeRequestSuccess](state, { augurCode, data }) {
    const currentStatus = state.realtime.requests[augurCode]
      ? state.realtime.requests[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        requests: {
          ...state.realtime.requests,
          [augurCode]: {
            ...currentStatus,
            loading: false,
            loaded: true,
            data,
            error: undefined,
          },
        },
      },
    };
  },
  [realtimeRequestFailure](state, { augurCode, error }) {
    const currentStatus = state.realtime.requests[augurCode]
      ? state.realtime.requests[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        requests: {
          ...state.realtime.requests,
          [augurCode]: {
            ...currentStatus,
            loading: false,
            loaded: false,
            error,
          },
        },
      },
    };
  },
  [updateRealtimeRequestBody](state, { augurCode, body }) {
    const currentStatus = state.realtime.requests[augurCode]
      ? state.realtime.requests[augurCode]
      : {};
    return {
      ...state,
      realtime: {
        ...state.realtime,
        requests: {
          ...state.realtime.requests,
          [augurCode]: {
            ...currentStatus,
            body,
          },
        },
      },
    };
  },
};

export function* activateRealtimeModelSaga({
  payload: { augurCode, servingType, datatronName },
}) {
  const { response, error } = yield call(
    Api.orchestration.activateRealtimeModel,
    augurCode,
    servingType,
    datatronName
  );
  if (response) {
    yield put(activateRealtimeModelSuccess(augurCode, servingType));
    yield put(fetchRealtimeModelStatus(augurCode));
  } else {
    yield put(activateRealtimeModelFailure(error, augurCode, servingType));
    yield put(fetchRealtimeModelStatus(augurCode));
  }
}

export function* watchActivateRealtimeModel() {
  yield takeEvery(activateRealtimeModel.getType(), activateRealtimeModelSaga);
}

export function* deactivateRealtimeModelSaga({
  payload: { augurCode, servingType },
}) {
  const { response, error } = yield call(
    Api.orchestration.deactivateRealtimeModel,
    augurCode,
    servingType
  );
  if (response) {
    yield put(deactivateRealtimeModelSuccess(augurCode, servingType));
    yield put(fetchRealtimeModelStatus(augurCode));
  } else {
    yield put(deactivateRealtimeModelFailure(error, augurCode, servingType));
    yield put(fetchRealtimeModelStatus(augurCode));
  }
}

export function* watchDeactivateRealtimeModel() {
  yield takeEvery(
    deactivateRealtimeModel.getType(),
    deactivateRealtimeModelSaga
  );
}

export function* fetchRealtimeStatusSaga({ payload: { augurCode } }) {
  const { response, error } = yield call(
    Api.orchestration.fetchRealtimeStatus,
    augurCode
  );
  if (response) {
    yield put(fetchRealtimeModelStatusSuccess(augurCode, response));
  } else {
    yield put(fetchRealtimeModelStatusFailure(augurCode, error));
  }
}

export function* watchFetchRealtimeStatus() {
  yield takeEvery(fetchRealtimeModelStatus.getType(), fetchRealtimeStatusSaga);
}

export function* fetchRealtimeSummarySaga() {
  const { response, error } = yield call(
    Api.orchestration.fetchRealtimeSummary
  );
  if (response) {
    yield put(fetchRealtimeModelSummarySuccess(response));
  } else {
    yield put(fetchRealtimeModelSummaryFailure(error));
  }
}

export function* watchFetchRealtimeSummary() {
  yield takeEvery(
    fetchRealtimeModelSummary.getType(),
    fetchRealtimeSummarySaga
  );
}

export function* postRealtimeRequestSaga({
  payload: { augurCode, url, body },
}) {
  const { response, error } = yield call(
    Api.orchestration.postRealtimeRequest,
    url,
    body
  );
  if (response) {
    yield put(realtimeRequestSuccess(augurCode, response));
  } else {
    yield put(realtimeRequestFailure(augurCode, error));
  }
}

export function* watchRealtimeRequest() {
  yield takeEvery(realtimeRequest.getType(), postRealtimeRequestSaga);
}
