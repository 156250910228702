import React, { FC } from 'react';
import { line } from 'd3-shape';
import { getX, getY } from './util';
import { ScaleLinear } from 'd3-scale';
import { DataType } from './LineChart';

type Props = {
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  data: DataType;
  stroke: string;
};

const StrokeLine: FC<Props> = (props: Props) => {
  const { xScale, yScale, data, stroke } = props;
  const strokeLine = line()
    .x((d) => xScale(getX(d)))
    .y((d) => yScale(getY(d)));
  const strokeLinePath = strokeLine(data);

  return (
    <path d={strokeLinePath} stroke={stroke} fill='none' strokeWidth='1.5' />
  );
};

export default StrokeLine;
