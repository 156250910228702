import React, { FC } from 'react';
import { FiServer } from 'react-icons/fi';
import { Session } from '../../../../store/workbench/state.types';
import styles from './styles.module.scss';

export type Props = {
  session: Session;
  deleteSession: (id: string, name: string) => void;
};

const SessionElement: FC<Props> = ({ session, deleteSession }) => {
  return (
    <div className={styles.element}>
      <FiServer className={styles.elementIcon} size={'20px'} />
      <p className={styles.elementName}>{session.name}</p>
      <div
        className={styles.shutdown}
        onClick={() => deleteSession(session.id, session.name)}
      >
        <p>Shutdown</p>
      </div>
    </div>
  );
};

export default SessionElement;
