import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';
import { fetchSummary } from './dashboard.summary.module';
import { sendNotification } from './notifications.module';
import { event } from '../../core/notifications';

export const addHabitat = createAction(
  'add new habitat',
  (habitatName) => habitatName
);

export const addHabitatSuccess = createAction(
  'add new habitat - success',
  (habitat) => habitat
);

export const addHabitatFail = createAction(
  'add new habitat - fail',
  (error) => error
);

export const reducer = {
  [addHabitat]: (state) => ({
    ...state,
    newHabitat: {
      ...(state.newHabitat || {}),
      submitting: true,
    },
  }),
  [addHabitatSuccess]: (state, data) => ({
    ...state,
    newHabitat: {
      ...(state.newHabitat || {}),
      submitting: false,
      createdHabitat: data,
    },
  }),
  [addHabitatFail]: (state, error) => ({
    ...state,
    newHabitat: {
      ...(state.newHabitat || {}),
      submitting: false,
      error,
    },
  }),
};

export function* addHabitatSaga({ payload: habitatName }) {
  const { response, error } = yield call(Api.habitats.addHabitat, habitatName);
  if (response) {
    yield put(addHabitatSuccess(response));
    yield put(
      sendNotification(
        'notifications.title.new_habitat',
        'notifications.description.habitat_was_added',
        event,
        { habitatName: habitatName }
      )
    );
    yield put(fetchSummary());
  } else {
    yield put(addHabitatFail(error));
  }
}

export function* watchAddHabitat() {
  yield takeEvery(addHabitat.getType(), addHabitatSaga);
}
