import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorOutput from '../../../organisms/workbench-cells/code-cell-outputs/error-output/ErrorOutput';

export default class ExecutionError extends Component {
  /**
   * Extracts the errors up to the active step TODO what's correct? In- or exclusive the activeStep?
   */
  flattenExecutionErrors() {
    const { executionPlan, activeStep } = this.props;
    return executionPlan
      .slice(0, activeStep)
      .flatMap((step) => step.executionErrors || []);
  }

  render() {
    return (
      <div className={'step-parent input-step-parent error-parent'}>
        <div className={'error-scroll-parent'}>
          {/* <div className={'error-message-container'}>
            There was an error during the execution of the previous step.
          </div> */}
          <div className={'error-outputs-container output-container-parent'}>
            {this.flattenExecutionErrors().map((e) => (
              <ErrorOutput
                ename={e.ename}
                evalue={e.evalue}
                traceback={e.traceback}
                onMeasure={() => {} /* Nothing to do inside the app */}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
ExecutionError.propTypes = {
  /** The notebook structured into parts to manage which cells can be executed and what is supposed to be shown in the
   * input output screens */
  executionPlan: PropTypes.arrayOf(
    PropTypes.shape({
      /** input|output|beginning */
      type: PropTypes.string.isRequired,
      /** waiting|executing|success|failure */
      status: PropTypes.string.isRequired,
      /** Cells for this executionPlan element (duplicate to the notebook.content.cells */
      cells: PropTypes.arrayOf(PropTypes.shape({})),
      executionErrors: PropTypes.array,
    })
  ),
  /** Index of the active (=visible) execution plan step */
  activeStep: PropTypes.number.isRequired,
};
