import React, { Component } from 'react';
import { Props } from './SortableSearchableList.container';
import './styles.scss';
import DropdownSelectInput from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import Button from '../../atoms/button/Button';

type State<Ordering> = {
  searchString: string;
  ordering: Ordering;
};

type OptionType<Ordering> = { label: string; value: Ordering };

export default class SortableSearchableList<
  Element,
  Ordering,
  ContentProps
> extends Component<Props<Element, Ordering, ContentProps>, State<Ordering>> {
  constructor(props: Props<Element, Ordering, ContentProps>) {
    super(props);
    const { defaultOrdering } = props;
    this.state = { searchString: '', ordering: defaultOrdering };
  }

  render() {
    const {
      data,
      contentProps,
      ContentComponent,
      search,
      orderings,
      sort,
      title,
      info,
      button,
      style = {},
    } = this.props;
    const searchedData = this.state.searchString
      ? data.filter((el) => search(el, this.state.searchString))
      : data;
    return (
      <div className={'SortableSearchableList--container'} style={style}>
        <div className={'SortableSearchableList--body'}>
          <div className={'SortableSearchableList--top'}>
            <div className={'SortableSearchableList--info'}>
              <div className={'SortableSearchableList--info-top-line'}>
                <div
                  className={'SortableSearchableList--top-side-by-side-parent'}
                >
                  <div>
                    <div>
                      <span className={'SortableSearchableList--name'}>
                        {title}
                      </span>
                    </div>
                    <div>
                      <span>{info}</span>
                    </div>
                  </div>
                  {button && (
                    <Button
                      buttonColor={'primary'}
                      withLink
                      buttonLabelDefault={button.buttonText}
                      onClick={button.onClick}
                      linkTo={button.link}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={'SortableSearchableList--headline'}>
            <div className={'SortableSearchableList--search'}>
              <input
                placeholder={'Filter by name'}
                type={'text'}
                value={this.state.searchString}
                onChange={(e) =>
                  this.setState({ ...this.state, searchString: e.target.value })
                }
              />
            </div>
            <DropdownSelectInput
              id='sorting-selector'
              name='order'
              options={orderings}
              value={orderings.find((o) => o.value === this.state.ordering)}
              onChange={(option: OptionType<Ordering>) => {
                this.setState({ ...this.state, ordering: option.value });
              }}
              containerClassName={'SortableSearchableList--sorting-selector'}
              clearable={false}
            />
          </div>
          {sort(searchedData, this.state.ordering).map((el, i) => (
            <ContentComponent {...contentProps(el, i)} />
          ))}
        </div>
      </div>
    );
  }
}
