import React, { FC } from 'react';
import { curveStepAfter, line } from 'd3-shape';

import { getThreshold, getTime } from '../../../core/common/drift';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { DriftDataEntry } from '../../modelManagement/modules/common/augur-details-tabs/accuracyDetails/types';

function getDataWithEdges(dataWithThreshold) {
  return [
    {
      ...dataWithThreshold[0],
      edge: 'left',
    },
    ...dataWithThreshold.slice(1, dataWithThreshold.length),
    // Add artificial point to have the threshold overlap the last bar too
    {
      edge: 'right',
    },
  ];
}

type Props = {
  data: DriftDataEntry[];
  innerPadding: number;
  xScale: ToBeRefined;
  yScale: ToBeRefined;
};

type DatumType = DriftDataEntry & {
  edge: 'left' | 'right';
};

const Threshold: FC<Props> = (props) => {
  const { data, xScale, yScale, innerPadding } = props;

  const dataWithThreshold = data.filter((item) => {
    return !!item.data.threshold;
  });

  if (dataWithThreshold.length === 0) {
    return <g />;
  }

  const dataWithEdges = getDataWithEdges(dataWithThreshold);

  const lineGenerator = line<DatumType>()
    .x((d) => {
      let shift = -innerPadding * xScale.bandwidth();

      if (d.edge === 'left') {
        shift = innerPadding;
      } else if (d.edge === 'right') {
        return (
          xScale(getTime(dataWithThreshold[dataWithThreshold.length - 1])) +
          xScale.bandwidth()
        );
      }

      return xScale(getTime(d)) + shift;
    })
    .y((d) => yScale(getThreshold(d)))
    .curve(curveStepAfter);

  const path = lineGenerator(dataWithEdges);

  return (
    <g>
      <path
        d={path}
        stroke='#5C6570'
        fill='none'
        strokeWidth='1.5'
        strokeDasharray='6, 3'
      />
    </g>
  );
};

export default Threshold;
