import React, { Component, ComponentType } from 'react';
import { TabLink } from 'react-tabs-redux';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import BrowserCollapseFlap from '../../atoms/BrowserCollapseFlap/BrowserCollapseFlap';
import { RouteComponentProps } from 'react-router';
import {
  locationWithWhitelistQuery,
  toLinkWhitelistQuery,
} from '../../../utils';

/** */
export type LinkType = {
  type: 'link';
  /** Color of the link - default: black */
  color?: 'black' | ' blue';
  /** Id of the entry */
  id: string;
  /** Where should the link point to? */
  to: string;
  /** Intl ID of the display name */
  nameIntlId: string;
  /** Default display name if the intlId can't be resolved */
  nameDefault: string;
  /** Icon component */
  icon: ComponentType<{ size?: string }>;
  /** If selectedTab is undefined, this category is activated per default */
  activePerDefault?: boolean;
};

/** Type Specs for the horizontal line entry for the SideMenu */
export type HrType = {
  type: 'hr';
};

/** Type Specs for a "category" headline for the SideMenu */
export type HeadlineType = {
  type: 'headline';
  headline: MessageDescriptor;
};

export type MenuEntryType = LinkType | HrType | HeadlineType;

type Props = {
  /** List of entries for the menu */
  menuEntries: MenuEntryType[];
  /** Which of the tabs is selected? …*/
  selectedTab: string;
  /** Handle the selection of an entry */
  handleSelect: (selectedTab: string, query?: string) => void;
  collapsable?: boolean;
  /** Not optional if collapsable */
  isExpanded?: boolean;
  /** Not optional if collapsable */
  setExpanded?: (isExpanded: boolean) => void;
  preserveWhitelistQueryOnLink?: string[];
};

class SideMenu extends Component<Props & RouteComponentProps> {
  renderLink(entry: LinkType, index: number) {
    const {
      selectedTab,
      handleSelect,
      location,
      preserveWhitelistQueryOnLink,
    } = this.props;

    return (
      <TabLink
        key={index}
        className={
          'SideMenu-tab-link ' +
          'SideMenu-tab-link--color-' +
          (entry.color || 'black')
        }
        isActive={
          selectedTab === entry.id || (entry.activePerDefault && !selectedTab)
        }
        handleSelect={(selectedTab) =>
          handleSelect(
            selectedTab,
            preserveWhitelistQueryOnLink?.length > 0
              ? locationWithWhitelistQuery(
                  location,
                  preserveWhitelistQueryOnLink
                ).search
              : undefined
          )
        }
        to={entry.to}
      >
        <Link
          to={
            preserveWhitelistQueryOnLink?.length > 0
              ? toLinkWhitelistQuery(
                  entry.to,
                  location,
                  preserveWhitelistQueryOnLink
                )
              : entry.to
          }
          id={`tab-${entry.id}`}
        >
          <div
            className={'SideMenu-tab-icon'}
            data-testingIdentifier={`tab-${entry.id}`}
          >
            <entry.icon size={'17px'} />
          </div>
          <FormattedMessage
            id={entry.nameIntlId}
            defaultMessage={entry.nameDefault}
          />
        </Link>
      </TabLink>
    );
  }

  renderHr(index: number) {
    return <div key={index} className={'SideMenu-hr'} />;
  }

  renderHeadline(entry: HeadlineType, index: number) {
    return (
      <div key={index} className={'SideMenu-headline'}>
        <FormattedMessage
          id={entry.headline.id || 'no-id'}
          defaultMessage={entry.headline.defaultMessage}
        />
      </div>
    );
  }

  renderEntry(entry: MenuEntryType, index: number) {
    switch (entry.type) {
      case 'hr':
        return this.renderHr(index);
      case 'headline':
        return this.renderHeadline(entry, index);
      case 'link':
        return this.renderLink(entry, index);
    }
  }

  render() {
    const { menuEntries, collapsable, isExpanded, setExpanded } = this.props;
    return (
      <div className='SideMenu'>
        {menuEntries.map((entry, index: number) =>
          this.renderEntry(entry, index)
        )}
        {collapsable && (
          <BrowserCollapseFlap
            isExpanded={isExpanded}
            toggleBrowser={setExpanded}
          />
        )}
      </div>
    );
  }
}

export default withRouter(SideMenu);
