import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';
import { sendNotification } from './notifications.module';
import { event } from '../../core/notifications';

export const predictionStarted = createAction(
  'prediction started',
  (prediction) => prediction
);

export function* predictionStartedSaga({ payload: { augurCode, augurName } }) {
  yield put(
    sendNotification(
      'notifications.title.predication_was_started',
      'notifications.description.predication_was_started',
      event,
      { augurCode, augurName }
    )
  );
}

export function* watchPredictionStarted() {
  yield takeEvery(predictionStarted.getType(), predictionStartedSaga);
}
