import {
  fieldArchHabitatDatapool,
  fieldCodeCapsuleHabitat,
  fieldDescription,
  fieldKernel,
  fieldName,
  fieldType,
  RepositoryTypeIds,
} from './repository.form';

// do not allow .asr, otherwise all hell will break loose
const directoryRegex = /^[a-zA-Z0-9-]+$/;

export function validate(allValues, formProps) {
  const { contentInCurrentDir } = formProps;
  const errors = {};

  errors[fieldName] = validateRepositoryName(
    allValues[fieldName],
    contentInCurrentDir
  );
  errors[fieldDescription] = validateRepositoryDescription(
    allValues[fieldDescription]
  );
  errors[fieldType] = validateRepositoryType(allValues[fieldType]);
  errors[fieldCodeCapsuleHabitat] = validateRepositoryHabitat(
    allValues[fieldCodeCapsuleHabitat],
    allValues[fieldType]
  );
  errors[fieldKernel] = validateRepositoryKernel(
    allValues[fieldKernel],
    allValues[fieldType]
  );
  errors[fieldArchHabitatDatapool] = validateHabitatDatapool(
    allValues[fieldArchHabitatDatapool],
    allValues[fieldType]
  );

  return errors;
}

/**
 * Validates the repository name
 * 'undefined' = valid
 * @param value
 * @param contentInCurrentDir
 * @returns {{id: 'no-id', defaultMessage: string}|undefined}
 */
export function validateRepositoryName(value, contentInCurrentDir) {
  // No empty names
  if (!value || !value?.display) {
    return { display: { id: 'no-id', defaultMessage: 'Please enter a name' } };
  }
  // Specify the allowed characters
  if (!directoryRegex.test(value?.slug)) {
    return {
      slug: {
        id: 'no-id',
        defaultMessage: 'Please enter valid name / remove invalid characters',
      },
    };
  }

  if (contentInCurrentDir.map((c) => c.name).includes(value?.slug)) {
    return {
      slug: {
        id: 'no-id',
        defaultMessage:
          'Directory/Repository with the given name already exists in this directory.',
      },
    };
  }
  return undefined;
}

/**
 * Validates the repository description
 * 'undefined' = valid
 * @param value
 * @returns {{id: 'no-id', defaultMessage: string}|undefined}
 */
export function validateRepositoryDescription(value) {
  // No empty descriptions
  if (!value || value.trim().length === 0) {
    return { id: 'no-id', defaultMessage: 'Please enter a description' };
  }

  // Maximum length of 300 characters
  if (value && value.length > 300) {
    return {
      id: 'no-id',
      defaultMessage: 'At most 300 characters are allowed',
    };
  }

  return undefined;
}

/**
 * Validates the repository type
 * 'undefined' = valid
 * @param value
 * @returns {{id: string, defaultMessage: string}|undefined}
 */
export function validateRepositoryType(value) {
  if (!value) {
    return { id: 'no-id', defaultMessage: 'Please select a repository type' };
  }

  return undefined;
}

/**
 * Valides the selected habitat
 * 'undefined' = valid
 * @param value
 * @param selectedType
 */
export function validateRepositoryHabitat(value, selectedType) {
  if (selectedType !== RepositoryTypeIds.CODE_CAPSULE) return undefined; // Only relevant for code capsules

  if (!value) {
    return { id: 'no-id', defaultMessage: 'Please select a Habitat' };
  }

  return undefined;
}

/**
 * Valides the repository kernel
 * 'undefined' = valid
 * @param value
 * @param selectedType
 */
export function validateRepositoryKernel(value, selectedType) {
  if (selectedType !== RepositoryTypeIds.ARCHETYPE) return undefined; // Only relevant for archetypes

  if (!value) {
    return { id: 'no-id', defaultMessage: 'Please select a Kernel' };
  }

  return undefined;
}

/**
 * @param value
 * @param selectedType
 * @returns {{habitat: {id: string, defaultMessage: string}}|{datapool: {id: 'no-id', defaultMessage: string}}|undefined}
 */
export function validateHabitatDatapool(value, selectedType) {
  if (selectedType !== RepositoryTypeIds.ARCHETYPE) return undefined; // Only relevant for archetypes

  const { habitat, datapool } = value || {};
  let error = undefined;

  if (!habitat) {
    error = {
      ...(error || {}),
      habitat: { id: 'no-id', defaultMessage: 'Please select a Habitat' },
    };
  }

  if (!datapool) {
    error = {
      ...(error || {}),
      datapool: { id: 'no-id', defaultMessage: 'Please select a Datapool' },
    };
  }

  return error;
}
