import React, { FC, useEffect, useMemo, useState } from 'react';
import { FiCheck, FiEdit2, FiX } from 'react-icons/fi';
import {
  useAugur,
  useUpdateAugur,
} from '../../../../../../../../../core/api/augurs';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../../../../types';

const AugurNameAndCodeComponent: FC = () => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { data: augur } = useAugur(habitatCode, augurCode);
  const name = useMemo(() => augur?.name ?? '', [augur]);
  useEffect(() => setInputValue(name), [name]);

  const { mutate: updateAugur } = useUpdateAugur(habitatCode, augurCode);
  const saveName = () => {
    if (inputValue !== name) {
      updateAugur({ name: inputValue });
    }
    setEditMode(false);
  };

  const handleKeyDown = (e) => {
    if (editMode) {
      if (e.key === 'Enter') {
        saveName();
      } else if (e.key === 'Escape') {
        setEditMode(false);
        setInputValue(augur.name);
      }
    }
  };

  const getName = () => {
    if (!editMode) {
      return (
        <p>
          {name}
          {'  '}
          <FiEdit2
            className={'action-icon'}
            size={16}
            onClick={() => setEditMode(!editMode)}
          />
        </p>
      );
    }

    return (
      <div>
        <input
          type='text'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder='Enter Augur Name'
          autoFocus
        />
        <FiCheck
          className={'action-icon inline-icon-ok'}
          size={16}
          onClick={saveName}
        />
        <FiX
          className={'action-icon inline-icon-cancel'}
          size={16}
          onClick={() => {
            setInputValue(name);
            setEditMode(false);
          }}
        />
      </div>
    );
  };

  return (
    <div className='augur-information'>
      <div className='information-name' onKeyDown={handleKeyDown}>
        {getName()}
      </div>
      <p className='information-code'>{augurCode}</p>
    </div>
  );
};

export default AugurNameAndCodeComponent;
