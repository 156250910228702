import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FiGitBranch } from 'react-icons/fi';
import RepoType from '../../RepoType';
import { ContextMenuTrigger } from 'react-contextmenu';
import ContextMenuRepo from './ContextMenuRepo.container';
import { formatDate } from 'common/dist/utils/dates';

function menuId(path) {
  return `cm-collab-${(path || '').replace('/', '').toLowerCase()}`;
}

export default class ContentElement extends Component {
  render() {
    const {
      full_name,
      path,
      repoDescription,
      repoName,
      createdAt,
      repoType,
      createdBy,
      speakingOwner,
      name,
    } = this.props;

    const parts = (full_name || '').split('/');
    const group = parts[0];
    const pathName = parts[1];
    // Choose a menuId that works even if the extra repo information like path is missing
    const menuIdSafe = path !== undefined ? menuId(path) : menuId(full_name);

    return (
      <Fragment>
        <ContextMenuTrigger id={menuIdSafe} holdToDisplay={-1}>
          <div className={'repository'}>
            <div className={'repo-icon-parent'}>
              <FiGitBranch className={'content-element-icon'} size={'20px'} />
            </div>

            <div className={'repo-name-parent'}>
              <div className={'repo-name'}>
                <span className={'repo-group'}>{speakingOwner ?? group}</span> /{' '}
                <span className={'name'}>{name ?? pathName}</span>
              </div>
              <div className={'repo-description'}>
                {repoDescription ? (
                  <span>{repoDescription}</span>
                ) : (
                  <span>
                    <i>No description available</i>
                  </span>
                )}
              </div>
              <div className={'repo-created'}>
                <span>
                  Created at{' '}
                  {createdAt &&
                    formatDate(new Date(createdAt), 'dd.MM.yyyy HH:mm:ss')}
                  {createdBy &&
                    ` by ${createdBy.firstName} ${createdBy.lastName}`}{' '}
                </span>
              </div>
            </div>

            <div className={'repo-type-parent'}>
              <RepoType repoType={repoType} />
            </div>
          </div>
        </ContextMenuTrigger>

        <ContextMenuRepo
          menuId={menuIdSafe}
          repoName={repoName}
          repoFullName={full_name}
        />
      </Fragment>
    );
  }
}
ContentElement.propTypes = {
  /** Name of the element - Can be missing if the extra information besides git is not available */
  name: PropTypes.string,
  /** Path of the element - Can be missing if the extra information besides git is not available */
  path: PropTypes.string,
  /** Description of the repository - only set if type=repository */
  repoDescription: PropTypes.string.isRequired,
  /** Name of the repository - only set if type=repository */
  repoName: PropTypes.string.isRequired,
  /** Code of the repository - only set if type=repository */
  repoCode: PropTypes.string.isRequired,
  /** Type of the repository. Only set if type=repository. Values: plain / code-capsule / app / archetype */
  repoType: PropTypes.string,
  /** Creation timestamp */
  createdAt: PropTypes.string.isRequired,
  /** Full name of the repository - Always guaranteed to be there, as it comes directly from git */
  full_name: PropTypes.string.isRequired,
  createdBy: PropTypes.shape({
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    code: PropTypes.string,
  }),
  /** If available can replace the group */
  speakingOwner: PropTypes.string,
  currentUserId: PropTypes.string,
};
