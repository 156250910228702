import React, { FC } from 'react';
import InputElementSelectModal from './InputElementSelectModal';
import InputVariables from './InputVariables';
import InputElements from './InputElements';
import { CellVariableType } from '../../../../../../../../../workbench/types';
import P3InputElement from './elements/_interface/P3InputElement';
import {
  AppInputCell,
  Session,
} from '../../../../../../../../../store/workbench/state.types';

type Props = {
  /** The cell to display */
  cell: AppInputCell;
  /** Path of the notebook */
  path: string;
  /** List of the selected cell IDs*/
  selectedCells: string[];
  /** Specs fo the add input element modal */
  addInputElementModal?: {
    active: boolean;
    path?: string;
    cellId: string;
  };
  /** Session of the notebook */
  session: Session;

  /** Select a list of cells */
  selectCells: (cellIds: string[]) => void;
  /** Add an input element */
  addInputElement: (
    path: string,
    cellId: string,
    element: P3InputElement,
    elementId: string
  ) => void;
  /** Add an input variable */
  addVariable: (path: string, cellId: string) => void;
  /** Execute all input variables of an app */
  executeVariables: (
    path: string,
    cellId: string,
    variables: CellVariableType[],
    sessionId: string,
    parentType: 'notebook'
  ) => void;
  /** Open or close the input elements */
  toggleInputElements: (
    path: string,
    cellId: string,
    elementsExpanded: boolean
  ) => void;
  /** Open or close the input variables */
  toggleInputVariables: (
    path: string,
    cellId: string,
    variablesExpanded: boolean
  ) => void;
  /** Show the add input element dialog */
  showAddInputElement: (path: string, cellId: string) => void;
  /** Hide the add input element dialog */
  hideAddInputElement: () => void;
  /** Show the edit input element dialog */
  showEditInputElement: (
    path: string,
    cellId: string,
    element: P3InputElement,
    as_variables: CellVariableType[]
  ) => void;
};

const Python3InputCell: FC<Props> = (props) => {
  const {
    path,
    cell,
    addInputElement,
    addVariable,
    executeVariables,
    toggleInputVariables,
    toggleInputElements,
    addInputElementModal,
    showAddInputElement,
    hideAddInputElement,
    showEditInputElement,
    session,
  } = props;

  return (
    <div className={'editor-parent-container'}>
      <div className={'editor-container app-cell-container'}>
        <InputVariables
          cell={cell}
          path={path}
          addVariable={addVariable}
          executeVariables={executeVariables}
          toggleInputVariables={toggleInputVariables}
          session={session}
        />
        <hr />
        <InputElements
          cell={cell}
          path={path}
          handleOpenDialogModal={() => showAddInputElement(path, cell.id)}
          toggleInputElements={toggleInputElements}
        />
      </div>

      <InputElementSelectModal
        isOpen={addInputElementModal.active}
        closeDialog={hideAddInputElement}
        path={addInputElementModal.path}
        cellId={addInputElementModal.cellId}
        addInputElement={addInputElement}
        toggleInputElements={toggleInputElements}
        showEditInputElement={(e) =>
          showEditInputElement(path, cell.id, e, cell.as_variables)
        }
      />
    </div>
  );
};

export default Python3InputCell;
