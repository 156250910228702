export const formName = 'rename-object';

export const fieldName = 'name';

export const form = {
  form: formName,
  validate,
};

const nameRegex = /^[a-zA-Z_0-9-]+$/;

export function validate(values) {
  const errors = {};

  // --- Validate the Label
  const nameField = values[fieldName];
  const name = nameField ? nameField.inputValue : '';
  if (name) {
    if (name.length < 1) {
      errors[fieldName] = 'The name must not be empty';
    }
    if (name.match(nameRegex)) {
      errors[fieldName] = 'Enter a valid name';
    }
  }
  return errors;
}
