import React, { Component } from 'react';
import {
  MergeRequest,
  MergeRequestOrdering,
  mergeRequestOrderings,
  Props,
} from './MergeRequests.container';
import SortableSearchableList from '../../../organisms/sortable-searchable-list/SortableSearchableList.container';
import MergeRequestListItem, {
  Props as ItemProps,
} from './MergeRequestListItem';
import { collaborationSpaceRoutes } from '../../routes';
import RepositoryHeader from '../overview/RepositoryHeader.container';

const sortData = (data, ordering) => {
  if (!data) return [];
  switch (ordering) {
    case MergeRequestOrdering.LastCreated:
      return data.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
    case MergeRequestOrdering.OldestCreated:
      return data.sort((a, b) => (a.created_at < b.created_at ? -1 : 1));
    case MergeRequestOrdering.NameAsc:
      return data.sort((a, b) => (a.title < b.title ? -1 : 1));
    case MergeRequestOrdering.NameDesc:
      return data.sort((a, b) => (a.title > b.title ? -1 : 1));
    case MergeRequestOrdering.CreatedByAsc:
      return data.sort((a, b) =>
        a.user.full_name < b.user.full_name ? -1 : 1
      );
    case MergeRequestOrdering.CreatedByDesc:
      return data.sort((a, b) =>
        a.user.full_name > b.user.full_name ? -1 : 1
      );
  }
  return data;
};

export default class MergeRequests extends Component<Props> {
  componentDidMount() {
    const { loadMergeRequests, group, repositoryName } = this.props;

    // Fetch the merge requests to display
    // TODO This should be wrapped by a paging component instead of limiting hard-coded to the latest 20 MRs
    // @ts-ignore
    loadMergeRequests(group, repositoryName, 'open', 1, 20);
  }

  render() {
    const { mergeRequests, group, repositoryName } = this.props;
    const List = SortableSearchableList<
      MergeRequest,
      MergeRequestOrdering,
      ItemProps
    >();

    return (
      <div className={'CollaborationSpace--content'}>
        <div className={'repository branches'}>
          <RepositoryHeader />

          <List
            orderings={mergeRequestOrderings}
            defaultOrdering={MergeRequestOrdering.LastCreated}
            data={mergeRequests}
            contentProps={(mr, i) => ({
              mr,
              group,
              repositoryName,
              key: mr.id,
            })}
            ContentComponent={MergeRequestListItem}
            search={(mr, searchString) => mr.title.includes(searchString)}
            sort={sortData}
            title={'Merge Requests'}
            button={{
              buttonText: 'Open Merge Request',
              link: `${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}/${collaborationSpaceRoutes.repositories.merge}`,
            }}
            style={{
              marginTop: '20px',
            }}
          />
        </div>
      </div>
    );
  }
}
