import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FiGitBranch, FiGitMerge } from 'react-icons/fi';
import { BsDot } from 'react-icons/all';
import { collaborationSpaceRoutes } from '../../routes';
import { Link } from 'react-router-dom';
import { formatDate } from 'common/dist/utils/dates';

function calcTime(date) {
  const diff = (Date.now() - date) / 1000;
  if (diff < 60) {
    if (Math.floor(diff) === 1) return diff + ' second ago';
    else return Math.floor(diff) + ' seconds ago';
  } else if (diff < 60 * 60) {
    const mins = Math.floor(diff / 60);
    if (mins === 1) return mins + ' minute ago';
    else return mins + ' minutes ago';
  } else if (diff < 60 * 60 * 24) {
    const hour = Math.floor(diff / 60 / 60);
    if (hour === 1) return hour + ' hour ago';
    else return hour + ' hours ago';
  } else if (diff < 60 * 60 * 24 * 7) {
    const days = Math.floor(diff / 60 / 60 / 24);
    if (days === 1) return days + ' day ago';
    else return days + ' days ago';
  } else {
    const weeks = Math.floor(diff / 60 / 60 / 24 / 7);
    if (weeks === 1) return weeks + ' week ago';
    else return weeks + ' weeks ago';
  }
}

export default class ContentElement extends Component {
  render() {
    const { name, switchBranch, commit, group, repositoryName } = this.props;

    return (
      <Fragment>
        <div className={'branch'} onClick={() => switchBranch(name)}>
          <div className={'branch-icon-parent'}>
            <FiGitBranch className={'content-element-icon'} size={'20px'} />
          </div>

          <div className={'branch-name-parent'}>
            <div className={'branch-name'}>
              <span className={'name'}>{name}</span>
            </div>
            <div className={'branch-commit'}>
              <span className={'commit-details'}>
                {commit ? (
                  commit.message
                ) : (
                  <i>"There are no commits on this branch yet."</i>
                )}
              </span>
            </div>
            <div className={'branch-details'}>
              <span>{commit ? commit.author.name : ''}</span>
              <span>
                <BsDot />
              </span>
              <span
                title={
                  commit
                    ? formatDate(
                        new Date(commit.timestamp),
                        'dd.MM.yyyy HH:mm:ss'
                      )
                    : ''
                }
              >
                {commit ? calcTime(new Date(commit.timestamp)) : ''}
              </span>
            </div>
          </div>

          <div className={'element-branch-buttons'}>
            <Link
              to={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}/${collaborationSpaceRoutes.repositories.merge}?sourceBranch=${name}`}
            >
              <div className={'element-branch-button'}>
                <FiGitMerge className={'icon'} size={'20px'} />
              </div>
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }
}
ContentElement.propTypes = {
  /** Name of the element */
  name: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  repositoryName: PropTypes.string.isRequired,
  switchBranch: PropTypes.func.isRequired,
  commit: PropTypes.arrayOf(PropTypes.object).isRequired,
};
