import { AdminMaintenanceState } from './state.types';

export const initial: AdminMaintenanceState = {
  enableMaintenance: {
    submitting: false,
  },
};

export const test: AdminMaintenanceState = {
  enableMaintenance: {
    submitting: false,
  },
};
