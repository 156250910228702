import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CodeCapsuleDetails from './CodeCapsuleDetails';
import { fetchSingleCodeCapsule } from '../../redux/modules/codeCapsules.module';

export function mapStateToProps(state, { match }) {
  const codeCapsuleCode = match.params.codeCapsuleCode;
  const habitatCode = match.params.habitatCode;
  return {
    codeCapsuleCode,
    habitatCode,
  };
}

export const mapDispatchToProps = {
  fetchSingleCodeCapsule,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CodeCapsuleDetails)
);
