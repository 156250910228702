import React, { Component } from 'react';
import MainContainer from '../pages/main-container/MainContainer';
import { InjectedFormProps } from 'redux-form';
import styles from './styles.module.scss';
import MultiPageWizard from '../organisms/multi-page-wizard/MultiPageWizard.container';
import { fieldHabitatName, form } from './newHabitat.form';
import * as routes from '../index/routes';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import WizardPage from '../organisms/multi-page-wizard/WizardPage';
import WizardStep from '../organisms/multi-page-wizard/WizardStep';
import { mpwAsyncValidate } from './NewHabitatWizard.container';
import { WrappedTextInputLine } from '../atoms/input-elements/text-input-line/TextInputLine';
import ConfirmationModal from '../organisms/confirmation-modal/ConfirmationModal';
import { ReduxActUnknown2 } from '../../store/reduxAct';
import messagesForm from 'common/dist/messages/form';

export type Props = {
  /** Is the new habitat currently being submitted? */
  submitting: boolean;
  /** Action to add the new habitat */
  addHabitat: (habitatName: string) => void;
  /** Action to update the new habitat */
  updateHabitat: (habitatName: string, habitatCode: string) => void;
  /** Entered name for the habitat */
  habitatName: string;
  habitatCode: string;
  update: boolean;
  deleteHabitatConfirm;
  hideDeleteHabitatConfirm: () => void;
  deleteHabitat: (habitatName: string, habitatCode: string) => void;
  showDeleteHabitatConfirm: () => void;
  allowDelete: boolean;
};

class NewHabitatWizard extends Component<
  Props & InjectedFormProps & RouteComponentProps
> {
  render() {
    const {
      submitting,
      addHabitat,
      history,
      habitatName,
      habitatCode,
      updateHabitat,
      update,
      deleteHabitatConfirm,
      hideDeleteHabitatConfirm,
      deleteHabitat,
      allowDelete,
      showDeleteHabitatConfirm,
    } = this.props;
    return (
      <MainContainer fullWidth scrollableY={false}>
        <div className={styles.newHabitat}>
          {/* @ts-ignore */}
          <MultiPageWizard
            {...form}
            isSubmitting={submitting}
            initialValues={
              update ? { [fieldHabitatName]: habitatName } : undefined
            }
            wizardHeadline={{
              id: `${update ? 'updateHabitat.header' : 'newHabitat.header'}`,
              defaultMessage: `${update ? 'Update' : 'Add'} Habitat`,
            }}
            // @ts-ignore
            asyncValidate={mpwAsyncValidate}
            asyncBlurField={[fieldHabitatName]}
            asyncChangeField={[]}
            onSubmit={(values) => {
              update
                ? updateHabitat(values[fieldHabitatName], habitatCode)
                : addHabitat(values[fieldHabitatName]);
              history.push(routes.app.prefix + routes.app.models);
            }}
            onCancel={() => {
              history.push(routes.app.prefix + routes.app.models);
            }}
            deleteButtonLabel={{
              id: `delete.button`,
              defaultMessage: `Delete Habitat`,
            }}
            deleteButtonLabelIsVisible={allowDelete}
            onDelete={() => {
              {
                return (
                  showDeleteHabitatConfirm as unknown as ReduxActUnknown2
                )(habitatName, habitatCode);
              }
            }}
          >
            {/* @ts-ignore */}
            <WizardPage
              pageTitle={{ id: 'habitat', defaultMessage: 'Habitat Name' }}
            >
              <WizardStep
                title={{
                  id: 'newHabitat.step1.add_name_for_habitat',
                  defaultMessage: 'Add a Name for the Habitat',
                }}
                description={{
                  id: 'newHabitat.step1.description_one',
                  defaultMessage:
                    'A habitat is where your Augurs and Datapools live. You cannot delete the Habitat after you have activated it.',
                }}
                stepNumber={1}
                fieldName={fieldHabitatName}
              >
                {/* @ts-ignore */}
                <WrappedTextInputLine
                  hasLabel
                  labelDefault={'Habitat Name'}
                  labelId={'habitatName'}
                  placeholderId={messagesForm.placeholderName.id}
                  placeholderDefault={
                    messagesForm.placeholderName.defaultMessage
                  }
                />
              </WizardStep>
              <ConfirmationModal
                secure={true}
                show={deleteHabitatConfirm?.show}
                secureInput={deleteHabitatConfirm?.habitatName}
                payload={{
                  habitatCode: deleteHabitatConfirm?.habitatCode,
                  habitatName: deleteHabitatConfirm?.habitatName,
                }}
                buttonConfirmAction={({ code }) => {
                  deleteHabitat(
                    deleteHabitatConfirm?.habitatName,
                    deleteHabitatConfirm?.habitatCode
                  );
                  history.push(routes.app.prefix + routes.app.models);
                }}
                hideModal={hideDeleteHabitatConfirm}
                buttonConfirmText={{
                  id: 'newHabitat.delete_habitat_button',
                  defaultMessage: 'Delete',
                }}
                buttonConfirmColor={'red'}
                description={{
                  id: 'newHabitat.delete_habitat_description',
                  defaultMessage:
                    'You are going to delete the habitat {nameBold}. Please type in {nameCode} and confirm if you are sure.',
                  values: {
                    nameBold: <b>{deleteHabitatConfirm?.habitatName}</b>,
                    nameCode: <code>{deleteHabitatConfirm?.habitatName}</code>,
                  },
                }}
                headline={{
                  id: 'newHabitat.delete_habitat',
                  defaultMessage: 'Delete Habitat',
                }}
                headlineColor={'red'}
              />
            </WizardPage>
          </MultiPageWizard>
        </div>
      </MainContainer>
    );
  }
}

export default withRouter(NewHabitatWizard);
