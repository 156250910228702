import React, { FC } from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { HistoryItemType } from '../../../../store/account/state.types';

const Yes = (
  <strong className='text-green'>
    <FormattedMessage id='common.yes' defaultMessage='Yes' />
  </strong>
);

const No = (
  <strong className='text-red'>
    <FormattedMessage id='common.no' defaultMessage='No' />
  </strong>
);

type Props = {
  /** Single entry of the Login history */
  item: HistoryItemType;
};

const LoginHistoryItem: FC<Props> = (props) => {
  const {
    item: { time, ipAddress, type },
  }: Props = props;

  const SUCCESS_EVENTS = ['LOGIN', 'LOGOUT'];
  const success = SUCCESS_EVENTS.includes(type);

  return (
    <tr>
      <td>
        <FormattedDate value={time} />
        &nbsp;
        <FormattedTime value={time} />
      </td>
      <td>{type}</td>
      <td>{success ? Yes : No}</td>
      <td>{ipAddress}</td>
    </tr>
  );
};

export default LoginHistoryItem;
