import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import User from './User';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import Paging, { PagingProps } from '../../../molecules/paging/Paging';
import adminMsgs from 'common/dist/messages/admin';
import { useUsersList } from '../../../../core/api/admin';
import Button from '../../../atoms/button/Button';
import styles from '../styles.module.scss';

export const adminAddUserLink = `${usersRoutes.basePath}/${routes.app.admin.addUser}`;
const PAGE_SIZE = 20;

export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  account: {
    name: string;
  };
  email: string;
  attributes?: {
    isTechnical: string[];
  };
  realmRoles: { name: string }[];
};

const Inner: FC<PagingProps> = ({ offset, search }) => {
  let { data: users } = useUsersList(offset, PAGE_SIZE, search);
  users = users ?? [];

  return (
    <div className={styles.listContainer}>
      <div className={'ct-list'}>
        <div className={'ct-headline ct-row'}>
          <div className={'ct-col ct-col-200px'}>
            <FormattedMessage {...adminMsgs.msgUsersFirstName} />
          </div>
          <div className={'ct-col ct-col-200px'}>
            <FormattedMessage {...adminMsgs.msgUsersLastName} />
          </div>
          <div className={'ct-col ct-col-200px'}>
            <FormattedMessage {...adminMsgs.msgUsersEmail} />
          </div>
          <div className={'ct-col ct-col-120px ct-col-admin'}>
            <FormattedMessage {...adminMsgs.msgUsersIsAdmin} />
          </div>
        </div>
        {users && users.map((user) => <User {...user} key={user.id} />)}
      </div>
    </div>
  );
};

export const Header = (
  currentPage: number,
  offset: number,
  limit: number,
  totalItemsOrCurrentItems: number
) => (
  <span>
    <FormattedMessage
      {...adminMsgs.msgUsersPagingHeader}
      values={{ from: offset + 1, to: offset + totalItemsOrCurrentItems }}
    />
  </span>
);

// auxiliary hook to provide Paging component with list of data (e.g. for number of items)
const useData = (offset, search) =>
  useUsersList(offset, PAGE_SIZE, search).data ?? [];

const UsersList: FC = () => {
  const intl = useIntl();

  return (
    <div>
      <div className={styles.topButtonBar}>
        <Button
          buttonColor={'secondary'}
          withLink={true}
          buttonLabelDefault={intl.formatMessage(adminMsgs.msgAddUser)}
          linkTo={adminAddUserLink}
        />
      </div>
      <Paging
        itemsPerPage={PAGE_SIZE}
        Headline={Header}
        searchEnabled
        useData={useData}
        InnerComponent={Inner}
      />
    </div>
  );
};

export default UsersList;
