import React, { FC } from 'react';
import '../styles_common.scss';
import Convert from 'ansi-to-html';
import { Stream } from 'common/dist/utils/workbench/jupyterNotebookFormat';

export type Props = {
  output: {
    name: Stream['name'];
    text: Stream['text'];
  };
};

const convert = new Convert({ escapeXML: true });

const StreamOutput: FC<Props> = (props: Props) => {
  const { output } = props;

  const htmlOutput = convert.toHtml(output.text as string);

  return (
    <div className={'CodeCellOutputs'}>
      <div
        className={
          'CodeCellOutputs--container CodeCellOutputs--container-stream'
        }
        dangerouslySetInnerHTML={{ __html: htmlOutput }}
      />
    </div>
  );
};

export default StreamOutput;
