import { connect } from 'react-redux';
import {
  checkWhetherNotebookIsRunning,
  hideShutdownWorkbenchModal,
} from '../../../../redux/workbench/modules/connection.module';
import { notebookUser } from '../../../../redux/workbench/selectors/notebookUser.selector';
import { RootState } from '../../../../store/store';
import ShutdownWorkbenchModal from './ShutdownWorkbenchModal';
import { stopWorkbench } from '../../../../store/admin/resources/actions';

const mapStateToProps = (state: RootState) => ({
  currentUserId: notebookUser(state),
  shutdownWorkbenchModalVisible: state.workbench.shutdownWorkbenchModalVisible,
});

export const mapDispatchToProps = {
  hideShutdownWorkbenchModal,
  stopWorkbench,
  checkWhetherNotebookIsRunning,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShutdownWorkbenchModal);
