import { connect, ConnectedProps } from 'react-redux';
import ModelTuningResults from './ModelTuningResults';
import { fetchArchetypeVersions } from '../../../../../../redux/modules/archetypeVersions.module';

export const mapDispatchToProps = {
  fetchArchetypeVersions,
};

const connector = connect(null, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(ModelTuningResults);
