import { patchApiRequest, postApiRequest } from './_apiRequests';

export function createDirectory(jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/`;
  const body = {
    type: 'directory',
  };
  return postApiRequest(url, body);
}

export function renameDirectory(oldPath, newPath, jupyterUser) {
  const url = `/jupyter/user/${jupyterUser}/api/contents/${encodeURIComponent(
    oldPath
  )}`;
  const body = {
    path: newPath,
  };
  return patchApiRequest(url, body);
}
