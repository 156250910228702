import { connect } from 'react-redux';
import { DeprecatedRootState } from '../../../../../../store/state.type';
import MergeRequestResolve from './MergeRequestResolve';
// @ts-ignore
import {
  mergerCreateMergeRequest,
  toggleCommitModal,
} from '../../../../../../redux/workbench/modules/merger.module';

export function mapStateToProps(state: DeprecatedRootState) {
  // --- Merge Request Details (from Gitea)
  const mergeRequest = state.workbench.collab?.mergeRequest || {};
  const {
    // @ts-ignore
    loading: mrLoading,
    // @ts-ignore
    loaded: mrLoaded,
    // @ts-ignore
    error: mrError,
    // @ts-ignore
    data: mrData,
  } = mergeRequest;

  // --- Merge Request Details (from the Merger API)
  const mergerMergeRequest = (state.workbench.mergerAPI || {}).merge || {};
  const {
    // @ts-ignore
    loading: mergerMrLoading,
    // @ts-ignore
    loaded: mergerMrLoaded,
    // @ts-ignore
    error: mergerMrError,
    // @ts-ignore
    data: mergerMrData,
  } = mergerMergeRequest;

  // --- Conflicted files (fetched with fetchMergeRequestStatus)
  const mergerApiStatus = (state.workbench.mergerAPI || {}).status || {};
  const {
    // @ts-ignore
    loading: statusLoading,
    // @ts-ignore
    loaded: statusLoaded,
    // @ts-ignore
    error: statusError,
    // @ts-ignore
    data: statusData,
  } = mergerApiStatus;

  return {
    // --- Merge Request Details (from Gitea)
    mrLoading,
    mrLoaded,
    mrError,
    mrData,

    // --- Merge Request Details (from the Merger API)
    mergerMrLoading,
    mergerMrLoaded,
    mergerMrError,
    mergerMrData,

    // --- Merge Request (resolve) Status (from the Merger API)
    statusLoading,
    statusLoaded,
    statusError,
    statusData,
  };
}

export const mapDispatchToProps = {
  mergerCreateMergeRequest,
  toggleCommitModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MergeRequestResolve);
