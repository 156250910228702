import { connect } from 'react-redux';

import MisclassificationChart from './MisclassificationChart';

export function mapStateToProps(state, { width, height, top, left }) {
  return {
    width,
    height,
    top,
    left,
  };
}

export default connect(mapStateToProps)(MisclassificationChart);
