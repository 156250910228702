import React, { Component, Fragment } from 'react';
import { TabContent } from 'react-tabs-redux';
import PropTypes from 'prop-types';
import Content from './content/Content.container';
import Sessions from './sessions/Sessions.container';
import RecycleBin from './recycleBin/RecycleBin.container';

export default class BrowserTabContents extends Component {
  render() {
    return (
      <Fragment>
        <TabContent
          className={'browser-tab-content'}
          for={'content'}
          isVisible={this.props.selectedBrowser === 'content'}
          renderActiveTabContentOnly
          key={'content'}
        >
          <Content />
        </TabContent>
        <TabContent
          className={'browser-tab-content'}
          for={'recycleBin'}
          isVisible={this.props.selectedBrowser === 'recycleBin'}
          renderActiveTabContentOnly
          key={'recycleBin'}
        >
          <RecycleBin />
        </TabContent>
        <TabContent
          className={'browser-tab-content'}
          for={'sessions'}
          isVisible={this.props.selectedBrowser === 'sessions'}
          renderActiveTabContentOnly
          key={'sessions'}
        >
          <Sessions />
        </TabContent>
      </Fragment>
    );
  }
}

BrowserTabContents.propTypes = {
  selectedBrowser: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
};
