import { createAction } from 'redux-act';
import { put, call, takeLatest, delay } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const checkAugurNameExists = createAction(
  'check whether augur with that name exists',
  (habitatCode, augurName, callbacks) => ({ habitatCode, augurName, callbacks })
);

export const checkAugurNameExistsSuccess = createAction(
  'check whether augur with that name exists - success',
  (result) => result
);

export const checkAugurNameExistsFail = createAction(
  'check whether augur with that name exists - fail',
  (fail) => fail
);

export const reducer = {
  [checkAugurNameExists]: (state, { habitatCode, augurName }) => ({
    ...state,
    newAugur: {
      ...state.newAugur,
      augurName: {
        habitatCode,
        augurName,
        checking: true,
      },
    },
  }),
  [checkAugurNameExistsSuccess]: (state, { exists }) => ({
    ...state,
    newAugur: {
      ...state.newAugur,
      augurName: {
        ...state.newAugur.augurName,
        checking: false,
        checked: true,
        exists,
      },
    },
  }),
  [checkAugurNameExistsFail]: (state, error) => ({
    ...state,
    newAugur: {
      ...state.newAugur,
      augurName: {
        ...state.newAugur.augurName,
        checking: false,
        checked: false,
        error,
      },
    },
  }),
};

export function* checkAugurNameExistsSaga({
  payload: {
    habitatCode,
    augurName,
    callbacks: { resolve, reject },
  },
}) {
  yield delay(500);
  if (!augurName) {
    reject({ augurName: { id: 'newAugur.stepOne.error.missing_augur_name' } });
    return;
  }

  const { response, error } = yield call(
    Api.augurs.checkAugurNameExists,
    habitatCode,
    augurName
  );
  if (response) {
    yield put(checkAugurNameExistsSuccess(response));
    resolve(response);
  } else {
    yield put(checkAugurNameExistsFail(error));
    reject(error);
  }
}

export function* watchCheckAugurNameExists() {
  yield takeLatest(checkAugurNameExists.getType(), checkAugurNameExistsSaga);
}
