import { createAction } from 'redux-act';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { notebookUser } from '../selectors/notebookUser.selector';
import * as NotebookApi from '../../../core/api/workbench/notebook';
import * as ContentApi from '../../../core/api/workbench/content';
import { fetchContent } from './content.module';
import { error as errorNotification } from 'react-notification-system-redux';
import { notificationCreateFileFail } from '../notifications/notifications';

export const addFile = createAction('add file', (fileName) => ({ fileName }));

export const addFileSuccess = createAction(
  'add file - success',
  (success) => success
);

export const addFileFail = createAction('add file - fail', (error) => error);

export const addFileRequest = createAction('add file - request');

export const reducer = {
  [addFileRequest]: (state) => ({
    ...state,
    isCreatingFile: true,
  }),
  [addFileSuccess]: (state) => ({
    ...state,
    isCreatingFile: false,
  }),
  [addFileFail]: (state) => ({
    ...state,
    isCreatingFile: false,
  }),
};

export function* addFileSaga({ payload: { fileName } }) {
  const filePath = yield select(
    (state) => `${state.workbench.content.selectedDirPath.slice(1).join('/')}/`
  );
  yield put(addFileRequest());
  const { response, error } = yield call(addFileCalls, fileName, filePath);
  if (response) {
    const path = response.path;
    yield put(addFileSuccess(response));
    const selectedDirPath = yield select(
      (state) => state.workbench.content.selectedDirPath
    );
    yield put(fetchContent(selectedDirPath));
  } else {
    yield put(addFileFail(error));
    yield put(errorNotification(notificationCreateFileFail()));
  }
}

export function* watchAddFile() {
  yield takeLatest(addFile().type, addFileSaga);
}

/**
 * The chain of function calls required to add a new file (not necessarily a notebook)
 *
 * @param fileName
 * @param filePath
 * @param content
 * @returns {Generator<*, void, ?>}
 */
export function* addFileCalls(
  fileName,
  filePath,
  content = defaultContent(fileName, filePath)
) {
  const jupyterUser = yield select((state) => notebookUser(state));

  return yield NotebookApi.createFile(jupyterUser, 'file').then(
    (response, error) => {
      if (response) {
        const name = response.response.name; // This is strange ...
        const newPathRaw = filePath + fileName;
        const newPath = newPathRaw.startsWith('/')
          ? newPathRaw.slice(1)
          : newPathRaw;
        return ContentApi.renameContent(name, newPath, jupyterUser).then(
          (response, error) => {
            if (response) {
              return NotebookApi.initialSaveFile(newPath, content, jupyterUser);
            }
            return { response: null, error };
          }
        );
      }
      return { response: null, error };
    }
  );
}

const defaultContent = (name, path) => ({
  name,
  path: `${path}/${name}`,
  content: '',
  mimetype: 'text/plain',
  writable: true,
  type: 'file',
  format: 'text',
});
