import { connect } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import CommunicationGroups from './CommunicationGroups';
import { updateDatapool } from '../../../../../../redux/modules/datapool.details.module';
import { fieldCommunicationGroups } from './form';

export function mapStateToProps(state: RootState) {
  const habitatCode = state.datapoolDetails?.datapool?.habitatCode;
  const datapoolCode = state.datapoolDetails?.datapool?.code;

  return {
    initialValues: {
      [fieldCommunicationGroups]:
        state.datapoolDetails?.datapool?.meta?.communicationGroups || [],
    },
    habitatCode,
    datapoolCode,
    communications: state.datapoolDetails?.datapool?.meta?.communications || [],
  };
}

export const mapDispatchToProps = {
  updateDatapool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationGroups);
