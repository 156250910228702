import { connect } from 'react-redux';

import Datapools from './Datapools';
import * as dashboardSelector from '../../../../redux/selectors/dashboard.selector';
import { RootState } from '../../../../store/store';

type OwnProps = {
  habitatCode: string;
};

export function mapStateToProps(state: RootState, { habitatCode }: OwnProps) {
  const datapools =
    state.dashboard.habitats[habitatCode]?.datapools?.data || [];
  const isListView = dashboardSelector.isListView(state, habitatCode);

  return {
    habitatCode,
    datapools,
    isListView,
  };
}

export default connect(mapStateToProps)(Datapools);
