import { connect, ConnectedProps } from 'react-redux';
import BucketBrowser, { Props } from './BucketBrowser';
import {
  deleteObject,
  fetchBucketContent,
  fetchS3Permissions,
} from '../../../../redux/modules/data.s3.module';
import { withRouter } from 'react-router-dom';
import { setActiveUploadWizard } from '../../../../redux/modules/data.cassandra.module';
import { S3_BUCKET_SCOPE_IDS } from 'common/dist/constants/keycloak';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/store';

export function mapStateToProps(
  state: RootState,
  {
    dataSourceCode,
    bucket: bucketOverride,
    bucketPath: bucketPathOverride,
    match,
  }: Props & RouteComponentProps<{ bucket: string }>
) {
  const bucketPath = bucketPathOverride ?? match.params['0'];
  const bucket = bucketOverride ?? (match.params.bucket || '');
  // state.data.s3[dataSourceCode].bucketContent[bucket][bucketPath]
  const { loading, loaded, error, data } =
    state.data.s3[dataSourceCode]?.bucketContent?.[bucket]?.[bucketPath] || {};
  const permissions = state.data.s3[dataSourceCode]?.permissions;
  const allowedToRead = new Set(
    bucket?.length > 0
      ? permissions?.data?.[bucket]
      : Object.values(permissions?.data || {}).flat()
  ).has(S3_BUCKET_SCOPE_IDS.READ);
  const allowedToWrite = new Set(
    bucket?.length > 0
      ? permissions?.data?.[bucket]
      : Object.values(permissions?.data || {}).flat()
  ).has(S3_BUCKET_SCOPE_IDS.WRITE);
  return {
    bucket,
    bucketPath,
    loading,
    loaded,
    error,
    data,
    allowedToRead,
    allowedToWrite,
  };
}

export const mapDispatchToProps: {
  /** Fetch the content of a bucket at the given directory */
  fetchBucketContent: (
    dataSourceCode: string,
    bucket: string,
    bucketPath: string
  ) => void;
  fetchS3Permissions: (dataSourceCode: string) => void;
  setActiveUploadWizard: (activeWizard: string, uploadCode: string) => void;
  deleteObject: (bucket: string, path: string, dataSourcCode: string) => void;
} = {
  fetchBucketContent,
  fetchS3Permissions,
  setActiveUploadWizard, // TODO? Uses the one from the cassandra upload
  deleteObject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(BucketBrowser));
