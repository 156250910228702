import { actionTypes } from 'redux-form';
import moment from 'moment';
import { getRandomInt } from 'common/dist/utils/random';
import { schedulePeriod } from 'common/dist/constants/enums';
import { timeFormat } from 'common/dist/constants/constants';

function getRandomTime() {
  return moment(`${getRandomInt(0, 23)}:${getRandomInt(0, 59)}`, 'H:m').format(
    timeFormat
  );
}

export function reducer(state, action) {
  if (!state) {
    return state;
  }

  switch (action.type) {
    case actionTypes.CHANGE:
      if (
        ~['augurSettings', 'newAugur2'].indexOf(action.meta.form) &&
        state.values
      ) {
        if (action.meta.field === 'predictionSchedule.mode') {
          return {
            ...state,
            values: {
              ...state.values,
              predictionSchedule: {
                mode: state.values.predictionSchedule.mode,
              },
            },
          };
        }

        if (action.meta.field === 'predictionSchedule.period') {
          const predictionSchedule = { ...state.values.predictionSchedule };

          if (action.payload === schedulePeriod.HOURLY) {
            predictionSchedule.periodValue = getRandomInt(0, 59);
          } else {
            predictionSchedule.time = getRandomTime();

            if (action.payload === schedulePeriod.WEEKLY) {
              predictionSchedule.periodValue = getRandomInt(0, 6);
            } else if (action.payload === schedulePeriod.MONTHLY) {
              predictionSchedule.periodValue = getRandomInt(0, 29);
            }
          }

          return {
            ...state,
            values: {
              ...state.values,
              predictionSchedule,
            },
          };
        }
      }

      return state;
    default:
      return state;
  }
}
