import React, { Fragment, ReactElement } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import CSS from 'csstype';
import { Link } from 'react-router-dom';
import { FiMinus } from 'react-icons/fi';
import { injectIntl, WrappedComponentProps } from 'react-intl';

export type RenderColumn<T, K extends keyof T> = {
  key: K;
  renderHeader?: () => ReactElement;
  renderCell: (data: T[K], row: T) => ReactElement;
  width?: CSS.Property.Width;
};

export type Props<T> = {
  data: T[];
  renderColumns: RenderColumn<T, keyof T>[];
  showHeader: boolean;
  fixedLayout?: boolean;
  striped?: boolean;
  borderAround?: boolean;
  roundedBorder?: boolean;
  verticalAlignMiddle?: boolean;
  addlTableClassName?: string;
  /** Optional mapping of row index => class name for the row */
  addlRowClassname?: (rowIndex: number, row: T) => string;
  /** Optional mapping of row index => link to for this row */
  rowLinkTo?: (rowIndex: number) => string | undefined;

  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: T) => void;
  /** Object key of the row elements that is undefined if removing is allowed for this row. If removing is supposed to
   * be disabled for this row, it's set to a message describing why removing is not allowed */
  keyRemoveDisabledMessage?: keyof T;
  /** Callback for when a row is clicked */
  renderClickedRow?: (element: T, rowIndex: number, fallbackFn) => void;
  onRowClick?: (clickedRow: T, rowIndex: number) => void;
};

const Table = <T extends unknown>(
  props
): ReactElement<Props<T> & WrappedComponentProps> => {
  const {
    data,
    renderColumns,
    showHeader,
    fixedLayout,
    striped,
    borderAround,
    roundedBorder,
    verticalAlignMiddle,
    addlRowClassname,
    rowLinkTo,
    addlTableClassName,
    removableRows,
    onRemoveRow,
    keyRemoveDisabledMessage,
    intl,
    renderClickedRow,
    onRowClick,
  } = props;

  const renderInnerRow = (el: T) => {
    return (
      <Fragment>
        {renderColumns.map((col) => (
          <td key={col.key as string} style={{ width: col.width || 'unset' }}>
            {col.renderCell(el[col.key], el)}
          </td>
        ))}
        {removableRows && renderRemoveIcon(el)}
      </Fragment>
    );
  };

  const renderRemoveIcon = (el: T) => {
    if (keyRemoveDisabledMessage && el[keyRemoveDisabledMessage]) {
      // Removing is disabled for this row
      return (
        <td
          className={classNames(styles.removeIconColumn, styles.removeDisabled)}
        >
          <div
            className={styles.removeIcon}
            data-tooltip={intl.formatMessage(el[keyRemoveDisabledMessage])}
          >
            <FiMinus size={16} />
          </div>
        </td>
      );
    } else {
      // Removing is enabled for this row
      return (
        <td className={styles.removeIconColumn}>
          <div
            className={styles.removeIcon}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onRemoveRow && onRemoveRow(el);
              return false;
            }}
          >
            <FiMinus size={16} />
          </div>
        </td>
      );
    }
  };

  const renderRow = (element: T, rowIndex: number) => {
    if (rowLinkTo && rowLinkTo(rowIndex)) {
      return (
        <tr
          key={rowIndex}
          className={classNames(
            addlRowClassname && addlRowClassname(rowIndex, element)
          )}
        >
          <Link
            to={rowLinkTo(rowIndex)}
            style={{
              textDecoration: 'none',
              display: 'contents',
            }}
          >
            {renderInnerRow(element)}
          </Link>
        </tr>
      );
    } else {
      return (
        <tr
          onClick={() => onRowClick && onRowClick(element, rowIndex)}
          key={rowIndex}
          className={classNames(
            addlRowClassname && addlRowClassname(rowIndex, element)
          )}
        >
          {renderInnerRow(element)}
        </tr>
      );
    }
  };

  return (
    <div
      className={classNames(styles.table, 'table-reset', {
        [styles.fixedLayout]: fixedLayout,
        [styles.striped]: striped,
        [styles.borderAround]: borderAround,
        [styles.roundedBorder]: roundedBorder,
        [styles.verticalAlignMiddle]: verticalAlignMiddle,
      })}
    >
      <table className={addlTableClassName}>
        {showHeader && (
          <thead>
            <tr>
              {renderColumns.map((col) => (
                <th
                  key={col.key as string}
                  style={{ width: col.width || 'unset' }}
                >
                  {col.renderHeader && col.renderHeader()}
                </th>
              ))}
              {removableRows && <th className={styles.removeIconColumn} />}
            </tr>
          </thead>
        )}
        <tbody>
          {data.map((el, i) => {
            if (renderClickedRow)
              return renderClickedRow(el, i, renderRow(el, i));
            else return renderRow(el, i);
          })}
        </tbody>
      </table>
    </div>
  );
};

export default injectIntl(Table) as <T extends any>(
  props: Props<T>
) => React.ReactElement<Props<T>>;
