import React, { FC } from 'react';
import Busy from '../../../../../../../components/atoms/busy/Busy';
import { FormattedMessage } from 'react-intl';
import ParameterTuningResultsComponent from '../../../../../../../components/organisms/parameter-tuning/parameter-tuning-results/ParameterTuningResults';
import IndicatorEmpty from '../../../../../../molecules/indicator-empty/IndicatorEmpty';
import { JobDescriptionBanner } from '../../job-banner/JobDescriptionBanner';
import { useLocation, useParams } from 'react-router-dom';
import { AugurDetailsQueryParams, AugurDetailsRouteParams } from '../../types';
import qs from 'qs';
import { useModel } from '../../../../../../../core/api/mlModels';
import _ from 'lodash';
import { ModelTuningResultsWait } from '../ModelTuningResults';
import { useAugur } from '../../../../../../../core/api/augurs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/store';
import { ArchetypeVersionWithArchetypeType } from 'common/dist/types/archetypeVersion';
import { extractParameterTuningSchemaFromArchetypeVersions } from '../../model-settings/modelSettingsCategories/modelTuning/parameter-tuning/ParameterTuning';
import { UseQueryResult } from 'react-query';
import { NbcModel } from 'common/dist/types/mlModel';

const ParameterTuningResults: FC = () => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const location = useLocation();

  const { data: augur } = useAugur(habitatCode, augurCode);

  const archetypeCode = augur?.archetypeVersion?.archetype?.code ?? '';
  const archetypeVersionNumber = augur?.archetypeVersion?.number ?? '';
  const archetypeIsCustom = augur?.archetypeVersion?.archetype?.custom ?? false;
  const archetypeVersions = useSelector<
    RootState,
    ArchetypeVersionWithArchetypeType[]
  >((state) => state.archetypeVersions);

  const parameterTuningSchema =
    extractParameterTuningSchemaFromArchetypeVersions(
      archetypeVersions,
      archetypeCode,
      archetypeIsCustom ? archetypeVersionNumber : 'latest'
    );

  const { modelCode } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as AugurDetailsQueryParams;

  const {
    data: model,
    error,
    isLoading,
    isSuccess,
    isError,
  } = useModel(habitatCode, augurCode, modelCode) as UseQueryResult<NbcModel>;

  const {
    loading: loadingSchema,
    loaded: loadedSchema,
    error: errorSchema,
  } = archetypeVersions[archetypeCode] || {};

  if (!parameterTuningSchema) {
    return (
      <IndicatorEmpty
        classNameImage={'wait-for-augur-pic'}
        headlineDefault={'No Parameter Tuning Schema available'}
      />
    );
  }

  return (
    <JobDescriptionBanner>
      <Busy isBusy={isLoading || loadingSchema}>
        {/** TODO temporary style **/}
        <div className='h100p'>
          {isError || errorSchema ? (
            <FormattedMessage
              id='details.model_tuning.error'
              defaultMessage={`${
                error ? 'Error model tuning results' : 'Error schema'
              }: {error}`}
              values={error ? { error } : { error: errorSchema }}
            />
          ) : (
            ''
          )}

          {isSuccess &&
            loadedSchema &&
            !_.isEmpty(model?.details?.parameterTuning) && (
              <div style={{ padding: '19px 31px' }}>
                <ParameterTuningResultsComponent
                  modelTuningResults={model.details.parameterTuning}
                  parameterTuningSchema={parameterTuningSchema}
                />
              </div>
            )}
          {isSuccess &&
            loadedSchema &&
            _.isEmpty(model?.details?.parameterTuning) && (
              // No difference in response if the details are missing completely or just the parameterTuning field,
              // because the backend response makes not distinction and we just want the parameterTuning field returned
              <ModelTuningResultsWait />
            )}
        </div>
      </Busy>
    </JobDescriptionBanner>
  );
};

export default ParameterTuningResults;
