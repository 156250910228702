import { connect } from 'react-redux';
import NotEvenOnePermission from './NotEvenOnePermission';
import { fetchComponentPermissions } from '../../../redux/modules/dashboard.module';

export function mapStateToProps(state) {
  return {
    permissions: state.dashboard?.permissions?.data || {},
  };
}

export const mapDispatchToProps = {
  fetchComponentPermissions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotEvenOnePermission);
