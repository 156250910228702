import React, { FC } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { HabitatDetailsType } from 'common/dist/types/habitat';
import styles from '../styles.module.scss';

type Props = {
  isActive: boolean;
  habitat: HabitatDetailsType;
  setActive: () => void;
  isDisabled: boolean;
};

const DatapoolsTab: FC<Props> = (props) => {
  const { isActive, habitat, setActive, isDisabled } = props;
  const datapoolsCount = (habitat.datapools || []).length;

  function handleClick(event) {
    event.preventDefault();
    setActive();
  }
  return (
    <div
      data-testingIdentifier={'DatapoolsTab'}
      className={classNames(styles.headerTab, {
        [styles.headerTabActive]: isActive,
        [styles.headerTabDisabled]: isDisabled,
      })}
      onClick={handleClick}
    >
      <div className={styles.headerContent}>
        <span
          className={classNames('icon-datapool', styles.headerIcon)}
          style={{ fontSize: '17px' }}
        />
        <div className={styles.headerText}>
          <FormattedMessage
            id='dashboard.header.datapools'
            defaultMessage='Datapools'
          >
            {(text) => (
              <span
                className={styles.headerTitle}
                data-testingIdentifier={'HabitatTabTitle'}
              >
                {text}
              </span>
            )}
          </FormattedMessage>
          <FormattedMessage
            id='dashboard.header.datapools_count'
            defaultMessage='{datapoolsCount, plural,
                        =0 {No Datapools}
                        one {# Datapool}
                        other {# Datapools}
                      }'
            values={{ datapoolsCount }}
          >
            {(text) => <span className={styles.headerSubTitle}>{text}</span>}
          </FormattedMessage>
        </div>
      </div>
    </div>
  );
};

export default DatapoolsTab;
