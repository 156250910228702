import PropTypes from 'prop-types';
import React from 'react';
import Busy from '../atoms/busy/Busy';

export default function PageSpinner({ shown, children }) {
  return (
    <div>
      <div style={{ display: !shown ? 'block' : 'none' }}>{children}</div>
      <div
        className='fullViewportHeight'
        style={{ display: shown ? 'block' : 'none' }}
      >
        <Busy isBusy />
      </div>
    </div>
  );
}

PageSpinner.propTypes = {
  shown: PropTypes.bool.isRequired,
  children: PropTypes.node,
};
