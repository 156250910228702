import {
  BackupCreateRequest,
  BackupCreateResponse,
  BackupListResponse,
  BackupMeta,
  BackupMetaWithId,
  BackupRestoreRequest,
  TemplateCreateRequest,
  TemplateCreateResponse,
  TemplateMeta,
} from '../../../components/admin/maintenance/types';

export const FETCH_BACKUPS = 'fetch backups';
export const FETCH_BACKUPS_SUCCESS = 'fetch backups - success';
export const FETCH_BACKUPS_FAIL = 'fetch backups - fail';
export const SHOW_RESTORE_BACKUP_MODAL = 'show restore backup modal';
export const HIDE_RESTORE_BACKUP_MODAL = 'hide restore backup modal';
export const RESTORE_BACKUP = 'restore backup';
export const RESTORE_BACKUP_SUCCESS = 'restore backup - success';
export const RESTORE_BACKUP_FAIL = 'restore backup - fail';
export const CREATE_BACKUP = 'create backup';
export const CREATE_BACKUP_SUCCESS = 'create backup - success';
export const CREATE_BACKUP_FAIL = 'create backup - fail';
export const FETCH_TEMPLATES = 'fetch templates';
export const FETCH_TEMPLATES_SUCCESS = 'fetch templates - success';
export const FETCH_TEMPLATES_FAIL = 'fetch templates - fail';
export const CREATE_TEMPLATE = 'create template';
export const CREATE_TEMPLATE_SUCCESS = 'create template - success';
export const CREATE_TEMPLATE_FAIL = 'create template - fail';

export interface FetchBackupsAction {
  type: typeof FETCH_BACKUPS;
}

export interface FetchBackupsSuccessAction {
  type: typeof FETCH_BACKUPS_SUCCESS;
  payload: { data: BackupListResponse };
}

export interface FetchBackupsFailAction {
  type: typeof FETCH_BACKUPS_FAIL;
  payload: { error: string };
}

export interface ShowRestoreBackupModalAction {
  type: typeof SHOW_RESTORE_BACKUP_MODAL;
  payload: { bak: BackupMetaWithId; template: TemplateMeta };
}

export interface HideRestoreBackupModalAction {
  type: typeof HIDE_RESTORE_BACKUP_MODAL;
}

export interface RestoreBackupAction {
  type: typeof RESTORE_BACKUP;
  payload: { bakRestore: BackupRestoreRequest };
}

export interface RestoreBackupSuccessAction {
  type: typeof RESTORE_BACKUP_SUCCESS;
  payload: { data: BackupMeta[] };
}

export interface RestoreBackupFailAction {
  type: typeof RESTORE_BACKUP_FAIL;
  payload: { error: string };
}

export interface CreateBackupAction {
  type: typeof CREATE_BACKUP;
  payload: { bakCreate: BackupCreateRequest; callback: () => void };
}

export interface CreateBackupSuccessAction {
  type: typeof CREATE_BACKUP_SUCCESS;
  payload: { bakCreated: BackupCreateResponse };
}

export interface CreateBackupFailAction {
  type: typeof CREATE_BACKUP_FAIL;
  payload: { error: string };
}

export interface FetchTemplatesAction {
  type: typeof FETCH_TEMPLATES;
}

export interface FetchTemplatesSuccessAction {
  type: typeof FETCH_TEMPLATES_SUCCESS;
  payload: { data: TemplateMeta[] };
}

export interface FetchTemplatesFailAction {
  type: typeof FETCH_TEMPLATES_FAIL;
  payload: { error: string };
}

export interface CreateTemplateAction {
  type: typeof CREATE_TEMPLATE;
  payload: { templateCreate: TemplateCreateRequest; callback: () => void };
}

export interface CreateTemplateSuccessAction {
  type: typeof CREATE_TEMPLATE_SUCCESS;
  payload: { templateCreated: TemplateCreateResponse };
}

export interface CreateTemplateFailAction {
  type: typeof CREATE_TEMPLATE_FAIL;
  payload: { error: string };
}

export type AdminBackupsActions =
  | FetchBackupsAction
  | FetchBackupsSuccessAction
  | FetchBackupsFailAction
  | ShowRestoreBackupModalAction
  | HideRestoreBackupModalAction
  | RestoreBackupAction
  | RestoreBackupSuccessAction
  | RestoreBackupFailAction
  | CreateBackupAction
  | CreateBackupSuccessAction
  | CreateBackupFailAction
  | FetchTemplatesAction
  | FetchTemplatesSuccessAction
  | FetchTemplatesFailAction
  | CreateTemplateAction
  | CreateTemplateSuccessAction
  | CreateTemplateFailAction;
