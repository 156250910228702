export const initial = {
  loading: false,
  loaded: false,
  error: undefined, // Just to make it explicit
  data: [],
};

export const test = {
  loading: false,
  loaded: false,
  error: undefined, // Just to make it explicit
  data: [],
};
