import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  postApiRequest,
  putApiRequest,
} from '../../core/api/_tools';
import { JobGroupInputType, JobGroupType } from 'common/dist/types/job';
import qs from 'qs';

// TODO this belongs with the other orchestration apis, but they are still untyped

export function fetchJobGroups(
  offset?: number,
  limit?: number,
  search?: string
): CompletedApiRequest<JobGroupType[]> {
  const q = qs.stringify({ offset, limit, search });
  return apiRequest(`/orchestration/jobgroups?${q}`);
}

export function fetchJobGroup(
  jobGroupCode: string
): CompletedApiRequest<JobGroupType> {
  return apiRequest(`/orchestration/jobgroups/jobgroup/${jobGroupCode}`);
}

export function addJobGroup(jobGroup: JobGroupInputType): CompletedApiRequest {
  return postApiRequest(`/orchestration/jobgroups/jobgroup`, jobGroup);
}

export function updateJobGroup(
  jobGroupCode: string,
  jobGroup: JobGroupInputType
): CompletedApiRequest {
  return putApiRequest(
    `/orchestration/jobgroups/jobgroup/${jobGroupCode}`,
    jobGroup
  );
}

export function deleteJobGroup(jobGroupCode: string): CompletedApiRequest {
  return deleteApiRequest(`/orchestration/jobgroups/jobgroup/${jobGroupCode}`);
}

export function runJobGroup(jobGroup: JobGroupInputType): CompletedApiRequest {
  return postApiRequest(`/orchestration/queue/jobgroup/add`, jobGroup);
}
