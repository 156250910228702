import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import WizardUpload from './wizard-upload/WizardUpload.container';
import { UPLOAD_WIZARDS } from './uploadWizards';
import WizardCommit from './wizard-commit/WizardCommit.container';
import UploadConfirmation from './UploadConfirmation.container';
import { RouteComponentProps } from 'react-router';

export interface Props {
  match?: {
    params?: {
      /** Code of the Data Source */
      dataSourceCode?: string;
    };
  };
  /** One of the UPLOAD_WIZARDS values */
  activeWizard?: string;
}

class Upload extends Component<Props & RouteComponentProps> {
  static defaultProps = {
    activeWizard: UPLOAD_WIZARDS.UPLOAD,
  };

  pickWizard() {
    const {
      match: { params },
      activeWizard,
    } = this.props;
    const dataSourceCode = params.dataSourceCode || '';
    if (activeWizard === UPLOAD_WIZARDS.UPLOAD) {
      // @ts-ignore seems like a problem of withRouter outside of connect
      return <WizardUpload dataSourceCode={dataSourceCode} />;
    } else if (activeWizard === UPLOAD_WIZARDS.COMMIT) {
      return <WizardCommit dataSourceCode={dataSourceCode} />;
    } else if (activeWizard === UPLOAD_WIZARDS.CONFIRMATION) {
      return <UploadConfirmation dataSourceCode={dataSourceCode} />;
    } else {
      return null;
    }
  }

  render() {
    return this.pickWizard();
  }
}

export default withRouter(Upload);
