import React, { Component, createRef } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import Dropzone from '../../../../../atoms/dropzone/Dropzone';
import Progress from '../../../../../atoms/progress/Progress';
import { styledFileSize } from '../../../../util';
import { WrappedFieldProps } from 'redux-form';
import { UploadData, UploadError } from '../form';
import { CProps } from './StepUpload.container';
import styles from '../../../../styles.module.scss';
import Button from '../../../../../atoms/button/Button';
import formMsgs from 'common/dist/messages/form';
import classNames from 'classnames';

export interface Props {
  dataSourceCode: string;
}

type CombinedProps = Props & CProps & WrappedFieldProps;

export default class StepUpload extends Component<CombinedProps> {
  dropzoneRef: React.RefObject<Dropzone>;

  constructor(props: CombinedProps) {
    super(props);
    this.dropzoneRef = createRef();
  }

  componentDidMount() {
    const { resetUploadFile, dataSourceCode } = this.props;
    resetUploadFile(dataSourceCode);
  }

  renderProgressInfo(progress) {
    if (!progress) return <div />;
    // Should not happen
    else if (progress.status === 'error') {
      return <FiX className={'error-icon'} size={20} />;
    } else if (progress.status === 'done') {
      return <FiCheck className={'check-icon'} size={20} />;
    }
    return (
      <span className={'progress-percentage'}>
        {progress ? progress.toFixed(0) : '?'} %
      </span>
    );
  }

  render() {
    const {
      meta: { valid, touched },
      input: { onChange, onBlur },
      uploading,
      uploaded,
      progress,
    } = this.props;
    const value: UploadData = this.props.input.value;
    const error: UploadError = this.props.meta.error;
    return (
      <div className={'step-container step-upload-container'}>
        <div>
          {/* Redux-Form error like missing file */}
          {touched && error && error.file && (
            <div className={'error-container'}>
              <span>{error.file + ''}</span>
            </div>
          )}
          <div
            className={classNames(styles.uploadInputParent, styles.multiple)}
          >
            <Dropzone
              onFilesAdded={(fileArray) => {
                if (fileArray.length > 0) {
                  onChange({
                    ...value,
                    file: value.file ? value.file.concat(fileArray) : fileArray,
                  });
                }
              }}
              uploading={uploading}
              onBlur={() => onBlur(value)}
              multiple
              ref={this.dropzoneRef}
            />
            <div className={styles.uploadRightPart}>
              <div className={styles.files}>
                {value?.file?.map((file) => (
                  <div
                    key={file.webkitRelativePath || file.name}
                    className={styles.fileParent}
                  >
                    <div className={styles.uploadText}>
                      <span
                        title={file.webkitRelativePath || file.name}
                        className={styles.filename}
                      >
                        {file.webkitRelativePath || file.name}
                      </span>
                      <span className={styles.filesize}>
                        {styledFileSize(file.size)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              {uploading || uploaded ? (
                <div className={styles.progressWrapper}>
                  <Progress progress={progress} done={uploaded} />
                  <div className={styles.progressInfo}>
                    {this.renderProgressInfo(progress)}
                  </div>
                </div>
              ) : (
                <div className={styles.buttons}>
                  <Button
                    buttonColor={'white'}
                    buttonLabelId={formMsgs.clear.id}
                    buttonLabelDefault={formMsgs.clear.defaultMessage}
                    withLink={false}
                    onClick={() => {
                      onChange({ file: [] });
                      this.dropzoneRef.current?.resetValues();
                    }}
                  />
                </div>
              )}
              {/* Upload error */}
              {this.props.error && (
                <div className='error-container'>
                  <span>{this.props.error + ''}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
