import React, { FC } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from '../styles.module.scss';

type Props = {
  isActive: boolean;
  setActive: () => void;
  isDisabled: boolean;
  codeCapsulesCount: number;
};

const CodeCapsules: FC<Props> = (props) => {
  const { isActive, codeCapsulesCount, setActive, isDisabled } = props;

  function handleClick(event) {
    event.preventDefault();
    setActive();
  }

  return (
    <div
      data-testingIdentifier={'CodeCapsulesTab'}
      className={classNames(styles.headerTab, {
        [styles.headerTabActive]: isActive,
        [styles.headerTabDisabled]: isDisabled,
      })}
      onClick={handleClick}
    >
      <div className={styles.headerContent}>
        <span className={classNames('icon-code-capsule', styles.headerIcon)} />
        <div className={styles.headerText}>
          <FormattedMessage
            id='dashboard.header.code_capsules'
            defaultMessage='Code Capsules'
          >
            {(text) => (
              <span
                className={styles.headerTitle}
                data-testingIdentifier={'HabitatTabTitle'}
              >
                {text}
              </span>
            )}
          </FormattedMessage>
          <FormattedMessage
            id='dashboard.header.code_capsules_count'
            defaultMessage='{codeCapsulesCount, plural,
                          =0 {No Code Capsules}
                          one {# Code Capsule}
                          other {# Code Capsules}
                        }'
            values={{ codeCapsulesCount }}
          >
            {(text) => <span className={styles.headerSubTitle}>{text}</span>}
          </FormattedMessage>
        </div>
      </div>
    </div>
  );
};

export default CodeCapsules;
