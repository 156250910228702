import React, { Component } from 'react';
// @ts-ignore
import Cassandra from './icons/Cassandra';
// @ts-ignore
import S3 from './icons/S3';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { Value } from './DataSourceButtons';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface Props {
  name: string;
  displayName: string;
  onChange: (v: Value) => ToBeRefined;
  selectedName: string;
  disabled: boolean;
}

export function dataSourceIcon(dsType: string) {
  switch (dsType) {
    case 'cassandra': {
      return <Cassandra className={styles.icon} />;
    }
    case 's3': {
      return <S3 className={styles.icon} />;
    }
    default:
      return null;
  }
}

export default class DataSourceButton extends Component<Props> {
  render() {
    const { selectedName, name, onChange, displayName, disabled } = this.props;
    return (
      <div
        data-testingIdentifier={displayName}
        className={classNames(styles.card, {
          [styles.selected]: selectedName && name === selectedName,
          [styles.unselected]: selectedName && name !== selectedName,
        })}
        onClick={() => {
          if (!disabled) {
            onChange(name);
          }
        }}
      >
        <div className={styles.iconContainer}>{dataSourceIcon(name)}</div>
        <span className={styles.displayName}>{displayName}</span>
      </div>
    );
  }
}
