import { createAction } from 'redux-act';

export const openTypeSelector = createAction(
  'click on column type selector',
  (columnId) => columnId
);

export const closeTypeSelector = createAction('close column type selector');

export const selectType = createAction(
  'select column type',
  (columnId, type) => ({ columnId, type })
);

export const reducer = {
  [openTypeSelector]: (state, columnId) => ({
    ...state,
    dataImport: {
      ...state.dataImport,
      openedTypeSelector:
        state.dataImport.openedTypeSelector === columnId ? '' : columnId,
    },
  }),

  [closeTypeSelector]: (state) => ({
    ...state,
    dataImport: {
      ...state.dataImport,
      openedTypeSelector: '',
    },
  }),

  [selectType]: (state, { columnId, type }) => ({
    ...state,
    dataImport: {
      ...state.dataImport,
      columnTypes: {
        ...state.dataImport.columnTypes,
        [columnId]: type,
      },
    },
  }),
};
