import React, { FC } from 'react';
import NameAndIconLoading from '../name-and-icon/NameAndIconLoading';
import NameAndIcon from '../name-and-icon/NameAndIcon';
import { CodeCapsule } from 'common/dist/types/codeCapsule';
import vars from '../../../../../scss/base/var.module.scss';

type Props = {
  /** Are the augur details loading? */
  loading?: boolean;
  /** Are the augur details loaded? */
  loaded?: boolean;
  /** The augur details */
  codeCapsule?: CodeCapsule;
};

const NameContainer: FC<Props> = (props: Props) => {
  const { codeCapsule, loading, loaded } = props;

  if (loading || !loaded) {
    return (
      <NameAndIconLoading
        gridColumnStart={1}
        gridColumnEnd={1}
        gridRowStart={1}
        gridRowEnd={'span 2'}
        Icon={() => (
          <span
            className='icon-code-capsule'
            style={{
              color: vars.colorPrimary,
              fontSize: '20px',
            }}
          />
        )}
      />
    );
  }

  return (
    <NameAndIcon
      gridColumnStart={1}
      gridColumnEnd={1}
      gridRowStart={1}
      gridRowEnd={'span 2'}
      Icon={() => (
        <span
          className='icon-code-capsule'
          style={{
            color: vars.colorPrimary,
            fontSize: '20px',
          }}
        />
      )}
      name={codeCapsule.name}
      subName={codeCapsule.habitatName}
      showHealthBar={false}
    />
  );
};

export default NameContainer;
