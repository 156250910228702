import React, { FC } from 'react';
import styles from './styles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import CassandraTablePreview from '../../../../cassandra-table-preview/CassandraTablePreview';
import { TableSample } from './types';
import { ColSpec } from '../../../../../../store/dataManagement/state.types';
import {
  ExtendedColSpec,
  HighlightStyle as BaseHighlightStyle,
} from '../../../../cassandra-table-preview/Table';
import { DatapoolType } from 'common/dist/types/datapool';
import classNames from 'classnames';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Tooltip from '../../../../../atoms/tooltip/Tooltip';
import vars from '../../../../../../../scss/base/var.module.scss';

type ContainerProps = {
  tableSample?: TableSample;
};

export type OwnProps = {
  /** Selected datapoolCode */
  datapool: DatapoolType;
  /** Name of the table to preview */
  tableName?: string;
  /** Label column (required for highlighting) */
  labelColumn?: string;
  /** Primary key columns (required for highlighting) */
  primaryKeyColumns: string[];
  /** Additional column highlights. The highlights caused by labelColumn and primaryKeyColumns will be evaluated with a
   * higher priority! */
  columnHighlights?: { [colName: string]: HighlightStyle };
  /** Limit the height or not? */
  fullHeight?: boolean;
};

type Props = OwnProps & ContainerProps;

export interface HighlightStyle extends BaseHighlightStyle {
  backgroundColor: string;
  colName: {
    color: string;
  };
  colType: {
    color: string;
  };
  legend?: MessageDescriptor;
}

const HIGHLIGHT_LABEL_COL_STYLE: HighlightStyle = {
  backgroundColor: vars.colorSecondary,
  colName: {
    color: 'white',
  },
  colType: {
    color: 'white',
  },
};
const HIGHLIGHT_ID_COL_STYLE: HighlightStyle = {
  backgroundColor: '#9f9f9f',
  colName: {
    color: 'white',
  },
  colType: {
    color: 'white',
  },
};

const sortBy = (colSpecA: ExtendedColSpec, colSpecB: ExtendedColSpec) => {
  const hA = colSpecA.highlightStyle?.backgroundColor;
  const hB = colSpecB.highlightStyle?.backgroundColor;
  if (!hA && !hB) {
    // Easiest case: both columns are not highlighted -> Simply sort by name
    return colSpecA.colName.toLowerCase() > colSpecB.colName.toLowerCase()
      ? 1
      : -1;
  } else if (hA && !hB) {
    // Column A is highlighted, B isn't -> A is smaller (put A all to the left of the table)
    return -1;
  } else if (!hA && hB) {
    // Column B is highlighted, A isn't -> A is greater (put B all to the left of the table)
    return 1;
  } else {
    // Easiest case: both columns are highlighted -> Sort by color (to group same colors) and then by name
    if (hA === hB) {
      return colSpecA.colName.toLowerCase() > colSpecB.colName.toLowerCase()
        ? 1
        : -1;
    } else {
      return hA < hB ? 1 : -1;
    }
  }
};

const getHighlightedColSpecs = (
  colSpecs?: ColSpec[],
  labelColumn?: string,
  primaryKeyColumns?: string[],
  columnHighlights?: { [colName: string]: HighlightStyle }
): ColSpec[] => {
  return (colSpecs || []).map((colSpec) => {
    if (colSpec.colName === labelColumn) {
      // Label Column
      return {
        ...colSpec,
        highlightStyle: HIGHLIGHT_LABEL_COL_STYLE,
      };
    } else if ((primaryKeyColumns || []).includes(colSpec.colName)) {
      // Primary Key Column
      return {
        ...colSpec,
        highlightStyle: HIGHLIGHT_ID_COL_STYLE,
      };
    } else if ((columnHighlights || {})[colSpec.colName]) {
      return {
        ...colSpec,
        highlightStyle: columnHighlights[colSpec.colName],
      };
    } else {
      // Column neither selected as label nor as a primary key
      return colSpec;
    }
  });
};

const TablePreview: FC<Props & WrappedFieldProps> = ({
  tableSample,
  labelColumn,
  primaryKeyColumns,
  columnHighlights,
  fullHeight,
}) => {
  const { data } = tableSample || {};

  if (!tableSample) {
    return null;
  }

  const highlightedColSpecs = getHighlightedColSpecs(
    data?.colSpecs,
    labelColumn,
    primaryKeyColumns,
    columnHighlights
  );

  return (
    <div className={styles.tablePreview}>
      {columnHighlights &&
        Object.entries(columnHighlights).some(([k, v]) => v.legend) && (
          <Tooltip anchorSelect={`.${styles.tablePreview} thead`}>
            <table
              style={{ borderCollapse: 'separate', borderSpacing: '0.5em' }}
            >
              {Object.entries(columnHighlights)
                .filter(([k, v]) => v.legend)
                .map(([k, v]) => (
                  <tr>
                    <td
                      style={{
                        paddingRight: '1em',
                        backgroundColor: v.backgroundColor,
                      }}
                    />
                    <td>
                      <FormattedMessage {...v.legend} />
                    </td>
                  </tr>
                ))}
            </table>
          </Tooltip>
        )}
      {data && data.colSpecs && data.data && (
        <div
          className={classNames(styles.tableParent, {
            [styles.limitHeight]: !fullHeight,
          })}
        >
          <CassandraTablePreview
            colSpecs={highlightedColSpecs}
            data={data.data}
            editable={false}
            sortBy={sortBy}
          />
        </div>
      )}
    </div>
  );
};

export default TablePreview;
