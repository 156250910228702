import datapoolMsgs from 'common/dist/messages/datapools';
import { MessageDescriptor } from 'react-intl';

export function validateDataSourceCode(
  dataSourceCode?: string
): MessageDescriptor {
  if (!dataSourceCode) {
    return datapoolMsgs.msgDatapoolMissingDataSourceCode;
  }

  return undefined;
}
