import PropTypes from 'prop-types';
import React from 'react';
import NotificationContainer from './Notification.container';

function Notifications({ notifications }) {
  return (
    <div
      data-testingIdentifier={'notification'}
      className='notifications'
      style={{ bottom: 0 /* 80 * (notifications.length - 1)*/ }}
    >
      {notifications.map((notification) => (
        <NotificationContainer
          key={notification.id}
          notification={notification}
        />
      ))}
    </div>
  );
}

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default Notifications;
