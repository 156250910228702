export const orchestrationRoutes = {
  basePath: '/app/orchestration',

  queue: {
    path: 'queue',
  },

  history: {
    path: 'history',
  },

  jobGroups: {
    path: 'jobgroups',
  },

  addJobGroup: {
    path: 'add-jobgroup',
  },

  editJobGroup: {
    path: 'edit-jobgroup',
  },

  schedulings: {
    path: 'schedulings',

    hourly: 'hourly',
    daily: 'daily',
    weekly: 'weekly',
    monthly: 'monthly',
  },

  addSchedule: {
    path: 'add-schedule',
  },

  editSchedule: {
    path: 'edit-schedule',
  },

  jobConfiguration: {
    path: 'jobconfiguration',
  },

  realtime: {
    path: 'realtime',
  },

  jobDetails: {
    path: 'jobdetails',
  },

  jobGroupDetails: {
    path: 'jobgroupdetails',
  },
};
