import { call, put, takeEvery } from 'redux-saga/effects';
import { enableMaintenanceFail, enableMaintenanceSuccess } from './actions';
import { ENABLE_MAINTENANCE, EnableMaintenanceAction } from './actions.types';
import { enableMaintenance } from './api';

export function* enableMaintenanceSaga({
  payload: { data },
}: EnableMaintenanceAction) {
  const { response, error } = yield call(enableMaintenance, data);
  if (response) {
    yield put(enableMaintenanceSuccess());
    setTimeout(() => window.location.reload(), 1000);
  } else {
    yield put(enableMaintenanceFail(error));
  }
}

export function* watchEnableMaintenance() {
  yield takeEvery(ENABLE_MAINTENANCE, enableMaintenanceSaga);
}
