import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import CandidateConditions from './CandidateConditions';
import { DeprecatedRootState } from '../../../../../../../../../store/state.type';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

const form = {
  form: 'candidateConditions',
  enableReinitialize: true,
};

const candidateConditionsSelector = formValueSelector(form.form);

export function mapStateToProps(
  state: DeprecatedRootState,
  ownProps: ModelSettingsProps<AugurSettingsDataNbc>
) {
  const {
    settings: { positiveCondition, negativeCondition },
  } = ownProps;

  return {
    ...state.form.candidateConditions,
    initialValues: {
      positiveCondition: positiveCondition,
      negativeCondition: negativeCondition,
    },
    positiveCondition: candidateConditionsSelector(state, 'positiveCondition'),
    negativeCondition: candidateConditionsSelector(state, 'negativeCondition'),
  };
}

export default connect(mapStateToProps)(reduxForm(form)(CandidateConditions));
