import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';
import ButtonBar from './ButtonBar.container';
import AceEditor from 'react-ace';
import CloseConfirm from '../../../part-right/editor/close-confirm/CloseConfirm.container';

export default class CodeContent extends Component {
  keyMap = {
    save: 'command+s',
  };

  keyHandlers = {
    save: (e) => {
      this.props.saveNotebook(this.props.path, this.props.content, 'file');
      e.preventDefault();
    },
  };

  render() {
    const {
      path,
      content,
      unsavedChanges,
      changeCodeContent,
      showCloseConfirm,
      paneId,
    } = this.props;

    // Is the close confirm supposed to be shown?
    if (showCloseConfirm) {
      return <CloseConfirm path={path} content={content} paneId={paneId} />;
    }

    return (
      <div className={'code-content'}>
        <HotKeys
          className={'hotkeys'}
          keyMap={this.keyMap}
          handlers={this.keyHandlers}
        >
          <ButtonBar
            path={path}
            content={content}
            unsavedChanges={unsavedChanges}
            paneId={paneId}
          />
          <div className={'editor-parent-container'}>
            <div className={'editor-con'}>
              <AceEditor
                ref={(input) => {
                  this.editorRef = input;
                }}
                width={'100%'}
                className={'code-cell-editor'}
                mode='python'
                theme='tomorrow'
                onChange={(content) => {
                  changeCodeContent({
                    path,
                    content,
                  });
                }}
                showGutter
                highlightActiveLine
                value={content}
                setOptions={{
                  maxLines: Infinity,
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                editorProps={{ $blockScrolling: Infinity }}
                scrollIntoView
                readOnly={false}
              />
            </div>
          </div>
        </HotKeys>
      </div>
    );
  }
}
CodeContent.propTypes = {
  path: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  unsavedChanges: PropTypes.bool,
  saveNotebook: PropTypes.func.isRequired,
  changeCodeContent: PropTypes.func.isRequired,
  showCloseConfirm: PropTypes.bool,
  paneId: PropTypes.string.isRequired,
};
