import { VARIABLE_TYPES } from '../variableTypes';
import { BaseVariableHandler, getFirstRaw } from './helper';

export function handleJson(variable, rawValues, firstRaw) {
  try {
    let truncatedFirstRaw = firstRaw; // Remove the ' in the beginning and the end of the string
    if (truncatedFirstRaw.startsWith("'"))
      truncatedFirstRaw = truncatedFirstRaw.slice(1);
    if (truncatedFirstRaw.endsWith("'"))
      truncatedFirstRaw = truncatedFirstRaw.slice(
        0,
        truncatedFirstRaw.length - 1
      );
    truncatedFirstRaw = truncatedFirstRaw.replaceAll('\\\\', '\\'); // Replace double slashes with single slash
    const parsedValue = JSON.parse(truncatedFirstRaw);
    return { parsedValue, error: undefined };
  } catch (e) {
    return { parsedValue: undefined, error: "Couldn't parse JSON" };
  }
}

export const JsonVariableHandler: BaseVariableHandler<string> = {
  type: VARIABLE_TYPES.JSON,
  displayName: 'Json',
  getExecutionCode: (inputValue: string) => inputValue,
  parseValue: (variable, rawValues) => {
    const firstRaw = getFirstRaw(variable, rawValues);
    if (!firstRaw) return { parsedValue: undefined, error: undefined };
    return handleJson(variable, rawValues, firstRaw);
  },
};
