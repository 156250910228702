import React, { Component } from 'react';
import { arc, pie } from 'd3-shape';

import * as misclassification from '../../../../core/common/misclassification';
import { Misclassification } from 'common/dist/types/reports';

type Props = {
  width: number;
  height: number;
  data: Misclassification;
};

class Donut extends Component<Props> {
  static defaultProps = {
    width: 100,
    height: 100,
  };

  render() {
    const { width, height, data } = this.props;

    const values = misclassification.getValues(data);

    const arcs = pie()(values);

    const margin = {
      top: 30,
      right: 0,
      bottom: 0,
      left: 40,
    };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;
    const position = `translate(${width / 2}, ${height / 2})`;

    const radius = Math.min(chartWidth, chartHeight);

    const arcGenerator = arc()
      .innerRadius(radius / 4.5)
      .outerRadius(radius / 2);

    // @ts-ignore
    const paths = arcs.map((item) => arcGenerator(item));

    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <svg style={{ width: '100%', height: '100%' }}>
          <g transform={position}>
            {paths.map((item, index) => (
              <path
                key={index}
                fill={misclassification.colors[index]}
                d={item}
              ></path>
            ))}
          </g>
        </svg>
      </div>
    );
  }
}

export default Donut;
