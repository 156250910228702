import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DatapoolNameAndCodeComponent from './DatapoolNameAndCodeComponent';
import { RootState } from '../../../../../../../../../store/store';
import { RouteComponentProps } from 'react-router';

export function mapStateToProps(
  state: RootState,
  {
    match,
  }: RouteComponentProps<{
    moduleType: string;
    datapoolCode: string;
    habitatCode: string;
  }>
) {
  const datapoolCode = match.params.datapoolCode;
  const habitatCode = match.params.habitatCode;
  const datapoolName = ((state.datapoolDetails || {}).datapool || {}).name;
  const inputFormat = ((state.datapoolDetails || {}).datapool || {})
    .inputFormat;

  return {
    habitatCode,
    datapoolCode,
    datapoolName,
    inputFormat,
  };
}

const connector = connect(mapStateToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(DatapoolNameAndCodeComponent));
