import { VARIABLE_TYPES } from './variableTypes';
import { StringVariableHandler } from './handler/StringVariableHandler';
import { NumberVariableHandler } from './handler/NumberVariableHandler';
import { TimestampVariableHandler } from './handler/TimestampVariableHandler';
import { JsonVariableHandler } from './handler/JsonVariableHandler';
import { ListOfStringVariableHandler } from './handler/LIstOfStringVariableHandler';
import { ListOfNumberVariableHandler } from './handler/LIstOfNumberVariableHandler';
import { PandasDataFrameVariableHandler } from './handler/PandasDataFrameVariableHandler';

export const VARIABLE_HANDLER = {
  [VARIABLE_TYPES.STRING]: StringVariableHandler,
  [VARIABLE_TYPES.NUMBER]: NumberVariableHandler,
  [VARIABLE_TYPES.TIMESTAMP]: TimestampVariableHandler,
  [VARIABLE_TYPES.JSON]: JsonVariableHandler,
  [VARIABLE_TYPES.LIST_OF_STRING]: ListOfStringVariableHandler,
  [VARIABLE_TYPES.LIST_OF_NUMBER]: ListOfNumberVariableHandler,
  [VARIABLE_TYPES.PANDAS_DATA_FRAME]: PandasDataFrameVariableHandler,
};

export const variableOptions = Object.keys(VARIABLE_HANDLER).map((value) => ({
  value,
  label: VARIABLE_HANDLER[value]?.displayName,
}));

// ---
/**
 * Parses the variable value from the rawValues (received as the Jupyter output)
 * Returns an object of shape { parsedValue, error } with one of the two values filled depending on whether the parsing succeeded or not
 * @param variable
 * @param rawValues
 */
export function parseVariableValue(variable, rawValues) {
  const handler = VARIABLE_HANDLER[variable.type];
  if (!handler) {
    console.warn(`Variable handler for type ${variable.type} not found.`);
    return undefined;
  }
  return handler.parseValue(variable, rawValues);
}

export function getExecutionCode(variable) {
  const handler = VARIABLE_HANDLER[variable.type];
  if (!handler) {
    console.warn(`Variable handler for type ${variable.type} not found.`);
    return undefined;
  }
  return handler.getExecutionCode(variable.source);
}
