import PropTypes from 'prop-types';

export const inputElementShape = PropTypes.shape({
  /** ID of the element (globally unique) */
  id: PropTypes.string,
  /** Type of the element ("text-input", ... ) */
  type: PropTypes.string,

  /** Settings of the input element (= defined when creating the notebook) */
  settings: PropTypes.shape({
    // --- Generic values
    /** Label of the element (shown above the element) */
    label: settingShape(PropTypes.string),
    /** Description of the element (shown above the element) */
    description: settingShape(PropTypes.string),
    /** Name of the output variable */
    outputVariable: settingShape(PropTypes.string),

    // --- Specific values (specific to certain types)
    lowerBound: settingShape(PropTypes.string),
    upperBound: settingShape(PropTypes.string),
    stepSize: settingShape(PropTypes.string),
    selectableValues: settingShape(PropTypes.string),
    defaultValue: settingShape(PropTypes.any),
  }),

  /** Data (=user input) of the element */
  data: PropTypes.shape({
    /** undefined if the user input is valid, a string with the error message if the input is not valid. */
    error: PropTypes.string,
    // + additional fields specific to the elements type
    checked: PropTypes.bool,
    number: PropTypes.number,
  }),
});

function settingShape(valueType) {
  return PropTypes.shape({
    /** input | variable Which origin is supposed to be used for this input, the user input field or a variable? Default: 'input' */
    origin: PropTypes.string,
    /** Only set if origin=variable: Which variable is supposed to be used for the value */
    variableName: PropTypes.string,
    /** Value entered into the user input field / dropdown / ... */
    inputValue: valueType,
  });
}

export const variableShape = PropTypes.shape({
  /** ID of the variable (globally unique) */
  id: PropTypes.string,
  /** Name of the variable = name of the variable in the code */
  name: PropTypes.string,
  /** Type of the variable: string|number|timestamp */
  type: PropTypes.string,
  /** Value as returned by the Jupyter kernel */
  rawValue: PropTypes.arrayOf(PropTypes.string),
  /** The rawValue parsed according to the type */
  parsedValue: PropTypes.any,
  /** Contains errors that might occur during the execution of the variable (by the Jupyter Kernel) or during parsing the value. error=undefined means the variable is valid */
  error: PropTypes.string,
  /** If the variable is of type string, it can be overridden with an URL Query Parameter */
  override: PropTypes.bool,
  /** Source of the Input Variable to be executed */
  source: PropTypes.string,
});
