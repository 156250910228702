import { createAction } from 'redux-act';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notebookUser } from '../selectors/notebookUser.selector';
import NotebookApi from '../../../core/api/workbench/git.notebook';

export const getDiskUsage = createAction('get disk usage');

export const getDiskUsageSuccess = createAction(
  'get disk usage - success',
  (data) => data
);

export const getDiskUsageFail = createAction(
  'get disk usage - fail',
  (error) => error
);

export const reducer = {
  [getDiskUsage]: (state) => ({
    ...state,
    diskUsage: {
      ...(state.diskUsage || {}),
      loading: true,
    },
  }),
  [getDiskUsageSuccess]: (state, data) => ({
    ...state,
    diskUsage: {
      ...(state.diskUsage || {}),
      loading: false,
      loaded: true,
      error: undefined,
      data,
    },
  }),
  [getDiskUsageFail]: (state, error) => ({
    ...state,
    diskUsage: {
      ...(state.diskUsage || {}),
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* getDiskUsageSaga() {
  const jupyterUser = yield select((state) => notebookUser(state));
  const notebookApi = new NotebookApi(jupyterUser);
  const { response, error } = yield call([
    notebookApi,
    notebookApi.getDiskUsage,
  ]);
  if (response) {
    yield put(getDiskUsageSuccess(response));
  } else {
    yield put(getDiskUsageFail(error));
  }
}

export function* watchGetDiskUsage() {
  yield takeLatest(getDiskUsage().type, getDiskUsageSaga);
}
