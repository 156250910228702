export const initial = {
  loaded: false,
  loading: false,
};

export const test = {
  name: 'Habitat 1',
  code: '123456',
  loaded: true,
  loading: false,
};
