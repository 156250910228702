import React, { Component, Fragment } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import {
  fieldDownloadButtonLabel,
  fieldDownloadPath,
} from '../../../python3-input-cell/elements/_interface/input-element.form';
import TextInputCol from '../../../settings-dialog/dialog-settings-cols/TextInputCol';
import { inputModalForm } from '../../../settings-dialog/inpmodalDialog.form';

class DownloadFileSettings extends Component<InjectedFormProps> {
  render() {
    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldDownloadButtonLabel}
            label={'Button Label'}
            component={TextInputCol}
            placeholder={'Download File'}
          />
        </div>
        <div className={'input-row'}>
          <Field
            name={fieldDownloadPath}
            label={'Path to the File to download'}
            component={TextInputCol}
            placeholder={'/workbench/...'}
          />
        </div>
      </Fragment>
    );
  }
}

export default reduxForm(inputModalForm)(DownloadFileSettings);
