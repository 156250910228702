import React from 'react';
import NotebookCell from '../../../../../organisms/workbench-cells/notebook-cell/NotebookCell';

/**
 * Wraps every notebook cell implementation
 */
export default abstract class NotebookCellWrapper {
  // Main component of the cell (visually: everything apart of the execute button on the left)
  abstract ChildComponent: any;
  // ID of the cell type - this is how the cell is looked up
  abstract type: string;
  // Name of the cell type - displayed in the dropdown menu to select a cell
  abstract name: string;
  // CSS-class that is passed to the parent container for styling-issues.
  abstract parentContainerClass: string;
  // Is the cell executable (e.g. Code Cell) or not (e.g. Markdown Cell / Raw Cell)
  abstract executable: boolean;

  /**
   * Wraps the ChildComponent into the generic NotebookCell-container.
   * The NotebookCell-container is receiving all the generic state / dispatch information already
   */
  renderWrappedComponent(props: any) {
    return (
      <NotebookCell
        parentContainerClass={this.parentContainerClass}
        key={props.key}
        path={props.path}
        cell={props.cell}
        session={props.session}
        selectedCells={props.selectedCells}
        selectCells={props.selectCells}
        executeCells={props.executeCells}
        // @ts-ignore
        changeSource={props.changeSource}
        renderFlap={props.renderFlap}
        allCells={props.allCells}
        metadata={props.cell.metadata}
        readOnly={props.readOnly}
      >
        <this.ChildComponent
          metadata={props.metadata}
          path={props.path}
          cell={props.cell}
          session={props.session}
          selectedCells={props.selectedCells}
          selectCells={props.selectCells}
          executeCells={props.executeCells}
          changeSource={props.changeSource}
          requestCodeCompletion={props.requestCodeCompletion}
          clearCodeCompletion={props.clearCodeCompletion}
          setRef={props.setRef}
          readOnly={props.readOnly}
        />
      </NotebookCell>
    );
  }
}
