import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ActivateRealtimeToggle from '../ActivateRealtimeToggle.container';
import MicroPostman from './MicroPostman.container';

export default class RestGroup extends Component {
  render() {
    const { isRestActive } = this.props;
    return (
      <div className={'activate-container rest-activate-container'}>
        <div className={'activate-top'}>
          <div className={'activate-title'}>
            <FormattedMessage
              id={'dashboard.augur_settings.rest_realtime_api'}
              defaultMessage={'Realtime API'}
            />
          </div>
          <div className={'activate-description'}>
            <FormattedMessage
              id={'dashboard.augur_settings.activate_rest_realtime_scoring'}
              defaultMessage={'Activate your model for scoring via REST'}
            />
          </div>
        </div>
        <div className={'activate-bottom'}>
          <ActivateRealtimeToggle servingType={'rest'} />
        </div>
        {isRestActive && <MicroPostman />}
      </div>
    );
  }
}

RestGroup.propTypes = {
  isRestActive: PropTypes.bool,
};
