import React, { FC } from 'react';
import SessionElement from './SessionElement.container';
import { Session } from '../../../../store/workbench/state.types';
import styles from './styles.module.scss';

export type Props = {
  data: Session[];
};

const SessionsList: FC<Props> = ({ data }) => {
  return (
    <div className={styles.listContainer}>
      {data &&
        data.map((session) => (
          <SessionElement key={session.id} session={session} />
        ))}
    </div>
  );
};

export default SessionsList;
