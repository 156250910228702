import { connect } from 'react-redux';
import ButtonsBaseFile, { BASE_FILE_PATH } from './ButtonsBaseFile';
import { DeprecatedRootState } from '../../../../../../../store/state.type';
// @ts-ignore
import {
  firstSelectedCellIndex,
  firstSelectedCellType,
} from '../../../../../../../redux/workbench/selectors/notebook.selectors';
// @ts-ignore
import {
  addCell,
  moveCellsUp,
  moveCellsDown,
  copyCellsToClipboard,
  selectCells,
  deleteCells,
  pasteCellsFromClipboard,
  changeCellType,
} from '../../../../../../../redux/workbench/modules/notebook.module';
// @ts-ignore
import { info as infoNotification } from 'react-notification-system-redux';
import { getAvailableCells } from '../../../../../../workbench/fileTypes/notebook/component/notebook-cells/NotebookCellManager';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    selectedCells:
      (state.workbench.notebooks[BASE_FILE_PATH] || {}).selectedCells || [],
    content: (state.workbench.notebooks[BASE_FILE_PATH] || {}).content || [],
    selectedCellIndex: firstSelectedCellIndex(state, BASE_FILE_PATH),
    clipboard: state.workbench.clipboard,
    availableCells: getAvailableCells(null, null, null),
    selectedCellType: firstSelectedCellType(state, BASE_FILE_PATH),
  };
}

export const mapDispatchToProps = {
  addCell,
  moveCellsUp,
  moveCellsDown,
  copyCellsToClipboard,
  selectCells,
  deleteCells,
  infoNotification,
  pasteCellsFromClipboard,
  changeCellType,
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsBaseFile);
