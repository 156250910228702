import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import NodeShape from './NodeShape';

interface Props {
  node: {
    id: string | number;
    type: string;
    label: string;
    operation: string;
    operands: string[];
    unary: boolean;
    depth: number;
    positive: boolean;
    negative: boolean;
  };
}
export default class TreePathNode extends Component<Props> {
  renderOperation() {
    const { node } = this.props;
    const { operation, operands, unary } = node;

    if (unary) {
      return `${operation} ${
        operands[0] ? parseFloat(operands[0]).toFixed(3) : '-'
      }`;
    }
    return '';
  }

  render() {
    const { node } = this.props;

    const isLeaf = node.type === 'leaf';
    const nodeClassName = classNames('tree-chart_node', {
      'tree-chart_node--positive': node.positive,
      'tree-chart_node--negative': node.negative,
      'tree-chart_node--leaf': isLeaf,
    });

    return (
      <div className={styles.selectionInfoItem}>
        <svg className={styles.selectionInfoIcon} width='20' height='20'>
          <NodeShape
            className={nodeClassName}
            circle={{ cx: 10, cy: 10, r: 5 }}
            isLeaf={isLeaf}
          />
        </svg>
        <div className={styles.selectionInfoLabel}>
          {node.label}
          <div className={styles.selectionInfoList}>
            {this.renderOperation()}
          </div>
        </div>
      </div>
    );
  }
}
