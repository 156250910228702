import PropTypes from 'prop-types';
import React from 'react';
import { select } from 'd3-selection';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

export default class Link extends React.Component {
  static propTypes = {
    link: PropTypes.object.isRequired,
    isHighLighted: PropTypes.bool.isRequired,
    totalRecordCount: PropTypes.number.isRequired,
    linkShapeFunc: PropTypes.func.isRequired,
    linkThicknessFunc: PropTypes.func.isRequired,
    animationDuration: PropTypes.shape({
      mount: PropTypes.shape({
        delay: PropTypes.number.isRequired,
        duration: PropTypes.number.isRequired,
      }),
      update: PropTypes.shape({
        delay: PropTypes.number.isRequired,
        duration: PropTypes.number.isRequired,
      }),
      exit: PropTypes.shape({
        delay: PropTypes.number.isRequired,
        duration: PropTypes.number.isRequired,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.linkRef = null;
    this.setLinkRef = this.setLinkRef.bind(this);
    this.handleComponentExit = this.handleComponentExit.bind(this);
  }

  componentDidMount() {
    const pathLength = this.linkRef.getTotalLength();
    this.linkRef.style.strokeDasharray = `${pathLength} ${pathLength}`;
    this.linkRef.style.strokeDashoffset = 0;
    this.animateLink(
      0,
      this.props.animationDuration.mount.delay,
      this.props.animationDuration.mount.duration
    );
  }

  componentDidUpdate({ link: { source, target } }) {
    const { link } = this.props;
    if (
      link.source.x !== source.x ||
      link.source.y !== source.y ||
      link.target.x !== target.x ||
      link.target.y !== target.y
    ) {
      this.linkRef.style.strokeDasharray = null;
      this.linkRef.style.strokeDashoffset = null;
      this.animateLink(
        0,
        this.props.animationDuration.update.delay,
        this.props.animationDuration.update.duration
      );
    }
  }

  setLinkRef(ref) {
    this.linkRef = ref;
  }

  handleComponentExit() {
    const pathLength = this.linkRef.getTotalLength();
    this.linkRef.style.strokeDasharray = `${pathLength} ${pathLength}`;
    this.linkRef.style.strokeDashoffset = 0;
    this.animateLink(
      pathLength,
      this.props.animationDuration.exit.delay,
      this.props.animationDuration.exit.duration
    );
  }

  animateLink(length, delay, duration) {
    const { link } = this.props;
    const d = this.props.linkShapeFunc(
      { x: link.source.x, y: link.source.y },
      { x: link.target.x, y: link.target.y }
    );

    select(this.linkRef)
      .transition()
      .delay(delay)
      .duration(duration)
      .style('stroke-dashoffset', length)
      .attr('d', d);
  }

  render() {
    const {
      link,
      isHighLighted,
      totalRecordCount,
      linkThicknessFunc,
      animationDuration,
      ...restProps
    } = this.props;

    const className = classNames({
      link: true,
      'link--highlighted': !isHighLighted,
    });

    const strokeWidth = linkThicknessFunc(
      (link.target.data.recordCount * 100) / totalRecordCount
    );

    return (
      <Transition
        timeout={{
          exit: animationDuration.exit.delay + animationDuration.exit.duration,
        }}
        onExit={this.handleComponentExit}
        {...restProps}
      >
        <path
          ref={this.setLinkRef}
          style={{ strokeWidth: `${strokeWidth}px` }}
          className={className}
        />
      </Transition>
    );
  }
}
