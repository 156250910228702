import PropTypes from 'prop-types';
import React, { ComponentType } from 'react';
import { renderRoutes } from 'react-router-config';
import { Redirect } from 'react-router-dom';

import SideNavContainer from './sidenav/SideNav';
import codeCapsuleRoutes, {
  codeCapsuleDefaultPage,
} from './codeCapsuleDetailRoutes';
import SubHeader from '../organisms/sub-header/SubHeader.container';
import OverlayCodeCapsuleDetails from '../organisms/sub-header/overlay-code-capsule-details/OverlayCodeCapsuleDetails.container';
import MainContainer from '../pages/main-container/MainContainer';
import WithSidemenu from '../molecules/with-sidemenu/WithSidemenu';
import { FiHash, FiPlay } from 'react-icons/fi';

export default class CodeCapsuleDetails extends React.Component {
  componentDidMount() {
    const { codeCapsuleCode, fetchSingleCodeCapsule, habitatCode } = this.props;
    if (codeCapsuleCode && habitatCode)
      fetchSingleCodeCapsule(codeCapsuleCode, habitatCode);
  }

  getActiveCategory() {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    if (!pathname) return null;
    const parts = pathname.split('/');
    if (parts.length < 7) return null;

    const active = parts[6]; // TODO No good idea to pick out the active category like this ...
    return active;
  }

  render() {
    const { match } = this.props;

    const { habitatCode, codeCapsuleCode } = match.params;

    if (match.isExact) {
      return <Redirect to={`${match.url}/${codeCapsuleDefaultPage}`} />;
    }

    const selectedCategory = this.getActiveCategory();

    const menuEntries = [
      {
        type: 'link',
        id: 'versions',
        nameIntlId: 'no-id',
        nameDefault: 'Versions',
        icon: () => <FiHash size={16} />,
        to: `/app/habitat/${habitatCode}/codecapsule/${codeCapsuleCode}/versions`,
        activePerDefault: true,
      },
      {
        type: 'link',
        id: 'executions',
        nameIntlId: 'no-id',
        nameDefault: 'Executions',
        icon: () => <FiPlay size={16} />,
        to: `/app/habitat/${habitatCode}/codecapsule/${codeCapsuleCode}/executions`,
        activePerDefault: true,
      },
    ];

    return (
      <MainContainer>
        <SubHeader
          hasOverlay
          Overlay={OverlayCodeCapsuleDetails}
          defaultSelectedTabId={'models'}
        />

        <WithSidemenu
          menuEntries={menuEntries}
          selectedTab={selectedCategory}
          handleSelect={() => {
            /* Nothing to do, right? */
          }}
        >
          {renderRoutes(codeCapsuleRoutes())}
        </WithSidemenu>
      </MainContainer>
    );
  }
}

CodeCapsuleDetails.propTypes = {
  codeCapsuleCode: PropTypes.string,
  habitatCode: PropTypes.string,
  match: PropTypes.object.isRequired,
  fetchSingleCodeCapsule: PropTypes.func.isRequired,
};
