import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  fieldNameDefaultValue,
  fieldNameSelectableValues,
  getValueFromSettingsField,
} from '../_interface/input-element.form';
import TextAreaCol from '../../../settings-dialog/dialog-settings-cols/TextAreaCol';
import DropdownSelectCol from '../../../settings-dialog/dialog-settings-cols/DropdownSelectCol';
import { variableShape } from '../_interface/P3InputElementShape';
import { VARIABLE_TYPES } from '../../variables/variableTypes';
import { inputModalForm } from '../../../settings-dialog/inpmodalDialog.form';

class DropdownSelectSettings extends Component {
  render() {
    const {
      selectableValues: selectableValuesField,
      variables,
      path,
      cellId,
      showSelectVariable,
    } = this.props;

    const selectableValues = getValueFromSettingsField(
      selectableValuesField,
      variables
    );
    let options = [];
    // This is a special case for the Dropdown Select Settings field unfortunately:
    //  When a variable is used, it's a list of Strings.
    //  But the user input comes as a a big string from the text area ...
    if (selectableValuesField.origin === 'variable') {
      // Variable
      options = selectableValues
        ? selectableValues.map((v) => ({ label: v, value: v }))
        : [];
    } else {
      // User input
      options = selectableValues
        ? selectableValues.split('\n').map((v) => ({ label: v, value: v }))
        : [];
    }

    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldNameDefaultValue}
            label={'Default Value (optional)'}
            component={DropdownSelectCol}
            placeholder={'Enter Default Value'}
            options={options}
          />
        </div>
        <div className={'input-row'}>
          <Field
            name={fieldNameSelectableValues}
            label={'Selectable Values'}
            component={TextAreaCol}
            placeholder={'Enter the selectable values'}
            allowVariable
            path={path}
            cellId={cellId}
            showSelectVariable={showSelectVariable}
            validVariableTypes={[VARIABLE_TYPES.LIST_OF_STRING]}
          />
        </div>
      </Fragment>
    );
  }
}

DropdownSelectSettings.propTypes = {
  selectableValues: PropTypes.shape({
    inputValue: PropTypes.arrayOf(PropTypes.string),
  }),
  variables: PropTypes.arrayOf(variableShape),
  path: PropTypes.string,
  cellId: PropTypes.string,
  showSelectVariable: PropTypes.func.isRequired,
};
DropdownSelectSettings.defaultProps = {
  selectableValues: [],
};

export default reduxForm(inputModalForm)(DropdownSelectSettings);
