import { connect } from 'react-redux';
import UploadElement from './UploadElement';

export function mapStateToProps(state, otherProps) {
  const uploadId = otherProps.element.id;
  const uploadElement = state.workbench.upload[uploadId] || {};

  // Derive the uploaded files list
  const uploadProgressSafe = uploadElement.uploadProgress || {};
  const uploadedFiles = Object.keys(uploadProgressSafe).filter(
    (name) => uploadProgressSafe[name].status === 'done'
  );

  return {
    uploadId,
    uploadedFiles,
  };
}

export default connect(mapStateToProps)(UploadElement);
