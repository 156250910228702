import { connect, ConnectedProps } from 'react-redux';
import PreQueue from './PreQueue';
import { fetchPreQueue } from '../../../../redux/modules/orchestration.queue.module';
import { DeprecatedRootState } from '../../../../store/state.type';

export type OwnProps = {
  jobGroupCodes: string[];
  loading: boolean; // List of all JobGroup codes;
  loaded: boolean;
  error?: string;
  fetchPreQueue(...args: unknown[]): unknown;
  cancelJobGroup(...args: unknown[]): unknown;
};

export type Props = OwnProps & ConnectedProps<typeof connector>;

export function mapStateToProps(
  state: DeprecatedRootState,
  ownProps: OwnProps
) {
  return {
    loading: state.queue.pre.loading,
    loaded: state.queue.pre.loaded,
    error: state.queue.pre.error,
    jobGroupCodes: state.queue.pre.jobGroupCodes,
  };
}

export const mapDispatchToProps = {
  fetchPreQueue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(PreQueue);
