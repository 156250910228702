export const initial = {
  tooltipX: 0,
  tooltipY: 0,
  tooltipData: [],
  isTooltip: false,
};

export const test = {
  tooltipX: 100,
  tooltipY: 100,
  tooltipData: [
    { bucket: 'True Positives', percent: '10%' },
    { bucket: 'True Negatives', percent: '20%' },
    { bucket: 'False Positives', percent: '20%' },
    { bucket: 'False Negatives', percent: '50%' },
  ],
  isTooltip: true,
};
