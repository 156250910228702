import React, { Component } from 'react';
import { DatapoolType } from 'common/dist/types/datapool';
import { CProps } from './WithTableSample.container';
import { nameToTableName } from '../../../../../dataManagement/util';
import _ from 'lodash';

export type OwnProps = {
  /** Selected datapool
   * Marked as optional for safety reasons - it might not be there while the parent component is still loading the
   * datapool that is then passed into this component (for example in the AugurDetails)
   */
  datapool?: DatapoolType;
  /** name of the selected table */
  tableName?: string;
  /** Child component */
  children: React.ReactNode;
};

export type Props = OwnProps & CProps;

export type WithTableSampleProps = Pick<
  CProps,
  | 'dataSources'
  | 'tablesLoading'
  | 'tablesLoaded'
  | 'tables'
  | 'tableName'
  | 'tableSample'
>;

/**
 * Wrapper Component that adds tables and a table sample from tableName.
 * Despite the name the tables are also often required in the wrapped components
 */
export default class WithTableSample extends Component<Props> {
  componentDidMount() {
    const {
      dataSources,
      datapool,
      datapoolDataSource,
      fetchDataSources,
      fetchCassandraTables,
      tables,
      tablesLoading,
      fetchBucketContent,
    } = this.props;
    // 1. Make sure the Data Sources are loaded
    if (!dataSources || !dataSources.data || dataSources.data.length === 0) {
      if (!dataSources.loading) {
        fetchDataSources();
      }
    }
    // 2. If possible (since the datapoolCode and cassandraDsCode are there) -> Load the tables
    if ((!tables || tables.length === 0) && datapool?.code) {
      if (!tablesLoading) {
        switch (datapoolDataSource?.ds_type) {
          case 'cassandra': {
            const keyspaceName = `datapool_${datapool.code.toLowerCase()}`;
            fetchCassandraTables(datapool.dataSourceCode, keyspaceName);
            break;
          }
          case 's3': {
            const bucketName = `datapool-${datapool.code.toLowerCase()}`;
            fetchBucketContent(datapool.dataSourceCode, bucketName, '');
          }
        }
      }
    }
  }

  /**
   * @param prevProps
   */
  componentDidUpdate(prevProps: Props) {
    const {
      dataSources,
      datapool,
      datapoolDataSource,
      fetchDataSources,
      fetchCassandraTables,
      tableName,
      fetchCassandraTableSample,
      fetchBucketContent,
      fetchPreview,
    } = this.props;
    // 1. Make sure the Data Sources are loaded
    if (
      (!dataSources || !dataSources.data || dataSources.data.length === 0) &&
      !dataSources.loading
    ) {
      if (!dataSources.loading) {
        fetchDataSources();
      }
    }
    // 2. If the datapoolCode or dataSource changed, re-load the tables!
    if (
      (datapool?.code !== prevProps.datapool?.code ||
        !_.isEqual(datapoolDataSource, prevProps.datapoolDataSource)) &&
      datapoolDataSource &&
      datapool.code
    ) {
      switch (datapoolDataSource?.ds_type) {
        case 'cassandra': {
          const keyspaceName = `datapool_${datapool.code.toLowerCase()}`;
          fetchCassandraTables(datapoolDataSource.code, keyspaceName);
          break;
        }
        case 's3': {
          const bucketName = `datapool-${datapool.code.toLowerCase()}`;
          fetchBucketContent(datapoolDataSource.code, bucketName, '');
        }
      }
    }
    // 3. If the selected training table changed - update the available columns
    if (
      (datapool?.code !== prevProps.datapool?.code ||
        !_.isEqual(datapoolDataSource, prevProps.datapoolDataSource) ||
        prevProps.tableName !== tableName) &&
      datapoolDataSource &&
      datapool.code &&
      tableName
    ) {
      switch (datapoolDataSource?.ds_type) {
        case 'cassandra': {
          const keyspaceName = `datapool_${datapool.code.toLowerCase()}`;
          fetchCassandraTableSample(
            datapoolDataSource.code,
            keyspaceName,
            tableName
          );
          break;
        }
        case 's3': {
          const bucketName = `datapool-${datapool.code.toLowerCase()}`;
          const bucketSlashPath = `${bucketName}/${nameToTableName(tableName)}`;
          fetchPreview(datapoolDataSource.code, bucketSlashPath);
        }
      }
    }
  }

  render() {
    const {
      children,
      dataSources,
      tablesLoading,
      tablesLoaded,
      tables,
      tableName,
      tableSample,
    } = this.props;

    const childProps: WithTableSampleProps = {
      ...this.props, // Needed to pass through other injected props like from wrapping inside a <Field>
      dataSources,
      tablesLoading,
      tablesLoaded,
      tables,
      tableName,
      tableSample,
    };

    const childrenWithProps = React.Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, childProps);
      }
      return child;
    });

    return <div>{childrenWithProps}</div>;
  }
}
