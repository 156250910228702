import AugurDetails from '../../../common/_interfaces/augur-details/AugurDetails';
import { AugurDetailsTab } from '../../../common/_interfaces/augur-details/AugurDetailsTab';
import SettingsWrapper from '../../../common/augur-details-tabs/settings/Settings.wrapper';
import AugurSettingsCategory from '../../../common/_interfaces/augur-details/settings/AugurSettingsCategory';
import NamesAndCodesCategory from '../../../common/augur-details-tabs/settings/augurSettingsCategories/namesAndCodes/NamesAndCodesCategory';
import RealtimeWrapper from '../../../common/augur-details-tabs/realtime/Realtime.wrapper';
import ModelSettingsWrapper from '../../../common/augur-details-tabs/model-settings/ModelSettings.wrapper';
import TaggerDictionaryWrapper from '../../../common/augur-details-tabs/model-settings/modelSettingsCategories/taggerDictionary/TaggerDictionary.wrapper';
import ModelSettingsCategory from '../../../common/_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNlt } from 'common/dist/types/augurSettings';

/**
 * Augur Details for the binary classification (event-based) augur
 */
export default class NltAugurDetails extends AugurDetails {
  // Specification of the sub-components of the Settings tab
  settingsCategories: AugurSettingsCategory[] = [new NamesAndCodesCategory()];

  // Tabs to show on the left in the Augur Details view
  tabs: (inputFormat: string | undefined) => AugurDetailsTab[] = (
    inputFormat
  ) => {
    const modelSettingsCategories: ModelSettingsCategory<AugurSettingsDataNlt>[] =
      [new TaggerDictionaryWrapper()];

    return [
      {
        type: 'headline',
        headline: {
          id: 'details.sideNav.settings',
          defaultMessage: 'Settings',
        },
      },
      new ModelSettingsWrapper(modelSettingsCategories),
      new RealtimeWrapper(['rest']),
      new SettingsWrapper(this.settingsCategories),
    ];
  };
}
