import { createAction } from 'redux-act';

export const onMenu = createAction('click on menu button');

export const closeMenu = createAction('click outside of menu button');

export const reducer = {
  [onMenu]: (state) => ({
    ...state,
    menu: {
      ...state.menu,
      isMenuShown: !state.menu.isMenuShown,
    },
  }),

  [closeMenu]: (state) => ({
    ...state,
    menu: {
      ...state.menu,
      isMenuShown: false,
    },
  }),
};
