// @ts-ignore
import { ComponentType } from 'react';
// @ts-ignore
import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import { MessageDescriptor } from 'react-intl';
import { FiTrendingUp } from 'react-icons/fi';
import PerformanceDrift from './PerformanceDrift';

export default class PerformanceDriftWrapper extends ComponentAugurDetailsTab {
  component: ComponentType = PerformanceDrift;
  icon: ComponentType = FiTrendingUp;
  id = 'performance-drift';
  to = (habitatCode: string, augurCode: string, shortAugurType: string) =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/performance-drift`;
  subRoutes: object[] | undefined = undefined;
  title: MessageDescriptor = {
    id: 'details.sideNav.performance_drift',
    defaultMessage: 'Performance Drift',
  };
}
