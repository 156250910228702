import React, { Component, Fragment } from 'react';
import './styles.scss';
import { ContextMenuTrigger } from 'react-contextmenu';
import Plot from './Plot';
import ContextMenuCategory from './ContextMenuCategory';
import Sizer from '../../../../../../../../../../../atoms/sizer/Sizer';

export interface DataElement {
  x: number; // x-Coordinate
  y: number; // y-Coordinate
  id: string; // ID of the datapoint
}

export interface SelectionElement {
  id: string;
  category: string; // Assigned category
}

interface Props {
  /** List of categories */
  categories: string[];
  defaultCategory: string;
  /** Category -> Color mapping */
  colorsMap: { [category: string]: string };
  /** Selected category that will be used to mark the data points */
  selectedCategory: string;
  /** List of data points to display */
  data: DataElement[];
  selection: SelectionElement[];

  selectCategory(category: string): void;

  updateSelection(data: SelectionElement[]): void;
}

const menuId = (category: string) => `menu-${category}`;

export default class SelectableScatterPlot extends Component<Props> {
  render() {
    const {
      categories,
      selectedCategory,
      selectCategory,
      data,
      selection,
      updateSelection,
      colorsMap,
      defaultCategory,
    } = this.props;

    return (
      <div className={'Ssp'}>
        <div className={'Ssp--categories-parent'}>
          {categories.map((category) => (
            <Fragment>
              <ContextMenuTrigger id={menuId(category)} holdToDisplay={-1}>
                <div
                  className={
                    'Ssp--category-tile' +
                    (selectedCategory === category
                      ? ' Ssp--category-selected'
                      : '')
                  }
                  onClick={() => selectCategory(category)}
                >
                  <span>{category}</span>
                  {colorsMap &&
                    colorsMap[category] && ( // If the colorsMap is user defined: Render the legend
                      <div
                        className={'legend-circle'}
                        style={{ backgroundColor: colorsMap[category] }}
                      />
                    )}
                </div>
              </ContextMenuTrigger>

              <ContextMenuCategory
                menuId={menuId(category)}
                onSelectAll={() =>
                  updateSelection(data.map((d) => ({ id: d.id, category })))
                }
              />
            </Fragment>
          ))}
        </div>
        <Sizer>
          {/* @ts-ignore */}
          <Plot
            data={data}
            selection={selection}
            updateSelection={updateSelection}
            selectedCategory={selectedCategory}
            categories={categories}
            colorsMap={colorsMap}
            defaultCategory={defaultCategory}
          />
        </Sizer>
      </div>
    );
  }
}
