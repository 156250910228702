// TODO Those IDs are duplicated in the module/... implementations of the augur details too
// Augur Details
export const influencers = 'influencers';
export const modelViewer = 'model-viewer';
export const accuracy = 'accuracy';
export const performance = 'performance-drift';
export const settings = 'settings';
export const download = 'download';
export const rejectedFeatures = 'rejected-features';
export const candidateConditions = 'candidate-conditions';
export const modelHistory = 'model-history';

// Datapool Details
export const taxonomy = 'taxonomy';
// TODO is there the need to make this dynamic?
