import { connect } from 'react-redux';
import StepKeyspace from './StepKeyspace';
import {
  fetchCassandraKeyspaces,
  fetchCassandraPermissions,
} from '../../../../../../redux/modules/data.cassandra.module';
import { CASSANDRA_KEYSPACE_SCOPE_IDS } from 'common/dist/constants/keycloak';

export function mapStateToProps(state, { dataSourceCode }) {
  const { loading, data } =
    (state.data.cassandra[dataSourceCode] || {}).keyspaces || {};
  const permissions = state.data.cassandra[dataSourceCode]?.permissions || {};
  return {
    availableKeyspaces: data?.filter((k) =>
      new Set(permissions.data?.[k.name]).has(
        CASSANDRA_KEYSPACE_SCOPE_IDS.MODIFY
      )
    ),
    keyspacesLoading: loading,
  };
}

export const mapDispatchToProps = {
  fetchCassandraKeyspaces,
  fetchCassandraPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepKeyspace);
