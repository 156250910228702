import AugurDetails from '../../../common/_interfaces/augur-details/AugurDetails';
import { AugurDetailsTab } from '../../../common/_interfaces/augur-details/AugurDetailsTab';
import ModelsHistoryWrapper from '../../../common/augur-details-tabs/modelhistory/ModelsHistory.wrapper';
import ModelViewerWrapper from '../../../common/augur-details-tabs/binary-modelviewer/ModelViewer.wrapper';
import PerformanceDriftWrapper from '../../../common/augur-details-tabs/performance-drift/PerformanceDrift.wrapper';
import InfluencersWrapper from '../../../common/augur-details-tabs/influencers/Influencers.wrapper';
import ModelTuningResultsWrapper from '../../../common/augur-details-tabs/model-tuning-results/ModelTuningResults.wrapper';
import AccuracyWrapper from '../../../common/augur-details-tabs/accuracy/Accuracy.wrapper';
import SettingsWrapper from '../../../common/augur-details-tabs/settings/Settings.wrapper';
import AccuracyDetailsWrapper from '../../../common/augur-details-tabs/accuracyDetails/AccuracyDetails.wrapper';
import AugurSettingsCategory from '../../../common/_interfaces/augur-details/settings/AugurSettingsCategory';
import AugurBiographyWrapper from '../../../common/augur-details-tabs/augur-biography/AugurBiography.wrapper';
import NamesAndCodesCategory from '../../../common/augur-details-tabs/settings/augurSettingsCategories/namesAndCodes/NamesAndCodesCategory';
import RealtimeWrapper from '../../../common/augur-details-tabs/realtime/Realtime.wrapper';
import ModelSettingsWrapper from '../../../common/augur-details-tabs/model-settings/ModelSettings.wrapper';
import ModelTuningCategoryWrapper from '../../../common/augur-details-tabs/model-settings/modelSettingsCategories/modelTuning/ModelTuningCategory.wrapper';
import RelearningCategoryWrapper from '../../../common/augur-details-tabs/model-settings/modelSettingsCategories/relearning/RelearningCategory.wrapper';
import InputDataCategoryWrapper from '../../../common/augur-details-tabs/model-settings/modelSettingsCategories/inputData/InputDataCategory.wrapper';
import SamplingStrategyCategoryWrapper from '../../../common/augur-details-tabs/model-settings/modelSettingsCategories/samplingStrategy/SamplingStrategyCategory.wrapper';
import { POPULATION_EVENTS, TRAINING } from 'common/dist/types/datapool';
import PredictionTargetsCategoryWrapper from '../../../common/augur-details-tabs/model-settings/modelSettingsCategories/predictionTargets/PredictionTargetsCategory.wrapper';
import CandidateConditionsCategoryWrapper from '../../../common/augur-details-tabs/model-settings/modelSettingsCategories/candidateConditions/CandidateConditionsCategory.wrapper';
import ScoreDistributionWrapper from '../../../common/augur-details-tabs/score-distribution/ScoreDistribution.wrapper';
import ModelSettingsCategory from '../../../common/_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

/**
 * Augur Details for the binary classification (event-based) augur
 */
export default class BcAugurDetails extends AugurDetails {
  // Specification of the sub-components of the Settings tab
  settingsCategories: AugurSettingsCategory[] = [new NamesAndCodesCategory()];

  // Tabs to show on the left in the Augur Details view
  tabs: (inputFormat: string | undefined) => AugurDetailsTab[] = (
    inputFormat
  ) => {
    if (!inputFormat) {
      console.warn(
        'InputFormat is empty. No way to know which categories to add.'
      );
    }
    const modelSettingsCategories: ModelSettingsCategory<AugurSettingsDataNbc>[] =
      [
        new ModelTuningCategoryWrapper(),
        new SamplingStrategyCategoryWrapper(),
        new RelearningCategoryWrapper(),
        new CandidateConditionsCategoryWrapper(),
      ];

    if (inputFormat === POPULATION_EVENTS) {
      modelSettingsCategories.push(new PredictionTargetsCategoryWrapper());
    } else if (inputFormat === TRAINING) {
      modelSettingsCategories.push(new InputDataCategoryWrapper());
    }

    return [
      {
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Evaluation' },
      },
      new AccuracyWrapper(),
      new AccuracyDetailsWrapper(),
      new PerformanceDriftWrapper(),

      { type: 'hr' },
      {
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Learning' },
      },
      new ModelViewerWrapper(),
      new InfluencersWrapper(),
      new ModelTuningResultsWrapper(),

      { type: 'hr' },
      {
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Prediction' },
      },
      new ScoreDistributionWrapper(),

      { type: 'hr' },
      {
        type: 'headline',
        headline: { id: 'no-id', defaultMessage: 'Model Management' },
      },
      new ModelsHistoryWrapper(),
      new AugurBiographyWrapper(),

      { type: 'hr' },
      {
        type: 'headline',
        headline: {
          id: 'details.sideNav.settings',
          defaultMessage: 'Settings',
        },
      },
      new RealtimeWrapper(['rest', 'zmq-mqtt']),
      new ModelSettingsWrapper(modelSettingsCategories),
      new SettingsWrapper(this.settingsCategories),
    ];
  };
}
