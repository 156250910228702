import React from 'react';
import PropTypes from 'prop-types';
import Label from '../atoms/label/Label';

/**
 * Displays the label for the repository type
 * @param props
 * @returns {*}
 * @constructor
 */
export default function RepoType(props) {
  const { repoType } = props;
  return (
    <div className={'repo-type'}>
      {repoType === 'plain' && (
        <Label
          text={{ defaultMessage: 'Plain' }}
          backgroundColor={'grey'}
          borderColor={'grey'}
        />
      )}
      {repoType === 'code-capsule' && (
        <Label
          text={{ defaultMessage: 'Code Capsule' }}
          backgroundColor={'blue'}
          borderColor={'blue'}
        />
      )}
      {repoType === 'app' && (
        <Label
          text={{ defaultMessage: 'App' }}
          backgroundColor={'light-blue'}
          borderColor={'light-blue'}
        />
      )}
      {repoType === 'archetype' && (
        <Label
          text={{ defaultMessage: 'Archetype' }}
          backgroundColor={'green'}
          borderColor={'green'}
        />
      )}
    </div>
  );
}
RepoType.propTypes = {
  /** Allowed values: plain / code-capsule / app / archetype */
  repoType: PropTypes.string,
};
