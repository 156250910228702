import {
  getCpuValueInMilliunits,
  getQuantityInBytes,
  isValidK8sCpuSpec,
  isValidK8sMemorySpec,
} from 'common/dist/utils/kubernetes';
import {
  WB_DEF_CPU_LIM,
  WB_DEF_CPU_REQ,
  WB_DEF_MEM_LIM,
  WB_DEF_MEM_REQ,
  WB_MAX_CPU,
  WB_MAX_GPU,
  WB_MAX_MEM,
} from './Attributes.form';

// Use field level validation since the form validation seems to ignore newly added fields

const memoryRegex = /^([0-9]+)([KMGT]?)$/;
// Apparently in jupyter-hub land 1K means 1024 and not 1000
const suffixFactor = {
  K: 1024,
  M: Math.pow(1024, 2),
  G: Math.pow(1024, 3),
  T: Math.pow(1024, 4),
};

function translateMemory(value: string) {
  const match = value.match(memoryRegex);
  if (match === null) {
    return null;
  } else {
    return Number(match[1]) * (match[2] === '' ? 1 : suffixFactor[match[2]]);
  }
}

export function validate(value: string | undefined, allValues, props, name) {
  let error = undefined;

  if (!value) {
    error = 'Please enter a value';
  } else if (
    (name === WB_MAX_MEM ||
      name === WB_DEF_MEM_LIM ||
      name === WB_DEF_MEM_REQ) &&
    !isValidK8sMemorySpec(value, true)
  ) {
    error = 'Please enter valid memory spec, for example: 2Gi';
  } else if (
    (name === WB_MAX_CPU ||
      name === WB_DEF_CPU_LIM ||
      name === WB_DEF_CPU_REQ) &&
    !isValidK8sCpuSpec(value)
  ) {
    error = 'Please enter a valid CPU spec, for example: 2.5';
  } else if (
    name === WB_MAX_GPU &&
    !isValidK8sCpuSpec(value, true, false) &&
    value !== '0'
  ) {
    error = 'Please enter a valid GPU spec, for example: 1';
  } else if (
    allValues[WB_MAX_CPU] &&
    (name === WB_DEF_CPU_LIM || name === WB_DEF_CPU_REQ) &&
    getCpuValueInMilliunits(value) >
      getCpuValueInMilliunits(allValues[WB_MAX_CPU])
  ) {
    error = 'The default CPU cannot exceed the maximum CPU';
  } else if (
    allValues[WB_DEF_CPU_LIM] &&
    name === WB_DEF_CPU_REQ &&
    getCpuValueInMilliunits(value) >=
      getCpuValueInMilliunits(allValues[WB_DEF_CPU_LIM])
  ) {
    error = 'The default CPU request must be lower than the default CPU limit';
  } else if (
    allValues[WB_MAX_MEM] &&
    (name === WB_DEF_MEM_LIM || name === WB_DEF_MEM_REQ) &&
    getCpuValueInMilliunits(value) >
      getCpuValueInMilliunits(allValues[WB_MAX_MEM])
  ) {
    error = 'The default memory cannot exceed the maximum memory';
  } else if (
    allValues[WB_DEF_MEM_LIM] &&
    name === WB_DEF_MEM_REQ &&
    getCpuValueInMilliunits(value) >=
      getCpuValueInMilliunits(allValues[WB_DEF_MEM_LIM])
  ) {
    error =
      'The default memory request must be lower than the default memory limit';
  }

  return error;
}
