import React, { FC } from 'react';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import { formatDate } from 'common/dist/utils/dates';
import { FiAlertCircle } from 'react-icons/fi';
import Checkbox from '../../../../../../../atoms/checkbox/Checkbox';
import { PredictionReportType } from 'common/dist/types/reports';

type Props = {
  data: PredictionReportType[];
  /** Code of the active model for this augur */
  activeModelCode?: string;
  /** List of job codes that were selected (state of AllScoringRuns)*/
  selectedReports: string[];
  /** Update the list of selected job codes (updates the state of AllScoringRuns) */
  selectReports: (selectedReports: string[]) => void;
};

const AllScoringRunsList: FC<Props> = (props: Props) => {
  const { data, activeModelCode, selectedReports, selectReports } = props;

  const renderHeadline = () => {
    return (
      <div className={classNames(styles.listRow, styles.listRowHeadline)}>
        <div
          className={classNames(styles.listColumn, styles.listColumnCreatedAt)}
        >
          Run Date
        </div>

        <div
          className={classNames(styles.listColumn, styles.listColumnInputTable)}
        >
          Input Table
        </div>

        <div
          className={classNames(
            styles.listColumn,
            styles.listColumnOutputTable
          )}
        >
          Output Table
        </div>

        <div
          className={classNames(styles.listColumn, styles.listColumnModelCode)}
        >
          Model Code
        </div>

        <div
          className={classNames(styles.listColumn, styles.listColumnCheckbox)}
        />
      </div>
    );
  };

  const renderEntry = (report: PredictionReportType) => {
    const {
      code,
      createdAt,
      data: { inputTable, outputTable },
      modelCode,
    } = report;
    return (
      <div
        className={classNames(styles.listRow, `all_scoring_runs_row_${code}`)}
      >
        <div
          className={classNames(styles.listColumn, styles.listColumnCreatedAt)}
        >
          {createdAt && formatDate(new Date(createdAt), 'dd.MM.yyyy HH:mm:ss')}
        </div>

        <div
          className={classNames(styles.listColumn, styles.listColumnInputTable)}
        >
          {inputTable}
        </div>

        <div
          className={classNames(
            styles.listColumn,
            styles.listColumnOutputTable
          )}
        >
          {outputTable}
        </div>

        <div
          className={classNames(styles.listColumn, styles.listColumnModelCode)}
        >
          {modelCode}
          {activeModelCode && activeModelCode !== modelCode && (
            <div
              className={classNames(styles.alertIcon)}
              title={'Model is not active for this Augur anymore'}
            >
              <FiAlertCircle size={14} />
            </div>
          )}
        </div>

        <div
          className={classNames(styles.listColumn, styles.listColumnCheckbox)}
        >
          <Checkbox
            disabled={false}
            checked={(selectedReports || []).includes(code)}
            onChange={() => {
              if (!(selectedReports || []).includes(code)) {
                // Add the job code
                selectReports([...(selectedReports || []), code]);
              } else {
                // Remove the job code
                selectReports(
                  (selectedReports || []).filter((x) => x !== code)
                );
              }
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.listLoaded}>
      {renderHeadline()}
      {data?.map((report: PredictionReportType) => renderEntry(report))}
    </div>
  );
};

export default AllScoringRunsList;
