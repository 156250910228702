import { connect, ConnectedProps as ConnectedProps_ } from 'react-redux';
import { destroy, getFormValues, reduxForm } from 'redux-form';
import {
  dsForm,
  fieldDSType,
  fieldName,
  fieldRole,
  fieldSettings,
  formName,
  FormValues,
} from './form';
import AddUpdateDataSource, { Props } from './AddUpdateDataSource';
import { DeprecatedRootState } from '../../../../store/state.type';
import {
  addDataSource,
  fetchDataSources,
  fetchDataSourceSettings,
  updateDataSource,
} from '../../../../redux/modules/data.dataSources.module';
import {
  CassandraSettings,
  DataSource,
  S3Settings,
} from 'common/dist/types/dataManagement/dataSource';

export function buildDataSourceForAdding(formValues: FormValues): DataSource {
  const settings = formValues[fieldSettings];
  const dsType = formValues[fieldDSType];
  const role = formValues[fieldRole];
  let typedSettings;
  if (dsType === 'cassandra') {
    const cassandraSettings = settings as CassandraSettings;
    typedSettings = {
      ...settings,
      port: Number(cassandraSettings.port),
      user: cassandraSettings.user === undefined ? '' : cassandraSettings.user,
      password:
        cassandraSettings.password === undefined
          ? ''
          : cassandraSettings.password,
    };
  } else if (dsType === 's3') {
    const s3Settings = settings as S3Settings;
    typedSettings = {
      ...settings,
      port: Number(s3Settings.port),
      accessKey: s3Settings.accessKey === undefined ? '' : s3Settings.accessKey,
      secretKey: s3Settings.secretKey === undefined ? '' : s3Settings.secretKey,
    };
  }
  return {
    name: formValues[fieldName],
    code: '',
    ds_type: formValues[fieldDSType],
    role,
    settings: typedSettings,
    created_at: '',
  };
}

export function buildDataSourceForUpdating(
  formValues: FormValues,
  dataSourceCode: string
): DataSource {
  const ds = buildDataSourceForAdding(formValues);
  ds.code = dataSourceCode;
  return ds;
}

function mapStateToProps(state: DeprecatedRootState, ownProps: Props) {
  const formValues: FormValues = getFormValues(formName)(state);
  const dataSources = state.data.dataSources;
  let initialValues: FormValues;
  if (ownProps.update) {
    const dataSource = dataSources.data.find(
      (ds: DataSource) => ds.code === ownProps.dataSourceCode
    );
    if (dataSource !== undefined) {
      initialValues = {
        name: dataSource.name,
        ds_type: dataSource.ds_type,
        settings: dataSource.settings,
      };
    }
  } else {
    initialValues = { settings: { region: 'us-east-1' } };
  }
  return {
    dataSources,
    formValues,
    initialValues,
  };
}

const mapDispatchToProps = {
  addDataSource,
  fetchDataSourceSettings,
  updateDataSource,
  fetchDataSources,
  destroyUploadForm: () => destroy(formName),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ConnectedProps = ConnectedProps_<typeof connector>;
export default connector(reduxForm<FormValues>(dsForm)(AddUpdateDataSource));
