// TODO WIP

import { useSelector } from 'react-redux';
import { DeprecatedRootState } from '../state.type';
import { LoadableData } from '../../utils';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import { MergeRequest } from '../../components/collaborationSpace/giteaTypes';
import { Commit } from '../../components/collaborationSpace/repository-details/merge-requests/merge-request-details/tab-overview/MergeRequestOverview';
import { ToBeRefined } from 'common/dist/types/todo_type';
import {
  CodeCell,
  ExecuteResult,
  JupyterNotebookFormat,
  MarkdownCell,
  RawCell,
} from 'common/dist/utils/workbench/jupyterNotebookFormat';
import { CodeCapsule } from 'common/dist/types/codeCapsule';
import { App } from 'common/dist/types/app';
import { Pane } from '../../workbench/types';

/** Format of repository.asr */
// TODO belongs in common
export interface RepoMeta {
  repoCode?: string;
  repoType: string;
  repoName: string;
  codeCapsuleCode?: string;
  archetypeCode?: string;
  appCode?: string;
  repoFullName: string;
}

export type RefStatusData = {
  /** Amount of commits ahead */
  ahead: {
    amount: number;
    commits: Commit[];
  };
  /** Amount of commits behind */
  behind: {
    amount: number;
    commits: Commit[];
  };
};

/** Format of details for a single repo, returned by the api */
// TODO belongs in common - same as CollabContent that is returned while adding a repo?
export interface RepoDetails {
  id: number;
  code: string;
  repoName: string;
  repoFullName: string;
  repoDescription: string;
  /** repoType: plain */
  repoType: string;
  codeCapsule: CodeCapsule;
  app: App;
  archetypeCode: string;
  // Less important/standardized fields TODO clarify
  /** type: repository */
  type: string;
  name: string;
  path: string;
  parentPath: string;
  createdAt: string;
  updatedAt: string;
  createdByUserId: string;
  owner: {
    username: string;
  };
  full_name: string;
}

interface ShowWarningOpenFile {
  path?: string;
  type?: string;
}

export interface Session {
  id: string;
  path: string;
  name: string;
  type: 'notebook' | string;
  kernel: Kernel;
  notebook: NotebookReference;
}

export enum ExecutionState {
  Idle = 'idle',
  Busy = 'busy',
  /** Custom */
  Starting = 'starting',
  /** Custom for the error case while checking the state */
  Unknown = 'unknown',
  /** Custom if the response is 404 */
  NotFound = 'not_found',
}

export interface Kernel {
  id: string;
  name: 'python3' | string;
  /** 2021-10-27T08:39:13.629165Z */
  last_activity: string;
  execution_state: ExecutionState;
  connections: number;
}

export interface NotebookReference {
  path: string;
  name: string;
}

/**
 * More specific ExecuteResult, that shows the different keys
 */
export interface SpecificExecuteResult extends ExecuteResult {
  data: {
    ['image/png']?: ToBeRefined;
    ['text/html']?: ToBeRefined;
    ['text/plain']?: ToBeRefined;
    ['application/vnd.jupyter.widget-view+json']?: ToBeRefined;
  };
}

/**
 * Common Cell attributes that are AltaSigma additions
 */
export interface AltaSigmaCommonCell {
  /** UUID for referencing specific cells */
  id: string;
}

export type CodeCompletionType = {
  row: number;
  column: number;
  currentRowSource: string;
  content: {
    metadata: {
      _jupyter_types_experimental: {
        start: number;
        end: number;
        text: string;
        type: string;
      }[];
    };
  }[];
};
/**
 * Code Cell attributes that are AltaSigma additions
 */
export type AltaSigmaCodeCell = AltaSigmaCommonCell &
  CodeCell & {
    /** Is the cell currently executing? This is a non-standard field and should not be saved */
    executing?: boolean;
    /** Code Completion suggestions delivered by Jupyter */
    completion?: CodeCompletionType;
  };

export type AltaSigmaRawCell = AltaSigmaCommonCell & RawCell;
export type AltaSigmaMarkdownCell = AltaSigmaCommonCell & MarkdownCell;

/**
 * App Input Cell
 */
export type AppInputCell = AltaSigmaCommonCell &
  Pick<AltaSigmaCodeCell, 'execution_count' | 'executing'> & {
    cell_type: 'python3-input';
    /** App input cell elements (text-inputs, ...) */
    as_elements?: ToBeRefined[];
    /** Are the app input elements expanded? */
    as_elements_expanded?: boolean;
    /** App input cell variables */
    as_variables?: ToBeRefined[];
    /** Are the app input variables expanded? */
    as_variables_expanded?: boolean;
  };
/**
 * App Output Cell
 */
export type AppOutputCell = AltaSigmaCommonCell &
  Pick<AltaSigmaCodeCell, 'execution_count' | 'executing'> & {
    cell_type: 'python3-output';
    /** App output cell elements (text-inputs, ...) */
    as_elements?: ToBeRefined[];
    /** Are the app output elements expanded? */
    as_elements_expanded?: boolean;
  };
export type CredentialsCell = AltaSigmaCommonCell &
  Pick<AltaSigmaCodeCell, 'execution_count' | 'executing'> & {
    cell_type: 'credentials';
  };
export type AltaSigmaCell =
  | AltaSigmaRawCell
  | AltaSigmaMarkdownCell
  | AltaSigmaCodeCell
  | AppInputCell
  | AppOutputCell
  | CredentialsCell;

export interface AltaSigmaNotebookFormat
  extends Omit<JupyterNotebookFormat, 'cells'> {
  cells: AltaSigmaCell[];
}

export interface Notebook extends JupyterContentElement {
  // --- Jupyter "native" attributes
  content: AltaSigmaNotebookFormat;
  session: Session;
  format: 'json'; // TODO extend enumeration if required
  size: number;
  writable: boolean;
  message: string;
  type: 'notebook';
  // --- Additional attributes added by AltaSigma
  selectedCells: string[];
  tabWidth: number;
  /** Force focus "flag": ID of the cell that needs to get focused after mounting, because it was newly created or the type changed */
  forceFocus: number;
  // TODO when are these added?
  unsavedChanges?: boolean;
  as_parentRepository?: Record<string, unknown>;
  showCloseConfirm?: boolean;
}

/**
 * Native Jupyter type for differentiating content.
 */
export type JupyterContentType = 'file' | 'directory' | 'notebook';

/**
 * Custom type added to JupyterContentElement to add more information about directories
 */
export type AsType = 'recycleBin' | 'repository';

/**
 * The content as returned by jupyter with some fields added for information.
 * Some values may be null because they haven't been fetched yet and are unknown or
 * because they don't apply. E.g. size for directories.
 */
export interface JupyterContentElement {
  name: string;
  path: string;
  last_modified: string;
  created: string;
  content: JupyterContentElement[] | any | null;
  format: null | string;
  mimetype: null | string;
  size: number | null;
  writable: boolean;
  type: JupyterContentType;
  asType?: AsType;
}

export interface WorkbenchState {
  content: {
    selectedDirPath: string[];
    openContextMenu?: string;
    root: JupyterContentElement;
  };
  panes: { [id: string]: Pane };
  repoMetas: {
    [path: string]: RepoMeta;
  };
  repoDetails: {
    loading: boolean;
    loaded: boolean;
    data: RepoDetails;
    error: unknown;
  };
  showDeleteContent: {
    path?: string;
    type?: string;
    permanently?: boolean;
  };
  clipboard: {
    data?: Record<string, unknown>;
    type?: string;
  };
  showEditNotebook?: {
    name?: string;
    path?: string;
    kernel?: string;
  };
  mostRecentPaneId: string;
  showWarningOpenFile: ShowWarningOpenFile;
  // TODO duplicated in state
  sessions: {
    data: Session[];
    error?: unknown;
  };
  notebooks: {
    // TODO these are not actually required to be Notebooks, probably just Files (or the Launcher dummy)
    [path: string]: Notebook;
  };
  showEditDirectory?: {
    name?: string;
    path?: string;
  };
  notebookRunning: LoadableData<boolean, string>;
  app: {
    appRunning: LoadableData<boolean, string>;
  };
  mergerAPI: {
    sourceExtended?: boolean;
    targetExtended?: boolean;
    commitModal: {
      isShown?: boolean;
    };
    refStatus?: LoadableData<RefStatusData, ApiError>;
    merge: LoadableData<ToBeRefined, ApiError>;
    status: LoadableData<ToBeRefined, ApiError>;
  };
  diskUsage: LoadableData<
    {
      /** Total available disk in bytes */
      total: number;
      /** Total used disk in bytes */
      used: number;
      /** Total free disk in bytes */
      free: number;
    },
    ApiError
  >;
  showRepositoryInfo: {
    isFetchBranchModalOpen?: boolean;
    fetchBranch?: string;
  };
  collab: {
    mergeRequest: LoadableData<MergeRequest, ApiError>;
    mergeRequests: LoadableData<ToBeRefined, ApiError>;
  };
  selectVariable: {};
  settings: {
    showHidden: boolean;
  };
}

// TODO fine here?
// Selectors
export const useShowWarningOpenFile = () =>
  useSelector<DeprecatedRootState, ShowWarningOpenFile>(
    (state) => state.workbench.showWarningOpenFile
  );
