import PropTypes from 'prop-types';
import React from 'react';
import * as d3 from 'd3';

import Bar from './Bar';
import { DataType } from './BarChart';
import { ToBeRefined } from 'common/dist/types/todo_type';

type Props = {
  data: DataType;
  xScale: ToBeRefined;
  yScale: ToBeRefined;
  colorBar: string;
};

const Bars = ({ data, xScale, yScale, colorBar }: Props) => {
  xScale.domain(data.map(([x]) => +x));
  yScale.domain([0, d3.max(data, ([, y]) => y)]);

  return (
    <g>
      {data.map((item) => (
        <Bar
          key={`${item[0]}_${item[1]}`}
          xScale={xScale}
          yScale={yScale}
          item={item}
          colorBar={colorBar}
        />
      ))}
    </g>
  );
};

Bars.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
};

export default Bars;
