export const initial = {
  error: '',
  loading: false,
  loaded: false,
  codes: [], // List of codes
  schedules: {}, // Mapping: Code -> Values
  deleteModal: {
    show: false,
  },
};

export const test = {
  error: '',
  loading: false,
  loaded: false,
  codes: [], // List of codes
  schedules: {}, // Mapping: Code -> Values
  deleteModal: {
    show: false,
  },
};
