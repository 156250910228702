import { connect } from 'react-redux';
import StepBranch from './StepBranch';

export function mapStateToProps(state, ownProps) {
  return {
    repoName: state.workbench.showCloneRepository.repoName,
    branches: state.workbench.collab.branches.data,
    selectedBranch: state.workbench.collab[ownProps.stateKey],
  };
}

export default connect(mapStateToProps)(StepBranch);
