import { connect } from 'react-redux';
import RepositoryInfo from './RepositoryInfo';
import { gitActiveBranch } from '../../../../../redux/workbench/modules/container-interactions.module';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { loadRepoMeta } from '../../../../../redux/workbench/modules/notebook.module';
import * as selector from '../../../../../redux/selectors/user.selector';
import { jumpToDirectory } from '../../../../../redux/workbench/modules/content.module';

export function mapStateToProps(state, { location }) {
  const infoFilePath = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).path;
  return {
    activeBranch: state.workbench.showRepositoryInfo.activeBranch,
    infoFilePath,
    repoMetas: state.workbench.repoMetas,
    currentUserId: selector.currentUser(state).id,
  };
}

export const mapDispatchToProps = {
  gitActiveBranch,
  loadRepoMeta,
  jumpToDirectory,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RepositoryInfo)
);
