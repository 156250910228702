import React, { FC } from 'react';
import { deriveElements } from './utils';
import ElementCardGrid from '../../../organisms/element-card-grid/ElementCardGrid';
import { CodeCapsule } from 'common/dist/types/codeCapsule';
import { ToBeRefined } from 'common/dist/types/todo_type';

type Props = {
  codeCapsules: { data: CodeCapsule[] };
  habitatCode: string;
  openRunCodeCapsuleModal: (codeCapsuleModalProps: ToBeRefined) => void;
};

const CodeCapsulesCardView: FC<Props> = (props) => {
  const { codeCapsules, habitatCode, openRunCodeCapsuleModal } = props;

  // Derive the elements
  const elements = deriveElements(
    codeCapsules,
    habitatCode,
    openRunCodeCapsuleModal
  );

  return (
    <div className={'code-capsules'}>
      <ElementCardGrid elements={elements} />
    </div>
  );
};

export default CodeCapsulesCardView;
