import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { form } from './mergeRequest.form';
import {
  fieldSrcBranch,
  fieldTitle,
  fieldDescription,
  fieldTargetBranch,
} from './mergeRequest.form';
import StepBranch from './StepBranch.container';
import { collaborationSpaceRoutes } from '../../routes';
import { Link } from 'react-router-dom';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import NameInputComponent from './NameInputComponent';
import TextAreaInput from '../../../workbench/part-right/wizards/_components/TextAreaInput';

export default class OpenMergeRequestWizard extends Component {
  componentDidMount() {
    const { fetchBranches, loadMergeRequestDetails } = this.props;
    const {
      match: {
        params: { group, repositoryName, id },
      },
    } = this.props;
    fetchBranches(group, repositoryName);
    if (id) {
      loadMergeRequestDetails(group, repositoryName, id);
    }
  }

  render() {
    const { filledCompletely, openMergeRequest, editMergeRequest } = this.props;

    const {
      match: {
        params: { group, repositoryName, id },
      },
    } = this.props;
    const { sourceBranch, targetBranch, title, description } = this.props;

    return (
      <div className={'dialog-container'}>
        <div className={'dialog-inner-container'}>
          <p className={'dialog-headline'}>
            {id ? 'Edit' : 'Create'} Merge Request
          </p>
          <form
            onKeyPress={(e) => {
              if (e.which === 13) e.preventDefault();
            }}
          >
            <StepBranch
              title='Select Source Branch'
              description={
                id
                  ? 'The source branch cannot be edited.'
                  : 'Select the branch you want to merge.'
              }
              fieldName={fieldSrcBranch}
              stateKey='sourceBranch'
              num={1}
              isDisabled={!!id}
            />
            <StepBranch
              title='Select Target Branch'
              description='Select the branch you want to merge into.'
              fieldName={fieldTargetBranch}
              stateKey='targetBranch'
              num={2}
            />
            <GenericFormStep
              formName={'mergeRequest'}
              fieldName={fieldTitle}
              title={{
                defaultMessage: 'Name',
              }}
              description={{
                defaultMessage: 'Enter a name for the merge request.',
              }}
              num={3}
              component={NameInputComponent}
            />
            <GenericFormStep
              formName={'mergeRequest'}
              fieldName={fieldDescription}
              title={{
                defaultMessage: 'Description',
              }}
              description={{
                defaultMessage: 'Enter a description for the merge request.',
              }}
              num={4}
              component={TextAreaInput}
            />
            <div className={'dialog-button-container'}>
              <Link
                to={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}`}
              >
                <button
                  type={'button'}
                  className={'dialog-button dialog-button-neutral'}
                >
                  Cancel
                </button>
              </Link>
              <Link
                to={`${
                  collaborationSpaceRoutes.basePath
                }/${group}/${repositoryName}/${
                  collaborationSpaceRoutes.repositories.mergeRequest
                }${id ? '/' + id : ''}`}
                onClick={() => {
                  if (filledCompletely) {
                    if (id)
                      editMergeRequest(
                        group,
                        repositoryName,
                        id,
                        title,
                        description,
                        targetBranch
                      );
                    else
                      openMergeRequest(
                        group,
                        repositoryName,
                        sourceBranch,
                        targetBranch,
                        title,
                        description
                      );
                  }
                }}
              >
                <button
                  type={'button'}
                  className={'dialog-button dialog-button-ok'}
                  disabled={!filledCompletely}
                >
                  Finish
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

OpenMergeRequestWizard.propTypes = {
  filledCompletely: PropTypes.bool.isRequired,
  openMergeRequest: PropTypes.func.isRequired,
  editMergeRequest: PropTypes.func.isRequired,
  /** List of notebooks in the currently active "selectedDirPath" */
  match: PropTypes.shape({
    params: PropTypes.shape({
      group: PropTypes.string,
      repositoryName: PropTypes.string,
    }).isRequired,
  }).isRequired,
  fetchBranches: PropTypes.func.isRequired,
  loadMergeRequestDetails: PropTypes.func.isRequired,
  sourceBranch: PropTypes.string,
  targetBranch: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  initialize: PropTypes.func,
  initialValues: PropTypes.object,
};
