export const test = {
  loaded: false,
  loading: true,
  initialized: false,
  habitats: {
    1: {
      isListView: false,
      activeTab: 'settings',
      order: {
        value: '2b',
      },
      datapools: {
        data: [],
        order: 'ascending',
        sortBy: 'name',
      },
      augurs: [
        {
          code: '2',
          name: 'Augur 2',
          lastEvaluationDate: '2016-07-06T11:23:36.274Z',
        },
        {
          code: '1',
          name: 'Augur 1',
          lastEvaluationDate: '2016-07-06T10:23:36.274Z',
        },
      ],
      archived: [],
    },
    123456: {
      isListView: false,
      activeTab: 'settings',
      order: {
        value: '2b',
      },
      datapools: {
        data: [],
      },
      augurs: [],
      archived: [],
    },
  },
  search: {
    query: 'augur',
    loading: false,
    loaded: true,
    error: '',
    data: {
      habitats: [],
      augurs: [{ name: 'augur 1', code: 'augurcode1' }],
    },
  },
};

export const initial = {
  loaded: false,
  loading: false,
  initialized: false,
  habitats: {},
  search: {
    query: '',
    loading: false,
    loaded: false,
    error: '',
    data: {
      habitats: [],
      augurs: [],
    },
  },
};
