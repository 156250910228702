import React, { FC, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import orchestrationMessages from 'common/dist/messages/orchestration';
import { FormattedDateTime } from '../../atoms/formatted-date-time/FormattedDateTime';
import { SelectedSchedule } from 'common/dist/utils/schedules';
import {
  monthDaysMap,
  weekDaysMap,
} from 'common/dist/constants/schedulePeriods';
import { zeroPad } from 'common/dist/utils/zeroPad';

export type Props = SelectedSchedule;

const ScheduleInfo: FC<Props> = ({
  trigger,
  trigDelayed,
  trigTimed,
  trigCron,
}) => {
  const intl = useIntl();

  const renderInfoCaption = () => (
    <Fragment>
      <b>
        <FormattedMessage
          {...(trigger === 'timed' || trigger === 'cron'
            ? orchestrationMessages.scheduleRepeating
            : orchestrationMessages.scheduleOneTime)}
        />
      </b>
    </Fragment>
  );

  const renderTimingInfo = () => {
    //TODO add detailed info for cron schedules -> https://gitlab.sigmalto.com/altasigma-platform/ticket-system/-/issues/1358

    if (trigDelayed && !trigTimed?.period) {
      return (
        <Fragment>
          <FormattedMessage
            {...orchestrationMessages.scheduleDescriptionDelayed}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              date: <FormattedDateTime date={new Date(trigDelayed)} />,
            }}
          />
        </Fragment>
      );
    }
    if (!trigTimed) return <></>;
    if (trigTimed.period === 'monthly') {
      const dayOfMonthId = monthDaysMap[trigTimed.dayOfMonth];
      const dayFormatted = intl.formatMessage({ id: dayOfMonthId });

      return (
        <Fragment>
          <FormattedMessage
            {...orchestrationMessages.scheduleDescriptionPeriodMonthly}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              dayOfMonth: dayFormatted,
              hour: zeroPad(trigTimed.hourOfDay, 2),
              minute: zeroPad(trigTimed.minuteOfHour, 2),
            }}
          />
        </Fragment>
      );
    } else if (trigTimed.period === 'weekly') {
      const dayOfWeekId = weekDaysMap[trigTimed.dayOfWeek];
      const dayFormatted = intl.formatMessage({
        id: dayOfWeekId,
      });

      return (
        <Fragment>
          <FormattedMessage
            {...orchestrationMessages.scheduleDescriptionPeriodWeekly}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              dayOfWeek: dayFormatted,
              hour: zeroPad(trigTimed.hourOfDay, 2),
              minute: zeroPad(trigTimed.minuteOfHour, 2),
            }}
          />
        </Fragment>
      );
    } else if (trigTimed.period === 'daily') {
      return (
        <Fragment>
          <FormattedMessage
            {...orchestrationMessages.scheduleDescriptionPeriodDaily}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              hour: zeroPad(trigTimed.hourOfDay, 2),
              minute: zeroPad(trigTimed.minuteOfHour, 2),
            }}
          />
        </Fragment>
      );
    } else if (trigTimed.period === 'hourly') {
      return (
        <FormattedMessage
          {...orchestrationMessages.scheduleDescriptionPeriodHourly}
          values={{
            b: (chunks) => <b>{chunks}</b>,
            minuteOfHour: zeroPad(trigTimed.minuteOfHour, 2),
          }}
        />
      );
    }
  };

  return (
    <div>
      <div>
        <span>{renderInfoCaption()}</span>
      </div>
      {renderTimingInfo()}
    </div>
  );
};

export default ScheduleInfo;
