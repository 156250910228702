import React, { Component } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { fieldInputTable } from '../_interface/input-element.form';
import { VARIABLE_TYPES } from '../../variables/variableTypes';
import { inputModalForm } from '../../../settings-dialog/inpmodalDialog.form';
import { SettingsType } from '../_interface/P3InputElement';
import VariableSelectCol from '../../../settings-dialog/dialog-settings-cols/VariableSelectCol';
import appMsgs from 'common/dist/messages/apps';
import { injectIntl, WrappedComponentProps } from 'react-intl';

type Props = {
  inputTableSetting?: SettingsType<string | undefined>;
  variables: string[];
  path: string;
  cellId: string;
  showSelectVariable: (
    path: string,
    cellId: string,
    name: string,
    validVariableTypes: string,
    label: string
  ) => void;
};

class EditableTableSettings extends Component<
  Props & InjectedFormProps & WrappedComponentProps
> {
  render() {
    const { path, cellId, showSelectVariable, intl } = this.props;

    return (
      <div className={'input-row'}>
        <Field
          name={fieldInputTable}
          label={intl.formatMessage(appMsgs.msgEditableTableInputLabel)}
          component={VariableSelectCol}
          placeholder={intl.formatMessage(
            appMsgs.msgEditableTableInputPlaceholder
          )}
          allowVariable
          path={path}
          cellId={cellId}
          showSelectVariable={showSelectVariable}
          validVariableTypes={[VARIABLE_TYPES.PANDAS_DATA_FRAME]}
        />
      </div>
    );
  }
}

export default reduxForm(inputModalForm)(injectIntl(EditableTableSettings));
