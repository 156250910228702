import React, { Component } from 'react';
import { CellDiffType, FileType } from './ResolveConflicts';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import SingleDiff from './SingleDiff.container';
import SingleNotebookCell from './SingleNotebookCell.container';
import ConflictsButtonRow from './ConflictsButtonRow.container';
import {
  Notebook,
  AltaSigmaCell,
} from '../../../../../../../store/workbench/state.types';

export type Props = {
  baseFile: Notebook;
  sourceFile: FileType;
  targetFile: FileType;
  sourceDiff: CellDiffType[];
  targetDiff: CellDiffType[];
  /** Render the Cell IDs for debugging? */
  debugRenderCellIds: boolean;

  /** Name of the file to resolve the conflics for. Coming from the QueryString from the URL */
  filename: string;

  /** Is the source column extended? */
  sourceExtended?: boolean;
  /** Is the target column extended? */
  targetExtended?: boolean;
};

export type NotebookDiffRow = {
  /** Can be used as the React key. The cell ID for this row, since there is only one cell ID per row. */
  id: string;
  sourceCell?: AltaSigmaCell;
  baseCell?: AltaSigmaCell;
  targetCell?: AltaSigmaCell;
  sourceDiff?: CellDiffType[];
  targetDiff?: CellDiffType[];
};

type NotebookDiffRowDict = { [id: string]: NotebookDiffRow };

/**
 * Merges two lists of IDs into one with respect to the ordering. No items will be dropped, the result will be a
 * super-set of both elements.
 * So [1,2,3] and [1,2,6,7,3] -> [1,2,6,7,3]
 * And [1,2,3] and [1,3] -> [1,2,3]
 * @param listMaster
 * @param listSlave
 */
function pairwiseIdMerge(
  listMaster: string[] = [],
  listSlave: string[] = []
): string[] {
  let indexSlave = 0,
    indexMaster = 0;
  const lenSlave = listSlave.length;
  const lenMaster = listMaster.length;
  const finalList: string[] = [];

  while (indexSlave < lenSlave || indexMaster < lenMaster) {
    const elementSlave =
      listSlave.length > indexSlave ? listSlave[indexSlave] : undefined;
    const elementMaster =
      listMaster.length > indexMaster ? listMaster[indexMaster] : undefined;

    // Reached end of list Master? (-> but because of the while condition there must still be elements in Slave)
    if (!elementSlave) {
      if (!finalList.includes(elementMaster)) {
        finalList.push(elementMaster);
      }
      indexMaster++;
      continue;
    }

    // Reached end of list Slave? (-> but because of the while condition there must still be elements in Master)
    if (!elementMaster) {
      if (!finalList.includes(elementSlave)) {
        finalList.push(elementSlave);
      }
      indexSlave++;
      continue;
    }

    // Both elements are the same, add it to the list
    if (elementSlave === elementMaster) {
      finalList.push(elementSlave);
      indexSlave++;
      indexMaster++;
      continue;
    } else {
      // The two elements are not the same. For example with [1,2,6,7,3] and [1,2,3] we would have the following setting
      //  now: indexSlave=2, indexMaster=2, elementSlave=6, elementMaster=3
      if (!finalList.includes(elementMaster)) {
        // The master has the "lead"
        finalList.push(elementMaster);
        indexMaster++;
        continue;
      } else {
        // Skip the element since it's already in the list
        indexMaster++;
      }
      if (!listMaster.includes(elementSlave)) {
        finalList.push(elementSlave);
        indexSlave++;
        continue;
      } else {
        // Skip the element since it's already in the list
        indexSlave++;
      }
    }
  }

  return finalList;
}

/**
 * Takes a list of cells and adds an UUID as id if the cell doesn't have one yet
 * Additionally, the output of the cells will be removed
 * @param cells
 */
function ensureCellIdsAndRemoveOutput(cells: AltaSigmaCell[]): AltaSigmaCell[] {
  return cells
    .filter((c) => !!c)
    .map((c) => ({
      ...c,
      id: c.id || uuidv4(),
      outputs: [],
    }));
}

/**
 * Takes the cells of the source, base and target notebook and derives the "table" for how to display the cells so that
 * they are aligned next to each other.
 * @param baseCells
 * @param sourceCells
 * @param targetCells
 * @param sourceDiff
 * @param targetDiff
 */
function deriveCellTable(
  baseCells: AltaSigmaCell[],
  sourceCells: AltaSigmaCell[],
  targetCells: AltaSigmaCell[],
  sourceDiff: CellDiffType[],
  targetDiff: CellDiffType[]
): NotebookDiffRow[] {
  // Add IDs to the Cells if there isn't an ID yet (rare case)
  const baseCellsWithEnsuredIds = ensureCellIdsAndRemoveOutput(baseCells);
  const sourceCellsWithEnsuredIds = ensureCellIdsAndRemoveOutput(sourceCells);
  const targetCellsWithEnsuredIds = ensureCellIdsAndRemoveOutput(targetCells);

  // Derive the merged list of all Cell IDs
  const baseIds = baseCellsWithEnsuredIds.map((c) => c.id);
  const sourceIds = sourceCellsWithEnsuredIds.map((c) => c.id);
  const targetIds = targetCellsWithEnsuredIds.map((c) => c.id);
  const mergedIds = pairwiseIdMerge(
    pairwiseIdMerge(baseIds, sourceIds),
    targetIds
  );

  // Map the arrays of cells to objects of {id: cell} for easier lookup
  const sourceCellsById = _.chain(sourceCellsWithEnsuredIds)
    .keyBy('id')
    .value();
  const targetCellsById = _.chain(targetCellsWithEnsuredIds)
    .keyBy('id')
    .value();
  const baseCellsById = _.chain(baseCellsWithEnsuredIds).keyBy('id').value();

  // Create the DiffRowsDict (id -> NotebookDiffRow) for easier lookup
  let diffRowsDict: { [id: string]: NotebookDiffRow } = {};
  mergedIds.forEach((id) => {
    diffRowsDict[id] = {
      id,
      baseCell: baseCellsById[id],
      sourceCell: sourceCellsById[id],
      targetCell: targetCellsById[id],
      sourceDiff: [],
      targetDiff: [],
    };
  });

  // Sort in the actions
  sourceDiff.forEach((diff) => {
    diffRowsDict = handleDiff(
      diff,
      diffRowsDict,
      'source',
      sourceCellsWithEnsuredIds,
      targetCellsWithEnsuredIds
    );
  });

  targetDiff.forEach((diff) => {
    diffRowsDict = handleDiff(
      diff,
      diffRowsDict,
      'target',
      sourceCellsWithEnsuredIds,
      targetCellsWithEnsuredIds
    );
  });

  // Return the DiffRows in the correct ordering derived previously
  const mapped = mergedIds.map((id) => diffRowsDict[id]);

  return mapped;
}

/**
 * Takes one single diff element and sorts that into the NotebookDiffRowDict (a copy will be returned)
 * @param diff
 * @param diffRowsDict
 * @param sourceOrTarget
 * @param sourceCells
 * @param targetCells
 */
export function handleDiff(
  diff: CellDiffType,
  diffRowsDict: NotebookDiffRowDict,
  sourceOrTarget: 'source' | 'target',
  sourceCells: AltaSigmaCell[],
  targetCells: AltaSigmaCell[]
): NotebookDiffRowDict {
  const pathParts = diff.path.split('/');
  const elementKey = pathParts[1];

  if (!['cells'].includes(elementKey)) {
    // Everything apart from "cells" will be ignored for the moment. Not sure whether more keys need to be treated at all?
    console.log(
      `Didn't treat elementKey ${elementKey}. Full diff element: ${JSON.stringify(
        diff
      )}`
    );
    return diffRowsDict; // Simply return the original object without modifying it.
  }

  const cellId = diff.cell_id;
  if (!cellId) {
    // early exit
    return diffRowsDict;
  }

  return {
    ...diffRowsDict,
    [cellId]: {
      ...diffRowsDict[cellId],
      sourceDiff:
        sourceOrTarget === 'source'
          ? [...((diffRowsDict[cellId] || {}).sourceDiff || []), diff]
          : (diffRowsDict[cellId] || {}).sourceDiff,
      targetDiff:
        sourceOrTarget === 'target'
          ? [...((diffRowsDict[cellId] || {}).targetDiff || []), diff]
          : (diffRowsDict[cellId] || {}).targetDiff,
    },
  };
}

export default class Conflicts extends Component<Props, {}> {
  renderForNotebook() {
    const {
      baseFile,
      sourceFile,
      targetFile,
      sourceDiff,
      targetDiff,
      filename,
      debugRenderCellIds,
      sourceExtended,
      targetExtended,
    } = this.props;
    const cellTable = deriveCellTable(
      baseFile.content.cells,
      sourceFile.content.cells,
      targetFile.content.cells,
      sourceDiff,
      targetDiff
    );

    return (
      <div className={'conflicts-parent'}>
        <div className={'conflicts-row conflicts-row-header'}>
          <div
            className={
              'conflicts-column single-diff-container source-file' +
              (sourceExtended ? '' : ' collapsed')
            }
          >
            {sourceExtended && <span>Source File</span>}
          </div>
          <div className={'conflicts-column conflicts-notebook base-file'}>
            Base File
          </div>
          <div
            className={
              'conflicts-column single-diff-container target-file' +
              (targetExtended ? '' : ' collapsed')
            }
          >
            {targetExtended && <span>Target File</span>}
          </div>
        </div>

        <ConflictsButtonRow />

        {cellTable.map((row) => (
          <div className={'conflicts-row'} key={row.id}>
            {/* Source Column */}
            <SingleDiff
              filename={filename}
              sourceOrTarget={'source'}
              cell={row.sourceCell}
              diffActions={row.sourceDiff}
              debugRenderCellIds={debugRenderCellIds}
              isExtended={sourceExtended}
            />

            <div className={'conflicts-column conflicts-notebook base-file'}>
              {
                // @ts-ignore if you want "removed", better to extend the type into a specific merge type
                row.baseCell && !row.baseCell.removed && (
                  <SingleNotebookCell
                    cell={row.baseCell}
                    debugRenderCellIds={debugRenderCellIds}
                  />
                )
              }
            </div>

            {/* Target Column */}
            <SingleDiff
              filename={filename}
              sourceOrTarget={'target'}
              cell={row.targetCell}
              diffActions={row.targetDiff}
              debugRenderCellIds={debugRenderCellIds}
              isExtended={targetExtended}
            />
          </div>
        ))}
      </div>
    );
  }

  renderForFile() {
    return (
      <div className={'conflicts-parent'}>
        <span style={{ color: '#ff09c8', fontWeight: 500 }}>
          TODO: Implement diffs for plain files
        </span>
      </div>
    );
  }

  // TODO Switch between the different conflict types instead of simply showing the notebook diffs
  render() {
    const { baseFile } = this.props;

    if (baseFile.name.endsWith('.ipynb')) return this.renderForNotebook();
    else return this.renderForFile();
  }
}
