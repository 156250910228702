import { createAction } from 'redux-act';
import { v4 as uuidv4 } from 'uuid';
import { p3ValidateElement } from '../../../components/workbench/fileTypes/notebook/component/notebook-cells/cell-implementations/app-cells/python3-input-cell/elements/_interface/Python3InputElementManager';
import { takeEvery, call } from 'redux-saga/effects';
import {
  executeInputCellVariables,
  openSockets,
} from './notebook.websocket.module';
import { VARIABLE_TYPES } from '../../../components/workbench/fileTypes/notebook/component/notebook-cells/cell-implementations/app-cells/python3-input-cell/variables/variableTypes';
import { parseVariableValue } from '../../../components/workbench/fileTypes/notebook/component/notebook-cells/cell-implementations/app-cells/python3-input-cell/variables/variableOptions';
import { selectCell, updateNotebookCells } from './utils/notebooks';

export const updateDataSourceCode = createAction(
  'udpate credential cell data source code',
  (path, cellId, code, dsType) => ({ path, cellId, code, dsType })
);

export const updateUsernameVariable = createAction(
  'udpate credential cell username variable',
  (path, cellId, name) => ({ path, cellId, name })
);

export const updatePasswordVariable = createAction(
  'udpate credential cell password variable',
  (path, cellId, name) => ({ path, cellId, name })
);

// --- INPUT CELLS
/**
 * Adds an input element to the end of an P3InputCell
 * @type {ComplexActionCreator3<unknown, unknown, unknown, {path: unknown, cellId: unknown, element: unknown}, {}>}
 */
export const addInputElement = createAction(
  'add input element',
  (path, cellId, element, elementId) => ({ path, cellId, element, elementId })
);

/**
 * Removes an input element from an P3InputCell
 * @type {ComplexActionCreator3<unknown, unknown, unknown, {elementId: unknown, path: unknown, cellId: unknown}, {}>}
 */
export const removeInputElement = createAction(
  'remove input element',
  (path, cellId, elementId) => ({ path, cellId, elementId })
);

/**
 * Updates the settings of the input element
 * @type {ComplexActionCreator4<unknown, unknown, unknown, unknown, {elementId: unknown, path: unknown, cellId: unknown, updateValues: unknown}, {}>}
 */
export const updateSettingsOfInputElement = createAction(
  'update settings of input element',
  (path, cellId, elementId, updateValues) => ({
    path,
    cellId,
    elementId,
    updateValues,
  })
);

/**
 * Updates the settings of the output element
 * @type {ComplexActionCreator4<unknown, unknown, unknown, unknown, {elementId: unknown, path: unknown, cellId: unknown, updateValues: unknown}, {}>}
 */
export const updateSettingsOfOutputElement = createAction(
  'update settings of output element',
  (path, cellId, elementId, updateValues) => ({
    path,
    cellId,
    elementId,
    updateValues,
  })
);

/**
 * Updates the user input of the input element in the Notebook view
 * @type {ComplexActionCreator4<unknown, unknown, unknown, unknown, {path: unknown, cellId: unknown, updateValues: unknown, element: unknown}, {}>}
 */
export const updateDataOfInputElement = createAction(
  'udpate data of input element',
  (path, cellId, element, updateValues) => ({
    path,
    cellId,
    element,
    updateValues,
  })
);

/**
 * Updates the user input of the input element in the App view
 * @type {ComplexActionCreator4<unknown, unknown, unknown, unknown, {path: unknown, cellId: unknown, updateValues: unknown, element: unknown}, {}>}
 */
export const updateDataOfInputElementApp = createAction(
  'update data of input element app view',
  (path, cellId, element, updateValues) => ({ cellId, element, updateValues })
);

// --- OUTPUT CELLS
/**
 * Adds an output element to the end of an P3OutputCell. Passing properties for an element is optional since there's no
 * information required to add an output element ('element' could for example be used to add default python code)
 * @type {ComplexActionCreator2<unknown, unknown, {path: unknown, cellId: unknown, element: {}}, {}>}
 */
export const addOutputElement = createAction(
  'add output element',
  (path, cellId, element = {}) => ({ path, cellId, element })
);

/**
 * Removes an output element from an P3InputCell
 * @type {ComplexActionCreator3<unknown, unknown, unknown, {elementId: unknown, path: unknown, cellId: unknown}, {}>}
 */
export const removeOutputElement = createAction(
  'remove output element',
  (path, cellId, elementId) => ({ path, cellId, elementId })
);

/**
 * Updates the user output of the output element
 * @type {ComplexActionCreator4<unknown, unknown, unknown, unknown, {path: unknown, cellId: unknown, updateValues: unknown, element: unknown}, {}>}
 */
export const updateDataOfOutputElement = createAction(
  'udpate data of output element',
  (path, cellId, element, updateValues) => ({
    path,
    cellId,
    element,
    updateValues,
  })
);

export const addOutputToOutputElement = createAction(
  'add output to output element',
  (path, cellId, elementId, output, parentType) => ({
    path,
    cellId,
    elementId,
    output,
    parentType,
  })
);

export const addOutputToInputVariable = createAction(
  'add output to input variable',
  (path, cellId, variableId, output, parentType) => ({
    path,
    cellId,
    variableId,
    output,
    parentType,
  })
);

export const clearOutputOfInputOutputCell = createAction(
  'clear output of input or output cell',
  (path, cellId, parentType) => ({ path, cellId, parentType })
);

export const clearOutputOfInputVariables = createAction(
  'clear output of input variables',
  (path, cellId, parentType) => ({ path, cellId, parentType })
);

export const addVariable = createAction(
  'add input cell variable',
  (path, cellId) => ({ path, cellId })
);

/**
 * Removes an variable from an P3InputCell
 * @type {ComplexActionCreator3<unknown, unknown, unknown, {elementId: unknown, path: unknown, cellId: unknown}, {}>}
 */
export const removeVariable = createAction(
  'remove input cell variable',
  (path, cellId, variableId) => ({ path, cellId, variableId })
);

export const updateVariable = createAction(
  'udpate input cell variable',
  (path, cellId, variable, updateValues, queryParameter = {}) => ({
    path,
    cellId,
    variable,
    updateValues,
    queryParameter,
  })
);

export const executeVariables = createAction(
  'execute input cell variables',
  (path, cellId, variables, sessionId, parentType) => ({
    path,
    cellId,
    variables,
    sessionId,
    parentType,
  })
);

export const showSelectVariable = createAction(
  'input cell - show select variable',
  (path, cellId, elementFieldName, validTypes, label) => ({
    path,
    cellId,
    elementFieldName,
    validTypes,
    label,
  })
);

export const hideSelectVariable = createAction(
  'input cell - hide select variable'
);

export const toggleInputElements = createAction(
  'input cell - toggle input elements',
  (path, cellId, expanded) => ({ path, cellId, expanded })
);

export const toggleInputVariables = createAction(
  'input cell - toggle input variables',
  (path, cellId, expanded) => ({ path, cellId, expanded })
);

export const toggleOutputElements = createAction(
  'output cell - toggle output elements',
  (path, cellId, expanded) => ({ path, cellId, expanded })
);

export const showAddInputElement = createAction(
  'input cell - show add input element',
  (path, cellId) => ({ path, cellId })
);

export const hideAddInputElement = createAction(
  'input cell - hide add input element'
);

export const showEditInputElement = createAction(
  'input cell - show edit input element',
  (path, cellId, element, variables) => ({ path, cellId, element, variables })
);

export const hideEditInputElement = createAction(
  'input cell - hide edit input element'
);

export const showEditOutputElement = createAction(
  'output cell - show edit output element',
  (path, cellId, element) => ({ path, cellId, element })
);

export const hideEditOutputElement = createAction(
  'output cell - hide edit output element'
);

export const showAddOutputElement = createAction(
  'output cell - show add output element',
  (path, cellId) => ({ path, cellId })
);

export const hideAddOutputElement = createAction(
  'output cell - hide add output element'
);

export const reducer = {
  [updateDataSourceCode](state, { path, cellId, code, dsType }) {
    const cell = selectCell(state, path, cellId);
    // The dsType should only ever be cassandra or s3
    const dsTypeOld = Object.keys(cell.as_credentials || {})[0];
    const updatedFields = {
      as_credentials: {
        [dsType]: {
          ...(cell.as_credentials?.[dsTypeOld] || {}),
          dataSourceCode: code,
        },
      },
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [updatePasswordVariable](state, { path, cellId, name }) {
    const cell = selectCell(state, path, cellId);
    const dsType = Object.keys(cell.as_credentials || {})[0];
    const updatedFields = {
      as_credentials: {
        [dsType]: {
          ...(cell.as_credentials?.[dsType] || {}),
          password: name,
        },
      },
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [updateUsernameVariable](state, { path, cellId, name }) {
    const cell = selectCell(state, path, cellId);
    const dsType = Object.keys(cell.as_credentials || {})[0];
    const updatedFields = {
      as_credentials: {
        [dsType]: {
          ...(cell.as_credentials?.[dsType] || {}),
          username: name,
        },
      },
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  // --- INPUT CELLS
  [addInputElement](state, { path, cellId, element, elementId }) {
    const elementWithId = { ...element, id: elementId };
    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_elements: cell.as_elements
        ? [...cell.as_elements, elementWithId]
        : [elementWithId],
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [removeInputElement](state, { path, cellId, elementId }) {
    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_elements: cell.as_elements
        ? cell.as_elements.filter((e) => e.id !== elementId)
        : [],
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [updateSettingsOfInputElement](
    state,
    { path, cellId, elementId, updateValues }
  ) {
    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_elements: cell.as_elements.map((e) =>
        e.id === elementId
          ? {
              ...e,
              settings: {
                ...e.settings,
                ...updateValues,
              },
            }
          : e
      ),
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [updateSettingsOfOutputElement](
    state,
    { path, cellId, elementId, updateValues }
  ) {
    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_elements: cell.as_elements.map((e) =>
        e.id === elementId
          ? {
              ...e,
              settings: {
                ...e.settings,
                ...updateValues,
              },
            }
          : e
      ),
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [updateDataOfInputElement](state, { path, cellId, element, updateValues }) {
    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_elements: cell.as_elements.map((e) =>
        e.id === element.id ? updateRecentElement(e, updateValues) : e
      ),
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [updateDataOfInputElementApp](state, { cellId, element, updateValues }) {
    const executionPlanStepIndex = state.app.executionPlan.findIndex(
      (step) => step.cells && step.cells.some((c) => c.id === cellId)
    );
    if (executionPlanStepIndex < 0) return state;
    const executionPlanStep = state.app.executionPlan[executionPlanStepIndex];

    const updatedExecutionPlanStep = {
      ...executionPlanStep,
      cells: executionPlanStep.cells.map((c) =>
        c.id === cellId
          ? {
              ...c,
              as_elements: c.as_elements.map((e) =>
                e.id === element.id ? updateRecentElement(e, updateValues) : e
              ),
            }
          : c
      ),
    };
    return {
      ...state,
      app: {
        ...state.app,
        executionPlan: [
          // A. Update the execution plan
          ...state.app.executionPlan.slice(0, executionPlanStepIndex),
          updatedExecutionPlanStep,
          ...state.app.executionPlan.slice(executionPlanStepIndex + 1),
        ],
        notebook: {
          // B. Update the notebook
          ...state.app.notebook,
          content: {
            ...state.app.notebook.content,
            cells: state.app.notebook.content.cells.map((c) =>
              c.id === cellId
                ? {
                    ...c,
                    as_elements: c.as_elements.map((e) =>
                      e.id === element.id
                        ? updateRecentElement(e, updateValues)
                        : e
                    ),
                  }
                : { ...c }
            ),
          },
        },
      },
    };
  },

  // --- OUTPUT CELLS
  [addOutputElement](state, { path, cellId, element }) {
    const elementId = uuidv4();
    const elementWithId = { ...element, id: elementId };
    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_elements: cell.as_elements
        ? [...cell.as_elements, elementWithId]
        : [elementWithId],
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [removeOutputElement](state, { path, cellId, elementId }) {
    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_elements: cell.as_elements
        ? cell.as_elements.filter((e) => e.id !== elementId)
        : [],
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [updateDataOfOutputElement](state, { path, cellId, element, updateValues }) {
    const error = p3ValidateElement(element);
    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_elements: cell.as_elements.map((e) =>
        e.id === element.id
          ? {
              ...e,
              data: {
                ...e.data,
                ...updateValues,
              },
              error,
            }
          : e
      ),
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [addOutputToOutputElement](
    state,
    { path, cellId, elementId, output, parentType }
  ) {
    if (parentType === 'notebook') {
      const cell = selectCell(state, path, cellId);
      const updatedFields = {
        as_elements: cell.as_elements.map((e) =>
          e.id === elementId
            ? {
                ...e,
                data: {
                  ...e.data,
                  outputs: [...(e.data.outputs || []), output],
                },
              }
            : e
        ),
      };
      return updateNotebookCells(state, path, cellId, updatedFields);
    } else if (parentType === 'app') {
      const executionPlanStepIndex = state.app.executionPlan.findIndex(
        (step) => step.cells && step.cells.some((c) => c.id === cellId)
      );
      if (executionPlanStepIndex < 0) return state;
      const executionPlanStep = state.app.executionPlan[executionPlanStepIndex];

      const updatedExecutionPlanStep = {
        ...executionPlanStep,
        // status: 'executing', // If one cell of the execution plan step is marked as now being executed the whole step is in state 'executing'
        cells: executionPlanStep.cells.map((c) =>
          c.id === cellId
            ? {
                ...c,
                as_elements: c.as_elements.map((e) =>
                  e.id === elementId
                    ? {
                        ...e,
                        data: {
                          ...e.data,
                          outputs: [...(e.data.outputs || []), output],
                        },
                      }
                    : e
                ),
              }
            : c
        ),
      };

      return {
        ...state,
        app: {
          ...state.app,
          executionPlan: [
            // A. Update the execution plan
            ...state.app.executionPlan.slice(0, executionPlanStepIndex),
            updatedExecutionPlanStep,
            ...state.app.executionPlan.slice(executionPlanStepIndex + 1),
          ],
          notebook: {
            ...state.app.notebook,
            content: {
              ...state.app.notebook.content,
              cells: state.app.notebook.content.cells.map((c) =>
                c.id === cellId
                  ? {
                      ...c,
                      as_elements: c.as_elements.map((e) =>
                        e.id === elementId
                          ? {
                              ...e,
                              data: {
                                ...e.data,
                                outputs: [...(e.data.outputs || []), output],
                              },
                            }
                          : e
                      ),
                    }
                  : c
              ),
            },
          },
        },
      };
    } else {
      // This case mustn't happen. But nothing to do in this case
      return state;
    }
  },
  [addOutputToInputVariable](
    state,
    { path, cellId, variableId, output, parentType }
  ) {
    if (parentType === 'notebook') {
      const cell = selectCell(state, path, cellId);
      const updatedFields = {
        as_variables: cell.as_variables.map((v) =>
          v.id === variableId
            ? {
                ...v,
                rawValue: [...(v.rawValue || []), output],
                ...parseVariableValue(v, [...(v.rawValue || []), output]),
              }
            : v
        ),
      };
      return updateNotebookCells(state, path, cellId, updatedFields);
    } else if (parentType === 'app') {
      const executionPlanStepIndex = state.app.executionPlan.findIndex(
        (step) => step.cells && step.cells.some((c) => c.id === cellId)
      );
      if (executionPlanStepIndex < 0) return state;
      const executionPlanStep = state.app.executionPlan[executionPlanStepIndex];

      const updatedExecutionPlanStep = {
        ...executionPlanStep,
        // status: 'executing', // If one cell of the execution plan step is marked as now being executed the whole step is in state 'executing'
        cells: executionPlanStep.cells.map((c) =>
          c.id === cellId
            ? {
                ...c,
                as_variables: c.as_variables.map((v) =>
                  v.id === variableId
                    ? {
                        ...v,
                        rawValue: [...(v.rawValue || []), output],
                        ...parseVariableValue(v, [
                          ...(v.rawValue || []),
                          output,
                        ]),
                      }
                    : v
                ),
              }
            : c
        ),
      };

      return {
        ...state,
        app: {
          ...state.app,
          executionPlan: [
            // A. Update the execution plan
            ...state.app.executionPlan.slice(0, executionPlanStepIndex),
            updatedExecutionPlanStep,
            ...state.app.executionPlan.slice(executionPlanStepIndex + 1),
          ],
          notebook: {
            ...state.app.notebook,
            content: {
              ...state.app.notebook.content,
              cells: state.app.notebook.content.cells.map((c) =>
                c.id === cellId
                  ? {
                      ...c,
                      as_variables: c.as_variables.map((v) =>
                        v.id === variableId
                          ? {
                              ...v,
                              rawValue: [...(v.rawValue || []), output],
                              ...parseVariableValue(v, [
                                ...(v.rawValue || []),
                                output,
                              ]),
                            }
                          : v
                      ),
                    }
                  : c
              ),
            },
          },
        },
      };
    } else {
      // This case mustn't happen. But nothing to do in this case
      return state;
    }
  },
  [clearOutputOfInputOutputCell](state, { path, cellId, parentType }) {
    if (parentType === 'notebook') {
      // Update the notebook itself
      const cell = selectCell(state, path, cellId);
      const updatedFields = {
        as_elements: cell.as_elements.map((e) => ({
          ...e,
          data: {
            ...e.data,
            outputs: [],
          },
        })),
      };
      return updateNotebookCells(state, path, cellId, updatedFields);
    } else if (parentType === 'app') {
      // Check whether the app needs to be updated  too
      if (!state.app.executionPlan) return state;
      const executionPlanStepIndex = state.app.executionPlan.findIndex(
        (step) => step.cells && step.cells.some((c) => c.id === cellId)
      );
      if (executionPlanStepIndex < 0) return state;
      const executionPlanStep = state.app.executionPlan[executionPlanStepIndex];

      const updatedExecutionPlanStep = {
        ...executionPlanStep,
        // status: 'executing', // If one cell of the execution plan step is marked as now being executed the whole step is in state 'executing'
        cells: executionPlanStep.cells.map((c) =>
          c.id === cellId
            ? {
                ...c,
                as_elements: c.as_elements.map((e) => ({
                  ...e,
                  data: {
                    ...e.data,
                    outputs: [],
                  },
                })),
              }
            : c
        ),
      };

      return {
        ...state,
        app: {
          ...state.app,
          executionPlan: [
            // A. Update the apps execution plan
            ...state.app.executionPlan.slice(0, executionPlanStepIndex),
            updatedExecutionPlanStep,
            ...state.app.executionPlan.slice(executionPlanStepIndex + 1),
          ],
        },
      };
    } else {
      // console.log('Unknown parentType.');
    }
  },
  [clearOutputOfInputVariables](state, { path, cellId, parentType }) {
    if (parentType === 'notebook') {
      const cell = selectCell(state, path, cellId);
      const updatedFields = {
        as_variables: cell.as_variables.map((v) => ({
          ...v,
          rawValue: [],
          error: undefined,
        })),
      };
      return updateNotebookCells(state, path, cellId, updatedFields);
    } else if (parentType === 'app') {
      // Check whether the app needs to be updated  too
      if (!state.app.executionPlan) return state;
      const executionPlanStepIndex = state.app.executionPlan.findIndex(
        (step) => step.cells && step.cells.some((c) => c.id === cellId)
      );
      if (executionPlanStepIndex < 0) return state;
      const executionPlanStep = state.app.executionPlan[executionPlanStepIndex];

      const updatedExecutionPlanStep = {
        ...executionPlanStep,
        // status: 'executing', // If one cell of the execution plan step is marked as now being executed the whole step is in state 'executing'
        cells: executionPlanStep.cells.map((c) =>
          c.id === cellId
            ? {
                ...c,
                as_variables: c.as_variables.map((v) => ({
                  ...v,
                  rawValue: [],
                  error: undefined,
                })),
              }
            : c
        ),
      };

      return {
        ...state,
        app: {
          ...state.app,
          executionPlan: [
            // A. Update the apps execution plan
            ...state.app.executionPlan.slice(0, executionPlanStepIndex),
            updatedExecutionPlanStep,
            ...state.app.executionPlan.slice(executionPlanStepIndex + 1),
          ],
        },
      };
    } else {
      // console.log('clearOutputOfInputVariables unknown parentType');
    }
  },
  [addVariable](state, { path, cellId }) {
    const variableId = uuidv4();
    const emptyVariable = {
      id: variableId,
      name: '',
      type: 'string',
      rawValue: [],
      parsedValue: undefined, // Just to make it explicit
      error: undefined, // Just to make it explicit
    };

    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_variables: cell.as_variables
        ? [...cell.as_variables, emptyVariable]
        : [emptyVariable],
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [removeVariable](state, { path, cellId, variableId }) {
    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_variables: cell.as_variables
        ? cell.as_variables.filter((v) => v.id !== variableId)
        : [],
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [updateVariable](
    state,
    { path, cellId, variable, updateValues, queryParameter }
  ) {
    const cell = selectCell(state, path, cellId);
    const updatedFields = {
      as_variables: cell.as_variables.map((v) =>
        v.id === variable.id
          ? updateRecentVariable(v, updateValues, queryParameter)
          : v
      ),
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [showSelectVariable]: (
    state,
    { path, cellId, elementFieldName, validTypes, label }
  ) => ({
    ...state,
    selectVariable: {
      ...state.selectVariable,
      active: true,
      path,
      cellId,
      elementFieldName,
      validTypes,
      label,
    },
  }),
  [hideSelectVariable]: (state) => ({
    ...state,
    selectVariable: {
      active: false,
    },
  }),
  [toggleInputElements](state, { path, cellId, expanded }) {
    const updatedFields = {
      as_elements_expanded: expanded,
      unsavedChanges: true,
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [toggleInputVariables](state, { path, cellId, expanded }) {
    const updatedFields = {
      as_variables_expanded: expanded,
      unsavedChanges: true,
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [toggleOutputElements](state, { path, cellId, expanded }) {
    const updatedFields = {
      as_elements_expanded: expanded,
      unsavedChanges: true,
    };
    return updateNotebookCells(state, path, cellId, updatedFields);
  },
  [showAddInputElement]: (state, { path, cellId }) => ({
    ...state,
    addInputElementModal: {
      ...state.addInputElementModal,
      active: true,
      path,
      cellId,
    },
  }),
  [hideAddInputElement]: (state) => ({
    ...state,
    addInputElementModal: {
      active: false,
    },
  }),
  [showEditInputElement]: (state, { path, cellId, element, variables }) => ({
    ...state,
    editInputElementModal: {
      ...state.editInputElementModal,
      active: true,
      path,
      cellId,
      element,
      variables,
    },
  }),
  [hideEditInputElement]: (state) => ({
    ...state,
    editInputElementModal: {
      active: false,
    },
  }),
  [showAddOutputElement]: (state, { path, cellId }) => ({
    ...state,
    addOutputElementModal: {
      ...state.addOutputElementModal,
      active: true,
      path,
      cellId,
    },
  }),
  [hideAddOutputElement]: (state) => ({
    ...state,
    addOutputElementModal: {
      active: false,
    },
  }),
  [showEditOutputElement]: (state, { path, cellId, element }) => ({
    ...state,
    editOutputElementModal: {
      ...state.editOutputElementModal,
      active: true,
      path,
      cellId,
      element,
    },
  }),
  [hideEditOutputElement]: (state) => ({
    ...state,
    editOutputElementModal: {
      active: false,
    },
  }),
};

const updateRecentElement = (recentElement, updateValues) => {
  // -- 1 .Create the updated element
  const updatedElement = {
    ...recentElement,
    data: {
      ...recentElement.data,
      ...updateValues,
    },
  };

  // -- 2. Validate it
  const error = p3ValidateElement(updatedElement);

  // -- 3. Return the updatedElement with possible errors
  return {
    ...updatedElement,
    data: {
      ...(updatedElement.data || {}),
      error,
    },
  };
};

const updateRecentVariable = (recentVariable, updateValues, queryParameter) => {
  // -- 1. Create the updated variable
  const updatedVariable = {
    ...recentVariable,
    ...updateValues,
  };

  // -- 2. Parse the rawValue and validate it
  let { parsedValue, error } = parseVariableValue(
    updatedVariable,
    updatedVariable.rawValue
  );
  let source = updatedVariable.name;

  // -- 3. Special Case: If it's a String variable, the Query Parameter override was specified and the parameter is
  // actually given in the URL: Override the value.
  if (
    !error &&
    updatedVariable.type === VARIABLE_TYPES.STRING &&
    updatedVariable.override
  ) {
    // - Check whether the variable is given as a Query Parameter, if yes - use that value
    if (queryParameter[updatedVariable.name]) {
      parsedValue = queryParameter[updatedVariable.name];
      /* In this case the source to be executed is not only the variable name, but:
       * x = "abc" # This is additionally to override the variable value
       * x
       */
      source = `${updatedVariable.name}="${
        queryParameter[updatedVariable.name]
      }"\n${queryParameter[updatedVariable.name]}`;
    }
  }

  // -- 4. Return the updatedVariable with possible errors
  return {
    ...updatedVariable,
    source,
    parsedValue,
    error,
  };
};

export function* executeVariablesSaga({
  payload: { path, cellId, sessionId, variables, parentType },
}) {
  const socket = openSockets[sessionId];
  yield call(
    executeInputCellVariables,
    path,
    socket,
    sessionId,
    cellId,
    variables,
    parentType
  );
}

export function* watchExecuteVariables() {
  yield takeEvery(executeVariables.getType(), executeVariablesSaga);
}
