export const getOrder = (state, { habitatCode }) =>
  state.dashboard.habitats[habitatCode]?.order?.value;

export const getAugurs = (state, { habitatCode }) =>
  (state.dashboard.habitats[habitatCode] || {}).augurs;

export const getAmountArchivedAugurs = (state, { habitatCode }) =>
  (state.dashboard.habitats[habitatCode] || {}).amountArchivedAugurs;

export const getArchivedAugurs = (state, { habitatCode }) =>
  state.dashboard.habitats[habitatCode].archived;

export function getAugurName(state, habitatCode, augurCode) {
  const augurs = getAugurs(state, { habitatCode });
  const augur = augurs.find((augur) => augur.code === augurCode);
  return augur.name;
}

export function getArchiveAugurName(state, habitatCode, augurCode) {
  const augurs = getArchivedAugurs(state, { habitatCode });
  const augur = augurs.find((augur) => augur.code === augurCode);
  return augur?.name;
}
