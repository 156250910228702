import React, { Component } from 'react';
import { CProps } from './HabitatNameAndCode.container';

export default class HabitatNameAndCodeComponent extends Component<CProps> {
  render() {
    const { habitatCode, habitatName } = this.props;
    return (
      <div>
        <p>{habitatName}</p>
        <p>{habitatCode}</p>
      </div>
    );
  }
}
