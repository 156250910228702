import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiPlus } from 'react-icons/fi';
import {
  inputElementShape,
  variableShape,
} from './elements/_interface/P3InputElementShape';
import { getElementByType } from './elements/_interface/Python3InputElementManager';
import ExpandCollapseIcon from '../../../../../../../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import IconButton from '../../../../../../../../atoms/icon-button/IconButton';

export default class InputElements extends Component {
  render() {
    const { handleOpenDialogModal, cell, path, toggleInputElements } =
      this.props;
    const elements = cell.as_elements ? cell.as_elements : [];

    const elementsExpanded = Object.keys(cell || {}).includes(
      'as_elements_expanded'
    )
      ? cell.as_elements_expanded
      : true; // Default: Expanded

    return (
      <div className={'input-elements'}>
        <div className={'input-output-cell-headline-container'}>
          <div className={'input-output-cell-headline-left'}>
            <ExpandCollapseIcon
              isExpanded={elementsExpanded}
              onClick={() =>
                toggleInputElements(path, cell.id, !elementsExpanded)
              }
              color={'black'}
              size={20}
              title={['Minimize Elements', 'Maximize Elements']}
              withBackground
            />

            <div className={'visual-input-headline'}>
              <span>Input Elements</span>
            </div>

            <div className={'visual-input-amount-indicator'}>
              <span>{(elements || []).length}</span>
            </div>
          </div>

          <div className={'input-output-cell-headline-right'}>
            <IconButton
              title={'Add Input Element'}
              Icon={() => <FiPlus size={'20px'} />}
              onClick={handleOpenDialogModal}
            />
          </div>
        </div>

        <div className={'elements-parent'}>
          {elementsExpanded &&
            elements.map((e, i) =>
              getElementByType(e.type).renderWrappedComponent({
                key: `${cell.id}_${i}`,
                cell,
                path,
                element: e,
              })
            )}
          {elementsExpanded && (!elements || elements.length === 0) && (
            <span className={'empty'}>
              This Input Cell doesn't have Input Elements yet.
            </span>
          )}
        </div>
      </div>
    );
  }
}
InputElements.propTypes = {
  cell: PropTypes.shape({
    as_elements: PropTypes.arrayOf(inputElementShape),
    as_variables: PropTypes.arrayOf(variableShape),
    source: PropTypes.string,
    id: PropTypes.string,
    as_elements_expanded: PropTypes.bool,
    as_variables_expanded: PropTypes.bool,
  }).isRequired,
  path: PropTypes.string.isRequired,
  handleOpenDialogModal: PropTypes.func.isRequired,
  toggleInputElements: PropTypes.func.isRequired,
};
