import React, { FC } from 'react';
import styles from './styles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import ParameterTuningSelection from '../../../parameter-tuning/parameter-tuning-selection/ParameterTuningSelection';
import { ParameterTuningSchema } from '../../../parameter-tuning/parameter-tuning-selection/types';

export type Props = {
  /** The json schema describing the parameter tuning */
  parameterTuningSchema?: ParameterTuningSchema;
};

const PipelineTuning: FC<Props & WrappedFieldProps> = (props) => {
  const { input, meta, parameterTuningSchema } = props;

  return (
    <div className={styles.parameterTuning}>
      <ParameterTuningSelection
        input={input}
        meta={meta}
        parameterTuningSchema={parameterTuningSchema}
      />
    </div>
  );
};

export default PipelineTuning;
