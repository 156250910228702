import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputValueSafe } from '../../python3-input-cell/elements/_interface/input-element.form';
import DropdownSelectInput from '../../../../../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

export default class DropdownSelectCol extends Component {
  render() {
    const {
      label,
      placeholder,
      options,
      input: { onChange, value },
      meta: { touched, error },
    } = this.props;
    return (
      <div className={`input-col${error ? ' error' : ''}`}>
        <div className={'form--headline'}>
          <p className={'form--label'}>{label}</p>
          {touched && error && <p className={'form--error'}>{error}</p>}
        </div>
        <div className={'form--input-parent'}>
          <DropdownSelectInput
            id={label}
            name={label}
            options={options}
            placeholder={{ id: 'no-id', defaultMessage: placeholder }}
            value={options.find((o) => o.value === inputValueSafe(value))}
            onChange={(option) =>
              onChange({
                ...value,
                inputValue: option.value,
              })
            } // Construct the settingField (= { origin, variableName, inputValue })
            openOnFocus
            className=''
            clearable={false}
          />
        </div>
      </div>
    );
  }
}
DropdownSelectCol.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};
