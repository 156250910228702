import { connect, ConnectedProps } from 'react-redux';
import BackupsPage from './BackupsPage';
import { DeprecatedRootState } from '../../../../store/state.type';
import {
  fetchBackups,
  fetchTemplates,
  hideRestoreBackupModal,
  restoreBackup,
} from '../../../../store/admin/backups/actions';

export enum BackupMetaOrdering {
  LastCreated,
  OldestCreated,
}

export const backupMetaOrderings = [
  { label: 'Last Created', value: BackupMetaOrdering.LastCreated },
  { label: 'Oldest Created', value: BackupMetaOrdering.OldestCreated },
];

function mapStateToProps(state: DeprecatedRootState) {
  return {
    backupsMeta: state.adminBackups.backups,
    restoreBackupModal: state.adminBackups.restoreBackupModal,
  };
}

const mapDispatchToProps = {
  fetchBackups,
  fetchTemplates,
  hideRestoreBackupModal,
  restoreBackup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type connectedProps = ConnectedProps<typeof connector>;
export default connector(BackupsPage);
