import React, { Component, Fragment } from 'react';
import { BASE_FILE_PATH } from './ButtonsBaseFile';
import { getCellByType } from '../../../../../../workbench/fileTypes/notebook/component/notebook-cells/NotebookCellManager';
import { AltaSigmaCell } from '../../../../../../../store/workbench/state.types';

type Props = {
  /** The notebook cell to render */
  cell: AltaSigmaCell;

  /** Render the Cell IDs for debugging? */
  debugRenderCellIds: boolean;

  selectedCells: string[];
  changeSource: (payload: {
    path: string;
    cellId: string;
    source: string;
  }) => void;
  selectCells: (path: string, cellsToSelect: string[]) => void;
};

export default class SingleNotebookCell extends Component<Props, {}> {
  render() {
    const {
      debugRenderCellIds,
      selectedCells,
      selectCells,
      cell,
      changeSource,
    } = this.props;
    return (
      <Fragment>
        {debugRenderCellIds && <span style={{ color: 'grey' }}>{cell.id}</span>}
        {getCellByType(cell.cell_type).renderWrappedComponent({
          key: cell.id,
          cell,
          path: BASE_FILE_PATH,
          session: {},
          selectedCells,
          selectCells,
          executeCells: () => {},
          changeSource,
          requestCodeCompletion: () => {},
          clearCodeCompletion: () => {},
          renderFlap: false,
        })}
      </Fragment>
    );
  }
}
