import * as icons from '../../core/constants/icons';

export const initial = {
  activeTabId: 'accuracy',
  tabs: [
    {
      id: 'accuracy',
      title: 'details.sideNav.accuracy',
      icon: icons.accuracy,
    },
    {
      id: 'model-viewer',
      title: 'details.sideNav.model_viewer',
      icon: icons.modelViewer,
    },
    {
      id: 'influencers',
      title: 'details.sideNav.influencers',
      icon: icons.influencers,
    },
    {
      id: 'performance-drift',
      title: 'details.sideNav.performance_drift',
      icon: icons.performance,
    },
    {
      id: 'rejected-features',
      title: 'details.sideNav.rejected_features',
      icon: icons.rejectedFeatures,
    },
    {
      id: 'candidate-conditions',
      title: 'details.sideNav.candidate_conditions',
      icon: icons.candidateConditions,
    },
    {
      id: 'settings',
      title: 'details.sideNav.settings',
      icon: icons.settings,
    },
  ],
  augurCode: '',
  habitatCode: '',
  augur: {},
  loading: false,
  loaded: false,
  error: '',
};

export const test = {
  tabs: [
    {
      id: 'id',
      title: 'Model Viewer',
      icon: icons.modelViewer,
    },
  ],
  augurCode: 'code1',
  habitatCode: 'code2',
  augur: {},
  loading: false,
  loaded: false,
  error: '',
};
