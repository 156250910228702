import { connect, ConnectedProps } from 'react-redux';
import KernelSpecs, { Props } from './KernelSpecs';
import { RootState } from '../../../../../../../store/store';
import { getSelectedKernel } from '../../notebook.form';

export function mapStateToProps(state: RootState, props: Props) {
  return {
    // @ts-ignore
    kernelspecs: ((state.workbench || {}).kernelspecs || {}).kernelspecs,
    // @ts-ignore
    error: ((state.workbench || {}).kernelspecs || {}).error,
    selectedKernel: props.isRepositoryWizard
      ? props.selectedKernel
      : getSelectedKernel(state),
  };
}

const connector = connect(mapStateToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(KernelSpecs);
