export function getX(item: [number, number]): number {
  return +item[0];
}

export function getY(item: [number, number]): number {
  return +item[1];
}

export function getClosestTo(x: number, data: [number, number][]) {
  // TODO is this typed correctly?
  return data.reduce((prev, curr) =>
    Math.abs(getX(curr) - x) < Math.abs(getX(prev) - x) ? curr : prev
  );
}

export function getRoundedY(item: [number, number]) {
  return +getY(item).toFixed(3);
}

export function getRoundedX(item: [number, number]) {
  return +getX(item).toFixed(3);
}
