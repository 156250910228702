import React, { ComponentType } from 'react';
import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import ModelArchive from './ModelArchive';
import { MessageDescriptor } from 'react-intl';

export default class ModelsHistoryWrapper extends ComponentAugurDetailsTab {
  component: ComponentType = ModelArchive;
  icon: ComponentType = () => <span className={'icon-model-history'} />;
  id = 'models';
  to = (habitatCode: string, augurCode: string, shortAugurType: string) =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/models`;
  subRoutes: object[] | undefined = undefined;
  title: MessageDescriptor = {
    id: 'details.sideNav.models',
    defaultMessage: 'Model Archive',
  };
}
