import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigMap from './ConfigMap.container';
import Busy from '../../atoms/busy/Busy';

export default class JobConfiguration extends Component {
  componentDidMount() {
    const { fetchJobConfigs } = this.props;
    fetchJobConfigs();
  }

  renderLoading() {
    return <Busy isBusy />;
  }

  renderError() {
    const { error } = this.props;
    return <span>Error: {JSON.stringify(error)}</span>;
  }

  renderLoaded() {
    const { cmNames } = this.props;
    return (
      <div className={'jobconfigs-parent'}>
        {cmNames &&
          cmNames.map((cmName) => <ConfigMap key={cmName} cmName={cmName} />)}
      </div>
    );
  }

  renderInner() {
    const { error, loading } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    return this.renderLoaded();
  }

  render() {
    return (
      <div className='Orchestration--content'>
        <div className={'Orchestration--inner-content'}>
          {this.renderInner()}
        </div>
      </div>
    );
  }
}

JobConfiguration.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  cmNames: PropTypes.arrayOf(PropTypes.string),
  fetchJobConfigs: PropTypes.func.isRequired,
};
