import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const ModuleCardLoading: FC = (props) => {
  return (
    <div className={classNames(styles.moduleCard, styles.moduleCardLoading)}>
      <div className={styles.iconContainer}>
        <div className={classNames(styles.iconCircle, styles.loading)} />
      </div>
      <div className={styles.nameContainer}>
        <span className={classNames(styles.nameBar1, styles.loading)} />
        <span className={classNames(styles.nameBar2, styles.loading)} />
      </div>
    </div>
  );
};

export default ModuleCardLoading;
