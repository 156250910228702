import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Button from '../../atoms/button/Button';

export default class ButtonBar extends Component {
  static propTypes = {
    datapoolCode: PropTypes.string.isRequired,
    habitatCode: PropTypes.string.isRequired,
    taxonomy: PropTypes.array.isRequired,
    fetchTaxonomy: PropTypes.func.isRequired,
    saveTaxonomy: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    saveButtonLoading: PropTypes.bool.isRequired,
  };

  handleCancel = () => {
    const { fetchTaxonomy, habitatCode, datapoolCode } = this.props;
    fetchTaxonomy(habitatCode, datapoolCode);
  };

  handleSave = () => {
    const { saveTaxonomy, habitatCode, datapoolCode, taxonomy } = this.props;

    saveTaxonomy(habitatCode, datapoolCode, taxonomy);
  };

  render() {
    const { saveButtonLoading, pristine } = this.props;

    return (
      <div className={styles.buttonBar}>
        <button
          type='button'
          className={classNames(styles.button, styles.buttonNeutral)}
          disabled={pristine}
          onClick={this.handleCancel}
        >
          Cancel
        </button>
        <button
          type='button'
          className={classNames(styles.button, styles.buttonOk)}
          disabled={pristine}
          onClick={this.handleSave}
        >
          {saveButtonLoading && (
            <ReactLoading
              style={{ width: '20px', height: '20px', fill: 'rgb(0, 0, 0)' }}
              type={'spinningBubbles'}
            />
          )}{' '}
          Save
        </button>
      </div>
    );
  }
}

ButtonBar.propTypes = {
  datapoolCode: PropTypes.string.isRequired,
  habitatCode: PropTypes.string.isRequired,
  taxonomy: PropTypes.array.isRequired,
  fetchTaxonomy: PropTypes.func.isRequired,
  saveTaxonomy: PropTypes.func.isRequired,
};
