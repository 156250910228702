import React, { Component, ReactChild, ReactChildren } from 'react';
import ReactIntl, { FormattedMessage, MessageDescriptor } from 'react-intl';
import './styles.scss';
import Button, { ButtonProps } from '../../atoms/button/Button';

export type Props = {
  /** Additional class name for the main container component */
  additionalClassname?: string;
  /** Does the wizard have a headline? */
  withHeadline: boolean;
  /** Message ID for the headline */
  headlineId?: string;
  /** Default Message for the headline if the intl id can't be resolved */
  headlineDefault: string;
  /** Specs for the buttons to render */
  buttons: ButtonProps[];
  /** Optional description for the entire wizard. */
  description?: MessageDescriptor;
};

/**
 * Parent component for all Wizards.
 * It's supposed to be used as a (in the best case: direct) child of a MainContainer
 */
class Wizard extends Component<Props> {
  render() {
    const {
      additionalClassname,
      children,
      withHeadline,
      headlineId,
      headlineDefault,
      buttons,
      description,
    } = this.props;
    return (
      <div
        className={
          'Wizard' + (additionalClassname ? ` ${additionalClassname}` : '')
        }
      >
        <div className={'Wizard--inner'}>
          {withHeadline && (
            <FormattedMessage
              id={headlineId || 'no-intl-id'}
              defaultMessage={headlineDefault}
            >
              {(txt) => <span className={'Wizard--headline'}>{txt}</span>}
            </FormattedMessage>
          )}
          {description && (
            <div className={'Wizard--description'}>
              <FormattedMessage
                id={description.id}
                defaultMessage={description.defaultMessage}
              />
            </div>
          )}
          {children}
          <div className={'Wizard--buttons'}>
            {buttons.map((buttonArgs, i) => (
              <Button {...buttonArgs} key={`btn_${i}`} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Wizard;
