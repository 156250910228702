import React, { FC } from 'react';
import styles from './styles.module.scss';
import DirectoryUp from './DirectoryUp';
import { S3Object } from '../../../../../store/dataManagement/state.types';
import classNames from 'classnames';
import DirectoryElement from './DirectoryElement';
import { objectIsImage, objectIsTable } from '../../../util';
import TableElement from './TableElement';
import FileElement from './FileElement';
import ImageElement from './ImageElement';

type Props = {
  bucketPath?: string;
  dataSourceCode: string;
  bucket: string;
  data?: S3Object[];
  showRenameModal: (renameFile: string, renamePath: string) => void;
  showDeleteModal: (deletePath: string) => void;
};

const isEmpty = (data) => {
  return !data || data.length === 0;
};

const isRoot = (bucketPath) => {
  return !(bucketPath !== undefined && bucketPath !== '/' && bucketPath !== '');
};

const FileBrowser: FC<Props> = (props) => {
  const {
    bucketPath,
    dataSourceCode,
    bucket,
    data,
    showRenameModal,
    showDeleteModal,
  } = props;

  return (
    <div className={styles.fileBrowser}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.rowHeader}>
            <th className={styles.colIcon} />
            <th className={styles.colName}>Name</th>
            <th className={styles.colCreatedAt}>Created At</th>
            <th className={styles.colSize}>Size</th>
            <th className={styles.colActions}>Actions</th>
          </tr>
        </thead>
        <tbody>
          <DirectoryUp
            bucket={bucket}
            bucketPath={bucketPath}
            dataSourceCode={dataSourceCode}
          />
          <FileBrowserEntries
            bucket={bucket}
            bucketPath={bucketPath}
            dataSourceCode={dataSourceCode}
            data={data}
            showRenameModal={showRenameModal}
            showDeleteModal={showDeleteModal}
          />
        </tbody>
      </table>
    </div>
  );
};
export default FileBrowser;

const FileBrowserEntries: FC<Props> = (props) => {
  const {
    bucketPath,
    dataSourceCode,
    bucket,
    data,
    showDeleteModal,
    showRenameModal,
  } = props;

  if (isEmpty(data) && isRoot(bucketPath)) {
    return (
      <tr className={classNames(styles.row, styles.rowEmptyIndicator)}>
        <td colSpan={5}>This bucket is empty</td>
      </tr>
    );
  }

  if (isEmpty(data) && !isRoot(bucketPath)) {
    return (
      <tr className={classNames(styles.row, styles.rowEmptyIndicator)}>
        <td colSpan={5}>This directory is empty</td>
      </tr>
    );
  }

  const sortedData = data.sort((a, b) => {
    let score = 0;
    if (a.objectType === 'directory') score -= 10;
    if (b.objectType === 'directory') score += 10;
    if (a.name > b.name) score += 1;
    return score;
  });

  const renderElement = (element) => {
    if (element.objectType === 'directory') {
      return (
        <DirectoryElement
          element={element}
          dataSourceCode={dataSourceCode}
          bucket={bucket}
        />
      );
    } else if (objectIsTable(element)) {
      return (
        <TableElement
          element={element}
          dataSourceCode={dataSourceCode}
          bucket={bucket}
          showRenameModal={showRenameModal}
          showDeleteModal={showDeleteModal}
        />
      );
    } else if (objectIsImage(element)) {
      return (
        <ImageElement
          element={element}
          dataSourceCode={dataSourceCode}
          bucket={bucket}
          showRenameModal={showRenameModal}
          showDeleteModal={showDeleteModal}
        />
      );
    } else {
      return (
        <FileElement
          element={element}
          dataSourceCode={dataSourceCode}
          bucket={bucket}
          showRenameModal={showRenameModal}
          showDeleteModal={showDeleteModal}
        />
      );
    }
  };

  return <>{sortedData.map(renderElement)}</>;
};
