import * as React from 'react';
import { FC, PropsWithChildren } from 'react';
import GenericFileBrowser, {
  ContentElement,
} from '../generic-file-browser/GenericFileBrowser';
import { Result } from '../../../../utils';
import {
  buttonBarButtons,
  contextMenuEntries,
  dnd,
  icons,
  onClickListeners,
} from './WorkbenchFileBrowser.defaults';

export type Props = {
  content: Result<ContentElement[], string>;
  path: string;
  width: number;
};

const WorkbenchFileBrowser: FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <GenericFileBrowser
      content={props.content}
      buttons={buttonBarButtons}
      onClickListeners={onClickListeners}
      contextMenuEntries={contextMenuEntries}
      dnd={dnd}
      icons={icons}
      width={props.width}
      showDiskSpace
      showMetrics
      showFileSizes
      path={props.path}
    />
  );
};

export default WorkbenchFileBrowser;
