import { connect } from 'react-redux';
import NotebookContent from './NotebookContent';
import {
  saveNotebook,
  selectCells,
} from '../../../../../../redux/workbench/modules/notebook.module';
import {
  clearCodeCompletion,
  executeCells,
  openSocket,
  requestCodeCompletion,
} from '../../../../../../redux/workbench/modules/notebook.websocket.module';
import { changeSource } from '../../../../../../redux/workbench/modules/notebook.source.module';
import { getSessionDetails } from '../../../../../../redux/workbench/modules/sessions.module';

export const mapDispatchToProps = {
  openSocket,
  executeCells,
  saveNotebook,
  selectCells,
  changeSource,
  requestCodeCompletion,
  clearCodeCompletion,
  getSessionDetails,
};
export default connect(undefined, mapDispatchToProps)(NotebookContent);
