import React, { Component } from 'react';
import TargetHierarchy from '../../../../../../../../organisms/modelManagement/newAugurWizard/targetHierarchy/TargetHierarchy.container';
import { Field, InjectedFormProps } from 'redux-form';
import { fieldPredictionTargets, FormValues } from './TargetList.form';
import CancelSubmitButtonsWrapper from '../../../../../../../../molecules/reset-submit-buttons-wrapper/CancelSubmitButtonsWrapper';
import styles from './styles.module.scss';
import InfoBox from '../../../../../../../../atoms/info-box/InfoBox';
import { FormattedMessage } from 'react-intl';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

export type Props = {
  /** List of prediction targets */
  predictionTargets: string[];
};

export default class TargetList extends Component<
  Props &
    InjectedFormProps<FormValues, Props> &
    ModelSettingsProps<AugurSettingsDataNbc>
> {
  render() {
    const {
      initialValues,
      pristine,
      reset,
      predictionTargets,
      saveSettings,
      saving,
    } = this.props;
    return (
      <form>
        <div className={'TargetList'}>
          <div className={'TargetList--field-parent'}>
            <CancelSubmitButtonsWrapper
              disabled={pristine || saving}
              savingButtonBusy={saving}
              onCancel={reset}
              onSubmit={() =>
                saveSettings(
                  {
                    predictionTargets,
                  },
                  true
                )
              }
            >
              <div>
                <Field
                  name={fieldPredictionTargets}
                  component={TargetHierarchy}
                  initial={initialValues}
                  {...this.props}
                />
                <div className={styles.infoBox}>
                  <InfoBox>
                    <FormattedMessage
                      id={'augur.info_box.predictionTargets'}
                      defaultMessage={
                        'The selected targets will be used for deriving the training table of your model. ' +
                        'Customers from the population table for which one of the selected events occurred will be used as positive examples (label=1), ' +
                        'customers for which none of the selected events occurred will be used as negative examples (label=0).'
                      }
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  </InfoBox>
                </div>
              </div>
            </CancelSubmitButtonsWrapper>
          </div>
        </div>
      </form>
    );
  }
}
