import { connect } from 'react-redux';
import { RootState } from '../../../../store/store';
import {
  Data as FormData,
  ErrorType,
  fieldDescription,
  fieldJobGroup,
  fieldName,
  form,
} from './form';
import { reduxForm } from 'redux-form';
import JobGroupWizard, { Props } from './JobGroupWizard';

function mapStateToProps(state: RootState, props: Props) {
  const jobGroup = state.jobGroups?.data?.find(
    (jg) => jg.code === props.jobGroupCode
  );

  if (jobGroup === undefined) {
    return {};
  } else {
    return {
      jobGroupSubmitting: state.jobGroups?.jobGroupSubmitting,
      initialValues: {
        [fieldName]: jobGroup.name,
        [fieldDescription]: jobGroup.description,
        [fieldJobGroup]: jobGroup,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<FormData, Props, ErrorType>(form)(JobGroupWizard)
);
