import DatapoolSettingsCategory, {
  WhiteOrGrey,
} from '../../../../_interfaces/datapool-details/settings/DatapoolSettingsCategory';
import DatapoolSettingsElement from '../../../../_interfaces/datapool-details/settings/DatapoolSettingsElement';
import DatapoolNameAndCode from './elements/DatapoolNameAndCode';
import HabitatNameAndCode from './elements/HabitatNameAndCode';
import DataSource from './elements/DataSource';

/**
 * Shows the habitat name & code, datapool name & code, archetype name & code and datapool name & code in the Datapool Settings
 */
export default class NamesAndCodesCategory implements DatapoolSettingsCategory {
  title = 'dashboard.datapool_settings.category.names_and_codes';
  children(): DatapoolSettingsElement[] {
    return [
      new DatapoolNameAndCode(),
      new HabitatNameAndCode(),
      new DataSource(),
    ];
  }
  backgroundColor: WhiteOrGrey = 'white';
}
