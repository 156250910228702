import { MainTabType } from './MainTab';
import { appsRoutes } from '../../../apps/routes';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import { collaborationSpaceRoutes } from '../../../collaborationSpace/routes';
import { dataManagementRoutes } from '../../../dataManagement/routes';
import {
  FiDatabase,
  FiEdit,
  FiGitBranch,
  FiGrid,
  FiLayers,
  FiMonitor,
} from 'react-icons/fi';
import React from 'react';
import {
  RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
} from 'common/dist/constants/keycloak';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';

/**
 * Derives the main tabs.
 * At the moment this is not really spectacular, but once the main tabs can be authorized the logic can simply be
 * implemented here
 */
export function deriveMainTabs(permissions: Record<string, string[]>): {
  mainTabs: MainTabType[];
  mainTabIds: string[];
} {
  const mainTabs = [] as MainTabType[];

  const showModelManagement = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX
  );
  const showAiApps = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX
  );
  const showWorkbench = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX
  );
  const showCollabSpace = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX
  );
  const showOrchestration = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX
  );
  const showDataMan = hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX
  );
  const ICON_SIZE = 20;

  // --- Model Management
  if (showModelManagement) {
    mainTabs.push({
      id: 'models',
      path: '/app/models',
      title: {
        id: 'dashboard.tab.modelManagement',
        defaultMessage: 'Model Management',
      },
      Icon: () => <FiGrid size={ICON_SIZE} />,
    });
  }

  // --- AI Apps
  if (showAiApps) {
    mainTabs.push({
      id: 'apps',
      path: appsRoutes.basePath,
      title: {
        id: 'dashboard.tab.apps',
        defaultMessage: 'AI Apps',
      },
      Icon: () => <FiMonitor size={ICON_SIZE} />,
    });
  }

  // --- Workbench
  if (showWorkbench) {
    mainTabs.push({
      id: 'workbench',
      path: workbenchRoutes.basePath,
      title: {
        id: 'dashboard.tab.workbench',
        defaultMessage: 'Workbench',
      },
      Icon: () => <FiEdit size={ICON_SIZE} />,
    });
  }

  // --- Collaboration Space
  if (showCollabSpace) {
    mainTabs.push({
      id: 'collab',
      path: collaborationSpaceRoutes.basePath,
      title: {
        id: 'dashboard.tab.collaboration_space',
        defaultMessage: 'Collaboration Space',
      },
      Icon: () => <FiGitBranch size={ICON_SIZE} />,
    });
  }

  // --- Orchestration
  if (showOrchestration) {
    mainTabs.push({
      id: 'orchestration',
      path: '/app/orchestration',
      title: {
        id: 'dashboard.tab.orchestration',
        defaultMessage: 'Orchestration',
      },
      Icon: () => <FiLayers size={ICON_SIZE} />,
    });
  }

  // --- Data Management
  if (showDataMan) {
    mainTabs.push({
      id: 'data',
      path: dataManagementRoutes.basePath,
      title: {
        id: 'dashboard.tab.data_management',
        defaultMessage: 'Data Management',
      },
      Icon: () => <FiDatabase size={ICON_SIZE} />,
    });
  }

  const mainTabIds = mainTabs.map((mt) => mt.id);
  return { mainTabs, mainTabIds };
}
