import React, { FC } from 'react';
import { OptionProps } from 'react-select';
import { OptionType } from './Datapool';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type OptionComponentProps = OptionProps<OptionType, false>;
const OptionComponent: FC<OptionComponentProps> = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <div
      {...innerProps}
      className={classNames(styles.optionComponent, {
        [styles.isSelected]: props.isSelected,
      })}
      ref={innerRef}
      data-testingIdentifier={props.data.label}
    >
      <span className={styles.title}>{props.data.datapool.name}</span>
      <span className={styles.subtitle}>{props.data.intlModuleType}</span>
    </div>
  );
};

export default OptionComponent;
