import { EnableMaintenanceRequest } from '../../../components/admin/maintenance/types';

export const ENABLE_MAINTENANCE = 'enable maintenance';
export const ENABLE_MAINTENANCE_SUCCESS = 'enable maintenance - success';
export const ENABLE_MAINTENANCE_FAIL = 'enable maintenance - fail';

export interface EnableMaintenanceAction {
  type: typeof ENABLE_MAINTENANCE;
  payload: { data: EnableMaintenanceRequest };
}

export interface EnableMaintenanceSuccessAction {
  type: typeof ENABLE_MAINTENANCE_SUCCESS;
}

export interface EnableMaintenanceFailAction {
  type: typeof ENABLE_MAINTENANCE_FAIL;
  payload: { error: string };
}

export type AdminMaintenanceActions =
  | EnableMaintenanceAction
  | EnableMaintenanceSuccessAction
  | EnableMaintenanceFailAction;
