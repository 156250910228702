import { connect, ConnectedProps } from 'react-redux';
import Statistics, { Props } from './Statistics';
import {
  updateDatapool,
  updateSelectedTables,
} from '../../../../../../redux/modules/datapool.details.module';
import { fetchCassandraTables } from '../../../../../../redux/modules/data.cassandra.module';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../../../../../store/store';
import { RouteComponentProps } from 'react-router';

function calcStatsFor(state) {
  if (!state.datapoolDetails.datapool) return [];
  if (!state.datapoolDetails.datapool.calcStatsFor) return [];
  return state.datapoolDetails.datapool.calcStatsFor;
}

function selectedTables(state) {
  if (!state.datapoolDetails.datapool) return calcStatsFor(state);
  if (!state.datapoolDetails.datapool.selectedTables)
    return calcStatsFor(state);
  return state.datapoolDetails.datapool.selectedTables;
}

export function mapStateToProps(
  state: RootState,
  props: Props &
    RouteComponentProps<{ habitatCode: string; datapoolCode: string }>
) {
  // TODO This is fixed to the single Cassandra instance and should be made dynamic later (to allow different backends
  //  for the datapools)
  const datapoolCode = props.match.params.datapoolCode || '';
  const keyspaceName = `datapool_${datapoolCode.toLowerCase()}`;

  let dataSourceCode = undefined;
  let defaultCassandraDs = undefined;
  // Will be fetched by parent container DatapoolDetails
  const dataSources = state.data.dataSources;
  if (dataSources && dataSources.data) {
    defaultCassandraDs = dataSources.data.find(
      (ds) => ds.role === 'default_cassandra'
    );
    if (defaultCassandraDs) {
      dataSourceCode = defaultCassandraDs.code;
    }
  }

  const { loading, loaded, error, data } =
    ((state.data.cassandra[dataSourceCode] || {}).tables || {})[keyspaceName] ||
    {};

  return {
    defaultCassandraDs,
    datapoolCode,
    habitatCode: props.match.params.habitatCode,
    selectedTables: selectedTables(state),
    initialSelectedTables: calcStatsFor(state),
    loading,
    loaded,
    error,
    data,
    dataSourceCode,
    keyspaceName: undefined, // TODO
  };
}

export const mapDispatchToProps = {
  fetchCassandraTables,

  updateSelectedTables,
  updateDatapool,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default withRouter(connector(Statistics));
