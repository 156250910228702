import { CustormerTableErrorType } from './OptCustomerTable';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { CustomerTableValueType } from 'common/dist/types/augurSettings.opt';

export function validateCustomerTable(customerTable: CustomerTableValueType) {
  const error: CustormerTableErrorType = {};

  // The tableName is required
  if (!customerTable?.tableName) {
    error['tableName'] =
      moduleOptimizationMessages.msgNewAugurStepCustomerTableErrNoTable;
  }

  // The customerIdColumn is required
  if (!customerTable?.customerIdColumn) {
    error['customerIdColumn'] =
      moduleOptimizationMessages.msgNewAugurStepCustomerTableErrNoCustomerIdColumn;
  }

  // The valueIdColumn is required
  if (!customerTable?.valueIdColumn) {
    error['valueIdColumn'] =
      moduleOptimizationMessages.msgNewAugurStepCustomerTableErrNoValueIdColumn;
  }

  // The valueColumn is required
  if (!customerTable?.valueColumn) {
    error['valueColumn'] =
      moduleOptimizationMessages.msgNewAugurStepCustomerTableErrNoValueColumn;
  }

  return cleanErrorObject(error);
}
