import React, { FC } from 'react';
import { renderParameterDescription } from './NodeEditor';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import _ from 'lodash';
import { MessageDescriptor } from 'react-intl';
import { SelectedTuningParameter } from 'common/dist/types/pipeline';
import { TuningParameterType } from '../../_pipeline-tuning-results-common/types';

type Props = {
  isTuneable: boolean;
  tuningParameters: TuningParameterType[];
  value: SelectedTuningParameter[];
  onChange: (value: SelectedTuningParameter[]) => void;
  onBlur: () => void;
  onFocus: () => void;
  error: {
    [parameterId: string]: MessageDescriptor;
  };
};

const TuningParameters: FC<Props> = (props) => {
  const {
    isTuneable,
    tuningParameters,
    value,
    onChange,
    onFocus,
    onBlur,
    error,
  } = props;

  if (!tuningParameters) {
    return null;
  } else {
    const paramsMap = _.keyBy(value, 'id');

    return (
      <div className={'PipelineTuningSelection--tuning'}>
        <span className={'PipelineTuningSelection--params-headline'}>
          Tunable Parameters
        </span>

        <div className={'PipelineTuningSelection--params-container'}>
          {tuningParameters.map((param) => {
            return (
              <div className={'PipelineTuningSelection--params-param'}>
                <span
                  className={'PipelineTuningSelection--params-param-headline'}
                >
                  {param.displayName}
                </span>
                {renderParameterDescription(param.description)}
                <div
                  className={
                    'PipelineTuningSelection--params-param-input-container'
                  }
                >
                  <TextInputLine
                    id={param.id}
                    name={param.id}
                    hasLabel={false}
                    placeholderDefault={param.displayName}
                    disabled={!isTuneable}
                    touched={true}
                    error={error?.[param.id]}
                    valid={!error?.[param.id]}
                    value={paramsMap[param.id]?.valueCandidates?.join(',')}
                    onChange={(e) =>
                      onChange(
                        value.map((p) =>
                          p.id === param.id
                            ? {
                                ...paramsMap[p.id],
                                valueCandidates:
                                  e.currentTarget.value.split(','),
                              }
                            : p
                        )
                      )
                    }
                    onFocus={onFocus}
                    onBlur={onBlur}
                    wrapMultipleErrorLines={true}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default TuningParameters;
