import {
  validateDescription,
  validateName,
  validateSingleChannel,
} from '../optChannels/validate';
import {
  PropensitiesErrorType,
  PropensitiesValueType,
} from './OptPropensities';
import { MessageDescriptor } from 'react-intl';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { _globalValidationError } from '../../../../modelManagement/modules/implementations/optimization/new-augur/optimization.form';
import _ from 'lodash';
import { Propensity } from 'common/dist/types/module.optimization';

export const valueIdRegex = /^[A-Za-z0-9_-]*$/;

export const validateValueId = (
  valueId?: string,
  existingValueIds?: string[]
): MessageDescriptor | undefined => {
  // The name field is required
  if (!valueId) {
    return moduleOptimizationMessages.msgNewDatapoolStepPropensitiesErrNoValueId;
  }

  // Valid characters
  if (!valueIdRegex.test(valueId)) {
    return moduleOptimizationMessages.msgNewDatapoolStepPropensitiesErrValueIdInvalidChar;
  }

  // Max length
  if (valueId.length > 64) {
    return moduleOptimizationMessages.msgNewDatapoolStepPropensitiesErrValueIdLength;
  }

  // Prevent valueId duplicates
  if ((existingValueIds || []).includes(valueId)) {
    return moduleOptimizationMessages.msgNewDatapoolStepPropensitiesErrValueIdExists;
  }

  return null;
};

/**
 * Validation for the "sub form": add propensity
 * @param value
 */
export const validatePropensities = (
  value: PropensitiesValueType
): PropensitiesErrorType => {
  const error = {};
  // Is there at least one propensity added?
  if (!value || value.length === 0) {
    error[_globalValidationError] =
      moduleOptimizationMessages.msgNewDatapoolStepPropensitiesErrNoPropensities;
  } else {
    value?.forEach((propensity) => {
      const constraintError = validateSinglePropensity(propensity, value);
      if (!_.isEmpty(constraintError)) {
        error[propensity.id] = constraintError;
      }
    });
  }

  return cleanErrorObject(error);
};

export const validateSinglePropensity = (
  propensity: Partial<Propensity>,
  allPropensities?: Partial<Propensity>[]
): PropensitiesErrorType => {
  const error = {};
  // Validate name
  error['valueId'] = validateValueId(
    propensity.valueId,
    (allPropensities || [])
      .filter((x) => x.id !== propensity.id)
      .map((pr) => pr.valueId)
  );
  // Validate description
  error['description'] = validateDescription(propensity.description);

  return cleanErrorObject(error);
};
