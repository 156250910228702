import React, { FC } from 'react';
import './styles.scss';
import { Node } from 'react-flow-renderer';
import { Props as JobProps } from '../job/Job';
import { executionType, JobType } from 'common/dist/types/job';
import { CodeCapsulesPerCode, CodeCapsulesPerHabitatCode } from './types';
import RunCodeCapsuleModal from '../../../runCodeCapsuleModal/RunCodeCapsuleModal.container';
import { CodeCapsule, CodeCapsuleVersion } from 'common/dist/types/codeCapsule';
import BackupJobModal from '../../../orchestration/job-schedules/add-schedule/backup-job-modal/BackupJobModal';

export const hasModal = (superType: string) =>
  ['code-capsule', 'backup'].includes(superType);

export interface ModalProps {
  isOpen: boolean;
  type?: 'backup';
  onRequestClose?: () => void;
  /** The modal takes a transformed node instead of just a job, because that way positioning etc. can happen before
   * and on finishing the modal, the node can just be added */
  node?: Node<JobProps>;
  /** Function to call when modal has created a new node and is closed */
  handleFinishedNode?: (finishedNode: Node<JobProps>) => void;
  codeCapsulesPerHabitatCode?: CodeCapsulesPerHabitatCode;
  codeCapsulesPerCode?: CodeCapsulesPerCode;
  edit?: boolean;
}

/**
 * TODO: Correct? Set jobCode on a job that was just edited by a modal
 * @param job
 * @param node
 * @param jobCode
 */
function finalizeNode(
  job: Readonly<Partial<JobType>>,
  node: Readonly<Node<JobProps>>,
  jobCode: string
): Node<JobProps> {
  const newJobWithJobCode = {
    ...job,
    jobCode: jobCode,
  } as JobType;
  return {
    ...node,
    data: { ...node.data, job: newJobWithJobCode },
  };
}

const Modals: FC<ModalProps> = (props) => {
  const { edit, isOpen } = props;
  const partialJob: Partial<JobType> = props.node?.data.job;
  let codeCapsuleVersions: CodeCapsuleVersion[] | undefined;
  if (partialJob?.superType === 'code-capsule') {
    const codeCapsule: CodeCapsule =
      props.codeCapsulesPerCode?.[partialJob.codeCapsuleCode];
    codeCapsuleVersions = codeCapsule.versions;
  }
  // Force remounting of Modals (and therefore resetting redux form/state - the goal) when closing and opening modals
  // Seems a bit daft and beside the point of this kind of modal, but it's also how it's done elsewhere
  if (!isOpen) {
    return null;
  }
  return (
    <div>
      {partialJob?.superType === 'backup' && (
        <BackupJobModal
          isOpen={props.isOpen}
          close={props.onRequestClose}
          onSubmit={(newJob) => {
            props.handleFinishedNode(
              finalizeNode(newJob, props.node, partialJob.jobCode)
            );
          }}
        />
      )}
      {partialJob?.superType === 'code-capsule' && (
        <RunCodeCapsuleModal
          isOpen={props.isOpen}
          close={props.onRequestClose}
          // @ts-ignore
          onSubmit={(
            repoName,
            repoCode,
            versionNumber,
            versionImageName,
            codeCapsuleCode,
            notebooksToExecute,
            parsedResourceValues,
            parameterMap,
            habitatCode,
            requestCredentialsFor,
            name,
            priority,
            gpuProduct
          ) => {
            const newJob: Partial<JobType> = {
              superType: 'code-capsule',
              moduleType: 'code-capsule',
              executionType: executionType.OTHER,
              repositoryCode: repoCode,
              codeCapsuleCode,
              habitatCode,
              capsuleVersionNumber: versionNumber,
              capsuleImage: versionImageName,
              notebooksToExecute,
              resources: parsedResourceValues,
              parameters: parameterMap,
              jobType: partialJob.jobType,
              requestCredentialsFor: requestCredentialsFor || [],
              name,
              priority,
              gpu: { model: gpuProduct },
            };
            props.handleFinishedNode(
              finalizeNode(newJob, props.node, partialJob.jobCode)
            );
          }}
          versions={codeCapsuleVersions}
          codeCapsuleCode={partialJob?.codeCapsuleCode}
          repoCode={partialJob?.repositoryCode}
          submitButtonLabelDefaultMessage={edit ? 'Submit' : 'Add Job'}
          // @ts-ignore
          initialValues={
            edit
              ? {
                  ...partialJob,
                  parameters: partialJob.parameters,
                  // TODO why is the key named different here?
                  versionNumber: {
                    label: partialJob.capsuleVersionNumber,
                    value: partialJob.capsuleVersionNumber,
                  },
                }
              : undefined
          }
        />
      )}
    </div>
  );
};

export default Modals;
