import React, { Component } from 'react';
// @ts-ignore
import ReactModal from 'react-modal';
import { FiX } from 'react-icons/fi';
import { Props } from './FetchBranchModal.container';
import infoStyles from './../../../info/styles.module.scss';
import Button from '../../../../../../atoms/button/Button';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default class FetchBranchModal extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { infoFilePath, branch, gitFetchAndTrackRemoteBranch } = this.props;

    // --- Derive the repository path from the infoFilePath ( "abc/def/repository.rsk" -> "/abc/def")
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');
    // ---

    gitFetchAndTrackRemoteBranch(repositoryPath, 'origin/' + branch);
  }

  render() {
    const { isFetchBranchModalOpen, hideFetchBranchModal, branch } = this.props;

    return (
      <ReactModal
        isOpen={isFetchBranchModalOpen}
        contentLabel='Fetch Branch Modal'
        onRequestClose={hideFetchBranchModal}
        style={customStyles}
        appElement={document.getElementById('react-app')}
        parentSelector={
          () => document.querySelector('#MainContainer')
          /* Function that will be called to get the parent element
             that the modal will be attached to. */
        }
      >
        <div className={infoStyles.modalCommitParent}>
          <div
            title={'Close Dialog'}
            className={'workbench-button close-button'}
            onClick={hideFetchBranchModal}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <div className={infoStyles.modalCommitElements}>
            Do you want to fetch the remote branch origin/{branch}?
          </div>

          <div className={infoStyles.modalCommitButtons}>
            <Button
              buttonColor={'secondary'}
              withLink={false}
              buttonLabelDefault={'Fetch'}
              onClick={this.handleClick}
            />
          </div>
        </div>
      </ReactModal>
    );
  }
}
