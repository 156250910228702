import { validateConstraints } from '../../../../../organisms/modelManagement/newAugurWizard/optConstraints/validate';
import { ConstraintsValueType } from 'common/dist/types/augurSettings.opt';

export const formName = 'constraints';

export const fieldConstraints = 'constraints';

export type FormValues = {
  [fieldConstraints]: ConstraintsValueType;
};

export const validate = (values: FormValues) => {
  const errors = {};

  errors[fieldConstraints] = validateConstraints(values[fieldConstraints]);

  return errors;
};

export const constraintsForm = {
  form: formName,
  enableReinitialize: true,
  validate,
  touchOnChange: true,
};
