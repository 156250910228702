import { createAction } from 'redux-act';
import * as dashboard from '../../core/dashboard';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const setHabitatView = createAction(
  'set cards view',
  (habitatCode, isListView) => ({ habitatCode, isListView })
);

export const setActiveTab = createAction(
  'set active tab of the habitat overview',
  (habitatCode, activeTab) => ({ habitatCode, activeTab })
);

export const removeAugurFromHabitat = createAction(
  'remove augur from habitat',
  (habitatCode, augurCode) => ({ habitatCode, augurCode })
);

export const addAugurToHabitat = createAction(
  'add archived augur to the active augurs list',
  (habitatCode, augur) => ({ habitatCode, augur })
);

export const fetchComponentPermissions = createAction(
  'fetch component permissions'
);

export const fetchComponentPermissionsSuccess = createAction(
  'fetch component permissions - success',
  (data) => ({ data })
);

export const fetchComponentPermissionsFailure = createAction(
  'fetch component permissions - failure',
  (error) => ({ error })
);

export const reducer = {
  [setHabitatView]: (state, payload) =>
    dashboard.setHabitatView(state, payload),

  [setActiveTab]: (state, { habitatCode, activeTab }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      habitats: {
        ...state.dashboard.habitats,
        [habitatCode]: {
          ...state.dashboard.habitats[habitatCode],
          activeTab,
        },
      },
    },
  }),

  [removeAugurFromHabitat]: (state, { habitatCode, augurCode }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      habitats: dashboard.removeAugurFromHabitat(
        state.dashboard.habitats,
        habitatCode,
        augurCode
      ),
    },
  }),

  [addAugurToHabitat]: (state, { habitatCode, augur }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      habitats: dashboard.addAugurToHabitat(
        state.dashboard.habitats,
        habitatCode,
        augur
      ),
    },
  }),

  [fetchComponentPermissions]: (state) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      permissions: {
        ...state.dashboard.permissions,
        loading: true,
      },
    },
  }),

  [fetchComponentPermissionsSuccess]: (state, { data }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      permissions: {
        loading: false,
        loaded: true,
        error: undefined,
        data,
      },
    },
  }),

  [fetchComponentPermissionsFailure]: (state, { error }) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      permissions: {
        loading: false,
        loaded: true,
        error,
        data: undefined,
      },
    },
  }),
};

export function* fetchComponentPermissionsSaga() {
  const { response, error } = yield call(
    Api.permissions.evaluatePermissions,
    'dashboard-component'
  );
  if (response) {
    yield put(fetchComponentPermissionsSuccess(response));
  } else {
    yield put(fetchComponentPermissionsFailure(error));
  }
}

export function* watchFetchComponentPermissions() {
  yield takeEvery(
    fetchComponentPermissions.getType(),
    fetchComponentPermissionsSaga
  );
}
