import { connect } from 'react-redux';

import Notification from './Notification';
import { removeNotification } from '../../redux/modules/notifications.module';

export function mapStateToProps(
  state,
  {
    notification: {
      title,
      description,
      descriptionValues,
      id,
      type,
      titleValues,
      progress,
    },
  }
) {
  return {
    title,
    description,
    type,
    id,
    descriptionValues,
    titleValues,
    progress,
  };
}

export const mapDispatchToProps = {
  remove: removeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
