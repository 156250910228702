import React, { FC, Fragment } from 'react';
import { formatDate } from 'common/dist/utils/dates';
import { FormattedRelativeTimeConvenient } from '../../../atoms/formatted-date-time/FormattedDateTime';
import styles from '../styles.module.scss';

type Props = {
  createdAt: Date | undefined;
  renderDate: boolean;
  isErroneous?: boolean;
};

const TimestampAndLine: FC<Props> = (props: Props) => {
  const { createdAt, renderDate } = props;
  if (!createdAt) {
    // Happens for the "gap" entry
    return (
      <Fragment>
        <div className={styles.timestampContainer} />
        <div className={styles.lineContainer}>
          <div className={styles.line} />
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={styles.timestampContainer}>
        <div className={styles.timestamp}>
          {renderDate && (
            <span className={styles.date}>
              {createdAt && formatDate(new Date(createdAt), 'dd.MM.yyyy')}
            </span>
          )}
          <span className={styles.time}>
            {createdAt && formatDate(new Date(createdAt), ' HH:mm:ss')}
          </span>
        </div>
        <span className={styles.ago}>
          <FormattedRelativeTimeConvenient date={new Date(createdAt)} />
        </span>
      </div>
      <div className={styles.lineContainer}>
        <div className={styles.line} />
        <div className={styles.bubble} />
      </div>
    </Fragment>
  );
};

export default TimestampAndLine;
