import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { dataManagementRoutes } from '../../routes';
import { FiChevronLeft } from 'react-icons/fi';
import { UPLOAD_WIZARDS } from '../upload/uploadWizards';
import Busy from '../../../atoms/busy/Busy';
import { CProps } from './Tables.container';
import { RouteComponentProps } from 'react-router';
import Button from '../../../atoms/button/Button';

export interface Props {
  /** Code of the data source */
  dataSourceCode: string;
  showBackButton?: boolean;
  showUploadButton?: boolean;
  browseTableLink?: (
    dataSourceCode: string,
    keyspaceName: string,
    tableName: string
  ) => string;
}

export default class Tables extends Component<
  Props & CProps & RouteComponentProps
> {
  static defaultProps = {
    showBackButton: true,
    showUploadButton: true,
    browseTableLink: (dataSourceCode, keyspaceName, tableName) =>
      `${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}/keyspace/${keyspaceName}/table/${tableName}`,
  };

  componentDidMount() {
    const {
      fetchCassandraTables,
      fetchCassandraPermissions,
      dataSourceCode,
      keyspaceName,
    } = this.props;
    fetchCassandraPermissions(dataSourceCode);
    if (dataSourceCode && keyspaceName) {
      fetchCassandraTables(dataSourceCode, keyspaceName);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { fetchCassandraTables, dataSourceCode, keyspaceName } = this.props;
    if (
      dataSourceCode &&
      keyspaceName &&
      dataSourceCode !== prevProps.dataSourceCode
    ) {
      // This case happens when the data sources are loaded during component did mount. Then the dataSourceCode is
      //  updated, so now the Cassandra tables can finally be loaded.
      fetchCassandraTables(dataSourceCode, keyspaceName);
    }
  }

  render() {
    const {
      keyspaceName,
      data,
      dataSourceCode,
      error,
      loading,
      loaded,
      setActiveUploadWizard,
      showBackButton,
      browseTableLink,
      showUploadButton,
      allowedToUpload,
      allowedToSelect,
    } = this.props;

    if (error) {
      return (
        <div className={'cassandra-tables'}>
          <span className={'error'}>{JSON.stringify(error)}</span>
        </div>
      );
    }

    if (loading) {
      return <Busy isBusy />;
    }

    return (
      <div className={'cassandra-tables'}>
        <div className={'tables-header'}>
          {showBackButton && (
            <div className={'back-button-container'}>
              <Link
                to={`${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}/${dataManagementRoutes.keyspaces}`}
                style={{ textDecoration: 'none' }}
              >
                <div className={'back-button'}>
                  <FiChevronLeft size={20} className={'back-icon'} />
                  <span className={'back-text'}>Back to Keyspaces</span>
                </div>
              </Link>
            </div>
          )}
          {showUploadButton && allowedToUpload && (
            <div className={'headline-buttons'}>
              <Button
                buttonColor={'secondary'}
                withLink
                buttonLabelDefault={'Upload Data'}
                linkTo={`${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}/upload?keyspaceName=${keyspaceName}`}
                onClick={() =>
                  setActiveUploadWizard(UPLOAD_WIZARDS.UPLOAD, undefined)
                }
              />
            </div>
          )}
        </div>
        <span className={'tables-headline'}>
          Tables of Keyspace {keyspaceName}
        </span>
        {data && (
          <div className={'tables-list'}>
            {data
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((table) => {
                if (allowedToSelect) {
                  return (
                    <Link
                      to={browseTableLink(
                        dataSourceCode,
                        keyspaceName,
                        table.name
                      )}
                      style={{ textDecoration: 'none' }}
                      className={'table-element-wrapper'}
                    >
                      <div className={'table-element'}>
                        <span className={'table-name'}>{table.name}</span>
                      </div>
                    </Link>
                  );
                } else {
                  return (
                    <div className={'table-element-wrapper'}>
                      <div className={'table-element'}>
                        <span className={'table-name'}>{table.name}</span>
                      </div>
                    </div>
                  );
                }
              })}

            {loaded && (!data || data.length === 0) && (
              <span>There are no Tables in this Keyspace yet.</span>
            )}
          </div>
        )}
      </div>
    );
  }
}
