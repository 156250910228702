import React, { Component } from 'react';
import KernelSpec from './KernelSpec';
import { CProps } from './KernelSpecs.container';
import { WrappedFieldProps } from 'redux-form';

export interface Props {
  selectedKernel?: {
    name: string;
  };
  /** Filters the available Kernels */
  kernelNameFilter?: (...args: unknown[]) => unknown;
  isRepositoryWizard?: boolean;
}

export default class KernelSpecs extends Component<
  Props & CProps & WrappedFieldProps
> {
  static defaultProps = {
    /** If no filter is passed, all kernels are supposed to be shown */
    kernelNameFilter: (name) => true,
  };

  // TODO This should be done with the "loading / loaded / error / data" logic
  //  At the moment simply nothing is shown while the kernelspecs are being loaded
  render() {
    const { kernelNameFilter, kernelspecs } = this.props;
    return (
      <div className={'kernelspecs'}>
        {kernelspecs &&
          Object.keys(kernelspecs)
            .filter((name) => kernelNameFilter(name))
            .map((key) => {
              const entry = kernelspecs[key];
              const name = entry.name;
              const displayName = entry.spec.display_name;
              return (
                <KernelSpec
                  name={name}
                  displayName={displayName}
                  key={name}
                  onChange={(value) => {
                    this.props.input.onFocus(undefined); // this is necessary to mark the field as touched
                    this.props.input.onChange(value);
                  }}
                  selectedKernel={this.props.selectedKernel}
                />
              );
            })}
      </div>
    );
  }
}
