import React, { FC } from 'react';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import { renderParameterDescription } from './NodeEditor';
import _ from 'lodash';
import { MessageDescriptor } from 'react-intl';
import { SelectedStaticParameter } from 'common/dist/types/pipeline';
import { StaticParameterType } from '../../_pipeline-tuning-results-common/types';

type Props = {
  isEditable: boolean;
  staticParameters: StaticParameterType[];
  value: SelectedStaticParameter[];
  onChange: (value: SelectedStaticParameter[]) => void;
  onBlur: () => void;
  onFocus: () => void;
  error: {
    [parameterId: string]: MessageDescriptor;
  };
};

const StaticParameters: FC<Props> = (props) => {
  const {
    isEditable,
    staticParameters,
    value,
    onChange,
    onFocus,
    onBlur,
    error,
  } = props;

  if (!staticParameters) {
    return null;
  } else {
    const paramsMap = _.keyBy(value, 'id');

    return (
      <div className={'PipelineTuningSelection--static'}>
        <span className={'PipelineTuningSelection--params-headline'}>
          Static Parameters
        </span>

        <div className={'PipelineTuningSelection--params-container'}>
          {staticParameters.map((param) => {
            return (
              <div className={'PipelineTuningSelection--params-param'}>
                <span
                  className={'PipelineTuningSelection--params-param-headline'}
                >
                  {param.displayName}
                </span>
                {renderParameterDescription(param.description)}
                <div
                  className={
                    'PipelineTuningSelection--params-param-input-container'
                  }
                >
                  <TextInputLine
                    id={param.id}
                    name={param.id}
                    hasLabel={false}
                    placeholderDefault={param.displayName}
                    disabled={!isEditable}
                    touched={true}
                    error={error?.[param.id]}
                    valid={!error?.[param.id]}
                    value={paramsMap[param.id]?.value as string}
                    onChange={(e) =>
                      onChange(
                        value.map((p) =>
                          p.id === param.id
                            ? { ...paramsMap[p.id], value: e.target.value }
                            : p
                        )
                      )
                    }
                    onFocus={onFocus}
                    onBlur={onBlur}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default StaticParameters;
