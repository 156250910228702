import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import ResourcePermissions from '../../../organisms/permissions/ressource-permissions/ResourcePermissions.container';
import {
  DASHBOARD_COMPONENT_SCOPES,
  RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
} from 'common/dist/constants/keycloak';
import { detailsRoute } from '../general-permissions/GeneralPermissions';
import { usersRoutes } from '../routes';
import { useIntl } from 'react-intl';
import commonMessages from 'common/dist/messages/common';

const descriptionsMap = {
  [RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX]: {
    headline: 'Model Management Permissions',
    groupPermissionsSubTitle:
      'Assign group-wise permissions to the Model Management',
    groupPermissionsDescription:
      'If you are a member of this group you have access to the Model Management',
    userPermissionsSubTitle:
      'Assign user-wise permissions to the Model Management',
  },
  [RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX]: {
    headline: 'App Permissions',
    groupPermissionsSubTitle: 'Assign group-wise permissions to the Apps',
    groupPermissionsDescription:
      'If you are a member of this group you have access to the Apps',
    userPermissionsSubTitle: 'Assign user-wise permissions to the Apps',
  },
  [RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX]: {
    headline: 'Workbench & Collaboration Space Permissions',
    groupPermissionsSubTitle:
      'Assign group-wise permissions to the Workbench and Collaboration Space',
    groupPermissionsDescription:
      'If you are a member of this group you have access to the Workbench and Collaboration Space',
    userPermissionsSubTitle:
      'Assign user-wise permissions to the Workbench and Collaboration Space',
  },
  [RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX]: {
    headline: 'Job Orchestration Permissions',
    groupPermissionsSubTitle:
      'Assign group-wise permissions to the Job Orchestration',
    groupPermissionsDescription:
      'If you are a member of this group you have access to the Job Orchestration',
    userPermissionsSubTitle:
      'Assign user-wise permissions to the Job Orchestration',
  },
  [RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX]: {
    headline: 'Data Management Permissions',
    groupPermissionsSubTitle:
      'Assign group-wise permissions to the Data Management',
    groupPermissionsDescription:
      'If you are a member of this group you have access to the Data Management',
    userPermissionsSubTitle:
      'Assign user-wise permissions to the Data Management',
  },
};

const GeneralDetails: FC = () => {
  const { id } = useParams<{ id: string }>();

  const intl = useIntl();

  return (
    <ResourcePermissions
      baseUrl={detailsRoute(id)}
      showBackButton
      backButtonLabel={intl.formatMessage(commonMessages.backTo, {
        origin: 'General Permissions',
      })}
      backToUrl={`${usersRoutes.basePath}/${usersRoutes.generalPermissions.path}`}
      resourceType={'dashboard-component'}
      resourceName={`dashboard-component:${id}`}
      details={{
        headline: { id: 'no-id', defaultMessage: descriptionsMap[id].headline },
        rows: [],
      }}
      scopes={DASHBOARD_COMPONENT_SCOPES}
      messagesGroupPermissions={{
        subTitle: {
          id: 'no-id',
          defaultMessage: descriptionsMap[id].groupPermissionsSubTitle,
        },
        description: {
          id: 'no-id',
          defaultMessage: descriptionsMap[id].groupPermissionsDescription,
        },
      }}
      messagesUserPermissions={{
        subTitle: {
          id: 'no-id',
          defaultMessage: descriptionsMap[id].userPermissionsSubTitle,
        },
      }}
    />
  );
};

export default GeneralDetails;
