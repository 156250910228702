import React, { Component } from 'react';
import PropTypes from 'prop-types';

const SERVING_TYPES = [
  { label: 'REST', id: 'rest' },
  { label: 'Kafka', id: 'kafka' },
  { label: 'HTTP Server-Sent Events', id: 'http-sse' },
];

export default class FieldServingTypes extends Component {
  render() {
    const {
      input: { value, onChange },
    } = this.props;
    return (
      <div className={'field-parent serving-types-parent'}>
        {SERVING_TYPES.map((servingType, i) => (
          <div className={'serving-type-row'} key={i}>
            <div className={'checkbox-parent'}>
              <label className='checkbox-item'>
                <input
                  className='mark-input'
                  type='checkbox'
                  checked={value && value.includes(servingType.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onChange([...value, servingType.id]);
                    } else {
                      onChange(value.filter((x) => x !== servingType.id));
                    }
                  }}
                />
                <span className='mark' />
              </label>
            </div>
            <div className={'label-parent'}>
              <span>{servingType.label}</span>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
FieldServingTypes.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        id: PropTypes.string,
      })
    ),
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({}),
};
