import { connect } from 'react-redux';
import StepTable from './StepTable';
import { fetchCassandraTables } from '../../../../../../redux/modules/data.cassandra.module';
import { formValueSelector } from 'redux-form';
import { fieldKeyspace, formName } from '../form';

export function mapStateToProps(state, { dataSourceCode }) {
  const selector = formValueSelector(formName);
  const keyspaceName = selector(state, fieldKeyspace)?.name;

  const { loading, loaded, error, data } =
    state.data.cassandra?.[dataSourceCode]?.tables?.[keyspaceName] || {};

  return {
    availableTables: data,
    tablesLoading: loading,
  };
}

export const mapDispatchToProps = {
  fetchCassandraTables,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTable);
