import React, { FC } from 'react';
import { ArchetypeValueType } from '../archetype/Archetype';
import styles from './styles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import InputError from '../../../../atoms/input-error/InputError';
import { MessageDescriptor } from 'react-intl';
import messagesAugur from 'common/dist/messages/augurs';
import PipelineTuningSelection from '../../../pipeline-tuning/pipeline-tuning-selection/PipelineTuningSelection';
import {
  PipelineErrorType,
  PipelineTuningSchemaType,
} from '../../../pipeline-tuning/_pipeline-tuning-results-common/types';

export type Props = {
  /** The selected archetype */
  archetype?: ArchetypeValueType;
  /** List of parameter tuning schemas */
  pipelineTuningSchemas?: PipelineTuningSchemaType[];
};

const PipelineTuning: FC<Props & WrappedFieldProps> = (props) => {
  const { pipelineTuningSchemas, input, meta } = props;
  const error: PipelineErrorType | undefined = meta.error;
  let shownError: MessageDescriptor | undefined;
  if (error?.general) {
    shownError = error.general;
  } else if (error) {
    shownError = messagesAugur.tuningPipelineValidationErrorUnspecified;
  }

  return (
    <div className={styles.pipelineTuning}>
      <InputError error={shownError} touched={meta.touched} />
      <PipelineTuningSelection
        input={input}
        meta={meta}
        pipelineTuningSchemas={pipelineTuningSchemas}
      />
    </div>
  );
};

export default PipelineTuning;
