import { createAction } from 'redux-act';
import _ from 'lodash';

export const changeSource = createAction('change source', (payload) => payload);

export const changeCodeContent = createAction(
  'change code content',
  (payload) => payload
);

export const reducer = {
  [changeSource]: (state, { path, cellId, source }) => {
    const nb = state.notebooks[path] || {};

    const currentSource = (nb.content?.cells || []).find(
      (c) => c.id === cellId
    )?.source;
    const unsavedChanges =
      !_.isEqual(currentSource, source) || nb.unsavedChanges;

    return {
      ...state,
      notebooks: {
        ...state.notebooks,
        [path]: {
          ...nb,
          content: {
            ...nb.content,
            cells: nb.content.cells.map((c) =>
              c.id === cellId
                ? {
                    ...c,
                    source,
                  }
                : { ...c }
            ),
          },
          unsavedChanges,
        },
      },
    };
  },
  [changeCodeContent]: (state, { path, content }) => ({
    ...state,
    notebooks: {
      ...state.notebooks,
      [path]: {
        ...(state.notebooks[path] || {}),
        content,
        unsavedChanges: true,
      },
    },
  }),
};
