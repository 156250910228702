import React, { FC } from 'react';
import styles from './styles.module.scss';
import BreadCrumbs from '../../../../../../atoms/bread-crumbs/BreadCrumbs';
import { scoreDistributionUrl } from '../ScoreDistribution.wrapper';
import Busy from '../../../../../../atoms/busy/Busy';
import CompareHeatmap from './CompareHeatmap';
import TabLineBubbles from '../../../../../../molecules/tab-line-bubbles/TabLineBubbles';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import { scoreDistributionCompareUrl } from '../overview/all-scoring-runs/AllScoringRuns';
import CompareBarChart from './CompareBarChart';
import { FiBarChart2, FiDownload, FiGrid } from 'react-icons/fi';
import Button from '../../../../../../atoms/button/Button';
import { downloadDistribution } from './common';
import commonMessages from 'common/dist/messages/common';
import { useIntl } from 'react-intl';
import { AugurDetailsRouteParams } from '../../types';
import { useReportsByCode } from '../../../../../../../core/api/reports';
import _ from 'lodash';
import { UseQueryResult } from 'react-query';
import { PredictionReportType } from 'common/dist/types/reports';

type Props = {
  /** List of report codes to compare (passed as query parameter the URL) */
  reportCodes: string[];
  /** Name of the Augur */
  augurName?: string;

  /** Code of the active model for this augur */
  activeModelCode?: string;
};

const Compare: FC<Props> = ({ reportCodes, augurName, activeModelCode }) => {
  const location = useLocation();
  const intl = useIntl();
  const { habitatCode, augurCode, moduleType } =
    useParams<AugurDetailsRouteParams>();

  const {
    data: reportsToCompare,
    error,
    isLoading,
    isError,
  } = useReportsByCode(habitatCode, augurCode, reportCodes) as UseQueryResult<
    PredictionReportType[]
  >;

  // FIXME: this should be removed, probably by matching against the full path
  const getActiveCategory = () => {
    const parts = location.pathname.split('/');
    if (parts.length < 10) return null;
    return parts[9]; // TODO No good idea to pick out the active category like this ...
  };

  const renderHeadline = () => {
    const buttons = [
      {
        id: 'heatmap',
        to: {
          pathname: 'heatmap',
          search: location.search,
        },
        intlId: 'no-id',
        intlDefault: 'Heatmap',
        icon: () => <FiGrid size={16} />,
      },
      {
        id: 'barchart',
        to: {
          pathname: 'barchart',
          search: location.search,
        },
        intlId: 'no-id',
        intlDefault: 'Bar Chart',
        icon: () => <FiBarChart2 size={16} />,
      },
    ];

    const RightComponent = () => (
      <Button
        withLink={false}
        buttonColor={'secondary'}
        buttonLabelDefault={'Download'}
        Icon={() => <FiDownload size={16} />}
        disabled={_.isEmpty(reportsToCompare)}
        onClick={() => {
          downloadDistribution(reportsToCompare, augurName);
        }}
      />
    );

    return (
      <div className={styles.scoreDistributionHeader}>
        <BreadCrumbs
          backToProps={[
            {
              linkTo: scoreDistributionUrl(habitatCode, augurCode, moduleType),
              label: intl.formatMessage(commonMessages.backTo, {
                origin: 'Score Distribution',
              }),
            },
          ]}
        />

        <div className={styles.scoreDistributionHeadline}>
          Compare Score Distribution
        </div>

        <div className={styles.scoreDistributionTabs}>
          <TabLineBubbles
            buttons={buttons}
            activeButton={getActiveCategory()}
            RightComponent={RightComponent}
            rightCenterY={true}
            rightAlignRight={true}
          />
        </div>
      </div>
    );
  };

  const renderNoReportCodes = () => {
    return (
      <div className={styles.scoreDistributionNoReportCodes}>
        No Report Codes provided.
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={styles.scoreDistributionCompareLoading}>
        <Busy isBusy positionAbsolute />
      </div>
    );
  };

  const renderError = () => {
    return (
      <div className={styles.scoreDistributionCompareError}>
        {JSON.stringify(error)}
      </div>
    );
  };

  const renderLoaded = () => {
    return (
      <div className={styles.scoreDistributionCompareLoaded}>
        <Switch>
          <Route path={`${scoreDistributionCompareUrl()}/barchart`}>
            <CompareBarChart
              data={reportsToCompare}
              activeModelCode={activeModelCode}
            />
          </Route>

          <Route path={`${scoreDistributionCompareUrl()}/heatmap`}>
            <CompareHeatmap
              data={reportsToCompare}
              activeModelCode={activeModelCode}
            />
          </Route>

          <Redirect
            to={{
              pathname: `${scoreDistributionCompareUrl(
                habitatCode,
                augurCode,
                moduleType
              )}/heatmap`,
              search: location.search,
            }}
          />
        </Switch>
      </div>
    );
  };

  const renderInnerComponent = () => {
    if (!reportCodes || reportCodes.filter((x) => x).length === 0)
      return renderNoReportCodes();
    else if (isLoading) return renderLoading();
    else if (isError) return renderError();
    else return renderLoaded();
  };

  return (
    <div className={styles.scoreDistributionCompare}>
      {renderHeadline()}
      {renderInnerComponent()}
    </div>
  );
};

export default Compare;
