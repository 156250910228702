import React, { FC } from 'react';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import InputError from '../../../../../../../../../components/atoms/input-error/InputError';
import {
  fieldTrainingTable,
  FieldTrainingTableValues,
} from './tableSelection.form';
import DropdownSelectInput from '../../../../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { WithTableSampleProps } from '../../../../../../../../organisms/modelManagement/newAugurWizard/commonComponents/withTableSample/WithTableSample';
import augurMsgs from 'common/dist/messages/augurs';
import { TypedWrappedFieldProps } from '../../../../../../../../../utils';

export type Props = {
  showTablePreview: () => void;
};

type OptionType = { label: string; value: string };

const TrainingTableSelectField: FC<
  Props &
    WithTableSampleProps &
    TypedWrappedFieldProps<FieldTrainingTableValues> &
    WrappedComponentProps
> = ({
  intl,
  meta: { touched, error },
  input: { onChange, value, onFocus, onBlur },
  tables,
  tablesLoading,
  tableSample,
  showTablePreview,
}) => {
  const placeholderPositiveLabel = intl.formatMessage(
    augurMsgs.msgTrainingTablePlaceholderPositiveLabel
  );
  const placeholderNegativeLabel = intl.formatMessage(
    augurMsgs.msgTrainingTablePlaceholderNegativeLabel
  );
  const { data, loading: columnsLoading } = tableSample || {};
  const columns = data?.colSpecs;

  const tableOptions =
    tablesLoading || !tables
      ? []
      : tables.map((table) => ({ label: table.name, value: table.name }));
  const columnOptions =
    columnsLoading || !tables || !columns
      ? []
      : columns
          .sort((a, b) => (a.colName > b.colName ? 1 : -1))
          .map((column) => ({
            label: `${column.colName} - ${column.colType}`,
            value: column.colName,
          }));

  return (
    <div>
      <div className='form--group'>
        <label className='form--label'>
          <FormattedMessage {...augurMsgs.msgTrainingTableTable} />
        </label>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <DropdownSelectInput
              id={`${fieldTrainingTable}.tableName`}
              name={fieldTrainingTable}
              placeholder={augurMsgs.msgTrainingTablePlaceholderTable}
              options={tableOptions}
              className=''
              value={(tableOptions || []).find(
                (o) => o.value === value.tableName
              )}
              onChange={(option: OptionType) =>
                onChange({ ...value, tableName: option.value })
              }
              onFocus={onFocus}
              onBlur={() => onBlur(value)}
              clearable={false}
              isLoading={tablesLoading}
              scrollMenuIntoView={false}
            />
          </div>

          <div
            title={'Show Preview'}
            className={
              'form--icon-button ' + (value.tableName ? 'enabled' : 'disabled')
            }
            style={{
              marginLeft: '10px',
            }}
            onClick={() => {
              if (value.tableName) {
                showTablePreview();
              }
            }}
          >
            <span className={'icon-tables'} style={{ fontSize: '16px' }} />
          </div>
        </div>
        <div className='error-line'>
          <InputError
            touched={touched}
            // @ts-ignore
            error={error}
          />
        </div>
      </div>

      <div className='form--group'>
        <label className='form--label'>
          <FormattedMessage {...augurMsgs.msgTrainingTableIdColumns} />
        </label>
        <DropdownSelectInput
          id={`${fieldTrainingTable}.primaryKeyColumns`}
          name={fieldTrainingTable}
          placeholder={augurMsgs.msgTrainingTablePlaceholderIdColumns}
          options={columnOptions}
          className=''
          value={columnOptions.filter((o) =>
            (value.primaryKeyColumns || []).includes(o.value)
          )}
          onChange={(options: OptionType[]) =>
            onChange({
              ...value,
              primaryKeyColumns: options.map((o) => o.value),
            })
          }
          onFocus={onFocus}
          onBlur={() => onBlur(value)}
          clearable={false}
          isLoading={columnsLoading}
          scrollMenuIntoView={false}
          multi
        />
      </div>

      <div className='form--group'>
        <label className='form--label'>
          <FormattedMessage {...augurMsgs.msgTrainingTableLabelColumn} />
        </label>
        <DropdownSelectInput
          id={`${fieldTrainingTable}.labelColumn`}
          name={fieldTrainingTable}
          placeholder={augurMsgs.msgTrainingTablePlaceholderLabelColumn}
          options={columnOptions}
          className=''
          value={columnOptions.find((o) => o.value === value.labelColumn)}
          onChange={(option: OptionType) =>
            onChange({ ...value, labelColumn: option.value })
          }
          onFocus={onFocus}
          onBlur={() => onBlur(value)}
          clearable={false}
          isLoading={columnsLoading}
          scrollMenuIntoView={false}
        />
      </div>

      <div className={'form--group form--group-flex'}>
        <div className={'form--col-flex'}>
          <label className='form--label'>
            <FormattedMessage {...augurMsgs.msgTrainingTablePositiveLabel} />
          </label>
          <input
            type={'text'}
            placeholder={placeholderPositiveLabel}
            className=''
            value={value.positiveLabelValue}
            onChange={(e) =>
              onChange({ ...value, positiveLabelValue: e.target.value })
            }
            onFocus={onFocus}
            onBlur={() => onBlur(value)}
          />
        </div>
        <div className={'form--col-flex'}>
          <label className='form--label'>
            <FormattedMessage {...augurMsgs.msgTrainingTableNegativeLabel} />
          </label>
          <input
            type={'text'}
            placeholder={placeholderNegativeLabel}
            className=''
            value={value.negativeLabelValue}
            onChange={(e) =>
              onChange({ ...value, negativeLabelValue: e.target.value })
            }
            onFocus={onFocus}
            onBlur={() => onBlur(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default TrainingTableSelectField;
