import * as React from 'react';

const SvgBinaryNumerical = (props) => (
  <svg
    strokeMiterlimit={10}
    style={{
      fillRule: 'nonzero',
      clipRule: 'evenodd',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }}
    viewBox='0 0 400 400'
    xmlSpace='preserve'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g fillRule='evenodd'>
      <path
        d='M50.42 3.332c-3.49 2.129-6.397 6.649-6.401 9.957-.003 2.062-.374 2.432-2.435 2.435-9.07.013-15.148 13.383-9.957 21.897 2.129 3.49 6.648 6.397 9.957 6.402 2.061.003 2.432.373 2.435 2.435.005 3.308 2.91 7.828 6.401 9.957 8.515 5.19 21.885-.888 21.897-9.957.004-2.062.374-2.432 2.436-2.435 3.308-.005 7.828-2.911 9.956-6.402 5.191-8.514-.887-21.884-9.956-21.897-2.062-.003-2.432-.373-2.436-2.435C72.305 4.22 58.935-1.86 50.421 3.332'
        fill='#6aa0eb'
      />
      <path
        d='M377.044 2.582C374.29 4.074 4.26 374.097 2.828 376.789c-6.47 12.163 7.619 26.251 19.781 19.781 2.895-1.54 372.86-371.636 374.245-374.378 5.897-11.675-8.38-25.808-19.81-19.61'
        fill='#9f9f9f'
      />
      <path
        d='M191.946 31.637c-3.49 2.129-6.397 6.649-6.401 9.957-.004 2.062-.374 2.432-2.436 2.435-3.308.005-7.828 2.911-9.956 6.402-5.191 8.514.887 21.884 9.956 21.897 2.062.003 2.432.373 2.436 2.435.012 9.07 13.382 15.148 21.897 9.957 3.49-2.129 6.397-6.648 6.401-9.957.004-2.062.374-2.432 2.436-2.435 3.308-.005 7.828-2.911 9.956-6.402 5.191-8.514-.887-21.884-9.956-21.897-2.062-.003-2.432-.373-2.436-2.435-.012-9.069-13.383-15.148-21.897-9.957M78.726 88.248c-3.49 2.128-6.397 6.648-6.402 9.956-.003 2.062-.373 2.432-2.435 2.436-3.308.004-7.828 2.911-9.957 6.401-5.191 8.514.888 21.885 9.957 21.897 2.062.004 2.432.374 2.435 2.436.005 3.308 2.911 7.828 6.402 9.956 8.514 5.191 21.884-.887 21.897-9.956.003-2.062.373-2.432 2.435-2.436 3.308-.004 7.828-2.911 9.957-6.401 5.191-8.515-.888-21.885-9.957-21.897-2.062-.004-2.432-.374-2.435-2.436-.013-9.069-13.383-15.147-21.897-9.956M163.641 102.4c-3.49 2.129-6.397 6.649-6.401 9.957-.004 2.062-.374 2.432-2.436 2.435-3.308.005-7.828 2.911-9.956 6.402-5.192 8.514.887 21.884 9.956 21.897 2.062.003 2.432.373 2.436 2.435.004 3.308 2.911 7.828 6.401 9.957 8.514 5.191 21.885-.888 21.897-9.957.003-2.062.374-2.432 2.435-2.435 9.069-.013 15.148-13.383 9.957-21.897-2.129-3.491-6.648-6.397-9.957-6.402-2.061-.003-2.432-.373-2.435-2.435-.012-9.069-13.383-15.148-21.897-9.957'
        fill='#6aa0eb'
      />
      <path
        d='M329.91 130.83c-13.755 5.286-20.745 22.521-15.173 37.415 8.612 23.015 44.339 23.056 52.942.06 9.081-24.269-13.592-46.765-37.769-37.475'
        fill='#224e90'
      />
      <path
        d='M22.115 159.011c-3.49 2.128-6.397 6.648-6.401 9.956-.004 2.062-.374 2.432-2.436 2.436-9.068.012-15.147 13.382-9.956 21.897 2.128 3.49 6.648 6.397 9.956 6.401 2.062.003 2.432.374 2.436 2.435.004 3.309 2.91 7.828 6.401 9.957 8.515 5.191 21.885-.888 21.897-9.957.004-2.061.374-2.432 2.436-2.435 3.308-.004 7.828-2.911 9.956-6.401 5.191-8.515-.887-21.885-9.956-21.897-2.062-.004-2.432-.374-2.436-2.436-.012-9.069-13.383-15.147-21.897-9.956M121.183 159.011c-3.49 2.128-6.397 6.648-6.401 9.956-.003 2.062-.374 2.432-2.435 2.436-3.309.004-7.829 2.911-9.957 6.401-5.191 8.514.888 21.885 9.957 21.897 2.061.003 2.432.374 2.435 2.435.004 3.309 2.911 7.828 6.401 9.957 8.515 5.191 21.885-.888 21.897-9.957.004-2.061.374-2.432 2.436-2.435 3.308-.004 7.828-2.911 9.956-6.401 5.191-8.515-.887-21.885-9.956-21.897-2.062-.004-2.432-.374-2.436-2.436-.012-9.069-13.382-15.147-21.897-9.956'
        fill='#6aa0eb'
      />
      <path
        d='M244.995 201.593c-13.755 5.286-20.746 22.521-15.173 37.415 8.612 23.015 44.338 23.056 52.942.06 9.08-24.269-13.592-46.765-37.769-37.475'
        fill='#224e90'
      />
      <path
        d='M64.573 215.621c-3.49 2.129-6.397 6.648-6.401 9.957-.004 2.061-.374 2.432-2.436 2.435-3.308.005-7.828 2.911-9.956 6.401-5.192 8.515.887 21.885 9.956 21.897 2.062.004 2.432.374 2.436 2.436.004 3.308 2.91 7.828 6.401 9.956 8.514 5.192 21.885-.887 21.897-9.956.003-2.062.374-2.432 2.435-2.436 9.07-.012 15.148-13.382 9.957-21.897-2.129-3.49-6.648-6.396-9.957-6.401-2.061-.003-2.432-.374-2.435-2.435-.01-6.387-6.443-11.714-14.15-11.714-3.297 0-5.793.566-7.747 1.757'
        fill='#6aa0eb'
      />
      <path
        d='M315.758 215.746c-13.756 5.286-20.746 22.52-15.173 37.414 8.611 23.016 44.338 23.057 52.942.06 9.08-24.268-13.592-46.764-37.769-37.474M230.842 272.356c-13.755 5.286-20.745 22.521-15.173 37.415 8.612 23.015 44.339 23.056 52.942.06 9.081-24.269-13.592-46.765-37.769-37.475M145.927 328.966c-13.756 5.286-20.746 22.521-15.173 37.415 8.612 23.016 44.338 23.056 52.942.06 9.08-24.269-13.592-46.765-37.769-37.475M287.452 343.119c-13.755 5.286-20.745 22.52-15.172 37.415 8.611 23.015 44.338 23.056 52.942.06 9.08-24.269-13.592-46.765-37.77-37.475'
        fill='#224e90'
      />
    </g>
  </svg>
);

export default SvgBinaryNumerical;
