import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { fieldNameDefaultValue } from '../_interface/input-element.form';
import { inputModalForm } from '../../../settings-dialog/inpmodalDialog.form';
import CheckBoxCol from '../../../settings-dialog/dialog-settings-cols/CheckBoxCol';

class CheckBoxSettings extends Component {
  render() {
    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldNameDefaultValue}
            label={'Default Value (optional)'}
            component={CheckBoxCol}
            placeholder={'Enter Default Value'}
          />
        </div>
      </Fragment>
    );
  }
}

export default reduxForm(inputModalForm)(CheckBoxSettings);
