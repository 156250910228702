import React, { ComponentType } from 'react';
import Settings from './Settings';

import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import AugurSettingsCategory from '../../_interfaces/augur-details/settings/AugurSettingsCategory';
import { MessageDescriptor } from 'react-intl';

export default class SettingsWrapper extends ComponentAugurDetailsTab {
  /**
   * @param settingsCategories Settings categories that are supposed to be displayed in the settings screen
   */
  constructor(settingsCategories: AugurSettingsCategory[]) {
    super();
    this.component = (props) => (
      <Settings settingsCategories={settingsCategories} {...props} />
    );
  }

  component: ComponentType; // Filled in the constructor with the enhanced version of the Settings Component
  icon: ComponentType = () => <span className={'icon-settings'} />;
  id = 'settings';
  to = (habitatCode: string, augurCode: string, shortAugurType: string) =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/settings`;
  subRoutes: object[] | undefined = undefined;
  title: MessageDescriptor = {
    id: 'details.sideNav.augur_settings',
    defaultMessage: 'Augur Settings',
  };
}
