import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import * as d3 from 'd3';
import { Transition } from 'react-transition-group';

import NodeShape from './NodeShape';
import { getOrigin } from './treeChart/tree';

class Node extends React.Component {
  static propTypes = {
    node: PropTypes.object.isRequired,
    onNodeEnter: PropTypes.func.isRequired,
    onNodeLeave: PropTypes.func.isRequired,
    onNodeClick: PropTypes.func.isRequired,
    onClickCallback: PropTypes.func,
    positiveClassName: PropTypes.string.isRequired,
    negativeClassName: PropTypes.string.isRequired,
    leafClassName: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    animationDuration: PropTypes.shape({
      mount: PropTypes.shape({
        delay: PropTypes.number.isRequired,
        duration: PropTypes.number.isRequired,
      }),
      update: PropTypes.shape({
        delay: PropTypes.number.isRequired,
        duration: PropTypes.number.isRequired,
      }),
      exit: PropTypes.shape({
        delay: PropTypes.number.isRequired,
        duration: PropTypes.number.isRequired,
      }),
    }).isRequired,
  };

  static defaultProps = {
    onClickCallback: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      nodePosition: {
        ...getOrigin(this.props.node),
      },
    };
    this.gRef = null;
    this.setGRef = this.setGRef.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleComponentExit = this.handleComponentExit.bind(this);
  }

  componentDidMount() {
    const { x, y } = this.props.node;
    const { y: originY } = getOrigin(this.props.node);
    this.animateNode(
      [0.5, 1],
      [
        { x, y: originY },
        { x, y },
      ],
      this.props.animationDuration.mount.delay,
      this.props.animationDuration.mount.duration / 2
    );
  }

  componentDidUpdate({ node: { x, y } }) {
    if (this.props.node.x !== x || this.props.node.y !== y) {
      this.animateNode(
        [1],
        [{ x: this.props.node.x, y: this.props.node.y }],
        this.props.animationDuration.update.delay,
        this.props.animationDuration.update.duration
      );
    }
  }

  setGRef(ref) {
    this.gRef = ref;
  }

  handleMouseEnter() {
    const { onNodeEnter, node } = this.props;
    onNodeEnter(node);
  }

  handleMouseLeave() {
    const { onNodeLeave, node } = this.props;
    onNodeLeave(node);
  }

  handleClick() {
    const { onNodeClick, onClickCallback, node } = this.props;
    onNodeClick(node);
    onClickCallback(node);
  }

  handleComponentExit() {
    const { x } = this.props.node;
    const { x: originX, y: originY } = getOrigin(this.props.node);
    this.animateNode(
      [0.5, 0],
      [
        { x, y: originY },
        { x: originX, y: originY },
      ],
      this.props.animationDuration.exit.delay,
      this.props.animationDuration.exit.duration / 2
    );
  }

  animateNode(opacities, positions, delay, duration) {
    let transition = d3.select(this.gRef).transition().delay(delay);
    positions.forEach((position, index) => {
      transition = transition
        .ease(d3.easeLinear)
        .duration(duration)
        .style('opacity', opacities[index])
        .attr('transform', `translate(${position.x},${position.y})`);
      if (index + 1 === positions.length) {
        transition = transition.on('end', () =>
          this.setState({ nodePosition: { ...position } })
        );
      } else {
        transition = transition.transition();
      }
    });
  }

  render() {
    const {
      node,
      type,
      positiveClassName,
      negativeClassName,
      leafClassName,
      animationDuration,
      isActive,
      isSelected,
      ...restProps
    } = this.props;
    const { x, y } = this.state.nodePosition;

    const isLeaf = type === 'leaf';
    const nodeClassName = classNames('tree-chart_node', {
      [positiveClassName]: !!+node.data.score,
      [negativeClassName]: !+node.data.score,
      [leafClassName]: isLeaf,
    });

    return (
      <Transition
        timeout={{
          exit: animationDuration.exit.delay + animationDuration.exit.duration,
        }}
        onExit={this.handleComponentExit}
        {...restProps}
      >
        <g
          ref={this.setGRef}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.handleClick}
          transform={`translate(${x},${y})`}
          style={{ opacity: 0 }}
        >
          <NodeShape
            className={nodeClassName}
            circle={isLeaf ? { r: 7, cx: 0, cy: 0 } : { r: 8, cx: 0, cy: 0 }}
            isLeaf={isLeaf}
            isActive={isActive}
            isSelected={isSelected}
          />
        </g>
      </Transition>
    );
  }
}

export default Node;
