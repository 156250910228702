import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import GraphWait from '../../../../../../components/details/GraphWait';
import BinaryTreeShadowModel from '../../../../../organisms/binary-tree-shadow-model/BinaryTreeShadowModel';
import Busy from '../../../../../../components/atoms/busy/Busy';
import qs from 'qs';
import { JobDescriptionBanner } from '../job-banner/JobDescriptionBanner';
import { AugurDetailsQueryParams, AugurDetailsRouteParams } from '../types';
import augurDetailsMsgs from 'common/dist/messages/augurs.details';
import { useModel } from '../../../../../../core/api/mlModels';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { NbcModel } from 'common/dist/types/mlModel';
import { UseQueryResult } from 'react-query';
import { ToBeRefined } from 'common/dist/types/todo_type';

const ModelViewer: FC = () => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const location = useLocation();
  const { modelCode } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as AugurDetailsQueryParams;

  const { data, error, isLoading, isError, isSuccess } = useModel(
    habitatCode,
    augurCode,
    modelCode
  ) as UseQueryResult<NbcModel, ToBeRefined>;

  return (
    <JobDescriptionBanner>
      <Busy isBusy={isLoading}>
        <div className='h100p'>
          {isError ? (
            <FormattedMessage id={'id.TODO'} defaultMessage={error.message} />
          ) : (
            ''
          )}

          {isSuccess &&
            _.isEmpty(data?.details?.tree || data?.details?.modelViewer) && (
              <GraphWait>
                <FormattedMessage
                  {...augurDetailsMsgs.msgModelViewerNotAvailable}
                />
              </GraphWait>
            )}
          {isSuccess &&
            !_.isEmpty(data?.details?.tree || data?.details?.modelViewer) && (
              <BinaryTreeShadowModel
                data={data.details.tree.data || data.details.modelViewer}
              />
            )}
        </div>
      </Busy>
    </JobDescriptionBanner>
  );
};

export default ModelViewer;
