import { ToBeRefined } from 'common/dist/types/todo_type';
import { Error as errorName, Value as valueName } from './StepName';
import { Error as errorDSType } from './step-ds-options/DataSourceButtons';
import {
  CassandraSettings,
  DataSourceSettings,
  DSRolesType,
  DSTypeType,
  S3Settings,
} from 'common/dist/types/dataManagement/dataSource';
import { Props } from './AddUpdateDataSource';

export const formName = 'add-update-data-source';

export const fieldName = 'name';
export const fieldDSType = 'ds_type';
export const fieldSettings = 'settings';
export const fieldRole = 'role';

export interface FormValues {
  [fieldName]?: valueName;
  [fieldDSType]?: DSTypeType;
  [fieldSettings]?: DataSourceSettings;
  [fieldRole]?: DSRolesType;
}

interface FormErrors {
  [fieldName]?: errorName;
  [fieldDSType]?: errorDSType;
  [fieldSettings]?: ToBeRefined;
}

export function validate(values: FormValues, props: Props) {
  let errors: FormErrors = {};

  // --- Validate Data Source name
  const dataSourceName = values[fieldName];
  if (!dataSourceName) {
    errors = { ...errors, [fieldName]: 'Please enter a name.' };
  }

  // --- Validate Data Source name
  const dataSourceType = values[fieldDSType];
  if (!dataSourceType) {
    errors = { ...errors, [fieldDSType]: 'Please select a data source.' };
  }

  // --- Validate Settings
  // (Different for data source types and whether they are added or updated)
  const dataSourceSettings = values[fieldSettings];
  const dsType = values[fieldDSType];
  if (dataSourceSettings) {
    if (dsType === 'cassandra') {
      const cassandraSettings = dataSourceSettings as CassandraSettings;
      let fieldsToCheck = ['host', 'port', 'datacenter'];
      if (!props.update)
        fieldsToCheck = fieldsToCheck.concat(['user', 'password']);
      // Check if fields are not undefined
      if (
        !fieldsToCheck.reduce((acc, val) => acc && cassandraSettings[val], true)
      ) {
        errors = { ...errors, [fieldSettings]: 'Please fill out all fields.' };
      } else if (!Number.isFinite(Number(cassandraSettings.port))) {
        errors = {
          ...errors,
          [fieldSettings]: 'Please enter a valid number for the port.',
        };
      }
    } else if (dsType === 's3') {
      const s3Settings = dataSourceSettings as S3Settings;
      let fieldsToCheck = ['host', 'port'];
      if (!props.update)
        fieldsToCheck = fieldsToCheck.concat(['accessKey', 'secretKey']);
      // Check if fields are not undefined
      if (!fieldsToCheck.reduce((acc, val) => acc && s3Settings[val], true)) {
        errors = { ...errors, [fieldSettings]: 'Please fill out all fields.' };
      } else if (!Number.isFinite(Number(s3Settings.port))) {
        errors = {
          ...errors,
          [fieldSettings]: 'Please enter a valid number for the port.',
        };
      }
    }
  } else {
    errors = { ...errors, [fieldSettings]: 'Please fill out all fields.' };
  }

  return errors;
}

export const dsForm = {
  form: formName,
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  touchOnChange: true,
};
