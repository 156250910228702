import { FC } from 'react';
import { ThemeColor, ThemeColorModifier, useThemeColor } from '../../../utils';
import Switch, { ReactSwitchProps } from 'react-switch';

export type Props = {
  themeColor: ThemeColor;
  modifier?: ThemeColorModifier;
};

const ThemedSwitch: FC<Props & ReactSwitchProps> = ({
  children,
  themeColor,
  modifier,
  ...props
}) => {
  const color = useThemeColor(themeColor, modifier);

  return <Switch onColor={color || undefined} {...props} />;
};

export default ThemedSwitch;
