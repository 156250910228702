import { connect } from 'react-redux';
import { RootState } from '../../../../store/store';
import OverlayCodeCapsuleDetails from './OverlayCodeCapsuleDetails';
import { triggerRunCodeCapsuleJob } from '../../../../redux/modules/triggerJobs.module';
import { CodeCapsulesPerCode } from '../../../../store/codeCapsules/state.types';
import { withRouter } from 'react-router-dom';

type OwnProps = {
  match: {
    params: {
      codeCapsuleCode;
    };
  };
};

export function mapStateToProps(state: RootState, { match }: OwnProps) {
  const codeCapsuleCode = match.params.codeCapsuleCode;
  const perCode =
    state.codeCapsules.perCode[codeCapsuleCode] || ({} as CodeCapsulesPerCode);
  const { loading, loaded, data } = perCode;
  return {
    loading,
    loaded,
    data,
  };
}

export const mapDispatchToProps = {
  triggerRunCodeCapsuleJob,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OverlayCodeCapsuleDetails)
);
