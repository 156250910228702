import { connect } from 'react-redux';
import Keyspaces from './Keyspaces';
import {
  fetchCassandraKeyspaces,
  fetchCassandraPermissions,
  setActiveUploadWizard,
} from '../../../../redux/modules/data.cassandra.module';
import { fetchSummary } from '../../../../redux/modules/dashboard.summary.module';
import { CASSANDRA_KEYSPACE_SCOPE_IDS } from 'common/dist/constants/keycloak';

export function mapStateToProps(state, { dataSourceCode }) {
  const { loading, loaded, error, data } =
    (state.data.cassandra[dataSourceCode] || {}).keyspaces || {};
  const permissions = state.data.cassandra[dataSourceCode]?.permissions || {};
  const allowedToUpload = new Set(
    Object.values(permissions.data || {}).flat()
  ).has(CASSANDRA_KEYSPACE_SCOPE_IDS.MODIFY);
  return {
    loading,
    loaded,
    error,
    data,
    summary: state.summary,
    permissions,
    allowedToUpload,
  };
}

export const mapDispatchToProps = {
  fetchCassandraKeyspaces,
  fetchCassandraPermissions,
  setActiveUploadWizard,
  fetchSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(Keyspaces);
