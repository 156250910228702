import { connect } from 'react-redux';
import MultipleSelectSettings from './MultipleSelectSettings';
import { formValueSelector } from 'redux-form';
import {
  formName,
  fieldNameSelectableValues,
} from '../_interface/input-element.form';

export function mapStateToProps(state) {
  const selectableValues = formValueSelector(formName)(
    state,
    fieldNameSelectableValues
  );

  return {
    selectableValues,
  };
}

export default connect(mapStateToProps)(MultipleSelectSettings);
