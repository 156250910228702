import React, { ComponentType } from 'react';
import AccuracyDetails from './AccuracyDetails';

import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import { MessageDescriptor } from 'react-intl';

export default class AccuracyDetailsWrapper extends ComponentAugurDetailsTab {
  component = AccuracyDetails;
  icon: ComponentType = () => <span className={'icon-accuracy-details'} />;
  id = 'accuracy-details/:tabId';
  to = (habitatCode: string, augurCode: string, shortAugurType: string) =>
    'accuracy-details/:tabId'; // This component isn't shown itself
  title: MessageDescriptor = {
    id: 'details.sideNav.accuracyDetails',
    defaultMessage: 'Accuracy Details',
  };
  showInMenu = false;
  subRoutes: object[] | undefined = [];
}
