import { connect } from 'react-redux';
import CommitModal from './CommitModal';
import { DeprecatedRootState } from '../../../../../../store/state.type';
import {
  toggleCommitModal,
  sendResolveConflictCommit,
} from '../../../../../../redux/workbench/modules/merger.module';
import { formValueSelector, isPristine, isValid } from 'redux-form';
import { fieldCommitMessage, gitCommitForm } from './gitCommit.form';

export function mapStateToProps(state: DeprecatedRootState) {
  const { isShown } = (state.workbench.mergerAPI || {}).commitModal || {};

  return {
    isShown,
    valid: isValid(gitCommitForm.form)(state),
    pristine: isPristine(gitCommitForm.form)(state),
    commitMessage: formValueSelector(gitCommitForm.form)(
      state,
      fieldCommitMessage
    ),
  };
}

export const mapDispatchToProps = {
  toggleCommitModal,
  sendResolveConflictCommit,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommitModal);
