export const formName = 'user-details-attributes';

export const form = {
  form: formName,
};

export const WB_MAX_MEM = 'workbench_max_memory';
export const WB_MAX_CPU = 'workbench_max_cpu';
export const WB_MAX_GPU = 'workbench_max_gpu';
export const WB_DEF_MEM_REQ = 'workbench_default_memory_request';
export const WB_DEF_CPU_REQ = 'workbench_default_cpu_request';
export const WB_DEF_MEM_LIM = 'workbench_default_memory_limit';
export const WB_DEF_CPU_LIM = 'workbench_default_cpu_limit';

// Memory allows the following suffixes: K -> Kilobytes M -> Megabytes G -> Gigabytes T -> Terabytes
// CPU are floats but passed as string
// TODO somehow set these as initialValues when they are added. Optionally also parameterize them from helm
export const editableAttributes = {
  [WB_MAX_MEM]: '4Gi',
  [WB_MAX_CPU]: '2',
  [WB_MAX_GPU]: '0',
  [WB_DEF_MEM_REQ]: '2Gi',
  [WB_DEF_CPU_REQ]: '1',
  [WB_DEF_MEM_LIM]: '4Gi',
  [WB_DEF_CPU_LIM]: '2',
};

/** Flatten the attribute data as returned from the api/keycloak (each attribute contains a list) */
export function dataToForm(data: { [attr: string]: string[] }): {
  [attr: string]: string;
} {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, value[0]])
  );
}
