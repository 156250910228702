import { createAction } from 'redux-act';

export const sessionRestored = createAction('session restored', (user) => user);

export const reducer = {
  [sessionRestored]: (state, payload) => ({
    ...state,
    currentUser: payload,
  }),
};
