import { connect, ConnectedProps } from 'react-redux';
import { clearForceFocus } from '../../../../../../../../redux/workbench/modules/notebook.focus.module';
import RawCell, { Props } from './RawCell';
import { RootState } from '../../../../../../../../store/store';

export function mapStateToProps(state: RootState, { path }: Props) {
  return {
    /** Force focus "flag": ID of the cell that needs to get focused after mounting, because it was newly created or the type changed */
    forceFocus: state.workbench.notebooks[path]?.forceFocus,
  };
}

export const mapDispatchToProps: { clearForceFocus: (path) => void } = {
  clearForceFocus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(RawCell);
