import { connect } from 'react-redux';

import DetailChart from './DetailChart';
import * as tooltipModule from '../../../../../../../../redux/modules/accuracy.module';

export function mapStateToProps(state, { width, height, data }) {
  return {
    width,
    height,
    data,
  };
}

export const mapDispatchToProps = {
  showTooltip: tooltipModule.showTooltip,
  hideTooltip: tooltipModule.hideTooltip,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailChart);
