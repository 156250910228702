import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import { withRouter } from 'react-router-dom';

class EmptyRecycleBinWizard extends Component {
  constructor(props) {
    super(props);
    this.state = { clicked: false };
  }

  render() {
    const { emptyRecycleBin } = this.props;
    return (
      <div
        className={'dialog-container'}
        style={{
          paddingTop: '40px', // This wizard requires additional space in the top to look good
        }}
      >
        <div className={'dialog-inner-container'}>
          <p className={'dialog-headline'}>Empty Recycle Bin</p>
          <div className={'step-container'}>
            <div className={'description-container'}>
              <div className={'bubble bubble-delete'}>
                <FiX className={'icon-x'} size={'20px'} />
              </div>
              <div className={'description'}>
                <div className={'title'}>
                  <p>Are you sure you want to empty the Recycle Bin?</p>
                </div>
              </div>
            </div>
          </div>
          <div className={'dialog-button-container'}>
            <Link to={workbenchRoutes.basePath}>
              <button className={'dialog-button dialog-button-neutral'}>
                Cancel
              </button>
            </Link>
            <Link
              to={workbenchRoutes.basePath}
              onClick={() => {
                if (!this.state.clicked) {
                  this.setState({ clicked: true });
                  emptyRecycleBin();
                }
              }}
              style={{ textDecoration: 'none' }}
            >
              <button
                className={
                  'button dialog-button dialog-button-delete ' +
                  (this.state.clicked ? ' dialog-button-disabled' : '')
                }
              >
                <span>Empty Recycle Bin</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

EmptyRecycleBinWizard.propTypes = {
  emptyRecycleBin: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(EmptyRecycleBinWizard);
