import { ComponentType } from 'react';
import { ComponentDatapoolDetailsTab } from '../../_interfaces/datapool-details/DatapoolDetailsTab';
import Machine from './Machine.container';
import MachineIcon from './MachineIcon';
import { DatapoolType } from 'common/dist/types/datapool';

export default class MachineWrapper extends ComponentDatapoolDetailsTab {
  type: 'component' = 'component';
  id = 'machine';
  title = {
    id: 'datapoolDetails.sideNav.machine',
    defaultMessage: 'Machine',
  };
  to = (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${shortDatapoolType}/machine`;

  icon: ComponentType = MachineIcon;
  component: ComponentType = Machine;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType): boolean =>
    datapool && datapool.meta && !!datapool.meta.machine;
}
