import { connect } from 'react-redux';
import ConfigureArchetypeModal from './ConfigureArchetypeModal';
import {
  hideConfigureArchetypeModal,
  updateArchetypeConfiguration,
} from '../../../../../redux/workbench/modules/archetype.module';
import { reset, getFormValues } from 'redux-form';
import { formName } from './form';

export function mapStateToProps(state) {
  const path = (state.workbench.archetype || {}).path;
  const notebook = (state.workbench.notebooks || {})[path] || {};
  return {
    isShown: (state.workbench.archetype || {}).isConfigureModalShown,
    path,
    formValues: getFormValues(formName)(state),
    initialValues: (((notebook || {}).content || {}).metadata || {})
      .as_archetype,
  };
}

export const mapDispatchToProps = {
  hideConfigureArchetypeModal,
  updateArchetypeConfiguration,
  resetForm: () => reset(formName),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureArchetypeModal);
