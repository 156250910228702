import React, { FC } from 'react';
import TargetList from './target-list/TargetList.container';
import './styles.scss';
import { ModelSettingsProps } from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

const PredictionTargetsCategory: FC<
  ModelSettingsProps<AugurSettingsDataNbc>
> = (props) => {
  return (
    <div className={'PredictionTargetsCategory'}>
      <TargetList {...props} />
    </div>
  );
};

export default PredictionTargetsCategory;
