import React, { FC, useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { fetchDataSources } from '../../../../redux/modules/data.dataSources.module';
import TabLineBubbles from '../../../molecules/tab-line-bubbles/TabLineBubbles';
import SingleS3Permissions from './SingleS3Permissions';
import { RouteComponentProps } from 'react-router';

export const s3DetailsRoute = (s3Code, bucket) =>
  `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.s3Permissions.path}/${s3Code}/${bucket}`;

type S3Type = {
  name: string;
  code: string;
};

type Props = {
  history: {
    location: {
      pathname: string;
    };
  };
};

const S3Permissions: FC<Props & RouteComponentProps> = (props) => {
  const {
    history: {
      location: { pathname, search },
    },
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataSources());
  }, [dispatch]);

  const s3Systems = useSelector<RootState, S3Type[]>((state) =>
    state.data?.dataSources?.data.filter((x) => x.ds_type === 's3')
  );

  const tabLineButtons = (s3Systems || []).map((s3) => ({
    id: s3.code,
    to: `/app/admin/users/s3Permissions/${s3.code}`,
    onClick: () => {},
    intlId: 'no-id',
    intlDefault: s3.name,
  }));

  const activeButton = pathname.split('/')[5];

  return (
    <div>
      <TabLineBubbles buttons={tabLineButtons} activeButton={activeButton} />

      <Switch>
        {s3Systems.map((s3) => (
          <Route path={`/app/admin/users/s3Permissions/${s3.code}`}>
            <div
              style={{
                marginTop: '15px',
              }}
            >
              <SingleS3Permissions code={s3.code} />
            </div>
          </Route>
        ))}

        {s3Systems.length > 0 && (
          <Redirect
            to={`/app/admin/users/s3Permissions/${s3Systems[0].code}${search}`}
            path={'/app/admin/users/s3Permissions'}
          />
        )}
      </Switch>
    </div>
  );
};

export default withRouter(S3Permissions);
