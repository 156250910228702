import React, { FC, useEffect, useState } from 'react';
import Table, { RenderColumn } from '../../../../molecules/table/Table';
import commonStyles from '../../newDatapoolWizard/optChannels/optCommonStyles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import {
  Propensity,
  TargetFunctionSummandGroup,
} from 'common/dist/types/module.optimization';
import { SPEAKING_SUM_OF, SPEAKING_SUM_OVER } from './common';
import classNames from 'classnames';
import styles from '../optConstraints/styles.module.scss';
import AddConstraint from '../optConstraints/AddConstraint';
import AddSummandGroup from './AddSummandGroup';
import { SPEAKING_OPERATOR } from '../optConstraints/common';
import { PropensitiesValueType } from '../../newDatapoolWizard/optPropensities/OptPropensities';

type Props = {
  editMode?: boolean;
  addedNewRow?: boolean;
  rowIndex: number;
};

const SummandGroupsTable: FC<Props & WrappedFieldProps> = (props) => {
  const { input, meta, editMode, addedNewRow, rowIndex } = props;
  const summandGroups: TargetFunctionSummandGroup[] =
    input.value?.summandGroups || [];
  const initialVisible = {
    index: addedNewRow ? summandGroups?.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  };
  const [visible, setShow] = useState(initialVisible);
  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : summandGroups?.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex]);

  const renderColumns: RenderColumn<
    TargetFunctionSummandGroup,
    keyof TargetFunctionSummandGroup
  >[] = [
    {
      key: 'name',
      renderHeader: () => <span>Name</span>,
      renderCell: (name: string, summandGroup) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[summandGroup.id]?.name,
          })}
        >
          {name}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,
      renderCell: (description?: string, summandGroup?) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[summandGroup?.id]?.description,
          })}
        >
          {description}
        </span>
      ),
    },
    {
      key: 'sumOver',
      renderHeader: () => <span>Sum Over</span>,
      renderCell: (sumOver: string, summandGroup) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[summandGroup.id]?.sumOf,
          })}
        >
          {SPEAKING_SUM_OVER[sumOver]}
        </span>
      ),
    },
    {
      key: 'sumOf',
      renderHeader: () => <span>Sum Of</span>,
      renderCell: (sumOf: string, summandGroup) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[summandGroup.id]?.sumOf,
          })}
        >
          {SPEAKING_SUM_OF[sumOf]}
        </span>
      ),
    },
    {
      key: 'factor',
      renderHeader: () => <span>Factor</span>,
      renderCell: (factor: string, summandGroup) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[summandGroup.id]?.factor,
          })}
        >
          {factor}
        </span>
      ),
    },
  ];
  const onRemove = (removedRow: TargetFunctionSummandGroup) => {
    const updatedSummandGroups = (summandGroups || []).filter(
      (con) => con.id !== removedRow.id
    );
    input.onChange({
      ...(input.value || {}),
      summandGroups: updatedSummandGroups,
    });
  };
  const renderClickedRow = (
    element: TargetFunctionSummandGroup,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (editMode && visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddSummandGroup
                rowIndex={rowIndex}
                constraintId={element.id}
                input={input}
                meta={meta}
                removableRows={visible.shown}
                onRemoveRow={onRemove}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };
  return (
    <Table
      data={summandGroups}
      renderColumns={renderColumns}
      showHeader
      removableRows={editMode}
      verticalAlignMiddle
      onRemoveRow={onRemove}
      addlRowClassname={(
        rowIndex: number,
        summandGroup: TargetFunctionSummandGroup
      ) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!meta.error?.[summandGroup.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
    />
  );
};

export default SummandGroupsTable;
