import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ActivateRealtimeToggle from '../ActivateRealtimeToggle.container';

/**
 * Shows the HttpSse part in the Realtime screen of the Augur Details
 */
export default class HttpSseGroup extends Component {
  render() {
    return (
      <div className={'activate-container rest-activate-container'}>
        <div className={'activate-top'}>
          <div className={'activate-title'}>
            <FormattedMessage
              id={'dashboard.augur_settings.http_sse_realtime_api'}
              defaultMessage={'HTTP Server-Sent Events Realtime Scoring'}
            />
          </div>
          <div className={'activate-description'}>
            <FormattedMessage
              id={'dashboard.augur_settings.activate_http_sse_realtime_scoring'}
              defaultMessage={
                'Activate your model for scoring via HTTP Server-Send Events'
              }
            />
          </div>
        </div>
        <div className={'activate-bottom'}>
          <ActivateRealtimeToggle servingType={'http-sse'} />
        </div>
      </div>
    );
  }
}
