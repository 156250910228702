import React, { FC } from 'react';
import styles from './styles.module.scss';
import { IntlShape } from 'react-intl';
import { TooltipComponent, TooltipProps, HeatMapDatum } from '@nivo/heatmap';



type Props = {
  getInterval: (bucketIndex: number) => string;
  intl: IntlShape;
} & TooltipProps<HeatMapDatum>;

const HeatmapTooltip: FC<Props> = ({ getInterval, intl, cell }) => {
  const { x } = cell.data;
  const timestamp = cell.id.substring(0, 24); //cut off the weird non-ISO ending
  const value = cell.value || 0; //cell.value == cell.data.y 

  return (
    <div className={styles.tooltip}>
      <div className={styles.tooltipLine}>
        <span className={styles.tooltipKey}>Interval:</span>
        <span className={styles.tooltipValue}>{getInterval(Number(x))}</span>
      </div>
      <div className={styles.tooltipLine}>
        <span className={styles.tooltipKey}>Amount:</span>
        <span className={styles.tooltipValue}>{value}</span>
      </div>
      <div className={styles.tooltipLine}>
        <span className={styles.tooltipKey}>Evaluation Date:</span>
        <span className={styles.tooltipValue}>
          {intl.formatDate(timestamp || '')}&nbsp;
          {intl.formatTime(timestamp || '')}
        </span>
      </div>
    </div>
  );
};

export const tooltip: (
  intl: IntlShape,
  getInterval: (bucketIndex: number) => string
) => TooltipComponent<HeatMapDatum> = (intl, getInterval) => {
  return (props) => <HeatmapTooltip {...props} intl={intl} getInterval={getInterval} />;
};
