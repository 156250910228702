import { connect } from 'react-redux';
import GitFileStatus from './GitFileStatus';
import { gitFileStatus } from '../../../../../../redux/workbench/modules/container-interactions.module';
import { showGitCommitModal } from '../../../../../../redux/workbench/modules/repository.module';

export function mapStateToProps(state) {
  const { loading, loaded, data, error } =
    state.workbench.showRepositoryInfo.gitFileStatus;

  return {
    loading,
    loaded,
    data,
    error,
  };
}

export const mapDispatchToProps = {
  gitFileStatus,
  showGitCommitModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GitFileStatus);
