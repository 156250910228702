import { connect } from 'react-redux';
import Workbench from './Workbench';
import { checkWhetherNotebookIsRunning } from '../../../redux/workbench/modules/connection.module';
import { notebookUser } from '../../../redux/workbench/selectors/notebookUser.selector';
import { RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX } from 'common/dist/constants/keycloak';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';

export function mapStateToProps(state) {
  const { loading, loaded, isRunning, isStopping, error, reloginRequired } =
    state.workbench.notebookRunning || {};
  const permissions = state.dashboard?.permissions?.data || {};

  const missingPermissionsError =
    state.dashboard?.permissions?.loaded &&
    !hasAccessPermission(
      permissions,
      RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX
    );

  return {
    // serverStarted: state.workbench.serverStarted,
    loading,
    loaded,
    isRunning,
    isStopping,
    reloginRequired,
    error,
    missingPermissionsError,
    jupyterUser: state.currentUser.id,
    notebookUser: notebookUser(state),
  };
}

export const mapDispatchToProps = {
  checkWhetherNotebookIsRunning,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workbench);
