import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as accountsApi from '../../core/api/accounts';
import * as adminApi from '../../core/api/admin';
import * as localeModule from './locale.module';
import { sendNotification } from './notifications.module';
import {
  error as errorType,
  event as eventType,
} from '../../core/notifications';
import { AdminChangePasswordForm } from '../../components/admin/users/user-details/change-password/ChangePassword';
import { stopSubmit } from 'redux-form';
import { accountPasswordForm } from '../../core/forms/account.form';

export const saveChanges = createAction('save account changes');

export const saveLanguage = createAction('save language', (locale) => ({
  locale,
}));

export const changePassword = createAction('change password', (password) => ({
  password,
}));

export const changePasswordAsAdmin = createAction(
  'change password as admin',
  (userId, password, isTemporary) => ({ userId, password, isTemporary })
);

export const changePasswordSuccess = createAction('change password success');

export const changePasswordFail = createAction(
  'change password fail',
  (error) => error
);

export const changeLanguageSuccess = createAction('change language success');

export const changeLanguageFail = createAction(
  'change language fail',
  (error) => error
);

export function* changePasswordSaga({ payload: { password } }) {
  const { response, error } = yield call(accountsApi.changePassword, password);

  if (response) {
    yield put(
      sendNotification(
        'account.password.change_password.title',
        'account.password.change_password.success',
        eventType
      )
    );
    yield put(changePasswordSuccess());
  } else {
    yield put(
      sendNotification(
        'account.password.new_password',
        error.formattedMessage.id,
        errorType,
        error.formattedMessage.values
      )
    );
    yield put(
      stopSubmit(accountPasswordForm, {
        _error: error.details.error_description,
      })
    );
  }
}

export function* changePasswordAsAdminSaga({
  payload: { userId, password, isTemporary },
}) {
  const { response, error } = yield call(
    adminApi.changePassword,
    userId,
    password,
    isTemporary
  );

  if (response) {
    yield put(
      sendNotification(
        'account.password.change_password.title',
        'account.password.change_password.success',
        eventType
      )
    );
    yield put(changePasswordSuccess());
  } else {
    yield put(
      sendNotification(
        'account.password.new_password',
        error.formattedMessage.id,
        errorType,
        error.formattedMessage.values
      )
    );
    yield put(
      stopSubmit(AdminChangePasswordForm, {
        _error: error.details.error_description,
      })
    );
  }
}

export function* saveLanguageSaga({ payload: { locale } }) {
  const { response, error } = yield call(accountsApi.changeLanguage, locale);

  if (response) {
    yield put(changeLanguageSuccess());
    yield put(localeModule.changeLocale(locale));
  } else {
    yield put(changeLanguageFail(error));
  }
}

export function* watchChangePassword() {
  yield takeEvery(changePassword.getType(), changePasswordSaga);
}

export function* watchChangePasswordAsAdmin() {
  yield takeEvery(changePasswordAsAdmin.getType(), changePasswordAsAdminSaga);
}

export function* watchSaveLanguage() {
  yield takeEvery(saveLanguage.getType(), saveLanguageSaga);
}
