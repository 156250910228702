import React, { Component } from 'react';
import { InjectedFormProps } from 'redux-form';

export default class Progress extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { progress = 0 } = this.props;
    return (
      <div className='progress-bar'>
        <div className='progress' style={{ width: `${progress}%` }} />
      </div>
    );
  }
}
type Props = {
  progress: number;
};
