import { connect } from 'react-redux';
import Step2 from './Step2';

function safeKernel(state) {
  if (!state.form.notebookWizard) return null;
  if (!state.form.notebookWizard.values) return null;
  return state.form.notebookWizard.values.kernel;
}

function safeKernelTouched(state) {
  if (!state.form.notebookWizard) return false;
  if (!state.form.notebookWizard.fields) return false;
  if (!state.form.notebookWizard.fields.kernel) return false;
  return state.form.notebookWizard.fields.kernel.touched;
}

function safeKernelError(state) {
  if (!state.form.notebookWizard) return undefined;
  if (!state.form.notebookWizard.syncErrors) return undefined;
  if (!state.form.notebookWizard.syncErrors.kernel) return undefined;
  return state.form.notebookWizard.syncErrors.kernel.message;
}

export function mapStateToProps(state) {
  return {
    kernel: safeKernel(state),
    kernelTouched: safeKernelTouched(state),
    kernelError: safeKernelError(state),
  };
}

export default connect(mapStateToProps)(Step2);
