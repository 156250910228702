import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

function Notification({
  title,
  description,
  descriptionValues,
  titleValues,
  type,
  remove,
  id,
  progress,
}) {
  function handleClick() {
    remove(id);
  }
  const notificationClassName = classNames(
    'notification',
    { 'notification--red': type === 'error' },
    { 'notification--green': type === 'event' }
  );
  const titleClassName = classNames(
    'notification__title',
    { 'notification__title--red': type === 'error' },
    { 'notification__title--green': type === 'event' }
  );
  return (
    <div className={notificationClassName} onClick={handleClick}>
      <h4 className={titleClassName}>
        <FormattedMessage id={title} values={titleValues} />
      </h4>
      <div className='notification__description'>
        <FormattedMessage id={description} values={descriptionValues} />
      </div>
      {progress > 0 && (
        <div className='notification__progress-container'>
          <div className='notification__progress-back' />
          <div
            className='notification__progress-track'
            style={{ width: `${progress}%` }}
          />
          <span className='notification__progress-state'>{progress}%</span>
        </div>
      )}
    </div>
  );
}

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  descriptionValues: PropTypes.object.isRequired,
  titleValues: PropTypes.object.isRequired,
  progress: PropTypes.number,
};

Notification.defaultProps = {
  progress: 0,
};

export default Notification;
