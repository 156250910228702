import { connect } from 'react-redux';
import RepositoryButtonBar from './RepositoryButtonBar';

export function mapStateToProps(state) {
  const { loading, loaded, error, data } =
    state.workbench.collab.repoDetails || {};
  return {
    loading,
    loaded,
    error,
    data,
  };
}

export const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepositoryButtonBar);
