import { ComponentType } from 'react';
import { MessageDescriptor } from 'react-intl';

/**
 * Will be rendered as a simple horizontal bar in the Sidenav of the Datapool Details
 */
export type HrDatapoolDetailsTab = {
  type: 'hr';
};

/**
 * Will be rendered as a simple headline in the Sidenav of the Datapool Details
 */
export type HeadlineDatapoolDetailsTab = {
  type: 'headline';
  headline: MessageDescriptor;
};

/**
 * Represents one tab of the Augur Details
 */
export abstract class ComponentDatapoolDetailsTab {
  // Will be a link in the Sidenav and a component that can be clicked and rendered
  type: 'component' = 'component';
  // ID of the Datapool Tab.
  id: string;
  // URL Base URL for the link in the sidenav
  to: (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ) => string;
  // React intl ID of the name of the component (shown in the bar on the left)
  title: MessageDescriptor;
  // Icon component to use for the Menu entry
  icon: ComponentType;
  // Component of the DatapoolTab
  component: ComponentType<any>;
  // Sub-routes for the component. Can be empty if no sub-routes exist.
  subRoutes: object[] | undefined;
  // Is the Datapool tab supposed to be shown?
  showTab: (datapool: object) => boolean;
}

export type DatapoolDetailsTab =
  | HrDatapoolDetailsTab
  | HeadlineDatapoolDetailsTab
  | ComponentDatapoolDetailsTab;
