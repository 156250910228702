import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabLink } from 'react-tabs-redux';
import { MdFolderOpen, MdDirectionsRun } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';

export const defaultBrowserSize = 400;
const browserBreakPoint = 380; // If smaller than this, the tabs are icons only (without text)

export default class BrowserTabLinks extends Component {
  render() {
    const { selectedBrowser, handleSelect, browserWidth } = this.props;
    return (
      <div
        className={
          'browser-tab-link-container' +
          (browserWidth < browserBreakPoint ? ' buttons-small' : '')
        }
      >
        <TabLink
          className={'browser-tab-link'}
          isActive={'content' === selectedBrowser}
          handleSelect={handleSelect}
          to='content'
          key='tab-content'
        >
          <MdFolderOpen className={'browser-tab-link-icon'} size={'20px'} />
          <div className={'browser-link-name'}>Private Files</div>
        </TabLink>
        <TabLink
          className={'browser-tab-link'}
          isActive={'recycleBin' === selectedBrowser}
          handleSelect={handleSelect}
          to='recycleBin'
          key='tab-recycleBin'
        >
          <FiTrash2 className={'browser-tab-link-icon'} size={'20px'} />
          <div className={'browser-link-name'}>Recycle Bin</div>
        </TabLink>
        <TabLink
          className={'browser-tab-link'}
          isActive={'sessions' === selectedBrowser}
          handleSelect={handleSelect}
          to='sessions'
          key='tab-sessions'
        >
          <MdDirectionsRun className={'browser-tab-link-icon'} size={'20px'} />
          <div className={'browser-link-name'}>Sessions</div>
        </TabLink>
      </div>
    );
  }
}
BrowserTabLinks.propTypes = {
  selectedBrowser: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  browserWidth: PropTypes.number,
};

BrowserTabLinks.defaultProps = {
  browserWidth: defaultBrowserSize,
};
