import { connect } from 'react-redux';
import EditableTableSettings from './EditableTableSettings';
import { formValueSelector } from 'redux-form';
import { formName, fieldInputTable } from '../_interface/input-element.form';

export function mapStateToProps(state) {
  const inputTableSetting = formValueSelector(formName)(state, fieldInputTable);

  return {
    inputTableSetting,
  };
}

export default connect(mapStateToProps)(EditableTableSettings);
