import { connect } from 'react-redux';
import Python3OutputCell from './Python3OutputCell';
import {
  addOutputElement,
  hideAddOutputElement,
  showAddOutputElement,
  toggleOutputElements,
  showEditOutputElement,
} from '../../../../../../../../../redux/workbench/modules/cells.app.module';

export function mapStateToProps(state) {
  return {
    addOutputElementModal: state.workbench.addOutputElementModal,
  };
}

export const mapDispatchToProps = {
  addOutputElement,
  toggleOutputElements,
  showAddOutputElement,
  hideAddOutputElement,
  showEditOutputElement,
};

export default connect(mapStateToProps, mapDispatchToProps)(Python3OutputCell);
