import { connect, ConnectedProps } from 'react-redux';
import { reduxForm } from 'redux-form';

import UserDetails from './UserDetails';
import * as account from '../../../../../core/forms/account.form';
import { RootState } from '../../../../../store/store';

export function mapStateToProps(state: RootState) {
  return {
    user: state.account.user,
    initialValues: {},
  };
}

export const form = {
  form: account.accountInfoForm,
  enableReinitialize: true,
};

const connector = connect(mapStateToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(reduxForm(form)(UserDetails));
