import { createAction } from 'redux-act';

export const selectMainTab = createAction(
  'select main tab',
  (selected) => selected
);

export const reducer = {
  [selectMainTab]: (state, selected) => ({
    ...state,
    selectedMainTab: selected,
  }),
};
