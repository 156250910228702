import { connect } from 'react-redux';
import { DeprecatedRootState } from '../../../../../../store/state.type';
import MergeRequestOverview from './MergeRequestOverview';
import { mergeMergeRequest } from '../../../../../../redux/workbench/modules/collab.module';
import { switchBranch } from '../../../../../../redux/workbench/modules/collab.repository.module';
import { fetchRefStatus } from '../../../../../../redux/workbench/modules/merger.module';

export function mapStateToProps(state: DeprecatedRootState) {
  const mergeRequest = (state.workbench.collab || {}).mergeRequest;
  const {
    loading: mrLoading,
    loaded: mrLoaded,
    error: mrError,
    data: mrData,
  } = mergeRequest;
  // --- Ref status information about amount commits ahead / behind (from the Merger API)
  const refStatus = (state.workbench.mergerAPI || {}).refStatus;
  const {
    loading: refStatusLoading,
    loaded: refStatusLoaded,
    error: refStatusError,
    data: refStatusData,
  } = refStatus;

  return {
    mrLoading,
    mrLoaded,
    mrError,
    mrData,

    refStatusLoading,
    refStatusLoaded,
    refStatusError,
    refStatusData,
  };
}

export const mapDispatchToProps = {
  switchBranch,
  mergeMergeRequest,
  fetchRefStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MergeRequestOverview);
