import { connect } from 'react-redux';
import ResourcePermissions from './ResourcePermissions';
import { RootState } from '../../../../store/store';
import {
  addGroupPermission,
  addUserPermission,
  loadGroups,
  loadUsersList,
  removeGroupPermission,
  removeUserPermission,
} from '../../../../redux/modules/admin.users.module';

function mapStateToProps(state: RootState) {
  return {
    users: state.admin?.users,
    userPermissions: state.admin.permissions,
    groups: state.admin.groups,
    groupPermissions: state.admin.groupPermissions,
    names: state.names,
  };
}

const mapDispatchToProps = {
  loadUsersList,
  addUserPermission,
  removeUserPermission,

  loadGroups,
  addGroupPermission,
  removeGroupPermission,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcePermissions);
