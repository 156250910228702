import { connect, ConnectedProps } from 'react-redux';
import { DeprecatedRootState } from '../../../store/state.type';
import ManageDataSources from './ManageDataSources';
import {
  deleteDataSource,
  hideDeleteDataSourceConfirm,
  showDeleteDataSourceConfirm,
} from '../../../redux/modules/data.dataSources.module';

export enum DataSourceOrdering {
  LastCreated,
  OldestCreated,
  NameAsc,
  NameDesc,
}

export const dataSourceOrderings = [
  { label: 'Last Created', value: DataSourceOrdering.LastCreated },
  { label: 'Oldest Created', value: DataSourceOrdering.OldestCreated },
  { label: 'Name A-Z', value: DataSourceOrdering.NameAsc },
  { label: 'Name Z-A', value: DataSourceOrdering.NameDesc },
];

export type OwnProps = {};

export type Props = OwnProps & ConnectedProps<typeof connector>;

export function mapStateToProps(
  state: DeprecatedRootState,
  ownProps: OwnProps
) {
  return {
    dataSources: state.data.dataSources.data,
    deleteDataSourceConfirm: state.data.manageDS.deleteDataSourceConfirm,
  };
}

export const mapDispatchToProps = {
  hideDeleteDataSourceConfirm,
  showDeleteDataSourceConfirm,
  deleteDataSource,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ManageDataSources);
