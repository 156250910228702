import React, { FunctionComponent } from 'react';
import ReactLoading from 'react-loading';
import { LoadableData } from '../../../utils';
import vars from '../../../../scss/base/var.module.scss';

export type LoadableDataProps<Data, Error> = {
  loadableData: LoadableData<Data, Error>;
  renderData: (data: Data) => React.ReactElement;
  renderError: (error: Error) => React.ReactElement;
};
export default function RenderLoadableData<Data, Error>(): FunctionComponent<
  LoadableDataProps<Data, Error>
> {
  const res: FunctionComponent<LoadableDataProps<Data, Error>> = ({
    loadableData,
    renderData,
    renderError,
  }: LoadableDataProps<Data, Error>): React.ReactElement => {
    if (!loadableData) {
      console.log('loadableData was undefined.');
      // Case that should never actually happen, just to be sure.
      return <div />;
    } else if (loadableData.loading) {
      // The data is currently (re-)loading -> Show the loading spinner
      return (
        <ReactLoading
          className={'starting-stopping-spinner'}
          type={'cylon'}
          color={vars.colorPrimary}
        />
      );
    } else if (loadableData.error) {
      // There was an error -> Display the error
      return renderError(loadableData.error);
    } else if (loadableData.data) {
      // The data was loaded and is there -> return it.
      return renderData(loadableData.data);
    } else {
      // TODO are there cases in which this case can actually happen?
      return <div />;
    }
  };
  return res;
}
