import { connect } from 'react-redux';
import MergeRequestDetails from './MergeRequestDetails';
import { DeprecatedRootState } from '../../../../../store/state.type';
import { loadMergeRequestDetails } from '../../../../../redux/workbench/modules/collab.module';

export function mapStateToProps(state: DeprecatedRootState) {
  const mergeRequest = (state.workbench.collab || {}).mergeRequest;
  const {
    loading: mrLoading,
    loaded: mrLoaded,
    error: mrError,
    data: mrData,
  } = mergeRequest;

  return {
    mrData,
    mrLoading,
  };
}

export const mapDispatchToProps = {
  loadMergeRequestDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MergeRequestDetails);
