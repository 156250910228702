import React, { Component } from 'react';
import {
  commonReduxFormProps,
  wizardHeadline,
} from '../../../../../newAugur/newAugur.form';
import WizardPage from '../../../../../organisms/multi-page-wizard/WizardPage';
import MultiPageWizard from '../../../../../organisms/multi-page-wizard/MultiPageWizard.container';
import WizardStep from '../../../../../organisms/multi-page-wizard/WizardStep';
import { renderDefaultWizardSteps } from '../../../../../newAugur/defaultWizard/DefaultNewAugurWizard';
import {
  fieldAugurName,
  fieldModuleType,
} from '../../../../../newAugur/defaultWizard/default.form';
import {
  fieldArchetype,
  fieldConstraints,
  fieldContactPolicy,
  fieldCustomerTable,
  fieldDatapool,
  fieldTargetFunction,
  getOptimizationMpwAsyncValidate,
  getOptimizationSubmitSummary,
  optimizationAsyncBlurFields,
  optimizationAsyncChangeFields,
  optimizationValidate,
  optimizationWarn,
} from './optimization.form';
import Archetype from '../../../../../organisms/modelManagement/newAugurWizard/archetype/Archetype.container';
import Datapool from '../../../../../organisms/modelManagement/newAugurWizard/datapool/Datapool.container';
import TablePreview from '../../../../../organisms/modelManagement/newAugurWizard/commonComponents/tablePreview/TablePreview.container';
import { ArchetypeValueType } from '../../../../../organisms/modelManagement/newAugurWizard/archetype/Archetype';
import { ArchetypeType } from 'common/dist/types/archetype';
import { moduleType as MODULE_TYPE } from 'common/dist/constants/module_list';
import WithTableSample from '../../../../../organisms/modelManagement/newAugurWizard/commonComponents/withTableSample/WithTableSample.container';
import OptCustomerTable from '../../../../../organisms/modelManagement/newAugurWizard/optCustomerTable/OptCustomerTable';
import { HighlightStyle } from '../../../../../organisms/modelManagement/newAugurWizard/commonComponents/tablePreview/TablePreview';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import messagesModuleOptimization from 'common/dist/messages/module.optimization';
import OptConstraints from '../../../../../organisms/modelManagement/newAugurWizard/optConstraints/OptConstraints.container';
import OptContactPolicies from '../../../../../organisms/modelManagement/newAugurWizard/optContactPolicies/OptContactPolicies.container';
import OptTargetFunction from '../../../../../organisms/modelManagement/newAugurWizard/optTargetFunction/OptTargetFunction';
import { CampaignOptimizationDatapoolType } from 'common/dist/types/datapool';
import messages from 'common/dist/messages/augurs';
import { ParsedQs } from 'qs';
import { ModulesState } from '../../../../../../store/modules/state.types';
import { PostAugurRequestBody } from 'common/dist/types/requestBodies/augurs';
import { AugurSettingsDataOpt } from 'common/dist/types/augurSettings';
import { CustomerTableValueType } from 'common/dist/types/augurSettings.opt';
import vars from '../../../../../../../scss/base/var.module.scss';

export type OwnProps = {
  /** Code of the habitat to add this augur to */
  habitatCode: string;
  /** Name of the habitat to add this augur to */
  habitatName?: string;
  /** List of available modules */
  modules?: ModulesState;
  /** Is the wizard currently being submitted? */
  isSubmitting?: boolean;
  /** Sends a request to create a new augur */
  addAugur: (augurSummary: PostAugurRequestBody<AugurSettingsDataOpt>) => void;
  /** Navigation on cancel (generally back to the origin) */
  navigateOnCancel: () => void;
  /** Selector for the redux-form form values */
  formValueSelector: (fieldName: string) => any;
  /** Optional passed url query (already parsed) */
  query?: ParsedQs;
  resetForm?: () => void;
};

type ContainerProps = {
  /** List of available archetypes */
  archetypes: ArchetypeType[];
};

export type Props = OwnProps & ContainerProps;

export default class OptimizationNewAugurWizard extends Component<Props> {
  render() {
    const {
      habitatCode,
      habitatName,
      modules,
      isSubmitting,
      addAugur,
      navigateOnCancel,
      formValueSelector,
      query,
      archetypes,
      resetForm,
    } = this.props;

    const onSubmit = (values) => {
      const submitSummary = getOptimizationSubmitSummary(values, habitatCode);
      addAugur(submitSummary);
    };

    // --- Selected values
    const moduleType = MODULE_TYPE.CAMPAIGN_OPTIMIZATION; // Selected in the DefaultAugurWizard
    const augurName = formValueSelector(fieldAugurName);
    const datapool: CampaignOptimizationDatapoolType =
      formValueSelector(fieldDatapool);

    // --- Initial values
    const initialValues = {
      // If this isn't set, the first click onto the Module Type in the DefaultNewAugurWizard won't work
      // only the second click will really select the module type and render the correct wizard.
      [fieldModuleType]: moduleType,
    };

    // --- Pick out the python archetype since this is the preferred one.
    //   has to be done like this since the archetype codes are not deterministic
    const pythonArchetype = (archetypes || []).find(
      (arch) => arch.name === 'Optimization'
    );
    const mpwInitialArchetype: ArchetypeValueType = pythonArchetype
      ? {
          origin: 'stock',
          code: pythonArchetype.code,
        }
      : undefined;

    // --- Steps
    const renderDatapoolStep = (stepNumber: number) => {
      return (
        <WizardStep
          stepNumber={stepNumber}
          title={{
            id: messages.msgDatapoolTitle.id,
            defaultMessage: messages.msgDatapoolTitle.defaultMessage,
          }}
          description={{
            id: messages.msgDatapoolDescription.id,
            defaultMessage: messages.msgDatapoolDescription.defaultMessage,
            // @ts-ignore
            values: {
              // TODO The "values" work - but why is the type causing trouble?
              moduleName: (modules?.data || []).find(
                (module) => module.type === moduleType
              )?.name,
              b: (chunks) => <b>{chunks}</b>,
            },
          }}
          fieldName={fieldDatapool}
        >
          {/* @ts-ignore */}
          <Datapool
            habitatCode={habitatCode}
            selectedModuleType={moduleType}
            dropdownSelectPortal={document.querySelector('body')}
            initialSelectedDatapoolCode={query?.datapoolCode as string}
          />
        </WizardStep>
      );
    };

    const renderArchetypeStep = (stepNumber: number) => {
      return (
        <WizardStep
          stepNumber={stepNumber}
          title={{
            id: messages.msgSelectArchetypeTitle.id,
            defaultMessage: messages.msgSelectArchetypeTitle.defaultMessage,
          }}
          description={{
            id: messages.msgSelectArchetypeDescription.id,
            defaultMessage:
              messages.msgSelectArchetypeDescription.defaultMessage,
          }}
          fieldName={fieldArchetype}
          mpwInitialValues={mpwInitialArchetype}
        >
          {/* @ts-ignore */}
          <Archetype
            datapool={datapool}
            habitatCode={habitatCode}
            dropdownSelectPortal={document.querySelector('body')}
          />
        </WizardStep>
      );
    };

    // --- Pages
    const renderPageCustomerTable = (stepNumber: number) => {
      return (
        // @ts-ignore
        <WizardPage
          pageTitle={
            moduleOptimizationMessages.msgNewAugurPageCustomerTableTitle
          }
        >
          <WizardStep
            title={moduleOptimizationMessages.msgNewAugurStepCustomerTableTitle}
            description={
              moduleOptimizationMessages.msgNewAugurStepCustomerTableDescription
            }
            stepNumber={stepNumber}
            fieldName={fieldCustomerTable}
            BottomComponent={TablePreview}
            bottomComponentProps={{
              datapoolCode: datapool?.code,
              tableName: formValueSelector(fieldCustomerTable)?.tableName,
              columnHighlights: customerTableColumnHighlights(
                formValueSelector(fieldCustomerTable)
              ),
            }}
            bottomComponentFullWidth
          >
            <WithTableSample
              datapool={datapool}
              tableName={formValueSelector(fieldCustomerTable)?.tableName}
            >
              {/* @ts-ignore */}
              <OptCustomerTable
                datapoolCode={datapool?.code}
                habitatCode={habitatCode}
                dropdownSelectPortal={document.querySelector('body')}
              />
            </WithTableSample>
          </WizardStep>
        </WizardPage>
      );
    };

    const renderPageConstraints = (stepNumber: number) => {
      return (
        // @ts-ignore
        <WizardPage
          pageTitle={moduleOptimizationMessages.msgNewAugurPageConstraintsTitle}
        >
          <WizardStep
            fieldName={fieldConstraints}
            title={moduleOptimizationMessages.msgNewAugurStepConstraintsTitle}
            description={
              moduleOptimizationMessages.msgNewAugurStepConstraintsDescription
            }
            stepNumber={stepNumber}
            BottomComponent={OptConstraints}
            bottomComponentProps={{
              habitatCode,
              datapoolCode: datapool?.code,
              tableName: formValueSelector(fieldCustomerTable)?.tableName,
              inEditMode: true,
            }}
          />
        </WizardPage>
      );
    };

    const renderPageContactPolicies = (stepNumber: number) => {
      return (
        // @ts-ignore
        <WizardPage
          pageTitle={
            moduleOptimizationMessages.msgNewAugurPageContactPolicyTitle
          }
        >
          <WizardStep
            fieldName={fieldContactPolicy}
            title={
              moduleOptimizationMessages.msgNewAugurStepContactPoliciesTitle
            }
            description={
              moduleOptimizationMessages.msgNewAugurStepContactPoliciesDescription
            }
            stepNumber={stepNumber}
            BottomComponent={OptContactPolicies}
            bottomComponentProps={{
              habitatCode,
              datapoolCode: datapool?.code,
              tableName: formValueSelector(fieldCustomerTable)?.tableName,
              inEditMode: true,
            }}
          />
        </WizardPage>
      );
    };

    const renderPageTargetFunction = (stepNumber: number) => {
      return (
        // @ts-ignore
        <WizardPage
          pageTitle={
            moduleOptimizationMessages.msgNewAugurPageTargetFunctionTitle
          }
        >
          <WizardStep
            fieldName={fieldTargetFunction}
            title={
              moduleOptimizationMessages.msgNewAugurStepTargetFunctionTitle
            }
            description={
              moduleOptimizationMessages.msgNewAugurStepTargetFunctionDescription
            }
            stepNumber={stepNumber}
            // @ts-ignore
            BottomComponent={OptTargetFunction}
            bottomComponentProps={{
              habitatCode,
              datapoolCode: datapool?.code,
              inEditMode: true,
            }}
          />
        </WizardPage>
      );
    };

    let pageNumber = 3; // Starting at 3, since the first two steps are always the same (coming from the DefaultAugurWizard)
    return (
      <MultiPageWizard
        {...commonReduxFormProps}
        initialValues={initialValues}
        // @ts-ignore
        validate={optimizationValidate}
        // @ts-ignore
        warn={optimizationWarn}
        // @ts-ignore
        asyncValidate={getOptimizationMpwAsyncValidate(habitatCode)}
        asyncBlurFields={optimizationAsyncBlurFields}
        asyncChangeFields={optimizationAsyncChangeFields}
        wizardHeadline={wizardHeadline(habitatName)}
        onCancel={navigateOnCancel}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      >
        {/* @ts-ignore */}
        <WizardPage
          pageTitle={{
            id: 'no-id',
            defaultMessage: 'Module Type & Augur Name',
          }}
        >
          {renderDefaultWizardSteps(modules, resetForm)}

          {renderDatapoolStep(pageNumber++)}
          {renderArchetypeStep(pageNumber++)}
        </WizardPage>

        {renderPageCustomerTable(pageNumber++)}
        {renderPageConstraints(pageNumber++)}
        {renderPageContactPolicies(pageNumber++)}
        {renderPageTargetFunction(pageNumber++)}
      </MultiPageWizard>
    );
  }
}

export const customerTableColumnHighlights = (
  customerTableValue: CustomerTableValueType
) => {
  const highlights: { [colName: string]: HighlightStyle } = {};
  if (!customerTableValue) return {};

  if (customerTableValue?.customerIdColumn) {
    highlights[customerTableValue?.customerIdColumn] = {
      backgroundColor: vars.colorSecondary,
      colName: {
        color: 'white',
      },
      colType: {
        color: 'white',
      },
      legend:
        messagesModuleOptimization.msgNewAugurCustomerTableCustomerIdColumnPlaceholder,
    };
  }

  if (customerTableValue?.customerId2Column) {
    highlights[customerTableValue?.customerId2Column] = {
      backgroundColor: vars.colorSecondary,
      colName: {
        color: 'white',
      },
      colType: {
        color: 'white',
      },
      legend:
        messagesModuleOptimization.msgNewAugurCustomerTableCustomerId2ColumnPlaceholder,
    };
  }

  if (customerTableValue?.valueIdColumn) {
    highlights[customerTableValue?.valueIdColumn] = {
      backgroundColor: vars.colorPrimaryHighlight,
      colName: {
        color: 'white',
      },
      colType: {
        color: 'white',
      },
      legend:
        messagesModuleOptimization.msgNewAugurValueTableValueIdColumnPlaceholder,
    };
  }

  if (customerTableValue?.valueColumn) {
    highlights[customerTableValue?.valueColumn] = {
      backgroundColor: vars.colorPrimary,
      colName: {
        color: 'white',
      },
      colType: {
        color: 'white',
      },
      legend:
        messagesModuleOptimization.msgNewAugurValueTableValueColumnPlaceholder,
    };
  }

  return highlights;
};
