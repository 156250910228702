import { connect } from 'react-redux';
import Sessions from './Sessions';
import { RootState } from '../../../../store/store';

export const mapStateToProps = (state: RootState) => ({
  data: state.workbench.sessions.data,
  error: state.workbench.sessions.error,
});

export default connect(mapStateToProps)(Sessions);
