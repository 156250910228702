import { createAction } from 'redux-act';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';
import { fetchContent } from './content.module';
import { directoryUser } from '../selectors/directoryUser.selector';
import {
  error as errorNotification,
  success as successNotification,
} from 'react-notification-system-redux';
import {
  notificationEditDirectoryFail,
  notificationEditDirectorySuccess,
} from '../notifications/notifications';
import * as DirectoryApi from '../../../core/api/workbench/directory';
import { RightParts } from '../../../components/workbench/part-right/rightParts';

export const addDirectory = createAction('add directory', (directoryName) => ({
  directoryName,
}));
export const addDirectoryRequest = createAction('add directory - request');
export const addDirectorySuccess = createAction(
  'add directory - success',
  (success) => success
);

export const addDirectoryFail = createAction(
  'add directory - fail',
  (error) => error
);
export const showEditDirectory = createAction(
  'show edit directory',
  (name, path) => ({ name, path })
);

export const hideEditDirectory = createAction('hide edit directory');
export const saveEditDirectory = createAction(
  'save edit directory',
  (directoryName, altaSigmaMeta) => ({ directoryName, altaSigmaMeta })
);

export const saveEditDirectorySuccess = createAction(
  'save edit directory - success',
  (success) => success
);

export const saveEditDirectoryFail = createAction(
  'save edit directory - fail',
  (error) => error
);

export function* addDirectorySaga({ payload: { directoryName } }) {
  const directoryPath = yield select(
    (state) => `${state.workbench.content.selectedDirPath.slice(1).join('/')}/`
  );
  yield put(addDirectoryRequest());
  const { response, error } = yield call(
    addDirectoryCalls,
    directoryName,
    directoryPath
  );
  if (response) {
    yield put(addDirectorySuccess(response));
    const selectedDirPath = yield select(
      (state) => state.workbench.content.selectedDirPath
    );
    yield put(fetchContent(selectedDirPath));
  } else {
    yield put(addDirectoryFail(error));
  }
}

export function* watchAddDirectory() {
  yield takeEvery(addDirectory().type, addDirectorySaga);
}

export function* saveEditDirectorySaga({ payload: { directoryName } }) {
  const oldPath = yield select(
    (state) => state.workbench.showEditDirectory?.path
  );
  if (!oldPath) yield cancel(); // Exit if the old path couldn't be selected

  const newDirectoryPath = yield select(
    (state) => `${state.workbench.content.selectedDirPath.slice(1).join('/')}/`
  );

  const { response, error } = yield call(
    saveEditDirectoryCalls,
    oldPath,
    directoryName,
    newDirectoryPath
  );
  if (response) {
    yield put(saveEditDirectorySuccess(response));
    const selectedDirPath = yield select(
      (state) => state.workbench.content.selectedDirPath
    );
    yield put(fetchContent(selectedDirPath));
    yield put(hideEditDirectory());
    yield put(
      successNotification(notificationEditDirectorySuccess(newDirectoryPath))
    );
  } else {
    yield put(saveEditDirectoryFail(error));
    yield put(hideEditDirectory());
    yield put(errorNotification(notificationEditDirectoryFail()));
  }
}

export function* watchSaveEditDirectory() {
  yield takeEvery(saveEditDirectory.getType(), saveEditDirectorySaga);
}

function* saveEditDirectoryCalls(oldPath, directoryName, directoryPath) {
  const jupyterUser = yield select((state) => directoryUser(state));
  const newPathRaw = directoryPath + directoryName;
  const newPath = newPathRaw.startsWith('/') ? newPathRaw.slice(1) : newPathRaw;
  return yield DirectoryApi.renameDirectory(oldPath, newPath, jupyterUser).then(
    (response, error) => {
      return { response: response, error };
    }
  );
}

export function* addDirectoryCalls(directoryName, directoryPath) {
  const jupyterUser = yield select((state) => directoryUser(state));
  return yield DirectoryApi.createDirectory(jupyterUser).then(
    (response, error) => {
      if (response) {
        const name = response.response.name; // This is strange ...
        const newPathRaw = directoryPath + directoryName;
        const newPath = newPathRaw.startsWith('/')
          ? newPathRaw.slice(1)
          : newPathRaw;
        return DirectoryApi.renameDirectory(name, newPath, jupyterUser);
      }
      return { response: null, error };
    }
  );
}

export const reducer = {
  [addDirectoryRequest]: (state) => ({
    ...state,
    isCreatingDirectory: true,
  }),
  [addDirectorySuccess]: (state) => ({
    ...state,
    isCreatingDirectory: false,
  }),
  [addDirectoryFail]: (state) => ({
    ...state,
    isCreatingDirectory: false,
  }),
  [showEditDirectory]: (state, { name, path }) => ({
    ...state,
    showEditDirectory: {
      name,
      path,
    },
    showOnRight: RightParts.EDIT_DIRECTORY,
  }),
};
