export const initial = [];

export const test = [
  {
    id: '123',
    title: 'New Augur',
    description: 'New Augur was added',
    type: 'event',
  },
];
