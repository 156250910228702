import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import styles from './styles.module.scss';
import { AugurDetailsQueryParams } from '../types';
import { FormattedMessage } from 'react-intl';
import augurDetailsMsgs from 'common/dist/messages/augurs.details';

export interface Props {}

export const JobDescriptionBanner: FC<Props> = ({ children }) => {
  const history = useHistory();
  const queryParams: AugurDetailsQueryParams = qs.parse(
    history.location.search,
    {
      ignoreQueryPrefix: true,
    }
  );
  return queryParams.modelCode ? (
    <div className={styles.parent}>
      <div className={styles.banner}>
        <FormattedMessage
          {...augurDetailsMsgs.jobBanner}
          values={{
            modelCode: queryParams.modelCode,
          }}
        />
      </div>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};
