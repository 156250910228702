import React, { Component } from 'react';
import RoleRepresentation from '@keycloak/keycloak-admin-client/lib/defs/roleRepresentation';
import ThemedSwitch from '../../../atoms/themed-switch/ThemedSwitch';

const FILTERED_ROLES = ['offline_access', 'uma_authorization'];

export interface Props {
  rolesData: RoleRepresentation[];
  usersRoles: RoleRepresentation[];
  /** Add a User-RealmRole mapping */
  addRealmRoleMapping: (
    userId: string,
    roleId: string,
    roleName: string
  ) => void;
  /** Remove a User-RealmRole mapping */
  removeRealmRoleMapping: (
    userId: string,
    roleId: string,
    roleName: string
  ) => void;
  userId?: string;
}

export default class Roles extends Component<Props> {
  render() {
    const {
      rolesData,
      usersRoles,
      addRealmRoleMapping,
      removeRealmRoleMapping,
      userId,
    } = this.props;
    const filteredRoles = rolesData.filter(
      (role) => !FILTERED_ROLES.includes(role.name)
    );

    return (
      <div className={'roles-container ct-list'}>
        <div className={'ct-row ct-headline'}>
          <div className={'ct-col ct-col-280px'}>
            <span>Role Name</span>
          </div>
          <div className={'ct-col ct-col-350px col-description'}>
            <span>Description</span>
          </div>
          <div className={'ct-col ct-col-80px'}>
            <span>Active</span>
          </div>
        </div>
        {filteredRoles
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((role) => (
            <div className={'ct-row roles-row'}>
              <div className={'ct-col ct-col-280px'}>
                <span>{role.name}</span>
              </div>
              <div className={'ct-col ct-col-350px col-description'}>
                <span>{role.description}</span>
              </div>
              <div className={'ct-col ct-col-80px'}>
                <ThemedSwitch
                  onChange={() => {
                    if (usersRoles.map((r) => r.id).includes(role.id)) {
                      removeRealmRoleMapping(userId, role.id, role.name);
                    } else {
                      addRealmRoleMapping(userId, role.id, role.name);
                    }
                  }}
                  checked={usersRoles.map((r) => r.id).includes(role.id)}
                  height={20}
                  width={40}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                  activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                  themeColor={'primary'}
                />
              </div>
            </div>
          ))}
      </div>
    );
  }
}
