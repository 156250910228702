import { createAction } from 'redux-act';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { reset as resetForm, actionTypes } from 'redux-form';

import * as wizard from '../../core/wizard';
import { initial } from '../state/dataImport.state';
import * as dataImportForm from '../../core/forms/dataImport.form';

export const reset = createAction('reset user input data for a data import');
export const setValidity = createAction(
  'set validity of a data import wizard',
  (validity) => ({ validity })
);

export const reducer = {
  [reset]: (state) => ({
    ...state,
    dataImport: wizard.reset(state.dataImport, initial),
  }),
  [setValidity]: (state, payload) => ({
    ...state,
    dataImport: wizard.setValidity(state.dataImport, payload),
  }),
};

export function* resetDataSaga() {
  yield put(resetForm(dataImportForm.step0Form));
}

const getDataImportForm = (state) => state.form[dataImportForm.step0Form];

function* updateFormValidity({ meta }) {
  if (meta.form.indexOf(dataImportForm.step0Form) !== 0) return;

  const form = yield select(getDataImportForm);
  yield put(setValidity(form && !form.syncErrors));
}

export function* watchDataImportReset() {
  yield takeEvery(reset.getType(), resetDataSaga);
}

export function* watchDataImportChanged() {
  yield takeLatest(actionTypes.UPDATE_SYNC_ERRORS, updateFormValidity);
}
