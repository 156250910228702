import ModelSettingsCategory from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { MessageDescriptor } from 'react-intl';
import { FiBookOpen } from 'react-icons/fi';
import React from 'react';
import TaggerDictionary from './TaggerDictionary.container';
import { AugurSettingsDataNlt } from 'common/dist/types/augurSettings';

export default class TaggerDictionaryWrapper
  implements ModelSettingsCategory<AugurSettingsDataNlt>
{
  component = TaggerDictionary;
  icon: React.ComponentType = () => <FiBookOpen size={17} />;
  id = 'tagger-dictionary';
  label: MessageDescriptor = {
    id: 'no-id',
    defaultMessage: 'Tagger Dictionary',
  };
}
