export function getJupyterBaseUrl(
  parentType: 'app' | 'notebook',
  jupyterUser: string,
  appVersionCode?: string
) {
  if (parentType === 'app') {
    const serverName = appVersionCode.toLowerCase();
    return `/jupyterapp/user/${jupyterUser}/${serverName}`;
  } else if (parentType === 'notebook') {
    // parentType === 'notebook'
    return `/jupyter/user/${jupyterUser}`;
  }
}
