import { MessageDescriptor } from 'react-intl';
import { ArchetypeValueType } from './Archetype';

type Fields = 'origin' | 'code' | 'versionNumber';

export function validateArchetype(
  values: ArchetypeValueType
): { [subField in Fields]?: MessageDescriptor } | undefined {
  if (!values) {
    return {
      code: { id: 'no-id', defaultMessage: 'Please select an Archetype' },
    };
  }

  const { origin, code, versionNumber } = values;
  if (!origin) {
    return {
      origin: {
        id: 'no-id',
        defaultMessage:
          'Please select whether your Augur is a Stock or a Custom Archetype',
      },
    };
  }

  if (!code) {
    return {
      code: { id: 'no-id', defaultMessage: 'Please select an Archetype' },
    };
  }

  if (origin === 'custom' && !versionNumber) {
    return {
      versionNumber: {
        id: 'no-id',
        defaultMessage: 'Please select the Archetype Version',
      },
    };
  }

  return undefined;
}
