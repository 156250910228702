import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import { collaborationSpaceRoutes } from './routes';
import Repositories from './browser/repositories/Repositories.container';
import Groups from './browser/groups/Groups.container';
import AddRepositoryWizard from './wizards/repository-add/AddRepositoryWizard.container';
import RepositoryDetails from './repository-details/RepositoryDetails';
import MainContainer from '../pages/main-container/MainContainer';
import WithSidemenu from '../molecules/with-sidemenu/WithSidemenu';
import { FiBook, FiGrid } from 'react-icons/fi';
import NotFound from '../pages/not-found/NotFound.container';
import NoPermissions from '../pages/no-permissions/NoPermissions';

const repositoryBrowserMenuEntries = [
  {
    type: 'link',
    id: 'repositories',
    to: 'repositories',
    nameIntlId: 'dashboard.tab.collab.repositories',
    nameDefault: 'Repositories',
    icon: FiBook,
  },
  {
    type: 'link',
    id: 'groups',
    to: 'groups',
    nameIntlId: 'dashboard.tab.collab.groups',
    nameDefault: 'Groups',
    icon: FiGrid,
  },
];

class CollaborationSpace extends Component {
  isConflictsActive = () =>
    !!matchPath(
      this.props.history.location.pathname,
      `${collaborationSpaceRoutes.basePath}/:group/:repositoryName/${collaborationSpaceRoutes.repositories.mergeRequest}/:id/${collaborationSpaceRoutes.repositories.resolve}`
    );

  getActiveCategory() {
    const path = this.props.history.location.pathname;
    if (!path) return null;
    const rep = path.replace('/app/collab/', '');
    const parts = rep.split('/');
    if (['repositories', 'groups'].includes(parts[0])) {
      // In this case we're in the repository browser - return this
      return parts[0];
    } else {
      // In this case we're in the repository details - drop the first 2 elements, since they're the repository group and name
      if (parts.length > 2) return parts[2];
      else return undefined; // This is fine and will be treated by the routing with a proper redirect to the repository overview
    }
  }

  renderPermissionsError() {
    return <NoPermissions fullViewHeight />;
  }

  render() {
    const { missingPermissionsError, notifications } = this.props;
    const selectedCategory = this.getActiveCategory();
    const handleSelect = (selectedTab) =>
      this.props.history.push(
        `${collaborationSpaceRoutes.basePath}/${selectedTab}`
      );

    // Check whether the conflicts page is currently shown as a sub-route. If yes, the width of the page needs to be adjusted.
    const isConflictsActive = this.isConflictsActive();

    if (missingPermissionsError) return this.renderPermissionsError();
    else
      return (
        <MainContainer
          additionalClassname={'CollaborationSpace'}
          transparent={false}
          fullWidth={isConflictsActive}
        >
          <Switch>
            {/* Browse Repositories  */}
            <Route
              path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.path}`}
              exact
            >
              <WithSidemenu
                menuEntries={repositoryBrowserMenuEntries}
                selectedTab={selectedCategory}
                handleSelect={handleSelect}
              >
                <Repositories />
              </WithSidemenu>
            </Route>

            {/* Browse Groups  */}
            <Route
              path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.groups.path}`}
            >
              <WithSidemenu
                menuEntries={repositoryBrowserMenuEntries}
                selectedTab={selectedCategory}
                handleSelect={handleSelect}
              >
                <Groups />
              </WithSidemenu>
            </Route>

            {/* Add Repository Wizard */}
            <Route
              path={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.create}`}
            >
              <AddRepositoryWizard />
            </Route>

            {/* Repository Details */}
            <Route
              path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName`}
            >
              <RepositoryDetails />
            </Route>

            {/* If no tab is selected, the default tab is Browse Repositories */}
            <Redirect
              exact
              path={collaborationSpaceRoutes.basePath}
              to={`${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.path}`}
            />

            {/* Default route: Not found. */}
            <Route component={() => <NotFound fullViewHeight={false} />} />
          </Switch>
        </MainContainer>
      );
  }
}

CollaborationSpace.propTypes = {
  missingPermissionsError: PropTypes.bool,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(CollaborationSpace);
