import { connect, ConnectedProps } from 'react-redux';
import GitCommitModal from './GitCommitModal';
import { hideGitCommitModal } from '../../../../../../redux/workbench/modules/repository.module';
import { isValid, isPristine, formValueSelector } from 'redux-form';
import { gitCommitForm } from './gitCommit.form';
import { gitAddAllAndCommit } from '../../../../../../redux/workbench/modules/container-interactions.module';
import qs from 'qs';
import { withRouter } from 'react-router-dom';

export type GitCommitModalProps = {
  isOpen?: boolean;
  hideGitCommitModal(...args: unknown[]): unknown;
  valid?: boolean;
  pristine?: boolean;
  gitAddAllAndCommit(...args: unknown[]): unknown;
  commitMessage?: string;
  infoFilePath: string;
  activeBranch: string;
};

export function mapStateToProps(state, { location }) {
  const infoFilePath = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).path;
  return {
    isOpen: state.workbench.showRepositoryInfo.showGitCommitModal,
    valid: isValid(gitCommitForm.form)(state),
    pristine: isPristine(gitCommitForm.form)(state),
    commitMessage: formValueSelector(gitCommitForm.form)(
      state,
      gitCommitForm.fieldCommitMessage
    ),
    infoFilePath,
    activeBranch: state.workbench.showRepositoryInfo.activeBranch,
  };
}

export const mapDispatchToProps = {
  hideGitCommitModal,
  gitAddAllAndCommit,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type connectedProps = ConnectedProps<typeof connector>;
export default withRouter(connector(GitCommitModal));
