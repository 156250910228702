import { ToBeRefined } from 'common/dist/types/todo_type';
import { MessageDescriptor } from 'react-intl';
import React from 'react';

export const formName = 'newAugur';

// --- Props that are passed to reduxForm() of all NewAugurWizards
export const commonReduxFormProps = {
  form: formName,
  destroyOnUnmount: false,
  /** Needed to allow for initial values in the specific wizard (i.e. to set an initial value for the sampling strategy) **/
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
};

// --- Headline
export const wizardHeadline: (habitatName: string) => MessageDescriptor = (
  habitatName: string
) => ({
  id: 'no-id', // 'newAugur.header.header',
  defaultMessage: 'Create a new Augur in Habitat{space}<b>{habitatName}</b>',
  values: {
    habitatName,
    space: <span style={{ width: '5px' }} />, // Otherwise the space is removed by react-intl ...
    b: (chunks) => <span style={{ fontWeight: 400 }}>{chunks}</span>,
  },
});

// ##########################################
// ################### OLD ##################
// ##########################################

// --- General
export const fieldDatapool = 'datapool';

// --- Binary Classification
export const fieldArchetype = 'archetype';
export const fieldPredictionSchedule = 'predictionSchedule';
export const fieldPredictionTargets = 'predictionTargets';
export const fieldTrainingTable = 'trainingTable';
export const fieldPredictionTable = 'predictionTable';
export const fieldSamplingStrategy = 'samplingStrategy';

export interface BCValue {
  [fieldSamplingStrategy]: ToBeRefined;
}

// --- SMAD
export const fieldReferencePeriods = 'referencePeriods';

// --- SBAD
export const fieldReferenceCycles = 'referenceCycles';

export const newAugurForm = {
  form: formName,
  destroyOnUnmount: false,
  touchOnChange: true,
  /** Needed i.a. for setting archetype initialValues after selecting the archetype **/
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  /** Needed i.a. for switching from parameterTuning of one archetype to another
   (syncErrors are not cleared, for no longer existing (sub-)fields, probably because of destroyOnUnmount: false) **/
  forceUnregisterOnUnmount: true,
};

export type NewAugurFormValues = {
  // TODO This type is very likely incomplete
  [fieldArchetype]?: {
    code?: string;
    versionNumber?: string;
  };
  columnSelection?: ToBeRefined;
  cycleStart?: ToBeRefined;
  [fieldDatapool]?: {
    code?: string;
    moduleType?: string;
    inputFormat?: string;
  };
  augurName?: string;
  parameterTuning?: ToBeRefined;
  [fieldPredictionSchedule]?: ToBeRefined;
  [fieldPredictionTable]?: string;
  [fieldReferencePeriods]?: ToBeRefined;
  [fieldSamplingStrategy]?: ToBeRefined;
  [fieldReferenceCycles]?: {
    manualOrAuto: 'manual' | 'auto';
    referenceCycles: ToBeRefined;
  };
  [fieldPredictionTargets]?: ToBeRefined;
  [fieldTrainingTable]?: {
    tableName?: string;
    labelColumn?: string;
    primaryKeyColumns?: string[];
    positiveLabelValue?: string;
    negativeLabelValue?: string;
  };
  windowSize: ToBeRefined;
};
