import { ComponentType } from 'react';
import { ComponentDatapoolDetailsTab } from '../../_interfaces/datapool-details/DatapoolDetailsTab';
import CommunicationGroups from './CommunicationGroups.container';
import { DatapoolType } from 'common/dist/types/datapool';
import { FiLayers } from 'react-icons/fi';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';

export default class OptCommunicationGroupsWrapper extends ComponentDatapoolDetailsTab {
  type: 'component' = 'component';
  id = 'communicationGroups';
  title =
    moduleOptimizationMessages.msgDatapoolDetailsCommunicationGroupsTabName;
  to = (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${shortDatapoolType}/communicationGroups`;

  icon: ComponentType = () => <FiLayers size={16} />;
  component: ComponentType<any> = CommunicationGroups;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType): boolean => true;
}
