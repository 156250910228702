import { ConfigProps } from 'redux-form';
import { Props, RouteParams } from './BuildArchetypeWizard';
import { RouteComponentProps } from 'react-router';

export const formName = 'buildArchetype';

export const fieldTag = 'tag';

export interface BuildArchetypeData {
  [fieldTag]: string;
}

export type BuildArchetypeError = string;

export const form: ConfigProps<
  BuildArchetypeData,
  Props & RouteComponentProps<RouteParams>,
  BuildArchetypeError
> = {
  form: formName,
  initialValues: {},
  enableReinitialize: true,
  validate: (values, props) => {
    let errors = {};

    // --- Validate Tag
    const tagRegex = /^[a-zA-Z0-9\.\-_]+$/;
    const tag = values[fieldTag];
    if (!tag || tag.trim() === '') {
      errors = { ...errors, [fieldTag]: 'Please enter a tag' };
    } else if (tag.length > 128) {
      // Seems to be enforced by the go builders use as a git tag
      errors = {
        ...errors,
        [fieldTag]: 'Please enter a tag with at maximum 128 characters',
      };
    } else if (!tagRegex.test(tag)) {
      errors = { ...errors, [fieldTag]: 'Please enter a valid tag' };
    } else if (
      (props.versions || []).map((version) => version.number).includes(tag)
    ) {
      errors = {
        ...errors,
        [fieldTag]: 'Please enter a tag different from existing tags',
      };
    }

    return errors;
  },
};
