import { connect } from 'react-redux';
import { RootState } from '../../../../store/store';
import { withRouter } from 'react-router-dom';
import { fetchSchedule } from '../../../../redux/modules/orchestration.schedules.module';
import { JOB_GROUP_VARIANTS } from './JobGroupDetails';
import JobGroupDetails, { RProps } from './JobGroupDetails.container';
import { JobGroupDetailsType } from 'common/src/types/job';

export const mapStateToProps = (state: RootState, { match }: RProps) => {
  const code = match.params.jobGroupCode;
  const variant = JOB_GROUP_VARIANTS.jobSchedule;
  const schedule = state.jobSchedules.schedules[code];

  const jobGroupDetails: JobGroupDetailsType = schedule
    ? {
        name: schedule.jobGroupInput.name,
        description: schedule.jobGroupInput.description,
        jobs: schedule.jobGroupInput.jobs,
        jobGroupTopology: schedule.jobGroupInput.jobGroupTopology,
        created: schedule.created,
        createdBy: schedule.createdBy,
        schedule: {
          trigger: schedule.trigger,
          trigDelayed: schedule.trigDelayed,
          trigTimed: schedule.trigTimed,
        },
      }
    : undefined;

  return {
    jobGroupDetails: jobGroupDetails,
    jobGroupVariant: variant,
  };
};

export const mapDispatchToProps = {
  fetchData: fetchSchedule,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(JobGroupDetails));
