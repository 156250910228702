export function nextStep(formState, currentStep) {
  return goToStep(formState, {
    step: Math.min(currentStep + 1, formState.numberOfSteps - 1),
  });
}

export function goToStep(formState, { step }) {
  const visited = formState.stepsVisited;

  return {
    ...formState,
    step,
    stepsVisited: [
      ...Array(step + 1).fill(true),
      ...Array(formState.numberOfSteps - step - 1).fill(false),
    ],
  };
}

export function reset(formState, initial) {
  return {
    ...initial,
    values: {
      ...initial.values,
    },
  };
}

export function setValidity(formState, { stepsValid }) {
  return {
    ...formState,
    stepsValid,
  };
}

export function updateFormSteps(formState, { numberOfSteps, fieldNames }) {
  return {
    ...formState,
    numberOfSteps,
    fieldNames,
    step: 0,
    stepsVisited: Array(numberOfSteps).fill(false),
    stepsValid: Array(numberOfSteps).fill(false),
  };
}
