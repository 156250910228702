import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import AscDesc, {
  ASC_DESC_VALUE,
} from '../../../../../../atoms/ascDesc/AscDesc';
import { OrderByType, SortByType } from './sorting';

export type Props = {
  sort: (sortBy: SortByType, orderBy: OrderByType) => void;
  sortBy: SortByType;
  orderBy: OrderByType;
};

const Header: FC<Props> = ({ sort, sortBy, orderBy }) => {
  return (
    <div className='rejected-features-row rejected-features-row-headline'>
      <div className='rejected-features-head-cell importance'>
        <AscDesc
          value={
            sortBy === 'importance'
              ? orderBy === 'ascending'
                ? ASC_DESC_VALUE.ASC
                : ASC_DESC_VALUE.DESC
              : undefined
          }
          onChange={(value) => {
            sort(
              'importance',
              value === ASC_DESC_VALUE.ASC ? 'ascending' : 'descending'
            );
          }}
        >
          <FormattedMessage
            id='details.rejectedFeatures.importance'
            defaultMessage='Importance'
          />
        </AscDesc>
      </div>

      <div className='rejected-features-head-cell rejected'>
        <AscDesc
          value={
            sortBy === 'rejected'
              ? orderBy === 'ascending'
                ? ASC_DESC_VALUE.ASC
                : ASC_DESC_VALUE.DESC
              : undefined
          }
          onChange={(value) => {
            sort(
              'rejected',
              value === ASC_DESC_VALUE.ASC ? 'ascending' : 'descending'
            );
          }}
        >
          <FormattedMessage
            id='details.rejectedFeatures.use'
            defaultMessage='Reject/Auto/Force'
          />
        </AscDesc>
      </div>

      <div className='rejected-features-head-cell feature'>
        <AscDesc
          value={
            sortBy === 'feature'
              ? orderBy === 'ascending'
                ? ASC_DESC_VALUE.ASC
                : ASC_DESC_VALUE.DESC
              : undefined
          }
          onChange={(value) => {
            sort(
              'feature',
              value === ASC_DESC_VALUE.ASC ? 'ascending' : 'descending'
            );
          }}
        >
          <FormattedMessage
            id='details.rejectedFeatures.feature'
            defaultMessage='Feature'
          />
        </AscDesc>
      </div>
    </div>
  );
};

export default Header;
