export const initial = {
  loading: false,
  loaded: false,
  error: '',
  data: [],
};

export const test = {
  loading: false,
  loaded: false,
  error: '',
  data: [],
};
