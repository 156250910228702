import React, { FC, useEffect, useRef } from 'react';
import './contextMenu.scss';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { DeprecatedRootState } from '../../../../store/state.type';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux-act';
import { useAppDispatch } from '../../../../store/store';

export type ContextMenuProps = {
  menuId: string;
  entries: ContextMenuEntry[];
  hide: () => void;
};

export type ContextMenuEntry = {
  /** Text to show for the entry */
  title: MessageDescriptor;
  /** Icon to show for the entry */
  icon: (
    state: DeprecatedRootState
  ) => React.ComponentType<{ className: string }>;
  /** Optional: onClick handler */
  onClick?: (dispatch: Dispatch) => void;
  isDisabled?: boolean;
};

/**
 * Maybe not strictly simpler, but removes some of the close redux integration.
 * (But it also handles the clickOutside itself and adds state-dependent icons)
 *
 * @param menuId
 * @param entries
 * @param hide
 * @constructor
 */
const SimpleContextMenu: FC<ContextMenuProps> = ({ menuId, entries, hide }) => {
  const ref = useRef(null);
  useEffect(() => {
    const clickOutsideListener = (evt) => {
      if (ref.current && !ref.current.contains(evt.target)) hide();
    };
    document.addEventListener('click', clickOutsideListener);
    return () => {
      document.removeEventListener('click', clickOutsideListener);
    };
  }, []);

  const state = useSelector<DeprecatedRootState, DeprecatedRootState>(
    (state) => state
  );
  const dispatch = useAppDispatch();

  return (
    <div className={'context-menu'} id={menuId} ref={ref}>
      <div className={'arrow'} />
      {entries.map((e, i) => {
        let onClick = (evt) => {
          if (e.onClick) {
            e.onClick(dispatch);
            evt.stopPropagation(); // To not trigger the surrounding listener, which contains the button that toggles visibility
          }
        };
        if (e.isDisabled) {
          onClick = undefined;
        }
        const Icon = e.icon(state);
        return (
          <div
            key={i}
            data-testingidentifier={`cm-${e.title.defaultMessage}`}
            className={
              e.isDisabled
                ? 'cm-item-container cm-item-container--disabled'
                : 'cm-item-container'
            }
            onClick={onClick}
          >
            <Icon className={'context-menu-icon'} />
            <FormattedMessage
              id={e.title.id}
              defaultMessage={e.title.defaultMessage}
            >
              {(message) => <p className={'context-menu-text'}>{message}</p>}
            </FormattedMessage>
          </div>
        );
      })}
    </div>
  );
};

export default SimpleContextMenu;
