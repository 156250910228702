import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { PathType, PipelineTuningValue } from 'common/dist/types/pipeline';
import './styles.scss';
import ModelStep from './ModelStep';
import { convertTuningParameterOutputs } from '../../_pipeline-tuning-results-common/utils';
import { ParameterType } from 'common/dist/types/module.nbc';

type Props = {
  /** Schema of the pipeline */
  pipeline: PipelineTuningValue;
  /** Path taken through the pipeline */
  path: PathType;
  /** List of the tuning parameters used */
  parameters: ParameterType[];
  activeNodeId?: string;
};

class ModelParameters extends Component<Props & WrappedComponentProps> {
  render() {
    const { pipeline, path, activeNodeId, parameters } = this.props;

    // Create a "flat" dictionary for the nodes: nodeId -> node
    const nodesDict = {};
    pipeline.nodes.forEach((node) => {
      if (node.type === 'node') {
        nodesDict[node.id] = node;
      } else if (node.type === 'group') {
        node.nodes.forEach((innerNode) => {
          nodesDict[innerNode.id] = innerNode;
        });
      }
    });

    const tuningParameterMap = convertTuningParameterOutputs(parameters);

    return (
      <div className={'ModelParameters'}>
        {path.path.map((nodeId) => (
          <ModelStep
            node={nodesDict[nodeId]}
            isActive={nodeId === activeNodeId}
            tuningParameterMap={tuningParameterMap[nodeId]}
          />
        ))}
      </div>
    );
  }
}

export default injectIntl(ModelParameters);
