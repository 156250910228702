import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import User from '../group-details/User';
import { usersRoutes } from '../routes';
import BreadCrumbs from '../../../atoms/bread-crumbs/BreadCrumbs';
import { Group } from '../../../../store/admin/state.types';
import Paging from '../../../molecules/paging/Paging';
import { Header } from '../user-list/UsersList';
import { NamesState } from '../../../../store/names/state.types';
import { habitatFromGroupName } from 'common/dist/constants/keycloak';
import { User as UserType } from 'common/dist/types/users';
import commonMessages from 'common/dist/messages/common';
import adminMessages from 'common/dist/messages/admin';

const PAGE_SIZE = 20;

export interface Props {
  editGroup?: Group;
  viewGroup?: Group;
  users: UserType[];
  loadUsersList: (
    fetchPermissions: boolean,
    offset?: number,
    limit?: number,
    search?: string
  ) => void;
  loadGroups: (fetchPermissions: boolean) => void;
  names: NamesState;
}

const HabitatDetails: FC<Props> = ({ editGroup, viewGroup, users }) => {
  return (
    <div className={'ct-list'}>
      <div className={'ct-headline ct-row'}>
        <div className={'ct-col ct-col-200px ct-col-flex-grow'}>
          <FormattedMessage {...adminMessages.msgUsersFirstName} />
        </div>
        <div className={'ct-col ct-col-200px ct-col-flex-grow'}>
          <FormattedMessage {...adminMessages.msgUsersLastName} />
        </div>
        <div className={'ct-col ct-col-200px ct-col-flex-grow'}>
          <FormattedMessage {...adminMessages.msgUsersEmail} />
        </div>
        <div className={'ct-col ct-col-80px'}>
          <FormattedMessage {...adminMessages.msgGroupsEdit} />
        </div>
        <div className={'ct-col ct-col-80px'}>
          <FormattedMessage {...adminMessages.msgGroupsView} />
        </div>
      </div>
      {users &&
        users
          .sort((a, b) => (a.email > b.email ? 1 : -1))
          .map((user) => {
            const isMember =
              user.groups.find((g) => g.id === editGroup.id) !== undefined;
            return (
              <div>
                <User
                  {...user}
                  isMember={isMember}
                  groupId={editGroup.id}
                  key={user.id}
                  additionalGroup={{
                    isMember:
                      user.groups.find((g) => g.id === viewGroup.id) !==
                      undefined,
                    groupId: viewGroup.id,
                  }}
                />
              </div>
            );
          })}
    </div>
  );
};

const HabitatDetailsPaginated: FC<Props> = (props) => {
  const { loadUsersList, loadGroups, users, editGroup, viewGroup, names } =
    props;

  const intl = useIntl();

  useEffect(() => {
    loadGroups(false);
  }, [loadGroups]);

  const updatePagingParameters = useCallback(
    (offset?: number, limit?: number, search?: string) =>
      loadUsersList(false, offset, limit, search),
    [loadUsersList]
  );

  if (editGroup === viewGroup && viewGroup === undefined) {
    return <div />;
  }

  const habitatCode = habitatFromGroupName(editGroup.name);
  const speakingName = names.habitatNames[habitatCode];
  return (
    <div className={'AdminUserDetails'}>
      <BreadCrumbs
        backToProps={[
          {
            linkTo: `${usersRoutes.basePath}/${usersRoutes.habitats.path}`,
            label: intl.formatMessage(commonMessages.backTo, {
              origin: intl.formatMessage(adminMessages.groupsOrigin),
            }),
          },
        ]}
      />

      <div className={'AdminUserDetails--header'}>
        <div
          className={
            'AdminUserDetails--part AdminUserDetails--part-user-details'
          }
        >
          <span className={'AdminUserDetails--part-headline'}>
            Habitat Details
          </span>
          <div className={'AdminUserDetails--part-details-container'}>
            <div className={'AdminUserDetails--part-details-line'}>
              <span className={'AdminUserDetails--part-details-key'}>
                Name:&nbsp;
              </span>
              <span className={'AdminUserDetails--part-details-value'}>
                {speakingName}
              </span>
            </div>
            <div className={'AdminUserDetails--part-details-line'}>
              <span className={'AdminUserDetails--part-details-key'}>
                Habitat Code:&nbsp;
              </span>
              <span className={'AdminUserDetails--part-details-value'}>
                {habitatCode}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={'AdminUserDetails--part part-user-roles'}>
        <span className={'AdminUserDetails--part-headline'}>Members</span>
        <Paging
          itemsPerPage={PAGE_SIZE}
          updatePagingParameters={updatePagingParameters}
          Headline={Header}
          currentItems={users.length}
          searchEnabled
        >
          <HabitatDetails {...props} />
        </Paging>
      </div>
    </div>
  );
};

export default HabitatDetailsPaginated;
