import { connect, ConnectedProps } from 'react-redux';
import WithTableSample, { OwnProps } from './WithTableSample';
import { fetchDataSources } from '../../../../../../redux/modules/data.dataSources.module';
import {
  fetchCassandraTables,
  fetchCassandraTableSample,
} from '../../../../../../redux/modules/data.cassandra.module';

import { RootState } from '../../../../../../store/store';
import {
  selectDataSources,
  selectS3Tables,
  selectS3TableSample,
  selectTables,
  selectTableSample,
} from '../dataSource.selectors';
import { LoadableData } from '../../../../../../utils';
import { CassandraDataPreview } from '../../../../../../store/dataManagement/state.types';
import {
  fetchBucketContent,
  fetchPreview,
} from '../../../../../../redux/modules/data.s3.module';

function mapStateToProps(state: RootState, { datapool, tableName }: OwnProps) {
  const dataSources = selectDataSources(state);
  const datapoolDataSource = dataSources.data.find(
    (ds) => ds.code === datapool?.dataSourceCode
  );
  let tables: { name: string }[];
  let tablesLoading: boolean;
  let tablesLoaded: boolean;
  let tableSample: LoadableData<CassandraDataPreview, unknown> | undefined;
  switch (datapoolDataSource?.ds_type) {
    case 'cassandra':
      ({ tables, tablesLoading, tablesLoaded } = selectTables(
        state,
        datapool.dataSourceCode,
        datapool.code
      ));
      ({ tableSample } = selectTableSample(
        state,
        datapool.dataSourceCode,
        datapool.code,
        tableName
      ));
      break;
    case 's3':
      ({ tables, tablesLoading, tablesLoaded } = selectS3Tables(
        state,
        datapool.dataSourceCode,
        datapool.code
      ));
      ({ tableSample } = selectS3TableSample(
        state,
        datapool.dataSourceCode,
        datapool.code,
        tableName
      ));
  }

  return {
    dataSources,
    datapoolDataSource,
    tablesLoading,
    tablesLoaded,
    tables,
    tableName,
    tableSample,
  };
}

const mapDispatchToProps: {
  /** Fetch all available Data Sources */
  fetchDataSources: () => void;
  /**  Fetch all Cassandra tables for a given Keyspace */
  fetchCassandraTables: (cassandraDsCode: string, keyspace: string) => void;
  /** Fetch a sample of the selected table (only required for the column names) */
  fetchCassandraTableSample: (
    cassandraDsCode: string,
    keyspaceName: string,
    tableName: string
  ) => void;
  fetchPreview: (dataSourceCode: string, path: string) => void;
  fetchBucketContent: (
    dataSourceCode: string,
    bucket: string,
    bucketPath: string
  ) => void;
} = {
  fetchDataSources,
  fetchCassandraTables,
  fetchCassandraTableSample,
  fetchBucketContent,
  fetchPreview,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(WithTableSample);
