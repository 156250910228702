import { connect } from 'react-redux';
import EmptyRecycleBinWizard from './EmptyRecycleBinWizard';
import { emptyRecycleBin } from '../../../../../redux/workbench/modules/content.module';

export function mapStateToProps(state, ownProps) {
  return {};
}

export const mapDispatchToProps = {
  emptyRecycleBin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmptyRecycleBinWizard);
