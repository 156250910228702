import React, { Component, Fragment } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { fieldNameTargetPath } from '../_interface/input-element.form';
import TextInputCol from '../../../settings-dialog/dialog-settings-cols/TextInputCol';
import { inputModalForm } from '../../../settings-dialog/inpmodalDialog.form';

class UploadElementSettings extends Component<InjectedFormProps> {
  render() {
    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldNameTargetPath}
            label={'Subdirectory Path (optional)'}
            component={TextInputCol}
            placeholder={'Enter Subdirectory Path'}
          />
        </div>
      </Fragment>
    );
  }
}

export default reduxForm(inputModalForm)(UploadElementSettings);
