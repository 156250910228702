import React, { FC } from 'react';
// @ts-ignore
import { Motion, spring } from 'react-motion';

type Props = {
  width: number;
  height: number;
};

const Curtain: FC<Props> = (props: Props) => {
  const { width, height } = props;
  return (
    <Motion defaultStyle={{ x: 0 }} style={{ x: spring(width) }}>
      {(value: { x: number; y: number }) => (
        <rect
          width={width < value.x ? 0 : width - value.x}
          height={height}
          x={value.x}
          fill='white'
        />
      )}
    </Motion>
  );
};

export default Curtain;
