import { connect } from 'react-redux';
import Notifications from './Notifications';

export function mapStateToProps(state) {
  return {
    // notifications: state.workbench.fileUploadNotification,
    // systemNotifications: state.workbench.notifications,
    notifications: state.notifications,
  };
}

export default connect(mapStateToProps)(Notifications);
