import React, { FC } from 'react';
import style from './styles.module.scss';
import classNames from 'classnames';

type Props = {
  isListView: boolean;
  setHabitatView: (isListView: boolean) => void;
};

const ViewSwitch: FC<Props> = (props) => {
  const { isListView, setHabitatView } = props;

  return (
    <div className={style.viewSwitch}>
      <a
        className={classNames(style.viewSwitchLink, {
          [style.viewSwitchLinkActive]: !isListView,
        })}
        onClick={() => setHabitatView(false)}
      >
        <span className='icon-th' />
      </a>
      <a
        className={classNames(style.viewSwitchLink, {
          [style.viewSwitchLinkActive]: isListView,
        })}
        onClick={() => setHabitatView(true)}
      >
        <span className='icon-reorder' />
      </a>
    </div>
  );
};

export default ViewSwitch;
