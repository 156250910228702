import { createReducer } from 'redux-act';
import { reducer as contentReducer } from './content.module';
import { reducer as notebookReducer } from './notebook.module';
import { reducer as notebookSourceReducer } from './notebook.source.module';
import { reducer as notebookSocketReducer } from './notebook.websocket.module';
import { reducer as sessionReducer } from './sessions.module';
import { reducer as browserReducer } from './browser.module';
import { reducer as kernelspecsReducer } from './kernelspecs.module';
import { reducer as overlappingNotebookReducer } from './notebook.overlapping.module';
import { reducer as connectionReducer } from './connection.module';
import { reducer as archetypeReducer } from './archetype.module';
import { reducer as fileReducer } from './file.add.module';
import { reducer as collabReducer } from './collab.module';
import { reducer as collabRepositoryReducer } from './collab.repository.module';
import { reducer as cellsAppReducer } from './cells.app.module';
import { reducer as repositoryReducer } from './repository.module';
import { reducer as containerInteractionsReducer } from './container-interactions.module';
import { reducer as uploadReducer } from './upload.module';
import { reducer as appReducer } from './app.module';
import { reducer as appConnectionReducer } from './app.connection.module';
import { reducer as mergerReducer } from './merger.module';
import { reducer as codeCapsuleReducer } from './codeCapsule.module';
import { reducer as diskUsageReducer } from './diskUsage.module';
import { reducer as notebookFocusReducer } from './notebook.focus.module';
import { reducer as directoryReducer } from './directory.module';
import { initialState } from '../state/initial';

export default createReducer(
  {
    ...contentReducer,
    ...notebookReducer,
    ...notebookFocusReducer,
    ...notebookSourceReducer,
    ...notebookSocketReducer,
    ...sessionReducer,
    ...browserReducer,
    ...kernelspecsReducer,
    ...overlappingNotebookReducer,
    ...connectionReducer,
    ...archetypeReducer,
    ...fileReducer,
    ...collabReducer,
    ...collabRepositoryReducer,
    ...cellsAppReducer,
    ...repositoryReducer,
    ...containerInteractionsReducer,
    ...uploadReducer,
    ...appReducer,
    ...codeCapsuleReducer,
    ...appConnectionReducer,
    ...mergerReducer,
    ...diskUsageReducer,
    ...directoryReducer,
  },
  // Seems to be ignored anyway since state.workbench is filled through preloadedState (?), but some automatic type hints are derived from this
  initialState
);
