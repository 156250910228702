import { connect } from 'react-redux';

import CodeCapsules from './CodeCapsules';
import * as dashboardSelector from '../../../../redux/selectors/dashboard.selector';
import { codeCapsulesSafe } from './utils';
import { triggerRunCodeCapsuleJob } from '../../../../redux/modules/triggerJobs.module';
import { RootState } from '../../../../store/store';

type OwnProps = {
  habitatCode: string;
};

export function mapStateToProps(state: RootState, { habitatCode }: OwnProps) {
  const isListView = dashboardSelector.isListView(state, habitatCode);
  return {
    habitatCode,
    isListView,
    codeCapsules: codeCapsulesSafe(state, habitatCode),
  };
}

export const mapDispatchToProps = {
  triggerRunCodeCapsuleJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeCapsules);
