import React, { FC } from 'react';
import styles from '../styles.module.scss';
import { FormattedMessage } from 'react-intl';

type Props = {};

export const OverlayMaintenance: FC<Props> = (props: Props) => {
  return (
    <div className={styles.subheaderLeft}>
      <div className={styles.subheaderSimpleHeadlineContainer}>
        <div className={styles.subheaderSimpleHeadline}>
          <FormattedMessage id='no-id' defaultMessage='Maintenance' />
        </div>
      </div>
    </div>
  );
};

export default OverlayMaintenance;
