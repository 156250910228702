import React, { FC } from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import Busy from '../../atoms/busy/Busy';

type DropZoneProps = {
  /** Is the image being fetched? */
  loading: boolean;
  /** Was the image fetched? */
  loaded: boolean;
  /** Fetched image */
  data: {
    imageType: string;
    imageData: string;
  };
  /** Error while fetching the image? */
  error?: string;
  /** Delete the users avatar */
  deleteAvatar: (...args: unknown[]) => void;

  /** Is the dropped file too large? */
  isFileTooLarge?: boolean;

  /** Is the image being uploaded? */
  uploading: boolean;
  /** Upload progress */
  progress?: number;
};

const DropZoneBody: FC<DropZoneProps & WrappedComponentProps> = ({
  loading,
  loaded,
  data,
  error,
  deleteAvatar,
  isFileTooLarge,
  uploading,
  progress,
}) => {
  if (uploading) {
    return (
      <div>
        <span>Uploading {progress}%</span>
      </div>
    );
  }

  if (loading) {
    return <Busy isBusy />;
  }

  if (isFileTooLarge) {
    return (
      <div>
        <span style={{ color: 'red' }}>
          File too large. Max allowed size: 1MB.
        </span>
      </div>
    );
  }

  if (data) {
    const avatarSrc = `data:${data.imageType};base64, ${data.imageData}`;

    return (
      <div className='AccountAddPhoto--block-in'>
        <img src={avatarSrc} alt='avatar' />
        <span
          className={'fa fa-times'}
          onClick={(e) => {
            e.preventDefault();
            deleteAvatar();
          }}
        />
        <span className={'fa fa-pencil'} />
      </div>
    );
  }

  return (
    <div>
      <span className='icon-plus' />
      <div>
        <FormattedMessage
          id='account.info.add_account_photo'
          defaultMessage='Add Account Photo'
        />
      </div>
    </div>
  );
};

export default injectIntl(DropZoneBody);
