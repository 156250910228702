import React, { FC } from 'react';
import { line } from 'd3-shape';

import { getX, getY } from './util';
import { ScaleLinear } from 'd3-scale';

type Props = {
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  helpLine: [[number, number], [number, number]];
  color: string;
};

const HelpLine: FC<Props> = (props) => {
  const { xScale, yScale, helpLine, color } = props;
  const strokeLine = line()
    .x((d) => xScale(getX(d)))
    .y((d) => yScale(getY(d)));
  const strokeLinePath = strokeLine(helpLine);

  return (
    <path d={strokeLinePath} stroke={color} fill='none' strokeWidth='1.5' />
  );
};

export default HelpLine;
