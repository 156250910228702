import { validateDictionary } from '../../../../../implementations/natural-language-tagging/new-augur/naturalLanguageTagging.form';

export const formName = 'augur-settings-dictionary';

export const fieldDictionary = 'dictionary';

export const form = {
  form: formName,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: (values) => {
    const errors = {};
    errors[fieldDictionary] = validateDictionary(values[fieldDictionary]);
    return errors;
  },
};
