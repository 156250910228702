import ModelSettingsCategory from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { MessageDescriptor } from 'react-intl';
import React from 'react';
import { FiShuffle } from 'react-icons/fi';
import CandidateConditionsCategory from './CandidateConditionsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

export default class CandidateConditionsCategoryWrapper
  implements ModelSettingsCategory<AugurSettingsDataNbc>
{
  component = CandidateConditionsCategory;
  icon: React.ComponentType = () => <FiShuffle size={17} />;
  id = 'candidate-conditions';
  label: MessageDescriptor = {
    id: 'no-id',
    defaultMessage: 'Candidate Conditions',
  };
}
