import React, { FC } from 'react';
import LoadError from '../LoadError';
import SessionsList from './SessionsList';
import { Session } from '../../../../store/workbench/state.types';
import IconButtonBar from '../../../organisms/workbench-browser/icon-button-bar/IconButtonBar';
import {
  ShutdownSessions,
  ShutdownWorkbench,
} from '../../../organisms/workbench-browser/icon-button-bar/IconButtonBar.defaults';
import { FormattedMessage } from 'react-intl';
import msgsWorkbench from 'common/dist/messages/workbench';
import styles from './styles.module.scss';

export type Props = {
  data: Session[];
  error: Record<string, unknown>;
  deleteSession(sessionId: string, sessionName: string): void;
};

const Sessions: FC<Props> = ({ data, error }) => {
  return (
    <div className={styles.contentContainer}>
      <IconButtonBar buttons={buttonBarButtons} />
      {error && <LoadError message={error.message} />}
      {data && data.length > 0 && <SessionsList data={data} />}
      {(!data || data.length === 0) && (
        <div className={styles.emptyList}>
          <FormattedMessage {...msgsWorkbench.workbenchSessionsEmpty} />
        </div>
      )}
    </div>
  );
};

export const buttonBarButtons = [ShutdownSessions, ShutdownWorkbench];

export default Sessions;
