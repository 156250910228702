/*
 *
 * This component is done based on Link (react-router)
 * https://github.com/reactjs/react-router/blob/master/modules/Link.js
 * The difference is that it changes browser location instead of router push
 *
 */

import React, { Component } from 'react';
import { ToBeRefined } from 'common/dist/types/todo_type';

type Props = {
  link: string | object;
  style: object;
  className: string;
  onClick: (e: ToBeRefined) => void;
  target?: string;
  activeClassName: string;
  activeStyle: ToBeRefined;
};

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export default class GoTo extends Component<Props, {}> {
  static defaultProps = {
    style: {},
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (event.defaultPrevented) {
      return;
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    // If target prop is set (e.g. link "_blank"), let browser handle link.
    if (this.props.target) {
      return;
    }

    event.preventDefault();

    location.assign(this.props.link as string);
  }

  render() {
    const { link, activeClassName, activeStyle, ...props } = this.props;

    // @ts-ignore - TODO whats going on here?
    props.href = link;

    if (activeClassName || activeStyle) {
      if (activeClassName) {
        if (props.className) {
          props.className += ` ${activeClassName}`;
        } else {
          props.className = activeClassName;
        }
      }

      if (activeStyle) {
        props.style = { ...props.style, ...activeStyle };
      }
    }

    return <a {...props} onClick={this.handleClick} />;
  }
}
