import { connect } from 'react-redux';
import Link from './Link';

export function isLinkHighlighted(path, link) {
  return !path.find(({ id }) => link.target.data.id === id);
}

export function mapStateToProps(state, { link }) {
  const path =
    (state.chart.active.tree.preview.path.length &&
      state.chart.active.tree.preview.path) ||
    state.chart.active.tree.selected.path;

  return {
    isHighLighted: isLinkHighlighted(path, link),
    totalRecordCount: +state.chart.tree.root.recordCount,
  };
}

export default connect(mapStateToProps)(Link);
