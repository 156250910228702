import { ComponentAugurDetailsTab } from '../../_interfaces/augur-details/AugurDetailsTab';
import React, { ComponentType, PropsWithChildren } from 'react';
import { FiSliders } from 'react-icons/fi';
import { MessageDescriptor } from 'react-intl';
import ModelSettingsCategory, {
  ModelSettingsProps,
} from '../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import ModelSettings from './ModelSettings.container';
import { Props as CategoryProps } from './ModelSettings';

export default class ModelTuningResultsWrapper extends ComponentAugurDetailsTab {
  /**
   * @param settingsCategories Settings categories that are supposed to be displayed in the settings screen
   */
  constructor(settingsCategories: ModelSettingsCategory[]) {
    super();
    this.component = this.wrapModelSettingsWithCategories(
      ModelSettings,
      settingsCategories
    );
  }

  /**
   * HOC that wraps the ModelSettings component with the modelSettingsCategories
   * @param ModelSettings
   * @param modelSettingsCategories
   */
  wrapModelSettingsWithCategories(
    ModelSettings: ComponentType<CategoryProps & ModelSettingsProps>,
    modelSettingsCategories: ModelSettingsCategory[]
  ): ComponentType<ModelSettingsProps & CategoryProps> {
    return (props: PropsWithChildren<ModelSettingsProps>) => (
      <ModelSettings
        modelSettingsCategories={modelSettingsCategories}
        {...props}
      />
    );
  }

  component: ComponentType<ModelSettingsProps>; // Filled in the constructor with the enhanced version of the Settings Component
  icon: ComponentType = () => <FiSliders size={17} />;
  id = 'model-settings';

  to = (habitatCode: string, augurCode: string, shortAugurType: string) =>
    `/app/habitat/${habitatCode}/augur/${augurCode}/${shortAugurType}/model-settings`;
  title: MessageDescriptor = {
    id: 'details.sideNav.model_settings',
    defaultMessage: 'Model Settings',
  };
  subRoutes: object[] | undefined = undefined;
}
