import { apiRequest, CompletedApiRequest, fetchQueryFn } from './_tools';
import {
  EvaluationReportType,
  PredictionReportType,
  ReportType,
} from 'common/dist/types/reports';
import qs from 'qs';
import { useQuery, UseQueryResult } from 'react-query';

export const reportKeys = {
  all: () => ['reports'] as const,
  some: (habitatCode: string, augurCode: string) =>
    [...reportKeys.all(), habitatCode, augurCode] as const,
  report: (habitatCode: string, augurCode: string, reportCode: string) =>
    [...reportKeys.some(habitatCode, augurCode), reportCode] as const,
  reports: (
    habitatCode: string,
    augurCode: string,
    modelCode: string,
    type?: 'evaluation' | 'prediction',
    offset?: number,
    limit?: number
  ) =>
    [
      ...reportKeys.some(habitatCode, augurCode),
      modelCode,
      type,
      offset,
      limit,
    ] as const,
  reportsByCode: (
    habitatCode: string,
    augurCode: string,
    reportCodes: string[]
  ) => [...reportKeys.some(habitatCode, augurCode), reportCodes] as const,
  latest: (
    habitatCode: string,
    augurCode: string,
    modelCode: string,
    type?: 'evaluation' | 'prediction'
  ) =>
    [
      ...reportKeys.some(habitatCode, augurCode),
      'latest',
      modelCode,
      type,
    ] as const,
};

export const getReports = (
  habitatCode: string,
  augurCode: string,
  modelCode?: string,
  type?: 'evaluation' | 'prediction',
  offset?: number,
  limit?: number
): CompletedApiRequest<ReportType[]> => {
  const query = qs.stringify(
    { modelCode, type, offset, limit },
    { addQueryPrefix: true }
  );
  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/reports${query}`
  );
};

export const useReports = (
  habitatCode: string,
  augurCode: string,
  modelCode?: string,
  type?: 'evaluation' | 'prediction',
  offset?: number,
  limit?: number
): UseQueryResult<ReportType[]> => {
  const key = reportKeys.reports(
    habitatCode,
    augurCode,
    modelCode,
    type,
    offset,
    limit
  );
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () =>
        getReports(habitatCode, augurCode, modelCode, type, offset, limit)
      ),
    {
      keepPreviousData: true,
    }
  );
};

export const getReportsByCode = (
  habitatCode: string,
  augurCode: string,
  reportCodes?: string[]
): CompletedApiRequest<ReportType[]> => {
  const query = qs.stringify(
    { reportCodes },
    { addQueryPrefix: true, arrayFormat: 'comma' }
  );
  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/reports${query}`
  );
};

export const useReportsByCode = (
  habitatCode: string,
  augurCode: string,
  reportCodes: string[]
): UseQueryResult<ReportType[]> => {
  const key = reportKeys.reportsByCode(habitatCode, augurCode, reportCodes);
  return useQuery(key, () =>
    fetchQueryFn(key, () =>
      getReportsByCode(habitatCode, augurCode, reportCodes)
    )
  );
};

export const getReport = (
  habitatCode: string,
  augurCode: string,
  reportCode: string
): CompletedApiRequest<ReportType> =>
  apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/reports/${reportCode}`
  );

export const useReport = (
  habitatCode: string,
  augurCode: string,
  reportCode: string
): UseQueryResult<ReportType> => {
  const key = reportKeys.report(habitatCode, augurCode, reportCode);
  return useQuery(key, () =>
    fetchQueryFn(key, () => getReport(habitatCode, augurCode, reportCode))
  );
};

export const getLatestReport = (
  habitatCode: string,
  augurCode: string,
  modelCode: string,
  type?: 'evaluation' | 'prediction'
): CompletedApiRequest<ReportType> => {
  const query = qs.stringify({ modelCode, type }, { addQueryPrefix: true });
  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/reports/latest${query}`
  );
};

export const useLatestReport = (
  habitatCode: string,
  augurCode: string,
  modelCode = 'active',
  type?: 'evaluation' | 'prediction'
): UseQueryResult<ReportType> => {
  const key = reportKeys.latest(habitatCode, augurCode, modelCode, type);
  return useQuery(key, () =>
    fetchQueryFn(key, () =>
      getLatestReport(habitatCode, augurCode, modelCode, type)
    )
  );
};

export const useLatestEvaluationReport = (
  habitatCode: string,
  augurCode: string,
  modelCode?: string
): UseQueryResult<EvaluationReportType> =>
  useLatestReport(
    habitatCode,
    augurCode,
    modelCode,
    'evaluation'
  ) as UseQueryResult<EvaluationReportType>;

export const useLatestPredictionReport = (
  habitatCode: string,
  augurCode: string,
  modelCode?: string
): UseQueryResult<PredictionReportType> =>
  useLatestReport(
    habitatCode,
    augurCode,
    modelCode,
    'prediction'
  ) as UseQueryResult<PredictionReportType>;
