import React, { FC } from 'react';
import LineChart from '../../../../../../../components/molecules/charts/line-chart/LineChart';
import augurDetailsMsgs from 'common/dist/messages/augurs.details';

type Props = {
  detailsLink: string;
  data: [number, number][];
  baseLine: number;
};

const CumulativeResponse: FC<Props> = (props: Props) => {
  const { detailsLink, data, baseLine } = props;
  return (
    <div className={'Accuracy--chart-container'}>
      <LineChart
        height={'100%'}
        width={'100%'}
        data={data}
        baseLine={baseLine}
        title={{
          id: 'details.accuracy.cumulative_response',
          defaultMessage: 'Cumulative Response',
        }}
        yLabel={{
          id: 'TODO',
          defaultMessage: 'y-Axis',
        }}
        xLabel={augurDetailsMsgs.msgPercentageCustomersContacted}
        // @ts-ignore
        link={{
          to: detailsLink,
        }}
        withBorder={true}
      />
    </div>
  );
};

export default CumulativeResponse;
