import React, { Component, ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

export type Props = {
  /** CSS class name of the image to use. The image must be registered at client/scss/partials/images.scss */
  classNameImage: string;

  /** Intl ID for the headline text */
  headlineId?: string;
  /** Default message for the headline */
  headlineDefault: string;

  /** Intl ID for the description text */
  descriptionId?: string;
  /** Default message for the description */
  descriptionDefault?: string;

  /** Optional component of actions (for example "Create Habitat now!" button) */
  Actions?: ComponentType;
};

class IndicatorEmpty extends Component<Props> {
  render() {
    const {
      classNameImage,
      headlineId,
      headlineDefault,
      descriptionId,
      descriptionDefault,
      Actions,
    } = this.props;
    return (
      <div className={'IndicatorEmpty'}>
        <div className={'IndicatorEmpty--picture ' + classNameImage}>
          &nbsp;
        </div>
        <FormattedMessage
          id={headlineId || 'no-id'}
          defaultMessage={headlineDefault}
        >
          {(txt) => <span className={'IndicatorEmpty--headline'}>{txt}</span>}
        </FormattedMessage>
        {(descriptionId || descriptionDefault) && (
          <FormattedMessage
            id={descriptionId || 'no-id'}
            defaultMessage={descriptionDefault}
          >
            {(txt) => (
              <span className={'IndicatorEmpty--description'}>{txt}</span>
            )}
          </FormattedMessage>
        )}

        {Actions && (
          <div className={'IndicatorEmpty--actions'}>
            <Actions />
          </div>
        )}
      </div>
    );
  }
}

export default IndicatorEmpty;
