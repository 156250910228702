import React, { Component, Fragment, ReactNode } from 'react';
import './styles.scss';
import { FormattedMessage } from 'react-intl';
import { MessageDescriptor } from 'react-intl';
import SettingsBlockElement from './SettingsBlockElement';

export type SettingsElementType = {
  /** Title of the settings element (put in blue on the right) */
  title: MessageDescriptor;
  /** Optional description of the settings element (put below the title on the right) */
  description?: MessageDescriptor;
  /** Optional component that is rendered below the title and the description (for example to display a list / small info button / ...) */
  DescriptionComponent?: ReactNode;
  /** React component for the settings element, for example: Component={<div/>} */
  Component: ReactNode;
};

export type Props = {
  /** Optional headline above the block */
  title?: MessageDescriptor;
  /** Background color of the settings block */
  color: 'white' | 'grey';
  /** List of the settings elements of this settings block */
  settingsElements: SettingsElementType[];
};

/**
 * Component that renders a white or grey settings block with an optional title above it.
 * Consists of several settings elements (each split by a horizontal line) with a title, description and a custom
 * component each. For example used for the "Augur Settings"
 */
export class SettingsBlock extends Component<Props> {
  render() {
    const { title, color, settingsElements } = this.props;

    const blockClassName = `SettingsBlock--block SettingsBlock--block-${color}`;

    return (
      <div className={'SettingsBlock'}>
        {title && (
          <div className='SettingsBlock--title'>
            <FormattedMessage
              id={title.id || 'no-id'}
              defaultMessage={title.defaultMessage}
            />
          </div>
        )}

        <div className={blockClassName}>
          {settingsElements &&
            settingsElements.map((element, i) => (
              <Fragment>
                <SettingsBlockElement
                  key={`se_${i}`}
                  title={element.title}
                  description={element.description}
                  Component={element.Component}
                  DescriptionComponent={element.DescriptionComponent}
                />
                {i < settingsElements.length - 1 && (
                  <hr className={'SettingsBlock--hr'} />
                )}
              </Fragment>
            ))}
        </div>
      </div>
    );
  }
}
