import { connect } from 'react-redux';
import StepBucket from './StepBucket';
import {
  fetchBuckets,
  fetchS3Permissions,
} from '../../../../../../redux/modules/data.s3.module';
import { S3_BUCKET_SCOPE_IDS } from 'common/dist/constants/keycloak';

export function mapStateToProps(state, { dataSourceCode }) {
  const { loading, loaded, error, data } =
    (state.data.s3[dataSourceCode] || {}).buckets || {};
  const permissions = state.data.s3[dataSourceCode]?.permissions || {};
  const availableBuckets = data?.filter((k) =>
    new Set(permissions.data?.[k.name]).has(S3_BUCKET_SCOPE_IDS.WRITE)
  );

  return {
    availableBuckets,
  };
}

export const mapDispatchToProps = {
  fetchBuckets,
  fetchS3Permissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepBucket);
