import React, { FC, useEffect } from 'react';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import { Group } from '../../../../store/admin/state.types';
import _ from 'lodash';
import { NamesState } from '../../../../store/names/state.types';
import { habitatFromGroupName } from 'common/dist/constants/keycloak';
import { LinkWithQuery } from '../../../atoms/link-with-query/LinkWithQuery';
import styles from '../styles.module.scss';
import adminMsgs from 'common/dist/messages/admin';
import Button from '../../../atoms/button/Button';
import { useIntl } from 'react-intl';

export const adminAddHabitatsLink = `${routes.app.prefix}${routes.app.models}${routes.app.newHabitat}`;
export const adminHabitatDetailsLink = (groupId: string): string =>
  `${usersRoutes.basePath}/${usersRoutes.habitats.path}/${groupId}`;

export interface Props {
  loadGroups: (fetchPermissions: boolean) => void;
  fetchNamesForAdminPermission: () => void;
  data: Group[];
  loading: boolean;
  loaded: boolean;
  error?: string;
  names: NamesState;
}

const Habitats: FC<Props> = ({
  loadGroups,
  fetchNamesForAdminPermission,
  data,
  names,
}) => {
  const intl = useIntl();

  useEffect(() => {
    loadGroups(false);
    fetchNamesForAdminPermission();
  }, [loadGroups, fetchNamesForAdminPermission]);

  return (
    <div>
      <div className={styles.topButtonBar}>
        <Button
          buttonColor={'secondary'}
          withLink={true}
          buttonLabelDefault={intl.formatMessage(adminMsgs.msgAddHabitat)}
          linkTo={adminAddHabitatsLink}
        />
      </div>

      <div className={styles.listContainer}>
        <div className={'ct-list'}>
          <div className={'ct-headline ct-row'}>
            <div className={'ct-col ct-col-200px'}>
              <span>Name</span>
            </div>
          </div>
          {data &&
            Object.entries(
              _.groupBy(
                data
                  .filter((g) => _.isEqual(g.attributes.isHabitat, ['true']))
                  .map((g) => ({
                    ...g,
                    habitatCode: habitatFromGroupName(g.name),
                  })),
                'habitatCode'
              )
            )
              .map(([_, groups]) =>
                groups.map((group) => ({
                  ...group,
                  speakingName:
                    names.habitatNames[habitatFromGroupName(group.name)],
                }))
              )
              .sort((a, b) => (a[0].speakingName > b[0].speakingName ? 1 : -1))
              .map((groups) => {
                return (
                  <LinkWithQuery
                    to={adminHabitatDetailsLink(
                      `${
                        groups.find((g) =>
                          _.isEqual(g.attributes.permissions, ['edit'])
                        )?.id
                      }&${
                        groups.find((g) =>
                          _.isEqual(g.attributes.permissions, ['view'])
                        )?.id
                      }`
                    )}
                    style={{ textDecoration: 'none' }}
                  >
                    <div className={'ct-row'}>
                      <div className={'ct-col ct-col-200px'}>
                        <span>{groups[0].speakingName}</span>
                      </div>
                    </div>
                  </LinkWithQuery>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default Habitats;
