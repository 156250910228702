import React, { PropsWithChildren, ReactElement, useState } from 'react';

export type Props<T> = {
  data?: T[];
  title: string;
  initialCollapsed: boolean;
  renderHeader: (title: string, collapsed: boolean) => ReactElement;
  renderBody: (data: T[]) => ReactElement;
  onToggle?: (isCollapsed: boolean) => void;
};

const GenericCollapsibleContainer = <T extends unknown>(
  props: PropsWithChildren<Props<T>>
): ReactElement<Props<T>> => {
  const { data, title, initialCollapsed, renderHeader, renderBody, onToggle } =
    props;
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  return (
    <>
      <div
        onClick={() => {
          setCollapsed(!collapsed);
          onToggle && onToggle(!collapsed);
        }}
      >
        {renderHeader(title, collapsed)}
      </div>
      {!collapsed && renderBody(data)}
    </>
  );
};

export default GenericCollapsibleContainer;
