import { connect } from 'react-redux';

import Tooltip from './Tooltip';

export function mapStateToProps(
  state,
  { top, left, height, width, containerHeight }
) {
  return {
    isTooltip: state.misclassification.isTooltip,
    tooltipX: state.misclassification.tooltipX,
    tooltipY: state.misclassification.tooltipY,
    tooltipData: state.misclassification.tooltipData,
    top,
    left,
    containerHeight,
    height,
    width,
  };
}

export default connect(mapStateToProps)(Tooltip);
