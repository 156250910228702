import React, { FC } from 'react';
import { scaleLinear } from 'd3-scale';
import { pack } from 'd3-hierarchy';

import DataSeries from './DataSeries';
import { useThemeColor } from '../../../../utils';
import { interpolateRgb } from 'd3-interpolate';

export type Props = {
  data: {
    feature: string;
    importance: number;
  }[];
  width?: number;
  className?: string;
  dataSeriesClassName?: string;
  bubbleClassName?: string;
  padding?: number;
  colors?: string[];
  maxNodes?: number;
  labelKey?: string;
  valueKey?: string;
};

const BubbleChart: FC<Props> = ({
  data,
  width = 800,
  className = 'bubble-chart',
  dataSeriesClassName = 'bubble-chart-data-series',
  bubbleClassName = 'bubble-chart-bubble',
  padding = 15,
  colors,
  maxNodes = 25,
  labelKey = 'label',
  valueKey = 'value',
}) => {
  const color0 = useThemeColor('primary-highlight', '-lighter90');
  const color1 = useThemeColor('primary-highlight', '-lighter60');
  if (!colors) {
    colors = [color0, color1];
  }

  const normalizedData = data
    .filter((e) => e.importance > 0)
    .map((element) => ({
      label: element[labelKey],
      value: element[valueKey],
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, maxNodes);

  const radius = scaleLinear().range([width / 20, width / 15]);

  const bubbles = pack().size([width, width]).padding(padding);

  const color = scaleLinear()
    // @ts-ignore
    .interpolate(interpolateRgb)
    // @ts-ignore
    .range([colors[0], colors[1]]);

  return (
    <div className={className}>
      <svg width={width} height={width}>
        <DataSeries
          className={dataSeriesClassName}
          bubbleClassName={bubbleClassName}
          data={normalizedData}
          bubbles={bubbles}
          color={color}
          radius={radius}
        />
      </svg>
    </div>
  );
};

export default BubbleChart;
