import React from 'react';
import { CardOrButtonType } from '../../../organisms/element-card-grid/ElementCardGrid';
import {
  getDatapoolDetailsLink,
  getNewDatapoolLink,
} from '../../../index/routes';
import Button from '../../../atoms/button/Button';
import { DatapoolType } from 'common/dist/types/datapool';
import { getModuleName } from '../../../modelManagement/modules/ModuleManagerClient';
import scssColors from '../../../../../scss/base/var.module.scss';

export function deriveElements(
  datapools: DatapoolType[],
  habitatCode
): CardOrButtonType[] {
  const elements: CardOrButtonType[] = datapools.map((datapool) => {
    const datapoolDetailsLink = getDatapoolDetailsLink({
      datapoolCode: datapool.code,
      habitatCode,
      moduleType: datapool.moduleType,
      tabId: undefined,
      // tabId: 'settings',
    });

    return {
      type: 'card',
      linkTo: datapoolDetailsLink,
      header: datapool.name,
      subHeader: getModuleName(datapool.moduleType),
      info: `Code: ${datapool.code}`,
      barColor: scssColors.colorTextLight,
      icons: [
        {
          Icon: () => (
            <div
              className={'icon-settings'}
              style={{
                color: scssColors.colorTextLight,
                fontSize: '17px',
              }}
            />
          ),
          linkTo: getDatapoolDetailsLink({
            datapoolCode: datapool.code,
            habitatCode,
            moduleType: datapool.moduleType,
            tabId: 'settings',
          }),
          tooltip: {
            id: 'common.settings',
            defaultMessage: 'Settings',
          },
        },
      ],
    };
  });

  // 'Add Datapool' button
  elements.push({
    type: 'button',
    Button: () => (
      <Button
        buttonLabelDefault={'New Datapool'}
        buttonColor={'secondary'}
        withLink={false}
        Icon={() => (
          <span
            style={{
              fontSize: '18px',
              margin: '-3px 0px -2px -10px',
              opacity: 0.5,
            }}
            className={'icon-plus'}
          />
        )}
      />
    ),
    linkTo: getNewDatapoolLink(habitatCode),
  });

  return elements;
}
