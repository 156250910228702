import { connect } from 'react-redux';
import Archetypes from './Archetypes';
import {
  fetchArchetypes,
  expandArchetype,
  collapseArchetype,
} from '../../../../../../redux/modules/augur.archetypes.module';
import { fetchArchetypeVersions } from '../../../../../../redux/modules/archetypeVersions.module';

export function mapStateToProps(state) {
  return {
    archetypes: state.archetypes.data,
    archetypesLoading: state.archetypes.loading,
    archetypesLoaded: state.archetypes.loaded,
    archetypesError: state.archetypes.error,
    archetypeVersions: state.archetypeVersions,
    archetypesExpanded: state.archetypes.expanded,
  };
}

export const mapDispatchToProps = {
  fetchArchetypes,
  fetchArchetypeVersions,
  expandArchetype,
  collapseArchetype,
};

export default connect(mapStateToProps, mapDispatchToProps)(Archetypes);
