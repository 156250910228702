import { select } from 'd3-selection';

const MARGIN = {
  top: 10,
  right: 200,
  bottom: 100,
  left: 10,
};
const AXIS_SIZE = 25;
const PADDING = 20;

export default function append(node, width, height) {
  const chartWidth = width - MARGIN.left - MARGIN.right;
  const chartHeight = height - MARGIN.top - MARGIN.bottom;

  const areaWidth = chartWidth - AXIS_SIZE - PADDING;
  const areaHeight = chartHeight - AXIS_SIZE - PADDING;

  const svg = select(node)
    .append('svg')
    .attr('width', chartWidth)
    .attr('height', chartHeight);

  const g = svg.append('g');
  g.attr('transform', `translate(${AXIS_SIZE + MARGIN.left}, ${MARGIN.top})`);

  return { g, chartHeight, chartWidth, areaWidth, areaHeight };
}
