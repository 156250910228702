import { connect } from 'react-redux';
import EditorParent from './EditorParent';
import { resizeSplitPane } from '../../../../../redux/workbench/modules/notebook.module';

export function mapStateToProps(state) {
  return {
    paneHierarchy: state.workbench.paneHierarchy,
    panes: state.workbench.panes,
  };
}

export const mapDispatchToProps = {
  resizeSplitPane,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorParent);
