import { createAction } from 'redux-act';
import memoize from 'lru-memoize';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as Api from '../../core/api';

export const apiSearch = memoize(10)(Api.dashboard.search);

export const search = createAction('search', (query) => query);

export const searchSuccess = createAction(
  'search - success',
  (result) => result
);

export const searchFail = createAction('search - fail', (error) => error);

export const reducer = {
  [search]: (state, query) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      search: {
        ...state.dashboard.search,
        query,
        loading: true,
        loaded: false,
      },
    },
  }),
  [searchSuccess]: (
    state,
    { habitats, datapools, augurs, codeCapsules, apps }
  ) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      search: {
        ...state.dashboard.search,
        loading: false,
        loaded: true,
        data: { habitats, datapools, augurs, codeCapsules, apps },
      },
    },
  }),
  [searchFail]: (state, error) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      search: {
        ...state.dashboard.search,
        loading: false,
        loaded: false,
        error,
      },
    },
  }),
};

export function* performSearch({ payload: query }) {
  const { response, error } = yield call(apiSearch, query);
  if (response) {
    yield put(searchSuccess(response));
  } else {
    yield put(searchFail(error.toString()));
  }
}

export function* watchSearch() {
  yield takeEvery(search.getType(), performSearch);
}
