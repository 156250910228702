import { ApiError } from 'common/dist/types/responseBodies/errors';
import { ToBeRefined } from 'common/dist/types/todo_type';
import {
  createAsyncThunk,
  createSlice,
  miniSerializeError,
} from '@reduxjs/toolkit';
import { DeprecatedRootState } from '../state.type';
import {
  fetchCassandraTables,
  fetchDataSources,
  fetchS3BucketContent,
} from '../../core/api/data';
import { fetchDataSourcesSuccess } from '../../redux/modules/data.dataSources.module';
import {
  objectIsTable,
  s3ObjectToTableObject,
} from '../../components/dataManagement/util';
import { S3Object } from '../dataManagement/state.types';
import { buildDatapoolString } from 'common/dist/utils/nameUtils';

export type AvailableTablesData = { name: string }[];

export type AvailableTables = {
  loading?: boolean;
  loaded?: boolean;
  error?: ApiError;
  data?: AvailableTablesData;
};

export type TableColumns = {
  loading?: boolean;
  loaded?: boolean;
  error: ApiError;
  data: ToBeRefined[]; // Column specs
};

export type DataSamples = {
  loading?: boolean;
  loaded?: boolean;
  error: ApiError;
  data: ToBeRefined[]; // Column specs
};

export type DatapoolTablesState = {
  byDatapoolCode: {
    [datapoolCode: string]: {
      availableTables: AvailableTables;

      columns: {
        byTableName: {
          [tableName: string]: TableColumns;
        };
      };

      dataSamples: {
        byTableName: {
          [tableName: string]: DataSamples;
        };
      };
    };
  };
};

/**
 * Fetches the datapool tables for a given datapoolCode.
 * This thunk also takes care of fetching the data source code if it's not already in the state and so on
 */
export const fetchDatapoolTables = createAsyncThunk<
  { data: AvailableTablesData },
  { datapoolCode: string; dataSourceCode: string },
  { rejectValue: { error: ApiError } }
>('fetchDatapoolTables', async ({ datapoolCode, dataSourceCode }, thunkAPI) => {
  const state = thunkAPI.getState() as DeprecatedRootState;

  // 1. Get the Default Cassandra Code: Check whether the data sources are there
  let dataSource = state.data.dataSources.data.find(
    (ds) => ds.code === dataSourceCode
  );

  // 1.1 The Data Sources are not loaded yet (or there is no default cassandra): Try whether (re-)loading the Data Sources
  //   fixes the problem
  if (!dataSource) {
    const { response: dsResponse, error: dsError } = await fetchDataSources();
    if (dsResponse) {
      thunkAPI.dispatch(fetchDataSourcesSuccess(dsResponse));
      dataSource = dsResponse.find((ds) => ds.code === dataSourceCode);
      if (!dataSource) {
        // TODO do we try to respond with the same error as the backend?
        return thunkAPI.rejectWithValue({
          error: {
            // @ts-ignore
            status: 'error',
            message:
              "No Cassandra database with role 'default_cassandra' found.",
          },
        });
      }
    } else {
      // TODO is the error returned directly or wrapped inside another object { error: dsError }?
      // @ts-ignore
      return thunkAPI.rejectWithValue(dsError);
    }
  }

  // 2. Load the tables for the given Datapool Code from the default Cassandra
  let tableResponse: AvailableTablesData;
  let tableError;
  switch (dataSource.ds_type) {
    case 'cassandra': {
      const keyspaceName = buildDatapoolString(datapoolCode, 'cassandra');
      ({ response: tableResponse, error: tableError } =
        await fetchCassandraTables(dataSourceCode, keyspaceName));
      break;
    }
    case 's3': {
      const bucketName = buildDatapoolString(datapoolCode, 's3');
      let bucketContentResponse: S3Object[];
      ({ response: bucketContentResponse, error: tableError } =
        await fetchS3BucketContent(dataSourceCode, bucketName, ''));
      tableResponse = bucketContentResponse
        .filter(objectIsTable)
        .map(s3ObjectToTableObject);
    }
  }
  if (tableResponse) {
    return { data: tableResponse };
  } else {
    return thunkAPI.rejectWithValue(tableError);
  }
});

// TODO fetchDatapoolTableColumns like fetchDatapoolTables
// TODO fetchDatapoolTablePreview like fetchDatapoolTables

export const initial: DatapoolTablesState = {
  byDatapoolCode: {},
};

export const test: DatapoolTablesState = {
  byDatapoolCode: {},
};

const slice = createSlice({
  name: 'datapoolTables',
  initialState: initial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDatapoolTables.pending, (state, { payload, meta }) => {
      const { datapoolCode } = meta.arg;
      state.byDatapoolCode[datapoolCode] = {
        ...(state.byDatapoolCode[datapoolCode] || ({} as ToBeRefined)),
        availableTables: {
          ...(state.byDatapoolCode[datapoolCode]?.availableTables || {}),
          loading: true,
          error: undefined,
        },
      };
    });

    builder.addCase(
      fetchDatapoolTables.fulfilled,
      (state, { payload, meta }) => {
        const { data } = payload;
        const { datapoolCode } = meta.arg;

        state.byDatapoolCode[datapoolCode] = {
          ...(state.byDatapoolCode[datapoolCode] || ({} as ToBeRefined)),
          availableTables: {
            ...(state.byDatapoolCode[datapoolCode]?.availableTables || {}),
            loading: false,
            loaded: true,
            data,
            error: undefined,
          },
        };
      }
    );

    builder.addCase(
      fetchDatapoolTables.rejected,
      (state, { payload, meta, error: serializedError }) => {
        if (!meta.rejectedWithValue) {
          console.error(
            'Received an unexpected error in fetchDatapoolTables.rejected',
            new Error(`${serializedError.name} ${serializedError.message}`)
          );
          return;
        }
        const { datapoolCode } = meta.arg;
        const { error } = payload;

        state.byDatapoolCode[datapoolCode] = {
          ...(state.byDatapoolCode[datapoolCode] || ({} as ToBeRefined)),
          availableTables: {
            ...(state.byDatapoolCode[datapoolCode]?.availableTables || {}),
            loading: false,
            loaded: false,
            error,
          },
        };
      }
    );
  },
});

const reducer = slice.reducer;
export { reducer as datapoolTablesReducer };
