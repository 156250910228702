import NotebookCellWrapper from '../../NotebookCellWrapper';
import RawCell from './RawCell.container';

export default class RawCellWrapper extends NotebookCellWrapper {
  ChildComponent = RawCell;
  type = 'raw';
  name = 'Raw';
  parentContainerClass = 'raw-cell';
  executable = false;
}
