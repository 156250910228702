import { connect } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import ContactPolicies from './ContactPolicies';
import { fieldContactPolicies } from './form';
import { ModelSettingsProps } from '../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataOpt } from 'common/dist/types/augurSettings';

export function mapStateToProps(
  state: RootState,
  ownProps: ModelSettingsProps<AugurSettingsDataOpt>
) {
  const {
    augur: { datapoolCode, habitatCode },
    settings: { contactPolicies },
  } = ownProps;

  return {
    initialValues: {
      [fieldContactPolicies]: contactPolicies || [],
    },
    habitatCode,
    datapoolCode,
  };
}

export default connect(mapStateToProps)(ContactPolicies);
