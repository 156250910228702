import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import ArchetypeVersionsList from './ArchetypeVersionsList';
import { FormattedDateTime } from '../../../../../../atoms/formatted-date-time/FormattedDateTime';

const CUSTOM_INTL = {
  id: 'newAugur.step.archetype.custom',
  defaultMessage: 'Custom',
};

const NOT_CUSTOM_INTL = {
  id: 'newAugur.step.archetype.original.no_prefix',
  defaultMessage: 'AltaSigma',
};

export default class ArchetypesList extends Component {
  componentDidMount() {
    const { archetypes, fetchArchetypeVersions } = this.props;
    archetypes.forEach((k) => fetchArchetypeVersions(k.code));
  }

  isArchetypeExpanded(archetypeCode) {
    const { archetypesExpanded } = this.props;
    return archetypesExpanded[archetypeCode];
  }

  renderVersionsColumn(archetypeCode) {
    const { archetypeVersions, expandArchetype, collapseArchetype } =
      this.props;
    if (!archetypeVersions || !archetypeVersions[archetypeCode]) {
      return <span>...</span>;
    }

    const { loading, loaded, versions } = archetypeVersions[archetypeCode];
    if (loading) {
      return <span>...</span>;
    }
    if (versions) {
      return (
        <div className={'ct-expand-wrapper'}>
          <span>{versions.length}</span>
          {versions.length > 0 &&
            (this.isArchetypeExpanded(archetypeCode) ? (
              <div
                className={'icon button button-transparent'}
                onClick={() => collapseArchetype(archetypeCode)}
              >
                <FiChevronUp size={24} />
              </div>
            ) : (
              <div
                className={'icon button button-transparent'}
                onClick={() => expandArchetype(archetypeCode)}
              >
                <FiChevronDown size={24} />
              </div>
            ))}
        </div>
      );
    }

    return <div />;
  }

  render() {
    const { archetypes, archetypeVersions, fetchArchetypeVersions } =
      this.props;

    return (
      <div className={'ct-list'}>
        <div className={'ct-headline ct-row'}>
          <div className={'ct-col ct-col-80px'}>
            <FormattedMessage id='common.code' defaultMessage='Code' />
          </div>
          <div className={'ct-col ct-col-flex-grow'}>
            <FormattedMessage id='common.name' defaultMessage='Name' />
          </div>
          <div className={'ct-col ct-col-70px'}>
            <FormattedMessage id='common.versions' defaultMessage='Versions' />
          </div>
          <div className={'ct-col ct-col-130px'}>
            <FormattedMessage
              id='common.created_by'
              defaultMessage='Created by'
            />
          </div>
          <div className={'ct-col ct-col-200px'}>
            <FormattedMessage
              id='common.created_at'
              defaultMessage='Created at'
              tagName='div'
            />
          </div>
        </div>
        {archetypes.map((item, i) => (
          <Fragment key={i}>
            <div className={'ct-row'} key={item.id}>
              <div className={'ct-col ct-col-80px'}>
                <div className={'ct-value'}>{item.code}</div>
                <div className={'ct-description'}>
                  <FormattedMessage id='common.code' defaultMessage='Code' />
                </div>
              </div>
              <div className={'ct-col ct-col-flex-grow'}>
                <div className={'ct-value'}>{item.name}</div>
                <div className={'ct-description'}>
                  <FormattedMessage id='common.name' defaultMessage='Name' />
                </div>
              </div>
              <div className={'ct-col ct-col-70px'}>
                <div className={'ct-value ct-show-overflow'}>
                  {this.renderVersionsColumn(item.code)}
                </div>
                <div className={'ct-description'}>
                  <FormattedMessage
                    id='common.versions'
                    defaultMessage='Versions'
                  />
                </div>
              </div>
              <div className={'ct-col ct-col-130px'}>
                <div className={'ct-value'}>
                  {item.user ? (item.user.name ? item.user.name : '-') : '-'}
                </div>
                <div className={'ct-description'}>
                  <FormattedMessage
                    id='common.created_by'
                    defaultMessage='Created by'
                  />
                </div>
              </div>
              <div className={'ct-col ct-col-200px'}>
                <div className={'ct-value'}>
                  <FormattedDateTime date={item.createdAt} />{' '}
                </div>
                <div className={'ct-description'}>
                  <FormattedMessage
                    id='common.created_at'
                    defaultMessage='Created at'
                  />
                </div>
              </div>
            </div>
            {this.isArchetypeExpanded(item.code) && (
              <ArchetypeVersionsList
                versions={
                  archetypeVersions[item.code]
                    ? archetypeVersions[item.code].versions
                    : []
                }
              />
            )}
            <div className={'ct-hr'} />
          </Fragment>
        ))}
      </div>
    );
  }
}

ArchetypesList.propTypes = {
  archetypes: PropTypes.array.isRequired,
  archetypeVersions: PropTypes.object.isRequired,
  fetchArchetypeVersions: PropTypes.func.isRequired,
  archetypesExpanded: PropTypes.object,
  expandArchetype: PropTypes.func.isRequired,
  collapseArchetype: PropTypes.func.isRequired,
};

ArchetypesList.defaultProps = {
  archetypes: [],
};
