import { apiRequest, CompletedApiRequest } from '../../core/api/_tools';
import { AuditEventList } from 'common/dist/types/auditLog';
import qs from 'qs';

export function fetchAuditLog(
  offset?: number,
  limit?: number,
  event?: string
): CompletedApiRequest<AuditEventList> {
  return apiRequest(
    `/api/auditlog/events${qs.stringify(
      { offset, limit, event },
      { addQueryPrefix: true }
    )}`
  );
}
