import { connect } from 'react-redux';

import GroupDetails from './GroupDetails';
import {
  loadGroups,
  loadUsersList,
} from '../../../../redux/modules/admin.users.module';
import { DeprecatedRootState } from '../../../../store/state.type';
import { RouteComponentProps } from 'react-router';

export function mapStateToProps(
  state: DeprecatedRootState,
  { match }: RouteComponentProps<{ groupId: string }>
) {
  const { groupId } = match.params;
  const group = state.admin.groups.data.find((g) => g.id === groupId);

  return {
    group,
    users: state.admin.users.data,
  };
}

export const mapDispatchToProps = {
  loadUsersList,
  loadGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
