/**
 * Returns the default empty notebook
 * @param kernelName
 * @param kernelDisplayName
 */
export function blankoNotebook(kernelName, kernelDisplayName) {
  return {
    type: 'notebook',
    format: 'json',
    content: {
      metadata: {
        kernelspec: {
          name: kernelName,
          display_name: kernelDisplayName,
        },
      },
      nbformat_minor: 2,
      nbformat: 4,
      cells: [
        {
          cell_type: 'code',
          source: '',
          metadata: {},
          execution_count: null,
          outputs: [],
        },
      ],
    },
    path: 'Untitled1.asapp',
  };
}
