import { createSlice } from '@reduxjs/toolkit';

export type RunMassPredictionModalState = {
  showModal: boolean;
};

export const initial: RunMassPredictionModalState = {
  showModal: false,
};

export const test: RunMassPredictionModalState = {
  showModal: false,
};

const slice = createSlice({
  name: 'runMassPredictionModal',
  initialState: initial as RunMassPredictionModalState,

  reducers: {
    showRunMassPredictionModal: (state) => ({
      showModal: true,
    }),
    hideRunMassPredictionModal: (state) => ({
      showModal: false,
    }),
  },
});

const reducer = slice.reducer;
export { reducer as runMassPredictionModalReducer };

const { showRunMassPredictionModal, hideRunMassPredictionModal } =
  slice.actions;
export { showRunMassPredictionModal, hideRunMassPredictionModal };
