import React, { FC, useState } from 'react';
import {
  Constraint,
  ConstraintDetails,
  ConstraintExhaustion,
  EnrichedConstraint,
  Operator,
} from 'common/dist/types/module.optimization';
import styles from './styles.module.scss';
import { CampaignOptimizationDatapoolType } from 'common/dist/types/datapool';
import { getConstraintDetails } from '../../newAugurWizard/optConstraints/helpers';
import Table, { RenderColumn } from '../../../../molecules/table/Table';
import {
  FiLayers,
  FiMaximize,
  FiMessageSquare,
  FiPhoneForwarded,
} from 'react-icons/all';
import { SPEAKING_CONSTRAINT_TYPE } from '../../newAugurWizard/optConstraints/common';
import Exhaustion from './Exhaustion';
import AscDesc, { ASC_DESC_VALUE } from '../../../../atoms/ascDesc/AscDesc';

export type Props = {
  /** */
  constraintExhaustion: ConstraintExhaustion[];
  /** Details of the underlying datapool */
  datapoolDetails?: CampaignOptimizationDatapoolType;
};

export type EnrichedExhaustion = {
  exhaustion: number;
  percentage: number;
  value: number;
  operator: Operator;
};

type FlatEnrichedConstraintExhaustion = EnrichedConstraint & {
  enrichedExhaustion: EnrichedExhaustion;
};

enum SORT_BY {
  NAME_ASC,
  NAME_DESC,
  EXHAUSTION_ASC,
  EXHAUSTION_DESC,
}

const enrichAndSort = (
  sortBy: SORT_BY,
  constraintExhaustion?: ConstraintExhaustion[],
  datapoolDetails?: CampaignOptimizationDatapoolType
) => {
  if (!constraintExhaustion) return [];
  // Enrich
  const enriched = constraintExhaustion.map((conExp) => {
    const valueNum = Number.parseInt(conExp?.constraint.value);
    return {
      ...(conExp?.constraint || ({} as Constraint)),
      enrichedExhaustion: {
        exhaustion: conExp?.exhaustion,
        value: valueNum,
        operator: conExp?.constraint?.operator,
        percentage:
          conExp?.exhaustion && valueNum > 0
            ? conExp?.exhaustion / valueNum
            : 0,
      },
      icon: conExp?.constraint?.constraintLevel,
      constraintDetails: getConstraintDetails(
        conExp?.constraint,
        datapoolDetails
      ),
    };
  });

  // Sort
  switch (sortBy) {
    case SORT_BY.NAME_ASC:
      return enriched.sort((a, b) => (a.name > b.name ? 1 : -1));
    case SORT_BY.NAME_DESC:
      return enriched.sort((a, b) => (a.name > b.name ? -1 : 1));
    case SORT_BY.EXHAUSTION_ASC:
      return enriched.sort((a, b) =>
        a.enrichedExhaustion.percentage > b.enrichedExhaustion.percentage
          ? 1
          : -1
      );
    case SORT_BY.EXHAUSTION_DESC:
      return enriched.sort((a, b) =>
        a.enrichedExhaustion.percentage > b.enrichedExhaustion.percentage
          ? -1
          : 1
      );
  }

  return enriched;
};

const OptConstraintExhaustion: FC<Props> = (props) => {
  const { constraintExhaustion, datapoolDetails } = props;
  const [sortBy, setSortBy] = useState<SORT_BY>(SORT_BY.NAME_ASC);

  if (!constraintExhaustion) return null;

  const flatEnrichedConstraintExhaustions: FlatEnrichedConstraintExhaustion[] =
    enrichAndSort(sortBy, constraintExhaustion, datapoolDetails);

  const renderColumns: RenderColumn<
    FlatEnrichedConstraintExhaustion,
    keyof FlatEnrichedConstraintExhaustion
  >[] = [
    {
      key: 'icon',
      renderHeader: () => null,
      renderCell: (constraintLevel) => {
        switch (constraintLevel) {
          case 'channel':
            return (
              <div className={styles.columnIcon}>
                <FiPhoneForwarded size={16} />
              </div>
            );
          case 'communication':
            return (
              <div className={styles.columnIcon}>
                <FiMessageSquare size={16} />
              </div>
            );
          case 'communicationGroup':
            return (
              <div className={styles.columnIcon}>
                <FiLayers size={16} />
              </div>
            );
          case 'allCommunications':
            return (
              <div className={styles.columnIcon}>
                <FiMaximize size={16} />
              </div>
            );
          default:
            return null;
        }
      },
      width: '20px',
    },
    {
      key: 'name',
      renderHeader: () => (
        <AscDesc
          value={
            sortBy === SORT_BY.NAME_ASC
              ? ASC_DESC_VALUE.ASC
              : sortBy === SORT_BY.NAME_DESC
              ? ASC_DESC_VALUE.DESC
              : undefined
          }
          onChange={(value) =>
            setSortBy(
              value === ASC_DESC_VALUE.ASC
                ? SORT_BY.NAME_ASC
                : SORT_BY.NAME_DESC
            )
          }
        >
          <span className={styles.headline}>Name</span>
        </AscDesc>
      ),
      renderCell: (name: string) => <span>{name}</span>,
    },
    {
      key: 'description',
      renderHeader: () => <span className={styles.headline}>Description</span>,
      renderCell: (description?: string) => <span>{description}</span>,
    },
    {
      key: 'constraintDetails',
      renderHeader: () => (
        <span className={styles.headline}>Constraint Level</span>
      ),
      renderCell: (constraintDetails: ConstraintDetails) => (
        <div className={styles.columnConstraintLevel}>
          <span className={styles.constraintReferenceName}>
            {constraintDetails.name}
          </span>
          <span className={styles.speakingLevel}>
            {constraintDetails.speakingLevel}
          </span>
        </div>
      ),
    },
    {
      key: 'constraintType',
      renderHeader: () => (
        <span className={styles.headline}>Constraint Type</span>
      ),
      renderCell: (constraintType: Constraint['constraintType']) => (
        <span>{SPEAKING_CONSTRAINT_TYPE[constraintType]}</span>
      ),
    },
    {
      key: 'enrichedExhaustion',
      renderHeader: () => (
        <AscDesc
          value={
            sortBy === SORT_BY.EXHAUSTION_ASC
              ? ASC_DESC_VALUE.ASC
              : sortBy === SORT_BY.EXHAUSTION_DESC
              ? ASC_DESC_VALUE.DESC
              : undefined
          }
          onChange={(value) =>
            setSortBy(
              value === ASC_DESC_VALUE.ASC
                ? SORT_BY.EXHAUSTION_ASC
                : SORT_BY.EXHAUSTION_DESC
            )
          }
        >
          <span className={styles.headline}>Exhaustion</span>
        </AscDesc>
      ),
      renderCell: (enrichedExhaustion: EnrichedExhaustion) => (
        <Exhaustion enrichedExhaustion={enrichedExhaustion} />
      ),
    },
  ];

  return (
    <div className={styles.optConstraintExhaustion}>
      <div className={styles.tableContainer}>
        <Table
          data={flatEnrichedConstraintExhaustions}
          renderColumns={renderColumns}
          showHeader
          verticalAlignMiddle
          addlRowClassname={(rowIndex: number) => styles.row}
        />
      </div>
    </div>
  );
};

export default OptConstraintExhaustion;
