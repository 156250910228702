import { connect } from 'react-redux';

import LoginHistory from './LoginHistory';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  return {
    history: state.account.loginHistory.history,
  };
}

export default connect(mapStateToProps)(LoginHistory);
