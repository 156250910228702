import { apiRequest } from './_tools';
import { User } from 'common/dist/types/users';
import { ApiError } from 'common/dist/types/responseBodies/errors';

export function getUser(): Promise<{ response?: User; error?: ApiError }> {
  return apiRequest('/api/user', {
    method: 'GET',
    credentials: 'include',
  });
}
