import React, { Children, Component, RefObject } from 'react';
import ReactDOM from 'react-dom';

export interface Props {
  width: number;
  height: number;
  margin: Record<string, unknown>;
}

interface State {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
}

class Sizer extends Component<Props, State> {
  unmounted: boolean | undefined;

  static defaultProps = {
    width: 0,
    height: 0,
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };

  constructor(props: Props) {
    super(props);

    this.handleResize = this.handleResize.bind(this);
    this.onRefChange = this.onRefChange.bind(this);
  }

  componentDidMount() {
    this.handleResize();
  }

  onRefChange(node: Element) {
    if (node) {
      new ResizeObserver(this.handleResize).observe(node);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleResize() {
    if (this.unmounted) return;

    const node = ReactDOM.findDOMNode(this) as Element | null;
    setTimeout(() => {
      if (!this.unmounted && node) {
        const clientRect = node.getBoundingClientRect();
        this.setState({
          width: clientRect.width,
          height: clientRect.height,
          top: clientRect.top,
          left: clientRect.left,
        });
      }
    }, 0);
  }

  render() {
    const { children, width, height } = this.props;

    if (width !== 0 && height !== 0) {
      this.state = { width, height };
    }

    if (!this.state) {
      return <div className='chartBox'></div>;
    }

    const props = {
      width: this.state.width,
      height: this.state.height,
      top: this.state.top,
      left: this.state.left,
      requestSize: () => this.handleResize(),
    };

    return (
      <div
        // @ts-ignore
        ref={this.onRefChange}
        style={{ height: '100%', maxHeight: '100%' }}
      >
        {/* @ts-ignore */}
        {React.cloneElement(Children.only(children), props)}
      </div>
    );
  }
}

export default Sizer;
