import { connect } from 'react-redux';
import LauncherCreateNotebook from './LauncherCreateNotebook';

export function mapStateToProps(state) {
  return {
    kernelspecs: (state.workbench.kernelspecs || {}).kernelspecs || {},
  };
}

export const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LauncherCreateNotebook);
