import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import WizardUpload from './wizard-upload/WizardUpload.container';
import { UPLOAD_WIZARDS } from './uploadWizards';
import UploadConfirmation from './UploadConfirmation.container';

class Upload extends Component {
  pickWizard() {
    const {
      match: { params },
      activeWizard,
    } = this.props;
    const dataSourceCode = params.dataSourceCode || '';
    if (activeWizard === UPLOAD_WIZARDS.UPLOAD) {
      return <WizardUpload dataSourceCode={dataSourceCode} />;
    } else if (activeWizard === UPLOAD_WIZARDS.CONFIRMATION) {
      return <UploadConfirmation dataSourceCode={dataSourceCode} />;
    } else {
      return null;
    }
  }

  render() {
    return this.pickWizard();
  }
}

Upload.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      /** Code of the Data Source */
      dataSourceCode: PropTypes.string,
    }),
  }),
  activeWizard: PropTypes.string, // One of the UPLOAD_WIZARDS values
};

Upload.defaultProps = {
  activeWizard: UPLOAD_WIZARDS.UPLOAD,
};

export default withRouter(Upload);
