import React, { Component, FC } from 'react';
import Wizard from '../../../pages/wizard/Wizard';
import { app as appRoutes } from '../../../index/routes';
import { fieldTemplateId, formName } from './CreateBackup.form';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import { BackupCreateRequest, TemplateMeta } from '../types';
import DropdownSelectInput from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import SubHeader from '../../../organisms/sub-header/SubHeader.container';
import OverlayMaintenance from '../../../organisms/sub-header/overlay-maintenance/OverlayMaintenance';
import { InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { ButtonProps } from '../../../atoms/button/Button';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { maintenanceRoutes } from '../routes';

export type Props = {
  /** Is the form valid (= can the form be submitted)?*/
  valid: boolean;
  /** Is the form currently submitting? (-> disable the Submit button and show a spinner in it)*/
  submitting: boolean;
  /** The values entered in the form */
  formValues: {
    templateId?: string;
  };
  templates: {
    loading: boolean;
    loaded: boolean;
    error?: string;
    data: TemplateMeta[];
  };
  createBackup(bakCreate: BackupCreateRequest, callback: () => void): void;
  fetchTemplates(): void;
};

type OptionType = { label: string; value: string };

export const ComponentSelectionStep: FC<Props & WrappedFieldProps> = (
  props
) => {
  const {
    templates: { data },
    input: { onChange, value, onFocus, onBlur },
    meta: { error, valid, touched },
  } = props;

  const options: OptionType[] = data.map((template) => ({
    label: `${template.name} (${template.id})`,
    value: template.id,
  }));

  return (
    <div className={'GenericFormStep--field'}>
      <DropdownSelectInput
        id={'templateId'}
        name={'templateId'}
        touched={touched}
        error={error}
        valid={valid}
        disabled={false}
        label={{
          id: 'todo',
          defaultMessage: 'Select the Template',
        }}
        placeholder={{
          id: 'todo',
          defaultMessage: 'No Template selected',
        }}
        value={options.find((o) => o.value === value)}
        onChange={(option: OptionType) => onChange(option.value)}
        onFocus={onFocus}
        onBlur={() => onBlur(value)}
        isLoading={false}
        options={options}
      />
    </div>
  );
};

class CreateBackup extends Component<
  Props & InjectedFormProps<ToBeRefined, Props> & RouteComponentProps
> {
  componentDidMount() {
    const { fetchTemplates } = this.props;
    fetchTemplates();
  }

  render() {
    const { templates, valid, submitting, createBackup, formValues, history } =
      this.props;

    const buttons: ButtonProps[] = [
      {
        withLink: true,
        linkTo:
          appRoutes.prefix +
          appRoutes.admin.index +
          appRoutes.admin.maintenance,
        buttonColor: 'white',
        buttonLabelId: 'todo',
        buttonLabelDefault: 'Cancel',
      },
      {
        withLink: false,
        buttonColor: 'secondary',
        buttonLabelId: 'todo',
        buttonLabelDefault: 'Finish',
        disabled: !valid,
        isBusy: submitting,
        onClick: () => {
          if (valid) {
            const bakCreate = { templateId: formValues[fieldTemplateId] };
            createBackup(bakCreate, () =>
              history.push(
                `${maintenanceRoutes.basePath}/${maintenanceRoutes.backups.path}`
              )
            );
          }
        },
      },
    ];

    return (
      <>
        <SubHeader hasOverlay Overlay={OverlayMaintenance} />

        <Wizard
          withHeadline={true}
          headlineId={'todo'}
          headlineDefault={'Create Backup'}
          buttons={buttons}
        >
          <GenericFormStep
            fieldName={fieldTemplateId}
            formName={formName}
            component={ComponentSelectionStep}
            title={{
              defaultMessage: 'Select the Template',
            }}
            description={{
              defaultMessage:
                'Select the Template for which to create a backup.',
            }}
            num={1}
            renderError={false}
            fieldProps={{ templates }}
          />
        </Wizard>
      </>
    );
  }
}

export default withRouter(CreateBackup);
