import { AsInputElement } from '../_interface/P3InputElement';
import {
  validateCommonSettings,
  getValueFromSettingsField,
} from '../_interface/input-element.form';

export const getSourceDateTime = (asElement: AsInputElement) => {
  if (
    !asElement.settings ||
    !asElement.settings.outputVariable ||
    !asElement.data
  )
    return '';

  let dateTime = asElement.data.datetime;
  if (
    !dateTime &&
    asElement.settings.defaultValue &&
    asElement.settings.defaultValue.inputValue
  ) {
    // If the dateTime isn't set: Inject the default value
    dateTime = asElement.settings.defaultValue.inputValue; // TODO use getValueFromSettingsField(settings, variables)
  }

  return [
    'from datetime import datetime',
    `${asElement.settings.outputVariable.inputValue} = datetime.strptime('${dateTime}', '%Y-%m-%d %H:%M:%S')`,
  ].join(' \n');
};

export const getSourceDate = (asElement: AsInputElement) => {
  if (
    !asElement.settings ||
    !asElement.settings.outputVariable ||
    !asElement.data
  )
    return '';

  let dateTime = asElement.data.datetime;
  if (
    !dateTime &&
    asElement.settings.defaultValue &&
    asElement.settings.defaultValue.inputValue
  ) {
    // If the dateTime isn't set: Inject the default value
    dateTime = asElement.settings.defaultValue.inputValue; // TODO use getValueFromSettingsField(settings, variables)
  }

  return [
    'from datetime import datetime',
    `${asElement.settings.outputVariable.inputValue} = datetime.strptime('${dateTime}', '%Y-%m-%d')`,
  ].join(' \n');
};

export const getSourceTime = (asElement: AsInputElement) => {
  if (
    !asElement.settings ||
    !asElement.settings.outputVariable ||
    !asElement.data
  )
    return '';

  let dateTime = asElement.data.datetime;
  if (
    !dateTime &&
    asElement.settings.defaultValue &&
    asElement.settings.defaultValue.inputValue
  ) {
    // If the dateTime isn't set: Inject the default value
    dateTime = asElement.settings.defaultValue.inputValue; // TODO use getValueFromSettingsField(settings, variables)
  }

  return [
    'from datetime import datetime',
    `${asElement.settings.outputVariable.inputValue} = datetime.strptime('${dateTime}', '%H:%M:%S')`,
  ].join(' \n');
};

export const validate = (asElement: AsInputElement, variables: object[]) =>
  undefined; // TODO Would a validation be necessary or will the DatetimePicker do this already?

export const validateSettings = (settings: object[], variables: object[]) => {
  // @ts-ignore
  const commonErrors = validateCommonSettings(settings);

  const specificErrors = {};
  const {
    // @ts-ignore
    lowerBound: lowerBoundField,
    // @ts-ignore
    upperBound: upperBoundField,
    // @ts-ignore
    defaultValue: defaultValueField,
  } = settings;

  const lowerBound = getValueFromSettingsField(lowerBoundField, variables);
  const upperBound = getValueFromSettingsField(upperBoundField, variables);
  const defaultValue = getValueFromSettingsField(defaultValueField, variables);

  // --- Check if the boundaries are proper timestamps
  let lowerValidTimestamp = true;
  let upperValidTimestamp = true;

  if (lowerBound && Number.isNaN(new Date(lowerBound).getTime())) {
    commonErrors.lowerBound = 'Please enter a valid timestamp';
    lowerValidTimestamp = false;
  }
  if (upperBound && Number.isNaN(new Date(upperBound).getTime())) {
    commonErrors.upperBound = 'Please enter a valid timestamp';
    upperValidTimestamp = false;
  }

  const lowerBoundCasted =
    lowerBound && lowerValidTimestamp ? new Date(lowerBound) : undefined;
  const upperBoundCasted =
    upperBound && upperValidTimestamp ? new Date(upperBound) : undefined;

  // --- Check if the lower boundary is < upper boundary
  if (
    lowerBoundCasted &&
    upperBoundCasted &&
    lowerBoundCasted > upperBoundCasted
  ) {
    commonErrors.lowerBound =
      'Lower Bound must be smaller or equal than the Upper Bound';
  }

  // --- Check the default value if given
  if (defaultValue) {
    let defaultValidTimestamp = true;
    // --- Check if the default value is a proper timestamp
    if (Number.isNaN(new Date(defaultValue).getTime())) {
      commonErrors.defaultValue = 'Please enter a valid timestamp';
      defaultValidTimestamp = false;
    }

    const defaultValueCasted =
      defaultValue && defaultValidTimestamp
        ? new Date(defaultValue)
        : undefined;

    // --- Check if the default value satiesfies the boundaries
    if (lowerBoundCasted && defaultValueCasted < lowerBoundCasted) {
      commonErrors.defaultValue =
        'Default Value must be greater or equal the Lower Bound';
    }

    if (upperBoundCasted && defaultValueCasted > upperBoundCasted) {
      commonErrors.defaultValue =
        'Default Value must be lower or equal the Upper Bound';
    }
  }

  return {
    ...commonErrors,
    ...specificErrors,
  };
};
