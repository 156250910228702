import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { resourceRoutes } from './routes';
import RunningApps from './running-apps/RunningAppsLogin';
import WithSidemenu from '../../molecules/with-sidemenu/WithSidemenu';
import { MenuEntryType } from '../../molecules/with-sidemenu/SideMenu';
import { FiRepeat } from 'react-icons/fi';
import SubHeader from '../../organisms/sub-header/SubHeader.container';
import OverlayResources from '../../organisms/sub-header/overlay-resources/OverlayResources';
import RunningWorkbench from './running-workbench/RunningWorkbenchLogin';
import { RouteComponentProps } from 'react-router';
import styles from './styles.module.scss';

const menuEntries = [
  {
    type: 'link',
    id: 'runningWorkbench',
    to: `${resourceRoutes.basePath}/${resourceRoutes.runningWorkbench.path}`,
    nameIntlId: 'dashboard.tab.resources.running_workbench',
    nameDefault: 'Running Workbenches',
    icon: FiRepeat,
  },
  {
    type: 'link',
    id: 'runningApps',
    to: `${resourceRoutes.basePath}/${resourceRoutes.runningApps.path}`,
    nameIntlId: 'dashboard.tab.resources.running_apps',
    nameDefault: 'Running Apps',
    icon: FiRepeat,
  },
] as MenuEntryType[];

class Resources extends Component<RouteComponentProps> {
  getActiveCategory() {
    const path = this.props.history.location.pathname;
    if (!path) return null;
    const rep = path.replace('/app/admin/resources/', '');
    const parts = rep.split('/');
    return parts[0];
  }

  renderInnerComponent() {
    const selectedCategory = this.getActiveCategory();

    return (
      <div className={styles.Resources}>
        <WithSidemenu
          menuEntries={menuEntries}
          selectedTab={selectedCategory}
          handleSelect={() => {}}
        >
          <Switch>
            <Route
              path={`${resourceRoutes.basePath}/${resourceRoutes.runningWorkbench.path}`}
              component={RunningWorkbench}
            />
            <Route
              path={`${resourceRoutes.basePath}/${resourceRoutes.runningApps.path}`}
              component={RunningApps}
            />
            <Redirect
              exact
              path={resourceRoutes.basePath}
              to={`${resourceRoutes.basePath}/${resourceRoutes.runningWorkbench.path}`}
            />
          </Switch>
        </WithSidemenu>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <SubHeader hasOverlay Overlay={OverlayResources} />
        {this.renderInnerComponent()}
      </Fragment>
    );
  }
}

export default withRouter(Resources);
