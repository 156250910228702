import React, { Component } from 'react';
import { FiPlay, FiLock } from 'react-icons/fi';
import anime from 'animejs';
import styles from './styles.module.scss';
import { AltaSigmaCell } from '../../../../store/workbench/state.types';
import classNames from 'classnames';

export type Props = {
  path: string;
  cell: AltaSigmaCell;
  sessionId: string;
  executeCells: (path: string, sessionId: string, cellId: string[]) => void;
  isSelected: boolean;
  /**
   * Basically readOnly mode, but different to the already existing readOnly mode, which shows a lock
   * This just disables the execution and the "play"-button for the execution.
   */
  executable?: boolean;
};

export default class CellInfoFlap extends Component<Props> {
  animationRef = undefined;

  constructor(props: Props) {
    super(props);
    this.animationRef = React.createRef();
  }

  /**
   * Renders the read only flap
   */
  renderReadonly() {
    const { isSelected } = this.props;
    return (
      <div
        className={classNames(styles.cellInfoFlap, {
          [styles.cellInfoFlapSelected]: isSelected,
        })}
      >
        <FiLock className={'icon lock-icon'} size={'16px'} />
      </div>
    );
  }

  /**
   * Renders the not executable flap, which just removes the on-hover functionality
   */
  renderNotExecutable() {
    const { isSelected, cell } = this.props;
    return (
      <div
        className={classNames(styles.cellInfoFlap, {
          [styles.cellInfoFlapSelected]: isSelected,
        })}
      >
        <p>{'execution_count' in cell ? cell.execution_count : undefined}</p>
      </div>
    );
  }

  /**
   * Renders the default execution flap
   * @returns {*}
   */
  renderDefault() {
    const { path, cell, sessionId, executeCells, isSelected } = this.props;
    const isExecuting = 'executing' in cell && cell.executing;

    return (
      <div
        className={classNames(styles.cellInfoFlap, {
          [styles.cellInfoFlapSelected]: isSelected,
        })}
      >
        <p className={styles.executionCount}>
          {isExecuting ? (
            <div
              className={styles.cellInfoFlapExecutingOverlay}
              ref={this.animationRef}
            />
          ) : 'execution_count' in cell ? (
            cell.execution_count
          ) : undefined}
        </p>
        <div
          title={'Execute cell'}
          className={styles.executionCountExecute}
          onClick={() => executeCells(path, sessionId, [cell.id])}
        >
          <FiPlay size={'16px'} />
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { cell } = this.props;
    if ('executing' in cell && cell.executing) {
      this.startAnimation();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { cell } = this.props;
    if (
      'executing' in cell &&
      cell.executing &&
      'executing' in prevProps.cell &&
      !prevProps.cell?.executing
    ) {
      this.startAnimation();
    }
  }

  startAnimation() {
    const translateXMovement = 25;
    anime({
      targets: this.animationRef.current,
      keyframes: [
        {
          translateX: translateXMovement, // Move all to the right
          width: 12,
          duration: 600,
          easing: 'easeInSine',
        },
        {
          translateX: translateXMovement + 4,
          width: 8,
          duration: 150,
          easing: 'easeOutSine',
        },
      ],
      direction: 'alternate',
      loop: true,
    });
  }

  render() {
    const { executable } = this.props;

    if (!executable) {
      return this.renderNotExecutable();
    } else {
      return this.renderDefault();
    }
  }
}
