import { UPLOAD_WIZARDS } from '../../components/dataManagement/cassandra/upload/uploadWizards';

export const initial = {
  dataSources: {
    loading: false,
    loaded: false,
    error: undefined, // Just to make it explicit
    data: [],
  },
  upload: {
    activeWizard: UPLOAD_WIZARDS.UPLOAD,
    uploading: false,
    uploaded: false,
    progress: 0,
    error: undefined, // Just to make it explicit
    commitInfo: {
      loading: false,
      loaded: false,
      error: undefined, // Just to make it explicit
      data: {},
    },
    // write: {
    //   writing: false,
    //   written: false,
    //   amount: 0
    //   error: undefined,
    //   data: {},
    // }
  },
  cassandra: {},
  s3: {},
  history: {},
  manageDS: {
    deleteDataSourceConfirm: {
      show: false,
      // name: ""
      // code: ""
    },
  },
};

export const test = {
  dataSources: {
    loading: false,
    loaded: false,
    error: undefined, // Just to make it explicit
    data: [],
  },
  upload: {
    activeWizard: UPLOAD_WIZARDS.UPLOAD,
    commitInfo: {
      loading: false,
      loaded: false,
      error: undefined, // Just to make it explicit
      data: {},
    },
  },
  cassandra: {},
  s3: {},
  history: {},
};
