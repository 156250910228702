import React, { FC, useState } from 'react';
import Wizard from '../../../pages/wizard/Wizard';
import { app as appRoutes } from '../../../index/routes';

import {
  fieldDescriptionId,
  fieldTitleId,
  formName,
} from './EnableMaintenance.form';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import TextInputLine from '../../../atoms/input-elements/text-input-line/TextInputLine';
import { EnableMaintenanceRequest } from '../types';
import ConfirmationModal from '../../../organisms/confirmation-modal/ConfirmationModal';
import './style.scss';
import TextInputArea from '../../../atoms/input-elements/text-input-area/TextInputArea';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { InjectedFormProps } from 'redux-form';
import { ButtonProps } from '../../../atoms/button/Button';

export type Props = {
  /** Is the form valid (= can the form be submitted)?*/
  isValid: boolean;
  /** Is the form currently submitting? (-> disable the Submit button and show a spinner in it)*/
  submitting: boolean;
  /** The values entered in the form */
  formValues: {
    templateId?: string;
  };
  enableMaintenance(data: EnableMaintenanceRequest): void;
};

const TitleInput: FC = (props: ToBeRefined) => {
  const {
    meta: { touched, error },
    input: { value, onChange, onFocus, onBlur },
  } = props;
  return (
    <div className={'GenericFormStep--field'}>
      <TextInputLine
        touched={touched}
        error={error}
        disabled={false}
        hasLabel={false}
        placeholderDefault={'Enter Title'}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.currentTarget.value);
          onBlur();
        }}
        onBlur={() => onBlur()}
        onFocus={onFocus}
        valid={!error}
        validating={false}
      />
    </div>
  );
};

const DescriptionInput: FC = (props: ToBeRefined) => {
  const {
    meta: { touched, error },
    input: { value, onChange, onFocus, onBlur },
  } = props;
  return (
    <div className={'GenericFormStep--field'}>
      <TextInputArea
        amountRows={5}
        touched={touched}
        error={error}
        disabled={false}
        hasLabel={false}
        placeholder={{
          id: 'no-id',
          defaultMessage: 'Enter Description',
        }}
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value);
          onBlur();
        }}
        onBlur={() => onBlur()}
        onFocus={onFocus}
      />
    </div>
  );
};

const EnableMaintenance: FC<Props & InjectedFormProps<ToBeRefined, Props>> = (
  props
) => {
  const { isValid, submitting, enableMaintenance, formValues } = props;
  const [showModal, setShowModal] = useState(false);

  const buttons: ButtonProps[] = [
    {
      withLink: true,
      linkTo:
        appRoutes.prefix + appRoutes.admin.index + appRoutes.admin.maintenance,
      buttonColor: 'white',
      buttonLabelId: 'todo',
      buttonLabelDefault: 'Cancel',
    },
    {
      withLink: false,
      buttonColor: 'secondary',
      buttonLabelId: 'todo',
      buttonLabelDefault: 'Submit',
      disabled: !isValid,
      isBusy: submitting,
      onClick: () => {
        if (isValid) {
          setShowModal(true);
        }
      },
    },
  ];

  return (
    <div className={'enable-maintenance-container'}>
      <Wizard
        withHeadline={true}
        headlineId={'todo'}
        headlineDefault={'Enable Maintenance Mode'}
        buttons={buttons}
        description={{
          id: 'no-id',
          defaultMessage:
            'Enabling the Maintenance Mode will disable the AltaSigma Dashboard for all users and show the Maintenance page instead.',
        }}
      >
        <GenericFormStep
          fieldName={fieldTitleId}
          formName={formName}
          component={TitleInput}
          title={{
            defaultMessage: 'Title',
          }}
          description={{
            defaultMessage: 'Enter the reason for the maintenance.',
          }}
          num={1}
          renderError={false}
        />
        <GenericFormStep
          fieldName={fieldDescriptionId}
          formName={formName}
          component={DescriptionInput}
          title={{
            defaultMessage: 'Description',
          }}
          description={{
            defaultMessage: 'Specify the reason for the maintenance.',
          }}
          num={2}
          renderError={false}
        />
      </Wizard>
      <ConfirmationModal
        show={showModal}
        hideModal={() => setShowModal(false)}
        buttonConfirmAction={() => {
          enableMaintenance({
            title: { defaultMessage: formValues[fieldTitleId], id: 'no-id' },
            description: {
              defaultMessage: formValues[fieldDescriptionId],
              id: 'no-id',
            },
          });
        }}
        payload={{}}
        secure={false}
        description={{
          id: 'no-id',
          defaultMessage:
            'Are you sure you want to enter the Maintenance Mode?',
        }}
      />
    </div>
  );
};

export default EnableMaintenance;
