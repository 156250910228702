import { VARIABLE_TYPES } from '../variableTypes';
import { BaseVariableHandler, getFirstRaw } from './helper';

export const StringVariableHandler: BaseVariableHandler<string> = {
  type: VARIABLE_TYPES.STRING,
  displayName: 'String',
  getExecutionCode: (inputValue: string) => inputValue,
  parseValue: (variable, rawValues) => {
    const firstRaw = getFirstRaw(variable, rawValues);
    if (!firstRaw) return { parsedValue: undefined, error: undefined };
    return { parsedValue: firstRaw, error: undefined };
  },
};
