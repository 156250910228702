import React, { Component, Fragment } from 'react';
import { FiX } from 'react-icons/fi';
import ReactModal from 'react-modal';
import Button from '../../atoms/button/Button';
import './confirmationModal.scss';
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  MessageDescriptor,
  WrappedComponentProps,
} from 'react-intl';

// Merge into Modal.defaultStyles
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export interface Props<Payload = unknown> {
  show: boolean;
  /** The object with which to call the confirm action. It typically comes from the state e.g. {code: xyz} */
  payload: Payload;
  /** Use the simple or the secure version (which requires user input for confirmation) */
  secure: boolean;
  /** The string which must be matched by the user input. Must be set if secure = True */
  secureInput?: string;
  /** Pass only properties of the two available buttons, instead of the buttons themselves,
   *  since we may need to disable the confirmation */
  buttonConfirmText?: MessageDescriptor;
  buttonConfirmColor?:
    | 'primary'
    | 'secondary'
    | 'blue'
    | 'white'
    | 'green'
    | 'red'
    | 'orange';
  /** The action to call on confirmation. Accepts a payload e.g. buttonConfirmAction({code: xyz}) */
  buttonCancelText?: MessageDescriptor;
  buttonCancelColor?: 'blue' | 'white' | 'green' | 'red' | 'orange';
  buttonConfirmAction: (payload: Payload) => void;
  hideModal: () => void;
  /** Headline, if missing it just shows "Confirmation" */
  headline?: MessageDescriptor;
  headlineColor?: 'blue' | 'white' | 'green' | 'red' | 'orange';
  /** Description, if missing it just shows "Are you sure?" */
  description?: React.ComponentProps<typeof FormattedMessage>;
}

interface State {
  userInput: string;
}

class ConfirmationModal<Payload = unknown> extends Component<
  Props<Payload> & { intl: IntlShape },
  State
> {
  constructor(props: Props<Payload> & WrappedComponentProps) {
    super(props);
    this.state = { userInput: '' };
  }

  render() {
    const {
      show,
      secure,
      secureInput,
      hideModal,
      buttonConfirmAction,
      headline,
      headlineColor,
      description,
      payload,
      buttonConfirmText,
      buttonCancelText,
      intl,
      buttonConfirmColor = 'orange',
      buttonCancelColor = 'white',
    } = this.props;
    const inputCorrect = this.state.userInput === secureInput;
    const buttonConfirmTextFinal =
      buttonConfirmText !== undefined
        ? intl.formatMessage(buttonConfirmText)
        : 'Confirm';
    const buttonCancelTextFinal =
      buttonCancelText !== undefined
        ? intl.formatMessage(buttonCancelText)
        : 'Cancel';
    return (
      <ReactModal
        isOpen={show}
        contentLabel='Dialog Modal'
        onRequestClose={hideModal}
        style={customStyles}
        appElement={document.getElementById('react-app')}
      >
        <div className={'ConfirmationModal'}>
          <div
            title={'Close Dialog'}
            className={'close-button'}
            onClick={hideModal}
          >
            <FiX className={'icon'} size={'20px'} />
          </div>

          <div
            className={`ConfirmationModal--headline ${
              headlineColor !== undefined
                ? 'confirmation-headline--' + headlineColor
                : ''
            }`}
          >
            {!headline && <span>Confirmation</span>}
            {headline && (
              <FormattedMessage
                id={headline.id}
                defaultMessage={headline.defaultMessage}
              />
            )}
          </div>

          <div className={'ConfirmationModal--description'}>
            {!description && <span>Are you sure?</span>}
            {!description && secure && (
              <Fragment>
                <br />
                <span>
                  Please type in <code>{secureInput}</code> to confirm.
                </span>
              </Fragment>
            )}
            {description && (
              <FormattedMessage
                id={description.id}
                defaultMessage={description.defaultMessage}
                values={description.values}
              />
            )}
          </div>

          {secure && (
            <div className={'ConfirmationModal--input'}>
              <input
                type={'text'}
                value={this.state.userInput}
                placeholder={''}
                onChange={(e) => this.setState({ userInput: e.target.value })}
              />
            </div>
          )}
          <div className={'ConfirmationModal--button-bar'}>
            <Button
              additionalClassNames={['button']}
              buttonColor={buttonConfirmColor}
              buttonLabelDefault={buttonConfirmTextFinal}
              withLink={false}
              disabled={secure ? !inputCorrect : false}
              onClick={() => {
                buttonConfirmAction(payload);
                hideModal();
              }}
            />
            <Button
              buttonColor={buttonCancelColor}
              buttonLabelDefault={buttonCancelTextFinal}
              withLink={false}
              onClick={() => {
                hideModal();
              }}
            />
          </div>
        </div>
      </ReactModal>
    );
  }
}

// Try to pass the Payload type through the injectIntl HOC, too ugly to use?
// export default injectIntl(ConfirmationModal) as unknown as <Payload = unknown>(props: Props<Payload>) => any;
export default injectIntl(ConfirmationModal);
