import React, { FC } from 'react';
import { jobGroupField, newScheduleFormName } from '../../newSchedule.form';
import GenericFormStep from '../../../../../molecules/generic-form-step/GenericFormStep';
import { showJobPicker } from '../../../../../../redux/modules/orchestration.schedules.module';
import { useAppDispatch } from '../../../../../../store/store';
import { StepComponent } from './StepComponent';
import stepMessage from 'common/dist/messages/orchestration';
import ReactLoading from 'react-loading';
import styles from './styles.module.scss';
import vars from '../../../../../../../scss/base/var.module.scss';

interface Props {
  /** Index of this step */
  step: number;
  /** Are the job groups (or one of the job groups) currently loading? */
  jobGroupsLoading?: boolean;
}

const LoadingIndicator = () => (
  <div className={styles.loadingIndicator}>
    <ReactLoading type={'cylon'} color={vars.colorPrimary} />
  </div>
);

/**
 * Wrapper for the StepComponent that adds GenericFormStep (and Field)
 * @param props
 * @constructor
 */
export const JobGroupStep: FC<Props> = (props) => {
  const { step, jobGroupsLoading } = props;
  const appDispatch = useAppDispatch();

  return (
    <GenericFormStep
      fieldName={jobGroupField}
      formName={newScheduleFormName}
      component={jobGroupsLoading ? LoadingIndicator : StepComponent}
      title={{
        id: stepMessage.msgJobGroupName.id,
        defaultMessage: stepMessage.msgJobGroupName.defaultMessage,
      }}
      description={{
        id: stepMessage.msgJobGroupDescription.id,
        defaultMessage: stepMessage.msgJobGroupDescription.defaultMessage,
      }}
      num={step}
      fieldProps={{
        onClick: (e) => appDispatch(showJobPicker('unneeded')),
      }}
    />
  );
};

export default JobGroupStep;
