import React, { FC, useEffect } from 'react';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import ResourceList from '../../../organisms/permissions/resouce-list/ResourceList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { fetchAppsAdmin } from '../../../../redux/modules/apps.module';

export const detailsRoute = (id) =>
  `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.appPermissions.path}/${id}`;

const AppPermissions: FC = () => {
  const headlines = [
    {
      headlineId: 'name',
      label: {
        id: 'no-id',
        defaultMessage: 'App Permissions',
      },
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAppsAdmin(false));
  }, [dispatch]);

  const apps = useSelector<RootState, ToBeRefined[]>(
    (state) =>
      // @ts-ignore
      state.appsAdmin?.data
  );

  const entries = (apps || [])
    .map((app) => ({ name: app.name, id: app.code }))
    .map((entry) => ({
      linkTo: detailsRoute(entry.id),
      columnValues: {
        name: entry.name,
      },
    }));

  return <ResourceList headlines={headlines} entries={entries} />;
};

export default AppPermissions;
