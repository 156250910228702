import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Button from '../../../atoms/button/Button';
import styles from './styles.module.scss';
import classNames from 'classnames';

type Props = {
  title: MessageDescriptor;
  linkTo: string;
  buttonLabelDefault: string;
  buttonLabelId?: string;
};

const NoElements: FC<Props> = (props) => {
  const { linkTo, buttonLabelDefault, buttonLabelId, title } = props;

  return (
    <div className={styles.noElements}>
      <div className={styles.noElementsContent}>
        <div className={classNames(styles.augurPic, 'augur--pic')} />
        <FormattedMessage {...title} />
        <Button
          additionalClassNames={[styles.addButton]}
          buttonLabelId={buttonLabelId}
          buttonLabelDefault={buttonLabelDefault}
          buttonColor={'secondary'}
          withLink={true}
          linkTo={linkTo}
          Icon={() => (
            <span
              style={{
                fontSize: '18px',
                margin: '-3px 0px -2px -10px',
                opacity: 0.5,
              }}
              className={'icon-plus'}
            />
          )}
        />
      </div>
    </div>
  );
};

export default NoElements;
