import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

export default class StepAddlInfo extends Component {
  static CHARSETS = {
    Automatic: null,
    'UTF-8': 'UTF-8',
    'ISO-8859-1': 'ISO-8859-1',
    'UTF-16': 'UTF-16',
    'US-ASCII': 'US-ASCII',
    cp1252: 'cp1252',
    'ISO-8859-15': 'ISO-8859-15',
    'ISO-8859-2': 'ISO-8859-2',
    'ISO-8859-3': 'ISO-8859-3',
    'ISO-8859-4': 'ISO-8859-4',
    'ISO-8859-5': 'ISO-8859-5',
    'ISO-8859-6': 'ISO-8859-6',
    'ISO-8859-7': 'ISO-8859-7',
    'ISO-8859-8': 'ISO-8859-8',
    'ISO-8859-9': 'ISO-8859-9',
    'ISO-8859-11': 'ISO-8859-11',
    'ISO-8859-13': 'ISO-8859-13',
  }; // Order by most common

  render() {
    const {
      meta: { error, touched },
      input: { value, onChange, onBlur },
    } = this.props;

    const options = Object.entries(StepAddlInfo.CHARSETS).map(([k, v]) => ({
      label: k,
      value: v,
    }));

    return (
      <div className={'GenericFormStep--field addl-info-input-parent'}>
        {touched && error && error.general && (
          <div className={'error-container'}>
            <span className={'error'}>{error.general + ''}</span>
          </div>
        )}

        <div className={'addl-info-info-row'}>
          <div className={'checkbox-parent'}>
            <label className='checkbox-item'>
              <input
                className='mark-input'
                type='checkbox'
                checked={value && value.hasHeadline}
                onChange={(e) =>
                  onChange({ ...value, hasHeadline: e.target.checked })
                }
              />
              <span className='mark' />
            </label>
          </div>
          <div className={'label-parent'}>
            <span>Table has headline</span>
          </div>
        </div>

        <div className={'addl-info-info-row'}>
          <div className={'text-input-parent'}>
            <input
              type={'text'}
              value={value.columnSeparator || ''}
              onChange={(e) =>
                onChange({ ...value, columnSeparator: e.target.value })
              }
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'label-parent'}>
            <span>Column separator</span>
          </div>
          {touched && error && error.columnSeparator && (
            <div className={'error-container'}>
              <span className={'error'}>{error.columnSeparator + ''}</span>
            </div>
          )}
        </div>

        <div className={'addl-info-info-row'}>
          <div className={'text-input-parent'}>
            <input
              type={'text'}
              value={value.thousandsSeparator || ''}
              onChange={(e) =>
                onChange({ ...value, thousandsSeparator: e.target.value })
              }
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'label-parent'}>
            <span>Thousands separator</span>
          </div>
          {touched && error && error.thousandsSeparator && (
            <div className={'error-container'}>
              <span className={'error'}>{error.thousandsSeparator + ''}</span>
            </div>
          )}
        </div>

        <div className={'addl-info-info-row'}>
          <div className={'text-input-parent'}>
            <input
              type={'text'}
              value={value.decimalSeparator || ''}
              onChange={(e) =>
                onChange({ ...value, decimalSeparator: e.target.value })
              }
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'label-parent'}>
            <span>Decimal separator</span>
          </div>
          {touched && error && error.decimalSeparator && (
            <div className={'error-container'}>
              <span className={'error'}>{error.decimalSeparator + ''}</span>
            </div>
          )}
        </div>

        <div className={'addl-info-info-row'}>
          <div className={'dropdown-parent'}>
            <DropdownSelectInput
              id={'keyspace-select'}
              name={'keyspace-select'}
              options={options}
              placeholder={{ id: 'no-id', defaultMessage: 'Automatic' }}
              value={options.find((o) => o.value === value.charset)}
              onChange={(option) =>
                onChange({ ...value, charset: option.value })
              }
              openOnFocus
              className=''
              clearable={false}
              scrollMenuIntoView={false}
            />
          </div>
          <div className={'label-parent'}>
            <span>File encoding</span>
          </div>
        </div>
      </div>
    );
  }
}

StepAddlInfo.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.shape({
      hasHeadline: PropTypes.bool,
      columnSeparator: PropTypes.string,
      thousandsSeparator: PropTypes.string,
      decimalSeparator: PropTypes.string,
      charset: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
  }),
  meta: PropTypes.shape({
    valid: PropTypes.bool,
    error: PropTypes.object, // TODO or is it a string?
  }),
};
