import React, { FC } from 'react';
import { getX, getY } from './util';
import { DataType } from './LineChart';
import { ScaleLinear } from 'd3-scale';

type Props = {
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  data: DataType;
  stroke: string;
};

const StrokeCircles: FC<Props> = (props: Props) => {
  const { xScale, yScale, data, stroke } = props;
  return (
    <g>
      {data.map((item, index) => (
        <circle
          key={`strokeCircle_${index}`}
          stroke={stroke}
          r='3'
          fill='white'
          cx={xScale(getX(item))}
          cy={yScale(getY(item))}
        />
      ))}
    </g>
  );
};

export default StrokeCircles;
