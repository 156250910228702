import {
  CommunicationsErrorType,
  CommunicationsValueType,
} from './OptCommunications';
import { MessageDescriptor } from 'react-intl';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import { validateDescription, validateName } from '../optChannels/validate';
import moduleOptimizationMessages from 'common/dist/messages/module.optimization';
import { _globalValidationError } from '../../../../modelManagement/modules/implementations/optimization/new-augur/optimization.form';
import _ from 'lodash';
import { Communication } from 'common/dist/types/module.optimization';

export const numberRegex = /^-?\d+\.?\d*$/;

export const validateNumber = (
  number: string,
  valueName: string
): MessageDescriptor | null => {
  // The field is required
  if (!number) {
    return {
      id: 'no-id',
      defaultMessage: `Please enter the ${valueName}`, // TODO how can this be moved into common/src/messages?
    };
  }

  // Valid characters
  if (!numberRegex.test(number)) {
    return moduleOptimizationMessages.msgNewDatapoolStepCommunicationsErrInvalidNumber;
  }

  return null;
};

export const validateCommunications = (
  value: CommunicationsValueType
): CommunicationsErrorType => {
  const error = {};
  // Is there at least one communication added?
  if (!value || value.length === 0) {
    error[_globalValidationError] =
      moduleOptimizationMessages.msgNewDatapoolStepCommunicationsErrNoCommunications;
  } else {
    value?.forEach((communication) => {
      const constraintError = validateSingleCommunication(communication, value);
      if (!_.isEmpty(constraintError)) {
        error[communication.id] = constraintError;
      }
    });
  }
  return cleanErrorObject(error);
};

/**
 * Validation for the "sub form": add communication
 * @param value
 */
export const validateSingleCommunication = (
  communication: Partial<Communication>,
  allCommunications: Partial<Communication>[]
): CommunicationsErrorType => {
  const error = {};

  // Validate name
  error['name'] = validateName(
    communication.name,
    (allCommunications || [])
      .filter((x) => x.id !== communication.id)
      .map((comm) => comm.name)
  );
  // Validate description
  error['description'] = validateDescription(communication.description);

  // The channel is required
  if (!communication.channelId) {
    error['channelId'] =
      moduleOptimizationMessages.msgNewDatapoolStepCommunicationsErrNoChannel;
  }

  // The propensity is required
  if (!communication.propensityId) {
    error['propensityId'] =
      moduleOptimizationMessages.msgNewDatapoolStepCommunicationsErrNoPropensity;
  }

  // Validate Boost Factor
  error['boostFactor'] = validateNumber(
    communication.boostFactor,
    'Boost Factor'
  );

  // Validate Variable Costs
  error['variableCosts'] = validateNumber(
    communication.variableCosts,
    'Variable Costs'
  );

  return cleanErrorObject(error);
};
