import React, { FC, useEffect } from 'react';
import { WrappedFieldProps } from 'redux-form';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataSources } from '../../../../../redux/modules/data.dataSources.module';
import { DeprecatedRootState } from '../../../../../store/state.type';
import { DataSources } from '../../../../../store/dataManagement/state.types';
import {
  DataSource as DataSourceType,
  DSRoles,
  typeToSpeaking,
} from 'common/dist/types/dataManagement/dataSource';
import datapoolsMsgs from 'common/dist/messages/datapools';
import { OptionProps } from 'react-select';
import classNames from 'classnames';
import styles from '../../newAugurWizard/datapool/styles.module.scss';

export type Props = {
  dropdownSelectPortal?: HTMLElement;
};

type OptionType = DataSourceType;

type OptionComponentProps = OptionProps<OptionType, false>;
const OptionComponent: FC<OptionComponentProps> = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <div
      {...innerProps}
      className={classNames(styles.optionComponent, {
        [styles.isSelected]: props.isSelected,
      })}
      ref={innerRef}
      data-testingIdentifier={props.label}
    >
      <span className={styles.title}>{props.data.name}</span>
      <span className={styles.subtitle}>
        {typeToSpeaking(props.data.ds_type)}
      </span>
    </div>
  );
};

const DataSource: FC<Props & WrappedFieldProps> = ({
  dropdownSelectPortal,
  input: { value, onChange, onBlur, onFocus },
  meta: { touched, error },
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataSources());
  }, [dispatch]);

  const dataSources = useSelector<DeprecatedRootState, DataSources>(
    (state) => state.data.dataSources
  );
  const options = dataSources.data
    .filter((dataSource) =>
      [DSRoles.DefaultCassandra, DSRoles.HostMount, DSRoles.RGW].includes(
        dataSource.role
      )
    )
    .map((o) => ({
      ...o,
      label: o.name,
      value: o.code,
    }));

  return (
    <div>
      <DropdownSelectInput
        id={'dataSourceCode'}
        name={'dataSourceCode'}
        touched={touched}
        error={error}
        valid={true}
        disabled={false}
        label={datapoolsMsgs.newDatapoolStepDataSourceLabel}
        placeholder={datapoolsMsgs.newDatapoolStepDataSourcePlaceholder}
        value={options.find((o) => o.code === value)}
        onChange={(option: OptionType) => onChange(option.code)}
        onFocus={onFocus}
        onBlur={() => onBlur(value)}
        options={options}
        menuPortalTarget={dropdownSelectPortal}
        customComponents={{ Option: OptionComponent }}
      />
    </div>
  );
};

export default DataSource;
