import { connect } from 'react-redux';
import JobLogs from './JobLogs';
import { fetchJobLogs } from '../../../redux/modules/orchestration.jobdetails.module';

export function mapStateToProps(state) {
  return {
    data: (state.jobLogs || {}).data || [],
    loading: (state.jobLogs || {}).loading,
    loaded: (state.jobLogs || {}).loaded,
    error: (state.jobLogs || {}).error,
    origin: state.jobDetails.origin,
  };
}

export const mapDispatchToProps = {
  fetchJobLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobLogs);
