import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import orchestrationMessages from 'common/dist/messages/orchestration';
import { CProps } from './JobGroupSchedule.container';
import { orchestrationRoutes } from '../../../orchestration/routes';
import JobGroupTopologyChart from '../job-group-topology-chart/JobGroupTopologyChart';
import IconButton from '../../../atoms/icon-button/IconButton';
import { FiEdit, FiTrash2 } from 'react-icons/all';
import { JobGroupInputType, JobScheduleType } from 'common/dist/types/job';
import AvatarIconContainer from '../../../atoms/avatar-icon/AvatarIcon.container';
import { useHistory } from 'react-router-dom';
import { JOB_GROUP_VARIANTS } from '../../../orchestration/details/job-group-details/JobGroupDetails';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { setDetailsOrigin } from '../../../../redux/modules/orchestration.jobdetails.module';
import ScheduleInfo from '../../../orchestration/job-schedules/ScheduleInfo';

export type Props = {
  /** Code of the schedule to display */
  scheduleCode: string;
  /** The schedule to render */
  schedule: {
    /** Description of the scheduled JobGroup */
    jobGroupInput: JobGroupInputType;
  } & JobScheduleType;
};

/**
 * Renders a single schedule
 */
const JobGroupSchedule: FC<Props & CProps> = ({
  schedule,
  scheduleCode,
  showDeleteSchedule,
  augurNames,
  datapoolNames,
  codeCapsuleNames,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const { jobGroupInput: jobGroup, createdBy } = schedule;

  return (
    <div
      className={classNames(styles.item, styles.schedule)}
      onClick={() => {
        dispatch(setDetailsOrigin(history.location.pathname));
        history.push(
          `${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobSchedule}/${schedule.scheduleCode}`
        );
      }}
    >
      <div
        className={styles.topology}
        style={{
          gridRow: '1',
          gridColumn: '1',
        }}
      >
        <JobGroupTopologyChart
          jobs={jobGroup.jobs}
          jobGroupTopology={jobGroup.jobGroupTopology}
          showJobStatus={false}
          augurNames={augurNames}
          datapoolNames={datapoolNames}
          codeCapsuleNames={codeCapsuleNames}
          slim
        />
      </div>
      <div
        className={styles.info}
        style={{
          gridRow: '1',
          gridColumn: '2',
        }}
      >
        <strong className={styles.field}>{jobGroup.name || ''}</strong>
        <div className={styles.description}>
          <div className={styles.box}>{jobGroup.description || ''}</div>
          <div className={styles.tooltip}>
            <span className={styles.tooltipText}>
              {jobGroup.description || ''}
            </span>
          </div>
        </div>
      </div>
      <div
        className={styles.info}
        style={{
          gridRow: '1',
          gridColumn: '3',
        }}
      >
        <ScheduleInfo
          trigger={schedule.trigger}
          trigDelayed={schedule.trigDelayed}
          trigTimed={schedule.trigTimed}
        />
      </div>
      <div
        className={styles.userIcon}
        style={{
          gridRow: '1',
          gridColumn: '4',
        }}
      >
        {createdBy && <AvatarIconContainer userId={createdBy} />}
      </div>
      <div
        className={styles.buttons}
        style={{
          gridRow: '1',
          gridColumn: '5',
        }}
      >
        <div
          className={styles.buttonsWrapper}
          onClick={(evt) => evt.stopPropagation()}
        >
          <IconButton
            Icon={FiEdit}
            size={18}
            linkTo={`${orchestrationRoutes.basePath}/${orchestrationRoutes.editSchedule.path}/${scheduleCode}`}
            title={intl.formatMessage(orchestrationMessages.scheduleEdit)}
          />
          <IconButton
            Icon={FiTrash2}
            size={18}
            onClick={() => showDeleteSchedule(scheduleCode)}
            title={intl.formatMessage(orchestrationMessages.scheduleDelete)}
          />
        </div>
      </div>
    </div>
  );
};

export default JobGroupSchedule;
