import React, { FC } from 'react';
import BarChart from '../../../../../../../components/molecules/charts/bar-chart/BarChart';
import augurDetailsMsgs from 'common/dist/messages/augurs.details';

type Props = {
  detailsLink: string;
  data: [number, number][];
};

const PredictedValues: FC<Props> = (props: Props) => {
  const { detailsLink, data } = props;
  return (
    <div className={'Accuracy--chart-container'}>
      <BarChart
        height={'100%'}
        width={'100%'}
        data={data}
        title={{
          id: 'details.accuracy.distribution_of_predicted_values',
          defaultMessage: 'Predicted Values (Evaluation Slice)',
        }}
        yLabel={augurDetailsMsgs.msgAbsoluteAmount}
        xLabel={augurDetailsMsgs.msgScoreBins}
        // @ts-ignore
        link={{
          to: detailsLink,
        }}
        withBorder={true}
      />
    </div>
  );
};

export default PredictedValues;
