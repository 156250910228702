/**
 * Helper function do download a file
 * @param filename
 * @param text
 * @param mimetype
 */
export function downloadContent(filename, content, mimetype) {
  const element = document.createElement('a');
  const blob = new Blob([content], { type: mimetype });
  element.setAttribute('href', URL.createObjectURL(blob));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function download(filename: string, url: string): void {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
