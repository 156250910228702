import { ComponentType } from 'react';
import { ComponentDatapoolDetailsTab } from '../../_interfaces/datapool-details/DatapoolDetailsTab';
import TaxonomyIcon from './TaxonomyIcon';
import { DatapoolType } from 'common/dist/types/datapool';
import TargetTaxonomy from '../../../../../organisms/targetTaxonomy/TargetTaxonomy.container';

export default class TaxonomyWrapper extends ComponentDatapoolDetailsTab {
  type: 'component' = 'component';
  id = 'taxonomy';
  title = {
    id: 'datapoolDetails.sideNav.taxonomy',
    defaultMessage: 'Taxonomy',
  };
  to = (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${shortDatapoolType}/taxonomy`;

  icon: ComponentType = TaxonomyIcon;
  component = TargetTaxonomy;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType) => true;
}
