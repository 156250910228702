import { connect } from 'react-redux';
import ConfigureCodeCapsuleModal from './ConfigureCodeCapsuleModal';
import { reset, getFormValues } from 'redux-form';
import { formName } from './form';
import {
  hideConfigureCodeCapsuleModal,
  updateCodeCapsuleConfiguration,
} from '../../../../redux/workbench/modules/codeCapsule.module';

export function mapStateToProps(state) {
  const path = (state.workbench.codeCapsule || {}).path;
  const repoMeta = state.workbench.content.repoMeta;
  const { resources, parameters } = repoMeta;
  const initialValues = { resources, parameters };
  return {
    isShown: (state.workbench.codeCapsule || {}).isConfigureModalShown,
    path,
    formValues: getFormValues(formName)(state),
    initialValues,
    repoMeta,
  };
}

export const mapDispatchToProps = {
  hideConfigureCodeCapsuleModal,
  updateCodeCapsuleConfiguration,
  resetForm: () => reset(formName),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureCodeCapsuleModal);
