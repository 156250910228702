export function toggleState(state, payload) {
  const lineId = payload.value;
  let mapIndex = 1;
  function toggleIsSelected(line) {
    const isSelected = lineId !== line.id ? line.isSelected : !line.isSelected;
    return {
      ...line,
      mapIndex: isSelected ? mapIndex++ : '',
      isSelected,
    };
  }
  return {
    ...state,
    chart: {
      ...state.chart,
      line: {
        ...state.chart.line,
        data: state.chart.line.data.map(toggleIsSelected),
      },
    },
  };
}
