import React, { Component } from 'react';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FiDroplet } from 'react-icons/fi';
// @ts-ignore
import { Portal } from 'react-portal';

interface Props {
  menuId: string;
  onSelectAll: () => void;
}

export default class ContextMenuCategory extends Component<Props> {
  render() {
    const { menuId, onSelectAll } = this.props;

    // The <ContextMenu> needs to be wrapped in a <Portal> to make sure it's placed at the correct position.
    // See: https://github.com/vkbansal/react-contextmenu/issues/193#issuecomment-394218334
    return (
      <Portal>
        <ContextMenu id={menuId} className={'context-menu'}>
          <MenuItem>
            <div className={'cm-item-container'} onClick={onSelectAll}>
              <FiDroplet className={'context-menu-icon'} />
              <p className={'context-menu-text'}>Mark all</p>
            </div>
          </MenuItem>
        </ContextMenu>
      </Portal>
    );
  }
}
