import * as React from 'react';

function SvgArchetype(props) {
  return (
    <svg height='1em' viewBox='0 0 512 512' width='1em' {...props}>
      <path
        fill='#eef0f2'
        d='M512 256c0 141.387-114.613 256-256 256S0 397.387 0 256 114.613 0 256 0s256 114.613 256 256zm0 0'
      />
      <path
        fill='#dce2eb'
        d='M512 256c0-6.313-.238-12.57-.688-18.77C459.13 185.008 360.212 86.266 360.212 86.266c-24.563-27.172-60.078-44.25-99.594-44.25-74.12 0-134.21 60.09-134.21 134.214 0 .696.015 1.391.027 2.086 0 1.582.046 3.176.144 4.778 0 .023.004.047.004.07.02.34.023.676.05 1.02l.075.933c.09 1.395.2 2.781.332 4.16l26.926 162.657v97.613l58.8 58.8A257.652 257.652 0 00256 512c141.387 0 256-114.613 256-256zm0 0'
      />
      <path
        fill='#ffdba9'
        d='M422.527 242.527l-23.156-55.433c-2.8-6.7-4.379-13.828-4.922-21.074-5.215-69.356-63.14-124.004-133.832-124.004-74.12 0-134.21 60.09-134.21 134.214 0 .696.015 1.391.027 2.086 0 1.582.046 3.176.144 4.778 0 .023.004.047.004.07.02.34.023.676.05 1.02l.075.933c.09 1.395.2 2.781.332 4.16l26.926 162.657v97.613H289.21v-71.98c0-10.563 8.562-19.126 19.125-19.126h34.102c24.296 0 43.996-19.699 43.996-43.996v-49.28h21.015c11.668 0 19.578-11.872 15.078-22.638zm0 0'
      />
      <path
        fill='#ffc473'
        d='M407.45 265.164h-21.016v49.281c0 24.293-19.704 43.996-43.996 43.996h-34.102c-10.563 0-19.125 8.563-19.125 19.122v71.984h-30.816V42.039c.742-.008 1.484-.02 2.23-.02 70.684 0 128.613 54.653 133.824 123.997.543 7.246 2.125 14.375 4.922 21.082l23.152 55.425c4.5 10.77-3.402 22.641-15.074 22.641zm0 0'
      />
      <path
        fill='#224e90'
        d='M345.977 192.227v-41.18h-17.493a72.178 72.178 0 00-5.957-14.422l12.352-12.352-29.117-29.12-12.356 12.35a72.178 72.178 0 00-14.422-5.956V84.059h-41.18v17.488a72.178 72.178 0 00-14.421 5.957l-12.356-12.352-29.117 29.121 12.352 12.352a72.178 72.178 0 00-5.957 14.422h-17.493v41.18h17.493a72.307 72.307 0 005.957 14.425l-12.352 12.352 29.117 29.117 12.356-12.351a71.95 71.95 0 0014.422 5.957v17.492h41.18v-17.492a71.95 71.95 0 0014.421-5.957l12.356 12.351 29.117-29.117-12.352-12.352a72.307 72.307 0 005.957-14.425h17.493zm-87.582-37.336c9.253 0 16.746 7.496 16.746 16.746 0 9.254-7.493 16.75-16.746 16.75-9.254 0-16.75-7.496-16.75-16.75 0-9.25 7.496-16.746 16.75-16.746zm0 0'
      />
      <path
        fill='#224e90'
        d='M345.977 192.227v-41.18h-17.493a72.178 72.178 0 00-5.957-14.422l12.352-12.352-29.117-29.12-12.356 12.35a72.178 72.178 0 00-14.422-5.956V84.059h-20.59v70.832c9.254 0 16.75 7.496 16.75 16.746 0 9.254-7.496 16.75-16.75 16.75v70.832h20.59v-17.492a71.937 71.937 0 0014.422-5.954l12.356 12.352 29.117-29.121-12.352-12.352a72.117 72.117 0 005.957-14.425h17.493zm0 0'
      />
      <path
        fill='#6aa0eb'
        d='M258.395 108.832c-34.692 0-62.805 28.117-62.805 62.805 0 34.691 28.113 62.804 62.805 62.804 34.687 0 62.804-28.113 62.804-62.804 0-34.688-28.117-62.805-62.804-62.805zm0 79.555c-9.254 0-16.75-7.496-16.75-16.75 0-9.25 7.496-16.746 16.75-16.746 9.253 0 16.746 7.496 16.746 16.746 0 9.254-7.493 16.75-16.746 16.75zm0 0'
      />
      <path
        fill='#6aa0eb'
        d='M258.395 108.832v46.059c9.253 0 16.746 7.496 16.746 16.746 0 9.254-7.493 16.75-16.746 16.75v46.054c34.687 0 62.804-28.113 62.804-62.804 0-34.688-28.117-62.805-62.804-62.805zm0 0'
      />
      <path
        fill='#224e90'
        d='M258.395 130.113c-22.934 0-41.524 18.59-41.524 41.524 0 22.933 18.59 41.523 41.524 41.523 22.933 0 41.523-18.59 41.523-41.523 0-22.934-18.59-41.524-41.523-41.524zm0 58.274c-9.254 0-16.75-7.496-16.75-16.75 0-9.25 7.496-16.746 16.75-16.746 9.253 0 16.746 7.496 16.746 16.746 0 9.254-7.493 16.75-16.746 16.75zm0 0'
      />
      <path
        fill='#224e90'
        d='M258.395 130.113v24.778c9.253 0 16.746 7.496 16.746 16.746 0 9.254-7.493 16.75-16.746 16.75v24.773c22.933 0 41.523-18.59 41.523-41.523 0-22.934-18.59-41.524-41.523-41.524zm0 0'
      />
    </svg>
  );
}

export default SvgArchetype;
