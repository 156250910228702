export const maintenanceRoutes = {
  basePath: '/app/admin/maintenance',

  backups: {
    path: 'backups',
  },

  templates: {
    path: 'templates',
  },

  maintenance: {
    path: 'maintenance',
  },
};
