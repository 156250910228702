import { createAction } from 'redux-act';

export const selectTab = createAction(
  'select orchestration tab',
  (selected) => selected
);

export const reducer = {
  [selectTab]: (state, selected) => ({
    ...state,
    orchestration: {
      ...state.orchestration,
      selectedCategory: selected,
    },
  }),
};
