import { connect } from 'react-redux';

import Bar from './Bar';
import {
  showToolTip,
  hideToolTip,
  moveToolTip,
} from '../../../../../../../../redux/modules/misclassification.tooltip.module';

export function mapStateToProps(state, { xScale, height, data }) {
  return {
    xScale,
    height,
    data,
  };
}

export const mapDispatchToProps = {
  showToolTip,
  hideToolTip,
  moveToolTip,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bar);
