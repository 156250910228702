import React from 'react';
import P3InputElement from '../_interface/P3InputElement';
import DatetimePicker from './DatetimePicker';
import DatetimePickerSettings from './DatetimePickerSettings';
import {
  getSourceDate,
  validate,
  validateSettings,
} from './datetimeCommonFunctions';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';
import vars from '../../../../../../../../../../../../scss/base/var.module.scss';

export default class DatetimePickerWrapper extends P3InputElement {
  // --- Components
  ChildComponent = (props: object) => (
    <DatetimePicker useDate useTime={false} {...props} />
  );
  AdditionalSettings: any = (props: object) => (
    <DatetimePickerSettings
      // @ts-ignore
      useDate
      useTime={false}
      {...props}
    />
  );
  // icon: any = DatetimePickerIcon;
  icon: any = DefaultElementIcon;
  iconColor = vars.colorPrimary;

  // --- AttributesComponent
  name = 'Date Picker';
  parentContainerClass = 'datetime-picker';
  type = 'date-picker';

  // --- Functions
  getSource = getSourceDate;
  validate = validate;
  validateSettings = validateSettings;
}
