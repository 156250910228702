import React, { Component } from 'react';
import DropdownSelectInput from '../../../../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { WithTableSampleProps } from '../../../../../../../../organisms/modelManagement/newAugurWizard/commonComponents/withTableSample/WithTableSample';
import { FieldPredictionTableValues } from './tableSelection.form';
import { TypedWrappedFieldProps } from '../../../../../../../../../utils';

type OptionType = { value: string; label: string };

export type Props = {
  showTablePreview: () => void;
};

export default class PredictionTableSelectField extends Component<
  Props &
    TypedWrappedFieldProps<FieldPredictionTableValues> &
    WithTableSampleProps
> {
  render() {
    const {
      tablesLoading,
      tablesLoaded,
      tables,
      showTablePreview,
      input: { value, onChange, onBlur, onFocus },
      meta: { error, touched },
    } = this.props;

    const tableOptions: OptionType[] =
      tablesLoading || !tables
        ? []
        : tables.map((table) => ({
            label: table.name,
            value: table.name,
          }));

    return (
      <div
        className={'GenericFormStep--field'}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <DropdownSelectInput
            id={'predictionTable'}
            name={'predictionTable'}
            touched={touched}
            error={error}
            valid={true}
            disabled={!tablesLoaded}
            label={{
              id: 'newAugur.training_table.select_prediction_table',
              defaultMessage: 'Select the Prediction Table',
            }}
            placeholder={{
              id: 'newAugur.training_table.no_table_selected',
              defaultMessage: 'No Table selected',
            }}
            value={tableOptions.find((o) => o.value === value?.tableName)}
            onChange={(option: OptionType) =>
              onChange({ tableName: option.value })
            }
            onFocus={onFocus}
            isLoading={tablesLoading}
            options={tableOptions}
            scrollMenuIntoView={false}
          />
        </div>

        <div
          title={'Show Preview'}
          className={
            'form--icon-button ' + (value.tableName ? 'enabled' : 'disabled')
          }
          style={{
            marginLeft: '10px',
          }}
          onClick={() => {
            if (value.tableName) {
              showTablePreview();
            }
          }}
        >
          <span className={'icon-tables'} style={{ fontSize: '16px' }} />
        </div>
      </div>
    );
  }
}
