import { connect } from 'react-redux';
import WizardCommit from './WizardCommit';
import { destroy, getFormValues, getFormSyncErrors } from 'redux-form';
import { formName } from './form';
import { formName as uploadFormName } from '../wizard-upload/form';
import {
  commitUpload,
  requestCommitInfo,
  setActiveUploadWizard,
} from '../../../../../redux/modules/data.cassandra.module';
import { DeprecatedRootState } from '../../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  const { loading, loaded, error, data } = state.data.upload.commitInfo || {};
  const uploadCode = state.data.upload.uploadCode;
  return {
    formValues: getFormValues(formName)(state),
    formErrors: getFormSyncErrors(formName)(state),
    loading,
    loaded,
    error,
    data,
    uploadCode,
  };
}

export const mapDispatchToProps = {
  requestCommitInfo,
  commitUpload,
  setActiveUploadWizard,
  destroyUploadForm: () => destroy(uploadFormName),
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardCommit);
