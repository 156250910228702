import { ConfigProps, FormErrors } from 'redux-form';
import { JobGroupInputType } from 'common/dist/types/job';
import { validateJobGroup } from 'common/dist/validation/addSchedule';
import { MessageDescriptor } from 'react-intl';
import { Props } from './JobGroupWizard';

export const formName = 'addEditJobGroup';

export const fieldName = 'name';
export const fieldDescription = 'description';
export const fieldJobGroup = 'jobGroup';

export interface Data {
  [fieldName]: string;
  [fieldDescription]: string;
  [fieldJobGroup]: Pick<JobGroupInputType, 'jobs' | 'jobGroupTopology'>;
}

export type ErrorType = MessageDescriptor;

export type Errors = FormErrors<Data, ErrorType>;

export function validate(values: Data): Errors {
  let errors: Errors = {};

  // --- Validate name
  const name = values[fieldName];
  if (!name) {
    errors = {
      ...errors,
      [fieldName]: { id: 'todo', defaultMessage: 'Please enter a name.' },
    };
  }

  // --- Validate description
  const description = values[fieldDescription];
  if (!description) {
    errors = {
      ...errors,
      [fieldDescription]: {
        id: 'todo',
        defaultMessage: 'Please enter a description.',
      },
    };
  }

  // --- Validate the JobGroup
  // TODO the error codes are borrowed from the schedule wizard
  if (values[fieldJobGroup] === undefined) {
    Object.assign(errors, {
      [fieldJobGroup]: {
        id: 'orchestration.newAugur.schedule.error.jobs_empty',
        defaultMessage: 'Jobs must not be empty.',
      },
    });
  } else if (values[fieldJobGroup]) {
    Object.assign(errors, {
      [fieldJobGroup]: validateJobGroup(values[fieldJobGroup]),
    });
  }

  return errors;
}

export const form: ConfigProps<Data, Props, ErrorType> = {
  form: formName,
  validate,
  /** Set because we don't wait to initialize the form until all calls are done */
  enableReinitialize: true,
  /** To keep the other fields and their errors, while the job group editor is shown*/
  destroyOnUnmount: false,
  /** Because the jobGroup field has no default touch behavior */
  touchOnChange: true,
};
