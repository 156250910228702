import React, { CSSProperties, FC } from 'react';
import { Column, useTable } from 'react-table';
import TableHeadlineCell from './TableHeadlineCell';
import { ValueType } from './CassandraTablePreview';
import { ColSpec } from '../../../store/dataManagement/state.types';

type DataType = { [colName: string]: string };

export interface HighlightStyle extends CSSProperties {
  colStyle?: CSSProperties;
  colType?: CSSProperties;
  colName?: CSSProperties;
}

export type ExtendedColSpec = ColSpec & {
  /** Frontend only, not in redux, just attached to this object when building the component */
  highlightStyle?: HighlightStyle;
};

/**
 * Transforms the input data into the structure that is expected by react-table
 * See: https://react-table.tanstack.com/docs/quick-start
 * @param colSpecs
 * @param data
 * @returns {{shapedCols: [], shapedData: []}}
 */
function toReactTableStructure(
  colSpecs: ExtendedColSpec[],
  data: string[][]
): {
  shapedCols: Column<DataType>[];
  shapedData: DataType[];
} {
  if (!colSpecs || !data) {
    // early exit
    return { shapedCols: [], shapedData: [] };
  }

  const shapedCols: Column<DataType>[] = colSpecs.map((col, i) => ({
    Header: col.colName,
    Type: col.colType,
    highlightStyle: col.highlightStyle, // This is a custom field, that is not used by react-table, only for passing around
    accessor: String(i), // To allow duplicate column names
  }));

  const shapedData: DataType[] = data.map((row) => {
    const shapedRow = {};
    colSpecs.forEach((colSpec, i) => {
      if (i >= row.length) shapedRow[i] = '#'; // Fallback for short rows
      else shapedRow[i] = row[i];
    });
    return shapedRow;
  });

  return { shapedCols, shapedData };
}

export type Props = {
  /** Is the data preview editable? */
  editable: boolean;
  /** Is the data preview currently being edited? */
  editing: boolean;
  /** "input" object that is required if the preview is editable */
  input?: {
    value: ValueType;
    onChange: (value: ValueType) => void;
  };

  /** List of all columns */
  colSpecs: ExtendedColSpec[];

  /** List of data */
  data: string[][];
};

const Table: FC<Props> = (props: Props) => {
  const { colSpecs, data, editable, input, editing } = props;

  if (colSpecs.length > 250) {
    console.warn(
      'Tables this large may cause performance issues. Consider using big-table/Table instead.'
    );
  }

  const { shapedCols, shapedData } = React.useMemo(
    () => toReactTableStructure(colSpecs, data),
    [colSpecs, data]
  );
  const tableInstance = useTable({ columns: shapedCols, data: shapedData });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <table {...getTableProps()}>
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column, i) => (
                  // Apply the header cell props
                  <TableHeadlineCell
                    key={i}
                    column={column}
                    input={input}
                    editable={editable}
                    editing={editing}
                  />
                ))
              }
            </tr>
          ))
        }
      </thead>
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => (
                    // Apply the cell props
                    <td {...cell.getCellProps()}>
                      {
                        // Render the cell contents
                        cell.render('Cell')
                      }
                    </td>
                  ))
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default Table;
