import React, { FC } from 'react';
import styles from './styles.module.scss';
import commonStyles from '../../newDatapoolWizard/optChannels/optCommonStyles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import DropdownSelectInput from '../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import { SPEAKING_SUM_OF, SPEAKING_SUM_OVER } from './common';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import classNames from 'classnames';
import { TargetFunctionSummandGroup } from 'common/dist/types/module.optimization';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import { FiMinus } from 'react-icons/fi';

type Option = { label: string; value: string };

export type Props = {
  rowIndex?: number;
  constraintId: string;
  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: TargetFunctionSummandGroup) => void;
};

const summandGroupSumOfOptions: Option[] = ['costs', 'values'].map((c) => ({
  label: SPEAKING_SUM_OF[c],
  value: c,
}));

const summandGroupSumOverOptions: Option[] = ['allCommunications'].map((c) => ({
  label: SPEAKING_SUM_OVER[c],
  value: c,
}));
const drvNewValue = (
  input: WrappedFieldInputProps,
  eventValue: string,
  key: string,
  rowIndex: number
) => {
  return {
    ...input.value,
    summandGroups: [
      ...input.value?.summandGroups?.slice(0, rowIndex),
      {
        ...(input.value?.summandGroups?.[rowIndex] || {}),
        [key]: eventValue,
      },
      ...input.value?.summandGroups?.slice(rowIndex + 1),
    ],
  };
};
const renderNameField = (props: Props & WrappedFieldProps) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optTargetFunction_name'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.name}
      valid={!meta.error?.[constraintId]?.name}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Name'}
      placeholderId={'no-id'}
      placeholderDefault={'Name'}
      value={input.value?.summandGroups?.[rowIndex]?.name}
      onChange={(e) => {
        const newValue = drvNewValue(input, e.target.value, 'name', rowIndex);
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderDescriptionField = (props: Props & WrappedFieldProps) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optTargetFunction_description'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.description}
      valid={!meta.error?.[constraintId]?.description}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Description'}
      placeholderId={'no-id'}
      placeholderDefault={'Description'}
      value={input.value?.summandGroups?.[rowIndex]?.description}
      onChange={(e) => {
        const newValue = drvNewValue(
          input,
          e.target.value,
          'description',
          rowIndex
        );
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const renderSumOfField = (props: Props & WrappedFieldProps) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <DropdownSelectInput
      id={'optTargetFunction_sumOf'}
      label={{
        id: 'no-id',
        defaultMessage: 'Sum Of',
      }}
      placeholder={{
        id: 'no-id',
        defaultMessage: 'Please select Sum Of',
      }}
      options={summandGroupSumOfOptions}
      onFocus={input.onFocus}
      onBlur={() => input.onBlur(input.value)}
      onChange={(option: Option) => {
        const newValue = drvNewValue(input, option.value, 'sumOf', rowIndex);
        input.onChange(newValue);
      }}
      value={summandGroupSumOfOptions.find(
        (o) => o.value === input.value?.summandGroups?.[rowIndex]?.sumOf
      )}
      error={meta.error?.[constraintId]?.sumOf}
      touched={meta.touched}
      valid={!meta.error?.[constraintId]?.sumOf}
    />
  );
};

const renderSumOverField = (props: Props & WrappedFieldProps) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <DropdownSelectInput
      id={'optTargetFunction_sumOver'}
      label={{
        id: 'no-id',
        defaultMessage: 'Sum Over',
      }}
      placeholder={{
        id: 'no-id',
        defaultMessage: 'Please select Sum Over',
      }}
      options={summandGroupSumOverOptions}
      onFocus={input.onFocus}
      onBlur={() => input.onBlur(input.value)}
      onChange={(option: Option) => {
        const newValue = drvNewValue(input, option.value, 'sumOver', rowIndex);
        input.onChange(newValue);
      }}
      value={summandGroupSumOverOptions.find(
        (o) => o.value === input.value?.summandGroups?.[rowIndex]?.sumOver
      )}
      error={meta.error?.[constraintId]?.sumOver}
      touched={meta.touched}
      valid={!meta.error?.[constraintId]?.sumOver}
    />
  );
};

const renderFactorField = (props: Props & WrappedFieldProps) => {
  const { input, meta, rowIndex, constraintId } = props;
  return (
    <TextInputLine
      id={'optTargetFunction_factor'}
      touched={meta.touched}
      error={meta.error?.[constraintId]?.factor}
      valid={!meta.error?.[constraintId]?.factor}
      hasLabel={true}
      labelId={'no-id'}
      labelDefault={'Factor'}
      placeholderId={'no-id'}
      placeholderDefault={'1'}
      value={input.value?.summandGroups?.[rowIndex]?.factor}
      onChange={(e) => {
        const newValue = drvNewValue(input, e.target.value, 'factor', rowIndex);
        input.onChange(newValue);
      }}
      onBlur={() => input.onBlur(input.value)}
      onFocus={input.onFocus}
    />
  );
};

const AddSummandGroup: FC<Props & WrappedFieldProps> = (props) => {
  const { input, rowIndex, removableRows, onRemoveRow } = props;
  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={commonStyles.RemoveIcon}
            onClick={() =>
              onRemoveRow && onRemoveRow(input.value.summandGroups[rowIndex])
            }
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div className={commonStyles.addElementField}>
          {renderSumOverField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderSumOfField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderFactorField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderNameField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderDescriptionField(props)}
        </div>
      </div>
    </div>
  );
};

export default AddSummandGroup;
