import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AvatarIcon, { Props as AvatarIconProps } from './AvatarIcon';
import {
  fetchUserSummaryById,
  getUserById,
  User,
} from '../../../store/users/slice';
import { RootState, useAppDispatch } from '../../../store/store';

export type Props = Pick<AvatarIconProps, 'userId'>;

const AvatarIconContainer: FC<Props> = ({ userId }) => {
  const user = useSelector<RootState, User | undefined>((state) =>
    getUserById(state.users, userId)
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!user) {
      dispatch(fetchUserSummaryById({ userId }));
    }
  }, [dispatch, user, userId]);
  if (!user?.loaded) {
    return null;
  }
  return (
    <AvatarIcon
      userId={userId}
      firstName={user.data?.firstName}
      lastName={user.data?.lastName}
      isTechnical={user.data?.isTechnical}
      photo={user.data?.avatar}
    />
  );
};

export default AvatarIconContainer;
