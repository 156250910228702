import PropTypes from 'prop-types';
import React from 'react';

import AxisLeft from './AxisLeft';
import BarsContainer from './Bars';
import TooltipContainer from './Tooltip.container';

function MisclassificationChart({ width, height, top, left, data }) {
  const margin = {
    top: 20,
    right: 0,
    bottom: 20,
    left: 30,
  };

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const axisLeftWidth = 10;
  const barsWidth = chartWidth - axisLeftWidth;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <svg
        width={width}
        height={height}
        style={{ width: '100%', height: '100%' }}
      >
        <clipPath id='barsClipPath'>
          <rect width={barsWidth} height={chartHeight} />
        </clipPath>
        <g
          transform={`translate(${axisLeftWidth + margin.left}, ${margin.top})`}
        >
          <rect
            width={barsWidth}
            height={chartHeight}
            stroke='#DBE1EB'
            fill='none'
          />
          <AxisLeft ticks={6} tickSize={barsWidth} height={chartHeight} />
          <g clipPath='url(#barsClipPath)'>
            <BarsContainer data={data} height={chartHeight} width={barsWidth} />
          </g>
        </g>
      </svg>
      <TooltipContainer
        top={top}
        left={left}
        containerWidth={width}
        containerHeight={height}
        height={40}
        width={170}
      />
    </div>
  );
}

MisclassificationChart.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
};

export default MisclassificationChart;
