import { createAction } from 'redux-act';

import * as tree from '../../components/organisms/binary-tree-shadow-model/treeChart/tree';
import { initial } from '../state/chart.state';

export const selectNode = createAction('select decision tree node', (node) => ({
  node,
}));

export const deselectNode = createAction(
  'deselect decision tree node',
  (node) => ({ node })
);

export const showSelectedNodePath = createAction(
  'show selected decision tree node path'
);

export const hideSelectedNodePath = createAction(
  'hide selected decision tree node path'
);

export const selectPreviewNode = createAction(
  'select decision tree node for preview',
  (node) => ({ node })
);

export const deselectPreviewNode = createAction(
  'deselect decision tree node for preview',
  (node) => ({ node })
);

export const showPreviewNodePath = createAction(
  'show preview decision tree node path'
);

export const hidePreviewNodePath = createAction(
  'hide preview decision tree node path'
);

export const reset = createAction('reset tree node state');

export const reducer = {
  [selectNode]: (state, { node }) => ({
    ...state,
    chart: {
      ...state.chart,
      active: {
        ...state.chart.active,
        tree: {
          ...state.chart.active.tree,
          selected: {
            ...state.chart.active.tree.selected,
            node,
          },
        },
      },
    },
  }),

  [deselectNode]: (state) => ({
    ...state,
    chart: {
      ...state.chart,
      active: {
        ...state.chart.active,
        tree: {
          ...state.chart.active.tree,
          selected: {
            ...state.chart.active.tree.selected,
            node: tree.DEFAULT_NODE,
          },
        },
      },
    },
  }),

  [selectPreviewNode]: (state, { node }) => ({
    ...state,
    chart: {
      ...state.chart,
      active: {
        ...state.chart.active,
        tree: {
          ...state.chart.active.tree,
          preview: {
            ...state.chart.active.tree.preview,
            node,
          },
        },
      },
    },
  }),

  [deselectPreviewNode]: (state) => ({
    ...state,
    chart: {
      ...state.chart,
      active: {
        ...state.chart.active,
        tree: {
          ...state.chart.active.tree,
          preview: {
            ...state.chart.active.tree.preview,
            node: tree.DEFAULT_NODE,
          },
        },
      },
    },
  }),

  [showSelectedNodePath]: (state) => {
    const path = tree.getPath(state.chart.active.tree.selected.node);

    return {
      ...state,
      chart: {
        ...state.chart,
        active: {
          ...state.chart.active,
          tree: {
            ...state.chart.active.tree,
            selected: {
              ...state.chart.active.tree.selected,
              path,
            },
          },
        },
      },
    };
  },

  [hideSelectedNodePath]: (state) => ({
    ...state,
    chart: {
      ...state.chart,
      active: {
        ...state.chart.active,
        tree: {
          ...state.chart.active.tree,
          selected: {
            ...state.chart.active.tree.selected,
            path: tree.DEFAULT_PATH,
          },
        },
      },
    },
  }),

  [showPreviewNodePath]: (state) => {
    const path = tree.getPath(state.chart.active.tree.preview.node);

    return {
      ...state,
      chart: {
        ...state.chart,
        active: {
          ...state.chart.active,
          tree: {
            ...state.chart.active.tree,
            preview: {
              ...state.chart.active.tree.preview,
              path,
            },
          },
        },
      },
    };
  },

  [hidePreviewNodePath]: (state) => ({
    ...state,
    chart: {
      ...state.chart,
      active: {
        ...state.chart.active,
        tree: {
          ...state.chart.active.tree,
          preview: {
            ...state.chart.active.tree.preview,
            path: tree.DEFAULT_PATH,
          },
        },
      },
    },
  }),

  [reset]: (state) => ({
    ...state,
    chart: {
      ...state.chart,
      active: {
        ...state.chart.active,
        tree: {
          ...initial.active.tree,
        },
      },
    },
  }),
};
