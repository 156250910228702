import { createAction } from 'redux-act';
import * as Api from '../../core/api';
import { put, call, takeEvery } from 'redux-saga/effects';

export const fetchJobConfigs = createAction('fetch job configuration summary');

export const fetchJobConfigsSuccess = createAction(
  'fetch job configuration summary - success',
  (data) => data
);

export const fetchJobConfigsError = createAction(
  'fetch job configuration summary - error',
  (error) => error
);

export const triggerEditConfigmap = createAction(
  'trigger edit configmap',
  (cmName, key, value) => ({ cmName, key, value })
);

export const editConfigmap = createAction(
  'edit configmap',
  (cmName, key, value) => ({ cmName, key, value })
);

export const cancelEditConfigmap = createAction('cancel edit configmap');

export const confirmEditConfigmap = createAction(
  'confirm edit configmap',
  (cmName, key, value) => ({ cmName, key, value })
);

export const reducer = {
  [fetchJobConfigs]: (state) => ({
    ...state,
    jobconfigs: {
      ...state.jobconfigs,
      loading: true,
      loaded: false,
    },
  }),
  [fetchJobConfigsSuccess]: function (state, data) {
    // Normalize the data
    const cmNames = Object.keys(data);
    const normalized = {};
    cmNames.map((cmName) => {
      normalized[cmName] = { configs: data[cmName] };
    });

    return {
      ...state,
      jobconfigs: {
        ...state.jobconfigs,
        data: normalized,
        cmNames,
        loading: false,
        loaded: true,
        error: null,
        edit: {},
      },
    };
  },
  [fetchJobConfigsError]: (state, error) => ({
    ...state,
    jobconfigs: {
      ...state.jobconfigs,
      error,
      loading: false,
      loaded: false,
    },
  }),
  [triggerEditConfigmap]: (state, { cmName, key, value }) => ({
    ...state,
    jobconfigs: {
      ...state.jobconfigs,
      edit: {
        cmName,
        key,
        value,
        originalValue: value,
        confirming: false,
      },
    },
  }),
  [editConfigmap]: (state, { cmName, key, value }) => ({
    ...state,
    jobconfigs: {
      ...state.jobconfigs,
      edit: {
        ...state.jobconfigs.edit,
        cmName,
        key,
        value,
        confirming: false,
      },
    },
  }),
  [cancelEditConfigmap]: (state) => ({
    ...state,
    jobconfigs: {
      ...state.jobconfigs,
      edit: {},
    },
  }),
  [confirmEditConfigmap]: (state, { cmName, key, value }) => ({
    ...state,
    jobconfigs: {
      ...state.jobconfigs,
      edit: {
        cmName,
        key,
        value,
        confirming: true,
      },
    },
  }),
};

export function* fetchJobConfigsSaga() {
  const { response, error } = yield call(
    Api.orchestration.fetchJobConfigSummary
  );
  if (response) {
    yield put(fetchJobConfigsSuccess(response));
  } else {
    yield put(fetchJobConfigsError(error));
  }
}

export function* watchFetchJobConfigs() {
  yield takeEvery(fetchJobConfigs.getType(), fetchJobConfigsSaga);
}

export function* confirmEditCmSaga({ payload: { cmName, key, value } }) {
  const { response, error } = yield call(
    Api.orchestration.patchJobConfig,
    cmName,
    key,
    value
  );
  if (response) {
    yield put(fetchJobConfigs());
    // TODO -> Throw success message
  } else {
    // TODO -> Throw error message
  }
}

export function* watchConfirmEditCm() {
  yield takeEvery(confirmEditConfigmap.getType(), confirmEditCmSaga);
}
