import React, { Component } from 'react';
import commonStyles from '../../newDatapoolWizard/optChannels/optCommonStyles.module.scss';

import { WrappedFieldProps } from 'redux-form';
import ConstraintsTable from './ConstraintsTable';
import { MessageDescriptor } from 'react-intl';
import { CampaignOptimizationDatapoolType } from 'common/dist/types/datapool';
import { GetDatapoolDetailsResponseBody } from 'common/dist/types/responseBodies/datapools';
import { v4 as uuidv4 } from 'uuid';
import { FiPlus } from 'react-icons/fi';
import _ from 'lodash';
import InputError from '../../../../atoms/input-error/InputError';
import { _globalValidationError } from '../../../../modelManagement/modules/implementations/optimization/new-augur/optimization.form';

type ContainerProps = {
  /** Are the datapool details currently loading? */
  datapoolDetailsLoading?: boolean;
  /** Datapool details */
  datapoolDetails?: GetDatapoolDetailsResponseBody;
  /** Fetch the details of the selected datapool */
  fetchDatapool: (habitatCode: string, datapoolCode: string) => void;
};

export type OwnProps = {
  /** Habitat Code the Augur is supposed to be added to */
  habitatCode: string;
  /** Code of the selected datapool */
  datapoolCode: string;
  /** Name of the selected customer table */
  customerTableName?: string;
  /** Is the edit mode enabled? (false = render static table of constraints)*/
  inEditMode: boolean;
};

export type Props = ContainerProps & OwnProps;

export type ConstraintsErrorType = {
  [constraintId: string]: {
    id?: MessageDescriptor;
    name?: MessageDescriptor;
    description?: MessageDescriptor;
    constraintType?: MessageDescriptor;
    operator?: MessageDescriptor;
    value?: MessageDescriptor;
    constraintLevel?: MessageDescriptor;
    channelId?: MessageDescriptor;
    communicationId?: MessageDescriptor;
    communicationGroupId?: MessageDescriptor;
  };
};

export interface State {
  addedNewRow: boolean;
}

export default class OptConstraints extends Component<
  Props & WrappedFieldProps,
  State
> {
  constructor(props: Props & WrappedFieldProps) {
    super(props);

    this.state = { addedNewRow: false };
    this.expandAutomatically(props);
  }

  componentDidMount() {
    const { habitatCode, datapoolCode, fetchDatapool } = this.props;
    fetchDatapool(habitatCode, datapoolCode);
  }

  render() {
    const { input, meta, datapoolDetailsLoading, datapoolDetails, inEditMode } =
      this.props;
    this.expandAutomatically(this.props);
    return (
      <div className={commonStyles.optComponent}>
        <div className={commonStyles.errorContainer}>
          <InputError
            touched={meta?.touched}
            error={meta?.error?.[_globalValidationError]}
          />
        </div>
        <div className={commonStyles.tableContainer}>
          <ConstraintsTable
            editMode={inEditMode}
            input={input}
            meta={meta}
            datapoolDetailsLoading={datapoolDetailsLoading}
            datapoolDetails={
              datapoolDetails as CampaignOptimizationDatapoolType
            }
            addedNewRow={this.state.addedNewRow}
            rowIndex={input.value?.length - 1 || 0}
          />
        </div>
        {inEditMode && (
          <div className={commonStyles.AddIconContainer}>
            <div
              className={commonStyles.AddIcon}
              onClick={() => {
                input.onChange([...(input.value || []), { id: uuidv4() }]);
                this.setState({ addedNewRow: true });
              }}
            >
              <FiPlus size={18} />
            </div>
          </div>
        )}
      </div>
    );
  }

  private expandAutomatically(props: Props & WrappedFieldProps) {
    const { input } = props;

    const isEmpty = _.isEmpty(input.value);
    //Also check if it was triggered by the Wizard
    if (props.inEditMode && isEmpty && !this.state.addedNewRow) {
      this.state = { addedNewRow: true };
      input.onChange([...(input.value || []), { id: uuidv4() }]);
    }
  }
}
