import { connect } from 'react-redux';
import { formValueSelector, reset } from 'redux-form';

import { formName } from './newDatapool.form';

import { fetchModules } from '../../redux/modules/modules.module';
import { addDatapool } from '../../redux/modules/datapool.add.module';

import { DeprecatedRootState } from '../../store/state.type';
import NewDatapoolWizard, { RouteParams } from './NewDatapoolWizard';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

export function mapStateToProps(
  state: DeprecatedRootState,
  {
    match: {
      params: { habitatCode },
    },
  }: RouteComponentProps<RouteParams>
) {
  // Selector for the values entered in the redux form
  const valueSelector = formValueSelector(formName);

  return {
    modules: state.modules,

    isSubmitting: state.newDatapool?.submitting,

    habitatName: (state.summary || []).find(
      (habitat) => habitat.code === habitatCode
    )?.name,

    formValueSelector: (fieldName: string) => valueSelector(state, fieldName),
  };
}

export const mapDispatchToProps = {
  fetchModules,
  resetForm: () => reset(formName),
  addDatapool,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewDatapoolWizard)
);
