// --- DROP TARGET: Tab Line
/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
export const elementTabLine = {
  /**
   * @param props
   * @param monitor
   * @returns {*}
   */
  canDrop(props, monitor) {
    const item = monitor.getItem();
    // 1. Dropping an element in the same bucket is not allowed.
    // -> Since this would have no effect (moving the file to the same place as it already is)
    if (item.paneId === props.paneId) return false;

    return true;
  },

  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      // Check whether some nested target already handled drop
      return;
    }

    // Obtain the dragged item and fire off the action to move the notebook
    const { paneId: sourcePane, path } = monitor.getItem();
    const { paneId: targetPane, moveNotebookToAnotherPane } = props;
    moveNotebookToAnotherPane(path, sourcePane, targetPane);
  },
};

/**
 * Specifies which props are injected into the DropTarget
 */
export function tabLineCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  };
}

// --- DROP TARGET: Editor Main
/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
export const elementEditorMain = {
  canDrop(props, monitor) {
    const item = monitor.getItem();

    return true;
  },

  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      // Check whether some nested target already handled drop
      return;
    }
    // Obtain the dragged item
    const notebookTab = monitor.getItem();
    // TODO In this case the notebook is supposed to be moved!
  },
};

/**
 * Specifies which props are injected into the DropTarget
 */
export function editorMainCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
    // item: monitor.getItem(),
  };
}
