import React, { Component } from 'react';
import {
  S3_BUCKET_SCOPES,
  s3BucketResourceName,
  s3ResourceType,
} from 'common/dist/constants/keycloak';
import { CProps } from './S3BucketDetails.container';
import ResourcePermissions from '../../../organisms/permissions/ressource-permissions/ResourcePermissions.container';
import { s3DetailsRoute } from '../s3-permissions/S3Permissions';
import dataManagementMessages from 'common/dist/messages/dataManagement';
import { datapoolFromBucket } from 'common/dist/constants/dataManagement/s3';
import commonMessages from 'common/dist/messages/common';
import { injectIntl, WrappedComponentProps } from 'react-intl';

class S3BucketDetails extends Component<CProps & WrappedComponentProps> {
  componentDidMount() {
    const { fetchDataSources } = this.props;
    fetchDataSources();
  }

  render() {
    const { dataSourceCode, bucketName, dataSource, names, intl } = this.props;
    const speakingName =
      names.datapoolNames[datapoolFromBucket(bucketName).toUpperCase()];

    let detailsRows = [
      {
        key: dataManagementMessages.msgS3PermissionsRowDataSourceName,
        value: dataSource?.name,
      },
      {
        key: dataManagementMessages.msgS3PermissionsRowBucketName,
        value: bucketName,
      },
    ];
    if (speakingName !== undefined) {
      detailsRows = [
        ...detailsRows,
        {
          key: dataManagementMessages.msgCassandraPermissionsRowDatapoolName,
          value: speakingName,
        },
      ];
    }

    return (
      <ResourcePermissions
        baseUrl={s3DetailsRoute(dataSourceCode, bucketName)}
        showBackButton
        backButtonLabel={intl.formatMessage(commonMessages.backTo, {
          origin: 'Buckets',
        })}
        backToUrl={`/app/admin/users/s3Permissions/${dataSourceCode}`}
        resourceType={s3ResourceType(dataSourceCode)}
        resourceName={s3BucketResourceName(dataSourceCode, bucketName)}
        details={{
          headline: dataManagementMessages.msgS3PermissionsHeadline,
          rows: detailsRows,
        }}
        scopes={S3_BUCKET_SCOPES}
        messagesGroupPermissions={{
          subTitle: dataManagementMessages.msgS3PermissionsGroupSubTitle,
          description: dataManagementMessages.msgS3PermissionsGroupDescription,
        }}
        messagesUserPermissions={{
          subTitle: dataManagementMessages.msgS3PermissionsUserSubTitle,
        }}
      />
    );
  }
}

export default injectIntl(S3BucketDetails);
