import React, { Component } from 'react';
import anime from 'animejs';
import LogoIconColored from '../../../atoms/icons/logo-colored/LogoIconColored';
import vars from '../../../../../scss/base/var.module.scss';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { K8sResources } from '../../../molecules/k8s-resources-select/K8sResourcesSelect';
import { UseFormHandleSubmit } from 'react-hook-form';
import { FormData } from './StartServer';

type Props = {
  /** User to start the notebook for */
  notebookUser: string;
  /** Sends the spawn request to JupyterHub */
  startServer: (
    notebookUser: string,
    workbenchImage: string,
    resources?: K8sResources
  ) => void;
  /** redux-action to check whether the notebook is running. If the notebook is running, it is marked in the redux
   * which causes Workbench.jsx to switch into the running screen */
  checkWhetherNotebookIsRunning: (notebookUser: string, retry: boolean) => void;
  /** true if the spawn request is pending (only the request - this doesn't have to do anything with the spawning
   * status of the notebook) */
  loading: boolean;
  /** true if the spawn request succeeded (only the request - this doesn't have to do anything with the spawning status
   * of the notebook) */
  loaded: boolean;
  /** possible error during the spawn request (only the request - this doesn't have to do anything with the spawning
   * status of the notebook) */
  error: string;
  /** Was the event source reachable? */
  eventSourceAvailable: boolean;
  /** Array of received eventSource messages (in the ordering they were received by the eventSource) */
  eventSourceMessages: {
    /** Progress between 0 - 100 */
    progress: number;
    /** Is the notebook ready? Only set if progress=100, I think */
    ready: boolean;
    raw_event: {
      /** Message */
      message: string;
    };
  }[];
  /** Callback for when the spawn message is changed */
  onMessageChange: (message: string) => void;
  handleSubmit: UseFormHandleSubmit<FormData>;
  isFormValid: boolean;
};

type State = {
  clicked: boolean;
  clickAnimationFinished: boolean;
  autoStartPlainAnimation: boolean;
  processedMessageIndex: number;
};

export default class StartServerButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clicked: false,
      clickAnimationFinished: false,
      autoStartPlainAnimation: false,
      processedMessageIndex: -1,
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.startClickAnimation = this.startClickAnimation.bind(this);
    this.startCorrectAnimation = this.startCorrectAnimation.bind(this);
    this.processEventSourceMessage = this.processEventSourceMessage.bind(this);
    this.startOpenAnimation = this.startOpenAnimation.bind(this);
  }

  componentDidMount() {
    const { loaded } = this.props;
    if (loaded) {
      this.startCorrectAnimation();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { loaded, eventSourceMessages, onMessageChange } = this.props;
    // Treat 'loaded' switched from false to true
    if (prevProps.loaded === false && loaded === true) {
      this.startCorrectAnimation();
    }

    // Treat new messages
    if (
      eventSourceMessages &&
      eventSourceMessages.length > this.state.processedMessageIndex + 1
    ) {
      const latestIndex = eventSourceMessages.length - 1;
      const latestMessage = eventSourceMessages[latestIndex];
      this.setState({ processedMessageIndex: latestIndex });
      this.processEventSourceMessage(latestMessage);

      onMessageChange(
        latestMessage.raw_event ? latestMessage.raw_event.message : ''
      );
    }
  }

  /**
   * Called by componentDidMount or componendDidUpdate when the spawn request returns (marked by loaded=true)
   */
  startCorrectAnimation() {
    const { eventSourceAvailable } = this.props;

    // Is the previous animation already finished?
    if (this.state.clickAnimationFinished) {
      if (eventSourceAvailable) {
        // Something to do?
      } else {
        this.startPlainAnimation();
      }
    } else {
      if (eventSourceAvailable) {
        // Something to do?
      } else {
        this.setState({ autoStartPlainAnimation: true });
      }
    }
  }

  /**
   * Processes EventSource messages
   * @param message
   */
  processEventSourceMessage(message) {
    // console.log("Message: ", message);
    const { progress, ready } = message;

    // --- Adjust the Progress bar
    const basicTimeline = anime.timeline({
      autoplay: false,
    });
    basicTimeline.add({
      targets: '#start-server-button--progress-bar',
      duration: 1200,
      width: 300 * (progress / 100),
      easing: 'easeInOutSine',
    });
    basicTimeline.play();

    // --- If ready fire the action to check and open the Workbench
    if (ready) {
      this.startOpenAnimation();
    }
  }

  startOpenAnimation() {
    const { checkWhetherNotebookIsRunning, notebookUser } = this.props;
    const basicTimeline = anime.timeline({
      autoplay: false,
      complete: () => checkWhetherNotebookIsRunning(notebookUser, true),
    });
    basicTimeline
      .add({
        targets:
          '#start-server-button-parent, #start-server-headline-parent, #start-server-message-parent',
        duration: 500,
        opacity: 0,
        easing: 'easeInOutSine',
      })
      .add({
        targets: '#start-server-parent',
        delay: 500,
        duration: 1000,
        width: window.innerWidth - 30,
        height: document.body.scrollHeight - 151,
        easing: 'easeInOutSine',
      });
    basicTimeline.play();
  }

  /**
   * Animation if the EventSource is not available
   */
  startPlainAnimation() {
    const { checkWhetherNotebookIsRunning, notebookUser } = this.props;
    const basicTimeline = anime.timeline({
      autoplay: false,
      complete: () => checkWhetherNotebookIsRunning(notebookUser, true),
    });

    basicTimeline
      .add({
        targets: '#start-server-button--progress-bar',
        duration: 2000,
        width: 300,
        easing: 'linear',
      })
      .add({
        targets: '#start-server-button--button',
        width: 0,
        duration: 1,
      })
      .add({
        targets: '#start-server-button--progress-bar',
        width: 80,
        height: 80,
        delay: 500,
        duration: 750,
        borderRadius: 80,
        backgroundColor: vars.colorPrimary,
      })
      .add({
        targets: '#start-server-button--logo',
        opacity: 1,
        delay: 0,
        duration: 1,
      })
      .add({
        targets: '#start-server-button--progress-bar',
        opacity: 0,
        delay: 0,
        duration: 1500,
      });

    basicTimeline.play();
  }

  /**
   * Animation when the button is clicked
   */
  startClickAnimation() {
    const basicTimeline = anime.timeline({
      autoplay: false,
      complete: () => {
        this.setState({ clickAnimationFinished: true });
        if (this.state.autoStartPlainAnimation) {
          // Set to true if loaded became true before the click animation was finished
          this.startPlainAnimation();
        }
      },
    });

    basicTimeline
      .add({
        // Hide the button text
        targets: '#start-server-button--text',
        duration: 1,
        opacity: '0',
      })
      .add({
        // Transform the button to the grey background of the progress bar
        targets: '#start-server-button--button',
        duration: 1300,
        height: 10,
        width: 300,
        backgroundColor: '#dce2eb',
        border: '0',
        borderTopLeftRadius: 100,
        borderBottomLeftRadius: 100,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
      });

    basicTimeline.play();
  }

  handleButtonClick() {
    const { startServer, notebookUser, isFormValid, handleSubmit } = this.props;

    if (this.state.clicked) return; // To prevent multiple starts by clicking the button quickly several times

    handleSubmit((data) => {
      this.setState({ ...this.state, clicked: true });
      this.startClickAnimation(); // Start the click animation

      // Cleanup the resources object
      let cleanedResources: Partial<FormData> = data;

      if (!cleanedResources?.useGpu) {
        // If not "useGpu", ensure all gpu related fields are removed
        const { gpuLimit, gpuProduct, ...restResources } =
          cleanedResources || {};
        
        /*GPUs are only supposed to be specified in the limits section, which means:
          - You can specify GPU limits without specifying requests, because Kubernetes will use the limit as the request value by default.
          - You can specify GPU in both limits and requests but these two values must be equal.
          - You cannot specify GPU requests without specifying limits. 
          Therefore, set the value of gpuRequest to be same as the value of gpuLimit.*/
        cleanedResources.gpuRequest = gpuLimit
        cleanedResources = restResources;
      }

      // Remove the "image" from the resources
      const { image, ...restResources } = cleanedResources;
      cleanedResources = restResources;

      startServer(notebookUser, data.image, cleanedResources); // Send the spawn request to JupyterHub
    })();
  }

  render() {
    const { isFormValid } = this.props;
    return (
      <div
        className={classNames(styles.startServerButton, {
          [styles.disabled]: !isFormValid,
        })}
        onClick={this.handleButtonClick}
      >
        <div
          className={styles.startServerButtonButton}
          id='start-server-button--button'
        >
          <div
            className={styles.startServerButtonText}
            id='start-server-button--text'
          >
            Start AI Workbench Server
          </div>
        </div>
        <div
          className={styles.startServerButtonProgressBar}
          id='start-server-button--progress-bar'
        />
        <div
          className={styles.startServerButtonLogo}
          id={'start-server-button--logo'}
        >
          <LogoIconColored />
        </div>
      </div>
    );
  }
}
