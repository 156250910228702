export const initial = {
  error: '',
  loading: false,
  loaded: false,
  codes: [], // List of codes
  jobGroups: {}, // Mapping: Code -> Values
  page: 1, // Opened page
  pageSize: 10, // Page size (amount of JobGroups per page)
  totalCount: 0, // Total count of JobGroups in the history database (not necessarily loaded)
};

export const test = {
  error: '',
  loading: false,
  loaded: false,
  codes: [], // List of codes
  jobGroups: {}, // Mapping: Code -> Values
  page: 1, // Opened page
  pageSize: 10, // Page size (amount of JobGroups per page)
  totalCount: 0, // Total count of JobGroups in the history database (not necessarily loaded)
};
