import React, { FC, Fragment } from 'react';
import {
  getIntlIdForType,
  getNameForType,
} from '../../../modelManagement/modules/ModuleManagerClient';
import DetailValueLoading from '../detail-value/DetailValueLoading';
import DetailValue from '../detail-value/DetailValue';
import { DatapoolType } from 'common/dist/types/datapool';
import { inputFormats } from 'common/dist/constants/datapool';
import {
  ModuleTypeType,
  moduleType as moduleTypeEnum,
} from 'common/dist/constants/module_list';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';
import DatapoolMsgs from 'common/dist/messages/datapools';

type Props = {
  /** Are the datapool details loading? */
  loading?: boolean;
  /** Are the datapool details loaded? */
  loaded?: boolean;
  /** The datapool details */
  datapool?: DatapoolType;
  dataSource?: DataSource;
};

const getDetailValues = (moduleType: ModuleTypeType) => {
  const details = {
    moduleType: {
      gridColumn: 2,
      gridRow: 1,
      detailKey: DatapoolMsgs.dashboardDatapoolSettingsActionBarModuleType,
    },
    dataSource: {
      gridColumn: 2,
      gridRow: 2,
      detailKey: DatapoolMsgs.dashboardDatapoolSettingsActionBarDataSource,
    },
  };

  if (moduleType === moduleTypeEnum.BINARY_NUMERICAL) {
    details['inputFormat'] = {
      gridColumn: 3,
      gridRow: 2,
      detailKey: DatapoolMsgs.dashboardDatapoolSettingsActionBarInputFormat,
    };
  }

  return details;
};

const DetailValues: FC<Props> = ({ loading, loaded, datapool, dataSource }) => {
  // @ts-ignore
  const detailValues = getDetailValues(datapool?.moduleType);

  if (loading || !loaded) {
    return (
      <Fragment>
        {Object.values(detailValues).map((dv) => (
          <DetailValueLoading {...dv} />
        ))}
      </Fragment>
    );
  }

  const speakingInputFormat = inputFormats.find(
    (x) => x.id === (datapool || {}).inputFormat
  );

  const detailValuesLoaded = {
    moduleType: {
      detailValue: {
        id: getIntlIdForType(datapool.moduleType),
        defaultMessage: getNameForType(datapool.moduleType),
      },
    },
    dataSource: {
      detailValue: {
        id: 'no-id',
        defaultMessage: dataSource?.name,
      },
    },
    inputFormat: {
      detailValue: {
        id: 'no-id',
        defaultMessage: speakingInputFormat
          ? speakingInputFormat.name
          : datapool.inputFormat,
      },
    },
  };

  return (
    <Fragment>
      {Object.keys(detailValues).map((detId) => {
        const det = detailValues[detId];
        const detL = detailValuesLoaded[detId];
        return <DetailValue {...det} {...detL} />;
      })}
    </Fragment>
  );
};

export default DetailValues;
