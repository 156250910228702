import React, { Component } from 'react';
import TreePath from './TreePath.container';
import { FormattedMessage } from 'react-intl';
import Tree from './Tree';
import { elbowLink, linearLinkThickness } from './treeChart/link';
import { expansionStrategy } from './treeChart/tree';
import augurMsgs from 'common/dist/messages/augurs';
import styles from './styles.module.scss';
import { BinaryTreeType } from 'common/dist/types/module.nbc';

interface Props {
  data: BinaryTreeType;
}
export default class BinaryTreeShadowModel extends Component<Props> {
  render() {
    const { data } = this.props;
    return (
      <div className={styles.pageSelection}>
        <div className={styles.selectionWrap}>
          <div className={styles.selectionInfo}>
            <TreePath />
          </div>
          <div className={styles.selectionGraph}>
            <div className={styles.selectionGraphHistory}>
              <FormattedMessage
                {...augurMsgs.msgModelViewerBinaryTreeSeeMore}
              />
            </div>
            <Tree
              data={data}
              linkShapeFunc={elbowLink(1)}
              linkThicknessFunc={linearLinkThickness([0, 100], [1, 4.5])}
              adjustTreeFunc={expansionStrategy}
              animationDuration={{
                mount: { delay: 0, duration: 200 },
                update: { delay: 0, duration: 200 },
                exit: { delay: 0, duration: 200 },
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
