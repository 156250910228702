import React, { Component, ReactChild, ReactChildren } from 'react';
import './styles.scss';

export type Props = {
  children: ReactChild | ReactChildren | ReactChild[] | ReactChildren[];
  /** Additional class name for the main container component */
  additionalClassname?: string;
  /** Use the full screen width? */
  fullWidth?: boolean;
  /** No background color and no border? */
  transparent?: boolean;
  /** Ignore the required height of the MainTabs? (for example useful for the NotFound component) */
  noMainTabs?: boolean;
  /** Is the content scrollable in the y-axis? */
  scrollableY?: boolean;
};

export default class MainContainer extends Component<Props, {}> {
  render() {
    const {
      children,
      additionalClassname,
      fullWidth,
      transparent,
      noMainTabs,
      scrollableY,
    } = this.props;
    return (
      <div
        id={'MainContainer'}
        className={
          'MainContainer' +
          (additionalClassname ? ` ${additionalClassname}` : '') +
          (noMainTabs ? ' no-main-tabs' : '') +
          (scrollableY ? ' scrollable-y' : ' fixed-y')
        }
      >
        <div
          className={
            'MainContainer--inner' +
            (transparent ? ' transparent' : '') +
            (fullWidth ? ' fullwidth' : ' centerwidth')
          }
        >
          {children}
        </div>
      </div>
    );
  }
}
