import {
  apiRequest,
  deleteApiRequest,
  postApiRequest,
  putApiRequest,
} from './_apiRequests';
import qs from 'qs';

export function fetchCollabContent(path) {
  const url = `/api/workbench/collab/content/${path}`;
  return apiRequest(url);
}

export function addRepository(
  parentPath,
  repositoryName,
  repositoryType,
  repositoryDescription,
  codeCapsuleHabitat,
  archKernel,
  archHabitat,
  archDatapool
) {
  const url = '/api/workbench/collab/gitrepo';
  const body = {
    parentPath,
    repositoryName,
    repositoryType,
    repositoryDescription,
    codeCapsuleHabitat,
    archKernel,
    archHabitat,
    archDatapool,
  };
  return postApiRequest(url, body);
}

export function addDirectory(parentPath, name) {
  const url = '/api/workbench/collab/content/directory';
  const body = {
    parentPath,
    name,
  };
  return postApiRequest(url, body);
}

/**
 * Takes the full name of the repository and the branch, and sends a request to the Dashboard API to request for the
 * latest commit for this branch
 * @param repoName
 * @param branch
 * @param repoGroup - default is a holdover from when the owner of repos was always "altasigma"
 * @returns {*}
 */
export function fetchLatestCommit(repoName, branch, repoGroup = 'altasigma') {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/branch/${branch}/latestCommit`;
  return apiRequest(url);
}

/**
 * Fetches the content of a repository, for a given ref at a given path
 * @param repoName
 * @param ref
 * @param path
 * @param repoGroup - default is a holdover from when the owner of repos was always "altasigma"
 * @returns {*}
 */
export function fetchRepoContent(repoName, ref, path, repoGroup = 'altasigma') {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/contents${path}?ref=${ref}`;
  return apiRequest(url);
}

export function deleteRepository(fullRepoName) {
  const url = `/api/workbench/collab/gitrepo/${fullRepoName}`;
  return deleteApiRequest(url);
}

export function deleteDirectory(directoryPath) {
  if (!directoryPath) return;

  let path = directoryPath;
  if (directoryPath.startsWith('/')) path = path.slice(1);
  const url = `/api/workbench/collab/content/directory/${path}`;
  return deleteApiRequest(url);
}

export function getRepositoryNameExists(repoName) {
  const url = '/api/workbench/collab/repositories/exists';
  const body = {
    repoName,
  };
  return postApiRequest(url, body);
}

export function fetchRepositories(offset, limit, search) {
  const q = qs.stringify({ offset, limit, search });
  const url = `/api/workbench/collab/repositories?${q}`;
  return apiRequest(url);
}

export function getBranches(repoGroup, repoName) {
  const url = `/api/workbench/collab/repository/${repoGroup}/${repoName}/branches`;
  return apiRequest(url);
}

export function fetchRepoDetails(repoGroup, repoName) {
  const url = `/api/workbench/collab/repository/${repoGroup}/${repoName}/details`;
  return apiRequest(url);
}

export function fetchGroups() {
  const url = '/api/workbench/collab/groups';
  return apiRequest(url);
}

export function openMergeRequest(
  repoGroup,
  repoName,
  src,
  target,
  title,
  description
) {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/merge-requests`;
  return postApiRequest(url, { src, target, title, description });
}

/**
 * Loads the details of a MergeRequest
 * @param repoGroup
 * @param repoName
 * @param id
 * @returns {*}
 */
export function loadMergeRequest(repoGroup, repoName, id) {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/merge-requests/${id}`;
  return apiRequest(url);
}

/**
 * Loads the summary list of MergeRequests (with respect to paging)
 * @param repoGroup
 * @param repoName
 * @param state
 * @param page
 * @param limit
 * @returns {*}
 */
export function loadMergeRequests(repoGroup, repoName, state, page, limit) {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/merge-requests?state=${state}&page=${page}&limit=${limit}`;
  return apiRequest(url);
}

export function mergeMergeRequest(repoGroup, repoName, id) {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/merge-requests/${id}/merge`;
  return postApiRequest(url, {});
}

export function updateMergeRequest(
  repoGroup,
  repoName,
  id,
  title,
  description,
  targetBranch
) {
  const url = `/api/workbench/collab/gitrepo/${repoGroup}/${repoName}/merge-requests/${id}`;
  return putApiRequest(url, { title, description, targetBranch });
}
