import { connect } from 'react-redux';
import StepTag from './StepTag';
import { fetchArchetypeVersions } from '../../../../../redux/modules/archetypeVersions.module';

export function mapStateToProps(state, { archetypeCode }) {
  const {
    loaded: versionsLoaded,
    loading: versionsLoading,
    error: versionsError,
    versions: versionsData,
  } = state.archetypeVersions[archetypeCode] || {};
  return {
    versionsLoaded,
    versionsLoading,
    versionsError,
    versionsData,
  };
}

export const mapDispatchToProps = {
  fetchArchetypeVersions,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepTag);
