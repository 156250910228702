import React, { CSSProperties, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import baseVariables from '../../../../scss/base/var.module.scss';

export interface Props {
  text: React.ComponentProps<typeof FormattedMessage>;
  textColor: keyof typeof colors;
  backgroundColor: keyof typeof colors;
  borderColor: keyof typeof colors;
}

const colors = {
  grey: baseVariables.colorTextLight,
  blue: baseVariables.colorBlue,
  'light-blue': baseVariables.colorBlueHighlight,
  green: baseVariables.colorGreen,
  orange: baseVariables.colorOrange,
  white: baseVariables.colorWhite,
  black: baseVariables.colorBlack,
};

const Label: FC<Props> = (props) => {
  const {
    text: { id = 'no-id', defaultMessage, values },
    textColor = props.backgroundColor !== undefined ? 'white' : 'black',
    backgroundColor,
    borderColor,
  } = props;
  // Build the style
  const style: CSSProperties = { color: colors[textColor] };
  if (backgroundColor !== undefined)
    Object.assign<CSSProperties, CSSProperties>(style, {
      backgroundColor: colors[backgroundColor],
    });
  if (borderColor !== undefined)
    Object.assign<CSSProperties, CSSProperties>(style, {
      borderColor: colors[borderColor],
    });
  return (
    <div className={styles.label} style={style}>
      <span>
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
          values={values}
        />
      </span>
    </div>
  );
};

export default Label;
