import React, { Component } from 'react';
import { ValueType } from './CassandraTablePreview';
import DropdownSelectInput from '../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import classNames from 'classnames';
import { HeaderGroup } from 'react-table';
import { ExtendedColSpec } from './Table';

type Props = {
  column: HeaderGroup & Pick<ExtendedColSpec, 'highlightStyle'>;
  /** Is the data preview editable? */
  editable: boolean;
  /** Is the data preview currently being edited? */
  editing: boolean;
  /** "input" object that is required if the preview is editable */
  input?: {
    value: ValueType;
    onChange: (value: ValueType) => void;
  };
};

type OptionType = { label: string; value: string };

const options: OptionType[] = [
  { label: 'VARCHAR', value: 'VARCHAR' },
  { label: 'INT', value: 'INT' },
  { label: 'BIGINT', value: 'BIGINT' },
  { label: 'DOUBLE', value: 'DOUBLE' },
];

function changeColName(
  input,
  columnId: string,
  oldName: string,
  newName: string,
  newType: string
) {
  const { value, onChange, onBlur } = input;

  const newValue = {
    ...value,
    [columnId]: { oldName: oldName, newName: newName, newType: newType },
  };
  onChange(newValue); // Set the new value
  onBlur(newValue); // To make the "touched" attribute of redux-form work
}

export default class TableHeadlineCell extends Component<Props> {
  render() {
    const { column, input, editable, editing } = this.props;

    const currentName =
      editable && (input.value[column.id] || {}).newName !== undefined
        ? input.value[column.id].newName
        : (column.render('Header') as string);
    const originalType = column.render('Type') as string;
    const currentType =
      editable && (input.value[column.id] || {}).newType !== undefined
        ? input.value[column.id].newType
        : originalType;
    const highlightStyle = column.highlightStyle;

    if (!editing) {
      return (
        <th
          style={{
            cursor: 'default',
            ...(highlightStyle || {}),
          }}
          {...column.getHeaderProps()}
        >
          <span style={highlightStyle?.colName} className={'col-name'}>
            {currentName}
          </span>
          <span style={highlightStyle?.colType} className={'col-type'}>
            {currentType}
          </span>
        </th>
      );
    } else {
      return (
        <th
          className={'data-preview-th'}
          onBlur={() => this.setState({ editing: false })}
          style={highlightStyle}
          {...column.getHeaderProps()}
        >
          <input
            className='col-name'
            type='text'
            value={currentName}
            onChange={(evt) =>
              changeColName(
                input,
                column.id,
                currentName,
                evt.target.value,
                currentType
              )
            }
            onBlur={() => this.setState({ editing: false })}
          />

          <DropdownSelectInput
            className={classNames('col-type', 'col-type-editing')}
            options={options}
            value={options.find((o) => o.value === currentType)}
            searchable={false}
            clearable={false}
            onChange={(option: OptionType) =>
              changeColName(
                input,
                column.id,
                currentName,
                currentName,
                option.value
              )
            }
            onBlur={() => this.setState({ editing: false })}
            onFocus={() => {}}
            appearance={'small'}
          />
        </th>
      );
    }
  }
}
