import { connect, ConnectedProps } from 'react-redux';
import { DeprecatedRootState } from '../../../../store/state.type';
import JobGroupExecution from './JobGroupExecution';
import { setDetailsOrigin } from '../../../../redux/modules/orchestration.jobdetails.module';
import {
  cancelJobGroup,
  hideJobGroupConfirm,
  showJobGroupConfirm,
} from '../../../../redux/modules/orchestration.queue.module';
import { runJobGroup } from '../../../../store/jobGroups/slice';

export function mapStateToProps(state: DeprecatedRootState) {
  const augurNames: Record<string, string> = state.names.augurNames;
  const datapoolNames: Record<string, string> = state.names.datapoolNames;
  const codeCapsuleNames: Record<string, string> = state.names.codeCapsuleNames;
  return {
    augurNames,
    datapoolNames,
    codeCapsuleNames,
    cancelJobGroupConfirm: state.queue.pre.cancelJobGroupConfirm,
  };
}

export const mapDispatchToProps = {
  setDetailsOrigin,
  cancelJobGroup,
  showJobGroupConfirm,
  hideJobGroupConfirm,
  runJobGroup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type connectedProps = ConnectedProps<typeof connector>;
export default connector(JobGroupExecution);
