import React, { Component } from 'react';
import anime, { AnimeTimelineInstance } from 'animejs';
import styles from './styles.module.scss';

export interface Props {
  isSpinning: boolean;
}

export default class AnimationSpinner extends Component<Props> {
  numberOfEls = 90;
  duration = 10000;
  delay = this.duration / this.numberOfEls;

  createEl(i: number, tl?: AnimeTimelineInstance): HTMLDivElement {
    const el = document.createElement('div');
    const rotate = (360 / this.numberOfEls) * i;
    const translateY = -50;
    const hue = Math.round((360 / this.numberOfEls) * i);
    el.classList.add(styles.el);
    // el.style.backgroundColor = 'hsl(' + hue + ', 40%, 60%)';
    el.style.backgroundColor = 'hsl(' + 216 + ', 40%, 60%)';
    el.style.transform =
      'rotate(' + rotate + 'deg) translateY(' + translateY + '%)';
    if (tl !== undefined) {
      tl.add({
        begin: () => {
          anime({
            targets: el,
            //backgroundColor: ['hsl(' + hue + ', 40%, 60%)', 'hsl(' + hue + ', 60%, 80%)'],
            backgroundColor: [
              'hsl(' + 216 + ', 40%, 60%)',
              'hsl(' + 216 + ', 60%, 80%)',
            ],
            rotate: [rotate + 'deg', rotate + 10 + 'deg'],
            translateY: [translateY + '%', translateY + 10 + '%'],
            scale: [1, 1.25],
            easing: 'easeInOutSine',
            direction: 'alternate',
            duration: this.duration * 0.1,
          });
        },
      });
    }
    return el;
  }

  createStaticEls() {
    const wrapperEl = document.querySelector('.' + styles.animationSpinner);

    for (let i = 0; i < this.numberOfEls; i++) {
      const el = this.createEl(i);
      wrapperEl?.appendChild(el);
    }
  }

  startAnimation() {
    const wrapperEl = document.querySelector('.' + styles.animationSpinner);

    const tl = anime.timeline({
      duration: this.delay,
      complete: () => {
        tl.restart();
      },
    });
    wrapperEl?.replaceChildren(
      ...Array.from({ length: this.numberOfEls }, (_, i) =>
        this.createEl(i, tl)
      )
    );
  }

  stopAnimation() {
    anime.remove('.' + styles.el);
    // Reset elements to initial state
    // TODO this does not include the extra rotation (which depends on the element id). Leads to the animated elements bunching up after stopping
    anime({
      targets: '.' + styles.el,
      easing: 'easeInOutSine',
      translateY: '-50%',
      scale: 1,
      duration: 500,
      backgroundColor: 'hsl(216, 40%, 60%)',
    });
  }

  componentDidMount() {
    const { isSpinning } = this.props;
    if (isSpinning) {
      this.startAnimation();
    } else {
      this.createStaticEls();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isSpinning } = this.props;
    if (prevProps.isSpinning && !isSpinning) {
      // Stop
      this.stopAnimation();
    } else if (!prevProps.isSpinning && isSpinning) {
      // Start
      this.startAnimation();
    }
  }

  render() {
    return (
      <div className={styles.animationContainer}>
        <div className={styles.animationSpinner} />
      </div>
    );
  }
}
