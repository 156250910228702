import React, { Component } from 'react';
import DataSourceButton from './DataSourceButton';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { typeToSpeaking } from 'common/dist/types/dataManagement/dataSource';
import styles from './styles.module.scss';

export type Value = string;
export type Error = string;

interface Props {
  input: {
    value: Value;
    onChange: (v: Value) => ToBeRefined;
  };
  disabled: boolean;
}

interface DataSourceOption {
  name: string;
  display_name: string;
}

export default class DataSourceButtons extends Component<Props> {
  dataSourceOptions: DataSourceOption[];

  constructor(props: Props) {
    super(props);
    this.dataSourceOptions = [
      { name: 'cassandra', display_name: typeToSpeaking('cassandra') },
      { name: 's3', display_name: typeToSpeaking('s3') },
    ];
  }

  render() {
    const {
      input: { onChange, value },
      disabled,
    } = this.props;
    return (
      <div className={styles.cardsContainer}>
        {this.dataSourceOptions.map((dsOption) => {
          const name = dsOption.name;
          const displayName = dsOption.display_name;
          return (
            <DataSourceButton
              name={name}
              displayName={displayName}
              key={name}
              onChange={(value: Value) => {
                onChange(value);
              }}
              selectedName={value}
              disabled={disabled}
            />
          );
        })}
      </div>
    );
  }
}
