import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  fieldNameDefaultValue,
  fieldNameLowerBound,
  fieldNameUpperBound,
} from '../_interface/input-element.form';
import DatetimePickerCol from '../../../settings-dialog/dialog-settings-cols/DatetimePickerCol';
import { variableShape } from '../_interface/P3InputElementShape';
import { VARIABLE_TYPES } from '../../variables/variableTypes';
import { inputModalForm } from '../../../settings-dialog/inpmodalDialog.form';

class DatetimePickerSettings extends Component {
  render() {
    const { path, cellId, showSelectVariable, useDate, useTime } = this.props;
    return (
      <Fragment>
        <div className={'input-row'}>
          <Field
            name={fieldNameDefaultValue}
            label={'Default Value (optional)'}
            component={DatetimePickerCol}
            placeholder={'Enter Default Value'}
            showSelectVariable={showSelectVariable}
            useDate={useDate}
            useTime={useTime}
          />
        </div>

        <div className={'input-row'}>
          <Field
            name={fieldNameLowerBound}
            label={'Lower Bound'}
            component={DatetimePickerCol}
            placeholder={'Enter Lower Bound'}
            allowVariable
            path={path}
            cellId={cellId}
            showSelectVariable={showSelectVariable}
            validVariableTypes={[VARIABLE_TYPES.TIMESTAMP]}
            useDate={useDate}
            useTime={useTime}
          />
          <Field
            name={fieldNameUpperBound}
            label={'Upper Bound'}
            component={DatetimePickerCol}
            placeholder={'Enter Upper Bound'}
            allowVariable
            path={path}
            cellId={cellId}
            showSelectVariable={showSelectVariable}
            validVariableTypes={[VARIABLE_TYPES.TIMESTAMP]}
            useDate={useDate}
            useTime={useTime}
          />
        </div>
      </Fragment>
    );
  }
}

DatetimePickerSettings.propTypes = {
  path: PropTypes.string,
  cellId: PropTypes.string,
  variables: PropTypes.arrayOf(variableShape),
  showSelectVariable: PropTypes.func.isRequired,
  useDate: PropTypes.bool,
  useTime: PropTypes.bool,
};
DatetimePickerSettings.defaultProps = {
  useDate: true,
  useTime: true,
};

export default reduxForm(inputModalForm)(DatetimePickerSettings);
