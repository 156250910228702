import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { collaborationSpaceRoutes } from '../routes';
import Repository from './overview/Repository.container';
import CloneRepositoryWizard from '../wizards/repository-clone/CloneRepositoryWizard.container';
import BuildAppWizard from '../wizards/repository-build-app/BuildAppWizard.container';
import BuildCodeCapsuleWizard from '../wizards/repository-build-code-capsule/BuildCodeCapsuleWizard.container';
import BuildArchetypeWizard from '../wizards/repository-build-archetype/BuildArchetypeWizard.container';
import Branches from './branches/Branches.container';
import OpenMergeRequestWizard from '../wizards/open-merge-request/OpenMergeRequestWizard.container';
import MergeRequests from './merge-requests/MergeRequests.container';
import MergeRequestDetails from './merge-requests/merge-request-details/MergeRequestDetails.container';
import ResolveConflicts from './merge-requests/merge-request-details/tab-resolve/resolve-conflicts/ResolveConflicts.container';
import WithSidemenu from '../../molecules/with-sidemenu/WithSidemenu';
import { FiBook, FiGitBranch, FiGitMerge } from 'react-icons/fi/index';

class RepositoryDetails extends Component {
  getActiveCategory() {
    const path = this.props.history.location.pathname;
    if (!path) return null;
    const rep = path.replace('/app/collab/', '');
    const parts = rep.split('/');
    if (['repositories', 'groups'].includes(parts[0])) {
      // In this case we're in the repository browser - return this
      return parts[0];
    } else {
      // In this case we're in the repository details - drop the first 2 elements,
      // since they're the repository group and name
      if (parts.length > 2) return parts[2];
      // This is fine and will be treated by the routing with a proper redirect to the repository overview
      else return undefined;
    }
  }

  render() {
    const selectedCategory = this.getActiveCategory();
    const handleSelect = (selectedTab) =>
      this.props.history.push(
        `${collaborationSpaceRoutes.basePath}/${selectedTab}`
      );

    const {
      match: {
        params: { group, repositoryName },
      },
    } = this.props;

    const repositoryDetailsMenuEntries = [
      {
        type: 'link',
        id: 'overview',
        activePerDefault: true,
        to: `${group}/${repositoryName}/overview`,
        nameIntlId: 'dashboard.tab.collab.repositories.overview',
        nameDefault: 'Overview',
        icon: FiBook,
      },
      {
        type: 'link',
        id: 'branches',
        to: `${group}/${repositoryName}/branches`,
        nameIntlId: 'dashboard.tab.collab.repositories.branches',
        nameDefault: 'Branches',
        icon: FiGitBranch,
      },
      {
        type: 'link',
        id: 'merge-requests',
        to: `${group}/${repositoryName}/merge-requests`,
        nameIntlId: 'dashboard.tab.collab.repositories.merge-requests',
        nameDefault: 'Merge Requests',
        icon: FiGitMerge,
      },
    ];

    return (
      <Switch>
        {/*/:group/:repositoryName/clone*/}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName/${collaborationSpaceRoutes.repositories.clone}`}
          component={() => <CloneRepositoryWizard />}
        />

        {/*/:group/:repositoryName/merge-request/:id/resolve?filename=... Endpoint to resolve a merge request of one single file */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName/${collaborationSpaceRoutes.repositories.mergeRequest}/:id/${collaborationSpaceRoutes.repositories.resolve}`}
          component={() => (
            <ResolveConflicts group={group} repositoryName={repositoryName} />
          )}
        />

        {/*/:group/:repositoryName/merge-request/:id*/}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName/${collaborationSpaceRoutes.repositories.mergeRequest}/:id`}
          component={() => (
            <WithSidemenu
              menuEntries={repositoryDetailsMenuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <MergeRequestDetails initialTab={0} />
            </WithSidemenu>
          )}
        />

        {/*/:group/:repositoryName/merge/edit/:id*/}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName/${collaborationSpaceRoutes.repositories.merge}/edit/:id`}
          component={() => (
            <OpenMergeRequestWizard
              group={group}
              repositoryName={repositoryName}
            />
          )}
        />

        {/*/:group/:repositoryName/merge*/}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName/${collaborationSpaceRoutes.repositories.merge}`}
          component={() => (
            <OpenMergeRequestWizard
              group={group}
              repositoryName={repositoryName}
            />
          )}
        />

        {/*/:group/:repositoryName/build-app*/}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName/${collaborationSpaceRoutes.repositories.buildApp}`}
        >
          <BuildAppWizard />
        </Route>

        {/*/:group/:repositoryName/build-arch*/}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName/${collaborationSpaceRoutes.repositories.buildArchetype}`}
        >
          <BuildArchetypeWizard />
        </Route>

        {/*/:group/:repositoryName/build-cc*/}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName/${collaborationSpaceRoutes.repositories.buildCodeCapsule}`}
        >
          <BuildCodeCapsuleWizard />
        </Route>

        {/*/:group/:repositoryName/branches*/}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName/branches`}
          component={() => (
            <WithSidemenu
              menuEntries={repositoryDetailsMenuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <Branches />
            </WithSidemenu>
          )}
        />

        {/*/:group/:repositoryName/merge-requests*/}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName/merge-requests`}
          component={() => (
            <WithSidemenu
              menuEntries={repositoryDetailsMenuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <MergeRequests group={group} repositoryName={repositoryName} />
            </WithSidemenu>
          )}
        />

        {/* Repository Details */}
        <Route
          path={`${collaborationSpaceRoutes.basePath}/:group/:repositoryName`}
          component={() => (
            <WithSidemenu
              menuEntries={repositoryDetailsMenuEntries}
              selectedTab={selectedCategory}
              handleSelect={handleSelect}
            >
              <Repository />
            </WithSidemenu>
          )}
        />
      </Switch>
    );
  }
}

RepositoryDetails.propTypes = {
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      group: PropTypes.string,
      repositoryName: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withRouter(RepositoryDetails);
