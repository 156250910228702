import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ResourcePermissions from '../../../organisms/permissions/ressource-permissions/ResourcePermissions.container';
import { DASHBOARD_COMPONENT_SCOPES } from 'common/dist/constants/keycloak';
import { detailsRoute } from '../app-permissions/AppPermissions';
import { usersRoutes } from '../routes';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppsAdmin } from '../../../../redux/modules/apps.module';
import { DeprecatedRootState } from '../../../../store/state.type';
import { App } from 'common/dist/types/app';
import { useIntl } from 'react-intl';
import commonMessages from 'common/dist/messages/common';

const AppDetails: FC = () => {
  const { id } = useParams<{ id: string }>();

  const intl = useIntl();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAppsAdmin(false));
  }, [dispatch]);

  const app = useSelector<DeprecatedRootState, App>((state) =>
    (state.appsAdmin?.data || []).find((app) => app.code === id)
  ) || { name: '' };

  return (
    <ResourcePermissions
      baseUrl={detailsRoute(id)}
      showBackButton
      backButtonLabel={intl.formatMessage(commonMessages.backTo, {
        origin: 'App Permissions',
      })}
      backToUrl={`${usersRoutes.basePath}/${usersRoutes.appPermissions.path}`}
      resourceType={'app'}
      resourceName={`app:${id}`}
      details={{
        headline: {
          id: 'no-id',
          defaultMessage: `App ${app.name} Permissions`,
        },
        rows: [],
      }}
      scopes={DASHBOARD_COMPONENT_SCOPES}
      messagesGroupPermissions={{
        subTitle: {
          id: 'no-id',
          defaultMessage: `Assign group-wise permissions to the app ${app.name}`,
        },
        description: {
          id: 'no-id',
          defaultMessage: `If you are a member of this group you have access to the app ${app.name}`,
        },
      }}
      messagesUserPermissions={{
        subTitle: {
          id: 'no-id',
          defaultMessage: `Assign user-wise permissions to the app ${app.name}`,
        },
      }}
    />
  );
};

export default AppDetails;
