import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';
import { sendNotification } from './notifications.module';
import { error as errorType, event } from '../../core/notifications';
import { fetchSummary } from './dashboard.summary.module';
import notifications from 'common/dist/messages/notifications';

export const updateHabitat = createAction(
  'update new habitat',
  (habitatName, habitatCode) => ({
    habitatName,
    habitatCode,
  })
);

export const updateHabitatSuccess = createAction(
  'update new habitat - success',
  (habitat) => habitat
);

export const updateHabitatFail = createAction(
  'update new habitat - fail',
  (error) => error
);
export const deleteHabitat = createAction(
  'delete habitat',
  (habitatName, habitatCode) => ({
    habitatName,
    habitatCode,
  })
);

export const deleteHabitatSuccess = createAction(
  'delete habitat - success',
  (habitat) => habitat
);

export const deleteHabitatFail = createAction(
  'delete habitat - fail',
  (error) => error
);
export const hideDeleteHabitatConfirm = createAction(
  'hide delete data source confirm'
);

export const showDeleteHabitatConfirm = createAction(
  'show delete data source confirm',
  (habitatName, habitatCode) => ({ habitatName, habitatCode })
);
export const reducer = {
  [updateHabitat]: (state) => ({
    ...state,
    newHabitat: {
      ...(state.newHabitat || {}),
      ...state.habitatCode,
      submitting: true,
    },
  }),
  [updateHabitatSuccess]: (state, data) => ({
    ...state,
    newHabitat: {
      ...state.newHabitat,
      submitting: false,
      createdHabitat: data,
    },
  }),
  [updateHabitatFail]: (state, error) => ({
    ...state,
    newHabitat: {
      ...(state.newHabitat || {}),
      submitting: false,
      error,
    },
  }),
  //delete
  [deleteHabitat]: (state) => ({
    ...state,
    newHabitat: {
      ...(state.newHabitat || {}),
      ...state.habitatCode,
      submitting: true,
    },
  }),
  [deleteHabitatSuccess]: (state, data) => ({
    ...state,
    newHabitat: {
      ...state.newHabitat,
      submitting: false,
    },
  }),
  [deleteHabitatFail]: (state, error) => ({
    ...state,
    newHabitat: {
      ...(state.newHabitat || {}),
      submitting: false,
      error,
    },
  }),

  [hideDeleteHabitatConfirm]: (state) => ({
    ...state,
    newHabitat: {
      ...state.newHabitat,
      deleteHabitatConfirm: {
        ...state.deleteHabitatConfirm,
        show: false,
      },
    },
  }),
  [showDeleteHabitatConfirm]: (state, { habitatName, habitatCode }) => ({
    ...state,
    newHabitat: {
      ...state.newHabitat,
      deleteHabitatConfirm: {
        show: true,
        habitatName,
        habitatCode,
      },
    },
  }),
};

export function* updateHabitatSaga({ payload: { habitatName, habitatCode } }) {
  const { response, error } = yield call(
    Api.habitats.updateHabitat,
    habitatCode,
    habitatName
  );
  if (response) {
    yield put(updateHabitatSuccess(response));
    yield put(
      sendNotification(
        notifications.msgTitleEditedHabitat.id,
        notifications.msgDescriptionEditedHabitat.id,
        event,
        { habitatName: habitatName }
      )
    );
    yield put(fetchSummary());
  } else {
    yield put(updateHabitatFail(error));
  }
}

export function* watchUpdateHabitat() {
  yield takeEvery(updateHabitat.getType(), updateHabitatSaga);
}
export function* deleteHabitatSaga({ payload: { habitatName, habitatCode } }) {
  const { response, error } = yield call(
    Api.habitats.deleteHabitat,
    habitatCode,
    habitatName
  );
  if (response) {
    yield put(deleteHabitatSuccess(response));
    yield put(
      sendNotification(
        notifications.msgTitleDeletedHabitat.id,
        notifications.msgDescriptionDeletedHabitat.id,
        event,
        { habitatName: habitatName }
      )
    );
    yield put(fetchSummary());
  } else {
    yield put(deleteHabitatFail(error));
    yield put(fetchSummary());
    yield put(
      sendNotification(
        notifications.msgTitleDeletedHabitatFailed.id,
        notifications.msgDescriptionDeletedHabitatFailed.id,
        errorType,
        { habitatName: habitatName }
      )
    );
  }
}

export function* watchDeleteHabitat() {
  yield takeEvery(deleteHabitat.getType(), deleteHabitatSaga);
}
