import PropTypes from 'prop-types';
import React from 'react';

import EvaluationBar from './EvaluationBar';

function EvaluationBars(props) {
  const {
    evaluations,
    xScale,
    yScale,
    showTooltip,
    hideTooltip,
    moveTooltip,
    isTooltip,
  } = props;
  return (
    <g>
      {evaluations.map((item, index) => (
        <EvaluationBar
          key={index}
          xScale={xScale}
          yScale={yScale}
          evaluation={item}
          isTooltip={isTooltip}
          showTooltip={showTooltip}
          hideTooltip={hideTooltip}
          moveTooltip={moveTooltip}
        />
      ))}
    </g>
  );
}

EvaluationBars.propTypes = {
  evaluations: PropTypes.array.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  moveTooltip: PropTypes.func.isRequired,
  isTooltip: PropTypes.bool,
};

export default EvaluationBars;
