import React, { ComponentType, FC } from 'react';
import styles from './styles.module.scss';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export type Props = {
  Button: ComponentType;
  /** Optional subheader below the button */
  subHeader?: MessageDescriptor;
  /** Optional link for the whole card */
  linkTo?: string;
  /** Optional callback when clicking the whole card */
  onClick?: () => void;
  /** Optional ID for the element card */
  id?: string;
  /** Optional additional classname for the element card */
  addlClassName?: string;
};

const ElementCardButtonOnly: FC<Props> = (props: Props) => {
  const { Button, subHeader, id, addlClassName, linkTo, onClick } = props;

  const renderInner = () => {
    return (
      <div className={styles.buttonParent}>
        <Button />
        {subHeader && (
          <div className={styles.subHeader}>
            <FormattedMessage {...subHeader} />
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (linkTo) {
      return (
        <Link
          className={styles.content}
          to={linkTo}
          onClick={() => onClick && onClick()}
          style={{ textDecoration: 'none' }}
        >
          {renderInner()}
        </Link>
      );
    } else {
      return (
        <div className={styles.content} onClick={() => onClick && onClick()}>
          {renderInner()}
        </div>
      );
    }
  };

  return (
    <div
      id={id}
      className={classNames(styles.elementCardButtonOnly, addlClassName, {
        [styles.clickable]: onClick || linkTo,
      })}
    >
      {renderContent()}
    </div>
  );
};

export default ElementCardButtonOnly;
