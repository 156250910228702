import React, { Component, Fragment } from 'react';
import RepositoryHeader from '../../../../overview/RepositoryHeader.container';
import { collaborationSpaceRoutes } from '../../../../../routes';
import { ToBeRefined } from 'common/dist/types/todo_type';
import ReactLoading from 'react-loading';
import Conflicts from './Conflicts';
import { Notebook } from '../../../../../../../store/workbench/state.types';
import vars from '../../../../../../../../scss/base/var.module.scss';
import Button from '../../../../../../atoms/button/Button';

export type FileType = {
  /** Name of the file */
  filename: string;
  /** Content of the file */
  content: ToBeRefined;
};

// --- Types for diffing cells
export type CellDiffType =
  | CellDiffTypeAdd
  | CellDiffTypeChange
  | CellDiffTypeRemove;

type CellDiffTypeAdd = {
  op: 'add';
  path: string;
  value: PartDiffType[];
  id: string;
  cell_id: string;
  before_cell_id: string;
};

type CellDiffTypeChange = {
  op: 'change';
  path: string;
  value: PartDiffType[];
  id: string;
  cell_id: string;
};

type CellDiffTypeRemove = {
  op: 'remove';
  path: string;
  id: string;
  cell_id: string;
};

// --- Types for diffing parts of cells
type PartDiffType = PartDiffTypeAdd | PartDiffTypeReplace | PartDiffTypeRemove;

type PartDiffTypeAdd = {
  op: 'add';
  path: string;
  value: string;
};

type PartDiffTypeReplace = {
  op: 'replace';
  path: string;
  value: string | number;
};

type PartDiffTypeRemove = {
  op: 'remove';
  path: string;
};

export type ResolveAction = ResolveActionAccept | ResolveActionReject;

type ResolveActionAccept = {
  id: string;
  action: 'accept';
  diff: CellDiffType;
};

type ResolveActionReject = {
  id: string;
  action: 'reject';
};

export type Props = {
  /** Name of the file to resolve the conflics for. Coming from the QueryString from the URL */
  filename: string;
  /** ID of the Merge Request in the Merger API. Coming from the QueryString from the URL */
  mergeId: number;

  match: {
    params: {
      id: number;
      group: string;
      repositoryName: string;
    };
  };
  /** Load the information about the merge conflict for the given file */
  loadMergeRequestConflict: (mergeId: number, fileName: string) => void;
  /** Are the details about the Merge Conflict currently loading? */
  loading?: boolean;
  /** Are the details about the Merge Conflict loaded? */
  loaded?: boolean;
  /** Was there an error while loading the Merge Conflict? */
  error?: string;
  /** Details about the Merge Conflict */
  data?: {
    status: 'pending' | 'conflict' | 'success' | 'failure';
    base_file?: FileType;
    source_file?: FileType;
    target_file?: FileType;
    source_diff?: CellDiffType[];
    target_diff?: CellDiffType[];
    /** Only if status == "failure"*/
    message: string;
  };
  /** The amount of diffs for which no action was taken yet */
  amountOpenDiffs: number;

  baseFileNotebook?: Notebook;

  /** Is the source column extended? */
  sourceExtended?: boolean;
  /** Is the target column extended? */
  targetExtended?: boolean;

  /** Submit the new version with the resolved conflicts */
  submitConflictResolve: (
    mergeId: number,
    fileName: string,
    resolvedVersion: Notebook | string
  ) => void;
};

export default class ResolveConflicts extends Component<
  Props,
  { submitting: boolean }
> {
  constructor(props: Props) {
    super(props);
    this.state = { submitting: false };
  }

  componentDidMount() {
    const { loadMergeRequestConflict, filename, mergeId } = this.props;
    loadMergeRequestConflict(mergeId, filename);
  }

  renderLoading() {
    return (
      <ReactLoading
        className={'starting-stopping-spinner'}
        type={'cylon'}
        color={vars.colorPrimary}
      />
    );
  }

  renderError() {
    const { error } = this.props;
    return <div>{JSON.stringify(error)}</div>;
  }

  renderLoaded() {
    const {
      data,
      amountOpenDiffs,
      baseFileNotebook,
      mergeId,
      filename,
      sourceExtended,
      targetExtended,
      match: { params },
      submitConflictResolve,
    } = this.props;
    return (
      <Fragment>
        <Conflicts
          baseFile={baseFileNotebook}
          sourceFile={data.source_file}
          targetFile={data.target_file}
          sourceDiff={data.source_diff}
          targetDiff={data.target_diff}
          debugRenderCellIds={false}
          filename={filename}
          sourceExtended={sourceExtended}
          targetExtended={targetExtended}
        />
        <div className={'amount-open-diffs'}>
          {amountOpenDiffs > 0 ? (
            amountOpenDiffs === 1 ? (
              <span className={'conflicts-open'}>1 Conflict to resolve</span>
            ) : (
              <span className={'conflicts-open'}>
                {amountOpenDiffs} Conflicts to resolve
              </span>
            )
          ) : (
            <span className={'conflicts-resolved'}>All Conflicts resolved</span>
          )}
        </div>
        <div className={'resolve-conflicts-buttons'}>
          <Button
            buttonColor={'transparent'}
            withLink
            buttonLabelDefault={'Cancel'}
            linkTo={`${collaborationSpaceRoutes.basePath}/${params.group}/${params.repositoryName}/${collaborationSpaceRoutes.repositories.mergeRequest}/${mergeId}`}
          />

          <Button
            buttonColor={'primary'}
            withLink
            buttonLabelDefault={'Submit'}
            disabled={amountOpenDiffs === 0 && !this.state.submitting}
            linkTo={`${collaborationSpaceRoutes.basePath}/${params.group}/${params.repositoryName}/${collaborationSpaceRoutes.repositories.mergeRequest}/${mergeId}`}
            onClick={() => {
              if (amountOpenDiffs === 0 && !this.state.submitting) {
                this.setState({ submitting: true });
                submitConflictResolve(mergeId, filename, baseFileNotebook);
              }
            }}
          />
        </div>
      </Fragment>
    );
  }

  renderContent() {
    const { loading, error, data } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (data) return this.renderLoaded();
    else return <div />;
  }

  render() {
    const {
      filename,
      match: { params },
    } = this.props;

    return (
      <div className={'CollaborationSpace--content'}>
        <div className={'repository resolve-conflicts-container'}>
          <RepositoryHeader
            linkTo={`${collaborationSpaceRoutes.basePath}/${params.group}/${params.repositoryName}/${collaborationSpaceRoutes.repositories.mergeRequest}`}
            message={'Back to Merge Request'}
          />
          <div className={'resolve-conflicts-headline'}>
            <span>ResolveConflicts for File {filename}</span>
          </div>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}
