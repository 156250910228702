import React, { Component } from 'react';
import { BackupMetaWithId } from '../types';
import SortableSearchableListContainer from '../../../organisms/sortable-searchable-list/SortableSearchableList.container';
import BackupElement from './BackupElement.container';
import { Props as BackupElementProps } from './BackupElement';
import {
  BackupMetaOrdering,
  backupMetaOrderings,
  connectedProps,
} from './BackupsPage.container';
import Busy from '../../../atoms/busy/Busy';
import { app as appRoutes } from '../../../index/routes';
import ConfirmationModal from '../../../organisms/confirmation-modal/ConfirmationModal';

type Props = {
  backupsMeta: {
    loading: boolean;
    loaded: boolean;
    error?: string;
    data: BackupMetaWithId[];
  };
  fetchBackups: () => void;
  fetchTemplates: () => void;
};

export default class BackupsPage extends Component<Props & connectedProps> {
  componentDidMount() {
    const { fetchBackups, fetchTemplates } = this.props;
    fetchBackups();
    fetchTemplates();
  }

  renderLoading() {
    return (
      <div className={'backups-parent'}>
        <Busy isBusy />
      </div>
    );
  }

  renderError() {
    const {
      backupsMeta: { error },
    } = this.props;
    return (
      <div className={'backups-parent'}>
        <span className={'error'}>{JSON.stringify(error)}</span>
      </div>
    );
  }

  renderLoaded() {
    const {
      backupsMeta,
      restoreBackupModal,
      restoreBackup,
      hideRestoreBackupModal,
    } = this.props;
    const List = SortableSearchableListContainer<
      BackupMetaWithId,
      BackupMetaOrdering,
      BackupElementProps
    >();
    return (
      <div className={'backups-parent'}>
        <List
          orderings={backupMetaOrderings}
          defaultOrdering={BackupMetaOrdering.LastCreated}
          data={backupsMeta.data}
          contentProps={(bak: BackupMetaWithId, i: number) => ({ bak })}
          ContentComponent={BackupElement}
          search={(bak: BackupMetaWithId, searchString: string) =>
            bak.backupMeta.timestamp.includes(searchString)
          }
          sort={(baks: BackupMetaWithId[], ordering: BackupMetaOrdering) => {
            switch (ordering) {
              case BackupMetaOrdering.LastCreated:
                return baks.sort((a, b) =>
                  a.backupMeta.timestamp > b.backupMeta.timestamp ? -1 : 1
                );
              case BackupMetaOrdering.OldestCreated:
                return baks.sort((a, b) =>
                  a.backupMeta.timestamp < b.backupMeta.timestamp ? -1 : 1
                );
            }
          }}
          title={'Backups'}
          button={{
            buttonText: 'Create Backup',
            link:
              appRoutes.prefix +
              appRoutes.admin.index +
              appRoutes.admin.createBackup,
          }}
        />
        <ConfirmationModal
          secure={true}
          secureInput={restoreBackupModal?.template?.name}
          show={restoreBackupModal?.show}
          payload={{
            backupId: restoreBackupModal?.bak?.backupMeta.id,
            templateId: restoreBackupModal?.bak?.templateId,
          }}
          buttonConfirmAction={({ backupId, templateId }) => {
            // Same input as payload above
            //@ts-ignore
            restoreBackup({ backupId, templateId });
          }}
          hideModal={hideRestoreBackupModal}
          buttonConfirmText={{
            id: 'todo',
            defaultMessage: 'Restore',
          }}
          buttonConfirmColor={'orange'}
          description={{
            id: 'todo',
            defaultMessage:
              'You are going to restore a backup with template {templateName} from {timestamp}. Please type in {nameCode} and confirm if you are sure.',
            values: {
              templateName: <b>{restoreBackupModal?.template?.name}</b>,
              timestamp: restoreBackupModal?.bak?.backupMeta.timestamp,
              nameCode: <code>{restoreBackupModal?.template?.name}</code>,
            },
          }}
          headline={{
            id: 'todo',
            defaultMessage: 'Restore Backup',
          }}
          headlineColor={'orange'}
        />
      </div>
    );
  }

  render() {
    const {
      backupsMeta: { error, loading },
    } = this.props;
    if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    return this.renderLoaded();
  }
}
