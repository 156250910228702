import { blankoNotebook } from './blankoNotebook';

/**
 * Defines the notebook as it's added to the Workbench when opening.
 * Currently simply the blanko Notebook is returned.
 *
 * @param kernelName
 * @param kernelDisplayName
 * @param altaSigmaMeta
 * @returns {{path: string, format: string, type: string, content: {nbformat_minor: number, metadata: {kernelspec: {name: *, display_name: *}}, cells: [{outputs: [], metadata: {}, execution_count: null, source: string, cell_type: string}], nbformat: number}}}
 */
export function initialNotebook(kernelName, kernelDisplayName) {
  return blankoNotebook(kernelName, kernelDisplayName);
}
