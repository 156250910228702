import { connect } from 'react-redux';
import RecycleBin from './RecycleBin';
import { fetchContent } from '../../../../redux/workbench/modules/content.module';
import { defaultBrowserSize } from '../BrowserTabLinks';

export function mapStateToProps(state) {
  return {
    content: (
      (((state.workbench.content || {}).root || {}).content || []).find(
        (c) => c.name === '__recycleBin'
      ) || {}
    ).content,
    width: state.workbench.browserWidth || defaultBrowserSize,
  };
}

export const mapDispatchToProps = {
  fetchContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecycleBin);
