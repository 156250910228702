import { connect } from 'react-redux';
import { RootState } from '../../../../store/store';
import { withRouter } from 'react-router-dom';
import { JOB_GROUP_VARIANTS } from './JobGroupDetails';
import JobGroupDetails, { RProps } from './JobGroupDetails.container';
import { fetchJobGroup } from '../../../../store/jobGroups/slice';

export const mapStateToProps = (state: RootState, { match }: RProps) => {
  const code = match.params.jobGroupCode;

  console.log(state.jobGroups.data.find((obj) => obj.code === code));

  return {
    jobGroupDetails: state.jobGroups.data.find((obj) => obj.code === code),
    jobGroupVariant: JOB_GROUP_VARIANTS.jobTemplate,
  };
};

export const mapDispatchToProps = {
  fetchData: (code: string) => fetchJobGroup({ jobGroupCode: code }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connector(JobGroupDetails));
