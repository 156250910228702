import { connect } from 'react-redux';
import AppsOverview from './AppsOverview';
import { fetchApps } from '../../redux/modules/apps.module';

export function mapStateToProps(state) {
  const { loading, loaded, error, data } = state.apps;
  return {
    loading,
    loaded,
    error,
    data,
  };
}

export const mapDispatchToProps = {
  fetchApps,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppsOverview);
