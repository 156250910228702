import { connect } from 'react-redux';
import Groups from './Groups';
import { fetchGroups } from '../../../../redux/workbench/modules/collab.module';
import { fetchUserSummaryById } from '../../../../store/users/slice';

export function mapStateToProps(state) {
  const { loading, loaded, error, data } =
    (state.workbench.collab || {}).groups || {};
  return {
    loading,
    loaded,
    error,
    data,
    usersById: state.users.byId,
  };
}

export const mapDispatchToProps = {
  fetchGroups,
  fetchUserSummaryById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
