import AugurSettingsElement from '../../../../../_interfaces/augur-details/settings/AugurSettingsElement';
import HabitatNameAndCodeComponent from './HabitatNameAndCodeComponent';

/**
 * Shows the augur name & code in the augur settings tab
 */
export default class HabitatNameAndCode implements AugurSettingsElement {
  component = HabitatNameAndCodeComponent;
  title = 'dashboard.augur_settings.habitat_title';
  description = 'dashboard.augur_settings.habitat_description';
}
