import React, { ReactElement, useEffect } from 'react';
import styles from './styles.module.scss';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';
import TextInputSearch from '../../atoms/input-elements/text-input-search/TextInputSearch.container';

export interface PagingParams {
  offset?: string;
  limit?: string;
  search?: string;
}

export type PagingProps = {
  offset?: number;
  search?: string;
};

/**
 * This type was added to try to model what happens when a queryParameterPrefix is passed. Since that is more of a runtime
 * thing, this type seems not very useful... Concretely it can't tell you that the only keys available are
 * this.props.queryParameterPrefix + 'offset' (or another PagingParams key)
 */
type PrefixedPagingParams<O, P extends string> = {
  [K in keyof O as `${P}${K & string}`]: O[K];
};

/**
 * Paging Component to wrap around content. This component has no idea about the shape of the content.
 * It just provides buttons with links to changed url parameters (?offset=20). Then on mounting a function is called
 * with these url parameters changeOffset(offset=20) (typically to fetch paginated content)
 */
export type Props<T> = {
  /** The number of items to fetch each time - the limit for pagination */
  itemsPerPage: number;
  /** If the total number of items is known, the button to go to the first/last page can be shown */
  totalItems?: number;
  /** only if totalItems is known */
  showFirstLast?: boolean;
  /** Alternative if total number is unknown to at least disable the forward button if there are less items returned than itemsPerPage */
  currentItems?: number; // TODO: remove when no longer used
  /** Additional headline element to display beside the buttons */
  Headline: (
    currentPage: number,
    offset: number,
    limit: number,
    totalItemsOrCurrentItems?: number
  ) => ReactElement;
  /** Callback to notify the parent component of changes in the Paging parameter
   * in order to update the list of data to display; TODO: remove when no longer used */
  updatePagingParameters?: (
    offset?: number,
    limit?: number,
    search?: string
  ) => void;
  /** The contained components TODO: remove when no longer used */
  children?;
  /** Flag to enable the search feature */
  searchEnabled?: boolean;
  /** Hook to access the data that is displayed in content. */
  useData?: (offset?: number, search?: string) => T[];
  /** Building instructions for the list held by the Paging component. */
  InnerComponent?: (props: PagingProps) => ReactElement;
  /** Optional parameter to separate two Pagings that are displayed on the same page */
  queryParameterPrefix?: string;
};

function queryToParameters(query, prefix) {
  const queryParameter = qs.parse(query, {
    ignoreQueryPrefix: true,
  }) as PrefixedPagingParams<PagingParams, string>;

  let offset = Number(queryParameter[prefix + 'offset']);
  offset = Number.isFinite(Number(offset)) ? Math.max(Number(offset), 0) : 0;

  const search = queryParameter[prefix + 'search'];

  return { offset, search };
}

function offsetToPage(offset: number, itemsPerPage: number): number {
  return Math.ceil(offset / itemsPerPage) + 1;
}

function offsetToUrl(
  pathname: string,
  search: string,
  offset: number,
  queryParameterPrefix: string
): string {
  const queryParameter = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as PrefixedPagingParams<PagingParams, string>;
  const query = {
    ...queryParameter,
    [queryParameterPrefix + 'offset']: offset || undefined,
  };
  return `${pathname}${qs.stringify(query, { addQueryPrefix: true })}`;
}

function searchToUrl(
  pathname: string,
  prevQuery: string,
  search: string,
  queryParameterPrefix: string,
  offset = 0
): string {
  const queryParameter: PagingParams = qs.parse(prevQuery, {
    ignoreQueryPrefix: true,
  }) as PrefixedPagingParams<PagingParams, string>;
  const query = {
    ...queryParameter,
    [queryParameterPrefix + 'search']: search || undefined,
    [queryParameterPrefix + 'offset']: offset || undefined,
  };
  return `${pathname}${qs.stringify(query, { addQueryPrefix: true })}`;
}

const Paging = <T,>({
  itemsPerPage,
  totalItems,
  currentItems,
  showFirstLast,
  location,
  history,
  searchEnabled,
  updatePagingParameters = () => null,
  Headline,
  children,
  queryParameterPrefix = '',
  useData = () => null,
  InnerComponent,
}: Props<T> & RouteComponentProps) => {
  const queryParameters = queryToParameters(
    location.search,
    queryParameterPrefix
  );
  const { search } = queryParameters;
  let { offset } = queryParameters;

  // restrict paging to multiples of page size
  if (offset % itemsPerPage) {
    offset = Math.floor(offset / itemsPerPage) * itemsPerPage;
    history.replace(
      searchToUrl(
        location.pathname,
        location.search,
        search,
        queryParameterPrefix,
        offset
      )
    );
  }

  // TODO: remove when no longer used (currently this component uses either useData or this callback, callback should be replaced)
  //   https://gitlab.sigmalto.com/altasigma-platform/ticket-system/-/issues/1087
  // callback when parameters have changed
  useEffect(() => {
    updatePagingParameters(offset, itemsPerPage, search);
  }, [updatePagingParameters, offset, search, itemsPerPage]);

  const data = useData(offset, search);

  const currentPage = offsetToPage(offset, itemsPerPage);
  const totalPages: number | undefined =
    totalItems && Math.ceil(totalItems / itemsPerPage);
  const canGoBackward = currentPage > 1;

  const computedCurrentItems = data?.length ?? currentItems;
  const canGoForward =
    totalItems === undefined
      ? computedCurrentItems === itemsPerPage
      : currentPage < totalPages;
  return (
    <div className={styles.PagingParent}>
      <div className={styles.PagingHeader}>
        {Headline(
          currentPage,
          offset,
          itemsPerPage,
          totalItems ?? computedCurrentItems
        )}
        <div className={styles.PagePickerContainer}>
          {searchEnabled && (
            <TextInputSearch
              initialValue={search}
              submitSearchQuery={(search) => {
                history.push(
                  searchToUrl(
                    location.pathname,
                    location.search,
                    search,
                    queryParameterPrefix
                  )
                );
              }}
            />
          )}{' '}
        </div>
        <div className={styles.pagePicker}>
          {totalItems > 0 && showFirstLast && (
            <Link
              data-testingIdentifier={'Page first'}
              to={offsetToUrl(
                location.pathname,
                location.search,
                0,
                queryParameterPrefix
              )}
              className={classNames(
                styles.left,
                styles.leftRounded,
                { [styles.active]: canGoBackward },
                { [styles.inactive]: !canGoBackward }
              )}
            >
              <FiChevronsLeft
                size={16}
                className={classNames(
                  styles.pageChevron,
                  { [styles.active]: canGoBackward },
                  { [styles.inactive]: !canGoBackward }
                )}
              />
            </Link>
          )}

          <Link
            data-testingIdentifier={'Page backward'}
            to={offsetToUrl(
              location.pathname,
              location.search,
              Math.max(offset - itemsPerPage, 0),
              queryParameterPrefix
            )}
            className={classNames(
              styles.left,
              { [styles.leftRounded]: !totalItems || !showFirstLast },
              { [styles.active]: canGoBackward },
              { [styles.inactive]: !canGoBackward }
            )}
          >
            <FiChevronLeft
              size={16}
              className={classNames(
                styles.pageChevron,
                { [styles.active]: canGoBackward },
                { [styles.inactive]: !canGoBackward }
              )}
            />
          </Link>

          <span className={styles.info}>Page {currentPage}</span>

          <Link
            data-testingIdentifier={'Page forward'}
            to={offsetToUrl(
              location.pathname,
              location.search,
              offset + itemsPerPage,
              queryParameterPrefix
            )}
            className={classNames(
              styles.right,
              { [styles.rightRounded]: !totalItems || !showFirstLast },
              { [styles.active]: canGoForward },
              { [styles.inactive]: !canGoForward }
            )}
          >
            <FiChevronRight
              size={16}
              className={classNames(
                styles.pageChevron,
                { [styles.active]: canGoForward },
                { [styles.inactive]: !canGoForward }
              )}
            />
          </Link>

          {totalItems > 0 && showFirstLast && (
            <Link
              data-testingIdentifier={'Page last'}
              to={offsetToUrl(
                location.pathname,
                location.search,
                totalItems - (totalItems % itemsPerPage || itemsPerPage),
                queryParameterPrefix
              )}
              className={classNames(
                styles.right,
                styles.rightRounded,
                { [styles.active]: canGoForward },
                { [styles.inactive]: !canGoForward }
              )}
            >
              <FiChevronsRight
                size={16}
                className={classNames(
                  styles.pageChevron,
                  { [styles.active]: canGoForward },
                  { [styles.inactive]: !canGoForward }
                )}
              />
            </Link>
          )}
        </div>
      </div>
      <div className={styles.PagingContent}>
        {InnerComponent ? (
          <InnerComponent offset={offset} search={search} />
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default withRouter(Paging);
