import React, { ComponentType, FC } from 'react';
import styles from '../styles.module.scss';
import classNames from 'classnames';

type Props = {
  gridColumnStart: number;
  gridColumnEnd: number;
  gridRowStart: number;
  gridRowEnd: number | string;
  Icon: ComponentType;
};

const NameAndIconLoading: FC<Props> = (props: Props) => {
  const { Icon, gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd } =
    props;

  return (
    <div
      className={styles.nameAndIcon}
      style={{
        gridColumnStart,
        gridColumnEnd,
        gridRowStart,
        gridRowEnd,
      }}
    >
      <div className={styles.iconContainer}>
        <Icon />
      </div>

      <div className={styles.nameContainer}>
        <div className={classNames(styles.name, styles.loading)} />
        <div className={classNames(styles.subName, styles.loading)} />
      </div>

      <div className={classNames(styles.healthBar, styles.healthNone)} />
    </div>
  );
};

export default NameAndIconLoading;
