import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { dataManagementRoutes } from '../routes';
import Tables from './tables/Tables.container';
import TableBrowser from './table-browser/TableBrowser.container';
import Tabs from './Tabs';
import {
  DSTypeType,
  typeToSpeaking,
} from 'common/dist/types/dataManagement/dataSource';

export interface Props {
  /** Name of the data source */
  name: string;
  /** Must be 'cassandra' in this case */
  type: DSTypeType;
  /** Code of the data source */
  code: string;
  /** Set the active upload wizard */
  setActiveUploadWizard(...args: unknown[]): unknown;
}

class Cassandra extends Component<Props> {
  render() {
    const { name, type, code } = this.props;
    return (
      <div className={'data-source-parent'}>
        <div className={'data-source-headline'}>
          <div className={'data-source-description'}>
            <span className={'data-source-name'}>{name}</span>
            <span className={'data-source-type'}>{typeToSpeaking(type)}</span>
          </div>
        </div>

        <div className={'data-source-body'}>
          <Switch>
            <Route
              exact
              path={[
                `${dataManagementRoutes.basePath}/cassandra/${code}/${dataManagementRoutes.keyspaces}`,
                `${dataManagementRoutes.basePath}/cassandra/${code}/${dataManagementRoutes.auth}`,
              ]}
            >
              <Tabs code={code} />
            </Route>
            <Route
              exact
              path={`${dataManagementRoutes.basePath}/cassandra/${code}/keyspace/:keyspaceName`}
              // @ts-ignore
              component={() => <Tables dataSourceCode={code} />}
            />
            <Route
              path={`${dataManagementRoutes.basePath}/cassandra/${code}/keyspace/:keyspaceName/table/:tableName`}
              // @ts-ignore
              component={() => <TableBrowser dataSourceCode={code} />}
            />
            <Redirect
              exact
              path={`${dataManagementRoutes.basePath}/cassandra/${code}`}
              to={`${dataManagementRoutes.basePath}/cassandra/${code}/${dataManagementRoutes.keyspaces}`}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Cassandra;
