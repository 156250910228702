import React, { FunctionComponent } from 'react';
import { Tabs, TabLink, TabContent } from 'react-tabs-redux';
import './tabLine.scss';

export type Props = {
  /** List of Tab names */
  tabNames: string[];
  /** List of tab content elements */
  tabContents: JSX.Element[];
  /** Optional styling that is injected into the outer component */
  style?: object;
};

const TabLine: FunctionComponent<Props> = ({
  tabNames,
  tabContents,
  style = {},
}: Props) => (
  <div className={'TabLine--container'} style={style}>
    <Tabs>
      <div className={'TabLine--tabs'}>
        {tabNames.map((name) => (
          <TabLink to={name}>{name}</TabLink>
        ))}
      </div>

      <div className={'TabLine--content'}>
        {tabContents.map((ContentElement, i) => (
          <TabContent for={tabNames[i]} key={i}>
            {ContentElement}
          </TabContent>
        ))}
      </div>
    </Tabs>
  </div>
);

export default TabLine;
