import React, { FC } from 'react';

import Busy from '../../../../../../components/atoms/busy/Busy';
import IndicatorEmpty from '../../../../../../components/molecules/indicator-empty/IndicatorEmpty';
import Lift from './charts/Lift';
import * as routes from '../../../../../../components/index/routes';
import CumulativeLift from './charts/CumulativeLift';
import Response from './charts/Response';
import CumulativeResponse from './charts/CumulativeResponse';
import CapturedResponse from './charts/CapturedResponse';
import CumulativeCapturedResponse from './charts/CumulativeCapturedResponse';
import Roc from './charts/Roc';
import Misclassification from './charts/Misclassification';
import { buckets } from '../../../../../../core/common/misclassification';
import PredictedValues from './charts/PredictedValues';
import { ACCURACY_DETAILS_TYPE } from 'common/dist/constants/accuracyCharts';
import { JobDescriptionBanner } from '../job-banner/JobDescriptionBanner';
import { AugurDetailsRouteParams } from '../types';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { useLatestEvaluationReport } from '../../../../../../core/api/reports';
import { Point } from 'common/dist/types/reports';

export const rocHelpLine: [Point, Point] = [
  [0, 0],
  [1, 1],
];

export const cumulativeCapturedReponseHelpLine: [Point, Point] = [
  [5, 0.08],
  [100, 1],
]; // TODO 0.08 seems a little random? Why not 0?

const Accuracy: FC = () => {
  const { habitatCode, augurCode, moduleType } =
    useParams<AugurDetailsRouteParams>();

  const location = useLocation();
  const { modelCode } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as { modelCode: string };

  const {
    isSuccess,
    isLoading,
    data: report,
  } = useLatestEvaluationReport(habitatCode, augurCode, modelCode);

  const renderCharts = () => {
    return (
      <div className='Accuracy--charts'>
        {report.data?.lift?.data && (
          <Lift
            baseLine={report.data.lift.baseline}
            data={report.data.lift.data}
            detailsLink={routes.getAccuracyDetailLinkForShort(
              habitatCode,
              augurCode,
              moduleType,
              ACCURACY_DETAILS_TYPE.LIFT
            )}
          />
        )}

        {report.data?.cumulativeLift?.data && (
          <CumulativeLift
            baseLine={report.data.cumulativeLift.baseline}
            data={report.data.cumulativeLift.data}
            detailsLink={routes.getAccuracyDetailLinkForShort(
              habitatCode,
              augurCode,
              moduleType,
              ACCURACY_DETAILS_TYPE.CUMULATIVE_LIFT
            )}
          />
        )}

        {report.data?.response?.data && (
          <Response
            baseLine={report.data.response.baseline}
            data={report.data.response.data}
            detailsLink={routes.getAccuracyDetailLinkForShort(
              habitatCode,
              augurCode,
              moduleType,
              ACCURACY_DETAILS_TYPE.RESPONSE
            )}
          />
        )}

        {report.data?.cumulativeResponse?.data && (
          <CumulativeResponse
            baseLine={report.data.cumulativeResponse.baseline}
            data={report.data.cumulativeResponse.data}
            detailsLink={routes.getAccuracyDetailLinkForShort(
              habitatCode,
              augurCode,
              moduleType,
              ACCURACY_DETAILS_TYPE.CUMULATIVE_RESPONSE
            )}
          />
        )}

        {report.data?.capturedResponse?.data && (
          <CapturedResponse
            baseLine={report.data.capturedResponse.baseline}
            data={report.data.capturedResponse.data}
            detailsLink={routes.getAccuracyDetailLinkForShort(
              habitatCode,
              augurCode,
              moduleType,
              ACCURACY_DETAILS_TYPE.CAPTURED_RESPONSE
            )}
          />
        )}

        {report.data?.cumulativeCapturedResponse?.data && (
          <CumulativeCapturedResponse
            baseLine={report.data.cumulativeCapturedResponse.baseline}
            data={report.data.cumulativeCapturedResponse.data}
            helpLine={cumulativeCapturedReponseHelpLine}
            detailsLink={routes.getAccuracyDetailLinkForShort(
              habitatCode,
              augurCode,
              moduleType,
              ACCURACY_DETAILS_TYPE.CUMULATIVE_CAPTURED_RESPONSE
            )}
          />
        )}

        {report.data?.roc?.data && (
          <Roc
            baseLine={report.data.roc.baseline}
            data={report.data.roc.data}
            helpLine={rocHelpLine}
            detailsLink={routes.getAccuracyDetailLinkForShort(
              habitatCode,
              augurCode,
              moduleType,
              ACCURACY_DETAILS_TYPE.ROC
            )}
          />
        )}

        {report.data?.misclassification?.data && (
          <Misclassification
            data={report.data.misclassification.data}
            legend={buckets}
            detailsLink={routes.getAccuracyDetailLinkForShort(
              habitatCode,
              augurCode,
              moduleType,
              ACCURACY_DETAILS_TYPE.MISCLASSIFICATION
            )}
          />
        )}

        {/* The predicted values are able to treat the missing data prop, so no need to switch this here */}
        <PredictedValues
          data={report.data?.predictedValuesDistribution?.data}
          detailsLink={routes.getAccuracyDetailLinkForShort(
            habitatCode,
            augurCode,
            moduleType,
            ACCURACY_DETAILS_TYPE.PREDICTED_VALUES_DISTRIBUTION
          )}
        />
      </div>
    );
  };

  const renderInner = () => {
    if (isLoading) {
      return <Busy isBusy />;
    } else if (isSuccess && report && report.data) {
      return renderCharts();
    } else {
      return (
        <IndicatorEmpty
          classNameImage={'wait-for-augur-pic'}
          headlineId={'augur.accuracy.charts_not_available'}
          headlineDefault={'Accuracy charts are not available'}
          descriptionId={'augur.wait_for_evaluation'}
          descriptionDefault={
            'Please wait until the Augur has completed the first evaluation session'
          }
        />
      );
    }
  };

  return (
    <JobDescriptionBanner>
      <div className={'Accuracy'}>{renderInner()}</div>
    </JobDescriptionBanner>
  );
};

export default Accuracy;
