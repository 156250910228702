import { HABITAT_TAB } from '../../components/dashboard/habitat/Habitat';

export const activeTab = (state, habitatCode) =>
  state.dashboard.habitats[habitatCode].activeTab;

export function hasNoAugurs(state, habitatCode) {
  return state.dashboard.habitats[habitatCode].augurs.length === 0;
}

export function isAugurActive(state, habitatCode) {
  return activeTab(state, habitatCode) === HABITAT_TAB.AUGURS;
}

export const datapoolsCount = (state, habitatCode) =>
  state.dashboard.habitats[habitatCode].datapools.data.length;

export const hasNoDatapools = (state, habitatCode) =>
  datapoolsCount(state, habitatCode) === 0;

// The Datapools tab is never disabled
export const isDatapoolsDisabled = (state, habitatCode) => false;

// The Augurs Tab is only disabled if there are no datapools for this habtat yet
export const isAugursDisabled = (state, habitatCode) =>
  hasNoDatapools(state, habitatCode);

/**
 * Is the datapools tab in the main dashboard selected?
 * @param state
 * @param habitatCode
 * @returns {boolean}
 */
export function isDatapoolActive(state, habitatCode) {
  return activeTab(state, habitatCode) === HABITAT_TAB.DATAPOOLS;
}

/**
 * Is the code capsules tab in the main dashboard selected?
 * @param state
 * @param habitatCode
 * @returns {boolean}
 */
export function isCodeCapsuleActive(state, habitatCode) {
  return activeTab(state, habitatCode) === HABITAT_TAB.CODE_CAPSULES;
}

/**
 * Is the code capsules tab in the main dashboard disabled?
 * @param state
 * @param habitatCode
 * @returns {boolean}
 */
export function isCodeCapsulesDisabled(state, habitatCode) {
  return false; // The code capsules tab is never disabled (there's no reason to do so)
}

export const codeCapsulesCount = (state, habitatCode) =>
  (state.codeCapsules.perHabitatCode[habitatCode]?.data || []).length;

export const hasNoCodeCapsules = (state, habitatCode) =>
  codeCapsulesCount(state, habitatCode) === 0;
