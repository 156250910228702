export const VARIABLE_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
  TIMESTAMP: 'timestamp',
  JSON: 'json',
  LIST_OF_STRING: 'list-of-string',
  LIST_OF_NUMBER: 'list-of-number',
  PANDAS_DATA_FRAME: 'pandas-dataframe',
};

export declare type VariableTypeType =
  typeof VARIABLE_TYPES[keyof typeof VARIABLE_TYPES];
