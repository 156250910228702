import { connect } from 'react-redux';
import NotebookWizard from './ui/NotebookWizard';
import {
  hideAddNotebook,
  addNotebook,
} from '../../../../../redux/workbench/modules/notebook.module';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { getNotebookName, getSelectedKernel } from './notebook.form';

export function mapStateToProps(state, { location }) {
  // Is a Kernel passed in the query url? (the key is ?kernel=... )
  const initialKernelName = (qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).kernel || '') as string;
  const kernelspecs =
    ((state.workbench || {}).kernelspecs || {}).kernelspecs || {};
  const initialKernel = kernelspecs[initialKernelName];
  if (initialKernel) {
    // "lift up" out the displayName properly to the first object level
    initialKernel.display_name = (initialKernel.spec || {}).display_name;
  }

  // The Kernel that's selected in the form
  const selectedKernel = getSelectedKernel(state);

  return {
    // Headline
    headline: 'Add new Notebook',
    // Notebook Name
    selectedNotebookName: getNotebookName(state),
    // Kernel
    selectedKernel,
    initialValues: {
      kernel: initialKernel,
    },
    // Same for Add, Edit, Paste
    isCreatingNoteBook: state.workbench.isCreatingNoteBook,
  };
}

export const mapDispatchToProps = {
  // Save button
  onSave: addNotebook,
  // Cancel button
  onCancel: hideAddNotebook,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotebookWizard)
);
