import { createAction } from 'redux-act';

export const showGitCommitModal = createAction('show git commit modal');

export const hideGitCommitModal = createAction('hide git commit modal');

export const showCreateBranchModal = createAction('show create branch modal');

export const hideCreateBranchModal = createAction('hide create branch modal');

export const showForceDeleteConfirm = createAction(
  'show force delete branch confirm'
);

export const hideForceDeleteConfirm = createAction(
  'hide force delete branch confirm'
);

export const showDeleteBranchModal = createAction('show delete branch modal');

export const hideDeleteBranchModal = createAction('hide delete branch modal');

export const showFetchBranchModal = createAction(
  'show fetch branch modal',
  (branch) => ({ branch })
);

export const hideFetchBranchModal = createAction('hide fetch branch modal');

export const reducer = {
  [showCreateBranchModal]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      isCreateBranchModalOpen: true,
    },
  }),
  [hideCreateBranchModal]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      isCreateBranchModalOpen: false,
    },
  }),
  [showFetchBranchModal]: (state, { branch }) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      isFetchBranchModalOpen: true,
      fetchBranch: branch,
    },
  }),
  [hideFetchBranchModal]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      isFetchBranchModalOpen: false,
    },
  }),
  [showDeleteBranchModal]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      isDeleteBranchModalOpen: true,
    },
  }),
  [hideDeleteBranchModal]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      isDeleteBranchModalOpen: false,
    },
  }),
  [showForceDeleteConfirm]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      isForceDeleteVisible: true,
    },
  }),
  [hideForceDeleteConfirm]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      isForceDeleteVisible: false,
    },
  }),
  [showGitCommitModal]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      showGitCommitModal: true,
    },
  }),
  [hideGitCommitModal]: (state) => ({
    ...state,
    showRepositoryInfo: {
      ...state.showRepositoryInfo,
      showGitCommitModal: false,
    },
  }),
};
