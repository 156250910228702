import { connect } from 'react-redux';
import Repository from './Repository';
import {
  fetchBranches,
  fetchRepoDetails,
  switchBranch,
} from '../../../../redux/workbench/modules/collab.repository.module';
import * as selector from '../../../../redux/selectors/user.selector';

export function mapStateToProps(state) {
  const { loading, loaded, error, data } =
    state.workbench.collab.repoDetails || {};
  const { activeBranch } = state.workbench.collab.repository || {};
  return {
    loading,
    loaded,
    error,
    data,
    branches: state.workbench.collab.branches.data || [],
    activeBranch,
    currentUserId: selector.currentUser(state).id,
  };
}

export const mapDispatchToProps = {
  fetchBranches,
  switchBranch,
  fetchRepoDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Repository);
