import React, { Component } from 'react';
import { derivePathPipeline } from '../_pipeline-tuning-results-common/utils';
import PipelineTuningChart from '../pipeline-tuning-chart/PipelineTuningChart';
import ExpandCollapseIcon from '../../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import SingleResultsTable from './SingleResultsTable';
import { SinglePathResult } from 'common/dist/types/module.nbc';
import {
  PathType,
  PipelineResultType,
  PipelineTuningValue,
  PipelineTuningValueNode,
} from 'common/dist/types/pipeline';

type Props = {
  /** Contains the id, displayName, the schema and the paths through this pipeline */
  pipelineResult: PipelineResultType;
  /** List of single results for this path */
  singleResults: SinglePathResult[];
  /** Path to display the results for */
  path: PathType;
  /** Highest score of this pipeline */
  highestScore: number;
  /** Formats the score with respect to the given tuning KPI*/
  scoreFormatter: (score: number) => string;
};

type State = {
  collapsed: boolean;
};

export default class SingleResultsPerPath extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { collapsed: true };
  }

  renderCollapsed(
    pathPipeline: PipelineTuningValue,
    topResult: SinglePathResult
  ) {
    const { scoreFormatter } = this.props;
    return (
      <div className={'SingleResultsPerPath--body'}>
        <div className={'SingleResultsPerPath--results-table'}>
          <SingleResultsTable
            singleResults={[topResult]}
            // @ts-ignore
            pathNodes={pathPipeline.nodes}
            scoreFormatter={scoreFormatter}
          />
        </div>
      </div>
    );
  }

  renderExpanded(
    pathPipeline: PipelineTuningValue,
    sortedSingleResults: SinglePathResult[]
  ) {
    const { scoreFormatter } = this.props;
    return (
      <div className={'SingleResultsPerPath--body'}>
        <div className={'SingleResultsPerPath--pipeline'}>
          <PipelineTuningChart
            // @ts-ignore
            pipeline={pathPipeline}
            onSelectingNode={(n) => {}}
            inactiveNodeIds={[]}
          />
        </div>
        <SingleResultsTable
          singleResults={sortedSingleResults}
          // @ts-ignore
          pathNodes={pathPipeline.nodes}
          scoreFormatter={scoreFormatter}
        />
      </div>
    );
  }

  renderHeadline(classifierNode: PipelineTuningValueNode) {
    const { singleResults, highestScore, scoreFormatter } = this.props;
    return (
      <div className={'SingleResultsPerPath--headline'}>
        <div
          className={
            'SingleResultsPerPath--headline-item SingleResultsPerPath--headline-item-toggle'
          }
        >
          <ExpandCollapseIcon
            isExpanded={!this.state.collapsed}
            onClick={(isExpanded: boolean) =>
              this.setState({ collapsed: !isExpanded })
            }
          />
        </div>
        <div
          className={
            'SingleResultsPerPath--headline-item SingleResultsPerPath--headline-item-best-score'
          }
          onClick={() => this.setState({ collapsed: !this.state.collapsed })}
        >
          <span className={'SingleResultsPerPath--headline-key'}>
            Best Score:{' '}
          </span>
          <span className={'SingleResultsPerPath--headline-value'}>
            {scoreFormatter(highestScore)}
          </span>
        </div>
        <div
          className={
            'SingleResultsPerPath--headline-item SingleResultsPerPath--headline-item-classifier'
          }
          onClick={() => this.setState({ collapsed: !this.state.collapsed })}
        >
          <span className={'SingleResultsPerPath--headline-key'}>
            Classifier:{' '}
          </span>
          <span className={'SingleResultsPerPath--headline-value'}>
            {classifierNode?.displayName}
          </span>
        </div>
        <div
          className={
            'SingleResultsPerPath--headline-item SingleResultsPerPath--headline-item-parameter-settings'
          }
          onClick={() => this.setState({ collapsed: !this.state.collapsed })}
        >
          <span className={'SingleResultsPerPath--headline-key'}>
            Tested Parameter-Settings:{' '}
          </span>
          <span className={'SingleResultsPerPath--headline-value'}>
            {singleResults?.length}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const { pipelineResult, singleResults, path } = this.props;

    // Sort the single results DESC by "score"
    const sortedSingleResults = singleResults.sort((a, b) => b.score - a.score);
    // Derive the path pipeline
    const pathPipeline = derivePathPipeline(pipelineResult.schema, path);
    // Get the classifier name (no need to go deeper into "groups", since the pathPipeline is flat and consists of "nodes" only!)
    // @ts-ignore
    const classifierNode = pathPipeline.nodes.find(
      (x) => x.type === 'node' && x.nodeType === 'classifier'
    ) as PipelineTuningValueNode;
    // Get the top result
    const topResult = sortedSingleResults[0];

    return (
      <div className={'SingleResultsPerPath'}>
        {this.renderHeadline(classifierNode)}

        {this.state.collapsed
          ? // @ts-ignore
            this.renderCollapsed(pathPipeline, topResult)
          : // @ts-ignore
            this.renderExpanded(pathPipeline, sortedSingleResults)}
      </div>
    );
  }
}
