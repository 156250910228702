import React, { FC, useEffect, useState } from 'react';
import { CommunicationsValueType } from './OptCommunications';
import Table, { RenderColumn } from '../../../../molecules/table/Table';
import commonStyles from '../optChannels/optCommonStyles.module.scss';
import {
  Communication,
  Propensity,
  CommunicationGroup,
  Channel,
} from 'common/dist/types/module.optimization';
import classNames from 'classnames';
import styles from '../../newAugurWizard/optConstraints/styles.module.scss';
import AddCommunication from './AddCommunication';
import { MessageDescriptor } from 'react-intl';
import { TypedWrappedFieldProps } from '../../../../../utils';

type Props = {
  /** List of channels defined in a previous wizard step */
  channels: Channel[];
  /** List of propensities defined in a previous wizard step */
  propensities: Propensity[];
  /** List of communication groups defined in a succeeding wizard step */
  communicationGroups?: CommunicationGroup[];
  editMode?: boolean;
  addedNewRow?: boolean;
  rowIndex: number;
};
type TableRowType = Communication & { removeDisabled: MessageDescriptor };

const CommunicationsTable: FC<
  Props & TypedWrappedFieldProps<CommunicationsValueType>
> = (props) => {
  const {
    input,
    channels,
    propensities,
    communicationGroups,
    meta,
    editMode,
    addedNewRow,
    rowIndex,
  } = props;
  const inputValue: CommunicationsValueType = input.value || [];

  // Derive additional properties for rendering only
  const communications = (inputValue || []).map((communication) => ({
    ...communication,
    removeDisabled: (communicationGroups || [])
      .flatMap((comGroup) => comGroup.communicationIds)
      .includes(communication.id)
      ? { id: 'no-id', defaultMessage: 'Used in a Communication Group' }
      : null,
  }));
  const initialVisible = {
    index: addedNewRow ? inputValue?.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  };
  const [visible, setShow] = useState(initialVisible);

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : inputValue?.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex]);
  const renderColumns: RenderColumn<TableRowType, keyof TableRowType>[] = [
    {
      key: 'name',
      renderHeader: () => <span>Name</span>,
      renderCell: (name: string, communication: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[communication.id]?.name,
          })}
        >
          {name}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,
      renderCell: (description?: string, communication?: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[communication?.id]?.description,
          })}
        >
          {description}
        </span>
      ),
    },
    {
      key: 'channelId',
      renderHeader: () => <span>Channel</span>,
      renderCell: (channelId: string, communication?: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[communication?.id]?.channelId,
          })}
        >
          {channels.find((ch) => ch.id === channelId)?.name}
        </span>
      ),
    },
    {
      key: 'propensityId',
      renderHeader: () => <span>Propensity</span>,
      renderCell: (propensityId: string, communication?: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[communication?.id]?.propensityId,
          })}
        >
          {propensities.find((al) => al.id === propensityId)?.valueId}
        </span>
      ),
    },
    {
      key: 'boostFactor',
      renderHeader: () => <span>Boost Factor</span>,
      renderCell: (boostFactor: string, communication?: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!meta.error?.[communication?.id]?.boostFactor,
          })}
        >
          {boostFactor}
        </span>
      ),
    },
    {
      key: 'variableCosts',
      renderHeader: () => <span>Variable Costs</span>,
      renderCell: (variableCosts: string, communication?: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]:
              !!meta.error?.[communication?.id]?.variableCosts,
          })}
        >
          {variableCosts}
        </span>
      ),
    },
    /*
    {
      key: 'fixedCosts',
      renderHeader: () => <span>Fixed Costs</span>,
      renderCell: (fixedCosts: string) => <span>{fixedCosts}</span>,
    },
     */
  ];
  const onRemove = (removedRow: Communication) => {
    const updatedCommunication = inputValue.filter(
      (com) => com.id !== removedRow.id
    );
    input.onChange(updatedCommunication);
  };
  const renderClickedRow = (
    element: Communication,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (editMode && visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddCommunication
                rowIndex={rowIndex}
                constraintId={element.id}
                input={input}
                meta={meta}
                channels={channels}
                propensities={propensities}
                //@ts-ignore
                removableRows={visible.shown && !element.removeDisabled}
                onRemoveRow={onRemove}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };

  return (
    <Table<TableRowType>
      data={communications}
      renderColumns={renderColumns}
      showHeader
      roundedBorder
      removableRows={editMode}
      verticalAlignMiddle
      onRemoveRow={onRemove}
      keyRemoveDisabledMessage={'removeDisabled'}
      addlRowClassname={(rowIndex: number, communication: Communication) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!meta.error?.[communication.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
    />
  );
};

export default CommunicationsTable;
