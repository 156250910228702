import React, { FC } from 'react';
import { FiBookmark } from 'react-icons/fi';
import { WrappedFieldProps } from 'redux-form';

type Props = WrappedFieldProps & {
  label: string;
  path: string;
  cellId: string;
  showSelectVariable: (
    path: string,
    cellId: string,
    name: string,
    validVariableTypes: string[],
    label: string
  ) => void;
  validVariableTypes: string[];
};

const VariableSelectCol: FC<Props> = (props) => {
  const {
    label,
    input: { value, name },
    meta: { touched, error },
    showSelectVariable,
    path,
    cellId,
    validVariableTypes,
  } = props;
  return (
    <div className={`input-col${error ? ' error' : ''}`}>
      <div className={'form--headline'}>
        <p className={'form--label'}>{label}</p>
        {touched && error && <p className={'form--error'}>{error}</p>}
      </div>
      <div className={'form--input-parent'}>
        <div
          title={'Use variable value'}
          className={`workbench-button use-variable-button origin-${
            value.origin || 'input'
          }`}
          onClick={() =>
            showSelectVariable(path, cellId, name, validVariableTypes, label)
          }
        >
          <FiBookmark className={'icon'} size={'20px'} />
        </div>
      </div>
    </div>
  );
};

export default VariableSelectCol;
