import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabContent } from 'react-tabs-redux';
import Apps from '../../apps/Apps.container';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';

export default class TabContentApps extends Component {
  render() {
    const { match } = this.props;
    const isVisible = match.params.mainTab === 'apps';

    return (
      <TabContent
        className={'main-tab-content'}
        for={'apps'}
        isVisible={isVisible}
        renderActiveTabContentOnly
      >
        <ErrorBoundary
          errorDescription={{
            id: 'error.tab.description',
          }}
          fullScreenHeight={true}
        >
          <Apps />
        </ErrorBoundary>
      </TabContent>
    );
  }
}
TabContentApps.propTypes = {
  match: PropTypes.object,
};
