// --- Interfaces
import ModuleClient from './common/_interfaces/ModuleClient';

// --- Implementations
import BcClient from './implementations/binary-classification/BcClient';
import NltClient from './implementations/natural-language-tagging/NltClient';

import { DatapoolType } from 'common/dist/types/datapool';
import { MenuEntryType } from '../../molecules/with-sidemenu/SideMenu';
import NotFound from '../../pages/not-found/NotFound.container';
import { AugurType } from 'common/dist/types/augur';
import { IntlShape } from 'react-intl';
import OptClient from './implementations/optimization/OptClient';
import { RouteConfig } from 'react-router-config';
import { DataSource } from 'common/dist/types/dataManagement/dataSource';

const modules: ReadonlyArray<ModuleClient> = [
  new BcClient(),
  new NltClient(),
  new OptClient(),
];

// --- Functions that help getting access to the required modules / parts

/**
 * Returns the module for the given type
 * @param type
 */
function getModuleForType(type: string): ModuleClient | undefined {
  const filtered = modules.filter((m) => m.type === type);
  if (filtered.length < 1) {
    console.warn(`Module for type [${type}] not found`);
    return undefined;
  }
  return filtered[0];
}

/**
 * Returns the intl id for the given type
 * @param type
 */
export function getIntlIdForType(type: string): string | undefined {
  const filtered = modules.filter((m) => m.type === type);
  if (filtered.length < 1) {
    console.warn(`Module for type [${type}] not found`);
    return undefined;
  }
  return filtered[0].intlId;
}

/**
 * Returns the intl id for the given type
 * @param type
 */
export function getNameForType(type: string): string | undefined {
  const filtered = modules.filter((m) => m.type === type);
  if (filtered.length < 1) {
    console.warn(`Module for type [${type}] not found`);
    return undefined;
  }
  return filtered[0].name;
}

/**
 * Returns the module for the given shortName
 * @param shortName
 */
function getModuleForShortName(shortName: string): ModuleClient | undefined {
  const filtered = modules.filter((m) => m.shortName === shortName);
  if (filtered.length < 1) {
    console.warn(`Module for shortName [${shortName}] not found`);
    return undefined;
  }
  return filtered[0];
}

/**
 * Returns the short name of the module given the type (e.g. for creating the details URL)
 * @param type
 */
export function getShortNameForType(type: string) {
  const filtered = modules.filter((m) => m.type === type);
  if (filtered.length < 1) {
    console.warn(`Module for type [${type}] not found`);
    return;
  }
  return filtered[0].shortName;
}

/**
 * Returns all routes of all Datapool types. The result of this function needs to be added to the router once.
 */
export function getAllDatapoolDetailsRoutes(
  datapool: DatapoolType,
  dataSource?: DataSource
): RouteConfig[] {
  return modules.flatMap((md: ModuleClient) =>
    md.datapoolDetails.getAllRoutes(md.shortName, datapool, dataSource)
  );
}

/**
 * Returns all Datapool Tabs (for the nav-bar on the left)
 */
export function getDatapoolDetailsTabs(
  habitatCode: string,
  datapoolCode: string,
  shortName: string,
  datapool: DatapoolType,
  dataSource?: DataSource
): MenuEntryType[] {
  const module = getModuleForShortName(shortName);
  if (!module) {
    return [];
  }
  return module.datapoolDetails.getAllMenuTabs(
    habitatCode,
    datapoolCode,
    shortName,
    datapool,
    dataSource
  );
}

/**
 * Returns all routes of all Datapool types. The result of this function needs to be added to the router once.
 */
export function getAllAugurDetailsRoutes(
  augurType: string,
  inputFormat: string | undefined
): RouteConfig[] {
  const flatMap = (f: any, xs: any) =>
    xs.reduce((acc: any, x: any) => acc.concat(f(x)), []);

  const routesNestedArrays = modules
    .filter((m) => m.shortName === augurType)
    .map(
      (md: ModuleClient) =>
        md.augurDetails.getAllRoutes(':moduleType', inputFormat) // TODO inputFormat = undefined is not good. Properly set that value
    );

  return [...flatMap((r) => r, routesNestedArrays), { component: NotFound }];
}

/**
 * Returns all Augur Tabs (for the nav-bar on the left)
 */
export function getAugurDetailsTabs(
  habitatCode: string,
  augurCode: string,
  shortName: string,
  inputFormat: string | undefined
): MenuEntryType[] {
  const module = getModuleForShortName(shortName);
  if (!module) {
    return [];
  }
  return module.augurDetails.getAllMenuTabs(
    habitatCode,
    augurCode,
    shortName,
    inputFormat
  );
}

/**
 * Returns the name for the module of the given type
 * @param type
 */
export function getModuleName(type: string): string | undefined {
  const module = getModuleForType(type);
  if (!module) {
    return undefined;
  }
  return module.name;
}

/**
 * Returns the suffix for the URL to redirect to when the augur details are opened
 * @param shortName
 */
export function getAugurDetailsDefaultPage(
  shortName: string
): string | undefined {
  const module = getModuleForShortName(shortName);
  if (!module) {
    return undefined;
  }
  return module.augurDetailsDefaultPage;
}

/**
 * Returns the suffix for the URL to redirect to when the augur details are opened
 * @param shortName
 */
export function getDatapoolDetailsDefaultPage(
  shortName: string
): string | undefined {
  const module = getModuleForShortName(shortName);
  if (!module) {
    return undefined;
  }
  return module.datapoolDetailsDefaultPage;
}

/**
 * Returs the info that is supposed to be shown for this augur in the overview.
 * @param augur
 * @param intl
 */
export function getOverviewAugurInfo(
  augur: AugurType,
  intl: IntlShape
): string {
  const module: ModuleClient = getModuleForType(augur.type);
  return module?.overviewAugurInfo(augur, intl);
}
