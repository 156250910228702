import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../store/store';
import Archetype from './Archetype';
import { fetchArchetypes } from '../../../../../redux/modules/augur.archetypes.module';

function mapStateToProps(state: RootState) {
  return {
    archetypes: state.archetypes,
  };
}

const mapDispatchToProps: {
  /** Loads the available Archetypes into the Redux state */
  fetchArchetypes: (habitatCode: string, datapoolCode: string) => void;
} = {
  fetchArchetypes,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(Archetype);
