import React, { Component, ReactNode } from 'react';
import CellInfoFlap from '../cell-info-flap/CellInfoFlap';
import './styles.scss';
import classNames from 'classnames';
import {
  AltaSigmaCell,
  Session,
} from '../../../../store/workbench/state.types';

export type Props = {
  path: string;
  cell: AltaSigmaCell;
  metadata?: {
    execution?: object;
    papermill?: object;
    tags?: string[];
  };
  session: Session;
  selectedCells: string[];
  parentContainerClass: string;
  executable?: boolean;
  renderFlap?: boolean;
  children: ReactNode;
  selectCells: (path: string, cellIds: string[]) => void;
  executeCells: (path: string, sessionId: string, cellId: string[]) => void;
  allCells: AltaSigmaCell[];
  readOnly?: boolean;
};

export default class NotebookCell extends Component<Props, {}> {
  static defaultProps = {
    executable: false,
    renderFlap: true,
  };

  cellRef = undefined;

  constructor(props) {
    super(props);

    this.cellRef = React.createRef();
  }

  /**
   * Check if an element is in viewport.
   *
   * @param {number} [offset]
   * @returns {boolean}
   */
  ragesIntoViewport(offset = 0) {
    if (!this.cellRef?.current) return false;
    const top = this.cellRef.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  scrollIntoView(behavior: 'smooth' | 'auto') {
    const parentHeight = this.cellRef.current.parentNode.clientHeight;
    const higherThanTheWindow =
      this.cellRef.current.clientHeight > parentHeight;

    if (higherThanTheWindow) {
      // Instead of scrollIntoView { block: 'start' } since this causes the whole page to scroll ...
      // Why is the "-45" necessary? Otherwise it will scroll outside of the view
      const scrollTop = this.cellRef.current.offsetTop - 45;
      this.cellRef.current.parentNode.scrollTo({
        top: scrollTop,
        behavior,
      });
    } else {
      // Why is the "-35" necessary? Otherwise it will not sit properly at the bottom
      const scrollTop =
        this.cellRef.current.offsetTop -
        35 -
        parentHeight +
        this.cellRef.current.clientHeight;
      this.cellRef.current.parentNode.scrollTo({
        top: scrollTop,
        behavior,
      });
    }
  }

  componentDidMount() {
    const { selectedCells, cell } = this.props;
    const isSelected = (selectedCells || []).includes(cell.id);

    // Cell is selected: make sure it's in the viewport
    if (isSelected && this.cellRef.current) {
      const ragesIntoViewport = this.ragesIntoViewport(-40);
      if (!ragesIntoViewport) {
        // Scrolling is disabled, since there needs to be a proper distinction between when to scroll and not
        // this.scrollIntoView('auto');
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (prevProps.selectedCells === this.props.selectedCells) return;
    const { selectedCells, cell } = this.props;
    if (selectedCells && selectedCells !== prevProps.selectedCells) {
      const isSelected = (selectedCells || []).includes(cell.id);
      const { selectedCells: prevSelectedCells } = prevProps;
      const wasSelected = (prevSelectedCells || []).includes(cell.id);

      // Cell was just selected: make sure it's in the viewport
      if (!wasSelected && isSelected && this.cellRef.current) {
        const ragesIntoViewport = this.ragesIntoViewport(-40);
        if (!ragesIntoViewport) {
          // Scrolling is disabled, since there needs to be a proper distinction between when to scroll and not
          // this.scrollIntoView('smooth');
        }
      }
    }
  }

  render() {
    const {
      path,
      cell,
      session,
      selectedCells,
      selectCells,
      executeCells,
      parentContainerClass,
      renderFlap,
      allCells,
      metadata,
      readOnly,
    } = this.props;
    const isSelected = (selectedCells || []).includes(cell.id);
    const { tags } = metadata;

    return (
      <div
        className={classNames(
          'NotebookCell',
          parentContainerClass,
          { 'NotebookCell-selected': isSelected },
          {
            'NotebookCell-is-multi-selected':
              isSelected && selectedCells?.length > 1,
          }
        )}
        onClick={(e) => {
          if (e.shiftKey && e.target === this.cellRef.current) {
            document.getSelection().removeAllRanges();
            const arr = [];
            const selectedIndex = allCells.findIndex(
              (c) => c.id === selectedCells[0]
            );
            const rangeIndex = allCells.findIndex((c) => c.id === cell.id);
            if (selectedIndex > rangeIndex) {
              for (let i = selectedIndex; i >= rangeIndex; i--) {
                arr.push(allCells[i].id);
              }
            } else {
              for (let i = selectedIndex; i <= rangeIndex; i++) {
                arr.push(allCells[i].id);
              }
            }
            selectCells(path, arr);
          } else selectCells(path, [cell.id]);
        }}
        ref={this.cellRef}
      >
        {renderFlap && (
          <CellInfoFlap
            path={path}
            cell={cell}
            // If the sessionId is null (how could that happen?) the cell execution will silently fail!
            sessionId={session?.id}
            executeCells={executeCells}
            isSelected={isSelected}
            executable={!readOnly}
          />
        )}
        <div
          className={classNames(
            'NotebookCell--container',
            {
              'NotebookCell--is-single-selected':
                isSelected && selectedCells?.length === 1,
            },
            tags
          )}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
