import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { err, ok } from '../../../../utils';
import { prepareContent } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser.utils';
import WorkbenchFileBrowser from '../../../organisms/workbench-browser/workbench-file-browser/WorkbenchFileBrowser';

/**
 * This component is the main part on the left side an contains the file browser
 * (that shows the 'content' = files + directories)
 */
export default class Content extends Component {
  componentDidMount() {
    //Initially
    this.props.fetchContent(this.props.selectedDirPath);
  }

  componentDidUpdate(prevProps) {
    //Every time a new folder was selected
    if (prevProps.selectedDirPath !== this.props.selectedDirPath) {
      this.props.fetchContent(this.props.selectedDirPath);
    }
  }

  render() {
    const { content, width, selectedDirPath, error } = this.props;

    const selectedContent = error
      ? err(error)
      : ok(prepareContent(content, selectedDirPath));
    const path = selectedDirPath.join('/');

    return (
      <WorkbenchFileBrowser
        content={selectedContent}
        path={path}
        width={width}
      />
    );
  }
}

Content.propTypes = {
  error: PropTypes.object,
  content: PropTypes.object,
  selectedDirPath: PropTypes.array,
  fetchContent: PropTypes.func.isRequired,
  repoMeta: PropTypes.object,
};
