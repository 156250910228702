import { connect } from 'react-redux';
import NotebookTabLinkElement from './NotebookTabLinkElement';
import {
  closeNotebook,
  selectNotebook,
  measuredTabWidth,
  setNotebookTabDragging,
} from '../../../../../../redux/workbench/modules/notebook.module';
import {
  hideMenu,
  updateOverlappingTabs,
} from '../../../../../../redux/workbench/modules/notebook.overlapping.module';

export function mapStateToProps(state, { paneId }) {
  return {
    pane: state.workbench.panes[paneId],
  };
}

export const mapDispatchToProps = {
  selectNotebook,
  closeNotebook,
  measuredTabWidth,
  updateOverlappingTabs,
  hideOverlappingMenu: hideMenu,
  setNotebookTabDragging,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotebookTabLinkElement);
