import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { dataManagementRoutes } from '../../routes';
import { FiChevronLeft } from 'react-icons/fi';
import { UPLOAD_WIZARDS } from '../upload/uploadWizards';
import Busy from '../../../atoms/busy/Busy';
import CassandraTablePreview from '../../../organisms/cassandra-table-preview/CassandraTablePreview';
import Button from '../../../atoms/button/Button';

export default class TableBrowser extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = { buttonClicked: false };
  }

  componentDidMount() {
    const {
      dataSourceCode,
      keyspaceName,
      tableName,
      fetchCassandraTableSample,
    } = this.props;
    if (keyspaceName && tableName) {
      fetchCassandraTableSample(dataSourceCode, keyspaceName, tableName);
    }
  }

  render() {
    const {
      match: { params },
      dataSourceCode,
      data,
      loading,
      loaded,
      error,
      setActiveUploadWizard,
    } = this.props;
    const tableName = params.tableName || '';
    const keyspaceName = params.keyspaceName || '';

    if (error) {
      return (
        <div className={'cassandra-table-browser'}>
          <span className={'error'}>{JSON.stringify(error)}</span>
        </div>
      );
    }

    if (loading) {
      return <Busy isBusy />;
    }

    return (
      <div className={'cassandra-table-browser table-reset'}>
        <div className={'table-browser-header'}>
          <div className={'back-button-container'}>
            <Link
              to={`${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}/keyspace/${keyspaceName}`}
              style={{ textDecoration: 'none' }}
            >
              <div className={'back-button'}>
                <FiChevronLeft size={20} className={'back-icon'} />
                <span className={'back-text'}>Back to Tables</span>
              </div>
            </Link>
          </div>
          <div className={'headline-buttons'}>
            <form
              action={`/dataman/cassandra/${dataSourceCode}/keyspace/${keyspaceName}/table/${tableName}/download`}
              method={'GET'}
              ref={this.formRef}
              onSubmit={(e) => {
                // TODO Try to prevent a page refresh
                // e.preventDefault() // This also just prevents the action/download?, so it's pointless
                // e.nativeEvent.preventDefault();
                // e.stopPropagation();
                // e.nativeEvent.stopImmediatePropagation();
                // e.stopPropagation();
                // return false;
              }}
            />
            <Button
              buttonColor={'secondary'}
              withLink
              buttonLabelDefault={'Upload Data'}
              linkTo={`${dataManagementRoutes.basePath}/cassandra/${dataSourceCode}/upload`}
              onClick={() =>
                setActiveUploadWizard(UPLOAD_WIZARDS.UPLOAD, undefined)
              }
            />
          </div>
        </div>

        <span className={'table-browser-headline'}>
          TableBrowser for Table {tableName} in Keyspace {keyspaceName}
        </span>
        {/* <span className={'table-browser-desc'}>Showing a data sample</span> */}

        {data && data.colSpecs && data.data && (
          <CassandraTablePreview
            colSpecs={data.colSpecs}
            data={data.data}
            editable={false}
          />
        )}

        {loaded && (!data || data.length === 0) && (
          <span>The table is currently empty.</span>
        )}
      </div>
    );
  }
}

TableBrowser.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      /** Name of the Keyspace (from the route) */
      keyspaceName: PropTypes.string,
      /** Name of the Table (from the route) */
      tableName: PropTypes.string,
    }),
  }),
  /** The keyspaceName is extracted from the props in TableBrowser.container (just for convenience)*/
  keyspaceName: PropTypes.string.isRequired,
  tableName: PropTypes.string.isRequired,
  dataSourceCode: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.shape({
    colSpecs: PropTypes.arrayOf(
      PropTypes.shape({
        colName: PropTypes.string,
        colType: PropTypes.string,
      })
    ),
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }),
  fetchCassandraTableSample: PropTypes.func.isRequired,
  /** Set the active upload wizard */
  setActiveUploadWizard: PropTypes.func.isRequired,
};
