export const initial = {
  loading: false,
  isSpinnerShown: false,
  error: '',
};

export const test = {
  loading: false,
  isSpinnerShown: false,
  error: '',
};
