import React, { FC, useState, useRef } from 'react';
import { BiographyEntryPrediction } from 'common/dist/types/augurBiography';
import TimestampAndLine from './TimestampAndLine';
import CommonEntryParts from './CommonEntryParts';
import { Link } from 'react-router-dom';
import ExpandCollapseIcon from '../../../atoms/expand-collapse-icon/ExpandCollapseIcon';
import BarChart from '../../../molecules/charts/bar-chart/BarChart';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { buildDatapoolString } from 'common/dist/utils/nameUtils';
import { FiChevronsRight } from 'react-icons/fi';

type Props = {
  entry: BiographyEntryPrediction;
  renderDate: boolean;
  datapoolCode: string;
  /** might be "undefined" while the data sources are still loading */
  dsType?: string;
  /** might be "undefined" while the data sources are still loading */
  dsCode?: string;
  selected: boolean;
  selectable: boolean;
};

const getLinks = (
  datapoolCode: string,
  dsType: string,
  dsCode: string,
  entry: BiographyEntryPrediction
) => {
  //the link for s3 buckets contain "-" instead of "_"
  //see ActionButtons.tsx
  //s3 doesn't support underscores
  const convertedDatapoolName = buildDatapoolString(datapoolCode, dsType);

  switch (dsType) {
    case 's3': {
      return {
        inputLink: `/app/data/${dsType}/${dsCode}/bucket/${convertedDatapoolName}/preview-table/${entry.inputTable}.parquet`,
        outputLink: `/app/data/${dsType}/${dsCode}/bucket/${convertedDatapoolName}/preview-table/${entry.outputTable}.parquet`,
      };
    }
    case 'cassandra': {
      return {
        inputLink: `/app/data/${dsType}/${dsCode}/keyspace/${convertedDatapoolName}/table/${entry.inputTable.toLowerCase()}`,
        outputLink: `/app/data/${dsType}/${dsCode}/keyspace/${convertedDatapoolName}/table/${entry.outputTable.toLowerCase()}`,
      };
    }
    default:
      return {};
  }
};

const PredictionEntry: FC<Props> = ({
  entry,
  renderDate,
  dsCode,
  datapoolCode,
  dsType,
  selected,
  selectable,
}) => {
  const [chartExpanded, expandChart] = useState(false);
  const { inputLink, outputLink } = getLinks(
    datapoolCode,
    dsType,
    dsCode,
    entry
  );

  const infoRef = useRef();

  return (
    <div
      className={classNames(styles.entry, styles.entryPrediction, {
        [styles.selected]: selected,
        [styles.selectable]: selectable,
      })}
    >
      <TimestampAndLine createdAt={entry.createdAt} renderDate={renderDate} />

      <div className={styles.entryBody}>
        <CommonEntryParts entry={entry} />

        <div className={styles.info}>
          <div className={styles.infoTitle}>Input Table:</div>
          <div className={styles.infoValue}>
            {dsCode && datapoolCode && entry.inputTable ? (
              <Link onClick={(e) => e.stopPropagation()} to={inputLink}>
                {entry.inputTable}
              </Link>
            ) : (
              <span>{entry.inputTable}</span>
            )}
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.infoTitle}>Output Table:</div>
          <div className={styles.infoValue}>
            {dsCode && datapoolCode && entry.outputTable ? (
              <Link onClick={(e) => e.stopPropagation()} to={outputLink}>
                {entry.outputTable}
              </Link>
            ) : (
              <span>{entry.outputTable}</span>
            )}
          </div>
        </div>

        {entry.predictedValuesDistribution && (
          <div
            ref={infoRef}
            className={classNames(styles.info, styles.infoPlot)}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={styles.infoTitle}
            >
              <span>Distribution of predicted values</span>
              <ExpandCollapseIcon
                isExpanded={chartExpanded}
                onClick={() => {
                  expandChart(!chartExpanded);
                }}
              />
            </div>
            {chartExpanded && (
              <div className={styles.infoPlotParent}>
                <BarChart
                  height={'120px'}
                  width={
                    // @ts-ignore
                    infoRef.current.getBoundingClientRect().width
                  }
                  data={entry.predictedValuesDistribution}
                  withBorder
                />
              </div>
            )}
          </div>
        )}
      </div>
      {selected && (
        <div className={styles.selectedMarker}>
          <FiChevronsRight size={16} />
        </div>
      )}
    </div>
  );
};

export default PredictionEntry;
