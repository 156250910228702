import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Wizard from '../../../../pages/wizard/Wizard';
import './styles.scss';

/**
 * Confirmation that is shown if there are unsaved changes in the notebook and the user clicked "close"
 */
export default class CloseConfirm extends Component {
  render() {
    const {
      path,
      content,
      saveNotebook,
      closeNotebook,
      hideCloseConfirm,
      paneId,
    } = this.props;

    const buttons = [
      {
        withLink: false,
        buttonColor: 'orange',
        buttonLabelId: 'todo',
        buttonLabelDefault: 'Discard Changes',
        onClick: () => {
          closeNotebook(path, paneId, false);
        },
      },
      {
        withLink: false,
        buttonColor: 'secondary',
        buttonLabelId: 'todo',
        buttonLabelDefault: 'Save & Close',
        onClick: () => {
          saveNotebook(path, content);
          closeNotebook(path, paneId, false);
        },
      },
      {
        withLink: false,
        buttonColor: 'white',
        buttonLabelId: 'todo',
        buttonLabelDefault: 'Cancel',
        onClick: () => hideCloseConfirm(path),
      },
    ];

    return (
      <Wizard
        withHeadline={true}
        headlineId={'todo'}
        headlineDefault={'Unsaved Changes'}
        buttons={buttons}
        additionalClassname={'CloseConfirm'}
      >
        <div className={'CloseConfirm--body'}>
          <div className={'CloseConfirm--headline'}>
            <p>There are unsaved changes.</p>
          </div>
          <div className={'CloseConfirm--text'}>
            <p>Are you sure you want to close without saving?</p>
          </div>
        </div>
      </Wizard>
    );
  }
}

CloseConfirm.propTypes = {
  /** Path of the notebook/file */
  path: PropTypes.string.isRequired,
  /** ID of the pane the notebook/file is opened in */
  paneId: PropTypes.string.isRequired,
  /** Content of the notebook/file */
  content: PropTypes.object.isRequired,
  /** Save the notebook/file */
  saveNotebook: PropTypes.func.isRequired,
  /** Close the notebook/file */
  closeNotebook: PropTypes.func.isRequired,
  /** Hide the confirmation dialog without doing anything */
  hideCloseConfirm: PropTypes.func.isRequired,
};
