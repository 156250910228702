import { connect } from 'react-redux';
import NotebookWizard from './ui/NotebookWizard';
import {
  hidePasteNotebook,
  pasteNotebookFromClipboard,
} from '../../../../../redux/workbench/modules/notebook.module';
import { getNotebookName } from './notebook.form';

function initialNotebookNameSafe(state) {
  if (!state.workbench.clipboard.data) return '';
  return state.workbench.clipboard.data.name;
}

function copiedNotebookTypeSafe(state) {
  return state.workbench.clipboard.type;
}

function copiedNotebookPathSafe(state) {
  if (!state.workbench.clipboard.data) return '';
  return state.workbench.clipboard.data.path;
}

export function mapStateToProps(state) {
  const clipboard = state.workbench.clipboard;

  return {
    // Can the wizard even work or is some crucial information missing?
    redirect: clipboard.data === undefined,
    // Headline
    headline: 'Paste Notebook',
    // Notebook Name
    selectedNotebookName: getNotebookName(state),
    // Kernel
    step2disabled: true,
    initialValues: {
      notebookName: initialNotebookNameSafe(state),
    },
    // Same for Add, Edit, Paste
    isCreatingNoteBook: state.workbench.isCreatingNoteBook,
  };
}

export const mapDispatchToProps: {
  onSave: (
    selectedNotebookName: string,
    kernelName: never,
    kernelDisplayName: never,
    altaSigmaMeta: never
  ) => void;
  onCancel: () => void;
} = {
  // Save button
  onSave: pasteNotebookFromClipboard,
  // Cancel button
  onCancel: hidePasteNotebook,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotebookWizard);
