import React, { FC } from 'react';

import NotificationsContainer from '../notification/Notifications.container';
import PageSpinnerContainer from '../pageSpinner/PageSpinner.container';
import { Redirect, Route, Switch } from 'react-router-dom';

import Admin from '../admin/Admin';
import UserProfile from '../user-profile/UserProfile.container';
import DashboardContainer from '../dashboard/Dashboard.container';
import ModelManagementNoTopBar from '../modelManagement/ModelManagementNoTopBar.container';
import NotFound from '../pages/not-found/NotFound.container';
import Header from '../organisms/header/header/Header.container';

import { app } from './routes';
import { mainTabSubPaths, routes } from 'common/dist/constants/frontendRoutes';
import NotEvenOnePermission from '../pages/not-even-one-permission/NotEvenOnePermission.container';

type Props = {
  mainTabsDefaultLink: string;
};

const RootRoutes: FC<Props> = (props: Props) => {
  const { mainTabsDefaultLink } = props;

  return (
    <div className='App'>
      <Header />
      <PageSpinnerContainer>
        <Switch>
          {/* Simply a redirect to the first permitted main tab */}
          <Route path={'/'} exact={true}>
            <Redirect to={mainTabsDefaultLink} />
          </Route>

          {/* Simply a redirect to /app/admin/users */}
          <Route
            path={app.prefix + app.admin.index}
            exact={true}
            component={() => <Redirect to='/app/admin/users' />}
          />

          {/* UserProfile /app/account/... */}
          <Route path={app.prefix + app.account} component={UserProfile} />

          {/* Admin */}
          <Route path={app.prefix + app.admin.index} component={Admin} />

          {/* Some pages from the Model Management that provide their own top bar */}
          <Route
            path={'/app/habitat/:habitatCode'}
            component={ModelManagementNoTopBar}
          />

          {/* Main Tabs (Dashboard, Workbench, Orchestration, ...). */}
          <Route
            path={`${app.prefix}:mainTab(${Object.values(mainTabSubPaths).join(
              '|'
            )})`}
            component={DashboardContainer}
          />

          {/* Shown if the user doesn't have permissions on any main tabs */}
          <Route path={routes.noMainTabs}>
            <NotEvenOnePermission fullViewHeight />
          </Route>

          {/* Fallback: 404 */}
          <Route path={'*'} component={() => <NotFound fullViewHeight />} />
        </Switch>
      </PageSpinnerContainer>
      <NotificationsContainer />
    </div>
  );
};

export default RootRoutes;
