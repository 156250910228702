import * as icons from '../../core/constants/icons';

export const initial = {
  activeTabId: 'taxonomy',
  tabs: [
    {
      id: 'taxonomy',
      title: 'datapoolDetails.sideNav.taxonomy',
      icon: icons.taxonomy,
    },
    {
      id: 'archetypes',
      title: 'datapoolDetails.sideNav.archetypes',
      icon: icons.settings,
    },
  ],
  datapoolCode: '',
  habitatCode: '',
  loading: false,
  loaded: false,
  taxonomy: [],
  datapool: undefined,
  treeData: [],
  initialTreeData: [],
  saving: false,
  error: undefined,
};

export const test = {
  activeTabId: 'taxonomy',
  tabs: [
    {
      id: 'taxonomy',
      title: 'datapoolDetails.sideNav.taxonomy',
      icon: icons.taxonomy,
    },
    {
      id: 'archetypes',
      title: 'datapoolDetails.sideNav.archetypes',
      icon: icons.settings,
    },
  ],
  datapoolCode: '',
  habitatCode: '',
  loading: false,
  loaded: false,
  taxonomy: [],
  datapool: undefined,
  treeData: [],
  initialTreeData: [],
  saving: false,
  error: undefined,
};
