import P3InputElement, { AsInputElement } from './P3InputElement';
import TextInputWrapper from '../text-input/TextInputWrapper';
import NumberInputWrapper from '../number-input/NumberInputWrapper';
import NumericSliderWrapper from '../numeric-slider/NumericSliderWrapper';
import CheckBoxWrapper from '../check-box/CheckBoxWrapper';
import ToggleWrapper from '../toggle/ToggleWrapper';
import DropdownSelectWrapper from '../dropdown-select/DropdownSelectWrapper';
import RadioButtonsWrapper from '../radio-buttons/RadioButtonsWrapper';
import MultipleSelectWrapper from '../multiple-select/MultipleSelectWrapper';
import DatetimePickerWrapper from '../datetime-picker/DatetimePickerWrapper';
import DatePickerWrapper from '../datetime-picker/DatePickerWrapper';
import UploadElementWrapper from '../upload-element/UploadElementWrapper';
import SelectableScatterPlotWrapper from '../selectable-scatter-plot/SelectableScatterPlotWrapper';
import { ComponentType } from 'react';
import EditableTableWrapper from '../editable-table/EditableTableWrapper';

// Contains all available Python3 Input Cells - required for looking them up by their id during rendering later
export const allInputElements: Array<P3InputElement> = [
  new TextInputWrapper(), // text-input
  new NumberInputWrapper(), // number-input
  new NumericSliderWrapper(), // numeric-slider
  new CheckBoxWrapper(), // check-box
  new ToggleWrapper(), // toggle
  new DropdownSelectWrapper(), // dropdown-select
  new RadioButtonsWrapper(), // radio-buttons
  new MultipleSelectWrapper(), // multiple-select
  new DatetimePickerWrapper(), // datetime-picker
  new DatePickerWrapper(), // date-picker
  new UploadElementWrapper(), // upload
  new SelectableScatterPlotWrapper(), // selectable-scatter-plot
  new EditableTableWrapper(), // editable-table
];

/**
 * Returns the input element implementation for the given type
 * @param elementType
 */
export function getElementByType(elementType: string): P3InputElement {
  const foundElement = allInputElements.find((c) => c.type === elementType);
  if (!foundElement) {
    // console.log(`Unknown element type: ${elementType}`);
  }
  return foundElement;
}

/**
 * Takes the as_elements and generates the code for them
 * @param cell
 */
export function p3CellToSource(cell: any): string {
  if (!cell.as_elements) return '';

  return cell.as_elements
    .map((e: any) => {
      const foundInputClass = getElementByType(e.type);
      if (!foundInputClass) return '';
      return foundInputClass.getSource(e, cell.as_variables);
    })
    .join('\r\n');
}

export function p3ValidateElement(element: AsInputElement) {
  const foundElement = getElementByType(element.type);
  if (!foundElement) return undefined;

  // @ts-ignore
  return foundElement.validate(element);
}

/**
 * Returns the "AdditionalSettings" Component for the given elementType
 * @param elementType
 */
export function p3AdditionalSettingsForType(
  elementType: string
): ComponentType | undefined {
  const foundElement = getElementByType(elementType);
  if (!foundElement) return undefined;

  return foundElement.AdditionalSettings;
}
