import React, { Component } from 'react';
import styles from './styles.module.scss';
import SamplingStrategySelect from '../../../../molecules/sampling-strategy/SamplingStrategySelect';
import { WrappedFieldProps } from 'redux-form';
import { DatapoolType } from 'common/dist/types/datapool';

export type Props = {
  datapool: DatapoolType;
  // selectedValue?: ValueType;
  dropdownSelectPortal?: HTMLElement;
};

export default class SamplingStrategy extends Component<
  Props & WrappedFieldProps
> {
  render() {
    const { dropdownSelectPortal, input, meta, datapool } = this.props;

    return (
      <div className={styles.samplingStrategy}>
        <SamplingStrategySelect
          input={input}
          meta={meta}
          withLinksInInfo={false}
          dropdownSelectPortal={dropdownSelectPortal}
          datapool={datapool}
        />
      </div>
    );
  }
}
