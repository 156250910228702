import React, { Component } from 'react';
import MultiPageWizard from '../../organisms/multi-page-wizard/MultiPageWizard.container';
import WizardPage from '../../organisms/multi-page-wizard/WizardPage';
import { commonReduxFormProps, wizardHeadline } from '../newDatapool.form';
import {
  defaultAsyncBlurFields,
  defaultAsyncChangeFields,
  defaultValidate,
  descriptionModuleTypeStep,
  descriptionNameStep,
  fieldDatapoolName,
  fieldDataSourceCode,
  fieldModuleType,
  getDefaultMpwAsyncValidate,
  titleModuleTypeStep,
  titleNameStep,
} from './default.form';
import WizardStep from '../../organisms/multi-page-wizard/WizardStep';
import DatapoolName from '../../organisms/modelManagement/newDatapoolWizard/datapoolName/DatapoolName';
import ModuleType from '../../organisms/modelManagement/newAugurWizard/moduleType/ModuleType';
import * as routes from '../../index/routes';
import DataSource from '../../organisms/modelManagement/newDatapoolWizard/dataSource/DataSource';
import datapoolMsgs from 'common/dist/messages/datapools';
import { ModulesState } from '../../../store/modules/state.types';

export type OwnProps = {
  habitatCode: string;
  habitatName?: string;

  /** Uses react-routers history.push() to open another page */
  goToLink: (link: string) => void;
};

type ContainerProps = {
  modules?: ModulesState;
};

type Props = OwnProps & ContainerProps;

export const renderDefaultWizardSteps = (modules: ModulesState) => {
  return (
    <>
      <WizardStep
        fieldName={fieldModuleType}
        stepNumber={1}
        title={titleModuleTypeStep}
        description={descriptionModuleTypeStep}
      >
        {/* @ts-ignore */}
        <ModuleType // Using the component ModuleType from the newAugur wizard is correct - the component is exactly the same as for the augurs
          modules={modules?.data || []}
          loading={modules?.loading}
          blurOnClick
        />
      </WizardStep>

      <WizardStep
        fieldName={fieldDatapoolName}
        stepNumber={2}
        title={titleNameStep}
        description={descriptionNameStep}
      >
        {/* @ts-ignore */}
        <DatapoolName
          inputId={fieldDatapoolName}
          inputName={fieldDatapoolName}
        />
      </WizardStep>

      <WizardStep
        stepNumber={3}
        title={datapoolMsgs.msgNewDatapoolStepDataSourceTitle}
        description={datapoolMsgs.msgNewDatapoolStepDataSourceDescription}
        fieldName={fieldDataSourceCode}
      >
        {/* @ts-ignore */}
        <DataSource dropdownSelectPortal={document.querySelector('body')} />
      </WizardStep>
    </>
  );
};

export default class DefaultNewDatapoolWizard extends Component<Props> {
  render() {
    const { habitatCode, habitatName, modules, goToLink } = this.props;

    return (
      // @ts-ignore
      <MultiPageWizard
        {...commonReduxFormProps}
        validate={defaultValidate}
        // @ts-ignore
        asyncValidate={getDefaultMpwAsyncValidate(habitatCode)}
        asyncBlurFields={defaultAsyncBlurFields}
        asyncChangeFields={defaultAsyncChangeFields}
        noNextOrSubmitButton
        wizardHeadline={wizardHeadline(habitatName)}
        onCancel={() => {
          goToLink(routes.app.prefix + routes.app.models);
        }}
      >
        {/* @ts-ignore */}
        <WizardPage
          pageTitle={{ id: 'no-id', defaultMessage: 'Module & Name' }}
        >
          {renderDefaultWizardSteps(modules)}
        </WizardPage>
      </MultiPageWizard>
    );
  }
}
