import React, { FC, useEffect, useState } from 'react';
import Browser from '../../part-left/Browser';
import RightPart from '../../part-right/RightPart';
import Fullscreen from 'react-full-screen';
import PanelGroup from 'react-panelgroup';
import Notifications from './Notifications.container';
import MainContainer from '../../../pages/main-container/MainContainer';
import { defaultBrowserSize } from '../../part-left/BrowserTabLinks';
import classNames from 'classnames';
import fullscreenStyles from '../../fullscreenStyles.module.scss';
import styles from './styles.module.scss';
import { FiMaximize, FiMinimize } from 'react-icons/fi';
import ToggleInput from '../../../atoms/input-elements/toggle-input/ToggleInput';
import { useQuery } from 'react-query';
import { apiRequest, fetchQueryFn } from '../../../../core/api/_tools';
import { getSettingsHistory } from '../../../../core/api/augurs';

type Props = {
  fullscreen: boolean;
  fetchSessions: () => void;
  fetchKernelspecs: () => void;
  setFullscreen: (isFullscreen: boolean) => void;
  toggleBrowser: (isExpanded: boolean) => void;
  browserExpanded: boolean;
  mainBrowserResize: (size: number) => void;
  notebookUser: string;
  resetEventSource: () => void;
  checkWhetherNotebookIsRunning: (notebookUser: string, retry: boolean) => void;
};

type Editor = 'asnotebooks' | 'vscode';

const WorkbenchMainContainer: FC<Props> = (props) => {
  const {
    checkWhetherNotebookIsRunning,
    fetchSessions,
    fetchKernelspecs,
    notebookUser,
    resetEventSource,
    toggleBrowser,
    browserExpanded,
    mainBrowserResize,
    fullscreen,
    setFullscreen,
  } = props;

  useEffect(() => {
    checkWhetherNotebookIsRunning(notebookUser, false);
    fetchSessions();
    fetchKernelspecs();
    resetEventSource();
  });

  const [editor, setEditor] = useState<Editor>('asnotebooks');

  const vsCodeUrl = `/jupyter/user/${notebookUser}/proxy/3000/?folder=%2Fworkbench`;

  const { isSuccess: vsCodeAvailable } = useQuery('vscode-available', () =>
    fetchQueryFn('vscode-available', () => apiRequest(vsCodeUrl))
  );

  const renderButtons = () => {
    return (
      <div
        className={classNames(styles.buttons, {
          [styles.isFullscreen]: fullscreen,
        })}
      >
        {vsCodeAvailable && (
          <div className={styles.editorToggleContainer}>
            <ToggleInput
              onChange={() =>
                setEditor(editor === 'asnotebooks' ? 'vscode' : 'asnotebooks')
              }
              checked={editor === 'vscode'}
              disabled={false}
            />
          </div>
        )}
        <div
          className={styles.fullscreenButton}
          onClick={() => setFullscreen(!fullscreen)}
        >
          {fullscreen ? (
            <FiMinimize className={'fullscreen-icon'} size={'16px'} />
          ) : (
            <FiMaximize className={'fullscreen-icon'} size={'16px'} />
          )}
        </div>
      </div>
    );
  };

  const renderAsNotebooks = () => {
    return (
      <MainContainer
        additionalClassname={classNames(
          'Workbench',
          { [fullscreenStyles.fullscreenContainer]: fullscreen },
          { 'browser-collapsed': !browserExpanded }
        )}
        transparent={false}
        fullWidth={true}
      >
        {renderButtons()}
        {/* For !browserExpanded add an empty div with size 0, so that the RightPart is not remounted when switching */}
        <PanelGroup
          panelWidths={
            browserExpanded
              ? [
                  {
                    minSize: 240,
                    size: defaultBrowserSize,
                    resize: 'dynamic',
                  },
                  { minSize: 300, resize: 'stretch' },
                ]
              : [
                  { minSize: 0, size: 0, resize: 'fixed' },
                  { minSize: 300, resize: 'stretch' },
                ]
          }
          onResizeEnd={(panels) => mainBrowserResize(panels[0].size)}
          spacing={0}
        >
          {browserExpanded ? (
            <Browser toggleBrowser={toggleBrowser} />
          ) : (
            <div />
          )}
          <RightPart
            browserExpanded={browserExpanded}
            toggleBrowser={toggleBrowser}
          />
        </PanelGroup>
        <Notifications />
      </MainContainer>
    );
  };

  const renderVsCode = () => {
    return (
      <MainContainer
        additionalClassname={classNames(
          'Workbench',
          { [fullscreenStyles.fullscreenContainer]: fullscreen },
          { 'browser-collapsed': !browserExpanded }
        )}
        transparent={false}
        fullWidth={true}
      >
        {renderButtons()}
        <iframe
          className={styles.vscodeIframe}
          width={'100%'}
          height={'100%'}
          src={vsCodeUrl}
        />
        <Notifications />
      </MainContainer>
    );
  };

  return (
    <Fullscreen
      enabled={fullscreen}
      onChange={(isFull) => setFullscreen(isFull)}
    >
      {editor === 'asnotebooks' ? renderAsNotebooks() : renderVsCode()}
    </Fullscreen>
  );
};

WorkbenchMainContainer.defaultProps = {
  browserExpanded: true,
};

export default WorkbenchMainContainer;
