import React, { Component } from 'react';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { S3Settings } from 'common/dist/types/dataManagement/dataSource';
import { WrappedFieldProps } from 'redux-form';

export type Value = S3Settings;
export type Error = string;
export type Props = {
  input: {
    value: Value;
    onBlur: (v: Value) => ToBeRefined;
    onChange: (v: Value) => ToBeRefined;
  };
  valid: boolean;
  error: Error;
  touched: boolean;
};

export default class StepS3Settings extends Component<
  Props & WrappedFieldProps
> {
  render() {
    const {
      meta: { error, touched },
      input: { value, onChange, onBlur },
    } = this.props;
    const { host, port, accessKey, secretKey, region } = value;
    return (
      <div className={'input-container'}>
        {touched && error && (
          <div className={'error-container'}>
            <p className={'error'}>{error}</p>
          </div>
        )}
        <div className={'input'}>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>Host</span>
            </label>
            <input
              type={'text'}
              value={host || ''}
              onChange={(e) => onChange({ ...value, host: e.target.value })}
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>Port</span>
            </label>
            <input
              type={'text'}
              value={port || ''}
              onChange={(e) => onChange({ ...value, port: e.target.value })}
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>Access Key</span>
            </label>
            <input
              type={'text'}
              value={accessKey || ''}
              onChange={(e) =>
                onChange({ ...value, accessKey: e.target.value })
              }
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>Secret Key</span>
            </label>
            <input
              type={'text'}
              value={secretKey || ''}
              onChange={(e) =>
                onChange({ ...value, secretKey: e.target.value })
              }
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
          <div className={'settings-input-element'}>
            <label className='form--label'>
              <span>Region</span>
            </label>
            <input
              type={'text'}
              value={region || ''}
              onChange={(e) => onChange({ ...value, region: e.target.value })}
              className={'form--field'}
              onBlur={() => onBlur(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}
