import { AccountState } from '../../store/account/state.types';

export const initial: AccountState = {
  photo: {
    loading: false,
    loaded: false,
    error: undefined,
    path: '',
    file: null,
    uploading: false,
    uploaded: false,
    uploadError: undefined,
  },
  loginHistory: {
    loading: false,
    loaded: false,
    error: undefined,
    history: [],
  },
};

// TODO
export const test: any = {
  language: 'en',
  user: {
    locale: 'nl',
    firstName: 'John',
    middleName: '',
    lastName: 'Johnson',
    position: 'manager',
    accountName: 'Manager Inc',
  },
  photo: {
    loading: false,
    loaded: false,
    error: 'error',
    path: '/path/',
    file: {},
  },
  loginHistory: {
    loading: false,
    loaded: true,
    error: '',
    history: [
      {
        timestamp: new Date(2016, 10, 10),
        ip: '1.1.1.1',
        browser: 'Chrome',
        device: 'Mac OSX',
        success: true,
      },
    ],
  },
};
