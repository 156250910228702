import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PreQueueContainer from './pre-queue/PreQueue.container';
import { orchestrationRoutes } from '../routes';

export class Queue extends Component {
  componentDidMount() {
    this.loadQueues();
  }

  loadQueues() {
    const { fetchPreQueue } = this.props;
    fetchPreQueue();
  }

  getActiveCategory() {
    const path = this.props.history.location.pathname;
    if (!path) return null;
    const rep = path.replace('/app/orchestration/queue/', '');
    const parts = rep.split('/');
    return parts[0];
  }

  render() {
    return (
      <div className='Orchestration--content'>
        <div className={'Orchestration--inner-content'}>
          <Switch>
            <Route
              exact
              path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.queue.path}/pre`}
            >
              <PreQueueContainer />
            </Route>
            <Redirect
              exact
              path={`${orchestrationRoutes.basePath}/${orchestrationRoutes.queue.path}`}
              to={`${orchestrationRoutes.basePath}/${orchestrationRoutes.queue.path}/pre`}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

Queue.propTypes = {
  links: PropTypes.shape({
    pre: PropTypes.string.isRequired,
    execution: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.object,
  fetchPreQueue: PropTypes.func.isRequired,
};

export default withRouter(Queue);
