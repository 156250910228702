import { connect } from 'react-redux';
import BrowserTabContents from './BrowserTabContents';
import { selectBrowser } from '../../../redux/workbench/modules/browser.module';

export function mapStateToProps(state) {
  return {
    selectedBrowser: state.workbench.selectedBrowser,
  };
}

export const mapDispatchToProps = {
  handleSelect: selectBrowser,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowserTabContents);
