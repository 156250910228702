export const initial = {
  isTooltip: false,
  tooltipX: 0,
  tooltipY: 0,
  tooltipData: [],
};

export const test = {
  isTooltip: false,
  tooltipX: 10,
  tooltipY: 20,
  tooltipData: [1, 1],
};
