import { apiRequest, CompletedApiRequest } from './_tools';
import qs from 'qs';
import { SearchResults } from 'common/dist/types/responseBodies/dashboard';

export function search(q: string): CompletedApiRequest<SearchResults> {
  const query = qs.stringify(
    { q: encodeURIComponent(q) },
    { addQueryPrefix: true }
  );
  return apiRequest(`/api/search${query}`);
}

export function getLocales(locale: string) {
  return apiRequest(`/api/locales/${locale}`);
}

export function getConfig() {
  return apiRequest('/api/config');
}
