import React, { FC } from 'react';
import { ToBeRefined } from 'common/dist/types/todo_type';
import Modal from '../../../organisms/modal/Modal';
import styles from '../styles.module.scss';

type Props = {
  isOpen: boolean;
  error: ToBeRefined;
  closeModal: () => void;
};

const ErrorModal: FC<Props> = (props: Props) => {
  const { isOpen, closeModal, error } = props;

  return (
    <Modal
      show={isOpen}
      hideModal={closeModal}
      headline={{
        id: 'common.error',
        defaultMessage: 'Error',
      }}
    >
      <div className={styles.errorModal}>
        <span>{error.message !== undefined ? error.message : error}</span>
      </div>
    </Modal>
  );
};

export default ErrorModal;
