import { ConfigProps } from 'redux-form';
import { Props } from './TableSelection';

export const formName = 'augur-settings-table-selection';

export const fieldTrainingTable = 'trainingTable';
export const fieldPredictionTable = 'predictionTable';

export interface FieldTrainingTableValues {
  tableName: string;
  primaryKeyColumns: string[];
  labelColumn: string;
  positiveLabelValue: string;
  negativeLabelValue: string;
}

export interface FieldPredictionTableValues {
  tableName: string;
}

export interface FormValues {
  [fieldTrainingTable]: FieldTrainingTableValues;
  [fieldPredictionTable]: FieldPredictionTableValues;
}

export const form: ConfigProps<FormValues, Props> = {
  form: formName,
  enableReinitialize: true,
};
