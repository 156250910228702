import React, { Component } from 'react';
import { getComponentForFileName } from '../../../fileTypes/FileTypeManager';
import { TabContent } from 'react-tabs-redux';
import DropOverlay from './DropOverlay.container';
import { Notebook } from '../../../../../store/workbench/state.types';
import { PaneContent } from '../../../../../workbench/types';

export interface Props {
  paneId: string;
  notebook?: Notebook;
  contentElement: PaneContent;
  selectedContent: string;
}

export default class SingleEditorPaneTabContent extends Component<Props> {
  render() {
    const { contentElement, notebook, selectedContent, paneId } = this.props;
    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <DropOverlay paneId={paneId} />
        <TabContent
          className={'notebook-tab-content'}
          for={contentElement.path}
          isVisible={contentElement.path === selectedContent}
          renderActiveTabContentOnly={false}
          key={contentElement.path}
        >
          {getComponentForFileName(
            // The launcher has no name
            contentElement.name || '',
            contentElement.type
            // @ts-ignore TODO these aren't always real Notebooks, but also Files or even the Launcher
          )(notebook, paneId)}
        </TabContent>
      </div>
    );
  }
}
