import P3InputElement, {
  AsInputElement,
  AsVariable,
} from '../_interface/P3InputElement';
import {
  getValueFromSettingsField,
  validateCommonSettings,
} from '../_interface/input-element.form';
import NumericSlider from './NumericSlider';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';
import NumericSliderSettings from './NumericSliderSettings';
import { numberRegex } from '../../variables/handler/helper';
import vars from '../../../../../../../../../../../../scss/base/var.module.scss';

export default class NumericSliderWrapper extends P3InputElement {
  // --- Components
  ChildComponent: React.ComponentType = NumericSlider;
  AdditionalSettings: any = NumericSliderSettings;
  // icon: any = NumericSliderIcon;
  icon: any = DefaultElementIcon;
  iconColor = vars.colorPrimary;

  // --- AttributesComponent
  name = 'Numeric Slider';
  parentContainerClass = 'numeric-slider';
  type = 'numeric-slider';

  // --- Functions
  getSource: (asElement: AsInputElement, variables: AsVariable[]) => string = (
    asElement,
    variables
  ) => {
    if (
      !asElement.settings ||
      !asElement.settings.outputVariable ||
      !asElement.data
    )
      return '';

    let number = asElement.data.number;
    const defaultValueField = asElement.settings.defaultValue;
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      variables
    );
    if (!number && defaultValue) {
      // If the number isn't set: Inject the default value
      number = defaultValue;
    }

    return `${asElement.settings.outputVariable.inputValue} = ${number}`;
  };

  /**
   * Checks:
   * - Is the input really a number?
   * TODO incomplete docs here
   * @param asElement
   */
  validate: (asElement: AsInputElement, variables: object[]) => string = (
    asElement: AsInputElement,
    variables: object[]
  ) =>
    // Since it's a slider with bounds, there can't be invalid user input
    undefined;

  /**
   * Function to validate the settings in the element modal (label, description, outputVariable, ...)
   * For the NumericSlider no additional validation apart from the common settings validation is required
   */
  validateSettings: (settings: object, variables: object[]) => object = (
    settings,
    variables
  ) => {
    // @ts-ignore
    const commonErrors = validateCommonSettings(settings);

    const specificErrors = {};
    const {
      // @ts-ignore
      lowerBound: lowerBoundField,
      // @ts-ignore
      upperBound: upperBoundField,
      // @ts-ignore
      defaultValue: defaultValueField,
      // @ts-ignore
      stepSize: stepSizeField,
    } = settings;

    const lowerBound = getValueFromSettingsField(lowerBoundField, variables);
    const upperBound = getValueFromSettingsField(upperBoundField, variables);
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      variables
    );
    const stepSize = getValueFromSettingsField(stepSizeField, variables);

    // --- Check if boundaries are given
    if (!lowerBound) {
      commonErrors.lowerBound = 'Please enter a Lower Bound';
    }
    if (!upperBound) {
      commonErrors.upperBound = 'Please enter an Upper Bound';
    }

    // --- Check if the boundaries are proper numbers
    let lowerValidNumber = true;
    let upperValidNumber = true;

    if (lowerBound && !lowerBound.toString().match(numberRegex)) {
      commonErrors.lowerBound = 'Please enter a valid number';
      lowerValidNumber = false;
    }
    if (upperBound && !upperBound.toString().match(numberRegex)) {
      commonErrors.upperBound = 'Please enter a valid number';
      upperValidNumber = false;
    }

    const lowerBoundCasted =
      lowerBound && lowerValidNumber ? parseFloat(lowerBound) : undefined;
    const upperBoundCasted =
      upperBound && upperValidNumber ? parseFloat(upperBound) : undefined;

    // --- Check if the lower boundary is < upper boundary
    if (
      lowerBoundCasted &&
      upperBoundCasted &&
      lowerBoundCasted > upperBoundCasted
    ) {
      commonErrors.lowerBound =
        'Lower Bound must be smaller or equal than the Upper Bound';
    }

    // --- Check the default value if given
    if (defaultValue) {
      let defaultValidNumber = true;
      // --- Check if the default value is a proper number
      if (!defaultValue.match(numberRegex)) {
        commonErrors.defaultValue = 'Please enter a valid number';
        defaultValidNumber = false;
      }

      const defaultValueCasted =
        defaultValue && defaultValidNumber
          ? parseFloat(defaultValue)
          : undefined;

      // --- Check if the default value satiesfies the boundaries
      if (lowerBoundCasted && defaultValueCasted < lowerBoundCasted) {
        commonErrors.defaultValue =
          'Default Value must be greater or equal the Lower Bound';
      }

      if (upperBoundCasted && defaultValueCasted > upperBoundCasted) {
        commonErrors.defaultValue =
          'Default Value must be lower or equal the Upper Bound';
      }
    }

    // --- Check the step size
    if (!stepSize) {
      // --- Check whether the step size is given
      commonErrors.stepSize = 'Please enter a step size';
    } else {
      // --- If the step size is given, check whether it's > 0
      if (parseFloat(stepSize) <= 0) {
        commonErrors.stepSize = 'Step size must be > 0';
      }
    }

    return {
      ...commonErrors,
      ...specificErrors,
    };
  };
}
