import { createAction } from 'typesafe-actions';
import {
  ENABLE_MAINTENANCE,
  ENABLE_MAINTENANCE_FAIL,
  ENABLE_MAINTENANCE_SUCCESS,
} from './actions.types';
import { EnableMaintenanceRequest } from '../../../components/admin/maintenance/types';

export const enableMaintenance = createAction(
  ENABLE_MAINTENANCE,
  (data: EnableMaintenanceRequest) => ({ data })
)();

export const enableMaintenanceSuccess = createAction(
  ENABLE_MAINTENANCE_SUCCESS
)();

export const enableMaintenanceFail = createAction(
  ENABLE_MAINTENANCE_FAIL,
  (error: string) => ({ error })
)();
