import { connect, ConnectedProps } from 'react-redux';
import UserSidenotes from './UserSidenotes';
import { RootState } from '../../../../store/store';
import { isAdmin } from '../../../../redux/selectors/user.selector';

export function mapStateToProps(state: RootState) {
  return {
    user: state.account.user,
    isAdmin: isAdmin(state),
  };
}

const connector = connect(mapStateToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(UserSidenotes);
