import { ToBeRefined } from 'common/dist/types/todo_type';

export const formName = 'createBackup';

export const fieldTemplateId = 'templateId';

export const form = {
  form: formName,
  validate: (values: ToBeRefined) => {
    let errors = {};

    // --- Validate id field
    const name = values[fieldTemplateId];
    if (!name) {
      errors = { ...errors, [fieldTemplateId]: 'Please select a template' };
    }

    return errors;
  },
};
