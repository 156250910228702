import React, { FunctionComponent, ReactNode } from 'react';
// @ts-ignore
import cn from 'classnames';

export type Props = {
  isBusy: boolean;
  children?: ReactNode;
  positionAbsolute?: boolean;
};

const Busy: FunctionComponent<Props> = ({
  isBusy,
  children = <div />,
  positionAbsolute = false,
}: Props): React.ReactElement => {
  if (isBusy) {
    return (
      <div className={cn('busy', positionAbsolute && 'busy-absolute')}>
        <div className='sk-fading-circle'>
          <div className='sk-circle1 sk-circle' />
          <div className='sk-circle2 sk-circle' />
          <div className='sk-circle3 sk-circle' />
          <div className='sk-circle4 sk-circle' />
          <div className='sk-circle5 sk-circle' />
          <div className='sk-circle6 sk-circle' />
          <div className='sk-circle7 sk-circle' />
          <div className='sk-circle8 sk-circle' />
          <div className='sk-circle9 sk-circle' />
          <div className='sk-circle10 sk-circle' />
          <div className='sk-circle11 sk-circle' />
          <div className='sk-circle12 sk-circle' />
        </div>
      </div>
    );
  }

  // @ts-ignore
  return children;
};

export default Busy;
