import React, { FC, useEffect, useState } from 'react';
import { AuditEventList } from 'common/dist/types/auditLog';
import AuditEventRow from './EventRow';
import auditLogMsgs from 'common/dist/messages/auditLog';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import classNames from 'classnames';
import _ from 'lodash';
import { fetchUserSummaryById } from '../../../store/users/api';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import { UserSummary } from 'common/dist/types/users';

export type Props = {
  auditLog: AuditEventList;
  /** Flag to prevent fetching the users (only required for storybook) */
  preventUserFetching?: boolean;
};

const AuditLog: FC<Props> = ({ auditLog, preventUserFetching }) => {
  const visibleUserIds = _.uniq(
    (auditLog.events || []).map((event) => event.userId).filter((id) => !!id)
  );

  const [userNames, setUserNames] = useState<{ [userId: string]: string }>({});

  useEffect(() => {
    if (preventUserFetching) return;

    const unknownIds = visibleUserIds.filter(
      (id) => !Object.keys(userNames || {}).includes(id)
    );
    Promise.all(
      unknownIds.map((id) => {
        setUserNames({
          ...userNames,
          [id]: '...',
        });

        return fetchUserSummaryById(id).then(
          ({
            response,
            error,
          }: {
            response?: UserSummary;
            error?: ApiError;
          }) => {
            if (error) {
              setUserNames({
                ...userNames,
                [id]: '', // To prevent fetching the user again
              });
            } else {
              setUserNames({
                ...userNames,
                [id]: `${response.firstName} ${response.lastName}`,
              });
            }
          }
        );
      })
    );
  }, [preventUserFetching, userNames, visibleUserIds]);

  const [highlightedUuid, setHighlightedUuid] = useState(undefined);

  return (
    <div className={classNames('table-reset', styles.table)}>
      <table>
        <thead>
          <tr>
            <td>
              <FormattedMessage {...auditLogMsgs.msgTimestamp} />
            </td>
            <td>
              <FormattedMessage {...auditLogMsgs.msgEventType} />
            </td>
            <td>
              <FormattedMessage {...auditLogMsgs.msgDetails} />
            </td>
          </tr>
        </thead>
        <tbody>
          {auditLog.events.map((event) => {
            const referencedEvent = [
              ...auditLog.events,
              ...auditLog.relatedEvents,
            ].find((ev) => ev.uuid === event.referencesEventUuid);

            const relatedEvents = _.uniq(
              [
                ...[...auditLog.events, ...auditLog.relatedEvents].filter(
                  (ev) =>
                    !!referencedEvent &&
                    ev.referencesEventUuid === referencedEvent.uuid
                ),
                ...[...auditLog.events, ...auditLog.relatedEvents].filter(
                  (ev) => ev.referencesEventUuid === event.uuid
                ),
                referencedEvent,
              ]
                .filter((ev) => !!ev && ev.uuid !== event.uuid)
                .map((ev) => ev.uuid)
            );

            return (
              <AuditEventRow
                onMouseEnter={() => setHighlightedUuid(event.uuid)}
                onMouseLeave={() => setHighlightedUuid(undefined)}
                disabled={
                  highlightedUuid &&
                  event.uuid !== highlightedUuid &&
                  !relatedEvents.includes(highlightedUuid)
                }
                key={event.uuid}
                event={event}
                userName={userNames[event.userId]}
                referencedEvent={referencedEvent}
                relatedEvents={relatedEvents}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AuditLog;
