import React, { Component, Fragment } from 'react';
import { FiGitMerge } from 'react-icons/fi';
import { collaborationSpaceRoutes } from '../../routes';
import { Link } from 'react-router-dom';
import './mergeRequestListItem.scss';
import { MergeRequest } from './MergeRequests.container';
import { formatDate } from 'common/dist/utils/dates';
import { FormattedRelativeTimeConvenient } from '../../../atoms/formatted-date-time/FormattedDateTime';

export type Props = {
  mr: MergeRequest;
  group: string;
  repositoryName: string;
};

export default class MergeRequestListItem extends Component<Props, {}> {
  render() {
    const { mr, group, repositoryName } = this.props;

    return (
      <Fragment>
        <Link
          to={`${collaborationSpaceRoutes.basePath}/${group}/${repositoryName}/${collaborationSpaceRoutes.repositories.mergeRequest}/${mr.id}`}
          style={{ textDecoration: 'none' }}
        >
          <div className={'MergeRequestListItem--element'}>
            <div className={'element-icon-parent'}>
              <FiGitMerge className={'element-icon'} size={'20px'} />
            </div>

            <div className={'element-name-parent'}>
              <div className={'element-name'}>
                <span className={'name'}>{mr.title}</span>
              </div>
              <div className={'element-commit'}>
                <span className={'commit-details'}>
                  Created&nbsp;
                  <span
                    title={
                      mr
                        ? formatDate(
                            new Date(mr.created_at),
                            'dd.MM.yyyy HH:mm:ss'
                          )
                        : ''
                    }
                  >
                    {mr && (
                      <FormattedRelativeTimeConvenient
                        date={new Date(mr.created_at)}
                      />
                    )}
                  </span>
                  &nbsp;by&nbsp;
                  <span>{mr ? mr.user.full_name : ''}</span>
                </span>
              </div>
            </div>
          </div>
        </Link>
      </Fragment>
    );
  }
}
