import { connect, ConnectedProps } from 'react-redux';
import TablePreviewModal, { Props } from './TablePreviewModal';
import {
  selectDataSources,
  selectS3TableSample,
  selectTableSample,
} from '../modelManagement/newAugurWizard/commonComponents/dataSource.selectors';
import { LoadableData } from '../../../utils';
import { CassandraDataPreview } from '../../../store/dataManagement/state.types';

export function mapStateToProps(state, props: Props) {
  const { datapool, tableName } = props;
  const dataSources = selectDataSources(state);
  const datapoolDataSource = dataSources.data.find(
    (ds) => ds.code === datapool?.dataSourceCode
  );
  let tableSample: LoadableData<CassandraDataPreview, unknown>;
  switch (datapoolDataSource?.ds_type) {
    case 'cassandra':
      ({ tableSample } = selectTableSample(
        state,
        datapool.dataSourceCode,
        datapool.code,
        tableName
      ));
      break;
    case 's3':
      ({ tableSample } = selectS3TableSample(
        state,
        datapool.dataSourceCode,
        datapool.code,
        tableName
      ));
  }
  const { loading, loaded, error, data } = tableSample || {};

  return {
    tableName,
    loading,
    loaded,
    error,
    data,
  };
}

const connector = connect(mapStateToProps, null);
export type CProps = ConnectedProps<typeof connector>;
export default connector(TablePreviewModal);
