import { BiographyType } from 'common/dist/types/augurBiography';
import { apiRequest, CompletedApiRequest } from '../../core/api/_tools';
import qs from 'qs';

export function fetchAugurBiography(
  habitatCode: string,
  augurCode: string,
  offset?: number,
  limit?: number,
  jobTypes?: ('prediction' | 'learning' | 'evaluation')[],
  excludeErrors?: boolean
): CompletedApiRequest<BiographyType> {
  const query = qs.stringify(
    {
      offset,
      limit,
      jobTypes: jobTypes?.join(','),
      excludeErrors,
    },
    { addQueryPrefix: true }
  );
  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/biography${query}`
  );
}
