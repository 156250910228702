import { connect } from 'react-redux';
import TabContentDataMan from './TabContentDataMan';
import { isAdmin } from '../../../redux/selectors/user.selector';

export function mapStateToProps(state) {
  return {
    isAdmin: isAdmin(state),
  };
}

export default connect(mapStateToProps)(TabContentDataMan);
