import { connect } from 'react-redux';
import GitNotPushed from './GitNotPushed';
import {
  gitListNotPushedCommits,
  gitPull,
  gitPush,
  hidePushConflictsModal,
} from '../../../../../../redux/workbench/modules/container-interactions.module';

export function mapStateToProps(state) {
  const { loading, loaded, data, error } =
    state.workbench.showRepositoryInfo.gitNotPushedCommits;
  return {
    activeBranch: state.workbench.showRepositoryInfo.activeBranch,
    loading,
    loaded,
    data,
    error,
    pushConflictsModal: state.workbench.pushConflictsModal || {},
  };
}

export const mapDispatchToProps = {
  gitListNotPushedCommits,
  gitPush,
  gitPull,
  hidePushConflictsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GitNotPushed);
