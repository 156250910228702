import React from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import { FiX } from 'react-icons/fi';
import { TabLink } from 'react-tabs-redux';
import { useDrag } from 'react-dnd';
import { DND_TYPES } from '../../editor-parent/dndConstants';

export default function NotebookTabLinkElement({
  selectNotebook,
  hideOverlappingMenu,
  updateOverlappingTabs,
  paneId,
  pane,
  name,
  path,
  type,
  closeNotebook,
  showCloseConfirm,
  measuredTabWidth,
  setNotebookTabDragging,
}) {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: DND_TYPES.FILE_TAB,
      paneId,
      path,
      name,
      contentType: type,
    },
    begin: (monitor) => {
      setNotebookTabDragging(true);
    },
    end: (item, monitor) => {
      setNotebookTabDragging(false);
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div key={path} ref={drag}>
      <Measure
        bounds
        onResize={(contentRect) => {
          measuredTabWidth(path, contentRect.bounds.width);
          updateOverlappingTabs();
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <TabLink
              className={'notebook-tab-link'}
              isActive={path === pane.selectedContent}
              handleSelect={(content) => {
                selectNotebook(content);
                hideOverlappingMenu();
              }}
              to={path}
            >
              <div className={'notebook-link-name'}>{name}</div>
              <div
                className={'notebook-close-icon-container'}
                title={'Close Notebook'}
                onClick={() => {
                  // closeNotebook(_,_, true) will check for changes instead of simply "force" closing the notebook.
                  // If there are changes, the "close confirm" wizard will be shown
                  closeNotebook(path, paneId, true);
                  updateOverlappingTabs();
                }}
              >
                <FiX className={'notebook-close-icon'} size={'14px'} />
              </div>
            </TabLink>
          </div>
        )}
      </Measure>
    </div>
  );
}
NotebookTabLinkElement.propTypes = {
  /** Name of the notebook */
  name: PropTypes.string.isRequired,
  /** Path of the notebook */
  path: PropTypes.string.isRequired,

  measuredTabWidth: PropTypes.func.isRequired,
  updateOverlappingTabs: PropTypes.func.isRequired,
  closeNotebook: PropTypes.func.isRequired,
  selectNotebook: PropTypes.func.isRequired,
  hideOverlappingMenu: PropTypes.func.isRequired,
  paneId: PropTypes.string.isRequired,
  pane: PropTypes.shape({
    selectedContent: PropTypes.string,
  }),
  setNotebookTabDragging: PropTypes.func.isRequired,
};
