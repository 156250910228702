import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MultipleSelectCol extends Component {
  render() {
    const {
      label,
      placeholder,
      options,
      input: { onChange, onBlur, onFocus, value },
      meta: { touched, error },
    } = this.props;
    return (
      <div className={`input-col${error ? ' error' : ''}`}>
        <div className={'form--headline'}>
          <p className={'form--label'}>{label}</p>
          {touched && error && <p className={'form--error'}>{error}</p>}
        </div>
        <div
          className={'form--input-parent multiple-select-element check-box '}
        >
          {options.map((o) => (
            <div className={'checkbox-item-parent'}>
              <label className={'checkbox-item'}>
                <input
                  className={'mark-input'}
                  type={'checkbox'}
                  value={o.value}
                  checked={value && (value.inputValue || []).includes(o.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      // Add the checked element
                      onChange({
                        ...(value || {}),
                        inputValue: [
                          ...((value ? value.inputValue : []) || []),
                          o.value,
                        ],
                      });
                    } else {
                      // Remove the checked element
                      onChange({
                        ...(value || {}),
                        inputValue: (
                          (value ? value.inputValue : []) || []
                        ).filter((x) => x !== o.value),
                      });
                    }
                  }}
                />
                <span className={'mark'} />
              </label>
              <span className={'name'}>{o.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
MultipleSelectCol.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};
