import { ORCHESTRATION_ORIGINS } from '../../components/orchestration/links';

export const initial = {
  loading: false,
  loaded: true,
  error: '',
  data: {},
  origin: null,
};

export const test = {
  data: {},
  loading: false,
  loaded: false,
  error: '',
  origin: ORCHESTRATION_ORIGINS.queue,
};
