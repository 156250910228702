import { connect } from 'react-redux';
import FileStep1 from './FileStep1'; // TODO
import { formName, fieldName } from './file.form';

function safeFileName(state) {
  if (!state.form[formName]) return '';
  if (!state.form[formName].values) return '';
  return state.form[formName].values[fieldName];
}

function safeFileNameTouched(state) {
  if (!state.form[formName]) return false;
  if (!state.form[formName].fields) return false;
  if (!state.form[formName].fields[fieldName]) return false;
  return state.form[formName].fields[fieldName].touched;
}

function safeFileNameError(state) {
  if (!state.form[formName]) return undefined;
  if (!state.form[formName].syncErrors) return undefined;
  if (!state.form[formName].syncErrors[fieldName]) return undefined;
  return state.form[formName].syncErrors[fieldName].message;
}

function filesInCurrentDir(state) {
  const dirs = state.workbench.content.selectedDirPath;
  if (!dirs) return [];

  let content = state.workbench.content;
  dirs.forEach((dir, i) => {
    if (i === 0) {
      // Root
      content = content[dir].content;
    } else {
      if (!content) return [];
      content = content.find((c) => c.name === dir).content; // TODO this is not failsafe (what if .find() returns undefined?)
    }
  });
  return content;
}

export function mapStateToProps(state) {
  return {
    selectedDirPath: state.workbench.content.selectedDirPath,
    fileName: safeFileName(state),
    fileNameTouched: safeFileNameTouched(state),
    fileNameError: safeFileNameError(state),
    filesInCurrentDir: filesInCurrentDir(state),
  };
}

export default connect(mapStateToProps)(FileStep1);
