import { connect } from 'react-redux';
import Apps from './Apps';
import { RootState } from '../../store/store';
import { hasAccessPermission } from 'common/dist/utils/authorization.general';
import { RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX } from 'common/dist/constants/keycloak';

export function mapStateToProps(state: RootState) {
  const permissions = state.dashboard?.permissions?.data || {};
  const missingPermissionsError = !hasAccessPermission(
    permissions,
    RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX
  );
  return {
    missingPermissionsError,
  };
}

export default connect(mapStateToProps)(Apps);
