import PropTypes from 'prop-types';
import React from 'react';
import { scaleLinear } from 'd3-scale';
import * as _ from 'lodash';

import * as misclassification from '../../../../../../../../core/common/misclassification';

class Bar extends React.Component {
  static propTypes = {
    xScale: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    showToolTip: PropTypes.func.isRequired,
    hideToolTip: PropTypes.func.isRequired,
    moveToolTip: PropTypes.func.isRequired,
    animate: PropTypes.bool.isRequired,
    tooltip: PropTypes.bool.isRequired,
    innerPadding: PropTypes.number.isRequired,
  };

  static defaultProps = {
    animate: true,
    tooltip: true,
    showToolTip: () => {},
    hideToolTip: () => {},
    moveToolTip: () => {},
    innerPadding: 0.4,
  };

  constructor(props) {
    super(props);

    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  debouncedShowTip = _.debounce((x, y) => {
    const { moveToolTip, data } = this.props;
    if (this.tooltip) {
      moveToolTip(x, y, data);
    }
  }, 0);

  handleMouseEnter(event) {
    const { showToolTip, data } = this.props;
    this.tooltip = true;
    showToolTip(event.clientX, event.clientY, data);
    this.setState({ animate: true });
  }

  handleMouseMove(event) {
    this.debouncedShowTip(event.clientX, event.clientY);
  }

  handleMouseLeave() {
    const { hideToolTip } = this.props;
    this.tooltip = false;
    hideToolTip();
    this.setState({ animate: false });
  }

  render() {
    const { xScale, animate, data, tooltip, height } = this.props;

    if (misclassification.isEmpty(data)) {
      return null;
    }

    const values = misclassification.getValues(data);
    const x = misclassification.getTimestamp(data);
    const domainMax = misclassification.getWholeCount(data);

    const yScale = scaleLinear().range([height, 0]).domain([0, domainMax]);

    const y = yScale(values[0]);
    const y1 = y - Math.abs(yScale(0) - yScale(values[1]));
    const y2 = y1 - Math.abs(yScale(0) - yScale(values[2]));
    const y3 = y2 - Math.abs(yScale(0) - yScale(values[3]));

    const style = { transition: 'All 0.5s ease' };
    if (this.state && animate) {
      if (this.state.animate) {
        style.transform = `translate(${
          -xScale(x) - xScale.bandwidth() / 2
        }px, 0) scale(2, 1)`;
      } else {
        style.transform = 'scale(1, 1)';
      }
    }

    return (
      <g
        onMouseLeave={tooltip ? this.handleMouseLeave : null}
        onMouseEnter={tooltip ? this.handleMouseEnter : null}
        onMouseMove={tooltip ? this.handleMouseMove : null}
        style={style}
      >
        <rect
          fill={misclassification.buckets[0].color}
          width={xScale.bandwidth()}
          height={Math.abs(yScale(0) - yScale(values[0]))}
          x={xScale(x)}
          y={y}
        />
        <rect
          fill={misclassification.buckets[1].color}
          width={xScale.bandwidth()}
          height={Math.abs(yScale(0) - yScale(values[1]))}
          x={xScale(x)}
          y={y1}
        />
        <rect
          fill={misclassification.buckets[2].color}
          width={xScale.bandwidth()}
          height={Math.abs(yScale(0) - yScale(values[2]))}
          x={xScale(x)}
          y={y2}
        />
        <rect
          fill={misclassification.buckets[3].color}
          width={xScale.bandwidth()}
          height={Math.abs(yScale(0) - yScale(values[3]))}
          x={xScale(x)}
          y={y3}
        />
      </g>
    );
  }
}

export default Bar;
