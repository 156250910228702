import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'react-tabs-redux';
import BrowserTabLinks from './BrowserTabLinks.container';
import BrowserTabContents from './BrowserTabContents.container';
import BrowserCollapseFlap from '../../atoms/BrowserCollapseFlap/BrowserCollapseFlap';

export default class Browser extends Component {
  render() {
    const { toggleBrowser } = this.props;
    return (
      <div className={'browser'}>
        <BrowserCollapseFlap isExpanded toggleBrowser={toggleBrowser} />

        <Tabs
          name={'browser-tabs'}
          renderActiveTabContentOnly
          id={'wb-browser-tabs'}
        >
          <BrowserTabLinks />
          <BrowserTabContents />
        </Tabs>
      </div>
    );
  }
}

Browser.propTypes = {
  toggleBrowser: PropTypes.func.isRequired,
};
