import { createAction } from 'redux-act';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as NotebookApi from '../../../core/api/workbench/notebook';
import { notebookUser } from '../selectors/notebookUser.selector';
import { loadRepoMeta } from './notebook.module';
import { gitFileStatus } from './container-interactions.module';

export const showConfigureCodeCapsuleModal = createAction(
  'show configure code capsule modal',
  (path) => ({ path })
);

export const hideConfigureCodeCapsuleModal = createAction(
  'hide configure code capsule modal'
);

export const updateCodeCapsuleConfiguration = createAction(
  'update code capsule configuration',
  (path, configuration) => ({ path, configuration })
);

export const updateCodeCapsuleConfigurationSuccess = createAction(
  'update code capsule configuration - success'
);

export const updateCodeCapsuleConfigurationFailure = createAction(
  'update code capsule configuration - failure'
);

export const reducer = {
  [showConfigureCodeCapsuleModal](state, { path }) {
    const notebook = state.notebooks[path];
    return {
      ...state,
      codeCapsule: {
        ...state.codeCapsule,
        isConfigureModalShown: true,
        path,
        notebook,
      },
    };
  },
  [hideConfigureCodeCapsuleModal]: (state) => ({
    ...state,
    codeCapsule: {
      ...state.codeCapsule,
      isConfigureModalShown: false,
    },
  }),
};

export function* updateCodeCapsuleConfigurationSaga({
  payload: { path, configuration },
}) {
  const jupyterUser = yield select((state) => notebookUser(state));

  // TODO? add new api call to save a file as json? (this only saves the file as text, which is also how it was fetched)
  const { response, error } = yield call(
    NotebookApi.saveFile,
    path,
    JSON.stringify(configuration),
    jupyterUser
  );

  if (response) {
    yield put(updateCodeCapsuleConfigurationSuccess());
    yield put(loadRepoMeta(path));
    // Remove the /repository.asr part from the path to use to refresh the local git changes
    const repositoryPath = path.split('/')[0];
    yield put(gitFileStatus(repositoryPath));
  } else {
    yield put(updateCodeCapsuleConfigurationFailure());
  }
}

export function* watchUpdateCodeCapsuleConfiguration() {
  yield takeEvery(
    updateCodeCapsuleConfiguration.getType(),
    updateCodeCapsuleConfigurationSaga
  );
}
