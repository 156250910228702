import P3InputElement, {
  AsInputElement,
  AsVariable,
} from '../_interface/P3InputElement';
import {
  getValueFromSettingsField,
  validateCommonSettings,
} from '../_interface/input-element.form';
import CheckBox from './CheckBox';
import CheckBoxSettings from './CheckBoxSettings';
import DefaultElementIcon from '../../../settings-dialog/DefaultElementIcon';
import vars from '../../../../../../../../../../../../scss/base/var.module.scss';

export default class CheckBoxWrapper extends P3InputElement {
  // --- Elements

  ChildComponent: React.ComponentType = CheckBox;
  AdditionalSettings: any = CheckBoxSettings;
  name = 'Checkbox';
  parentContainerClass = 'check-box';
  type = 'check-box';
  icon: any = DefaultElementIcon;
  iconColor = vars.colorPrimary;

  // --- Functions

  getSource: (asElement: AsInputElement, variables: AsVariable[]) => string = (
    asElement,
    variables
  ) => {
    if (
      !asElement.settings ||
      !asElement.settings.outputVariable ||
      !asElement.data
    )
      return '';

    let checked = asElement.data.checked;
    const defaultValueField = asElement.settings.defaultValue;
    const defaultValue = getValueFromSettingsField(
      defaultValueField,
      variables
    );
    if (
      !['true', 'false'].includes(`${checked}`) &&
      ['true', 'false'].includes(`${defaultValue}`)
    ) {
      // If the value isn't set: Inject the default value
      checked = defaultValue;
    }

    return `${asElement.settings.outputVariable.inputValue} = ${
      checked ? 'True' : 'False'
    }`;
  };

  /**
   * No validation implemented - every input is valid.
   * @param asElement
   */
  validate: (asElement: AsInputElement, variables: object[]) => string = (
    asElement: AsInputElement,
    variables: object[]
  ) => undefined;

  /**
   * Function to validate the settings in the element modal (label, description, outputVariable, ...)
   * For the NumberInput no additional validation apart from the common settings validation is required
   */
  validateSettings: (values: object, variables: object[]) => object =
    validateCommonSettings;
}
