import NotebookCellWrapper from '../../../NotebookCellWrapper';
import Python3InputCell from './Python3InputCell.container';

export default class Python3InputCellWrapper extends NotebookCellWrapper {
  ChildComponent = Python3InputCell;
  type = 'python3-input';
  name = 'App Input';
  parentContainerClass = 'python3-input-cell input-cell app-cell';
  executable = true;
}
