import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import ScoreDistribution from './ScoreDistribution';
import {
  hideRunMassPredictionModal,
  showRunMassPredictionModal,
} from '../../../../../../store/runMassPredictionModal/slice';
import { fetchDatapoolTables } from '../../../../../../store/datapoolTables/slice';
import { runJobGroup } from '../../../../../../store/jobGroups/slice';

export function mapStateToProps(state: RootState) {
  return {
    isMassPredictionModalShown: state.runMassPredictionModal?.showModal,
  };
}

export const mapDispatchToProps = {
  showRunMassPredictionModal,
  hideRunMassPredictionModal,
  fetchDatapoolTables,
  runJobGroup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(ScoreDistribution);
