import React, { Component } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import Dropzone from '../../../../../atoms/dropzone/Dropzone';
import Progress from '../../../../../atoms/progress/Progress';
import { styledFileSize } from '../../../../util';
import { WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';
import styles from '../../../../styles.module.scss';

export interface Props {
  uploading?: boolean;
  uploaded?: boolean;
  progress?: number;
  error?: Record<string, unknown>;
  dataSourceCode: string;
  resetUploadFile(...args: unknown[]): unknown;
  analysis?: {
    analyzing?: boolean;
    analyzed?: boolean;
    error?: string;
    message?: string;
  };
}

export default class StepUpload extends Component<Props & WrappedFieldProps> {
  componentDidMount() {
    const { resetUploadFile, dataSourceCode } = this.props;
    resetUploadFile(dataSourceCode);
  }

  renderProgressInfo(progress) {
    if (!progress) return <div />; // Should not happen
    else if (progress.status === 'error') {
      return <FiX className={'error-icon'} size={20} />;
    } else if (progress.status === 'done') {
      return <FiCheck className={'check-icon'} size={20} />;
    }
    return (
      <span className={'progress-percentage'}>
        {progress ? progress.toFixed(0) : '?'} %
      </span>
    );
  }

  render() {
    const {
      input: { value, onChange, onBlur },
      uploading,
      uploaded,
      progress,
      analysis,
    } = this.props;
    return (
      <div
        className={classNames(
          'GenericFormStep--field',
          styles.uploadInputParent
        )}
      >
        <Dropzone
          onFilesAdded={(fileArray) =>
            onChange({
              ...value,
              file: fileArray.length > 0 ? fileArray[0] : undefined,
            })
          }
          uploading={uploading}
          acceptedFileTypes={'.csv'}
          onBlur={() => onBlur(value)}
          multiple={false}
        />
        <div className={styles.uploadRightPart}>
          {value && value.file && (
            <div className={styles.files}>
              <div key={value.file.name} className={styles.fileParent}>
                <div className={styles.uploadText}>
                  <span title={value.file.name} className={styles.filename}>
                    {value.file.name}
                  </span>
                  <span className={styles.filesize}>
                    {styledFileSize(value.file.size)}
                  </span>
                </div>
              </div>
            </div>
          )}

          {(uploading || uploaded) && (
            <div className={styles.progressWrapper}>
              <Progress progress={progress} done={uploaded} />
              <div className={styles.progressInfo}>
                {this.renderProgressInfo(progress)}
              </div>
            </div>
          )}
          {this.props.error && (
            <div className='error-container'>
              <span>{this.props.error + ''}</span>
            </div>
          )}
          {analysis && analysis.message && (
            <div>
              <span>
                <i>{analysis.message}</i>
              </span>
            </div>
          )}
          {analysis && analysis.error && (
            <div className='error-container'>
              <span>{analysis.error}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
