import { getMaxY } from '../../../../../../../../../core/common/accuracyDetail';

import * as axisLeft from './axisLeft';
import * as axisBottom from './axisBottom';
import * as charts from './charts';
import * as circles from './circles';
import { DISTANCE_BETWEEN_LAYERS } from './config';
const THREE = require('three');

if (process.env.NODE_ENV !== 'test') {
  require('imports-loader?THREE=three!./OrbitControls.js'); // eslint-disable-line
  require('imports-loader?THREE=three!three/examples/js/renderers/CSS3DRenderer.js'); // eslint-disable-line
}

let stop = () => {};

export function setupControls(camera, node) {
  const controls = new THREE.OrbitControls(camera, node);
  controls.rotateSpeed = 0.3;
  controls.zoomSpeed = 1;
  controls.enablePan = false;

  controls.minDistance = 900;
  controls.maxDistance = 1100;

  controls.minPolarAngle = (Math.PI * 10) / 32;
  controls.maxPolarAngle = (Math.PI * 19) / 32;

  controls.minAzimuthAngle = (-Math.PI * 3) / 4;
  controls.maxAzimuthAngle = (Math.PI * 3) / 4;

  return controls;
}

export function setupScene({ width, height, data, showTooltip, hideTooltip }) {
  const maxY = getMaxY(data);
  const scene = new THREE.Scene();
  charts.render(scene, width, height, data, maxY);
  axisLeft.render(scene, width, height, data, maxY);
  axisBottom.render(scene, width, height, data);
  circles.render(scene, width, height, data, showTooltip, hideTooltip, maxY);

  return scene;
}

let camera;
let controls;

export function start(node, props) {
  const { width, height, data } = props;

  stop();

  if (!camera) {
    camera = new THREE.PerspectiveCamera(40, width / height, 1, 10000);
    camera.position.set(
      0,
      0,
      -(850 + DISTANCE_BETWEEN_LAYERS * (data.length - 3))
    );
    controls = setupControls(camera, node);
  }

  const scene = setupScene(props);

  const renderer = new THREE.CSS3DRenderer();
  renderer.setSize(width, height);
  node.appendChild(renderer.domElement);

  const render = () => {
    renderer.render(scene, camera);
  };

  controls.addEventListener('change', render);

  render();

  stop = () => {
    while (node.firstChild) {
      node.removeChild(node.firstChild);
    }
    controls.removeEventListener('change', render);
    camera = null;
    controls = null;
  };
}
