import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  postUploadRequest,
  putApiRequest,
} from './_tools';
import { PutChangePasswordResponseBody } from 'common/dist/types/responseBodies/currentUser';
import { Avatar } from 'common/dist/types/users';

export function changePassword(
  newPassword
): CompletedApiRequest<PutChangePasswordResponseBody> {
  return putApiRequest('/api/user/password', { newPassword });
}

export function getPasswordPolicies() {
  return apiRequest('/api/password-policies');
}

export function getUser() {
  return apiRequest('/api/user');
}

export function changeLanguage(language) {
  return putApiRequest('/api/user/locale', { locale: language });
}

export function fetchPhoto(): CompletedApiRequest<Avatar> {
  return apiRequest('/api/user/avatar');
}

export function uploadPhoto(photo) {
  return postUploadRequest('/api/user/avatar', photo);
}

export function deletePhoto() {
  return deleteApiRequest('/api/user/avatar');
}

export function getLoginHistory() {
  return apiRequest('/api/user/login-history');
}

export function updateInfo(info) {
  return putApiRequest('/api/user', info);
}
