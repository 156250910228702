import { NamesState } from '../../store/names/state.types';

export const initial: NamesState = {
  augurNames: {},
  datapoolNames: {},
  repositoryNames: {},
  codeCapsuleNames: {},
  habitatNames: {},
};

export const test: NamesState = {
  augurNames: {},
  datapoolNames: {},
  repositoryNames: {},
  codeCapsuleNames: {},
  habitatNames: {},
};
