import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { dataManagementRoutes } from '../../routes';
import { UPLOAD_WIZARDS } from '../upload/uploadWizards';
import PermissionTable from '../../../organisms/permission-table/PermissionTable';
import { RouteComponentProps } from 'react-router';
import { S3Permissions } from '../../../../store/dataManagement/state.types';
import {
  S3_BUCKET_SCOPE_IDS,
  S3_BUCKET_SCOPES,
} from 'common/dist/constants/keycloak';
import { CProps } from './Buckets.container';
import {
  datapoolForBucket,
  isDatapoolBucket,
} from 'common/dist/constants/dataManagement/s3';
import Button from '../../../atoms/button/Button';

export type Props = {
  /** Fetch list of all Buckets */
  fetchBuckets: (dataSourceCode: string) => void;
  fetchS3Permissions: (dataSourceCode: string) => void;
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  data: {
    name: string;
    owner: string;
    createdAt: string;
  }[];
  dataSourceCode: string;
  /** In transfer mode (true) or in regular mode (false)? */
  isTransferMode: boolean;
  /** Browser side if transfer mode is active */
  transferLeftOrRight: 'left' | 'right';
  /** Set the active upload wizard */
  setActiveUploadWizard: (activeWizard: string, uploadCode: string) => void;
  permissions: S3Permissions;
};

class Buckets extends Component<Props & CProps & RouteComponentProps> {
  componentDidMount() {
    const { fetchBuckets, fetchS3Permissions, dataSourceCode } = this.props;
    fetchBuckets(dataSourceCode);
    fetchS3Permissions(dataSourceCode);
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className={'buckets'}>
        <span className={'error'}>{JSON.stringify(error)}</span>
      </div>
    );
  }

  renderLoaded() {
    const {
      data,
      dataSourceCode,
      setActiveUploadWizard,
      permissions,
      datapoolNames,
    } = this.props;
    const allowedToUpload = new Set(
      Object.values(permissions.data || {}).flat()
    ).has(S3_BUCKET_SCOPE_IDS.WRITE);
    return (
      <div className={'buckets'}>
        <div className={'buckets-headline-container'}>
          <span className={'buckets-headline'} />
          {allowedToUpload && (
            <Button
              buttonColor={'secondary'}
              withLink
              buttonLabelDefault={'Upload Data'}
              linkTo={`${dataManagementRoutes.basePath}/s3/${dataSourceCode}/upload`}
              onClick={() =>
                setActiveUploadWizard(UPLOAD_WIZARDS.UPLOAD, undefined)
              }
            />
          )}
        </div>

        {data && (
          <PermissionTable
            title={'S3 Bucket'}
            permissionColumns={S3_BUCKET_SCOPES.map((s) => s.displayName)}
            rowProps={data
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((bucket) => {
                const permissionSet = new Set(permissions.data?.[bucket.name]);
                const allowedToRead = permissionSet.has(
                  S3_BUCKET_SCOPE_IDS.READ
                );
                const allowedToWrite = permissionSet.has(
                  S3_BUCKET_SCOPE_IDS.WRITE
                );
                return {
                  title: bucket.name,
                  displayTitle:
                    isDatapoolBucket(bucket.name) &&
                    `Datapool ${datapoolForBucket(bucket.name, datapoolNames)}`,
                  subInfo: `Created at: ${bucket.createdAt}`,
                  withLink: allowedToRead || allowedToWrite,
                  linkTo: `${dataManagementRoutes.basePath}/s3/${dataSourceCode}/bucket/${bucket.name}/content/`,
                  permissionList: S3_BUCKET_SCOPES.map((s) =>
                    permissionSet.has(s.id)
                  ),
                };
              })}
          />
        )}
      </div>
    );
  }

  renderEmpty() {
    return (
      <div className={'buckets buckets-empty-parent'}>
        <div className={'buckets-empty'}>
          <div className={'buckets-empty-elements'}>
            <div className={'buckets-empty-pic'}>&nbsp;</div>
            <span className={'headline'}>No Buckets yet</span>
            <span className={'description'}>
              Please ask an Administrator for permission to existing Buckets
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { data, error } = this.props;
    if (error) return this.renderError();
    if (data && data.length === 0) return this.renderEmpty();
    return this.renderLoaded();
  }
}

export default withRouter(Buckets);
