import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FiCheck } from 'react-icons/fi';
import { fieldPath, validatePath } from './cloneRepository.form';
// import Content from "../../../workbench/part-left/content/Content.container";
import { err, ok } from '../../../../utils';
import { prepareContent } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser.utils';
import CloneRepositoryFileBrowser from '../../../organisms/workbench-browser/clone-repository-file-browser/CloneRepositoryFileBrowser';

export default class StepPath extends Component {
  render() {
    // The value of the Field will actually be set in the componentDidUpdate method() of the PathSelectFieldComponent
    return (
      <Field
        name={fieldPath}
        label={fieldPath}
        component={PathSelectFieldComponent}
        validate={[validatePath]}
        {...this.props}
      />
    );
  }
}

StepPath.propTypes = {
  /** */
  selectedDirPath: PropTypes.array.isRequired,
  /** List of notebooks in the currently active "selectedDirPath" */
  notebooksInCurrentDir: PropTypes.array.isRequired,
  repoName: PropTypes.string, // Display name of the repository
};

// --- Form Component

export class PathSelectFieldComponent extends Component {
  componentDidMount() {
    const {
      selectedDirPath,
      input: { onChange },
    } = this.props;
    onChange(selectedDirPath); // Manually update the selectedDirPath of the Field
    this.props.fetchContent(this.props.selectedDirPath);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      selectedDirPath,
      input: { onChange },
    } = this.props;
    if (prevProps.selectedDirPath !== selectedDirPath) {
      onChange(selectedDirPath); // Manually update the selectedDirPath of the Field
      this.props.fetchContent(this.props.selectedDirPath);
    }
  }

  errorMessage() {
    const {
      meta: { error },
    } = this.props;
    return error && <p className={'error'}>{error}</p>;
  }

  render() {
    const { content, width, selectedDirPath, error, meta } = this.props;
    const selectedContent = error
      ? err(error)
      : ok(prepareContent(content, selectedDirPath));

    return (
      <div className={'step-container step-1-container'}>
        <div className={'description-container'}>
          {!meta.valid ? (
            <div className={'bubble bubble-info'}>
              <p>1</p>
            </div>
          ) : (
            <div className={'bubble bubble-ok'}>
              <FiCheck className={'checked-icon'} size={'20px'} />
            </div>
          )}
          <div className={'description'}>
            <div className={'title'}>
              <p>Select Path</p>
            </div>
            <div className={'text'}>
              <p>Select a path to clone the repository into.</p>
            </div>
          </div>
        </div>
        <div
          className={`input-container${
            meta.error ? ' input-container-error' : ''
          }`}
          style={{
            minHeight: '300px',
            maxHeight: '600px',
          }}
        >
          <div className={'error-container'}>{this.errorMessage()}</div>

          <CloneRepositoryFileBrowser
            content={selectedContent}
            width={width}
            path={selectedDirPath.join('/')}
          />
        </div>
      </div>
    );
  }
}
PathSelectFieldComponent.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
  /** */
  selectedDirPath: PropTypes.array.isRequired,
  repoName: PropTypes.string, // Display name of the repository
};
