import React, { FC, useMemo } from 'react';
import Button from '../../../../../../../../atoms/button/Button';
import { Field, InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { fieldPipelineTuning } from './pipelineTuning.form';
import styles from '../styles.module.scss';
import formMsgs from 'common/dist/messages/form';
import InputError from '../../../../../../../../atoms/input-error/InputError';
import PipelineTuningSelection, {
  Props as PipelineTuningSelectionProps,
} from '../../../../../../../../organisms/pipeline-tuning/pipeline-tuning-selection/PipelineTuningSelection';
import messagesAugur from 'common/dist/messages/augurs';
import { PipelineTuningValue } from 'common/dist/types/pipeline';
import {
  deriveInitialValues,
  parseAllPipelineParameterValues,
  validatePipelineTuningField,
} from '../../../../../../../../organisms/pipeline-tuning/pipeline-tuning-selection/formHelpers';
import { MessageDescriptor } from 'react-intl';
import {
  PipelineErrorType,
  PipelineTuningSchemaType,
} from '../../../../../../../../organisms/pipeline-tuning/_pipeline-tuning-results-common/types';
import { ModelSettingsProps } from '../../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../../../../types';
import { useAugur } from '../../../../../../../../../core/api/augurs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../../store/store';
import { ArchetypeVersionWithArchetypeType } from 'common/dist/types/archetypeVersion';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

export interface Props {
  /** Settings value with the parameters for this augur's pipeline tuning */
  pipelineTuningValue: PipelineTuningValue[];
}

export function extractPipelineTuningSchemasFromArchetypeVersions(
  archetypeVersions: ArchetypeVersionWithArchetypeType[],
  archetypeCode: string,
  archetypeVersionNumber: string
): PipelineTuningSchemaType[] | undefined {
  // Find the settings for the correct archetype and version in the data (if settings are missing in db they are null)
  const archetypeVersionSettings: {
    pipelineTuning: PipelineTuningSchemaType[];
  } | null = archetypeVersions[archetypeCode]?.versions.find(
    (version) => version.number === archetypeVersionNumber
  )?.settings;
  return archetypeVersionSettings?.pipelineTuning;
}

const PipelineTuning: FC<
  Props & InjectedFormProps & ModelSettingsProps<AugurSettingsDataNbc>
> = ({ pristine, reset, change, valid, saveSettings, pipelineTuningValue }) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const { data: augur } = useAugur(habitatCode, augurCode);

  const archetypeCode = augur?.archetypeVersion?.archetype?.code ?? '';
  const archetypeVersionNumber = augur?.archetypeVersion?.number ?? '';
  const archetypeIsCustom = augur?.archetypeVersion?.archetype?.custom ?? false;
  const archetypeVersions = useSelector<
    RootState,
    ArchetypeVersionWithArchetypeType[]
  >((state) => state.archetypeVersions);

  const pipelineTuningSchemas =
    extractPipelineTuningSchemasFromArchetypeVersions(
      archetypeVersions,
      archetypeCode,
      archetypeIsCustom ? archetypeVersionNumber : 'latest'
    );

  const defaultValues = deriveInitialValues(pipelineTuningSchemas);

  const handleSubmit = () => {
    if (!valid) {
      return;
    }
    const convertedPipelineTuningValue =
      parseAllPipelineParameterValues(pipelineTuningValue);
    const settings = {
      pipelineTuning: convertedPipelineTuningValue,
    };
    saveSettings(settings, true);
  };

  const validate = useMemo(
    (value?: PipelineTuningValue[]) =>
      validatePipelineTuningField(value, pipelineTuningSchemas),
    [pipelineTuningSchemas]
  );

  return (
    <div>
      <div>
        <Field
          id={fieldPipelineTuning}
          name={fieldPipelineTuning}
          component={PipelineTuningSelectionWithError}
          validate={validate}
          // ComponentProps
          pipelineTuningSchemas={pipelineTuningSchemas}
          defaultValues={defaultValues}
        />
      </div>

      <div className={styles.PipelineTuningButtons}>
        <Button
          withLink={false}
          onClick={() => change(fieldPipelineTuning, defaultValues)}
          buttonColor={'white'}
          buttonLabelId={formMsgs.restore.id}
          buttonLabelDefault={formMsgs.restore.defaultMessage}
          buttonId={'btn-table-selection-restore'}
        />
        <div className={styles.PipelineTuningButtonsRight}>
          <Button
            disabled={pristine}
            withLink={false}
            onClick={reset}
            buttonColor={'white'}
            buttonLabelId={formMsgs.cancel.id}
            buttonLabelDefault={formMsgs.cancel.defaultMessage}
            buttonId={'btn-table-selection-cancel'}
          />
          <Button
            disabled={pristine || !valid}
            withLink={false}
            onClick={handleSubmit}
            buttonColor={'secondary'}
            buttonLabelId={formMsgs.submit.id}
            buttonLabelDefault={formMsgs.submit.defaultMessage}
            buttonId={'btn-table-selection-submit'}
          />
        </div>
      </div>
    </div>
  );
};

export default PipelineTuning;

const PipelineTuningSelectionWithError: FC<
  PipelineTuningSelectionProps & WrappedFieldProps
> = ({ pipelineTuningSchemas, defaultValues, input, meta }) => {
  const error: PipelineErrorType | undefined = meta.error;
  let shownError: MessageDescriptor | undefined;
  if (error?.general) {
    shownError = error.general;
  } else if (error) {
    shownError = messagesAugur.tuningPipelineValidationErrorUnspecified;
  }

  return (
    <div>
      <InputError error={shownError} touched={meta.touched} />
      <PipelineTuningSelection
        input={input}
        meta={meta}
        pipelineTuningSchemas={pipelineTuningSchemas}
        defaultValues={defaultValues}
      />
    </div>
  );
};
