import React, { Component } from 'react';
import styles from '../styles.module.scss';
import NameContainer from './NameContainer';
import ActionButtons from './ActionButtons';
import DetailValues from './DetailValues';
import { CProps } from './OverlayDatapoolDetails.container';

type Props = {
  triggerDatapoolStatisticsJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string
  ) => void;
  setActiveUploadWizard: (
    activeWizard: string,
    uploadCode: string | undefined
  ) => void; // UPLOAD_WIZARDS.UPLOAD, undefined
};

export default class OverlayDatapoolDetails extends Component<Props & CProps> {
  render() {
    const {
      loading,
      loaded,
      datapool,
      habitatName,
      triggerDatapoolStatisticsJob,
      setActiveUploadWizard,
      dataSource,
    } = this.props;

    return (
      <div className={styles.subheaderLeft}>
        <div className={styles.detailsBar}>
          <NameContainer
            loading={loading}
            loaded={loaded}
            datapool={datapool}
            habitatName={habitatName}
          />

          <DetailValues
            loading={loading}
            loaded={loaded}
            datapool={datapool}
            dataSource={dataSource}
          />

          <ActionButtons
            loading={loading}
            loaded={loaded}
            datapool={datapool}
            dataSource={dataSource}
            triggerDatapoolStatisticsJob={triggerDatapoolStatisticsJob}
            setActiveUploadWizard={setActiveUploadWizard}
          />
        </div>
      </div>
    );
  }
}
