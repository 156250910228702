import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from '../../core/api';

export const fetchPredictionTargets = createAction(
  'fetch predication targets',
  (habitatCode, datapoolCode) => ({ habitatCode, datapoolCode })
);

export const fetchPredictionTargetsSuccess = createAction(
  'fetch predication targets - success',
  (data) => data
);

export const fetchPredictionTargetsFail = createAction(
  'fetch predication targets - fail',
  (error) => error
);

export const reducer = {
  [fetchPredictionTargets]: (state) => ({
    ...state,
    predictionTargets: {
      ...state.predictionTargets,
      loading: true,
    },
  }),
  [fetchPredictionTargetsSuccess]: (state, targets) => ({
    ...state,
    predictionTargets: {
      ...state.predictionTargets,
      loading: false,
      loaded: true,
      data: targets,
    },
  }),
  [fetchPredictionTargetsFail]: (state, error) => ({
    ...state,
    predictionTargets: {
      ...state.predictionTargets,
      loading: false,
      loaded: false,
      error,
    },
  }),
};

export function* fetchPredictionTargetsSaga({
  payload: { habitatCode, datapoolCode },
}) {
  const { response, error } = yield call(
    Api.datapools.fetchPredictionTargets,
    habitatCode,
    datapoolCode
  );
  if (response) {
    yield put(fetchPredictionTargetsSuccess(response));
  } else {
    yield put(fetchPredictionTargetsFail(error));
  }
}

export function* watchFetchPredictionTargets() {
  yield takeEvery(fetchPredictionTargets.getType(), fetchPredictionTargetsSaga);
}
