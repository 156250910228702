import { getCodeCapsulesDetailsLink } from '../../../index/routes';
import React from 'react';
import Button from '../../../atoms/button/Button';
import { collaborationSpaceRoutes } from '../../../collaborationSpace/routes';
import { RootState } from '../../../../store/store';
import { CodeCapsule } from 'common/dist/types/codeCapsule';
import { CardOrButtonType } from '../../../organisms/element-card-grid/ElementCardGrid';
import scssColors from '../../../../../scss/base/var.module.scss';

/**
 * Selector for the codeCapsules
 * @param state
 * @param habitatCode
 * @returns {{}|*}
 */
export function codeCapsulesSafe(state: RootState, habitatCode: string) {
  if (!habitatCode) return {};
  return state.codeCapsules.perHabitatCode[habitatCode];
}

export function deriveElements(
  codeCapsules: { data: CodeCapsule[] },
  habitatCode,
  openRunCodeCapsuleModal
): CardOrButtonType[] {
  const elements: CardOrButtonType[] = codeCapsules.data.map((codeCapsule) => {
    const codeCapsuleDetailsLink = getCodeCapsulesDetailsLink({
      codeCapsuleCode: codeCapsule.code,
      habitatCode,
      tabId: undefined,
    });
    const amountVersions = codeCapsule.versions
      ? codeCapsule.versions.length
      : 0;
    const amountVersionsText = `${amountVersions} ${
      amountVersions === 1 ? 'Version' : 'Versions'
    }`;

    return {
      type: 'card',
      linkTo: codeCapsuleDetailsLink,
      header: codeCapsule.name,
      subHeader: amountVersionsText,
      info: `Code: ${codeCapsule.code}`,
      barColor: scssColors.colorBlue,
      icons: [
        {
          Icon: () => (
            <div
              className={'icon-play'}
              style={{
                color: scssColors.colorTextLight,
                fontSize: '15px',
              }}
              onClick={(e) => {
                openRunCodeCapsuleModal({
                  versions: codeCapsule.versions,
                  name: codeCapsule.name,
                  repoCode: codeCapsule.repoCode,
                  codeCapsuleCode: codeCapsule.code,
                  habitatCode: codeCapsule.habitatCode,
                });
                e.preventDefault();
              }}
            />
          ),
          tooltip: {
            id: 'no-id',
            defaultMessage: 'Run Code Capsule',
          },
        },
      ],
    };
  });

  // 'Add Code Capsule' button
  elements.push({
    type: 'button',
    Button: () => (
      <Button
        buttonLabelDefault={'New Code Capsule'}
        buttonColor={'secondary'}
        withLink={false}
        Icon={() => (
          <span
            style={{
              fontSize: '18px',
              margin: '-3px 0px -2px -10px',
              opacity: 0.5,
            }}
            className={'icon-plus'}
          />
        )}
      />
    ),
    linkTo: `${collaborationSpaceRoutes.basePath}/${collaborationSpaceRoutes.repositories.create}?repositoryType=code-capsule&habitatCode=${habitatCode}`,
  });

  return elements;
}
