export const initial = {
  summary: {
    error: '',
    loading: false,
    loaded: false,
    data: [],
  },
  augurs: {},
  requests: {},
};

export const test = {
  summary: {
    error: '',
    loading: false,
    loaded: false,
    data: [],
  },
  augurs: {},
  requests: {},
};
