import { connect } from 'react-redux';
import UploadConfirmation from './UploadConfirmation';

export function mapStateToProps(state) {
  const { writing, written, amount, error, data } =
    (state.data.upload || {}).write || {};
  return {
    writing,
    written,
    amount,
    error,
    data,
  };
}

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UploadConfirmation);
