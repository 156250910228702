import React, { FC } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import styles from './styles.module.scss';
import classNames from 'classnames';

export type Props = {
  isExpanded: boolean;
  toggleBrowser: (isExpanded: boolean) => void;
};

const BrowserCollapseFlap: FC<Props> = ({ isExpanded, toggleBrowser }) => {
  return (
    <div
      className={classNames(
        styles.browserCollapseFlap,
        { [styles.expanded]: isExpanded },
        { [styles.collapsed]: !isExpanded }
      )}
      onClick={() => toggleBrowser(!isExpanded)}
    >
      <FiChevronLeft size={16} className={styles.collapseIcon} />
    </div>
  );
};

export default BrowserCollapseFlap;
