import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiError } from 'common/dist/types/responseBodies/errors';
import { fetchAuditLog as fetchAuditLogApi } from './api';
import { AuditEventList } from 'common/dist/types/auditLog';

export type AuditLogState = {
  loaded?: boolean;
  loading?: boolean;
  data?: AuditEventList;
  error?: ApiError;
};

export const initial: AuditLogState = {
  loaded: false,
  loading: false,
  data: { events: [], relatedEvents: [] },
};
export const test: AuditLogState = {};

export const fetchAuditLog = createAsyncThunk<
  { data: AuditEventList },
  { offset?: number; limit?: number; event?: string },
  { rejectValue: { error: ApiError } }
>('fetchAuditLog', async ({ offset, limit, event }, thunkAPI) => {
  const { response, error } = await fetchAuditLogApi(offset, limit, event);

  if (response) {
    return { data: response };
  } else {
    return thunkAPI.rejectWithValue({ error });
  }
});

const slice = createSlice({
  name: 'auditLog',
  initialState: initial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAuditLog.pending, (state, { payload, meta }) => {
      state.loaded = false;
      state.loading = true;
    });
    builder.addCase(fetchAuditLog.fulfilled, (state, { payload, meta }) => {
      const { data } = payload;
      state.data = data;
      state.loading = false;
      state.loaded = true;
      state.error = undefined;
    });
    builder.addCase(fetchAuditLog.rejected, (state, { payload, meta }) => {
      const { error } = payload;
      state.error = error;
      state.loading = false;
      state.loaded = false;
    });
  },
});

const reducer = slice.reducer;
export { reducer as auditLogReducer };
