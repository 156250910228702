import * as _ from 'lodash';

export function getTime(item) {
  return Date.parse(item.time);
}

export function getDrift(item) {
  return item.data?.drift;
}

export function getThreshold(item) {
  return item.data?.threshold;
}

export function isEvaluationsEmpty(evaluations) {
  return _.isEmpty(evaluations.filter((e) => getDrift(e) !== null));
}
