import React, { FC } from 'react';
import RelearningCondition from './relearning-condition/RelearningCondition.container';
import { ModelSettingsProps } from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

const RelearningCategory: FC<ModelSettingsProps<AugurSettingsDataNbc>> = (
  props
) => {
  return <RelearningCondition {...props} />;
};

export default RelearningCategory;
