import { connect, ConnectedProps } from 'react-redux';
import RunPredictionModal, { Props } from './RunPredictionModal';
import { RootState } from '../../../store/store';
import { hideRunPredictionModal } from '../../../store/runPredictionModal/slice';
import { triggerPredictionJob } from '../../../redux/modules/triggerJobs.module';
import { formValueSelector, isPristine, isValid, reduxForm } from 'redux-form';
import { fieldTable, form, formName } from './form';
import { fetchDatapoolTables } from '../../../store/datapoolTables/slice';

export function mapStateToProps(state: RootState, ownProps: Props) {
  const { datapool, scoringTable } = ownProps;

  return {
    isValid: isValid(formName)(state),
    isPristine: isPristine(formName)(state),
    selectedTable: formValueSelector(formName)(state, fieldTable),

    initialValues: {
      [fieldTable]: scoringTable,
    },

    showModal: state.runPredictionModal?.showModal,
    /** Available tables for the given Datapool */
    availableTables:
      state.datapoolTables.byDatapoolCode?.[datapool.code]?.availableTables,
  };
}

export const mapDispatchToProps: {
  hideRunPredictionModal: () => void;
  triggerPredictionJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string,
    overwritePredictionTable: string
  ) => void;
  fetchDatapoolTables: (payload: {
    datapoolCode: string;
    dataSourceCode: string;
  }) => void;
} = {
  hideRunPredictionModal,
  triggerPredictionJob,
  fetchDatapoolTables,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(reduxForm(form)(RunPredictionModal));
