import { apiRequest, postApiRequest, putApiRequest } from './_apiRequests';
import { Notebook } from '../../../store/workbench/state.types';

export const API_PORT = 3040;

export enum CommitFilter {
  None = 'none',
  NotPulled = 'not_pulled',
  NotPushed = 'not_pushed',
}

export function openMergeRequest(
  mergeRequestId: number,
  repository: string,
  group: string,
  sourceBranch: string,
  targetBranch: string
) {
  const url = '/merger/merge_request';
  const body = {
    merge_request_id: mergeRequestId,
    repository: `${group}/${repository}.git`,
    group,
    source_branch: sourceBranch,
    target_branch: targetBranch,
  };
  return postApiRequest(url, body);
}

export function getMergeStatus(id: number) {
  const url = `/merger/merge_status?merge_id=${id}`;
  return apiRequest(url);
}

export function getRefStatus(repository: string, ref1: string, ref2: string) {
  const url = `/merger/ref_status?repository=${repository}&ref1=${ref1}&ref2=${ref2}`;
  return apiRequest(url);
}

export function getMergeRequestConflict(mergeId: number, fileName: string) {
  const url = `/merger/conflict?merge_id=${mergeId}&filename=${fileName}&deep_diff=false`;
  return apiRequest(url);
}

export function putConflictResolve(
  mergeId: number,
  fileName: string,
  resolvedVersion: Notebook | string
) {
  const url = `/merger/resolve?merge_id=${mergeId}&filename=${fileName}`;
  const body = { resolved_version: JSON.stringify(resolvedVersion) };
  return putApiRequest(url, body);
}

export function postResolveConflictCommit(
  mergeId: number,
  commitMessage: string
) {
  const url = `/merger/commit?merge_id=${mergeId}`;
  const body = { commit_msg: commitMessage };
  return postApiRequest(url, body);
}
