import { connect } from 'react-redux';
import TargetTaxonomy from './TargetTaxonomy';

import { sendNotification } from '../../../redux/modules/notifications.module';
import {
  fetchTaxonomy,
  setNewTaxonomy,
  setTreeData,
} from '../../../redux/modules/datapool.details.module';

export function mapStateToProps(state) {
  const treeData = state.datapoolDetails.treeData;

  return {
    treeData,
    newTaxonomy: state.datapoolDetails.newTaxonomy,
  };
}

export const mapDispatchToProps = {
  fetchTaxonomy,
  setTreeData,
  setNewTaxonomy,
  sendNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetTaxonomy);
