import React, { Component, ComponentType, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { MessageDescriptor } from 'react-intl';
import './styles.scss';
import classNames from 'classnames';

export interface Props {
  errorHeadline?: MessageDescriptor;
  errorDescription?: MessageDescriptor;
  actions?: ComponentType;
  /** Sets "height: 100vh" to the Error Boundary */
  fullScreenHeight?: boolean;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    errorHeadline: {
      id: 'error.headline',
      defaultMessage: 'An unexpected error occurred.',
    },
    // errorDescription:{
    //   id: 'error.description',
    //   defaultMessage: 'Try reloading the page.',
    // }
  };

  constructor(props: PropsWithChildren<Props>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      const { errorHeadline, errorDescription, actions, fullScreenHeight } =
        this.props;
      return (
        <div
          className={classNames('ErrorBoundary', {
            'ErrorBoundary-100vh': fullScreenHeight,
          })}
        >
          <div className={'ErrorBoundary--picture error--pic'}>&nbsp;</div>

          <FormattedMessage {...errorHeadline}>
            {(txt) => <span className={'ErrorBoundary--headline'}>{txt}</span>}
          </FormattedMessage>

          {errorDescription && (
            <FormattedMessage {...errorDescription}>
              {(txt) => (
                <span className={'ErrorBoundary--description'}>{txt}</span>
              )}
            </FormattedMessage>
          )}

          {actions && <div className={'ErrorBoundary--actions'}>{actions}</div>}
        </div>
      );
    }
    return this.props.children;
  }
}
