import { connect } from 'react-redux';
import Habitats from './Habitats';
import {
  fetchNamesForAdminPermission,
  loadGroups,
} from '../../../../redux/modules/admin.users.module';
import { DeprecatedRootState } from '../../../../store/state.type';

export function mapStateToProps(state: DeprecatedRootState) {
  const { data, loading, loaded, error } = state.admin.groups;
  return {
    data,
    loading,
    loaded,
    error,
    names: state.names,
  };
}

export const mapDispatchToProps = {
  loadGroups,
  fetchNamesForAdminPermission,
};

export default connect(mapStateToProps, mapDispatchToProps)(Habitats);
