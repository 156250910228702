import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KernelSpec from '../../../../workbench/part-right/wizards/notebook/ui/kernels/KernelSpec';

export default class RepositoryStepKernel extends Component {
  componentDidMount() {
    const {
      kernelspecs,
      input: { onChange, onFocus },
    } = this.props;

    if (Object.keys(kernelspecs || {}).length === 1) {
      // Auto-select if there is only one kernelspec

      const entry = kernelspecs[Object.keys(kernelspecs || {})[0]];
      const name = entry.name;
      const displayName = entry.spec.display_name;

      onFocus();
      onChange({ name, displayName });
    }
  }

  render() {
    const {
      kernelspecs,
      meta: { touched, error },
      input: { value, onChange, onFocus },
    } = this.props;

    return (
      <div className={'GenericFormStep--field kernelspecs'}>
        {touched && error && (
          <div className={'error-container'}>
            <p className={'error'}>{error}</p>
          </div>
        )}

        {kernelspecs &&
          Object.keys(kernelspecs).map((key) => {
            const entry = kernelspecs[key];
            const name = entry.name;
            let displayName = entry.spec.display_name;
            // Cleanup for the name of the Python kernel
            displayName = displayName.replace('(ipykernel)', '').trim();
            return (
              <KernelSpec
                name={name}
                displayName={displayName}
                key={name}
                onChange={(value) => {
                  onFocus(); // this is necessary to mark the field as touched
                  onChange(value);
                }}
                selectedKernel={value}
              />
            );
          })}
      </div>
    );
  }
}

RepositoryStepKernel.propTypes = {
  kernelspecs: PropTypes.object,
  error: PropTypes.object,
  selectedKernel: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
  isRepositoryWizard: PropTypes.bool,
};

RepositoryStepKernel.defaultProps = {};
