import {
  Constraint,
  ConstraintDetails,
} from 'common/dist/types/module.optimization';
import { CampaignOptimizationDatapoolType } from 'common/dist/types/datapool';
import { SPEAKING_CONSTRAINT_LEVEL } from './common';

export const getConstraintDetails = (
  constraint: Constraint,
  datapoolDetails?: CampaignOptimizationDatapoolType
): ConstraintDetails => {
  switch (constraint.constraintLevel) {
    case 'channel': {
      return {
        speakingLevel: SPEAKING_CONSTRAINT_LEVEL[constraint.constraintLevel],
        name: (datapoolDetails?.meta?.channels || []).find(
          (ch) => ch.id === constraint.channelId
        )?.name,
      };
    }
    case 'communication': {
      return {
        speakingLevel: SPEAKING_CONSTRAINT_LEVEL[constraint.constraintLevel],
        name: (datapoolDetails?.meta?.communications || []).find(
          (ch) => ch.id === constraint.communicationId
        )?.name,
      };
    }
    case 'communicationGroup': {
      return {
        speakingLevel: SPEAKING_CONSTRAINT_LEVEL[constraint.constraintLevel],
        name: (datapoolDetails?.meta?.communicationGroups || []).find(
          (ch) => ch.id === constraint.communicationGroupId
        )?.name,
      };
    }
    case 'allCommunications': {
      return {
        speakingLevel: SPEAKING_CONSTRAINT_LEVEL[constraint.constraintLevel],
      };
    }
  }
};
