import React, { FC, ReactChild, ReactChildren, useState } from 'react';
import SideMenu, { MenuEntryType } from './SideMenu';
import './styles.scss';
import { Tabs } from 'react-tabs-redux';
import ErrorBoundary from '../../pages/error-boundary/ErrorBoundary';
import BrowserCollapseFlap from '../../atoms/BrowserCollapseFlap/BrowserCollapseFlap';

export type Props = {
  /** List of entries for the menu */
  menuEntries: MenuEntryType[];
  /** Which of the tabs is selected? …*/
  selectedTab: string;
  /** Handle the selection of an entry */
  handleSelect: (selectedTab: string, query?: string) => void;
  /** Child components */
  children: ReactChild | ReactChildren | ReactChild[] | ReactChildren[];
  collapsable?: boolean;
  preserveWhitelistQueryOnLink?: string[];
};

const WithSidemenu: FC<Props> = ({
  menuEntries,
  selectedTab,
  handleSelect,
  children,
  collapsable = false,
  preserveWhitelistQueryOnLink = [],
}) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className={'WithSidemenu'}>
      <Tabs selectedTab={selectedTab}>
        {expanded && (
          <SideMenu
            menuEntries={menuEntries}
            selectedTab={selectedTab}
            handleSelect={handleSelect}
            collapsable={collapsable}
            isExpanded={expanded}
            setExpanded={() => setExpanded(false)}
            preserveWhitelistQueryOnLink={preserveWhitelistQueryOnLink}
          />
        )}
        <ErrorBoundary
          errorDescription={{
            id: 'error.sidemenu.description',
          }}
        >
          <div className={'WithSidemenu--content'}>
            {!expanded && (
              <BrowserCollapseFlap
                isExpanded={expanded}
                toggleBrowser={() => setExpanded(true)}
              />
            )}
            {children}
          </div>
        </ErrorBoundary>
      </Tabs>
    </div>
  );
};
export default WithSidemenu;
