import { connect } from 'react-redux';
import MarkdownContent from './MarkdownContent';
import { saveNotebook } from '../../../../../redux/workbench/modules/notebook.module';
import { changeCodeContent } from '../../../../../redux/workbench/modules/notebook.source.module';

export const mapDispatchToProps = {
  saveNotebook,
  changeCodeContent,
};
export default connect(null, mapDispatchToProps)(MarkdownContent);
