import { line } from 'd3-shape';
import * as _ from 'lodash';

import {
  getX,
  getY,
  getRunData,
} from '../../../../../../../../../core/common/accuracyDetail';

export default function append(node, run, fill, xScale, yScale) {
  const x1 = getX(_.first(getRunData(run)));
  const y1 = 0;
  const x2 = getX(_.last(getRunData(run)));
  const y2 = 0;

  const path = line()
    .x((d) => xScale(getX(d)))
    .y((d) => yScale(getY(d)))([
    [x1, y1],
    [x2, y2],
  ]);

  node.append('path').attr('stroke', fill).attr('d', path).attr('opacity', 0.9);
}
