/**
 * Shows the model tuning (pipeline tuning or parameter tuning) in the Augur Details
 */
import ModelSettingsCategory from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { MessageDescriptor } from 'react-intl';
import { FiTarget } from 'react-icons/fi';
import ModelTuningCategory from './ModelTuningCategory';
import { ComponentType } from 'react';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

export default class ModelTuningCategoryWrapper
  implements ModelSettingsCategory<AugurSettingsDataNbc>
{
  component = ModelTuningCategory;
  icon: ComponentType = () => <FiTarget size={17} />;
  id = 'model-tuning';
  label: MessageDescriptor = {
    id: 'no-id',
    defaultMessage: 'Model Tuning',
  };
}
