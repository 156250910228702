import { ComponentType } from 'react';
import { ComponentDatapoolDetailsTab } from '../../_interfaces/datapool-details/DatapoolDetailsTab';
import Archetypes from './Archetypes.container.js';
import ArchetypesIcon from './ArchetypesIcon';
import { DatapoolType } from 'common/dist/types/datapool';

export default class ArchetypesWrapper extends ComponentDatapoolDetailsTab {
  type: 'component' = 'component';
  id = 'archetypes';
  title = {
    id: 'datapoolDetails.sideNav.archetypes',
    defaultMessage: 'Archetpes',
  };
  to = (
    habitatCode: string,
    datapoolCode: string,
    shortDatapoolType: string
  ): string =>
    `/app/habitat/${habitatCode}/datapool/${datapoolCode}/${shortDatapoolType}/archetypes`;

  icon: ComponentType = ArchetypesIcon;
  component: ComponentType = Archetypes;

  subRoutes: object[] | undefined = undefined;
  showTab = (datapool: DatapoolType): boolean => true;
}
