import React, { FC } from 'react';
import './styles.scss';
import { FiMenu, FiChevronDown } from 'react-icons/fi';
import AvatarIcon from '../../../atoms/avatar-icon/AvatarIcon';

export type Props = {
  currentUser?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  photo?: {
    imageType: string;
    imageData: string;
  };
  closeMenu: () => void;
  openMenu: () => void;
  isMenuShown?: boolean;
};

/**
 * Component that is shown in the right top corner of the header. Clicking it opens the menu.
 */
const ProfileMenuButton: FC<Props> = ({
  isMenuShown,
  currentUser,
  openMenu,
  closeMenu,
  photo,
}) => (
  <div
    id={'ProfileMenuButton'}
    className={'ProfileMenuButton'}
    onClick={() => {
      isMenuShown ? closeMenu() : openMenu();
    }}
  >
    <div className='ProfileMenuButton--image'>
      <AvatarIcon
        userId={currentUser?.id || ''}
        firstName={currentUser?.firstName || ''}
        lastName={currentUser?.lastName || ''}
        showTooltip={false}
        fallbackToIcon={false}
        photo={photo}
      />
    </div>

    <div className='ProfileMenuButton--name'>
      {`${currentUser.firstName} ${currentUser.lastName}`}
    </div>

    <div className='ProfileMenuButton--icon'>
      <FiChevronDown size={14} />
    </div>

    <div className='ProfileMenuButton--hamburger'>
      <FiMenu size={20} />
    </div>
  </div>
);

export default ProfileMenuButton;
