import React, { Component, Fragment } from 'react';
import { CellDiffType, ResolveAction } from './ResolveConflicts';
import SingleNotebookCell from './SingleNotebookCell.container';
import { FiChevronsLeft, FiChevronsRight, FiX } from 'react-icons/fi';
import { AltaSigmaCell } from '../../../../../../../store/workbench/state.types';

type Props = {
  /** Filename of the file to resolve the diff for */
  filename: string;
  /** Is it a diff coming from the source or the target file? @*/
  sourceOrTarget: 'source' | 'target';

  /** Object of diff id -> selected action */
  resolveActions: { [id: string]: ResolveAction };
  /** Add a resolve action into the Redux state */
  addResolveAction: (fileName: string, resolveAction: ResolveAction) => void;
  /** Remove a resolve action into the Redux state */
  removeResolveAction: (fileName: string, resolveActionId: string) => void;

  /** Source or target cell (will be one column) */
  cell?: AltaSigmaCell;
  /** List of actions for how to handle this diff */
  diffActions: CellDiffType[];

  /** Render the Cell IDs for debugging? */
  debugRenderCellIds: boolean;
  /** Is the column extended or collapsed? */
  isExtended: boolean;
};

export default class SingleDiff extends Component<Props, {}> {
  /**
   * Renders a single diff action either for the source or the target
   * One single diff means one single cell (!)
   * @param diff
   * @param sourceOrTarget
   */
  renderDiffAction(diff: CellDiffType, sourceOrTarget: 'source' | 'target') {
    const {
      filename,
      addResolveAction,
      removeResolveAction,
      resolveActions,
      isExtended,
    } = this.props;
    const resolveAction = (resolveActions || {})[diff.id];

    if (!resolveAction) {
      // No action has been taken yet
      return (
        <Fragment key={diff.id}>
          <div
            className={'diff-action'}
            onClick={() => {
              addResolveAction(filename, {
                id: diff.id,
                action: 'accept',
                diff,
              });
            }}
            title={`Accept ${diff.op}`}
          >
            {isExtended && (
              <div className={'diff-action-icon ' + diff.op}>
                {sourceOrTarget === 'source' ? (
                  <FiChevronsRight size={16} />
                ) : (
                  <FiChevronsLeft size={16} />
                )}
              </div>
            )}
          </div>
          <div
            className={'diff-action'}
            onClick={() => {
              addResolveAction(filename, { id: diff.id, action: 'reject' });
            }}
            title={'Reject'}
          >
            {isExtended && (
              <div className={'diff-action-icon reject'}>
                <FiX size={16} />
              </div>
            )}
          </div>
        </Fragment>
      );
    } else if (resolveAction.action === 'accept') {
      // The diff has been accepted. No actions available anymore.
      return <div />;
    } else if (resolveAction.action === 'reject') {
      // The diff has been rejected
      return (
        <div
          className={'diff-action'}
          onClick={() => {
            removeResolveAction(filename, diff.id);
          }}
          title={'Reject'}
        >
          {isExtended && (
            <div className={'diff-action-icon reject'}>
              <FiX size={16} />
            </div>
          )}
        </div>
      );
    }
  }

  render() {
    const {
      sourceOrTarget,
      cell,
      diffActions,
      debugRenderCellIds,
      resolveActions,
      isExtended,
    } = this.props;

    let actionClassName = '';
    if (diffActions && diffActions.length > 0 && resolveActions) {
      const resolveAction = resolveActions[diffActions[0].id];
      if (resolveAction) {
        // TODO This is a bit dirty. Only the first diff action is treated here for the coloring, even though all
        //  possible diff actions are rendered.
        actionClassName = `diff-handled handled-${resolveAction.action} `;
      }
    }

    return (
      <div
        className={
          'conflicts-column single-diff-container ' +
          actionClassName +
          ((diffActions || []).length > 0 ? diffActions[0].op : '') +
          ' ' +
          sourceOrTarget +
          '-file' +
          (isExtended ? '' : ' collapsed')
        }
        style={{
          flexDirection: sourceOrTarget === 'source' ? 'row' : 'row-reverse',
        }}
      >
        {isExtended && (
          <div className={`conflicts-notebook`}>
            {cell && (
              <SingleNotebookCell
                cell={cell}
                debugRenderCellIds={debugRenderCellIds}
              />
            )}
          </div>
        )}
        <div className={'actions-parent'}>
          {(diffActions || []).map((diff) =>
            this.renderDiffAction(diff, sourceOrTarget)
          )}
        </div>
      </div>
    );
  }
}
