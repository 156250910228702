import React, { Component } from 'react';
import styles from './styles.module.scss';

import { WrappedFieldProps } from 'redux-form';
import { HierarchyOptionElement } from '../../../../atoms/input-elements/hierarchy-checkboxes-input/HierarchyCheckboxesInput';
import HierarchyCheckboxes from '../../../../atoms/input-elements/hierarchy-checkboxes-input/components/HierarchyCheckboxes';
import { injectIntl, WrappedComponentProps } from 'react-intl';

export type OwnProps = {
  habitatCode: string;
  datapoolCode: string;
};

type ContainerProps = {
  predictionTargets: {
    data?: HierarchyOptionElement[];
    loading: boolean;
    loaded: boolean;
    error?: string;
  };
  fetchPredictionTargets: (habitatCode: string, datapoolCode: string) => void;
};

export type Props = OwnProps & ContainerProps;

class TargetHierarchy extends Component<
  Props & WrappedFieldProps & WrappedComponentProps
> {
  componentDidMount() {
    const { habitatCode, datapoolCode, fetchPredictionTargets } = this.props;
    fetchPredictionTargets(habitatCode, datapoolCode);
  }

  render() {
    const { predictionTargets, input, meta, intl } = this.props;
    const {
      data,
      loaded,
      loading,
      error: loadingError,
    } = predictionTargets || {};
    const { value, onChange } = input;
    const { touched, error } = meta;
    if (loadingError) {
      // --- Early exit: There was an error while fetching the prediction targets
      return (
        <div className={styles.targetHierarchy}>
          <span style={{ color: 'red' }}>
            The prediction targets couldn't be fetched.
          </span>
        </div>
      );
    }

    return (
      <div className={styles.targetHierarchy}>
        <HierarchyCheckboxes
          value={value}
          onChange={onChange}
          options={data}
          loading={loading}
          loaded={loaded}
          intl={intl}
          error={error}
          touched={touched}
        />
      </div>
    );
  }
}

export default injectIntl(TargetHierarchy);
