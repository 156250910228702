import { connect } from 'react-redux';
import JobConfiguration from './JobConfiguration';
import { fetchJobConfigs } from '../../../redux/modules/orchestration.jobconfigs.module';
import {
  error,
  loading,
  loaded,
  cmNames,
} from '../../../redux/selectors/orchestration.jobconfigs.selector';

export function mapStateToProps(state) {
  return {
    error: error(state),
    loading: loading(state),
    loaded: loaded(state),
    cmNames: cmNames(state),
  };
}

export const mapDispatchToProps = {
  fetchJobConfigs,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobConfiguration);
