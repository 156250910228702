import React, { FC } from 'react';
import ParameterTuning from './parameter-tuning/ParameterTuning';
import PipelineTuning from './pipeline-tuning/PipelineTuning.container';
import { ModelSettingsProps } from '../../../../_interfaces/augur-details/model-settings/ModelSettingsCategory';
import { useParams } from 'react-router-dom';
import { AugurDetailsRouteParams } from '../../../types';
import { useAugur } from '../../../../../../../../core/api/augurs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../store/store';
import { ArchetypeVersionWithArchetypeType } from 'common/dist/types/archetypeVersion';
import { AugurSettingsDataNbc } from 'common/dist/types/augurSettings';

const ModelTuningCategory: FC<ModelSettingsProps<AugurSettingsDataNbc>> = (
  props
) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const { data: augur } = useAugur(habitatCode, augurCode);

  const archetypeCode = augur?.archetypeVersion?.archetype?.code || '';
  const archetypeVersions = useSelector<
    RootState,
    ArchetypeVersionWithArchetypeType[]
  >((state) => state.archetypeVersions?.[archetypeCode]?.versions || []);

  const archetypeVersionNumber = augur?.archetypeVersion?.number || '';
  const archetypeVersion = archetypeVersions.find(
    (version) => version.number === archetypeVersionNumber
  );
  const { supportsPipelineTuning, supportsParameterTuning } =
    archetypeVersion || {};

  if (supportsParameterTuning) {
    return <ParameterTuning {...props} />;
  } else if (supportsPipelineTuning) {
    return <PipelineTuning {...props} />;
  } else {
    return <div className={'ModelTuningCategory'} />;
  }
};

export default ModelTuningCategory;
