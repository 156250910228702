import React, { Component } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import ButtonsBaseFile from './ButtonsBaseFile.container';

export type Props = {
  /** Is the source column extended? */
  sourceExtended?: boolean;
  /** Is the target column extended? */
  targetExtended?: boolean;
  /** Extend / collapse the source column */
  toggleSourceFile: (isExtended: boolean) => boolean;
  /** Extend / collapse the target column */
  toggleTargetFile: (isExtended: boolean) => boolean;
};

export default class ConflictsButtonRow extends Component<Props, {}> {
  render() {
    const {
      sourceExtended,
      targetExtended,
      toggleSourceFile,
      toggleTargetFile,
    } = this.props;

    return (
      <div className={'conflicts-row conflicts-row-buttons'}>
        <div
          className={
            'conflicts-column buttons-column source-file' +
            (sourceExtended ? '' : ' collapsed')
          }
        >
          {sourceExtended ? (
            <div
              title={'Collapse Source Notebook'}
              className={'merger-button'}
              style={{ marginLeft: 'auto' }}
              onClick={() => toggleSourceFile(false)}
            >
              <FiArrowLeft className={'icon'} size={'20px'} />
            </div>
          ) : (
            <div
              title={'Extend Source Notebook'}
              className={'merger-button'}
              style={{ marginLeft: 'auto' }}
              onClick={() => toggleSourceFile(true)}
            >
              <FiArrowRight className={'icon'} size={'20px'} />
            </div>
          )}
        </div>

        <div className={'conflicts-column buttons-column base-file'}>
          <ButtonsBaseFile />
        </div>

        <div
          className={
            'conflicts-column buttons-column target-file' +
            (targetExtended ? '' : ' collapsed')
          }
        >
          {targetExtended ? (
            <div
              title={'Collapse Target Notebook'}
              className={'merger-button'}
              onClick={() => toggleTargetFile(false)}
            >
              <FiArrowRight className={'icon'} size={'20px'} />
            </div>
          ) : (
            <div
              title={'Extend Target Notebook'}
              className={'merger-button'}
              onClick={() => toggleTargetFile(true)}
            >
              <FiArrowLeft className={'icon'} size={'20px'} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
