import React, { FC, useEffect } from 'react';
import ResourceList from '../../../organisms/permissions/resouce-list/ResourceList';
import { s3DetailsRoute } from './S3Permissions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { fetchBuckets } from '../../../../redux/modules/data.s3.module';
import { Bucket } from 'common/dist/types/dataManagement/s3';
import { DeprecatedRootState } from '../../../../store/state.type';
import { NamesState } from '../../../../store/names/state.types';
import { datapoolFromBucket } from 'common/dist/constants/dataManagement/s3';

type Props = {
  /** Code of the Cassandra Data Source*/
  code: string;
};

const SingleS3Permissions: FC<Props> = (props: Props) => {
  const { code } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBuckets(code));
  }, [code, dispatch]);

  const buckets = useSelector<RootState, Bucket[]>(
    (state) => state.data.s3[code]?.buckets?.data
  );
  const names = useSelector<DeprecatedRootState, NamesState>(
    (state) => state.names
  );

  return (
    <ResourceList
      headlines={[
        {
          headlineId: 'bucketName',
          label: {
            id: 'no-id',
            defaultMessage: 'Bucket Name',
          },
        },
        {
          headlineId: 'datapoolName',
          label: {
            id: 'no-id',
            defaultMessage: 'Datapool Name',
          },
        },
      ]}
      entries={(buckets || []).map((bucket) => {
        const speakingName =
          names.datapoolNames[datapoolFromBucket(bucket.name).toUpperCase()];
        return {
          linkTo: s3DetailsRoute(code, bucket.name),
          columnValues: {
            bucketName: bucket.name,
            datapoolName: speakingName,
          },
        };
      })}
    />
  );
};

export default SingleS3Permissions;
