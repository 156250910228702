import messages from 'common/dist/messages/admin';
import { FormErrors } from 'redux-form';

export const formName = 'addUser';

export const fieldCredentials = 'credentials';
export const fieldUserDetails = 'userDetails';

export interface FormValues {
  [fieldCredentials]?: {
    email: string;
    password: string;
    isTemporaryPassword?: boolean;
  };
  [fieldUserDetails]?: {
    firstName: string;
    lastName: string;
  };
}
export type ErrorType = Record<string, unknown>;
//Simpler regex https://mailtrap.io/blog/validate-emails-in-react/
// Suggestion:  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/;
function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(password) {
  return (
    password.length >= 8 &&
    /[A-Z]/.test(password) &&
    /[a-z]/.test(password) &&
    /(\d|[\W_])/.test(password)
  );
}

export const validate = (values) => {
  const { email, password, isTemporaryPassword } =
    (values || {})[fieldCredentials] || {};
  const { firstName, lastName } = (values || {})[fieldUserDetails] || {};
  let errors: FormErrors<FormValues, ErrorType> = {};
  // --- EMAIL
  if (!email) {
    errors = {
      ...errors,
      [fieldCredentials]: {
        ...errors[fieldCredentials],
        email: messages.msgUsersValidationEmail.defaultMessage,
      },
    };
  } else if (!validateEmail(email)) {
    errors = {
      ...errors,
      [fieldCredentials]: {
        ...errors[fieldCredentials],
        email: messages.msgUsersValidationEmailFailed.defaultMessage,
      },
    };
  }

  // --- PASSWORD
  if (!password) {
    errors = {
      ...errors,
      [fieldCredentials]: {
        ...errors[fieldCredentials],
        password: messages.msgUsersValidationPasswordFailed.defaultMessage,
      },
    };
  } else if (!validatePassword(password)) {
    errors = {
      ...errors,
      [fieldCredentials]: {
        ...errors[fieldCredentials],
        password: messages.msgUsersValidationPasswordFailed.defaultMessage,
      },
    };
  }

  // --- FIRST NAME
  if (!firstName)
    errors = {
      ...errors,
      [fieldUserDetails]: {
        ...errors[fieldUserDetails],
        firstName: messages.msgUsersValidationFirstname.defaultMessage,
      },
    };

  // --- LAST NAME
  if (!lastName)
    errors = {
      ...errors,
      [fieldUserDetails]: {
        ...errors[fieldUserDetails],
        lastName: messages.msgUsersValidationLastname.defaultMessage,
      },
    };

  return errors;
};

export const form = {
  form: formName,
  validate,
};
export const updateEmail = (
  value: FormValues[typeof fieldCredentials],
  email: string
): FormValues[typeof fieldCredentials] => ({ ...value, email });
export const updatePassword = (
  value: FormValues[typeof fieldCredentials],
  password: string
): FormValues[typeof fieldCredentials] => ({
  ...value,
  password,
});
export const updateFirstName = (
  value: FormValues[typeof fieldUserDetails],
  firstName: string
): FormValues[typeof fieldUserDetails] => ({ ...value, firstName });
export const updateLastName = (
  value: FormValues[typeof fieldUserDetails],
  lastName: string
): FormValues[typeof fieldUserDetails] => ({
  ...value,
  lastName,
});
