import { Props } from './RunCodeCapsuleModal';
import { ConfigProps, FormErrors } from 'redux-form';
import {
  isValidK8sCpuSpec,
  isValidK8sMemorySpec,
} from 'common/dist/utils/kubernetes';

export const formName = 'runCodeCapsule';
export const fieldVersionNumber = 'versionNumber';
export const fieldParameters = 'parameters';
export const fieldResources = 'resources';
export const fieldNotebooks = 'notebooksToExecute';
export const fieldJobName = 'name';
export const fieldGpu = 'gpu';
export const fieldPriority = 'priority';

export interface Data {
  [fieldVersionNumber]: { label: string; value: string };
  [fieldParameters]: Record<string, string>;
  [fieldResources]: {
    cpu?: string;
    memory?: string;
    gpu?: string;
  };
  [fieldNotebooks]: string[];
  [fieldJobName]: string;
  [fieldGpu]?: string;
  [fieldPriority]: { value: Priority; label: string };
}

export type Priority = 'highest' | 'high' | 'medium' | 'low' | 'lowest';

export const PRIORITY_HIGHEST = 'highest';
export const PRIORITY_HIGH = 'high';
export const PRIORITY_MEDIUM = 'medium';
export const PRIORITY_LOW = 'low';
export const PRIORITY_LOWEST = 'lowest';

export const PRIORITIES_DISPLAY_NAMES = {
  [PRIORITY_HIGHEST]: 'Highest',
  [PRIORITY_HIGH]: 'High',
  [PRIORITY_MEDIUM]: 'Medium',
  [PRIORITY_LOW]: 'Low',
  [PRIORITY_LOWEST]: 'Lowest',
};

export const ORDERED_PRIORITIES = [
  'highest',
  'high',
  'medium',
  'low',
  'lowest',
];

export type ErrorType = Record<string, string> | string;

type Errors = FormErrors<Data, ErrorType>;

export function validate(values: Data): Errors {
  const { resources, parameters, notebooksToExecute, versionNumber } = values;
  let errors: Errors = {};

  // --- Validate Resources
  if (resources) {
    const { cpu, memory, gpu } = resources;
    if (cpu) {
      if (!isValidK8sCpuSpec(cpu)) {
        errors = {
          ...errors,
          resources: {
            ...((errors.resources as Record<string, string>) || {}),
            cpu: 'Please enter valid CPU, for example: 500m or 2',
          },
        };
      }
    }
    if (memory) {
      if (!isValidK8sMemorySpec(memory, true)) {
        errors = {
          ...errors,
          resources: {
            ...((errors.resources as Record<string, string>) || {}),
            memory: 'Please enter valid Memory, for example: 2Gi',
          },
        };
      }
    }
    if (gpu) {
      const num = Number(gpu).valueOf();
      if (isNaN(num) || num < 0) {
        errors = {
          ...errors,
          resources: {
            ...((errors.resources as Record<string, string>) || {}),
            gpu: 'Please enter valid GPU',
          },
        };
      }
    }
  }

  // --- Validate Parameters
  if (parameters) {
    // TODO
  }

  // --- Validate Notebooks to execute
  if (!notebooksToExecute || notebooksToExecute.length < 1) {
    errors = {
      ...errors,
      notebooksToExecute: 'Please select at least one notebook',
    };
  }

  // --- Validate Version number
  if (!versionNumber) {
    errors = {
      ...errors,
      versionNumber: 'Please select a version',
    };
  }

  return errors;
}

export const form: ConfigProps<Data, Props, ErrorType> = {
  form: formName,
  validate,
  // enableReinitialize, because depending on the versionNumber there are different initialValues
  enableReinitialize: true,
};
