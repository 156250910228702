import React, { FC, useState } from 'react';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import styles from './styles.module.scss';
import { WrappedFieldProps } from 'redux-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';

const displayToSlug = (display: string) =>
  display
    .trim()
    .toLowerCase()
    .replaceAll(/[^a-z0-9-]/g, '-')
    .replace(/-+/g, '-')
    .replace(/(^-)|(-$)/, '');

const RepositoryStepName: FC<WrappedFieldProps> = (props) => {
  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { touched, valid, error },
  } = props;
  const [isSlugUnmodified, setIsSlugUnmodified] = useState(true);
  const user = useSelector<RootState>((state) => state.currentUser);
  return (
    <div className={'GenericFormStep--field'}>
      <TextInputLine
        touched={touched}
        error={error?.display}
        valid={touched && valid}
        disabled={false}
        id={'repository-name-display'}
        name={'repository-name-display'}
        hasLabel={true}
        labelDefault={'Enter Name'}
        placeholderDefault={'Enter Repository Name'}
        value={value?.display}
        onChange={(e) => {
          onChange({
            display: e.target.value,
            slug: isSlugUnmodified ? displayToSlug(e.target.value) : value.slug,
          });
        }}
        onBlur={() => onBlur(value)}
        onFocus={onFocus}
      />
      <div className={styles.container}>
        <div className={styles.group}>
          <span>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {user?.firstName}&nbsp;{user?.lastName}
          </span>
          <span>/</span>
        </div>
        <TextInputLine
          touched={touched}
          error={error?.slug}
          valid={touched && valid}
          disabled={false}
          id={'repository-name-slug'}
          name={'repository-name-slug'}
          hasLabel={false}
          placeholderDefault={'Enter Repository Slug'}
          value={value?.slug}
          onChange={(e) => {
            setIsSlugUnmodified((e.target.value ?? '').length === 0);
            onChange({ display: value?.display, slug: e.target.value });
          }}
          onBlur={() => onBlur(value)}
          onFocus={onFocus}
        />
      </div>
    </div>
  );
};

export default RepositoryStepName;
