import { connect } from 'react-redux';
import BuildAppWizard from './BuildAppWizard';
import { fieldTag, formName } from './form';
import { formValueSelector, isValid } from 'redux-form';
import { triggerAppBuild } from '../../../../redux/workbench/modules/collab.module';
import { fetchRepoDetails } from '../../../../redux/workbench/modules/collab.repository.module';
import { getTag } from '../common';

export function mapStateToProps(state) {
  const { loading, loaded, error, data } =
    state.workbench.collab.repoDetails || {};
  const appCode = data?.app.code;
  const versions = state.appVersions[appCode];

  const initialFieldTag = getTag(versions?.data);

  return {
    valid: isValid(formName)(state),
    tag: formValueSelector(formName)(state, fieldTag),
    notebooksToExecute: ['main.asapp'], // TODO This is fixed for the beginning.
    loading,
    loaded,
    error,
    data,
    versions,

    initialValues: {
      [fieldTag]: initialFieldTag,
    },
  };
}

export const mapDispatchToProps = {
  triggerAppBuild,
  fetchRepoDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildAppWizard);
