import { MessageDescriptor } from 'react-intl';
import _ from 'lodash';
import { RootState } from '../../../../store/store';
import { ArchetypeVersionType } from 'common/dist/types/archetypeVersion';
import messagesAugur from 'common/dist/messages/augurs';
import {
  PipelineTuningValue,
  PipelineTuningValueNode,
  PipelineTuningValueNodeOrGroup,
  SelectedStaticParameter,
  SelectedTuningParameter,
} from 'common/dist/types/pipeline';
import {
  NodeType,
  PipelineErrorType,
  PipelineTuningSchemaType,
  SingleNodeErrorType,
  SinglePipelineErrorType,
  StaticParameterType,
  TuningParameterType,
  ValueDescriptionType,
} from '../_pipeline-tuning-results-common/types';
import { ArchetypeWithArchetypeVersionsType } from 'common/dist/types/archetype';

/**
 * Validate the pipeline tuning field.
 * Checks every single pipeline and if there is an error, the error will be injected into the object of index -> single pipeline error
 * @param value array of values of the pipelines
 * @param pipelineTuningSchemas
 */
export function validatePipelineTuningField(
  value: PipelineTuningValue[] | undefined,
  pipelineTuningSchemas: PipelineTuningSchemaType[]
): PipelineErrorType {
  const error: PipelineErrorType = {};
  if (!value) {
    return undefined;
  }

  if (!pipelineTuningSchemas) {
    console.error('Missing pipelineTuningSchemas for validation');
    return undefined;
  }

  value.forEach((pipelineValue: PipelineTuningValue, index: number) => {
    const pipelineSchema = pipelineTuningSchemas[index];
    const singlePipelineError = validateSinglePipeline(
      pipelineValue,
      pipelineSchema
    );
    if (singlePipelineError) {
      error[index] = singlePipelineError;
    }
  });

  // General error
  const allNodes: PipelineTuningValueNode[] = value.flatMap((pipelineValue) =>
    pipelineValue.nodes.flatMap((node) =>
      node.type === 'group' ? node.nodes : [node]
    )
  );
  // Find at least one active classifier. Activated nodes can just be missing isActive, i.e. they are active by default unless specified otherwise
  const pathExists = allNodes.find(
    (node) => node.nodeType === 'classifier' && node.isActive !== false
  );
  if (!pathExists) {
    error.general = messagesAugur.tuningPipelineValidationErrorNoClassifier;
  }

  // --- Return the errors if there are any - otherwise return undefined. Returning an empty object doesn't work when
  // using field-level validation
  if (Object.keys(error).length === 0) {
    return undefined;
  } else {
    return error;
  }
}

/**
 * Validate one single pipeline of the tuning field
 * @param pipelineValue value of one single pipeline
 * @param pipelineSchema
 */
function validateSinglePipeline(
  pipelineValue: PipelineTuningValue,
  pipelineSchema: PipelineTuningSchemaType
): SinglePipelineErrorType {
  const error = {};

  // --- Derive the node schema map first
  const nodeSchemaMap = _.keyBy(
    (pipelineSchema.nodes || []).flatMap((n) => {
      if (n.type === 'node') return [n];
      else if (n.type === 'group') return n.nodes;
      else return undefined;
    }),
    'id'
  );

  // --- Validate the nodes
  const nodeErrors = {};
  pipelineValue.nodes.forEach((node: PipelineTuningValueNodeOrGroup) => {
    if (node.type === 'node') {
      const nodeError = validateSingleNode(node, nodeSchemaMap[node.id]);
      if (nodeError) {
        nodeErrors[node.id] = nodeError;
      }
    } else if (node.type === 'group') {
      node.nodes.forEach((innerNode: PipelineTuningValueNode) => {
        const innerNodeError = validateSingleNode(
          innerNode,
          nodeSchemaMap[innerNode.id]
        );
        if (innerNodeError) {
          nodeErrors[innerNode.id] = innerNodeError;
        }
      });
    }
  });
  if (Object.keys(nodeErrors).length > 0) {
    error['nodes'] = nodeErrors;
  }

  // --- Return the errors if there are any - otherwise return undefined
  if (Object.keys(error).length === 0) {
    return undefined;
  } else {
    return error;
  }
}

function validateSingleNode(
  nodeValue: PipelineTuningValueNode,
  nodeSchema: NodeType
): SingleNodeErrorType {
  const error = {};

  // --- Validate the static parameters (if the node isEditable)
  const staticParameterErrors = {};
  if (nodeSchema.isEditable) {
    const staticParamValueMap = _.keyBy(nodeValue.staticParameters || [], 'id');
    (nodeSchema.staticParameters || []).forEach(
      (paramSchema: StaticParameterType) => {
        const paramValue = staticParamValueMap[paramSchema.id];
        const staticParamError = validateSingleStaticParameter(
          paramValue,
          paramSchema
        );
        if (staticParamError) {
          staticParameterErrors[paramValue.id] = staticParamError;
        }
      }
    );
  }
  if (Object.keys(staticParameterErrors).length > 0) {
    error['staticParameters'] = staticParameterErrors;
  }

  // --- Validate the tuning parameters
  const tuningParameterErrors = {};
  if (nodeSchema.isTuneable) {
    const tuningParamValueMap = _.keyBy(nodeValue.tuningParameters || [], 'id');
    (nodeSchema.tuningParameters || []).forEach(
      (paramSchema: TuningParameterType) => {
        const paramValue = tuningParamValueMap[paramSchema.id];
        const tuningParamError = validateSingleTuningParameter(
          paramValue,
          paramSchema
        );
        if (tuningParamError) {
          tuningParameterErrors[paramValue.id] = tuningParamError;
        }
      }
    );
  }
  if (Object.keys(tuningParameterErrors).length > 0) {
    error['tuningParameters'] = tuningParameterErrors;
  }

  // --- Return the errors if there are any - otherwise return undefined
  if (Object.keys(error).length === 0) {
    return undefined;
  } else {
    return error;
  }
}

/**
 *
 * @param paramValue
 * @param paramSchema
 */
function validateSingleStaticParameter(
  paramValue: SelectedStaticParameter,
  paramSchema: StaticParameterType
): MessageDescriptor | undefined {
  if (!paramValue || !paramSchema) return undefined;

  const { id, displayName, value } = paramValue;

  // 1. Check whether the value is defined
  if (!value) {
    return {
      id: 'no-id',
      defaultMessage: 'Field must not be empty',
    };
  }

  // 2. Check whether the value is valid
  if (!isValueInValidValues(value, paramSchema.validValues)) {
    return {
      id: 'no-id',
      defaultMessage: 'Value must be {strValidValues}',
      // @ts-ignore
      values: { strValidValues: validValuesToString(paramSchema.validValues) },
    };
  }

  // 3. Check whether the value ends with a "." - which would be an incomplete double (which is not parsed to an actual double in "parseToCorrectType()")
  if (value.toString().endsWith('.')) {
    return {
      id: 'no-id',
      defaultMessage: 'Value is not valid',
    };
  }

  // --- The param is valid, return undefined
  return undefined;
}

/**
 *
 * @param paramValue
 * @param paramSchema
 */
function validateSingleTuningParameter(
  paramValue: SelectedTuningParameter,
  paramSchema: TuningParameterType
): MessageDescriptor | undefined {
  if (!paramValue || !paramSchema) return undefined;

  const { id, displayName, valueCandidates } = paramValue;

  // 1. Check whether the value is defined
  if (!valueCandidates || valueCandidates.filter((x) => x).length === 0) {
    // filter(x => x) to drop the empty strings that might possibly be there
    return {
      id: 'no-id',
      defaultMessage: 'Field must not be empty',
    };
  }

  // 2. Check whether the values are all valid
  const invalidValueErrors = valueCandidates
    .map((value) => {
      if (!isValueInValidValues(value, paramSchema.validValues)) {
        return {
          id: 'no-id',
          defaultMessage: 'Value must be {strValidValues}',
          values: {
            strValidValues: validValuesToString(paramSchema.validValues),
          },
        };
      } else return undefined;
    })
    .filter((x) => x); // simply remove the undefined entries
  if (invalidValueErrors.length > 0) return invalidValueErrors[0];

  // 3. Check whether one of the values ends with a "." - which would be an incomplete double (which is not parsed to an actual double in "parseToCorrectType()")
  const endsWithDotErrors = valueCandidates
    .map((value) => {
      if (value.toString().endsWith('.')) {
        return {
          id: 'no-id',
          defaultMessage: 'Value is not valid',
        };
      } else return undefined;
    })
    .filter((x) => x); // simply remove the undefined entries
  if (endsWithDotErrors.length > 0) return endsWithDotErrors[0];

  // --- The param is valid, return undefined
  return undefined;
}

function isValueInValidValues(
  value: string | number,
  validValues: ValueDescriptionType[]
): boolean {
  let isIn = false;
  validValues.forEach((singleValidValues) => {
    if (singleValidValues.type === 'string') {
      if (singleValidValues.values.includes(value.toString())) isIn = true;
    } else if (['int', 'double'].includes(singleValidValues.type)) {
      if (
        singleValidValues.minValue <= value &&
        value <= singleValidValues.maxValue
      )
        isIn = true;
    }
  });
  return isIn;
}

/**
 * Takes a list of valid values and converts it to a readable string for user output. This string will be wrapped in:
 * "Value must be {...}"
 * @param validValues
 */
function validValuesToString(validValues: ValueDescriptionType[]): string {
  return validValues
    .map((singleValidValues) => {
      if (singleValidValues.type === 'string') {
        return `in ["${singleValidValues.values.join('", "')}"]`;
      } else if (['int', 'double'].includes(singleValidValues.type)) {
        return `between [${singleValidValues.minValue},${singleValidValues.maxValue}]`;
      }
    })
    .join(' or ');
}

/**
 * Extract the parameter tuning schema from the settings contained in the combined archetype + archetype_versions data
 */
export function extractPipelineTuningSchemasFromState(
  state: RootState,
  archetypeCode: string,
  archetypeVersionNumber: string
): PipelineTuningSchemaType[] | undefined {
  const archetypes: ArchetypeWithArchetypeVersionsType[] =
    state.archetypes.data;

  if (!archetypes) return undefined;

  // Find the settings for the correct archetype and version in the data (if settings are missing in db they are null)
  const archetypeVersionSettings: {
    pipelineTuning: PipelineTuningSchemaType[];
  } | null = archetypes
    ?.find((archetype) => archetype.code === archetypeCode)
    ?.versions.find(
      (version: ArchetypeVersionType) =>
        version.number === archetypeVersionNumber
    )?.settings;
  return archetypeVersionSettings?.pipelineTuning;
}

export function deriveInitialValues(
  pipelineTuningSchemas: PipelineTuningSchemaType[]
): PipelineTuningValue[] {
  const nodeToMappedNode: (node: NodeType) => PipelineTuningValueNode = (
    node: NodeType
  ) => ({
    id: node.id,
    type: 'node',
    nodeType: node.nodeType,
    displayName: node.displayName,
    description: node.description,
    isTuneable: node.isTuneable,
    tuningParameters: !node.tuningParameters
      ? undefined
      : node.tuningParameters.map((param) => ({
          id: param.id,
          displayName: param.displayName,
          valueCandidates: param.default,
        })),
    isEditable: node.isEditable,
    staticParameters: !node.staticParameters
      ? undefined
      : node.staticParameters.map((param) => ({
          id: param.id,
          displayName: param.displayName,
          value: param.default,
        })),
  });

  return pipelineTuningSchemas.map((pipelineSchema) => {
    const mappedNodes = pipelineSchema.nodes.map((node) => {
      if (node.type === 'node') {
        return nodeToMappedNode(node);
      } else if (node.type === 'group') {
        return {
          id: node.id,
          type: node.type,
          nodes: node.nodes.map((n) => nodeToMappedNode(n)),
        };
      } else {
        return undefined;
      }
    });

    return {
      id: pipelineSchema.id,
      displayName: pipelineSchema.displayName,
      optimizationMethod: pipelineSchema.optimizationMethod,
      edges: pipelineSchema.edges,
      nodes: mappedNodes,
    };
  });
}

/**
 * Receives a string as the rawValue (that was entered into a HTML input element) and tries to convert it to a number
 * if possible
 */
export const parseToCorrectType = (rawValue: string | number | undefined) => {
  if (!rawValue) return rawValue; // Only process values that are not null

  if (isNaN(parseFloat(rawValue as string))) {
    return rawValue;
  } else {
    return parseFloat(rawValue as string);
  }
};

/**
 * Parses a single node
 * @param node
 */
export function parseSingleNode(
  node: PipelineTuningValueNode
): PipelineTuningValueNode {
  return {
    ...node,
    staticParameters: !node.staticParameters
      ? undefined
      : node.staticParameters.map((staticParam) => ({
          ...staticParam,
          value: parseToCorrectType(staticParam.value),
        })),
    tuningParameters: !node.tuningParameters
      ? undefined
      : node.tuningParameters.map((tuningParam) => ({
          ...tuningParam,
          valueCandidates: tuningParam.valueCandidates.map((value) =>
            parseToCorrectType(value)
          ),
        })),
  };
}

/**
 * Takes a list of pipeline values and converts it's static and tuning parameters to the best types
 * @param value
 */
export function parseAllPipelineParameterValues(
  value: PipelineTuningValue[]
): PipelineTuningValue[] {
  if (!value) return undefined;

  return value.map((pipeline) => ({
    ...pipeline,
    nodes: pipeline.nodes.map((node) => {
      if (node.type === 'node') {
        return parseSingleNode(node);
      } else if (node.type === 'group') {
        return {
          ...node,
          nodes: node.nodes.map((n) => parseSingleNode(n)),
        };
      }
    }),
  }));
}
