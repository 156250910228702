import React, { FC } from 'react';
import TextInputLine from '../../../../atoms/input-elements/text-input-line/TextInputLine';
import { WrappedFieldProps } from 'redux-form';

export type Props = {
  inputId?: string;
  inputName?: string;
};

const DatapoolName: FC<Props & WrappedFieldProps> = (props) => {
  const {
    inputId,
    inputName,
    meta: { touched, asyncValidating, valid, error },
    input,
  } = props;

  return (
    <TextInputLine
      touched={touched}
      error={error}
      validating={asyncValidating}
      valid={touched && valid}
      disabled={false}
      id={inputId}
      name={inputName}
      hasLabel={true}
      labelId={'newDatapool.stepOne.datapool’s_name'}
      labelDefault={"Datapool's Name"}
      placeholderId={'newDatapool.stepOne.please_enter_name'}
      placeholderDefault={'Please enter a name'}
      value={input.value}
      onFocus={input.onFocus}
      onChange={input.onChange}
      onBlur={input.onBlur}
    />
  );
};

export default DatapoolName;
