import React, { FC, useEffect, useState } from 'react';
import IconButton, {
  Props as IconButtonProps,
} from '../../../atoms/icon-button/IconButton';
import './styles.scss';
import { DeprecatedRootState } from '../../../../store/state.type';
import { Dispatch } from 'redux-act';
import { useDispatch, useSelector } from 'react-redux';
import SimpleContextMenu, {
  ContextMenuEntry,
} from '../context-menu/SimpleContextMenu';

export type IconButtonBarButton = Omit<
  IconButtonProps,
  'onClick' | 'disabled'
> & {
  onClick?: (state: DeprecatedRootState, dispatch: Dispatch) => void;
  disabled?: (state: DeprecatedRootState) => boolean;
  /** If a contextMenu is set, the onClick will be ignored */
  contextMenu?: ContextMenuEntry[];
};

export type Props = {
  buttons: IconButtonBarButton[];
};

const IconButtonBar: FC<Props> = ({ buttons }) => {
  const state = useSelector<DeprecatedRootState, DeprecatedRootState>(
    (state) => state
  );
  const dispatch = useDispatch();
  // For which id is the contextMenu shown?
  const [expandedId, setExpandedId] = useState<string>(null);
  return (
    <div className={'button-bar'}>
      {buttons
        .map((b) => ({
          ...b,
          onClick: b.onClick ? () => b.onClick(state, dispatch) : undefined,
          disabled: b.disabled ? b.disabled(state) : false,
        }))
        .map((b, i) => (
          <div
            key={i}
            style={{ position: 'relative' }}
            onClick={() =>
              setExpandedId((prev) =>
                prev === b.id || !b.contextMenu ? null : b.id
              )
            }
          >
            <IconButton key={i} size={20} {...b} />
            {expandedId === b.id && (
              <SimpleContextMenu
                menuId={'settings'}
                entries={b.contextMenu}
                hide={() => setExpandedId(null)}
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default IconButtonBar;
