import { connect, ConnectedProps } from 'react-redux';
import RunningApps from './RunningApps';
import {
  fetchRunningApps,
  stopApp,
} from '../../../../store/admin/resources/actions';
import { DeprecatedRootState } from '../../../../store/state.type';
import { fetchUserSummaryById } from '../../../../store/users/slice';
import { notebookUser } from '../../../../redux/workbench/selectors/notebookUser.selector';
import { checkWhetherAppIsRunning } from '../../../../redux/workbench/modules/app.connection.module';
import { RootState } from '../../../../store/store';

export function mapStateToProps(state: RootState) {
  const { loading, loaded, data, error, stoppingApps } =
    state.adminResources.runningApps || {};
  return {
    loading,
    loaded,
    /** Response from the call against the JupyterHubs .../users endpoint */
    data,
    error,
    /** List of servers that are currently stopped (userCodes define the servers in this case) */
    stoppingApps,
    users: state.users,
  };
}

export const mapDispatchToProps = {
  fetchRunningApps,
  fetchUserSummaryById,
  /** Stop the Workbench (the Jupyter Notebook) for a given user */
  stopApp,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(RunningApps);
