import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FiCheck } from 'react-icons/fi';
import DropdownSelectInput from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

export default class StepBranch extends Component {
  render() {
    const { fieldName } = this.props;
    return (
      <Field
        name={fieldName}
        label={fieldName}
        component={BranchSelectFieldComponent}
        {...this.props}
      />
    );
  }
}

StepBranch.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

// --- Form Component

export class BranchSelectFieldComponent extends Component {
  componentDidMount() {
    if (this.props.initialValue && this.props.branches.length > 0) {
      this.props.input.onBlur(this.props.initialValue);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.branches !== this.props.branches &&
      this.props.branches.length > 0 &&
      this.props.initialValue
    ) {
      this.props.input.onBlur(this.props.initialValue);
    }
  }

  errorMessage() {
    const {
      meta: { error, touched },
    } = this.props;
    return touched && error && <p className={'error'}>{error}</p>;
  }

  render() {
    const {
      title,
      description,
      branches,
      fieldName,
      num,
      meta: { valid, error, touched },
    } = this.props;
    const { value, onChange, onBlur } = this.props.input;

    const options = branches.map((branch) => ({
      label: branch.name,
      value: branch.name,
    }));

    return (
      <div className={'step-container step-1-container'}>
        <div className={'description-container'}>
          {touched && !error ? (
            <div className={'bubble bubble-ok'}>
              <FiCheck className={'checked-icon'} size={'20px'} />
            </div>
          ) : (
            <div className={'bubble bubble-info'}>
              <p>{num}</p>
            </div>
          )}
          <div className={'description'}>
            <div className={'title'}>
              <p>{title}</p>
            </div>
            <div className={'text'}>
              <p>{description}</p>
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <DropdownSelectInput
            id='git-branch-selector'
            name={fieldName}
            options={options}
            value={options.find((o) => o.value === value)}
            onChange={(option) => {
              onChange(option.value);
            }}
            openOnFocus
            className={'git-branch-selector'}
            clearable={false}
            disabled={this.props.isDisabled ? this.props.isDisabled : false}
          />

          <div className={'error-container'}>{this.errorMessage()}</div>
        </div>
      </div>
    );
  }
}

BranchSelectFieldComponent.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    valid: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
  selectedBranch: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  num: PropTypes.number.isRequired,
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  initialValue: PropTypes.string,
  isDisabled: PropTypes.bool,
};
