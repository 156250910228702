import { checkGitRefFormat } from '../../../../../../../redux/workbench/modules/container-interactions.module';

export const fieldBranchName = 'branch-name';

export const createBranchForm = {
  form: 'create-branch-form',
  fieldBranchName,
  validate: (values) => {
    let errors = {};

    const branchName = values[fieldBranchName];
    if (!branchName || branchName.trim() === '') {
      errors = { ...errors, [fieldBranchName]: 'Please enter a branch name' };
    }

    return errors;
  },
  asyncValidate: (values, dispatch) => {
    const branchName = values[fieldBranchName];
    return new Promise((resolve, reject) =>
      dispatch(checkGitRefFormat(branchName, { resolve, reject }))
    ).then(({ valid }) =>
      valid === 'true'
        ? null
        : Promise.reject({
            [fieldBranchName]: { id: 'newBranch.error.notValid' },
          })
    );
  },
};
