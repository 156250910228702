import { ParameterTuningValue } from './ParameterTuningSelection';
import {
  inputStringToList,
  isValid,
  Parameter,
  ParameterTuningSchema,
} from './types';

export const fieldName = 'parameterTuning';

export function validateParameterTuningField(
  value: ParameterTuningValue,
  parameterTuningSchema: ParameterTuningSchema
) {
  // Validate meta
  const metaValues = value?.meta;
  if (
    !Object.entries(metaValues || {}).some(
      ([algo, algoMeta]) => algoMeta.selected
    )
  ) {
    return {
      meta: {
        id: 'newAugur.step.tuningParameter.error.at_least_one_algorithm',
        defaultMessage: 'Please select at least one algorithm.',
      },
    };
  }

  // Validate searchStrategy
  if (!value.searchStrategy) {
    return {
      searchStrategy: {
        id: 'newAugur.step.tuningParameter.error.missing_search_strategy',
      },
    };
  }

  // Further validation is not possible without having the parameterTuningSchema
  if (!parameterTuningSchema) return undefined;

  // Validate algorithms & parameters
  for (const algorithm of parameterTuningSchema.availableAlgorithms) {
    // for each algorithm ...
    const algorithmName = algorithm.name;
    if (value?.meta?.[algorithmName].selected) {
      // If the algorithm is selected (=active)
      for (const parameter of algorithm.parameters) {
        // for each parameter ...
        const parameterName = parameter.name;
        const parameterError = validateParameter(
          parameter,
          value?.algorithms[algorithmName]?.[parameterName]
        );
        if (parameterError) {
          return {
            algorithms: {
              [algorithmName]: parameterError,
            },
          };
        }
      }
    }
  }

  return undefined;
}

function validateParameter(parameter: Parameter, parameterValue?: string) {
  if (parameterValue === undefined || parameterValue === '') {
    return {
      [parameter.name]: {
        id: 'newAugur.step.tuningParameter.error.missing_values',
      },
    };
  } else {
    const valueList = inputStringToList(parameterValue);
    // Iterate over the input list and return when an entry does not fit with at least on of the list of validValues
    for (let i = 0; i < valueList.length; i++) {
      if (!parameter.validValues.some((vt) => isValid(valueList[i], vt))) {
        return {
          [parameter.name]: {
            id: 'newAugur.step.tuningParameter.error.invalid_value',
            values: { invalidValue: valueList[i] },
          },
        };
      }
    }
  }

  return undefined;
}
