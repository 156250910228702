import { createAction } from 'redux-act';
import { put, call, fork, take, cancel } from 'redux-saga/effects';

import { updateIntl } from 'react-intl-redux';

import * as Api from '../../core/api';

export const changeLocale = createAction(
  'change locale',
  (locale, callback = () => {}) => ({ locale, callback })
);

export function* changeLocaleSaga({ payload: { locale, callback } }) {
  locale = 'en';
  const { response } = yield call(Api.dashboard.getLocales, locale);

  if (response) {
    localStorage.setItem('locale', locale);
    yield put(updateIntl({ locale, messages: response }));
  }

  callback();
}

export function* watchChangeLocale() {
  let lastTask;
  let lastLocale;

  while (true) {
    // eslint-disable-line no-constant-condition
    const action = yield take(changeLocale.getType());
    const locale = action.payload.locale;

    if (locale !== lastLocale) {
      if (lastTask) {
        yield cancel(lastTask);
      }

      lastLocale = locale;
      lastTask = yield fork(changeLocaleSaga, action);
    } else {
      action.payload.callback();
    }
  }
}
