import { createAction } from 'redux-act';

import * as misclassification from '../../core/common/misclassification';

export const showToolTip = createAction('show tooltip');

export const moveToolTip = createAction('move tooltip', (x, y, data) => ({
  x,
  y,
  data,
}));

export const hideToolTip = createAction('hide tooltip');

export const reducer = {
  [showToolTip]: (state) => ({
    ...state,
    misclassification: {
      ...state.misclassification,
      isTooltip: true,
    },
  }),

  [moveToolTip]: (state, { x, y, data }) => {
    const tooltipData = misclassification.getPercentBuckets(data);
    return {
      ...state,
      misclassification: {
        ...state.misclassification,
        tooltipX: x,
        tooltipY: y,
        tooltipData,
      },
    };
  },

  [hideToolTip]: (state) => ({
    ...state,
    misclassification: {
      ...state.misclassification,
      isTooltip: false,
      tooltipX: 0,
      tooltipY: 0,
      tooltipData: [],
    },
  }),
};
