import React, { FC, useState } from 'react';
import NodeEditor from './node-editor/NodeEditor';
import _ from 'lodash';
import PipelineTuningChart from '../pipeline-tuning-chart/PipelineTuningChart';
import {
  PipelineTuningValue,
  PipelineTuningValueNode,
  PipelineTuningValueNodeOrGroup,
} from 'common/dist/types/pipeline';
import {
  PipelineTuningSchemaType,
  SinglePipelineErrorType,
} from '../_pipeline-tuning-results-common/types';

type Props = {
  /** The pipeline schema, that defines the structure, which values are valid, and so on. This object does not contain
   * the actual values entered into the input fields. This information comes in the "value" prop. */
  pipeline: PipelineTuningSchemaType;
  /** The default values of the pipeline (used for the "reset to defaults" button) */
  defaultValues: PipelineTuningValue;

  value: PipelineTuningValue;
  onChange: (value: PipelineTuningValue) => void;
  onBlur: () => void;
  onFocus: () => void;
  error: SinglePipelineErrorType;
};

const getNodesValuesMap = (nodes: PipelineTuningValueNodeOrGroup[]) => {
  return _.keyBy(
    (nodes || []).flatMap((n) => {
      if (n.type === 'node') return [n];
      else if (n.type === 'group') return n.nodes;
      else return undefined;
    }),
    'id'
  );
};

const Pipeline: FC<Props> = (props: Props) => {
  const { pipeline, value, onChange, onBlur, onFocus, error, defaultValues } =
    props;

  const [selectedNode, onSelectingNode] = useState(null);
  const nodesValuesMap = getNodesValuesMap(value?.nodes);

  const inactiveNodeIds = (value?.nodes || []).flatMap((node) => {
    if (
      node.type === 'node' &&
      Object.keys(node).includes('isActive') &&
      !node.isActive
    ) {
      return [node.id];
    } else if (node.type === 'group') {
      return node.nodes
        .filter((n) => Object.keys(n).includes('isActive') && !n.isActive)
        .map((n) => n.id);
    } else {
      return [] as string[];
    }
  });

  return (
    <div className={'PipelineTuningSelection--pipeline-container'}>
      <PipelineTuningChart
        pipeline={pipeline}
        onSelectingNode={onSelectingNode}
        inactiveNodeIds={inactiveNodeIds}
      />

      {selectedNode && (
        <NodeEditor
          selectedNode={selectedNode}
          value={nodesValuesMap[selectedNode?.id]}
          onChange={(nodeValue: PipelineTuningValueNode) =>
            onChange({
              ...(value || ({} as PipelineTuningValue)),
              nodes: value.nodes.map((node) => {
                if (node.type === 'node') {
                  if (node.id === selectedNode.id) {
                    return nodeValue;
                  } else {
                    return node;
                  }
                } else if (node.type === 'group') {
                  return {
                    ...node,
                    nodes: node.nodes.map((n) =>
                      n.id === selectedNode.id ? nodeValue : n
                    ),
                  };
                } else {
                  return undefined;
                }
              }),
            })
          }
          onFocus={onFocus}
          onBlur={onBlur}
          error={error?.nodes?.[selectedNode?.id]}
        />
      )}
    </div>
  );
};

export default Pipeline;
