import React, { FC } from 'react';
import * as routes from '../../../index/routes';
import { usersRoutes } from '../routes';
import ResourceList from '../../../organisms/permissions/resouce-list/ResourceList';
import {
  RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX,
  RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
} from 'common/dist/constants/keycloak';

export const detailsRoute = (id) =>
  `${routes.app.prefix}${routes.app.admin.index}${routes.app.admin.users}/${usersRoutes.generalPermissions.path}/${id}`;

const GeneralPermissions: FC = () => {
  const headlines = [
    {
      headlineId: 'name',
      label: {
        id: 'no-id',
        defaultMessage: 'General Permissions',
      },
    },
  ];

  const entries = [
    {
      name: 'Model Management',
      id: RESOURCE_TYPE_DASHBOARD_MODEL_MANAGEMENT_SUFFIX,
    },
    {
      name: 'Apps',
      id: RESOURCE_TYPE_DASHBOARD_APPS_SUFFIX,
    },
    {
      name: 'Workbench & Collaboration Space',
      id: RESOURCE_TYPE_DASHBOARD_WORKBENCH_COLLAB_SUFFIX,
    },
    {
      name: 'Job Orchestration',
      id: RESOURCE_TYPE_DASHBOARD_ORCHESTRATION_SUFFIX,
    },
    {
      name: 'Data Management',
      id: RESOURCE_TYPE_DASHBOARD_DATA_MANAGEMENT_SUFFIX,
    },
  ].map((entry) => ({
    linkTo: detailsRoute(entry.id),
    columnValues: {
      name: entry.name,
    },
  }));

  return <ResourceList headlines={headlines} entries={entries} />;
};

export default GeneralPermissions;
